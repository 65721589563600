<h2 mat-dialog-title>New template</h2>
<mat-dialog-content [style.width]="'350px'">
    <form class="cm-new-item-form">
        @if ($can().update.template(null, "organization")) {
            <cm-organization-select
                [updateOrganization]="setNewTemplateOrganization.bind(this)"
                [organizationId]="newItemData.organizationId"
                [showLabel]="false"
                [usedInAddEntityContext]="true"
            ></cm-organization-select>
        }
        <mat-form-field appearance="fill">
            <input matInput placeholder="Name" [(ngModel)]="newItemData.name" name="name" />
        </mat-form-field>
        @if (!newItemData.templateType || sortedTemplateTypeLabels.length > 1) {
            <mat-form-field appearance="fill">
                <mat-select [(ngModel)]="newItemData.templateType" placeholder="Type" name="templateType" [disabled]="!$can().update.template(null, 'type')">
                    @for (templateType of sortedTemplateTypeLabels; track templateType.state) {
                        <mat-option [value]="templateType.state">{{ templateType.label }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        }
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="cancelAction()">Cancel</button>
    <button mat-button color="accent" (click)="confirmAction()" [disabled]="!newItemData.name || !newItemData.organizationId || !newItemData.templateType">
        Create
    </button>
</mat-dialog-actions>
