<div class="cm-title-left">
    <ng-content select="[cm-title-left]"></ng-content>
</div>
<div class="cm-title-right">
    <ng-content select="[cm-title-right]"></ng-content>
    @if ($screenSize() !== screenSizes.minimize) {
        <i class="far fa-window-minimize" (click)="screenSizeUpdate(screenSizes.minimize)"></i>
    }
    @if ($screenSize() !== screenSizes.splitScreen) {
        <i class="far fa-table-columns" (click)="screenSizeUpdate(screenSizes.splitScreen)"></i>
    }
    @if ($screenSize() !== screenSizes.mediumScreen) {
        <i class="far fa-compress-alt" (click)="screenSizeUpdate(screenSizes.mediumScreen)"></i>
    }
    @if ($screenSize() !== screenSizes.fullScreen) {
        <i class="far fa-expand-alt" (click)="screenSizeUpdate(screenSizes.fullScreen)"></i>
    }
</div>
