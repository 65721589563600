import {MeshNodes, DataNodes} from "@cm/render-nodes"

export interface BoundsData {
    centroid: [number, number, number]
    surfaceArea: number
    aabb: [[number, number, number], [number, number, number]]
    radii: {xy: number; xz: number; yz: number; xyz: number}
}

export interface UntransformedBoundsData {
    centroid: [number, number, number]
    surfaceArea: number
    aabb: [[number, number, number], [number, number, number]]
    radii: {xy: number; xz: number; yz: number; xyz: number}
    matrix: number[]
}

export interface MaterialGroup {
    start: number
    count: number
    materialIndex: number
}

export interface ReifiedMeshData {
    contentHash: string
    vertices: Float32Array
    normals: Float32Array
    uvs: Float32Array[]
    faceIDs: Int32Array
    materialGroups: MaterialGroup[] // start and count refer to triangle indices/counts
    centroid: [number, number, number]
    surfaceArea: number
    bounds: BoundsData
}

export interface AbstractMeshData {
    contentHash: string
    displayGeometryGraph: MeshNodes.Mesh
    renderGeometryGraph: MeshNodes.Mesh
    displayGeometryGraphResources: DataNodes.EvaluationResources
}

export interface CompleteMeshData {
    reified: ReifiedMeshData
    abstract: AbstractMeshData
}

export interface MeshBuffers {
    vertices: Float32Array
    normals: Float32Array
    uvs: Float32Array[]
    faceIDs: Int32Array
    indices: Uint32Array | Uint16Array
    materialIndex: number
}

export interface CurveData {
    vertices: Float32Array
}

function createTrivialFaceIndices(vertexCount: number): Uint32Array | Uint16Array {
    const index = new (vertexCount > 65535 ? Uint32Array : Uint16Array)(vertexCount)
    for (let i = 0; i < vertexCount; i++) index[i] = i
    return index
}

function createMeshBuffers(vertices: Float32Array, normals: Float32Array, uvs: Float32Array[], faceIDs: Int32Array, group: MaterialGroup): MeshBuffers {
    const {start, count} = group

    const vertexAndNormalStart = start * 9 // 3 vertices per triangle, 3 coordinates per vertex
    const vertexAndNormalEnd = vertexAndNormalStart + count * 9

    const uvStart = start * 6 // 3 vertices per triangle, 2 coordinates per vertex
    const uvEnd = uvStart + count * 6

    const faceIDStart = start * 3 // 3 vertices per triangle
    const faceIDEnd = faceIDStart + count * 3

    const verticesSubarray = vertices.subarray(vertexAndNormalStart, vertexAndNormalEnd)
    return {
        vertices: verticesSubarray,
        normals: normals.subarray(vertexAndNormalStart, vertexAndNormalEnd),
        uvs: uvs.map((uv) => uv.subarray(uvStart, uvEnd)),
        faceIDs: faceIDs.subarray(faceIDStart, faceIDEnd),
        indices: createTrivialFaceIndices(verticesSubarray.length / 3),
        materialIndex: group.materialIndex,
    }
}

export function createMeshBuffersForMaterialGroups(
    vertices: Float32Array,
    normals: Float32Array,
    uvs: Float32Array[],
    faceIDs: Int32Array,
    groups: MaterialGroup[],
): MeshBuffers[] {
    if (groups.length === 0) throw new Error("No material groups found")

    const numIndices = vertices.length / 3
    if (faceIDs.length !== numIndices && faceIDs.length !== numIndices / 3) throw new Error("Face IDs length does not match vertices length")
    if (faceIDs.length === numIndices) return groups.map((group) => createMeshBuffers(vertices, normals, uvs, faceIDs, group))
    else {
        const faceIDsRepeated = new Int32Array(faceIDs.length * 3)

        for (let i = 0; i < faceIDs.length; i++) {
            faceIDsRepeated[i * 3] = faceIDs[i]
            faceIDsRepeated[i * 3 + 1] = faceIDs[i]
            faceIDsRepeated[i * 3 + 2] = faceIDs[i]
        }

        return groups.map((group) => createMeshBuffers(vertices, normals, uvs, faceIDsRepeated, group))
    }
}
