// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import PackageJson from "../../package.json"

/**
 * Configuration for preview deployments (i.e. all deployments to non-prod environments).
 */
export const environment = {
    production: true,
    name: "preview",
    version: PackageJson.version,

    auth: {
        google: {
            clientId: "608675176242-9a7778mmllu4026o5m3ehi2n58vir03n.apps.googleusercontent.com",
        },
        microsoft: {
            clientId: "151d8cd7-eff9-441e-b8b9-1072287949cb",
        },
    },

    firebase: {
        apiKey: "AIzaSyD4QYSCTEdEEkMUvqtCBb6_z_JxC1pZ20A",
        authDomain: "cm-platform-dev.firebaseapp.com",
        databaseURL: "https://cm-platform-dev.firebaseio.com",
        projectId: "cm-platform-dev",
        storageBucket: "cm-platform-dev.appspot.com",
        messagingSenderId: "608675176242",
        appId: "1:608675176242:web:a1164f2858a6f638e2412b",
    },
    firebaseConfig: {
        collectionEnabled: true,
    },

    rendering: {
        materialAssets: {
            samples: 64,
        },
        shaderBall: {
            samples: 256,
        },
        defaultQueueDomain: "colormass",
    },

    storage: {
        bucketsPrefix: "cm-platform-dev-",
        usePostToUpload: false,
    },

    style: {
        navigationBar: {
            background: "#991b1b",
            label: "dev",
        },
    },

    debug: {
        enableCallStack: false,
    },

    platformDeployUrl: "https://dev.colormass.com",
    configuratorDeployUrl: "https://configurator-dev.colormass.com",
    gqlApiEndpoint: "https://gql-dev.colormass.com",
    localTilingEndpoint: "ws://localhost:5678/",

    defaultRenderQueueDomain: "render",
}
