import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { MaterialGraphExtendedRevisionFragmentDoc, MaterialGraphRevisionFragmentDoc, MaterialGraphTextureSetRevisionFragmentDoc, MaterialGraphNodeFragmentDoc, MaterialGraphConnectionFragmentDoc } from '../material-graph/material-graph.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DataObjectDetailsForNameAssetFromSchemaServiceFragment = { __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, related?: Array<{ __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, originalFileName: string }> };

export type MaterialDetailsForNameAssetFromSchemaServiceFragment = { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, name?: string | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', assignmentType: Types.DataObjectAssignmentType, dataObject: { __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, related?: Array<{ __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, originalFileName: string }> } }>, organization?: { __typename: 'Organization', name?: string | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', name: string, type: Types.TagType, otherInfo?: string | null } }>, latestCyclesRevision?: { __typename: 'MaterialRevision', legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', id: string, legacyId: number }, destination: { __typename: 'MaterialNode', id: string, legacyId: number } }>, material: { __typename: 'Material', name?: string | null, legacyId: number } } | null };

export type MaterialForNameAssetFromSchemaServiceQueryVariables = Types.Exact<{
  materialId: Types.Scalars['ID']['input'];
  dataObjectAssignmentFilter?: Types.InputMaybe<Types.DataObjectAssignmentFilterInput>;
  includeRelated: Types.Scalars['Boolean']['input'];
}>;


export type MaterialForNameAssetFromSchemaServiceQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, name?: string | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', assignmentType: Types.DataObjectAssignmentType, dataObject: { __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, related?: Array<{ __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, originalFileName: string }> } }>, organization?: { __typename: 'Organization', name?: string | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', name: string, type: Types.TagType, otherInfo?: string | null } }>, latestCyclesRevision?: { __typename: 'MaterialRevision', legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', id: string, legacyId: number }, destination: { __typename: 'MaterialNode', id: string, legacyId: number } }>, material: { __typename: 'Material', name?: string | null, legacyId: number } } | null } };

export type MaterialsForNameAssetFromSchemaServiceQueryVariables = Types.Exact<{
  filter: Types.MaterialFilterInput;
  dataObjectAssignmentFilter?: Types.InputMaybe<Types.DataObjectAssignmentFilterInput>;
  includeRelated?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type MaterialsForNameAssetFromSchemaServiceQuery = { __typename: 'Query', materials: Array<{ __typename: 'Material', id: string, legacyId: number, articleId?: string | null, name?: string | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', assignmentType: Types.DataObjectAssignmentType, dataObject: { __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, related?: Array<{ __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, originalFileName: string }> } }>, organization?: { __typename: 'Organization', name?: string | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', name: string, type: Types.TagType, otherInfo?: string | null } }>, latestCyclesRevision?: { __typename: 'MaterialRevision', legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', id: string, legacyId: number }, destination: { __typename: 'MaterialNode', id: string, legacyId: number } }>, material: { __typename: 'Material', name?: string | null, legacyId: number } } | null } | null> };

export type NameAssetFromSchemaUpdateDataObjectMutationVariables = Types.Exact<{
  input: Types.MutationUpdateDataObjectInput;
  includeRelated?: Types.Scalars['Boolean']['input'];
}>;


export type NameAssetFromSchemaUpdateDataObjectMutation = { __typename: 'Mutation', updateDataObject: { __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, related?: Array<{ __typename: 'DataObject', id: string, mediaType?: string | null, objectName: string, originalFileName: string }> } };

export const DataObjectDetailsForNameAssetFromSchemaServiceFragmentDoc = gql`
    fragment DataObjectDetailsForNameAssetFromSchemaService on DataObject {
  id
  mediaType
  objectName
  related @include(if: $includeRelated) {
    id
    mediaType
    objectName
    originalFileName
  }
}
    `;
export const MaterialDetailsForNameAssetFromSchemaServiceFragmentDoc = gql`
    fragment MaterialDetailsForNameAssetFromSchemaService on Material {
  id
  legacyId
  articleId
  dataObjectAssignments(filter: $dataObjectAssignmentFilter) {
    assignmentType
    dataObject {
      ...DataObjectDetailsForNameAssetFromSchemaService
    }
  }
  name
  organization {
    name
  }
  tagAssignments {
    tag {
      name
      type
      otherInfo
    }
  }
  latestCyclesRevision {
    ...MaterialGraphExtendedRevision
  }
}
    `;
export const MaterialForNameAssetFromSchemaServiceDocument = gql`
    query materialForNameAssetFromSchemaService($materialId: ID!, $dataObjectAssignmentFilter: DataObjectAssignmentFilterInput, $includeRelated: Boolean!) {
  material(id: $materialId) {
    ...MaterialDetailsForNameAssetFromSchemaService
  }
}
    ${MaterialDetailsForNameAssetFromSchemaServiceFragmentDoc}
${DataObjectDetailsForNameAssetFromSchemaServiceFragmentDoc}
${MaterialGraphExtendedRevisionFragmentDoc}
${MaterialGraphRevisionFragmentDoc}
${MaterialGraphNodeFragmentDoc}
${MaterialGraphTextureSetRevisionFragmentDoc}
${MaterialGraphConnectionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialForNameAssetFromSchemaServiceGQL extends Apollo.Query<MaterialForNameAssetFromSchemaServiceQuery, MaterialForNameAssetFromSchemaServiceQueryVariables> {
    override document = MaterialForNameAssetFromSchemaServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialsForNameAssetFromSchemaServiceDocument = gql`
    query materialsForNameAssetFromSchemaService($filter: MaterialFilterInput!, $dataObjectAssignmentFilter: DataObjectAssignmentFilterInput, $includeRelated: Boolean = false) {
  materials(filter: $filter) {
    ...MaterialDetailsForNameAssetFromSchemaService
  }
}
    ${MaterialDetailsForNameAssetFromSchemaServiceFragmentDoc}
${DataObjectDetailsForNameAssetFromSchemaServiceFragmentDoc}
${MaterialGraphExtendedRevisionFragmentDoc}
${MaterialGraphRevisionFragmentDoc}
${MaterialGraphNodeFragmentDoc}
${MaterialGraphTextureSetRevisionFragmentDoc}
${MaterialGraphConnectionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialsForNameAssetFromSchemaServiceGQL extends Apollo.Query<MaterialsForNameAssetFromSchemaServiceQuery, MaterialsForNameAssetFromSchemaServiceQueryVariables> {
    override document = MaterialsForNameAssetFromSchemaServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NameAssetFromSchemaUpdateDataObjectDocument = gql`
    mutation nameAssetFromSchemaUpdateDataObject($input: MutationUpdateDataObjectInput!, $includeRelated: Boolean! = false) {
  updateDataObject(input: $input) {
    ...DataObjectDetailsForNameAssetFromSchemaService
  }
}
    ${DataObjectDetailsForNameAssetFromSchemaServiceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NameAssetFromSchemaUpdateDataObjectGQL extends Apollo.Mutation<NameAssetFromSchemaUpdateDataObjectMutation, NameAssetFromSchemaUpdateDataObjectMutationVariables> {
    override document = NameAssetFromSchemaUpdateDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }