import {Component, OnInit, input} from "@angular/core"

@Component({
    selector: "cm-inspector-section",
    templateUrl: "./inspector-section.component.html",
    styleUrls: ["./inspector-section.component.scss"],
})
export class InspectorSectionComponent implements OnInit {
    constructor() {}
    readonly $allowOverflow = input<boolean>(false, {alias: "allowOverflow"})

    ngOnInit(): void {}
}
