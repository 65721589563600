import {Component, inject, OnInit} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatInputModule} from "@angular/material/input"
import {MatMenu, MatMenuItem, MatMenuModule} from "@angular/material/menu"
import {MatSelectModule} from "@angular/material/select"
import {OrganizationDetailsFragment, UpdateOrganizationGQL} from "@pages/organizations/organizations-page.generated"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {AttachmentsComponent} from "@common/components/files/attachments/attachments.component"
import {IsLoadingDirective} from "@common/directives"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {capitalizeFirstLetter} from "@common/helpers/utils/string"
import {DialogSize} from "@common/models/dialogs"
import {ContentTypeModel, DataObjectAssignmentType, MutationUpdateOrganizationInput, OrganizationType} from "@generated"
import {BaseDetailsComponent} from "@platform/components/base/base-details/base-details.component"
import {GalleryImageComponent} from "@platform/components/details/gallery-image/gallery-image.component"
import {TitleSectionComponent} from "@platform/components/details/title-section/title-section.component"
import {DetailsDialogLayoutComponent} from "@platform/components/layouts/details-dialog-layout/details-dialog-layout.component"
import {OrganizationDetailsGQL} from "@platform/components/organizations/organization-details/organization-details.generated"

@Component({
    imports: [
        AttachmentsComponent,
        DetailsDialogLayoutComponent,
        FormsModule,
        IsLoadingDirective,
        MatInputModule,
        MatSelectModule,
        RoutedDialogComponent,
        MatMenu,
        MatMenuItem,
        MatMenuModule,
        GalleryImageComponent,
        TitleSectionComponent,
        AttachmentsComponent,
    ],
    selector: "cm-organization-details",
    styleUrls: ["organization-details.component.scss"],
    templateUrl: "organization-details.component.html",
})
export class OrganizationDetailsComponent
    extends BaseDetailsComponent<OrganizationDetailsFragment, Omit<MutationUpdateOrganizationInput, "id">>
    implements OnInit
{
    override _contentTypeModel = ContentTypeModel.Organization
    override _fetchItem = fetchThrowingErrors(inject(OrganizationDetailsGQL))
    override _updateItem = mutateThrowingErrors(inject(UpdateOrganizationGQL))

    override get title() {
        const item = this.$item()
        return item?.name || (item ? `${capitalizeFirstLetter(this.displayName)} ${item?.legacyId}` : null)
    }

    hasGalleryImage() {
        return this.$item()?.dataObjectAssignments?.some((assignment) => assignment.assignmentType === DataObjectAssignmentType.GalleryImage)
    }

    parseLegacyIdFromString(legacyId: string) {
        return parseInt(legacyId, 10)
    }

    protected readonly OrganizationType = OrganizationType
    protected readonly DialogSize = DialogSize
    protected readonly assignmentTypes = [
        DataObjectAssignmentType.Attachment,
        DataObjectAssignmentType.GalleryImage,
        DataObjectAssignmentType.PdfTemplate,
        DataObjectAssignmentType.Font,
        DataObjectAssignmentType.FontBold,
    ]
}
