import {ChangeDetectorRef, Component, Input, viewChild, output} from "@angular/core"
import {MatMenu, MatMenuModule} from "@angular/material/menu"
import {MenuItem} from "@app/common/components/menu/multi-level-menu/menu-item"

@Component({
    selector: "cm-multilevel-menu",
    templateUrl: "./multi-level-menu.component.html",
    styleUrls: ["./multi-level-menu.component.scss"],
    imports: [MatMenuModule],
})
export class MultiLevelMenu {
    protected _items: MenuItem[] = []
    @Input() set items(value: MenuItem[]) {
        this._items = value
        // Explicitly trigger change detection to avoid ExpressionChangedAfterItHasBeenCheckedError after a menu update (caused by menuRef)
        this.cdr.detectChanges()
    }
    readonly $menuRef = viewChild.required<MatMenu>("menuRef")
    readonly itemClicked = output<MenuItem>()

    constructor(private cdr: ChangeDetectorRef) {}

    onMenuItemClicked(item: MenuItem) {
        this.itemClicked.emit(item)
    }
}
