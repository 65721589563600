import {inject, Injectable, Injector} from "@angular/core"
import {TemplateThumbnailRenderingFragment} from "@app/common/services/template-thumbnail-rendering/template-thumbnail-rendering.generated"
import {templateThumbnailJobGraph} from "@app/common/helpers/rendering/template/template-thumbnail-job-graph"
import {MaterialGraphService} from "@app/common/services/material-graph/material-graph.service"
import {RenderingService} from "@app/common/services/rendering/rendering.service"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {Parameters, SceneNodes} from "@cm/template-nodes"
import {graphToJson} from "@cm/utils/graph-json"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {RenderingServiceCreateJobGQL} from "@common/services/rendering/rendering.generated"
import {GetTemplateForThumbnailRenderingGQL} from "@common/services/template-thumbnail-rendering/template-thumbnail-rendering.generated"
import {loadTemplateGraph} from "@cm/template-nodes/utils/serialization-utils"

const STUDIO_SCENE_TEMPLATE_ID = "b4670fe9-0d78-4762-8d96-94dae867dfa8"

@Injectable({
    providedIn: "root",
})
export class TemplateThumbnailRenderingService {
    readonly materialGraphService = inject(MaterialGraphService)
    readonly renderingService = inject(RenderingService)

    sceneManagerService: SceneManagerService
    readonly templateGql = inject(GetTemplateForThumbnailRenderingGQL)
    readonly createJobGql = inject(RenderingServiceCreateJobGQL)

    #injector = inject(Injector)

    constructor() {
        const injector = Injector.create({providers: [SceneManagerService], parent: this.#injector})
        this.sceneManagerService = injector.get(SceneManagerService)
    }

    // Questions: error handling, reflect job progess in UI?
    async renderTemplateThumbnail(template: TemplateThumbnailRenderingFragment): Promise<void> {
        if (template.id === STUDIO_SCENE_TEMPLATE_ID) {
            throw new Error("Cannot render thumbnail for studio scene template itself")
        }

        // prepare studio scene template
        const {template: studioSceneTemplate} = await fetchThrowingErrors(this.templateGql)({templateId: STUDIO_SCENE_TEMPLATE_ID})
        const studioSceneTemplateGraph = this.getTemplateGraph(studioSceneTemplate)
        if (!studioSceneTemplateGraph) {
            throw new Error("Studio scene template is missing")
        }

        // prepare template graph
        const templateGraph = this.getTemplateGraph(template)
        if (!templateGraph) {
            throw new Error("Template graph is missing")
        }

        // prepare scene manager
        this.sceneManagerService.$templateGraph.set(studioSceneTemplateGraph)
        this.sceneManagerService.$defaultCustomerId.set(studioSceneTemplate.organization.legacyId)
        this.sceneManagerService.$instanceParameters.set(
            new Parameters({
                "studio-scene-template-input": templateGraph,
            }),
        )

        this.sceneManagerService.compileTemplate()
        await this.sceneManagerService.sync(true)

        const sceneNodes = this.sceneManagerService.$scene()
        await this.submitRenderJob(template, sceneNodes)
    }

    private getTemplateGraph(studioSceneTemplate: TemplateThumbnailRenderingFragment) {
        const templateRevision = studioSceneTemplate.latestRevision

        if (!templateRevision) return null

        const {graph} = templateRevision
        if (!graph) return null

        return loadTemplateGraph(graph)
    }

    private async submitRenderJob(template: TemplateThumbnailRenderingFragment, sceneNodes: SceneNodes.SceneNode[]) {
        const jobName = `Thumbnail generation for template ${template.id}`

        const {mainRenderTask, shadowMaskRenderTask, combinedRenderTask} = await this.renderingService.createRenderTask({
            nodes: sceneNodes,
            final: true,
            name: jobName,
            organizationLegacyId: template.organization.legacyId,
        })

        // post processing settings are ignored during rendering, we'll need to add them to the job graph
        const postProcessingSettings = sceneNodes.find(SceneNodes.RenderPostProcessingSettings.is)

        const jobGraph = templateThumbnailJobGraph({
            template,
            combinedRenderData: combinedRenderTask,
            mainRenderTask,
            shadowMaskRenderTask,
            postProcessingSettings,
        })

        return mutateThrowingErrors(this.createJobGql)({
            input: {
                name: jobName,
                organizationLegacyId: template.organization.legacyId,
                graph: graphToJson(jobGraph, console),
            },
        }).then(({createJob}) => createJob)
    }
}
