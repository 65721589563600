// import {performance} from "perf_hooks";
import {TypedImageData} from "@cm/utils/typed-image-data"
import {z} from "zod"

type CryptomatteEntity = "object" | "material" | "asset"

export const CryptomatteIdSchema = z
    .string()
    .refine((value) => value.startsWith("object:") || value.startsWith("material:") || value.startsWith("asset:")) as z.ZodType<CryptomatteId>

export type CryptomatteId = `${CryptomatteEntity}:${string}`

export const CryptomatteManifestSchema = z.object({
    objects: z.record(z.string(), z.string()).optional(), // key is ID without entity tag, value is hex string
    materials: z.record(z.string(), z.string()).optional(),
    assets: z.record(z.string(), z.string()).optional(),
})

export type CryptomatteManifest = z.infer<typeof CryptomatteManifestSchema>

export class MatteProcessing {
    private idSets: Set<number>[]
    private width: number | undefined
    private height: number | undefined
    private idMap = new Map<`${CryptomatteEntity}:${string}`, number>()

    constructor(
        manifest: CryptomatteManifest,
        private passes: TypedImageData[],
    ) {
        for (const entityType of ["object", "material", "asset"] as CryptomatteEntity[]) {
            for (const [id, value] of Object.entries(manifest[`${entityType}s`] ?? {})) {
                this.idMap.set(`${entityType}:${id}`, parseInt(value, 16))
            }
        }
        this.idSets = passes.map((p) => this.ingestPass(p))
    }

    private ingestPass(pass: TypedImageData): Set<number> {
        // const tStart = performance.now();

        if (this.width && pass.width !== this.width) throw new Error("Invalid pass width")
        if (this.height && pass.height !== this.height) throw new Error("Invalid pass height")
        this.width = pass.width
        this.height = pass.height

        const uintView = new Uint32Array(pass.data.buffer)
        const numPixels = uintView.length / 4
        let ofs = 0
        const idSet = new Set<number>()
        for (let idx = 0; idx < numPixels; idx++) {
            idSet.add(uintView[ofs + 0])
            idSet.add(uintView[ofs + 2])
            ofs += 4
        }

        return idSet

        // console.log(`matteProcessing.ingestPass duration : ${performance.now() - tStart}`);
    }

    generateCoverageMask(ids: CryptomatteId[]): TypedImageData<ArrayBufferView, "L", "float32"> {
        // const tStart = performance.now();
        if (!(this.width && this.height)) throw new Error("No cryptomatte data")

        const idSet = new Set(
            ids.map((id) => {
                const value = this.idMap.get(id)
                if (value == null) throw Error(`ID ${id} not found in cryptomatte manifest`)
                return value
            }),
        )

        const numPixels = this.width * this.height
        const outCoverage = new Float32Array(numPixels)
        for (const id of idSet) {
            for (let passIdx = 0; passIdx < this.passes.length; passIdx++) {
                const pass = this.passes[passIdx]
                if (!this.idSets[passIdx].has(id)) continue
                const uintView = new Uint32Array(pass.data.buffer, pass.data.byteOffset, pass.data.byteLength / 4)
                const floatView = new Float32Array(pass.data.buffer, pass.data.byteOffset, pass.data.byteLength / 4)
                let ofs = 0
                for (let i = 0; i < numPixels; i++) {
                    // each crypto matte input image contains 2 ranks with ids and coverage stored in RGBA channels
                    if (uintView[ofs + 0] === id) outCoverage[i] += floatView[ofs + 1]
                    if (uintView[ofs + 2] === id) outCoverage[i] += floatView[ofs + 3]
                    ofs += 4
                }
            }
        }

        // console.log(`matteProcessing.getCoverageMaskForIdSet duration : ${performance.now() - tStart}`);
        return {
            data: outCoverage,
            width: this.width,
            height: this.height,
            channelLayout: "L",
            dataType: "float32",
            colorSpace: "linear",
        }
    }
}
