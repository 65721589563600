import {Component, OnInit, viewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {CombineXYZInputs, CombineXYZOutputs} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {ParameterValue} from "@node-editor/models/events"

@Component({
    selector: "cm-combine-xyz-node",
    templateUrl: "./combine-xyz-node.component.html",
    styleUrls: ["./combine-xyz-node.component.scss"],
    imports: [NodeBaseComponent],
})
export class CombineXYZNodeComponent implements OnInit {
    readonly $nodeBase = viewChild.required<MaterialNodeBase>("nodeBase")
    outputs = CombineXYZOutputs
    inputs = CombineXYZInputs
    typeInfo = CombineXYZNodeType

    ngOnInit() {
        //TODO: Move parameter creation out of the UI class into a separate "functional" node layer, see https://github.com/colormass/platform/issues/1307#issuecomment-1566146464
        const nodeBase = this.$nodeBase()
        if (!nodeBase.node.parameters.find((parameter: ParameterValue) => parameter.name === "Vector"))
            nodeBase.node.parameters.push({name: "Vector", type: "vector", value: [0, 0, 0]})
    }
}

export const CombineXYZNodeType: MaterialNodeType<typeof CombineXYZNodeComponent> = {
    id: "combineXYZ",
    label: "Combine XYZ",
    color: "#4987af",
    name: "ShaderNodeCombineXYZ",
    inputs: [CombineXYZInputs.X, CombineXYZInputs.Y, CombineXYZInputs.Z],
    outputs: [CombineXYZOutputs.image],
    component: CombineXYZNodeComponent,
}
