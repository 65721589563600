import {Component, viewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {MapRangeInputs, MapRangeOutputs} from "@material-editor/models/nodes/map-range"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-map-range-node",
    templateUrl: "./map-range-node.component.html",
    styleUrls: ["./map-range-node.component.scss"],
    imports: [NodeBaseComponent],
})
export class MapRangeNodeComponent {
    readonly $nodeBase = viewChild<MaterialNodeBase>("nodeBase")
    inputs = MapRangeInputs
    outputs = MapRangeOutputs
    typeInfo = MapRangeNodeType
}

export const MapRangeNodeType: MaterialNodeType<typeof MapRangeNodeComponent> = {
    id: "mapRange",
    label: "Map Range",
    color: "#4987af",
    name: "ShaderNodeMapRange",
    inputs: [MapRangeInputs.vector, MapRangeInputs.fromMin, MapRangeInputs.fromMax, MapRangeInputs.toMin, MapRangeInputs.toMax] as never[],
    outputs: [MapRangeOutputs.vector],
    component: MapRangeNodeComponent,
}
