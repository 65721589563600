import {Component, DestroyRef, effect, inject, Injector} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {ConfigMenuComponent} from "@app/common/components/viewers/configurator/config-menu/config-menu.component"
import {ConfigMenuService} from "@app/common/components/viewers/configurator/config-menu/services/config-menu.service"
import {createMaterialReferenceFromLegacyId} from "@app/common/components/viewers/configurator/helpers/parameters"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {ThreeSceneManagerService} from "@app/template-editor/services/three-scene-manager.service"

@Component({
    selector: "cm-template-select-variation",
    imports: [ConfigMenuComponent],
    templateUrl: "./template-select-variation.component.html",
    styleUrl: "./template-select-variation.component.scss",
})
export class TemplateSelectVariationComponent {
    private readonly sceneManagerService = inject(SceneManagerService)
    private readonly threeSceneManagerService = inject(ThreeSceneManagerService)
    private readonly configMenuService = inject(ConfigMenuService)
    private readonly destroyRef = inject(DestroyRef)
    private readonly injector = inject(Injector)

    constructor() {
        effect(() => {
            this.configMenuService.setUiStyle("icons")
        })
    }

    ngOnInit() {
        this.sceneManagerService.descriptorList$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((descriptors) => {
            this.configMenuService.setInterface(descriptors)
        })

        this.configMenuService.configSelected$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((config) => {
            this.sceneManagerService.setTemplateParameter(config.config.props.id, config.variant.id)
            this.syncConfigChangesAndNotify()
        })

        this.configMenuService.materialSelected$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (material) => {
            const materialReference = await createMaterialReferenceFromLegacyId(material.legacyId, this.injector)
            this.sceneManagerService.setTemplateParameter(material.input.props.id, materialReference)
        })
    }

    private async syncConfigChangesAndNotify() {
        await this.threeSceneManagerService.sync(true)
        this.configMenuService.setSynchronizing(false)
    }
}
