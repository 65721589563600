import {Component, input} from "@angular/core"
import {MatMenuModule, MatMenuPanel} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {LoadingSpinnerIconComponent} from "@app/common/components/progress"
import {DisableButtonAfterClickDirective} from "@app/common/directives"
import {MaterialOutputFragment} from "@app/platform/components/materials/material-outputs/material-outputs.generated"

export type RenderEngineOptions = {useGpu: boolean; useCloud: boolean}

export type SharedOutputButtonProps = {
    buttonClass: "cm-text-button" | "cm-button"
    icon?: string
    label: string
}

type InitialOutputButtonProps = {
    state: "initial"
    createTooltip: string
    onCreate: (options?: RenderEngineOptions) => void
}

type LoadingOutputButtonProps = {
    state: "loading"
    cancelTooltip: string
    canCancel: boolean
    onCancel?: () => void
}

type FailedOutputButtonProps = {
    state: "failed"
    onRemove?: () => void
    onRetry: () => void
    removeTooltip?: string
    retryTooltip: string
}

type CompletedOutputButtonProps = {
    state: "complete"
    canEdit?: boolean
    canPreview?: boolean
    canRemove?: boolean
    downloadTooltip: string
    editTooltip?: string
    hasUpdate?: boolean
    onDownload?: () => void
    onEdit?: () => void
    onPreview?: () => void
    onRemove?: () => void
    previewTooltip?: string
    removeTooltip?: string
    result?: MaterialOutputFragment["result"]
    updateTooltip?: string
}

export type OutputButtonProps = SharedOutputButtonProps &
    (InitialOutputButtonProps | LoadingOutputButtonProps | FailedOutputButtonProps | CompletedOutputButtonProps)

@Component({
    selector: "cm-material-output-button",
    imports: [DisableButtonAfterClickDirective, LoadingSpinnerIconComponent, MatMenuModule, MatTooltipModule],
    templateUrl: "./material-output-button.component.html",
    styleUrl: "./material-output-button.component.scss",
})
export class MaterialOutputButtonComponent {
    readonly $debugMenu = input<MatMenuPanel | null>(null, {alias: "debugMenu"})
    readonly $imageDownloadMenu = input<MatMenuPanel | null>(null, {alias: "imageDownloadMenu"})
    readonly $isDebugEnabled = input<boolean>(false, {alias: "isDebugEnabled"})
    readonly $props = input.required<OutputButtonProps>({alias: "props"})

    // workaround for nested tooltips
    outerTooltipDisabled = false

    constructor() {}
}
