import {Box2Like, ColorLike, Size2Like, Vector2Like} from "@cm/math"
import {HalImageSourceData} from "@common/models/hal/hal-image/types"
import {DrawArgs} from "@common/models/hal/hal-paintable"
import {HalImageView} from "@common/models/hal/hal-image"
import {WebGl2ImagePhysical} from "@common/models/webgl2/webgl2-image-physical"
import {WebGl2ImageVirtual} from "@common/models/webgl2/webgl2-image-virtual"
import {clearPaintable} from "@common/helpers/webgl2/webgl2-painter-utils"
import {WebGl2Context} from "@common/models/webgl2/webgl2-context"

export class WebGl2ImageView implements HalImageView {
    readonly isHalImage = true
    readonly isHalImageView = true

    readonly context: WebGl2Context

    constructor(
        readonly image: WebGl2ImagePhysical | WebGl2ImageVirtual,
        private region: Box2Like,
    ) {
        this.context = image.context
        if (
            region.x < 0 ||
            region.y < 0 ||
            region.width < 0 ||
            region.height < 0 ||
            region.width > image.descriptor.width ||
            region.height > image.descriptor.height
        ) {
            throw new Error("Invalid region")
        }
    }

    get descriptor() {
        return {
            ...this.image.descriptor,
            width: this.region.width,
            height: this.region.height,
            textureOffset: this.image.descriptor.textureOffset.add(this.region),
        }
    }

    get forceAlphaToOne() {
        return this.image.forceAlphaToOne
    }

    dispose(): void {
        this.image.dispose()
    }

    // HalPaintable
    beginDraw(args: DrawArgs): number {
        return this.image.beginDraw({
            ...args,
            region: this.adjustRegion(args.region),
        })
    }

    // HalPaintable
    beginDrawPass(args: DrawArgs, pass: number) {
        return this.image.beginDrawPass(
            {
                ...args,
                region: this.adjustRegion(args.region),
            },
            pass,
        )
    }

    // HalPaintable
    endDrawPass(args: DrawArgs, pass: number) {
        this.image.endDrawPass(
            {
                ...args,
                region: this.adjustRegion(args.region),
            },
            pass,
        )
    }

    // HalPaintable
    endDraw(args: DrawArgs): void {
        this.image.endDraw({
            ...args,
            region: this.adjustRegion(args.region),
        })
    }

    // HalPaintable
    get width(): number {
        return this.descriptor.width
    }

    // HalPaintable
    get height(): number {
        return this.descriptor.height
    }

    // HalPaintable
    clear(color?: ColorLike, mipLevel?: number) {
        clearPaintable(this, color, mipLevel)
    }

    get numMipLevels(): number {
        return this.image.numMipLevels
    }

    getMipLevelSize(mipLevel: number): Size2Like {
        return this.image.getMipLevelSize(mipLevel)
    }

    readImageDataFloat(region?: Box2Like): Float32Array {
        return this.image.readImageDataFloat(this.adjustRegion(region))
    }

    writeImageData(sourceData: HalImageSourceData, sourceRegion?: Box2Like, targetOffset?: Vector2Like): void {
        this.image.writeImageData(sourceData, sourceRegion, this.adjustOffset(targetOffset))
    }

    private adjustRegion(region?: Box2Like): Box2Like {
        region ??= {x: 0, y: 0, width: this.region.width, height: this.region.height}
        if (region.x >= this.region.width || region.y >= this.region.height) {
            throw new Error("Invalid region")
        }
        return {
            ...this.adjustOffset(region),
            width: region.width,
            height: region.height,
        }
    }

    private adjustOffset(offset?: Vector2Like): Vector2Like {
        offset ??= {x: 0, y: 0}
        return {
            x: this.region.x + offset.x,
            y: this.region.y + offset.y,
        }
    }
}
