import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type FilesGridDataObjectFragment = { __typename: 'DataObject', id: string, legacyId: number, state: Types.DataObjectState, name: string, organization: { __typename: 'Organization', id: string, name?: string | null } };

export type FilesGridItemsQueryVariables = Types.Exact<{
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.DataObjectFilterInput>;
}>;


export type FilesGridItemsQuery = { __typename: 'Query', dataObjectsCount: number, dataObjects: Array<{ __typename: 'DataObject', id: string, legacyId: number, state: Types.DataObjectState, name: string, organization: { __typename: 'Organization', id: string, name?: string | null } } | null> };

export type FilesGridCreateDataObjectMutationVariables = Types.Exact<{
  input: Types.MutationCreateDataObjectInput;
}>;


export type FilesGridCreateDataObjectMutation = { __typename: 'Mutation', createDataObject: { __typename: 'DataObject', id: string, legacyId: number, state: Types.DataObjectState, name: string, organization: { __typename: 'Organization', id: string, name?: string | null } } };

export const FilesGridDataObjectFragmentDoc = gql`
    fragment FilesGridDataObject on DataObject {
  id
  legacyId
  name: originalFileName
  organization {
    id
    name
  }
  state
}
    `;
export const FilesGridItemsDocument = gql`
    query filesGridItems($take: Int, $skip: Int, $filter: DataObjectFilterInput) {
  dataObjects(take: $take, skip: $skip, filter: $filter) {
    ...FilesGridDataObject
  }
  dataObjectsCount(filter: $filter)
}
    ${FilesGridDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FilesGridItemsGQL extends Apollo.Query<FilesGridItemsQuery, FilesGridItemsQueryVariables> {
    override document = FilesGridItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FilesGridCreateDataObjectDocument = gql`
    mutation filesGridCreateDataObject($input: MutationCreateDataObjectInput!) {
  createDataObject(input: $input) {
    ...FilesGridDataObject
  }
}
    ${FilesGridDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FilesGridCreateDataObjectGQL extends Apollo.Mutation<FilesGridCreateDataObjectMutation, FilesGridCreateDataObjectMutationVariables> {
    override document = FilesGridCreateDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }