import * as Types from '../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TemplateListItemFragment = { __typename: 'Template', id: string, legacyId: number, name?: string | null, state: Types.TemplateState, type: Types.TemplateType, public: boolean, comment?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, organization: { __typename: 'Organization', name?: string | null, id: string }, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, completed: boolean, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string } | null, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null };

export type TemplateWithLatestRevisionFragment = { __typename: 'Template', state: Types.TemplateState, type: Types.TemplateType, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string } | null } | null };

export type GetOrganizationUuidFromTemplateUuidQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetOrganizationUuidFromTemplateUuidQuery = { __typename: 'Query', template: { __typename: 'Template', id: string, organizationId: string } };

export type GetTemplateUuidFromLegacyIdQueryVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type GetTemplateUuidFromLegacyIdQuery = { __typename: 'Query', template: { __typename: 'Template', id: string } };

export type GetTemplateLegacyIdFromUuidQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetTemplateLegacyIdFromUuidQuery = { __typename: 'Query', template: { __typename: 'Template', id: string, legacyId: number } };

export type GetTemplateUuidFromSceneLegacyIdQueryVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type GetTemplateUuidFromSceneLegacyIdQuery = { __typename: 'Query', scene: { __typename: 'Scene', picture: { __typename: 'Picture', template?: { __typename: 'Template', id: string } | null } } };

export type GetTemplateMaterialRevisionIdFromLegacyIdQueryVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type GetTemplateMaterialRevisionIdFromLegacyIdQuery = { __typename: 'Query', materialRevision: { __typename: 'MaterialRevision', id: string } };

export type GetTemplateWithLatestRevisionQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetTemplateWithLatestRevisionQuery = { __typename: 'Query', template: { __typename: 'Template', state: Types.TemplateState, type: Types.TemplateType, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string } | null } | null } };

export type GetTemplateRevisionDetailsQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetTemplateRevisionDetailsQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', id: string, graph?: any | null, template: { __typename: 'Template', id: string, legacyId: number } } };

export type GetTemplatesQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.TemplateFilterInput>;
  orderBy?: Types.InputMaybe<Array<Types.TemplateOrderByInput> | Types.TemplateOrderByInput>;
}>;


export type GetTemplatesQuery = { __typename: 'Query', templatesCount: number, templates: Array<{ __typename: 'Template', id: string, legacyId: number, name?: string | null, state: Types.TemplateState, type: Types.TemplateType, public: boolean, comment?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, organization: { __typename: 'Organization', name?: string | null, id: string }, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, completed: boolean, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string } | null, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null } | null> };

export type BatchUpdateArStatisticsMutationVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ArStatisticsFilterInput>;
  templateRevisionId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type BatchUpdateArStatisticsMutation = { __typename: 'Mutation', batchUpdateArStatistics: number };

export type BatchUpdateDataObjectAssignmentsMutationVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.DataObjectAssignmentFilterInput>;
  contentTypeModel: Types.ContentTypeModel;
  objectId: Types.Scalars['ID']['input'];
}>;


export type BatchUpdateDataObjectAssignmentsMutation = { __typename: 'Mutation', batchUpdateDataObjectAssignments: number };

export type BatchUpdateRenderJobsMutationVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.RenderJobFilterInput>;
  templateRevisionId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type BatchUpdateRenderJobsMutation = { __typename: 'Mutation', batchUpdateRenderJobs: number };

export type BatchUpdateSceneStatisticsMutationVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.SceneStatisticsFilterInput>;
  templateId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type BatchUpdateSceneStatisticsMutation = { __typename: 'Mutation', batchUpdateSceneStatistics: number };

export type CreateTemplateMutationVariables = Types.Exact<{
  input: Types.MutationCreateTemplateInput;
}>;


export type CreateTemplateMutation = { __typename: 'Mutation', createTemplate: { __typename: 'Template', id: string, legacyId: number, name?: string | null, state: Types.TemplateState, type: Types.TemplateType, public: boolean, comment?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, organization: { __typename: 'Organization', name?: string | null, id: string }, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, completed: boolean, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string } | null, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null } };

export type CreateTemplateRevisionMutationVariables = Types.Exact<{
  input: Types.MutationCreateTemplateRevisionInput;
}>;


export type CreateTemplateRevisionMutation = { __typename: 'Mutation', createTemplateRevision: { __typename: 'TemplateRevision', id: string, legacyId: number } };

export type UpdateTemplateNameMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpdateTemplateNameMutation = { __typename: 'Mutation', updateTemplate: { __typename: 'Template', legacyId: number } };

export type UpdateTemplateMutationVariables = Types.Exact<{
  input: Types.MutationUpdateTemplateInput;
}>;


export type UpdateTemplateMutation = { __typename: 'Mutation', updateTemplate: { __typename: 'Template', id: string, legacyId: number, name?: string | null, state: Types.TemplateState, type: Types.TemplateType, public: boolean, comment?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, organization: { __typename: 'Organization', name?: string | null, id: string }, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, completed: boolean, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string } | null, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null } };

export const TemplateListItemFragmentDoc = gql`
    fragment TemplateListItem on Template {
  id
  legacyId
  name
  state
  galleryImage {
    id
  }
  type
  public
  comment
  organization {
    name
    id
  }
  latestRevision {
    id
    legacyId
    completed
    createdAt
    graph
    createdBy {
      id
    }
    renderedImages(take: 4) {
      id
    }
  }
}
    `;
export const TemplateWithLatestRevisionFragmentDoc = gql`
    fragment TemplateWithLatestRevision on Template {
  state
  type
  latestRevision {
    id
    legacyId
    createdAt
    graph
    createdBy {
      id
    }
  }
}
    `;
export const GetOrganizationUuidFromTemplateUuidDocument = gql`
    query getOrganizationUuidFromTemplateUuid($id: ID!) {
  template(id: $id) {
    id
    organizationId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOrganizationUuidFromTemplateUuidGQL extends Apollo.Query<GetOrganizationUuidFromTemplateUuidQuery, GetOrganizationUuidFromTemplateUuidQueryVariables> {
    override document = GetOrganizationUuidFromTemplateUuidDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateUuidFromLegacyIdDocument = gql`
    query getTemplateUuidFromLegacyId($legacyId: Int!) {
  template(legacyId: $legacyId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateUuidFromLegacyIdGQL extends Apollo.Query<GetTemplateUuidFromLegacyIdQuery, GetTemplateUuidFromLegacyIdQueryVariables> {
    override document = GetTemplateUuidFromLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateLegacyIdFromUuidDocument = gql`
    query getTemplateLegacyIdFromUuid($id: ID!) {
  template(id: $id) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateLegacyIdFromUuidGQL extends Apollo.Query<GetTemplateLegacyIdFromUuidQuery, GetTemplateLegacyIdFromUuidQueryVariables> {
    override document = GetTemplateLegacyIdFromUuidDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateUuidFromSceneLegacyIdDocument = gql`
    query getTemplateUuidFromSceneLegacyId($legacyId: Int!) {
  scene(legacyId: $legacyId) {
    picture {
      template {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateUuidFromSceneLegacyIdGQL extends Apollo.Query<GetTemplateUuidFromSceneLegacyIdQuery, GetTemplateUuidFromSceneLegacyIdQueryVariables> {
    override document = GetTemplateUuidFromSceneLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateMaterialRevisionIdFromLegacyIdDocument = gql`
    query getTemplateMaterialRevisionIdFromLegacyId($legacyId: Int!) {
  materialRevision(legacyId: $legacyId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateMaterialRevisionIdFromLegacyIdGQL extends Apollo.Query<GetTemplateMaterialRevisionIdFromLegacyIdQuery, GetTemplateMaterialRevisionIdFromLegacyIdQueryVariables> {
    override document = GetTemplateMaterialRevisionIdFromLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateWithLatestRevisionDocument = gql`
    query getTemplateWithLatestRevision($id: ID!) {
  template(id: $id) {
    ...TemplateWithLatestRevision
  }
}
    ${TemplateWithLatestRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateWithLatestRevisionGQL extends Apollo.Query<GetTemplateWithLatestRevisionQuery, GetTemplateWithLatestRevisionQueryVariables> {
    override document = GetTemplateWithLatestRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateRevisionDetailsDocument = gql`
    query getTemplateRevisionDetails($id: ID, $legacyId: Int) {
  templateRevision(id: $id, legacyId: $legacyId) {
    id
    graph
    template {
      id
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateRevisionDetailsGQL extends Apollo.Query<GetTemplateRevisionDetailsQuery, GetTemplateRevisionDetailsQueryVariables> {
    override document = GetTemplateRevisionDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplatesDocument = gql`
    query getTemplates($skip: Int, $take: Int, $filter: TemplateFilterInput, $orderBy: [TemplateOrderByInput!]) {
  templates(skip: $skip, take: $take, filter: $filter, orderBy: $orderBy) {
    ...TemplateListItem
  }
  templatesCount(filter: $filter)
}
    ${TemplateListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplatesGQL extends Apollo.Query<GetTemplatesQuery, GetTemplatesQueryVariables> {
    override document = GetTemplatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchUpdateArStatisticsDocument = gql`
    mutation batchUpdateArStatistics($filter: ArStatisticsFilterInput, $templateRevisionId: ID) {
  batchUpdateArStatistics(
    filter: $filter
    templateRevisionId: $templateRevisionId
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchUpdateArStatisticsGQL extends Apollo.Mutation<BatchUpdateArStatisticsMutation, BatchUpdateArStatisticsMutationVariables> {
    override document = BatchUpdateArStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchUpdateDataObjectAssignmentsDocument = gql`
    mutation batchUpdateDataObjectAssignments($filter: DataObjectAssignmentFilterInput, $contentTypeModel: ContentTypeModel!, $objectId: ID!) {
  batchUpdateDataObjectAssignments(
    filter: $filter
    contentTypeModel: $contentTypeModel
    objectId: $objectId
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchUpdateDataObjectAssignmentsGQL extends Apollo.Mutation<BatchUpdateDataObjectAssignmentsMutation, BatchUpdateDataObjectAssignmentsMutationVariables> {
    override document = BatchUpdateDataObjectAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchUpdateRenderJobsDocument = gql`
    mutation batchUpdateRenderJobs($filter: RenderJobFilterInput, $templateRevisionId: ID) {
  batchUpdateRenderJobs(filter: $filter, templateRevisionId: $templateRevisionId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchUpdateRenderJobsGQL extends Apollo.Mutation<BatchUpdateRenderJobsMutation, BatchUpdateRenderJobsMutationVariables> {
    override document = BatchUpdateRenderJobsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchUpdateSceneStatisticsDocument = gql`
    mutation batchUpdateSceneStatistics($filter: SceneStatisticsFilterInput, $templateId: ID) {
  batchUpdateSceneStatistics(filter: $filter, templateId: $templateId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchUpdateSceneStatisticsGQL extends Apollo.Mutation<BatchUpdateSceneStatisticsMutation, BatchUpdateSceneStatisticsMutationVariables> {
    override document = BatchUpdateSceneStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTemplateDocument = gql`
    mutation createTemplate($input: MutationCreateTemplateInput!) {
  createTemplate(input: $input) {
    ...TemplateListItem
  }
}
    ${TemplateListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTemplateGQL extends Apollo.Mutation<CreateTemplateMutation, CreateTemplateMutationVariables> {
    override document = CreateTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTemplateRevisionDocument = gql`
    mutation createTemplateRevision($input: MutationCreateTemplateRevisionInput!) {
  createTemplateRevision(input: $input) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTemplateRevisionGQL extends Apollo.Mutation<CreateTemplateRevisionMutation, CreateTemplateRevisionMutationVariables> {
    override document = CreateTemplateRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTemplateNameDocument = gql`
    mutation UpdateTemplateName($id: ID!, $name: String) {
  updateTemplate(input: {id: $id, name: $name}) {
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTemplateNameGQL extends Apollo.Mutation<UpdateTemplateNameMutation, UpdateTemplateNameMutationVariables> {
    override document = UpdateTemplateNameDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTemplateDocument = gql`
    mutation updateTemplate($input: MutationUpdateTemplateInput!) {
  updateTemplate(input: $input) {
    ...TemplateListItem
  }
}
    ${TemplateListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTemplateGQL extends Apollo.Mutation<UpdateTemplateMutation, UpdateTemplateMutationVariables> {
    override document = UpdateTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }