import {InterfaceDescriptor} from "#template-nodes/interface-descriptors"
import {IMaterialGraph} from "@cm/material-nodes/interfaces/material-data"
import {ObjectData, MeshData} from "#template-nodes/interfaces/object-data"
import {ISceneManager} from "#template-nodes/interfaces/scene-manager"
import {ObjectId, SceneNodes} from "#template-nodes/interfaces/scene-object"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {MaterialLike} from "#template-nodes/node-types"
import {MaterialAssignments} from "#template-nodes/nodes/material-assignment"
import {MeshDecal} from "#template-nodes/nodes/mesh-decal"
import {TemplateParameterValue} from "#template-nodes/nodes/parameters"
import {Render} from "#template-nodes/nodes/render"
import {SceneProperties} from "#template-nodes/nodes/scene-properties"
import {TemplateGraph, TemplateGraphParameters} from "#template-nodes/nodes/template-graph"
import {TemplateInstance} from "#template-nodes/nodes/template-instance"
import {BuilderInlet, BuilderOutlet} from "#template-nodes/runtime-graph/graph-builder"
import {TransformAccessorListEntry} from "#template-nodes/runtime-graph/nodes/compile-template"
import {SolverData} from "#template-nodes/runtime-graph/nodes/solver/data"
import {SolverObjectData} from "#template-nodes/runtime-graph/nodes/solver/object-data"
import {SolverRelationData} from "#template-nodes/runtime-graph/nodes/solver/relation-data"
import {SolverVariableData} from "#template-nodes/runtime-graph/nodes/solver/variable-data"
import {NotReady} from "#template-nodes/runtime-graph/slots"
import {ImageGenerator} from "@cm/material-nodes/nodes/apply-mesh-render-settings"
import {Matrix4} from "@cm/math"
import {BoundsData, UntransformedBoundsData} from "#template-nodes/geometry-processing/mesh-data"
import {
    ModedNodeContext,
    modedNodeContext,
    visitorContext as visitorContextSchema,
    VisitorNode,
    VisitorNodeContext as VisitorNodeContextType,
    VisitorNodeResult,
} from "@cm/graph/declare-visitor-node"
import {isNodeGraphInstance, jsonSchema, NodeGraphDifferences, NodeGraphSnapshot, NodeParameters} from "@cm/graph/node-graph"
import {z} from "zod"

export enum VisitMode {
    FilterActive = "onFilterActive",
    Compile = "onCompile",
}
export const visitMode = z.enum(["onFilterActive", "onCompile"])

export type TemplateNode<ParamTypes extends NodeParameters = {}> = VisitorNode<Context, ParamTypes>
export const isTemplateNode = (instance: unknown): instance is TemplateNode => isNodeGraphInstance(instance)
export const templateNode = z.unknown().superRefine((arg, ctx): arg is TemplateNode => {
    if (!isTemplateNode(arg))
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Expected template node",
            fatal: true,
        })

    return z.NEVER
}) // We have to use .superRefine() because .refine() doesn't abort early https://github.com/colinhacks/zod#abort-early

export type TemplateGraphDifferences = NodeGraphDifferences<Context>
export type TemplateGraphSnapshot = NodeGraphSnapshot<VisitorNodeResult<TemplateGraphParameters>, Context, TemplateGraphParameters>

export type TemplateData = {
    graph: TemplateGraph
}

export const unmodedContext = z.object({
    onTraverseAll: z.unknown(),
    onFilterActive: z.unknown(),
    onCompile: z.unknown(),
})
export type OnCompileContext = {
    evaluator: NodeEvaluator
    root: TemplateGraph
    sceneProperties: SceneProperties | undefined
    render: Render | undefined
    templateDepth: number
    topLevelObjectId: string | null
    overrideMaterial?: (parent: MeshDecal | {materialAssignments: MaterialAssignments; key: string}) => MaterialLike | null | undefined
    currentTemplate: {
        preDisplayList: BuilderOutlet<SceneNodes.SceneNode | null>[]
        displayList: BuilderOutlet<SceneNodes.SceneNode | null>[]
        descriptorList: BuilderOutlet<InterfaceDescriptor<unknown>>[]
        solverData: {
            solverObjects: BuilderOutlet<SolverObjectData>[]
            solverVariables: BuilderOutlet<SolverVariableData>[]
            solverRelations: BuilderOutlet<SolverRelationData | null>[]
        }
        transformAccessorList: BuilderOutlet<TransformAccessorListEntry>[]
        allBounds: BuilderOutlet<BoundsData>[]
        allUntransformedBounds: BuilderOutlet<UntransformedBoundsData>[]
        templateOutputs: {[id: string]: BuilderInlet<EvaluatedTemplateInput["value"]>}
        nodeToObjectMap: Map<TemplateNode, ObjectId>
        objectToNodeMap: Map<ObjectId, TemplateNode>
    }
    subTemplates: {
        readyList: BuilderOutlet<boolean>[]
        preDisplayLists: BuilderOutlet<SceneNodes.SceneNode[]>[]
        displayLists: BuilderOutlet<SceneNodes.SceneNode[]>[]
        exposeClaimedSubTemplateInputs: boolean
        descriptorLists: BuilderOutlet<InterfaceDescriptor<unknown>[]>[]
        solverDatas: BuilderOutlet<SolverData>[]
        lookupByExternalIdPathMap: Map<TemplateInstance, BuilderOutlet<(x: string[]) => TemplateNode | null>>
    }
}
export type UnmodedContext = {
    onTraverseAll?: {}
    onFilterActive?: {
        evaluator: NodeEvaluator
        root: TemplateGraph
        onVisitedSceneProperties: (sceneProperties: SceneProperties) => boolean
        onVisitedRender: (sceneProperties: Render) => boolean
    }
    onCompile?: OnCompileContext
}

export const context = modedNodeContext(unmodedContext, visitMode)
export type Context = ModedNodeContext<VisitMode, UnmodedContext>
export const visitorContext = visitorContextSchema(context)
export type VisitorNodeContext = VisitorNodeContextType<Context>

export function isValidExternalId(id: string) {
    if (id.length === 0) return false
    else return /^[a-zA-Z0-9_\/-]+$/.test(id)
}

export const externalId = z.string().refine(isValidExternalId)
export type ExternalId = z.infer<typeof externalId>

const number01 = z.number().min(0).max(1)
export const colorValue = z.tuple([number01, number01, number01])
export type ColorValue = z.infer<typeof colorValue>

export const vectorValue = z.tuple([z.number(), z.number(), z.number()])
export type VectorValue = z.infer<typeof vectorValue>

export const matrix4Value = z.array(z.number()).length(16)
export type Matrix4Value = z.infer<typeof matrix4Value>

export const positionValue = z.tuple([z.number(), z.number(), z.number()])
export type PositionValue = z.infer<typeof positionValue>

export const eulerValue = z.tuple([z.number(), z.number(), z.number()])
export type EulerValue = z.infer<typeof eulerValue>

export const anyJsonValue = jsonSchema
export type AnyJSONValue = z.infer<typeof anyJsonValue>
export const isAnyJSONValue = (value: unknown): value is AnyJSONValue => anyJsonValue.safeParse(value).success

export type EvaluatedTemplateValueType = "material" | "template" | "object" | "image" | "nodes" | "string" | "number" | "boolean" | "json" | "unknown"
export type EvaluatedTemplateInput = {
    type: EvaluatedTemplateValueType
    origin?: TemplateParameterValue
    value: IMaterialGraph | TemplateData | ObjectData | ImageGenerator | AnyJSONValue | EvaluatedTemplateInput[]
}
export type EvaluatedTemplateInputs = {[inputId: string]: EvaluatedTemplateInput | typeof NotReady}
export type EvaluatedTemplateOutputs = {[outputId: string]: any}

export const lodType = z.enum(["web", "ar", "pathTraced"])
export type LodType = z.infer<typeof lodType>
export const getLodTypeDescription = (lodType: LodType) => {
    switch (lodType) {
        case "web":
            return "Web"
        case "ar":
            return "AR"
        case "pathTraced":
            return "Path-traced"
    }
}

export type TemplateContext = {
    sceneManager: ISceneManager
    templateMatrix: Matrix4
    lodType: LodType
    defaultCustomerId?: number
    showDimensions: boolean
}

export const getEmptyCompileContextData = (
    evaluator: NodeEvaluator,
    root: TemplateGraph,
    sceneProperties: SceneProperties | undefined,
    render: Render | undefined,
    templateDepth: number,
    topLevelObjectId: string | null,
    exposeClaimedSubTemplateInputs: boolean,
    overrideMaterial?: (parent: MeshDecal | {materialAssignments: MaterialAssignments; key: string}) => MaterialLike | null | undefined,
): OnCompileContext => {
    return {
        evaluator,
        root,
        sceneProperties,
        render,
        templateDepth,
        topLevelObjectId,
        overrideMaterial,
        currentTemplate: {
            preDisplayList: [],
            displayList: [],
            descriptorList: [],
            solverData: {
                solverObjects: [],
                solverVariables: [],
                solverRelations: [],
            },
            transformAccessorList: [],
            allBounds: [],
            allUntransformedBounds: [],
            templateOutputs: {},
            nodeToObjectMap: new Map(),
            objectToNodeMap: new Map(),
        },
        subTemplates: {
            readyList: [],
            preDisplayLists: [],
            displayLists: [],
            exposeClaimedSubTemplateInputs,
            descriptorLists: [],
            solverDatas: [],
            lookupByExternalIdPathMap: new Map(),
        },
    }
}

export enum ImageColorSpace {
    Gamma_2_0 = "Gamma_2_0",
    Gamma_2_2 = "Gamma_2_2",
    Linear = "Linear",
    Srgb = "Srgb",
    Unknown = "Unknown",
}
export const imageColorSpace = z.nativeEnum(ImageColorSpace)
