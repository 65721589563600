import {isFirefox} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {HalContext, HalContextDescriptor} from "@common/models/hal/hal-context"
import {WebGl2ImageAtlas} from "@common/models/webgl2/webgl2-image-atlas"
import {HalPainterImageBlit} from "@common/models/hal/common/hal-painter-image-blit"
import {HalImage, HalImagePhysical} from "@common/models/hal/hal-image"
import {Box2Like, Vector2Like} from "@cm/math"
import {HalPaintable} from "@common/models/hal/hal-paintable"
import {HalImageDescriptor} from "@common/models/hal/hal-image/types"
import {WebGl2ImagePhysical} from "@common/models/webgl2/webgl2-image-physical"
import {TextureEditorSettings} from "@app/textures/texture-editor/texture-editor-settings"

export class WebGl2Context implements HalContext {
    readonly descriptor: HalContextDescriptor = {
        atlasPageSize: WebGl2ImageAtlas.pageSize,
    }

    readonly gl: WebGL2RenderingContext
    readonly maxDrawBuffers: number
    readonly maxTextureUnits: number
    readonly maxTextureSize: number
    readonly maxTextureLayers: number
    readonly EXT_color_buffer_float: boolean
    readonly EXT_color_buffer_half_float: boolean
    readonly EXT_float_blend: boolean

    static createFromCanvas(canvas: HTMLCanvasElement): WebGl2Context {
        console.log("Initializing WebGL2 canvas")
        if (!canvas) {
            throw Error("WebGL canvas is null.")
        }
        const gl = canvas.getContext("webgl2", {
            antialias: false,
            preserveDrawingBuffer: false,
            alpha: true,
            premultipliedAlpha: true,
        })
        if (!gl) {
            throw Error("WebGL2 context could not be obtained from canvas.")
        }
        return WebGl2Context.createFromWebGL2RenderingContext(gl)
    }

    static createFromWebGL2RenderingContext(gl: WebGL2RenderingContext): WebGl2Context {
        return new WebGl2Context(gl)
    }

    private constructor(gl: WebGL2RenderingContext) {
        this.gl = gl
        gl.disable(gl.DEPTH_TEST)
        this.maxDrawBuffers = gl.getParameter(gl.MAX_DRAW_BUFFERS)
        this.maxTextureUnits = gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS)
        this.maxTextureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE)
        this.maxTextureLayers = gl.getParameter(gl.MAX_ARRAY_TEXTURE_LAYERS)
        if (isFirefox) {
            this.maxTextureSize = Math.min(this.maxTextureSize, 8192) // interestingly firefox on win returns 16384, but only seems to handle 8192 properly, so we artificially restrict it here (checked 31.3.23)
        }
        this.EXT_color_buffer_float = gl.getExtension("EXT_color_buffer_float") != null
        this.EXT_color_buffer_half_float = gl.getExtension("EXT_color_buffer_half_float") != null
        this.EXT_float_blend = gl.getExtension("EXT_float_blend") != null
        console.log("WebGL2 context initialized.")
        console.log("  MaxDrawBuffers: ", this.maxDrawBuffers)
        console.log("  maxTextureUnits: ", this.maxTextureUnits)
        console.log("  MaxTextureSize: ", this.maxTextureSize)
        console.log("  MaxTextureLayers: ", this.maxTextureLayers)
        console.log("  EXT_color_buffer_float: ", this.EXT_color_buffer_float ? "yes" : "no")
        console.log("  EXT_color_buffer_half_float: ", this.EXT_color_buffer_half_float ? "yes" : "no")
        console.log("  EXT_float_blend: ", this.EXT_float_blend ? "yes" : "no")

        if (TextureEditorSettings.EnableExperimental) {
            this._atlas = new WebGl2ImageAtlas(this, {
                numPages: 1 << 14, // 16384 pages, 256x256 pixels each, float32, 4GB total
            })
        }

        this.blitter = new HalPainterImageBlit(this)
    }

    // HalEntity
    get context(): HalContext {
        return this
    }

    // HalEntity
    dispose() {
        // unbind everything
        const gl = this.gl
        for (let unit = 0; unit < this.maxTextureUnits; ++unit) {
            gl.activeTexture(gl.TEXTURE0 + unit)
            gl.bindTexture(gl.TEXTURE_2D, null)
            gl.bindTexture(gl.TEXTURE_CUBE_MAP, null)
        }
        gl.bindBuffer(gl.ARRAY_BUFFER, null)
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null)
        gl.bindRenderbuffer(gl.RENDERBUFFER, null)
        gl.bindFramebuffer(gl.FRAMEBUFFER, null)
        // dispose
        this.canvasRenderingContext2DData?.canvas.remove()
        this.bufferImageByFormatHash.forEach((image) => image.dispose())
        this._atlas?.dispose()
        this.blitter.dispose()
        console.log("WebGL2 canvas disposed")
    }

    // HalContext
    async flush(waitForCompletion: boolean): Promise<void> {
        const gl = this.gl
        let sync: WebGLSync | null = null
        if (waitForCompletion) {
            sync = gl.fenceSync(this.gl.SYNC_GPU_COMMANDS_COMPLETE, 0)
            if (!sync) {
                throw new Error("Failed to create sync object")
            }
        }
        gl.flush()
        if (sync) {
            const clientWaitAsync = (gl: WebGL2RenderingContext, sync: WebGLSync, flags: number, interval_ms: number) => {
                return new Promise<void>((resolve, reject) => {
                    function test() {
                        const res = gl.clientWaitSync(sync, flags, 0)
                        if (res == gl.WAIT_FAILED) {
                            reject()
                            return
                        }
                        if (res == gl.TIMEOUT_EXPIRED) {
                            setTimeout(test, interval_ms)
                            return
                        }
                        resolve()
                    }

                    test()
                })
            }
            await clientWaitAsync(gl, sync, 0, 10)
            gl.deleteSync(sync)
        }
    }

    // HalContext
    blit(args: {sourceImage: HalImage; sourceRegion?: Box2Like; targetImage: HalPaintable; targetOffset?: Vector2Like}) {
        this.blitter.paint({
            target: args.targetImage,
            sourceImages: args.sourceImage,
            options: {
                sourceRegion: args.sourceRegion,
                targetOffset: args.targetOffset,
            },
        })
    }

    get atlas(): WebGl2ImageAtlas {
        if (!this._atlas) {
            throw new Error("Atlas not initialized")
        }
        return this._atlas
    }

    // HalContext
    requestSynchronousBufferImage(descriptor: HalImageDescriptor): HalImagePhysical {
        const formatHash = this.getFormatHash(descriptor)
        let imageWidth = descriptor.width
        let imageHeight = descriptor.height
        let image = this.bufferImageByFormatHash.get(formatHash)
        if (image && (image.width < descriptor.width || image.height < descriptor.height)) {
            // image too small; make larger
            imageWidth = Math.max(image.width, descriptor.width)
            imageHeight = Math.max(image.height, descriptor.height)
            image.dispose()
            image = undefined
        }
        if (!image) {
            image = new WebGl2ImagePhysical(this, {
                width: imageWidth,
                height: imageHeight,
                channelLayout: descriptor.channelLayout,
                dataType: descriptor.dataType,
                options: {
                    useMipMaps: false,
                },
            })
            this.bufferImageByFormatHash.set(formatHash, image)
        }
        return image
    }

    requestSynchronousRenderingContext2D(width: number, height: number): CanvasRenderingContext2D {
        if (!this.canvasRenderingContext2DData) {
            const canvas = document.createElement("canvas")
            canvas.width = width
            canvas.height = height
            const ctx = canvas.getContext("2d", {willReadFrequently: true})
            if (!ctx) {
                throw new Error("Failed to get 2D rendering context")
            }
            this.canvasRenderingContext2DData = {canvas, ctx}
        } else {
            if (this.canvasRenderingContext2DData.canvas.width < width || this.canvasRenderingContext2DData.canvas.height < height) {
                this.canvasRenderingContext2DData.canvas.width = Math.max(this.canvasRenderingContext2DData.canvas.width, width)
                this.canvasRenderingContext2DData.canvas.height = Math.max(this.canvasRenderingContext2DData.canvas.height, height)
                const ctx = this.canvasRenderingContext2DData.canvas.getContext("2d")
                if (!ctx) {
                    throw new Error("Failed to get 2D rendering context")
                }
                this.canvasRenderingContext2DData.ctx = ctx
            }
        }
        return this.canvasRenderingContext2DData.ctx
    }

    private getFormatHash(descriptor: HalImageDescriptor) {
        return `${descriptor.channelLayout}_${descriptor.dataType}`
    }

    private _atlas?: WebGl2ImageAtlas

    private blitter: HalPainterImageBlit
    private bufferImageByFormatHash: Map<string, WebGl2ImagePhysical> = new Map()

    private canvasRenderingContext2DData?: CanvasRenderingContext2DData
}

type CanvasRenderingContext2DData = {
    canvas: HTMLCanvasElement
    ctx: CanvasRenderingContext2D
}
