import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {CanvasBaseToolboxItem, ToolMouseEvent} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"
import {Color, ColorLike, Vector2Like} from "@cm/math"
import {ArrowItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/basic/arrow-item"
import {EventEmitter} from "@angular/core"

export class EditVectorItem extends CanvasBaseToolboxItemBase {
    readonly vectorChanged = new EventEmitter<Vector | undefined>()

    constructor(parentItem: CanvasBaseToolboxItem, vector?: Vector, width?: number, color?: ColorLike) {
        super(parentItem)
        this.cursor = "crosshair"
        this._width = width ?? 3
        this._color = color ? Color.fromColorLike(color) : new Color(1, 0, 0, 1)
        this.vector = vector
    }

    set vector(vector: Vector | undefined) {
        if (
            (!this._vector && !vector) ||
            (this._vector &&
                vector &&
                this._vector.from.x === vector.from.x &&
                this._vector.from.y === vector.from.y &&
                this._vector.to.x === vector.to.x &&
                this._vector.to.y === vector.to.y)
        ) {
            return // nothing changed
        }
        this._vector = vector
        this.createArrowItem()
    }

    get vector(): Vector | undefined {
        return this._vector
    }

    override hitTest(): CanvasBaseToolboxItem | null {
        return this.selected ? this : null
    }

    override onMouseDown(event: ToolMouseEvent): boolean {
        super.onMouseDown(event)
        if (this.canCreateVector) {
            this._isDragging = true
            return false
        }
        return true
    }

    override onMouseUp(event: ToolMouseEvent): boolean {
        super.onMouseUp(event)
        if (this._isDragging) {
            this._isDragging = false
            this.vectorChanged.emit(this._vector)
            return false
        }
        return true
    }

    override onMouseDrag(event: ToolMouseEvent): boolean {
        super.onMouseDrag(event)
        if (this._isDragging) {
            this.vector = {
                from: event.downPoint,
                to: event.point,
            }
            return false
        }
        return true
    }

    private get canCreateVector() {
        return true
    }

    private createArrowItem() {
        this._arrowItem?.remove()
        if (this._vector) {
            this._arrowItem = new ArrowItem(this, this._vector.from, this._vector.to, this._width, this._color)
        }
    }

    private _vector?: Vector
    private _width: number
    private _color: Color
    private _arrowItem?: ArrowItem
    private _isDragging = false
}

export type Vector = {
    from: Vector2Like
    to: Vector2Like
}
