import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UserListItemFragment = { __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type GetUsersQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.UserFilterInput>;
}>;


export type GetUsersQuery = { __typename: 'Query', usersCount: number, users: Array<{ __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export type CreateUserMutationVariables = Types.Exact<{
  input: Types.MutationCreateUserInput;
}>;


export type CreateUserMutation = { __typename: 'Mutation', createUser: { __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type InviteUserMutationVariables = Types.Exact<{
  input: Types.MutationInviteUserInput;
}>;


export type InviteUserMutation = { __typename: 'Mutation', inviteUser: { __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type CreateUserAssignmentMutationVariables = Types.Exact<{
  input: Types.MutationCreateUserAssignmentInput;
}>;


export type CreateUserAssignmentMutation = { __typename: 'Mutation', createUserAssignment: { __typename: 'UserAssignment', id: string, state?: Types.UserAssignmentState | null, user: { __typename: 'User', firstName?: string | null, lastName?: string | null } } };

export type UpdateUserMutationVariables = Types.Exact<{
  input: Types.MutationUpdateUserInput;
}>;


export type UpdateUserMutation = { __typename: 'Mutation', updateUser: { __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type DeleteUserAssignmentsMutationVariables = Types.Exact<{
  input: Types.MutationDeleteUserAssignmentsInput;
}>;


export type DeleteUserAssignmentsMutation = { __typename: 'Mutation', deleteUserAssignments: number };

export const UserListItemFragmentDoc = gql`
    fragment UserListItem on User {
  id
  legacyId
  firstName
  lastName
  name
  galleryImage {
    id
  }
}
    `;
export const GetUsersDocument = gql`
    query getUsers($skip: Int, $take: Int, $filter: UserFilterInput) {
  users(skip: $skip, take: $take, filter: $filter) {
    ...UserListItem
  }
  usersCount(filter: $filter)
}
    ${UserListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsersGQL extends Apollo.Query<GetUsersQuery, GetUsersQueryVariables> {
    override document = GetUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation createUser($input: MutationCreateUserInput!) {
  createUser(input: $input) {
    ...UserListItem
  }
}
    ${UserListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    override document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InviteUserDocument = gql`
    mutation inviteUser($input: MutationInviteUserInput!) {
  inviteUser(input: $input) {
    ...UserListItem
  }
}
    ${UserListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class InviteUserGQL extends Apollo.Mutation<InviteUserMutation, InviteUserMutationVariables> {
    override document = InviteUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserAssignmentDocument = gql`
    mutation createUserAssignment($input: MutationCreateUserAssignmentInput!) {
  createUserAssignment(input: $input) {
    id
    state
    user {
      firstName
      lastName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserAssignmentGQL extends Apollo.Mutation<CreateUserAssignmentMutation, CreateUserAssignmentMutationVariables> {
    override document = CreateUserAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation updateUser($input: MutationUpdateUserInput!) {
  updateUser(input: $input) {
    ...UserListItem
  }
}
    ${UserListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    override document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserAssignmentsDocument = gql`
    mutation deleteUserAssignments($input: MutationDeleteUserAssignmentsInput!) {
  deleteUserAssignments(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserAssignmentsGQL extends Apollo.Mutation<DeleteUserAssignmentsMutation, DeleteUserAssignmentsMutationVariables> {
    override document = DeleteUserAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }