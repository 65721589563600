import * as Types from '../../../generated/types';

import { gql } from 'apollo-angular';
import { PriceMappingPriceFragmentDoc, PriceMappingPriceGraphFragmentDoc } from '../components/price-mapping/price-mapping.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PricingHelperPriceGraphFragment = { __typename: 'PriceGraph', id: string, state: Types.PriceGraphState, templateId: string, revisionNumber: number, graph: any };

export type PricingHelperPriceFragment = { __typename: 'Price', currency: Types.Currency, price: number, idFromCatalog: { __typename: 'IdFromCatalog', originalId?: string | null } };

export type PricingHelperGetPricesQueryVariables = Types.Exact<{
  filter: Types.PriceFilterInput;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type PricingHelperGetPricesQuery = { __typename: 'Query', prices: Array<{ __typename: 'Price', currency: Types.Currency, price: number, idFromCatalog: { __typename: 'IdFromCatalog', originalId?: string | null } } | null> };

export type PricingHelperGetPriceGraphsQueryVariables = Types.Exact<{
  filter: Types.PriceGraphFilterInput;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type PricingHelperGetPriceGraphsQuery = { __typename: 'Query', priceGraphs: Array<{ __typename: 'PriceGraph', id: string, state: Types.PriceGraphState, templateId: string, revisionNumber: number, graph: any } | null> };

export type PricingHelperDeletePricesMutationVariables = Types.Exact<{
  originalIds: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
  organizationId: Types.Scalars['ID']['input'];
}>;


export type PricingHelperDeletePricesMutation = { __typename: 'Mutation', deletePrices?: any | null };

export type PricingHelperDeleteIdFromCustomerToPriceGraphMutationVariables = Types.Exact<{
  priceGraphId: Types.Scalars['ID']['input'];
}>;


export type PricingHelperDeleteIdFromCustomerToPriceGraphMutation = { __typename: 'Mutation', deleteIdFromCustomerToPriceGraph?: any | null };

export type PricingHelperCreateOrUpdatePricesMutationVariables = Types.Exact<{
  prices: Array<Types.PriceInput> | Types.PriceInput;
  organizationId: Types.Scalars['ID']['input'];
}>;


export type PricingHelperCreateOrUpdatePricesMutation = { __typename: 'Mutation', createOrUpdatePrices?: any | null };

export const PricingHelperPriceGraphFragmentDoc = gql`
    fragment PricingHelperPriceGraph on PriceGraph {
  id
  state
  templateId
  revisionNumber
  graph
}
    `;
export const PricingHelperPriceFragmentDoc = gql`
    fragment PricingHelperPrice on Price {
  idFromCatalog {
    originalId
  }
  currency
  price
}
    `;
export const PricingHelperGetPricesDocument = gql`
    query pricingHelperGetPrices($filter: PriceFilterInput!, $take: Int, $skip: Int) {
  prices(filter: $filter, take: $take, skip: $skip) {
    ...PriceMappingPrice
  }
}
    ${PriceMappingPriceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PricingHelperGetPricesGQL extends Apollo.Query<PricingHelperGetPricesQuery, PricingHelperGetPricesQueryVariables> {
    override document = PricingHelperGetPricesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PricingHelperGetPriceGraphsDocument = gql`
    query pricingHelperGetPriceGraphs($filter: PriceGraphFilterInput!, $take: Int, $skip: Int) {
  priceGraphs(filter: $filter, take: $take, skip: $skip) {
    ...PriceMappingPriceGraph
  }
}
    ${PriceMappingPriceGraphFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PricingHelperGetPriceGraphsGQL extends Apollo.Query<PricingHelperGetPriceGraphsQuery, PricingHelperGetPriceGraphsQueryVariables> {
    override document = PricingHelperGetPriceGraphsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PricingHelperDeletePricesDocument = gql`
    mutation pricingHelperDeletePrices($originalIds: [String!]!, $organizationId: ID!) {
  deletePrices(originalIds: $originalIds, organizationId: $organizationId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PricingHelperDeletePricesGQL extends Apollo.Mutation<PricingHelperDeletePricesMutation, PricingHelperDeletePricesMutationVariables> {
    override document = PricingHelperDeletePricesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PricingHelperDeleteIdFromCustomerToPriceGraphDocument = gql`
    mutation pricingHelperDeleteIdFromCustomerToPriceGraph($priceGraphId: ID!) {
  deleteIdFromCustomerToPriceGraph(priceGraphId: $priceGraphId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PricingHelperDeleteIdFromCustomerToPriceGraphGQL extends Apollo.Mutation<PricingHelperDeleteIdFromCustomerToPriceGraphMutation, PricingHelperDeleteIdFromCustomerToPriceGraphMutationVariables> {
    override document = PricingHelperDeleteIdFromCustomerToPriceGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PricingHelperCreateOrUpdatePricesDocument = gql`
    mutation pricingHelperCreateOrUpdatePrices($prices: [PriceInput!]!, $organizationId: ID!) {
  createOrUpdatePrices(prices: $prices, organizationId: $organizationId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PricingHelperCreateOrUpdatePricesGQL extends Apollo.Mutation<PricingHelperCreateOrUpdatePricesMutation, PricingHelperCreateOrUpdatePricesMutationVariables> {
    override document = PricingHelperCreateOrUpdatePricesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }