import {Component, input} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {JobState} from "@generated"
import {jobStateColor} from "@common/helpers/jobs/color"
import {jobStateIconClass} from "@common/helpers/jobs/icons"
import {TippyDirective} from "@ngneat/helipopper"
import {JobStateTooltipComponent} from "@platform/components/jobs/job-state-tooltip/job-state-tooltip.component"

@Component({
    selector: "cm-job-thumbnail",
    imports: [MatTooltipModule, TippyDirective],
    templateUrl: "./job-thumbnail.component.html",
    styleUrl: "./job-thumbnail.component.scss",
})
export class JobThumbnailComponent {
    readonly $job = input<{
        state: JobState
    } | null>(null, {alias: "job"})
    protected readonly jobStateIconClass = jobStateIconClass
    protected readonly JobStateTooltipComponent = JobStateTooltipComponent
    protected readonly jobStateColor = jobStateColor
}
