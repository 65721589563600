import {assertNever} from "@cm/utils"
import {Vector2, Vector2Like} from "@cm/math"
import {GridInterpolator} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/grid-interpolator"
import {BoundaryDirection, BoundaryItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-item"
import {merge} from "rxjs"
import {CurveBoundaryInterpolator} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/curve-boundary-interpolator"
import {
    BoundaryControlPointBagItem,
    ComputeSnapPositionFn,
} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-control-point-bag-item"
import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {OperatorTiling} from "@app/textures/texture-editor/texture-edit-nodes"
import {EventEmitter} from "@angular/core"
import {GridPoint} from "@app/textures/texture-editor/operator-stack/operators/tiling/helpers/create-grid-mapping-geometry"
import {ChangeEvent} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-curve-item"
import {HelperLinesBagItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/helper-lines/helper-lines-bag-item"

export class SpatialMappingItem extends CanvasBaseToolboxItemBase {
    readonly mappingChanged = new EventEmitter<ChangeEvent>()
    readonly viewModeChanged = new EventEmitter<ViewMode>()
    readonly boundaryFollowsHelperLinesEnabledChanged = new EventEmitter<boolean>()

    constructor(
        parentItem: CanvasBaseToolboxItemBase,
        readonly helperLinesBagItem: HelperLinesBagItem,
        node: OperatorTiling,
        computeSnapPosition: ComputeSnapPositionFn,
    ) {
        super(parentItem)

        // create corner control points
        this._pointVizBagItem = new BoundaryControlPointBagItem(this, computeSnapPosition)
        const pos00 = Vector2.fromVector2Like(node.cornerControlPoints.topLeft.positionPx)
        const pos10 = Vector2.fromVector2Like(node.cornerControlPoints.topRight.positionPx)
        const pos01 = Vector2.fromVector2Like(node.cornerControlPoints.bottomLeft.positionPx)
        const pos11 = Vector2.fromVector2Like(node.cornerControlPoints.bottomRight.positionPx)
        const cp00 = this._pointVizBagItem.createControlPoint({type: "user", sourcePosition: pos00, resultUV: {x: 0, y: 0}, deletable: false})
        const cp10 = this._pointVizBagItem.createControlPoint({type: "user", sourcePosition: pos10, resultUV: {x: 1, y: 0}, deletable: false})
        const cp01 = this._pointVizBagItem.createControlPoint({type: "user", sourcePosition: pos01, resultUV: {x: 0, y: 1}, deletable: false})
        const cp11 = this._pointVizBagItem.createControlPoint({type: "user", sourcePosition: pos11, resultUV: {x: 1, y: 1}, deletable: false})
        this._pointVizBagItem.identifyControlPoints([cp00, cp01, cp10, cp11])

        // Horizontal boundary
        const boundaryH = new BoundaryItem(this, BoundaryDirection.Horizontal, false)
        const horizontalBoundaryCurveTop = boundaryH.curveMin
        horizontalBoundaryCurveTop.createControlPoint(cp00, 0)
        horizontalBoundaryCurveTop.createControlPoint(cp10, 1)
        const horizontalBoundaryCurveBottom = boundaryH.curveMax
        horizontalBoundaryCurveBottom.createControlPoint(cp01, 0)
        horizontalBoundaryCurveBottom.createControlPoint(cp11, 1)
        node.boundaries.horizontal.controlPoints.forEach((controlPoint) => {
            boundaryH.insertControlPoints(
                controlPoint.type,
                controlPoint.loBound.normalizedCurvePosition,
                controlPoint.loBound.positionPx,
                controlPoint.hiBound.positionPx,
            )
        })

        // Vertical boundary
        const boundaryV = new BoundaryItem(this, BoundaryDirection.Vertical, true)
        const verticalBoundaryCurveLeft = boundaryV.curveMin
        verticalBoundaryCurveLeft.createControlPoint(cp00, 0)
        verticalBoundaryCurveLeft.createControlPoint(cp01, 1)
        const verticalBoundaryCurveRight = boundaryV.curveMax
        verticalBoundaryCurveRight.createControlPoint(cp10, 0)
        verticalBoundaryCurveRight.createControlPoint(cp11, 1)
        node.boundaries.vertical.controlPoints.forEach((controlPoint) => {
            boundaryV.insertControlPoints(
                controlPoint.type,
                controlPoint.loBound.normalizedCurvePosition,
                controlPoint.loBound.positionPx,
                controlPoint.hiBound.positionPx,
            )
        })

        this._boundaries = [boundaryH, boundaryV]
        merge(boundaryH.boundaryChanged, boundaryV.boundaryChanged).subscribe((event) => this.onBoundaryChanged(event))

        this._pointVizBagItem.bringToFront()
    }

    get viewMode() {
        return this._viewMode
    }

    set viewMode(value: ViewMode) {
        if (this._viewMode === value) {
            return
        }
        this._viewMode = value
        this.invalidate()
        this.viewModeChanged.emit(value)
    }

    get boundaryFollowsHelperLinesEnabled() {
        return this._boundaryFollowsHelperLinesEnabled
    }

    set boundaryFollowsHelperLinesEnabled(value: boolean) {
        if (this._boundaryFollowsHelperLinesEnabled === value) {
            return
        }
        this._boundaryFollowsHelperLinesEnabled = value
        this.boundaryFollowsHelperLinesEnabledChanged.emit(value)
    }

    get pointVizBagItem() {
        return this._pointVizBagItem
    }

    getBoundary(direction: BoundaryDirection) {
        return this._boundaries[direction]
    }

    get boundaryH() {
        return this.getBoundary(BoundaryDirection.Horizontal)
    }

    get boundaryV() {
        return this.getBoundary(BoundaryDirection.Vertical)
    }

    get mappedSize() {
        this.update()
        return this._mappedSize
    }

    get interpolator() {
        this.update()
        if (!this._gridInterpolator) {
            throw new Error("Grid interpolator not initialized")
        }
        return this._gridInterpolator
    }

    // attention: this method is expensive when in result view mode !
    // mapSourceSpacePositionToScreenSpace(position: Vector2Like, viewMode?: ViewMode) {
    //     this.update()
    //     viewMode ??= this.viewMode
    //     switch (viewMode) {
    //         case ViewMode.Source:
    //             return Vector2.fromVector2Like(position)
    //         case ViewMode.Result: {
    //             if (!this._gridInterpolator) {
    //                 throw new Error("Grid interpolator not found")
    //             }
    //             const mappedSize = this.mappedSize
    //             const uv = this._gridInterpolator.solveForUV(Vector2.fromVector2Like(position))
    //             if (!uv) {
    //                 throw new Error("UV not found")
    //             }
    //             return uv.mulInPlace(mappedSize)
    //         }
    //         default:
    //             assertNever(viewMode)
    //     }
    // }

    mapUVToScreenSpace(uv: Vector2Like, viewMode?: ViewMode) {
        viewMode ??= this.viewMode
        switch (viewMode) {
            case ViewMode.Source:
                return this.interpolator.interpolate(uv)
            case ViewMode.Result: {
                return this.mappedSize.mul(uv)
            }
            default:
                throw new Error("Invalid view mode")
        }
    }

    mapUVToSourceSpace(uv: Vector2Like) {
        return this.interpolator.interpolate(uv)
    }

    mapScreenSpacePositionToSourceSpace(position: Vector2Like, viewMode?: ViewMode): Vector2 {
        viewMode ??= this.viewMode
        switch (viewMode) {
            case ViewMode.Source:
                return Vector2.fromVector2Like(position)
            case ViewMode.Result:
                return this.mapUVToSourceSpace(Vector2.fromVector2Like(position).divInPlace(this.mappedSize))
            default:
                assertNever(viewMode)
        }
    }

    mapScreenSpaceOffsetToSourceSpace(position: Vector2Like, offset: Vector2Like, viewMode?: ViewMode) {
        this.update()
        const mappedPosition = this.mapScreenSpacePositionToSourceSpace(position, viewMode)
        const mappedOffsetPosition = this.mapScreenSpacePositionToSourceSpace(Vector2.fromVector2Like(position).addInPlace(offset), viewMode)
        return mappedOffsetPosition.subInPlace(mappedPosition)
    }

    getNumGridPointSubdivisionsForDirection(direction: BoundaryDirection) {
        const maxPixelsPerGridSegment = 128 // minimum number of pixels per grid segment
        const minGridSegmentsPerControlSegment = 1 // minimum number of grid segments per control segment
        const boundary = this.getBoundary(direction)
        return (
            1 +
            Math.max(
                Math.ceil(boundary.mappedLength / maxPixelsPerGridSegment),
                (boundary.curveMin.curveControlPoints.length - 1) * minGridSegmentsPerControlSegment,
            )
        )
    }

    getNumGridPointSubdivisions() {
        return {
            x: this.getNumGridPointSubdivisionsForDirection(BoundaryDirection.Horizontal),
            y: this.getNumGridPointSubdivisionsForDirection(BoundaryDirection.Vertical),
        }
    }

    computeGridTaps(desc: SubDivisionDesc | number[]) {
        if (Array.isArray(desc)) {
            return desc
        } else {
            if (desc.numSteps < 1) {
                throw new Error("Invalid number of steps")
            }
            const taps = []
            for (let i = 0; i < desc.numSteps; i++) {
                const t = desc.numSteps === 1 ? desc.tMin : desc.tMin + ((desc.tMax - desc.tMin) * i) / (desc.numSteps - 1)
                taps.push(t)
            }
            return taps
        }
    }

    // TODO this is not fully working as expected
    computeGridPoints_adaptive(tRangeU: [number, number], tRangeV: [number, number], eps = 0.25): GridPoint[][] {
        throw new Error("Not implemented")

        const curveTop = this.boundaryH.curveMin
        const curveLeft = this.boundaryV.curveMin
        // get t values from control points
        const tu = curveTop.curveControlPoints.filter((cp) => cp.t >= tRangeU[0] && cp.t <= tRangeU[1]).map((cp) => cp.t)
        const tv = curveLeft.curveControlPoints.filter((cp) => cp.t >= tRangeV[0] && cp.t <= tRangeV[1]).map((cp) => cp.t)
        // add boundary t values if not already present
        if (tu.length === 0) {
            tu.push(tRangeU[0])
            tu.push(tRangeU[1])
        } else {
            if (tu[0] > tRangeU[0]) {
                tu.unshift(tRangeU[0])
            }
            if (tu[tu.length - 1] < tRangeU[1]) {
                tu.push(tRangeU[1])
            }
        }
        if (tv.length === 0) {
            tv.push(tRangeV[0])
            tv.push(tRangeV[1])
        } else {
            if (tv[0] > tRangeV[0]) {
                tv.unshift(tRangeV[0])
            }
            if (tv[tv.length - 1] < tRangeV[1]) {
                tv.push(tRangeV[1])
            }
        }
        // compute initial grid points
        const gridPoints: GridPoint[][] = []
        for (const ty of tv) {
            const linePoints: GridPoint[] = []
            for (const tx of tu) {
                const targetPixel = new Vector2(tx, ty).mulInPlace(this.mappedSize)
                const sourcePixel = this.mapScreenSpacePositionToSourceSpace(targetPixel, ViewMode.Result)
                linePoints.push({
                    sourcePixel,
                    targetPixel,
                })
            }
            gridPoints.push(linePoints)
        }
        // sub-divide in u direction
        const subdivideU = (gridPoints: GridPoint[][], iu: number, tu0: number, tu1: number) => {
            const tx = (tu0 + tu1) / 2
            const subDivPoints: GridPoint[] = []
            let needsSubdivision = false
            for (let iv = 0; iv < tv.length; iv++) {
                const ty = tv[iv]
                const targetPixel = new Vector2(tx, ty).mulInPlace(this.mappedSize)
                const sourcePixel = this.mapScreenSpacePositionToSourceSpace(targetPixel, ViewMode.Result)
                const gp0 = gridPoints[iv][iu - 1]
                const gp1 = gridPoints[iv][iu]
                const interpolated = Vector2.add(gp0.sourcePixel, gp1.sourcePixel).mul(0.5)
                const distanceSquared = Vector2.distanceSquared(interpolated, sourcePixel)
                if (distanceSquared > eps * eps) {
                    needsSubdivision = true
                }
                subDivPoints.push({
                    sourcePixel,
                    targetPixel,
                })
            }
            let insertedPoints = 0
            if (needsSubdivision) {
                for (let iv = 0; iv < tv.length; iv++) {
                    gridPoints[iv].splice(iu, 0, subDivPoints[iv])
                }
                insertedPoints++
                // recursive subdivision
                insertedPoints += subdivideU(gridPoints, iu, tu0, tx)
                insertedPoints += subdivideU(gridPoints, iu + insertedPoints, tx, tu1)
            }
            return insertedPoints
        }
        let insertedPointsU = 0
        for (let iu = 1; iu < tu.length; iu++) {
            const tu0 = tu[iu - 1]
            const tu1 = tu[iu]
            insertedPointsU += subdivideU(gridPoints, iu + insertedPointsU, tu0, tu1)
        }
        console.log(`Grid resolution is ${gridPoints[0].length}x${gridPoints.length}`)
        return gridPoints
    }

    computeGridPoints(u: SubDivisionDesc | number[], v: SubDivisionDesc | number[]): GridPoint[][] {
        u = this.computeGridTaps(u)
        v = this.computeGridTaps(v)
        const resultSize = this.mappedSize
        const gridPoints = []
        for (const ty of v) {
            const linePoints: GridPoint[] = []
            for (const tx of u) {
                const targetPixel = new Vector2(tx, ty).mulInPlace(resultSize)
                const sourcePixel = this.mapScreenSpacePositionToSourceSpace(targetPixel, ViewMode.Result)
                linePoints.push({
                    sourcePixel,
                    targetPixel,
                })
            }
            gridPoints.push(linePoints)
        }
        return gridPoints
    }

    private onBoundaryChanged(event: ChangeEvent) {
        this.invalidate()
        this.mappingChanged.emit(event)
    }

    private invalidate() {
        this._needsUpdate = true
    }

    private update() {
        if (!this._needsUpdate) {
            return
        }
        this._needsUpdate = false

        const boundaryH = this.boundaryH
        const boundaryV = this.boundaryV
        const mappedWidth = boundaryH.mappedLength
        const mappedHeight = boundaryV.mappedLength
        this._mappedSize = new Vector2(mappedWidth, mappedHeight)

        const topCurveInterpolator = boundaryH.curveMin.interpolator
        const bottomCurveInterpolator = boundaryH.curveMax.interpolator
        const leftCurveInterpolator = boundaryV.curveMin.interpolator
        const rightCurveInterpolator = boundaryV.curveMax.interpolator
        if (!topCurveInterpolator || !bottomCurveInterpolator || !leftCurveInterpolator || !rightCurveInterpolator) {
            throw new Error("Curve interpolator not found")
        }
        this._gridInterpolator = new CurveBoundaryInterpolator(topCurveInterpolator, bottomCurveInterpolator, leftCurveInterpolator, rightCurveInterpolator)
    }

    private _needsUpdate = true
    private _viewMode = ViewMode.Source
    private _boundaryFollowsHelperLinesEnabled = true
    private _pointVizBagItem: BoundaryControlPointBagItem
    private _boundaries: [BoundaryItem, BoundaryItem]
    private _gridInterpolator?: GridInterpolator
    private _mappedSize = new Vector2(1, 1)
}

export enum ViewMode {
    Source = "source",
    Result = "result",
}

export type SubDivisionDesc = {
    tMin: number
    tMax: number
    numSteps: number
}
