import {Box2Like, Size2Like} from "@cm/math"
import {copyRegion} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-copy-region"
import {DataType, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {resize} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-resize"
import {convert} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-convert"

const SCOPE_NAME = "CreateImagePyramid"

export type ParameterType = {
    sourceImage: ImageRef
    sourceRegion?: Box2Like // default: {x: 0, y: 0, width: sourceImage.width, height: sourceImage.height}
    maxLevels?: number // defaults to full pyramid down to 1x1
    minResolution?: Size2Like // defaults to 1x1
    downSamplingFn?: CustomImageOpFn // default: simple 2x2 average
    resultDataType?: DataType // default: sourceImage.dataType
}

export type ReturnType = {
    descriptor: {
        width: number
        height: number
        levels: number
    }
    resultImages: ImageRef[]
}

export type CustomImageOpFn = (cmdQueue: ImageOpCommandQueue, sourceImage: ImageRef, targetLevel: number) => ImageRef

export const createImagePyramid = (
    cmdQueue: ImageOpCommandQueue,
    {sourceImage, sourceRegion, maxLevels, minResolution, downSamplingFn, resultDataType}: ParameterType,
): ReturnType => {
    cmdQueue.beginScope(SCOPE_NAME)

    if (sourceRegion) {
        sourceImage = copyRegion(cmdQueue, {sourceImage, sourceRegion, resultImageOrDataType: resultDataType})
    } else {
        sourceImage = convert(cmdQueue, {sourceImage, dataType: resultDataType})
    }

    if (maxLevels !== undefined) {
        if (maxLevels < 1) {
            throw new Error("maxLevels must be at least 1")
        }
        if (minResolution !== undefined) {
            throw new Error("minResolution is not allowed when maxLevels is set")
        }
        const maxLevelScaling = 2 ** (maxLevels - 1)
        minResolution = {
            width: Math.ceil(sourceImage.descriptor.width / maxLevelScaling),
            height: Math.ceil(sourceImage.descriptor.height / maxLevelScaling),
        }
    }
    if (!minResolution) {
        minResolution = {width: 1, height: 1}
    }
    downSamplingFn ??= (cmdQueue, sourceImage) =>
        resize(cmdQueue, {
            sourceImage,
            interpolation: "cubic",
            resultSize: {
                width: Math.ceil(sourceImage.descriptor.width / 2),
                height: Math.ceil(sourceImage.descriptor.height / 2),
            },
        })
    const width = sourceImage.descriptor.width
    const height = sourceImage.descriptor.height
    const resultImages = [sourceImage]
    while (sourceImage.descriptor.width > minResolution.width || sourceImage.descriptor.height > minResolution.height) {
        sourceImage = downSamplingFn(cmdQueue, sourceImage, resultImages.length)
        resultImages.push(sourceImage)
    }
    cmdQueue.endScope(SCOPE_NAME)
    return {
        descriptor: {
            width,
            height,
            levels: resultImages.length,
        },
        resultImages,
    }
}
