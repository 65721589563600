import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ResolveServicePictureRevisionFragment = { __typename: 'PictureRevision', id: string, legacyId: number, number: number, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }> };

export type ResolveServicePictureFragment = { __typename: 'Picture', id: string, legacyId: number, number: number, state: Types.PictureState, attachments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }>, latestRevision?: { __typename: 'PictureRevision', id: string, legacyId: number, number: number } | null, revisions: Array<{ __typename: 'PictureRevision', id: string, legacyId: number, number: number, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }> }>, organization: { __typename: 'Organization', id: string, legacyId: number }, template?: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number }> } | null };

export type ResolveServiceTemplateFragment = { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number }> };

export type ResolveServiceTemplateQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ResolveServiceTemplateQuery = { __typename: 'Query', template: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number }> } };

export type ResolveServicePictureQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ResolveServicePictureQuery = { __typename: 'Query', picture: { __typename: 'Picture', id: string, legacyId: number, number: number, state: Types.PictureState, attachments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }>, latestRevision?: { __typename: 'PictureRevision', id: string, legacyId: number, number: number } | null, revisions: Array<{ __typename: 'PictureRevision', id: string, legacyId: number, number: number, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }> }>, organization: { __typename: 'Organization', id: string, legacyId: number }, template?: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number }> } | null } };

export type ResolveServiceCreateTemplateMutationVariables = Types.Exact<{
  input: Types.MutationCreateTemplateInput;
}>;


export type ResolveServiceCreateTemplateMutation = { __typename: 'Mutation', createTemplate: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number }> } };

export type ResolveServiceUpdateTemplateMutationVariables = Types.Exact<{
  input: Types.MutationUpdateTemplateInput;
}>;


export type ResolveServiceUpdateTemplateMutation = { __typename: 'Mutation', updateTemplate: { __typename: 'Template', id: string } };

export type ResolveServicePictureFromLegacyIdQueryVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type ResolveServicePictureFromLegacyIdQuery = { __typename: 'Query', picture: { __typename: 'Picture', id: string } };

export const ResolveServicePictureRevisionFragmentDoc = gql`
    fragment ResolveServicePictureRevision on PictureRevision {
  id
  legacyId
  number
  dataObjectAssignments(filter: {assignmentType: [PictureData]}) {
    id
    dataObject {
      id
    }
  }
}
    `;
export const ResolveServiceTemplateFragmentDoc = gql`
    fragment ResolveServiceTemplate on Template {
  id
  legacyId
  revisions {
    id
    legacyId
  }
}
    `;
export const ResolveServicePictureFragmentDoc = gql`
    fragment ResolveServicePicture on Picture {
  id
  legacyId
  number
  state
  attachments: dataObjectAssignments(filter: {assignmentType: [Attachment]}) {
    id
    dataObject {
      id
    }
  }
  latestRevision {
    id
    legacyId
    number
  }
  revisions {
    ...ResolveServicePictureRevision
  }
  organization {
    id
    legacyId
  }
  template {
    ...ResolveServiceTemplate
  }
}
    `;
export const ResolveServiceTemplateDocument = gql`
    query resolveServiceTemplate($id: ID!) {
  template(id: $id) {
    ...ResolveServiceTemplate
  }
}
    ${ResolveServiceTemplateFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResolveServiceTemplateGQL extends Apollo.Query<ResolveServiceTemplateQuery, ResolveServiceTemplateQueryVariables> {
    override document = ResolveServiceTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResolveServicePictureDocument = gql`
    query resolveServicePicture($id: ID!) {
  picture(id: $id) {
    ...ResolveServicePicture
  }
}
    ${ResolveServicePictureFragmentDoc}
${ResolveServicePictureRevisionFragmentDoc}
${ResolveServiceTemplateFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResolveServicePictureGQL extends Apollo.Query<ResolveServicePictureQuery, ResolveServicePictureQueryVariables> {
    override document = ResolveServicePictureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResolveServiceCreateTemplateDocument = gql`
    mutation resolveServiceCreateTemplate($input: MutationCreateTemplateInput!) {
  createTemplate(input: $input) {
    ...ResolveServiceTemplate
  }
}
    ${ResolveServiceTemplateFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResolveServiceCreateTemplateGQL extends Apollo.Mutation<ResolveServiceCreateTemplateMutation, ResolveServiceCreateTemplateMutationVariables> {
    override document = ResolveServiceCreateTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResolveServiceUpdateTemplateDocument = gql`
    mutation resolveServiceUpdateTemplate($input: MutationUpdateTemplateInput!) {
  updateTemplate(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResolveServiceUpdateTemplateGQL extends Apollo.Mutation<ResolveServiceUpdateTemplateMutation, ResolveServiceUpdateTemplateMutationVariables> {
    override document = ResolveServiceUpdateTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResolveServicePictureFromLegacyIdDocument = gql`
    query resolveServicePictureFromLegacyId($legacyId: Int!) {
  picture(legacyId: $legacyId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResolveServicePictureFromLegacyIdGQL extends Apollo.Query<ResolveServicePictureFromLegacyIdQuery, ResolveServicePictureFromLegacyIdQueryVariables> {
    override document = ResolveServicePictureFromLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }