import {InletDescriptor, OutletDescriptor} from "#template-nodes/runtime-graph/descriptors"
import {IMaterialGraph} from "@cm/material-nodes/interfaces/material-data"
import {Inlet, NotReady, Outlet} from "#template-nodes/runtime-graph/slots"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {transformColorOverlay} from "@cm/material-nodes/material-node-graph-transformations"
import {ImageGenerator} from "@cm/material-nodes/nodes/apply-mesh-render-settings"

const TD = TypeDescriptors

type TransformColorOverlayDescriptorType = {
    image: InletDescriptor<ImageGenerator>
    useAlpha: InletDescriptor<boolean>
    outputMaterial: OutletDescriptor<IMaterialGraph>
    material: InletDescriptor<IMaterialGraph>
    size: InletDescriptor<[number, number]>
}

const transformColorOverlayDescriptor: TransformColorOverlayDescriptorType = {
    material: TD.inlet(TD.MaterialGraph),
    size: TD.inlet(TD.Tuple<[number, number]>()),
    image: TD.inlet(TD.ImageGenerator),
    useAlpha: TD.inlet(TD.Boolean),
    outputMaterial: TD.outlet(TD.MaterialGraph),
}

export class TransformColorOverlay implements NodeClassImpl<TransformColorOverlayDescriptorType, typeof TransformColorOverlay> {
    static descriptor = transformColorOverlayDescriptor
    static uniqueName = "TransformColorOverlay"
    material!: Inlet<IMaterialGraph>
    size!: Inlet<[number, number]>
    image!: Inlet<ImageGenerator>
    useAlpha!: Inlet<boolean>
    outputMaterial!: Outlet<IMaterialGraph>

    run() {
        const material = this.material
        const image = this.image
        const size = this.size
        const useAlpha = this.useAlpha
        if (material === NotReady || image === NotReady || size === NotReady || useAlpha === NotReady) {
            this.outputMaterial.emitIfChanged(NotReady)
            return
        }
        this.outputMaterial.emitIfChanged(image ? transformColorOverlay(material, image.imageNode, size, useAlpha) : material)
    }
}
