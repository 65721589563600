import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MaterialsPageUserFragment = { __typename: 'User', id: string, name: string };

export type MaterialsPageScannerUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MaterialsPageScannerUsersQuery = { __typename: 'Query', users: Array<{ __typename: 'User', id: string, name: string } | null> };

export type MaterialsPageVisibleUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MaterialsPageVisibleUsersQuery = { __typename: 'Query', users: Array<{ __typename: 'User', id: string, name: string } | null> };

export const MaterialsPageUserFragmentDoc = gql`
    fragment MaterialsPageUser on User {
  id
  name
}
    `;
export const MaterialsPageScannerUsersDocument = gql`
    query materialsPageScannerUsers {
  users(filter: {systemRole: Scanner}) {
    ...MaterialsPageUser
  }
}
    ${MaterialsPageUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialsPageScannerUsersGQL extends Apollo.Query<MaterialsPageScannerUsersQuery, MaterialsPageScannerUsersQueryVariables> {
    override document = MaterialsPageScannerUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialsPageVisibleUsersDocument = gql`
    query materialsPageVisibleUsers {
  users(filter: {visibleInFilters: true}, take: 9999) {
    ...MaterialsPageUser
  }
}
    ${MaterialsPageUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialsPageVisibleUsersGQL extends Apollo.Query<MaterialsPageVisibleUsersQuery, MaterialsPageVisibleUsersQueryVariables> {
    override document = MaterialsPageVisibleUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }