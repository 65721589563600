import {Component, input} from "@angular/core"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {OperatorTest, Parameter} from "@app/textures/texture-editor/operator-stack/operators/test/operator-test"
import {SliderComponent} from "@common/components/inputs/slider/slider.component"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatSelectModule} from "@angular/material/select"

@Component({
    selector: "cm-rotate-panel",
    templateUrl: "./test-panel.component.html",
    styleUrls: ["./test-panel.component.scss"],
    imports: [ButtonComponent, InspectorSectionComponent, SliderComponent, ToggleComponent, MatFormFieldModule, MatSelectModule],
})
export class TestPanelComponent {
    readonly $operator = input.required<OperatorTest>({alias: "operator"})
    readonly $disabled = input(false, {alias: "disabled"})

    get parameters() {
        return this.$operator().getParameters()
    }

    setParameterValue(index: number, value: Parameter["value"]) {
        this.$operator().setParameterValue(index, value)
    }

    setEdited() {
        this.$operator().markEdited()
    }
}
