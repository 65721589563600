import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ImageTextureNodeTextureFragment = { __typename: 'Texture', id: string, type: Types.TextureType, latestRevision?: { __typename: 'TextureRevision', legacyId: number, number?: number | null } | null, revisions: Array<{ __typename: 'TextureRevision', id: string, legacyId: number, number?: number | null }>, textureSet: { __typename: 'TextureSet', textureGroup: { __typename: 'TextureGroup', id: string } } };

export type ImageTextureNodeTextureRevisionFragment = { __typename: 'TextureRevision', id: string, height: number, number?: number | null, state: Types.TextureRevisionState, width: number, texture?: { __typename: 'Texture', id: string, type: Types.TextureType, latestRevision?: { __typename: 'TextureRevision', legacyId: number, number?: number | null } | null, revisions: Array<{ __typename: 'TextureRevision', id: string, legacyId: number, number?: number | null }>, textureSet: { __typename: 'TextureSet', textureGroup: { __typename: 'TextureGroup', id: string } } } | null, dataObject: { __typename: 'DataObject', thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null }, thumbnailAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }> };

export type ImageTextureNodeTextureQueryVariables = Types.Exact<{
  textureRevisionLegacyId: Types.Scalars['Int']['input'];
}>;


export type ImageTextureNodeTextureQuery = { __typename: 'Query', textureRevision: { __typename: 'TextureRevision', id: string, height: number, number?: number | null, state: Types.TextureRevisionState, width: number, texture?: { __typename: 'Texture', id: string, type: Types.TextureType, latestRevision?: { __typename: 'TextureRevision', legacyId: number, number?: number | null } | null, revisions: Array<{ __typename: 'TextureRevision', id: string, legacyId: number, number?: number | null }>, textureSet: { __typename: 'TextureSet', textureGroup: { __typename: 'TextureGroup', id: string } } } | null, dataObject: { __typename: 'DataObject', thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null }, thumbnailAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }> } };

export const ImageTextureNodeTextureFragmentDoc = gql`
    fragment ImageTextureNodeTexture on Texture {
  id
  latestRevision {
    legacyId
    number
  }
  revisions {
    id
    legacyId
    number
  }
  textureSet {
    textureGroup {
      id
    }
  }
  type
}
    `;
export const ImageTextureNodeTextureRevisionFragmentDoc = gql`
    fragment ImageTextureNodeTextureRevision on TextureRevision {
  id
  height
  number
  state
  texture {
    ...ImageTextureNodeTexture
  }
  dataObject {
    thumbnail(fileType: jpg, resolution: Low) {
      downloadUrl
    }
  }
  thumbnailAssignments: dataObjectAssignments(
    filter: {assignmentType: [GalleryImage]}
  ) {
    dataObject {
      thumbnail(fileType: jpg, resolution: Low) {
        downloadUrl
      }
    }
  }
  width
}
    `;
export const ImageTextureNodeTextureDocument = gql`
    query imageTextureNodeTexture($textureRevisionLegacyId: Int!) {
  textureRevision(legacyId: $textureRevisionLegacyId) {
    ...ImageTextureNodeTextureRevision
  }
}
    ${ImageTextureNodeTextureRevisionFragmentDoc}
${ImageTextureNodeTextureFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ImageTextureNodeTextureGQL extends Apollo.Query<ImageTextureNodeTextureQuery, ImageTextureNodeTextureQueryVariables> {
    override document = ImageTextureNodeTextureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }