import {TextFieldModule} from "@angular/cdk/text-field"
import {AfterViewInit, Component, ElementRef, OnInit, input, viewChild, output} from "@angular/core"

@Component({
    selector: "cm-native-input-text-area",
    templateUrl: "./native-input-text-area.component.html",
    styleUrls: ["./native-input-text-area.component.scss"],
    imports: [TextFieldModule],
})
export class NativeInputTextAreaComponent implements OnInit, AfterViewInit {
    readonly $inputElement = viewChild.required<ElementRef>("inputField")
    readonly $placeholder = input<string>(undefined, {alias: "placeholder"})
    readonly $initialValue = input.required<string>({alias: "initialValue"})
    readonly $autoFocus = input(false, {alias: "autoFocus"})
    readonly valueChange = output<string>()
    readonly keyUp = output<string>()
    readonly $disabled = input(false, {alias: "disabled"})
    readonly $minRows = input(0, {alias: "minRows"})

    readonly $input = viewChild<ElementRef<HTMLTextAreaElement> | null>("inputField")

    value: string | null = null
    ngOnInit() {
        this.value = this.$initialValue()
    }

    ngAfterViewInit(): void {
        if (this.$autoFocus()) {
            this.$inputElement().nativeElement.focus()
        }
    }

    onChange(_$event: Event) {
        this.value = this.$inputElement().nativeElement.value
        this.valueChange.emit(this.value!)
    }

    onKeyUp(_$event: KeyboardEvent) {
        this.keyUp.emit(this.$inputElement().nativeElement.value!)
    }

    updateValue(value: string) {
        const inputValue = this.$input()
        if (inputValue) {
            inputValue.nativeElement.value = value
        }
        this.valueChange.emit(value)
    }

    focus() {
        const inputValue = this.$input()
        if (inputValue) {
            inputValue.nativeElement.focus()
        }
    }
}
