import {Component, input, model, output} from "@angular/core"
import {ScreenSizes} from "@material-editor/models/screen-sizes"

@Component({
    selector: "cm-title-bar",
    templateUrl: "./title-bar.component.html",
    styleUrls: ["./title-bar.component.scss"],
})
export class TitleBarComponent {
    screenSizes: typeof ScreenSizes = ScreenSizes
    readonly $screenSize = model(ScreenSizes.mediumScreen, {alias: "screenSize"})
    readonly screenSizeChange = output<ScreenSizes>({alias: "screenSizeChange"})

    constructor() {}

    screenSizeUpdate(value: ScreenSizes) {
        this.$screenSize.set(value)
        this.screenSizeChange.emit(value)
    }
}
