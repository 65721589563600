import * as Types from '../../../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MaterialForConfigMenuServiceFragment = { __typename: 'Material', id: string, name?: string | null, revisions: Array<{ __typename: 'MaterialRevision', id: string }>, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string } | null };

export type DataObjectThumbnailForConfigMenuServiceQueryVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type DataObjectThumbnailForConfigMenuServiceQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } };

export const MaterialForConfigMenuServiceFragmentDoc = gql`
    fragment MaterialForConfigMenuService on Material {
  id
  revisions {
    id
  }
  latestCyclesRevision {
    id
  }
  name
}
    `;
export const DataObjectThumbnailForConfigMenuServiceDocument = gql`
    query dataObjectThumbnailForConfigMenuService($legacyId: Int!) {
  dataObject(legacyId: $legacyId) {
    id
    thumbnail {
      downloadUrl
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DataObjectThumbnailForConfigMenuServiceGQL extends Apollo.Query<DataObjectThumbnailForConfigMenuServiceQuery, DataObjectThumbnailForConfigMenuServiceQueryVariables> {
    override document = DataObjectThumbnailForConfigMenuServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }