import {Component, viewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {CombineRgbInputs, CombineRgbOutputs} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-combine-rgb-node",
    templateUrl: "./combine-rgb-node.component.html",
    styleUrls: ["./combine-rgb-node.component.scss"],
    imports: [NodeBaseComponent],
})
export class CombineRgbNodeComponent {
    readonly $nodeBase = viewChild<MaterialNodeBase>("nodeBase")
    outputs = CombineRgbOutputs
    inputs = CombineRgbInputs
    typeInfo = CombineRGBNodeType
}

export const CombineRGBNodeType: MaterialNodeType<typeof CombineRgbNodeComponent> = {
    id: "combineRgb",
    label: "Combine RGB",
    color: "#4987af",
    name: "ShaderNodeCombineRGB",
    inputs: [CombineRgbInputs.red, CombineRgbInputs.green, CombineRgbInputs.blue],
    outputs: [CombineRgbOutputs.image],
    component: CombineRgbNodeComponent,
}
