import {AddressMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/common-types"
import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {ImageDescriptor, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {assertNoBatching} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"
import {getHalAddressMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils-webgl2"
import {convertToImageProcessingGeometry} from "@app/textures/texture-editor/operator-stack/image-op-system/utils/rasterize-geometry-helpers"
import {ImageProcessingNodes} from "@cm/image-processing-nodes"
import {JobNodes} from "@cm/job-nodes"
import {ColorLike, Vector2Like} from "@cm/math"
import {isHalImageDescriptor} from "@common/helpers/hal"
import externalData = ImageProcessingNodes.externalData

export type Geometry = {
    topology: "triangleList"
    vertices: {
        positions: Vector2Like[] // in pixel space
        uvs?: Vector2Like[] // in pixel space; default: {x: 0, y: 0}
        colors?: ColorLike[] // default: {r: 1, g: 1, b: 1, a: 1}
    }
    indices?: number[] // default: [0, 1, 2, 3, 4, 5, ...]
}

export type ParameterType = {
    geometry: Geometry | JobNodes.DataObjectReference
    textureImage?: ImageRef
    addressMode?: AddressMode // default: "wrap"
    resultImageOrDescriptor: ImageRef | ImageDescriptor
}

export type ReturnType = ImageRef

const imageOpRasterizeGeometry: ImageOpType<ParameterType, ReturnType> = {
    name: "RasterizeGeometry",

    WebGL2: ({cmdQueue, parameters: {geometry, textureImage, addressMode, resultImageOrDescriptor}}) => {
        if (JobNodes.isDataObjectReference(geometry)) {
            throw new Error("DataObjectReference is not supported in WebGL2")
        }
        addressMode ??= "wrap"
        if (geometry.topology !== "triangleList") {
            throw new Error("Only triangleList topology is supported in WebGL2")
        }
        const painter = cmdQueue.createPainter(
            "primitive",
            "rasterizeGeometry",
            `
            vec4 computeColor(vec2 worldPosition, vec2 uv, vec4 color) {
                ${textureImage ? `color *= texturePx0(uv, ${getHalAddressMode(addressMode)});` : ""}
                return color;
            }
        `,
        )
        const resultImage = isHalImageDescriptor(resultImageOrDescriptor) ? cmdQueue.createImage(resultImageOrDescriptor) : resultImageOrDescriptor
        assertNoBatching(resultImage.descriptor)
        const vertexPositions = geometry.vertices.positions
        const vertexUVs = geometry.vertices.uvs ?? geometry.vertices.positions.map(() => ({x: 0, y: 0}))
        const vertexColors = geometry.vertices.colors ?? geometry.vertices.positions.map(() => ({r: 1, g: 1, b: 1, a: 1}))
        const indices = geometry.indices ?? vertexPositions.map((_, index) => index)
        cmdQueue.paint(painter, {
            geometry: {
                vertices: {
                    positions: vertexPositions,
                    uvs: vertexUVs,
                    colors: vertexColors,
                },
                indices,
            },
            sourceImages: textureImage,
            resultImage,
        })
        return resultImage
    },

    ImgProc: ({cmdQueue, parameters: {geometry, textureImage, addressMode, resultImageOrDescriptor}}) => {
        addressMode ??= "wrap"
        if (addressMode !== "wrap") {
            throw new Error("Only wrap address mode is supported in ImgProc")
        }
        let imgProcGeometry: ImageProcessingNodes.GeometryNode
        if (JobNodes.isDataObjectReference(geometry)) {
            imgProcGeometry = externalData(geometry, "geometry")
        } else {
            imgProcGeometry = convertToImageProcessingGeometry(geometry)
        }
        const resultImage = isHalImageDescriptor(resultImageOrDescriptor) ? cmdQueue.createImage(resultImageOrDescriptor) : resultImageOrDescriptor
        return cmdQueue.createImage(resultImage.descriptor, {
            type: "rasterizeGeometry",
            geometry: imgProcGeometry,
            texture: textureImage,
            target: resultImage,
        })
    },
}

export function rasterizeGeometry(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpRasterizeGeometry, parameters)
}
