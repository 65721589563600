import {EventEmitter} from "@angular/core"
import {Box2, Matrix3x2, Vector2, Vector2Like} from "@cm/math"
import paper from "paper"
import {filter, Subject, takeUntil} from "rxjs"
import {CanvasBaseComponent, CanvasPhysicalInfo} from "@common/components/canvas"
import {CanvasBaseToolboxItem, ToolKeyEvent, ToolMouseEvent, ToolWheelEvent} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export class CanvasBaseToolboxItemBase<ParentItemType extends CanvasBaseToolboxItem = CanvasBaseToolboxItem> implements CanvasBaseToolboxItem {
    readonly canvasBoundsChange = new EventEmitter<Box2>()
    readonly viewChange = new EventEmitter<Matrix3x2>()
    readonly cursorChange = new EventEmitter<string | undefined>()
    readonly physicalInfoChange = new EventEmitter<CanvasPhysicalInfo | undefined>()

    readonly itemRemove = new EventEmitter<void>()
    readonly visibleChange = new EventEmitter<boolean>()
    readonly disabledChange = new EventEmitter<boolean>()
    readonly selectedChange = new EventEmitter<boolean>()

    // inputMode = InputMode.Selected
    selectionMode = SelectionMode.SingleSelect
    private readonly items: CanvasBaseToolboxItem[] = []
    private _paperLayer!: paper.Layer
    private _visible = true
    private _disabled = false
    private _selected = false
    private _cursor: string | undefined = undefined
    private readonly SHOW_DEBUG_HINTS = false
    protected unsubscribe = new Subject<void>()

    private readonly baseHitTolerance = 8
    protected readonly hitTestOptions = {
        stroke: true,
        segments: true,
        fill: true,
        tolerance: this.baseHitTolerance,
        match: (_hitResult: paper.HitResult): boolean => true,
    }

    constructor(readonly parentItem: ParentItemType) {
        this.parentItem.beginPaperCreation()

        this._paperLayer = new paper.Layer()
        this._paperLayer.name = "ToolboxItemLayer"
        this._paperLayer.remove() // remove from default parent

        this.parentItem.canvasBoundsChange.pipe(takeUntil(this.unsubscribe)).subscribe((canvasBounds) => this.canvasBoundsChange.emit(canvasBounds))
        this.parentItem.viewChange.pipe(takeUntil(this.unsubscribe)).subscribe((transform) => this.viewChange.emit(transform))
        this.parentItem.physicalInfoChange.pipe(takeUntil(this.unsubscribe)).subscribe((physicalInfo) => this.physicalInfoChange.emit(physicalInfo))
        this.parentItem.addChildItem(this)
        this.bringToFront()

        this.beginPaperCreation()
    }

    remove(): void {
        this.unsubscribe.next()
        this.unsubscribe.complete()
        this.selected = false
        this.itemRemove.emit()
        this.itemRemove.complete()
        this.visibleChange.complete()
        this.disabledChange.complete()
        this.selectedChange.complete()
        this.removeAllItems()
        this._paperLayer.removeChildren()
        this._paperLayer.remove()
    }

    beginPaperCreation(): void {
        this.canvasBase.paperScope.activate()
        this.paperLayer.activate()
    }

    get children(): readonly CanvasBaseToolboxItem[] {
        return this.items
    }

    get cursor(): string | undefined {
        return this._cursor
    }

    set cursor(cursor: string | undefined) {
        if (this._cursor === cursor) {
            return
        }
        this._cursor = cursor
        this.cursorChange.emit(cursor)
    }

    get physicalInfo(): CanvasPhysicalInfo | undefined {
        return this.canvasBase.navigation.physicalInfo
    }

    isPointInImage(point: Vector2Like): boolean {
        return this.canvasBounds.containsPoint(point)
    }

    get canvasCursorPosition(): Vector2 {
        return this.canvasBase.canvasCursorPosition
    }

    get paperLayer(): paper.Layer {
        return this._paperLayer
    }

    set visible(visible: boolean) {
        if (this._visible == visible) {
            return
        }
        /*if (!visible) {
            this.selected = false;
        }*/
        this._visible = visible
        this.paperLayer.visible = visible
        this.visibleChange.emit(visible)
    }

    get visible(): boolean {
        return this._visible
    }

    set disabled(disabled: boolean) {
        if (this._disabled == disabled) {
            return
        }
        if (disabled) {
            this.selected = false
        }
        this._disabled = disabled
        this.disabledChange.emit(disabled)
    }

    get disabled(): boolean {
        return this._disabled
    }

    set selected(selected: boolean) {
        if (this._selected == selected) {
            return
        }
        this._selected = selected
        this.selectedChange.emit(selected)
    }

    get selected(): boolean {
        return this._selected
    }

    // automatically called from CanvasBaseToolboxEntityBase::ctor (TODO is there a better way than having to make this public ?)
    addChildItem(item: CanvasBaseToolboxItemBase) {
        if (this.items.indexOf(item) >= 0) {
            throw new Error("Trying to add canvas-tool-item multiple times to the tool-box.")
        }
        this.items.push(item)
        item.itemRemove.subscribe(() => this.removeItem(item))
        item.selectedChange.subscribe((selected) => this.itemSelectionChanged(item, selected))
        this.paperLayer.addChild(item.paperLayer)
    }

    sendToBack(): void {
        this.paperLayer.sendToBack()
        if (this.parentItem) {
            this.parentItem.sendItemToBack(this)
        }
    }

    bringToFront(): void {
        this.paperLayer.bringToFront()
        if (this.parentItem) {
            this.parentItem.bringItemToFront(this)
        }
    }

    sendItemToBack(item: CanvasBaseToolboxItem): void {
        const index = this.items.indexOf(item)
        if (index < 0) {
            throw new Error("Trying to send canvas-tool-item to back which is not part of the tool-box. Did you try to send the item multiple times ?")
        }
        this.items.splice(index, 1)
        this.items.push(item)
    }

    bringItemToFront(item: CanvasBaseToolboxItem): void {
        const index = this.items.indexOf(item)
        if (index < 0) {
            throw new Error("Trying to bring canvas-tool-item to front which is not part of the tool-box. Did you try to bring the item multiple times ?")
        }
        this.items.splice(index, 1)
        this.items.unshift(item)
    }

    private removeItem(item: CanvasBaseToolboxItem): void {
        const index = this.items.indexOf(item)
        if (index < 0) {
            throw new Error("Trying to remove canvas-tool-item which is not part of the tool-box. Did you try to remove the item multiple times ?")
        }
        this.items.splice(index, 1)
    }

    removeAllItems(selectedOnly = false) {
        while (this.items.length > 0) {
            for (const item of this.items) {
                if (!selectedOnly || item.selected) {
                    item.remove()
                    break
                }
            }
        }
    }

    // selectAllItems(selected: boolean, recurse: boolean): void {
    //     if (selected && this.items.length > 1 && this.selectionMode != SelectionMode.MultiSelect) {
    //         return // not allowed
    //     }
    //     this.items.forEach((item) => {
    //         item.selected = selected
    //         if (recurse) {
    //             item.selectAllItems(selected, recurse)
    //         }
    //     })
    // }

    protected addShortcut(keys: string[]) {
        return this.canvasBase.hotkeys.addShortcut(keys).pipe(
            takeUntil(this.unsubscribe),
            filter(() => this.visible && !this.disabled),
        )
    }

    get canvasBase(): CanvasBaseComponent {
        if (this.parentItem) {
            return this.parentItem.canvasBase
        }
        throw new Error("No valid root entity found")
    }

    get canvasBounds(): Box2 {
        return this.canvasBase.canvasBounds
    }

    get zoomLevel(): number {
        return this.canvasBase.navigation.getZoomLevel()
    }

    private itemSelectionChanged(item: CanvasBaseToolboxItemBase, selected: boolean) {
        if (selected && this.selectionMode == SelectionMode.SingleSelect) {
            // deselect all other items
            this.items.filter((iteratedItem) => iteratedItem != item).forEach((item) => (item.selected = false))
        }
        if (this.SHOW_DEBUG_HINTS) {
            console.log("Setting selection of " + item.constructor.name + " to " + selected)
        }
    }

    // private keyEventInputFilter(event: paper.KeyEvent, item: CanvasBaseToolboxItem): boolean {
    //     return true
    // }

    // private mouseEventInputFilter(event: WheelEvent | paper.ToolEvent, item: CanvasBaseToolboxItem): boolean {
    //     if (!item.hitInfo.hitItem) {
    //         return false
    //     }
    //     if (!this.customInputFilter(event, item)) {
    //         return false
    //     }
    //     switch (this.inputMode) {
    //         case InputMode.None:
    //             return false
    //         case InputMode.Selected:
    //             return item.hitInfo.hitItem != this || item.selected // either the item is selected or a descendant of the item is hit
    //         case InputMode.All:
    //             return true
    //         default:
    //             throw new Error("input-filter-mode not implemented")
    //     }
    // }

    // protected customInputFilter(_event: paper.KeyEvent | WheelEvent | paper.ToolEvent, _item: CanvasBaseToolboxItem): boolean {
    //     return true
    // }

    onKeyDown(event: ToolKeyEvent): boolean {
        let result = true
        this.items
            // .filter((item) => this.keyEventInputFilter(event, item))
            .forEach((item) => {
                const ret = item.onKeyDown(event)
                result &&= ret
            })
        return result
    }

    onKeyUp(event: ToolKeyEvent): boolean {
        let result = true
        this.items
            // .filter((item) => this.keyEventInputFilter(event, item))
            .forEach((item) => {
                const ret = item.onKeyUp(event)
                result &&= ret
            })
        return result
    }

    onMouseWheel(_event: ToolWheelEvent): boolean {
        // let result = true
        // this.items
        //     .filter((item) => this.mouseEventInputFilter(event, item))
        //     .forEach((item) => {
        //         const ret = item.onMouseWheel(event)
        //         result &&= ret
        //     })
        // return result
        return true
    }

    onMouseDown(event: ToolMouseEvent): boolean {
        if (this.selectionMode !== SelectionMode.None) {
            if (event.buttons === 1) {
                this.selected = true
            }
        }
        // this.updateSelectionOnClick()
        // let result = true
        // this.items
        //     .filter((item) => this.mouseEventInputFilter(event, item))
        //     .forEach((item) => {
        //         if (item.hitInfo.hitItem === item) {
        //             item.hasCapture = true
        //         }
        //         const ret = item.onMouseDown(event)
        //         result &&= ret
        //     })
        // return result
        return true
    }

    onMouseUp(_event: ToolMouseEvent): boolean {
        // let result = true
        // this.items
        //     .filter((item) => this.mouseEventInputFilter(event, item))
        //     .forEach((item) => {
        //         if (item.hitInfo.hitItem === item) {
        //             item.hasCapture = false
        //         }
        //         const ret = item.onMouseUp(event)
        //         result &&= ret
        //     })
        // return result
        return true
    }

    onMouseDrag(_event: ToolMouseEvent): boolean {
        // let result = true
        // this.items
        //     .filter((item) => this.mouseEventInputFilter(event, item))
        //     .forEach((item) => {
        //         const ret = item.onMouseDrag(event)
        //         result &&= ret
        //     })
        // return result
        return true
    }

    onMouseMove(_event: ToolMouseEvent): boolean {
        // let result = true
        // this.items
        //     .filter((item) => this.mouseEventInputFilter(event, item))
        //     .forEach((item) => {
        //         const ret = item.onMouseMove(event)
        //         result &&= ret
        //     })
        // return result
        return true
    }

    onMouseLeave(_event: ToolMouseEvent): boolean {
        return true
    }

    hitTest(point: Vector2Like): CanvasBaseToolboxItem | null {
        if (!this.visible) {
            return null
        }
        for (const child of this.children) {
            const childHit = child.hitTest(point)
            if (childHit) {
                return childHit
            }
        }
        return null
    }

    paperHitTest(point: Vector2Like, hitTestOptions?: object): paper.HitResult | null {
        return this.paperLayer.hitTest(point, {...this.hitTestOptions, hitTestOptions})
    }

    // private numSelections(): number {
    //     return this.items.filter((item) => item.selected).length
    // }

    // private updateSelectionOnClick(): void {
    //     if (this.selectionMode == SelectionMode.None) {
    //         return
    //     }
    //     switch (this.selectionMode) {
    //         case SelectionMode.SingleSelect:
    //             // only deselect all if nothing was hit or the hit item is not selected yet to avoid switch selection off and back on again
    //             if (!this.hitInfo.hitItem || !this.hitInfo.hitItem.selected || this.numSelections() > 1) {
    //                 this.selectAllItems(false, true)
    //             }
    //             if (this.hitInfo.hitItem) {
    //                 this.hitInfo.hitItem.selected = true
    //             }
    //             break
    //         case SelectionMode.MultiSelect:
    //             throw new Error("MultiSelect not implemented yet") // TODO to be implemented
    //     }
    // }
}

export enum InputMode {
    None = "None",
    Selected = "Selected",
    All = "All",
}

export enum SelectionMode {
    None = "None",
    SingleSelect = "SingleSelect",
    MultiSelect = "MultiSelect",
}
