import * as Types from '../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateTemplateRevisionMutationVariables = Types.Exact<{
  input: Types.MutationUpdateTemplateRevisionInput;
}>;


export type UpdateTemplateRevisionMutation = { __typename: 'Mutation', updateTemplateRevision: { __typename: 'TemplateRevision', id: string, legacyId: number } };

export const UpdateTemplateRevisionDocument = gql`
    mutation updateTemplateRevision($input: MutationUpdateTemplateRevisionInput!) {
  updateTemplateRevision(input: $input) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTemplateRevisionGQL extends Apollo.Mutation<UpdateTemplateRevisionMutation, UpdateTemplateRevisionMutationVariables> {
    override document = UpdateTemplateRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }