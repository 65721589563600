import {animate, style, transition, trigger} from "@angular/animations"
import {Component, DestroyRef, inject, Injector, OnInit, signal, input, output} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatButtonModule} from "@angular/material/button"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatTableModule} from "@angular/material/table"
import {MatTooltipModule} from "@angular/material/tooltip"
import {provideAnimations} from "@angular/platform-browser/animations"
import {JobTaskTableItemFragment} from "@app/platform/components/jobs/job-tasks-table/job-tasks-table.generated"
import {LoadedData} from "@app/platform/models/data"
import {IsDefined} from "@cm/utils/filter"
import {OverflowableTextComponent} from "@common/components/data"
import {FullPageFeedbackComponent} from "@common/components/full-page-feedback/full-page-feedback.component"
import {TableCellComponent} from "@common/components/tables/table-cell/table-cell.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {AuthService} from "@common/services/auth/auth.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {JobTaskThumbnailComponent} from "@platform/components/jobs/job-task-thumbnail/job-task-thumbnail.component"
import {JobTaskTableItemsGQL} from "@platform/components/jobs/job-tasks-table/job-tasks-table.generated"
import {PLACEHOLDER_ITEMS} from "@platform/models/data/constants"
import {formatRenderJobTaskClaimedByField, isClaimedRenderJobTask} from "@platform/models/job-task/render-job-task"
import {DataLoaderService} from "@platform/services/data"
import {filter, forkJoin, from, Observable, of, switchMap} from "rxjs"
import {v4 as uuid4} from "uuid"

@Component({
    selector: "cm-job-tasks-table",
    templateUrl: "./job-tasks-table.component.html",
    styleUrls: ["./job-tasks-table.component.scss"],
    imports: [
        MatTableModule,
        MatTooltipModule,
        MatButtonModule,
        MatProgressBarModule,
        OverflowableTextComponent,
        TableCellComponent,
        FullPageFeedbackComponent,
        JobTaskThumbnailComponent,
    ],
    animations: [
        trigger("fadeInPlaceholder", [transition("void => *", [style({opacity: 0.4, scale: 0.7}), animate("1000ms", style({opacity: 1, scale: 0.98}))])]),
    ],
    providers: [provideAnimations()],
})
export class JobTasksTableComponent implements OnInit {
    readonly clickItem = output<JobTaskTableItemFragment | undefined>()

    readonly auth = inject(AuthService)
    readonly refresh = inject(RefreshService)
    readonly injector = inject(Injector)

    readonly jobId$ = input.required<Observable<string | null | undefined>>()

    readonly dataLoader = inject(DataLoaderService)
    readonly destroyRef = inject(DestroyRef)

    readonly itemsGql = inject(JobTaskTableItemsGQL)

    readonly $data = signal(PLACEHOLDER_ITEMS(5) as LoadedData<JobTaskTableItemFragment>)

    ngOnInit() {
        this.jobId$()
            .pipe(
                filter(IsDefined),
                switchMap((id) => fetchThrowingErrors(this.itemsGql)({id})),
                switchMap(({items: dataItems}) =>
                    forkJoin(
                        dataItems.map((dataItem) =>
                            IsDefined(dataItem)
                                ? isClaimedRenderJobTask(dataItem)
                                    ? from(formatRenderJobTaskClaimedByField(dataItem, this.injector))
                                    : of({...dataItem, claimedBy: undefined})
                                : of(dataItem),
                        ),
                    ),
                ),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe((dataItems) => {
                this.$data.set({
                    items: dataItems.map((dataItem) => ({trackId: uuid4(), data: dataItem, error: null})),
                    error: null,
                    complete: false,
                    totalCount: dataItems.length,
                })
            })
    }
}
