export interface MenuItem {
    id: string
    text: string
    subItems: MenuItem[]
    context?: unknown
}

export interface MenuItemSource {
    pathInMenu: string //'A/B/C' will put the item in the menu under 'A' -> 'B' -> 'C'
    [key: string]: unknown
}

export function initializeMenuItems(menuItemSources: MenuItemSource[]): MenuItem[] {
    const rootItems: MenuItem[] = []
    const pathMap = new Map<string, MenuItem>()

    menuItemSources.forEach((menuItemSource) => {
        const pathSegments = menuItemSource.pathInMenu.split("/")
        let currentLevel = rootItems

        pathSegments.reduce((accumulatedPath, segment, index) => {
            const newPath = `${accumulatedPath}/${segment}`
            let menuItem = pathMap.get(newPath)

            if (!menuItem) {
                menuItem = {
                    id: menuItemSource.pathInMenu,
                    text: segment,
                    subItems: [],
                }
                pathMap.set(newPath, menuItem)
                currentLevel.push(menuItem)
            }

            if (index === pathSegments.length - 1) {
                menuItem.context = menuItemSource
            }

            currentLevel = menuItem.subItems
            return newPath
        }, "")
    })

    return rootItems
}
