import {Component, OnInit, input, model, viewChild, output} from "@angular/core"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextComponent} from "@common/components/inputs/native/native-input-text/native-input-text.component"
import {debounceTime, Subject, takeUntil, tap} from "rxjs"

@Component({
    selector: "cm-search",
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.scss"],
    imports: [InputContainerComponent, NativeInputTextComponent],
})
export class SearchComponent implements OnInit {
    readonly $value = model("", {alias: "value"})
    readonly $placeholder = input("Search...", {alias: "placeholder"})
    readonly inputStarted = output<string>()
    readonly valueChange = output<string>()
    readonly focus = output<FocusEvent>()
    readonly blur = output<FocusEvent>()
    searchTextChanged: Subject<string> = new Subject<string>()
    unsubscribe = new Subject<void>()

    readonly $nativeInput = viewChild.required(NativeInputTextComponent)

    ngOnInit(): void {
        this.searchTextChanged
            .pipe(
                tap((newValue) => {
                    this.$value.set(newValue)
                }),
                tap((newValue) => this.inputStarted.emit(newValue)),
                debounceTime(300),
                // we need to emit even if the old value is the same as the new value
                // because the content might already have been replaced by placeholders
                // e.g. when changing `text` to `text2` and then back to `text` within the debounce time
                // distinctUntilChanged(),
                takeUntil(this.unsubscribe),
            )
            .subscribe((text: string) => {
                this.valueChange.emit(text)
            })
    }

    ngOnDestroy() {
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }

    updateValue(value: string) {
        this.$nativeInput().updateValue(value)
    }
}
