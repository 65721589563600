import {DeclareTemplateNode} from "#template-nodes/declare-template-node"
import {TemplateNode, OnCompileContext} from "#template-nodes/types"
import {registerNode} from "@cm/graph/register-node"
import {MaterialLike, materialLike, numberLike} from "#template-nodes/node-types"
import {z} from "zod"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {IMaterialData, keyForMaterialData} from "@cm/material-nodes/interfaces/material-data"
import {UVOffset} from "#template-nodes/interfaces/scene-manager"
import {SceneNodes} from "#template-nodes/interfaces/scene-object"
import {BuilderInlet} from "#template-nodes/runtime-graph/graph-builder"
import {nodeInstance} from "@cm/graph/instance"
import {transformOffsetUVs} from "@cm/material-nodes/material-node-graph-transformations"
import {MeshDecal} from "#template-nodes/nodes/mesh-decal"

const materialAssignmentParameters = z.object({
    node: materialLike.nullable(),
    horizontalOffset: numberLike.optional(),
    verticalOffset: numberLike.optional(),
    rotation: numberLike.optional(),
    side: z.enum(["front", "back", "double"]), // same as MaterialSide
})
export type MaterialAssignmentParameters = z.infer<typeof materialAssignmentParameters>

@registerNode
export class MaterialAssignment extends DeclareTemplateNode(
    {
        parameters: materialAssignmentParameters,
    },
    {},
    {nodeClass: "MaterialAssignment"},
) {
    setupMaterialAssignment(scope: GraphBuilderScope, context: OnCompileContext, overriddenMaterial?: MaterialLike | null) {
        const {evaluator, currentTemplate, sceneProperties} = context
        const {preDisplayList} = currentTemplate
        const {node: material, horizontalOffset, verticalOffset, rotation, side} = this.parameters
        let [materialGraph, matGraphInvalid] = scope.branch(evaluator.evaluateMaterial(scope, overriddenMaterial ?? material))

        const nullToZero = (number: BuilderInlet<number | null>, uniqueId: string) => scope.pureLambda(number, (number) => number ?? 0, uniqueId)

        if (horizontalOffset || verticalOffset || rotation) {
            const offset = scope.struct<UVOffset>("UVOffset", {
                horizontal: nullToZero(evaluator.evaluateNumber(scope, horizontalOffset ?? 0), `horizontal`),
                vertical: nullToZero(evaluator.evaluateNumber(scope, verticalOffset ?? 0), `vertical`),
                rotation: nullToZero(evaluator.evaluateNumber(scope, rotation ?? 0), `rotation`),
            })

            materialGraph = scope.pureLambda(
                scope.tuple(materialGraph, offset),
                ([materialGraph, offset]) => transformOffsetUVs(materialGraph, offset),
                `transformOffsetUVs`,
            )
        }

        const textureResolution = sceneProperties?.parameters.textureResolution ?? "2000px"
        const sceneTextureFiltering = sceneProperties?.parameters.textureFiltering
        const textureFiltering = sceneTextureFiltering === true ? "linear" : "nearest"

        const materialData = scope.struct<IMaterialData>("IMaterialData", {
            name: scope.get(materialGraph, "name"),
            materialGraph,
            side,
            realtimeSettings: scope.struct<IMaterialData["realtimeSettings"]>("RealtimeSettings", {
                disable: overriddenMaterial === undefined && !(sceneProperties?.parameters.enableRealtimeMaterials ?? true),
                textureResolution,
                textureFiltering,
            }),
        })

        const preloadMaterialId = scope.pureLambda(materialData, keyForMaterialData, `preloadMatKey`)

        const preloadMaterial = scope.phi(
            scope.struct<SceneNodes.PreloadMaterial>("PreloadMaterial", {
                type: "PreloadMaterial",
                id: preloadMaterialId,
                topLevelObjectId: preloadMaterialId,
                materialData,
            }),
            matGraphInvalid,
        )

        preDisplayList.push(preloadMaterial)

        return scope.phi(materialData, matGraphInvalid)
    }
}

export type MaterialAssignmentFwd = TemplateNode<MaterialAssignmentParameters>

@registerNode
export class MaterialAssignments extends DeclareTemplateNode(
    //owns material assignments
    {parameters: z.record(nodeInstance(MaterialAssignment).nullable())},
    {},
    {nodeClass: "MaterialAssignments"},
) {
    setupMaterialAssignments(scope: GraphBuilderScope, context: OnCompileContext) {
        const assignmentEntries = Object.entries(this.parameters).map(([key, assignment]) => {
            return scope.tuple(parseInt(key), setupMaterialAssignmentWithOverride(scope.scope(key), context, {materialAssignments: this, key}, assignment))
        })

        return scope.entriesToMap(scope.list(assignmentEntries, "matAssignmentList"))
    }
}

const temporaryAssignment = new MaterialAssignment({node: null, side: "front"})

export function setupMaterialAssignmentWithOverride(
    scope: GraphBuilderScope,
    context: OnCompileContext,
    parent: MeshDecal | {materialAssignments: MaterialAssignments; key: string},
    materialAssignment: MaterialAssignment | null,
) {
    const {overrideMaterial} = context

    const nullRet = scope.valueWithoutType(null)

    if (overrideMaterial === undefined) return materialAssignment === null ? nullRet : materialAssignment.setupMaterialAssignment(scope, context)

    const overriddenMaterial = overrideMaterial(parent)
    if (overriddenMaterial === undefined) return materialAssignment === null ? nullRet : materialAssignment.setupMaterialAssignment(scope, context)
    else {
        if (materialAssignment) return materialAssignment.setupMaterialAssignment(scope, context, overriddenMaterial)

        if (overriddenMaterial === null) return nullRet

        return temporaryAssignment.setupMaterialAssignment(scope, context, overriddenMaterial)
    }
}
