import {TypedImageData} from "@cm/utils/typed-image-data"

export function getNumChannels(imageOrChannelLayout: Pick<TypedImageData, "channelLayout"> | TypedImageData["channelLayout"]): number {
    const channelLayout = typeof imageOrChannelLayout === "object" ? imageOrChannelLayout.channelLayout : imageOrChannelLayout // TODO how to better distinguish those types ?
    switch (channelLayout) {
        case "L":
            return 1
        case "RGB":
            return 3
        case "BGR":
            return 3
        case "ARGB":
            return 4
        case "RGBA":
            return 4
        case "ABGR":
            return 4
        case "BGRA":
            return 4
    }
}

export function getBytesPerChannel(imageOrDataType: Pick<TypedImageData, "dataType"> | TypedImageData["dataType"]): number {
    const dataType = typeof imageOrDataType === "object" ? imageOrDataType.dataType : imageOrDataType // TODO how to better distinguish those types ?
    switch (dataType) {
        case "float32":
            return 4
        case "float16":
            return 2
        case "uint16":
            return 2
        case "uint8":
            return 1
    }
}

export function getBytesPerPixel(image: Pick<TypedImageData, "dataType" | "channelLayout">): number {
    return getNumChannels(image) * getBytesPerChannel(image)
}

export function getTypedArrayConstructorByDataType(
    dataType: TypedImageData["dataType"],
): Uint8ArrayConstructor | Uint16ArrayConstructor | Float32ArrayConstructor {
    switch (dataType) {
        case "uint8":
            return Uint8Array
        case "uint16":
            return Uint16Array
        case "float16":
            return Uint16Array
        case "float32":
            return Float32Array
        default:
            throw new Error(`Invalid data type: ${dataType}`)
    }
}

export function createImageData(
    width: number,
    height: number,
    channelLayout: TypedImageData["channelLayout"],
    dataType: TypedImageData["dataType"],
    colorSpace: "sRGB" | "linear",
    dpi?: number,
): TypedImageData {
    return {
        data: new (getTypedArrayConstructorByDataType(dataType))(width * height * getNumChannels(channelLayout)),
        width,
        height,
        channelLayout,
        dataType,
        colorSpace,
        dpi,
    }
}
