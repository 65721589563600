import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PriceMappingPriceGraphFragment = { __typename: 'PriceGraph', id: string, state: Types.PriceGraphState, templateId: string, revisionNumber: number, graph: any };

export type PriceMappingPriceFragment = { __typename: 'Price', currency: Types.Currency, price: number, idFromCatalog: { __typename: 'IdFromCatalog', originalId?: string | null } };

export type PriceMappingIdFromCatalogToPriceGraphFragment = { __typename: 'IdFromCatalogToPriceGraph', priceGraphUuid: string, idFromCatalog: { __typename: 'IdFromCatalog', originalId?: string | null } };

export type PriceMappingGetPriceGraphsQueryVariables = Types.Exact<{
  filter: Types.PriceGraphFilterInput;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type PriceMappingGetPriceGraphsQuery = { __typename: 'Query', priceGraphs: Array<{ __typename: 'PriceGraph', id: string, state: Types.PriceGraphState, templateId: string, revisionNumber: number, graph: any } | null> };

export type PriceMappingGetPriceQueryVariables = Types.Exact<{
  filter: Types.PriceFilterInput;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type PriceMappingGetPriceQuery = { __typename: 'Query', prices: Array<{ __typename: 'Price', currency: Types.Currency, price: number, idFromCatalog: { __typename: 'IdFromCatalog', originalId?: string | null } } | null> };

export type PriceMappingOrganizationIdFromTemplateIdQueryVariables = Types.Exact<{
  templateId: Types.Scalars['ID']['input'];
}>;


export type PriceMappingOrganizationIdFromTemplateIdQuery = { __typename: 'Query', template: { __typename: 'Template', id: string, organizationId: string } };

export type PriceMappingCreatePriceGraphMutationVariables = Types.Exact<{
  input: Types.MutationCreatePriceGraphInput;
}>;


export type PriceMappingCreatePriceGraphMutation = { __typename: 'Mutation', createPriceGraph: { __typename: 'PriceGraph', id: string, state: Types.PriceGraphState, graph: any, revisionNumber: number } };

export type PriceMappingUpdatePriceGraphMutationVariables = Types.Exact<{
  input: Types.MutationUpdatePriceGraphInput;
}>;


export type PriceMappingUpdatePriceGraphMutation = { __typename: 'Mutation', updatePriceGraph: { __typename: 'PriceGraph', state: Types.PriceGraphState, graph: any, revisionNumber: number } };

export type PriceMappingDeletePriceGraphMutationVariables = Types.Exact<{
  priceGraphId: Types.Scalars['ID']['input'];
}>;


export type PriceMappingDeletePriceGraphMutation = { __typename: 'Mutation', deletePriceGraph?: any | null };

export type GetPriceGraphsForCatalogIdsQueryVariables = Types.Exact<{
  organizationUuid: Types.Scalars['ID']['input'];
  originalIds: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
  take: Types.Scalars['Int']['input'];
}>;


export type GetPriceGraphsForCatalogIdsQuery = { __typename: 'Query', idFromCatalogToPriceGraphItems: Array<{ __typename: 'IdFromCatalogToPriceGraph', priceGraphUuid: string, idFromCatalog: { __typename: 'IdFromCatalog', originalId?: string | null } } | null> };

export const PriceMappingPriceGraphFragmentDoc = gql`
    fragment PriceMappingPriceGraph on PriceGraph {
  id
  state
  templateId
  revisionNumber
  graph
}
    `;
export const PriceMappingPriceFragmentDoc = gql`
    fragment PriceMappingPrice on Price {
  idFromCatalog {
    originalId
  }
  currency
  price
}
    `;
export const PriceMappingIdFromCatalogToPriceGraphFragmentDoc = gql`
    fragment PriceMappingIdFromCatalogToPriceGraph on IdFromCatalogToPriceGraph {
  priceGraphUuid
  idFromCatalog {
    originalId
  }
}
    `;
export const PriceMappingGetPriceGraphsDocument = gql`
    query priceMappingGetPriceGraphs($filter: PriceGraphFilterInput!, $take: Int, $skip: Int) {
  priceGraphs(filter: $filter, take: $take, skip: $skip) {
    ...PriceMappingPriceGraph
  }
}
    ${PriceMappingPriceGraphFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PriceMappingGetPriceGraphsGQL extends Apollo.Query<PriceMappingGetPriceGraphsQuery, PriceMappingGetPriceGraphsQueryVariables> {
    override document = PriceMappingGetPriceGraphsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PriceMappingGetPriceDocument = gql`
    query priceMappingGetPrice($filter: PriceFilterInput!, $take: Int, $skip: Int) {
  prices(filter: $filter, take: $take, skip: $skip) {
    ...PriceMappingPrice
  }
}
    ${PriceMappingPriceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PriceMappingGetPriceGQL extends Apollo.Query<PriceMappingGetPriceQuery, PriceMappingGetPriceQueryVariables> {
    override document = PriceMappingGetPriceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PriceMappingOrganizationIdFromTemplateIdDocument = gql`
    query priceMappingOrganizationIdFromTemplateId($templateId: ID!) {
  template(id: $templateId) {
    id
    organizationId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PriceMappingOrganizationIdFromTemplateIdGQL extends Apollo.Query<PriceMappingOrganizationIdFromTemplateIdQuery, PriceMappingOrganizationIdFromTemplateIdQueryVariables> {
    override document = PriceMappingOrganizationIdFromTemplateIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PriceMappingCreatePriceGraphDocument = gql`
    mutation priceMappingCreatePriceGraph($input: MutationCreatePriceGraphInput!) {
  createPriceGraph(input: $input) {
    id
    state
    graph
    revisionNumber
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PriceMappingCreatePriceGraphGQL extends Apollo.Mutation<PriceMappingCreatePriceGraphMutation, PriceMappingCreatePriceGraphMutationVariables> {
    override document = PriceMappingCreatePriceGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PriceMappingUpdatePriceGraphDocument = gql`
    mutation priceMappingUpdatePriceGraph($input: MutationUpdatePriceGraphInput!) {
  updatePriceGraph(input: $input) {
    state
    graph
    revisionNumber
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PriceMappingUpdatePriceGraphGQL extends Apollo.Mutation<PriceMappingUpdatePriceGraphMutation, PriceMappingUpdatePriceGraphMutationVariables> {
    override document = PriceMappingUpdatePriceGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PriceMappingDeletePriceGraphDocument = gql`
    mutation priceMappingDeletePriceGraph($priceGraphId: ID!) {
  deletePriceGraph(priceGraphId: $priceGraphId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PriceMappingDeletePriceGraphGQL extends Apollo.Mutation<PriceMappingDeletePriceGraphMutation, PriceMappingDeletePriceGraphMutationVariables> {
    override document = PriceMappingDeletePriceGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPriceGraphsForCatalogIdsDocument = gql`
    query getPriceGraphsForCatalogIds($organizationUuid: ID!, $originalIds: [String!]!, $take: Int!) {
  idFromCatalogToPriceGraphItems(
    distinct: false
    filter: {originalIds: $originalIds, organizationUuid: $organizationUuid}
    take: $take
  ) {
    ...PriceMappingIdFromCatalogToPriceGraph
  }
}
    ${PriceMappingIdFromCatalogToPriceGraphFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPriceGraphsForCatalogIdsGQL extends Apollo.Query<GetPriceGraphsForCatalogIdsQuery, GetPriceGraphsForCatalogIdsQueryVariables> {
    override document = GetPriceGraphsForCatalogIdsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }