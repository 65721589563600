import {Component, input, model, output} from "@angular/core"
import {MatSliderModule} from "@angular/material/slider"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"

@Component({
    selector: "cm-slider",
    templateUrl: "./slider.component.html",
    styleUrls: ["./slider.component.scss"],
    imports: [MatSliderModule, InputContainerComponent, NumericInputComponent],
})
export class SliderComponent {
    readonly $disabled = input(false, {alias: "disabled"})
    readonly $exponent = input(
        1, // allows for non-linear sliders when set to a value other than 1
        {alias: "exponent"},
    ) // allows for non-linear sliders when set to a value other than 1
    readonly $displayValue = input(true, {alias: "displayValue"})
    readonly $displayValuePrecision = input(2, {alias: "displayValuePrecision"})
    readonly $min = input(1, {alias: "min"})
    readonly $max = input(100, {alias: "max"})
    readonly $value = model(50, {alias: "value"})
    readonly valueChange = output<number>()
    readonly isDragging = output<boolean>()

    constructor() {}

    set valueNumber(value: number) {
        this.$value.set(Math.max(this.$min(), Math.min(this.$max(), value)))
        this.valueChange.emit(this.$value())
    }

    get valueNumber(): number {
        return this.$value()
    }

    get sliderMin(): number {
        return this.value2sliderValue(this.$min())
    }

    get sliderMax(): number {
        return this.value2sliderValue(this.$max())
    }

    get sliderStep(): number {
        return (this.sliderMax - this.sliderMin) / 100
    }

    get sliderValue(): number {
        return this.value2sliderValue(this.$value())
    }

    set sliderValue(value: number) {
        this.$value.set(this.sliderValue2value(value))
        this.valueChange.emit(this.$value())
    }

    private value2sliderValue(value: number): number {
        return Math.pow(value, 1 / this.$exponent())
    }

    private sliderValue2value(sliderValue: number): number {
        return Math.pow(sliderValue, this.$exponent())
    }

    protected updateValueNumber(value: number | null | undefined): void {
        if (value === null || value === undefined) return
        this.valueNumber = value
    }
}
