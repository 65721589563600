<div class="cm-login-container">
    <!--login page-->
    @if (!forgotPassword && passwordToken === undefined) {
        <div class="cm-login-card">
            <div class="cm-login-card-header">Log in to colormass</div>
            <form class="cm-login-form" name="loginForm" [formGroup]="loginForm" novalidate (ngSubmit)="login()">
                <cm-input-container
                    [matTooltip]="
                        loginForm.controls.loginEmailFormControl.hasError('email') || loginForm.controls.loginEmailFormControl.hasError('required')
                            ? 'Please enter a valid email address'
                            : ''
                    "
                    [warning]="loginForm.controls.loginEmailFormControl.hasError('email') || loginForm.controls.loginEmailFormControl.hasError('required')"
                >
                    <ng-container cm-top-label>
                        <div class="cm-top-label">Email:</div>
                    </ng-container>
                    <ng-container cm-input>
                        <input
                            placeholder="Enter email"
                            name="email"
                            formControlName="loginEmailFormControl"
                            [(ngModel)]="credentials.email"
                            autofocus
                            class="cm-email"
                        />
                    </ng-container>
                </cm-input-container>
                <cm-input-container
                    [matTooltip]="loginForm.controls.loginPasswordFormControl.hasError('required') ? 'Password is required' : ''"
                    [warning]="loginForm.controls.loginPasswordFormControl.hasError('required')"
                >
                    <ng-container cm-top-label>
                        <div class="cm-top-label">Password:</div>
                    </ng-container>
                    <ng-container cm-input>
                        <input
                            placeholder="Enter password"
                            matInput
                            type="password"
                            name="password"
                            formControlName="loginPasswordFormControl"
                            [(ngModel)]="credentials.password"
                            class="cm-password"
                        />
                    </ng-container>
                </cm-input-container>
                @if (!showProgressCircle) {
                    <button class="cm-sing-in-button" type="submit" [disabled]="showProgressCircle || !loginForm.valid" [class.cm-active]="loginForm.valid">
                        <i class="far fa-sign-in"></i>
                        <span>Log in</span>
                    </button>
                    <a class="cm-reset-password" (click)="forgotPassword = true">Reset password.</a>
                }
            </form>
            @if (!showProgressCircle) {
                <div class="cm-login-card-bottom">
                    @if (microsoftSsoLink) {
                        <a class="cm-sign-in-with-microsoft" [href]="microsoftSsoLink"
                            ><img class="cm-microsoft-logo" alt="Microsoft logo" ngSrc="assets/images/Microsoft.svg" width="21" height="21" />Sign in with
                            Microsoft</a
                        >
                    }
                    @if (googleSsoLink) {
                        <a class="cm-sign-in-with-google" [href]="googleSsoLink"
                            ><img class="cm-google-logo" alt="Google logo" ngSrc="assets/images/Google.png" width="20" height="20" />Sign in with Google</a
                        >
                    }
                    <div class="cm-legal-container">
                        When using the colormass platform you agree to the
                        <a class="cm-legal" href="https://www.colormass.com/legal/terms-of-service">colormass Terms of Service</a>.
                    </div>
                </div>
            }
            @if (showProgressCircle) {
                <mat-progress-spinner mode="indeterminate" [diameter]="24" color="primary"></mat-progress-spinner>
            }
        </div>
    }
    <!--forgot password page-->
    @if (forgotPassword && passwordToken === undefined) {
        <div class="cm-login-card">
            <div class="cm-login-card-header">Forgot Password</div>
            <form
                class="cm-login-form"
                name="forgotPasswordForm"
                [formGroup]="forgotPasswordForm"
                novalidate
                (ngSubmit)="sendForgotPasswordEmail(passwordResetEmail)"
            >
                <cm-input-container
                    [matTooltip]="forgotPasswordForm.controls.passwordResetEmailFormControl.hasError('required') ? 'Email is required' : ''"
                    [warning]="forgotPasswordForm.controls.passwordResetEmailFormControl.hasError('required')"
                >
                    <ng-container cm-top-label>
                        <div class="cm-top-label">Email:</div>
                    </ng-container>
                    <ng-container cm-input>
                        <input
                            placeholder="Enter email"
                            name="email"
                            formControlName="passwordResetEmailFormControl"
                            class="cm-email"
                            [(ngModel)]="passwordResetEmail"
                            autofocus
                        />
                    </ng-container>
                </cm-input-container>
                <button
                    class="cm-sing-in-button"
                    type="submit"
                    [disabled]="showProgressCircle || !forgotPasswordForm.valid"
                    [class.cm-active]="!showProgressCircle && forgotPasswordForm.valid"
                >
                    Reset Password
                </button>
            </form>
            @if (showProgressCircle) {
                <mat-progress-spinner mode="indeterminate" [diameter]="24" color="primary"></mat-progress-spinner>
            } @else {
                @if (googleSsoLink || microsoftSsoLink) {
                    <div class="cm-sign-in-alternative">or</div>
                    <div class="cm-login-form">
                        @if (microsoftSsoLink) {
                            <a class="cm-sign-in-with-microsoft" [href]="microsoftSsoLink"
                                ><img class="cm-microsoft-logo" alt="Microsoft logo" ngSrc="assets/images/Microsoft.svg" width="21" height="21" />Sign in with
                                Microsoft</a
                            >
                        }
                        @if (googleSsoLink) {
                            <a class="cm-sign-in-with-google" [href]="googleSsoLink"
                                ><img class="cm-google-logo" alt="Google logo" ngSrc="assets/images/Google.png" width="20" height="20" />Sign in with Google</a
                            >
                        }
                    </div>
                }
                <a class="cm-back" (click)="forgotPassword = false"><i class="fa-regular fa-chevron-left"></i> Back to Login</a>
            }
        </div>
    }
    <!--reset password page-->
    @if (!forgotPassword && passwordToken != undefined) {
        <div class="cm-login-card">
            <div class="cm-login-card-header">{{ cardHeading }}</div>
            <form class="cm-login-form" name="passwordResetForm" [formGroup]="passwordResetForm" novalidate (ngSubmit)="setPassword(passwordVariantOne)">
                <cm-input-container
                    [matTooltip]="passwordResetForm.controls.passwordVariantOneFormControl.hasError('required') ? 'Field is required' : ''"
                    [warning]="passwordResetForm.controls.passwordVariantOneFormControl.hasError('required')"
                >
                    <ng-container cm-top-label>
                        <div class="cm-top-label">Password:</div>
                    </ng-container>
                    <ng-container cm-input>
                        <input
                            type="password"
                            placeholder="Type password"
                            name="passwordVariantOne"
                            required
                            [(ngModel)]="passwordVariantOne"
                            formControlName="passwordVariantOneFormControl"
                            autofocus
                            class="cm-password"
                    /></ng-container>
                </cm-input-container>
                <cm-input-container
                    [matTooltip]="passwordResetForm.controls.passwordVariantTwoFormControl.hasError('required') ? 'Field is required' : ''"
                    [warning]="passwordResetForm.controls.passwordVariantTwoFormControl.hasError('required')"
                >
                    <ng-container cm-top-label>
                        <div class="cm-top-label">Retype password:</div>
                    </ng-container>
                    <ng-container cm-input>
                        <input
                            type="password"
                            placeholder="Type Password"
                            formControlName="passwordVariantTwoFormControl"
                            name="passwordVariantTwo"
                            required
                            [(ngModel)]="passwordVariantTwo"
                            autofocus
                            class="cm-password"
                    /></ng-container>
                </cm-input-container>
                <button
                    type="submit"
                    class="cm-sing-in-button"
                    [class.cm-active]="!showProgressCircle && passwordVariantOne == passwordVariantTwo && passwordResetForm.valid"
                    [disabled]="showProgressCircle || passwordVariantOne !== passwordVariantTwo || !passwordResetForm.valid"
                >
                    Reset Password
                </button>
            </form>
            @if (showProgressCircle) {
                <mat-progress-spinner mode="indeterminate" [diameter]="24" color="primary"></mat-progress-spinner>
            } @else {
                @if (googleSsoLink || microsoftSsoLink) {
                    <div class="cm-sign-in-alternative">or</div>
                    <div class="cm-login-form">
                        @if (microsoftSsoLink) {
                            <a class="cm-sign-in-with-microsoft" [href]="microsoftSsoLink"
                                ><img class="cm-microsoft-logo" alt="Microsoft logo" ngSrc="assets/images/Microsoft.svg" width="21" height="21" />Sign in with
                                Microsoft</a
                            >
                        }
                        @if (googleSsoLink) {
                            <a class="cm-sign-in-with-google" [href]="googleSsoLink"
                                ><img class="cm-google-logo" alt="Google logo" ngSrc="assets/images/Google.png" width="20" height="20" />Sign in with Google</a
                            >
                        }
                    </div>
                }
                <a class="cm-back" (click)="forgotPassword = false"><i class="fa-regular fa-chevron-left"></i> Back to Login</a>
            }
        </div>
    }
    <!--reset password page-->
</div>
