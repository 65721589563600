import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {getColor, rgbBlendType, threeConvert, threeRGBColorNode, threeValueNode} from "#material-nodes/three-utils"
import {color, vec3} from "#material-nodes/types"
import {getAll} from "@cm/graph/utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({resultColor: materialSlots})
const InputTypeSchema = z.object({
    factorFloat: materialSlots.optional(),
    factorVector: materialSlots.optional(),
    aFloat: materialSlots.optional(),
    bFloat: materialSlots.optional(),
    aVector: materialSlots.optional(),
    bVector: materialSlots.optional(),
    aColor: materialSlots.optional(),
    bColor: materialSlots.optional(),
})
const ParametersTypeSchema = z.object({
    factorFloat: z.number().optional(),
    factorVector: vec3.optional(),
    aFloat: z.number().optional(),
    bFloat: z.number().optional(),
    aVector: vec3.optional(),
    bVector: vec3.optional(),
    aColor: color.optional(),
    bColor: color.optional(),
    blendType: rgbBlendType.optional(),
    clampFactor: z.boolean().optional(),
    clampResult: z.boolean().optional(),
    dataType: z.enum(["FLOAT", "VECTOR", "RGBA"]).optional(),
    factorMode: z.enum(["UNIFORM", "NON_UNIFORM"]).optional(),
})
export class Mix extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            if (parameters.dataType !== "RGBA") throw new Error("Only rgba data type is supported for now")

            const {aColor, bColor} = await getAll(inputs, get)
            const inputA = aColor ?? threeConvert(parameters.aColor, threeRGBColorNode) ?? threeRGBColorNode({r: 0, g: 0, b: 0})
            const inputB = bColor ?? threeConvert(parameters.bColor, threeRGBColorNode) ?? threeRGBColorNode({r: 0, g: 0, b: 0})

            const factorFloat = await get(inputs.factorFloat)
            let facNode = factorFloat ?? threeConvert(parameters.factorFloat, threeValueNode) ?? threeValueNode(1)
            if (parameters.clampFactor) facNode = THREENodes.clamp(facNode, threeValueNode(0), threeValueNode(1))

            const {blendType, clampResult} = parameters
            const operation = blendType ?? "MIX"
            const mixedColor = getColor(operation, inputA, inputB, facNode)
            return {resultColor: clampResult ? THREENodes.clamp(mixedColor, threeValueNode(0), threeValueNode(1)) : mixedColor}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
