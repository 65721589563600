@if ($show()) {
    <mat-form-field [cmIsLoading]="!$options()" [appearance]="$usedInAddEntityContext() ? 'fill' : 'outline'">
        @if ($showLabel()) {
            <mat-label>{{ $label() }}</mat-label>
        }
        <mat-select
            placeholder="organization"
            [ngModel]="$selected()"
            [disabled]="$options().length <= 1 || !$can().update.organization($selected() ?? undefined)"
            [compareWith]="compareAssignments"
            [ngModelOptions]="{standalone: true}"
        >
            @for (organization of $options(); track organization.id) {
                <mat-option (onSelectionChange)="toggleOrganization($event)" [value]="organization">{{ organization.name }} </mat-option>
            }
        </mat-select>
    </mat-form-field>
}
