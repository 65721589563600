<cm-action-bar>
    <ng-container cm-action-items>
        <cm-action-item
            [tooltip]="$canDownload() ? 'Download' : 'Download not possible, because texture is edited or its HQ processing has not yet completed.'"
            [disabled]="!$canDownload()"
            cmIconClass="far fa-arrow-to-bottom"
            [menuTriggerFor]="textureDownloadMenu"
        ></cm-action-item>
        <cm-action-item tooltip="Actual size (100%) [SPACE]" cmIconClass="far fa-expand" (itemClick)="zoomClick.emit()"></cm-action-item>
        <cm-action-item tooltip="Display tiled [T]" cmIconClass="fas fa-grid" (itemClick)="gridClick.emit()" [active]="$gridActive()"></cm-action-item>
        @if (isStaff) {
            <cm-action-item
                tooltip="Boost dark regions in display [B]"
                cmIconClass="fas fa-lightbulb-on"
                (itemClick)="brightnessBoostToggle.emit()"
                [active]="$brightnessBoostActive()"
            ></cm-action-item>
        }
    </ng-container>
</cm-action-bar>
<mat-menu #textureDownloadMenu="matMenu">
    @if ($dataObject(); as dataObject) {
        <button mat-menu-item (click)="downloadDataObject(dataObject.originalFileName, dataObject.originalDownloadUrl)">Original</button>
        @if (dataObject.tiffDataObject?.downloadUrl; as downloadUrl) {
            <button mat-menu-item (click)="downloadDataObject(dataObject.originalFileName, downloadUrl)">TIFF</button>
        }
        @if (dataObject.jpgDataObject?.downloadUrl; as downloadUrl) {
            <button mat-menu-item (click)="downloadDataObject(dataObject.originalFileName, downloadUrl)">JPEG</button>
        }
    }
</mat-menu>
