import {WebGl2Context} from "@common/models/webgl2/webgl2-context"
import {DoublyLinkedList, Node} from "@common/models/hal/doubly-linked-list"
import {Box2Like, Vector2, Vector3Like} from "@cm/math"
import {WebGl2ImagePhysical} from "@common/models/webgl2/webgl2-image-physical"

const TRACE = false

export type FreePageCallback = (pageHandle: PageHandle) => void

export type PageHandleData = {readonly pageIndex: Vector2; freePageCallback?: FreePageCallback}
export type PageHandle = Node<PageHandleData>

export type AtlasDescriptor = {
    numPages: number
}

export class WebGl2ImageAtlas {
    constructor(
        readonly context: WebGl2Context,
        readonly descriptor: AtlasDescriptor,
    ) {
        const {atlasImage, numPages} = this.createAtlas(descriptor)
        this._atlasImage = atlasImage
        this._numPages = numPages
        this.initCache()
    }

    // HalEntity
    dispose(): void {
        this._pageWriteBufferImages.forEach((image) => image.dispose())
        this.releaseAtlas()
    }

    get image(): WebGl2ImagePhysical {
        return this._atlasImage
    }

    addPage(freePageCallback: FreePageCallback): PageHandle {
        // find a page to replace
        const pageHandle = this._pageIndicesLru.head!
        this.freePage(pageHandle, true)
        pageHandle.value.freePageCallback = freePageCallback
        // register page usage
        this.registerPageUsage(pageHandle)
        if (TRACE) {
            console.log(`Adding page: ${pageHandle.value.pageIndex.x}, ${pageHandle.value.pageIndex.y}`)
        }
        return pageHandle
    }

    freePage(pageHandle: PageHandle, callFreePageCallback: boolean): void {
        if (callFreePageCallback && pageHandle.value.freePageCallback) {
            if (TRACE) {
                console.log(`Freeing page: ${pageHandle.value.pageIndex.x}, ${pageHandle.value.pageIndex.y}`)
            }
            pageHandle.value.freePageCallback(pageHandle)
        }
        pageHandle.value.freePageCallback = undefined
        this._pageIndicesLru.moveToFront(pageHandle)
    }

    registerPageUsage(pageHandle: PageHandle): void {
        if (TRACE) {
            console.log(`Registering page usage: ${pageHandle.value.pageIndex.x}, ${pageHandle.value.pageIndex.y}`)
        }
        this._pageIndicesLru.moveToEnd(pageHandle)
    }

    // writeToPage(pageHandle: PageHandle, sourceData: TypedArrayImage | NativeImageData, sourceRegion?: Box2Like) {
    //     const pageSizeWithMargin = WebGl2ImageAtlas.pageSize + WebGl2ImageAtlas.pageMargin
    //     this._atlasImage.writeImageData(
    //         sourceData,
    //         {
    //             x: sourceRegion?.x ?? 0,
    //             y: sourceRegion?.y ?? 0,
    //             width: Math.min(pageSizeWithMargin, sourceRegion?.width ?? pageSizeWithMargin),
    //             height: Math.min(pageSizeWithMargin, sourceRegion?.height ?? pageSizeWithMargin),
    //         },
    //         {
    //             x: pageHandle.value.pageIndex.x * pageSizeWithMargin,
    //             y: pageHandle.value.pageIndex.y * pageSizeWithMargin,
    //         },
    //     )
    //     this.registerPageUsage(pageHandle)
    // }
    //
    // beginRenderToPage(pageHandle: PageHandle, region?: Box2Like) {
    //     if (this._pageWriteInProgress) {
    //         throw new Error("Page write already in progress")
    //     }
    //     this._pageWriteInProgress = true
    //     let bufferImage: WebGl2ImagePhysical
    //     if (this._pageWriteBufferInfos.length >= this._pageWriteBufferImages.length) {
    //         bufferImage = new WebGl2ImagePhysical(this.context, {
    //             width: WebGl2ImageAtlas.pageSizeWithMargin,
    //             height: WebGl2ImageAtlas.pageSizeWithMargin,
    //             channelLayout: "RGBA",
    //             dataType: "float32",
    //         })
    //         this._pageWriteBufferImages.push(bufferImage)
    //         if (this._pageWriteBufferInfos.length + 1 !== this._pageWriteBufferImages.length) {
    //             throw new Error("Internal error: Page write buffer image mismatch")
    //         }
    //     } else {
    //         bufferImage = this._pageWriteBufferImages[this._pageWriteBufferInfos.length]
    //     }
    //     region ??= {
    //         x: 0,
    //         y: 0,
    //         width: WebGl2ImageAtlas.pageSizeWithMargin,
    //         height: WebGl2ImageAtlas.pageSizeWithMargin,
    //     }
    //     const fullTargetRegion = {
    //         x: 0,
    //         y: 0,
    //         width: WebGl2ImageAtlas.pageSizeWithMargin,
    //         height: WebGl2ImageAtlas.pageSizeWithMargin,
    //     }
    //     const bufferRegion = Box2.intersect(fullTargetRegion, region)
    //     this._pageWriteBufferInfos.push({
    //         pageHandle: pageHandle,
    //         bufferImage: bufferImage,
    //         region: bufferRegion,
    //     })
    //     if (bufferImage.beginDraw(bufferRegion, 0) > 1) {
    //         throw new Error("Page write buffer image must have only one draw pass")
    //     }
    //     const transform = bufferImage.beginDrawPass(bufferRegion, 0, 0)
    //     this.registerPageUsage(pageHandle)
    //     return transform
    // }
    //
    // endRenderToPage(pageHandle: PageHandle) {
    //     if (!this._pageWriteInProgress) {
    //         throw new Error("No page write in progress")
    //     }
    //     this._pageWriteInProgress = false
    //     const pageWiteInfo = this._pageWriteBufferInfos[this._pageWriteBufferInfos.length - 1]
    //     if (pageWiteInfo.pageHandle !== pageHandle) {
    //         throw new Error("Page write mismatch")
    //     }
    //     const bufferImage = pageWiteInfo.bufferImage
    //     const bufferRegion = pageWiteInfo.region
    //     bufferImage.endDrawPass(bufferRegion, 0, 0)
    //     bufferImage.endDraw(bufferRegion, 0)
    // }
    //
    // // TODO this can be very slow atm
    // flushRenderToPages() {
    //     if (this._pageWriteInProgress) {
    //         throw new Error("Page write in progress")
    //     }
    //     // blit the rendered page buffer images to actual atlas image
    //     this._pageWriteBufferInfos.forEach((pageWriteInfo) => {
    //         this.context.blit({
    //             sourceImage: pageWriteInfo.bufferImage,
    //             sourceRegion: pageWriteInfo.region,
    //             targetImage: this._atlasImage,
    //             targetOffset: {
    //                 x: pageWriteInfo.pageHandle.value.pageIndex.x * WebGl2ImageAtlas.pageSizeWithMargin + pageWriteInfo.region.x,
    //                 y: pageWriteInfo.pageHandle.value.pageIndex.y * WebGl2ImageAtlas.pageSizeWithMargin + pageWriteInfo.region.y,
    //             },
    //         })
    //     })
    //     this._pageWriteBufferInfos = []
    // }

    private initCache(): void {
        this._pageIndicesLru.clear()
        for (let y = 0; y < this._numPages.y; y++) {
            for (let x = 0; x < this._numPages.x; x++) {
                this._pageIndicesLru.append({
                    pageIndex: new Vector2(x, y),
                })
            }
        }
    }

    private createAtlas(descriptor: AtlasDescriptor): {atlasImage: WebGl2ImagePhysical; numPages: Vector3Like} {
        if (descriptor.numPages <= 0) {
            throw new Error("Atlas must have at least one page")
        }
        const pageSizeWithMargin = WebGl2ImageAtlas.pageSize + WebGl2ImageAtlas.pageMargin
        const maxPagesX = Math.floor(this.context.maxTextureSize / pageSizeWithMargin)
        const numPagesX = Math.min(descriptor.numPages, maxPagesX)
        const numPagesY = Math.ceil(descriptor.numPages / numPagesX)
        if (TRACE) {
            console.log(`Creating atlas image with ${numPagesX}x${numPagesY} pages`)
        }
        const atlasImage = new WebGl2ImagePhysical(this.context, {
            width: numPagesX * pageSizeWithMargin,
            height: numPagesY * pageSizeWithMargin,
            channelLayout: "R",
            dataType: "float32",
        })
        return {
            atlasImage: atlasImage,
            numPages: {x: numPagesX, y: numPagesY, z: 1},
        }
    }

    private releaseAtlas() {
        this._atlasImage.dispose()
    }

    private _atlasImage: WebGl2ImagePhysical
    private _numPages: Vector3Like = {x: 0, y: 0, z: 0}
    private _pageIndicesLru = new DoublyLinkedList<PageHandleData>()

    private _pageWriteBufferImages: WebGl2ImagePhysical[] = []
    private _pageWriteBufferInfos: PageWriteInfo[] = []
    private _pageWriteInProgress = false

    static readonly pageSizeShift = 8
    static readonly pageSize = 1 << WebGl2ImageAtlas.pageSizeShift
    static readonly pageMargin = 0
    static readonly pageSizeWithMargin = WebGl2ImageAtlas.pageSize + WebGl2ImageAtlas.pageMargin
}

type PageWriteInfo = {
    pageHandle: PageHandle
    bufferImage: WebGl2ImagePhysical
    region: Box2Like
}
