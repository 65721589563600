import {KeyValuePipe} from "@angular/common"
import {ChangeDetectionStrategy, Component, input, output} from "@angular/core"
import {MatMenuModule} from "@angular/material/menu"
import {AuthService} from "@common/services/auth/auth.service"
import {AnyStateType, StateLabel} from "@platform/models/state-labels/state-label"
import {UtilsService} from "@legacy/helpers/utils"
import {AssetState, MaterialState, ModelState, PictureState, TaskState, TemplateState} from "@generated"

@Component({
    selector: "cm-state-label",
    templateUrl: "./state-label.component.html",
    styleUrls: ["./state-label.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatMenuModule, KeyValuePipe],
})
export class StateLabelComponent<StateType extends AnyStateType> {
    readonly $state = input.required<StateType>({alias: "state"})
    readonly $stateLabels = input.required<Map<StateType, StateLabel<StateType>>>({alias: "stateLabels"})
    readonly $editable = input<boolean>(false, {alias: "editable"})
    readonly $backgroundColor = input<string>(undefined, {alias: "backgroundColor"})
    readonly $disableCompleted = input(false, {alias: "disableCompleted"})
    readonly stateChange = output<StateType>()

    constructor(
        public auth: AuthService,
        public utils: UtilsService,
    ) {}

    public get data() {
        return this.$stateLabels()?.get(this.$state())
    }

    public get isCompleted() {
        const state = this.$state()
        return (
            state === AssetState.Completed ||
            state === ModelState.Completed ||
            state === MaterialState.Completed ||
            state === TaskState.Archived ||
            state === TemplateState.Completed ||
            state === PictureState.Completed
        )
    }

    onStateSelected(state: StateType) {
        this.stateChange.emit(state)
    }
}
