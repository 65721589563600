import {Component, computed} from "@angular/core"
import {quaternionToAngleDegrees} from "@app/common/helpers/utils/math-utils"
import {TemplateTreeObjectTransformComponent} from "@app/template-editor/components/template-tree-object-transform/template-tree-object-transform.component"
import {RigidRelation} from "@cm/template-nodes"
import {BallRotation, FixedRotation, HingeRotation, Translation} from "@cm/template-nodes"
import {isObject, ObjectLike} from "@cm/template-nodes"
import {R1Variable, S1Variable, S3Variable} from "@cm/template-nodes"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {z} from "zod"
import {resolveSelectionPossibilityValue, SelectionPossibilities, SelectionPossibility, ValueSlotComponent} from "../../value-slot/value-slot.component"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"

@Component({
    selector: "cm-rigid-relation-inspector",
    templateUrl: "./rigid-relation-inspector.component.html",
    styleUrl: "./rigid-relation-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent, TemplateTreeObjectTransformComponent, ButtonComponent],
})
export class RigidRelationInspectorComponent extends BaseInspectorComponent<RigidRelation> {
    readonly $translation = computed(() => this.$parameters().translation)
    readonly $rotation = computed(() => this.$parameters().rotation)
    translationTypePossibilites: SelectionPossibilities<R1Variable | number> = [
        {value: 0, name: "Fixed"},
        {value: () => new R1Variable({default: 0, range: [-100, 100]}), name: "Variable"},
    ]
    translationTypeSelected = (selectionPossibility: SelectionPossibility<R1Variable | number>, value: R1Variable | number) => {
        const selectionPossibilityValue = resolveSelectionPossibilityValue(selectionPossibility.value)
        return typeof value === typeof selectionPossibilityValue
    }
    isR1Variable = (value: R1Variable | number): value is R1Variable => value instanceof R1Variable
    numberSchema = z.number()
    rotationTypePossibilites: SelectionPossibilities<FixedRotation | HingeRotation | BallRotation> = [
        {value: () => new FixedRotation({x: 0, y: 0, z: 0}), name: "Fixed"},
        {
            value: () =>
                new HingeRotation({
                    axis: "x",
                    rotation: new S1Variable({
                        default: [0, 1],
                    }),
                }),
            name: "Hinge",
        },
        {
            value: () =>
                new BallRotation({
                    rotation: new S3Variable({
                        default: [0, 0, 0, 1],
                    }),
                }),
            name: "Ball",
        },
    ]
    rotationTypeSelected = (
        selectionPossibility: SelectionPossibility<FixedRotation | HingeRotation | BallRotation>,
        value: FixedRotation | HingeRotation | BallRotation,
    ) => {
        const selectionPossibilityValue = resolveSelectionPossibilityValue(selectionPossibility.value)
        return value.getNodeClass() === selectionPossibilityValue.getNodeClass()
    }
    isFixedRotation = (value: FixedRotation | HingeRotation | BallRotation): value is FixedRotation => value instanceof FixedRotation
    isHingeRotation = (value: FixedRotation | HingeRotation | BallRotation): value is HingeRotation => value instanceof HingeRotation
    isBallRotation = (value: FixedRotation | HingeRotation | BallRotation): value is BallRotation => value instanceof BallRotation
    hingeRotationAxisPossibilites: SelectionPossibilities<"x" | "y" | "z"> = [
        {value: "x", name: "X"},
        {value: "y", name: "Y"},
        {value: "z", name: "Z"},
    ]
    isObject = isObject
    private getMatrix(target: ObjectLike | null) {
        if (!target) return undefined
        if (!isObject(target)) return undefined
        return this.sceneManagerService.getTransformAccessor(target)?.getTransform()
    }
    readonly $matrixA = computed(() => this.getMatrix(this.$node().parameters.targetA))
    readonly $matrixB = computed(() => this.getMatrix(this.$node().parameters.targetB))

    captureRelationParams() {
        const matrixA = this.$matrixA()
        const matrixB = this.$matrixB()

        if (!matrixA) return
        if (!matrixB) return

        const {position, quaternion} = matrixA.inverse().multiply(matrixB).decompose()
        const angles = quaternionToAngleDegrees(quaternion)

        const node = this.$node()
        this.sceneManagerService.modifyTemplateGraph(() => {
            node.updateParameters({
                translation: new Translation({x: position.x, y: position.y, z: position.z}),
                rotation: new FixedRotation({x: angles[0], y: angles[1], z: angles[2]}),
            })
        })
    }
}
