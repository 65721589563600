import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TemplateThumbnailRenderingFragment = { __typename: 'Template', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number }, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null } | null };

export type GetTemplateForThumbnailRenderingQueryVariables = Types.Exact<{
  templateId: Types.Scalars['ID']['input'];
}>;


export type GetTemplateForThumbnailRenderingQuery = { __typename: 'Query', template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number }, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null } | null } };

export type TemplateThumbnailRenderingCreateJobTaskMutationVariables = Types.Exact<{
  input: Types.MutationCreateJobInput;
}>;


export type TemplateThumbnailRenderingCreateJobTaskMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string, legacyId: number } };

export const TemplateThumbnailRenderingFragmentDoc = gql`
    fragment TemplateThumbnailRendering on Template {
  id
  legacyId
  name
  organization {
    id
    legacyId
  }
  latestRevision {
    id
    legacyId
    graph
  }
}
    `;
export const GetTemplateForThumbnailRenderingDocument = gql`
    query getTemplateForThumbnailRendering($templateId: ID!) {
  template(id: $templateId) {
    ...TemplateThumbnailRendering
  }
}
    ${TemplateThumbnailRenderingFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateForThumbnailRenderingGQL extends Apollo.Query<GetTemplateForThumbnailRenderingQuery, GetTemplateForThumbnailRenderingQueryVariables> {
    override document = GetTemplateForThumbnailRenderingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateThumbnailRenderingCreateJobTaskDocument = gql`
    mutation templateThumbnailRenderingCreateJobTask($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateThumbnailRenderingCreateJobTaskGQL extends Apollo.Mutation<TemplateThumbnailRenderingCreateJobTaskMutation, TemplateThumbnailRenderingCreateJobTaskMutationVariables> {
    override document = TemplateThumbnailRenderingCreateJobTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }