import {Component, input, output} from "@angular/core"
import {MatMenuModule} from "@angular/material/menu"
import {TextureEditorDataObjectFragment} from "@app/textures/service/textures-api.generated"
import {FilesService} from "@common/services/files/files.service"
import {AuthService} from "@common/services/auth/auth.service"
import {ActionItemComponent} from "@app/common/components/menu/actions/action-item/action-item.component"
import {ActionBarComponent} from "@app/common/components/menu/actions/action-bar/action-bar.component"

@Component({
    selector: "cm-tiling-controls",
    templateUrl: "./tiling-controls.component.html",
    styleUrls: ["./tiling-controls.component.scss"],
    imports: [ActionItemComponent, ActionBarComponent, MatMenuModule],
})
export class TilingControlsComponent {
    readonly $dataObject = input<TextureEditorDataObjectFragment>(undefined, {alias: "dataObject"})
    readonly $gridActive = input(false, {alias: "gridActive"})
    readonly $canDownload = input(true, {alias: "canDownload"})
    readonly $brightnessBoostActive = input(false, {alias: "brightnessBoostActive"})

    readonly zoomClick = output<void>()
    readonly gridClick = output<void>()
    readonly brightnessBoostToggle = output<void>()

    constructor(private authService: AuthService) {}

    get isStaff(): boolean {
        return this.authService.isStaff()
    }

    protected downloadDataObject(originalFileName: string, downloadUrl: string): void {
        FilesService.downloadFile(originalFileName, downloadUrl)
    }
}
