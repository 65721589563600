import {CompleteMeshData, AbstractMeshData} from "#template-nodes/geometry-processing/mesh-data"
import {ISceneManager} from "#template-nodes/interfaces/scene-manager"
import {Inlet, NotReady, Outlet} from "#template-nodes/runtime-graph/slots"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {defer, map, Subscription} from "rxjs"

const TD = TypeDescriptors

const generateMeshDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManager>()),
    inputMeshData: TD.inlet(TD.AbstractMeshData),
    completeMeshData: TD.outlet(TD.CompleteMeshData),
}

export class GenerateMesh implements NodeClassImpl<typeof generateMeshDescriptor, typeof GenerateMesh> {
    static descriptor = generateMeshDescriptor
    static uniqueName = "GenerateMesh"
    sceneManager!: Inlet<ISceneManager>
    inputMeshData!: Inlet<AbstractMeshData>
    completeMeshData!: Outlet<CompleteMeshData>

    private pendingEvaluation: Subscription | null = null
    private updatedDuringEvaluation = false

    private triggerEvaluation() {
        const {sceneManager, inputMeshData} = this

        this.completeMeshData.emitIfChanged(NotReady)

        if (sceneManager === NotReady || inputMeshData === NotReady) {
            return
        }

        if (this.pendingEvaluation) {
            this.updatedDuringEvaluation = true
            return
        }

        this.pendingEvaluation = sceneManager.addTask({
            description: `evaluateMesh(${inputMeshData.displayGeometryGraph.type})`,
            task: defer(() => sceneManager.evaluateMesh(inputMeshData.displayGeometryGraph, inputMeshData.displayGeometryGraphResources)).pipe(
                map((reified) => {
                    this.pendingEvaluation?.unsubscribe()
                    this.pendingEvaluation = null
                    this.completeMeshData.emitIfChanged({
                        reified,
                        abstract: inputMeshData,
                    })
                    if (this.updatedDuringEvaluation) {
                        this.updatedDuringEvaluation = false
                        this.triggerEvaluation()
                    }
                }),
            ),
            critical: true,
        })
    }

    run() {
        this.triggerEvaluation()
    }

    cleanup() {
        this.pendingEvaluation?.unsubscribe()
        this.pendingEvaluation = null
    }
}
