import * as Types from '../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TemplateRevisionDetailsForTemplateRevisionBatchApiCallFragment = { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null };

export type GetTemplateRevisionDetailsForTemplateRevisionBatchApiCallQueryVariables = Types.Exact<{
  legacyIds?: Types.InputMaybe<Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']>;
}>;


export type GetTemplateRevisionDetailsForTemplateRevisionBatchApiCallQuery = { __typename: 'Query', templateRevisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null } | null> };

export const TemplateRevisionDetailsForTemplateRevisionBatchApiCallFragmentDoc = gql`
    fragment TemplateRevisionDetailsForTemplateRevisionBatchApiCall on TemplateRevision {
  id
  legacyId
  graph
}
    `;
export const GetTemplateRevisionDetailsForTemplateRevisionBatchApiCallDocument = gql`
    query getTemplateRevisionDetailsForTemplateRevisionBatchApiCall($legacyIds: [Int!]) {
  templateRevisions(filter: {legacyId: {in: $legacyIds}}, take: 1000) {
    ...TemplateRevisionDetailsForTemplateRevisionBatchApiCall
  }
}
    ${TemplateRevisionDetailsForTemplateRevisionBatchApiCallFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateRevisionDetailsForTemplateRevisionBatchApiCallGQL extends Apollo.Query<GetTemplateRevisionDetailsForTemplateRevisionBatchApiCallQuery, GetTemplateRevisionDetailsForTemplateRevisionBatchApiCallQueryVariables> {
    override document = GetTemplateRevisionDetailsForTemplateRevisionBatchApiCallDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }