import type {OperationVariables} from "@apollo/client"
import type {MutationOptions} from "@apollo/client/core"
import {EmptyObject} from "@common/helpers/api/fetch"
import {firstValueFrom, type Observable} from "rxjs"
import type {MutationResult} from "apollo-angular"

export const mutateIgnoringErrors =
    <ResultType = object, VariablesType extends OperationVariables = EmptyObject>(mutation: {
        mutate: (variables?: VariablesType, options?: MutationOptions<ResultType, VariablesType>) => Observable<MutationResult<ResultType>>
    }) =>
    (variables?: VariablesType, options?: MutationOptions<ResultType, VariablesType>): Promise<ResultType | null | undefined> =>
        firstValueFrom(mutation.mutate(variables, options)).then(({data}) => data)

export const mutateThrowingErrors =
    <ResultType = object, VariablesType extends OperationVariables = EmptyObject>(mutation: {
        mutate: (variables?: VariablesType, options?: MutationOptions<ResultType, VariablesType>) => Observable<MutationResult<ResultType>>
    }) =>
    (variables?: VariablesType, options?: MutationOptions<ResultType, VariablesType>): Promise<ResultType> =>
        firstValueFrom(mutation.mutate(variables, options)).then(({data, errors}) => {
            const firstError = errors?.[0]
            if (firstError) {
                console.error("error in mutation", errors)
                throw firstError
            }
            return data!
        })

export const mutateReturningDataAndErrors =
    <ResultType = object, VariablesType extends OperationVariables = EmptyObject>(mutation: {
        mutate: (variables?: VariablesType, options?: MutationOptions<ResultType, VariablesType>) => Observable<MutationResult<ResultType>>
    }) =>
    (variables?: VariablesType, options?: MutationOptions<ResultType, VariablesType>): Promise<MutationResult<ResultType>> =>
        firstValueFrom(mutation.mutate(variables, options))
