import {Component, ElementRef, OnInit, input, viewChild, output} from "@angular/core"

@Component({
    selector: "cm-native-input-text",
    templateUrl: "./native-input-text.component.html",
    styleUrls: ["./native-input-text.component.scss"],
})
export class NativeInputTextComponent implements OnInit {
    readonly $placeholder = input<string>("Search...", {alias: "placeholder"})
    readonly $initialValue = input<string>("", {alias: "initialValue"})
    readonly valueChange = output<string>()
    readonly focus = output<FocusEvent>()
    readonly blur = output<FocusEvent>()
    readonly keyUp = output<string>()
    readonly inputStarted = output<string>()
    readonly $disabled = input(false, {alias: "disabled"})
    readonly $required = input(false, {alias: "required"})
    readonly $showInvalid = input(false, {alias: "showInvalid"})
    readonly $type = input("text", {alias: "type"})

    readonly $input = viewChild<ElementRef<HTMLInputElement> | null>("inputField")

    ngOnInit() {
        const inputValue = this.$input()
        if (inputValue) {
            inputValue.nativeElement.value = this.$initialValue()
        }
    }

    onChange($event: Event) {
        const target = $event.currentTarget
        if (target instanceof HTMLInputElement) {
            this.valueChange.emit(target.value)
        }
    }

    onKeyUp($event: KeyboardEvent) {
        const target = $event.currentTarget
        if (target instanceof HTMLInputElement) {
            this.keyUp.emit(target.value)
        }
    }

    updateValue(value: string) {
        const inputValue = this.$input()
        if (inputValue) {
            inputValue.nativeElement.value = value
        }
        this.valueChange.emit(value)
    }
}
