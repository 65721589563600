import {PriorityQueue} from "@cm/utils"

export class AStarPathFinding {
    static findPath<NodeType extends number>(
        numIndices: number, // number of nodes
        start: NodeType, // start node index
        end: NodeType, // end node index
        heuristic: (from: NodeType, to: NodeType) => number, // the heuristic from node "from" to node "to"
        neighborIndices: (index: NodeType) => NodeType[], // the neighbors of a node
        moveToNeighborCost: (from: NodeType, to: NodeType) => number, // the cost to move from node to one of its neighbors
    ): number[] {
        const gScore = new Float32Array(numIndices) // currently minimal cost from end to node
        gScore.fill(Infinity)
        gScore[end] = 0
        const fScore = new Float32Array(numIndices) // gScore+heuristic: sum of currently minimal cost from end to node plus the heuristic from node to start
        fScore.fill(Infinity)
        fScore[end] = heuristic(end, start)
        const openSet = new PriorityQueue<NodeType>() // node indices in the open list, sorted by fScore
        openSet.enqueue(end, fScore[end]) // we traverse the path backwards
        const cameFrom = new Int32Array(numIndices) // node indices which lead to the current node in the shortest path
        const reconstruct_path = (current: number) => {
            const total_path = [current]
            while (current !== end) {
                current = cameFrom[current]
                total_path.push(current)
            }
            return total_path
        }
        for (;;) {
            const current = openSet.dequeue()
            if (current === null) {
                throw new Error("No path found")
            }
            if (current === start) {
                return reconstruct_path(current)
            }
            const neighbors = neighborIndices(current)
            for (const neighbor of neighbors) {
                // d(current,neighbor) is the weight of the edge from current to neighbor
                // tentative_gScore is the distance from start to the neighbor through current
                const tentative_gScore = gScore[current] + moveToNeighborCost(current, neighbor)
                if (tentative_gScore < gScore[neighbor]) {
                    // This path to neighbor is better than any previous one. Record it!
                    cameFrom[neighbor] = current
                    gScore[neighbor] = tentative_gScore
                    fScore[neighbor] = tentative_gScore + heuristic(neighbor, start)
                    if (!openSet.contains(neighbor)) {
                        openSet.enqueue(neighbor, fScore[neighbor])
                    } else {
                        openSet.updatePriority(neighbor, fScore[neighbor])
                    }
                }
            }
        }
    }
}
