import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TemplateDetailsForTemplateEditorFragment = { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, number: number, createdAt: string }> };

export type GetTemplateDetailsForTemplateEditorQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetTemplateDetailsForTemplateEditorQuery = { __typename: 'Query', template: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, number: number, createdAt: string }> } };

export type PictureDetailsForTemplateEditorFragment = { __typename: 'Picture', id: string, legacyId: number, template?: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, number: number, createdAt: string }> } | null, organization: { __typename: 'Organization', id: string } };

export type GetPictureDetailsForTemplateEditorQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetPictureDetailsForTemplateEditorQuery = { __typename: 'Query', picture: { __typename: 'Picture', id: string, legacyId: number, template?: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, number: number, createdAt: string }> } | null, organization: { __typename: 'Organization', id: string } } };

export type TemplateRevisionDetailsForTemplateEditorFragment = { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null, inconsistencies: Array<any>, number: number, template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number } } };

export type GetTemplateRevisionDetailsForTemplateEditorQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetTemplateRevisionDetailsForTemplateEditorQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null, inconsistencies: Array<any>, number: number, template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number } } } };

export type GetLatestTemplateRevisionDetailsForTemplateEditorQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetLatestTemplateRevisionDetailsForTemplateEditorQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', template: { __typename: 'Template', id: string, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null, inconsistencies: Array<any>, number: number, template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number } } } | null } } };

export type GetTemplateRevisionsDetailsForTemplateEditorQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.TemplateRevisionFilterInput>;
}>;


export type GetTemplateRevisionsDetailsForTemplateEditorQuery = { __typename: 'Query', templateRevisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null, inconsistencies: Array<any>, number: number, template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number } } } | null> };

export type CreateTemplateRevisionForTemplateEditorMutationVariables = Types.Exact<{
  input: Types.MutationCreateTemplateRevisionInput;
}>;


export type CreateTemplateRevisionForTemplateEditorMutation = { __typename: 'Mutation', createTemplateRevision: { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null, inconsistencies: Array<any>, number: number, template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number } } } };

export type CreateTemplateForTemplateEditorMutationVariables = Types.Exact<{
  input: Types.MutationCreateTemplateInput;
}>;


export type CreateTemplateForTemplateEditorMutation = { __typename: 'Mutation', createTemplate: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, number: number, createdAt: string }> } };

export const TemplateDetailsForTemplateEditorFragmentDoc = gql`
    fragment TemplateDetailsForTemplateEditor on Template {
  id
  legacyId
  revisions {
    id
    number
    createdAt
  }
}
    `;
export const PictureDetailsForTemplateEditorFragmentDoc = gql`
    fragment PictureDetailsForTemplateEditor on Picture {
  id
  legacyId
  template {
    ...TemplateDetailsForTemplateEditor
  }
  organization {
    id
  }
}
    `;
export const TemplateRevisionDetailsForTemplateEditorFragmentDoc = gql`
    fragment TemplateRevisionDetailsForTemplateEditor on TemplateRevision {
  id
  legacyId
  graph
  inconsistencies
  number
  template {
    id
    legacyId
    name
    organization {
      id
      legacyId
    }
  }
}
    `;
export const GetTemplateDetailsForTemplateEditorDocument = gql`
    query getTemplateDetailsForTemplateEditor($id: ID!) {
  template(id: $id) {
    ...TemplateDetailsForTemplateEditor
  }
}
    ${TemplateDetailsForTemplateEditorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateDetailsForTemplateEditorGQL extends Apollo.Query<GetTemplateDetailsForTemplateEditorQuery, GetTemplateDetailsForTemplateEditorQueryVariables> {
    override document = GetTemplateDetailsForTemplateEditorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPictureDetailsForTemplateEditorDocument = gql`
    query getPictureDetailsForTemplateEditor($id: ID!) {
  picture(id: $id) {
    ...PictureDetailsForTemplateEditor
  }
}
    ${PictureDetailsForTemplateEditorFragmentDoc}
${TemplateDetailsForTemplateEditorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPictureDetailsForTemplateEditorGQL extends Apollo.Query<GetPictureDetailsForTemplateEditorQuery, GetPictureDetailsForTemplateEditorQueryVariables> {
    override document = GetPictureDetailsForTemplateEditorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateRevisionDetailsForTemplateEditorDocument = gql`
    query getTemplateRevisionDetailsForTemplateEditor($id: ID!) {
  templateRevision(id: $id) {
    ...TemplateRevisionDetailsForTemplateEditor
  }
}
    ${TemplateRevisionDetailsForTemplateEditorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateRevisionDetailsForTemplateEditorGQL extends Apollo.Query<GetTemplateRevisionDetailsForTemplateEditorQuery, GetTemplateRevisionDetailsForTemplateEditorQueryVariables> {
    override document = GetTemplateRevisionDetailsForTemplateEditorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLatestTemplateRevisionDetailsForTemplateEditorDocument = gql`
    query getLatestTemplateRevisionDetailsForTemplateEditor($id: ID!) {
  templateRevision(id: $id) {
    template {
      id
      latestRevision {
        ...TemplateRevisionDetailsForTemplateEditor
      }
    }
  }
}
    ${TemplateRevisionDetailsForTemplateEditorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLatestTemplateRevisionDetailsForTemplateEditorGQL extends Apollo.Query<GetLatestTemplateRevisionDetailsForTemplateEditorQuery, GetLatestTemplateRevisionDetailsForTemplateEditorQueryVariables> {
    override document = GetLatestTemplateRevisionDetailsForTemplateEditorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateRevisionsDetailsForTemplateEditorDocument = gql`
    query getTemplateRevisionsDetailsForTemplateEditor($filter: TemplateRevisionFilterInput) {
  templateRevisions(filter: $filter) {
    ...TemplateRevisionDetailsForTemplateEditor
  }
}
    ${TemplateRevisionDetailsForTemplateEditorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateRevisionsDetailsForTemplateEditorGQL extends Apollo.Query<GetTemplateRevisionsDetailsForTemplateEditorQuery, GetTemplateRevisionsDetailsForTemplateEditorQueryVariables> {
    override document = GetTemplateRevisionsDetailsForTemplateEditorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTemplateRevisionForTemplateEditorDocument = gql`
    mutation createTemplateRevisionForTemplateEditor($input: MutationCreateTemplateRevisionInput!) {
  createTemplateRevision(input: $input) {
    ...TemplateRevisionDetailsForTemplateEditor
  }
}
    ${TemplateRevisionDetailsForTemplateEditorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTemplateRevisionForTemplateEditorGQL extends Apollo.Mutation<CreateTemplateRevisionForTemplateEditorMutation, CreateTemplateRevisionForTemplateEditorMutationVariables> {
    override document = CreateTemplateRevisionForTemplateEditorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTemplateForTemplateEditorDocument = gql`
    mutation createTemplateForTemplateEditor($input: MutationCreateTemplateInput!) {
  createTemplate(input: $input) {
    ...TemplateDetailsForTemplateEditor
  }
}
    ${TemplateDetailsForTemplateEditorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTemplateForTemplateEditorGQL extends Apollo.Mutation<CreateTemplateForTemplateEditorMutation, CreateTemplateForTemplateEditorMutationVariables> {
    override document = CreateTemplateForTemplateEditorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }