<button class="cm-button" (click)="toggle()">
    <ng-content select="[cm-left-icon]"> </ng-content>
    <ng-content select="[cm-text]"> </ng-content>
    <i class="fa-regular {{ $expanded() ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i>
</button>
@if ($expanded()) {
    <div class="cm-dropdown-content">
        <ng-content select="[cm-dropdown-content]"> </ng-content>
    </div>
}
