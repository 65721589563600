import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type BasicOrganizationInfoFragment = { __typename: 'Organization', id: string, legacyId: number, name?: string | null, type?: Types.OrganizationType | null, visibleInFilters?: boolean | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type BasicOrganizationMembershipInfoFragment = { __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null, type?: Types.OrganizationType | null, visibleInFilters?: boolean | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type OrganizationDropdownOptionFragment = { __typename: 'Organization', id: string, name?: string | null };

export type AllOrganizationsQueryVariables = Types.Exact<{
  skip: Types.Scalars['Int']['input'];
  take: Types.Scalars['Int']['input'];
}>;


export type AllOrganizationsQuery = { __typename: 'Query', organizations: Array<{ __typename: 'Organization', id: string, legacyId: number, name?: string | null, type?: Types.OrganizationType | null, visibleInFilters?: boolean | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export type OrganizationMembershipsQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
}>;


export type OrganizationMembershipsQuery = { __typename: 'Query', user: { __typename: 'User', id: string, role?: Types.SystemRole | null, memberships: Array<{ __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null, type?: Types.OrganizationType | null, visibleInFilters?: boolean | null, galleryImage?: { __typename: 'DataObject', id: string } | null } }> } };

export const BasicOrganizationInfoFragmentDoc = gql`
    fragment BasicOrganizationInfo on Organization {
  id
  legacyId
  galleryImage {
    id
  }
  name
  type
  visibleInFilters
}
    `;
export const BasicOrganizationMembershipInfoFragmentDoc = gql`
    fragment BasicOrganizationMembershipInfo on Membership {
  id
  organization {
    ...BasicOrganizationInfo
  }
}
    `;
export const OrganizationDropdownOptionFragmentDoc = gql`
    fragment OrganizationDropdownOption on Organization {
  id
  name
}
    `;
export const AllOrganizationsDocument = gql`
    query allOrganizations($skip: Int!, $take: Int!) {
  organizations(skip: $skip, take: $take) {
    ...BasicOrganizationInfo
  }
}
    ${BasicOrganizationInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AllOrganizationsGQL extends Apollo.Query<AllOrganizationsQuery, AllOrganizationsQueryVariables> {
    override document = AllOrganizationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganizationMembershipsDocument = gql`
    query organizationMemberships($userId: ID!) {
  user(id: $userId) {
    id
    role
    memberships {
      ...BasicOrganizationMembershipInfo
    }
  }
}
    ${BasicOrganizationMembershipInfoFragmentDoc}
${BasicOrganizationInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationMembershipsGQL extends Apollo.Query<OrganizationMembershipsQuery, OrganizationMembershipsQueryVariables> {
    override document = OrganizationMembershipsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }