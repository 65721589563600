import {EventEmitter} from "@angular/core"
import {OperatorToolboxBase} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator-toolbox-base"
import {OperatorToolboxItemBase} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator-toolbox-item-base"
import {OperatorTiling} from "@app/textures/texture-editor/operator-stack/operators/tiling/operator-tiling"
import {SpatialMappingItem, ViewMode} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/spatial-mapping-item"
import {GridHelperLinesItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/grid-helper-lines-item"
import {AreaViz} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/basic/area-viz"
import {Vector2} from "@cm/math"
import {ChangeEvent} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-curve-item"
import {auditTime, filter, merge, Observable, Subject, takeUntil} from "rxjs"
import {Hotkeys} from "@app/common/services/hotkeys/hotkeys.service"
import {HelperLinesBagItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/helper-lines/helper-lines-bag-item"

export class TilingAreaToolboxItem extends OperatorToolboxItemBase<OperatorTiling> {
    readonly areaChanged = new EventEmitter<ChangeEvent>()

    constructor(
        readonly operatorTiling: OperatorToolboxBase<OperatorTiling>,
        readonly helperLinesBagItem: HelperLinesBagItem,
    ) {
        super(operatorTiling)

        const node = operatorTiling.operator.node

        this._spatialMapping = new SpatialMappingItem(this, helperLinesBagItem, node, operatorTiling.operator.computeSnapPosition.bind(operatorTiling.operator))
        merge(this._spatialMapping.mappingChanged, this._spatialMapping.viewModeChanged)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.onDisplayedMappingChanged())
        this._spatialMapping.mappingChanged.pipe(takeUntil(this.unsubscribe)).subscribe((event) => this.areaChanged.emit(event))

        this._gridHelperLines = new GridHelperLinesItem(this._spatialMapping)
        this._borderArea = new AreaViz(this._spatialMapping)
        this._borderArea.color = {r: 0, g: 0, b: 0, a: AREA_ALPHA}
        this._tileArea = new AreaViz(this._spatialMapping)
        this._tileArea.color = {r: 0, g: 0, b: 0, a: AREA_ALPHA}

        this._synchronizeDisplay.pipe(auditTime(0), takeUntil(this.unsubscribe)).subscribe(() => this.updateDisplay())
        this._synchronizeDisplay.next()

        const applyPipe = <T>(obs: Observable<T>) =>
            obs.pipe(
                takeUntil(this.unsubscribe),
                filter(() => this.visible && !this.disabled),
            )

        operatorTiling.operator.viewMode$.pipe(takeUntil(this.unsubscribe)).subscribe((viewMode) => (this._spatialMapping.viewMode = viewMode))
        merge(operatorTiling.operator.borderBlendEnabled$, operatorTiling.operator.borderBlendDistancePx$)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this._synchronizeDisplay.next())

        operatorTiling.operator.boundaryFollowsHelperLinesEnabled$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((enabled) => (this._spatialMapping.boundaryFollowsHelperLinesEnabled = enabled))

        const hotkeys = this.parentItem.operator.callback.injector.get(Hotkeys)
        applyPipe(hotkeys.addShortcut(["Delete", "Backspace"])).subscribe(() => this._spatialMapping.pointVizBagItem.deleteSelectedControlPoints())
        applyPipe(hotkeys.addShortcut(["ArrowLeft"])).subscribe(() =>
            this._spatialMapping.pointVizBagItem.offsetSelectedControlPointsInScreenSpace({x: -1, y: 0}),
        )
        applyPipe(hotkeys.addShortcut(["ArrowRight"])).subscribe(() =>
            this._spatialMapping.pointVizBagItem.offsetSelectedControlPointsInScreenSpace({x: 1, y: 0}),
        )
        applyPipe(hotkeys.addShortcut(["ArrowUp"])).subscribe(() =>
            this._spatialMapping.pointVizBagItem.offsetSelectedControlPointsInScreenSpace({x: 0, y: -1}),
        )
        applyPipe(hotkeys.addShortcut(["ArrowDown"])).subscribe(() =>
            this._spatialMapping.pointVizBagItem.offsetSelectedControlPointsInScreenSpace({x: 0, y: 1}),
        )
    }

    get spatialMapping() {
        return this._spatialMapping
    }

    private onDisplayedMappingChanged() {
        this._synchronizeDisplay.next()
    }

    private updateDisplay() {
        if (this._spatialMapping.viewMode === ViewMode.Result) {
            // don't show area in result view
            this._tileArea.setAreaPoints([])
            this._borderArea.setAreaPoints([])
        } else {
            const tValuesTop = this._spatialMapping.boundaryH.curveMin.tValues
            const tValuesBottom = this._spatialMapping.boundaryH.curveMax.tValues.slice().reverse()
            const tValuesLeft = this._spatialMapping.boundaryV.curveMin.tValues.slice().reverse()
            const tValuesRight = this._spatialMapping.boundaryV.curveMax.tValues

            const tileAreaGridPointsTop = tValuesTop.map((t) => this.spatialMapping.mapUVToScreenSpace(new Vector2(t, 0)))
            const tileAreaGridPointsBottom = tValuesBottom.map((t) => this.spatialMapping.mapUVToScreenSpace(new Vector2(t, 1)))
            const tileAreaGridPointsLeft = tValuesLeft.map((t) => this.spatialMapping.mapUVToScreenSpace(new Vector2(0, t)))
            const tileAreaGridPointsRight = tValuesRight.map((t) => this.spatialMapping.mapUVToScreenSpace(new Vector2(1, t)))
            const tileAreaPoints = tileAreaGridPointsTop.concat(tileAreaGridPointsRight).concat(tileAreaGridPointsBottom).concat(tileAreaGridPointsLeft)
            this._tileArea.setAreaPoints(tileAreaPoints)

            const borderPx = this.operatorTiling.operator.borderBlendEnabled$.value ? this.operatorTiling.operator.borderBlendDistancePx$.value : 0
            const borderTValueH = borderPx / this._spatialMapping.mappedSize.x
            const borderTValueV = borderPx / this._spatialMapping.mappedSize.y
            const borderAreaGridPointsTop = [-borderTValueH, ...tValuesTop, 1 + borderTValueH].map((t) =>
                this.spatialMapping.mapUVToScreenSpace(new Vector2(t, -borderTValueV)),
            )
            const borderAreaGridPointsBottom = [1 + borderTValueH, ...tValuesBottom, -borderTValueH].map((t) =>
                this.spatialMapping.mapUVToScreenSpace(new Vector2(t, 1 + borderTValueV)),
            )
            const borderAreaGridPointsLeft = [1 + borderTValueV, ...tValuesLeft, -borderTValueV].map((t) =>
                this.spatialMapping.mapUVToScreenSpace(new Vector2(-borderTValueH, t)),
            )
            const borderAreaGridPointsRight = [-borderTValueV, ...tValuesRight, 1 + borderTValueV].map((t) =>
                this.spatialMapping.mapUVToScreenSpace(new Vector2(1 + borderTValueH, t)),
            )
            const borderAreaPoints = borderAreaGridPointsTop
                .concat(borderAreaGridPointsRight)
                .concat(borderAreaGridPointsBottom)
                .concat(borderAreaGridPointsLeft)
            this._borderArea.setAreaPoints(borderAreaPoints)
        }
    }

    private _spatialMapping: SpatialMappingItem
    private _gridHelperLines: GridHelperLinesItem
    private _borderArea: AreaViz
    private _tileArea: AreaViz
    private _synchronizeDisplay = new Subject<void>()
}

const AREA_ALPHA = 0.25
