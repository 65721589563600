<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> Config Group </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="$node()" [key]="'id'" [label]="'ID'" [validate]="isValidExternalId"></cm-value-slot>
        <cm-value-slot [node]="$node()" [key]="'name'" [label]="'Name'"></cm-value-slot>
        <cm-value-slot [node]="$node()" [key]="'displayWithLabels'" [label]="'Display with labels'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>
