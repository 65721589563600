<cm-material-inspector [label]="$label()" [node]="$material()" [highlighted]="$highlighted()" (requestUpdateMaterial)="onMaterialUpdated($event)">
    <ng-container cm-label> <ng-content select="[cm-label]"></ng-content> </ng-container>
    <ng-container cm-top-right>
        @if ($node(); as node) {
            <div matTooltip="Move material" (click)="$event.stopPropagation()" [matMenuTriggerFor]="meshUVMenu">
                <i class="far fa-arrows"></i>
                <!-- hasBackdrop needs to be false in order for node drag and drop to work! -->
                <mat-menu [hasBackdrop]="false" #meshUVMenu>
                    <div (click)="$event.stopPropagation()" class="cm-overlay-settings">
                        <cm-inspector-section>
                            <ng-container cm-title> Material Settings </ng-container>
                            <ng-container cm-content>
                                <cm-value-slot
                                    [node]="node"
                                    [key]="'horizontalOffset'"
                                    [label]="'Horizontal'"
                                    [delta]="0.03"
                                    [numberModifier]="'arrows'"
                                    [throttledUpdate]="true"
                                ></cm-value-slot>
                                <cm-value-slot
                                    [node]="node"
                                    [key]="'verticalOffset'"
                                    [label]="'Vertical'"
                                    [delta]="0.03"
                                    [numberModifier]="'arrows'"
                                    [throttledUpdate]="true"
                                ></cm-value-slot>
                                <cm-value-slot
                                    [node]="node"
                                    [key]="'rotation'"
                                    [label]="'Rotation'"
                                    [delta]="0.03"
                                    [numberModifier]="'arrows'"
                                    [throttledUpdate]="true"
                                ></cm-value-slot>
                            </ng-container>
                        </cm-inspector-section>
                    </div>
                </mat-menu>
            </div>
        }
    </ng-container>
    <ng-container cm-bottom-right>
        @if ($node(); as node) {
            <div matTooltip="Side: {{ $sideLabel() }}" (click)="$event.stopPropagation()" [matMenuTriggerFor]="meshSideMenu">
                <mat-menu #meshSideMenu>
                    <div (click)="$event.stopPropagation()" class="cm-overlay-settings">
                        <cm-inspector-section>
                            <ng-container cm-title> Material Settings </ng-container>
                            <ng-container cm-content>
                                <cm-value-slot [node]="node" [key]="'side'" [label]="'Side'" [selectionPossibilities]="sidePossibilities"></cm-value-slot>
                            </ng-container>
                        </cm-inspector-section>
                    </div>
                </mat-menu>
                <i class="far fa-bring-forward"></i>
            </div>
        }
    </ng-container>
</cm-material-inspector>
