import {SmartPtr} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/smart-ptr"
import {RefCountable} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/ref-countable"

export class SmartPtrArray<T extends RefCountable> extends Array<SmartPtr<T>> {
    constructor(items: SmartPtr<T>[])
    constructor(...items: SmartPtr<T>[])

    constructor(...items: SmartPtr<T>[] | SmartPtr<T>[][]) {
        super(...SmartPtrArray.resolveItems(items).map((item) => new SmartPtr(item)))
    }

    private static resolveItems(items: unknown[]) {
        if (Array.isArray(items[0])) {
            return items[0]
        } else {
            return items
        }
    }

    [Symbol.dispose] = () => this.release()

    release() {
        this.forEach((image) => image.release())
    }

    override push(...items: SmartPtr<T>[]) {
        return super.push(...items.map((item) => new SmartPtr(item)))
    }

    override splice(start: number, deleteCount: number, ...items: SmartPtr<T>[]) {
        return super.splice(start, deleteCount, ...items.map((item) => new SmartPtr(item)))
    }

    override unshift(...items: SmartPtr<T>[]) {
        return super.unshift(...items.map((item) => new SmartPtr(item)))
    }

    override shift() {
        this[0].release()
        return super.shift()
    }

    override pop() {
        this[this.length - 1].release()
        return super.pop()
    }
}

export const isSmartPtrArray = <T extends RefCountable>(value: unknown): value is SmartPtrArray<T> => {
    return value instanceof SmartPtrArray
}
