import {CmLogger} from "#utils/log"
import {WebSocketImplementation} from "#utils/websocket/types"
import {WebSocketMessagingConnection} from "#utils/websocket/websocket-messaging"
import {firstValueFrom} from "rxjs"

export class RemoteMessagingConnection<RuntimeImplementation extends WebSocketImplementation> {
    close$: Promise<void>
    responsePending = false

    constructor(
        private webSocketConnection: WebSocketMessagingConnection<RuntimeImplementation>,
        private remoteName: string,
        protected logger: CmLogger,
    ) {
        this.close$ = firstValueFrom(webSocketConnection.close$)
        webSocketConnection.message$.subscribe((msg) => logger.log("Message outside transaction:", msg))
    }

    disconnect() {
        this.webSocketConnection.destroy()
    }

    doTransaction(msg: any): Promise<any> {
        this.responsePending = true
        return firstValueFrom(this.webSocketConnection.doTransaction(msg)).then((resp: any) => {
            this.responsePending = false
            if (resp?.response === "ok") {
                return resp
            } else {
                const msg = `${this.remoteName} error:` + resp?.message
                this.logger.error(msg)
                throw new Error(msg)
            }
        })
    }
}
