import {RefCountable} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/ref-countable"

export class SmartPtr<T extends RefCountable> {
    constructor(ref: T | SmartPtr<T> | null = null) {
        this.setInternal(ref, true)
    }

    [Symbol.dispose] = () => this.release()

    get isAssigned(): boolean {
        return !!this._ref
    }

    get ref(): T {
        if (!this._ref) {
            throw new Error("ImageRefSmartPtr.imageRef: already released")
        }
        return this._ref
    }

    release() {
        if (!this._ref) {
            throw new Error("ImageRefSmartPtr.imageRef: already released")
        }
        this._ref.release()
        this._ref = null
    }

    protected setInternal(ref: T | SmartPtr<T> | null, addRefRawPtr: boolean) {
        const prevRef = this._ref
        if (ref instanceof SmartPtr) {
            this._ref = ref._ref
            this._ref?.addRef()
        } else {
            this._ref = ref
            if (addRefRawPtr) {
                this._ref?.addRef()
            }
        }
        prevRef?.release()
    }

    private _ref: T | null = null
}

export const isSmartPtr = <T extends RefCountable>(value: unknown): value is SmartPtr<T> => {
    return value instanceof SmartPtr && value.isAssigned
}

export class SmartPtrReassignable<T extends RefCountable> extends SmartPtr<T> {
    set(ref: SmartPtr<T> | null) {
        this.setInternal(ref, true)
    }

    static promoteSmartPtr<T extends RefCountable>(ref: SmartPtr<T>): SmartPtrReassignable<T> {
        const reassignable = new SmartPtrReassignable(ref)
        ref.release()
        return reassignable
    }
}
