import {Component, computed, inject, input, output, viewChild} from "@angular/core"
import {toObservable, toSignal} from "@angular/core/rxjs-interop"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MaterialFilterInput} from "@generated"
import {OrganizationsService} from "@app/common/services/organizations/organizations.service"
import {SelectMaterialComponent} from "@app/platform/components/materials/select-material/select-material.component"
import {TemplateNodeDragService} from "@app/template-editor/services/template-node-drag.service"
import {MaterialLike, MaterialReference} from "@cm/template-nodes"
import {CardComponent} from "@common/components/cards/card/card.component"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {ThumbnailComponent} from "@common/components/thumbnail/thumbnail.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {Settings} from "@common/models/settings/settings"
import {DialogService} from "@common/services/dialog/dialog.service"
import {BaseInspectorComponent} from "@template-editor/components/inspectors/base-inspector/base-inspector.component"
import {GetMaterialDetailsForMaterialInspectorGQL} from "@template-editor/components/inspectors/material-inspector/material-inspector.generated"
import {TemplateNodeComponent} from "@template-editor/components/template-node/template-node.component"
import {GetMaterialDetailsForTemplateTreeAddGQL} from "@template-editor/components/template-tree-add/template-tree-add.generated"
import {from, of, switchMap} from "rxjs"

@Component({
    selector: "cm-material-inspector",
    templateUrl: "./material-inspector.component.html",
    styleUrl: "./material-inspector.component.scss",
    imports: [CardComponent, MatMenuModule, MatTooltipModule, TemplateNodeComponent, ThumbnailComponent, DataObjectThumbnailComponent],
})
export class MaterialInspectorComponent extends BaseInspectorComponent<MaterialLike | null> {
    readonly $label = input<string | undefined>(undefined, {alias: "label"})
    readonly $labelText = computed(() => {
        const label = this.$label()
        if (label && label.length > 0) return `${label}: `
        return "Material: "
    })
    readonly $highlighted = input(false, {alias: "highlighted"})
    requestUpdateMaterial = output<MaterialReference | null>()
    private readonly $dragImage = viewChild.required<TemplateNodeComponent>("dragImage")

    private readonly materialGql = inject(GetMaterialDetailsForMaterialInspectorGQL)
    private readonly materialDetailsGql = inject(GetMaterialDetailsForTemplateTreeAddGQL)

    readonly dialogService = inject(DialogService)
    readonly organizations = inject(OrganizationsService)
    readonly drag = inject(TemplateNodeDragService)

    private readonly $thumbnailData = toSignal(
        toObservable(this.$node).pipe(
            switchMap((material) => {
                if (!material) return of(Settings.NO_MATERIAL_URL)
                if (material instanceof MaterialReference)
                    return from(fetchThrowingErrors(this.materialGql)({legacyId: material.parameters.materialRevisionId}))
                else return of(Settings.SWITCH_MATERIAL_URL)
            }),
        ),
        {
            initialValue: Settings.IMAGE_NOT_AVAILABLE_SMALL_URL,
        },
    )
    readonly $thumbnailUrl = computed(() => {
        const thumbnailData = this.$thumbnailData()
        if (typeof thumbnailData === "string") return thumbnailData
        else return undefined
    })
    readonly $thumbnailObject = computed(() => {
        const thumbnailData = this.$thumbnailData()
        if (typeof thumbnailData === "string") return undefined
        return thumbnailData.materialRevision.material.galleryImage
    })
    readonly $isReference = computed(() => {
        const node = this.$node()
        if (!node) return false
        const material = this.$node()
        if (!material) return false
        return !(material.parents.size === 1 && material.parents.has(node))
    })

    materialFilters: MaterialFilterInput = {hasCyclesMaterial: true}

    removeMaterial() {
        this.requestUpdateMaterial.emit(null)
    }

    openSelectMaterialDialog() {
        this.dialogService.selectInDialog(SelectMaterialComponent, {
            filters: this.materialFilters,
            onSelect: async ({id}: {id: string}) => {
                const {material} = await fetchThrowingErrors(this.materialDetailsGql)({id: id})
                const {latestCyclesRevision} = material

                if (latestCyclesRevision && latestCyclesRevision.legacyId !== undefined) {
                    const materialReference = new MaterialReference({
                        name: material.name ?? "New Material Reference",
                        materialRevisionId: latestCyclesRevision.legacyId,
                    })
                    this.requestUpdateMaterial.emit(materialReference)
                }
            },
        })
    }

    gotoReference() {
        if (!this.$isReference()) return

        const material = this.$node()
        if (!material) return

        this.sceneManagerService.selectNode({templateNode: material, part: "root"})
    }

    dragStart(event: DragEvent) {
        const material = this.$node()
        if (!material) {
            event.preventDefault()
            return
        }

        event.dataTransfer?.setDragImage(this.$dragImage().getDragImage(), 0, 0)

        this.drag.dragStart(event, material)
    }
}
