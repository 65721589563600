import * as Types from '../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PricingServiceGetTemplateQueryVariables = Types.Exact<{
  templateId: Types.Scalars['ID']['input'];
}>;


export type PricingServiceGetTemplateQuery = { __typename: 'Query', template: { __typename: 'Template', id: string, organizationId: string } };

export const PricingServiceGetTemplateDocument = gql`
    query pricingServiceGetTemplate($templateId: ID!) {
  template(id: $templateId) {
    id
    organizationId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PricingServiceGetTemplateGQL extends Apollo.Query<PricingServiceGetTemplateQuery, PricingServiceGetTemplateQueryVariables> {
    override document = PricingServiceGetTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }