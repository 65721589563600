import {computed, DestroyRef, effect, inject, Injectable, Injector, OnDestroy, Signal, signal, untracked} from "@angular/core"
import {takeUntilDestroyed, toObservable, toSignal} from "@angular/core/rxjs-interop"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {DialogComponent} from "@app/common/components/dialogs/dialog/dialog.component"
import {encodeConfiguratorURLParams} from "@app/common/components/viewers/configurator/helpers/parameters"
import {isMobileDevice} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {TransientDataObject} from "@app/common/helpers/transient-data-object/transient-data-object"
import {EndpointUrls} from "@app/common/models/constants/urls"
import {MaterialGraphService} from "@app/common/services/material-graph/material-graph.service"
import {DIALOG_DEFAULT_WIDTH} from "@app/template-editor/helpers/constants"
import {MeshDataCache} from "@app/template-editor/helpers/mesh-data-cache"
import {cancelDeferredTask, queueDeferredTask} from "@cm/browser-utils"
import {Matrix4} from "@cm/math"
import {
    AnyJSONValue,
    AreaLight,
    BooleanInfo,
    Camera,
    CompileTemplate,
    ConfigGroup,
    ConfigInfo,
    ConfigVariant,
    deleteNodesFromTemplateGraph,
    getInterfaceIdPrefix,
    getNodeOwner,
    getReferencingDeletedTemplateNodes,
    getTemplateNodeLabel,
    getUniqueTemplateNodeParent,
    GraphBuilder,
    GraphScheduler,
    ImageColorSpace,
    ImageInfo,
    InterfaceDescriptor,
    ISceneManager,
    isOutput,
    isTemplateContainer,
    JSONInfo,
    MaterialAssignment,
    MaterialAssignments,
    MaterialInfo,
    MaterialReference,
    ReifiedMeshData,
    Node,
    NodeEvaluator,
    Nodes,
    NotReady,
    NumberInfo,
    Object,
    ObjectId,
    ObjectInfo,
    Parameters,
    RunTemplate,
    SceneNodes,
    SceneProperties,
    Solver,
    SolverState,
    StoredMesh,
    StringInfo,
    TemplateContext,
    TemplateGraph,
    TemplateGraphDifferences,
    TemplateGraphSnapshot,
    TemplateInfo,
    TemplateInstance,
    TemplateParameterValue,
    TransformAccessor,
} from "@cm/template-nodes"
import {SceneManagerTask} from "@cm/template-nodes/interfaces/scene-manager"
import {MeshCurve} from "@cm/template-nodes/nodes/mesh-curve"
import {TemplateNode} from "@cm/template-nodes/template-node"
import {LodType} from "@cm/template-nodes/types"
import {CompactUIDTable} from "@cm/utils"
import {extensionForContentType} from "@cm/utils/content-types"
import {MediaTypeSchema} from "@cm/utils/data-object"
import {IdDetails} from "@cm/utils/id-details"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {WebAssemblyWorkerService} from "@app/template-editor/helpers/mesh-processing"
import {UtilsService} from "@legacy/helpers/utils"
import {TemplateRevisionGraphCache} from "@template-editor/helpers/template-revision-graph-cache"
import {GetHdriDetailsForSceneManagerServiceGQL, GetMaterialsDetailsForSceneManagerServiceGQL} from "@template-editor/services/scene-manager.service.generated"
import {catchError, delay, filter, finalize, firstValueFrom, map, Subject, Subscription, switchMap, take, tap, timer} from "rxjs"
import {z} from "zod"
import {DataObjectCache} from "../helpers/data-object-cache"
import {DecalMeshDataCache} from "../helpers/decal-mesh-data-cache"
import {MaterialGraphCache} from "../helpers/material-graph-cache"
import {MeshNodes, DataNodes} from "@cm/render-nodes"
import {ConnectionSolver} from "@app/template-editor/helpers/connection-solver/connection-solver"
import {IDataObject} from "@cm/material-nodes/interfaces/data-object"
import {CurveInfo, MeshInfo, NodesInfo} from "@cm/template-nodes/interface-descriptors"

const getEmtpyTemplateGraph = () => new TemplateGraph({name: "Empty Graph", nodes: new Nodes({list: []})})
const instantiateTemplateGraph = (templateGraph: TemplateGraph, parameters: Parameters, lockedTransform: Matrix4) =>
    new TemplateInstance({
        name: "Root Template",
        id: "root",
        template: templateGraph,
        parameters,
        lockedTransform: lockedTransform.toArray(),
        visible: true,
    })

export type TemplateNodePart = {
    templateNode: TemplateNode
    part: "root" | "target" | `group${number}` | `controlPoint${number}`
}

export const isGroupPart = (part: string): part is `group${number}` => part.match(/group\d+/) !== null
export const getGroupPartNumber = (part: `group${number}`): number => {
    const match = part.match(/group(\d+)$/)

    if (match && match.length > 1) {
        const slot = match[1]
        return parseInt(slot)
    }

    throw new Error(`Invalid group part: ${part}`)
}

export const isControlPointPart = (part: string): part is `controlPoint${number}` => part.match(/controlPoint\d+/) !== null

export const getControlPointPartNumber = (part: `controlPoint${number}`): number => {
    const match = part.match(/controlPoint(\d+)$/)

    if (match && match.length > 1) {
        const slot = match[1]
        return parseInt(slot)
    }

    throw new Error(`Invalid control point part: ${part}`)
}

export type SceneNodePart = {
    sceneNode: SceneNodes.SceneNode
    part: TemplateNodePart["part"]
}

export enum TransformMode {
    Translate = "translate",
    Rotate = "rotate",
    Scale = "scale",
}

export type Intersection = {
    distance: number
    point: {x: number; y: number; z: number}
    faceIndex?: number | undefined
    uv?: {x: number; y: number} | undefined
    uv1?: {x: number; y: number} | undefined
    normal?: {x: number; y: number; z: number}
}

export type SceneNodePartIntersection = {sceneNodePart: SceneNodePart; intersection?: Intersection}

export type SceneNodePartClickEvent = {
    target: SceneNodePartIntersection[]
    modifiers: {shiftKey: boolean; ctrlKey: boolean}
}

const uvWireframeTestMaterial = new MaterialReference({name: "Pebble gray", materialRevisionId: 2390})

const uv1TestMaterialUv0 = new MaterialReference({name: "UV Test Pattern - Checker map B", materialRevisionId: 50260})
const uv1TestMaterialUv1 = new MaterialReference({name: "UV Test Pattern - Checker map B", materialRevisionId: 55414})
const uv1TestMaterialUv2 = new MaterialReference({name: "UV Test Pattern - Checker map B", materialRevisionId: 55415})

const uv2TestMaterialUv0 = new MaterialReference({name: "UV Test Pattern - Checker map Grain", materialRevisionId: 50261})
const uv2TestMaterialUv1 = new MaterialReference({name: "UV Test Pattern - Checker map Grain", materialRevisionId: 55416})
const uv2TestMaterialUv2 = new MaterialReference({name: "UV Test Pattern - Checker map Grain", materialRevisionId: 55417})

@Injectable()
export class SceneManagerService implements OnDestroy {
    //Inputs
    $defaultCustomerId = signal<number | undefined>(undefined)
    $templateRevisionId = signal<string | undefined>(undefined)
    $transformMode = signal<TransformMode>(TransformMode.Translate)
    $exposeClaimedSubTemplateInputs = signal(true)
    $templateGraph = signal(getEmtpyTemplateGraph())
    $instanceParameters = signal(new Parameters({}))
    $instanceTransform = signal(Matrix4.identity())
    $lodType = signal<LodType>("web")
    $reviewMode = signal<"wireframe" | "uvTest1" | "uvTest2" | undefined>(undefined)
    $reviewFocus = signal<SceneNodes.WireframeMesh["channel"]>("faces")
    $showDimensionGuides = signal(false)

    //Undo / Redo related
    $templateGraphChangeReference = signal<TemplateGraphSnapshot | undefined>(undefined)
    $currentlyModifyingScene = computed(() => this.$templateGraphChangeReference() !== undefined)
    finishedModifyingScene$ = toObservable(this.$templateGraphChangeReference).pipe(
        filter((x) => x === undefined),
        map(() => {
            return
        }),
    )
    private $undoHistory = signal<TemplateGraphDifferences[]>([])
    private $redoHistory = signal<TemplateGraphDifferences[]>([])
    $canUndo = computed(() => this.$undoHistory().length > 0 && this.$templateGraphChangeReference() === undefined)
    $canRedo = computed(() => this.$redoHistory().length > 0 && this.$templateGraphChangeReference() === undefined)

    //Current configuration
    $currentLocalConfiguration = computed(() => this.getCurrentConfiguration(false))
    $currentGlobalConfiguration = computed(() => this.getCurrentConfiguration(true))

    //Events
    private _templateTreeChanged$ = new Subject<TemplateGraphDifferences>()
    public templateTreeChanged$ = this._templateTreeChanged$.asObservable()
    private _templateSwapped$ = new Subject<void>()
    public templateSwapped$ = this._templateSwapped$.asObservable()

    //Modified state
    private $updatedSerializedTemplateGraphSignal = signal(0)
    synchronizeSerializedTemplateGraph() {
        this.$updatedSerializedTemplateGraphSignal.update((x) => x + 1)
    }
    private $serializedTemplateGraphHash = computed(() => {
        this.$updatedSerializedTemplateGraphSignal()
        const templateGraph = this.$templateGraph()
        return templateGraph.getHash()
    })
    private $templateTreeChangedSignal = toSignal(this.templateTreeChanged$)
    private $currentTemplateGraphHash = computed(() => {
        this.$templateTreeChangedSignal()
        const templateGraph = this.$templateGraph()
        return templateGraph.getHash()
    })
    $templateGraphModified = computed(() => this.$serializedTemplateGraphHash() !== this.$currentTemplateGraphHash())

    private _clickedSceneNodePart$ = new Subject<SceneNodePartClickEvent>()
    private clickedSceneNodePart$ = this._clickedSceneNodePart$.asObservable()
    watchForClickedSceneNodePart() {
        if (this.watchingForClickedSceneNodePart()) throw new Error("Already watching for clicked scene node part")

        return this.clickedSceneNodePart$.pipe(take(1))
    }
    watchingForClickedSceneNodePart() {
        return this._clickedSceneNodePart$.observed
    }

    $templateInstance = computed(() => instantiateTemplateGraph(this.$templateGraph(), this.$instanceParameters(), this.$instanceTransform()))

    private $preDisplayList = signal<SceneNodes.SceneNode[]>([])
    private $displayList = signal<SceneNodes.SceneNode[]>([])
    private $descriptorList = signal<InterfaceDescriptor<unknown, object>[]>([])
    descriptorList$ = toObservable(this.$descriptorList)
    private $_ready = signal<boolean>(false)
    public $ready = this.$_ready.asReadonly()
    ready$ = toObservable(this.$_ready)
    templateRevisionId$ = toObservable(this.$templateRevisionId)
    defaultCustomerId$ = toObservable(this.$defaultCustomerId)
    templateGraph$ = toObservable(this.$templateGraph)

    $scene = computed(() => [...this.$preDisplayList(), ...this.$displayList()])

    $cameras = computed(() => this.$scene().filter(SceneNodes.Camera.is))

    $reviewedUvChannel = computed(() => {
        const reviewFocus = this.$reviewFocus()
        if (reviewFocus === "faces") return "uv0"
        else return reviewFocus
    })

    private $_selectedNodeParts = signal<TemplateNodePart[]>([])
    public $selectedNodeParts = this.$_selectedNodeParts.asReadonly()
    public selectedNodeParts$ = toObservable(this.$selectedNodeParts)
    $selectedSceneNodes = computed(() => {
        return this.$selectedNodeParts()
            .map((selectedNodePart) => this.getSceneNodeParts(selectedNodePart))
            .flat()
    })

    $hoveredNodePart = signal<TemplateNodePart | undefined>(undefined)
    hoveredNodePart$ = toObservable(this.$hoveredNodePart)
    $hoveredSceneNodes = computed(() => {
        const hoveredNode = this.$hoveredNodePart()
        return hoveredNode ? this.getSceneNodeParts(hoveredNode) : []
    })

    private $_highlightedNodes = signal<TemplateNode[]>([])
    $highlightedNodes = this.$_highlightedNodes.asReadonly()

    private $_criticalTasks = signal<SceneManagerTask[]>([])
    criticalTasks$ = toObservable(this.$_criticalTasks)
    private $_optionalTasks = signal<SceneManagerTask[]>([])
    optionalTasks$ = toObservable(this.$_optionalTasks)
    $numCriticalTasks = computed(() => this.$_criticalTasks().length)
    $numPendingTasks = computed(() => this.$numCriticalTasks() + this.$_optionalTasks().length)

    private $activeNodeSet = signal(new Set<TemplateNode>())
    private $nodeToObjectMap = signal(new Map<TemplateNode, ObjectId>())
    private $objectToNodeMap = signal(new Map<ObjectId, TemplateNode>())
    private $solverUpdateAll = signal<(postStep: boolean) => void>(() => {})
    private $transformAccessorMap = signal<Map<ObjectId, TransformAccessor>>(new Map())

    private readonly utilsService = inject(UtilsService)
    readonly workerService = inject(WebAssemblyWorkerService)
    private readonly materialGraphService = inject(MaterialGraphService)
    private readonly destroyRef = inject(DestroyRef)
    private readonly dialog = inject(MatDialog)
    private sceneManager: SceneManager
    private scheduler = new GraphScheduler(undefined, {cancelDeferredTask, queueDeferredTask})
    private builder = new GraphBuilder("root", this.scheduler)
    readonly injector = inject(Injector)
    readonly getHDRIDetailsForSceneManagerService = inject(GetHdriDetailsForSceneManagerServiceGQL)

    $configuratorUrlParams = computed(() => {
        return encodeConfiguratorURLParams(this.$descriptorList(), this.injector)
    })

    $verticalArPlacement = computed(() => {
        const sceneProperties = this.$templateGraph().parameters.nodes.parameters.list.filter((x): x is SceneProperties => x instanceof SceneProperties)
        if (sceneProperties.length === 0) return false
        return sceneProperties[0].parameters.verticalArPlacement
    })

    private requestedRecompile = new Subject<void>()

    constructor() {
        effect(() => this.compileTemplate())

        effect(() => {
            this.$scene()

            const solver = this.sceneManager.getConnectionSolver()
            if (solver.checkReady()) this.updateAllMeshPositions(solver)
            else console.warn("Solver not ready")
        })

        effect(() => {
            this.$templateGraph()
            untracked(() => this._templateSwapped$.next())
        })

        this.templateSwapped$.pipe(takeUntilDestroyed(this.destroyRef), delay(0)).subscribe(() => {
            this.$templateGraphChangeReference.set(undefined)
            this.$undoHistory.set([])
            this.$redoHistory.set([])

            this.$_selectedNodeParts.set([])
            this.$hoveredNodePart.set(undefined)
            this.$_highlightedNodes.set([])
        })

        this.requestedRecompile
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap(() => timer(100)),
            )
            .subscribe(() => this.compileTemplate())

        this.sceneManager = new SceneManager(this, this.injector, this.workerService, this.materialGraphService, this.$templateInstance)

        this.workerService.startInitialWorkers()
    }

    getObjectId(node: TemplateNode) {
        return this.$nodeToObjectMap().get(node)
    }

    getSceneNodeParts(nodePart: TemplateNodePart): SceneNodePart[] {
        const {templateNode, part} = nodePart
        const objectId = this.getObjectId(templateNode)
        if (!objectId) return []

        const scene = this.$scene()
        return scene
            .filter((sceneNode) => {
                return sceneNode.topLevelObjectId === objectId
            })
            .map((sceneNode) => ({sceneNode, part}))
    }

    compileTemplate(): void {
        const templateInstance = this.$templateInstance()

        //console.log("Template instance changed", templateInstance)

        const scope = this.builder.scope()

        const templateMatrix = this.$instanceTransform()

        const templateContext: TemplateContext = {
            sceneManager: this.sceneManager,
            templateMatrix,
            lodType: this.$lodType(),
            defaultCustomerId: this.$defaultCustomerId(),
            showDimensions: this.$showDimensionGuides(),
        }

        const evaluator = new NodeEvaluator(new CompactUIDTable<string>(), scope, templateContext, {}, undefined)
        const [graphValid, graphInvalid] = scope.branch(evaluator.evaluateTemplate(scope, templateInstance.parameters.template))
        const graph = scope.phi(scope.get(graphValid, "graph"), graphInvalid)

        const [inputs] = evaluator.evaluateTemplateInputs(scope, templateInstance)

        const overrideMaterial = (() => {
            switch (this.$reviewMode()) {
                case "wireframe":
                    return uvWireframeTestMaterial
                case "uvTest1":
                    switch (this.$reviewedUvChannel()) {
                        case "uv0":
                            return uv1TestMaterialUv0
                        case "uv1":
                            return uv1TestMaterialUv1
                        case "uv2":
                            return uv1TestMaterialUv2
                    }
                case "uvTest2":
                    switch (this.$reviewedUvChannel()) {
                        case "uv0":
                            return uv2TestMaterialUv0
                        case "uv1":
                            return uv2TestMaterialUv1
                        case "uv2":
                            return uv2TestMaterialUv2
                    }
                default:
                    return undefined
            }
        })()

        const compileTemplate = scope.node(CompileTemplate, {
            graph,
            sceneProperties: null,
            render: null,
            inputs,
            templateContext,
            topLevelObjectId: null,
            templateDepth: 0,
            exposeClaimedSubTemplateInputs: this.$exposeClaimedSubTemplateInputs(),
            overrideMaterial: () => overrideMaterial,
        })

        const runTemplate = scope.node(RunTemplate, {
            compiledTemplate: compileTemplate.compiledTemplate,
        })

        const solver = scope.node(Solver, {
            state: scope.state(SolverState, "solverState"),
            sceneManager: this.sceneManager,
            data: runTemplate.solverData,
        })

        scope.tap(compileTemplate.activeNodeSet, (activeNodeSet) => {
            if (activeNodeSet !== NotReady) this.$activeNodeSet.set(activeNodeSet)
        })

        scope.tap(compileTemplate.nodeToObjectMap, (nodeToObjectMap) => {
            if (nodeToObjectMap !== NotReady) this.$nodeToObjectMap.set(nodeToObjectMap)
        })

        scope.tap(compileTemplate.objectToNodeMap, (objectToNodeMap) => {
            if (objectToNodeMap !== NotReady) this.$objectToNodeMap.set(objectToNodeMap)
        })

        scope.tap(runTemplate.preDisplayList, (preDisplayList) => {
            if (preDisplayList !== NotReady) this.$preDisplayList.set(preDisplayList)
        })

        scope.tap(runTemplate.displayList, (displayList) => {
            if (displayList !== NotReady) this.$displayList.set(displayList)
        })

        scope.tap(runTemplate.descriptorList, (descriptorList) => {
            if (descriptorList !== NotReady) this.$descriptorList.set(descriptorList)
        })

        scope.tap(runTemplate.ready, (ready) => {
            if (ready !== NotReady) this.$_ready.set(ready)
            else this.$_ready.set(false)
        })

        scope.tap(runTemplate.transformAccessorList, (transformAccessorList) => {
            if (transformAccessorList !== NotReady) this.$transformAccessorMap.set(new Map(transformAccessorList.map((x) => [x.objectId, x.transformAccessor])))
        })

        scope.tap(solver.updateAll, (updateAll) => {
            if (updateAll !== NotReady) this.$solverUpdateAll.set(updateAll)
        })

        this.builder.finalizeChanges()
    }

    ngOnDestroy(): void {
        this.sceneManager.destroy()
    }

    requiresSync(waitForOptionalTasks: boolean) {
        return (
            this.scheduler.hasPendingUpdates ||
            this.$_criticalTasks().length > 0 ||
            (waitForOptionalTasks && this.$_optionalTasks().length > 0) ||
            !this.$ready()
        )
    }

    async sync(waitForOptionalTasks: boolean): Promise<void> {
        if (this.scheduler.hasPendingUpdates) {
            await firstValueFrom(this.scheduler.sync())
            return this.sync(waitForOptionalTasks)
        } else if (this.$_criticalTasks().length > 0) {
            await firstValueFrom(
                this.criticalTasks$.pipe(
                    filter((criticalTasks) => criticalTasks.length === 0),
                    delay(0), //This is essential, otherwise the signal will not have been updated after the await
                    take(1),
                ),
            )
            return this.sync(waitForOptionalTasks)
        } else if (waitForOptionalTasks && this.$_optionalTasks().length > 0) {
            await firstValueFrom(
                this.optionalTasks$.pipe(
                    filter((optionalTasks) => optionalTasks.length === 0),
                    delay(0), //This is essential, otherwise the signal will not have been updated after the await
                    take(1),
                ),
            )
            return this.sync(waitForOptionalTasks)
        } else if (!this.$ready()) {
            await firstValueFrom(
                this.ready$.pipe(
                    filter((ready) => ready === true),
                    take(1),
                ),
            )
            return this.sync(waitForOptionalTasks)
        }
    }

    sceneNodePartToTemplateNodePart(sceneNodePart: SceneNodePart): TemplateNodePart | undefined {
        const templateNode = this.$objectToNodeMap().get(sceneNodePart.sceneNode.topLevelObjectId)
        if (templateNode) return {templateNode, part: sceneNodePart.part}
        else return undefined
    }

    selectNode(templateNodePart: TemplateNodePart | undefined) {
        const selectedNodeParts = this.$selectedNodeParts()
        if (!templateNodePart && selectedNodeParts.length === 0) return
        if (templateNodePart) {
            const {templateNode, part} = templateNodePart

            if (selectedNodeParts.length === 1 && selectedNodeParts[0].templateNode === templateNode && selectedNodeParts[0].part === part) {
                if ((templateNode instanceof Camera || templateNode instanceof AreaLight) && part === "root") {
                    this.$_selectedNodeParts.set([{templateNode, part: "target"}])
                    return
                } else return
            }
        }

        this.$_selectedNodeParts.set(templateNodePart ? [templateNodePart] : [])
        if (templateNodePart?.templateNode instanceof ConfigVariant) {
            const {templateNode: configVariant} = templateNodePart
            getNodeOwner(configVariant, (nodeOwner) => {
                if (nodeOwner instanceof ConfigGroup) {
                    const instanceParameters = this.$instanceParameters()
                    instanceParameters.replaceParameters({
                        ...instanceParameters.parameters,
                        [nodeOwner.parameters.id]: configVariant.parameters.id,
                    })
                    this.compileTemplate()
                }
            })
        }
    }

    setTemplateParameter(paramId: string, value: TemplateParameterValue) {
        const instanceParameters = this.$instanceParameters()

        instanceParameters.replaceParameters({
            ...instanceParameters.parameters,
            [paramId]: value,
        })
        this.compileTemplate()
    }

    addNodeToSelection(templateNodePart: TemplateNodePart) {
        const selectedNodeParts = this.$selectedNodeParts()
        if (templateNodePart.templateNode instanceof MeshCurve) {
            if (!selectedNodeParts.some((x) => x.templateNode === templateNodePart.templateNode && x.part === templateNodePart.part)) {
                this.$_selectedNodeParts.set([...selectedNodeParts, templateNodePart])
            }
        } else if (!selectedNodeParts.some((x) => x.templateNode === templateNodePart.templateNode)) {
            this.$_selectedNodeParts.set([...selectedNodeParts, templateNodePart])
        }
    }

    removeNodeFromSelection(templateNodePart: TemplateNodePart) {
        const selectedNodeParts = this.$selectedNodeParts()
        const selectedNodePartIdx = selectedNodeParts.findIndex((x) => x.templateNode === templateNodePart.templateNode && x.part === templateNodePart.part)
        if (selectedNodePartIdx >= 0) {
            selectedNodeParts.splice(selectedNodePartIdx, 1)
            this.$_selectedNodeParts.set([...selectedNodeParts])
        }
    }

    handleClickEvent(event: SceneNodePartClickEvent) {
        if (this.watchingForClickedSceneNodePart()) {
            this._clickedSceneNodePart$.next(event)
            return
        }

        const {target, modifiers} = event
        const {shiftKey, ctrlKey} = modifiers
        if (target.length > 0) {
            const {sceneNodePart} = target[0]
            const templateNodePart = this.sceneNodePartToTemplateNodePart(sceneNodePart)
            if (!templateNodePart) return
            if (shiftKey) this.addNodeToSelection(templateNodePart)
            else if (ctrlKey) this.removeNodeFromSelection(templateNodePart)
            else this.selectNode(templateNodePart)
        } else if (!shiftKey && !ctrlKey) this.selectNode(undefined)
    }

    hoverNode(templateNodePart: TemplateNodePart | undefined) {
        this.$hoveredNodePart.set(templateNodePart)
    }

    highlightNode(templateNode: TemplateNode, value: boolean) {
        const highlightedNodes = this.$highlightedNodes()

        if (value) {
            if (!highlightedNodes.includes(templateNode)) this.$_highlightedNodes.set([...highlightedNodes, templateNode])
        } else {
            const idx = highlightedNodes.indexOf(templateNode)
            if (idx >= 0) {
                highlightedNodes.splice(idx, 1)
                this.$_highlightedNodes.set([...highlightedNodes])
            }
        }
    }

    isHighlightedNode(templateNode: TemplateNode) {
        return this.$highlightedNodes().includes(templateNode)
    }

    addTask(sceneManagerTask: SceneManagerTask): Subscription {
        const startTime = Date.now()

        const {description, task, critical} = sceneManagerTask

        const tasksSignal = critical ? this.$_criticalTasks : this.$_optionalTasks

        tasksSignal.update((existingTasks) => [...existingTasks, sceneManagerTask])
        const taskOperator = task.pipe(
            tap(() => {
                const endTime = Date.now()
                const timeDiff = endTime - startTime
                console.log(`Task ${description} took ${timeDiff}ms`)
            }),
            catchError((error) => {
                const endTime = Date.now()
                const timeDiff = endTime - startTime
                console.error(`Task ${description} failed after ${timeDiff}ms`)
                throw error
            }),
            finalize(() => {
                tasksSignal.update((existingTasks) => {
                    return existingTasks.filter((x) => x !== sceneManagerTask)
                })
            }),
        )

        if (critical) {
            return taskOperator.subscribe()
        } else {
            return this.criticalTasks$
                .pipe(
                    filter((criticalTasks) => criticalTasks.length === 0),
                    take(1),
                    tap(() => console.log("Critical tasks completed, starting optional task", description)),
                    switchMap(() => taskOperator),
                )
                .subscribe()
        }
    }

    async getHdriAsBufferAndExtension(hdriIdDetails: IdDetails) {
        const {hdri} = await fetchThrowingErrors(this.getHDRIDetailsForSceneManagerService)(hdriIdDetails)

        const parsedDetails = z
            .object({dataObject: z.object({mediaType: MediaTypeSchema, bucketName: z.string(), objectName: z.string(), originalFileName: z.string()})})
            .safeParse(hdri)
        if (!parsedDetails.success) throw Error("Failed to parse hdri details")

        const {dataObject} = parsedDetails.data

        const extension = (() => {
            try {
                return extensionForContentType(dataObject.mediaType)
            } catch (e) {
                return dataObject.originalFileName.split(".").pop() ?? "hdr"
            }
        })()

        const arrayBuffer = await firstValueFrom(
            this.utilsService.getResourceAsBuffer(EndpointUrls.GoogleStorage(dataObject.bucketName, dataObject.objectName)),
        )
        return {buffer: new Uint8Array(arrayBuffer), extension}
    }

    isNodeActive(node: TemplateNode) {
        return this.$activeNodeSet().has(node)
    }

    private updateAllMeshPositions(solver: ConnectionSolver) {
        const solverUpdateAll = this.$solverUpdateAll()

        solverUpdateAll(false)
        while (solver.step()) {}
        solverUpdateAll(true)

        return true
    }

    getISceneManager() {
        return this.sceneManager
    }

    getTransformAccessor(node: TemplateNode) {
        const objectId = this.getObjectId(node)
        if (objectId) return this.$transformAccessorMap().get(objectId)
        return undefined
    }

    beginModifyTemplateGraph() {
        const templateGraphChangeReference = this.$templateGraphChangeReference()
        if (templateGraphChangeReference) return

        this.$templateGraphChangeReference.set(this.$templateGraph().getSnapshot())
    }

    endModifyTemplateGraph() {
        const templateGraphChangeReference = this.$templateGraphChangeReference()
        if (!templateGraphChangeReference) return

        const templateGraph = this.$templateGraph()
        const graphDifference = templateGraph.getDifferences(templateGraphChangeReference)
        this.notifyTemplateTreeChange(graphDifference)
        this.pushUndoHistory(graphDifference)

        this.$templateGraphChangeReference.set(undefined)
    }

    private pushUndoHistory(graphDifference: TemplateGraphDifferences) {
        const undoHistory = this.$undoHistory()
        undoHistory.push(graphDifference)
        if (undoHistory.length > 20) undoHistory.shift()
        this.$undoHistory.set([...undoHistory])
        this.$redoHistory.set([])
    }

    private notifyTemplateTreeChange(differences: TemplateGraphDifferences) {
        const {deletedNodes, modifiedNodes} = differences

        if (deletedNodes.size > 0) {
            const selectedNodeParts = this.$selectedNodeParts()
            const selectedNodePartsFiltered = selectedNodeParts.filter((x) => !deletedNodes.has(x.templateNode))
            if (selectedNodePartsFiltered.length !== selectedNodeParts.length) this.$_selectedNodeParts.set(selectedNodePartsFiltered)

            const hoveredNodePart = this.$hoveredNodePart()
            if (hoveredNodePart && deletedNodes.has(hoveredNodePart.templateNode)) this.$hoveredNodePart.set(undefined)

            const highlightedNodes = this.$highlightedNodes()
            const highlightedNodesFiltered = highlightedNodes.filter((x) => !deletedNodes.has(x))
            if (highlightedNodesFiltered.length !== highlightedNodes.length) this.$_highlightedNodes.set(highlightedNodesFiltered)
        }

        if (modifiedNodes.size > 0) {
            const selectedNodeParts = this.$selectedNodeParts()
            const selectionChanged = selectedNodeParts.find((x) => modifiedNodes.has(x.templateNode)) !== undefined
            if (selectionChanged) this.$_selectedNodeParts.set([...selectedNodeParts])

            const hoveredNodePart = this.$hoveredNodePart()
            if (hoveredNodePart && modifiedNodes.has(hoveredNodePart.templateNode))
                this.$hoveredNodePart.set({templateNode: hoveredNodePart.templateNode, part: hoveredNodePart.part})

            const highlightedNodes = this.$highlightedNodes()
            const highlightedNodesChanged = [...highlightedNodes].find((x) => modifiedNodes.has(x)) !== undefined
            if (highlightedNodesChanged) this.$_highlightedNodes.set([...highlightedNodes])
        }

        this._templateTreeChanged$.next(differences)
    }

    private modifyTemplateGraphImpl(modifier: (templateGraph: TemplateGraph) => void, undoable: boolean, compileImmediate: boolean) {
        const templateGraph = this.$templateGraph()
        const templateGraphChangeReference = this.$templateGraphChangeReference()

        if (!templateGraphChangeReference) {
            const graphDifference = templateGraph.trackDifferences(modifier)
            const {addedNodes, deletedNodes, modifiedNodes} = graphDifference
            if (addedNodes.size === 0 && deletedNodes.size === 0 && modifiedNodes.size === 0) return
            this.notifyTemplateTreeChange(graphDifference)
            if (undoable) this.pushUndoHistory(graphDifference)
        } else modifier(templateGraph)

        if (compileImmediate) this.compileTemplate()
        else this.requestedRecompile.next()
    }

    modifyTemplateGraph(modifier: (templateGraph: TemplateGraph) => void, compileImmediate = true) {
        this.modifyTemplateGraphImpl(modifier, true, compileImmediate)
    }

    undo = () => {
        if (!this.$canUndo()) return

        const undoHistory = this.$undoHistory()
        const redoHistory = this.$redoHistory()

        const graphDifference = undoHistory[undoHistory.length - 1]
        if (graphDifference) {
            this.modifyTemplateGraphImpl(() => graphDifference.applyBackward(), false, true)
            undoHistory.pop()

            redoHistory.push(graphDifference)
            this.$undoHistory.set([...undoHistory])
            this.$redoHistory.set([...redoHistory])
        }
    }

    redo = () => {
        if (!this.$canRedo()) return

        const undoHistory = this.$undoHistory()
        const redoHistory = this.$redoHistory()

        if (redoHistory.length === 0) return

        const graphDifference = redoHistory[redoHistory.length - 1]
        if (graphDifference) {
            this.modifyTemplateGraphImpl(() => graphDifference.applyForward(), false, true)
            redoHistory.pop()

            undoHistory.push(graphDifference)
            this.$undoHistory.set([...undoHistory])
            this.$redoHistory.set([...redoHistory])
        }
    }

    deleteNodes(templateNodeParts: TemplateNodePart[]) {
        const selectedControlPoints = templateNodeParts.filter(
            (
                templateNodePart,
            ): templateNodePart is {
                templateNode: MeshCurve
                part: `controlPoint${number}`
            } => templateNodePart.templateNode instanceof MeshCurve && isControlPointPart(templateNodePart.part),
        )
        const otherTemplateNodeParts = templateNodeParts.filter(
            (templateNodePart) => !selectedControlPoints.some((selectedControlPoint) => selectedControlPoint === templateNodePart),
        )

        const nodesToDelete = new Set([...otherTemplateNodeParts.map((x) => x.templateNode)])

        if (nodesToDelete.size === 0 && selectedControlPoints.length === 0) return

        const templateGraph = this.$templateGraph()

        if (nodesToDelete.has(templateGraph)) return

        const deleteNodes = () => {
            this.modifyTemplateGraph((templateGraph) => {
                const selectedControlPointsByTemplateNode = new Map<MeshCurve, Set<number>>()
                for (const selectedControlPoint of selectedControlPoints) {
                    const {templateNode, part} = selectedControlPoint
                    const index = getControlPointPartNumber(part)
                    const controlPoints = selectedControlPointsByTemplateNode.get(templateNode) ?? new Set()
                    controlPoints.add(index)
                    selectedControlPointsByTemplateNode.set(templateNode, controlPoints)
                }
                for (const [templateNode, controlPoints] of selectedControlPointsByTemplateNode)
                    templateNode.updateParameters({controlPoints: [...templateNode.parameters.controlPoints].filter((_, index) => !controlPoints.has(index))})

                deleteNodesFromTemplateGraph(templateGraph, nodesToDelete)
            })
        }

        const referencingNodes = getReferencingDeletedTemplateNodes(templateGraph, nodesToDelete)
        if (referencingNodes.size === 0) deleteNodes()
        else {
            const describeNode = (node: TemplateNode, isReference: boolean): string => {
                if (isTemplateContainer(node)) {
                    const containerParent = getUniqueTemplateNodeParent(node)
                    return getTemplateNodeLabel(containerParent)
                } else if (node instanceof MaterialAssignments) {
                    const parentMesh = getUniqueTemplateNodeParent(node)
                    return getTemplateNodeLabel(parentMesh)
                } else if (node instanceof MaterialAssignment) {
                    if (isReference) return getTemplateNodeLabel(node.parameters.node)
                    else return `${getTemplateNodeLabel(node)} of ${describeNode(getUniqueTemplateNodeParent(node), false)} `
                } else if (node instanceof Parameters) {
                    const parentMesh = getUniqueTemplateNodeParent(node)
                    return getTemplateNodeLabel(parentMesh)
                } else if (isOutput(node)) {
                    if (isReference) return getTemplateNodeLabel(node.parameters.template)
                    else return `${getTemplateNodeLabel(node)} of ${describeNode(getUniqueTemplateNodeParent(node), false)} `
                }

                return getTemplateNodeLabel(node)
            }

            const count = [...referencingNodes].reduce((acc, [, children]) => acc + children.size, 0)
            const description = [...referencingNodes].reduce((acc, [node, children]) => {
                const addNewLine = acc.length > 0 ? acc + "<br><br>" : acc
                return (
                    addNewLine +
                    "<strong>" +
                    describeNode(node, false) +
                    "</strong>" +
                    " references " +
                    [...new Set(children.values())].reduce((acc, child, index) => {
                        const addComma = acc.length > 0 ? (index === children.size - 1 ? acc + " and " : acc + ", ") : acc
                        return addComma + "<strong>" + describeNode(child, true) + "</strong>"
                    }, "")
                )
            }, "")

            const dialogRef: MatDialogRef<DialogComponent, boolean> = this.dialog.open(DialogComponent, {
                width: DIALOG_DEFAULT_WIDTH,
                data: {
                    title: "Delete Nodes",
                    message:
                        "There are <strong>" +
                        count.toString() +
                        "</strong> references to the nodes about to be deleted: <br><br>" +
                        description +
                        "<br><br>Those references will also be removed in the process. The nodes themselves will not be deleted.<br><br>Are you sure you want to proceed?",
                    confirmLabel: "Delete References",
                    cancelLabel: "Cancel",
                    isDestructive: true,
                },
            })
            dialogRef.afterClosed().subscribe((confirmed) => {
                if (!confirmed) return
                deleteNodes()
            })
        }
    }

    getDescriptorsForNode(node: TemplateInstance) {
        const descriptorList = this.$descriptorList()
        if (node === this.$templateInstance()) return descriptorList
        return descriptorList.filter((descriptor) => {
            const [prefix, _] = getInterfaceIdPrefix(descriptor.props.id)
            return prefix === node.parameters.id
        })
    }

    private getCurrentConfiguration(includeAllSubTemplateInputs: boolean) {
        const configuration: {[paramId: string]: AnyJSONValue | Node} = {}

        for (const descriptor of this.$descriptorList()) {
            if (descriptor.props.type !== "input") continue
            if (descriptor.props.isSetByTemplate && !includeAllSubTemplateInputs) continue

            const {id, name} = descriptor.props
            if (descriptor.props.value === null) continue

            if (descriptor instanceof ConfigInfo) configuration[id] = descriptor.props.value.id
            else if (
                descriptor instanceof ObjectInfo ||
                descriptor instanceof MeshInfo ||
                descriptor instanceof CurveInfo ||
                descriptor instanceof MaterialInfo ||
                descriptor instanceof TemplateInfo ||
                descriptor instanceof ImageInfo ||
                descriptor instanceof StringInfo ||
                descriptor instanceof NumberInfo ||
                descriptor instanceof BooleanInfo ||
                descriptor instanceof JSONInfo ||
                descriptor instanceof NodesInfo
            ) {
                continue
            } else throw new Error("Invalid descriptor type")
        }

        return new Parameters(configuration)
    }

    getDescriptors() {
        return this.$descriptorList()
    }
}

class SceneManager implements ISceneManager {
    private connectionSolver = new ConnectionSolver()
    private meshCache: MeshDataCache
    private decalMeshCache: DecalMeshDataCache
    private templateRevisionGraphCache: TemplateRevisionGraphCache
    private dataObjectCache: DataObjectCache
    private materialGraphCache: MaterialGraphCache

    readonly getMaterialsDetailsForSceneManagerService = inject(GetMaterialsDetailsForSceneManagerServiceGQL)

    constructor(
        private sceneManagerService: SceneManagerService,
        private injector: Injector,
        private workerService: WebAssemblyWorkerService,
        private materialGraphService: MaterialGraphService,
        private templateInstance: Signal<TemplateInstance>,
    ) {
        this.meshCache = new MeshDataCache(this.workerService)
        this.decalMeshCache = new DecalMeshDataCache(this.workerService)
        this.dataObjectCache = new DataObjectCache(this.injector)
        this.templateRevisionGraphCache = new TemplateRevisionGraphCache(this.injector)
        this.materialGraphCache = new MaterialGraphCache(this.materialGraphService)
    }

    destroy() {
        this.connectionSolver.destroy()
    }

    getConnectionSolver() {
        return this.connectionSolver
    }

    isMobileDevice() {
        return isMobileDevice
    }

    arMode() {
        return this.sceneManagerService.$lodType() === "ar"
    }

    defaultTransformForObject(node: Object, useLockedTransform: boolean = true): Matrix4 | undefined {
        if (useLockedTransform && node.parameters.lockedTransform) {
            return Matrix4.fromArray(node.parameters.lockedTransform)
        } else if (node instanceof StoredMesh) {
            const {metaData} = node.parameters
            const {defaultPosition} = metaData ?? {}
            if (defaultPosition) {
                const [x, y, z] = defaultPosition
                return Matrix4.translation(x, y, z)
            }
        }

        return undefined
    }

    getRootNode() {
        return this.templateInstance()
    }

    createTransientDataObject(data: Uint8Array, contentType: string, imageColorSpace: ImageColorSpace) {
        const parsedMediaType = MediaTypeSchema.safeParse(contentType)
        if (!parsedMediaType.success) throw Error(`Invalid media/content type: ${contentType}`)
        return new TransientDataObject({data, mediaType: parsedMediaType.data, imageColorSpace: imageColorSpace})
    }

    addTask(sceneManagerTask: SceneManagerTask): Subscription {
        return this.sceneManagerService.addTask(sceneManagerTask)
    }

    loadDataObject(dataObjectId: number) {
        return firstValueFrom(this.dataObjectCache.get(dataObjectId))
    }

    async getDataObjectData(dataObject: IDataObject): Promise<ArrayBuffer> {
        const resp = await fetch(dataObject.downloadUrl)
        return resp.arrayBuffer()
    }

    async findMaterial(articleId: string | null, customerId: number | null) {
        const {materials} = await fetchThrowingErrors(this.getMaterialsDetailsForSceneManagerService)({
            filter: {articleId: {equals: articleId ?? undefined}, organizationLegacyId: {equals: customerId ?? undefined}},
        })

        if (materials.length > 1) console.error(`Multiple materials found for articleId: ${articleId} and customerId: ${customerId}`)

        const latestCyclesRevision = materials[0]?.latestCyclesRevision
        if (!latestCyclesRevision) return null
        return firstValueFrom(this.materialGraphCache.get(latestCyclesRevision.legacyId))
    }

    loadMaterial(materialRevisionId: number) {
        return firstValueFrom(this.materialGraphCache.get(materialRevisionId))
    }

    evaluateMesh(graph: MeshNodes.Mesh, resources: DataNodes.EvaluationResources): Promise<ReifiedMeshData> {
        return firstValueFrom(this.meshCache.evaluateMesh(graph, resources))
    }

    getCachedMesh(graph: MeshNodes.Mesh): ReifiedMeshData | null {
        return this.meshCache.getIfCached(graph)
    }

    loadTemplateGraph(templateRevisionId: number) {
        return firstValueFrom(this.templateRevisionGraphCache.get(templateRevisionId))
    }

    clipAndOffsetMeshForDecal(mesh: ReifiedMeshData, uvCenter: [number, number], uvRotation: number, size: [number, number], offset: number) {
        return firstValueFrom(this.decalMeshCache.clipAndOffsetMeshForDecal(mesh, uvCenter, uvRotation, size, offset))
    }
}
