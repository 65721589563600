import {Inlet, NotReady, Outlet} from "#template-nodes/runtime-graph/slots"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"

const TD = TypeDescriptors

const getFieldDescriptor = {
    input: TD.inlet(TD.ShallowJSON()),
    key: TD.inlet(TD.Identity<any>()),
    output: TD.outlet(TD.Identity<any>()),
}

export class GetField<T> implements NodeClassImpl<typeof getFieldDescriptor, typeof GetField> {
    static descriptor = getFieldDescriptor
    static uniqueName = "GetField"
    input!: Inlet<T>
    key!: Inlet<keyof T>
    output!: Outlet<any>

    run() {
        if (this.input === NotReady || this.key === NotReady) {
            this.output.emitIfChanged(NotReady)
            return
        }
        const value = this.input?.[this.key]
        this.output.emitIfChanged(value)
    }
}
