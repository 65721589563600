import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CreateTemplateForTemplateAddDialogMutationVariables = Types.Exact<{
  input: Types.MutationCreateTemplateInput;
}>;


export type CreateTemplateForTemplateAddDialogMutation = { __typename: 'Mutation', createTemplate: { __typename: 'Template', id: string, legacyId: number, name?: string | null, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number } | null } };

export const CreateTemplateForTemplateAddDialogDocument = gql`
    mutation createTemplateForTemplateAddDialog($input: MutationCreateTemplateInput!) {
  createTemplate(input: $input) {
    id
    legacyId
    name
    latestRevision {
      id
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTemplateForTemplateAddDialogGQL extends Apollo.Mutation<CreateTemplateForTemplateAddDialogMutation, CreateTemplateForTemplateAddDialogMutationVariables> {
    override document = CreateTemplateForTemplateAddDialogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }