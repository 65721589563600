import {Pipe, PipeTransform} from "@angular/core"
import {differenceInDays} from "date-fns/differenceInDays"
import {formatDate} from "date-fns/format"

@Pipe({
    name: "cmDuration",
})
export class DurationFromMillisecondsPipe implements PipeTransform {
    transform(durationInMilliseconds: number | undefined | null): string {
        if (durationInMilliseconds === null || durationInMilliseconds === undefined) {
            return "-"
        }
        const date = new Date(durationInMilliseconds)
        const start = new Date(0)
        const days = differenceInDays(date, start)
        if (days > 0) {
            return `${formatDate(date, "HH:mm:ss")} (+${days} days)`
        } else {
            return formatDate(date, "HH:mm:ss")
        }
    }
}
