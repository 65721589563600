import {Component, model} from "@angular/core"

@Component({
    selector: "cm-dropdown-button",
    imports: [],
    templateUrl: "./dropdown-button.component.html",
    styleUrl: "./dropdown-button.component.scss",
})
export class DropdownButtonComponent {
    $expanded = model(false, {alias: "expanded"})

    toggle() {
        this.$expanded.update((expanded) => !expanded)
    }
}
