import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TagsTableItemFragment = { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: Types.TagType, assignmentsCount: number, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null };

export type GetTagsTableItemsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.TagFilterInput>;
  order?: Types.InputMaybe<Array<Types.TagOrderByInput> | Types.TagOrderByInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetTagsTableItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: Types.TagType, assignmentsCount: number, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } | null> };

export const TagsTableItemFragmentDoc = gql`
    fragment TagsTableItem on Tag {
  id
  legacyId
  color
  description
  name
  type
  organization {
    id
    legacyId
    name
  }
  assignmentsCount
}
    `;
export const GetTagsTableItemsDocument = gql`
    query getTagsTableItems($filter: TagFilterInput, $order: [TagOrderByInput!], $take: Int, $skip: Int) {
  items: tags(filter: $filter, orderBy: $order, take: $take, skip: $skip) {
    ...TagsTableItem
  }
  totalCount: tagsCount(filter: $filter)
}
    ${TagsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagsTableItemsGQL extends Apollo.Query<GetTagsTableItemsQuery, GetTagsTableItemsQueryVariables> {
    override document = GetTagsTableItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }