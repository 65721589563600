import {ChangeDetectionStrategy, Component, inject, OnInit, input, model} from "@angular/core"
import {BasicTagInfoFragment} from "@common/services/tags/tags.generated"
import {TagType} from "@generated"
import {AuthService} from "@common/services/auth/auth.service"

@Component({
    selector: "cm-tag-labels",
    templateUrl: "./tag-labels.component.html",
    styleUrls: ["./tag-labels.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagLabelsComponent implements OnInit {
    readonly $tags = model<BasicTagInfoFragment[]>([], {alias: "tags"})
    readonly $filterType = input<TagType>(undefined, {alias: "filterType"})

    readonly auth = inject(AuthService)

    ngOnInit() {
        this.getTags()
    }

    getTags(): void {
        if (this.$filterType()) {
            this.$tags.set(this.$tags().filter((tag) => tag.type !== this.$filterType()))
        }
    }
}
