import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, viewChild} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {TippyDirective} from "@ngneat/helipopper"

@Component({
    selector: "cm-overflowable-text",
    imports: [MatTooltipModule, TippyDirective],
    templateUrl: "./overflowable-text.component.html",
    styleUrl: "./overflowable-text.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverflowableTextComponent implements AfterViewInit {
    readonly $textElement = viewChild.required<ElementRef<HTMLSpanElement>>("textElement")
    tooltip?: string
    readonly changeDetectorRef = inject(ChangeDetectorRef)

    ngAfterViewInit() {
        this.tooltip = this.$textElement().nativeElement.innerText
        this.changeDetectorRef.markForCheck()
    }
}
