import {AuthorizationSilo} from "@common/models/auth/authorization-silo"
import {OrganizationType, SystemRole, User} from "@generated"

export const authorizationContext = (
    silo?: AuthorizationSilo | null,
    user?:
        | (Pick<User, "isStaff" | "isSuperuser" | "role"> & {
              memberships: {organization: {id: string; type?: OrganizationType | null}}[]
          })
        | null,
): {
    isStaff: boolean
    isSuperuser: boolean
    isFabricManufacturer: boolean
    isPhotographer: boolean
    ownOrganizationIds: string[]
} => {
    if (silo) {
        return {
            isStaff: silo.systemRole === SystemRole.Staff || silo.systemRole === SystemRole.Superadmin,
            isSuperuser: silo.systemRole === SystemRole.Superadmin,
            isPhotographer: silo.organization?.type === OrganizationType.Photographer,
            isFabricManufacturer: silo.organization?.type === OrganizationType.FabricManufacturer,
            ownOrganizationIds: silo.organization?.id ? [silo.organization.id] : [],
        }
    } else {
        if (user) {
            return {
                isStaff: user.role === SystemRole.Staff || user.role === SystemRole.Superadmin,
                isSuperuser: user.role === SystemRole.Superadmin,
                isPhotographer: user.memberships.some((membership) => membership.organization?.type === OrganizationType.Photographer),
                isFabricManufacturer: user.memberships.some((membership) => membership.organization?.type === OrganizationType.FabricManufacturer),
                ownOrganizationIds: user.memberships.map((membership) => membership.organization.id) ?? [],
            }
        } else {
            return {
                isStaff: false,
                isSuperuser: false,
                isPhotographer: false,
                isFabricManufacturer: false,
                ownOrganizationIds: [],
            }
        }
    }
}
