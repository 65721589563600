import {Component, input} from "@angular/core"

@Component({
    selector: "cm-full-page-feedback",
    imports: [],
    templateUrl: "./full-page-feedback.component.html",
    styleUrl: "./full-page-feedback.component.scss",
})
export class FullPageFeedbackComponent {
    readonly $text = input<string | null>("An error has occurred", {alias: "text"})
    readonly $icon = input<"no-files" | "cloud-plus" | "search" | "error" | "google" | "microsoft" | "id">("error", {alias: "icon"})
}
