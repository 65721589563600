import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ExecutionPlanFragment = { __typename: 'Job', executionPlan?: any | null, state: Types.JobState };

export type GetExecutionPlanQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetExecutionPlanQuery = { __typename: 'Query', job: { __typename: 'Job', executionPlan?: any | null, state: Types.JobState } };

export const ExecutionPlanFragmentDoc = gql`
    fragment ExecutionPlan on Job {
  executionPlan
  state
}
    `;
export const GetExecutionPlanDocument = gql`
    query getExecutionPlan($id: ID, $legacyId: Int) {
  job(id: $id, legacyId: $legacyId) {
    ...ExecutionPlan
  }
}
    ${ExecutionPlanFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetExecutionPlanGQL extends Apollo.Query<GetExecutionPlanQuery, GetExecutionPlanQueryVariables> {
    override document = GetExecutionPlanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }