import {HalContext} from "@common/models/hal/hal-context"
import {DrawArgs, HalPaintable} from "@common/models/hal/hal-paintable"
import {ColorLike, Matrix3x2} from "@cm/math"
import * as WebGl2PainterUtils from "@common/helpers/webgl2/webgl2-painter-utils"
import {WebGl2Context} from "@common/models/webgl2/webgl2-context"

export class WebGl2PaintableCanvas implements HalPaintable {
    readonly context: WebGl2Context

    constructor(
        context: HalContext,
        private canvas: HTMLCanvasElement,
    ) {
        if (!(context instanceof WebGl2Context)) {
            throw new Error("WebGl2PaintableCanvas requires a WebGl2Context")
        }
        this.context = context
    }

    // HalEntity
    dispose() {}

    // HalPaintable
    get width(): number {
        return this.canvas.width
    }

    // HalPaintable
    get height(): number {
        return this.canvas.height
    }

    // HalPaintable
    get forceAlphaToOne(): boolean {
        return false
    }

    // HalPaintable
    beginDraw(args: DrawArgs): number {
        if (args.region.x !== 0 || args.region.y !== 0 || args.region.width !== this.width || args.region.height !== this.height) {
            throw new Error("WebGl2PaintableCanvas does not support drawing to a region")
        }
        if (args.mipLevel !== 0) {
            throw new Error("WebGl2PaintableCanvas does not support mip levels")
        }
        return 1
    }

    // HalPaintable
    beginDrawPass(_args: DrawArgs, _pass: number): Matrix3x2 {
        const gl = this.context.gl
        gl.bindFramebuffer(gl.FRAMEBUFFER, null)
        gl.viewport(0, 0, this.canvas.width, this.canvas.height)

        const transform = new Matrix3x2([1, 0, 0, -1, 0, 0]) // flip y axis
        transform.append(new Matrix3x2([1, 0, 0, 1, -1, -1])) // origin at top left
        transform.append(new Matrix3x2([2 / this.canvas.width, 0, 0, 2 / this.canvas.height, 0, 0])) // normalize to [-1, 1]
        return transform
    }

    // HalPaintable
    endDrawPass(_args: DrawArgs, _pass: number): void {}

    // HalPaintable
    endDraw(_args: DrawArgs): void {}

    // HalPaintable
    clear(color?: ColorLike, mipLevel?: number) {
        WebGl2PainterUtils.clearPaintable(this, color, mipLevel)
    }
}
