import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProjectTreeProjectsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID']['input'];
}>;


export type ProjectTreeProjectsQuery = { __typename: 'Query', projects: Array<{ __typename: 'Project', id: string, name: string, number: number, organization: { __typename: 'Organization', id: string }, sets: Array<{ __typename: 'Set', id: string, name: string, number: number, created: string, project: { __typename: 'Project', id: string, organization: { __typename: 'Organization', id: string } }, galleryImage?: { __typename: 'DataObject', id: string } | null }> } | null> };

export type ProjectTreeCreateProjectMutationVariables = Types.Exact<{
  input: Types.MutationCreateProjectInput;
}>;


export type ProjectTreeCreateProjectMutation = { __typename: 'Mutation', createProject: { __typename: 'Project', id: string, name: string, number: number, organization: { __typename: 'Organization', id: string }, sets: Array<{ __typename: 'Set', id: string, name: string, number: number, created: string, project: { __typename: 'Project', id: string, organization: { __typename: 'Organization', id: string } }, galleryImage?: { __typename: 'DataObject', id: string } | null }> } };

export type ProjectTreeDeleteProjectMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ProjectTreeDeleteProjectMutation = { __typename: 'Mutation', deleteProject?: any | null };

export type ProjectTreeDeleteSetMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ProjectTreeDeleteSetMutation = { __typename: 'Mutation', deleteSet?: any | null };

export type ProjectTreeSetFragment = { __typename: 'Set', id: string, name: string, number: number, created: string, project: { __typename: 'Project', id: string, organization: { __typename: 'Organization', id: string } }, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type ProjectTreeProjectFragment = { __typename: 'Project', id: string, name: string, number: number, organization: { __typename: 'Organization', id: string }, sets: Array<{ __typename: 'Set', id: string, name: string, number: number, created: string, project: { __typename: 'Project', id: string, organization: { __typename: 'Organization', id: string } }, galleryImage?: { __typename: 'DataObject', id: string } | null }> };

export type ProjectTreeSetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ProjectTreeSetQuery = { __typename: 'Query', set: { __typename: 'Set', id: string, name: string, number: number, created: string, project: { __typename: 'Project', id: string, organization: { __typename: 'Organization', id: string } }, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type ProjectTreeUpdateProjectMutationVariables = Types.Exact<{
  input: Types.MutationUpdateProjectInput;
}>;


export type ProjectTreeUpdateProjectMutation = { __typename: 'Mutation', updateProject: { __typename: 'Project', id: string, name: string, number: number, organization: { __typename: 'Organization', id: string }, sets: Array<{ __typename: 'Set', id: string, name: string, number: number, created: string, project: { __typename: 'Project', id: string, organization: { __typename: 'Organization', id: string } }, galleryImage?: { __typename: 'DataObject', id: string } | null }> } };

export type ProjectTreeUpdateSetMutationVariables = Types.Exact<{
  input: Types.MutationUpdateSetInput;
}>;


export type ProjectTreeUpdateSetMutation = { __typename: 'Mutation', updateSet: { __typename: 'Set', id: string, name: string, number: number, created: string, project: { __typename: 'Project', id: string, organization: { __typename: 'Organization', id: string } }, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type ProjectTreeCreateSetMutationVariables = Types.Exact<{
  input: Types.MutationCreateSetInput;
}>;


export type ProjectTreeCreateSetMutation = { __typename: 'Mutation', createSet: { __typename: 'Set', id: string, name: string, number: number, created: string, project: { __typename: 'Project', id: string, organization: { __typename: 'Organization', id: string } }, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type ProjectTreePicturesQueryVariables = Types.Exact<{
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.PictureFilterInput>;
}>;


export type ProjectTreePicturesQuery = { __typename: 'Query', pictures: Array<{ __typename: 'Picture', id: string } | null> };

export type ProjectTreeUpdatePictureMutationVariables = Types.Exact<{
  input: Types.MutationUpdatePictureInput;
}>;


export type ProjectTreeUpdatePictureMutation = { __typename: 'Mutation', updatePicture: { __typename: 'Picture', id: string } };

export type ProjectTreeUserFragment = { __typename: 'User', id: string, name: string };

export type ProjectTreeVisibleUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProjectTreeVisibleUsersQuery = { __typename: 'Query', users: Array<{ __typename: 'User', id: string, name: string } | null> };

export const ProjectTreeSetFragmentDoc = gql`
    fragment ProjectTreeSet on Set {
  id
  name
  number
  created
  project {
    id
    organization {
      id
    }
  }
  galleryImage {
    id
  }
}
    `;
export const ProjectTreeProjectFragmentDoc = gql`
    fragment ProjectTreeProject on Project {
  id
  name
  number
  organization {
    id
  }
  sets {
    ...ProjectTreeSet
  }
}
    `;
export const ProjectTreeUserFragmentDoc = gql`
    fragment ProjectTreeUser on User {
  id
  name
}
    `;
export const ProjectTreeProjectsDocument = gql`
    query projectTreeProjects($organizationId: ID!) {
  projects(
    filter: {organizationId: {equals: $organizationId}}
    orderBy: [{key: legacyId, direction: desc}]
    take: 1000
  ) {
    ...ProjectTreeProject
  }
}
    ${ProjectTreeProjectFragmentDoc}
${ProjectTreeSetFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeProjectsGQL extends Apollo.Query<ProjectTreeProjectsQuery, ProjectTreeProjectsQueryVariables> {
    override document = ProjectTreeProjectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreeCreateProjectDocument = gql`
    mutation projectTreeCreateProject($input: MutationCreateProjectInput!) {
  createProject(input: $input) {
    ...ProjectTreeProject
  }
}
    ${ProjectTreeProjectFragmentDoc}
${ProjectTreeSetFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeCreateProjectGQL extends Apollo.Mutation<ProjectTreeCreateProjectMutation, ProjectTreeCreateProjectMutationVariables> {
    override document = ProjectTreeCreateProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreeDeleteProjectDocument = gql`
    mutation projectTreeDeleteProject($id: ID!) {
  deleteProject(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeDeleteProjectGQL extends Apollo.Mutation<ProjectTreeDeleteProjectMutation, ProjectTreeDeleteProjectMutationVariables> {
    override document = ProjectTreeDeleteProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreeDeleteSetDocument = gql`
    mutation projectTreeDeleteSet($id: ID!) {
  deleteSet(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeDeleteSetGQL extends Apollo.Mutation<ProjectTreeDeleteSetMutation, ProjectTreeDeleteSetMutationVariables> {
    override document = ProjectTreeDeleteSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreeSetDocument = gql`
    query projectTreeSet($id: ID!) {
  set(id: $id) {
    ...ProjectTreeSet
  }
}
    ${ProjectTreeSetFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeSetGQL extends Apollo.Query<ProjectTreeSetQuery, ProjectTreeSetQueryVariables> {
    override document = ProjectTreeSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreeUpdateProjectDocument = gql`
    mutation projectTreeUpdateProject($input: MutationUpdateProjectInput!) {
  updateProject(input: $input) {
    ...ProjectTreeProject
  }
}
    ${ProjectTreeProjectFragmentDoc}
${ProjectTreeSetFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeUpdateProjectGQL extends Apollo.Mutation<ProjectTreeUpdateProjectMutation, ProjectTreeUpdateProjectMutationVariables> {
    override document = ProjectTreeUpdateProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreeUpdateSetDocument = gql`
    mutation projectTreeUpdateSet($input: MutationUpdateSetInput!) {
  updateSet(input: $input) {
    ...ProjectTreeSet
  }
}
    ${ProjectTreeSetFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeUpdateSetGQL extends Apollo.Mutation<ProjectTreeUpdateSetMutation, ProjectTreeUpdateSetMutationVariables> {
    override document = ProjectTreeUpdateSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreeCreateSetDocument = gql`
    mutation projectTreeCreateSet($input: MutationCreateSetInput!) {
  createSet(input: $input) {
    ...ProjectTreeSet
  }
}
    ${ProjectTreeSetFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeCreateSetGQL extends Apollo.Mutation<ProjectTreeCreateSetMutation, ProjectTreeCreateSetMutationVariables> {
    override document = ProjectTreeCreateSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreePicturesDocument = gql`
    query projectTreePictures($take: Int, $filter: PictureFilterInput) {
  pictures(take: $take, filter: $filter) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreePicturesGQL extends Apollo.Query<ProjectTreePicturesQuery, ProjectTreePicturesQueryVariables> {
    override document = ProjectTreePicturesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreeUpdatePictureDocument = gql`
    mutation projectTreeUpdatePicture($input: MutationUpdatePictureInput!) {
  updatePicture(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeUpdatePictureGQL extends Apollo.Mutation<ProjectTreeUpdatePictureMutation, ProjectTreeUpdatePictureMutationVariables> {
    override document = ProjectTreeUpdatePictureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectTreeVisibleUsersDocument = gql`
    query projectTreeVisibleUsers {
  users(filter: {visibleInFilters: true}, take: 9999) {
    ...ProjectTreeUser
  }
}
    ${ProjectTreeUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTreeVisibleUsersGQL extends Apollo.Query<ProjectTreeVisibleUsersQuery, ProjectTreeVisibleUsersQueryVariables> {
    override document = ProjectTreeVisibleUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }