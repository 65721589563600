import {TemplatePortal} from "@angular/cdk/portal"
import {Component, HostListener, inject, OnDestroy, OnInit, TemplateRef, ViewContainerRef, input, viewChild, output} from "@angular/core"
import {ActivatedRoute, Router, UrlTree} from "@angular/router"
import {DialogService} from "@common/services/dialog/dialog.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {DialogSize, ModalInfo} from "@common/models/dialogs"

/**
 * A dialog that is opened and closed by routing to a specific URL.
 * This component does not have a `closed` mode - it is closed by navigating to a different URL, destroying the component.
 *
 * Use the `triggered-dialog` component if you need a dialog that can be opened and closed on demand without routing.
 *
 * To enable the `needsConfirmationToClose` feature, you should use the `closeIfClean` method,
 * which will show a confirmation dialog if necessary (e.g. for unsaved changes).
 */
@Component({
    imports: [],
    selector: "cm-routed-dialog",
    styleUrls: ["./routed-dialog.component.scss"],
    templateUrl: "./routed-dialog.component.html",
})
export class RoutedDialogComponent implements OnInit, OnDestroy {
    // The width of the dialog.
    readonly $dialogSize = input(
        DialogSize.Medium,
        // Makes the dialog's content (rather than the entire dialog) scrollable.
        {alias: "dialogSize"},
    )
    // Makes the dialog's content (rather than the entire dialog) scrollable.
    readonly $dialogScroll = input(
        true,
        // Request user confirmation before closing the dialog.
        {alias: "dialogScroll"},
    )
    // Request user confirmation before closing the dialog.
    readonly $needsConfirmationToClose = input(
        false,
        // Optional URL to navigate to when the dialog is closed.
        // If not provided, will default to closeNavigationPath
        // from the current route's data (or "../") while preserving all parameters.
        {alias: "needsConfirmationToClose"},
    )
    // Optional URL to navigate to when the dialog is closed.
    // If not provided, will default to closeNavigationPath
    // from the current route's data (or "../") while preserving all parameters.
    readonly $closeRoute = input<
        UrlTree | string
        // Emits when the dialog has been closed.
    >(undefined, {alias: "closeRoute"})

    // Emits when the dialog has been closed.
    readonly close = output<void>()

    readonly $overlayTemplate = viewChild.required<TemplateRef<HTMLElement>>("overlayTemplate")

    readonly dialog = inject(DialogService)
    readonly notifications = inject(NotificationsService)
    readonly route = inject(ActivatedRoute)
    readonly router = inject(Router)
    readonly viewContainerRef = inject(ViewContainerRef)

    modalInfo?: ModalInfo

    ngOnInit(): void {
        this.openOverlay()
    }

    ngOnDestroy(): void {
        if (this.modalInfo) {
            this.dialog.closeModal(this.modalInfo)
        }
    }

    private openOverlay(): void {
        this.modalInfo = this.dialog.openModal(new TemplatePortal(this.$overlayTemplate(), this.viewContainerRef))
    }

    public async closeIfClean() {
        if (this.$needsConfirmationToClose()) {
            const confirmed = await this.notifications.confirmationDialog({
                title: "Leave page ?",
                message: "Changes you made may not be saved. Are you sure you want to leave?",
                confirm: "Leave",
                cancel: "Stay",
            })
            if (confirmed) {
                await this.closeOverlay()
            }
        } else {
            await this.closeOverlay()
        }
    }

    private async closeOverlay() {
        const route =
            this.$closeRoute() ??
            this.router.createUrlTree([this.route.snapshot.data.closeNavigationPath ?? "../"], {
                relativeTo: this.route,
                queryParams: {
                    ...(this.route.snapshot.data.closeQueryParamOverride ?? {}),
                },
                queryParamsHandling: "merge",
            })
        this.close.emit()
        await this.router.navigateByUrl(route)
    }

    @HostListener("window:beforeunload", ["$event"])
    private async unloadNotification($event: BeforeUnloadEvent) {
        if (this.$needsConfirmationToClose()) {
            $event.returnValue = true
            $event.preventDefault()
        }
    }

    protected readonly DialogSize = DialogSize
}
