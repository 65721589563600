import {CdkConnectedOverlay, CdkOverlayOrigin} from "@angular/cdk/overlay"
import {Component, signal} from "@angular/core"
import {TemplateAddMaterialComponent} from "../template-add-material/template-add-material.component"
import {TemplateAddSceneItemComponent} from "../template-add-scene-item/template-add-scene-item.component"
import {TemplateAddProductComponent} from "../template-add-product/template-add-product.component"
import {TemplateAddOtherComponent} from "../template-add-other/template-add-other.component"
import {TemplateAddSceneComponent} from "../template-add-scene/template-add-scene.component"

type SectionType = "scene" | "products" | "materials" | "scene-item" | "other"

@Component({
    selector: "cm-template-add",
    templateUrl: "./template-add.component.html",
    styleUrl: "./template-add.component.scss",
    imports: [
        CdkConnectedOverlay,
        CdkOverlayOrigin,
        TemplateAddMaterialComponent,
        TemplateAddSceneItemComponent,
        TemplateAddProductComponent,
        TemplateAddOtherComponent,
        TemplateAddSceneComponent,
    ],
})
export class TemplateAddComponent {
    readonly $currentSection = signal<SectionType | undefined>(undefined)

    toggleSection(section: SectionType) {
        this.$currentSection.update((previousSection) => (previousSection === section ? undefined : section))
    }
}
