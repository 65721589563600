import {Directive, HostListener} from "@angular/core"

@Directive({
    selector: "[disableAfterClick]",
    host: {
        "[attr.disabled]": "disabled || null",
    },
})
export class DisableButtonAfterClickDirective {
    disabled: true | undefined

    constructor() {
        this.disabled = undefined
    }

    @HostListener("click", ["$event"])
    onClick(event: MouseEvent) {
        event.preventDefault()
        event.stopPropagation()
        if (event.currentTarget instanceof HTMLElement) {
            event.currentTarget.setAttribute("disabled", "disabled")
        }
        this.disabled = true
    }
}
