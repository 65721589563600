import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AttachedDataObjectFragment = { __typename: 'DataObject', id: string, state: Types.DataObjectState, originalFileName: string, mediaType?: string | null, bucketName: string, objectName: string, width?: number | null, height?: number | null, downloadUrl: string, organization: { __typename: 'Organization', id: string, name?: string | null }, metadataProcessingJob?: { __typename: 'Job', id: string, state: Types.JobState } | null, thumbnailProcessingJob?: { __typename: 'Job', id: string, state: Types.JobState } | null };

export type AttachmentFragment = { __typename: 'DataObjectAssignment', id: string, assignmentType: Types.DataObjectAssignmentType, assignmentKey?: string | null, dataObject: { __typename: 'DataObject', id: string, state: Types.DataObjectState, originalFileName: string, mediaType?: string | null, bucketName: string, objectName: string, width?: number | null, height?: number | null, downloadUrl: string, organization: { __typename: 'Organization', id: string, name?: string | null }, metadataProcessingJob?: { __typename: 'Job', id: string, state: Types.JobState } | null, thumbnailProcessingJob?: { __typename: 'Job', id: string, state: Types.JobState } | null } };

export type CreateAttachmentMutationVariables = Types.Exact<{
  input: Types.MutationCreateDataObjectAssignmentInput;
}>;


export type CreateAttachmentMutation = { __typename: 'Mutation', createDataObjectAssignment: { __typename: 'DataObjectAssignment', id: string, assignmentType: Types.DataObjectAssignmentType, assignmentKey?: string | null, dataObject: { __typename: 'DataObject', id: string, state: Types.DataObjectState, originalFileName: string, mediaType?: string | null, bucketName: string, objectName: string, width?: number | null, height?: number | null, downloadUrl: string, organization: { __typename: 'Organization', id: string, name?: string | null }, metadataProcessingJob?: { __typename: 'Job', id: string, state: Types.JobState } | null, thumbnailProcessingJob?: { __typename: 'Job', id: string, state: Types.JobState } | null } } };

export type DeleteAttachedFileMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteAttachedFileMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export type UpdateAttachmentMutationVariables = Types.Exact<{
  input: Types.MutationUpdateDataObjectAssignmentInput;
}>;


export type UpdateAttachmentMutation = { __typename: 'Mutation', updateDataObjectAssignment: { __typename: 'DataObjectAssignment', id: string, assignmentType: Types.DataObjectAssignmentType, assignmentKey?: string | null, dataObject: { __typename: 'DataObject', id: string, state: Types.DataObjectState, originalFileName: string, mediaType?: string | null, bucketName: string, objectName: string, width?: number | null, height?: number | null, downloadUrl: string, organization: { __typename: 'Organization', id: string, name?: string | null }, metadataProcessingJob?: { __typename: 'Job', id: string, state: Types.JobState } | null, thumbnailProcessingJob?: { __typename: 'Job', id: string, state: Types.JobState } | null } } };

export type AttachmentRenameDataObjectMutationVariables = Types.Exact<{
  input: Types.MutationUpdateDataObjectInput;
}>;


export type AttachmentRenameDataObjectMutation = { __typename: 'Mutation', updateDataObject: { __typename: 'DataObject', id: string, state: Types.DataObjectState, originalFileName: string, mediaType?: string | null, bucketName: string, objectName: string, width?: number | null, height?: number | null, downloadUrl: string, organization: { __typename: 'Organization', id: string, name?: string | null }, metadataProcessingJob?: { __typename: 'Job', id: string, state: Types.JobState } | null, thumbnailProcessingJob?: { __typename: 'Job', id: string, state: Types.JobState } | null } };

export type AttachmentsQueryVariables = Types.Exact<{
  filter: Types.DataObjectAssignmentFilterInput;
}>;


export type AttachmentsQuery = { __typename: 'Query', dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: Types.DataObjectAssignmentType, assignmentKey?: string | null, dataObject: { __typename: 'DataObject', id: string, state: Types.DataObjectState, originalFileName: string, mediaType?: string | null, bucketName: string, objectName: string, width?: number | null, height?: number | null, downloadUrl: string, organization: { __typename: 'Organization', id: string, name?: string | null }, metadataProcessingJob?: { __typename: 'Job', id: string, state: Types.JobState } | null, thumbnailProcessingJob?: { __typename: 'Job', id: string, state: Types.JobState } | null } } | null> };

export const AttachedDataObjectFragmentDoc = gql`
    fragment AttachedDataObject on DataObject {
  id
  state
  originalFileName
  mediaType
  bucketName
  objectName
  width
  height
  downloadUrl
  organization {
    id
    name
  }
  metadataProcessingJob {
    id
    state
  }
  thumbnailProcessingJob {
    id
    state
  }
}
    `;
export const AttachmentFragmentDoc = gql`
    fragment Attachment on DataObjectAssignment {
  id
  dataObject {
    ...AttachedDataObject
  }
  assignmentType
  assignmentKey
}
    `;
export const CreateAttachmentDocument = gql`
    mutation createAttachment($input: MutationCreateDataObjectAssignmentInput!) {
  createDataObjectAssignment(input: $input) {
    ...Attachment
  }
}
    ${AttachmentFragmentDoc}
${AttachedDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAttachmentGQL extends Apollo.Mutation<CreateAttachmentMutation, CreateAttachmentMutationVariables> {
    override document = CreateAttachmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAttachedFileDocument = gql`
    mutation deleteAttachedFile($id: ID!) {
  deleteDataObject(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAttachedFileGQL extends Apollo.Mutation<DeleteAttachedFileMutation, DeleteAttachedFileMutationVariables> {
    override document = DeleteAttachedFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAttachmentDocument = gql`
    mutation updateAttachment($input: MutationUpdateDataObjectAssignmentInput!) {
  updateDataObjectAssignment(input: $input) {
    ...Attachment
  }
}
    ${AttachmentFragmentDoc}
${AttachedDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAttachmentGQL extends Apollo.Mutation<UpdateAttachmentMutation, UpdateAttachmentMutationVariables> {
    override document = UpdateAttachmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AttachmentRenameDataObjectDocument = gql`
    mutation attachmentRenameDataObject($input: MutationUpdateDataObjectInput!) {
  updateDataObject(input: $input) {
    ...AttachedDataObject
  }
}
    ${AttachedDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AttachmentRenameDataObjectGQL extends Apollo.Mutation<AttachmentRenameDataObjectMutation, AttachmentRenameDataObjectMutationVariables> {
    override document = AttachmentRenameDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AttachmentsDocument = gql`
    query attachments($filter: DataObjectAssignmentFilterInput!) {
  dataObjectAssignments(filter: $filter) {
    ...Attachment
  }
}
    ${AttachmentFragmentDoc}
${AttachedDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AttachmentsGQL extends Apollo.Query<AttachmentsQuery, AttachmentsQueryVariables> {
    override document = AttachmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }