import {Injector} from "@angular/core"
import {AsyncCacheMap} from "@app/common/helpers/async-cache-map/async-cache-map"
import {Nodes, TemplateGraph} from "@cm/template-nodes"
import {from, map} from "rxjs"
import {TemplateRevisionBatchApiCall} from "./template-revision-batch-api-call"
import {loadTemplateGraph} from "@cm/template-nodes/utils/serialization-utils"

export class TemplateRevisionGraphCache extends AsyncCacheMap<number, TemplateGraph> {
    private templateRevisionBatchApiCall: TemplateRevisionBatchApiCall
    constructor(injector: Injector) {
        super((id: number) => {
            return from(this.templateRevisionBatchApiCall.fetch({legacyId: id})).pipe(
                map((templateRevision) => templateRevision.graph),
                map((graphJson) => {
                    if (!graphJson) return new TemplateGraph({name: "Untitled Template", nodes: new Nodes({list: []})})
                    return loadTemplateGraph(graphJson)
                }),
            )
        })
        this.templateRevisionBatchApiCall = new TemplateRevisionBatchApiCall(injector)
    }
}
