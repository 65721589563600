import {Component, Inject} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {ImageColorSpace} from "@generated"
import {MeasurementType, MeasurementTypes} from "@common/models/settings/settings"
import {Labels} from "@labels"

@Component({
    selector: "cm-upload-texture-settings-dialog",
    templateUrl: "./upload-texture-settings-dialog.component.html",
    styleUrls: ["./upload-texture-settings-dialog.component.scss"],
    imports: [MatDialogModule, MatInputModule, FormsModule, MatSelectModule, MatButtonModule],
})
export class UploadTextureSettingDialogComponent {
    result: Partial<UploadTextureSettingDialogResult> = {
        displacement: undefined,
        colorSpace: undefined,
    }

    constructor(
        public dialogRef: MatDialogRef<UploadTextureSettingDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {showDisplacementSetting?: boolean; displacement?: number; measurementType?: MeasurementType},
    ) {
        if (data) {
            this.result = {
                displacement: data.displacement,
                colorSpace: undefined,
            }
            this.imperial = data.measurementType === MeasurementTypes.Imperial
            this.showDisplacementSetting = data.showDisplacementSetting ?? false
        }
    }

    cancelAction(): void {
        this.dialogRef.close(false)
    }

    confirmAction(): void {
        if (this.result && this.imperial) {
            if (this.result.displacement) {
                this.result.displacement = MeasurementTypes.Imperial.convertToMetric(this.result.displacement)
            }
        }
        this.dialogRef.close(this.result)
    }

    protected readonly ImageColorSpace = ImageColorSpace
    protected readonly Labels = Labels

    protected imperial = false
    protected showDisplacementSetting = true
}

export type UploadTextureSettingDialogResult = {
    colorSpace: ImageColorSpace
    displacement?: number
}
