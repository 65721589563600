import {registerNode} from "@cm/graph/register-node"
import {positionValue} from "#template-nodes/types"
import {namedNodeParameters} from "#template-nodes/nodes/named-node"
import {z} from "zod"
import {visitNone} from "@cm/graph/declare-visitor-node"
import {DeclareMeshNode, TemplateMeshNode} from "#template-nodes/declare-mesh-node"
import {LoadMesh} from "#template-nodes/runtime-graph/nodes/load-mesh"
import {nodeInstance} from "@cm/graph/instance"
import {DataObjectReference} from "#template-nodes/nodes/data-object-reference"
import {LoadData} from "#template-nodes/runtime-graph/nodes/load-data"

const storedMeshParameters = namedNodeParameters.merge(
    z.object({
        drcDataObject: nodeInstance(DataObjectReference),
        plyDataObject: nodeInstance(DataObjectReference),
        metaData: z
            .object({
                dracoBitDepth: z.number().optional(),
                dracoResolution: z.number().optional(),
                osdUseRenderIterations: z.number().optional(),
                osdRenderIterations: z.number().optional(),
                defaultPosition: positionValue.optional(),
                centered: z.boolean().optional(),
                exporterVersion: z.string().optional(),
            })
            .optional(),
    }),
)
export type StoredMeshParameters = z.infer<typeof storedMeshParameters>

@registerNode
export class StoredMesh extends DeclareMeshNode(
    {parameters: storedMeshParameters},
    {
        onVisited: {
            onCompile: function (this: StoredMeshFwd, {context, parameters}) {
                const {evaluator} = context
                const {templateContext} = evaluator
                const {sceneManager} = templateContext
                const {subdivisionRenderIterations, plyDataObject, drcDataObject} = parameters

                const scope = evaluator.getScope(this)
                const {completeMeshData} = scope.node(LoadMesh, {
                    sceneManager,
                    data: scope.node(LoadData, {
                        sceneManager,
                        dataObject: evaluator.fetchDataObject(scope, drcDataObject),
                    }).data,
                    drcDataObjectId: drcDataObject.parameters.dataObjectId,
                    plyDataObjectId: plyDataObject.parameters.dataObjectId,
                    displaySubdivisionLevel: this.getDisplaySubdivisionLevel(context),
                    renderSubdivisionLevel: subdivisionRenderIterations ?? 0,
                })

                this.setupMesh(scope, context, completeMeshData, false)

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "StoredMesh"},
) {}

export type StoredMeshFwd = TemplateMeshNode<StoredMeshParameters>
