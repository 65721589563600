<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> Image RGB Curve </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="$node()" [key]="'name'" [label]="'Name'"></cm-value-slot>
        <cm-value-slot [node]="$node()" [key]="'input'" [label]="'Input'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Output Curve </ng-container>
    <ng-container cm-content>
        <cm-rgb-curves
            [controlPoints]="$controlPoints()"
            [width]="270"
            [height]="270"
            (onControlPointAdded)="addControlPoint($event.curve, $event.point)"
            (onControlPointRemoved)="removeControlPoint($event.curve, $event.pointId)"
            (onBeginControlPointDrag)="beginDrag()"
            (onControlPointChanged)="changeControlPoint($event.curve, $event.pointId, $event.point)"
            (onEndControlPointDrag)="endDrag()"
        ></cm-rgb-curves>
    </ng-container>
</cm-inspector-section>
