import {Component, input, model, output} from "@angular/core"

@Component({
    selector: "cm-toggle",
    templateUrl: "./toggle.component.html",
    styleUrls: ["./toggle.component.scss"],
})
export class ToggleComponent {
    readonly $toggle = model(false, {alias: "toggle"})
    readonly $disabled = input(false, {alias: "disabled"})
    readonly toggleChange = output<boolean>()
}
