import {Component, inject, OnInit} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatDialogModule} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {HdrisGridItemFragment} from "@app/platform/components/hdris/hdris-grid/hdris-grid.generated"
import {CardErrorComponent, CardPlaceholderComponent} from "@common/components/cards"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"
import {ItemListComponent, ListInfoComponent} from "@common/components/item"
import {InfiniteListComponent} from "@common/components/lists"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {ContentTypeModel, MutationCreateHdriInput, MutationUpdateHdriInput} from "@generated"
import {CreateHdrisGridItemGQL, HdrisGridItemsGQL} from "@platform/components/hdris/hdris-grid/hdris-grid.generated"

@Component({
    imports: [
        CardErrorComponent,
        InfiniteListComponent,
        ListInfoComponent,
        CardPlaceholderComponent,
        MatDialogModule,
        FormsModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        EntityCardComponent,
        OrganizationSelectComponent,
    ],
    selector: "cm-hdris-grid",
    styleUrls: ["./hdris-grid.component.scss"],
    templateUrl: "./hdris-grid.component.html",
})
export class HdrisGridComponent extends ItemListComponent<HdrisGridItemFragment, MutationUpdateHdriInput, MutationCreateHdriInput> implements OnInit {
    protected override _contentTypeModel = ContentTypeModel.Hdri
    readonly createHdrisGridItem = inject(CreateHdrisGridItemGQL)
    protected override _createItem = (data: MutationCreateHdriInput) =>
        mutateThrowingErrors(this.createHdrisGridItem)({input: data}).then((result) => result.createHdri)

    readonly itemsGql = inject(HdrisGridItemsGQL)

    protected _fetchList = ({skip, take}: {skip: number; take: number}) =>
        fetchThrowingErrors(this.itemsGql)({
            take: take,
            skip: skip,
            filter: this.filters.hdriFilter(),
        }).then(({hdris, hdrisCount}) => ({items: hdris, totalCount: hdrisCount}))

    protected _refreshItem = ({id, legacyId}: {id?: string; legacyId?: number}) =>
        fetchThrowingErrors(this.itemsGql)({
            take: 1,
            filter: {
                ...this.filters.hdriFilter(),
                id: id ? {equals: id} : undefined,
                legacyId: legacyId ? {equals: legacyId} : undefined,
            },
        }).then(({hdris}) => hdris?.[0] || undefined)

    updateOrganization = async (data: {organizationId: string}) => {
        this.newItemData.organizationId = data.organizationId
    }
}
