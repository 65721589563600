@if (!$can().read.organization()) {
    <div class="cm-own-organizations-filter-container">
        <cm-filter-list>
            <ng-container cm-title> Organization</ng-container>
            <ng-container cm-list>
                @if (selectedOrganizations.length) {
                    <div class="cm-selected-organization-list">
                        @for (organization of selectedOrganizations; track organization.state) {
                            <div (click)="onRemoveOrganization(organization)" class="cm-selected-organization">
                                <i class="fa-solid fa-xmark"></i>
                                <div [matTooltip]="organization.label" class="cm-selected-organization-name">
                                    {{ organization.label }}
                                </div>
                            </div>
                        }
                    </div>
                } @else {
                    <a (click)="selectOwnOrganizations()">
                        <div>+ Show own library only</div>
                    </a>
                }
            </ng-container>
        </cm-filter-list>
    </div>
}
