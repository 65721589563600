import {Component, viewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {MixInputs, MixOutputs, blendMode} from "@material-editor/models/nodes/mix"

@Component({
    selector: "cm-mix-node",
    templateUrl: "./mix-node.component.html",
    styleUrls: ["./mix-node.component.scss"],
    imports: [NodeBaseComponent],
})
export class MixNodeComponent {
    readonly $nodeBase = viewChild<MaterialNodeBase>("nodeBase")
    inputs = MixInputs
    outputs = MixOutputs
    typeInfo = MixNodeType
    settings = [blendMode]
}

export const MixNodeType: MaterialNodeType<typeof MixNodeComponent> = {
    id: "mix",
    label: "Mix",
    color: "#FFAA00",
    name: "ShaderNodeMix",
    inputs: [MixInputs.aColor, MixInputs.bColor],
    outputs: [MixOutputs.resultColor],
    component: MixNodeComponent,
}
