import {DeclareMaterialNode, materialSlots} from "#material-nodes/declare-material-node"
import {z} from "zod"
import {threeRGBColorNode} from "#material-nodes/three-utils"

export class DistanceTexture extends DeclareMaterialNode(
    {
        returns: z.object({color: materialSlots}),
        inputs: z.object({
            vector: materialSlots.optional(),
        }),
        parameters: z.object({
            meshObjectData: z.unknown(),
            extension: z.enum(["REPEAT", "EXTEND", "CLIP", "MIRROR"]).optional(),
            interpolation: z.enum(["Linear", "Cubic", "Closest", "Smart"]).optional(),
            projection: z.enum(["FLAT", "BOX", "SPHERE", "TUBE"]).optional(),
            range: z.number().optional(),
            width: z.number().optional(),
            height: z.number().optional(),
            forceOriginalResolution: z.boolean().optional(),
            uvChannel: z.number().optional(),
            targets: z.array(z.unknown()).optional(),
            innerValue: z.number().optional(),
        }),
    },
    {
        toThree: async ({get, inputs, parameters, context}) => {
            console.warn("WARNING: Distance texture node not implemented!")
            return {color: threeRGBColorNode({r: 0, g: 0, b: 0})}
        },
    },
) {}
