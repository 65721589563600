import {Component, input} from "@angular/core"
import {MatTooltip} from "@angular/material/tooltip"
import {UtilsService} from "@legacy/helpers/utils"

@Component({
    selector: "cm-copy-legacy-id-button",
    imports: [MatTooltip],
    templateUrl: "./copy-legacy-id-button.component.html",
    styleUrl: "./copy-legacy-id-button.component.scss",
})
export class CopyLegacyIdButtonComponent {
    readonly $legacyId = input<number>(undefined, {alias: "legacyId"})

    constructor(public utils: UtilsService) {}
}
