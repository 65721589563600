import {Component, inject, OnInit, signal} from "@angular/core"
import {HDRILight} from "@cm/template-nodes"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {GetHdriDetailsForHdriLightInspectorGQL} from "@template-editor/components/inspectors/hdri-light-inspector/hdri-light-inspector.generated"
import {SelectionPossibilities, ValueSlotComponent} from "../../value-slot/value-slot.component"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"

@Component({
    selector: "cm-hdri-light-inspector",
    templateUrl: "./hdri-light-inspector.component.html",
    styleUrl: "./hdri-light-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent],
})
export class HDRILightInspectorComponent extends BaseInspectorComponent<HDRILight> implements OnInit {
    readonly $hdriIdPossibilities = signal<SelectionPossibilities<number> | undefined>(undefined)

    readonly hdriDetailsGql = inject(GetHdriDetailsForHdriLightInspectorGQL)

    override async ngOnInit() {
        super.ngOnInit()
        const {hdris} = await fetchThrowingErrors(this.hdriDetailsGql)({})
        const hdriPossibilities: SelectionPossibilities<number> = []
        for (const hdri of hdris) {
            if (!hdri) continue
            hdriPossibilities.push({value: hdri.legacyId, name: hdri.name ?? "Unnamed HDRI"})
        }

        this.$hdriIdPossibilities.set(hdriPossibilities)
    }
}
