import {skipped, visitNone} from "@cm/graph/declare-visitor-node"
import {registerNode} from "@cm/graph/register-node"
import {Matrix4, Vector3} from "@cm/math"
import {DeclareObjectNode, TemplateObjectNode} from "#template-nodes/declare-object-node"
import {DimensionGuideInfo, SceneNodes, objectLike, ObjectLike} from "@cm/template-nodes"
import {namedNodeParameters} from "#template-nodes/nodes/named-node"
import {indexToDirection, getBoundsEdgeTransform} from "#template-nodes/utils/dimension-guide-utils"
import {boundsEdgeNumber, getBoundsEdge} from "#template-nodes/utils/scene-geometry-utils"
import {z} from "zod"

const guideParameters = z.object({
    boundsEdge: boundsEdgeNumber,
    show: z.boolean(),
})

export type GuideParameters = z.infer<typeof guideParameters>

export const unitForDisplay = z.enum(["mm", "cm", "m", "in", "ft"])
type UnitForDisplay = z.infer<typeof unitForDisplay>

const dimensionGuideParameters = namedNodeParameters.merge(
    z.object({
        offset: z.number(),
        parent: objectLike.nullable(),
        guides: z.tuple([guideParameters, guideParameters, guideParameters]),
        unitForDisplay: unitForDisplay.default("cm"),
    }),
)

export type DimensionGuidesParameters = {
    offset: number
    parent: ObjectLike | null
    guides: [GuideParameters, GuideParameters, GuideParameters]
    unitForDisplay: UnitForDisplay
}

@registerNode
export class DimensionGuides extends DeclareObjectNode(
    {parameters: dimensionGuideParameters},
    {
        onVisited: {
            onFilterActive: ({parameters}) => {
                if (parameters.parent === null) return skipped
                return visitNone(parameters)
            },
            onCompile: function (this: DimensionGuidesFwd, {context, parameters}) {
                const {evaluator} = context
                if (!evaluator.templateContext.showDimensions) return skipped

                const {parent, guides, offset, unitForDisplay} = parameters

                const scope = evaluator.getScope(this)

                if (!parent) return skipped

                const [objectData, objectDataInvalid] = scope.branch(evaluator.evaluateObject(scope, parent))

                const dimensionGuideRawData = scope.tuple(objectData, guides, offset, unitForDisplay)

                const dimensionGuides = scope.pureLambda(
                    dimensionGuideRawData,
                    ([objectData, guides, offset, unitForDisplay]) => {
                        const boundsMatrix = Matrix4.fromArray(objectData.untransformedBounds.matrix)

                        //guides.entries, because we need the original index
                        const result: DimensionGuideInfo[] = [...guides.entries()]
                            .filter(([_, guide]) => guide.show)
                            .map(([originalIndex, guide]): DimensionGuideInfo => {
                                const [s, e] = getBoundsEdge(indexToDirection(originalIndex), guide.boundsEdge, objectData.untransformedBounds)
                                const transform = getBoundsEdgeTransform(indexToDirection(originalIndex), guide.boundsEdge, offset)

                                const start = Vector3.fromArray(transform.multiplyVectorXYZW(s[0], s[1], s[2], 1))
                                const end = Vector3.fromArray(transform.multiplyVectorXYZW(e[0], e[1], e[2], 1))
                                const startTransformed = Vector3.fromArray(boundsMatrix.multiplyVectorXYZW(start.x, start.y, start.z, 1))
                                const endTransformed = Vector3.fromArray(boundsMatrix.multiplyVectorXYZW(end.x, end.y, end.z, 1))
                                return {
                                    start: startTransformed,
                                    end: endTransformed,
                                    label: convertLength(start.sub(end).norm(), unitForDisplay),
                                }
                            })
                        return result
                    },
                    "bounds",
                )

                this.setupObject(
                    scope,
                    context,
                    "DimensionGuides",
                    undefined,
                    objectData,
                    (objectProps) => {
                        return scope.struct<SceneNodes.DimensionGuides>("DimensionGuides", {
                            type: "DimensionGuides",
                            ...objectProps,
                            guides: dimensionGuides,
                        })
                    },
                    objectDataInvalid,
                )

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "DimensionGuides"},
) {}

function convertLength(lengthInCm: number, targetUnit: UnitForDisplay): string {
    const conversionFromCm = {
        mm: 10,
        cm: 1,
        m: 0.01,
        in: 1 / 2.54,
        ft: 1 / 30.48,
    }

    const decimalPlaces = {
        mm: 0,
        cm: 0,
        m: 2,
        in: 1,
        ft: 1,
    }

    const convertedValue = lengthInCm * conversionFromCm[targetUnit]
    return `${convertedValue.toFixed(decimalPlaces[targetUnit])} ${targetUnit}`
}

export type DimensionGuidesFwd = TemplateObjectNode<DimensionGuidesParameters>
