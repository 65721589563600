import {TextureType} from "@generated"
import {StateLabel} from "@platform/models/state-labels/state-label"

export const TextureTypeLabels: Map<
    TextureType,
    StateLabel<TextureType> & {
        isColorData: boolean
        channelLayout: "RGB" | "L"
    }
> = new Map<TextureType, StateLabel<TextureType> & {isColorData: boolean; channelLayout: "RGB" | "L"}>([
    [
        TextureType.Diffuse,
        {
            state: TextureType.Diffuse,
            label: "Diffuse",
            background: "#7ec17a",
            isColorData: true,
            channelLayout: "RGB",
        },
    ],
    [
        TextureType.DiffuseRoughness,
        {
            state: TextureType.DiffuseRoughness,
            label: "Diffuse roughness",
            background: "#7ec17a",
            isColorData: false,
            channelLayout: "L",
        },
    ],
    [
        TextureType.Normal,
        {
            state: TextureType.Normal,
            label: "Normal",
            background: "#7ec17a",
            isColorData: false,
            channelLayout: "RGB",
        },
    ],
    [
        TextureType.Roughness,
        {
            state: TextureType.Roughness,
            label: "Roughness",
            background: "#7ec17a",
            isColorData: false,
            channelLayout: "L",
        },
    ],
    [
        TextureType.F0,
        {
            state: TextureType.F0,
            label: "F0",
            background: "#7ec17a",
            isColorData: false,
            channelLayout: "RGB",
        },
    ],
    [
        TextureType.Ior,
        {
            state: TextureType.Ior,
            label: "IOR",
            background: "#7ec17a",
            isColorData: false,
            channelLayout: "L",
        },
    ],
    [
        TextureType.AnisotropyRotation,
        {
            state: TextureType.AnisotropyRotation,
            label: "Anisotropy rotation",
            background: "#7ec17a",
            isColorData: false,
            channelLayout: "L",
        },
    ],
    [
        TextureType.AnisotropyStrength,
        {
            state: TextureType.AnisotropyStrength,
            label: "Anisotropy strength",
            background: "#7ec17a",
            isColorData: false,
            channelLayout: "L",
        },
    ],
    [
        TextureType.Error,
        {
            state: TextureType.Error,
            label: "Error",
            background: "#7ec17a",
            isColorData: false,
            channelLayout: "L",
        },
    ],
    [
        TextureType.Mask,
        {
            state: TextureType.Mask,
            label: "Mask",
            background: "#7ec17a",
            isColorData: false,
            channelLayout: "L",
        },
    ],
    [
        TextureType.Displacement,
        {
            state: TextureType.Displacement,
            label: "Displacement",
            background: "#7ec17a",
            isColorData: false,
            channelLayout: "L",
        },
    ],
    [
        TextureType.SpecularStrength,
        {
            state: TextureType.SpecularStrength,
            label: "Specular strength",
            background: "#7ec17a",
            isColorData: false,
            channelLayout: "L",
        },
    ],
    [
        TextureType.SpecularTint,
        {
            state: TextureType.SpecularTint,
            label: "Specular tint",
            background: "#7ec17a",
            isColorData: false,
            channelLayout: "RGB",
        },
    ],
    [
        TextureType.Anisotropy,
        {
            state: TextureType.Anisotropy,
            label: "Anisotropy",
            background: "#7ec17a",
            isColorData: false,
            channelLayout: "RGB",
        },
    ],
    [
        TextureType.Metalness,
        {
            state: TextureType.Metalness,
            label: "Metalness",
            background: "#7ec17a",
            isColorData: false,
            channelLayout: "L",
        },
    ],
    [
        TextureType.Transmission,
        {
            state: TextureType.Transmission,
            label: "Transmission",
            background: "#7ec17a",
            isColorData: true,
            channelLayout: "RGB",
        },
    ],
])
