import {Component, ElementRef, OnDestroy, OnInit, output, viewChild} from "@angular/core"
import {HalPainterImageFinalConversion} from "@common/models/hal/common/hal-painter-image-final-conversion"
import {HalContext} from "@common/models/hal/hal-context"
import {HalPaintable} from "@common/models/hal/hal-paintable"
import {Vector2} from "@cm/math"
import {WebGl2Context} from "@common/models/webgl2/webgl2-context"
import {WebGl2PaintableCanvas} from "@common/models/webgl2/webgl2-paintable-canvas"
import {createHalImage} from "@common/models/hal/hal-image/create"
import {HalImage} from "@common/models/hal/hal-image"

@Component({
    selector: "cm-webgl2-canvas",
    templateUrl: "./webgl2-canvas.component.html",
    styleUrls: ["./webgl2-canvas.component.scss"],
})
export class WebGl2CanvasComponent implements OnInit, OnDestroy {
    readonly $webGlCanvasElementRef = viewChild.required<ElementRef>("webGlCanvasElement")

    readonly resized = output<Vector2>()

    // OnInit
    ngOnInit() {
        const canvas = this.$webGlCanvasElementRef().nativeElement
        if (!canvas) {
            throw Error("Canvas not found")
        }
        this._canvas = canvas
        this._halContext = WebGl2Context.createFromCanvas(this._canvas)

        this._paintableCanvas = new WebGl2PaintableCanvas(this._halContext, this._canvas)
        this._backBufferImage = createHalImage(this._halContext, {
            width: 256,
            height: 256,
            channelLayout: "RGBA",
            dataType: "uint8srgb",
        })
        this._painterImageFinalConversion = HalPainterImageFinalConversion.create(this._halContext)

        this.resizeObserver = new ResizeObserver(async (entries) => {
            const entry = entries.find((entry) => entry.target === this.canvas)
            if (!entry) {
                throw Error("ResizeObserver: entry not found")
            }
            let width: number
            let height: number
            if (entry.devicePixelContentBoxSize) {
                width = Math.round(entry.devicePixelContentBoxSize[0].inlineSize)
                height = Math.round(entry.devicePixelContentBoxSize[0].blockSize)
            } else {
                width = Math.round(entry.contentRect.width * devicePixelRatio)
                height = Math.round(entry.contentRect.height * devicePixelRatio)
            }
            await this.resizeCanvasToDisplaySize(width, height)
            this.resized.emit(new Vector2(width, height))
        })
        try {
            this.resizeObserver.observe(this._canvas, {box: "device-pixel-content-box"})
            // eslint-disable-next-line unused-imports/no-unused-vars
        } catch (_error: unknown) {
            console.warn("'device-pixel-content-box' feature not supported; try the established way (which may lead to blurry images)")
            this.resizeObserver.observe(this._canvas, {box: "content-box"})
        }
    }

    // OnDestroy
    ngOnDestroy(): void {
        this.resizeObserver?.disconnect()

        this._backBufferImage.dispose()
        this._painterImageFinalConversion.dispose()
        this._paintableCanvas.dispose()

        this._halContext.dispose()
    }

    get canvas(): HTMLCanvasElement {
        if (!this._canvas) {
            throw Error("Canvas not initialized")
        }
        return this._canvas
    }

    get halContext(): HalContext {
        if (!this._halContext) {
            throw Error("WebGlCanvas not initialized")
        }
        return this._halContext
    }

    get backBufferImage(): HalImage {
        return this._backBufferImage
    }

    async clearBackBuffer(): Promise<void> {
        await this._backBufferImage.clear({r: 0, g: 0, b: 0, a: 0})
    }

    async presentBackBuffer(convertToSRGB: boolean, gamma: number): Promise<void> {
        // blit the sRgb image to the canvas
        this._painterImageFinalConversion.convertToSrgb = convertToSRGB
        this._painterImageFinalConversion.gamma = gamma
        this._painterImageFinalConversion.paint(this._paintableCanvas, this._backBufferImage)
        await this._halContext.flush(true)
    }

    private async resizeCanvasToDisplaySize(displayWidth: number, displayHeight: number) {
        const needResize = this._allocatedSize.x !== displayWidth || this._allocatedSize.y !== displayHeight
        if (!needResize) {
            return
        }
        this._allocatedSize = new Vector2(displayWidth, displayHeight)
        this.canvas.width = displayWidth
        this.canvas.height = displayHeight
        this._backBufferImage.dispose()
        this._backBufferImage = createHalImage(this.halContext, {
            width: displayWidth,
            height: displayHeight,
            channelLayout: "RGBA",
            dataType: "uint8srgb",
        })
    }

    private _canvas!: HTMLCanvasElement
    private _halContext!: HalContext
    private resizeObserver!: ResizeObserver
    // these are the gl elements for the linear color space framebuffer (the main render target)
    private _paintableCanvas!: HalPaintable
    private _backBufferImage!: HalImage
    private _painterImageFinalConversion!: HalPainterImageFinalConversion
    private _allocatedSize = new Vector2(0, 0)
}
