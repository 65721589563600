import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ModelListItemFragment = { __typename: 'Model', id: string, legacyId: number, articleId?: string | null, name?: string | null, nextActor: Types.NextActor, state: Types.ModelState, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string, legacyId: number } | null } }>, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type GetModelsQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.ModelFilterInput>;
  orderBy?: Types.InputMaybe<Array<Types.ModelOrderByInput> | Types.ModelOrderByInput>;
}>;


export type GetModelsQuery = { __typename: 'Query', modelsCount: number, models: Array<{ __typename: 'Model', id: string, legacyId: number, articleId?: string | null, name?: string | null, nextActor: Types.NextActor, state: Types.ModelState, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string, legacyId: number } | null } }>, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export type CreateModelMutationVariables = Types.Exact<{
  input: Types.MutationCreateModelInput;
}>;


export type CreateModelMutation = { __typename: 'Mutation', createModel: { __typename: 'Model', id: string, legacyId: number, articleId?: string | null, name?: string | null, nextActor: Types.NextActor, state: Types.ModelState, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string, legacyId: number } | null } }>, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type UpdateModelMutationVariables = Types.Exact<{
  input: Types.MutationUpdateModelInput;
}>;


export type UpdateModelMutation = { __typename: 'Mutation', updateModel: { __typename: 'Model', id: string, legacyId: number, articleId?: string | null, name?: string | null, nextActor: Types.NextActor, state: Types.ModelState, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string, legacyId: number } | null } }>, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type BatchUpdateModelsMutationVariables = Types.Exact<{
  filter: Types.ModelFilterInput;
  state?: Types.InputMaybe<Types.ModelState>;
  addTagId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  removeTagId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  paymentState?: Types.InputMaybe<Types.PaymentState>;
  assignUserId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  removeUserAssignment?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  nextActor?: Types.InputMaybe<Types.NextActor>;
}>;


export type BatchUpdateModelsMutation = { __typename: 'Mutation', batchUpdateModels: number };

export const ModelListItemFragmentDoc = gql`
    fragment ModelListItem on Model {
  __typename
  id
  legacyId
  articleId
  name
  nextActor
  state
  organization {
    id
    legacyId
    name
  }
  currentUserAssignment {
    id
    firstName
    lastName
    galleryImage {
      id
    }
  }
  tagAssignments {
    tag {
      id
      legacyId
      createdBy {
        id
      }
      name
      description
      color
      type
      organization {
        id
        legacyId
      }
    }
  }
  taskInfos {
    inProgress
    waitingForFeedback
    completed
    archived
  }
  galleryImage {
    id
  }
}
    `;
export const GetModelsDocument = gql`
    query getModels($skip: Int, $take: Int, $filter: ModelFilterInput, $orderBy: [ModelOrderByInput!]) {
  models(skip: $skip, take: $take, filter: $filter, orderBy: $orderBy) {
    ...ModelListItem
  }
  modelsCount(filter: $filter)
}
    ${ModelListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetModelsGQL extends Apollo.Query<GetModelsQuery, GetModelsQueryVariables> {
    override document = GetModelsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateModelDocument = gql`
    mutation createModel($input: MutationCreateModelInput!) {
  createModel(input: $input) {
    ...ModelListItem
  }
}
    ${ModelListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateModelGQL extends Apollo.Mutation<CreateModelMutation, CreateModelMutationVariables> {
    override document = CreateModelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateModelDocument = gql`
    mutation updateModel($input: MutationUpdateModelInput!) {
  updateModel(input: $input) {
    ...ModelListItem
  }
}
    ${ModelListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateModelGQL extends Apollo.Mutation<UpdateModelMutation, UpdateModelMutationVariables> {
    override document = UpdateModelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchUpdateModelsDocument = gql`
    mutation batchUpdateModels($filter: ModelFilterInput!, $state: ModelState, $addTagId: ID, $removeTagId: ID, $paymentState: PaymentState, $assignUserId: ID, $removeUserAssignment: Boolean, $nextActor: NextActor) {
  batchUpdateModels(
    filter: $filter
    addTagId: $addTagId
    assignUserId: $assignUserId
    paymentState: $paymentState
    removeTagId: $removeTagId
    removeUserAssignment: $removeUserAssignment
    state: $state
    nextActor: $nextActor
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchUpdateModelsGQL extends Apollo.Mutation<BatchUpdateModelsMutation, BatchUpdateModelsMutationVariables> {
    override document = BatchUpdateModelsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }