import {inject, Injectable} from "@angular/core"
import {MaterialsCardFragment, MaterialsCardDataGQL} from "@common/services/material-card/materials-card.service.generated"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {ApiRequest} from "@common/models/api-request/api-request"
import {BatchApiCallById} from "@common/helpers/batch-api-call/batch-api-call-by-id"
import {IsUnique} from "@cm/utils/filter"

export type RequestPayload = {
    id: string
}

export type ResponsePayload = MaterialsCardFragment

export type BatchedRequestPayload = {
    requests: ApiRequest<RequestPayload, ResponsePayload>[]
}

@Injectable({
    providedIn: "root",
})
export class MaterialsCardService extends BatchApiCallById<RequestPayload, ResponsePayload, BatchedRequestPayload> {
    constructor() {
        super()
    }

    private readonly materialsCardData = inject(MaterialsCardDataGQL)

    protected batchRequests(requests: ApiRequest<RequestPayload, ResponsePayload>[]): BatchedRequestPayload[] {
        return [{requests}]
    }

    protected async callApi(payload: BatchedRequestPayload): Promise<(ResponsePayload | undefined | null)[]> {
        const ids = payload.requests.map((request) => request.payload.id).filter(IsUnique)
        return fetchThrowingErrors(this.materialsCardData)({ids}).then((response) => response.materials)
    }
}
