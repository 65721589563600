import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type JobTaskDetailsFragment = { __typename: 'JobTask', id: string, legacyId: number, claimedBy?: string | null, deferredUntil?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, started?: string | null, state: Types.JobTaskState, taskType?: string | null, createdBy: { __typename: 'User', id: string, legacyId: number, name: string }, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, job?: { __typename: 'JobTask', id: string, legacyId: number, name?: string | null } | null };

export type JobTaskDetailsQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type JobTaskDetailsQuery = { __typename: 'Query', item: { __typename: 'JobTask', id: string, legacyId: number, claimedBy?: string | null, deferredUntil?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, started?: string | null, state: Types.JobTaskState, taskType?: string | null, createdBy: { __typename: 'User', id: string, legacyId: number, name: string }, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, job?: { __typename: 'JobTask', id: string, legacyId: number, name?: string | null } | null } };

export type UpdateJobTaskDetailsMutationVariables = Types.Exact<{
  input: Types.MutationUpdateJobTaskInput;
}>;


export type UpdateJobTaskDetailsMutation = { __typename: 'Mutation', item: { __typename: 'JobTask', id: string, legacyId: number, claimedBy?: string | null, deferredUntil?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, started?: string | null, state: Types.JobTaskState, taskType?: string | null, createdBy: { __typename: 'User', id: string, legacyId: number, name: string }, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, job?: { __typename: 'JobTask', id: string, legacyId: number, name?: string | null } | null } };

export const JobTaskDetailsFragmentDoc = gql`
    fragment JobTaskDetails on JobTask {
  __typename
  id
  legacyId
  claimedBy
  deferredUntil
  finished
  graph
  message
  name
  started
  state
  taskType
  createdBy {
    id
    legacyId
    name
  }
  organization {
    id
    legacyId
    name
  }
  job {
    id
    legacyId
    name
  }
}
    `;
export const JobTaskDetailsDocument = gql`
    query jobTaskDetails($id: ID, $legacyId: Int) {
  item: jobTask(id: $id, legacyId: $legacyId) {
    ...JobTaskDetails
  }
}
    ${JobTaskDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobTaskDetailsGQL extends Apollo.Query<JobTaskDetailsQuery, JobTaskDetailsQueryVariables> {
    override document = JobTaskDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateJobTaskDetailsDocument = gql`
    mutation updateJobTaskDetails($input: MutationUpdateJobTaskInput!) {
  item: updateJobTask(input: $input) {
    ...JobTaskDetails
  }
}
    ${JobTaskDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateJobTaskDetailsGQL extends Apollo.Mutation<UpdateJobTaskDetailsMutation, UpdateJobTaskDetailsMutationVariables> {
    override document = UpdateJobTaskDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }