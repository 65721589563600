import {Component, input, output} from "@angular/core"

@Component({
    selector: "cm-list-item",
    templateUrl: "./list-item.component.html",
    styleUrls: ["./list-item.component.scss"],
    host: {
        "[class.cm-list-item-selected]": "$selected()",
        "[class.cm-list-item-disabled]": "$disabled()",
        "[class.cm-list-item-highlighted]": "$highlighted()",
    },
})
export class ListItemComponent {
    readonly $selected = input(false, {alias: "selected"})
    readonly $disabled = input(false, {alias: "disabled"})
    readonly $highlighted = input(false, {alias: "highlighted"})
    readonly $expandable = input(false, {alias: "expandable"})
    readonly $expanded = input(false, {alias: "expanded"})
    readonly arrowToggle = output()
}
