import {Pipe, PipeTransform} from "@angular/core"
import filesize from "filesize"

@Pipe({
    name: "filesize",
})
export class FilesizePipe implements PipeTransform {
    private static transformValue(value: number | string, options: {base?: number} = {}) {
        return filesize(Number(value), options)
    }

    transform(value: number | number[] | string | string[], options?: {base?: number}) {
        if (Array.isArray(value)) {
            return value.map((val) => FilesizePipe.transformValue(val, options))
        }

        return FilesizePipe.transformValue(value, options)
    }
}
