import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {BoundaryCurveItem, ChangeEvent} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-curve-item"
import {Vector2, Vector2Like} from "@cm/math"
import {EventEmitter} from "@angular/core"
import {TilingControlPointType} from "@app/textures/texture-editor/texture-edit-nodes"
import {SpatialMappingItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/spatial-mapping-item"
import {isApple} from "@common/helpers/device-browser-detection/device-browser-detection"
import {CurveVizItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/basic/curve-viz-item"
import {IsUnique} from "@cm/utils"
import {ToolMouseEvent} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export class BoundaryItem extends CanvasBaseToolboxItemBase<SpatialMappingItem> {
    readonly boundaryChanged = new EventEmitter<ChangeEvent>()

    constructor(
        readonly spatialMapping: SpatialMappingItem,
        readonly direction: BoundaryDirection,
        flipLabelSide: boolean,
    ) {
        super(spatialMapping)
        this._curves = [this.createCurve({flipLabelSide: flipLabelSide}), this.createCurve({flipLabelSide: !flipLabelSide})]

        this.setEditMode(this.defaultEditMode)
    }

    get curveMin() {
        return this._curves[0]
    }

    get curveMax() {
        return this._curves[1]
    }

    get mappedLength() {
        this.updateResultMapping()
        return this._mappedLength
    }

    insertControlPoints(type: TilingControlPointType, t: number, controlPoint0Position?: Vector2Like, controlPoint1Position?: Vector2Like) {
        this._suppressChangeEmission = true

        controlPoint0Position ??= this.spatialMapping.mapUVToSourceSpace(
            new Vector2(this.direction === BoundaryDirection.Horizontal ? t : 0, this.direction === BoundaryDirection.Vertical ? t : 0),
        )
        controlPoint1Position ??= this.spatialMapping.mapUVToSourceSpace(
            new Vector2(this.direction === BoundaryDirection.Horizontal ? t : 1, this.direction === BoundaryDirection.Vertical ? t : 1),
        )

        // create control points
        const pointVizItem0 = this.spatialMapping.pointVizBagItem.createControlPoint({
            type,
            sourcePosition: controlPoint0Position,
            resultUV: {
                x: this.direction === BoundaryDirection.Horizontal ? t : 0,
                y: this.direction === BoundaryDirection.Vertical ? t : 0,
            },
            deletable: true,
        })
        const pointVizItem1 = this.spatialMapping.pointVizBagItem.createControlPoint({
            type,
            sourcePosition: controlPoint1Position,
            resultUV: {
                x: this.direction === BoundaryDirection.Horizontal ? t : 1,
                y: this.direction === BoundaryDirection.Vertical ? t : 1,
            },
            deletable: true,
        })
        // identify control points
        this.spatialMapping.pointVizBagItem.identifyControlPoints([pointVizItem0, pointVizItem1])

        // insert control points
        const curveControlPoint0 = this._curves[0].createControlPoint(pointVizItem0, t)
        const curveControlPoint1 = this._curves[1].createControlPoint(pointVizItem1, t)

        this._suppressChangeEmission = false
        this.boundaryChanged.emit({type: "point-added"})

        return {curveControlPoint0, curveControlPoint1}
    }

    override onKeyDown(event: KeyboardEvent): boolean {
        if (event.key === this.alternateDragKey) {
            this.setEditMode(this.alternateEditMode)
        }
        return false
    }

    override onKeyUp(event: KeyboardEvent): boolean {
        if (event.key === this.alternateDragKey) {
            this.setEditMode(this.defaultEditMode)
        }
        return false
    }

    private onCurveClicked(boundaryCurveItem: BoundaryCurveItem, event: {position: Vector2; t: number}) {
        if (this._editMode !== "insert-point") {
            return
        }
        // find boundary and insert control points
        const {curveControlPoint0, curveControlPoint1} = this.insertControlPoints("user", event.t)
        // mark the control point that was clicked as being touched
        switch (boundaryCurveItem) {
            case this._curves[0]:
                curveControlPoint0.controlPoint.item.markTouched()
                break
            case this._curves[1]:
                curveControlPoint1.controlPoint.item.markTouched()
                break
        }
    }

    private onCurveDragging(boundaryCurveItem: BoundaryCurveItem, event: ToolMouseEvent) {
        if (this._editMode !== "move-boundary") {
            return
        }
        // compute the new curve's t-value along the perpendicular curves
        const sourceSpacePoint = this.spatialMapping.mapScreenSpacePositionToSourceSpace(event.point)
        const uv = this.spatialMapping.interpolator.solveForUV(sourceSpacePoint)
        if (!uv) {
            return // solve failed
        }
        const isHorizontal = this.direction === BoundaryDirection.Horizontal
        const isMinCurve = boundaryCurveItem === this._curves[0]
        let otherT = isHorizontal ? uv.y : uv.x
        const minPxDelta = 10 // minimum width of the resized boundary
        const minTDelta = minPxDelta / (isHorizontal ? this.spatialMapping.mappedSize.y : this.spatialMapping.mappedSize.x)
        if (isMinCurve) {
            otherT = Math.min(otherT, 1 - minTDelta)
        } else {
            otherT = Math.max(otherT, minTDelta)
        }
        const tValues = boundaryCurveItem.tValues
        const curvePoints = tValues.map((t) => this.spatialMapping.mapUVToScreenSpace(isHorizontal ? new Vector2(t, otherT) : new Vector2(otherT, t)))
        const shiftedCurveVizItem = new CurveVizItem(this, 2, false)
        shiftedCurveVizItem.color = {r: 1, g: 1, b: 1, a: 1}
        shiftedCurveVizItem.showLabel = false
        shiftedCurveVizItem.setCurvePoints(curvePoints, tValues)
        this._curveShiftInfo?.curveVizItem.remove()
        this._curveShiftInfo = {
            curveVizItem: shiftedCurveVizItem,
            shiftedT: otherT,
        }
    }

    private onCurveDragFinished(boundaryCurveItem: BoundaryCurveItem) {
        if (this._curveShiftInfo) {
            this.shiftCurve(boundaryCurveItem, this._curveShiftInfo.shiftedT)
            this._curveShiftInfo.curveVizItem.remove()
            this._curveShiftInfo = undefined
        }
    }

    private shiftCurve(boundaryCurveItem: BoundaryCurveItem, shiftedT: number) {
        this._suppressChangeEmission = true
        // compute new control point positions
        const isHorizontal = this.direction === BoundaryDirection.Horizontal
        const isMinCurve = boundaryCurveItem === this._curves[0]
        const newControlPointSourceSpacePositions = boundaryCurveItem.curveControlPoints.map((controlPoint) => {
            const uv = isHorizontal ? new Vector2(controlPoint.t, shiftedT) : new Vector2(shiftedT, controlPoint.t)
            return this.spatialMapping.mapUVToSourceSpace(uv)
        })
        // determine intermediate control points on the other boundary to drop
        const otherBoundary = this.spatialMapping.boundaryH === this ? this.spatialMapping.boundaryV : this.spatialMapping.boundaryH
        const controlPointsToKeep = new Set(boundaryCurveItem.curveControlPoints.map((curveControlPoint) => curveControlPoint.controlPoint))
        const otherControlPointsToRemove = otherBoundary.curveMin.curveControlPoints
            .filter(
                (curveControlPoint) =>
                    !controlPointsToKeep.has(curveControlPoint.controlPoint) && (isMinCurve ? curveControlPoint.t < shiftedT : curveControlPoint.t > shiftedT),
            )
            .map((controlPoint) => controlPoint.controlPoint)
            .filter(IsUnique)
        // remove control points
        this.spatialMapping.pointVizBagItem.deleteControlPoints(otherControlPointsToRemove)
        // move control points to new positions
        boundaryCurveItem.curveControlPoints.forEach((curveControlPoint, index) => {
            curveControlPoint.controlPoint.sourcePosition = newControlPointSourceSpacePositions[index]
        })
        this._suppressChangeEmission = false
        // notify
        this.boundaryChanged.emit({type: "point-source-position-moved"})
    }

    private setEditMode(editMode: EditMode) {
        this._editMode = editMode
        // set cursors
        this._curves.forEach((curve) => {
            let cursor: string
            if (this._editMode === "move-boundary") {
                const firstCurveControlPoint = curve.curveControlPoints[0]
                const lastCurveControlPoint = curve.curveControlPoints[curve.curveControlPoints.length - 1]
                const edgeDirection = Vector2.fromVector2Like(lastCurveControlPoint.controlPoint.sourcePosition).subInPlace(
                    firstCurveControlPoint.controlPoint.sourcePosition,
                )
                const isVertical = Math.abs(edgeDirection.x) < Math.abs(edgeDirection.y)
                cursor = isVertical ? "ew-resize" : "ns-resize"
            } else {
                cursor = "crosshair"
            }
            curve.curveVisItem.cursor = cursor
        })
    }

    private createCurve(curveInfo: {flipLabelSide: boolean}) {
        const boundaryCurveItem = new BoundaryCurveItem(this, curveInfo.flipLabelSide)
        boundaryCurveItem.curveChanged.subscribe((event) => this.onCurveChanged(event))
        boundaryCurveItem.curveVisItem.clicked.subscribe((event) => this.onCurveClicked(boundaryCurveItem, event))
        boundaryCurveItem.curveVisItem.dragging.subscribe((event) => this.onCurveDragging(boundaryCurveItem, event))
        boundaryCurveItem.curveVisItem.dragFinished.subscribe(() => this.onCurveDragFinished(boundaryCurveItem))
        this.invalidateResultMapping()
        return boundaryCurveItem
    }

    private onCurveChanged(event: ChangeEvent) {
        if (event.type !== "point-result-uv-moved") {
            this.invalidateResultMapping()
        }
        if (!this._suppressChangeEmission) {
            this.boundaryChanged.emit(event)
        }
    }

    private invalidateResultMapping() {
        this._needsUpdateResultMapping = true
    }

    private updateResultMapping() {
        if (!this._needsUpdateResultMapping) {
            return
        }
        this._needsUpdateResultMapping = false

        // update all curves
        const curveCumulativeArcLengths = this._curves.map((curve) => curve.computeCurveCumulativeArcLengths())

        // compute curve arc lengths
        const curveArcLengths = curveCumulativeArcLengths.map((curveCumulativeArcLength) => curveCumulativeArcLength[curveCumulativeArcLength.length - 1])

        // compute average arc length
        const avgCurveLength = curveArcLengths.reduce((sum, curveArcLength) => sum + curveArcLength, 0) / curveArcLengths.length
        this._mappedLength = Math.ceil(avgCurveLength)

        // no normalization if any curve has zero length
        const containsZeroArcLength = curveArcLengths.some((arcLength) => arcLength === 0)
        if (!containsZeroArcLength) {
            // adjust control points t-values to the average of all identified control points
            const tValues = curveCumulativeArcLengths.map((curveCumulativeArcLength, index) =>
                curveCumulativeArcLength.map((arcLength) => arcLength / curveArcLengths[index]),
            )
            for (let i = 0; i < tValues[0].length; i++) {
                const avgT = tValues.reduce((sum, t) => sum + t[i], 0) / tValues.length
                this._curves.forEach((curve) => {
                    const controlPoint = curve.curveControlPoints[i]
                    // we only align the t values of user points
                    // if (controlPoint.controlPoint.type === "user") {
                    controlPoint.t = avgT
                    // }
                })
            }
        }
    }

    private _needsUpdateResultMapping = true
    private readonly _curves: [BoundaryCurveItem, BoundaryCurveItem]
    private _curveShiftInfo?: CurveShiftInfo
    private _mappedLength = 0
    private _suppressChangeEmission = false
    private _editMode: EditMode = "insert-point"

    private readonly alternateDragKey = isApple ? "Meta" : "Alt"
    private readonly defaultEditMode: EditMode = "insert-point"
    private readonly alternateEditMode: EditMode = "move-boundary"
}

export enum BoundaryDirection {
    Horizontal = 0,
    Vertical = 1,
}

type EditMode = "insert-point" | "move-boundary"

type CurveShiftInfo = {
    curveVizItem: CurveVizItem
    shiftedT: number
}
