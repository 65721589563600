import {Injector} from "@angular/core"
import {getAllGlftAssignments, deleteDataObjectAssignment, deleteDataObject} from "@app/common/helpers/ar/ar"
import {BackendArGenerationTemplateRevisionFragment} from "@pages/ar-generation/backend-ar-generation.generated"

export const removeCachedArData = async (templateRevision: BackendArGenerationTemplateRevisionFragment, injector: Injector): Promise<void> => {
    for (const gltfAssignment of templateRevision.gltfAssignments) await deleteDataObjectAssignment(gltfAssignment.id, injector)

    for (const gltfAssignment of templateRevision.gltfAssignments) {
        const allAssignments = await getAllGlftAssignments(gltfAssignment.dataObject.id, injector)
        if (allAssignments.length !== 0) return

        await deleteDataObject(gltfAssignment.dataObject.id, injector) //related ones are cascade-deleted
    }
}
