import {TilingControlPointType} from "@app/textures/texture-editor/texture-edit-nodes"
import {PointVizItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/basic/point-viz-item"
import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {Matrix3x2, rad2deg, Vector2, Vector2Like} from "@cm/math"
import {SpatialMappingItem, ViewMode} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/spatial-mapping-item"
import {auditTime, buffer, merge, Subject, takeUntil, tap} from "rxjs"
import {EventEmitter} from "@angular/core"
import {isApple} from "@common/helpers/device-browser-detection/device-browser-detection"
import {assertNever} from "@cm/utils"
import {BoundaryControlPoint} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-control-point"
import {ToolMouseEvent} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export class BoundaryControlPointBagItem extends CanvasBaseToolboxItemBase<SpatialMappingItem> {
    readonly pointCreated = new EventEmitter<BoundaryControlPoint>()
    readonly pointRemoved = new EventEmitter<BoundaryControlPoint>()
    readonly pointMoved = new EventEmitter<BoundaryControlPoint>()
    readonly dragBegin = new EventEmitter<void>()
    readonly dragEnd = new EventEmitter<void>()

    constructor(
        readonly spatialMapping: SpatialMappingItem,
        private computeSnapPosition: ComputeSnapPositionFn,
    ) {
        super(spatialMapping)
        merge(this.spatialMapping.mappingChanged, this.spatialMapping.viewModeChanged)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.onDisplayedMappingChanged())

        this._snapControlPointItem = new PointVizItem(this, {type: "snap", radius: SNAP_CIRCLE_RADIUS, color: SNAP_CIRCLE_COLOR, movable: false})
        this._snapControlPointItem.visible = false

        // this is a bit complicated, but it tries to achieve batching of updates without creating constant high-frequency timers which cause angular change detection to run constantly, costing a lot of performance
        let closingTimerId: Timer | undefined = undefined
        const closingNotifier = new Subject<void>()
        this._synchronizePointVizItemPosition$
            .pipe(
                tap(() => {
                    // create a new timer on first value, if none is running already and buffer values until timeout fires
                    if (closingTimerId === undefined) {
                        closingTimerId = setTimeout(() => {
                            closingTimerId = undefined
                            closingNotifier.next()
                        }, 10)
                    }
                }),
                buffer(closingNotifier), // batch multiple updates
                takeUntil(this.unsubscribe),
            )
            .subscribe((controlPoints) => this.updatePointVizItemPositions(controlPoints))

        this._updateSnapPosition$.pipe(auditTime(0)).subscribe(async (event) => {
            const snapPosition = await this.computeSnapPosition(event)
            this.setSnapPosition(snapPosition)
        })

        this.setEditMode(this.defaultEditMode)
    }

    createControlPoint(pointDesc: ControlPointDesc): BoundaryControlPoint {
        let radius: number
        let color: string
        let movable: boolean
        switch (pointDesc.type) {
            case "user":
                radius = USER_CONTROL_POINT_RADIUS
                color = USER_CONTROL_POINT_COLOR
                movable = true
                break
            case "alignment":
                radius = ALIGNMENT_CONTROL_POINT_RADIUS
                color = ALIGNMENT_CONTROL_POINT_COLOR
                movable = false
                break
        }
        const pointVizItem = new PointVizItem(this, {type: pointDesc.type, radius, color, movable})
        const controlPoint = new BoundaryControlPoint(pointDesc.type, pointDesc.sourcePosition, pointDesc.resultUV, pointVizItem, pointDesc.deletable)
        this._controlPoints.push(controlPoint)
        pointVizItem.cursor = "move"
        pointVizItem.dragging.subscribe((event) => this.onControlPointDragging(controlPoint, event))
        pointVizItem.dragFinished.subscribe(() => this.onControlPointDragFinished(controlPoint))
        controlPoint.sourcePositionChanged.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.pointMoved.emit(controlPoint)
            this._synchronizePointVizItemPosition$.next(controlPoint)
        })
        this._synchronizePointVizItemPosition$.next(controlPoint)
        pointVizItem.selectedChange.subscribe((selected) => {
            // inform all identified points about the selection change
            const identifiedControlPoints = this.getIdentifiedControlPoints(controlPoint)
            identifiedControlPoints.forEach(
                (controlPoint) => (controlPoint.item.color = selected ? USER_CONTROL_POINT_COLOR_IDENTIFIED_SELECTED : USER_CONTROL_POINT_COLOR),
            )
        })
        this._snapControlPointItem.bringToFront() // keep snap control point on top
        this.pointCreated.emit(controlPoint)
        return controlPoint
    }

    identifyControlPoints(controlPoints: BoundaryControlPoint[]) {
        for (const pointVizItem of controlPoints) {
            for (const otherPointVizItem of controlPoints) {
                if (pointVizItem === otherPointVizItem) {
                    continue
                }
                const identifiedControlPointItems = this._identifiedControlPointsItemsByControlPoint.get(pointVizItem)
                if (!identifiedControlPointItems) {
                    this._identifiedControlPointsItemsByControlPoint.set(pointVizItem, new Set([otherPointVizItem]))
                } else {
                    identifiedControlPointItems.add(otherPointVizItem)
                }
            }
        }
    }

    deleteControlPoints(controlPoints: BoundaryControlPoint[]) {
        const itemsToDelete = new Set<BoundaryControlPoint>()
        // delete identified points too
        controlPoints.forEach((controlPoint) => {
            itemsToDelete.add(controlPoint)
            const identifiedControlPoints = this.getIdentifiedControlPoints(controlPoint)
            identifiedControlPoints.forEach((identifiedControlPointItem) => itemsToDelete.add(identifiedControlPointItem))
        })
        // delete control points
        for (const controlPointItem of itemsToDelete) {
            this.deleteControlPoint(controlPointItem)
        }
    }

    deleteSelectedControlPoints() {
        const controlPointsToDelete = this._controlPoints.filter((controlPoint) => controlPoint.deletable && controlPoint.item.selected)
        this.deleteControlPoints(controlPointsToDelete)
    }

    deleteControlPointsOfType(type: TilingControlPointType) {
        const controlPointsToDelete = this._controlPoints.filter((controlPoint) => controlPoint.type === type)
        this.deleteControlPoints(controlPointsToDelete)
    }

    hasControlPointsOfType(type: TilingControlPointType) {
        return this._controlPoints.some((controlPoint) => controlPoint.type === type)
    }

    // setControlPointPositionInScreenSpace(pointVizItem: PointVizItem, position: Vector2Like) {
    //     pointVizItem.position = position
    //     const mappedPosition = this.spatialMapping.mapScreenSpacePositionToSourceSpace(position)
    //     this._controlPointsByItem.get(pointVizItem)?.forEach((controlPoint) => (controlPoint.sourcePosition = mappedPosition))
    // }

    offsetControlPointPositionInScreenSpace(controlPoint: BoundaryControlPoint, offset: Vector2Like) {
        controlPoint.item.position = controlPoint.item.position.add(offset)
        const mappedOffset = this.spatialMapping.mapScreenSpaceOffsetToSourceSpace(controlPoint.item.position, offset)
        controlPoint.sourcePosition = Vector2.fromVector2Like(controlPoint.sourcePosition).addInPlace(mappedOffset)
    }

    offsetSelectedControlPointsInScreenSpace(offset: Vector2Like) {
        this._controlPoints
            .filter((controlPoint) => controlPoint.item.selected)
            .forEach((controlPoint) => this.offsetControlPointPositionInScreenSpace(controlPoint, offset))
    }

    get isDraggingControlPoint() {
        return this._isDraggingControlPoint
    }

    override onKeyDown(event: KeyboardEvent): boolean {
        if (event.key === this.alternateDragKey) {
            this.setEditMode(this.alternateEditMode)
        }
        return false
    }

    override onKeyUp(event: KeyboardEvent): boolean {
        if (event.key === this.alternateDragKey) {
            this.setEditMode(this.defaultEditMode)
        }
        return false
    }

    private setEditMode(editMode: EditMode) {
        this._editMode = editMode
        // set control point cursors
        let cursor: string
        switch (editMode) {
            case "move-points":
                cursor = "move"
                break
            case "rotate-points":
                cursor = "nesw-resize"
                break
            default:
                assertNever(editMode)
        }
        this._controlPoints.forEach((controlPoint) => (controlPoint.item.cursor = cursor))
    }

    private onDisplayedMappingChanged() {
        this._controlPoints.forEach((controlPoint) => this._synchronizePointVizItemPosition$.next(controlPoint))
    }

    private updatePointVizItemPositions(controlPoints: BoundaryControlPoint[]) {
        controlPoints.forEach((controlPoint) => this.updatePointVizItemPosition(controlPoint))
    }

    private updatePointVizItemPosition(controlPoint: BoundaryControlPoint) {
        controlPoint.item.position = this.spatialMapping.mapUVToScreenSpace(controlPoint.resultUV)
    }

    private deleteControlPoint(controlPoint: BoundaryControlPoint) {
        const index = this._controlPoints.indexOf(controlPoint)
        if (index < 0) {
            throw new Error("Control point not found")
        }
        this._controlPoints.splice(index, 1)
        this.pointRemoved.emit(controlPoint)
        controlPoint.item.remove()
    }

    private getIdentifiedControlPoints(controlPoint: BoundaryControlPoint): Set<BoundaryControlPoint> {
        const identifiedControlPointItems = this._identifiedControlPointsItemsByControlPoint.get(controlPoint)
        if (!identifiedControlPointItems) {
            return new Set()
        }
        return identifiedControlPointItems
    }

    private onControlPointDragging(controlPoint: BoundaryControlPoint, event: ToolMouseEvent) {
        this.dragBegin.emit()
        this._isDraggingControlPoint = true
        switch (this._editMode) {
            case "move-points":
                this.moveControlPoint(controlPoint, event)
                break
            case "rotate-points":
                this.rotateControlPoints(controlPoint, event)
                break
            default:
                assertNever(this._editMode)
        }
    }

    private moveControlPoint(controlPoint: BoundaryControlPoint, event: ToolMouseEvent) {
        this.offsetControlPointPositionInScreenSpace(controlPoint, event.delta)
        this.triggerUpdateSnapPosition(controlPoint)
        // this.showSnapRadius(controlPoint.item.position)
    }

    private rotateControlPoints(controlPoint: BoundaryControlPoint, event: ToolMouseEvent) {
        // TODO recalc of rotation center seems costly if there are a lot of them
        const rotationCenter = this._controlPoints
            .reduce((sum, controlPoint) => sum.add(controlPoint.sourcePosition), new Vector2())
            .div(this._controlPoints.length)
        const angleMeasureCenter = this._controlPoints
            .reduce((sum, controlPointItem) => sum.add(controlPointItem.item.position), new Vector2())
            .div(this._controlPoints.length)
        const mousePosition = this.spatialMapping.viewMode === ViewMode.Source ? event.point : controlPoint.item.position.sub(event.delta)
        const controlPointPosition = this.spatialMapping.viewMode === ViewMode.Source ? controlPoint.sourcePosition : controlPoint.item.position
        const rotationAngle = rad2deg(
            -Math.asin(
                Vector2.cross(
                    Vector2.fromVector2Like(mousePosition).subInPlace(angleMeasureCenter).normalized(),
                    Vector2.fromVector2Like(controlPointPosition).subInPlace(angleMeasureCenter).normalized(),
                ),
            ),
        )
        const rotationMatrix = new Matrix3x2().rotate(rotationAngle)
        this._controlPoints.forEach(
            (controlPoint) =>
                (controlPoint.sourcePosition = rotationMatrix
                    .multiplyVector(Vector2.fromVector2Like(controlPoint.sourcePosition).sub(rotationCenter))
                    .add(rotationCenter)),
        )
    }

    private onControlPointDragFinished(controlPoint: BoundaryControlPoint) {
        const currentSnapPosition = this._currentSnapPosition
        if (currentSnapPosition) {
            controlPoint.sourcePosition = currentSnapPosition
            this.setSnapPosition(undefined)
        }
        this._isDraggingControlPoint = false
        this._updateSnapPosition$.next(undefined)
        this.dragEnd.emit()
    }

    private triggerUpdateSnapPosition(controlPoint: BoundaryControlPoint) {
        // find most recently used identified control point item
        const identifiedControlPoints = Array.from(this.getIdentifiedControlPoints(controlPoint))
        const referenceControlPoint = identifiedControlPoints
            .filter((controlPointItem) => controlPointItem.item.lastTouchedTimeStamp)
            .sort((a, b) => b.item.lastTouchedTimeStamp - a.item.lastTouchedTimeStamp)
            .find(() => true)
        if (referenceControlPoint) {
            referenceControlPoint.item.color = USER_CONTROL_POINT_COLOR_SNAP_REFERENCE
        }
        this._updateSnapPosition$.next({position: controlPoint.sourcePosition, referencePosition: referenceControlPoint?.sourcePosition})
    }

    private setSnapPosition(position: Vector2 | undefined) {
        this._currentSnapPosition = position
        if (this._currentSnapPosition && this._isDraggingControlPoint) {
            this._snapControlPointItem.position = this._currentSnapPosition
            this._snapControlPointItem.visible = true
        } else {
            this._snapControlPointItem.visible = false
        }
    }

    private _controlPoints = Array<BoundaryControlPoint>()
    private _identifiedControlPointsItemsByControlPoint = new Map<BoundaryControlPoint, Set<BoundaryControlPoint>>()

    private _snapControlPointItem: PointVizItem
    private _updateSnapPosition$ = new Subject<{position: Vector2; referencePosition: Vector2 | undefined} | undefined>()
    private _currentSnapPosition?: Vector2

    private _synchronizePointVizItemPosition$ = new Subject<BoundaryControlPoint>()
    private _isDraggingControlPoint = false
    private _editMode: EditMode = "move-points"

    private readonly alternateDragKey = isApple ? "Meta" : "Alt"
    private readonly defaultEditMode: EditMode = "move-points"
    private readonly alternateEditMode: EditMode = "rotate-points"
}

type EditMode = "move-points" | "rotate-points"

type ControlPointDesc = {
    type: TilingControlPointType
    sourcePosition: Vector2Like
    resultUV: Vector2Like
    deletable: boolean
}

export type ComputeSnapPositionFn = (parameters: {position: Vector2; referencePosition: Vector2 | undefined} | undefined) => Promise<Vector2 | undefined>

const _GUIDE_CONTROL_POINT_COLOR = "yellow"
const _GUIDE_CONTROL_POINT_RADIUS = 1

const ALIGNMENT_CONTROL_POINT_COLOR = "blue"
const ALIGNMENT_CONTROL_POINT_RADIUS = 2

const USER_CONTROL_POINT_RADIUS = 10
const USER_CONTROL_POINT_COLOR = "red"
const USER_CONTROL_POINT_COLOR_IDENTIFIED_SELECTED = "pink"

const SNAP_CIRCLE_COLOR = "lightgreen"
const SNAP_CIRCLE_RADIUS = 10
const USER_CONTROL_POINT_COLOR_SNAP_REFERENCE = SNAP_CIRCLE_COLOR
