import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ListInfoOrganizationLegacyIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ListInfoOrganizationLegacyIdQuery = { __typename: 'Query', organization: { __typename: 'Organization', legacyId: number } };

export type ListInfoTagFragment = { __typename: 'Tag', id: string, name: string };

export type ListInfoVisibleTagsQueryVariables = Types.Exact<{
  filter: Types.TagFilterInput;
}>;


export type ListInfoVisibleTagsQuery = { __typename: 'Query', tags: Array<{ __typename: 'Tag', id: string, name: string } | null> };

export type ListInfoUserFragment = { __typename: 'User', id: string, name: string };

export type ListInfoVisibleUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListInfoVisibleUsersQuery = { __typename: 'Query', users: Array<{ __typename: 'User', id: string, name: string } | null> };

export const ListInfoTagFragmentDoc = gql`
    fragment ListInfoTag on Tag {
  id
  name
}
    `;
export const ListInfoUserFragmentDoc = gql`
    fragment ListInfoUser on User {
  id
  name
}
    `;
export const ListInfoOrganizationLegacyIdDocument = gql`
    query listInfoOrganizationLegacyId($id: ID!) {
  organization(id: $id) {
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListInfoOrganizationLegacyIdGQL extends Apollo.Query<ListInfoOrganizationLegacyIdQuery, ListInfoOrganizationLegacyIdQueryVariables> {
    override document = ListInfoOrganizationLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListInfoVisibleTagsDocument = gql`
    query listInfoVisibleTags($filter: TagFilterInput!) {
  tags(filter: $filter, take: 9999) {
    ...ListInfoTag
  }
}
    ${ListInfoTagFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListInfoVisibleTagsGQL extends Apollo.Query<ListInfoVisibleTagsQuery, ListInfoVisibleTagsQueryVariables> {
    override document = ListInfoVisibleTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListInfoVisibleUsersDocument = gql`
    query listInfoVisibleUsers {
  users(filter: {visibleInFilters: true}, take: 9999) {
    ...ListInfoUser
  }
}
    ${ListInfoUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListInfoVisibleUsersGQL extends Apollo.Query<ListInfoVisibleUsersQuery, ListInfoVisibleUsersQueryVariables> {
    override document = ListInfoVisibleUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }