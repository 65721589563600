<div class="cm-tag-search-container">
    <cm-search
        [value]="(searchText$ | async)!"
        [placeholder]="$placeholder()"
        (focus)="onFocus()"
        (blur)="focus = false"
        (valueChange)="onSearchTextChanged($event)"
    ></cm-search>
    <cm-dropdown [isOpen]="$showDropdown() && $hasData() && focus">
        <ng-container cm-dropdown>
            <div class="cm-dropdown-list">
                @if ($showLoadingPlaceholders()) {
                    @for (index of [0, 1, 2, 3, 4]; track index) {
                        <div class="cm-list-item">
                            <div class="cm-tag-label cm-tag-label--placeholder">Customer</div>
                            <div class="cm-tag-name">
                                <div class="cm-text-placeholder"></div>
                            </div>
                        </div>
                    }
                } @else {
                    @for (organization of $filteredOrganizations(); track organization.state) {
                        <div (mousedown)="$event.preventDefault()" (click)="onSelectOrganization(organization)" class="cm-list-item">
                            <div class="cm-customer-label">Customer</div>
                            <div class="cm-customer-name">
                                {{ organization.label }}
                            </div>
                        </div>
                    }
                    @for (tag of $filteredTags(); track tag.state) {
                        <div (mousedown)="$event.preventDefault()" (click)="onSelectTag(tag)" class="cm-list-item">
                            <div [style.background]="tag.background" class="cm-tag-label">{{ tag.typeLabel }}</div>
                            <div class="cm-tag-name">
                                {{ tag.label }}
                            </div>
                        </div>
                    }
                }
            </div>
        </ng-container>
    </cm-dropdown>
    @if ($selectedTags().length) {
        <cm-filter-list>
            <ng-container cm-title> Tags</ng-container>
            <ng-container cm-list>
                <div class="cm-selected-tag-list">
                    @for (tag of $selectedTags(); track tag.state) {
                        <div (click)="onRemoveTag(tag)" class="cm-selected-tag">
                            <i class="fa-solid fa-xmark"></i>
                            <div [matTooltip]="tag.typeLabel ?? ''" class="cm-selected-tag-name">
                                {{ tag.label }}
                            </div>
                        </div>
                    }
                </div>
            </ng-container>
        </cm-filter-list>
    }
    @if ($can().read.organization()) {
        @if ($selectedOrganizationIds().length && $visibleOrganizations() === null) {
            <cm-placeholder class="cm-organization-select-placeholder"></cm-placeholder>
        } @else if ($selectedOrganizations().length) {
            <cm-filter-list>
                <ng-container cm-title> Organization</ng-container>
                <ng-container cm-list>
                    <div class="cm-selected-tag-list">
                        @for (organization of $selectedOrganizations(); track organization.state) {
                            <div (click)="onRemoveOrganization(organization)" class="cm-selected-tag">
                                <i class="fa-solid fa-xmark"></i>
                                <div [matTooltip]="organization.label" class="cm-selected-tag-name">
                                    {{ organization.label }}
                                </div>
                            </div>
                        }
                    </div>
                </ng-container>
            </cm-filter-list>
        }
    }
    @if (!$can().read.organization()) {
        <cm-filter-list>
            <ng-container cm-title> Organization</ng-container>
            <ng-container cm-list>
                @if ($selectedOrganizationIds().length) {
                    @if ($visibleOrganizations() === null) {
                        <cm-placeholder class="cm-organization-select-placeholder"></cm-placeholder>
                    } @else {
                        <div class="cm-selected-tag-list">
                            @for (organization of $selectedOrganizations(); track organization.state) {
                                <div (click)="onRemoveOrganization(organization)" class="cm-selected-tag">
                                    <i class="fa-solid fa-xmark"></i>
                                    <div [matTooltip]="organization.label" class="cm-selected-tag-name">
                                        {{ organization.label }}
                                    </div>
                                </div>
                            }
                        </div>
                    }
                } @else if (organizations.$current()) {
                    <a [routerLink]="['./']" [queryParams]="{organizationId: organizations.$current()?.id}" queryParamsHandling="merge">
                        <div>+ Show own library only</div>
                    </a>
                }
            </ng-container>
        </cm-filter-list>
    }
</div>
