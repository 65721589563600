import {
    AssetState,
    ContentTypeModel,
    DataObjectState,
    MaterialState,
    ModelState,
    ModelType,
    NextActor,
    PaymentState,
    PictureState,
    TagType,
    TaskState,
    TemplateState,
    TemplateType,
} from "@generated"

/**
 * Convenience object to be imported into components for quick access to API enums in templates.
 * Also provides compound enums used in pre-defined compound filters
 */
export const Enums = {
    AssetState: AssetState,
    ContentTypeModel: ContentTypeModel,
    DataObjectState: DataObjectState,
    MaterialState: MaterialState,
    ModelState: ModelState,
    ModelType: ModelType,
    NextActor: NextActor,
    PaymentState: PaymentState,
    PictureState: PictureState,
    TagType: TagType,
    TaskState: TaskState,
    TemplateState: TemplateState,
    TemplateType: TemplateType,

    InPipelineStates: {
        Asset: [AssetState.Shading, AssetState.Review].join(","),
        Model: [ModelState.InfoReview, ModelState.Modelling, ModelState.Review].join(","),
        Material: [MaterialState.InfoReview, MaterialState.Scanning, MaterialState.Tiling, MaterialState.Shading, MaterialState.Review].join(","),
        Picture: [PictureState.InfoReview, PictureState.Stage1, PictureState.Stage2, PictureState.FinalRender].join(","),
        Template: [TemplateState.InProgress, TemplateState.Review].join(","),
    },
    NextActors: {
        CustomerInProgress: [NextActor.Team1, NextActor.Team2, NextActor.ProjectManager].join(","),
        ProductionWaitingForFeedback: [NextActor.Customer, NextActor.ProjectManager].join(","),
        ProductionInProgress: [NextActor.Team1, NextActor.Team2].join(","),
    },
}

export * from "@common/models/enums/flat-thumbnail"
