import {Component, input, output} from "@angular/core"

@Component({
    selector: "cm-sidenav",
    templateUrl: "./sidenav.component.html",
    styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent {
    readonly $sidenavOpen = input(false, {alias: "sidenavOpen"})
    readonly $attachToParent = input(false, {alias: "attachToParent"})
    readonly backgroundClick = output<void>()
}
