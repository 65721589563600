import {Component, computed, inject, Injector, OnInit, signal} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatDialogModule} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {MatTooltipModule} from "@angular/material/tooltip"
import {RouterModule} from "@angular/router"
import {
    ContentTypeModel,
    ModelState,
    MutationCreateTemplateInput,
    MutationUpdateTemplateInput,
    SortOrder,
    TemplateOrderByCriteria,
    TemplateState,
    TemplateType,
} from "@generated"
import {
    TemplateListItemFragment,
    GetTemplatesQuery,
    GetTemplatesGQL,
    GetTemplateRevisionDetailsGQL,
    GetTemplatesQueryVariables,
} from "@app/templates/templates.generated"
import {CreateTemplateGQL, UpdateTemplateGQL} from "@app/templates/templates.generated"
import {CardErrorComponent, CardPlaceholderComponent} from "@common/components/cards"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {EntityResponsiveSidebarComponent} from "@common/components/entity/entity-responsive-sidebar/entity-responsive-sidebar.component"
import {CheckboxesFilterComponent, TagSearchFilterComponent} from "@common/components/filters"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"
import {ItemFiltersComponent, ItemListComponent, ListInfoComponent} from "@common/components/item"
import {InfiniteListComponent} from "@common/components/lists"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {Labels, ProductTypeLabels, SceneTypeLabels, StateLabel, TemplateTypeLabels} from "@labels"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {CancellableRequest} from "@platform/models/data/cancellable-request"

@Component({
    selector: "cm-templates",
    templateUrl: "./templates.component.html",
    styleUrls: ["./templates.component.scss"],
    imports: [
        CardErrorComponent,
        CardPlaceholderComponent,
        CheckboxesFilterComponent,
        EntityCardComponent,
        EntityResponsiveSidebarComponent,
        FormsModule,
        InfiniteListComponent,
        ItemFiltersComponent,
        ListInfoComponent,
        MatButtonModule,
        MatDialogModule,
        MatInputModule,
        MatSelectModule,
        RouterModule,
        StateLabelComponent,
        TagSearchFilterComponent,
        OrganizationSelectComponent,
        MatTooltipModule,
    ],
})
export class TemplatesComponent
    extends ItemListComponent<TemplateListItemFragment, MutationUpdateTemplateInput, MutationCreateTemplateInput>
    implements OnInit
{
    protected override _contentTypeModel = ContentTypeModel.Template
    public stateLabels: StateLabel<ModelState>[] = Array.from(Labels.ModelState.values())
    public sortedTemplateTypeLabels: StateLabel<TemplateType>[] = []

    readonly templatesGql = inject(GetTemplatesGQL)
    readonly sceneManagerTemplateRevision = inject(GetTemplateRevisionDetailsGQL)
    readonly createTemplate = inject(CreateTemplateGQL)
    readonly updateTemplateGql = inject(UpdateTemplateGQL)
    readonly injector = inject(Injector)

    private fetchRequest = new CancellableRequest<GetTemplatesQuery, GetTemplatesQueryVariables>(this.templatesGql, this.injector, this.destroyRef)

    public readonly $viewMode = signal<"scenes" | "products" | undefined>(undefined)
    public readonly $entityName = computed(() => {
        const viewMode = this.$viewMode()
        if (viewMode === "scenes") return "scene"
        else if (viewMode === "products") return "product"
        else return "template"
    })
    public readonly $typeLabels = computed(() => {
        const viewMode = this.$viewMode()
        if (viewMode === "scenes") return SceneTypeLabels
        else if (viewMode === "products") return ProductTypeLabels
        else return TemplateTypeLabels
    })

    private readonly $filterFunction = computed(() => {
        const viewMode = this.$viewMode()
        if (viewMode === "scenes") return this.filters.sceneFilter
        else if (viewMode === "products") return this.filters.productFilter
        else return this.filters.templateFilter
    })

    override _initialNewItemData = () => ({
        organizationId: this.organizations.$current()?.id,
        name: "",
        state: TemplateState.Draft,
        templateType: this.$viewMode() === "scenes" ? TemplateType.Room : TemplateType.Product,
    })

    override ngOnInit() {
        super.ngOnInit()

        this.$viewMode.set(this.route.snapshot.data.viewMode)

        this.sortedTemplateTypeLabels = Array.from(this.$typeLabels().values()).sort((a, b) => a.label.localeCompare(b.label))
    }

    setNewTemplateOrganization = async (data: {organizationId: string}) => {
        console.log("Setting new template organization", data.organizationId)
        this.newItemData.organizationId = data.organizationId
    }

    // OVERLOADS
    protected override openNewItem: () => boolean = () => false

    protected override _createItem = async (data: MutationCreateTemplateInput) => {
        this.notifications.showInfo("Creating template...")
        const {createTemplate: template} = await mutateThrowingErrors(this.createTemplate)({
            input: data,
        })
        await this.router.navigate([template.id, "revisions", "add"], {
            relativeTo: this.route,
            queryParamsHandling: "preserve",
        })
        return template
    }

    protected override _fetchList = ({skip, take}: {skip: number; take: number}) =>
        this.fetchRequest
            .fetch({
                take: take,
                skip: skip,
                filter: this.$filterFunction()(),
                orderBy: [
                    {key: TemplateOrderByCriteria.Name, direction: SortOrder.Asc},
                    {key: TemplateOrderByCriteria.Id, direction: SortOrder.Desc},
                ],
            })
            .then(({templates, templatesCount}) => ({items: templates, totalCount: templatesCount}))

    protected override _refreshItem = ({id, legacyId}: {id?: string; legacyId?: number}) =>
        fetchThrowingErrors(this.templatesGql)({
            take: 1,
            filter: {
                ...this.$filterFunction()(),
                id: id ? {equals: id} : undefined,
                legacyId: legacyId ? {equals: legacyId} : undefined,
            },
        }).then(({templates}) => templates?.[0] || undefined)

    protected override _updateItem = (data: MutationUpdateTemplateInput) =>
        mutateThrowingErrors(this.updateTemplateGql)({
            input: data,
        }).then(({updateTemplate: template}) => template)

    templateLink = (template: TemplateListItemFragment) => {
        if (!template.latestRevision) {
            return ["./", template.id, "revisions", "add"]
        } else {
            return ["./", template.id, "revisions", template.latestRevision.id, "edit"]
        }
    }

    multiThumbnailIds = (template: TemplateListItemFragment) => {
        if (template?.galleryImage?.id) {
            return null
        }
        return template?.latestRevision?.renderedImages?.map(({id}) => id) ?? null
    }

    _copyTemplate = async (_template: TemplateListItemFragment) => {}

    copyTemplate = async (template: TemplateListItemFragment) => {
        await this.notifications.withUserFeedback(
            async () => {
                const {createTemplate: newTemplate} = await mutateThrowingErrors(this.createTemplate)({
                    input: {
                        name: `Copy of ${template.name}`,
                        organizationId: template.organization.id,
                        state: TemplateState.Draft,
                        templateType: template.type,
                        comment: template.comment,
                        public: template.public,
                        revisions: [
                            {
                                graph: template.latestRevision?.graph,
                            },
                        ],
                    },
                })

                if (!newTemplate.latestRevision) throw new Error("No template revision added to copy")
                await this.router.navigate([newTemplate.id, "revisions", newTemplate.latestRevision.id, "edit"], {
                    relativeTo: this.route,
                    queryParamsHandling: "preserve",
                })
            },
            {
                success: "Template copied successfully",
                error: "Cannot copy template",
            },
        )
    }
}
