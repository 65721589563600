import {Component, inject, OnInit, output} from "@angular/core"
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {OrganizationSelectComponent} from "../../../common/components/inputs/select/organization-select/organization-select.component"
import {PermissionsService} from "@app/common/services/permissions/permissions.service"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {MatTooltipModule} from "@angular/material/tooltip"
import {RouterModule} from "@angular/router"
import {StateLabel, TemplateTypeLabels} from "@app/platform/models/state-labels"
import {TemplateType, MutationCreateTemplateInput} from "@generated"
import {CreateTemplateForTemplateAddDialogGQL, CreateTemplateForTemplateAddDialogMutation} from "./template-add-dialog.generated"
import {mutateThrowingErrors} from "@app/common/helpers/api/mutate"

export type TemplateAddDialogComponentData = MutationCreateTemplateInput

@Component({
    selector: "cm-template-add-dialog",
    imports: [FormsModule, MatButtonModule, MatDialogModule, MatInputModule, MatSelectModule, RouterModule, OrganizationSelectComponent, MatTooltipModule],
    templateUrl: "./template-add-dialog.component.html",
    styleUrl: "./template-add-dialog.component.scss",
})
export class TemplateAddDialogComponent implements OnInit {
    readonly onTemplateCreated = output<CreateTemplateForTemplateAddDialogMutation["createTemplate"]>()
    readonly permission = inject(PermissionsService)
    $can = this.permission.$to
    private readonly data = inject<MutationCreateTemplateInput>(MAT_DIALOG_DATA)
    readonly createTemplate = inject(CreateTemplateForTemplateAddDialogGQL)

    readonly dialogRef = inject(MatDialogRef<TemplateAddDialogComponent>)

    public sortedTemplateTypeLabels: StateLabel<TemplateType>[] = []

    newItemData: MutationCreateTemplateInput = {...this.data}

    ngOnInit() {
        this.sortedTemplateTypeLabels = Array.from(TemplateTypeLabels.values()).sort((a, b) => a.label.localeCompare(b.label))
    }

    setNewTemplateOrganization = async (data: {organizationId: string}) => {
        this.newItemData.organizationId = data.organizationId
    }

    async confirmAction() {
        const {createTemplate: template} = await mutateThrowingErrors(this.createTemplate)({
            input: this.newItemData,
        })

        this.onTemplateCreated.emit(template)
        this.dialogRef.close(true)
    }

    cancelAction() {
        this.dialogRef.close(false)
    }
}
