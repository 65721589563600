import {Component, viewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {TexNoiseInputs, TexNoiseOutputs} from "@material-editor/models/nodes/tex-noise"
import {TexVoronoiInputs, TexVoronoiOutputs} from "@material-editor/models/nodes/tex-voronoi"

@Component({
    selector: "cm-tex-voronoi-node",
    templateUrl: "./tex-voronoi-node.component.html",
    styleUrls: ["./tex-voronoi-node.component.scss"],
    imports: [NodeBaseComponent],
})
export class TexVoronoiNodeComponent {
    readonly $nodeBase = viewChild<MaterialNodeBase>("nodeBase")
    inputs = TexVoronoiInputs
    outputs = TexVoronoiOutputs
    typeInfo = TexVoronoiNodeType
}

export const TexVoronoiNodeType: MaterialNodeType<typeof TexVoronoiNodeComponent> = {
    id: "texVoronoi",
    label: "Voronoi Texture",
    color: "#4987af",
    name: "ShaderNodeTexVoronoi",
    inputs: [
        TexVoronoiInputs.vector,
        TexVoronoiInputs.w,
        TexVoronoiInputs.scale,
        TexVoronoiInputs.detail,
        TexVoronoiInputs.smoothness,
        TexVoronoiInputs.randomness,
    ] as never[],
    outputs: [TexNoiseOutputs.distance, TexNoiseOutputs.color, TexNoiseOutputs.position],
    component: TexVoronoiNodeComponent,
}
