import {Component, input} from "@angular/core"

@Component({
    selector: "cm-dropdown",
    templateUrl: "./dropdown.component.html",
    styleUrls: ["./dropdown.component.scss"],
})
export class DropdownComponent {
    readonly $isOpen = input(false, {alias: "isOpen"})

    constructor() {}
}
