import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type RenderJobTaskJobFarmInstanceQueryVariables = Types.Exact<{
  hwFingerprint: Types.Scalars['String']['input'];
}>;


export type RenderJobTaskJobFarmInstanceQuery = { __typename: 'Query', jobFarmInstances: Array<{ __typename: 'JobFarmInstance', otherInfo?: any | null } | null> };

export const RenderJobTaskJobFarmInstanceDocument = gql`
    query renderJobTaskJobFarmInstance($hwFingerprint: String!) {
  jobFarmInstances(filter: {hwFingerprint: {equals: $hwFingerprint}}) {
    otherInfo
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RenderJobTaskJobFarmInstanceGQL extends Apollo.Query<RenderJobTaskJobFarmInstanceQuery, RenderJobTaskJobFarmInstanceQueryVariables> {
    override document = RenderJobTaskJobFarmInstanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }