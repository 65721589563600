import {DeclareTemplateNodeTS} from "#template-nodes/declare-template-node"
import {TemplateNode} from "#template-nodes/types"
import {registerNode} from "@cm/graph/register-node"
import {MaterialLike, materialLike, numberLike, NumberLike} from "#template-nodes/node-types"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {IMaterialGraph} from "@cm/material-nodes/interfaces/material-data"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {z} from "zod"
import {skipped, visitNone} from "@cm/graph/declare-visitor-node"
import {BuilderInlet} from "#template-nodes/runtime-graph/graph-builder"
import {UVOffset} from "#template-nodes/interfaces/scene-manager"
import {transformOffsetUVs} from "@cm/material-nodes/material-node-graph-transformations"

const moveMaterialParameters = z.object({
    material: materialLike.nullable(),
    horizontalOffset: numberLike.optional(),
    verticalOffset: numberLike.optional(),
    rotation: numberLike.optional(),
})
export type MoveMaterialParameters = {
    material: MaterialLike | null
    horizontalOffset?: NumberLike
    verticalOffset?: NumberLike
    rotation?: NumberLike
}

@registerNode
export class MoveMaterial
    extends DeclareTemplateNodeTS<MoveMaterialParameters>(
        {
            validation: {paramsSchema: moveMaterialParameters},
            onVisited: {
                onFilterActive: ({parameters}) => {
                    if (parameters.material === null) return skipped
                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "MoveMaterial"},
    )
    implements EvaluableTemplateNode<IMaterialGraph | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        const {horizontalOffset, verticalOffset, rotation} = this.parameters

        const material = evaluator.evaluateMaterial(scope, this.parameters.material)
        let [materialValid, materialInvalid] = scope.branch(material)

        const nullToZero = (number: BuilderInlet<number | null>, uniqueId: string) => scope.pureLambda(number, (number) => number ?? 0, uniqueId)

        if (horizontalOffset || verticalOffset || rotation) {
            const offset = scope.struct<UVOffset>("UVOffset", {
                horizontal: nullToZero(evaluator.evaluateNumber(scope, horizontalOffset ?? 0), `horizontal`),
                vertical: nullToZero(evaluator.evaluateNumber(scope, verticalOffset ?? 0), `vertical`),
                rotation: nullToZero(evaluator.evaluateNumber(scope, rotation ?? 0), `rotation`),
            })

            materialValid = scope.pureLambda(
                scope.tuple(materialValid, offset),
                ([materialGraph, offset]) => transformOffsetUVs(materialGraph, offset),
                `transformOffsetUVs`,
            )
        }

        return scope.phi(materialValid, materialInvalid)
    }
}

export type MoveMaterialFwd = TemplateNode<MoveMaterialParameters> & EvaluableTemplateNode<IMaterialGraph | null>
