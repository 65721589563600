import {Component, DestroyRef, inject, OnInit, input, output} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {ContentTypeModel} from "@generated"
import {PictureAssetsSectionFragment} from "@app/platform/components/details/picture-assets-section/picture-assets-section.generated"
import {DialogSize} from "@app/common/models/dialogs"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {EntityAddCardComponent} from "@common/components/entity/entity-add-card/entity-add-card.component"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {SectionComponent} from "@common/components/item"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {DialogService} from "@common/services/dialog/dialog.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {Enums} from "@enums"
import {Labels} from "@labels"
import {SelectAssetComponent} from "@platform/components/assets/select-asset/select-asset.component"
import {PictureAssetsSectionGQL} from "@platform/components/details/picture-assets-section/picture-assets-section.generated"
import {
    CreatePictureDetailsAssetAssignmentGQL,
    PictureDetailsDeleteAssetAssignmentGQL,
} from "@platform/components/pictures/picture-details/picture-details.generated"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"

@Component({
    selector: "cm-picture-assets-section",
    imports: [SectionComponent, EntityCardComponent, EntityAddCardComponent, StateLabelComponent, DataObjectThumbnailComponent],
    templateUrl: "./picture-assets-section.component.html",
    styleUrl: "./picture-assets-section.component.scss",
})
export class PictureAssetsSectionComponent implements OnInit {
    readonly $pictureId = input.required<string>({alias: "pictureId"})
    readonly afterAssetChange = output<string>()

    readonly permission = inject(PermissionsService)
    readonly dialog = inject(DialogService)
    readonly destroyRef = inject(DestroyRef)
    readonly notifications = inject(NotificationsService)
    readonly refresh = inject(RefreshService)
    $can = this.permission.$to

    readonly pictureAssetsSection = inject(PictureAssetsSectionGQL)
    readonly createPictureDetailsAssetAssignment = inject(CreatePictureDetailsAssetAssignmentGQL)
    readonly pictureDetailsDeleteAssetAssignment = inject(PictureDetailsDeleteAssetAssignmentGQL)

    picture: PictureAssetsSectionFragment | null = null

    ngOnInit() {
        this.refresh
            .keepFetched$(this.$pictureId(), ContentTypeModel.Picture, fetchThrowingErrors(this.pictureAssetsSection))
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((picture) => {
                this.picture = picture ?? null
            })
    }

    openSelectAssetDialog() {
        this.dialog.selectInDialog(SelectAssetComponent, {
            onSelect: (asset) =>
                this.notifications.withUserFeedback(
                    async () => {
                        const picture = this.picture
                        if (picture) {
                            await mutateThrowingErrors(this.createPictureDetailsAssetAssignment)({
                                input: {
                                    pictureId: picture.id,
                                    assetId: asset.id,
                                },
                            })
                            this.afterAssetChange.emit(picture.id)
                        }
                    },
                    {error: "Cannot create picture asset assignment"},
                ),
        })
    }

    async deletePictureAssetAssignment(assetAssignmentId: string) {
        return this.notifications.withUserFeedback(
            async () => {
                const picture = this.picture
                if (picture) {
                    await mutateThrowingErrors(this.pictureDetailsDeleteAssetAssignment)({id: assetAssignmentId})
                    this.afterAssetChange.emit(picture.id)
                }
            },
            {
                success: "Picture asset assignment deleted",
                error: "Cannot delete picture asset assignment",
            },
        )
    }

    protected readonly Enums = Enums
    protected readonly Labels = Labels
    protected readonly DialogSize = DialogSize
}
