import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type HdriDetailsFragment = { __typename: 'Hdri', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, dataObject?: { __typename: 'DataObject', state: Types.DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type HdriDetailsQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type HdriDetailsQuery = { __typename: 'Query', item: { __typename: 'Hdri', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, dataObject?: { __typename: 'DataObject', state: Types.DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type UpdateHdriDetailsMutationVariables = Types.Exact<{
  input: Types.MutationUpdateHdriInput;
}>;


export type UpdateHdriDetailsMutation = { __typename: 'Mutation', item: { __typename: 'Hdri', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, dataObject?: { __typename: 'DataObject', state: Types.DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type DeleteHdriMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteHdriMutation = { __typename: 'Mutation', deleteHdri?: any | null };

export const HdriDetailsFragmentDoc = gql`
    fragment HdriDetails on Hdri {
  id
  legacyId
  __typename
  name
  organization {
    id
    legacyId
    name
  }
  dataObject {
    state
    bucketName
    objectName
    mediaType
  }
  galleryImage {
    id
  }
}
    `;
export const HdriDetailsDocument = gql`
    query hdriDetails($id: ID) {
  item: hdri(id: $id) {
    ...HdriDetails
  }
}
    ${HdriDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class HdriDetailsGQL extends Apollo.Query<HdriDetailsQuery, HdriDetailsQueryVariables> {
    override document = HdriDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHdriDetailsDocument = gql`
    mutation updateHdriDetails($input: MutationUpdateHdriInput!) {
  item: updateHdri(input: $input) {
    ...HdriDetails
  }
}
    ${HdriDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHdriDetailsGQL extends Apollo.Mutation<UpdateHdriDetailsMutation, UpdateHdriDetailsMutationVariables> {
    override document = UpdateHdriDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteHdriDocument = gql`
    mutation deleteHdri($id: ID!) {
  deleteHdri(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteHdriGQL extends Apollo.Mutation<DeleteHdriMutation, DeleteHdriMutationVariables> {
    override document = DeleteHdriDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }