import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "#template-nodes/runtime-graph/slots"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"
import {ISceneManager} from "#template-nodes/interfaces/scene-manager"
import {TemplateGraph} from "#template-nodes/nodes/template-graph"
import {Subscription, defer, map} from "rxjs"

const TD = TypeDescriptors

const loadGraphDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManager>()),
    templateRevisionId: TD.inlet(TD.Number),
    graph: TD.outlet(TD.Identity<TemplateGraph>()),
}

export class LoadGraph implements NodeClassImpl<typeof loadGraphDescriptor, typeof LoadGraph> {
    static descriptor = loadGraphDescriptor
    static uniqueName = "LoadGraph"
    sceneManager!: Inlet<ISceneManager>
    templateRevisionId!: Inlet<number>
    graph!: Outlet<TemplateGraph>

    private pending: Subscription | null = null

    run() {
        this.graph.emitIfChanged(NotReady)
        this.pending?.unsubscribe()

        if (this.sceneManager === NotReady || this.templateRevisionId === NotReady) {
            return
        }

        const {sceneManager, templateRevisionId} = this

        this.pending = this.sceneManager.addTask({
            description: `loadTemplateGraph(${this.templateRevisionId})`,
            task: defer(() => sceneManager.loadTemplateGraph(templateRevisionId)).pipe(
                map((graph) => {
                    this.pending = null
                    this.graph.emitIfChanged(graph)
                }),
            ),
            critical: true,
        })
    }

    cleanup() {
        this.pending?.unsubscribe()
    }
}
