import {SmartPtrArray} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/smart-ptr-array"
import {SmartPtr} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/smart-ptr"
import {ImageBase} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-base"
import {ImageWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/image-webgl2"
import {Box2Like} from "@cm/math"
import {HalImage} from "@common/models/hal/hal-image"
import {createHalImageView} from "@common/models/hal/hal-image/create"

export class ImageViewWebGL2 extends ImageBase {
    constructor(imageWebGL2: ImageWebGL2, region?: Box2Like) {
        super(
            {
                ...imageWebGL2.descriptor,
                width: region?.width ?? imageWebGL2.descriptor.width,
                height: region?.height ?? imageWebGL2.descriptor.height,
            },
            imageWebGL2.addressSpace,
            imageWebGL2.id,
            () => imageWebGL2.release(),
        )
        imageWebGL2.addRef()
        this.halImageView = createHalImageView(
            imageWebGL2.halImage,
            region ?? {x: 0, y: 0, width: imageWebGL2.descriptor.width, height: imageWebGL2.descriptor.height},
        )
    }

    readonly halImageView: HalImage
}

export function isImageViewWebGL2(obj: unknown): obj is ImageViewWebGL2 {
    return obj instanceof ImageViewWebGL2
}

export class ImageViewPtrWebGl2 extends SmartPtr<ImageViewWebGL2> {}

export class ImageViewPtrArrayWebGl2 extends SmartPtrArray<ImageViewWebGL2> {}

export function isImageViewPtrWebGl2(obj: unknown): obj is ImageViewPtrWebGl2 {
    return obj instanceof ImageViewPtrWebGl2 && obj.isAssigned && isImageViewWebGL2(obj.ref)
}
