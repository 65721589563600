import {ChangeDetectionStrategy, Component, input} from "@angular/core"
import {TaskInfos} from "@generated"

@Component({
    selector: "cm-comment-boxes",
    templateUrl: "./comment-boxes.component.html",
    styleUrls: ["./comment-boxes.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentBoxesComponent {
    readonly $entity = input<{
        taskInfos: TaskInfos
    }>(undefined, {alias: "entity"})
}
