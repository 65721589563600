import {Component, Input, input} from "@angular/core"

@Component({
    selector: "cm-plain-input-socket",
    templateUrl: "./plain-input-socket.component.html",
    styleUrls: ["./plain-input-socket.component.scss"],
})
export class PlainInputSocketComponent {
    readonly $label = input.required<string>({alias: "label"})
    readonly $value = input.required<string>({alias: "value"})

    _connected: {value: boolean} = {value: false}
    get connected(): {value: boolean} {
        return this._connected
    }

    @Input() set connected(value: {value: boolean}) {
        this._connected = value
    }
}
