import {Component} from "@angular/core"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {ValueSlotComponent} from "../../value-slot/value-slot.component"
import {DistanceTexture} from "@cm/template-nodes/nodes/distance-texture"

@Component({
    selector: "cm-distance-texture-inspector",
    templateUrl: "./distance-texture-inspector.component.html",
    styleUrl: "./distance-texture-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent],
})
export class DistanceTextureInspectorComponent extends BaseInspectorComponent<DistanceTexture> {}
