import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {JobsHelperStateUpdateGQL} from "@common/helpers/jobs/jobs.helper.generated"
import {LoadedData, LoadedDataItem} from "@platform/models/data"
import {JobState} from "@generated"
import {exhaustMap, Observable, of, startWith, switchMap, take, takeUntil, takeWhile} from "rxjs"
import {intervalBackoff} from "backoff-rxjs"
import {IsDefined} from "@cm/utils"
import {TabStateService} from "@common/services/tab-state/tab-state.service"
import {Injector} from "@angular/core"

// keep updating the state of running jobs until they are no longer running
// implements a backoff strategy to avoid flooding the server with requests
export const keepCheckingRunningJobs$ =
    (injector: Injector, tabState: TabStateService) =>
    <
        JobsFragment extends {
            id: string
            state: JobState
            started?: string | null
            progress?: number | null
            message?: string | null
        },
    >(
        jobsData$: Observable<LoadedData<JobsFragment>>,
    ): Observable<LoadedData<JobsFragment>> => {
        return jobsData$.pipe(
            switchMap((jobsData) => {
                const ids = jobsData.items
                    .map(({data}) => data)
                    .filter(IsDefined)
                    .filter((job) => job.state === JobState.Init || job.state === JobState.Running || job.state === JobState.Runnable)
                    .map((job) => job.id)
                if (ids.length === 0) {
                    return of(jobsData)
                }
                return intervalBackoff({
                    initialInterval: 1000,
                    backoffDelay: (index) => Math.pow(1.1, index) * 1000,
                }).pipe(
                    takeUntil(tabState.becomesInactive$.pipe(take(1))),
                    exhaustMap(async () => {
                        const jobsHelperStateUpdate = injector.get(JobsHelperStateUpdateGQL)
                        const updates = await fetchThrowingErrors(jobsHelperStateUpdate)({
                            ids,
                        })
                        return {
                            ...jobsData,
                            items: jobsData.items.map((item) => {
                                const itemId = item.data?.id
                                if (itemId) {
                                    const relevantUpdate = updates.jobs.find((update) => update && update.id === itemId)
                                    if (relevantUpdate) {
                                        const returnValue: LoadedDataItem<JobsFragment> = {
                                            trackId: item.trackId,
                                            data: {
                                                ...item.data,
                                                progress: relevantUpdate.progress,
                                                state: relevantUpdate.state,
                                                started: relevantUpdate.started,
                                                message: relevantUpdate.message,
                                            } as JobsFragment,
                                            error: null,
                                        }
                                        return returnValue
                                    }
                                }
                                return item
                            }),
                        }
                    }),
                    startWith(jobsData),
                    takeWhile(() =>
                        jobsData.items.some(
                            (item) => item.data?.state === JobState.Init || item.data?.state === JobState.Running || item.data?.state === JobState.Runnable,
                            true,
                        ),
                    ),
                )
            }),
        )
    }
