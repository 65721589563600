import {Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core"
import {LoadingSpinnerIconComponent} from "@app/common/components/progress"
import {ConfigMenuService} from "@app/common/components/viewers/configurator/config-menu/services/config-menu.service"
import {firstValueFrom, Subject, takeUntil} from "rxjs"

@Component({
    selector: "cm-pdf-download-button",
    templateUrl: "./pdf-download-button.component.html",
    styleUrls: ["./pdf-download-button.component.scss"],
    encapsulation: ViewEncapsulation.ShadowDom,
    imports: [LoadingSpinnerIconComponent],
})
export class PdfDownloadButton implements OnInit, OnDestroy {
    public hidden: boolean = true
    public loading: boolean = false

    private _destroyed$ = new Subject<void>()

    constructor(private configMenuService: ConfigMenuService) {}

    ngOnInit() {
        // only show this button once the menu interface is avalaible
        this.configMenuService.interfaceNew$.pipe(takeUntil(this._destroyed$)).subscribe((interfaces) => {
            if (interfaces.length > 0) {
                this.hidden = false
                this._destroyed$.next()
                this._destroyed$.complete()
            }
        })
    }

    ngOnDestroy() {
        this._destroyed$.next()
        this._destroyed$.complete()
    }

    async onClick() {
        this.loading = true
        await new Promise((resolve) => setTimeout(resolve, 4000)) // Add artificial delay
        this.configMenuService.emitPdfDownloadRequested()
        await firstValueFrom(this.configMenuService.pdfDownloadFinished$())
        this.loading = false
    }
}
