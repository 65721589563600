import {Component, computed, DestroyRef, inject, Injector, input, OnDestroy, OnInit, output, signal} from "@angular/core"
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {NotificationsService} from "@app/common/services/notifications/notifications.service"
import {ImageProcessingService} from "@app/common/services/rendering/image-processing.service"
import {RenderingService} from "@app/common/services/rendering/rendering.service"
import {
    createPostProcessJob,
    createRenderJob,
    deletePostProcessJob,
    deleteRenderJob,
    getAssignmentKey,
    getConfigurationString,
    getJobAssignments,
    isJobError,
    isJobPending,
    JobData,
    postProcessingSettingsToImageProcessingSettings,
} from "@app/template-editor/helpers/render-jobs"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {ImageProcessingNodes, PostProcessingInputData} from "@cm/image-processing-nodes"
import {postProcessingGraph} from "@cm/image-processing/render-post-processing"
import {JobNodes} from "@cm/job-nodes/job-nodes"
import {PictureRenderJobOutput, PictureRenderJobOutputSchema, RenderMetadata, RenderMetadataSchema} from "@cm/job-nodes/rendering"
import {isCryptomattePass} from "@cm/render-nodes"
import {SceneNodes} from "@cm/template-nodes"
import {LodType} from "@cm/template-nodes/types"
import {promiseAllProperties} from "@cm/utils"
import {jsonToGraph} from "@cm/browser-utils"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {LoadingSpinnerComponent, LoadingSpinnerIconComponent} from "@common/components/progress"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {ImageColorSpace, JobState} from "@generated"
import {
    CancelJobForTemplateImageViewerGQL,
    DeleteJobForTemplateImageViewerGQL,
    DeleteJobForTemplateImageViewerMutation,
    GetDataObjectDetailsForTemplateImageViewerGQL,
    GetDataObjectDetailsForTemplateImageViewerQuery,
    GetJobDetailsForTemplateImageViewerGQL,
} from "@template-editor/components/template-image-viewer/template-image-viewer.component.generated"
import {TemplateJobManagerService} from "@template-editor/services/template-job-manager.service"
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    defer,
    EMPTY,
    filter,
    from,
    interval,
    map,
    Observable,
    of,
    startWith,
    Subscription,
    switchMap,
    take,
    tap,
} from "rxjs"
import {TemplateImageViewerCanvasComponent} from "../template-image-viewer-canvas/template-image-viewer-canvas.component"
import {TemplateImageViewerControlsComponent} from "../template-image-viewer-controls/template-image-viewer-controls.component"
import {TemplateTreeViewType} from "../template-tree-view-type-selector/template-tree-view-type-selector.component"
import {ActionItemComponent} from "@app/common/components/menu/actions/action-item/action-item.component"
import {ImageProcessingUtils} from "@cm/image-processing/image-processing"

const JOB_POLL_INTERVAL = 5000 // 5 seconds
const RENDER_REFRESH_INTERVAL = 5 * 60 * 1000 // 5 minutes

type AvailableJobIds = {render?: string; postProcess?: string}
type RelevantJobId = {type: "render" | "postProcess"; jobId: string}
type RelevantJobData = {type: "render" | "postProcess"; job: JobData}

export type PostProcessedImageData =
    | {
          type: "computed"
          imageData: ImageData
      }
    | {type: "cached"; dataObject: GetDataObjectDetailsForTemplateImageViewerQuery["dataObject"]}
@Component({
    selector: "cm-template-image-viewer",
    templateUrl: "./template-image-viewer.component.html",
    styleUrl: "./template-image-viewer.component.scss",
    imports: [
        LoadingSpinnerComponent,
        TemplateImageViewerCanvasComponent,
        TemplateImageViewerControlsComponent,
        ButtonComponent,
        MatTooltipModule,
        LoadingSpinnerIconComponent,
        ActionItemComponent,
        MatMenuModule,
    ],
})
export class TemplateImageViewerComponent implements OnInit, OnDestroy {
    readonly $maskOverlayColor = input<ImageProcessingNodes.RGBColor>([1, 0.2, 0.2], {alias: "maskOverlayColor"})
    readonly $maskOverlayAlpha = input(0.5, {alias: "maskOverlayAlpha"})
    readonly $viewType = input.required<TemplateTreeViewType>({alias: "viewType"})

    private readonly sceneManagerService = inject(SceneManagerService)
    private readonly injector = inject(Injector)
    private readonly destroyRef = inject(DestroyRef)
    private readonly renderingService = inject(RenderingService)
    private readonly notificationsService = inject(NotificationsService)
    private readonly templateJobManagerService = inject(TemplateJobManagerService)
    private readonly getDataObjectDetailsForTemplateImageViewerGQL = inject(GetDataObjectDetailsForTemplateImageViewerGQL)
    private readonly getJobDetailsForTemplateImageViewerGQL = inject(GetJobDetailsForTemplateImageViewerGQL)
    private readonly cancelJobGql = inject(CancelJobForTemplateImageViewerGQL)
    private readonly deleteJobGql = inject(DeleteJobForTemplateImageViewerGQL)

    private readonly $postProcessingSettings = computed(() => this.sceneManagerService.$scene().find(SceneNodes.RenderPostProcessingSettings.is))

    private resolvedProcessingSettings$ = toObservable(this.$postProcessingSettings).pipe(
        map((settings) => (settings ? postProcessingSettingsToImageProcessingSettings(settings) : undefined)),
        switchMap((settings) => {
            if (!settings) return of(undefined)

            function isColorTuple(x: any): x is ImageProcessingNodes.RGBColor {
                return Array.isArray(x) && x.length === 3 && x.every((num) => typeof num === "number")
            }

            const {backgroundColor, mask, ...rest} = settings

            const resolveImage = (image: ImageProcessingNodes.ImageNode) =>
                defer(() =>
                    from(
                        ImageProcessingUtils.resolveExternalData(image, async (node) => {
                            if (node.sourceData.type !== "dataObjectReference") throw new Error("Unsupported source type")
                            if (typeof node.resolvedData !== "undefined") return
                            if (node.resolveTo !== "encodedData") throw new Error(`Cannot resolve external data type: ${node.resolveTo}`)

                            const dataObject = await this.sceneManagerService.getISceneManager().loadDataObject(node.sourceData.dataObjectId)

                            node.resolvedData = {
                                data: new Uint8Array(await this.sceneManagerService.getISceneManager().getDataObjectData(dataObject)),
                                mediaType: dataObject.mediaType,
                                colorSpace: dataObject.imageColorSpace === ImageColorSpace.Srgb ? "sRGB" : "linear",
                            }
                        }),
                    ),
                )

            return combineLatest([
                isColorTuple(backgroundColor) || !backgroundColor ? of(backgroundColor) : resolveImage(backgroundColor),
                mask ? resolveImage(mask) : of(undefined),
            ]).pipe(map(([backgroundColor, mask]) => ({...rest, backgroundColor, mask})))
        }),
    )
    private maskOverlayColor$ = toObservable(this.$maskOverlayColor)
    private maskOverlayAlpha$ = toObservable(this.$maskOverlayAlpha)

    readonly $renderSettings = computed(() => this.sceneManagerService.$scene().find(SceneNodes.RenderSettings.is))
    readonly $renderInProgress = computed(() => {
        const relevantJobData = this.$relevantJobData()
        if (relevantJobData === undefined || relevantJobData.type !== "render" || !isJobPending(relevantJobData.job) || this.$stage() !== "done")
            return undefined
        return relevantJobData.job
    })

    readonly viewTypeChanged = output<TemplateTreeViewType>()

    readonly $stage = signal<"loading" | "processing" | "waitingRenderOutput" | "waitingPostProcessingOutput" | "done">("loading")
    readonly $availableJobIds = signal<AvailableJobIds | undefined>(undefined)
    readonly $relevantJobData = signal<RelevantJobData | undefined>(undefined)
    readonly $postProcessedImageData = signal<PostProcessedImageData | undefined>(undefined)
    readonly $startingJob = signal(false)
    readonly $cancellingJob = signal(false)
    readonly $deletingJob = signal(false)

    $overrideJobData = this.templateJobManagerService.$selectedUnassignedJob
    overrideJobData$ = toObservable(this.$overrideJobData)

    currentLocalConfiguration$ = toObservable(this.sceneManagerService.$currentLocalConfiguration)

    JobState = JobState
    isJobError = isJobError

    private refreshJobs$ = new BehaviorSubject<void>(undefined)
    private processSubscription: Subscription | undefined

    isRefreshJobInitialized() {
        return this.processSubscription && !this.processSubscription.closed
    }

    refreshJobs() {
        if (!this.isRefreshJobInitialized()) {
            const refreshRender$ = interval(RENDER_REFRESH_INTERVAL).pipe(
                filter(() => this.$renderInProgress() !== undefined),
                startWith(0),
            )

            this.processSubscription = combineLatest([
                this.overrideJobData$,
                this.sceneManagerService.templateRevisionId$,
                this.currentLocalConfiguration$,
                this.refreshJobs$,
                refreshRender$,
            ])
                .pipe(
                    tap(() => {
                        this.$stage.set("loading")
                        this.$availableJobIds.set(undefined)
                        this.$relevantJobData.set(undefined)
                    }),
                    switchMap(([overrideJobData, templateRevisionId, configuration]) => {
                        if (overrideJobData) {
                            return of([
                                overrideJobData.type === "render" ? overrideJobData.job : undefined,
                                overrideJobData.type === "postProcess" ? overrideJobData.job : undefined,
                            ])
                        }

                        if (!templateRevisionId) {
                            this.$stage.set("done")
                            return EMPTY
                        }

                        const configurationString = getConfigurationString(configuration)

                        return from(
                            getJobAssignments(this.injector, templateRevisionId, [
                                getAssignmentKey("render", configurationString),
                                getAssignmentKey("postProcess", configurationString),
                            ]),
                        )
                    }),
                    map(([render, postProcess]) => ({render: render?.id, postProcess: postProcess?.id})),
                    tap((availableJobIds) => {
                        this.$availableJobIds.set(availableJobIds)
                    }),
                    map(({render, postProcess}): RelevantJobId | undefined => {
                        if (postProcess) return {type: "postProcess", jobId: postProcess}
                        else if (render) return {type: "render", jobId: render}
                        else return undefined
                    }),
                    switchMap((relevantJobId) => {
                        if (!relevantJobId) {
                            this.$stage.set("done")
                            this.$postProcessedImageData.set(undefined)
                            return EMPTY
                        }
                        const {type, jobId} = relevantJobId
                        return type === "render" ? this.getPostProcessedImageDataFromRenderJob(jobId) : this.getPostProcessedImageDataFromPostProcessJob(jobId)
                    }),
                    tap(() => this.$stage.set("done")),
                    catchError((error) => {
                        this.$stage.set("done")
                        this.$postProcessedImageData.set(undefined)
                        console.error(error)
                        return EMPTY
                    }),
                    takeUntilDestroyed(this.destroyRef),
                )
                .subscribe((postProcessedData) => {
                    this.$postProcessedImageData.set(postProcessedData)
                })
        } else this.refreshJobs$.next()
    }

    private readonly imageProcessingService = inject(ImageProcessingService)

    private originalLodType: LodType
    constructor() {
        this.originalLodType = this.sceneManagerService.$lodType()
        this.sceneManagerService.$lodType.set("pathTraced")
    }

    ngOnInit() {
        interval(JOB_POLL_INTERVAL)
            .pipe(
                filter(() => this.$renderInProgress() !== undefined),
                switchMap(() => {
                    const relevantJobData = this.$relevantJobData()
                    if (relevantJobData === undefined) return EMPTY

                    const {type, job} = relevantJobData
                    if (type !== "render") return EMPTY

                    return this.getJobDetailsForTemplateImageViewerGQL.fetch({id: job.id}, {fetchPolicy: "no-cache"})
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe((jobStatusUpdate) => {
                const {job} = jobStatusUpdate.data
                this.$relevantJobData.set({type: "render", job})
                if (job.state === JobState.Complete || isJobError(job)) this.refreshJobs()
            })

        this.refreshJobs()

        combineLatest([this.overrideJobData$, this.sceneManagerService.templateRevisionId$, this.currentLocalConfiguration$])
            .pipe(
                filter((_) => !this.isRefreshJobInitialized()),
                tap(() => this.refreshJobs()),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe()
    }

    ngOnDestroy() {
        this.sceneManagerService.$lodType.set(this.originalLodType)
    }

    getPostProcessedImageDataFromRenderJob(jobId: string): Observable<PostProcessedImageData> {
        const postProcessingInput$ = this.getJobDetailsForTemplateImageViewerGQL
            .watch({id: jobId}, {pollInterval: JOB_POLL_INTERVAL, fetchPolicy: "no-cache"})
            .valueChanges.pipe(
                map(({data}) => data.job),
                tap((job) => {
                    this.$relevantJobData.set({type: "render", job})
                    if (isJobError(job)) throw new Error(`Render job failed with state ${job.state} and message: ${job.message}`)
                }),
                switchMap((job) => {
                    if (![JobState.Running, JobState.Complete].includes(job.state)) {
                        this.$stage.set("waitingRenderOutput")
                        return EMPTY
                    }

                    const {output} = job
                    const graph = jsonToGraph(output)
                    const renderOutput = PictureRenderJobOutputSchema.parse(graph)

                    if (!renderOutput.renderPasses && !renderOutput.preview) {
                        this.$stage.set("waitingRenderOutput")
                        return EMPTY
                    }

                    return from(getPostProcessingInput(renderOutput, this.injector, true))
                }),
                take(1),
            )

        const settings$ = combineLatest([this.resolvedProcessingSettings$, this.maskOverlayColor$, this.maskOverlayAlpha$]).pipe(
            map(([postProcessingSettings, maskOverlayColor, maskOverlayAlpha]) => ({postProcessingSettings, maskOverlayColor, maskOverlayAlpha})),
        )

        const computedPostProcessedImage$ = combineLatest([postProcessingInput$, settings$]).pipe(
            tap(() => this.$stage.set("processing")),
            switchMap(([renderOutput, settings$]) => {
                const {postProcessingSettings, maskOverlayColor, maskOverlayAlpha} = settings$
                const {image, selectedMask: mask} = postProcessingGraph(renderOutput, postProcessingSettings ?? {})
                let graph = image

                if (mask) {
                    graph = {
                        type: "blend",
                        mode: "normal",
                        amount: maskOverlayAlpha,
                        background: graph,
                        foreground: {
                            type: "applyMask",
                            input: {
                                type: "sRGB",
                                color: maskOverlayColor,
                            },
                            mask,
                        },
                    }
                }
                graph = {
                    type: "convert",
                    dataType: "uint8",
                    channelLayout: "RGBA",
                    sRGB: true,
                    input: graph,
                }

                return this.imageProcessingService.evalGraph(graph)
            }),
            switchMap((processedImage) => this.imageProcessingService.toCanvasImageData(processedImage.image)),
            filter((imageData): imageData is ImageData => {
                if (imageData === null) throw new Error("Image data is null")
                return true
            }),
            map((imageData) => ({type: "computed" as const, imageData})),
        )

        return computedPostProcessedImage$
    }

    getPostProcessedImageDataFromPostProcessJob(jobId: string): Observable<PostProcessedImageData> {
        const dataObjectReference$ = this.getJobDetailsForTemplateImageViewerGQL.watch({id: jobId}, {pollInterval: JOB_POLL_INTERVAL}).valueChanges.pipe(
            map(({data}) => data.job),
            tap((job) => {
                this.$relevantJobData.set({type: "postProcess", job})
                if (isJobError(job)) throw new Error(`Post process job failed with state ${job.state} and message: ${job.message}`)
            }),
            switchMap((job) => {
                if (job.state !== JobState.Complete) {
                    this.$stage.set("waitingPostProcessingOutput")
                    return EMPTY
                }

                const {output} = job
                const graph = jsonToGraph(output)
                const dataObjectReference = JobNodes.DataObjectReferenceSchema.parse(graph)
                return of(dataObjectReference)
            }),
            take(1),
        )

        const cachedPostProcessedImage$ = dataObjectReference$.pipe(
            switchMap((dataObjectReference) => {
                return this.getDataObjectDetailsForTemplateImageViewerGQL
                    .watch({legacyId: dataObjectReference.dataObjectId}, {pollInterval: JOB_POLL_INTERVAL})
                    .valueChanges.pipe(
                        map(({data: {dataObject}}) => dataObject),
                        filter((dataObject) => {
                            const thumnailAvailable = typeof dataObject.thumbnail?.downloadUrl === "string"
                            if (!thumnailAvailable) this.$stage.set("waitingPostProcessingOutput")
                            return thumnailAvailable
                        }),
                        take(1),
                    )
            }),
            map((dataObject) => ({
                type: "cached" as const,
                dataObject,
            })),
            take(1),
        )

        return cachedPostProcessedImage$
    }

    async render() {
        try {
            this.$startingJob.set(true)

            const templateRevisionId = this.sceneManagerService.$templateRevisionId()
            if (!templateRevisionId) throw Error("No template revision id set")

            const defaultCustomerId = this.sceneManagerService.$defaultCustomerId()
            if (!defaultCustomerId) throw Error("No default customer id set")

            if (this.sceneManagerService.$lodType() !== "pathTraced") throw Error("Cannot render with LOD type other than pathTraced")

            this.sceneManagerService.compileTemplate()
            await this.sceneManagerService.sync(true)

            const variationString = getConfigurationString(this.sceneManagerService.$currentLocalConfiguration())

            const [, renderJob] = await createRenderJob(
                this.injector,
                this.renderingService,
                templateRevisionId,
                variationString,
                this.sceneManagerService.$scene(),
                defaultCustomerId,
            )

            this.notificationsService.showInfo(`Job submitted. (id = ${renderJob.id})`)
        } finally {
            this.$startingJob.set(false)
        }

        this.refreshJobs()
    }

    private async deleteJobs(deleteRender: boolean, deletePostProcess: boolean): Promise<DeleteJobForTemplateImageViewerMutation[]> {
        const templateRevisionId = this.sceneManagerService.$templateRevisionId()
        if (!templateRevisionId) throw Error("No template revision id set")

        const configurationString = getConfigurationString(this.sceneManagerService.$currentLocalConfiguration())

        const jobs = [
            ...(deleteRender ? [deleteRenderJob(this.injector, templateRevisionId, configurationString)] : []),
            ...(deletePostProcess ? [deletePostProcessJob(this.injector, templateRevisionId, configurationString)] : []),
        ]
        return (await Promise.all(jobs)).filter((result): result is DeleteJobForTemplateImageViewerMutation => result !== undefined)
    }

    async deleteAll() {
        await this.deleteJobs(true, true)
        this.refreshJobs()
    }

    async reRender() {
        await this.deleteJobs(true, true)
        this.render()
    }

    async deletePostProcess() {
        await this.deleteJobs(false, true)
        this.refreshJobs()
    }

    async finalizeRender() {
        try {
            this.$startingJob.set(true)

            const templateRevisionId = this.sceneManagerService.$templateRevisionId()
            if (!templateRevisionId) throw Error("No template revision id set")

            const defaultCustomerId = this.sceneManagerService.$defaultCustomerId()
            if (!defaultCustomerId) throw Error("No default customer id set")

            const variationString = getConfigurationString(this.sceneManagerService.$currentLocalConfiguration())

            const postProcessingSettings = this.$postProcessingSettings()
            if (!postProcessingSettings) throw Error("No post processing settings found")

            const [, postProcessingJob] = await createPostProcessJob(
                this.injector,
                templateRevisionId,
                variationString,
                postProcessingSettings,
                defaultCustomerId,
            )

            this.notificationsService.showInfo(`Job submitted. (id = ${postProcessingJob.id})`)
            this.$postProcessedImageData.set(undefined)
        } finally {
            this.$startingJob.set(false)
        }

        this.refreshJobs()
    }

    async reFinalizeRender() {
        await this.deleteJobs(false, true)
        this.finalizeRender()
    }

    async cancelJob(jobId: string) {
        this.$cancellingJob.set(true)
        try {
            await mutateThrowingErrors(this.cancelJobGql)({id: jobId})
            this.refreshJobs()
        } finally {
            this.$cancellingJob.set(false)
        }
    }

    async deleteJob(jobId: string) {
        this.$deletingJob.set(true)
        try {
            await mutateThrowingErrors(this.deleteJobGql)({id: jobId})
            this.refreshJobs()
        } finally {
            this.$deletingJob.set(false)
        }
    }

    async deleteOverrideJob() {
        this.$deletingJob.set(true)
        try {
            const overrideJobData = this.$overrideJobData()
            if (overrideJobData) {
                await mutateThrowingErrors(this.deleteJobGql)({id: overrideJobData.job.id})
                this.$overrideJobData.set(undefined) // this will trigger refreshJobs
            }
        } finally {
            this.$deletingJob.set(false)
        }
    }
}

export const getPostProcessingInput = async (renderOutput: PictureRenderJobOutput, injector: Injector, inMemory: boolean): Promise<PostProcessingInputData> => {
    const dataObjectGql = injector.get(GetDataObjectDetailsForTemplateImageViewerGQL)

    const getImage = async (dataObjectReference: JobNodes.DataObjectReference): Promise<ImageProcessingNodes.ImageNode> => {
        if (inMemory) {
            const {dataObject} = await fetchThrowingErrors(dataObjectGql)({legacyId: dataObjectReference.dataObjectId})
            const {mediaType, imageColorSpace, downloadUrl} = dataObject

            if (!mediaType) throw new Error("No media type found")
            if (imageColorSpace !== ImageColorSpace.Linear && imageColorSpace !== ImageColorSpace.Srgb) {
                throw Error(`Cannot resolve external data of unsupported color space: ${imageColorSpace}`)
            }

            const response = await fetch(downloadUrl)
            if (!response.ok) {
                throw new Error(`Failed to download data: ${response.statusText} for image data object ${dataObjectReference.dataObjectId}`)
            }

            const data = new Uint8Array(await response.arrayBuffer())

            return {
                type: "decode",
                input: {
                    type: "externalData",
                    resolveTo: "encodedData",
                    sourceData: dataObjectReference,
                    resolvedData: {
                        data,
                        mediaType,
                        colorSpace: imageColorSpace === ImageColorSpace.Linear ? "linear" : "sRGB",
                    },
                },
            }
        } else return {type: "decode", input: {type: "externalData", resolveTo: "encodedData", sourceData: dataObjectReference}}
    }

    const getRenderMetadata = async (dataObjectReference: JobNodes.DataObjectReference): Promise<RenderMetadata> => {
        const {dataObject} = await fetchThrowingErrors(dataObjectGql)({legacyId: dataObjectReference.dataObjectId})
        const {downloadUrl} = dataObject

        const response = await fetch(downloadUrl)
        if (!response.ok) {
            throw new Error(`Failed to download data: ${response.statusText} for metadata object ${dataObjectReference.dataObjectId}`)
        }

        const data = await response.json()
        return RenderMetadataSchema.parse(data)
    }

    if (renderOutput.renderPasses) {
        const {renderPasses, aoShadowMaskPass, metadata} = renderOutput

        const {Combined: combinedPass, ShadowCatcher: shadowCatcherPass} = renderPasses
        if (!combinedPass) throw Error("Job output has no combined render pass")

        return promiseAllProperties({
            combinedPass: getImage(combinedPass),
            shadowCatcherPass: shadowCatcherPass ? getImage(shadowCatcherPass) : undefined,
            shadowMaskPass: aoShadowMaskPass ? getImage(aoShadowMaskPass) : undefined,
            maskData: metadata
                ? (async () => {
                      //NOTE: all cryptomatte passes use a common ID space, so the list order and type (material, asset, etc.) does not matter
                      const pendingCryptoPasses = Object.entries(renderPasses)
                          .filter((entry): entry is [string, JobNodes.DataObjectReference] => {
                              const [passName, dataObjectRef] = entry
                              return isCryptomattePass(passName) && dataObjectRef !== undefined
                          })
                          .map(([_, dataObjectRef]) => getImage(dataObjectRef))

                      if (pendingCryptoPasses.length === 0) return undefined
                      return {
                          cryptoPasses: await Promise.all(pendingCryptoPasses),
                          cryptoManifest: (await getRenderMetadata(metadata)).cryptomatteManifest,
                      }
                  })()
                : undefined,
        })
    } else if (renderOutput.preview) {
        const image = await getImage(renderOutput.preview)
        return {combinedPass: image}
    } else throw Error("Job output has no valid renderPasses or preview")
}
