import {ChangeDetectionStrategy, Component, inject, input} from "@angular/core"
import {MatSelectModule} from "@angular/material/select"
import {InterfaceDescriptor, isConfigInput, isMaterialInput} from "@cm/template-nodes"
import {SelectMaterialButton} from "@common/components/viewers/configurator/config-menu/select-material/select-material.component"
import {ConfigMenuService} from "@common/components/viewers/configurator/config-menu/services/config-menu.service"

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "cm-default-menu",
    templateUrl: "./default-menu.component.html",
    styleUrls: ["./default-menu.component.scss"],
    imports: [MatSelectModule, SelectMaterialButton],
})
export class DefaultMenuComponent {
    readonly $interfaceDescriptors = input.required<InterfaceDescriptor<unknown, object>[]>({alias: "interfaceDescriptors"})

    isConfigInput = isConfigInput
    isMaterialInput = isMaterialInput

    readonly configMenuService = inject(ConfigMenuService)

    constructor() {}
}
