import {DestroyRef, inject, Injectable, Signal} from "@angular/core"
import {toSignal} from "@angular/core/rxjs-interop"
import {batchedData$} from "@app/platform/helpers/data/batched-data"
import {DEFAULT_BATCH_SIZES} from "@common/models/data/constants"
import {PagingParams} from "@common/models/data/types"
import {InfiniteScrollService} from "@common/services/infinite-scroll/infinite-scroll.service"
import {PagingService} from "@common/services/paging/paging.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {PLACEHOLDER_ITEMS} from "@platform/models/data/constants"
import {pagedData$} from "@platform/helpers/data/paged-data"
import {DataLoader, DataWatcher, LoadedData} from "@platform/models/data"
import {combineLatest, EMPTY, from, map, Observable, of, switchMap} from "rxjs"

@Injectable({
    providedIn: "root",
})
export class DataLoaderService {
    readonly destroyRef = inject(DestroyRef)
    readonly paging = inject(PagingService)
    readonly refresh = inject(RefreshService)
    readonly infiniteScroll = inject(InfiniteScrollService)

    public pagedData$<
        EntityType extends {
            id: string
        },
    >(
        loader$: Observable<DataLoader<EntityType>>,
        paging$: Observable<PagingParams> | "global",
        batchSizes?: {initial: number},
    ): Observable<LoadedData<EntityType>> {
        const pagingOrGlobal$: Observable<PagingParams> = paging$ === "global" ? this.paging.params$ : paging$
        const loaderObservable$: Observable<DataWatcher<EntityType>> = loader$.pipe(
            map((dataLoader) => (skip: number, take: number) => from(dataLoader(skip, take))),
        )
        return pagedData$(loaderObservable$, pagingOrGlobal$, this.refresh.observeAll$, batchSizes)
    }

    public $pagedData<EntityType extends {id: string}>(
        loader$: Observable<DataLoader<EntityType>>,
        paging$: Observable<PagingParams> | "global",
    ): Signal<LoadedData<EntityType>> {
        return toSignal(this.pagedData$(loader$, paging$), {initialValue: PLACEHOLDER_ITEMS<EntityType>()})
    }

    public watchPagedData$<EntityType extends {id: string}>(
        items$: Observable<(skip: number, take: number) => Observable<{data?: {items: (EntityType | null)[]} | null}>>,
        count$: Observable<{data?: {totalCount: number} | null}>,
        paging$: Observable<PagingParams> | "global",
    ): Observable<LoadedData<EntityType>> {
        const loader$: Observable<DataWatcher<EntityType>> = items$.pipe(
            switchMap((itemsLoader) =>
                of((skip: number, take: number) => {
                    return combineLatest([itemsLoader(skip, take), count$]).pipe(
                        switchMap(([itemsResult, totalCountResult]) => {
                            if (itemsResult.data && totalCountResult.data) {
                                return of({
                                    items: itemsResult.data.items,
                                    totalCount: totalCountResult.data.totalCount,
                                })
                            }
                            return EMPTY
                        }),
                    )
                }),
            ),
        )
        const pagingOrGlobal$: Observable<PagingParams> = paging$ === "global" ? this.paging.params$ : paging$
        return pagedData$(loader$, pagingOrGlobal$, this.refresh.observeAll$)
    }

    public watchPagedDataItems$<EntityType extends {id: string}>(
        items$: Observable<(skip: number, take: number) => Observable<{data?: {items: (EntityType | null)[]; itemsCount: number} | null}>>,
        paging$: Observable<PagingParams> | "global",
    ): Observable<LoadedData<EntityType>> {
        const loader$: Observable<DataWatcher<EntityType>> = items$.pipe(
            switchMap((itemsLoader) =>
                of((skip: number, take: number) => {
                    return itemsLoader(skip, take).pipe(
                        switchMap(({data}) => {
                            if (data) {
                                return of({
                                    items: data.items,
                                    totalCount: data.itemsCount,
                                })
                            }
                            return EMPTY
                        }),
                    )
                }),
            ),
        )
        const pagingOrGlobal$: Observable<PagingParams> = paging$ === "global" ? this.paging.params$ : paging$
        return pagedData$(loader$, pagingOrGlobal$, this.refresh.observeAll$)
    }

    public $watchPagedData<EntityType extends {id: string}>(
        items$: Observable<(skip: number, take: number) => Observable<{data?: {items: (EntityType | null)[]} | null}>>,
        count$: Observable<{data?: {totalCount: number} | null}>,
        paging$: Observable<PagingParams> | "global",
    ): Signal<LoadedData<EntityType>> {
        return toSignal(this.watchPagedData$(items$, count$, paging$), {initialValue: PLACEHOLDER_ITEMS<EntityType>()})
    }

    public fullData$<
        EntityType extends {
            id: string
        },
    >(loader$: Observable<DataLoader<EntityType>>): Observable<LoadedData<EntityType>> {
        return this.pagedData$(loader$, of({page: 0, pageSize: 1000}), {initial: 5})
    }

    public $fullData<EntityType extends {id: string}>(loader$: Observable<DataLoader<EntityType>>): Signal<LoadedData<EntityType>> {
        return toSignal(this.fullData$(loader$), {initialValue: PLACEHOLDER_ITEMS<EntityType>()})
    }

    public batchedData$<EntityType extends {id: string}>(
        loader$: Observable<DataLoader<EntityType>>,
        needsMorePlaceholders$: Observable<boolean>,
        batchParams = DEFAULT_BATCH_SIZES,
    ): Observable<LoadedData<EntityType>> {
        const loaderObservable$: Observable<DataWatcher<EntityType>> = loader$.pipe(
            map((dataLoader) => (skip: number, take: number) => from(dataLoader(skip, take))),
        )
        return batchedData$(loaderObservable$, needsMorePlaceholders$, batchParams)
    }

    public $batchedData<EntityType extends {id: string}>(
        loader$: Observable<DataLoader<EntityType>>,
        needsMorePlaceholders$: Observable<boolean>,
        batchParams = DEFAULT_BATCH_SIZES,
    ): Signal<LoadedData<EntityType>> {
        return toSignal(this.batchedData$(loader$, needsMorePlaceholders$, batchParams), {
            requireSync: true,
        })
    }
}
