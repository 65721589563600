<div class="cm-label">
    <div #ref><ng-content select="[cm-label]"> </ng-content></div>
    @if (!ref.innerHTML.trim()) {
        {{ $labelText() }}
    }
</div>
<cm-card
    [defaultThumbnail]="false"
    (click)="onClick()"
    class="cm-accept-nodes"
    [class.cm-drop]="$highlighted()"
    [class.cm-connected]="$node() !== null"
    [draggable]="true"
    (dragstart)="dragStart($event)"
    (dragend)="drag.dragEnd($event)"
>
    <ng-container cm-thumbnail>
        @if (!$node()) {
            <div class="cm-drop-background">
                <i [class]="$imageIcon() + ' cm-image-icon'"></i>
                @if ($label(); as label) {
                    <span class="cm-image-text">{{ label }}</span>
                }
                <span class="cm-image-text-subtitle">Drag here or click to upload a file</span>
            </div>
        } @else {
            @if ($thumbnailObject(); as thumbnailData) {
                <cm-data-object-thumbnail [dataObjectId]="thumbnailData.id" class="cm-picture" draggable="false"></cm-data-object-thumbnail>
            } @else {
                @if ($thumbnailUrl(); as thumbnailData) {
                    <cm-thumbnail [url]="thumbnailData" class="cm-picture" draggable="false"></cm-thumbnail>
                }
            }
        }
    </ng-container>

    <ng-container cm-title>
        @if ($node(); as node) {
            <cm-template-node [node]="node" [noDrag]="true" #dragImage></cm-template-node>
        } @else {
            <i class="fa fa-sign-in"></i>
            <span class="cm-image-title-none"> (No Image) </span>
        }
    </ng-container>

    @if ($node()) {
        <ng-container cm-top-left>
            <div matTooltip="Remove assignment" (click)="$event.stopPropagation(); removeAssignment()" class="cm-icon">
                <i class="far fa-trash"></i>
            </div>
        </ng-container>
    }
    @if ($isReference()) {
        <ng-container cm-bottom-left>
            <div matTooltip="Node is a reference" (click)="$event.stopPropagation(); gotoReference()" class="cm-icon">
                <i class="far fa-external-link"></i>
            </div>
        </ng-container>
    }
</cm-card>
