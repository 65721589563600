import {Component, output} from "@angular/core"

@Component({
    selector: "cm-menu-dialog",
    templateUrl: "./menu-dialog.component.html",
    styleUrls: ["./menu-dialog.component.scss"],
})
export class MenuDialogComponent {
    readonly close = output<void>()
}
