import {Component, inject, Inject} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {ContentTypeModel, TagType, TemplateType} from "@generated"
import {AuthService} from "@common/services/auth/auth.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {Enums} from "@enums"
import {Labels} from "@labels"
import {UtilsService} from "@legacy/helpers/utils"

@Component({
    selector: "cm-new-item-dialog",
    templateUrl: "./new-item-dialog.component.html",
    styleUrls: ["./new-item-dialog.component.scss"],
    imports: [MatDialogModule, MatInputModule, MatSelectModule, FormsModule, MatButtonModule],
})
export class NewItemDialogComponent {
    result: NewItemDialogResultGql = new NewItemDialogResultGql()
    readonly Enums = Enums
    readonly Labels = Labels
    templateTypeLabels = Array.from(Labels.TemplateType.values())
    tagTypeLabels = Array.from(Labels.TagType.values())

    readonly organizations = inject(OrganizationsService)
    readonly permission = inject(PermissionsService)
    $can = this.permission.$to

    constructor(
        public dialogRef: MatDialogRef<NewItemDialogComponent>,
        public authService: AuthService,
        public utils: UtilsService,
        @Inject(MAT_DIALOG_DATA) public data: NewItemDialogDataGql,
    ) {
        // Set Product as default types
        this.result.templateType = TemplateType.Product
        this.result.tagType = TagType.MaterialRange
    }

    cancelAction(): void {
        this.dialogRef.close(false)
    }

    confirmAction(): void {
        this.dialogRef.close(this.result)
    }
}

export class NewItemDialogDataGql {
    entityType?: ContentTypeModel
}

export class NewItemDialogResultGql {
    name?: string
    url?: string
    organizationId?: string
    templateType?: TemplateType
    tagType?: TagType
}
