import {Component, computed} from "@angular/core"
import {BaseInspectorComponent} from "@app/template-editor/components/inspectors/base-inspector/base-inspector.component"
import {SelectionPossibilities, ValueSlotComponent} from "@app/template-editor/components/value-slot/value-slot.component"
import {DimensionGuides, GuideParameters, unitForDisplay} from "@cm/template-nodes"
import {BoundsEdgeDimension, boundsEdgeDirectionToIndex, boundsEdgeNumber} from "@cm/template-nodes/utils/scene-geometry-utils"

@Component({
    selector: "cm-dimension-guides-inspector",
    standalone: true,
    templateUrl: "./dimension-guides-inspector.component.html",
    styleUrl: "./dimension-guides-inspector.component.scss",
    imports: [ValueSlotComponent],
})
export class DimensionGuidesInspectorComponent extends BaseInspectorComponent<DimensionGuides> {
    readonly $edgeOptions = computed<SelectionPossibilities<number>>(() => {
        return boundsEdgeNumber.options.map((opt) => ({
            name: `Edge ${opt.value}`,
            value: opt.value,
        }))
    })

    readonly $unitOptions = computed<SelectionPossibilities<string>>(() => {
        const result = unitForDisplay.options.map((opt) => ({
            name: opt,
            value: opt,
        }))

        return result
    })

    changedEdge(direction: BoundsEdgeDimension) {
        const node = this.$node()
        const guide: GuideParameters = node.parameters.guides[boundsEdgeDirectionToIndex(direction)]

        node.updateParameters({
            ...node.parameters,
            [`guide${direction.toUpperCase()}`]: guide,
        })
    }
}
