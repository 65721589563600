<div class="cm-texture-selector">
    @for (textureType of textureTypeOrder; track textureType) {
        @if (textureItemByType.get(textureType); as textureItem) {
            <div class="cm-texture-map">
                <div
                    class="cm-texture-container"
                    [matTooltip]="descriptorByTextureType(textureItem.textureType).label"
                    matTooltipPosition="above"
                    (click)="itemClicked(textureItem)"
                >
                    <img class="cm-texture-image" draggable="false" [src]="textureItem.thumbnailUrl" alt="Texture thumbnail" />
                    @if (textureType === $selectedTextureType()) {
                        <i class="far fa-check cm-selected-icon"></i>
                    }
                </div>
            </div>
        }
    }
</div>
