import {NodeGraphClass, NodeParameters, nodeParameters} from "@cm/graph/node-graph"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {ObjectData} from "#template-nodes/interfaces/object-data"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {DeclareTemplateNodeTS, TemplateNodeTSImplementation, TemplateNodeImplementation, TemplateNodeMeta} from "#template-nodes/declare-template-node"
import {TemplateNode, OnCompileContext, matrix4Value} from "#template-nodes/types"
import {z} from "zod"
import {SolverObjectData} from "#template-nodes/runtime-graph/nodes/solver/object-data"
import {Transform, TransformState} from "#template-nodes/runtime-graph/nodes/transform"
import {ThisStructID} from "#template-nodes/runtime-graph/types"
import {CompleteMeshData, BoundsData, UntransformedBoundsData} from "#template-nodes/geometry-processing/mesh-data"
import {BuilderInlet, BuilderOutlet} from "#template-nodes/runtime-graph/graph-builder"
import {transformBounds} from "#template-nodes/utils/scene-geometry-utils"
import {Matrix4} from "@cm/math"
import {TransformAccessorListEntry} from "#template-nodes/runtime-graph/nodes/compile-template"
import {ObjectId, SceneNodes} from "#template-nodes/interfaces/scene-object"
import {SolverData} from "#template-nodes/runtime-graph/nodes/solver/data"

const objectNode = z.object({
    lockedTransform: matrix4Value.optional(),
    $defaultTransform: matrix4Value.optional(),
    visible: z.boolean().default(true),
})
export type ObjectNode = z.infer<typeof objectNode>

export function DeclareObjectNode<ZodParamTypes extends z.ZodType<NodeParameters>>(
    definition: {
        parameters: ZodParamTypes
    },
    implementation: TemplateNodeImplementation<z.infer<typeof definition.parameters> & ObjectNode>,
    meta: TemplateNodeMeta<z.infer<typeof definition.parameters> & ObjectNode>,
) {
    const {parameters: paramsSchema} = definition
    type ParamTypes = z.infer<typeof paramsSchema>

    return DeclareObjectNodeTS<ParamTypes>({...implementation, validation: {paramsSchema}}, meta)
}

const isPreDisplayItem = (sceneNode: SceneNodes.SceneNode) => SceneNodes.AreaLight.is(sceneNode)

export function DeclareObjectNodeTS<ParamTypes extends NodeParameters>(
    implementation: TemplateNodeTSImplementation<ParamTypes & ObjectNode>,
    meta: TemplateNodeMeta<ParamTypes & ObjectNode>,
): NodeGraphClass<TemplateObjectNode<ParamTypes>> {
    const retClass = class
        extends DeclareTemplateNodeTS<ParamTypes & ObjectNode>(
            {...implementation, validation: {paramsSchema: objectNode.and(implementation.validation?.paramsSchema ?? nodeParameters)}},
            meta,
        )
        implements EvaluableTemplateNode<ObjectData | null>
    {
        evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
            return scope.unresolvedToNull(evaluator.templateScope.resolve<ObjectData>(`objectData-${evaluator.getLocalId(this)}`))
        }

        setupTransform(
            scope: GraphBuilderScope,
            context: OnCompileContext,
            objectId: ObjectId,
            transform: BuilderInlet<Matrix4> | undefined,
        ): readonly [BuilderOutlet<Matrix4>, BuilderOutlet<SolverObjectData>] {
            const {evaluator, currentTemplate} = context
            const {solverData, transformAccessorList} = currentTemplate
            const {templateContext} = evaluator
            const {templateMatrix} = templateContext
            const {sceneManager} = templateContext
            const {lockedTransform} = this.parameters
            const {solverObjects} = solverData

            const transformNode = scope.node(Transform, {
                state: scope.state(TransformState, "transformState"),
                defaultTransform: sceneManager.defaultTransformForObject(this)?.toArray(),
                lockedTransform: scope.pureLambda(
                    scope.tuple(transform, lockedTransform),
                    ([transform, lockedTransform]) => {
                        if (transform && lockedTransform) return transform.multiply(Matrix4.fromArray(lockedTransform)).toArray()
                        return transform?.toArray() ?? lockedTransform
                    },
                    "lockedTransform",
                ),
                parentMatrix: transform === undefined ? templateMatrix : Matrix4.identity(),
            })

            const solverObject = scope.struct<SolverObjectData>("SolverObjectData", {
                id: ThisStructID,
                transformAccessor: transformNode.accessor,
            })
            solverObjects.push(solverObject)

            transformAccessorList.push(
                scope.struct<TransformAccessorListEntry>("TransformAccessorListEntry", {
                    objectId,
                    transformAccessor: transformNode.accessor,
                }),
            )

            return [transformNode.matrix, solverObject] as const
        }

        setupObject(
            scope: GraphBuilderScope,
            context: OnCompileContext,
            typeName: string,
            meshData: BuilderInlet<CompleteMeshData> | undefined,
            parent:
                | BuilderInlet<{
                      id: ObjectId
                      matrix: Matrix4
                      visible: boolean
                  }>
                | undefined,
            generator: (
                objectProps: {
                    $id: ObjectId
                    id: ObjectId
                    topLevelObjectId: ObjectId
                    transform: BuilderInlet<Matrix4>
                },
                otherData: {
                    bounds: BuilderOutlet<BoundsData>
                    solverObject: BuilderOutlet<SolverObjectData>
                    solverData: BuilderOutlet<SolverData>
                    visible: BuilderOutlet<boolean>
                },
            ) => BuilderOutlet<SceneNodes.SceneNode | SceneNodes.SceneNode[]>,
            invalidator?: BuilderOutlet<null>,
        ) {
            const {evaluator, topLevelObjectId, currentTemplate} = context
            const {allBounds, allUntransformedBounds, objectToNodeMap, nodeToObjectMap} = currentTemplate
            const {preDisplayLists, displayLists} = context.subTemplates
            const {templateScope} = evaluator
            const {visible: thisVisible} = this.parameters

            const objectId = scope.genStructId(typeName)
            objectToNodeMap.set(objectId, this)
            nodeToObjectMap.set(this, objectId)

            const [transformMatrix, solverObject] = this.setupTransform(scope, context, objectId, parent ? scope.get(parent, "matrix") : undefined)

            const untransformedBounds = scope.pureLambda(
                scope.tuple(
                    ((): BuilderInlet<BoundsData> => {
                        if (meshData) return scope.get(scope.get(meshData, "reified"), "bounds")
                        else {
                            //TODO: proper bounds for planes, etc.
                            return {
                                centroid: [0, 0, 0],
                                surfaceArea: 0,
                                aabb: [
                                    [0, 0, 0],
                                    [0, 0, 0],
                                ],
                                radii: {xy: 0, xz: 0, yz: 0, xyz: 0},
                            }
                        }
                    })(),
                    transformMatrix,
                ),
                ([bounds, transformMatrix]): UntransformedBoundsData => {
                    return {...bounds, matrix: transformMatrix.toArray()}
                },
                "getBounds",
            )

            allUntransformedBounds.push(untransformedBounds)

            const bounds = scope.pureLambda(
                scope.tuple(untransformedBounds, transformMatrix),
                ([bounds, matrix]) => transformBounds(bounds, matrix),
                "transformBounds",
            )

            allBounds.push(bounds)

            const visible = scope.pureLambda(
                scope.tuple(thisVisible, parent),
                ([thisVisible, parentVisible]) => thisVisible && (parentVisible?.visible ?? true),
                "visible",
            )

            const solverData = scope.struct<SolverData>("SolverData", {
                objects: scope.pureLambda(solverObject, (solverObject) => [solverObject], "solverObject"),
                relations: [],
                variables: [],
            })

            const sceneNodes = scope.pureLambda(
                generator(
                    {
                        $id: objectId,
                        id: objectId,
                        topLevelObjectId: topLevelObjectId ?? objectId,
                        transform: transformMatrix,
                    },
                    {
                        bounds: bounds,
                        solverObject,
                        solverData,
                        visible,
                    },
                ),
                (sceneNode) => (Array.isArray(sceneNode) ? sceneNode : [sceneNode]),
                "sceneNodes",
            )

            const preDisplaySceneNode = scope.pureLambda(sceneNodes, (sceneNodes) => sceneNodes.filter(isPreDisplayItem), "preDisplaySceneNode")
            const displaySceneNode = scope.pureLambda(
                sceneNodes,
                (sceneNodes) => sceneNodes.filter((sceneNode) => !isPreDisplayItem(sceneNode)),
                "displaySceneNode",
            )

            preDisplayLists.push(
                scope.pureLambda(
                    scope.tuple(visible, preDisplaySceneNode),
                    ([visible, preDisplaySceneNode]) => (visible ? preDisplaySceneNode : []),
                    "preDisplayList",
                ),
            )
            displayLists.push(
                scope.pureLambda(scope.tuple(visible, displaySceneNode), ([visible, displaySceneNode]) => (visible ? displaySceneNode : []), "displayList"),
            )

            const objectData = scope.struct<ObjectData>("ObjectData", {
                id: objectId,
                topLevelObjectId: topLevelObjectId ?? objectId,
                matrix: transformMatrix,
                solverObject,
                bounds: bounds,
                untransformedBounds: untransformedBounds,
                preDisplayList: preDisplaySceneNode,
                displayList: displaySceneNode,
                solverData,
                visible,
            })

            templateScope.alias(invalidator ? scope.phi(objectData, invalidator) : objectData, `objectData-${evaluator.getLocalId(this)}`)
        }
    }
    return retClass
}

export type TemplateObjectNode<ParamTypes extends NodeParameters = {}> = TemplateNode<ParamTypes & ObjectNode> &
    EvaluableTemplateNode<ObjectData | null> & {
        setupTransform(
            scope: GraphBuilderScope,
            context: OnCompileContext,
            objectId: ObjectId,
            transform: BuilderInlet<Matrix4> | undefined,
        ): readonly [BuilderOutlet<Matrix4>, BuilderOutlet<SolverObjectData>]
        setupObject(
            scope: GraphBuilderScope,
            context: OnCompileContext,
            typeName: string,
            meshData: BuilderInlet<CompleteMeshData> | undefined,
            parent:
                | BuilderInlet<{
                      id: ObjectId
                      matrix: Matrix4
                      visible: boolean
                  }>
                | undefined,
            generator: (
                objectProps: {
                    $id: ObjectId
                    id: ObjectId
                    topLevelObjectId: ObjectId
                    transform: BuilderInlet<Matrix4>
                },
                otherData: {
                    bounds: BuilderOutlet<BoundsData>
                    solverObject: BuilderOutlet<SolverObjectData>
                    solverData: BuilderOutlet<SolverData>
                    visible: BuilderOutlet<boolean>
                },
            ) => BuilderOutlet<SceneNodes.SceneNode | SceneNodes.SceneNode[]>,
            invalidator?: BuilderOutlet<null>,
        ): void
    }
