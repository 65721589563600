import {Component} from "@angular/core"
import {Group} from "@cm/template-nodes"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {ValueSlotComponent} from "../../value-slot/value-slot.component"

@Component({
    selector: "cm-group-inspector",
    templateUrl: "./group-inspector.component.html",
    styleUrl: "./group-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent],
})
export class GroupInspectorComponent extends BaseInspectorComponent<Group> {}
