import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TagDetailsItemFragment = { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: Types.TagType, createdBy: { __typename: 'User', id: string, name: string, email: string }, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null };

export type GetTagDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetTagDetailsQuery = { __typename: 'Query', item: { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: Types.TagType, createdBy: { __typename: 'User', id: string, name: string, email: string }, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } };

export type UpdateTagDetailsMutationVariables = Types.Exact<{
  input: Types.MutationUpdateTagInput;
}>;


export type UpdateTagDetailsMutation = { __typename: 'Mutation', item: { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: Types.TagType, createdBy: { __typename: 'User', id: string, name: string, email: string }, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } };

export const TagDetailsItemFragmentDoc = gql`
    fragment TagDetailsItem on Tag {
  id
  legacyId
  __typename
  color
  description
  name
  type
  createdBy {
    id
    name
    email
  }
  organization {
    id
    legacyId
    name
  }
}
    `;
export const GetTagDetailsDocument = gql`
    query getTagDetails($id: ID!) {
  item: tag(id: $id) {
    ...TagDetailsItem
  }
}
    ${TagDetailsItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagDetailsGQL extends Apollo.Query<GetTagDetailsQuery, GetTagDetailsQueryVariables> {
    override document = GetTagDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTagDetailsDocument = gql`
    mutation updateTagDetails($input: MutationUpdateTagInput!) {
  item: updateTag(input: $input) {
    ...TagDetailsItem
  }
}
    ${TagDetailsItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTagDetailsGQL extends Apollo.Mutation<UpdateTagDetailsMutation, UpdateTagDetailsMutationVariables> {
    override document = UpdateTagDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }