import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MaterialRevisionsHistoryDialogMaterialRevisionFragment = { __typename: 'MaterialRevision', id: string, number: number, createdAt: string, hasCyclesMaterial: boolean, graphSchema?: string | null, comment?: string | null, createdBy?: { __typename: 'User', firstName?: string | null, lastName?: string | null } | null, matFileDataObject?: { __typename: 'DataObject', downloadUrl: string } | null };

export type MaterialRevisionsHistoryDialogMaterialRevisionQueryVariables = Types.Exact<{
  materialId: Types.Scalars['ID']['input'];
}>;


export type MaterialRevisionsHistoryDialogMaterialRevisionQuery = { __typename: 'Query', materialRevisions: Array<{ __typename: 'MaterialRevision', id: string, number: number, createdAt: string, hasCyclesMaterial: boolean, graphSchema?: string | null, comment?: string | null, createdBy?: { __typename: 'User', firstName?: string | null, lastName?: string | null } | null, matFileDataObject?: { __typename: 'DataObject', downloadUrl: string } | null } | null> };

export type MaterialRevisionsHistoryDialogDeleteMaterialRevisionMutationVariables = Types.Exact<{
  materialRevisionId: Types.Scalars['ID']['input'];
}>;


export type MaterialRevisionsHistoryDialogDeleteMaterialRevisionMutation = { __typename: 'Mutation', deleteMaterialRevision?: any | null };

export const MaterialRevisionsHistoryDialogMaterialRevisionFragmentDoc = gql`
    fragment MaterialRevisionsHistoryDialogMaterialRevision on MaterialRevision {
  id
  number
  createdBy {
    firstName
    lastName
  }
  createdAt
  hasCyclesMaterial
  graphSchema
  comment
  matFileDataObject {
    downloadUrl
  }
}
    `;
export const MaterialRevisionsHistoryDialogMaterialRevisionDocument = gql`
    query materialRevisionsHistoryDialogMaterialRevision($materialId: ID!) {
  materialRevisions(
    orderBy: {direction: desc, key: number}
    filter: {materialId: {equals: $materialId}}
  ) {
    ...MaterialRevisionsHistoryDialogMaterialRevision
  }
}
    ${MaterialRevisionsHistoryDialogMaterialRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialRevisionsHistoryDialogMaterialRevisionGQL extends Apollo.Query<MaterialRevisionsHistoryDialogMaterialRevisionQuery, MaterialRevisionsHistoryDialogMaterialRevisionQueryVariables> {
    override document = MaterialRevisionsHistoryDialogMaterialRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialRevisionsHistoryDialogDeleteMaterialRevisionDocument = gql`
    mutation materialRevisionsHistoryDialogDeleteMaterialRevision($materialRevisionId: ID!) {
  deleteMaterialRevision(id: $materialRevisionId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialRevisionsHistoryDialogDeleteMaterialRevisionGQL extends Apollo.Mutation<MaterialRevisionsHistoryDialogDeleteMaterialRevisionMutation, MaterialRevisionsHistoryDialogDeleteMaterialRevisionMutationVariables> {
    override document = MaterialRevisionsHistoryDialogDeleteMaterialRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }