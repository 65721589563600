import {Component, ElementRef, input, OnDestroy, OnInit, ViewChild} from "@angular/core"
import paper from "paper"
import {merge, Subject} from "rxjs"
import {toObservable} from "@angular/core/rxjs-interop"

@Component({
    selector: "cm-ruler",
    standalone: true,
    imports: [],
    templateUrl: "./ruler.component.html",
    styleUrl: "./ruler.component.scss",
})
export class RulerComponent implements OnInit, OnDestroy {
    $orientation = input<Orientation>("horizontal", {alias: "orientation"})
    $color = input<string>("black", {alias: "colorTicks"})
    $majorTickIntervalBase = input<number>(10, {alias: "majorTickIntervalBase"})
    $pixelsPerUnit = input<number>(1, {alias: "pixelsPerUnit"})
    $originOffset = input<number>(0, {alias: "originOffset"}) // in units
    $cursorPosition = input<number | undefined>(undefined, {alias: "cursorPosition"}) // in units
    $showCursorLabel = input<boolean>(false, {alias: "showCursorLabel"})

    @ViewChild("canvasElement", {static: true}) canvasElementRef!: ElementRef

    constructor() {
        merge(toObservable(this.$orientation), toObservable(this.$color), toObservable(this.$originOffset), toObservable(this.$pixelsPerUnit)).subscribe(() =>
            this.updateTicks.next(),
        )
        merge(toObservable(this.$cursorPosition), toObservable(this.$showCursorLabel)).subscribe(() => this.createCursor())
    }

    ngOnInit() {
        this.canvas = this.canvasElementRef.nativeElement
        this.scope = new paper.PaperScope()
        this.scope.setup(this.canvas)
        this.project = this.scope.project

        this.resizeObserver = new ResizeObserver(() => this.updateTicks.next())
        this.resizeObserver.observe(this.canvas, {box: "content-box"})

        this.ticksGroup = new paper.Group()
        this.ticksGroup.sendToBack()
        this.cursorGroup = new paper.Group()
        this.cursorGroup.bringToFront()

        this.updateTicks.subscribe(() => this.createTicks())
        this.createTicks()
    }

    ngOnDestroy() {
        this.resizeObserver.disconnect()
        this.removeCursor()
        this.removeTicks()
        this.cursorGroup.remove()
        this.cursorGroup.remove()
        this.project.clear()
        this.project.remove()
    }

    private createCursor() {
        this.removeCursor()
        const cursorPosition = this.$cursorPosition()
        if (cursorPosition === undefined) {
            return
        }
        this.scope.activate()
        this.project.activate()
        const isHorizontal = this.$orientation() === "horizontal"
        const view = this.project.view
        const height = isHorizontal ? view.size.height : view.size.width
        const color = new paper.Color(this.$color())
        // const cursorLineHeight = height
        const cursorPositionPx = this.unit2pixel(cursorPosition)
        // const from = new paper.Point(cursorPositionPx, height - cursorLineHeight)
        // const to = new paper.Point(cursorPositionPx, height)
        // if (!isHorizontal) {
        //     from.set(from.y, from.x)
        //     to.set(to.y, to.x)
        // }

        // marker
        const cursorTipPos = new paper.Point(cursorPositionPx, height)
        const cursorHeight = 5
        const cursorSideOfs1 = new paper.Point(5, -cursorHeight)
        const cursorSideOfs2 = new paper.Point(-5, -cursorHeight)
        if (!isHorizontal) {
            cursorTipPos.set(cursorTipPos.y, cursorTipPos.x)
            cursorSideOfs1.set(cursorSideOfs1.y, cursorSideOfs1.x)
            cursorSideOfs2.set(cursorSideOfs2.y, cursorSideOfs2.x)
        }
        const marker = new paper.Path({
            segments: [cursorTipPos, cursorTipPos.add(cursorSideOfs1), cursorTipPos.add(cursorSideOfs2)],
            fillColor: color,
            closed: true,
        })
        this.cursorGroup.addChild(marker)

        if (this.$showCursorLabel()) {
            const textGroup = new paper.Group()
            textGroup.insertBelow(marker)

            // text
            const numDecimalPlaces = Math.max(0, Math.ceil(Math.log10(this.$pixelsPerUnit())))
            const text = new paper.PointText({
                point: cursorTipPos,
                content: cursorPosition.toFixed(numDecimalPlaces),
                fontFamily: "Roboto",
                fontSize: 12,
                fillColor: "black",
            })
            if (!isHorizontal) {
                text.rotate(-90, cursorTipPos)
            }
            textGroup.addChild(text)

            // text background
            const textBounds = text.bounds
            const padding = new paper.Size(4, 1)
            const offset = new paper.Point(0, -1)
            if (!isHorizontal) {
                padding.set(padding.height, padding.width)
                offset.set(offset.y, offset.x)
            }
            const backgroundSize = textBounds.size.add(padding.multiply(2))
            const backgroundFrom = textBounds.point.subtract(padding).add(offset)
            const textBackground = new paper.Path.Rectangle({
                point: backgroundFrom,
                size: backgroundSize,
                fillColor: "white",
                strokeColor: color,
                strokeWidth: 1,
            })
            textGroup.addChild(textBackground)
            textBackground.insertBelow(text)

            if (isHorizontal) {
                textGroup.translate(new paper.Point(-textBounds.size.width / 2, -backgroundSize.height / 2 + offset.y))
            } else {
                textGroup.translate(new paper.Point(-backgroundSize.width / 2 + offset.x, textBounds.size.height / 2))
            }
        }
    }

    private removeCursor() {
        this.cursorGroup.removeChildren()
    }

    private createTicks() {
        const pixelsPerUnit = this.$pixelsPerUnit()
        const needToRecreateTicks = !this.cachedTicksInfo || this.cachedTicksInfo.pixelsPerUnit !== pixelsPerUnit
        if (needToRecreateTicks) {
            this.removeTicks()
            this.cachedTicksInfo = undefined
        }
        if (pixelsPerUnit <= 0) {
            return
        }
        this.scope.activate()
        this.project.activate()
        const minMajorTickDistancePx = 75
        const majorFontSize = 10
        const fontOffset = new paper.Point(3, 5)
        const isHorizontal = this.$orientation() === "horizontal"
        const view = this.project.view
        const width = isHorizontal ? view.size.width : view.size.height
        const height = isHorizontal ? view.size.height : view.size.width
        const majorTickIntervalBase = this.$majorTickIntervalBase()
        const minMajorTickDistanceUnits = minMajorTickDistancePx / pixelsPerUnit
        const optimalIntervalExponent = Math.log(minMajorTickDistanceUnits) / Math.log(majorTickIntervalBase)
        const optimalIntervalExponentInt = Math.floor(optimalIntervalExponent)
        const optimalIntervalExponentFrac = optimalIntervalExponent - optimalIntervalExponentInt
        const unitsPerTick = Math.pow(majorTickIntervalBase, optimalIntervalExponentInt)
        const majorTickHeight = height * 0.7
        const minorTickHeight = majorTickHeight * (1 - optimalIntervalExponentFrac)
        const minorFontSize = majorFontSize * (1 - optimalIntervalExponentFrac)
        const minorFontAlpha = Math.max(0, 1 - optimalIntervalExponentFrac * 2) // *2 to fade out faster
        const color = new paper.Color(this.$color())

        const drawTicks = (startTick: number, endTick: number) => {
            for (let index = startTick; index <= endTick; index++) {
                const isMajor = index % majorTickIntervalBase === 0
                const unit = index * unitsPerTick
                const pixel = this.unit2pixel(unit)
                const tickHeight = isMajor ? majorTickHeight : minorTickHeight
                const from = new paper.Point(pixel, height - tickHeight)
                const to = new paper.Point(pixel, height)
                if (!isHorizontal) {
                    from.set(from.y, from.x)
                    to.set(to.y, to.x)
                }
                const tick = new paper.Path.Line({
                    from,
                    to,
                    strokeColor: color,
                    strokeWidth: 1,
                })
                this.ticksGroup.addChild(tick)
                const fontAlpha = isMajor ? 1 : minorFontAlpha
                if (fontAlpha > 0) {
                    const fontColor = new paper.Color(color)
                    fontColor.alpha = fontAlpha
                    const text = new paper.PointText({
                        point: from.add(fontOffset),
                        content: unit.toFixed(6).replace(/(\.0+|(?<=\.\d+)0+)$/, ""), // remove trailing zeros
                        fillColor: fontColor,
                        fontFamily: "Roboto",
                        fontSize: isMajor ? majorFontSize : minorFontSize,
                    })
                    if (!isHorizontal) {
                        text.rotate(-90, from)
                    }
                    this.ticksGroup.addChild(text)
                }
            }
        }

        const numTicksHeadroom = majorTickIntervalBase >> 1 // to avoid the first tick's label popping in/out of view
        let startTick = Math.floor((this.$originOffset() - numTicksHeadroom * unitsPerTick) / unitsPerTick)
        let endTick = Math.floor((this.pixel2unit(width) + this.$originOffset()) / unitsPerTick)
        if (this.cachedTicksInfo) {
            drawTicks(startTick, this.cachedTicksInfo.startTick - 1)
            drawTicks(this.cachedTicksInfo.endTick + 1, endTick)
            startTick = Math.min(startTick, this.cachedTicksInfo.startTick)
            endTick = Math.max(endTick, this.cachedTicksInfo.endTick)
        } else {
            drawTicks(startTick, endTick)
        }
        this.cachedTicksInfo = {startTick, endTick, pixelsPerUnit}
        const viewOffset = new paper.Point(width / 2 + this.$originOffset() * pixelsPerUnit, height / 2)
        if (!isHorizontal) {
            viewOffset.set(viewOffset.y, viewOffset.x)
        }
        this.project.view.center = viewOffset

        this.createCursor()
    }

    private removeTicks() {
        this.ticksGroup?.removeChildren()
    }

    private unit2pixel(unit: number) {
        return unit * this.$pixelsPerUnit()
    }

    private pixel2unit(pixel: number) {
        return pixel / this.$pixelsPerUnit()
    }

    private resizeObserver!: ResizeObserver
    private canvas!: HTMLCanvasElement
    private scope!: paper.PaperScope
    private project!: paper.Project
    private ticksGroup!: paper.Group
    private cursorGroup!: paper.Group
    private cachedTicksInfo?: CachedTicksInfo
    private updateTicks = new Subject<void>()
}

type CachedTicksInfo = {
    startTick: number
    endTick: number
    pixelsPerUnit: number
}

export type Orientation = "horizontal" | "vertical"
