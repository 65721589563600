import {LoadedData, LoadedDataItem} from "@platform/models/data/types"
import {v4 as uuid4} from "uuid"

// loading state placeholder
// use `import {PLACEHOLDER_ITEM as _}` for shorthand convenience
export const PLACEHOLDER_ITEM = {data: null, error: null} as LoadedDataItem<unknown>

export const PLACEHOLDER_ITEMS = <EntityType>(length?: number): LoadedData<EntityType> => ({
    items: Array.from({length: length ?? 10}, () => ({trackId: uuid4(), data: null, error: null})),
    totalCount: undefined,
    error: null,
    complete: false,
})

export const ERROR_ITEMS = <EntityType>(length: number, error: Error): LoadedData<EntityType> => ({
    items: Array.from({length}, () => ({trackId: uuid4(), data: null, error})),
    totalCount: undefined,
    error,
    complete: false,
})

export const INITIAL_DATA = PLACEHOLDER_ITEMS(10)
export const EMPTY_DATA: LoadedData<unknown> = {items: [], totalCount: undefined, error: null, complete: false}
