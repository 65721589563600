import {computed, DestroyRef, effect, inject, Injectable, OnDestroy, signal, untracked} from "@angular/core"
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop"
import {ThreeMesh} from "@app/template-editor/helpers/three-mesh"
import {ThreeShadowCatcher, threeValueNode} from "@cm/material-nodes"
import {Matrix4, Vector3} from "@cm/math"
import {ObjectId, SceneNodes} from "@cm/template-nodes"
import {objectDifferent} from "@template-editor/helpers/change-detection"
import {ThreeAreaLight} from "@template-editor/helpers/three-area-light"
import {ThreeCamera} from "@template-editor/helpers/three-camera"
import {ThreeEnvironment} from "@template-editor/helpers/three-environment"
import {ThreeGrid} from "@template-editor/helpers/three-grid"
import {ThreeObject, ThreeObjectPart} from "@template-editor/helpers/three-object"
import {ThreeProgressiveUVShadowMap} from "@template-editor/helpers/three-progressive-uv-shadow-map"
import {SceneManagerService, SceneNodePart} from "@template-editor/services/scene-manager.service"
import {ThreeMaterialManagerService} from "@template-editor/services/three-material-manager.service"
import {concatMap, defer, delay, delayWhen, filter, from, of, range, Subject, switchMap, take, tap, timer} from "rxjs"
import {Three as THREE} from "@cm/material-nodes/three"
import {WebGLRenderer} from "@cm/material-nodes"
import {ThreeNodes as THREENodes} from "@cm/material-nodes/three"
import {ThreeAnnotation} from "../helpers/three-annotation"
import {ThreeLightPortal} from "../helpers/three-light-portal"
import {ThreeMeshCurveControl} from "../helpers/three-mesh-curve-control"
import {ThreePoint} from "../helpers/three-point"
import {ThreePreloadMaterial} from "../helpers/three-preload-material"
import {ThreeRectangle} from "../helpers/three-rectangle"
import {ThreeSeam} from "../helpers/three-seam"
import {ThreeDimensionGuides} from "../helpers/three-dimension-guides"
import {ThreeWireframeMesh} from "../helpers/three-wireframe-mesh"

export const SHADOW_MAP_COMPUTATION_DELAY = 1000
export const DEFAULT_NUM_SHADOW_MAP_OUTER_UPDATE_ITERATIONS = 75
export const DEFAULT_NUM_SHADOW_MAP_INNER_UPDATE_ITERATIONS = 4
export const DEFAULT_NUM_SHADOW_MAP_OUTER_SMOOTH_ITERATIONS = 0
export const DEFAULT_NUM_SHADOW_MAP_INNER_SMOOTH_ITERATIONS = 1
export const DEFAULT_SHADOW_MAP_RESOLUTION = 1024

export type DisplayMode = "configurator" | "editor"

const defaultGrid = {
    type: "Grid",
    id: "grid",
    size: 1000,
    divisions: 10,
    color1: [0.26, 0.26, 0.26],
    color2: [0.73, 0.73, 0.73],
    transform: Matrix4.identity(),
} as SceneNodes.Grid

const defaultEnvironment = {
    id: "defaultEnvironment",
    type: "Environment",
    envData: {
        type: "url",
        url: "assets/images/template-editor-envmap.exr",
        originalFileExtension: "exr",
    },
    intensity: 1,
    rotation: new Vector3(0, 0, 0),
    mirror: false,
    priority: 9999,
} as SceneNodes.Environment

@Injectable()
export class ThreeSceneManagerService implements OnDestroy {
    $ambientLight = signal(true)
    $showGrid = signal(true)
    $displayMode = signal<DisplayMode>("editor")
    $showProgressiveLightMapDebug = signal(false)
    $showUnselectedCurves = signal(false)
    showUnselectedCurves$ = toObservable(this.$showUnselectedCurves)
    $orbitIsDragging = signal(false)

    readonly sceneManagerService = inject(SceneManagerService)
    readonly materialManagerService = inject(ThreeMaterialManagerService)
    private readonly destroyRef = inject(DestroyRef)

    protected threeFactory: {
        [key in SceneNodes.SceneNode["type"]]?: {
            new (threeSceneManagerService: ThreeSceneManagerService, onAsyncUpdate: () => void): ThreeObject
        }
    } = {
        Grid: ThreeGrid,
        Mesh: ThreeMesh,
        MeshCurveControl: ThreeMeshCurveControl,
        WireframeMesh: ThreeWireframeMesh,
        Environment: ThreeEnvironment,
        AreaLight: ThreeAreaLight,
        Camera: ThreeCamera,
        Rectangle: ThreeRectangle,
        Point: ThreePoint,
        Annotation: ThreeAnnotation,
        LightPortal: ThreeLightPortal,
        PreloadMaterial: ThreePreloadMaterial,
        Seam: ThreeSeam,
        DimensionGuides: ThreeDimensionGuides,
    }

    private objectCache = new Map<ObjectId, ThreeObject>()
    private baseScene = new THREE.Scene()

    getObjectsFromSceneNodes(sceneNode: SceneNodePart[]): ThreeObjectPart[] {
        const allThreeObjects = Array.from(this.objectCache.values())

        return sceneNode
            .map((sceneNodePart) => {
                return allThreeObjects
                    .filter((threeObject) => {
                        const objectSceneNode = threeObject.getSceneNode()
                        return sceneNodePart.sceneNode === objectSceneNode
                    })
                    .map((threeObject) => ({
                        threeObject,
                        part: sceneNodePart.part,
                    }))
            })
            .flat()
    }

    $selectedObjects = computed(() => this.getObjectsFromSceneNodes(this.sceneManagerService.$selectedSceneNodes()))
    $hoveredObjects = computed(() => this.getObjectsFromSceneNodes(this.sceneManagerService.$hoveredSceneNodes()))
    private renderer: WebGLRenderer | undefined

    private requestedRedraw = new Subject<void>()
    requestedRedraw$ = this.requestedRedraw.asObservable()

    requestedResize = new Subject<void>()
    requestedResize$ = this.requestedResize.asObservable()

    $cursorPosition = signal<Vector3 | undefined>(undefined)
    private $cursorObject = computed(() => {
        const cursorPosition = this.$cursorPosition()
        if (!cursorPosition) return undefined

        const transform = new Matrix4()
        transform.setTranslation(cursorPosition)
        return {id: "cursorObject", type: "Point", size: 20, transform} as SceneNodes.Point
    })

    private shadowMapMeshes = new Set<THREE.Mesh<THREE.BufferGeometry, THREE.Material>>()
    private shadowMapMeshesChanged = new Subject<void>()

    private $sceneOptions = computed(() => this.sceneManagerService.$scene().find(SceneNodes.SceneOptions.is))
    private $shadowCatcherFalloff = computed(() => this.$sceneOptions()?.shadowCatcherFalloff, {
        equal: (a, b) => (a === undefined ? a === b : !objectDifferent(a, b, undefined)),
    })
    private $environmentMapMode = computed(() => this.$sceneOptions()?.environmentMapMode)
    private $materialModifier = computed(() => {
        const shadowCatcherFalloff = this.$shadowCatcherFalloff()
        const environmentMapMode = this.$environmentMapMode()
        const reviewMode = this.sceneManagerService.$reviewMode()

        return (material: THREE.Material) => {
            if (material instanceof ThreeShadowCatcher) {
                if (shadowCatcherFalloff) material.setFilterParameters({...shadowCatcherFalloff, bias: 0.0})
                else material.resetFilterParameters()
            }

            const specularOnlyMaterialModifier = (material: THREE.Material, remove: boolean) => {
                if (!(material instanceof THREENodes.MeshStandardNodeMaterial)) return false
                if (material.roughnessNode) {
                    if (remove) {
                        //TODO THREE UPGRADE
                        //@ts-ignore
                        material.envMapIntensityNode = null
                    } else {
                        const zero = threeValueNode(0)
                        const one = threeValueNode(1)

                        //TODO THREE UPGRADE
                        //@ts-ignore
                        material.envMapIntensityNode = THREENodes.pow(
                            THREENodes.sub(one, THREENodes.clamp(material.roughnessNode, zero, one)),
                            threeValueNode(4),
                        )
                    }

                    return true
                }

                return false
            }

            if (reviewMode === "wireframe") {
                material.polygonOffset = true
                material.polygonOffsetFactor = 5
            } else {
                material.polygonOffset = false
                material.polygonOffsetFactor = 0
            }

            if (specularOnlyMaterialModifier(material, environmentMapMode !== "specularOnly")) material.needsUpdate = true
        }
    })
    private $renderLights = computed(() => this.$sceneOptions()?.enableRealtimeLights !== false)
    private $realtimeShadowMapOptions = computed(() => this.$sceneOptions()?.realtimeShadowMapOptions)
    private $shadowMapResolution = computed(() => this.$realtimeShadowMapOptions()?.resolution ?? DEFAULT_SHADOW_MAP_RESOLUTION)
    private $shadowMapOuterUpdateIterations = computed(
        () => this.$realtimeShadowMapOptions()?.outerUpdateIterations ?? DEFAULT_NUM_SHADOW_MAP_OUTER_UPDATE_ITERATIONS,
    )
    private $shadowMapInnerUpdateIterations = computed(
        () => this.$realtimeShadowMapOptions()?.innerUpdateIterations ?? DEFAULT_NUM_SHADOW_MAP_INNER_UPDATE_ITERATIONS,
    )
    private $shadowMapSmoothOuterIterations = computed(
        () => this.$realtimeShadowMapOptions()?.outerSmoothIterations ?? DEFAULT_NUM_SHADOW_MAP_OUTER_SMOOTH_ITERATIONS,
    )
    private $shadowMapSmoothInnerIterations = computed(
        () => this.$realtimeShadowMapOptions()?.innerSmoothIterations ?? DEFAULT_NUM_SHADOW_MAP_INNER_SMOOTH_ITERATIONS,
    )

    private $staticDisplayList = computed<SceneNodes.SceneNode[]>(() => [
        ...(this.$showGrid() ? [defaultGrid] : []),
        ...(() => {
            const cursorObject = this.$cursorObject()
            return cursorObject ? [cursorObject] : []
        })(),
    ])
    private $renderScene = computed(() => {
        const getScene = () => {
            const scene = this.sceneManagerService.$scene()
            const reviewMode = this.sceneManagerService.$reviewMode()
            const channel = reviewMode === "wireframe" ? this.sceneManagerService.$reviewFocus() : this.sceneManagerService.$reviewedUvChannel()

            const sceneAdaptedForLights =
                !this.$renderLights() || reviewMode !== undefined ? scene.map((x) => (SceneNodes.AreaLight.is(x) ? {...x, on: false} : x)) : scene
            const sceneAdaptedForWireframes =
                reviewMode !== undefined
                    ? [
                          ...sceneAdaptedForLights,
                          ...sceneAdaptedForLights.filter(SceneNodes.Mesh.is).map(({completeMeshData, transform, id, topLevelObjectId}) => {
                              const ret: SceneNodes.WireframeMesh = {
                                  type: "WireframeMesh",
                                  completeMeshData,
                                  transform,
                                  id: id + "_wireframe",
                                  topLevelObjectId: topLevelObjectId,
                                  channel,
                              }
                              return ret
                          }),
                      ]
                    : sceneAdaptedForLights

            return sceneAdaptedForWireframes
        }

        const scene = [...getScene(), ...this.$staticDisplayList()]

        const hasIllumination = () => scene.some((x) => (SceneNodes.AreaLight.is(x) && x.on) || SceneNodes.Environment.is(x))

        const envMapNodes = scene.filter(SceneNodes.Environment.is)
        const otherNodes = scene.filter((item) => !SceneNodes.Environment.is(item))

        const selectedEnvironment =
            envMapNodes.reduce<SceneNodes.Environment | undefined>((acc, item) => {
                if (!acc) return item
                if (item.priority <= acc.priority) return item
                return acc
            }, undefined) ?? (this.$ambientLight() && !hasIllumination() ? defaultEnvironment : undefined)

        const renderScene = selectedEnvironment ? [selectedEnvironment, ...otherNodes] : otherNodes
        return renderScene
    })
    private $managedScene = computed(() => {
        const scene = new Map<string, {sceneNode: SceneNodes.SceneNode; visible: boolean}>()
        this.$renderScene().forEach((sceneNode) => {
            if (scene.has(sceneNode.id)) return
            scene.set(sceneNode.id, {sceneNode, visible: true})
        })

        const visibleSceneItems = [...scene.values()]
        visibleSceneItems.forEach(({sceneNode}) => {
            if (SceneNodes.MeshCurveControl.is(sceneNode)) {
                const {mesh} = sceneNode
                if (scene.has(mesh.id)) return
                scene.set(mesh.id, {sceneNode: mesh, visible: false})
            } else if (SceneNodes.Seam.is(sceneNode)) {
                sceneNode.item.forEach((item) => {
                    if (scene.has(item.id)) return
                    scene.set(item.id, {sceneNode: item, visible: false})
                })
            }
        })

        const retVal = [...scene.values()]
        retVal.sort(({sceneNode: a}, {sceneNode: b}) => {
            if (SceneNodes.Mesh.is(a) && !SceneNodes.Mesh.is(b)) return -1
            else if (!SceneNodes.Mesh.is(a) && SceneNodes.Mesh.is(b)) return 1
            else return 0
        })

        return retVal
    })
    private $hasAreaLights = computed(() => this.$renderScene().some((x) => SceneNodes.AreaLight.is(x) && x.on))
    $hasIllumination = computed(() => this.$hasAreaLights() || this.sceneManagerService.$scene().some((x) => SceneNodes.Environment.is(x)))
    private $hasMeshes = computed(() => this.$renderScene().some(SceneNodes.Mesh.is))

    private $initialTemplateLoadCompleted = signal(false)

    private $requiresProgressiveLightMap = computed(
        () => this.$hasAreaLights() && this.$hasMeshes() && this.$sceneOptions()?.enableRealtimeShadows !== false && this.$initialTemplateLoadCompleted(),
    )
    private progressiveLightMap: ThreeProgressiveUVShadowMap | undefined
    private progressiveLightMapUpdate = new Subject<void>()

    private threeObjectUpdated = new Subject<{threeObject: ThreeObject; asyncUpdate: boolean}>()
    threeObjectUpdated$ = this.threeObjectUpdated.asObservable()

    constructor() {
        this.sceneManagerService.templateSwapped$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap(() => {
                    this.$initialTemplateLoadCompleted.set(false)
                    this.sceneManagerService.compileTemplate()
                }),
                switchMap(() =>
                    this.sceneManagerService.criticalTasks$.pipe(
                        filter((criticalTask) => criticalTask.length === 0),
                        take(1),
                        delay(SHADOW_MAP_COMPUTATION_DELAY),
                    ),
                ),
            )
            .subscribe(() => {
                this.$initialTemplateLoadCompleted.set(true)
            })

        this.materialManagerService.requestedRedraw$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.requestRedraw())

        effect(() => {
            const selectedSceneNodes = this.sceneManagerService
                .$selectedSceneNodes()
                .map((sceneNodePart) => sceneNodePart.sceneNode)
                .filter((x) => x.id === x.topLevelObjectId)

            this.objectCache.forEach((threeObject) => {
                const objectSceneNode = threeObject.getSceneNode()
                threeObject.setSelected(selectedSceneNodes.some((selectedSceneNode) => selectedSceneNode.id === objectSceneNode.id))
            })
            this.requestRedraw()
        })

        effect(() => {
            const displayMode = this.$displayMode()
            this.objectCache.forEach((threeObject) => {
                threeObject.onDisplayModeChange(displayMode)
            })
            this.requestRedraw()
        })

        effect(() => {
            this.updateScene()
        })

        effect(() => {
            const showProgressiveLightMapDebug = untracked(this.$showProgressiveLightMapDebug)

            if (this.$requiresProgressiveLightMap()) {
                if (!this.progressiveLightMap) {
                    this.progressiveLightMap = new ThreeProgressiveUVShadowMap(this.getRenderer(), this.$shadowMapResolution())

                    if (showProgressiveLightMapDebug) this.baseScene.add(this.progressiveLightMap.getDebugObject())
                    this.progressiveLightMap.attachedUVShadowMap$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((mesh) => {
                        if (!this.progressiveLightMap) throw new Error("Progressive light map not set")
                        const {material} = mesh

                        const newMaterial = this.materialManagerService.acquireVariation(
                            material,
                            (material) => {
                                //@ts-ignore
                                return material.uvShadowMap !== null
                            },
                            (newMaterial) => {},
                        )
                        //@ts-ignore
                        newMaterial.uvShadowMap = this.progressiveLightMap.getUVShadowMap()
                        this.materialManagerService.releaseMaterial(material)
                        mesh.material = newMaterial
                    })
                    this.progressiveLightMap.detachedUVShadowMap$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((mesh) => {
                        const {material} = mesh

                        const newMaterial = this.materialManagerService.acquireVariation(
                            material,
                            (material) => {
                                //@ts-ignore
                                return material.uvShadowMap === null
                            },
                            (newMaterial) => {
                                //@ts-ignore
                                newMaterial.uvShadowMap = null
                            },
                        )
                        this.materialManagerService.releaseMaterial(material)
                        mesh.material = newMaterial
                    })

                    untracked(() => this.shadowMapMeshesChanged.next())
                } else {
                    const resolution = this.$shadowMapResolution()
                    if (this.progressiveLightMap.getResolution() !== resolution) {
                        this.progressiveLightMap.setResolution(resolution)
                        untracked(() => this.shadowMapMeshesChanged.next())
                    }
                }
            } else {
                if (this.progressiveLightMap) {
                    if (showProgressiveLightMapDebug) this.baseScene.remove(this.progressiveLightMap.getDebugObject())
                    this.progressiveLightMap.dispose()
                    this.progressiveLightMap = undefined
                    this.requestRedraw()
                }
            }
        })

        effect(() => {
            this.$shadowMapOuterUpdateIterations()
            this.$shadowMapInnerUpdateIterations()
            this.$shadowMapSmoothOuterIterations()
            this.$shadowMapSmoothInnerIterations()
            this.requestShadowMapUpdate()
        })

        effect(() => {
            const showProgressiveLightMapDebug = this.$showProgressiveLightMapDebug()
            if (!this.progressiveLightMap) return

            if (showProgressiveLightMapDebug) this.baseScene.add(this.progressiveLightMap.getDebugObject())
            else this.baseScene.remove(this.progressiveLightMap.getDebugObject())
            this.requestRedraw()
        })

        effect(() => {
            this.materialManagerService.materialModifier = this.$materialModifier()
            this.materialManagerService.updateMaterials(this.materialManagerService.materialModifier)
            this.requestRedraw()
        })

        this.shadowMapMeshesChanged
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                filter(() => this.progressiveLightMap !== undefined),
                switchMap(() => timer(this.$displayMode() === "configurator" ? 10 : 1000).pipe(switchMap(() => from(this.sync(false))))),
            )
            .subscribe(() => this.shadowMapMeshesSettled())

        const shadowUpdate = async (outerIteration: number) => {
            return new Promise<void>((resolve) => {
                if (!this.progressiveLightMap) resolve()

                requestAnimationFrame(() => {
                    if (this.progressiveLightMap) {
                        //if (iteration === 0) this.progressiveLightMap.reset()
                        const shadowMapComputeInnerIterations = this.$shadowMapInnerUpdateIterations()
                        const iteration = outerIteration * shadowMapComputeInnerIterations
                        this.progressiveLightMap.compute(this.baseScene, iteration, shadowMapComputeInnerIterations)

                        this.requestRedraw()
                    }
                    resolve()
                })
            })
        }

        const shadowSmooth = async (outerIteration: number) => {
            return new Promise<void>((resolve) => {
                if (!this.progressiveLightMap) resolve()

                requestAnimationFrame(() => {
                    if (this.progressiveLightMap) {
                        this.progressiveLightMap.smoothResult(this.$shadowMapSmoothInnerIterations())

                        this.requestRedraw()
                    }
                    resolve()
                })
            })
        }

        const pauseWhenOrbitIsDragging = toObservable(this.$orbitIsDragging).pipe(filter((isDragging) => !isDragging))

        //If for 1000ms there are no other progressive light map update events, start accumulating shadow map updates
        this.progressiveLightMapUpdate
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap(() =>
                    timer(this.$displayMode() === "configurator" ? 10 : 1000).pipe(
                        switchMap(() =>
                            range(this.$shadowMapOuterUpdateIterations()).pipe(
                                concatMap((iteration) =>
                                    defer(() =>
                                        of(iteration).pipe(
                                            delayWhen(() => (this.$orbitIsDragging() ? pauseWhenOrbitIsDragging : of(undefined))),
                                            switchMap(() => from(shadowUpdate(iteration))),
                                        ),
                                    ),
                                ),
                            ),
                        ),
                    ),
                ),
                switchMap(() =>
                    timer(this.$displayMode() === "configurator" ? 10 : 1000).pipe(
                        switchMap(() =>
                            range(this.$shadowMapSmoothOuterIterations()).pipe(
                                concatMap((iteration) =>
                                    defer(() =>
                                        of(iteration).pipe(
                                            delayWhen(() => (this.$orbitIsDragging() ? pauseWhenOrbitIsDragging : of(undefined))),
                                            switchMap(() => from(shadowSmooth(iteration))),
                                        ),
                                    ),
                                ),
                            ),
                        ),
                    ),
                ),
            )
            .subscribe()
    }

    init(canvas: HTMLCanvasElement) {
        if (this.renderer) throw new Error("Renderer already initialized")

        this.renderer = new WebGLRenderer({
            canvas,
            preserveDrawingBuffer: true,
            powerPreference: "high-performance",
            antialias: false,
            alpha: true,
        })

        const pixelRatio = window.devicePixelRatio
        this.renderer.setPixelRatio(pixelRatio)
        this.renderer.setClearColor(0x000000, 0)

        this.materialManagerService.$threeRenderer.set(this.renderer)
    }

    resizeRenderer(width: number, height: number) {
        if (!this.renderer) throw new Error("Renderer not set, forgot to call init()?")
        this.renderer.setSize(width, height)
        this.requestedResize.next()
    }

    private updateScene() {
        const scene = this.$managedScene()

        const toDelete = new Set<ObjectId>()
        for (const [id] of this.objectCache) toDelete.add(id)

        const getThreeObject = (sceneNode: SceneNodes.SceneNode) => {
            const cachedThreeObject = this.objectCache.get(sceneNode.id)
            if (cachedThreeObject) return cachedThreeObject

            const Factory = this.threeFactory[sceneNode.type]
            if (!Factory) {
                return undefined
            }

            const threeSceneManager = this
            const threeObject = new Factory(this, function (this: ThreeObject) {
                threeSceneManager.threeObjectUpdated.next({threeObject: this, asyncUpdate: true})
                const scene = threeSceneManager.$managedScene()
                const managedSceneNode = scene.find((x) => x.sceneNode.id === this.getSceneNode().id)
                if (managedSceneNode?.visible) threeSceneManager.requestRedraw()
            })

            this.objectCache.set(sceneNode.id, threeObject)
            return threeObject
        }

        let needsUpdate = false
        let needsShadowMapUpdate = false
        for (const {sceneNode, visible} of scene) {
            const threeObject = getThreeObject(sceneNode)
            if (threeObject) {
                if (threeObject.update(sceneNode)) {
                    this.threeObjectUpdated.next({threeObject: threeObject, asyncUpdate: false})

                    if (visible) {
                        if (threeObject instanceof ThreeMesh || threeObject instanceof ThreeAreaLight || threeObject instanceof ThreeSeam)
                            needsShadowMapUpdate = true
                        needsUpdate = true
                    }
                }

                const threeRenderObject = threeObject.getRenderObject()
                if (visible) {
                    if (!threeRenderObject.parent) this.baseScene.add(threeRenderObject)
                } else {
                    if (threeRenderObject.parent) this.baseScene.remove(threeRenderObject)
                }
                toDelete.delete(sceneNode.id)
            }
        }

        for (const id of toDelete) {
            const threeObject = this.objectCache.get(id)
            if (threeObject) {
                const threeRenderObject = threeObject.getRenderObject()
                if (threeRenderObject.parent) {
                    this.baseScene.remove(threeRenderObject)
                    if (threeObject instanceof ThreeMesh || threeObject instanceof ThreeAreaLight || threeObject instanceof ThreeSeam)
                        needsShadowMapUpdate = true
                    needsUpdate = true
                }
                threeObject.dispose(true)
                this.objectCache.delete(id)
            }
        }

        if (needsUpdate) this.requestRedraw()
        if (needsShadowMapUpdate) untracked(() => this.progressiveLightMapUpdate.next())
    }

    getRenderer() {
        if (!this.renderer) throw new Error("Renderer not set")
        return this.renderer
    }

    modifyBaseScene(modifier: (scene: THREE.Scene) => void) {
        modifier(this.baseScene)
    }

    getSceneReference() {
        return this.baseScene
    }

    getManagedThreeReferences() {
        return this.$managedScene()
            .map((x) => {
                const threeObject = this.objectCache.get(x.sceneNode.id)
                if (!threeObject) return undefined
                return threeObject.getRenderObject()
            })
            .filter((x): x is THREE.Object3D => x !== undefined)
    }

    ngOnDestroy() {
        if (this.progressiveLightMap) {
            this.progressiveLightMap.dispose()
            this.progressiveLightMap = undefined
        }

        for (const threeObject of this.objectCache.values()) threeObject.dispose(true)
        this.objectCache.clear()

        if (this.renderer) this.renderer.dispose()
        this.materialManagerService.$threeRenderer.set(undefined)
    }

    async sync(waitForOptionalTasks: boolean): Promise<void> {
        await this.sceneManagerService.sync(waitForOptionalTasks)
        this.updateScene()
        while (this.sceneManagerService.requiresSync(waitForOptionalTasks)) {
            await this.sceneManagerService.sync(waitForOptionalTasks)
            this.updateScene()
        }
    }

    requestRedraw() {
        this.requestedRedraw.next()
    }

    addShadowMapMesh(mesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>) {
        if (!this.shadowMapMeshes.has(mesh)) {
            this.shadowMapMeshes.add(mesh)
            this.requestShadowMapUpdate()
        }
    }

    removeShadowMapMesh(mesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>, willBeDisposed: boolean) {
        if (this.shadowMapMeshes.has(mesh)) {
            this.shadowMapMeshes.delete(mesh)
            if (willBeDisposed && this.progressiveLightMap) this.progressiveLightMap.onDisposingMesh(mesh)
            this.requestShadowMapUpdate()
        }
    }

    requestShadowMapUpdate() {
        this.shadowMapMeshesChanged.next()
    }

    getThreeObject(id: ObjectId) {
        return this.objectCache.get(id)
    }

    exchangedShadowMapMeshTexture(mesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>) {
        if (this.progressiveLightMap && this.progressiveLightMap.has(mesh)) {
            const {material} = mesh

            const newMaterial = this.materialManagerService.acquireVariation(
                material,
                (material) => {
                    //@ts-ignore
                    return material.uvShadowMap !== null
                },
                (newMaterial) => {},
            )
            //@ts-ignore
            newMaterial.uvShadowMap = this.progressiveLightMap.getUVShadowMap()
            this.materialManagerService.releaseMaterial(material)
            mesh.material = newMaterial
        }
    }

    protected shadowMapMeshesSettled() {
        if (this.progressiveLightMap) {
            this.progressiveLightMap.attachToMeshes(this.shadowMapMeshes)
            this.progressiveLightMapUpdate.next()
        }
    }
}
