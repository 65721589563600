import {DeclareObjectNode, ObjectNode, TemplateObjectNode} from "#template-nodes/declare-object-node"
import {GlobalRenderConstants, SceneNodes} from "#template-nodes/interfaces/scene-object"
import {NamedNodeParameters, namedNodeParameters} from "#template-nodes/nodes/named-node"
import {colorValue, positionValue} from "#template-nodes/types"
import {cameraLookAt} from "#template-nodes/utils/camera-utils"
import {visitNone, VisitorNodeVersion} from "@cm/graph/declare-visitor-node"
import {versionChain} from "@cm/graph/node-graph"
import {registerNode} from "@cm/graph/register-node"
import {Vector3} from "@cm/math"
import {z} from "zod"

const areaLightParameters = namedNodeParameters.merge(
    z.object({
        intensity: z.number(),
        width: z.number(),
        height: z.number(),
        color: colorValue,
        target: positionValue,
        targeted: z.boolean(),
        on: z.boolean(),
        directionality: z.number(),
        visibleDirectly: z.boolean(),
        visibleInReflections: z.boolean(),
        visibleInRefractions: z.boolean(),
        transparent: z.boolean(),
        lightType: z.enum(["Corona", "Blender"]),
    }),
)
export type AreaLightParameters = z.infer<typeof areaLightParameters>

type V0 = ObjectNode &
    NamedNodeParameters & {
        intensity: number
        width: number
        height: number
        color: [number, number, number]
        target: [number, number, number]
        on: boolean
        directionality: number
        visibleDirectly: boolean
        visibleInReflections: boolean
        visibleInRefractions: boolean
        transparent: boolean
    }

type V1 = V0 & {lightType: "Corona" | "Blender"}
type V2 = V1 & {targeted: boolean}

const v0: VisitorNodeVersion<V0, V1> = {
    toNextVersion: (parameters) => {
        return {...parameters, lightType: "Corona"}
    },
}

const v1: VisitorNodeVersion<V1, V2> = {
    toNextVersion: (parameters) => {
        return {...parameters, targeted: true}
    },
}

@registerNode
export class AreaLight extends DeclareObjectNode(
    {parameters: areaLightParameters},
    {
        onVisited: {
            onCompile: function (this: AreaLightFwd, {context, parameters}) {
                const {evaluator} = context
                const {
                    width,
                    height,
                    color,
                    target,
                    targeted,
                    on,
                    intensity,
                    directionality,
                    visibleDirectly,
                    visibleInReflections,
                    visibleInRefractions,
                    transparent,
                    lightType,
                } = parameters

                const scope = evaluator.getScope(this)

                //Our platform has light intensity values x, they are transformed as
                //x * lightIntensityScale * width * height * PI
                const widthM = width / 100
                const heightM = height / 100
                const mappedIntensity = lightType === "Corona" ? intensity : intensity / Math.PI / GlobalRenderConstants.lightIntensityScale / widthM / heightM

                this.setupObject(scope, context, "AreaLight", undefined, undefined, ({transform: objectTransform, ...objectProps}) => {
                    const transform = targeted
                        ? scope.pureLambda(
                              scope.tuple(objectTransform, target),
                              ([transform, target]) => {
                                  return cameraLookAt(transform.getTranslation(), Vector3.fromArray(target))
                              },
                              "transform",
                          )
                        : objectTransform

                    return scope.struct<SceneNodes.AreaLight>("AreaLight", {
                        type: "AreaLight",
                        ...objectProps,
                        transform,
                        width,
                        height,
                        color,
                        on,
                        intensity: mappedIntensity,
                        directionality,
                        visibleDirectly,
                        visibleInReflections,
                        visibleInRefractions,
                        target: Vector3.fromArray(target),
                        targeted,
                        transparent: transparent,
                    })
                })

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "AreaLight", versionChain: versionChain([v0, v1])},
) {}

export type AreaLightFwd = TemplateObjectNode<AreaLightParameters>
