import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MaterialExplorerItemFragment = { __typename: 'Material', legacyId: number, latestRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', legacyId: number, id: string }, destination: { __typename: 'MaterialNode', legacyId: number, id: string } }> } | null, textureGroup?: { __typename: 'TextureGroup', legacyId: number, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, textures: Array<{ __typename: 'Texture', id: string, type: Types.TextureType, latestRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: Types.DataObjectState } } | null }> }> } | null };

export type MaterialExplorerInfoQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID']['input'];
}>;


export type MaterialExplorerInfoQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, matExplorerMaterial?: { __typename: 'Material', id: string } | null, matExplorerTemplate?: { __typename: 'Template', id: string } | null } };

export type MaterialExplorerItemQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MaterialExplorerItemQuery = { __typename: 'Query', material: { __typename: 'Material', legacyId: number, latestRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', legacyId: number, id: string }, destination: { __typename: 'MaterialNode', legacyId: number, id: string } }> } | null, textureGroup?: { __typename: 'TextureGroup', legacyId: number, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, textures: Array<{ __typename: 'Texture', id: string, type: Types.TextureType, latestRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: Types.DataObjectState } } | null }> }> } | null } };

export type MaterialExplorerDataQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID']['input'];
}>;


export type MaterialExplorerDataQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, legacyId: number, matExplorerMaterial?: { __typename: 'Material', id: string } | null, matExplorerTemplate?: { __typename: 'Template', id: string, legacyId: number } | null } };

export type MaterialExplorerMaterialNodeQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MaterialExplorerMaterialNodeQuery = { __typename: 'Query', materialNode: { __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: Types.DataObjectState } } | null } };

export type MaterialExplorerTextureFragment = { __typename: 'Texture', id: string, type: Types.TextureType, latestRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: Types.DataObjectState } } | null };

export type MaterialExplorerTextureRevisionFragment = { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: Types.DataObjectState } };

export type MaterialExplorerTextureSetRevisionFragment = { __typename: 'TextureSetRevision', id: string, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number, state: Types.DataObjectState } }> };

export type MaterialExplorerTextureSetFragment = { __typename: 'TextureSet', id: string, legacyId: number, textures: Array<{ __typename: 'Texture', id: string, type: Types.TextureType, latestRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: Types.DataObjectState } } | null }> };

export type MaterialExplorerTextureGroupFragment = { __typename: 'TextureGroup', legacyId: number, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, textures: Array<{ __typename: 'Texture', id: string, type: Types.TextureType, latestRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: Types.DataObjectState } } | null }> }> };

export type MaterialExplorerMaterialNodeFragment = { __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: Types.DataObjectState } } | null };

export type MaterialExplorerMaterialRevisionFragment = { __typename: 'MaterialRevision', id: string, legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', legacyId: number, id: string }, destination: { __typename: 'MaterialNode', legacyId: number, id: string } }> };

export type MaterialExplorerUpdateMaterialNodeMutationVariables = Types.Exact<{
  input: Types.MutationUpdateMaterialNodeInput;
}>;


export type MaterialExplorerUpdateMaterialNodeMutation = { __typename: 'Mutation', updateMaterialNode: { __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: Types.DataObjectState } } | null } };

export type MaterialExplorerCreateTextureRevisionMutationVariables = Types.Exact<{
  input: Types.MutationCreateTextureRevisionInput;
}>;


export type MaterialExplorerCreateTextureRevisionMutation = { __typename: 'Mutation', createTextureRevision: { __typename: 'TextureRevision', id: string, legacyId: number, dataObject: { __typename: 'DataObject', state: Types.DataObjectState } } };

export type MaterialExplorerDeleteTextureRevisionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MaterialExplorerDeleteTextureRevisionMutation = { __typename: 'Mutation', deleteTextureRevision?: any | null };

export type MaterialExplorerCreateTextureSetRevisionMutationVariables = Types.Exact<{
  input: Types.MutationCreateTextureSetRevisionInput;
}>;


export type MaterialExplorerCreateTextureSetRevisionMutation = { __typename: 'Mutation', createTextureSetRevision: { __typename: 'TextureSetRevision', id: string, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number, state: Types.DataObjectState } }> } };

export type MaterialExplorerDeleteTextureSetRevisionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MaterialExplorerDeleteTextureSetRevisionMutation = { __typename: 'Mutation', deleteTextureSetRevision: { __typename: 'TextureSetRevision', id: string } };

export const MaterialExplorerMaterialRevisionFragmentDoc = gql`
    fragment MaterialExplorerMaterialRevision on MaterialRevision {
  id
  legacyId
  nodes {
    id
  }
  connections {
    sourceParameter
    destinationParameter
    source {
      legacyId
      id
    }
    destination {
      legacyId
      id
    }
  }
}
    `;
export const MaterialExplorerTextureRevisionFragmentDoc = gql`
    fragment MaterialExplorerTextureRevision on TextureRevision {
  id
  legacyId
  dataObject {
    state
  }
}
    `;
export const MaterialExplorerTextureFragmentDoc = gql`
    fragment MaterialExplorerTexture on Texture {
  id
  type
  latestRevision {
    ...MaterialExplorerTextureRevision
  }
}
    `;
export const MaterialExplorerTextureSetFragmentDoc = gql`
    fragment MaterialExplorerTextureSet on TextureSet {
  id
  legacyId
  textures {
    ...MaterialExplorerTexture
  }
}
    `;
export const MaterialExplorerTextureGroupFragmentDoc = gql`
    fragment MaterialExplorerTextureGroup on TextureGroup {
  legacyId
  textureSets {
    ...MaterialExplorerTextureSet
  }
}
    `;
export const MaterialExplorerItemFragmentDoc = gql`
    fragment MaterialExplorerItem on Material {
  legacyId
  __typename
  latestRevision {
    ...MaterialExplorerMaterialRevision
  }
  textureGroup {
    ...MaterialExplorerTextureGroup
  }
}
    `;
export const MaterialExplorerTextureSetRevisionFragmentDoc = gql`
    fragment MaterialExplorerTextureSetRevision on TextureSetRevision {
  id
  mapAssignments {
    textureType
    dataObject {
      legacyId
      state
    }
  }
}
    `;
export const MaterialExplorerMaterialNodeFragmentDoc = gql`
    fragment MaterialExplorerMaterialNode on MaterialNode {
  id
  legacyId
  __typename
  name
  parameters
  textureRevision {
    ...MaterialExplorerTextureRevision
  }
}
    `;
export const MaterialExplorerInfoDocument = gql`
    query materialExplorerInfo($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    matExplorerMaterial {
      id
    }
    matExplorerTemplate {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExplorerInfoGQL extends Apollo.Query<MaterialExplorerInfoQuery, MaterialExplorerInfoQueryVariables> {
    override document = MaterialExplorerInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialExplorerItemDocument = gql`
    query materialExplorerItem($id: ID!) {
  material(id: $id) {
    ...MaterialExplorerItem
  }
}
    ${MaterialExplorerItemFragmentDoc}
${MaterialExplorerMaterialRevisionFragmentDoc}
${MaterialExplorerTextureGroupFragmentDoc}
${MaterialExplorerTextureSetFragmentDoc}
${MaterialExplorerTextureFragmentDoc}
${MaterialExplorerTextureRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExplorerItemGQL extends Apollo.Query<MaterialExplorerItemQuery, MaterialExplorerItemQueryVariables> {
    override document = MaterialExplorerItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialExplorerDataDocument = gql`
    query materialExplorerData($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    legacyId
    matExplorerMaterial {
      id
    }
    matExplorerTemplate {
      id
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExplorerDataGQL extends Apollo.Query<MaterialExplorerDataQuery, MaterialExplorerDataQueryVariables> {
    override document = MaterialExplorerDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialExplorerMaterialNodeDocument = gql`
    query materialExplorerMaterialNode($id: ID!) {
  materialNode(id: $id) {
    ...MaterialExplorerMaterialNode
  }
}
    ${MaterialExplorerMaterialNodeFragmentDoc}
${MaterialExplorerTextureRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExplorerMaterialNodeGQL extends Apollo.Query<MaterialExplorerMaterialNodeQuery, MaterialExplorerMaterialNodeQueryVariables> {
    override document = MaterialExplorerMaterialNodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialExplorerUpdateMaterialNodeDocument = gql`
    mutation materialExplorerUpdateMaterialNode($input: MutationUpdateMaterialNodeInput!) {
  updateMaterialNode(input: $input) {
    ...MaterialExplorerMaterialNode
  }
}
    ${MaterialExplorerMaterialNodeFragmentDoc}
${MaterialExplorerTextureRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExplorerUpdateMaterialNodeGQL extends Apollo.Mutation<MaterialExplorerUpdateMaterialNodeMutation, MaterialExplorerUpdateMaterialNodeMutationVariables> {
    override document = MaterialExplorerUpdateMaterialNodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialExplorerCreateTextureRevisionDocument = gql`
    mutation materialExplorerCreateTextureRevision($input: MutationCreateTextureRevisionInput!) {
  createTextureRevision(input: $input) {
    ...MaterialExplorerTextureRevision
  }
}
    ${MaterialExplorerTextureRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExplorerCreateTextureRevisionGQL extends Apollo.Mutation<MaterialExplorerCreateTextureRevisionMutation, MaterialExplorerCreateTextureRevisionMutationVariables> {
    override document = MaterialExplorerCreateTextureRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialExplorerDeleteTextureRevisionDocument = gql`
    mutation materialExplorerDeleteTextureRevision($id: ID!) {
  deleteTextureRevision(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExplorerDeleteTextureRevisionGQL extends Apollo.Mutation<MaterialExplorerDeleteTextureRevisionMutation, MaterialExplorerDeleteTextureRevisionMutationVariables> {
    override document = MaterialExplorerDeleteTextureRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialExplorerCreateTextureSetRevisionDocument = gql`
    mutation materialExplorerCreateTextureSetRevision($input: MutationCreateTextureSetRevisionInput!) {
  createTextureSetRevision(input: $input) {
    ...MaterialExplorerTextureSetRevision
  }
}
    ${MaterialExplorerTextureSetRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExplorerCreateTextureSetRevisionGQL extends Apollo.Mutation<MaterialExplorerCreateTextureSetRevisionMutation, MaterialExplorerCreateTextureSetRevisionMutationVariables> {
    override document = MaterialExplorerCreateTextureSetRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialExplorerDeleteTextureSetRevisionDocument = gql`
    mutation materialExplorerDeleteTextureSetRevision($id: ID!) {
  deleteTextureSetRevision(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialExplorerDeleteTextureSetRevisionGQL extends Apollo.Mutation<MaterialExplorerDeleteTextureSetRevisionMutation, MaterialExplorerDeleteTextureSetRevisionMutationVariables> {
    override document = MaterialExplorerDeleteTextureSetRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }