import {DestroyRef, inject, Injectable} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {ContentTypeModel} from "@generated"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {TabStateService} from "@common/services/tab-state/tab-state.service"
import {ThumbnailAvailableGQL} from "@platform/services/thumbnails/thumbnails.generated"
import {intervalBackoff} from "backoff-rxjs"
import {filter, firstValueFrom, switchMap, take, takeUntil} from "rxjs"

@Injectable({
    providedIn: "root",
})
export class ThumbnailsService {
    readonly destroyRef = inject(DestroyRef)
    readonly refresh = inject(RefreshService)
    readonly tabState = inject(TabStateService)

    readonly thumbnailAvailableGql = inject(ThumbnailAvailableGQL)

    async waitUntilAvailable(dataObjectId: string, refreshOnAvailable: boolean = true) {
        const scheduleCheck = this.tabState.becomesActive$.pipe(
            switchMap(() =>
                intervalBackoff({
                    initialInterval: 1000,
                    backoffDelay: (index) => Math.pow(1.1, index) * 1000,
                }).pipe(takeUntil(this.tabState.becomesInactive$)),
            ),
        )
        return firstValueFrom(
            scheduleCheck.pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap(() => fetchThrowingErrors(this.thumbnailAvailableGql)({id: dataObjectId})),
                filter(({dataObject}) => !!dataObject?.thumbnailAvailable),
                take(1),
            ),
        ).then(() => {
            if (refreshOnAvailable) this.refresh.item({id: dataObjectId, __typename: ContentTypeModel.DataObject})
        })
    }
}
