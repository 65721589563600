import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type JobDetailsFragment = { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, claimedBy?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, priority: number, progress?: number | null, started?: string | null, state: Types.JobState, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, legacyId: number }>, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } };

export type JobDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type JobDetailsQuery = { __typename: 'Query', item: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, claimedBy?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, priority: number, progress?: number | null, started?: string | null, state: Types.JobState, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, legacyId: number }>, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type UpdateJobDetailsMutationVariables = Types.Exact<{
  input: Types.MutationUpdateJobInput;
}>;


export type UpdateJobDetailsMutation = { __typename: 'Mutation', item: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, claimedBy?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, priority: number, progress?: number | null, started?: string | null, state: Types.JobState, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, legacyId: number }>, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type JobDetailsDeleteJobMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type JobDetailsDeleteJobMutation = { __typename: 'Mutation', deleteJob?: any | null };

export type JobDetailsCancelMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type JobDetailsCancelMutation = { __typename: 'Mutation', cancelJob: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, claimedBy?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, priority: number, progress?: number | null, started?: string | null, state: Types.JobState, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, legacyId: number }>, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type JobDetailsRerunAllTasksInJobMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type JobDetailsRerunAllTasksInJobMutation = { __typename: 'Mutation', rerunAllTasksInJob: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, claimedBy?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, priority: number, progress?: number | null, started?: string | null, state: Types.JobState, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, legacyId: number }>, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type JobDetailsRestartMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type JobDetailsRestartMutation = { __typename: 'Mutation', restartJob: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, claimedBy?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, priority: number, progress?: number | null, started?: string | null, state: Types.JobState, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, legacyId: number }>, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type JobDetailsNudgeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type JobDetailsNudgeMutation = { __typename: 'Mutation', nudgeJob: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, claimedBy?: string | null, finished?: string | null, graph?: any | null, message?: string | null, name?: string | null, priority: number, progress?: number | null, started?: string | null, state: Types.JobState, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, legacyId: number }>, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export const JobDetailsFragmentDoc = gql`
    fragment JobDetails on Job {
  __typename
  id
  legacyId
  cancelled
  claimedBy
  finished
  graph
  message
  name
  priority
  progress
  started
  state
  createdBy {
    id
    legacyId
    name
  }
  dataObjectAssignments {
    id
    legacyId
  }
  organization {
    id
    legacyId
    name
  }
}
    `;
export const JobDetailsDocument = gql`
    query jobDetails($id: ID!) {
  item: job(id: $id) {
    ...JobDetails
  }
}
    ${JobDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobDetailsGQL extends Apollo.Query<JobDetailsQuery, JobDetailsQueryVariables> {
    override document = JobDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateJobDetailsDocument = gql`
    mutation updateJobDetails($input: MutationUpdateJobInput!) {
  item: updateJob(input: $input) {
    ...JobDetails
  }
}
    ${JobDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateJobDetailsGQL extends Apollo.Mutation<UpdateJobDetailsMutation, UpdateJobDetailsMutationVariables> {
    override document = UpdateJobDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobDetailsDeleteJobDocument = gql`
    mutation jobDetailsDeleteJob($id: ID!) {
  deleteJob(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class JobDetailsDeleteJobGQL extends Apollo.Mutation<JobDetailsDeleteJobMutation, JobDetailsDeleteJobMutationVariables> {
    override document = JobDetailsDeleteJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobDetailsCancelDocument = gql`
    mutation jobDetailsCancel($id: ID!) {
  cancelJob(id: $id) {
    ...JobDetails
  }
}
    ${JobDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobDetailsCancelGQL extends Apollo.Mutation<JobDetailsCancelMutation, JobDetailsCancelMutationVariables> {
    override document = JobDetailsCancelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobDetailsRerunAllTasksInJobDocument = gql`
    mutation jobDetailsRerunAllTasksInJob($id: ID!) {
  rerunAllTasksInJob(id: $id) {
    ...JobDetails
  }
}
    ${JobDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobDetailsRerunAllTasksInJobGQL extends Apollo.Mutation<JobDetailsRerunAllTasksInJobMutation, JobDetailsRerunAllTasksInJobMutationVariables> {
    override document = JobDetailsRerunAllTasksInJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobDetailsRestartDocument = gql`
    mutation jobDetailsRestart($id: ID!) {
  restartJob(id: $id) {
    ...JobDetails
  }
}
    ${JobDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobDetailsRestartGQL extends Apollo.Mutation<JobDetailsRestartMutation, JobDetailsRestartMutationVariables> {
    override document = JobDetailsRestartDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobDetailsNudgeDocument = gql`
    mutation jobDetailsNudge($id: ID!) {
  nudgeJob(id: $id) {
    ...JobDetails
  }
}
    ${JobDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobDetailsNudgeGQL extends Apollo.Mutation<JobDetailsNudgeMutation, JobDetailsNudgeMutationVariables> {
    override document = JobDetailsNudgeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }