<cm-add-entity-button
    title="New tag"
    [formTemplate]="addTagTemplate"
    [canConfirm]="canConfirmCreate"
    [confirm]="confirmCreate"
    [contentTypeModel]="ContentTypeModel.Tag"
></cm-add-entity-button>

<ng-template #addTagTemplate>
    <form class="cm-new-item-form">
        <mat-form-field appearance="fill">
            <input matInput placeholder="Name" [(ngModel)]="data.name" name="name" />
        </mat-form-field>
        <cm-organization-select
            [usedInAddEntityContext]="true"
            [updateOrganization]="updateOrganization.bind(this)"
            [organizationId]="$organizationId() ?? null"
            [showIfViewedByOwner]="false"
            [showLabel]="false"
        ></cm-organization-select>
        <mat-form-field appearance="fill">
            <mat-select [(value)]="data.type" placeholder="Type" [disabled]="!$can().create.tag()">
                @for (tagType of $tagTypes(); track tagType.state) {
                    <mat-option [value]="tagType.state">{{ tagType.label }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <cm-input-container>
            <ng-container cm-inline-label> Color:</ng-container>
            <ng-container cm-input>
                <cm-color-input [(hexValue)]="data.color"></cm-color-input>
            </ng-container>
        </cm-input-container>
        <cm-input-container>
            <ng-container cm-top-label> Description:</ng-container>
            <ng-container cm-input>
                <cm-native-input-text-area [initialValue]="data.description ?? ''" (valueChange)="data.description = $event" [minRows]="4">
                </cm-native-input-text-area>
            </ng-container>
        </cm-input-container>
    </form>
</ng-template>
