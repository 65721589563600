<div class="cm-title cm-user-list-title">Assign user</div>
<div class="cm-user-list">
    @for (userChoice of visibleUsers; track userChoice.id) {
        <div
            class="cm-user"
            (click)="userChoice.id === $user()?.id ? deleteAssignedUser() : assignUser(userChoice)"
            [class.cm-active-user]="userChoice.id == $user()?.id || !$user()"
            matTooltip="{{ userChoice.firstName }} {{ userChoice.lastName }}"
        >
            <cm-user-thumbnail [user]="userChoice"></cm-user-thumbnail>
        </div>
    }
</div>
<div class="cm-title cm-next-actor-title">Up next</div>
<mat-button-toggle-group class="cm-toggle-group" [ngModel]="nextActor" (change)="updateNextActor($event.value)" [hideSingleSelectionIndicator]="true">
    <mat-button-toggle matTooltip="Team 1" [value]="NextActor.Team1">T1</mat-button-toggle>
    <mat-button-toggle matTooltip="Team 2" [value]="NextActor.Team2">T2</mat-button-toggle>
    <mat-button-toggle matTooltip="Project manager" [value]="NextActor.ProjectManager">PM</mat-button-toggle>
    <mat-button-toggle matTooltip="Customer" [value]="NextActor.Customer">C</mat-button-toggle>
</mat-button-toggle-group>
