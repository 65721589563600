import {Component, Inject} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"

@Component({
    selector: "cm-rename-dialog",
    templateUrl: "./rename-dialog.component.html",
    styleUrls: ["./rename-dialog.component.scss"],
    imports: [MatInputModule, MatDialogContent, MatDialogTitle, MatDialogActions, MatButtonModule, FormsModule],
})
export class RenameDialogComponent {
    name: string
    caption: string
    allowUndefined: boolean

    constructor(
        public dialogRef: MatDialogRef<RenameDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {currentName: string; caption: string; allowUndefined?: boolean},
    ) {
        this.name = data.currentName
        this.caption = data.caption ?? "Rename"
        this.allowUndefined = data.allowUndefined ?? false
    }

    selectNameWithoutExtension(input: HTMLInputElement): void {
        const lastDotIndex: number = input.value.lastIndexOf(".")
        if (lastDotIndex > 0) {
            input.setSelectionRange(0, lastDotIndex)
        } else {
            input.setSelectionRange(0, input.value.length)
        }
    }

    cancelAction(): void {
        this.dialogRef.close(false)
    }

    confirmAction(): void {
        this.dialogRef.close(this.name)
    }
}
