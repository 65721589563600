<div [class.cm-square-container]="$squareFormat()">
    <div class="cm-card">
        <div class="cm-content">
            <a disabled="" class="cm-picture-container">
                <cm-data-object-thumbnail></cm-data-object-thumbnail>
            </a>
        </div>
        <div class="cm-details">
            <div class="cm-title-placeholder"></div>
            <div class="cm-subtitle-placeholder"></div>
        </div>
    </div>
</div>
