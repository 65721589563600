@if ($data().items.length === 0) {
    <cm-full-page-feedback text="No associated jobs"></cm-full-page-feedback>
} @else {
    <div class="cm-associated-jobs-table">
        <cm-table-cell class="cm-first-column" [header]="true"> ID </cm-table-cell>
        <cm-table-cell [centered]="true" [header]="true"> State </cm-table-cell>
        <cm-table-cell [header]="true"> Name </cm-table-cell>
        <cm-table-cell [centered]="true" [header]="true"> Message </cm-table-cell>
        <cm-table-cell [header]="true">
            <span class="cm-header--padded">Created</span>
        </cm-table-cell>
        @for (row of $data().items; track row.trackId) {
            <div class="cm-ruler"></div>
            <div class="cm-table-row" (click)="clickItem.emit(row.data ?? undefined)">
                <cm-table-cell [item]="row">
                    <span class="cm-id cm-first-column">{{ row.data?.legacyId }}</span>
                </cm-table-cell>
                <cm-table-cell [item]="row" [centered]="true">
                    <cm-job-thumbnail [job]="row.data"></cm-job-thumbnail>
                </cm-table-cell>
                <cm-table-cell [item]="row">
                    <cm-overflowable-text>
                        {{ row.data?.name ?? "-" }}
                    </cm-overflowable-text>
                </cm-table-cell>
                <cm-table-cell [item]="row" [centered]="true">
                    @if (row.data?.message) {
                        <i [tp]="row.data?.message ?? 'Test'" tpVariation="light" class="fa-solid fa-comment cm-icon--light"></i>
                    }
                </cm-table-cell>
                <cm-table-cell [item]="row">
                    <cm-entity-metadata [$data]="row.data"></cm-entity-metadata>
                </cm-table-cell>
            </div>
        }
    </div>
}
