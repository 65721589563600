import {Component, computed} from "@angular/core"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {SelectionPossibilities, ValueSlotComponent} from "../../value-slot/value-slot.component"
import {getLodTypeDescription, getTemplateNodeClassLabel, LodType as LodTypeNode, LodTypeType} from "@cm/template-nodes"

@Component({
    selector: "cm-lod-type-inspector",
    templateUrl: "./lod-type-inspector.component.html",
    styleUrl: "./lod-type-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent],
})
export class LodTypeInspectorComponent extends BaseInspectorComponent<LodTypeNode> {
    readonly $class = computed(() => getTemplateNodeClassLabel(this.$node()))

    lodTypePossibilites: SelectionPossibilities<LodTypeType> = [
        {value: "web", name: getLodTypeDescription("web")},
        {value: "ar", name: getLodTypeDescription("ar")},
        {value: "pathTraced", name: getLodTypeDescription("pathTraced")},
    ]
}
