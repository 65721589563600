import {Component, viewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {InvertInputs, InvertOutputs} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-invert-node",
    templateUrl: "./invert-node.component.html",
    styleUrls: ["./invert-node.component.scss"],
    imports: [NodeBaseComponent],
})
export class InvertNodeComponent {
    readonly $nodeBase = viewChild<MaterialNodeBase>("nodeBase")
    outputs = InvertOutputs
    inputs = InvertInputs
    typeInfo = InvertNodeType
}

export const InvertNodeType: MaterialNodeType<typeof InvertNodeComponent> = {
    id: "invert",
    label: "Invert",
    color: "#6e5eb2",
    name: "ShaderNodeInvert",
    inputs: [InvertInputs.color, InvertInputs.fac],
    outputs: [InvertOutputs.color],
    component: InvertNodeComponent,
}
