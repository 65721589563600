<div class="cm-arrow-button-container">
    <div class="cm-arrow-buttons">
        <button (click)="tickIncrement()" (mousedown)="startChange($event)" [class.cm-dragging]="$isChanging()">
            <i class="fa-solid fa-angle-up"></i>
        </button>
        <button (click)="tickDecrement()" (mousedown)="startChange($event)" [class.cm-dragging]="$isChanging()">
            <i class="fa-solid fa-angle-down"></i>
        </button>
    </div>
</div>
