import {ThreeSceneManagerService} from "@template-editor/services/three-scene-manager.service"
import {Subscription, combineLatest} from "rxjs"
import * as THREE from "three"
import {TemplateNodePart} from "../services/scene-manager.service"

export abstract class ThreeAnnotationBase extends THREE.Object3D {
    private elementCache = new Map<string, HTMLDivElement>()
    private subscription: Subscription | undefined

    constructor(
        protected threeSceneManagerService: ThreeSceneManagerService,
        protected templateNodePart: TemplateNodePart,
    ) {
        super()
    }

    protected init() {
        this.subscription = combineLatest([
            this.threeSceneManagerService.sceneManagerService.selectedNodeParts$,
            this.threeSceneManagerService.sceneManagerService.hoveredNodePart$,
        ]).subscribe(([selectedNodeParts, hoveredNodePart]) => {
            if (this.threeSceneManagerService.$displayMode() === "configurator") return

            const outlineObjects = hoveredNodePart ? [hoveredNodePart] : selectedNodeParts

            const highlighted = hoveredNodePart
                ? hoveredNodePart.templateNode === this.templateNodePart.templateNode &&
                  (hoveredNodePart.part === "root" || hoveredNodePart.part === this.templateNodePart.part)
                : outlineObjects.some((nodePart) => {
                      return this.templateNodePart.templateNode === nodePart.templateNode && this.templateNodePart.part === nodePart.part
                  })

            const selected = selectedNodeParts.some((nodePart) => {
                return this.templateNodePart.templateNode === nodePart.templateNode && this.templateNodePart.part === nodePart.part
            })

            this.updateStyle(highlighted, selected)
        })
    }

    protected abstract updateStyle(highlighted: boolean, selected: boolean): void

    abstract isConcealable(): boolean
    abstract updateLabels(label: string, description: string): void
    abstract getTranslationOffset(): {x: string; y: string}
    abstract getOcclusionOpacity(): number

    getElement(camera: THREE.Camera) {
        const cached = this.elementCache.get(camera.uuid)
        if (cached) return cached
        else {
            const cloned = this.getContainer().cloneNode(true) as HTMLDivElement

            cloned.addEventListener("mousedown", this.onMouseDown)
            cloned.addEventListener("click", this.onClick)

            this.elementCache.set(camera.uuid, cloned)
            return cloned
        }
    }

    protected abstract getContainer(): HTMLDivElement

    private onMouseDown = (event: MouseEvent) => {
        event.stopPropagation()
    }

    protected onClick = (event: MouseEvent) => {
        if (this.threeSceneManagerService.$displayMode() === "configurator") return
        if (this.parent) {
            this.threeSceneManagerService.sceneManagerService.handleClickEvent({
                target: this.threeSceneManagerService.sceneManagerService.getSceneNodeParts(this.templateNodePart).map((x) => ({sceneNodePart: x})),
                modifiers: {
                    shiftKey: event.shiftKey,
                    ctrlKey: event.ctrlKey,
                },
            })
            event.stopPropagation()
        }
    }

    dispose(final: boolean) {
        for (const element of this.elementCache.values()) {
            element.removeEventListener("mousedown", this.onMouseDown)
            element.removeEventListener("click", this.onClick)
            element.remove()
        }
        this.elementCache.clear()

        if (final) this.subscription?.unsubscribe()
    }
}
