import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SaveMaterialRevisionDialogMaterialFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, comment?: string | null, organization?: { __typename: 'Organization', name?: string | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', name: string } }> };

export type SaveMaterialRevisionDialogMaterialQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type SaveMaterialRevisionDialogMaterialQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, comment?: string | null, organization?: { __typename: 'Organization', name?: string | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', name: string } }> } };

export const SaveMaterialRevisionDialogMaterialFragmentDoc = gql`
    fragment SaveMaterialRevisionDialogMaterial on Material {
  id
  legacyId
  name
  comment
  organization {
    name
  }
  tagAssignments(tagTypes: MaterialRange) {
    tag {
      name
    }
  }
}
    `;
export const SaveMaterialRevisionDialogMaterialDocument = gql`
    query saveMaterialRevisionDialogMaterial($id: ID, $legacyId: Int) {
  material(id: $id, legacyId: $legacyId) {
    ...SaveMaterialRevisionDialogMaterial
  }
}
    ${SaveMaterialRevisionDialogMaterialFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveMaterialRevisionDialogMaterialGQL extends Apollo.Query<SaveMaterialRevisionDialogMaterialQuery, SaveMaterialRevisionDialogMaterialQueryVariables> {
    override document = SaveMaterialRevisionDialogMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }