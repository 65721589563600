import {DeclareObjectNode, ObjectNode, TemplateObjectNode} from "#template-nodes/declare-object-node"
import {IMaterialData} from "@cm/material-nodes/interfaces/material-data"
import {MeshRenderSettings, SceneNodes} from "#template-nodes/interfaces/scene-object"
import {ImageLike, imageLike, MaterialLike, MeshLike, meshLike} from "#template-nodes/node-types"
import {MaterialAssignment, setupMaterialAssignmentWithOverride} from "#template-nodes/nodes/material-assignment"
import {NamedNodeParameters, namedNodeParameters} from "#template-nodes/nodes/named-node"
import {BuilderInlet} from "#template-nodes/runtime-graph/graph-builder"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {GetMeshDecal} from "#template-nodes/runtime-graph/nodes/get-mesh-decal"
import {TransformColorOverlay} from "#template-nodes/runtime-graph/nodes/transform-color-overlay"
import {skipped, visitNone, VisitorNodeVersion} from "@cm/graph/declare-visitor-node"
import {nodeInstance} from "@cm/graph/instance"
import {CircularRefNode, versionChain} from "@cm/graph/node-graph"
import {registerNode} from "@cm/graph/register-node"
import {ImageGenerator} from "@cm/material-nodes/interfaces/image-generator"
import {transformDecalMask} from "@cm/material-nodes/material-node-graph-transformations"
import {z} from "zod"

const decalMaskType = z.enum(["binary", "opacity"])
export type DecalMaskType = z.infer<typeof decalMaskType>

const meshDecalParameters = namedNodeParameters.merge(
    z.object({
        mesh: meshLike.nullable(),
        mask: imageLike.optional(),
        invertMask: z.boolean(),
        maskType: decalMaskType,
        color: imageLike.optional(),
        offset: z.tuple([z.number(), z.number()]),
        rotation: z.number(),
        size: z.tuple([z.number().nullable(), z.number().nullable()]),
        distance: z.number(),
        materialAssignment: nodeInstance(MaterialAssignment).nullable(),
    }),
)
export type MeshDecalParameters = z.infer<typeof meshDecalParameters>

type V0 = ObjectNode &
    NamedNodeParameters & {
        mesh: MeshLike | null
        mask?: ImageLike
        invertMask: boolean
        maskType: "binary" | "opacity"
        color?: ImageLike
        offset: [number, number]
        rotation: number
        size: [number | null, number | null]
        distance: number
        material: MaterialLike | null
    }

type V1 = Omit<V0, "material"> & {materialAssignment: MaterialAssignment | null}
const v0: VisitorNodeVersion<V0, V1> = {
    toNextVersion: (parameters) => {
        const {material, ...rest} = parameters
        if (material instanceof CircularRefNode) throw new Error("Cannot resolve circular references when going from v0 to v1 in mesh decal")
        return {...rest, materialAssignment: material ? new MaterialAssignment({node: material, side: "front"}) : null}
    },
}

@registerNode
export class MeshDecal extends DeclareObjectNode(
    {parameters: meshDecalParameters},
    {
        onVisited: {
            onFilterActive: ({parameters}) => {
                if (parameters.mesh === null) return skipped
                return visitNone(parameters)
            },
            onCompile: function (this: MeshDecalFwd, {context, parameters}) {
                const {mesh, materialAssignment, invertMask, mask, maskType, color, size: inputSize, offset, rotation, distance} = parameters

                if (!mesh) return skipped

                const {evaluator} = context
                const {templateContext} = evaluator
                const {sceneManager} = templateContext

                const scope = evaluator.getScope(this)

                const [meshObjectData, meshObjectDataInvalid] = scope.branch(evaluator.evaluateMesh(scope, mesh))

                const maskImageData = evaluator.evaluateImage(scope, mask ?? null)
                const colorImageData = evaluator.evaluateImage(scope, color ?? null)

                const size = computeConstrainedSizeFromImageData(
                    scope,
                    inputSize,
                    scope.pureLambda(
                        scope.tuple(maskImageData, colorImageData),
                        ([maskImageData, colorImageData]) => maskImageData ?? colorImageData,
                        "relevantDataObject",
                    ), // prefer getting size defaults from mask, rather than overlay image,
                )

                const {completeMeshData} = scope.node(GetMeshDecal, {
                    sceneManager,
                    inputCompleteMeshData: scope.get(meshObjectData, "completeMeshData"),
                    offset,
                    rotation: rotation * (Math.PI / 180),
                    distance,
                    size,
                })

                const materialData = setupMaterialAssignmentWithOverride(scope, context, this, materialAssignment)
                const [materialAssignmentData, materialAssignmentDataInvalid] = scope.branch(materialData)

                const materialGraph = scope.phi(scope.get(materialAssignmentData, "materialGraph"), materialAssignmentDataInvalid)

                const [validMaterialGraph] = scope.branch(materialGraph)
                const [validColorDataObject] = scope.branch(colorImageData)
                const [transformedMaterialGraph, transformedMaterialGraphInvalid] = scope.branch(
                    scope.phi(
                        scope.node(TransformColorOverlay, {
                            material: validMaterialGraph,
                            image: validColorDataObject,
                            size,
                            useAlpha: false,
                        }).outputMaterial,
                        materialGraph,
                    ),
                )

                const alphaMaskThreshold = getAlphaMaskThresholdFromDecalMaskType(maskType)

                const decalMaterialData = scope.phi(
                    scope.pureLambda(
                        scope.tuple(transformedMaterialGraph, maskImageData, colorImageData, size, invertMask, alphaMaskThreshold, materialData),
                        ([transformedMaterialGraph, maskImageData, colorImageData, size, invertMask, alphaMaskThreshold, materialData]) => {
                            const materialDataWithMask: IMaterialData = {
                                name: transformedMaterialGraph.name,
                                materialGraph: transformDecalMask(transformedMaterialGraph, {
                                    maskImage: maskImageData ? maskImageData.imageNode : undefined,
                                    colorOverlayImage: colorImageData ? colorImageData.imageNode : undefined,
                                    widthCm: size[0],
                                    heightCm: size[1],
                                    invert: invertMask,
                                }),
                                side: "front",
                                alphaMaskThreshold,
                                realtimeSettings: materialData?.realtimeSettings,
                            }
                            return materialDataWithMask
                        },
                        "materialMapValid",
                    ),
                    transformedMaterialGraphInvalid,
                )

                const materialMap = scope.pureLambda(decalMaterialData, (materialData) => new Map([[0, materialData]]), "materialMap")

                const parentMesh = scope.pureLambda(
                    meshObjectData,
                    (meshObjectData) => {
                        return meshObjectData.displayList.find(SceneNodes.Mesh.is) ?? null
                    },
                    "parentMesh",
                )

                this.setupObject(
                    scope,
                    context,
                    "Mesh",
                    completeMeshData,
                    meshObjectData,
                    (objectProps) => {
                        return scope.struct<SceneNodes.Mesh>("Mesh", {
                            type: "Mesh",
                            ...objectProps,
                            meshRenderSettings: scope.struct<MeshRenderSettings>("MeshRenderSettings", {}),
                            completeMeshData,
                            materialMap,
                            visibleDirectly: scope.get(meshObjectData, "visibleDirectly"),
                            visibleInReflections: scope.get(meshObjectData, "visibleInReflections"),
                            visibleInRefractions: scope.get(meshObjectData, "visibleInRefractions"),
                            castRealtimeShadows: scope.get(meshObjectData, "castRealtimeShadows"),
                            receiveRealtimeShadows: scope.get(meshObjectData, "receiveRealtimeShadows"),
                            isDecal: true,
                            parent: parentMesh,
                            isProcedural: true,
                        })
                    },
                    meshObjectDataInvalid,
                )

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "MeshDecal", versionChain: versionChain([v0])},
) {}

export type MeshDecalFwd = TemplateObjectNode<MeshDecalParameters>

function getAlphaMaskThresholdFromDecalMaskType(decalMaskType: DecalMaskType) {
    switch (decalMaskType) {
        case "binary":
            return 0.5
        case "opacity":
            return 0.0
        default:
            throw Error("Unrecognized decal mask type.")
    }
}

// compute size based on (partially) specified values and DataObject width/height
function computeConstrainedSizeFromImageData(
    scope: GraphBuilderScope,
    inputSize: [number | null, number | null],
    imageData: BuilderInlet<ImageGenerator | null>,
) {
    if (inputSize[0] !== null && inputSize[1] !== null) {
        // need to use scope.tuple here, because change detection for scope.value will not work when inputSize array is reused (it assumes reference equality check is sufficient)
        return scope.tuple(inputSize[0], inputSize[1]) // size is fully specified
    } else if (inputSize[0] === null && inputSize[1] === null) {
        return scope.tuple(10, 10) // size is unspecified
    } else {
        // size is partially specified, use aspect ratio of mask
        return scope.pureLambda(
            scope.tuple(inputSize, imageData),
            ([inputSize, imageData]): [number, number] => {
                const metadata = imageData?.metadata

                if (!metadata) {
                    console.error("Image metadata not available")
                    return [10, 10]
                }

                const {width, height, legacyId} = metadata

                if (width === undefined || height === undefined) {
                    console.error(`DataObject ${legacyId ?? " with unknown id "} width/height not available`)
                    return [10, 10]
                } else if (inputSize[0] === null) {
                    if (inputSize[1] === null) {
                        console.error(`DataObject ${legacyId ?? " with unknown id "} input size 0/1 not available`)
                        return [10, 10]
                    }
                    return [(inputSize[1] * width) / height, inputSize[1]]
                } else {
                    return [inputSize[0], (inputSize[0] * height) / width]
                }
            },
            "constrainedSize",
        )
    }
}
