import {Component, computed, input, output} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ConfigMenuService} from "@app/common/components/viewers/configurator/config-menu/services/config-menu.service"
import {PricedItemNode, PricingContext, PricingNode} from "@cm/pricing"
import {VariantConditionNodeComponent} from "@pricing/components/node-visualizers/variant-condition-node/variant-condition-node.component"

type ValidityStatus = "valid" | "warning" | "error"

@Component({
    selector: "cm-priced-item",
    templateUrl: "./priced-item-node.component.html",
    styleUrls: ["./priced-item-node.component.scss", "../node-styles.scss"],
    imports: [VariantConditionNodeComponent, MatTooltipModule],
})
export class PricedItemNodeComponent {
    readonly $node = input.required<PricingNode>({alias: "node"})

    readonly $pricedItemNode = computed<PricedItemNode | undefined>(() => {
        const node = this.$node()
        return node instanceof PricedItemNode ? node : undefined
    })

    readonly $pricingContext = input.required<PricingContext>({alias: "pricingContext"})

    readonly $priceResult = computed<{price?: number; error?: string}>(() => {
        const node = this.$pricedItemNode()
        if (node) {
            try {
                const price = node.getPrice(this.$pricingContext())
                return {price}
            } catch (e) {
                return {error: String(e)}
            }
        } else {
            return {error: "Node is undefined"}
        }
    })

    readonly $validityStatus = computed<ValidityStatus>(() => {
        const priceResult = this.$priceResult()
        const node = this.$pricedItemNode()

        const hasPrice = priceResult.price !== undefined

        const condition = node?.parameters.condition?.parameters.condition
        const hasCondition = condition ? condition.variantIds.length > 0 : false

        if (hasPrice) {
            return "valid"
        } else if (!hasPrice && !hasCondition) {
            return "warning"
        } else {
            return "error"
        }
    })

    readonly $description = computed<string>(() => {
        const node = this.$pricedItemNode()
        const result = this.$priceResult()
        if (node) {
            return result.price !== undefined ? `${node.getDescription()}: ${result.price}` : `${node.getDescription()}: Could not get price`
        } else {
            return ""
        }
    })

    readonly conditionRemoved = output<void>()

    constructor(public configMenuService: ConfigMenuService) {}
}
