import {Component, effect, inject, input} from "@angular/core"
import {TemplatesSectionItemFragment} from "@app/platform/components/details/templates-section/templates-section.generated"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {SectionComponent} from "@common/components/item"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {Labels} from "@labels"
import {
    TemplatesSectionItemsForMaterialGQL,
    TemplatesSectionItemsForTemplateGQL,
} from "@platform/components/details/templates-section/templates-section.generated"

type ItemType = {id: string; __typename: "Material"} | {id: string; __typename: "Template"} | null | undefined

@Component({
    selector: "cm-templates-section",
    imports: [SectionComponent, EntityCardComponent, PlaceholderComponent],
    templateUrl: "./templates-section.component.html",
    styleUrl: "./templates-section.component.scss",
})
export class TemplatesSectionComponent {
    readonly $item = input<ItemType>(null, {alias: "item"})
    readonly $title = input<string | null | undefined>(null, {alias: "title"})

    readonly permission = inject(PermissionsService)
    readonly itemsForMaterialGql = inject(TemplatesSectionItemsForMaterialGQL)
    readonly itemsForTemplateGql = inject(TemplatesSectionItemsForTemplateGQL)
    $can = this.permission.$to

    templates: TemplatesSectionItemFragment[] | undefined | null = undefined

    constructor() {
        effect(() => this.loadTemplates(this.$item()))
    }

    loadTemplates(item: ItemType) {
        this.templates = undefined
        switch (item?.__typename) {
            case "Material": {
                const materialId = this.$item()!.id
                fetchThrowingErrors(this.itemsForMaterialGql)({materialId})
                    .then(({material}) => {
                        // use map to prevent duplicates
                        const templatesById = new Map<string, TemplatesSectionItemFragment>()
                        material.revisions.forEach((revision) =>
                            revision.templateRevisions.forEach((templateRevision) =>
                                templatesById.set(templateRevision.template.id, templateRevision.template),
                            ),
                        )
                        // sort by legacyId to ensure consistent order
                        this.templates = Array.from([...templatesById.values()].sort((a, b) => a.legacyId - b.legacyId))
                    })
                    .catch(() => {
                        this.templates = null
                    })
                break
            }
            case "Template": {
                const templateId = item!.id
                fetchThrowingErrors(this.itemsForTemplateGql)({templateId})
                    .then(({template}) => {
                        // use map to prevent duplicates
                        const templatesById = new Map<string, TemplatesSectionItemFragment>()
                        template.revisions.forEach((revision) =>
                            revision.referencedBy.forEach((templateRevision) => templatesById.set(templateRevision.template.id, templateRevision.template)),
                        )
                        // sort by legacyId to ensure consistent order
                        this.templates = Array.from([...templatesById.values()].sort((a, b) => a.legacyId - b.legacyId))
                    })
                    .catch(() => {
                        this.templates = null
                    })
                break
            }
            default: {
                this.templates = null
            }
        }
    }

    thumbnailIds(template: TemplatesSectionItemFragment): string[] | null {
        if (template.galleryImage?.id) {
            return [template.galleryImage.id]
        }
        return template.latestRevision?.renderedImages?.map(({id}) => id) ?? null
    }

    get showSection() {
        return this.templates === undefined || !!this.templates?.length
    }

    get showPlaceholders() {
        return this.templates === undefined
    }

    protected readonly Labels = Labels
}
