<div class="cm-value-slot" (dragover)="dragOver($event)" (dragleave)="dragLeave($event)" (drop)="drag.drop($event)">
    @if (!$propertyAcceptsJSON() && $propertyAcceptsBoolean() && !$propertyAcceptsNodes() && $selectionPossibilities() === undefined) {
        <div class="cm-raw-boolean">
            <cm-toggle [toggle]="$booleanValue() ?? false" (toggleChange)="updateValue($event)" [matTooltip]="$tooltipText()">
                <ng-container cm-text>
                    @if ($label(); as label) {
                        <div [matTooltip]="label">
                            {{ label }}
                        </div>
                    }
                </ng-container>
            </cm-toggle>
            <div class="cm-filler"></div>
            @if ($propertyFilled() && $canClear()) {
                <i (click)="$event.stopPropagation(); clear()" class="far fa-times-circle cm-button" [matTooltip]="'Clear'"></i>
            }
        </div>
    } @else {
        <ng-template #labelTemplate>
            <div class="cm-label">
                @if ($labelText(); as labelText) {
                    <div [matTooltip]="$labelTooltipText()" class="cm-value-title" [class.cm-overridden-title]="$overwrittenValue() !== undefined">
                        {{ labelText }}
                    </div>
                    @if ($overwrittenValue() !== undefined) {
                        <i
                            (click)="$event.stopPropagation(); clear()"
                            class="far fa-circle-info"
                            [matTooltip]="'The value was overwritten by the selected configuration. This is not the stored value.'"
                        ></i>
                    }
                }
            </div>
        </ng-template>
        @if ($materialLikeNode(); as materialLike) {
            <cm-material-inspector [node]="materialLike.materialLike" [highlighted]="isDropTarget()" (requestUpdateMaterial)="updateValue($event)">
                <ng-container cm-label>
                    <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
                </ng-container>
                <ng-container cm-top-right> <ng-content select="[cm-top-right]"></ng-content> </ng-container>
                <ng-container cm-bottom-right> <ng-content select="[cm-bottom-right]"></ng-content> </ng-container>
            </cm-material-inspector>
        } @else {
            @if ($materialAssignmentNode(); as materialAssignment) {
                <cm-material-assignment-inspector
                    [label]="$label()"
                    [node]="materialAssignment.materialAssignment"
                    [highlighted]="isDropTarget()"
                    (requestUpdateMaterialAssignment)="requestUpdate.emit($event)"
                >
                    <ng-container cm-label>
                        <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
                    </ng-container>
                </cm-material-assignment-inspector>
            } @else {
                @if ($materialAssignmentsNode(); as materialAssignments) {
                    <cm-material-assignments-inspector [node]="materialAssignments.materialAssignments" [mesh]="materialAssignments.mesh">
                    </cm-material-assignments-inspector>
                } @else {
                    @if ($imageLikeNode(); as imageLike) {
                        <cm-image-inspector
                            [label]="$label()"
                            [node]="imageLike.imageLike"
                            [highlighted]="isDropTarget()"
                            (requestUpdateImage)="updateValue($event)"
                        >
                            <ng-container cm-label>
                                <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
                            </ng-container>
                        </cm-image-inspector>
                    } @else {
                        <cm-input-container
                            class="cm-input-container"
                            [backgroundColor]="isDropTarget() ? '#fef9c3' : $propertyAcceptsNodes() ? '#f4fdff' : 'transparent'"
                            [borderColor]="$propertyAcceptsNodes() ? '#22d3ee' : ''"
                        >
                            <ng-container cm-top-label>
                                @if ($topLabel() === true) {
                                    <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
                                }
                            </ng-container>
                            <ng-container cm-inline-label>
                                @if ($topLabel() !== true) {
                                    <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
                                }
                            </ng-container>
                            <ng-container cm-input>
                                <div class="cm-edit-section">
                                    @if ($propertyAcceptsNodes() && !$isLinkedToAnyNodes()) {
                                        <i class="fa fa-sign-in" [matTooltip]="$acceptedValuesText()"></i>
                                    }
                                    @if ($isLinkedToAnyNodes()) {
                                        @if ($acceptedNodeClassArrays().length > 0) {
                                            @if ($nodeArrayProperty(); as nodeArrayProperty) {
                                                <div class="cm-template-node-list">
                                                    @for (nodeProperty of nodeArrayProperty; track nodeProperty.instanceId) {
                                                        <div class="cm-edit-section">
                                                            <cm-template-node
                                                                class="cm-node cm-input-container"
                                                                [node]="nodeProperty"
                                                                [notClickable]="$selectionPossibilities() !== undefined"
                                                                [matMenuTriggerFor]="selectionPossibilitiesMenu"
                                                            ></cm-template-node>
                                                            <div class="cm-filler"></div>
                                                            <i
                                                                (click)="$event.stopPropagation(); removeFromArray(nodeProperty)"
                                                                class="far fa-times-circle cm-button"
                                                                [matTooltip]="'Remove'"
                                                            ></i>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        } @else {
                                            @if ($nodeProperty(); as nodeProperty) {
                                                <cm-template-node
                                                    class="cm-node cm-input-container"
                                                    [node]="nodeProperty"
                                                    [notClickable]="$selectionPossibilities() !== undefined"
                                                    [matMenuTriggerFor]="selectionPossibilitiesMenu"
                                                ></cm-template-node>
                                            }
                                        }
                                    } @else {
                                        @if ($selectionPossibilities(); as selectionPossibilities) {
                                            @if ($propertyFilled()) {
                                                <i [class]="'far fa-cog cm-accepts-icon'"></i>
                                                <cm-string-input
                                                    class="cm-input-container"
                                                    [value]="$selectionText() ?? ''"
                                                    [readonly]="true"
                                                    [isButton]="true"
                                                    [matMenuTriggerFor]="selectionPossibilitiesMenu"
                                                    [matTooltip]="$selectionText() ?? ''"
                                                >
                                                </cm-string-input>
                                            } @else {
                                                <cm-numeric-input
                                                    class="cm-input-container cm-fallback-text"
                                                    [value]="undefined"
                                                    [placeholder]="$fallbackText()"
                                                    [readonly]="true"
                                                    [isButton]="true"
                                                    [matMenuTriggerFor]="selectionPossibilitiesMenu"
                                                >
                                                </cm-numeric-input>
                                            }
                                        } @else {
                                            @if ($propertyAcceptsJSON()) {
                                                <cm-json-input
                                                    class="cm-input-container"
                                                    [value]="$jsonValue()"
                                                    (valueChange)="updateValue($event)"
                                                    [matTooltip]="$tooltipText()"
                                                >
                                                </cm-json-input>
                                            } @else if ($propertyAcceptsNumber()) {
                                                <cm-numeric-input
                                                    class="cm-input-container"
                                                    [value]="$numberValue()"
                                                    [decimalPlaces]="$decimalPlaces()"
                                                    [emptyValue]="0"
                                                    [placeholder]="$fallbackText()"
                                                    (valueChange)="updateValue($event)"
                                                    [matTooltip]="$tooltipText()"
                                                >
                                                </cm-numeric-input>
                                                @if ($numberModifier() === "slider") {
                                                    <cm-slider
                                                        [min]="$min() ?? 0"
                                                        [max]="$max() ?? 100"
                                                        [value]="$numberValue() ?? 0"
                                                        (valueChange)="updateValue($event)"
                                                        [matTooltip]="$tooltipText()"
                                                        [displayValue]="false"
                                                        (isDragging)="setDragging($event)"
                                                    ></cm-slider>
                                                } @else if ($numberModifier() === "arrows") {
                                                    <cm-numeric-up-down-arrows
                                                        [min]="$min()"
                                                        [max]="$max()"
                                                        [value]="$numberValue() ?? 0"
                                                        [delta]="$delta() ?? 1"
                                                        (valueChange)="updateValue($event)"
                                                        [matTooltip]="$tooltipText()"
                                                        (isDragging)="setDragging($event)"
                                                    >
                                                    </cm-numeric-up-down-arrows>
                                                }
                                            } @else if ($propertyAcceptsBoolean()) {
                                                <cm-toggle
                                                    [toggle]="$booleanValue() ?? false"
                                                    (toggleChange)="updateValue($event)"
                                                    [matTooltip]="$tooltipText()"
                                                >
                                                </cm-toggle>
                                            } @else if ($propertyAcceptsString()) {
                                                @if ($minRows() > 1) {
                                                    <cm-native-input-text-area
                                                        class="cm-input-container"
                                                        [initialValue]="$stringValue() ?? ''"
                                                        [minRows]="$minRows()"
                                                        (valueChange)="updateValue($event)"
                                                        [matTooltip]="$tooltipText()"
                                                    >
                                                    </cm-native-input-text-area>
                                                } @else {
                                                    <cm-string-input
                                                        class="cm-input-container"
                                                        [value]="$stringValue() ?? ''"
                                                        (valueChange)="updateValue($event)"
                                                        [matTooltip]="$tooltipText()"
                                                        [validate]="$validate()"
                                                    >
                                                    </cm-string-input>
                                                }
                                            } @else if ($propertyAcceptsColor()) {
                                                <div class="cm-colorslot">
                                                    <cm-color-input
                                                        class="cm-input-container"
                                                        [value]="$colorValue() ?? [0, 0, 0]"
                                                        (valueChange)="updateValue($event)"
                                                        (isPopupOpen)="setDragging($event)"
                                                        [matTooltip]="$tooltipText()"
                                                    ></cm-color-input>
                                                </div>
                                            } @else {
                                                @if ($propertyFilled() && $acceptedNodeClassArrays().length === 0) {
                                                    <cm-string-input
                                                        class="cm-input-container cm-fallback-text"
                                                        [value]="JSON.stringify($property())"
                                                        [readonly]="true"
                                                        [matTooltip]="$tooltipText()"
                                                    >
                                                    </cm-string-input>
                                                } @else {
                                                    <cm-numeric-input
                                                        class="cm-input-container cm-fallback-text"
                                                        [value]="undefined"
                                                        [placeholder]="
                                                            $acceptedNodeClassArrays().length > 0
                                                                ? '(no nodes)'
                                                                : $acceptedNodeClasses().length > 0
                                                                  ? '(no node)'
                                                                  : $fallbackText()
                                                        "
                                                        [readonly]="true"
                                                    >
                                                    </cm-numeric-input>
                                                }
                                            }
                                        }
                                    }
                                    <div class="cm-filler"></div>
                                    @if ($selectionPossibilities(); as selectionPossibilities) {
                                        <i
                                            (click)="$event.stopPropagation()"
                                            class="far fa-circle-caret-down cm-button"
                                            [matTooltip]="'Select'"
                                            [matMenuTriggerFor]="selectionPossibilitiesMenu"
                                        >
                                        </i>
                                    }
                                    @if ($propertyFilled() && $canClear()) {
                                        <i (click)="$event.stopPropagation(); clear()" class="far fa-times-circle cm-button" [matTooltip]="'Clear'"></i>
                                    }
                                    @if ($canPaste()) {
                                        <i (click)="$event.stopPropagation(); paste()" class="far fa-paste cm-button" [matTooltip]="'Paste'"></i>
                                    }
                                </div>
                            </ng-container>
                        </cm-input-container>
                    }
                }
            }
        }
    }
</div>
<mat-menu #selectionPossibilitiesMenu>
    @if ($selectionPossibilities(); as selectionPossibilities) {
        @for (selectionPossibility of selectionPossibilities; track selectionPossibility.name) {
            <cm-list-item
                [selected]="$isSelected()(selectionPossibility, $property())"
                (click)="
                    $isSelected()(selectionPossibility, $property()) ? undefined : updateValue(resolveSelectionPossibilityValue(selectionPossibility.value))
                "
            >
                <ng-container cm-pre-title><i class="far fa-cog"></i></ng-container>
                <ng-container cm-title>{{ selectionPossibility.name }}</ng-container>
                <ng-container cm-icons>
                    @if (selectionPossibility.actions) {
                        @for (action of selectionPossibility.actions; track action.iconClass) {
                            <i (click)="$event.stopPropagation(); action.fn()" [matTooltip]="action.toolTip ?? ''" [class]="action.iconClass"></i>
                        }
                    }
                </ng-container>
            </cm-list-item>
        }
    }
</mat-menu>
