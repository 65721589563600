import {Geometry} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-rasterize-geometry"
import {Base64DataSchema, ImageProcessingNodes} from "@cm/image-processing-nodes"
import {toBase64} from "@cm/browser-utils"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {z} from "zod"

export function convertToImageProcessingGeometry(geometry: Geometry): ImageProcessingNodes.Geometry {
    return {
        type: "geometry",
        topology: geometry.topology,
        vertices: {
            positions: geometry.vertices.positions.map((position) => [position.x, position.y]),
            uvs: geometry.vertices.uvs ? geometry.vertices.uvs.map((uv) => [uv.x, uv.y]) : undefined,
            colors: geometry.vertices.colors ? geometry.vertices.colors.map((color) => [color.r, color.g, color.b, color.a ?? 1]) : undefined,
        },
        indices: geometry.indices,
    }
}

export async function uploadGeometry(uploadService: UploadGqlService, organizationId: string, geometry: Geometry, filename = "geometry.json") {
    const positions = new Float32Array(geometry.vertices.positions.flatMap((position) => [position.x, position.y]))
    const uvs = geometry.vertices.uvs ? new Float32Array(geometry.vertices.uvs.flatMap((uv) => [uv.x, uv.y])) : undefined
    const indices = geometry.indices ? new Uint32Array(geometry.indices) : undefined
    const mapToBase64 = (data: Float32Array | Uint32Array): z.infer<typeof Base64DataSchema> => {
        return {
            type: "base64",
            data: toBase64(data),
        }
    }
    const geometryBase64: ImageProcessingNodes.GeometryBase64 = {
        type: "geometry-base64",
        topology: "triangleList",
        vertices: {
            positions: mapToBase64(positions),
            uvs: uvs ? mapToBase64(uvs) : undefined,
        },
        indices: indices ? mapToBase64(indices) : undefined,
    }
    const contentType = "application/json"
    const file = new File([new Blob([JSON.stringify(geometryBase64)], {type: contentType})], filename, {type: contentType})
    return uploadService.createAndUploadDataObject(file, {organizationId: organizationId}, {showUploadToolbar: false, processUpload: false})
}
