import {Component, inject, input, output} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonToggleModule} from "@angular/material/button-toggle"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {DataObjectAssignmentType} from "@generated"
import {
    PictureViewerDataObjectAssignmentFragment,
    PictureViewerPictureDataObjectFragment,
    PictureViewerPictureRevisionFragment,
} from "@platform/components/pictures/picture-viewer/picture-viewer.generated"
import {isMobileDevice} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {CanvasBaseComponent} from "@common/components/canvas"
import {FilesizePipe} from "@common/pipes/filesize/filesize.pipe"
import {FormatDatePipe} from "@common/pipes/format-date/format-date.pipe"
import {TimeAgoPipe} from "@common/pipes/time-ago/time-ago.pipe"
import {AuthService} from "@common/services/auth/auth.service"
import {FilesService} from "@common/services/files/files.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {ActionPanelRowComponent} from "@app/common/components/menu/actions/action-panel-row/action-panel-row.component"
import {ActionPanelComponent} from "@app/common/components/menu/actions/action-panel/action-panel.component"
import {ActionBarComponent} from "@app/common/components/menu/actions/action-bar/action-bar.component"
import {ActionItemComponent} from "@app/common/components/menu/actions/action-item/action-item.component"

@Component({
    selector: "cm-picture-controls",
    templateUrl: "./picture-controls.component.html",
    styleUrls: ["./picture-controls.component.scss"],
    imports: [
        ActionPanelRowComponent,
        ActionPanelComponent,
        MatTooltipModule,
        FilesizePipe,
        ActionBarComponent,
        ActionItemComponent,
        MatMenuModule,
        MatButtonToggleModule,
        FormsModule,
        FormatDatePipe,
        TimeAgoPipe,
    ],
})
export class PictureControlsComponent {
    readonly $dataObject = input<PictureViewerPictureDataObjectFragment>(undefined, {alias: "dataObject"})
    readonly $pictureRevision = input<PictureViewerPictureRevisionFragment>(undefined, {alias: "pictureRevision"})
    readonly $pictureCanvas = input<CanvasBaseComponent>(undefined, {alias: "pictureCanvas"})

    readonly revisionChange = output<"previous" | "next">()

    readonly edit = output<void>()
    readonly save = output<void>()
    readonly discard = output<void>()

    readonly $selectedAssignment = input<PictureViewerDataObjectAssignmentFragment>(undefined, {alias: "selectedAssignment"})
    readonly selectedAssignmentChange = output<PictureViewerDataObjectAssignmentFragment>()

    isMobileDevice = isMobileDevice

    readonly permission = inject(PermissionsService)
    $can = this.permission.$to

    constructor(public auth: AuthService) {}

    getZoomLevel(): number {
        return Math.round((this.$pictureCanvas()?.navigation?.getPhysicalZoomLevel() ?? devicePixelRatio) * 100)
    }

    getFileTypeLabel(dataObject: {mediaType?: string | null}): string {
        switch (dataObject?.mediaType) {
            case "image/x-exr":
                return "EXR"
            case "image/tiff":
                return "TIFF"
            case "image/jpeg":
                return "JPEG"
            default:
                return dataObject?.mediaType ?? "?"
        }
    }

    labelForAssignment(assignment: {assignmentType: DataObjectAssignmentType}): string {
        switch (assignment.assignmentType) {
            case DataObjectAssignmentType.Attachment:
                return "Attachment"
            case DataObjectAssignmentType.GalleryImage:
                return "GalleryImage"
            case DataObjectAssignmentType.CustomerLogo:
                return "CustomerLogo"
            case DataObjectAssignmentType.PictureData:
                return "PictureData"
            case DataObjectAssignmentType.PictureDataSecondary:
                return "PictureDataSecondary"
            case DataObjectAssignmentType.PictureDataTertiary:
                return "PictureDataTertiary"
            case DataObjectAssignmentType.PictureDataLinear:
                return "PictureDataLinear"
            case DataObjectAssignmentType.CryptoMatte01:
                return "CryptoMatte01"
            case DataObjectAssignmentType.CryptoMatte02:
                return "CryptoMatte02"
            case DataObjectAssignmentType.CryptoMatte03:
                return "CryptoMatte03"
            case DataObjectAssignmentType.ProfilePicture:
                return "ProfilePicture"
            case DataObjectAssignmentType.DrawingData:
                return "DrawingData"
            case DataObjectAssignmentType.ScanMergedFile:
                return "ScanMergedFile"
            case DataObjectAssignmentType.ScanFittingWorkPackage:
                return "ScanFittingWorkPackage"
            case DataObjectAssignmentType.ScanAssemblyWorkPackage:
                return "ScanAssemblyWorkPackage"
            case DataObjectAssignmentType.ScanCalibrationData:
                return "ScanCalibrationData"
            case DataObjectAssignmentType.ScanLog:
                return "ScanLog"
            case DataObjectAssignmentType.MeshDataDrc:
                return "MeshDataDRC"
            case DataObjectAssignmentType.MeshDataDrcProxy:
                return "MeshDataDRCProxy"
            case DataObjectAssignmentType.MeshDataPly:
                return "MeshDataPLY"
            case DataObjectAssignmentType.TemplateConfigIcon:
                return "TemplateConfigIcon"
            case DataObjectAssignmentType.CachedTemplateGltf:
                return "CachedTemplateGLTF"
            case DataObjectAssignmentType.CachedTemplateUsdz:
                return "CachedTemplateUSDZ"
            case DataObjectAssignmentType.Icon:
                return "Icon"
            default:
                return "?"
        }
    }

    downloadOriginal(dataObject: {originalFileName: string; downloadUrl: string}): void {
        FilesService.downloadFile(dataObject.originalFileName, dataObject.downloadUrl)
    }

    downloadJpeg(dataObject?: {originalFileName: string; thumbnail?: {downloadUrl: string} | null} | null): void {
        if (dataObject?.thumbnail?.downloadUrl) {
            FilesService.downloadFile(dataObject.originalFileName, dataObject.thumbnail?.downloadUrl)
        }
    }
}
