import {Component, computed, inject, output} from "@angular/core"
import {MaterialFilterInput} from "@generated"
import {cardAnimations} from "@app/common/components/cards/animations"
import {BaseSelectComponent} from "@app/platform/components/base/base-select/base-select.component"
import {MaterialReference} from "@cm/template-nodes"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {TemplateAddMaterialItemsGQL, TemplateAddMaterialItemFragment} from "@template-editor/components/template-add-material/template-add-material.generated"
import {CardErrorComponent} from "../../../common/components/cards/card-error/card-error.component"
import {CardPlaceholderComponent} from "../../../common/components/cards/card-placeholder/card-placeholder.component"
import {OwnOrganizationsFilterComponent} from "../../../common/components/filters/own-organizations-filter/own-organizations-filter.component"
import {SearchComponent} from "../../../common/components/inputs/search/search.component"
import {InfiniteListComponent} from "../../../common/components/lists/infinite-list/infinite-list.component"
import {TemplateAddCardComponent} from "../template-add-card/template-add-card.component"

@Component({
    selector: "cm-template-add-material",
    templateUrl: "./template-add-material.component.html",
    styleUrl: "./template-add-material.component.scss",
    animations: [...cardAnimations],
    imports: [SearchComponent, InfiniteListComponent, CardErrorComponent, TemplateAddCardComponent, CardPlaceholderComponent, OwnOrganizationsFilterComponent],
})
export class TemplateAddMaterialComponent extends BaseSelectComponent<TemplateAddMaterialItemFragment, MaterialFilterInput> {
    readonly materialsGql = inject(TemplateAddMaterialItemsGQL)
    private rawData = this.implementDataLoader(fetchThrowingErrors(this.materialsGql))
    readonly $data = computed(() => this.rawData().items.map((item) => ({...item, node: item.data ? this.getMaterialReference(item.data) : undefined})))
    readonly onItemClicked = output()

    constructor() {
        super()
        this.$customFilters.set({hasCyclesMaterial: true})
    }

    private getMaterialReference(data: TemplateAddMaterialItemFragment) {
        const materialRevisionId = data.latestCyclesRevision?.legacyId
        if (materialRevisionId === undefined) throw new Error("Material revision ID is undefined")

        return new MaterialReference({name: data.name ?? "New Material Reference", materialRevisionId})
    }
}
