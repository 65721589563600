import {RefCountable} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/ref-countable"

export class RefCountableBase implements RefCountable {
    constructor(args?: {addRefFn?: () => void; releaseFn?: () => void; disposeFn?: () => void}) {
        this._addRefFn = args?.addRefFn
        this._releaseFn = args?.releaseFn
        this._disposeFn = args?.disposeFn
    }

    get refCount(): number {
        return this._refCount
    }

    addRef() {
        ++this._refCount
        this._addRefFn?.()
    }

    release() {
        --this._refCount
        this._releaseFn?.()
        if (this._refCount === 0) {
            this._disposeFn?.()
        }
    }

    private _refCount = 1
    private readonly _addRefFn?: () => void
    private readonly _releaseFn?: () => void
    private readonly _disposeFn?: () => void
}
