import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type HdriForEditorSceneQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type HdriForEditorSceneQuery = { __typename: 'Query', hdri: { __typename: 'Hdri', dataObject?: { __typename: 'DataObject', mediaType?: string | null, bucketName: string, objectName: string } | null } };

export type UploadHdriFromFileMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  dataObjectId: Types.Scalars['ID']['input'];
}>;


export type UploadHdriFromFileMutation = { __typename: 'Mutation', updateHdri: { __typename: 'Hdri', id: string } };

export const HdriForEditorSceneDocument = gql`
    query hdriForEditorScene($id: ID, $legacyId: Int) {
  hdri(id: $id, legacyId: $legacyId) {
    dataObject {
      mediaType
      bucketName
      objectName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HdriForEditorSceneGQL extends Apollo.Query<HdriForEditorSceneQuery, HdriForEditorSceneQueryVariables> {
    override document = HdriForEditorSceneDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadHdriFromFileDocument = gql`
    mutation uploadHdriFromFile($id: ID!, $dataObjectId: ID!) {
  updateHdri(input: {id: $id, dataObjectId: $dataObjectId}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadHdriFromFileGQL extends Apollo.Mutation<UploadHdriFromFileMutation, UploadHdriFromFileMutationVariables> {
    override document = UploadHdriFromFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }