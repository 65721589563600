import {User} from "@generated"
import {StateLabel} from "@labels"

export const fullName = (user: Pick<User, "firstName" | "lastName">): string => {
    return `${user.firstName} ${user.lastName}`
}

export const sortUserStateLabelListByLastName = (users: StateLabel<string>[]): StateLabel<string>[] => {
    return users.sort((a, b) => a.label.split(" ").slice(-1)[0].localeCompare(b.label.split(" ").slice(-1)[0]))
}
