import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UploadServiceDataObjectFragment = { __typename: 'DataObject', id: string, legacyId: number, signedUploadUrl: string };

export type UploadServiceGetDataObjectQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type UploadServiceGetDataObjectQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, legacyId: number, signedUploadUrl: string } };

export type UploadServiceDeleteDataObjectMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type UploadServiceDeleteDataObjectMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export type UploadServiceCreateDataObjectMutationVariables = Types.Exact<{
  input: Types.MutationCreateDataObjectInput;
}>;


export type UploadServiceCreateDataObjectMutation = { __typename: 'Mutation', createDataObject: { __typename: 'DataObject', id: string, legacyId: number, signedUploadUrl: string } };

export type DataObjectQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DataObjectQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, state: Types.DataObjectState } };

export type OrganizationQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type OrganizationQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, name?: string | null, type?: Types.OrganizationType | null } };

export type ColormassOrganizationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ColormassOrganizationQuery = { __typename: 'Query', organizations: Array<{ __typename: 'Organization', id: string, type?: Types.OrganizationType | null } | null> };

export const UploadServiceDataObjectFragmentDoc = gql`
    fragment UploadServiceDataObject on DataObject {
  id
  legacyId
  signedUploadUrl
}
    `;
export const UploadServiceGetDataObjectDocument = gql`
    query uploadServiceGetDataObject($id: ID!) {
  dataObject(id: $id) {
    ...UploadServiceDataObject
  }
}
    ${UploadServiceDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadServiceGetDataObjectGQL extends Apollo.Query<UploadServiceGetDataObjectQuery, UploadServiceGetDataObjectQueryVariables> {
    override document = UploadServiceGetDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadServiceDeleteDataObjectDocument = gql`
    mutation uploadServiceDeleteDataObject($id: ID!) {
  deleteDataObject(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadServiceDeleteDataObjectGQL extends Apollo.Mutation<UploadServiceDeleteDataObjectMutation, UploadServiceDeleteDataObjectMutationVariables> {
    override document = UploadServiceDeleteDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadServiceCreateDataObjectDocument = gql`
    mutation uploadServiceCreateDataObject($input: MutationCreateDataObjectInput!) {
  createDataObject(input: $input) {
    ...UploadServiceDataObject
  }
}
    ${UploadServiceDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadServiceCreateDataObjectGQL extends Apollo.Mutation<UploadServiceCreateDataObjectMutation, UploadServiceCreateDataObjectMutationVariables> {
    override document = UploadServiceCreateDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DataObjectDocument = gql`
    query dataObject($id: ID!) {
  dataObject(id: $id) {
    id
    state
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DataObjectGQL extends Apollo.Query<DataObjectQuery, DataObjectQueryVariables> {
    override document = DataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganizationDocument = gql`
    query organization($id: ID!) {
  organization(id: $id) {
    id
    name
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationGQL extends Apollo.Query<OrganizationQuery, OrganizationQueryVariables> {
    override document = OrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ColormassOrganizationDocument = gql`
    query colormassOrganization {
  organizations(filter: {url: {equals: "colormass"}}) {
    id
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ColormassOrganizationGQL extends Apollo.Query<ColormassOrganizationQuery, ColormassOrganizationQueryVariables> {
    override document = ColormassOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }