export class PriorityQueue<T> {
    private heap: {element: T; priority: number}[] = []
    private indexMap: Map<T, number> = new Map()
    private comparator: (priorityA: number, priorityB: number) => boolean = (a, b) => a > b // Min heap by default

    private parent(index: number): number {
        return Math.floor((index - 1) / 2)
    }

    private leftChild(index: number): number {
        return 2 * index + 1
    }

    private rightChild(index: number): number {
        return 2 * index + 2
    }

    private swap(index1: number, index2: number): void {
        const temp = this.heap[index1]
        this.heap[index1] = this.heap[index2]
        this.heap[index2] = temp

        // Update the index map
        this.indexMap.set(this.heap[index1].element, index1)
        this.indexMap.set(this.heap[index2].element, index2)
    }

    private heapifyUp(index: number): void {
        let currentIndex = index
        while (currentIndex > 0 && this.comparator(this.heap[this.parent(currentIndex)].priority, this.heap[currentIndex].priority)) {
            this.swap(currentIndex, this.parent(currentIndex))
            currentIndex = this.parent(currentIndex)
        }
    }

    private heapifyDown(index: number): void {
        let currentIndex = index
        for (;;) {
            const left = this.leftChild(currentIndex)
            const right = this.rightChild(currentIndex)
            let largest = currentIndex
            if (left < this.heap.length && !this.comparator(this.heap[left].priority, this.heap[largest].priority)) {
                largest = left
            }
            if (right < this.heap.length && !this.comparator(this.heap[right].priority, this.heap[largest].priority)) {
                largest = right
            }
            if (largest === currentIndex) {
                break
            }
            this.swap(currentIndex, largest)
            currentIndex = largest
        }
    }

    enqueue(element: T, priority: number): void {
        if (this.indexMap.has(element)) {
            this.updatePriority(element, priority)
            return
        }

        const node = {element, priority}
        this.heap.push(node)
        this.indexMap.set(element, this.heap.length - 1)
        this.heapifyUp(this.heap.length - 1)
    }

    dequeue(): T | null {
        if (this.heap.length === 0) {
            return null
        }

        const root = this.heap[0]
        const last = this.heap.pop()!
        this.indexMap.delete(root.element)

        if (this.heap.length > 0) {
            this.heap[0] = last
            this.indexMap.set(last.element, 0)
            this.heapifyDown(0)
        }

        return root.element
    }

    peek(): T | null {
        return this.heap.length > 0 ? this.heap[0].element : null
    }

    peekPriority(): number | null {
        return this.heap.length > 0 ? this.heap[0].priority : null
    }

    updatePriority(element: T, newPriority: number): void {
        const index = this.indexMap.get(element)

        if (index === undefined) throw new Error("Element not found in the queue")

        const oldPriority = this.heap[index].priority
        this.heap[index].priority = newPriority

        if (newPriority < oldPriority) {
            this.heapifyUp(index)
        } else {
            this.heapifyDown(index)
        }
    }

    contains(element: T): boolean {
        return this.indexMap.has(element)
    }

    isEmpty(): boolean {
        return this.heap.length === 0
    }
}
