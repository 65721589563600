import {Injectable, Signal, WritableSignal} from "@angular/core"

@Injectable({
    providedIn: "root",
})
export class NodeSelectionManagerService<NodeComponentT extends {$selected: WritableSignal<boolean>}> {
    selected?: NodeComponentT | null

    select(node: NodeComponentT): void {
        if (this.selected) this.selected.$selected.set(false)
        node.$selected.set(true)
        this.selected = node
    }

    deselectAll(): void {
        if (this.selected) this.selected.$selected.set(false)
        this.selected = null
    }
}
