import {CommonModule} from "@angular/common"
import {Component, inject, input} from "@angular/core"
import {FullPageFeedbackComponent} from "@common/components/full-page-feedback/full-page-feedback.component"
import {ImagePlaceholderComponent} from "@common/components/placeholders/image-placeholder/image-placeholder.component"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {DialogService} from "@common/services/dialog/dialog.service"
import {z} from "zod"
import {ActivatedRoute, Router} from "@angular/router"

@Component({
    selector: "cm-details-dialog-layout",
    imports: [CommonModule, FullPageFeedbackComponent, ImagePlaceholderComponent, PlaceholderComponent],
    templateUrl: "./details-dialog-layout.component.html",
    styleUrl: "./details-dialog-layout.component.scss",
})
export class DetailsDialogLayoutComponent {
    readonly $isLoaded = input(true, {alias: "isLoaded"})
    readonly $loadError = input<Error | null>(null, {alias: "loadError"})
    readonly $title = input<string | null>(null, {alias: "title"})
    readonly $wideSidebar = input(false, {alias: "wideSidebar"})

    readonly dialog = inject(DialogService)
    readonly route = inject(ActivatedRoute)
    readonly router = inject(Router)

    errorText(loadError?: Error) {
        if (!loadError) {
            return undefined
        }
        const response = z.object({response: z.object({errors: z.array(z.object({message: z.string()}))})}).safeParse(loadError)
        return response.success ? response.data.response.errors?.[0]?.message : loadError.message
    }

    async closeModal() {
        const route = this.router.createUrlTree([this.route.snapshot.data.closeNavigationPath ?? "../"], {
            relativeTo: this.route,
            queryParams: {
                ...(this.route.snapshot.data.closeQueryParamOverride ?? {}),
            },
            queryParamsHandling: "merge",
        })
        await this.router.navigateByUrl(route)
    }
}
