import {Inlet, NotReady, Outlet} from "#template-nodes/runtime-graph/slots"
import {NodeClassImpl, Unresolved} from "#template-nodes/runtime-graph/types"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"

const TD = TypeDescriptors

const unresolvedToNullDescriptor = {
    input: TD.inlet(TD.Identity<any>()),
    output: TD.outlet(TD.Identity<any>()),
}

export class UnresolvedToNull<T> implements NodeClassImpl<typeof unresolvedToNullDescriptor, typeof UnresolvedToNull> {
    static descriptor = unresolvedToNullDescriptor
    static uniqueName = "UnresolvedToNull"
    input!: Inlet<T | typeof Unresolved>
    output!: Outlet<T | null>

    run() {
        if (this.input === NotReady) this.output.emitIfChanged(NotReady)
        else if (this.input === Unresolved) this.output.emitIfChanged(null)
        else this.output.emitIfChanged(this.input)
    }
}
