<div class="cm-tasks">
    @if (tasks === undefined) {
        <cm-placeholder [dark]="true"></cm-placeholder>
        <cm-placeholder [dark]="true"></cm-placeholder>
        <cm-placeholder [dark]="true"></cm-placeholder>
        <cm-placeholder [dark]="true"></cm-placeholder>
        <cm-placeholder [dark]="true"></cm-placeholder>
    } @else {
        @for (task of tasks; track task.id) {
            <cm-list-item-detailed
                [ngClass]="{completed: task.state == Enums.TaskState.Completed}"
                (click)="navigateToTask(task)"
                (mouseover)="mouseOverTask.emit(task); highlightTask(task)"
            >
                <ng-container cm-left>
                    <div [class.cm-selected-task]="task === $selectedTask()" class="cm-task-number" (click)="$event.stopPropagation()">
                        {{ task.number }}
                    </div>
                </ng-container>
                <ng-container cm-title>
                    @if (lastComment(task); as lastTaskComment) {
                        <div class="cm-task-title">
                            {{ lastTaskComment.text }}
                        </div>
                    }
                </ng-container>
                <div cm-subtitle class="cm-task-subtitle">
                    <cm-state-label
                        [stateLabels]="Labels.TaskState"
                        (click)="$event.stopPropagation()"
                        [state]="task.state"
                        [editable]="$can().update.task()"
                        (stateChange)="updateTask(task, {state: $event})"
                    >
                    </cm-state-label>
                    @if ($can().read.task(task, "public")) {
                        @if (task.public) {
                            <i class="far fa-eye"></i>
                        } @else {
                            <i class="far fa-eye-slash"></i>
                        }
                    }
                    @if (lastComment(task); as lastTaskComment) {
                        <div class="cm-task-extra-info">
                            <i class="far fa-comment-alt-lines"></i>
                            <span class="cm-task-icon-text">{{ task.comments.length }}</span>
                        </div>
                        <div class="cm-task-extra-info">
                            <i class="far fa-clock"></i>
                            <span class="cm-task-icon-text"> {{ lastComment(task)?.createdAt | cmTimeAgo }}</span>
                        </div>
                    }
                </div>
                <ng-container cm-top-right>
                    @if ($can().read.menu("archiveTask")) {
                        <div class="cm-task-menu" (click)="$event.stopPropagation()" [matMenuTriggerFor]="taskMenu">
                            <i class="fas fa-ellipsis-v"></i>
                        </div>
                    }
                    <mat-menu #taskMenu="matMenu">
                        <button (click)="archiveTask(task)" mat-menu-item>
                            <span>Archive</span>
                        </button>
                    </mat-menu>
                </ng-container>
            </cm-list-item-detailed>
        }
        @if (addingTask) {
            <div class="cm-task-new">
                <cm-input-container>
                    <ng-container cm-input>
                        <cm-native-input-text-area
                            [initialValue]="newTaskText"
                            [autoFocus]="true"
                            [minRows]="3"
                            [placeholder]="'Enter a comment'"
                            (keyUp)="newTaskText = $event"
                        >
                        </cm-native-input-text-area>
                    </ng-container>
                </cm-input-container>
                <div class="cm-task-new-actions">
                    <cm-button [disabled]="!newTaskText" (click)="addTask(newTaskText); toggleAddTask()" class="cm-task-new-add">
                        <ng-container cm-text> Add task</ng-container>
                    </cm-button>
                    <div class="cm-task-new-cancel" (click)="toggleAddTask()">
                        <i class="far fa-xmark"></i>
                    </div>
                </div>
            </div>
        }
        @if ($can().create.task({organization: organizationId !== undefined ? {id: organizationId} : undefined}) && !addingTask) {
            <div (click)="toggleAddTask()" class="cm-task-add">
                <div class="cm-task-new-add-text"><i class="far fa-plus"></i> Add a new task</div>
            </div>
        }
    }
</div>
