import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MaterialEditorMaterialRevisionFragment = { __typename: 'MaterialRevision', id: string, hasCyclesMaterial: boolean, legacyId: number, number: number, connections: Array<{ __typename: 'MaterialConnection', id: string, legacyId: number, destinationParameter: string, sourceParameter: string, destination: { __typename: 'MaterialNode', id: string, legacyId: number }, source: { __typename: 'MaterialNode', id: string, legacyId: number } }>, nodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, height: number, width: number, texture?: { __typename: 'Texture', type: Types.TextureType } | null } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number } }> } | null, materialRevision: { __typename: 'MaterialRevision', id: string } }> };

export type MaterialEditorMaterialFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, revisions: Array<{ __typename: 'MaterialRevision', id: string, hasCyclesMaterial: boolean, legacyId: number, number: number, connections: Array<{ __typename: 'MaterialConnection', id: string, legacyId: number, destinationParameter: string, sourceParameter: string, destination: { __typename: 'MaterialNode', id: string, legacyId: number }, source: { __typename: 'MaterialNode', id: string, legacyId: number } }>, nodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, height: number, width: number, texture?: { __typename: 'Texture', type: Types.TextureType } | null } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number } }> } | null, materialRevision: { __typename: 'MaterialRevision', id: string } }> }> };

export type MaterialEditorMaterialConnectionFragment = { __typename: 'MaterialConnection', id: string, legacyId: number, destinationParameter: string, sourceParameter: string, destination: { __typename: 'MaterialNode', id: string, legacyId: number }, source: { __typename: 'MaterialNode', id: string, legacyId: number } };

export type MaterialEditorMaterialNodeFragment = { __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, height: number, width: number, texture?: { __typename: 'Texture', type: Types.TextureType } | null } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number } }> } | null, materialRevision: { __typename: 'MaterialRevision', id: string } };

export type MaterialEditorTextureFragment = { __typename: 'Texture', id: string, legacyId: number, type: Types.TextureType, latestRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, displacement?: number | null, height: number, state: Types.TextureRevisionState, width: number, texture?: { __typename: 'Texture', type: Types.TextureType } | null } | null };

export type MaterialEditorTextureRevisionFragment = { __typename: 'TextureRevision', id: string, legacyId: number, displacement?: number | null, height: number, state: Types.TextureRevisionState, width: number, texture?: { __typename: 'Texture', type: Types.TextureType } | null };

export type MaterialEditorTextureSetFragment = { __typename: 'TextureSet', id: string, textures: Array<{ __typename: 'Texture', id: string, legacyId: number, type: Types.TextureType, latestRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, displacement?: number | null, height: number, state: Types.TextureRevisionState, width: number, texture?: { __typename: 'Texture', type: Types.TextureType } | null } | null }> };

export type OrganizationsWithNodeEditorTemplateIdQueryVariables = Types.Exact<{
  organizationIds: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type OrganizationsWithNodeEditorTemplateIdQuery = { __typename: 'Query', organizations: Array<{ __typename: 'Organization', id: string, legacyId: number, nodeEditorTemplate?: { __typename: 'Template', legacyId: number, id: string } | null } | null> };

export type MaterialEditorCreateMaterialRevisionMutationVariables = Types.Exact<{
  input: Types.MutationCreateMaterialRevisionInput;
}>;


export type MaterialEditorCreateMaterialRevisionMutation = { __typename: 'Mutation', createMaterialRevision: { __typename: 'MaterialRevision', id: string, hasCyclesMaterial: boolean, legacyId: number, number: number, connections: Array<{ __typename: 'MaterialConnection', id: string, legacyId: number, destinationParameter: string, sourceParameter: string, destination: { __typename: 'MaterialNode', id: string, legacyId: number }, source: { __typename: 'MaterialNode', id: string, legacyId: number } }>, nodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, height: number, width: number, texture?: { __typename: 'Texture', type: Types.TextureType } | null } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number } }> } | null, materialRevision: { __typename: 'MaterialRevision', id: string } }> } };

export type MaterialEditorCreateMaterialNodeMutationVariables = Types.Exact<{
  input: Types.MutationCreateMaterialNodeInput;
}>;


export type MaterialEditorCreateMaterialNodeMutation = { __typename: 'Mutation', createMaterialNode: { __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, height: number, width: number, texture?: { __typename: 'Texture', type: Types.TextureType } | null } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number } }> } | null, materialRevision: { __typename: 'MaterialRevision', id: string } } };

export type MaterialEditorCreateMaterialConnectionMutationVariables = Types.Exact<{
  input: Types.MutationCreateMaterialConnectionInput;
}>;


export type MaterialEditorCreateMaterialConnectionMutation = { __typename: 'Mutation', createMaterialConnection: { __typename: 'MaterialConnection', id: string, legacyId: number, destinationParameter: string, sourceParameter: string, destination: { __typename: 'MaterialNode', id: string, legacyId: number }, source: { __typename: 'MaterialNode', id: string, legacyId: number } } };

export type MaterialEditorMaterialQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MaterialEditorMaterialQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, revisions: Array<{ __typename: 'MaterialRevision', id: string, hasCyclesMaterial: boolean, legacyId: number, number: number, connections: Array<{ __typename: 'MaterialConnection', id: string, legacyId: number, destinationParameter: string, sourceParameter: string, destination: { __typename: 'MaterialNode', id: string, legacyId: number }, source: { __typename: 'MaterialNode', id: string, legacyId: number } }>, nodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, height: number, width: number, texture?: { __typename: 'Texture', type: Types.TextureType } | null } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number } }> } | null, materialRevision: { __typename: 'MaterialRevision', id: string } }> }> } };

export type MaterialEditorTextureRevisionQueryVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type MaterialEditorTextureRevisionQuery = { __typename: 'Query', textureRevision: { __typename: 'TextureRevision', id: string, legacyId: number, displacement?: number | null, height: number, state: Types.TextureRevisionState, width: number, texture?: { __typename: 'Texture', type: Types.TextureType } | null } };

export type MaterialEditorTextureSetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MaterialEditorTextureSetQuery = { __typename: 'Query', textureSet: { __typename: 'TextureSet', id: string, textures: Array<{ __typename: 'Texture', id: string, legacyId: number, type: Types.TextureType, latestRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, displacement?: number | null, height: number, state: Types.TextureRevisionState, width: number, texture?: { __typename: 'Texture', type: Types.TextureType } | null } | null }> } };

export type MaterialEditorMaterialConnectionsQueryVariables = Types.Exact<{
  materialRevisionId: Types.Scalars['ID']['input'];
}>;


export type MaterialEditorMaterialConnectionsQuery = { __typename: 'Query', materialConnections: Array<{ __typename: 'MaterialConnection', id: string, legacyId: number, destinationParameter: string, sourceParameter: string, destination: { __typename: 'MaterialNode', id: string, legacyId: number }, source: { __typename: 'MaterialNode', id: string, legacyId: number } } | null> };

export type MaterialEditorMaterialNodesQueryVariables = Types.Exact<{
  materialRevisionId: Types.Scalars['ID']['input'];
}>;


export type MaterialEditorMaterialNodesQuery = { __typename: 'Query', materialNodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', id: string, legacyId: number, height: number, width: number, texture?: { __typename: 'Texture', type: Types.TextureType } | null } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number } }> } | null, materialRevision: { __typename: 'MaterialRevision', id: string } } | null> };

export type MaterialEditorResolveTextureRevisionLegacyIdQueryVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type MaterialEditorResolveTextureRevisionLegacyIdQuery = { __typename: 'Query', textureRevision: { __typename: 'TextureRevision', id: string } };

export type MaterialEditorLatestTextureSetRevisionQueryVariables = Types.Exact<{
  textureSetId: Types.Scalars['ID']['input'];
}>;


export type MaterialEditorLatestTextureSetRevisionQuery = { __typename: 'Query', textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string } | null> };

export const MaterialEditorMaterialConnectionFragmentDoc = gql`
    fragment MaterialEditorMaterialConnection on MaterialConnection {
  id
  legacyId
  destination {
    id
    legacyId
  }
  destinationParameter
  source {
    id
    legacyId
  }
  sourceParameter
}
    `;
export const MaterialEditorMaterialNodeFragmentDoc = gql`
    fragment MaterialEditorMaterialNode on MaterialNode {
  id
  legacyId
  name
  parameters
  textureRevision {
    id
    legacyId
    height
    texture {
      type
    }
    width
  }
  textureSetRevision {
    id
    width
    height
    mapAssignments {
      textureType
      dataObject {
        legacyId
      }
    }
  }
  materialRevision {
    id
  }
}
    `;
export const MaterialEditorMaterialRevisionFragmentDoc = gql`
    fragment MaterialEditorMaterialRevision on MaterialRevision {
  id
  hasCyclesMaterial
  legacyId
  number
  connections {
    ...MaterialEditorMaterialConnection
  }
  nodes {
    ...MaterialEditorMaterialNode
  }
}
    `;
export const MaterialEditorMaterialFragmentDoc = gql`
    fragment MaterialEditorMaterial on Material {
  __typename
  id
  legacyId
  name
  revisions {
    ...MaterialEditorMaterialRevision
  }
}
    `;
export const MaterialEditorTextureRevisionFragmentDoc = gql`
    fragment MaterialEditorTextureRevision on TextureRevision {
  id
  legacyId
  displacement
  height
  state
  texture {
    type
  }
  width
}
    `;
export const MaterialEditorTextureFragmentDoc = gql`
    fragment MaterialEditorTexture on Texture {
  id
  legacyId
  latestRevision {
    ...MaterialEditorTextureRevision
  }
  type
}
    `;
export const MaterialEditorTextureSetFragmentDoc = gql`
    fragment MaterialEditorTextureSet on TextureSet {
  id
  textures {
    ...MaterialEditorTexture
  }
}
    `;
export const OrganizationsWithNodeEditorTemplateIdDocument = gql`
    query organizationsWithNodeEditorTemplateId($organizationIds: [ID!]!) {
  organizations(filter: {id: {in: $organizationIds}}) {
    id
    legacyId
    nodeEditorTemplate {
      legacyId
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationsWithNodeEditorTemplateIdGQL extends Apollo.Query<OrganizationsWithNodeEditorTemplateIdQuery, OrganizationsWithNodeEditorTemplateIdQueryVariables> {
    override document = OrganizationsWithNodeEditorTemplateIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorCreateMaterialRevisionDocument = gql`
    mutation materialEditorCreateMaterialRevision($input: MutationCreateMaterialRevisionInput!) {
  createMaterialRevision(input: $input) {
    ...MaterialEditorMaterialRevision
  }
}
    ${MaterialEditorMaterialRevisionFragmentDoc}
${MaterialEditorMaterialConnectionFragmentDoc}
${MaterialEditorMaterialNodeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorCreateMaterialRevisionGQL extends Apollo.Mutation<MaterialEditorCreateMaterialRevisionMutation, MaterialEditorCreateMaterialRevisionMutationVariables> {
    override document = MaterialEditorCreateMaterialRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorCreateMaterialNodeDocument = gql`
    mutation materialEditorCreateMaterialNode($input: MutationCreateMaterialNodeInput!) {
  createMaterialNode(input: $input) {
    ...MaterialEditorMaterialNode
  }
}
    ${MaterialEditorMaterialNodeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorCreateMaterialNodeGQL extends Apollo.Mutation<MaterialEditorCreateMaterialNodeMutation, MaterialEditorCreateMaterialNodeMutationVariables> {
    override document = MaterialEditorCreateMaterialNodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorCreateMaterialConnectionDocument = gql`
    mutation materialEditorCreateMaterialConnection($input: MutationCreateMaterialConnectionInput!) {
  createMaterialConnection(input: $input) {
    ...MaterialEditorMaterialConnection
  }
}
    ${MaterialEditorMaterialConnectionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorCreateMaterialConnectionGQL extends Apollo.Mutation<MaterialEditorCreateMaterialConnectionMutation, MaterialEditorCreateMaterialConnectionMutationVariables> {
    override document = MaterialEditorCreateMaterialConnectionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorMaterialDocument = gql`
    query materialEditorMaterial($id: ID!) {
  material(id: $id) {
    ...MaterialEditorMaterial
  }
}
    ${MaterialEditorMaterialFragmentDoc}
${MaterialEditorMaterialRevisionFragmentDoc}
${MaterialEditorMaterialConnectionFragmentDoc}
${MaterialEditorMaterialNodeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorMaterialGQL extends Apollo.Query<MaterialEditorMaterialQuery, MaterialEditorMaterialQueryVariables> {
    override document = MaterialEditorMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorTextureRevisionDocument = gql`
    query materialEditorTextureRevision($legacyId: Int!) {
  textureRevision(legacyId: $legacyId) {
    ...MaterialEditorTextureRevision
  }
}
    ${MaterialEditorTextureRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorTextureRevisionGQL extends Apollo.Query<MaterialEditorTextureRevisionQuery, MaterialEditorTextureRevisionQueryVariables> {
    override document = MaterialEditorTextureRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorTextureSetDocument = gql`
    query materialEditorTextureSet($id: ID!) {
  textureSet(id: $id) {
    ...MaterialEditorTextureSet
  }
}
    ${MaterialEditorTextureSetFragmentDoc}
${MaterialEditorTextureFragmentDoc}
${MaterialEditorTextureRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorTextureSetGQL extends Apollo.Query<MaterialEditorTextureSetQuery, MaterialEditorTextureSetQueryVariables> {
    override document = MaterialEditorTextureSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorMaterialConnectionsDocument = gql`
    query materialEditorMaterialConnections($materialRevisionId: ID!) {
  materialConnections(
    filter: {materialRevisionId: {equals: $materialRevisionId}}
    take: 2147483647
  ) {
    ...MaterialEditorMaterialConnection
  }
}
    ${MaterialEditorMaterialConnectionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorMaterialConnectionsGQL extends Apollo.Query<MaterialEditorMaterialConnectionsQuery, MaterialEditorMaterialConnectionsQueryVariables> {
    override document = MaterialEditorMaterialConnectionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorMaterialNodesDocument = gql`
    query materialEditorMaterialNodes($materialRevisionId: ID!) {
  materialNodes(
    filter: {materialRevisionId: {equals: $materialRevisionId}}
    take: 2147483647
  ) {
    ...MaterialEditorMaterialNode
  }
}
    ${MaterialEditorMaterialNodeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorMaterialNodesGQL extends Apollo.Query<MaterialEditorMaterialNodesQuery, MaterialEditorMaterialNodesQueryVariables> {
    override document = MaterialEditorMaterialNodesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorResolveTextureRevisionLegacyIdDocument = gql`
    query materialEditorResolveTextureRevisionLegacyId($legacyId: Int!) {
  textureRevision(legacyId: $legacyId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorResolveTextureRevisionLegacyIdGQL extends Apollo.Query<MaterialEditorResolveTextureRevisionLegacyIdQuery, MaterialEditorResolveTextureRevisionLegacyIdQueryVariables> {
    override document = MaterialEditorResolveTextureRevisionLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialEditorLatestTextureSetRevisionDocument = gql`
    query materialEditorLatestTextureSetRevision($textureSetId: ID!) {
  textureSetRevisions(
    filter: {textureSetId: {equals: $textureSetId}}
    orderBy: {direction: desc, key: createdAt}
    take: 1
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialEditorLatestTextureSetRevisionGQL extends Apollo.Query<MaterialEditorLatestTextureSetRevisionQuery, MaterialEditorLatestTextureSetRevisionQueryVariables> {
    override document = MaterialEditorLatestTextureSetRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }