import * as Types from '../../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TagSelectAssignMutationVariables = Types.Exact<{
  objectId: Types.Scalars['ID']['input'];
  contentTypeModel: Types.ContentTypeModel;
  tagId: Types.Scalars['ID']['input'];
}>;


export type TagSelectAssignMutation = { __typename: 'Mutation', createTagAssignment: { __typename: 'TagAssignment', id: string } };

export type TagSelectUnassignMutationVariables = Types.Exact<{
  tagAssignmentId: Types.Scalars['ID']['input'];
}>;


export type TagSelectUnassignMutation = { __typename: 'Mutation', deleteTagAssignment?: any | null };

export type TagSelectTagFragment = { __typename: 'Tag', id: string, name: string, type: Types.TagType };

export type TagSelectTagsQueryVariables = Types.Exact<{
  filter: Types.TagFilterInput;
}>;


export type TagSelectTagsQuery = { __typename: 'Query', tags: Array<{ __typename: 'Tag', id: string, name: string, type: Types.TagType } | null> };

export type TagSelectTagAssignmentsQueryVariables = Types.Exact<{
  filter: Types.TagAssignmentFilterInput;
}>;


export type TagSelectTagAssignmentsQuery = { __typename: 'Query', tagAssignments: Array<{ __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string } } | null> };

export const TagSelectTagFragmentDoc = gql`
    fragment TagSelectTag on Tag {
  id
  name
  type
}
    `;
export const TagSelectAssignDocument = gql`
    mutation tagSelectAssign($objectId: ID!, $contentTypeModel: ContentTypeModel!, $tagId: ID!) {
  createTagAssignment(
    input: {objectId: $objectId, tagId: $tagId, contentTypeModel: $contentTypeModel}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TagSelectAssignGQL extends Apollo.Mutation<TagSelectAssignMutation, TagSelectAssignMutationVariables> {
    override document = TagSelectAssignDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TagSelectUnassignDocument = gql`
    mutation tagSelectUnassign($tagAssignmentId: ID!) {
  deleteTagAssignment(id: $tagAssignmentId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TagSelectUnassignGQL extends Apollo.Mutation<TagSelectUnassignMutation, TagSelectUnassignMutationVariables> {
    override document = TagSelectUnassignDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TagSelectTagsDocument = gql`
    query tagSelectTags($filter: TagFilterInput!) {
  tags(filter: $filter, take: 9999) {
    ...TagSelectTag
  }
}
    ${TagSelectTagFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TagSelectTagsGQL extends Apollo.Query<TagSelectTagsQuery, TagSelectTagsQueryVariables> {
    override document = TagSelectTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TagSelectTagAssignmentsDocument = gql`
    query tagSelectTagAssignments($filter: TagAssignmentFilterInput!) {
  tagAssignments(filter: $filter) {
    id
    tag {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TagSelectTagAssignmentsGQL extends Apollo.Query<TagSelectTagAssignmentsQuery, TagSelectTagAssignmentsQueryVariables> {
    override document = TagSelectTagAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }