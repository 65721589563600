import {AccordionStyle, UiStyle} from "@cm/template-nodes/nodes/scene-properties"

export const getBasicPage = (webComponentSource: string, parameters: string): string => {
    const paramString = parameters ? `parameters=${parameters}` : ""
    return `
<!DOCTYPE html>
<html>
    <head>
        <link href="https://fonts.googleapis.com/css?family=Roboto:200,300,400,500,600,700" rel="stylesheet" />
        <script src=${webComponentSource} type="module"></script>
        <style>
            html, body {
                width: 100%;
                height: 100%;
                margin: 0;
                overflow: hidden;
            }
            cm-configurator-main {
                display: block;
                width: 100%;
                height: 100%;
            }
        </style>
    </head>
    <body>
        <cm-configurator-main template-uuid="1499ef45-504e-4782-bdf1-3175f207a96f" ${paramString} ></cm-configurator-main>
    </body>
</html>
`
}

//The following page is a 1:1 copy from the sample repository
export const getStylingPage = (
    webComponentSource: string,
    uiStyle: UiStyle,
    accordionStyle: AccordionStyle,
    useCaptions: boolean,
    includePdfButton: boolean,
): string => {
    return `
<!DOCTYPE html>
<html style="height: 100%">
    <head>
        <link href="https://fonts.googleapis.com/css?family=Roboto:200,300,400,500,600,700" rel="stylesheet" />

        <!-- Load the colormass configurator web components -->
        <script src="${webComponentSource}" type="module"></script>

        <title>Configurator Web Component Example</title>
        <meta charset="UTF-8" />
        <style>
            :root {
                /* Available css variables */
                --cm-menu-max-num-cols: 8;
                --cm-menu-icon-size: 50px;
                --cm-menu-background-color: rgba(255, 255, 255, 0);
                --cm-tooltip-font-size: 14px;
                --cm-tooltip-background-color: gray;
                font-family: "Roboto";
            }

            .container {
                display: flex;
                height: 100%;
                overflow: hidden;
            }

            .menu {
                justify-content: center;
                width: 15%;
                background-color: #f4f4f4;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                min-width: 400px;
                --color: #d78a5e;

                align-items: center;
                gap: 15px;

                /* The web components use the shadow dom and cannot be accessed directly. The menu currently exposes
                three parts to style: cm-config-group-title, cm-icons-container and cm-icon-container.
                See part="..." in the browser devtools. */
                ::part(cm-config-group-title) {
                    border-width: 1px;
                    border-style: solid;
                    border-color: rgba(0, 140, 153, 0);
                    background-color: var(--color);
                    padding: 10px;
                    color: white;
                    font-size: 16px;
                }

                ::part(cm-config-group-title):hover {
                    border-width: 1px;
                    border-style: solid;
                    border-color: var(--color);
                    background-color: rgba(255, 255, 255, 1);
                    color: var(--color);
                    cursor: pointer;
                }

                ::part(cm-icons-container) {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }

                ::part(cm-icon-container) {
                    border-width: 1px;
                    border-style: solid;
                    border-color: rgba(0, 140, 153, 0);
                }

                ::part(cm-icon-container):hover {
                    border-width: 1px;
                    border-style: solid;
                    border-color: var(--color);
                }

                ::part(cm-icon-with-caption) {
                    gap: 8px;
                    font-size: 10px;
                }

                ::part(cm-config-menu-icons) {
                    gap: 10px;
                }
            }

            cm-configurator-pdf-download-button::part(cm-button) {
                border-width: 1px;
                border-style: solid;
                border-color: rgba(0, 140, 153, 0);
                background-color: var(--color);
                padding: 10px;
                color: white;
            }

            cm-configurator-pdf-download-button::part(cm-button):hover {
                border-color: var(--color);
                background-color: white;
                color: var(--color);
                cursor: pointer;
            }

            .configurator-container {
                flex-grow: 1;
                box-sizing: border-box;
            }
        </style>
    </head>
    <body style="width: 100%; height: 100%; margin: 0">
        <div class="container">
            <div class="menu">
                <!-- Override the default settings for the menu appearance with "accordion"  -->
                <cm-configurator-menu ui-style=${uiStyle} accordion-style=${accordionStyle} ${useCaptions ? "use-captions=true" : ""} style="width: 100%"></cm-configurator-menu>
                ${includePdfButton ? "<cm-configurator-pdf-download-button></cm-configurator-pdf-download-button>" : ""}
            </div>
            <div class="configurator-container">
                <cm-configurator-main template-uuid="22fee4a0-dc34-40c3-a2d3-290126fb8111" use-external-menu="true"></cm-configurator-main>
            </div>
        </div>
    </body>
</html>
`
}

export const getPdfButtonPage = (webComponentSource: string): string => {
    return `

    <!DOCTYPE html>
    <html style="height: 100%">
        <head>
            <link href="https://fonts.googleapis.com/css?family=Roboto:200,300,400,500,600,700" rel="stylesheet" />
    
            <!-- Load the colormass configurator web components -->
            <script src="${webComponentSource}" type="module"></script>
    
            <title>Configurator Web Component Example</title>
            <meta charset="UTF-8" />
            <style>
                :root {
                    font-family: "Roboto";
                }
    
                .container {
                    display: flex;
                    height: 100%;
                    overflow: hidden;
                }
    
                .menu {
                    justify-content: center;
                    width: 15%;
                    background-color: #f4f4f4;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    min-width: 400px;
                    --color: #d78a5e;
    
                    align-items: center;
                    gap: 15px;
                }
    
                cm-configurator-pdf-download-button::part(cm-button) {
                    border-width: 1px;
                    border-style: solid;
                    border-color: rgba(0, 140, 153, 0);
                    background-color: var(--color);
                    padding: 10px;
                    color: white;
                }
    
                cm-configurator-pdf-download-button::part(cm-button):hover {
                    border-color: var(--color);
                    background-color: white;
                    color: var(--color);
                    cursor: pointer;
                }
    
                .configurator-container {
                    flex-grow: 1;
                    box-sizing: border-box;
                }
            </style>
        </head>
        <body style="width: 100%; height: 100%; margin: 0">
            <div class="container">
                <div class="menu">
                    <cm-configurator-pdf-download-button></cm-configurator-pdf-download-button>
                </div>
                <div class="configurator-container">
                    <cm-configurator-main template-uuid="d12dfd86-a8fb-46d9-81ab-5491866ce126" use-external-menu="true"></cm-configurator-main>
                </div>
            </div>
        </body>
    </html>
`
}
