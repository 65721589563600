import {ApolloClientOptions, InMemoryCache, NormalizedCacheObject} from "@apollo/client/core"
import {getHttpLink} from "@common/helpers/api/http-link"

export const cmApolloConfig: () => ApolloClientOptions<NormalizedCacheObject> = () => ({
    link: getHttpLink(),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "network-only",
            nextFetchPolicy: "network-only",
        },
        query: {
            fetchPolicy: "network-only",
            nextFetchPolicy: "network-only",
        },
    },
})
