import {ImageProcessingNodes} from "@cm/image-processing-nodes"
import {halfToFloatArray} from "@cm/utils"
import {TypedImageData} from "@cm/utils/typed-image-data"
import sharp, {ColourspaceEnum, Metadata} from "sharp"

export {ColourspaceEnum, Metadata}

export namespace ImageProcessingLibvips {
    type SharpImageData = Omit<TypedImageData<ReturnType<typeof sharp>>, "width" | "height" | "dataType"> & {
        hasAppliedResize?: boolean // true if the image has been resized
    }

    export function toLibvipsImageData(image: TypedImageData, icc?: string): SharpImageData {
        if (typeof process !== "object") throw Error(`toLibvipsImageData not supported (Nodejs runtime only)`)

        let array: typeof sharp extends (...args: infer A) => infer R ? A[0] : never
        let depth: sharp.RawOptions["depth"]
        switch (image.dataType) {
            case "uint8":
                array = new Uint8Array(image.data.buffer)
                depth = "uchar"
                break
            case "uint16":
                array = new Uint16Array(image.data.buffer)
                depth = "ushort"
                break
            case "float16":
                array = new Float32Array(halfToFloatArray(image.data).buffer)
                depth = "float"
                break // libvips does not support float16, convert to float32 first
            case "float32":
                array = new Float32Array(image.data.buffer)
                depth = "float"
                break
        }

        const sharpImage = sharp(array, {
            raw: {
                width: image.width,
                height: image.height,
                channels: image.channelLayout.length as sharp.Raw["channels"],
            },
            limitInputPixels: false,
        })
            .raw({depth})
            .withMetadata({
                density: image.dpi,
                icc,
            })

        return {
            // For L colorspace we need to extract the first channel, otherwise sharp will interpret the data as RGB when the buffer is generated in fromLibvipsImageData
            data: image.channelLayout === "L" ? sharpImage.extractChannel(0) : sharpImage,
            channelLayout: image.channelLayout,
            colorSpace: image.colorSpace,
            dpi: image.dpi,
        }
    }

    const getChannelLayout = (channels: sharp.OutputInfo["channels"], channelLayoutHint: TypedImageData["channelLayout"]): TypedImageData["channelLayout"] => {
        switch (channels) {
            case 1:
                return "L"
            case 2:
                throw Error(`Unsupported channel layout ${channels}`)
            case 3:
            case 4:
                if (channelLayoutHint.length === channels) return channelLayoutHint
                else throw Error(`Unsupported channel layout ${channels} for hint ${channelLayoutHint}`)
            default:
                throw Error(`Unsupported channel layout ${channels}`)
        }
    }

    export async function fromLibvipsImageData(image: SharpImageData): Promise<TypedImageData> {
        if (typeof process !== "object") throw Error(`fromLibvipsImageData not supported (Nodejs runtime only)`)

        const {data, info} = await image.data.raw().toBuffer({resolveWithObject: true})

        const getDataType = (depth: string): TypedImageData["dataType"] => {
            switch (depth) {
                case "uchar":
                    return "uint8"
                case "ushort":
                    return "uint16"
                case "float":
                    return "float32"
                default:
                    throw Error(`Unsupported image depth ${depth}`)
            }
        }

        const {depth} = info as {depth?: string}
        if (depth === undefined) throw Error("No depth information available")

        return {
            ...image,
            data: new Uint8Array(data.buffer),
            width: info.width,
            height: info.height,
            channelLayout: getChannelLayout(info.channels, image.channelLayout),
            dataType: getDataType(depth),
        }
    }

    export async function resize(
        image: SharpImageData,
        width: number,
        height: number,
        mode: ImageProcessingNodes.Resize["mode"],
        interpolationMode: ImageProcessingNodes.Resize["interpolationMode"],
    ): Promise<SharpImageData> {
        const options: sharp.ResizeOptions = {fit: "fill", kernel: interpolationMode}

        if (mode === "scale-factor") {
            const metadata = await image.data.metadata()
            if (!metadata.width) {
                throw Error("No width information available")
            }
            if (!metadata.height) {
                throw Error("No height information available")
            }
            width *= metadata.width
            height *= metadata.height
        }

        // in case the image was resized before we start a new pipeline before resizing because sharp does not support resizing an image multiple times
        if (image.hasAppliedResize) {
            const imageData = await ImageProcessingLibvips.fromLibvipsImageData(image)
            image = ImageProcessingLibvips.toLibvipsImageData(imageData)
        }
        return {
            ...image,
            data: image.data.resize(width, height, options),
            dpi: undefined,
            hasAppliedResize: true,
        }
    }

    export function create(
        width: TypedImageData["width"],
        height: TypedImageData["height"],
        dataType: TypedImageData["dataType"],
        colorSpace: TypedImageData["colorSpace"],
        color: number | readonly [number, number, number] | readonly [number, number, number, number],
        dpi: TypedImageData["dpi"],
    ): SharpImageData {
        const getData = () => {
            const data = typeof color === "number" ? [color] : color

            switch (dataType) {
                case "uint8":
                    return Uint8Array.from(data)
                case "uint16":
                    return Uint16Array.from(data)
                case "float32":
                    return Float32Array.from(data)
            }

            throw Error(`Creating a ${dataType} image is currently not supported")`)
        }

        const getDepth = () => {
            switch (dataType) {
                case "uint8":
                    return "uchar"
                case "uint16":
                    return "ushort"
                case "float32":
                    return "float"
            }

            throw Error(`Creating a ${dataType} image is currently not supported")`)
        }

        const channelLayout = typeof color === "number" ? "L" : getChannelLayout(color.length, color.length === 3 ? "RGB" : "RGBA")

        const sharpImage = sharp(getData(), {
            raw: {
                width: 1,
                height: 1,
                channels: typeof color === "number" ? 1 : color.length,
            },
        })
            .raw({depth: getDepth()})
            .resize(width, height, {fit: "fill", kernel: "nearest"})

        return {
            // For L colorspace we need to extract the first channel, otherwise sharp will interpret the data as RGB when the buffer is generated in fromLibvipsImageData
            data: channelLayout === "L" ? sharpImage.extractChannel(0) : sharpImage,
            channelLayout,
            colorSpace,
            dpi,
        }
    }

    export function extractChannel(input: SharpImageData, channel: 0 | 3 | 2 | 1 | "red" | "green" | "blue" | "alpha"): SharpImageData {
        return {
            ...input,
            data: input.data.extractChannel(channel),
            channelLayout: "L",
            dpi: input.dpi,
        }
    }

    ///////////////////////////////////////////////////////////////////////
    export function encodeTIFF(image: TypedImageData, options?: {icc?: string}): Promise<Uint8Array> {
        const imageData = toLibvipsImageData(image, options?.icc)
        return (
            imageData.data
                .tiff({compression: "lzw"})
                // replace alpha channel with white background
                .flatten({background: "#ffffff"})
                .toBuffer()
        )
    }

    export function encodePNG(image: TypedImageData, options?: {icc?: string}): Promise<Uint8Array> {
        const imageData = toLibvipsImageData(image, options?.icc)
        return imageData.data.png().toBuffer()
    }

    export function encodeJPEG(image: TypedImageData, options?: {icc?: string}): Promise<Uint8Array> {
        const imageData = toLibvipsImageData(image, options?.icc)
        return (
            imageData.data
                .jpeg({quality: 90, chromaSubsampling: "4:4:4"})
                // replace alpha channel with white background
                .flatten({background: "#ffffff"})
                .toBuffer()
        )
    }

    export async function decodeImage(image: Uint8Array): Promise<TypedImageData> {
        const sharpImage = sharp(image, {ignoreIcc: true, limitInputPixels: false})
        const metadata = await sharpImage.metadata()

        if (metadata.channels === undefined) throw Error("No channel information available")

        const channelLayout = getChannelLayout(metadata.channels, metadata.channels === 4 ? "RGBA" : "RGB")

        return fromLibvipsImageData({
            // For L colorspace we need to extract the first channel, otherwise sharp will interpret the data as RGB when the buffer is generated in fromLibvipsImageData
            data: channelLayout === "L" ? sharpImage.extractChannel(0) : sharpImage,
            channelLayout,
            dpi: metadata.density,
            colorSpace: "sRGB",
        })
    }

    /**
     * Read the file into memory and return the metadata, including image size and color space.
     */
    export async function getImageMetadata(file: string | Uint8Array | Buffer): Promise<Metadata> {
        // TODO: find a better way to get the metadata without loading the whole image into memory
        // https://github.com/lovell/sharp/issues/236
        // could try this approach: https://github.com/lovell/sharp/issues/236#issuecomment-565260529
        // but how do we deal with the color space
        const image = sharp(file, {limitInputPixels: false})
        return image.metadata()
    }
}
