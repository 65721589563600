import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TextureThumbnailViewQueryDataObjectQueryVariables = Types.Exact<{
  dataObjectId: Types.Scalars['ID']['input'];
}>;


export type TextureThumbnailViewQueryDataObjectQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, legacyId: number, imageColorSpace?: Types.ImageColorSpace | null, downloadUrl: string, mediaType?: string | null, originalFileName: string, width?: number | null, height?: number | null, thumbnailAvailable: boolean, related: Array<{ __typename: 'DataObject', id: string, legacyId: number, imageColorSpace?: Types.ImageColorSpace | null, downloadUrl: string, mediaType?: string | null, originalFileName: string, width?: number | null, height?: number | null, thumbnailAvailable: boolean, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number } }>, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number } } };

export type TextureThumbnailViewDataObjectFragment = { __typename: 'DataObject', id: string, legacyId: number, imageColorSpace?: Types.ImageColorSpace | null, downloadUrl: string, mediaType?: string | null, originalFileName: string, width?: number | null, height?: number | null, thumbnailAvailable: boolean, related: Array<{ __typename: 'DataObject', id: string, legacyId: number, imageColorSpace?: Types.ImageColorSpace | null, downloadUrl: string, mediaType?: string | null, originalFileName: string, width?: number | null, height?: number | null, thumbnailAvailable: boolean, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number } }>, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number } };

export type TextureThumbnailViewDataObjectDetailsFragment = { __typename: 'DataObject', id: string, legacyId: number, imageColorSpace?: Types.ImageColorSpace | null, downloadUrl: string, mediaType?: string | null, originalFileName: string, width?: number | null, height?: number | null, thumbnailAvailable: boolean, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number } };

export type TextureThumbnailViewUpdateDataObjectMutationVariables = Types.Exact<{
  input: Types.MutationUpdateDataObjectInput;
}>;


export type TextureThumbnailViewUpdateDataObjectMutation = { __typename: 'Mutation', updateDataObject: { __typename: 'DataObject', id: string } };

export const TextureThumbnailViewDataObjectDetailsFragmentDoc = gql`
    fragment TextureThumbnailViewDataObjectDetails on DataObject {
  id
  legacyId
  imageColorSpace
  downloadUrl
  mediaType
  originalFileName
  width
  height
  thumbnailAvailable
  thumbnail(fileType: jpg, resolution: Low) {
    downloadUrl
  }
  organization {
    id
    legacyId
  }
}
    `;
export const TextureThumbnailViewDataObjectFragmentDoc = gql`
    fragment TextureThumbnailViewDataObject on DataObject {
  ...TextureThumbnailViewDataObjectDetails
  related {
    ...TextureThumbnailViewDataObjectDetails
  }
}
    `;
export const TextureThumbnailViewQueryDataObjectDocument = gql`
    query textureThumbnailViewQueryDataObject($dataObjectId: ID!) {
  dataObject(id: $dataObjectId) {
    ...TextureThumbnailViewDataObject
  }
}
    ${TextureThumbnailViewDataObjectFragmentDoc}
${TextureThumbnailViewDataObjectDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureThumbnailViewQueryDataObjectGQL extends Apollo.Query<TextureThumbnailViewQueryDataObjectQuery, TextureThumbnailViewQueryDataObjectQueryVariables> {
    override document = TextureThumbnailViewQueryDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureThumbnailViewUpdateDataObjectDocument = gql`
    mutation textureThumbnailViewUpdateDataObject($input: MutationUpdateDataObjectInput!) {
  updateDataObject(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureThumbnailViewUpdateDataObjectGQL extends Apollo.Mutation<TextureThumbnailViewUpdateDataObjectMutation, TextureThumbnailViewUpdateDataObjectMutationVariables> {
    override document = TextureThumbnailViewUpdateDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }