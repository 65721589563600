import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { MaterialGraphRevisionFragmentDoc, MaterialGraphTextureSetRevisionFragmentDoc } from '../material-graph/material-graph.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MapsExportTextureRevisionFragment = { __typename: 'TextureRevision', legacyId: number, width: number, height: number, displacement?: number | null, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null }, texture?: { __typename: 'Texture', type: Types.TextureType } | null };

export type NormalToDisplacementStepTextureRevisionQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type NormalToDisplacementStepTextureRevisionQuery = { __typename: 'Query', textureRevision: { __typename: 'TextureRevision', createdByVersion?: string | null, texture?: { __typename: 'Texture', textureSet: { __typename: 'TextureSet', textures: Array<{ __typename: 'Texture', type: Types.TextureType, revisions: Array<{ __typename: 'TextureRevision', createdByVersion?: string | null, legacyId: number, width: number, height: number, displacement?: number | null, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null }, texture?: { __typename: 'Texture', type: Types.TextureType } | null }> }> } } | null } };

export type MaterialRevisionForMapsExportFragment = { __typename: 'MaterialRevision', legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number, width: number, height: number, displacement?: number | null, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null }, texture?: { __typename: 'Texture', type: Types.TextureType } | null } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', id: string, legacyId: number }, destination: { __typename: 'MaterialNode', id: string, legacyId: number } }>, material: { __typename: 'Material', name?: string | null, legacyId: number } };

export type MaterialMapsExporterConnectionFragment = { __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', id: string, legacyId: number }, destination: { __typename: 'MaterialNode', id: string, legacyId: number } };

export type MaterialMapsExporterNodeFragment = { __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number, width: number, height: number, displacement?: number | null, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null }, texture?: { __typename: 'Texture', type: Types.TextureType } | null } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null };

export type MaterialNodeForMapsExportQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type MaterialNodeForMapsExportQuery = { __typename: 'Query', materialNode: { __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number, width: number, height: number, displacement?: number | null, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null }, texture?: { __typename: 'Texture', type: Types.TextureType } | null } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null } };

export type MaterialRevisionForMapsExportQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type MaterialRevisionForMapsExportQuery = { __typename: 'Query', material: { __typename: 'Material', latestCyclesRevision?: { __typename: 'MaterialRevision', legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number, width: number, height: number, displacement?: number | null, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null }, texture?: { __typename: 'Texture', type: Types.TextureType } | null } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', id: string, legacyId: number }, destination: { __typename: 'MaterialNode', id: string, legacyId: number } }>, material: { __typename: 'Material', name?: string | null, legacyId: number } } | null } };

export type MapsExportMaterialDetailsFragment = { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, name?: string | null, organization?: { __typename: 'Organization', legacyId: number } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', name: string } }> };

export type MaterialForMapsExportQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type MaterialForMapsExportQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, name?: string | null, organization?: { __typename: 'Organization', legacyId: number } | null, tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', name: string } }> } };

export type MaterialMapsExporterDeleteDataObjectMutationVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type MaterialMapsExporterDeleteDataObjectMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export type MaterialMapsExporterDeleteJsonFileMutationVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type MaterialMapsExporterDeleteJsonFileMutation = { __typename: 'Mutation', deleteJsonFile?: any | null };

export type MaterialMapsExporterUpdateDataObjectMutationVariables = Types.Exact<{
  input: Types.MutationUpdateDataObjectInput;
}>;


export type MaterialMapsExporterUpdateDataObjectMutation = { __typename: 'Mutation', updateDataObject: { __typename: 'DataObject', legacyId: number } };

export type MaterialMapsExporterCreateJobMutationVariables = Types.Exact<{
  input: Types.MutationCreateJobInput;
}>;


export type MaterialMapsExporterCreateJobMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string, legacyId: number } };

export type MaterialMapsExporterUpdateJsonFileMutationVariables = Types.Exact<{
  input: Types.MutationUpdateJsonFileInput;
}>;


export type MaterialMapsExporterUpdateJsonFileMutation = { __typename: 'Mutation', updateJsonFile: { __typename: 'JsonFile', legacyId: number } };

export type MaterialMapsExporterCreateJsonFileAssignmentMutationVariables = Types.Exact<{
  input: Types.MutationCreateJsonFileAssignmentInput;
}>;


export type MaterialMapsExporterCreateJsonFileAssignmentMutation = { __typename: 'Mutation', createJsonFileAssignment: { __typename: 'JsonFileAssignment', id: string, jsonFile: { __typename: 'JsonFile', id: string, content: any, legacyId: number } } };

export type MaterialMapsExporterJsonFileAssignmentsQueryVariables = Types.Exact<{
  filter: Types.JsonFileAssignmentFilterInput;
}>;


export type MaterialMapsExporterJsonFileAssignmentsQuery = { __typename: 'Query', jsonFileAssignments: Array<{ __typename: 'JsonFileAssignment', id: string, assignmentType: Types.JsonFileAssignmentType, jsonFile: { __typename: 'JsonFile', id: string, content: any, legacyId: number } }> };

export const MapsExportTextureRevisionFragmentDoc = gql`
    fragment MapsExportTextureRevision on TextureRevision {
  legacyId
  width
  height
  displacement
  dataObject {
    legacyId
    width
    height
  }
  texture {
    type
  }
}
    `;
export const MaterialMapsExporterNodeFragmentDoc = gql`
    fragment MaterialMapsExporterNode on MaterialNode {
  id
  legacyId
  name
  textureRevision {
    ...MapsExportTextureRevision
  }
  textureSetRevision {
    ...MaterialGraphTextureSetRevision
  }
  parameters
}
    `;
export const MaterialMapsExporterConnectionFragmentDoc = gql`
    fragment MaterialMapsExporterConnection on MaterialConnection {
  source {
    id
    legacyId
  }
  sourceParameter
  destination {
    id
    legacyId
  }
  destinationParameter
}
    `;
export const MaterialRevisionForMapsExportFragmentDoc = gql`
    fragment MaterialRevisionForMapsExport on MaterialRevision {
  ...MaterialGraphRevision
  nodes {
    ...MaterialMapsExporterNode
  }
  connections {
    ...MaterialMapsExporterConnection
  }
}
    `;
export const MapsExportMaterialDetailsFragmentDoc = gql`
    fragment MapsExportMaterialDetails on Material {
  id
  legacyId
  articleId
  organization {
    legacyId
  }
  name
  tagAssignments(tagTypes: MaterialRange) {
    tag {
      name
    }
  }
}
    `;
export const NormalToDisplacementStepTextureRevisionDocument = gql`
    query NormalToDisplacementStepTextureRevision($id: ID, $legacyId: Int) {
  textureRevision(id: $id, legacyId: $legacyId) {
    createdByVersion
    texture {
      textureSet {
        textures {
          type
          revisions {
            ...MapsExportTextureRevision
            createdByVersion
          }
        }
      }
    }
  }
}
    ${MapsExportTextureRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NormalToDisplacementStepTextureRevisionGQL extends Apollo.Query<NormalToDisplacementStepTextureRevisionQuery, NormalToDisplacementStepTextureRevisionQueryVariables> {
    override document = NormalToDisplacementStepTextureRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialNodeForMapsExportDocument = gql`
    query materialNodeForMapsExport($id: ID) {
  materialNode(id: $id) {
    ...MaterialMapsExporterNode
    textureRevision {
      ...MapsExportTextureRevision
    }
  }
}
    ${MaterialMapsExporterNodeFragmentDoc}
${MapsExportTextureRevisionFragmentDoc}
${MaterialGraphTextureSetRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialNodeForMapsExportGQL extends Apollo.Query<MaterialNodeForMapsExportQuery, MaterialNodeForMapsExportQueryVariables> {
    override document = MaterialNodeForMapsExportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialRevisionForMapsExportDocument = gql`
    query materialRevisionForMapsExport($id: ID, $legacyId: Int) {
  material(id: $id, legacyId: $legacyId) {
    latestCyclesRevision {
      ...MaterialRevisionForMapsExport
    }
  }
}
    ${MaterialRevisionForMapsExportFragmentDoc}
${MaterialGraphRevisionFragmentDoc}
${MaterialMapsExporterNodeFragmentDoc}
${MapsExportTextureRevisionFragmentDoc}
${MaterialGraphTextureSetRevisionFragmentDoc}
${MaterialMapsExporterConnectionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialRevisionForMapsExportGQL extends Apollo.Query<MaterialRevisionForMapsExportQuery, MaterialRevisionForMapsExportQueryVariables> {
    override document = MaterialRevisionForMapsExportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialForMapsExportDocument = gql`
    query materialForMapsExport($id: ID, $legacyId: Int) {
  material(id: $id, legacyId: $legacyId) {
    ...MapsExportMaterialDetails
  }
}
    ${MapsExportMaterialDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialForMapsExportGQL extends Apollo.Query<MaterialForMapsExportQuery, MaterialForMapsExportQueryVariables> {
    override document = MaterialForMapsExportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialMapsExporterDeleteDataObjectDocument = gql`
    mutation materialMapsExporterDeleteDataObject($legacyId: Int!) {
  deleteDataObject(legacyId: $legacyId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialMapsExporterDeleteDataObjectGQL extends Apollo.Mutation<MaterialMapsExporterDeleteDataObjectMutation, MaterialMapsExporterDeleteDataObjectMutationVariables> {
    override document = MaterialMapsExporterDeleteDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialMapsExporterDeleteJsonFileDocument = gql`
    mutation materialMapsExporterDeleteJsonFile($legacyId: Int!) {
  deleteJsonFile(legacyId: $legacyId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialMapsExporterDeleteJsonFileGQL extends Apollo.Mutation<MaterialMapsExporterDeleteJsonFileMutation, MaterialMapsExporterDeleteJsonFileMutationVariables> {
    override document = MaterialMapsExporterDeleteJsonFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialMapsExporterUpdateDataObjectDocument = gql`
    mutation materialMapsExporterUpdateDataObject($input: MutationUpdateDataObjectInput!) {
  updateDataObject(input: $input) {
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialMapsExporterUpdateDataObjectGQL extends Apollo.Mutation<MaterialMapsExporterUpdateDataObjectMutation, MaterialMapsExporterUpdateDataObjectMutationVariables> {
    override document = MaterialMapsExporterUpdateDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialMapsExporterCreateJobDocument = gql`
    mutation materialMapsExporterCreateJob($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialMapsExporterCreateJobGQL extends Apollo.Mutation<MaterialMapsExporterCreateJobMutation, MaterialMapsExporterCreateJobMutationVariables> {
    override document = MaterialMapsExporterCreateJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialMapsExporterUpdateJsonFileDocument = gql`
    mutation materialMapsExporterUpdateJsonFile($input: MutationUpdateJsonFileInput!) {
  updateJsonFile(input: $input) {
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialMapsExporterUpdateJsonFileGQL extends Apollo.Mutation<MaterialMapsExporterUpdateJsonFileMutation, MaterialMapsExporterUpdateJsonFileMutationVariables> {
    override document = MaterialMapsExporterUpdateJsonFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialMapsExporterCreateJsonFileAssignmentDocument = gql`
    mutation materialMapsExporterCreateJsonFileAssignment($input: MutationCreateJsonFileAssignmentInput!) {
  createJsonFileAssignment(input: $input) {
    id
    jsonFile {
      id
      content
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialMapsExporterCreateJsonFileAssignmentGQL extends Apollo.Mutation<MaterialMapsExporterCreateJsonFileAssignmentMutation, MaterialMapsExporterCreateJsonFileAssignmentMutationVariables> {
    override document = MaterialMapsExporterCreateJsonFileAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialMapsExporterJsonFileAssignmentsDocument = gql`
    query materialMapsExporterJsonFileAssignments($filter: JsonFileAssignmentFilterInput!) {
  jsonFileAssignments(filter: $filter) {
    id
    assignmentType
    jsonFile {
      id
      content
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialMapsExporterJsonFileAssignmentsGQL extends Apollo.Query<MaterialMapsExporterJsonFileAssignmentsQuery, MaterialMapsExporterJsonFileAssignmentsQueryVariables> {
    override document = MaterialMapsExporterJsonFileAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }