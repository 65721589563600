<cm-routed-dialog [dialogSize]="DialogSize.Small">
    <ng-container cm-overlay-content>
        <cm-details-dialog-layout [loadError]="$loadError()" [isLoaded]="!!$item()">
            <ng-container cm-title>
                <cm-title-section
                    [title]="title"
                    [isLoaded]="$item() !== undefined"
                    [canEdit]="$can().update.template($item(), 'name')"
                    (onChanged)="updateItem({name: $event})"
                    (onClosed)="closeModal()"
                ></cm-title-section>
            </ng-container>
            <ng-container cm-buttons>
                @if ($item(); as template) {
                    <button class="cm-simple-window-header-button cm-item-menu" [matMenuTriggerFor]="menuOptions">
                        <i class="fas fa-ellipsis-v"></i>
                    </button>
                    <mat-menu #menuOptions="matMenu">
                        <button mat-menu-item (click)="removeGalleryImage()" [disabled]="!canRemoveGalleryImage">Remove gallery image</button>
                        <button [disabled]="!$can().create.template(template)" mat-menu-item (click)="copyTemplate(template)">
                            <i class="far fa-copy"></i>
                            <span>Make a copy</span>
                        </button>
                        @if (template.latestRevision) {
                            <button
                                [disabled]="!$can().delete.templateRevision({template})"
                                mat-menu-item
                                (click)="deleteTemplateRevision(template.latestRevision)"
                            >
                                <i class="far fa-trash"></i>
                                <span>Discard last revision</span>
                            </button>
                        }
                        <button mat-menu-item (click)="requestDeleteConfirmation()" [disabled]="!template || !$can().delete.item(template)">
                            Delete template
                        </button>
                    </mat-menu>
                }
            </ng-container>
            <ng-container cm-sidebar>
                @if ($item(); as template) {
                    @if ($can().update.templateRevision()) {
                        <div (click)="addOrEditTemplateRevision()">
                            @if (template.latestRevision; as latestRevision) {
                                <cm-button
                                    class="cm-action-button"
                                    matTooltip="Revision created by
              {{ latestRevision.createdBy?.name }}
              {{ latestRevision.createdAt | cmTimeAgo }}"
                                >
                                    <ng-container cm-left-icon>
                                        <i class="far fa-edit"></i>
                                    </ng-container>
                                    <ng-container cm-text>
                                        {{ getEditButtonLabel() }} ({{ template.revisions.length }}
                                        )
                                    </ng-container>
                                </cm-button>
                            } @else {
                                <ng-template>
                                    <cm-button class="cm-action-button">
                                        <ng-container cm-left-icon>
                                            <i class="far fa-edit"></i>
                                        </ng-container>
                                        <ng-container cm-text>
                                            {{ getEditButtonLabel() }}
                                        </ng-container>
                                    </cm-button>
                                </ng-template>
                            }
                        </div>
                    }
                    @if (template.latestRevision) {
                        <cm-button (click)="viewTemplate()" class="cm-action-button">
                            <ng-container cm-left-icon>
                                <i class="far fa-eye"></i>
                            </ng-container>
                            <ng-container cm-text> View</ng-container>
                        </cm-button>
                        <cm-button
                            (click)="renderThumbnail()"
                            class="cm-action-button"
                            matTooltip="Render template thumbnail"
                            [disabled]="renderThumbnailButtonDisabled"
                        >
                            <ng-container cm-left-icon>
                                <i class="far fa-image"></i>
                            </ng-container>
                            <ng-container cm-text> Render thumbnail</ng-container>
                            <ng-container cm-right-icon>
                                <i class="fa-regular fa-play"></i>
                            </ng-container>
                        </cm-button>
                    }

                    <cm-organization-select
                        [updateOrganization]="updateItem.bind(this)"
                        [organizationId]="template?.organization?.id ?? null"
                        [disabled]="template?.locked || !$can().update.template(template, 'organization')"
                    ></cm-organization-select>

                    @if ($can().update.template(template, "public")) {
                        <cm-boolean-label-select
                            [name]="'Visibility'"
                            [options]="Labels.Public"
                            [disabled]="!!template?.locked && !!template?.public"
                            [value]="template.public"
                            (onChanged)="updateItem({public: $event})"
                        >
                        </cm-boolean-label-select>
                    }
                    @if ($can().update.template(template, "type")) {
                        <mat-form-field class="cm-infos-field">
                            <mat-label>Type</mat-label>
                            <mat-select [ngModel]="template.type" (ngModelChange)="updateItem({templateType: $event})">
                                <mat-option [value]="Enums.TemplateType.Product">Product</mat-option>
                                <mat-option [value]="Enums.TemplateType.Room">Room</mat-option>
                                <mat-option [value]="Enums.TemplateType.Part">Part</mat-option>
                                @if (auth.isStaff()) {
                                    <mat-option [value]="Enums.TemplateType.Scene">Scene</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    }
                    <cm-tag-select
                        [assignedToId]="template.id"
                        [organizationId]="template.organization.id"
                        [contentTypeModel]="Enums.ContentTypeModel.Template"
                        [type]="Enums.TagType.ProductRange"
                    ></cm-tag-select>
                }
            </ng-container>
            <ng-container cm-main>
                @if ($item(); as template) {
                    <cm-section-content>
                        <cm-corners-layout cm-content>
                            <cm-gallery-image [item]="template" [multiThumbnailIds]="$multiThumbnailIds()"></cm-gallery-image>
                            <cm-copy-legacy-id-button cm-bottom-right [legacyId]="template.legacyId"></cm-copy-legacy-id-button>
                        </cm-corners-layout>
                    </cm-section-content>

                    @if ($can().read.template(template, "templates")) {
                        <cm-templates-section [item]="template" [title]="'Referencing templates'"></cm-templates-section>
                    }

                    @if ($can().read.template(template, "comment")) {
                        <cm-comment-section
                            [item]="template"
                            [canEdit]="$can().update.template(template, 'comment')"
                            (onChanged)="updateItemSubject.next($event)"
                        ></cm-comment-section>
                    }

                    @if ($can().read.template(template, "files")) {
                        <cm-files-section [item]="$item()"></cm-files-section>
                    }
                }
            </ng-container>
        </cm-details-dialog-layout>
    </ng-container>
</cm-routed-dialog>
