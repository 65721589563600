import * as Types from '../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DataObjectDetailsForDataObjectBatchApiCallFragment = { __typename: 'DataObject', id: string, legacyId: number, width?: number | null, height?: number | null, downloadUrl: string, mediaType?: string | null, originalFileName: string, imageColorSpace?: Types.ImageColorSpace | null, related: Array<{ __typename: 'DataObject', id: string, legacyId: number, width?: number | null, height?: number | null, downloadUrl: string, mediaType?: string | null, originalFileName: string }> };

export type GetDataObjectDetailsForDataObjectBatchApiCallQueryVariables = Types.Exact<{
  legacyIds?: Types.InputMaybe<Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']>;
}>;


export type GetDataObjectDetailsForDataObjectBatchApiCallQuery = { __typename: 'Query', dataObjects: Array<{ __typename: 'DataObject', id: string, legacyId: number, width?: number | null, height?: number | null, downloadUrl: string, mediaType?: string | null, originalFileName: string, imageColorSpace?: Types.ImageColorSpace | null, related: Array<{ __typename: 'DataObject', id: string, legacyId: number, width?: number | null, height?: number | null, downloadUrl: string, mediaType?: string | null, originalFileName: string }> } | null> };

export const DataObjectDetailsForDataObjectBatchApiCallFragmentDoc = gql`
    fragment DataObjectDetailsForDataObjectBatchApiCall on DataObject {
  id
  legacyId
  width
  height
  downloadUrl
  mediaType
  originalFileName
  imageColorSpace
  related {
    id
    legacyId
    width
    height
    downloadUrl
    mediaType
    originalFileName
  }
}
    `;
export const GetDataObjectDetailsForDataObjectBatchApiCallDocument = gql`
    query getDataObjectDetailsForDataObjectBatchApiCall($legacyIds: [Int!]) {
  dataObjects(filter: {legacyId: {in: $legacyIds}}, take: 1000) {
    ...DataObjectDetailsForDataObjectBatchApiCall
  }
}
    ${DataObjectDetailsForDataObjectBatchApiCallFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDataObjectDetailsForDataObjectBatchApiCallGQL extends Apollo.Query<GetDataObjectDetailsForDataObjectBatchApiCallQuery, GetDataObjectDetailsForDataObjectBatchApiCallQueryVariables> {
    override document = GetDataObjectDetailsForDataObjectBatchApiCallDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }