import {Observable} from "rxjs"
import {ReifiedMeshData} from "@cm/template-nodes"
import {AsyncCacheMap} from "@common/helpers/async-cache-map/async-cache-map"
import {clipAndOffsetMeshForDecal, WebAssemblyWorkerService} from "@app/template-editor/helpers/mesh-processing"

export class DecalMeshDataCache {
    private cache: AsyncCacheMap<string, ReifiedMeshData, [ReifiedMeshData, [number, number], number, [number, number], number]>

    constructor(private workerService: WebAssemblyWorkerService) {
        this.cache = new AsyncCacheMap((key, [mesh, uvCenter, uvRotation, size, offset]) => {
            return clipAndOffsetMeshForDecal(this.workerService, mesh, uvCenter, uvRotation, size, offset)
        })
    }

    clipAndOffsetMeshForDecal(
        mesh: ReifiedMeshData,
        uvCenter: [number, number],
        uvRotation: number,
        size: [number, number],
        offset: number,
    ): Observable<ReifiedMeshData> {
        const key = `${mesh.contentHash},${uvCenter[0]},${uvCenter[1]},${uvRotation},${size[0]},${size[1]},${offset}`
        return this.cache.get(key, [mesh, uvCenter, uvRotation, size, offset])
    }

    invalidate() {
        this.cache.clear()
    }
}
