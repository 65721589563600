import {Matrix4, Vector3} from "@cm/math"
import {ObjectLike} from "#template-nodes/node-types"
import {DimensionGuides} from "@cm/template-nodes"
import {BoundsEdgeDimension, BoundsEdgeNumber} from "#template-nodes/utils/scene-geometry-utils"

export const indexToDirection = (index: number): BoundsEdgeDimension => {
    switch (index) {
        case 0:
            return "x"
        case 1:
            return "y"
        case 2:
            return "z"
        default:
            throw new Error("Invalid index")
    }
}

export const initDimensionGuides = (name: string, parent: ObjectLike | null, offset: number = 5, boundsEdge: BoundsEdgeNumber = 1) => {
    return new DimensionGuides({
        name,
        visible: true,
        lockedTransform: Matrix4.identity().toArray(),
        parent,
        offset,
        guides: [
            {
                boundsEdge,
                show: true,
            },
            {
                boundsEdge,
                show: true,
            },
            {
                boundsEdge,
                show: true,
            },
        ],
        unitForDisplay: "cm",
    })
}

export const getBoundsEdgeTransform = (dir: BoundsEdgeDimension, number: BoundsEdgeNumber, offset: number): Matrix4 => {
    const translation = new Vector3(0, 0, 0)

    switch (dir) {
        case "x":
            translation.y = number < 2 ? -offset : offset
            translation.z = number % 2 ? offset : -offset
            break
        case "y":
            translation.x = number < 2 ? -offset : offset
            translation.y = number % 2 ? offset : -offset
            break
        case "z":
            translation.x = number < 2 ? -offset : offset
            translation.z = number % 2 ? offset : -offset
            break
    }

    const result = Matrix4.identity()
    result.setTranslation(translation)
    return result
}
