import {JobState, JobTaskState} from "@generated"

export const jobTaskIcon = (dbState?: JobTaskState) => {
    switch (dbState) {
        case JobTaskState.Init:
            return "\uf192"
        case JobTaskState.Runnable:
        case JobTaskState.Running:
            return "\uf144"
        case JobTaskState.Complete:
            return "\uf058"
        case JobTaskState.Cancelled:
            return "\uf28d"
        case JobTaskState.Failed:
            return "\uf06a"
        case JobTaskState.Fatal:
            return "\uf057"
        case JobTaskState.Queued:
            return "\ue0fe"
        case JobTaskState.Timeout:
            return "\uf017"
        default:
            return "\uf059"
    }
}

export const jobTaskNodeClass = (dbState?: JobTaskState): string => {
    switch (dbState) {
        case JobTaskState.Complete:
            return "success"
        case JobTaskState.Running:
            return "running"
        case JobTaskState.Queued:
            return "runnable"
        case JobTaskState.Cancelled:
            return "cancelled"
        case JobTaskState.Fatal:
        case JobTaskState.Failed:
        case JobTaskState.Timeout:
            return "error"
        case JobTaskState.Missing:
        case JobTaskState.Runnable:
        case JobTaskState.Init:
        default:
            return "init"
    }
}

export const jobTaskStateIconClass = (job?: {state?: JobTaskState | JobState} | null): string => {
    switch (job?.state) {
        case JobTaskState.Init:
            return "fa-solid fa-circle-dot"
        case JobTaskState.Runnable:
            return "fa-solid fa-circle-play"
        case JobTaskState.Running:
            return "fa-solid fa-circle-play"
        case JobTaskState.Complete:
            return "fa-solid fa-circle-check"
        case JobTaskState.Cancelled:
            return "fa-solid fa-circle-stop"
        case JobTaskState.Failed:
            return "fa-solid fa-circle-exclamation"
        case JobTaskState.Timeout:
            return "fa-solid fa-clock"
        case JobTaskState.Fatal:
            return "fa-solid fa-circle-xmark"
        case JobTaskState.Queued:
            return "fa-solid fa-circle-bolt"
        case JobTaskState.Missing:
        default:
            return "fa-solid fa-circle-question"
    }
}

export const jobStateIconClass = (job: {state: JobTaskState | JobState} | null): string => {
    switch (job?.state) {
        case JobState.Init:
            return "fa-solid fa-circle-dot"
        case JobState.Running:
        case JobState.Runnable:
            return "fa-solid fa-circle-play"
        case JobState.Complete:
            return "fa-solid fa-circle-check cm-icon-success"
        case JobState.Cancelled:
            return "fa-solid fa-circle-stop cm-icon-error"
        case JobState.Failed:
            return "fa-solid fa-circle-exclamation cm-icon-error"
        default:
            return "fa-solid fa-circle-question"
    }
}

export const jobPriorityIconClass = (job: {priority: number}): string => {
    if (job.priority > 0) {
        return "fa-solid fa-arrow-up"
    } else {
        return "fa-solid fa-circle-small"
    }
}
