@if ($data().items.length !== 0) {
    <div class="cm-jobs-table">
        <cm-table-cell class="cm-first-column" [header]="true"> ID </cm-table-cell>
        <cm-table-cell [centered]="true" [header]="true"> State </cm-table-cell>
        <cm-table-cell [header]="true"> Progress </cm-table-cell>
        <cm-table-cell [header]="true"> Name </cm-table-cell>
        <cm-table-cell [centered]="true" [header]="true"> Priority </cm-table-cell>
        <cm-table-cell [centered]="true" [header]="true"> Action </cm-table-cell>
        <cm-table-cell [header]="true"> Created by </cm-table-cell>
        <cm-table-cell [header]="true"> Created </cm-table-cell>
        <cm-table-cell [header]="true"> Started </cm-table-cell>
        <cm-table-cell [header]="true"> Organization </cm-table-cell>
        <cm-table-cell [header]="true"> Message </cm-table-cell>
        @for (row of $data().items; track row.trackId) {
            <div class="cm-ruler"></div>
            <div class="cm-table-row" (click)="rowClicked(row.data)">
                <cm-table-cell [item]="row">
                    <span class="cm-id cm-first-column">{{ row.data?.legacyId }}</span>
                </cm-table-cell>
                <cm-table-cell [item]="row" [centered]="true">
                    <cm-job-thumbnail [job]="row.data"></cm-job-thumbnail>
                </cm-table-cell>
                <cm-table-cell [item]="row" [tp]="progressTooltip(row.data)" tpVariation="light">
                    @if ((row.data?.state === JobState.Running || row.data?.state === JobState.Runnable) && row.data?.progress !== null) {
                        <mat-progress-bar mode="determinate" [value]="row.data?.progress"></mat-progress-bar>
                    }
                </cm-table-cell>
                <cm-table-cell [item]="row">
                    <cm-overflowable-text>
                        {{ row.data?.name ?? "-" }}
                    </cm-overflowable-text>
                </cm-table-cell>
                <cm-table-cell [item]="row" [centered]="true">
                    @if (row.data; as data) {
                        @if (data.state === JobState.Running || data.state === JobState.Runnable) {
                            @if (data.priority > 0) {
                                <button
                                    mat-button
                                    color="accent"
                                    (click)="$event.stopPropagation(); togglePriority(data, false)"
                                    tp="High priority"
                                    tpVariation="light"
                                >
                                    <i [class]="jobPriorityIconClass(data)"></i>
                                </button>
                            } @else {
                                <button
                                    mat-button
                                    color="primary"
                                    (click)="$event.stopPropagation(); togglePriority(data, true)"
                                    tp="Default priority"
                                    tpVariation="light"
                                >
                                    <i [class]="jobPriorityIconClass(data)"></i>
                                </button>
                            }
                        }
                    }
                </cm-table-cell>
                <cm-table-cell [item]="row" [centered]="true">
                    @if (row.data; as data) {
                        @switch (data.state) {
                            @case (JobState.Running) {
                                <button mat-button color="primary" (click)="$event.stopPropagation(); cancelJob(data)">Cancel</button>
                            }
                            @case (JobState.Runnable) {
                                <button mat-button color="primary" (click)="$event.stopPropagation(); cancelJob(data)">Cancel</button>
                            }
                            @case (JobState.Complete) {}
                            @case (JobState.Init) {
                                @if ($can().update.job()) {
                                    <button mat-button color="primary" (click)="$event.stopPropagation(); restartJob(data)">Start</button>
                                }
                            }
                            @default {
                                @if ($can().update.job()) {
                                    <button mat-button color="primary" (click)="$event.stopPropagation(); restartJob(data)">Restart</button>
                                }
                            }
                        }
                    }
                </cm-table-cell>
                <cm-table-cell [item]="row">
                    <cm-user-thumbnail [user]="row.data?.createdBy"></cm-user-thumbnail>
                    &nbsp;
                    {{ row.data?.createdBy?.name }}
                </cm-table-cell>
                <cm-table-cell [item]="row" [tp]="formatForDisplay(row.data?.createdAt, {shorten: false})" tpVariation="light">
                    <div class="cm-date-cell">
                        {{ row.data?.createdAt ? (row.data?.createdAt | cmTimeAgo) : "-" }}
                    </div>
                </cm-table-cell>
                <cm-table-cell [item]="row" [tp]="formatForDisplay(row.data?.started, {shorten: false})" tpVariation="light">
                    <div class="cm-date-cell">
                        {{ row.data?.started ? (row.data?.started | cmTimeAgo) : "-" }}
                    </div>
                </cm-table-cell>
                <cm-table-cell [item]="row">
                    <cm-overflowable-text>
                        {{ row.data?.organization?.name }}
                    </cm-overflowable-text>
                </cm-table-cell>
                <cm-table-cell [item]="row">
                    <cm-overflowable-text>
                        {{ row.data?.message }}
                    </cm-overflowable-text>
                </cm-table-cell>
            </div>
        }
    </div>
}
<cm-paginator></cm-paginator>
