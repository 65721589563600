import * as Types from '../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TextureEditorCreateJobMutationVariables = Types.Exact<{
  input: Types.MutationCreateJobInput;
}>;


export type TextureEditorCreateJobMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string, legacyId: number } };

export type TextureEditorCreateJobAssignmentMutationVariables = Types.Exact<{
  input: Types.MutationCreateJobAssignmentInput;
}>;


export type TextureEditorCreateJobAssignmentMutation = { __typename: 'Mutation', createJobAssignment: { __typename: 'JobAssignment', id: string } };

export type TextureEditorUpdateTextureSetRevisionMutationVariables = Types.Exact<{
  input: Types.MutationUpdateTextureSetRevisionInput;
}>;


export type TextureEditorUpdateTextureSetRevisionMutation = { __typename: 'Mutation', updateTextureSetRevision: { __typename: 'TextureSetRevision', id: string } };

export type TextureEditorCancelProcessingJobMutationVariables = Types.Exact<{
  jobId: Types.Scalars['ID']['input'];
}>;


export type TextureEditorCancelProcessingJobMutation = { __typename: 'Mutation', cancelJob: { __typename: 'Job', id: string } };

export const TextureEditorCreateJobDocument = gql`
    mutation textureEditorCreateJob($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureEditorCreateJobGQL extends Apollo.Mutation<TextureEditorCreateJobMutation, TextureEditorCreateJobMutationVariables> {
    override document = TextureEditorCreateJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureEditorCreateJobAssignmentDocument = gql`
    mutation textureEditorCreateJobAssignment($input: MutationCreateJobAssignmentInput!) {
  createJobAssignment(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureEditorCreateJobAssignmentGQL extends Apollo.Mutation<TextureEditorCreateJobAssignmentMutation, TextureEditorCreateJobAssignmentMutationVariables> {
    override document = TextureEditorCreateJobAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureEditorUpdateTextureSetRevisionDocument = gql`
    mutation textureEditorUpdateTextureSetRevision($input: MutationUpdateTextureSetRevisionInput!) {
  updateTextureSetRevision(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureEditorUpdateTextureSetRevisionGQL extends Apollo.Mutation<TextureEditorUpdateTextureSetRevisionMutation, TextureEditorUpdateTextureSetRevisionMutationVariables> {
    override document = TextureEditorUpdateTextureSetRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureEditorCancelProcessingJobDocument = gql`
    mutation textureEditorCancelProcessingJob($jobId: ID!) {
  cancelJob(id: $jobId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureEditorCancelProcessingJobGQL extends Apollo.Mutation<TextureEditorCancelProcessingJobMutation, TextureEditorCancelProcessingJobMutationVariables> {
    override document = TextureEditorCancelProcessingJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }