import {Component, input} from "@angular/core"

@Component({
    selector: "cm-placeholder",
    imports: [],
    templateUrl: "./placeholder.component.html",
    styleUrl: "./placeholder.component.scss",
})
export class PlaceholderComponent {
    readonly $dark = input(false, {alias: "dark"})
    readonly $loading = input(true, {alias: "loading"})
    readonly $format = input<"round" | "rect" | "circle">("round", {alias: "format"})
}
