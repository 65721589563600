import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type BackendArGenerationTemplateRevisionFragment = { __typename: 'TemplateRevision', id: string, graph?: any | null, template: { __typename: 'Template', id: string, organizationId: string }, gltfAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, related: Array<{ __typename: 'DataObject', id: string }> } }> };

export type GetTemplateRevisionForBackendArGenerationQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  assignmentKey: Types.StringFilterInput;
}>;


export type GetTemplateRevisionForBackendArGenerationQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', id: string, graph?: any | null, template: { __typename: 'Template', id: string, organizationId: string }, gltfAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, related: Array<{ __typename: 'DataObject', id: string }> } }> } };

export const BackendArGenerationTemplateRevisionFragmentDoc = gql`
    fragment BackendArGenerationTemplateRevision on TemplateRevision {
  id
  graph
  template {
    id
    organizationId
  }
  gltfAssignments: dataObjectAssignments(
    filter: {assignmentKey: $assignmentKey, assignmentType: CachedTemplateGltf}
  ) {
    id
    dataObject {
      id
      related {
        id
      }
    }
  }
}
    `;
export const GetTemplateRevisionForBackendArGenerationDocument = gql`
    query getTemplateRevisionForBackendArGeneration($id: ID!, $assignmentKey: StringFilterInput!) {
  templateRevision(id: $id) {
    ...BackendArGenerationTemplateRevision
  }
}
    ${BackendArGenerationTemplateRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateRevisionForBackendArGenerationGQL extends Apollo.Query<GetTemplateRevisionForBackendArGenerationQuery, GetTemplateRevisionForBackendArGenerationQueryVariables> {
    override document = GetTemplateRevisionForBackendArGenerationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }