<i class="far fa-gear" matTooltip="Editor Settings" [matMenuTriggerFor]="editorConfigurationMenu">
    <mat-menu #editorConfigurationMenu>
        <div class="cm-editor-settings-menu">
            <mat-button-toggle-group
                class="cm-toggle-group"
                [value]="sceneManagerService.$lodType()"
                (change)="sceneManagerService.$lodType.set($event.value)"
                [hideSingleSelectionIndicator]="true"
            >
                <mat-button-toggle [matTooltip]="getLodTypeDescription('web')" [value]="'web'" (click)="$event.stopPropagation()">{{
                    getLodTypeDescription("web")
                }}</mat-button-toggle>
                <mat-button-toggle [matTooltip]="getLodTypeDescription('ar')" [value]="'ar'" (click)="$event.stopPropagation()">{{
                    getLodTypeDescription("ar")
                }}</mat-button-toggle>
                <mat-button-toggle [matTooltip]="getLodTypeDescription('pathTraced')" [value]="'pathTraced'" (click)="$event.stopPropagation()">{{
                    getLodTypeDescription("pathTraced")
                }}</mat-button-toggle>
            </mat-button-toggle-group>
            @if (!threeSceneManagerService.$hasIllumination()) {
                <cm-toggle
                    (click)="$event.stopPropagation()"
                    [toggle]="threeSceneManagerService.$ambientLight()"
                    (toggleChange)="threeSceneManagerService.$ambientLight.set($event)"
                >
                    <ng-container cm-text>Ambient Light</ng-container>
                </cm-toggle>
            }
            <cm-toggle
                (click)="$event.stopPropagation()"
                [toggle]="threeSceneManagerService.$showGrid()"
                (toggleChange)="threeSceneManagerService.$showGrid.set($event)"
            >
                <ng-container cm-text>Show Grid</ng-container>
            </cm-toggle>
            <cm-toggle
                (click)="$event.stopPropagation()"
                [toggle]="threeSceneManagerService.$showProgressiveLightMapDebug()"
                (toggleChange)="threeSceneManagerService.$showProgressiveLightMapDebug.set($event)"
            >
                <ng-container cm-text>Debug Shadows</ng-container>
            </cm-toggle>
            <cm-toggle
                (click)="$event.stopPropagation()"
                [toggle]="threeSceneManagerService.$showUnselectedCurves()"
                (toggleChange)="threeSceneManagerService.$showUnselectedCurves.set($event)"
            >
                <ng-container cm-text>Show All Curves </ng-container>
            </cm-toggle>
            <cm-toggle
                (click)="$event.stopPropagation()"
                [toggle]="localPreviewRenderingService.$enable()"
                (toggleChange)="localPreviewRenderingService.$enable.set($event)"
            >
                <ng-container cm-text>Local Rendering Preview</ng-container>
            </cm-toggle>
            @if (localPreviewRenderingService.$enable()) {
                <div class="cm-menu-section">
                    @if (localPreviewRenderingService.$connected()) {
                        <div class="cm-status-text cm-connected"><i class="fas fa-check-circle"></i> Connected</div>
                    } @else {
                        <div class="cm-status-text cm-disconnected"><i class="fas fa-times-circle"></i> Not Connected</div>
                    }

                    <div class="cm-truncated-label-wrapper">
                        <div class="cm-status-text cm-truncated-label" [title]="localPreviewRenderingService.$statusMessage()">
                            Status: {{ localPreviewRenderingService.$statusMessage() }}
                        </div>
                    </div>

                    <cm-toggle
                        (click)="$event.stopPropagation()"
                        [toggle]="localPreviewRenderingService.$highQuality()"
                        (toggleChange)="localPreviewRenderingService.$highQuality.set($event)"
                    >
                        <ng-container cm-text>Full res. textures</ng-container>
                    </cm-toggle>

                    <cm-toggle
                        (click)="$event.stopPropagation()"
                        [toggle]="localPreviewRenderingService.$useGPU()"
                        (toggleChange)="localPreviewRenderingService.$useGPU.set($event)"
                    >
                        <ng-container cm-text>Use GPU</ng-container>
                    </cm-toggle>

                    <mat-form-field appearance="outline" subscriptSizing="dynamic">
                        <mat-label>Preview Pass</mat-label>
                        <mat-select
                            class="cm-small-select"
                            [ngModel]="localPreviewRenderingService.$pass()"
                            (ngModelChange)="localPreviewRenderingService.$pass.set($event)"
                        >
                            @for (option of localPreviewRenderingService.availablePasses; track option.value) {
                                <mat-option [value]="option.value">{{ option.name }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
            }
            <cm-toggle
                (click)="$event.stopPropagation()"
                [toggle]="blenderConnectorService.$enable()"
                (toggleChange)="blenderConnectorService.$enable.set($event)"
            >
                <ng-container cm-text>Connect to Blender</ng-container>
            </cm-toggle>
            @if (blenderConnectorService.$enable()) {
                <div class="cm-menu-section">
                    @if (blenderConnectorService.$state() === "Connected") {
                        <div class="cm-status-text cm-connected"><i class="fas fa-check-circle"></i> Connected</div>
                    } @else {
                        <div class="cm-status-text cm-disconnected"><i class="fas fa-times-circle"></i> Not Connected</div>
                    }
                </div>
            }
            <cm-toggle
                (click)="$event.stopPropagation()"
                [toggle]="sceneManagerService.$reviewMode() !== undefined"
                (toggleChange)="sceneManagerService.$reviewMode.set($event ? 'wireframe' : undefined)"
            >
                <ng-container cm-text>Review</ng-container>
            </cm-toggle>
            @if (sceneManagerService.$reviewMode(); as reviewMode) {
                <mat-button-toggle-group
                    class="cm-toggle-group"
                    [value]="reviewMode"
                    (change)="sceneManagerService.$reviewMode.set($event.value)"
                    [hideSingleSelectionIndicator]="true"
                >
                    <mat-button-toggle matTooltip="Wireframe" [value]="'wireframe'" (click)="$event.stopPropagation()">Wireframe</mat-button-toggle>
                    <mat-button-toggle matTooltip="UV Test" [value]="'uvTest1'" (click)="$event.stopPropagation()">UV Test</mat-button-toggle>
                    <mat-button-toggle matTooltip="UV Test 2" [value]="'uvTest2'" (click)="$event.stopPropagation()">UV Test 2</mat-button-toggle>
                </mat-button-toggle-group>

                <mat-button-toggle-group
                    class="cm-toggle-group"
                    [value]="reviewMode === 'wireframe' ? sceneManagerService.$reviewFocus() : sceneManagerService.$reviewedUvChannel()"
                    (change)="sceneManagerService.$reviewFocus.set($event.value)"
                    [hideSingleSelectionIndicator]="true"
                >
                    @if (reviewMode === "wireframe") {
                        <mat-button-toggle matTooltip="Faces" [value]="'faces'" (click)="$event.stopPropagation()">Faces</mat-button-toggle>
                    }
                    <mat-button-toggle matTooltip="UV" [value]="'uv0'" (click)="$event.stopPropagation()">UV</mat-button-toggle>
                    <mat-button-toggle matTooltip="UV1" [value]="'uv1'" (click)="$event.stopPropagation()">UV1</mat-button-toggle>
                    <mat-button-toggle matTooltip="UV2" [value]="'uv2'" (click)="$event.stopPropagation()">UV2</mat-button-toggle>
                </mat-button-toggle-group>
            }
        </div>
    </mat-menu>
</i>
@if (sceneManagerService.$cameras().length > 0) {
    <cm-toggle
        (click)="$event.stopPropagation()"
        [toggle]="$selectedCameraId() !== undefined"
        (toggleChange)="selectedCameraIdChanged.emit($event ? sceneManagerService.$cameras()[0].id : undefined)"
    >
        <ng-container cm-text>Camera</ng-container>
    </cm-toggle>
}
