import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MaterialsCardInputFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type MaterialsCardFragment = { __typename: 'Material', id: string, nextActor: Types.NextActor, state: Types.MaterialState, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, organization?: { __typename: 'Organization', id: string } | null, tagAssignments: Array<{ __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string, legacyId: number } | null } }>, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number } };

export type MaterialsCardDataQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type MaterialsCardDataQuery = { __typename: 'Query', materials: Array<{ __typename: 'Material', id: string, nextActor: Types.NextActor, state: Types.MaterialState, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, organization?: { __typename: 'Organization', id: string } | null, tagAssignments: Array<{ __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string, legacyId: number } | null } }>, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number } } | null> };

export const MaterialsCardInputFragmentDoc = gql`
    fragment MaterialsCardInput on Material {
  id
  legacyId
  name
  organization {
    id
    name
  }
  galleryImage {
    id
  }
}
    `;
export const MaterialsCardFragmentDoc = gql`
    fragment MaterialsCard on Material {
  __typename
  id
  currentUserAssignment {
    id
    firstName
    lastName
    galleryImage {
      id
    }
  }
  organization {
    id
  }
  nextActor
  state
  tagAssignments {
    id
    tag {
      id
      legacyId
      createdBy {
        id
      }
      name
      description
      color
      type
      organization {
        id
        legacyId
      }
    }
  }
  taskInfos {
    inProgress
    waitingForFeedback
    completed
    archived
  }
}
    `;
export const MaterialsCardDataDocument = gql`
    query materialsCardData($ids: [ID!]) {
  materials(filter: {id: {in: $ids}}, take: 10000) {
    ...MaterialsCard
  }
}
    ${MaterialsCardFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialsCardDataGQL extends Apollo.Query<MaterialsCardDataQuery, MaterialsCardDataQueryVariables> {
    override document = MaterialsCardDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }