import {ThreeAnnotationObject} from "@app/template-editor/helpers/three-annotation"
import {ThreeAnnotationBase} from "@app/template-editor/helpers/three-annotation-base"
import {anyDifference, arrayDifferent} from "@template-editor/helpers/change-detection"
import {ThreeObject, mathIsEqual, setThreeObjectPart} from "@template-editor/helpers/three-object"
import {ThreeSceneManagerService} from "@template-editor/services/three-scene-manager.service"

import {Three as THREE, Line2, LineGeometry, LineMaterial} from "@cm/material-nodes/three"
import {TemplateNodePart} from "../services/scene-manager.service"
import {DimensionGuideInfo, SceneNodes} from "@cm/template-nodes"
import {Vector3} from "@cm/math"
import {AnnotationStyleRegistry} from "@app/template-editor/helpers/annotation-style-registry"

const css = `
.cm-dimension-guide-label {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000080;
    box-shadow: 0 0 0 2px #ffffff80;
    font-size: 14px;
    font-weight: bold;
    color: white;
    border-radius: 8px;
    padding: 3px;
}
.cm-dimension-guide-label-container {
    position: absolute;
    user-select: none;
    pointer-events: auto;
}
.cm-annotation-container.editor {
    cursor: pointer;
}
.cm-dimension-guide-label-container.editor:hover .cm-dimension-guide-label {
    color: #40c4ff;
    box-shadow: 0 0 0 2px #40c4ff;
}`

AnnotationStyleRegistry.getInstance().registerStyles("dimension-guide-styles", css)

class ThreeDimensionGuideLabel extends ThreeAnnotationBase {
    private readonly labelContainerElement: HTMLDivElement
    private readonly labelElement: HTMLDivElement
    private readonly descriptionElem: HTMLDivElement

    constructor(
        protected override threeSceneManagerService: ThreeSceneManagerService,
        protected override templateNodePart: TemplateNodePart,
    ) {
        super(threeSceneManagerService, templateNodePart)

        this.labelElement = document.createElement("div")
        this.labelElement.className = "cm-dimension-guide-label"
        this.descriptionElem = document.createElement("div")

        this.labelContainerElement = document.createElement("div")
        this.labelContainerElement.className = "cm-dimension-guide-label-container"
        this.labelContainerElement.appendChild(this.labelElement)

        if (threeSceneManagerService.$displayMode() === "editor") this.labelContainerElement.classList.add("editor")

        this.init()
    }

    protected updateStyle(highlighted: boolean, selected: boolean): void {
        const backgroundColor = highlighted ? "red" : ""
        const pointerEvents = selected ? "none" : "auto"

        if (this.labelElement.style.backgroundColor !== backgroundColor || this.labelContainerElement.style.pointerEvents !== pointerEvents) {
            this.labelElement.style.backgroundColor = backgroundColor
            this.labelContainerElement.style.pointerEvents = pointerEvents
            this.dispose(false)
        }
    }

    isConcealable() {
        return true
    }

    updateLabels(label: string, description: string) {
        this.labelElement.innerHTML = label
        this.descriptionElem.innerHTML = description
        this.dispose(false)
    }

    protected getContainer(): HTMLDivElement {
        return this.labelContainerElement
    }

    getTranslationOffset() {
        return {x: "10px", y: "-50%"}
    }

    getOcclusionOpacity(): number {
        return 0
    }
}

class ThreeDirectionIndicator extends THREE.Group {
    private lineStartEnd: Float32Array
    private lineGeometry: LineGeometry
    private lineMaterial: LineMaterial
    private line: Line2
    private startArrow: THREE.Mesh
    private endArrow: THREE.Mesh
    private arrowGeometry: THREE.CylinderGeometry
    private arrowMaterial: THREE.MeshBasicMaterial
    private arrowLength: number

    constructor(color: number, arrowLength: number, arrowWidth: number) {
        super()
        this.arrowLength = arrowLength

        this.lineStartEnd = new Float32Array(6)
        this.lineGeometry = new LineGeometry()
        this.lineMaterial = new LineMaterial({
            color: color,
            linewidth: 2,
            resolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
        })

        this.line = new Line2(this.lineGeometry, this.lineMaterial)
        this.line.raycast = () => null
        this.add(this.line)

        this.arrowGeometry = new THREE.CylinderGeometry(0, arrowWidth, this.arrowLength, 5)
        this.arrowMaterial = new THREE.MeshBasicMaterial({color})

        this.startArrow = new THREE.Mesh(this.arrowGeometry, this.arrowMaterial)
        this.endArrow = new THREE.Mesh(this.arrowGeometry.clone(), this.arrowMaterial)

        this.add(this.startArrow)
        this.add(this.endArrow)

        window.addEventListener("resize", () => {
            this.lineMaterial.resolution.set(window.innerWidth, window.innerHeight)
        })
    }

    setPoints(start: Vector3, end: Vector3) {
        const direction = end.sub(start).normalized()

        const adjustedStart = start.add(direction.mul(this.arrowLength / 2))
        const adjustedEnd = end.sub(direction.mul(this.arrowLength / 2))

        this.lineStartEnd.set([...adjustedStart.toArray(), ...adjustedEnd.toArray()])
        this.lineGeometry.setPositions(this.lineStartEnd)
        this.line.computeLineDistances()

        this.startArrow.position.set(adjustedStart.x, adjustedStart.y, adjustedStart.z)
        this.endArrow.position.set(adjustedEnd.x, adjustedEnd.y, adjustedEnd.z)

        const threeDirection = new THREE.Vector3(direction.x, direction.y, direction.z)
        this.startArrow.quaternion.setFromUnitVectors(new THREE.Vector3(0, -1, 0), threeDirection)
        this.endArrow.quaternion.setFromUnitVectors(new THREE.Vector3(0, 1, 0), threeDirection)
    }

    dispose() {
        this.lineGeometry.dispose()
        this.lineMaterial.dispose()
        this.arrowGeometry.dispose()
        this.arrowMaterial.dispose()
    }
}

class DimensionGuideComponents extends THREE.Object3D {
    protected start: ThreeAnnotationObject | undefined
    protected end: ThreeAnnotationObject | undefined
    protected label: ThreeDimensionGuideLabel | undefined
    protected directionIndicator: ThreeDirectionIndicator
    protected axisIndex: number

    constructor(
        private threeSceneManagerService: ThreeSceneManagerService,
        lineColor: number,
        arrowLength: number,
        arrowWidth: number,
        sceneNode: SceneNodes.DimensionGuides,
        axisIndex: number,
    ) {
        super()
        this.axisIndex = axisIndex
        this.directionIndicator = new ThreeDirectionIndicator(lineColor, arrowLength, arrowWidth)
        this.add(this.directionIndicator)
        this.setupLabel(sceneNode, axisIndex)
    }

    private setupLabel(sceneNode: SceneNodes.DimensionGuides, axisIndex: number) {
        if (this.start) return

        const {sceneManagerService} = this.threeSceneManagerService
        const nodePart = sceneManagerService.sceneNodePartToTemplateNodePart({
            sceneNode,
            part: "root",
        })

        if (!nodePart) throw new Error("Template node part not found")

        if (this.label === undefined) {
            this.label = new ThreeDimensionGuideLabel(this.threeSceneManagerService, nodePart)
            this.add(this.label)
        }
    }

    public update(guide: DimensionGuideInfo) {
        const start = guide.start
        const end = guide.end

        if (!this.label) throw new Error("Label not initialized")

        this.label.position.copy(start.add(end.sub(start).div(2)))
        this.label.updateLabels(guide.label, "Dimension Guide")
        this.directionIndicator.setPoints(start, end)

        if (this.start && this.end) {
            this.start!.position.copy(start)
            this.end!.position.copy(end)
        }
    }

    public dispose() {
        this.start?.dispose(true)
        this.end?.dispose(true)
        this.directionIndicator.dispose()
    }
}

export class ThreeDimensionGuides extends ThreeObject<SceneNodes.DimensionGuides> {
    protected override renderObject = new THREE.Group()

    private components: DimensionGuideComponents[] = []
    private lineColor = 0x353d37
    private arrowLength = 2.5
    private arrowWidth = 0.6

    constructor(threeSceneManagerService: ThreeSceneManagerService, onAsyncUpdate: () => void) {
        super(threeSceneManagerService, onAsyncUpdate)
        setThreeObjectPart(this.getRenderObject(), this)
    }

    private getOrCreateComponent(index: number, sceneNode: SceneNodes.DimensionGuides): DimensionGuideComponents {
        if (this.components[index]) return this.components[index]

        const component = new DimensionGuideComponents(this.threeSceneManagerService, this.lineColor, this.arrowLength, this.arrowWidth, sceneNode, index)
        this.components[index] = component
        return component
    }

    private setupComponents(sceneNode: SceneNodes.DimensionGuides) {
        while (this.components.length > sceneNode.guides.length) {
            const component = this.components.pop()
            if (component) {
                this.renderObject.remove(component)
                component.dispose()
            }
        }

        sceneNode.guides.forEach((guide, index) => {
            if (guide) {
                const component = this.getOrCreateComponent(index, sceneNode)
                this.renderObject.add(component)
            } else {
                if (this.components[index]) {
                    this.renderObject.remove(this.components[index])
                }
            }
        })
    }

    private updateComponents(sceneNode: SceneNodes.DimensionGuides) {
        sceneNode.guides.forEach((guide, index) => {
            if (guide && this.components[index]) this.components[index].update(guide)
        })
    }

    override setup(sceneNode: SceneNodes.DimensionGuides) {
        return anyDifference([
            arrayDifferent(
                sceneNode.guides,
                this.parameters?.guides,
                (valueA, valueB) => {
                    return (
                        valueA == valueB && mathIsEqual(valueA?.start, valueB?.end) && mathIsEqual(valueA?.end, valueB?.end) && valueA?.label === valueB?.label
                    )
                },
                ({}) => {
                    this.setupComponents(sceneNode)
                    this.updateComponents(sceneNode)
                },
            ),
        ])
    }

    override dispose() {
        this.components.forEach((component) => component.dispose())
        this.components = []
    }
}
