/**
 * locateFile is executed by the emscripten runtime to resolve the location of files.
 * It allows loading of wasm-files from our firebase domain, while embedding a web
 * component to a 3rd party website. Without this, the wasm files would be loaded from the 3rd party domain.
 * https://emscripten.org/docs/api_reference/module.html
 * https://github.com/emscripten-core/emscripten/issues/10434
 */
import {environment} from "@environment"

export const locateFile = (path: string) => `${environment.configuratorDeployUrl}/assets/${path}`
