import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DataObjectThumbnailFragment = { __typename: 'DataObject', id: string, mediaType?: string | null, state: Types.DataObjectState, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, thumbnailProcessingJob?: { __typename: 'Job', state: Types.JobState } | null };

export type QueryDataObjectThumbnailsQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  resolution: Types.DownloadResolution;
}>;


export type QueryDataObjectThumbnailsQuery = { __typename: 'Query', dataObjects: Array<{ __typename: 'DataObject', id: string, mediaType?: string | null, state: Types.DataObjectState, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, thumbnailProcessingJob?: { __typename: 'Job', state: Types.JobState } | null } | null> };

export const DataObjectThumbnailFragmentDoc = gql`
    fragment DataObjectThumbnail on DataObject {
  id
  mediaType
  state
  thumbnail(fileType: jpg, resolution: $resolution) {
    downloadUrl
  }
  thumbnailProcessingJob {
    state
  }
}
    `;
export const QueryDataObjectThumbnailsDocument = gql`
    query queryDataObjectThumbnails($ids: [ID!], $resolution: DownloadResolution!) {
  dataObjects(filter: {id: {in: $ids}}, take: 10000) {
    ...DataObjectThumbnail
  }
}
    ${DataObjectThumbnailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryDataObjectThumbnailsGQL extends Apollo.Query<QueryDataObjectThumbnailsQuery, QueryDataObjectThumbnailsQueryVariables> {
    override document = QueryDataObjectThumbnailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }