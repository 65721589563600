import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type JobsHelperStateUpdateQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type JobsHelperStateUpdateQuery = { __typename: 'Query', jobs: Array<{ __typename: 'Job', id: string, message?: string | null, progress?: number | null, started?: string | null, state: Types.JobState } | null> };

export const JobsHelperStateUpdateDocument = gql`
    query jobsHelperStateUpdate($ids: [ID!]!) {
  jobs(filter: {id: {in: $ids}}) {
    id
    message
    progress
    started
    state
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class JobsHelperStateUpdateGQL extends Apollo.Query<JobsHelperStateUpdateQuery, JobsHelperStateUpdateQueryVariables> {
    override document = JobsHelperStateUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }