import {Component, input, model, output} from "@angular/core"

@Component({
    selector: "cm-action-panel",
    templateUrl: "./action-panel.component.html",
    styleUrls: ["./action-panel.component.scss"],
})
export class ActionPanelComponent {
    readonly $arrowRight = input("0", {alias: "arrowRight"})
    readonly $minWidth = input("50px", {alias: "minWidth"})
    readonly $maxWidth = input("500px", {alias: "maxWidth"})
    readonly $marginRight = input("0", {alias: "marginRight"})
    readonly $visible = model(false, {alias: "visible"})

    readonly onShow = output<void>()
    readonly onHide = output<void>()

    show(): void {
        this.$visible.set(true)
        this.onShow.emit()
    }

    hide(): void {
        this.$visible.set(false)
        this.onHide.emit()
    }
}
