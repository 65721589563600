<div class="cm-section-title">Material outputs</div>
@if ($material(); as material) {
    @if (material.latestCyclesRevision) {
        @if ($can().read.material(material, "outputs")) {
            <cm-dropdown-button [class.cm-unavailable]="$flatImageDataObjectIds().length === 0">
                <ng-container cm-left-icon>
                    <i class="fa-regular fa-image"></i>
                </ng-container>
                <ng-container cm-text> Flat thumbnails ({{ $flatImageDataObjectIds().length }})</ng-container>
                <ng-container cm-dropdown-content>
                    <div class="cm-options-list">
                        @for (flatThumbnailButtonState of $flatThumbnailButtonsState(); track flatThumbnailButtonState.label) {
                            <cm-material-output-button [imageDownloadMenu]="imageDownloadMenu" [props]="flatThumbnailButtonState"></cm-material-output-button>
                        }
                    </div>
                </ng-container>
            </cm-dropdown-button>

            <cm-material-output-button
                [debugMenu]="debugMenu"
                [imageDownloadMenu]="tileableImageIsZipFile ? null : imageDownloadMenu"
                [isDebugEnabled]="isDebugEnabled"
                [props]="$tileableImageButtonState()"
            ></cm-material-output-button>

            <cm-material-output-button [imageDownloadMenu]="imageDownloadMenu" [props]="$shaderBallButtonState()"></cm-material-output-button>

            <cm-dropdown-button [class.cm-unavailable]="$defaultPbrExports().length === 0">
                <ng-container cm-left-icon>
                    <i class="fa-solid fa-box-archive"></i>
                </ng-container>
                <ng-container cm-text>PBR exports ({{ $defaultPbrExports().length + $customPbrExports().length }})</ng-container>
                <ng-container cm-dropdown-content>
                    <div class="cm-options-list">
                        <div class="cm-export-section-headline">Default exports</div>
                        @for (defaultPbrExportsButtonState of $defaultPbrExportsButtonStates(); track defaultPbrExportsButtonState.label) {
                            @if (defaultPbrExportsButtonState.exportSummary && defaultPbrExportsButtonState.sourceInfo) {
                                <cm-dropdown-inline-button>
                                    <ng-container cm-inline-button>
                                        <cm-material-output-button [props]="defaultPbrExportsButtonState"></cm-material-output-button>
                                    </ng-container>
                                    <ng-container cm-dropdown-content>
                                        <cm-material-output-pbr-export-detail
                                            [exportSummary]="defaultPbrExportsButtonState.exportSummary"
                                            [sourceInfo]="defaultPbrExportsButtonState.sourceInfo"
                                        ></cm-material-output-pbr-export-detail>
                                    </ng-container>
                                </cm-dropdown-inline-button>
                            } @else {
                                <cm-material-output-button [props]="defaultPbrExportsButtonState"></cm-material-output-button>
                            }
                        }
                        @if ($customPbrExportsButtonStates().length > 0) {
                            <div class="cm-export-section-headline">Custom exports</div>
                            @for (customPbrExportsButtonState of $customPbrExportsButtonStates(); track $index) {
                                @if (customPbrExportsButtonState.exportSummary && customPbrExportsButtonState.sourceInfo) {
                                    <cm-dropdown-inline-button>
                                        <ng-container cm-inline-button>
                                            <cm-material-output-button [props]="customPbrExportsButtonState"></cm-material-output-button>
                                        </ng-container>
                                        <ng-container cm-dropdown-content>
                                            <cm-material-output-pbr-export-detail
                                                [exportSummary]="customPbrExportsButtonState.exportSummary"
                                                [sourceInfo]="customPbrExportsButtonState.sourceInfo"
                                            ></cm-material-output-pbr-export-detail>
                                        </ng-container>
                                    </cm-dropdown-inline-button>
                                } @else {
                                    <cm-material-output-button [props]="customPbrExportsButtonState"></cm-material-output-button>
                                }
                            }
                        }
                        <cm-dropdown-button>
                            <ng-container cm-left-icon>
                                <i class="fa-solid fa-sliders-h"></i>
                            </ng-container>
                            <ng-container cm-text> Custom exports ({{ $customPbrExports().length }})</ng-container>
                            <ng-container cm-dropdown-content>
                                <cm-material-output-custom-pbr-export-form [onSubmit]="generateCustomPbrExport"></cm-material-output-custom-pbr-export-form>
                            </ng-container>
                        </cm-dropdown-button>
                    </div>
                </ng-container>
            </cm-dropdown-button>
        } @else {
            <cm-hint>
                <ng-container cm-text> Outputs for materials outside your organizations are not visible to you. </ng-container>
            </cm-hint>
        }
    } @else {
        <cm-hint>
            <ng-container cm-text>
                @if (material.textureGroup) {
                    Please create a new material and <b>save it</b>. The material output options will only appear then.
                } @else {
                    Please scan a new fabric to get started.
                }
            </ng-container>
        </cm-hint>
    }
} @else {
    <cm-placeholder [dark]="true"></cm-placeholder>
    <cm-placeholder [dark]="true"></cm-placeholder>
    <cm-placeholder [dark]="true"></cm-placeholder>
    <cm-placeholder [dark]="true"></cm-placeholder>
}

<mat-menu #debugMenu="matMenu">
    <ng-template matMenuContent let-onClick="onClick">
        <div class="cm-menu-container">
            <div>Rendering options:</div>
            <div class="cm-menu-options">
                <button class="cm-menu-option cm-simple-button" (click)="onClick?.({useCloud: true, useGpu: true})">Cloud GPU</button>
                <button class="cm-menu-option cm-simple-button" (click)="onClick?.({useCloud: false, useGpu: false})">Local CPU</button>
            </div>
        </div>
    </ng-template>
</mat-menu>

<mat-menu #imageDownloadMenu="matMenu">
    <ng-template matMenuContent let-dataObject="dataObject">
        <div class="cm-menu-container">
            <div>Download as:</div>
            <div class="cm-menu-options">
                <a href="{{ dataObject.downloadUrl }}" class="cm-menu-option"> .EXR </a>
                <a href="{{ dataObject.jpeg?.downloadUrl }}" class="cm-menu-option"> .JPEG </a>
                <a href="{{ dataObject.tiff?.downloadUrl }}" class="cm-menu-option"> .TIFF </a>
            </div>
        </div>
    </ng-template>
</mat-menu>

<cm-image-viewer #imageViewer></cm-image-viewer>
