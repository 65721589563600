import {Component, input} from "@angular/core"
import {PricedItem} from "@cm/pricing"
import {CurrencyPipe} from "@angular/common"
import {TippyDirective} from "@ngneat/helipopper"

@Component({
    selector: "cm-price-table",
    templateUrl: "./price-table.component.html",
    styleUrls: ["./price-table.component.scss"],
    imports: [CurrencyPipe, TippyDirective],
})
export class PriceTableComponent {
    readonly $items = input.required<PricedItem[]>({alias: "items"})
    readonly $error = input.required<string>({alias: "error"})
}
