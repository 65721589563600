import {Component, OnInit, input} from "@angular/core"
import {RouterLink} from "@angular/router"
import {ContentTypeModel} from "@generated"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {FullPageFeedbackComponent} from "@common/components/full-page-feedback/full-page-feedback.component"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {ChangeListItem} from "@platform/models/history/change-list-item"

@Component({
    selector: "cm-change-list",
    templateUrl: "./change-list.component.html",
    styleUrls: ["./change-list.component.scss"],
    imports: [RouterLink, PlaceholderComponent, FullPageFeedbackComponent, DataObjectThumbnailComponent],
})
export class ChangeListComponent implements OnInit {
    readonly $changeList = input<ChangeListItem[]>(undefined, {alias: "changeList"})
    readonly $contentTypeModel = input<ContentTypeModel>(undefined, {alias: "contentTypeModel"})
    entityUrl?: string

    ngOnInit() {
        const contentTypeModel = this.$contentTypeModel()
        if (contentTypeModel == ContentTypeModel.Picture) {
            this.entityUrl = "/pictures"
        } else if (contentTypeModel == ContentTypeModel.Asset) {
            this.entityUrl = "/assets"
        } else if (contentTypeModel == ContentTypeModel.Material) {
            this.entityUrl = "/materials"
        } else if (contentTypeModel == ContentTypeModel.Model) {
            this.entityUrl = "/models"
        }
    }
}
