<div class="cm-inline-toggle-button-container">
    <button class="cm-text-button" (click)="toggle()">
        <i class="fa-regular {{ $expanded() ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i>
    </button>
    <ng-content select="[cm-inline-button]"></ng-content>
</div>
@if ($expanded()) {
    <div class="cm-dropdown-content">
        <ng-content select="[cm-dropdown-content]"> </ng-content>
    </div>
}
