import {Component, inject, signal} from "@angular/core"
import {MatButtonModule} from "@angular/material/button"
import {MatDialog} from "@angular/material/dialog"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatTableModule} from "@angular/material/table"
import {MatTooltipModule} from "@angular/material/tooltip"
import {RouterOutlet} from "@angular/router"
import {TypedMatCellDefDirective} from "@app/common/directives"
import {JobFarmGroupsTableItemFragment} from "@app/platform/components/job-farm-groups/job-farm-groups-table/job-farm-groups-table.generated"
import {TableCellComponent} from "@common/components/tables/table-cell/table-cell.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {BreakpointsService} from "@common/services/breakpoints/breakpoints.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {UtilsService} from "@legacy/helpers/utils"
import {BaseTableComponent} from "@platform/components/base/base-table/base-table.component"
import {EditJobFarmGroupDialogComponent} from "@platform/components/job-farm-groups/edit-job-farm-group-dialog/edit-job-farm-group-dialog.component"
import {
    CreateJobFarmGroupsTableItemGQL,
    DeleteJobFarmGroupsTableItemGQL,
    JobFarmGroupsTableItemsGQL,
    UpdateJobFarmGroupsTableItemGQL,
} from "@platform/components/job-farm-groups/job-farm-groups-table/job-farm-groups-table.generated"
import {of} from "rxjs"

export type JobFarmGroupDialogResult = {
    id: string
    name: string
    organizationId: string | null
    queueGroup: string
    create?: boolean
    delete?: boolean
}

@Component({
    selector: "cm-job-farm-groups-table",
    templateUrl: "./job-farm-groups-table.component.html",
    styleUrls: ["./job-farm-groups-table.component.scss"],
    imports: [RouterOutlet, MatButtonModule, MatProgressBarModule, MatTableModule, MatTooltipModule, TableCellComponent, TypedMatCellDefDirective],
})
export class JobFarmGroupsTableComponent extends BaseTableComponent<JobFarmGroupsTableItemFragment> {
    readonly breakpoints = inject(BreakpointsService)
    readonly can = inject(PermissionsService)
    readonly dialog = inject(MatDialog)
    readonly utils = inject(UtilsService)

    readonly jobFarmGroupsTableItems = inject(JobFarmGroupsTableItemsGQL)
    readonly createJobFarmGroupsTableItem = inject(CreateJobFarmGroupsTableItemGQL)
    readonly deleteJobFarmGroupsTableItem = inject(DeleteJobFarmGroupsTableItemGQL)
    readonly updateJobFarmGroupsTableItem = inject(UpdateJobFarmGroupsTableItemGQL)

    readonly $columns = signal(["id", "organization", "name", "queueGroup"])
    $data = this.dataLoader.$batchedData<JobFarmGroupsTableItemFragment>(
        of((skip: number, take: number) => {
            return fetchThrowingErrors(this.jobFarmGroupsTableItems)({skip, take})
        }),
        this.infiniteScroll.shouldLoadMore$,
    )

    private showEditDialog(item: JobFarmGroupsTableItemFragment | null = null): void {
        const dialogRef = this.dialog.open<EditJobFarmGroupDialogComponent, JobFarmGroupsTableItemFragment, JobFarmGroupDialogResult>(
            EditJobFarmGroupDialogComponent,
            {
                width: "350px",
                data: item,
            },
        )
        dialogRef.afterClosed().subscribe(async (result) => {
            if (result?.delete) {
                await this.notification.withUserFeedback(() => mutateThrowingErrors(this.deleteJobFarmGroupsTableItem)({id: result.id}), {
                    success: "Group deleted.",
                    error: "Failed to delete group.",
                })
                // TODO: add job farm groups to content type models
                // this.refresh.all(ContentTypeModel.JobFarmGroup)
            } else if (result?.id && result?.name && result?.organizationId && result?.queueGroup) {
                if (result.create) {
                    await this.notification.withUserFeedback(
                        () =>
                            mutateThrowingErrors(this.createJobFarmGroupsTableItem)({
                                input: {
                                    id: result.id,
                                    name: result.name,
                                    organizationId: result.organizationId,
                                    queueGroup: result.queueGroup,
                                },
                            }),
                        {
                            success: "Group created.",
                            error: "Failed to create group.",
                        },
                    )
                    // TODO: add job farm groups to content type models
                    // this.refresh.all(ContentTypeModel.JobFarmGroup)
                } else {
                    await this.notification.withUserFeedback(
                        () =>
                            mutateThrowingErrors(this.updateJobFarmGroupsTableItem)({
                                input: {
                                    id: result.id,
                                    name: result.name,
                                    organizationId: result.organizationId,
                                    queueGroup: result.queueGroup,
                                },
                            }),
                        {
                            success: "Group updated.",
                            error: "Failed to update group.",
                        },
                    )
                    // this.refresh.all(ContentTypeModel.JobFarmGroup)
                }
            }
        })
    }

    rowClick(item: JobFarmGroupsTableItemFragment | null): void {
        this.showEditDialog(item)
    }

    createItem() {
        this.showEditDialog()
    }
}
