import {popperVariation, TippyConfig, tooltipVariation} from "@ngneat/helipopper"

export const cmTippyConfig: Partial<TippyConfig> = {
    appendTo: () => document.body, //this is the standard container for tooltips/overlays, can be changed inline with tpAppendTo
    defaultVariation: "tooltip",
    variations: {
        light: {...tooltipVariation, theme: "light", animation: "scale-subtle", delay: [200, 0], placement: "top"},
        tooltip: {...tooltipVariation, theme: "colormass-tooltip", animation: "scale-subtle"},
        popper: {...popperVariation, theme: "colormass-popover", arrow: false},
    },
}
