import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"
import {HelperLineItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/helper-lines/helper-line-item"
import {EditVectorItem, Vector} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/helper-lines/edit-vector-item"
import {Vector2, Vector2Like} from "@cm/math"
import {filter} from "rxjs"
import {EventEmitter} from "@angular/core"
import {IsDefined} from "@cm/utils"

export class HelperLinesBagItem extends CanvasBaseToolboxItemBase {
    readonly traceVectorCreated = new EventEmitter<Vector>()
    readonly helperLineCreated = new EventEmitter<HelperLineItem>()
    readonly helperLineRemoved = new EventEmitter<HelperLineItem>()
    readonly helperLineUpdated = new EventEmitter<HelperLineItem>()
    readonly helperLineFinalized = new EventEmitter<HelperLineItem>()

    constructor(parentItem: CanvasBaseToolboxItem) {
        super(parentItem)

        this.editVectorItem = new EditVectorItem(this)
        this.editVectorItem.vectorChanged.pipe(filter(IsDefined)).subscribe((vector) => this.traceVectorCreated.emit(vector))

        this.addShortcut(["Delete", "Backspace"]).subscribe(() => this.deleteSelectedHelperLines())
    }

    get helperLineCrossings() {
        this.updateHelperLineCrossings()
        return this._helperLineCrossings
    }

    setHelperLineTracingMode(enable: boolean) {
        this.editVectorItem.vector = undefined
        this.editVectorItem.visible = enable
        this.editVectorItem.selected = enable
        // bring to front if enabled to be able to trace over other helper lines and boundaries
        if (enable) {
            this.bringToFront()
        } else {
            this.sendToBack()
        }
    }

    createHelperLine(from: Vector2Like, to: Vector2Like) {
        const helperLine = new HelperLineItem(this, from, to)
        this.helperLines.push(helperLine)
        helperLine.linePointsChanged.subscribe(() => {
            this.invalidateHelperLineCrossingsAndAreas()
            this.helperLineUpdated.emit(helperLine)
        })
        helperLine.stateChanged.pipe(filter((state) => state === "finished")).subscribe(() => {
            this.helperLineFinalized.emit(helperLine)
        })
        this.helperLineCreated.emit(helperLine)
        return helperLine
    }

    removeHelperLine(helperLine: HelperLineItem) {
        const index = this.helperLines.indexOf(helperLine)
        if (index === -1) {
            throw Error("Selected line not found in helperLines")
        }
        this.helperLines.splice(index, 1)
        helperLine.remove()
        this.helperLineRemoved.emit(helperLine)
    }

    deleteSelectedHelperLines() {
        const selected = this.helperLines.filter((line) => line.selected)
        selected.forEach((line) => this.removeHelperLine(line))
    }

    getClosestPoint(position: Vector2Like): {closestPoint: Vector2; offset: number; distance: number; helperLine: HelperLineItem} | undefined {
        return this.helperLines.reduce(
            (result, helperLine) => {
                const closestPointAndOffset = helperLine.getClosestPointAndOffsetToLocation(position)
                const distance = Vector2.distance(closestPointAndOffset.closestPoint, position)
                if (!result || distance < result.distance) {
                    return {...closestPointAndOffset, distance, helperLine}
                } else {
                    return result
                }
            },
            undefined as {closestPoint: Vector2; offset: number; distance: number; helperLine: HelperLineItem} | undefined,
        )
    }

    private invalidateHelperLineCrossingsAndAreas() {
        this._needUpdateHelperLineCrossings = true
    }

    private updateHelperLineCrossings() {
        if (!this._needUpdateHelperLineCrossings) {
            return
        }
        this._needUpdateHelperLineCrossings = false
        // update crossings
        this._helperLineCrossings.length = 0
        for (let i = 0; i < this.helperLines.length; i++) {
            for (let j = i + 1; j < this.helperLines.length; j++) {
                const line1 = this.helperLines[i]
                const line2 = this.helperLines[j]
                const path1 = line1.path
                const path2 = line2.path
                if (!path1 || !path2) {
                    throw Error("Path not found")
                }
                const intersectionLocations = path1.getIntersections(path2)
                intersectionLocations.forEach((intersection) => {
                    const helperLineCrossing: HelperLineCrossing = {
                        point: Vector2.fromVector2Like(intersection.point),
                        crossedHelperLines: [
                            {helperLine: line1, offset: intersection.offset},
                            {helperLine: line2, offset: path2.getOffsetOf(intersection.point)},
                        ],
                    }
                    this._helperLineCrossings.push(helperLineCrossing)
                })
            }
        }
    }

    readonly helperLines: HelperLineItem[] = []
    readonly editVectorItem: EditVectorItem

    private readonly _helperLineCrossings: HelperLineCrossing[] = []
    private _needUpdateHelperLineCrossings = true
}

export type HelperLineCrossing = {
    point: Vector2
    crossedHelperLines: [CrossedHelperLine, CrossedHelperLine]
}

export type CrossedHelperLine = {
    helperLine: HelperLineItem
    offset: number
}
