import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {getColor, rgbBlendType, threeConvert, threeRGBColorNode, threeValueNode} from "#material-nodes/three-utils"
import {color} from "#material-nodes/types"
import {getAll} from "@cm/graph/utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({color: materialSlots})
const InputTypeSchema = z.object({
    color1: materialSlots.optional(),
    color2: materialSlots.optional(),
    fac: materialSlots.optional(),
})
const ParametersTypeSchema = z.object({
    blendType: rgbBlendType.optional(),
    color1: color.optional(),
    color2: color.optional(),
    fac: z.number().optional(),
    useClamp: z.boolean().optional(),
    useAlpha: z.boolean().optional(),
})
export class MixRGB extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {color1, color2, fac} = await getAll(inputs, get)
            const inputA = color1 ?? threeConvert(parameters.color1, threeRGBColorNode) ?? threeRGBColorNode({r: 0, g: 0, b: 0})
            const inputB = color2 ?? threeConvert(parameters.color2, threeRGBColorNode) ?? threeRGBColorNode({r: 0, g: 0, b: 0})
            const facNode = fac ?? threeConvert(parameters.fac, threeValueNode) ?? threeValueNode(1)
            const {blendType, useClamp} = parameters
            const operation = blendType ?? "MIX"
            const mixedColor = getColor(operation, inputA, inputB, facNode)
            return {color: useClamp ? THREENodes.clamp(mixedColor, threeValueNode(0), threeValueNode(1)) : mixedColor}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
