<!-- Maybe unify this node and remove the individual visualizers... -->
<div class="cm-node-flex-container">
    @if ($isPriceContainerNode()) {
        <cm-price-container [node]="$node()" (conditionRemoved)="conditionRemoved.emit()"></cm-price-container>
    }
    @if ($isPricedItemNode()) {
        <cm-priced-item [pricingContext]="$pricingContext()" [node]="$node()" (conditionRemoved)="conditionRemoved.emit()"></cm-priced-item>
    }
    @if ($isAmountGroupNode()) {
        <cm-amount-group [node]="$node()"></cm-amount-group>
    }
    @if ($isAmountNode()) {
        <cm-amount [node]="$node()" (conditionRemoved)="conditionRemoved.emit()"></cm-amount>
    }
</div>
