import {Component, input, output} from "@angular/core"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextComponent} from "@common/components/inputs/native/native-input-text/native-input-text.component"

type ItemType = {articleId?: string | null}

@Component({
    selector: "cm-article-id-section",
    imports: [InputContainerComponent, NativeInputTextComponent],
    templateUrl: "./article-id-section.component.html",
    styleUrl: "./article-id-section.component.scss",
})
export class ArticleIdSectionComponent {
    readonly $item = input<ItemType | null | undefined>(null, {alias: "item"})
    readonly $label = input<string>("Article ID:", {alias: "label"})
    readonly $canEdit = input<boolean>(false, {alias: "canEdit"})
    readonly onChanged = output<ItemType>()
}
