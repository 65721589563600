import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PaymentStateLabelForMaterialFragment = { __typename: 'Material', id: string, paymentState: Types.PaymentState };

export type PaymentStateLabelForMaterialsQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type PaymentStateLabelForMaterialsQuery = { __typename: 'Query', materials: Array<{ __typename: 'Material', id: string, paymentState: Types.PaymentState } | null> };

export type PaymentStateLabelForModelFragment = { __typename: 'Model', id: string, paymentState: Types.PaymentState };

export type PaymentStateLabelForModelsQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type PaymentStateLabelForModelsQuery = { __typename: 'Query', models: Array<{ __typename: 'Model', id: string, paymentState: Types.PaymentState } | null> };

export type PaymentStateLabelForPictureFragment = { __typename: 'Picture', id: string, paymentState: Types.PaymentState };

export type PaymentStateLabelForPicturesQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type PaymentStateLabelForPicturesQuery = { __typename: 'Query', pictures: Array<{ __typename: 'Picture', id: string, paymentState: Types.PaymentState } | null> };

export const PaymentStateLabelForMaterialFragmentDoc = gql`
    fragment PaymentStateLabelForMaterial on Material {
  id
  __typename
  paymentState
}
    `;
export const PaymentStateLabelForModelFragmentDoc = gql`
    fragment PaymentStateLabelForModel on Model {
  id
  __typename
  paymentState
}
    `;
export const PaymentStateLabelForPictureFragmentDoc = gql`
    fragment PaymentStateLabelForPicture on Picture {
  id
  __typename
  paymentState
}
    `;
export const PaymentStateLabelForMaterialsDocument = gql`
    query paymentStateLabelForMaterials($ids: [ID!]) {
  materials(filter: {id: {in: $ids}}, take: 10000) {
    ...PaymentStateLabelForMaterial
  }
}
    ${PaymentStateLabelForMaterialFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentStateLabelForMaterialsGQL extends Apollo.Query<PaymentStateLabelForMaterialsQuery, PaymentStateLabelForMaterialsQueryVariables> {
    override document = PaymentStateLabelForMaterialsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaymentStateLabelForModelsDocument = gql`
    query paymentStateLabelForModels($ids: [ID!]) {
  models(filter: {id: {in: $ids}}, take: 10000) {
    ...PaymentStateLabelForModel
  }
}
    ${PaymentStateLabelForModelFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentStateLabelForModelsGQL extends Apollo.Query<PaymentStateLabelForModelsQuery, PaymentStateLabelForModelsQueryVariables> {
    override document = PaymentStateLabelForModelsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaymentStateLabelForPicturesDocument = gql`
    query paymentStateLabelForPictures($ids: [ID!]) {
  pictures(filter: {id: {in: $ids}}, take: 10000) {
    ...PaymentStateLabelForPicture
  }
}
    ${PaymentStateLabelForPictureFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentStateLabelForPicturesGQL extends Apollo.Query<PaymentStateLabelForPicturesQuery, PaymentStateLabelForPicturesQueryVariables> {
    override document = PaymentStateLabelForPicturesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }