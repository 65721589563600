import {Component, computed, input, output} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatTooltipModule} from "@angular/material/tooltip"
import {PricingNode, ConditionalAmountNode} from "@cm/pricing"
import {VariantConditionNodeComponent} from "@pricing/components/node-visualizers/variant-condition-node/variant-condition-node.component"

@Component({
    selector: "cm-amount",
    templateUrl: "./conditional-amount-node.component.html",
    styleUrls: ["./conditional-amount-node.component.scss", "../node-styles.scss"],
    imports: [VariantConditionNodeComponent, FormsModule, MatTooltipModule],
})
export class ConditionalAmountNodeComponent {
    readonly $node = input.required<PricingNode>({alias: "node"})
    readonly $conditionalAmountNode = computed<ConditionalAmountNode | undefined>(() => {
        const node = this.$node()
        if (node instanceof ConditionalAmountNode) return node
        else return undefined
    })

    readonly $description = computed<string>(() => {
        const node = this.$conditionalAmountNode()
        if (node === undefined) return ""
        return node.parameters.description.length > 0 ? node.parameters.description : "Amount group node"
    })

    readonly conditionRemoved = output<void>()
}
