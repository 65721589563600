import {AfterViewInit, Component, Input, input, viewChild} from "@angular/core"
import {ImageViewPtrWebGl2, ImageViewWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/image-view-webgl2"
import {CanvasBaseComponent, CanvasPhysicalInfo} from "@common/components/canvas"
import * as paper from "paper"
import {firstValueFrom, Subject, Subscription, takeUntil} from "rxjs"
import {Box2, Box2Like, Size2Like} from "@cm/math"
import {SmartPtrReassignable} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/smart-ptr"
import {OperatorToolboxBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-toolbox-base"
import {TextureEditorSettings} from "app/textures/texture-editor/texture-editor-settings"
import {HalPainterImageStretchTiled} from "app/textures/texture-editor/tiling-canvas/hal/hal-painter-image-stretch-tiled"
import {AsyncReentrancyGuard} from "@cm/utils/async-reentrancy-guard"
import {TextureEditorCallback} from "app/textures/texture-editor/texture-editor-callback"
import {Operator} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {WebGl2Context} from "@common/models/webgl2"
import {HalImage} from "@common/models/hal/hal-image"
import {ImageHal} from "@app/textures/texture-editor/operator-stack/image-op-system/brainstorm/image-hal"
import {Region} from "@app/textures/texture-editor/operator-stack/image-op-system/brainstorm/image"

const TRACE = TextureEditorSettings.EnableFullTrace

@Component({
    selector: "cm-tiling-canvas",
    templateUrl: "./tiling-canvas.component.html",
    styleUrls: ["./tiling-canvas.component.scss"],
    imports: [CanvasBaseComponent],
})
export class TilingCanvasComponent implements AfterViewInit {
    readonly $canvasBase = viewChild.required<CanvasBaseComponent>("canvasBase")

    readonly $callback = input.required<TextureEditorCallback>({alias: "callback"})

    readonly $physicalInfo = input.required<CanvasPhysicalInfo>({alias: "physicalInfo"})

    @Input() set gridActive(value: boolean) {
        if (this._gridActive === value) {
            return
        }
        this._gridActive = value
        this.$canvasBase().requestRedraw()
    }

    get gridActive(): boolean {
        return this._gridActive
    }

    @Input() set gridOutlineActive(value: boolean) {
        this._gridOutlineActive = value
        this.updateGrid()
    }

    @Input() set showAtlas(value: boolean) {
        if (this._showAtlas === value) {
            return
        }
        this._showAtlas = value
        this.$canvasBase().requestRedraw()
    }

    get showAtlas(): boolean {
        return this._showAtlas
    }

    ngAfterViewInit(): void {
        this.$canvasBase().beginPaperCreation()
        this.gridLinesGroup = new paper.Group()
        this.gridLinesGroup.name = "GridLines"

        this.halPainterImageDrawTiled = new HalPainterImageStretchTiled(this.$canvasBase().halContext)
        this.$canvasBase().customDrawFn = this.drawCanvasImage.bind(this)
    }

    ngOnDestroy(): void {
        this.unsubscribe.next()
        this.unsubscribe.complete()
        this.halPainterImageDrawTiled.dispose()
    }

    get toolbox(): OperatorToolboxBase<Operator> | null {
        return this.operatorToolbox
    }

    set toolbox(value: OperatorToolboxBase<Operator> | null) {
        if (this.operatorToolbox === value) {
            return
        }
        this.operatorToolbox = value
        this.$canvasBase().toolbox = value
    }

    get displayGamma(): number {
        return this.$canvasBase().displayGamma
    }

    set displayGamma(value: number) {
        const canvasBase = this.$canvasBase()
        canvasBase.displayGamma = value
        canvasBase.requestRedraw()
    }

    async loadImage(url: string, isSRGB: boolean) {
        try {
            await this._loadImageGate.startAndRejectCurrent(async () =>
                firstValueFrom(this.$canvasBase().loadImage(url, isSRGB).pipe(takeUntil(this.unsubscribe))),
            )
            // eslint-disable-next-line unused-imports/no-unused-vars
        } catch (_error: unknown) {
            // ignore
        }
    }

    setResultImageNew(resultImage: ImageHal | null): void {
        if (this._resultImageNew) {
            this._subscriptionsNew.forEach((subscription) => subscription.unsubscribe())
            this._subscriptionsNew = []
            this._resultImageNew.release()
        }
        this._resultImageNew = resultImage
        if (this._resultImageNew) {
            this._subscriptionsNew.push(this._resultImageNew.invalidated.subscribe(() => this.$canvasBase().requestRedraw()))
        }
        this.$canvasBase().requestRedraw()
        this.updateGrid()
    }

    setResultImage(resultImage: ImageViewPtrWebGl2 | null): void {
        this.resultImage.set(resultImage)
        this.$canvasBase().requestRedraw()
        this.updateGrid()
    }

    setMessage(message: string | undefined): void {
        this.message = message
    }

    private async drawCanvasImage(): Promise<Box2Like> {
        if (TRACE) {
            console.log("drawCanvasImage - begin")
        }
        const webGlCanvasComponent = this.$canvasBase().$webGlCanvasComponent()
        const outputWidth = webGlCanvasComponent.backBufferImage.descriptor.width
        const outputHeight = webGlCanvasComponent.backBufferImage.descriptor.height
        let halImage: HalImage | null
        if (this._showAtlas) {
            const halContext = this.$canvasBase().halContext
            if (halContext instanceof WebGl2Context) {
                halImage = halContext.atlas.image
            } else {
                throw new Error("HalContext is not an instance of WebGl2Context")
            }
        } else {
            if (TextureEditorSettings.EnableExperimental) {
                if (this._resultImageNew) {
                    const invTransform = this.$canvasBase().viewTransform.inverted()
                    const p00 = invTransform.transform(new paper.Point(0, 0)).floor()
                    const p11 = invTransform.transform(new paper.Point(outputWidth, outputHeight)).ceil()
                    const zoom = this.$canvasBase().navigation.getPhysicalZoomLevel()
                    const mipLevel = Math.max(0, Math.min(this._resultImageNew.numMipLevels - 1, Math.floor(Math.log2(1 / zoom))))
                    const visibleRegion: Region = {
                        x: p00.x,
                        y: p00.y,
                        width: p11.x - p00.x,
                        height: p11.y - p00.y,
                        mipLevel: mipLevel,
                    }
                    // console.log(`visibleRegion: ${JSON.stringify(visibleRegion)}`)
                    this._resultImageNew.updateRegions([visibleRegion])
                    halImage = this._resultImageNew.halImage
                } else {
                    halImage = null
                }
            } else {
                halImage = this.resultImage.isAssigned ? this.resultImage.ref.halImageView : this.$canvasBase().halImage // show result image if available, fallback to source image
            }
        }
        if (!halImage) {
            return new Box2(0, 0, 0, 0)
        }
        const drawnBox = this.halPainterImageDrawTiled.paint(
            webGlCanvasComponent.backBufferImage,
            halImage,
            this._gridActive ? new Box2(0, 0, outputWidth, outputHeight) : undefined,
            {transform: this.$canvasBase().viewTransform},
        )
        if (TRACE) {
            console.log("drawCanvasImage - end")
        }
        return drawnBox
    }

    loadingCompleted(): void {
        // This is required to redraw the grid in case the texture size changes while the grid being active.
        const gridOutlineActive = this._gridOutlineActive
        if (this._gridActive) {
            this.gridActive = false
            this.gridActive = true
            this.gridOutlineActive = gridOutlineActive
        }
        this.$canvasBase().requestRedraw()
    }

    private updateGrid(): void {
        if (this.gridLinesGroup) {
            this.$canvasBase().beginPaperCreation()
            this.gridLinesGroup.removeChildren()
            if (this._gridOutlineActive) {
                const canvasBounds = this.resultImage.isAssigned
                    ? this.resultImage.ref.descriptor
                    : (this.$canvasBase().halImage?.descriptor ?? {width: 0, height: 0})
                const halfGridDisplaySize = 10
                for (let x = -halfGridDisplaySize; x <= halfGridDisplaySize + 1; x++) {
                    const p0 = new paper.Point(x * canvasBounds.width, -halfGridDisplaySize * canvasBounds.height)
                    const p1 = new paper.Point(x * canvasBounds.width, (halfGridDisplaySize + 1) * canvasBounds.height)
                    const linePath = new paper.Path.Line(p0, p1)
                    linePath.strokeColor = new paper.Color(1, 1, 1, 1)
                    linePath.strokeWidth = 1
                    linePath.strokeScaling = false
                    this.gridLinesGroup.addChild(linePath)
                }
                for (let y = -halfGridDisplaySize; y <= halfGridDisplaySize + 1; y++) {
                    const p0 = new paper.Point(-halfGridDisplaySize * canvasBounds.width, y * canvasBounds.height)
                    const p1 = new paper.Point((halfGridDisplaySize + 1) * canvasBounds.width, y * canvasBounds.height)
                    const linePath = new paper.Path.Line(p0, p1)
                    linePath.strokeColor = new paper.Color(1, 1, 1, 1)
                    linePath.strokeWidth = 1
                    linePath.strokeScaling = false
                    this.gridLinesGroup.addChild(linePath)
                }
            }
        }
    }

    public get tileBounds() {
        const halImageSize: Size2Like = this.resultImage.isAssigned
            ? this.resultImage.ref.halImageView.descriptor
            : (this.$canvasBase().halImage?.descriptor ?? {
                  width: 0,
                  height: 0,
              })
        return new Box2(0, 0, halImageSize.width, halImageSize.height)
    }

    protected message: string | undefined = undefined

    private unsubscribe = new Subject<void>()
    private _loadImageGate = new AsyncReentrancyGuard.PromiseGate()
    private halPainterImageDrawTiled!: HalPainterImageStretchTiled
    private gridLinesGroup: paper.Group | null = null
    private _gridOutlineActive = false
    private _gridActive = false
    private _showAtlas = false
    private operatorToolbox: OperatorToolboxBase<Operator> | null = null
    private resultImage = new SmartPtrReassignable<ImageViewWebGL2>()

    private _resultImageNew: ImageHal | null = null
    private _subscriptionsNew: Subscription[] = []
}
