import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ScanJobFragment = { __typename: 'ScanJob', id: string, active: boolean, completed?: string | null, createdAt: string, sampleRotation: number, started?: string | null, state: Types.ScanJobState, activeSubJobsCount: number, startedSubJobsCount: number, subJobsCount: number, completedSubJobsCount: number, createdBy?: { __typename: 'User', name: string } | null, logAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }>, textureSet: { __typename: 'TextureSet', textureGroup: { __typename: 'TextureGroup', organization: { __typename: 'Organization', id: string } } } };

export type ScanJobsForTextureSetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ScanJobsForTextureSetQuery = { __typename: 'Query', textureSet: { __typename: 'TextureSet', id: string, name?: string | null, scanJob?: { __typename: 'ScanJob', id: string, active: boolean, completed?: string | null, createdAt: string, sampleRotation: number, started?: string | null, state: Types.ScanJobState, activeSubJobsCount: number, startedSubJobsCount: number, subJobsCount: number, completedSubJobsCount: number, createdBy?: { __typename: 'User', name: string } | null, logAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }>, textureSet: { __typename: 'TextureSet', textureGroup: { __typename: 'TextureGroup', organization: { __typename: 'Organization', id: string } } } } | null } };

export type ScanJobUpdateMutationVariables = Types.Exact<{
  input: Types.MutationUpdateScanJobInput;
}>;


export type ScanJobUpdateMutation = { __typename: 'Mutation', updateScanJob: { __typename: 'ScanJob', id: string, active: boolean, completed?: string | null, createdAt: string, sampleRotation: number, started?: string | null, state: Types.ScanJobState, activeSubJobsCount: number, startedSubJobsCount: number, subJobsCount: number, completedSubJobsCount: number, createdBy?: { __typename: 'User', name: string } | null, logAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }>, textureSet: { __typename: 'TextureSet', textureGroup: { __typename: 'TextureGroup', organization: { __typename: 'Organization', id: string } } } } };

export const ScanJobFragmentDoc = gql`
    fragment ScanJob on ScanJob {
  id
  active
  completed
  createdAt
  sampleRotation
  started
  state
  createdBy {
    name
  }
  logAssignments: dataObjectAssignments(filter: {assignmentType: ScanLog}) {
    dataObject {
      downloadUrl
    }
  }
  activeSubJobsCount
  startedSubJobsCount
  subJobsCount
  completedSubJobsCount
  textureSet {
    textureGroup {
      organization {
        id
      }
    }
  }
}
    `;
export const ScanJobsForTextureSetDocument = gql`
    query scanJobsForTextureSet($id: ID!) {
  textureSet(id: $id) {
    id
    name
    scanJob {
      ...ScanJob
    }
  }
}
    ${ScanJobFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScanJobsForTextureSetGQL extends Apollo.Query<ScanJobsForTextureSetQuery, ScanJobsForTextureSetQueryVariables> {
    override document = ScanJobsForTextureSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScanJobUpdateDocument = gql`
    mutation scanJobUpdate($input: MutationUpdateScanJobInput!) {
  updateScanJob(input: $input) {
    ...ScanJob
  }
}
    ${ScanJobFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScanJobUpdateGQL extends Apollo.Mutation<ScanJobUpdateMutation, ScanJobUpdateMutationVariables> {
    override document = ScanJobUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }