import {DestroyRef, Injectable, computed, inject, signal} from "@angular/core"
import {DeclareListNode} from "@cm/graph"
import {isNodeOwner, isSwitch, isTemplateOwningContainer, isTemplateReferenceContainer} from "@cm/template-nodes"
import {TemplateNode, context, isTemplateNode, templateNode} from "@cm/template-nodes"
import {v4 as uuid4} from "uuid"
import {Parameters, TemplateInstance} from "@cm/template-nodes"
import {TemplateGraph} from "@cm/template-nodes"
import {SceneManagerService} from "@template-editor/services/scene-manager.service"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {isNamedNode} from "@cm/template-nodes"
import {isIdNode} from "@cm/template-nodes"
import {MaterialAssignment, MaterialAssignments} from "@cm/template-nodes"
import {TemplateInput} from "@cm/template-nodes"
import {TemplateOutput} from "@cm/template-nodes"
import {TemplateSwitch} from "@cm/template-nodes"
import {TemplateExport} from "@cm/template-nodes"
import {removeUndefinedEntriesFromObject} from "@cm/utils"

class GatherTemplateNode extends DeclareListNode(
    {
        context,
        item: templateNode,
    },
    {},
) {}

@Injectable({providedIn: "root"})
export class TemplateNodeClipboardService {
    private $_nodes = signal<TemplateNode[]>([])
    $nodes = this.$_nodes.asReadonly()
    valid = computed(() => this.$_nodes().length > 0)
    private readonly sceneManagerService = inject(SceneManagerService)
    private readonly destroyRef = inject(DestroyRef)

    constructor() {
        this.sceneManagerService.templateSwapped$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.clear()
        })
    }

    clear(): void {
        this.$_nodes.set([])
    }

    copy(nodes: TemplateNode | TemplateNode[]) {
        if (Array.isArray(nodes)) this.$_nodes.set([...nodes])
        else this.$_nodes.set([nodes])
    }

    pasteReferences() {
        return [...this.$_nodes()]
    }

    pasteDuplicates() {
        const gatherTemplateNode = new GatherTemplateNode({list: this.pasteReferences()})
        const gatherTemplateNodeCopy = gatherTemplateNode.clone({
            cloneSubNode: (parent, subNode, path) => {
                const cloneSubNode = (() => {
                    if (parent instanceof GatherTemplateNode) return true
                    if (isTemplateNode(parent) && isTemplateNode(subNode)) {
                        //Clone all node owning nodes
                        if (isNodeOwner(subNode)) {
                            //Unless not used as a direct template graph reference
                            if (subNode instanceof TemplateGraph) {
                                if (
                                    parent instanceof TemplateExport ||
                                    parent instanceof TemplateInput ||
                                    parent instanceof TemplateOutput ||
                                    parent instanceof TemplateSwitch ||
                                    parent instanceof TemplateInstance
                                ) {
                                    return false
                                }
                            }
                            return true
                        }
                        //Clone node container nodes
                        if (isNodeOwner(parent) && isTemplateOwningContainer(subNode)) return true
                        //Also clone referenced nodes of the containers
                        if (isTemplateOwningContainer(parent)) return true

                        //Clone material assignments (in meshes)
                        if (subNode instanceof MaterialAssignments) return true
                        //Clone all material assignment items within material assignments, but do not clone the referenced nodes of the containers
                        if (parent instanceof MaterialAssignments && subNode instanceof MaterialAssignment) return true

                        //Clone switch nodes
                        if (isSwitch(subNode)) return true
                        //Clone switch container nodes, but do not clone the referenced nodes
                        if (isSwitch(parent) && isTemplateReferenceContainer(subNode)) return true

                        //Clone parameters (in template instance)
                        if (subNode instanceof Parameters) return true
                    }

                    return false
                })()

                return cloneSubNode
            },
            parameterOverrides: (node) => {
                if (isTemplateNode(node))
                    return removeUndefinedEntriesFromObject({
                        name: isNamedNode(node) ? `${node.parameters.name} copy` : undefined,
                        id: isIdNode(node) ? uuid4() : undefined,
                    })
                else return undefined
            },
        })

        gatherTemplateNode.clear()

        const returnNodes = [...gatherTemplateNodeCopy.parameters.list]

        gatherTemplateNodeCopy.clear()

        return returnNodes
    }
}
