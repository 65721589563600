<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> Preview </ng-container>
    <ng-container cm-content>
        @if ($camera(); as camera) {
            <div class="cm-camera-preview">
                <cm-three-template-scene-viewer [camera]="camera" [allowEdit]="false"></cm-three-template-scene-viewer>
            </div>
        }
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Resolution </ng-container>
    <ng-container cm-content>
        <div class="cm-resolution">
            <cm-value-slot [node]="$node()" [key]="'resolutionX'" [label]="'X'" [decimalPlaces]="0"></cm-value-slot>
            <div class="cm-multiplier">×</div>
            <cm-value-slot [node]="$node()" [key]="'resolutionY'" [label]="'Y'" [decimalPlaces]="0"></cm-value-slot>
        </div>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Camera Settings </ng-container>
    <ng-container cm-content>
        <div class="cm-camera-settings">
            <cm-value-slot [node]="$node()" [key]="'focalLength'" [label]="'Focal l.'" [decimalPlaces]="0"></cm-value-slot>
            <cm-value-slot [node]="$node()" [key]="'filmGauge'" [label]="'Sensor'" [decimalPlaces]="0"></cm-value-slot>
            @if ($automaticTarget() === undefined) {
                <cm-value-slot [node]="$node()" [key]="'autoFocus'" [label]="'Autofocus'" class="cm-span2"></cm-value-slot>
                @if ($parameters().autoFocus === false) {
                    <cm-value-slot [node]="$node()" [key]="'focalDistance'" [label]="'Focal d.'" [decimalPlaces]="1"></cm-value-slot>
                    <cm-button
                        class="cm-action-button"
                        (click)="chooseFocus()"
                        [disabled]="$sceneNode() === undefined || sceneManagerService.watchingForClickedSceneNodePart()"
                    >
                        <ng-container cm-text> Choose Focus </ng-container>
                    </cm-button>
                }
            }
            <cm-value-slot [node]="$node()" [key]="'fStop'" [label]="'F-stop'" [decimalPlaces]="1"></cm-value-slot>
            <cm-value-slot [node]="$node()" [key]="'zoomFactor'" [label]="'Zoom'" [decimalPlaces]="2"></cm-value-slot>
        </div>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Advanced Camera Settings </ng-container>
    <ng-container cm-content>
        <div class="cm-camera-settings">
            <cm-value-slot [node]="$node()" [key]="'shiftX'" [label]="'Shift X'" [decimalPlaces]="2"></cm-value-slot>
            <cm-value-slot [node]="$node()" [key]="'shiftY'" [label]="'Shift Y'" [decimalPlaces]="2"></cm-value-slot>
            <cm-value-slot [node]="$node()" [key]="'nearClip'" [label]="'Near clip'" [decimalPlaces]="1"></cm-value-slot>
            <cm-value-slot [node]="$node()" [key]="'farClip'" [label]="'Far clip'" [decimalPlaces]="1"></cm-value-slot>
            <cm-value-slot [node]="$node()" [key]="'ev'" [label]="'Exposure'" [decimalPlaces]="2"></cm-value-slot>
        </div>
        <cm-value-slot [node]="$node()" [key]="'automaticVerticalTilt'" [label]="'Auto vertical tilt'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Configurator Settings </ng-container>
    <ng-container cm-content>
        <div class="cm-camera-settings">
            <cm-value-slot [node]="$node()" [key]="'minDistance'" [label]="'Min dist.'" [decimalPlaces]="1"></cm-value-slot>
            <cm-value-slot [node]="$node()" [key]="'maxDistance'" [label]="'Max dist.'" [decimalPlaces]="1"></cm-value-slot>
            <cm-value-slot [node]="$node()" [key]="'minPolarAngle'" [label]="'Min angle (v)'" [decimalPlaces]="1"></cm-value-slot>
            <cm-value-slot [node]="$node()" [key]="'minAzimuthAngle'" [label]="'Min angle (h)'" [decimalPlaces]="1"></cm-value-slot>
            <cm-value-slot [node]="$node()" [key]="'maxPolarAngle'" [label]="'Max angle (v)'" [decimalPlaces]="1"></cm-value-slot>
            <cm-value-slot [node]="$node()" [key]="'maxAzimuthAngle'" [label]="'Max angle (h)'" [decimalPlaces]="1"></cm-value-slot>
        </div>
        <cm-value-slot [node]="$node()" [key]="'enablePanning'" [label]="'Enable panning'"></cm-value-slot>
        <cm-value-slot [node]="$node()" [key]="'screenSpacePanning'" [label]="'Screen space panning'"></cm-value-slot>
        <cm-tone-mapping-inspector [node]="$node()"> </cm-tone-mapping-inspector>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Target </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="$node()" [key]="'targeted'" [label]="'Targeted'" (onChanged)="onChangedTargeted($event)"></cm-value-slot>
        <cm-value-slot [node]="$node()" [key]="'automaticTarget'" [label]="'Automatic target'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>
