import {inject, Injectable} from "@angular/core"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {AuthService} from "@common/services/auth/auth.service"
import {MaterialRevisionsForMaterialGraphGQL} from "@common/services/material-graph/material-graph.generated"
import {ApiRequest} from "@common/models/api-request/api-request"
import {BatchedRequestPayload, RequestPayload, ResponsePayload} from "@common/models/material-graph/material-revision-for-material-graph-batch-api-call"
import {BatchApiCall} from "@common/helpers/batch-api-call/batch-api-call"
import {IsUnique} from "@cm/utils/filter"

@Injectable({
    providedIn: "root",
})
export class MaterialRevisionForMaterialGraphBatchApiCall extends BatchApiCall<RequestPayload, ResponsePayload, BatchedRequestPayload> {
    private readonly auth = inject(AuthService)
    private readonly materialRevisionsGql = inject(MaterialRevisionsForMaterialGraphGQL)

    constructor() {
        super(1)
    }

    protected dispatchResponses(batchedPayload: BatchedRequestPayload, responses: ResponsePayload[]) {
        const requestsById = new Map<number, ApiRequest<RequestPayload, ResponsePayload>[]>()
        batchedPayload.requests.forEach((request) => {
            if (!requestsById.has(request.payload.legacyId)) {
                requestsById.set(request.payload.legacyId, [])
            }
            requestsById.get(request.payload.legacyId)!.push(request)
        })
        responses.forEach((response) => {
            const requests = requestsById.get(response.legacyId)
            if (!requests) {
                throw new Error("No request not found")
            }
            requests.forEach((request) => request.resolve(response))
            requestsById.delete(response.legacyId)
        })
        requestsById.forEach((requests) => requests.forEach((request) => request.reject(Error("No response received"))))
    }

    protected batchRequests(requests: ApiRequest<RequestPayload, ResponsePayload>[]): BatchedRequestPayload[] {
        return [{requests}]
    }

    protected async callApi(payload: BatchedRequestPayload): Promise<(ResponsePayload | undefined | null)[]> {
        const legacyIds = payload.requests.map((request) => request.payload.legacyId).filter(IsUnique)
        return fetchThrowingErrors(this.materialRevisionsGql)({
            legacyIds,
        })
            .then((response) => response.materialRevisions)
            .catch((error) => {
                // ignore error if user has logged out in the meantime
                if (this.auth.isLoggedIn()) {
                    throw error
                } else {
                    return []
                }
            })
    }
}
