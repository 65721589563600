import {DataType, ImageRef, isImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueueWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-webgl2"
import {getMostPreciseDataType} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"

// Converts linear RGB to CIE-LAB color space (D65 white point). This color space is perceptually uniform and therefore can be used to measure color differences (as euclidean distances in that space).

const SCOPE_NAME = "ConvertRgbToLab"

export type ParameterType = {
    sourceImage: ImageRef
    resultImageOrDataType?: ImageRef | DataType
}

export type ReturnType = ImageRef

export const convertRgbToLab = (cmdQueue: ImageOpCommandQueueWebGL2, {sourceImage, resultImageOrDataType}: ParameterType): ReturnType => {
    cmdQueue.beginScope(SCOPE_NAME)
    const convert = cmdQueue.createPainter(
        "compositor",
        "convertRgbToLab",
        `
            vec4 computeColor(ivec2 targetPixel) {
                vec3 source = texelFetch0(targetPixel).rgb;
                
                // convert linear RGB to XYZ
                vec3 xyz = vec3(
                    0.4124564 * source.r + 0.3575761 * source.g + 0.1804375 * source.b,
                    0.2126729 * source.r + 0.7151522 * source.g + 0.0721750 * source.b,
                    0.0193339 * source.r + 0.1191920 * source.g + 0.9503041 * source.b
                );
                
                // normalize for D65 white point
                xyz /= vec3(0.95047, 1.0, 1.08883);
                
                // convert XYZ to LAB
                float epsilon = 216.0 / 24389.0;
                float kappa = 24389.0 / 27.0;
                vec3 f = vec3(
                    xyz.r > epsilon ? pow(xyz.r, 1.0 / 3.0) : (kappa * xyz.r + 16.0) / 116.0,
                    xyz.g > epsilon ? pow(xyz.g, 1.0 / 3.0) : (kappa * xyz.g + 16.0) / 116.0,
                    xyz.b > epsilon ? pow(xyz.b, 1.0 / 3.0) : (kappa * xyz.b + 16.0) / 116.0
                );
                vec3 lab = vec3(
                    116.0 * f.y - 16.0,
                    500.0 * (f.x - f.y),
                    200.0 * (f.y - f.z)
                );
                
                return vec4(lab, 1);
            }
        `,
    )
    if (!isImageRef(resultImageOrDataType)) {
        resultImageOrDataType = cmdQueue.createImage({
            ...sourceImage.descriptor,
            dataType: getMostPreciseDataType(resultImageOrDataType ?? "float16", "float16"),
        })
    }
    cmdQueue.paint(convert, {
        sourceImages: sourceImage,
        resultImage: resultImageOrDataType,
    })
    cmdQueue.endScope(SCOPE_NAME)
    return resultImageOrDataType
}
