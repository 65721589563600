import {inject, Injectable} from "@angular/core"
import {ContentTypeModel, ImageColorSpace, ImageDataType, TextureType} from "@generated"
import {RefreshService} from "@app/common/services/refresh/refresh.service"
import {ImageProcessingService} from "@app/common/services/rendering/image-processing.service"
import {
    DeleteScanJobGQL,
    DeleteScanSubJobGQL,
    DeleteTextureGQL,
    DeleteTextureRevisionGQL,
    DeleteTextureSetGQL,
    DeleteTextureSetRevisionGQL,
    QueryDataObjectLegacyIdGQL,
    QueryTextureEditDataObjectLegacyIdGQL,
    QueryTextureEditorDataObjectGQL,
    QueryTextureLoaderDataObjectImageDescriptorGQL,
    QueryTextureSetDeletionDataGQL,
    QueryTextureSetRevisionDataGQL,
    QueryTextureSetRevisionTextureRevisionIdsGQL,
    UpdateDataObjectImageDimensionsGQL,
    CreateMaterialAiJobGQL,
    CreateMaterialAiJobAssignmentGQL,
    CancelMaterialAiJobGQL,
} from "@app/textures/service/textures-api.generated"
import {DataType} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageProcessingNodes as Nodes} from "@cm/image-processing-nodes"
import {TypedImageData} from "@cm/utils/typed-image-data"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {firstValueFrom} from "rxjs"
import {HalImage} from "@common/models/hal/hal-image"
import {createTypedArrayImage} from "@common/models/hal/hal-image/utils"
import {ImageCache} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-cache"
import {JobNodes} from "@cm/job-nodes"
import {Utility} from "@cm/job-nodes/utility"
import {imageToPbrMaps} from "@cm/job-nodes/material-ai"
import {uploadProcessingMetadataTask, uploadProcessingThumbnailsTask} from "@cm/job-nodes/upload-processing"
import {Settings} from "@app/common/models/settings/settings"
import {graphToJson} from "@cm/utils/graph-json"

export type DataObjectImageDescriptor = {
    id: string
    legacyId: number
    width: number
    height: number
    imageDataType: ImageDataType
    imageColorSpace: ImageColorSpace
    downloadUrlExr: string
    downloadUrlJpg: string
}

@Injectable({providedIn: "root"})
export class TexturesApiService {
    constructor(
        private refreshService: RefreshService,
        private imageProcessingService: ImageProcessingService,
    ) {}

    private queryTextureSetRevisionData = inject(QueryTextureSetRevisionDataGQL)
    private queryTextureEditorDataObject = inject(QueryTextureEditorDataObjectGQL)
    private queryTextureSetRevisionTextureRevisionIds = inject(QueryTextureSetRevisionTextureRevisionIdsGQL)
    private deleteTextureSetRevisionGql = inject(DeleteTextureSetRevisionGQL)
    private mutateDeleteTextureSet = inject(DeleteTextureSetGQL)
    private mutateDeleteTextureRevision = inject(DeleteTextureRevisionGQL)
    private mutateDeleteTexture = inject(DeleteTextureGQL)
    private queryTextureEditDataObjectLegacyId = inject(QueryTextureEditDataObjectLegacyIdGQL)
    private queryTextureLoaderDataObjectImageDescriptor = inject(QueryTextureLoaderDataObjectImageDescriptorGQL)
    private updateDataObjectImageDimensions = inject(UpdateDataObjectImageDimensionsGQL)
    private queryTextureSetDeletionData = inject(QueryTextureSetDeletionDataGQL)
    private deleteScanJob = inject(DeleteScanJobGQL)
    private mutateDeleteScanSubJob = inject(DeleteScanSubJobGQL)
    private queryDataObjectLegacyId = inject(QueryDataObjectLegacyIdGQL)
    private createMaterialAiJob = inject(CreateMaterialAiJobGQL)
    private createMaterialAiJobAssignment = inject(CreateMaterialAiJobAssignmentGQL)
    private cancelMaterialAiJob = inject(CancelMaterialAiJobGQL)

    async resolveDataObjectLegacyId(dataObjectId: string): Promise<number> {
        return await fetchThrowingErrors(this.queryDataObjectLegacyId)({id: dataObjectId}).then((result) => result.dataObject.legacyId)
    }

    async queryTextureSetRevision(textureSetRevisionId: string) {
        return await fetchThrowingErrors(this.queryTextureSetRevisionData)({id: textureSetRevisionId}).then((result) => result.textureSetRevision)
    }

    async queryDataObject(dataObjectId: string) {
        return await fetchThrowingErrors(this.queryTextureEditorDataObject)({dataObjectId}).then((result) => result.dataObject)
    }

    async deleteEmptyTextureSet(textureSetId: string) {
        console.info(`Deleting empty texture set ${textureSetId}`)
        // very data
        const data = await fetchThrowingErrors(this.queryTextureSetDeletionData)({id: textureSetId})
        if (data.textureSet.textureSetRevisions.length > 0) {
            throw Error(`Texture set ${textureSetId} is not empty`)
        }
        // remove all old-school texture revisions
        await Promise.all(
            data.textureSet.textures
                .flatMap((texture) => texture.revisions)
                .map((revision) => mutateThrowingErrors(this.mutateDeleteTextureRevision)({id: revision.id})),
        )
        // remove all old-school textures
        await Promise.all(data.textureSet.textures.map((texture) => mutateThrowingErrors(this.mutateDeleteTexture)({id: texture.id})))
        // remove scan job along with its sub-jobs
        if (data.textureSet.scanJob) {
            // remove sub-jobs
            await Promise.all(data.textureSet.scanJob.subJobs.map((subJob) => mutateThrowingErrors(this.mutateDeleteScanSubJob)({id: subJob.id})))
            // remove scan job
            await mutateThrowingErrors(this.deleteScanJob)({id: data.textureSet.scanJob.id})
        }
        // remove texture set
        await mutateThrowingErrors(this.mutateDeleteTextureSet)({id: textureSetId})
        // refresh texture group
        this.refreshService.item({
            id: data.textureSet.textureGroup.id,
            __typename: ContentTypeModel.TextureGroup,
        })
    }

    async deleteTextureSetRevision(textureSetRevisionId: string) {
        console.info(`Deleting texture set revision ${textureSetRevisionId}`)
        const result = await fetchThrowingErrors(this.queryTextureSetRevisionTextureRevisionIds)({id: textureSetRevisionId})
        await mutateThrowingErrors(this.deleteTextureSetRevisionGql)({id: textureSetRevisionId})
        // TODO remove this work-around once the implicit creation of texture set revisions from legacy texture revisions is removed
        if (result.textureSetRevision.textureSet.textureSetRevisions.length === 1) {
            // this was the last revision of the texture set, so we need to remove all legacy texture revisions as well, otherwise a new texture set revision will be recreated from those
            console.info(`Deleting all legacy textures and texture-revisions from texture-set ${result.textureSetRevision.textureSet.id}`)
            await Promise.all(
                result.textureSetRevision.textureSet.textures.flatMap((texture) =>
                    texture.revisions.map((revision) => mutateThrowingErrors(this.mutateDeleteTextureRevision)({id: revision.id})),
                ),
            )
            await Promise.all(result.textureSetRevision.textureSet.textures.map((texture) => mutateThrowingErrors(this.mutateDeleteTexture)({id: texture.id})))
        }
        // await Promise.all([
        //     ...result.textureSetRevision.mapAssignments.map((mapAssignment) => this.deleteDataObjectIfNotReferenced(mapAssignment.dataObject.id)),
        //     ...result.textureSetRevision.sourceDataObjectReferences.map((sourceDataObjectReference) =>
        //         this.deleteDataObjectIfNotReferenced(sourceDataObjectReference.dataObject.id),
        //     ),
        // ])
        this.refreshService.item({
            id: result.textureSetRevision.textureSet.id,
            __typename: ContentTypeModel.TextureSet,
        })
    }

    async getDataObjectLegacyId(dataObjectId: string) {
        return await fetchThrowingErrors(this.queryTextureEditDataObjectLegacyId)({id: dataObjectId}).then((result) => result.dataObject.legacyId)
    }

    async getDataObjectImageDescriptor(dataObjectId: string, options?: {requireJpg?: boolean}) {
        const requireJpg = options?.requireJpg ?? true
        let dataObject = await fetchThrowingErrors(this.queryTextureLoaderDataObjectImageDescriptor)({id: dataObjectId}).then((result) => result.dataObject)

        // here we do some stunt to update potentially missing width/height of the data object
        if (!dataObject.width || !dataObject.height) {
            // this data-object is missing width/height; try to download the image to get the dimensions
            console.warn(`Data object ${dataObjectId} is missing width/height; trying to download the image to get the dimensions and update the data object`)
            let width, height: number
            const jpgUrl = dataObject.jpgDataObject?.downloadUrl
            if (jpgUrl) {
                const image = new Image()
                image.src = jpgUrl
                await new Promise<void>((resolve) => {
                    image.onload = () => {
                        resolve()
                    }
                    image.onerror = (error) => {
                        throw Error(`Failed to load image: ${error}`)
                    }
                })
                width = image.width
                height = image.height
            } else {
                const image = await this.downloadAndDecodeEXR(dataObject.downloadUrlExr)
                width = image.width
                height = image.height
            }
            // update the data object
            await mutateThrowingErrors(this.updateDataObjectImageDimensions)({
                dataObjectId: dataObjectId,
                width,
                height,
            })
            // re-fetch the data object
            dataObject = await fetchThrowingErrors(this.queryTextureLoaderDataObjectImageDescriptor)({id: dataObjectId}).then((result) => result.dataObject)
            if (!dataObject.width || !dataObject.height) {
                throw Error(`Data object ${dataObjectId} is still missing width/height after attempting to update it`)
            }
        }

        if (requireJpg && !dataObject.jpgDataObject) {
            throw Error(`Data object ${dataObjectId} is missing a jpgDataObject and therefore is an incomplete image descriptor.`)
        }
        const dataObjectImageDescriptor: DataObjectImageDescriptor = {
            id: dataObject.id,
            legacyId: dataObject.legacyId,
            width: dataObject.width ?? 0,
            height: dataObject.height ?? 0,
            imageDataType: dataObject.imageDataType ?? ImageDataType.NonColor,
            imageColorSpace: dataObject.imageColorSpace ?? ImageColorSpace.Linear,
            downloadUrlExr: dataObject.downloadUrlExr,
            downloadUrlJpg: dataObject.jpgDataObject?.downloadUrl ?? "",
        }
        return dataObjectImageDescriptor
    }

    async createHalImageFromDataObject(
        imageCache: ImageCache,
        dataObjectId: string,
        options?: {
            downloadFormat?: "exr" | "jpg" // defaults to "jpg"
            preferredOutputFormat?: DataType // defaults to "float32"
        },
    ): Promise<HalImage> {
        const downloadFormat = options?.downloadFormat ?? "jpg"
        const outputFormat = options?.preferredOutputFormat ?? "float32"
        const requireJpg = downloadFormat === "jpg"
        const dataObjectImageDescriptor = await this.getDataObjectImageDescriptor(dataObjectId, {requireJpg})
        if (downloadFormat === "exr") {
            const image = await this.downloadAndDecodeEXR(dataObjectImageDescriptor.downloadUrlExr)
            return await this.halPaintableImageFromImageData(imageCache, image, outputFormat)
        } else {
            // const halImage = createHalPaintableImage(halContext)
            // await halImage.create({
            //     url: dataObjectImageDescriptor.downloadUrlJpg,
            //     options: {useSRgbFormat: dataObjectImageDescriptor.imageDataType === ImageDataType.Color},
            // })
            // load image
            const htmlImageElement = new Image()
            htmlImageElement.crossOrigin = "anonymous"
            await new Promise<void>((resolve) => {
                htmlImageElement.onload = async () => {
                    resolve()
                }
                htmlImageElement.onerror = (error) => {
                    throw Error("Failed to load image: " + error)
                }
                htmlImageElement.src = dataObjectImageDescriptor.downloadUrlJpg
            })
            const image = await imageCache.getImage({
                width: htmlImageElement.width,
                height: htmlImageElement.height,
                channelLayout: "RGB",
                dataType: dataObjectImageDescriptor.imageDataType === ImageDataType.Color ? "uint8srgb" : "uint8",
            })
            image.writeImageData({
                isSrgb: dataObjectImageDescriptor.imageDataType === ImageDataType.Color,
                data: htmlImageElement,
            })
            return image
        }
    }

    private async downloadAndDecodeEXR(url: string): Promise<TypedImageData> {
        const data = await fetch(url)
            .then((response) => response.blob())
            .then((blob) => blob.arrayBuffer())
        const decodedImage = await firstValueFrom(this.imageProcessingService.decodeEXR(new Uint8Array(data)))
        const convertedImage = Nodes.convert(Nodes.input(decodedImage), "float32", "L", false)
        const evaledImage = await firstValueFrom(this.imageProcessingService.evalGraph(convertedImage))
        return evaledImage.image
    }

    private async halPaintableImageFromImageData(imageCache: ImageCache, imageData: TypedImageData, outputFormat: DataType): Promise<HalImage> {
        if (imageData.channelLayout !== "L") {
            throw Error("imageData layout must be L")
        }
        if (imageData.dataType !== "float32") {
            throw Error("imageData dataTyoe must be float32")
        }
        // const halImage = createHalPaintableImage(halContext)
        // await halImage.create({
        //     width: imageData.width,
        //     height: imageData.height,
        //     channelLayout: "R",
        //     dataType: outputFormat,
        // })
        const halImage = imageCache.getImage({
            width: imageData.width,
            height: imageData.height,
            channelLayout: "R",
            dataType: outputFormat,
        })
        halImage.writeImageData(createTypedArrayImage(imageData.width, imageData.height, "R", new Float32Array(imageData.data.buffer)))
        return halImage
    }

    async startMaterialAiJob(textureSetRevisionId: string, inputMapType: TextureType = TextureType.Diffuse) {
        // get dataObject for diffuse texture of latest revision
        const textureSetRevision = await this.queryTextureSetRevision(textureSetRevisionId)
        const inputDataObjectId = textureSetRevision.mapAssignments.find((mapAssignment) => mapAssignment.textureType === inputMapType)?.dataObject?.id
        const inputDataObject = inputDataObjectId ? await this.queryDataObject(inputDataObjectId) : null
        if (!inputDataObject) {
            throw Error(`Texture set revision ${textureSetRevisionId} does not have a map assignment for ${inputMapType}`)
        }

        const generateDisplacement: boolean = false

        const generateTask = JobNodes.task(imageToPbrMaps, {
            input: JobNodes.value({
                inputImage: JobNodes.dataObjectReference(inputDataObject.legacyId),
                filterRadius: 128,
                generateDisplacement,
            }),
        })

        const processDataObject = (dataObject: JobNodes.TypedDataNode<JobNodes.DataObjectReference>) => {
            return JobNodes.get(
                JobNodes.task(uploadProcessingThumbnailsTask, {
                    input: JobNodes.task(uploadProcessingMetadataTask, {
                        input: JobNodes.struct({
                            dataObject,
                        }),
                    }),
                }),
                "dataObject",
            )
        }

        const mapDataObjectRefNodes = {
            diffuse: processDataObject(JobNodes.get(generateTask, "diffuse")),
            normal: processDataObject(JobNodes.get(generateTask, "normal")),
            roughness: processDataObject(JobNodes.get(generateTask, "roughness")),
            metalness: processDataObject(JobNodes.get(generateTask, "metalness")),
            anisotropy: processDataObject(JobNodes.get(generateTask, "anisotropy")),
            specularStrength: processDataObject(JobNodes.get(generateTask, "specularStrength")),
            displacement: generateDisplacement
                ? processDataObject(JobNodes.get(generateTask, "displacement") as JobNodes.TypedDataNode<JobNodes.DataObjectReference>)
                : undefined,
        }

        const newRevTask = JobNodes.task(Utility.TextureSetRevision.task, {
            input: JobNodes.struct({
                operation: JobNodes.value("create" as const),
                fields: JobNodes.struct({
                    textureSetId: JobNodes.value(textureSetRevision.textureSet.id),
                    name: JobNodes.value(`${textureSetRevision.name ?? "Untitled"} (Material AI)`),
                    width: JobNodes.value(textureSetRevision.width),
                    height: JobNodes.value(textureSetRevision.height),
                    // TODO: displacement scale
                    // TODO: updatedBy user?
                    createdById: JobNodes.value(textureSetRevision.createdBy ? textureSetRevision.createdBy.id : undefined),
                    mapAssignments: JobNodes.list([
                        JobNodes.struct({
                            textureType: JobNodes.value(TextureType.Diffuse),
                            dataObjectId: JobNodes.get(mapDataObjectRefNodes.diffuse, "dataObjectId"),
                        }),
                        JobNodes.struct({
                            textureType: JobNodes.value(TextureType.Normal),
                            dataObjectId: JobNodes.get(mapDataObjectRefNodes.normal, "dataObjectId"),
                        }),
                        JobNodes.struct({
                            textureType: JobNodes.value(TextureType.Roughness),
                            dataObjectId: JobNodes.get(mapDataObjectRefNodes.roughness, "dataObjectId"),
                        }),
                        JobNodes.struct({
                            textureType: JobNodes.value(TextureType.Metalness),
                            dataObjectId: JobNodes.get(mapDataObjectRefNodes.metalness, "dataObjectId"),
                        }),
                        JobNodes.struct({
                            textureType: JobNodes.value(TextureType.Anisotropy),
                            dataObjectId: JobNodes.get(mapDataObjectRefNodes.anisotropy, "dataObjectId"),
                        }),
                        JobNodes.struct({
                            textureType: JobNodes.value(TextureType.SpecularStrength),
                            dataObjectId: JobNodes.get(mapDataObjectRefNodes.specularStrength, "dataObjectId"),
                        }),
                        ...(mapDataObjectRefNodes.displacement
                            ? [
                                  JobNodes.struct({
                                      textureType: JobNodes.value(TextureType.Displacement),
                                      dataObjectId: JobNodes.get(mapDataObjectRefNodes.displacement, "dataObjectId"),
                                  }),
                              ]
                            : []),
                    ]),
                }),
            }),
        })

        const jobGraph = JobNodes.jobGraph(newRevTask, {
            progress: {
                type: "progressGroup",
                items: [
                    {
                        node: generateTask,
                        factor: 1,
                    },
                    {
                        node: newRevTask,
                        factor: 0.1,
                    },
                ],
            },
            platformVersion: Settings.APP_VERSION,
        })
        const result = await mutateThrowingErrors(this.createMaterialAiJob)({
            input: {
                name: `Material AI Job for TextureSet ${textureSetRevision.textureSet.legacyId} (revision ${textureSetRevision.id})`,
                organizationLegacyId: textureSetRevision.textureSet.textureGroup.organization.legacyId,
                graph: graphToJson(jobGraph, console),
            },
        })
        const jobId = result.createJob.id
        if (jobId === undefined) {
            throw new Error("Failed to create job")
        }
        await mutateThrowingErrors(this.createMaterialAiJobAssignment)({
            input: {
                objectId: textureSetRevision.textureSet.id,
                contentTypeModel: ContentTypeModel.TextureSet,
                jobId,
            },
        })
        return jobId
    }
}
