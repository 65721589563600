import * as Types from '../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type QueryTextureEditProcessingJobStateQueryVariables = Types.Exact<{
  textureSetRevisionId: Types.Scalars['ID']['input'];
}>;


export type QueryTextureEditProcessingJobStateQuery = { __typename: 'Query', textureSetRevision: { __typename: 'TextureSetRevision', editsProcessingJob?: { __typename: 'Job', id: string, state: Types.JobState, progress?: number | null } | null } };

export type QueryTextureSetReferencedMaterialInfoQueryVariables = Types.Exact<{
  materialRevisionId: Types.Scalars['ID']['input'];
}>;


export type QueryTextureSetReferencedMaterialInfoQuery = { __typename: 'Query', materialRevision: { __typename: 'MaterialRevision', id: string, legacyId: number, number: number, material: { __typename: 'Material', id: string, legacyId: number, name?: string | null } } };

export type CreateNewTextureSetRevisionMutationVariables = Types.Exact<{
  input: Types.MutationCreateTextureSetRevisionInput;
}>;


export type CreateNewTextureSetRevisionMutation = { __typename: 'Mutation', createTextureSetRevision: { __typename: 'TextureSetRevision', id: string } };

export const QueryTextureEditProcessingJobStateDocument = gql`
    query queryTextureEditProcessingJobState($textureSetRevisionId: ID!) {
  textureSetRevision(id: $textureSetRevisionId) {
    editsProcessingJob {
      id
      state
      progress
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureEditProcessingJobStateGQL extends Apollo.Query<QueryTextureEditProcessingJobStateQuery, QueryTextureEditProcessingJobStateQueryVariables> {
    override document = QueryTextureEditProcessingJobStateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryTextureSetReferencedMaterialInfoDocument = gql`
    query queryTextureSetReferencedMaterialInfo($materialRevisionId: ID!) {
  materialRevision(id: $materialRevisionId) {
    id
    legacyId
    number
    material {
      id
      legacyId
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureSetReferencedMaterialInfoGQL extends Apollo.Query<QueryTextureSetReferencedMaterialInfoQuery, QueryTextureSetReferencedMaterialInfoQueryVariables> {
    override document = QueryTextureSetReferencedMaterialInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateNewTextureSetRevisionDocument = gql`
    mutation createNewTextureSetRevision($input: MutationCreateTextureSetRevisionInput!) {
  createTextureSetRevision(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateNewTextureSetRevisionGQL extends Apollo.Mutation<CreateNewTextureSetRevisionMutation, CreateNewTextureSetRevisionMutationVariables> {
    override document = CreateNewTextureSetRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }