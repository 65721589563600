import {Component, ElementRef, inject} from "@angular/core"
import {OwnOrganizationsFilterComponent} from "@app/common/components/filters/own-organizations-filter/own-organizations-filter.component"
import {SelectMaterialItemFragment} from "@app/platform/components/materials/select-material/select-material.generated"
import {SelectDialogComponent} from "@common/components/dialogs/select-dialog/select-dialog.component"
import {SearchComponent} from "@common/components/inputs/search/search.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {Enums} from "@enums"
import {MaterialFilterInput} from "@generated"
import {Labels} from "@labels"
import {BaseSelectComponent} from "@platform/components/base/base-select/base-select.component"
import {SelectMaterialItemsGQL} from "@platform/components/materials/select-material/select-material.generated"

@Component({
    selector: "cm-select-material",
    imports: [SelectDialogComponent, SearchComponent, OwnOrganizationsFilterComponent],
    templateUrl: "./select-material.component.html",
    styleUrl: "./select-material.component.scss",
})
export class SelectMaterialComponent extends BaseSelectComponent<SelectMaterialItemFragment, MaterialFilterInput> {
    $data = this.implementDataLoader(fetchThrowingErrors(inject(SelectMaterialItemsGQL)))

    protected readonly Enums = Enums
    protected readonly Labels = Labels
    protected readonly ElementRef = ElementRef
}
