import {Component, Inject, signal} from "@angular/core"
import {JobState} from "@generated"
import {jobStateIconClass} from "@common/helpers/jobs"
import {jobStateColor} from "@common/helpers/jobs/color"
import {formatForDisplay, formatTimeDifference} from "@common/helpers/utils/dates"
import {TIPPY_REF, TippyInstance} from "@ngneat/helipopper"

@Component({
    selector: "cm-job-state-tooltip",
    imports: [],
    templateUrl: "./job-state-tooltip.component.html",
    styleUrl: "./job-state-tooltip.component.scss",
})
export class JobStateTooltipComponent {
    readonly $job = signal<{state: JobState; cancelled?: string; finished?: string; started?: string; progress?: number} | null>(null)

    constructor(@Inject(TIPPY_REF) tippy: TippyInstance) {
        this.$job.set(tippy.data)
    }

    protected readonly JobState = JobState
    protected readonly jobStateIconClass = jobStateIconClass
    protected readonly jobStateColor = jobStateColor
    protected readonly formatForDisplay = formatForDisplay
    protected readonly formatTimeDifference = formatTimeDifference
}
