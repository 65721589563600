import {Component, DestroyRef, inject, input, model, output} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {IdInFilter} from "@generated"
import {OrganizationsService} from "@app/common/services/organizations/organizations.service"
import {DataLoaderWithFilter} from "@platform/models/data"
import {DataLoaderService} from "@platform/services/data/data-loader/data-loader.service"
import {BehaviorSubject, debounceTime, combineLatest, map} from "rxjs"
import {FiltersService} from "@common/services/filters/filters.service"
import {DialogSize} from "@common/models/dialogs"
import {GridSize} from "@app/common/components/lists"
import {MAT_DIALOG_DATA} from "@angular/material/dialog"

@Component({
    selector: "cm-base-select",
    imports: [],
    template: "",
    styleUrl: "./base-select.component.scss",
})
export class BaseSelectComponent<EntityType extends {id: string}, FilterInputType extends {search?: string | null; organizationId?: IdInFilter | null}> {
    // fixed filters applied irrespective of user selected filters
    readonly $customFilters = model<Omit<FilterInputType, "search" | "organizationId"> | undefined>(undefined, {alias: "customFilters"})
    readonly selectItem = output<EntityType>()
    readonly done = output<void>()
    readonly cancel = output<void>()
    readonly $dialogSize = model(DialogSize.Large, {alias: "dialogSize"})
    readonly $gridSize = input<GridSize>("large", {alias: "gridSize"})
    private readonly data = inject<{dialogSize: DialogSize; customFilters: FilterInputType}>(MAT_DIALOG_DATA, {optional: true})

    constructor() {
        if (this.data) {
            this.$dialogSize.set(this.data.dialogSize)
            this.$customFilters.set(this.data.customFilters)
        }
    }

    pageFilledStatusChange$ = new BehaviorSubject(false)
    searchText$ = new BehaviorSubject("")
    organizationsIds$ = new BehaviorSubject<string[] | undefined>(undefined)

    readonly dataLoader = inject(DataLoaderService)
    readonly destroyRef = inject(DestroyRef)
    readonly filters = inject(FiltersService)
    readonly organizations = inject(OrganizationsService)

    // filters selected by the user within the dialog
    uiFilters$ = combineLatest([this.searchText$, this.organizationsIds$]).pipe(
        takeUntilDestroyed(),
        debounceTime(300),
        map(([search, maybeOrganizationIds]) => ({
            search,
            organizationId: maybeOrganizationIds ? {in: maybeOrganizationIds} : undefined,
        })),
    )

    implementDataLoader = (gqlQuery: DataLoaderWithFilter<EntityType, FilterInputType>) =>
        this.dataLoader.$batchedData<EntityType>(
            this.uiFilters$.pipe(
                takeUntilDestroyed(),
                map((uiFilters) => (skip: number, take: number) => {
                    return gqlQuery({
                        skip,
                        take,
                        filter: {
                            ...uiFilters,
                            ...this.$customFilters(),
                        } as FilterInputType,
                    })
                }),
            ),
            this.pageFilledStatusChange$.pipe(map((value) => !value)),
        )

    onSelectItem(item: EntityType) {
        this.selectItem.emit(item)
        this.done.emit()
    }

    cancelDialog() {
        this.cancel.emit()
        this.done.emit()
    }
}
