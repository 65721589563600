import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {DrawableImageHandle} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/drawable-image-handle"

export type ParameterType = {
    drawableImageHandle: DrawableImageHandle
}

export type ReturnType = ImageRef

const imageOpDrawableImage: ImageOpType<ParameterType, ReturnType> = {
    name: "DrawableImage",

    WebGL2: ({cmdQueue, parameters: {drawableImageHandle}}) => {
        return cmdQueue.createDrawableImageRef(drawableImageHandle)
    },

    ImgProc: ({cmdQueue, parameters: {drawableImageHandle}}) => {
        return cmdQueue.createDrawableImageRef(drawableImageHandle)
    },
}

export function drawableImage(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpDrawableImage, parameters)
}
