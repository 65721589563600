import {Component, inject, Signal} from "@angular/core"
import {Router} from "@angular/router"
import {FiltersService} from "@common/services/filters/filters.service"
import {InfiniteScrollService} from "@common/services/infinite-scroll/infinite-scroll.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {PagingService} from "@common/services/paging/paging.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {LoadedData} from "@platform/models/data"
import {DataLoaderService, SortOrderService} from "@platform/services/data"
import {PermissionsService} from "@common/services/permissions/permissions.service"

@Component({
    selector: "cm-base-table",
    imports: [],
    template: "",
    styleUrls: [],
})
export abstract class BaseTableComponent<EntityType> {
    readonly dataLoader = inject(DataLoaderService)
    readonly filters = inject(FiltersService)
    readonly infiniteScroll = inject(InfiniteScrollService)
    readonly notification = inject(NotificationsService)
    readonly paging = inject(PagingService)
    readonly permission = inject(PermissionsService)
    readonly refresh = inject(RefreshService)
    readonly router = inject(Router)
    readonly sortOrder = inject(SortOrderService)
    $can = this.permission.$to
    abstract $columns: Signal<string[]>
    abstract $data: Signal<LoadedData<EntityType>>
}
