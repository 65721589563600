import {IsBlobLike} from "#utils/blob"
import {extractBlobs, resolveBlobs} from "#utils/websocket/helpers"
import {CreateWebSocket, MessageData, MessageEvent, WebSocketImplementation} from "#utils/websocket/types"
import {Observable, ReplaySubject, Subject} from "rxjs"

export type CreateWebSocketMessagingConnection<RuntimeImplementation extends WebSocketImplementation> = (
    url: string,
    maxPayload?: number,
) => Observable<WebSocketMessagingConnection<RuntimeImplementation>>

export const implementCreateWebSocketMessagingConnection =
    <RuntimeImplementation extends WebSocketImplementation>(
        createWebSocket: CreateWebSocket<RuntimeImplementation>,
        isBlobLike: IsBlobLike,
    ): CreateWebSocketMessagingConnection<RuntimeImplementation> =>
    (url: string, maxPayload?: number) => {
        const socket = createWebSocket(url, maxPayload ? {maxPayload: maxPayload} : undefined)
        const connect$ = new Subject<WebSocketMessagingConnection<RuntimeImplementation>>()
        socket.onopen = (_event) => {
            connect$.next(new WebSocketMessagingConnection(socket, isBlobLike))
            connect$.complete()
        }
        socket.onerror = (event) => {
            connect$.error(event)
            connect$.complete()
        }
        return connect$
    }

export class WebSocketMessagingConnection<RuntimeImplementation extends WebSocketImplementation> {
    private transactions: Subject<any>[] = []

    message$ = new Subject<any>()
    close$ = new Subject<void>()

    constructor(
        private socket: RuntimeImplementation["WebSocket"],
        private isBlobLike: (suspectedBlob: unknown) => boolean,
    ) {
        socket.binaryType = "arraybuffer"
        socket.onerror = this.onError.bind(this)
        socket.onclose = this.onClose.bind(this)
        socket.onmessage = this.onMessage.bind(this)
    }

    destroy() {
        this.socket?.close()
        this.message$.complete()
    }

    get connected() {
        return this.socket.readyState === this.socket.OPEN
    }

    send(data: RuntimeImplementation["MessageData"]): void {
        const [msg, blobs] = extractBlobs(data, this.isBlobLike)
        for (let idx = 0; idx < blobs.length; idx++) {
            this.socket.send(JSON.stringify({$send_blob: idx}))
            this.socket.send(blobs[idx])
        }
        this.socket.send(JSON.stringify(msg))
    }

    doTransaction(msg: MessageData<RuntimeImplementation["MessageData"]>) {
        const response$ = new ReplaySubject<any>()
        this.transactions.push(response$)
        this.send(msg)
        return response$
    }

    private recvAwaitingBlob = false
    private recvBlobs: ArrayBufferView[] = []

    private dispatchMsg(msg: any) {
        const transaction = this.transactions.shift()
        if (transaction) {
            transaction.next(msg)
            transaction.complete()
        } else {
            this.message$.next(msg)
        }
    }

    private dispatchError(err: any) {
        const transaction = this.transactions.shift()
        if (transaction) {
            transaction.error(err)
            transaction.complete()
        } else {
            throw new Error(err)
        }
    }

    private onMessage(event: MessageEvent<MessageData<RuntimeImplementation["MessageData"]>>) {
        const msg = event.data
        if (msg instanceof ArrayBuffer) {
            if (!this.recvAwaitingBlob) {
                this.dispatchError("Expecting JSON, got binary blob!")
                return
            }
            this.recvBlobs.push(new Uint8Array(msg))
            this.recvAwaitingBlob = false
        } else if (ArrayBuffer.isView(msg)) {
            if (!this.recvAwaitingBlob) {
                this.dispatchError("Expecting JSON, got binary blob!")
                return
            }
            this.recvBlobs.push(new Uint8Array(msg.buffer, msg.byteOffset, msg.byteLength))
            this.recvAwaitingBlob = false
        } else if (this.isBlobLike(msg)) {
            this.dispatchError("Got unexpected binary blob type!")
            return
        } else {
            if (this.recvAwaitingBlob) {
                this.dispatchError("Expecting binary blob, got JSON!")
                return
            }
            let parsedObject: object = JSON.parse(msg as string)
            if (typeof parsedObject === "object" && "$send_blob" in parsedObject) {
                const idx = parsedObject["$send_blob"] as number
                if (idx !== this.recvBlobs.length) {
                    this.dispatchError("Got binary blob out of sequence!")
                    return
                }
                this.recvAwaitingBlob = true
            } else {
                parsedObject = resolveBlobs(parsedObject, this.recvBlobs)
                this.recvBlobs = []
                this.dispatchMsg(parsedObject)
            }
        }
    }

    private onError(event: any) {
        this.dispatchError(event)
    }

    private onClose(event: any) {
        while (true) {
            const transaction = this.transactions.shift()
            if (!transaction) break
            transaction.error("Connection closed")
            transaction.complete()
        }
        this.message$.complete()
        this.close$.next()
        this.close$.complete()
    }
}
