import {Box2, Box2Like, Size2Like, Vector2, Vector2Like} from "@cm/math"
import {HalContext} from "@common/models/hal/hal-context"
import {HalPainterImageCompositor} from "@common/models/hal/hal-painter-image-compositor"
import {HalPainterPrimitiveOptions} from "@common/models/hal/hal-painter-primitive/types"
import {HalPainter} from "@common/models/hal/hal-painter"
import {HalPainterParametersType} from "@app/common/models/hal/hal-painter/types"
import {createHalPainterImageCompositor} from "@common/models/hal/hal-painter-image-compositor/create"
import {HalPainterSourceImage, HalPainterTarget} from "@common/models/hal/hal-painter-primitive"

/**
 * Blits an image to a target image.
 * Blitting function is of the form:
 *      vec4 computeColor(ivec2 targetPixel, ivec2 sourcePixel) {
 *          return texelFetch0(sourcePixel);
 *      }
 */

export class HalPainterImageBlit implements HalPainter {
    constructor(
        readonly context: HalContext,
        blittingFunction?: string,
    ) {
        this.imageCompositor = createHalPainterImageCompositor(this.context, this.getCompositingFunction(blittingFunction ?? DEFAULT_BLITTING_FUNCTION))
    }

    dispose(): void {
        this.imageCompositor.dispose()
    }

    paint({target, parameters, sourceImages, options}: HalPainterImageBlitPaintArgs) {
        const primarySource = sourceImages instanceof Array ? sourceImages[0] : sourceImages
        const primarySourceSize: Size2Like = primarySource.descriptor
        const sourceRegion = options?.sourceRegion
            ? Box2.fromBox2Like(options?.sourceRegion)
            : Box2.fromPositionAndSize(
                  {
                      x: 0,
                      y: 0,
                  },
                  new Vector2(primarySourceSize.width, primarySourceSize.height),
              )
        const targetOffset = options?.targetOffset ?? {x: 0, y: 0}
        this.imageCompositor.paint({
            target,
            parameters: {
                ...parameters,
                u_internal_blitSourceOffset: {
                    type: "int2",
                    value: new Vector2(sourceRegion.x - targetOffset.x, sourceRegion.y - targetOffset.y),
                },
            },
            sourceImages,
            options: {
                ...options,
                targetRegion: Box2.fromPositionAndSize(targetOffset, sourceRegion.size),
            },
        })
    }

    private getCompositingFunction(blittingFunction: string): string {
        return `
            ${blittingFunction}
            
            uniform ivec2 u_internal_blitSourceOffset;

            vec4 computeColor(ivec2 targetPixel) {
                return computeColor(targetPixel, targetPixel + u_internal_blitSourceOffset);
            }
        `
    }

    public static create(context: HalContext, compositingFunction?: string): HalPainterImageBlit {
        return new HalPainterImageBlit(context, compositingFunction)
    }

    private imageCompositor: HalPainterImageCompositor
}

export type HalPainterImageBlitPaintArgs = {
    target: HalPainterTarget
    parameters?: HalPainterParametersType
    sourceImages: HalPainterSourceImage | [HalPainterSourceImage, ...(HalPainterSourceImage | undefined)[]]
    options?: HalPainterImageBlitOptions
}

export type HalPainterImageBlitOptions = HalPainterPrimitiveOptions & {
    sourceRegion?: Box2Like
    targetOffset?: Vector2Like
}

const DEFAULT_BLITTING_FUNCTION = `
    vec4 computeColor(ivec2 targetPixel, ivec2 sourcePixel) {
        return texelFetch0(sourcePixel);
    }
`
