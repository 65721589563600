import {CancelDeferredTask, DeferredTaskImplementation, QueueDeferredTask} from "@cm/utils"

export let queueDeferredTask: QueueDeferredTask
export let cancelDeferredTask: CancelDeferredTask

if (window.queueMicrotask) {
    queueDeferredTask = function (fn: () => void) {
        window.queueMicrotask(fn)
        return 1
    }

    cancelDeferredTask = function (handle: number) {}
} else {
    queueDeferredTask = window.setTimeout
    cancelDeferredTask = window.clearTimeout
}

export const BrowserDeferredTaskImplementation: DeferredTaskImplementation = {queueDeferredTask, cancelDeferredTask}

export class DeferredBatchCall {
    constructor(private _fn: () => void) {}

    get isPending(): boolean {
        return this._handle !== undefined
    }

    schedule() {
        if (this._handle === undefined) {
            this._handle = queueDeferredTask(() => this.call())
        }
    }

    cancel() {
        if (this._handle !== undefined) {
            cancelDeferredTask(this._handle)
            this._handle = undefined
        }
    }

    private call() {
        this._handle = undefined
        this._fn()
    }

    private _handle: number | undefined = undefined
}

class Debouncer<T extends unknown[]> {
    private args?: T
    private scheduled = false
    private fn: () => void

    constructor(fn: (...args: T) => void) {
        this.fn = () => {
            if (this.scheduled) {
                const args = this.args!
                this.scheduled = false
                this.args = undefined
                fn(...args)
            }
        }
    }

    trigger(...args: T) {
        if (!this.scheduled) {
            this.args = args
            this.scheduled = true
            queueDeferredTask(this.fn)
        }
    }
}

export function debounceFunction<T extends unknown[]>(fn: (...args: T) => void): typeof fn {
    const d = new Debouncer(fn)
    return d.trigger.bind(d as any) as any
}
