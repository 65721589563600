import {JobState} from "@generated"
import {StateLabel} from "@platform/models/state-labels/state-label"

export const JobStateLabels: Map<JobState, StateLabel<JobState>> = new Map<JobState, StateLabel<JobState>>([
    // Runnable and Running jobs are grouped together, because they are both represented by the same DB value
    // the difference is that Running jobs have at least one running job task
    // and this is not considered by the backend filtering logic
    [JobState.Running, {state: JobState.Running, label: "Runnable/Running"}],
    [JobState.Complete, {state: JobState.Complete, label: "Complete"}],
    [JobState.Cancelled, {state: JobState.Cancelled, label: "Cancelled"}],
    [JobState.Failed, {state: JobState.Failed, label: "Failed"}],
    [JobState.Init, {state: JobState.Init, label: "Init"}],
])
