import {ImageProcessingNodes as Nodes} from "@cm/image-processing-nodes"
import {assertNever} from "@cm/utils"
import {TypedImageData} from "@cm/utils/typed-image-data"
import {AddressMode} from "app/textures/texture-editor/operator-stack/image-op-system/detail/common-types"
import {ChannelLayout, DataType} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {IMGPROC_USE_FLOAT32_EVERYWHERE} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-imgproc"

export function getImgProcChannelLayout(channelLayout: ChannelLayout): TypedImageData["channelLayout"] {
    switch (channelLayout) {
        case "R":
            return "L"
        case "RGB":
            return "RGB"
        case "RGBA":
            return "RGBA"
        default:
            assertNever(channelLayout)
    }
}

export function getImgProcDataType(format: DataType): TypedImageData["dataType"] {
    if (IMGPROC_USE_FLOAT32_EVERYWHERE) {
        return "float32"
    }
    switch (format) {
        case "uint8":
        case "uint8srgb":
            return "uint8"
        case "float32":
            return "float32"
        case "float16":
            return "float16"
        default:
            assertNever(format)
    }
}

export function getImgProcPixelAddressMode(addressMode: AddressMode): Nodes.PixelAddressMode {
    switch (addressMode) {
        case "clamp":
            return "clamp"
        case "wrap":
            return "wrap"
        case "wrap-mirrored":
            throw new Error("Mirror address mode is not supported by ImgProc")
        case "border":
            return "border"
        default:
            assertNever(addressMode)
    }
}
