import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TemplateDetailsForTemplateTreeAddFragment = { __typename: 'Template', id: string, legacyId: number, name?: string | null, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number } | null };

export type GetTemplateDetailsForTemplateTreeAddQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetTemplateDetailsForTemplateTreeAddQuery = { __typename: 'Query', template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number } | null } };

export type MaterialDetailsForTemplateTreeAddFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null };

export type GetMaterialDetailsForTemplateTreeAddQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetMaterialDetailsForTemplateTreeAddQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null } };

export const TemplateDetailsForTemplateTreeAddFragmentDoc = gql`
    fragment TemplateDetailsForTemplateTreeAdd on Template {
  id
  legacyId
  name
  latestRevision {
    id
    legacyId
  }
}
    `;
export const MaterialDetailsForTemplateTreeAddFragmentDoc = gql`
    fragment MaterialDetailsForTemplateTreeAdd on Material {
  id
  legacyId
  name
  latestCyclesRevision {
    id
    legacyId
  }
}
    `;
export const GetTemplateDetailsForTemplateTreeAddDocument = gql`
    query getTemplateDetailsForTemplateTreeAdd($id: ID!) {
  template(id: $id) {
    ...TemplateDetailsForTemplateTreeAdd
  }
}
    ${TemplateDetailsForTemplateTreeAddFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateDetailsForTemplateTreeAddGQL extends Apollo.Query<GetTemplateDetailsForTemplateTreeAddQuery, GetTemplateDetailsForTemplateTreeAddQueryVariables> {
    override document = GetTemplateDetailsForTemplateTreeAddDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMaterialDetailsForTemplateTreeAddDocument = gql`
    query getMaterialDetailsForTemplateTreeAdd($id: ID!) {
  material(id: $id) {
    ...MaterialDetailsForTemplateTreeAdd
  }
}
    ${MaterialDetailsForTemplateTreeAddFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialDetailsForTemplateTreeAddGQL extends Apollo.Query<GetMaterialDetailsForTemplateTreeAddQuery, GetMaterialDetailsForTemplateTreeAddQueryVariables> {
    override document = GetMaterialDetailsForTemplateTreeAddDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }