import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TemplatesSectionItemFragment = { __typename: 'Template', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'TemplateRevision', renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null, organization: { __typename: 'Organization', id: string, name?: string | null } };

export type TemplatesSectionItemsForMaterialQueryVariables = Types.Exact<{
  materialId: Types.Scalars['ID']['input'];
}>;


export type TemplatesSectionItemsForMaterialQuery = { __typename: 'Query', material: { __typename: 'Material', revisions: Array<{ __typename: 'MaterialRevision', templateRevisions: Array<{ __typename: 'TemplateRevision', template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'TemplateRevision', renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null, organization: { __typename: 'Organization', id: string, name?: string | null } } }> }> } };

export type TemplatesSectionItemsForTemplateQueryVariables = Types.Exact<{
  templateId: Types.Scalars['ID']['input'];
}>;


export type TemplatesSectionItemsForTemplateQuery = { __typename: 'Query', template: { __typename: 'Template', revisions: Array<{ __typename: 'TemplateRevision', referencedBy: Array<{ __typename: 'TemplateRevision', template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'TemplateRevision', renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null, organization: { __typename: 'Organization', id: string, name?: string | null } } }> }> } };

export const TemplatesSectionItemFragmentDoc = gql`
    fragment TemplatesSectionItem on Template {
  id
  legacyId
  name
  galleryImage {
    id
  }
  latestRevision {
    renderedImages(take: 4) {
      id
    }
  }
  organization {
    id
    name
  }
}
    `;
export const TemplatesSectionItemsForMaterialDocument = gql`
    query templatesSectionItemsForMaterial($materialId: ID!) {
  material(id: $materialId) {
    revisions {
      templateRevisions {
        template {
          ...TemplatesSectionItem
        }
      }
    }
  }
}
    ${TemplatesSectionItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplatesSectionItemsForMaterialGQL extends Apollo.Query<TemplatesSectionItemsForMaterialQuery, TemplatesSectionItemsForMaterialQueryVariables> {
    override document = TemplatesSectionItemsForMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplatesSectionItemsForTemplateDocument = gql`
    query templatesSectionItemsForTemplate($templateId: ID!) {
  template(id: $templateId) {
    revisions {
      referencedBy {
        template {
          ...TemplatesSectionItem
        }
      }
    }
  }
}
    ${TemplatesSectionItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplatesSectionItemsForTemplateGQL extends Apollo.Query<TemplatesSectionItemsForTemplateQuery, TemplatesSectionItemsForTemplateQueryVariables> {
    override document = TemplatesSectionItemsForTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }