import {BrushToolboxItem} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"
import {ImageOpContextWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-webgl2"
import {drawableImage} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-drawable-image"
import {Box2, Size2Like} from "@cm/math"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {DrawableImageCache} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/drawable-image-cache"
import {takeUntil} from "rxjs"
import {assertNever} from "@cm/utils"
import {DrawableImageHandle} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/drawable-image-handle"

export class DrawableImageBrushToolboxItem extends BrushToolboxItem {
    constructor(parent: CanvasBaseToolboxItem, imageOpContext: ImageOpContextWebGL2) {
        super(parent, imageOpContext)
        if (!imageOpContext.drawableImageCache) {
            throw Error("Drawable image cache is not set")
        }
        this.drawableImageCache = imageOpContext.drawableImageCache
        this.drawableImageCache.imageInstanceUpdated.pipe(takeUntil(this.unsubscribe)).subscribe((event) => this.onImageInstanceUpdated(event.id, "clamp"))
    }

    setDrawableImage(handle: DrawableImageHandle | undefined, boundingBoxHandling: "reset" | "fill") {
        if (this._drawableImageHandle === handle) {
            return
        }
        this._drawableImageHandle = handle
        if (this._drawableImageHandle) {
            this.onImageInstanceUpdated(this._drawableImageHandle.id, boundingBoxHandling)
        }
    }

    flushBrushStrokesExt(cmdQueue: ImageOpCommandQueue, size: Size2Like) {
        if (!this._drawableImageHandle) {
            throw Error("Drawable image handle is not set")
        }
        cmdQueue.setDrawableImageSize(this._drawableImageHandle, size)
        const resultImage = drawableImage(cmdQueue, {
            drawableImageHandle: this._drawableImageHandle,
        })
        if (this.flushBrushStrokes(cmdQueue, resultImage)) {
            this.drawableImageCache.markDirty(this._drawableImageHandle.id)
        }
    }

    private onImageInstanceUpdated(id: number, boundingBoxHandling: "clamp" | "reset" | "fill") {
        if (this._drawableImageHandle && this._drawableImageHandle.id === id) {
            const descriptor = this.drawableImageCache.getImageDescriptor(id)
            switch (boundingBoxHandling) {
                case "clamp":
                    this.boundingBox.intersectInPlace(Box2.fromSize(descriptor)) // clamp to new image size
                    break
                case "reset":
                    this.boundingBox.makeEmpty()
                    break
                case "fill":
                    this.boundingBox = Box2.fromSize(descriptor)
                    break
                default:
                    assertNever(boundingBoxHandling)
            }
        }
    }

    readonly drawableImageCache: DrawableImageCache
    private _drawableImageHandle: DrawableImageHandle | undefined
}
