import {Component, computed} from "@angular/core"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {ValueSlotComponent} from "../../value-slot/value-slot.component"
import {Value} from "@cm/template-nodes/node-types"
import {getTemplateNodeClassLabel} from "@cm/template-nodes"

@Component({
    selector: "cm-value-inspector",
    templateUrl: "./value-inspector.component.html",
    styleUrl: "./value-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent],
})
export class ValueInspectorComponent extends BaseInspectorComponent<Value> {
    readonly $class = computed(() => getTemplateNodeClassLabel(this.$node()))
}
