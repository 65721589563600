import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { OrganizationDetailsFragmentDoc } from '../../../pages/organizations/organizations-page.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type OrganizationDetailsQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type OrganizationDetailsQuery = { __typename: 'Query', item: { __typename: 'Organization', id: string, legacyId: number, name?: string | null, url?: string | null, visibleInFilters?: boolean | null, salesEmail?: string | null, defaultScannerUrl?: string | null, type?: Types.OrganizationType | null, galleryImage?: { __typename: 'DataObject', id: string } | null, nodeEditorTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerMaterial?: { __typename: 'Material', legacyId: number } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: Types.DataObjectAssignmentType, dataObject: { __typename: 'DataObject', state: Types.DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } }> } };

export const OrganizationDetailsDocument = gql`
    query organizationDetails($id: ID, $legacyId: Int) {
  item: organization(id: $id, legacyId: $legacyId) {
    ...OrganizationDetails
  }
}
    ${OrganizationDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationDetailsGQL extends Apollo.Query<OrganizationDetailsQuery, OrganizationDetailsQueryVariables> {
    override document = OrganizationDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }