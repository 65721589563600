<cm-select-dialog
    [data]="$data()"
    (selectItem)="onSelectItem($event)"
    [title]="'Select model'"
    [pageFilledStatusChange]="pageFilledStatusChange$"
    [gridSize]="$gridSize()"
    (cancel)="cancelDialog()"
>
    <cm-search cm-filters (valueChange)="searchText$.next($event)" placeholder="Name, description, ID, ..."></cm-search>
</cm-select-dialog>
