import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MaterialDownloadDataFragment = { __typename: 'DataObject', id: string, legacyId: number, downloadUrl: string, mediaType?: string | null, originalFileName: string };

export type MaterialDetailsFragment = { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, description?: string | null, locked: boolean, name?: string | null, nextActor: Types.NextActor, priorityOrder?: any | null, state: Types.MaterialState, public: boolean, meshSpecific: boolean, sampleArrival?: string | null, comment?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', firstName?: string | null, lastName?: string | null } | null } | null, latestRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, createdAt: string, number: number, createdBy?: { __typename: 'User', name: string, firstName?: string | null, lastName?: string | null } | null } | null, organization?: { __typename: 'Organization', id: string, legacyId: number } | null, textureGroup?: { __typename: 'TextureGroup', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string }, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, name?: string | null }> } | null, iconAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, related: Array<{ __typename: 'DataObject', id: string, legacyId: number, downloadUrl: string, mediaType?: string | null, originalFileName: string }> } }> };

export type MaterialDetailsQueryVariables = Types.Exact<{
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type MaterialDetailsQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, description?: string | null, locked: boolean, name?: string | null, nextActor: Types.NextActor, priorityOrder?: any | null, state: Types.MaterialState, public: boolean, meshSpecific: boolean, sampleArrival?: string | null, comment?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', firstName?: string | null, lastName?: string | null } | null } | null, latestRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, createdAt: string, number: number, createdBy?: { __typename: 'User', name: string, firstName?: string | null, lastName?: string | null } | null } | null, organization?: { __typename: 'Organization', id: string, legacyId: number } | null, textureGroup?: { __typename: 'TextureGroup', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string }, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, name?: string | null }> } | null, iconAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, related: Array<{ __typename: 'DataObject', id: string, legacyId: number, downloadUrl: string, mediaType?: string | null, originalFileName: string }> } }> } };

export type MaterialDetailsDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MaterialDetailsDeleteMutation = { __typename: 'Mutation', deleteMaterial?: any | null };

export type MaterialDetailsDeleteDataObjectMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MaterialDetailsDeleteDataObjectMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export type GetMaterialDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetMaterialDetailsQuery = { __typename: 'Query', item: { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, description?: string | null, locked: boolean, name?: string | null, nextActor: Types.NextActor, priorityOrder?: any | null, state: Types.MaterialState, public: boolean, meshSpecific: boolean, sampleArrival?: string | null, comment?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', firstName?: string | null, lastName?: string | null } | null } | null, latestRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, createdAt: string, number: number, createdBy?: { __typename: 'User', name: string, firstName?: string | null, lastName?: string | null } | null } | null, organization?: { __typename: 'Organization', id: string, legacyId: number } | null, textureGroup?: { __typename: 'TextureGroup', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string }, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, name?: string | null }> } | null, iconAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, related: Array<{ __typename: 'DataObject', id: string, legacyId: number, downloadUrl: string, mediaType?: string | null, originalFileName: string }> } }> } };

export type UpdateMaterialDetailsMutationVariables = Types.Exact<{
  input: Types.MutationUpdateMaterialInput;
}>;


export type UpdateMaterialDetailsMutation = { __typename: 'Mutation', item: { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, description?: string | null, locked: boolean, name?: string | null, nextActor: Types.NextActor, priorityOrder?: any | null, state: Types.MaterialState, public: boolean, meshSpecific: boolean, sampleArrival?: string | null, comment?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', firstName?: string | null, lastName?: string | null } | null } | null, latestRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, createdAt: string, number: number, createdBy?: { __typename: 'User', name: string, firstName?: string | null, lastName?: string | null } | null } | null, organization?: { __typename: 'Organization', id: string, legacyId: number } | null, textureGroup?: { __typename: 'TextureGroup', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string }, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, name?: string | null }> } | null, iconAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, related: Array<{ __typename: 'DataObject', id: string, legacyId: number, downloadUrl: string, mediaType?: string | null, originalFileName: string }> } }> } };

export type MaterialDetailsCreateTextureGroupMutationVariables = Types.Exact<{
  input: Types.MutationCreateTextureGroupInput;
}>;


export type MaterialDetailsCreateTextureGroupMutation = { __typename: 'Mutation', createTextureGroup: { __typename: 'TextureGroup', id: string } };

export type MaterialDetailsIconDataObjectQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MaterialDetailsIconDataObjectQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, thumbnailProcessingJob?: { __typename: 'Job', state: Types.JobState } | null } };

export type MaterialDetailsCreateTextureSetMutationVariables = Types.Exact<{
  input: Types.MutationCreateTextureSetInput;
}>;


export type MaterialDetailsCreateTextureSetMutation = { __typename: 'Mutation', createTextureSet: { __typename: 'TextureSet', id: string } };

export type MaterialDetailsDeleteIconAssignmentMutationVariables = Types.Exact<{
  dataObjectAssignmentId: Types.Scalars['ID']['input'];
}>;


export type MaterialDetailsDeleteIconAssignmentMutation = { __typename: 'Mutation', deleteDataObjectAssignment?: any | null };

export type MaterialDetailsCreateIconAssignmentMutationVariables = Types.Exact<{
  objectId: Types.Scalars['ID']['input'];
  dataObjectId: Types.Scalars['ID']['input'];
}>;


export type MaterialDetailsCreateIconAssignmentMutation = { __typename: 'Mutation', createDataObjectAssignment: { __typename: 'DataObjectAssignment', id: string } };

export const MaterialDownloadDataFragmentDoc = gql`
    fragment MaterialDownloadData on DataObject {
  id
  legacyId
  downloadUrl
  mediaType
  originalFileName
}
    `;
export const MaterialDetailsFragmentDoc = gql`
    fragment MaterialDetails on Material {
  __typename
  id
  legacyId
  articleId
  description
  locked
  galleryImage {
    id
  }
  latestCyclesRevision {
    id
    createdAt
    number
    createdBy {
      firstName
      lastName
    }
  }
  latestRevision {
    id
    legacyId
    createdAt
    number
    createdBy {
      name
      firstName
      lastName
    }
  }
  name
  nextActor
  priorityOrder
  state
  organization {
    id
    legacyId
  }
  textureGroup {
    id
    legacyId
    name
    organization {
      id
    }
    textureSets {
      id
      legacyId
      name
    }
  }
  iconAssignments: dataObjectAssignments(filter: {assignmentType: Icon}) {
    id
    dataObject {
      id
      related {
        ...MaterialDownloadData
      }
    }
  }
  public
  meshSpecific
  sampleArrival
  comment
}
    `;
export const MaterialDetailsDocument = gql`
    query materialDetails($legacyId: Int, $id: ID) {
  material(legacyId: $legacyId, id: $id) {
    ...MaterialDetails
  }
}
    ${MaterialDetailsFragmentDoc}
${MaterialDownloadDataFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialDetailsGQL extends Apollo.Query<MaterialDetailsQuery, MaterialDetailsQueryVariables> {
    override document = MaterialDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialDetailsDeleteDocument = gql`
    mutation materialDetailsDelete($id: ID!) {
  deleteMaterial(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialDetailsDeleteGQL extends Apollo.Mutation<MaterialDetailsDeleteMutation, MaterialDetailsDeleteMutationVariables> {
    override document = MaterialDetailsDeleteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialDetailsDeleteDataObjectDocument = gql`
    mutation materialDetailsDeleteDataObject($id: ID!) {
  deleteDataObject(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialDetailsDeleteDataObjectGQL extends Apollo.Mutation<MaterialDetailsDeleteDataObjectMutation, MaterialDetailsDeleteDataObjectMutationVariables> {
    override document = MaterialDetailsDeleteDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMaterialDetailsDocument = gql`
    query getMaterialDetails($id: ID!) {
  item: material(id: $id) {
    ...MaterialDetails
  }
}
    ${MaterialDetailsFragmentDoc}
${MaterialDownloadDataFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialDetailsGQL extends Apollo.Query<GetMaterialDetailsQuery, GetMaterialDetailsQueryVariables> {
    override document = GetMaterialDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMaterialDetailsDocument = gql`
    mutation updateMaterialDetails($input: MutationUpdateMaterialInput!) {
  item: updateMaterial(input: $input) {
    ...MaterialDetails
  }
}
    ${MaterialDetailsFragmentDoc}
${MaterialDownloadDataFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMaterialDetailsGQL extends Apollo.Mutation<UpdateMaterialDetailsMutation, UpdateMaterialDetailsMutationVariables> {
    override document = UpdateMaterialDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialDetailsCreateTextureGroupDocument = gql`
    mutation materialDetailsCreateTextureGroup($input: MutationCreateTextureGroupInput!) {
  createTextureGroup(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialDetailsCreateTextureGroupGQL extends Apollo.Mutation<MaterialDetailsCreateTextureGroupMutation, MaterialDetailsCreateTextureGroupMutationVariables> {
    override document = MaterialDetailsCreateTextureGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialDetailsIconDataObjectDocument = gql`
    query materialDetailsIconDataObject($id: ID!) {
  dataObject(id: $id) {
    id
    thumbnailProcessingJob {
      state
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialDetailsIconDataObjectGQL extends Apollo.Query<MaterialDetailsIconDataObjectQuery, MaterialDetailsIconDataObjectQueryVariables> {
    override document = MaterialDetailsIconDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialDetailsCreateTextureSetDocument = gql`
    mutation materialDetailsCreateTextureSet($input: MutationCreateTextureSetInput!) {
  createTextureSet(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialDetailsCreateTextureSetGQL extends Apollo.Mutation<MaterialDetailsCreateTextureSetMutation, MaterialDetailsCreateTextureSetMutationVariables> {
    override document = MaterialDetailsCreateTextureSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialDetailsDeleteIconAssignmentDocument = gql`
    mutation materialDetailsDeleteIconAssignment($dataObjectAssignmentId: ID!) {
  deleteDataObjectAssignment(id: $dataObjectAssignmentId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialDetailsDeleteIconAssignmentGQL extends Apollo.Mutation<MaterialDetailsDeleteIconAssignmentMutation, MaterialDetailsDeleteIconAssignmentMutationVariables> {
    override document = MaterialDetailsDeleteIconAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialDetailsCreateIconAssignmentDocument = gql`
    mutation materialDetailsCreateIconAssignment($objectId: ID!, $dataObjectId: ID!) {
  createDataObjectAssignment(
    input: {objectId: $objectId, dataObjectId: $dataObjectId, type: Icon, contentTypeModel: Material}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialDetailsCreateIconAssignmentGQL extends Apollo.Mutation<MaterialDetailsCreateIconAssignmentMutation, MaterialDetailsCreateIconAssignmentMutationVariables> {
    override document = MaterialDetailsCreateIconAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }