import {Component, inject, output} from "@angular/core"
import {FormGroupDirective, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {ErrorStateMatcher} from "@angular/material/core"
import {MatDialogModule, MatDialogRef, MatDialogTitle} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {DialogComponent} from "@common/components/dialogs/dialog/dialog.component"

@Component({
    selector: "cm-password-change-dialog",
    templateUrl: "./password-change-dialog.component.html",
    styleUrls: ["./password-change-dialog.component.scss"],
    imports: [MatDialogTitle, MatDialogModule, ReactiveFormsModule, MatInputModule, MatButtonModule],
})
export class PasswordChangeDialogComponent {
    readonly onConfirm = output<string>()
    newPassword = ""
    confirmPassword = ""
    matcher = new PasswordErrorStateMatcher()
    readonly formBuilder = inject(UntypedFormBuilder)
    readonly dialogRef = inject(MatDialogRef<DialogComponent>)

    changePasswordForm = this.formBuilder.group(
        {
            newPasswordControl: new UntypedFormControl("", [Validators.required]),
            confirmPasswordControl: new UntypedFormControl(""),
        },
        {validator: this.checkPasswords},
    )

    checkPasswords(group: UntypedFormGroup) {
        // here we have the 'passwords' group
        const password = group.get("newPasswordControl")?.value
        const confirmPassword = group.get("confirmPasswordControl")?.value
        return password === confirmPassword ? null : {notSame: true}
    }

    confirmAction(): void {
        this.onConfirm.emit(this.newPassword)
        this.dialogRef.close(true)
    }

    cancelAction(): void {
        this.dialogRef.close(false)
    }
}

export class PasswordErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, _form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && control.parent?.dirty && control.touched)
        const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty && control.touched)
        return invalidCtrl || invalidParent
    }
}
