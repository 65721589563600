import {Component, inject, OnInit, input} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatDatepicker, MatDatepickerInput} from "@angular/material/datepicker"
import {MatInputModule} from "@angular/material/input"
import {ArStatisticsFilterInput, SceneStatisticsFilterInput} from "@generated"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {PictureArStatisticsGQL, PictureSceneStatisticsGQL} from "@platform/components/pictures/picture-statistics/picture-statistics.generated"
import {endOfDay} from "date-fns/endOfDay"
import {startOfDay} from "date-fns/startOfDay"

@Component({
    selector: "cm-picture-statistics",
    imports: [FormsModule, MatDatepickerInput, MatDatepicker, MatInputModule],
    templateUrl: "./picture-statistics.component.html",
    styleUrl: "./picture-statistics.component.scss",
})
export class PictureStatisticsComponent implements OnInit {
    fromDate = startOfDay(new Date())
    toDate = endOfDay(new Date())
    datePickerMaxValue: Date = new Date()

    readonly $templateId = input<string>(undefined, {alias: "templateId"})
    readonly $templateRevisionId = input<string>(undefined, {alias: "templateRevisionId"})

    readonly arStatisticsGql = inject(PictureArStatisticsGQL)
    readonly sceneStatisticsGql = inject(PictureSceneStatisticsGQL)

    sceneStatisticsItemsCount = 0
    arStatisticsItemsCount = 0

    ngOnInit() {
        void this.updateStatistics()
    }

    async updateStatistics() {
        this.fromDate = startOfDay(new Date())
        this.toDate = endOfDay(new Date())
        const templateRevisionId = this.$templateRevisionId()
        if (templateRevisionId) {
            const filter: ArStatisticsFilterInput = {
                templateRevisionId: {equals: templateRevisionId},
                created: {gte: this.fromDate.toJSON(), lte: this.toDate.toJSON()},
            }
            const {arStatisticsItemsCount} = await fetchThrowingErrors(this.arStatisticsGql)({filter})
            this.arStatisticsItemsCount = arStatisticsItemsCount
        }
        const templateId = this.$templateId()
        if (templateId) {
            const filter: SceneStatisticsFilterInput = {
                templateId: {equals: templateId},
                created: {gte: this.fromDate.toJSON(), lte: this.toDate.toJSON()},
            }
            const {sceneStatisticsItemsCount} = await fetchThrowingErrors(this.sceneStatisticsGql)({filter})
            this.sceneStatisticsItemsCount = sceneStatisticsItemsCount
        }
    }
}
