import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {threeConvert, threeValueNode} from "#material-nodes/three-utils"
import {getAll} from "@cm/graph"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({value: materialSlots})
const InputTypeSchema = z.object({
    value: materialSlots.optional(),
    fromMin: materialSlots.optional(),
    fromMax: materialSlots.optional(),
    toMin: materialSlots.optional(),
    toMax: materialSlots.optional(),
    steps: materialSlots.optional(),
    result: materialSlots.optional(),
})
const ParametersTypeSchema = z.object({
    dataType: z.enum(["float", "vector"]).optional(),
    interpolationType: z.enum(["linear", "steppedLinear", "smoothStep", "smootherStep"]).optional(),
    clamp: z.boolean().optional(),
    value: z.number().optional(),
    fromMin: z.number().optional(),
    fromMax: z.number().optional(),
    toMin: z.number().optional(),
    toMax: z.number().optional(),
})

export class MapRange extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {value, fromMin, fromMax, toMin, toMax} = await getAll(inputs, get)
            const fromMinParam = fromMin ?? threeConvert(parameters.fromMin, threeValueNode) ?? 0
            const fromMaxParam = fromMax ?? threeConvert(parameters.fromMax, threeValueNode) ?? 1
            const toMinParam = toMin ?? threeConvert(parameters.toMin, threeValueNode) ?? 0
            const toMaxParam = toMax ?? threeConvert(parameters.toMax, threeValueNode) ?? 1
            const valueParam = value ?? threeConvert(parameters.value, threeValueNode) ?? 0
            const clampedValue = parameters.clamp ? THREENodes.max(THREENodes.min(valueParam, fromMaxParam), fromMinParam) : valueParam
            const normalizedValue = THREENodes.div(THREENodes.sub(clampedValue, fromMinParam), THREENodes.sub(fromMaxParam, fromMinParam))
            // TODO: support interpolation types other than linear, take steps into account
            return {value: THREENodes.add(THREENodes.mul(THREENodes.sub(toMaxParam, toMinParam), normalizedValue), toMinParam)}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
