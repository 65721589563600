import {MaterialMapsExporter} from "@cm/material-nodes/material-maps-exporter"
import {MaterialOutputType} from "@generated"
import {StateLabel} from "@platform/models/state-labels/state-label"

export type PbrExportOption = {
    format: MaterialMapsExporter.Format
    resolution: MaterialMapsExporter.Resolution
}

export const PbrExportOptionLabels = new Map<MaterialOutputType, StateLabel<PbrExportOption>>([
    [
        MaterialOutputType.PbrExportDefaultJpegLow,
        {
            label: "JPEG - Low (72 DPI)",
            state: {
                format: "jpeg",
                resolution: "dpi72",
            },
        },
    ],
    [
        MaterialOutputType.PbrExportDefaultJpegOriginal,
        {
            label: "JPEG - Original",
            state: {
                format: "jpeg",
                resolution: "original",
            },
        },
    ],
    [
        MaterialOutputType.PbrExportDefaultTiffLow,
        {
            label: "TIFF - Low (72 DPI)",
            state: {
                format: "tiff",
                resolution: "dpi72",
            },
        },
    ],
    [
        MaterialOutputType.PbrExportDefaultTiffOriginal,
        {
            label: "TIFF - Original",
            state: {
                format: "tiff",
                resolution: "original",
            },
        },
    ],
    [
        MaterialOutputType.PbrExportDefaultExrOriginal,
        {
            label: "EXR - Original",
            state: {
                format: "exr",
                resolution: "original",
            },
        },
    ],
])
