// TODO: remove these and replace them with Node/browser-specific injected implementations
export let isBlobLike: (x: any) => boolean
if (typeof window !== "undefined") {
    isBlobLike = function (x: any) {
        return ArrayBuffer.isView(x) || x instanceof ArrayBuffer || x instanceof Blob
    }
} else {
    isBlobLike = function (x: any) {
        return ArrayBuffer.isView(x) || x instanceof ArrayBuffer
    }
}

export let queueDeferredTask: (fn: () => void) => number
export let cancelDeferredTask: (handle: number) => void

if (typeof window !== "undefined") {
    if (window.queueMicrotask) {
        queueDeferredTask = function (fn: () => void) {
            window.queueMicrotask(fn)
            return 1
        }

        cancelDeferredTask = function (handle: number) {}
    } else {
        queueDeferredTask = window.setTimeout
        cancelDeferredTask = window.clearTimeout
    }
}
