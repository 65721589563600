import {Component, input} from "@angular/core"
import {SectionComponent} from "@common/components/item"
import {EntityActivityComponent, EntityActivityType} from "@common/components/entity/entity-activity/entity-activity.component"

@Component({
    selector: "cm-activity-section",
    imports: [EntityActivityComponent, SectionComponent],
    templateUrl: "./activity-section.component.html",
    styleUrl: "./activity-section.component.scss",
})
export class ActivitySectionComponent {
    readonly $item = input<EntityActivityType | undefined | null>(null, {alias: "item"})
}
