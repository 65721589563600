import {Component, input, output} from "@angular/core"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"

@Component({
    selector: "cm-title-section",
    imports: [PlaceholderComponent],
    templateUrl: "./title-section.component.html",
    styleUrl: "./title-section.component.scss",
})
export class TitleSectionComponent {
    readonly $iconClass = input<string | null>("far fa-info-circle", {alias: "iconClass"})
    readonly $title = input<string | null | undefined>(undefined, {alias: "title"})
    readonly $isLoaded = input<boolean>(false, {alias: "isLoaded"})
    readonly $canEdit = input<boolean>(false, {alias: "canEdit"})
    readonly $placeholder = input<string>("(name)", {alias: "placeholder"})
    readonly onChanged = output<string>()
    readonly onClosed = output<void>()
}
