import {Component, OnInit} from "@angular/core"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {BrushSettingsPanelComponent} from "@app/textures/texture-editor/operator-stack/operators/shared/panel/brush-settings-panel/brush-settings-panel.component"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {SliderComponent} from "@common/components/inputs/slider/slider.component"
import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"
import {BrushSettings} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"
import {OperatorHighpass} from "app/textures/texture-editor/operator-stack/operators/highpass/operator-highpass"
import {OperatorPanelBase} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator-panel-base"
import {ActionPanelRowComponent} from "@app/common/components/menu/actions/action-panel-row/action-panel-row.component"

@Component({
    selector: "cm-highpass-panel",
    templateUrl: "./highpass-panel.component.html",
    styleUrls: ["./highpass-panel.component.scss"],
    imports: [ToggleComponent, InspectorSectionComponent, ButtonComponent, SliderComponent, ActionPanelRowComponent, BrushSettingsPanelComponent],
})
export class HighpassPanelComponent extends OperatorPanelBase<OperatorHighpass> implements OnInit {
    ngOnInit(): void {
        this._useIndividualHV = !this.$operator().isIsotropic
    }

    set showGuides(value: boolean) {
        this.$operator().showGuides = value
    }

    get showGuides(): boolean {
        return this.$operator().showGuides
    }

    set showLowpass(value: boolean) {
        this.$operator().showLowpass = value
    }

    get showLowpass(): boolean {
        return this.$operator().showLowpass
    }

    set showMask(value: boolean) {
        this.$operator().showMask = value
    }

    get showMask(): boolean {
        return this.$operator().showMask
    }

    set intensity(value: number) {
        this.$operator().intensity = value
    }

    get intensity(): number {
        return this.$operator().intensity
    }

    set useWrapAround(value: boolean) {
        this.$operator().useWrapAround = value
    }

    get useWrapAround(): boolean {
        return this.$operator().useWrapAround
    }

    set correctionMode(value: TextureEditNodes.OperatorHighpassCorrectionMode) {
        this.$operator().correctionMode = value
    }

    get correctionMode(): TextureEditNodes.OperatorHighpassCorrectionMode {
        return this.$operator().correctionMode
    }

    set useIndividualHV(value: boolean) {
        if (this._useIndividualHV === value) {
            return
        }
        this._useIndividualHV = value
        if (!this._useIndividualHV) {
            this.smoothingDistanceV = this.smoothingDistanceH
            this.infiniteH = false
            this.infiniteV = false
        }
    }

    get useIndividualHV(): boolean {
        return this._useIndividualHV
    }

    get infiniteH(): boolean {
        return !this.$operator().useH
    }

    set infiniteH(value: boolean) {
        this.$operator().useH = !value
        if (value && this.infiniteV) {
            this.infiniteV = false
        }
    }

    set smoothingDistanceH(value: number) {
        this.$operator().smoothingDistanceH = value
        if (!this._useIndividualHV) {
            this.smoothingDistanceV = this.smoothingDistanceH
        }
    }

    get smoothingDistanceH(): number {
        return this.$operator().smoothingDistanceH
    }

    set angleOffset(value: number) {
        this.$operator().angleOffset = value
    }

    get angleOffset(): number {
        return this.$operator().angleOffset
    }

    get infiniteV(): boolean {
        return !this.$operator().useV
    }

    set infiniteV(value: boolean) {
        this.$operator().useV = !value
        if (value && this.infiniteH) {
            this.infiniteH = false
        }
    }

    set smoothingDistanceV(value: number) {
        this.$operator().smoothingDistanceV = value
    }

    get smoothingDistanceV(): number {
        return this.$operator().smoothingDistanceV
    }

    get hasMask(): boolean {
        return this.$operator().hasMask
    }

    get brushSettings(): BrushSettings {
        return this.$operator().brushSettings
    }

    addMask(): void {
        this.$operator().addMask()
    }

    removeMask(): void {
        this.$operator().removeMask()
    }

    private _useIndividualHV = false
}
