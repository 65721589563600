import {Component} from "@angular/core"
import {RoutedDialogComponent} from "@app/common/components/dialogs/routed-dialog/routed-dialog.component"
import {ConfigMenuComponent} from "@app/common/components/viewers/configurator/config-menu/config-menu.component"
import {ConfiguratorComponent} from "@app/common/components/viewers/configurator/configurator/configurator.component"
import {PdfDownloadButton} from "@app/common/components/viewers/configurator/pdf-download-button/pdf-download-button.component"
import {DialogSize} from "@app/common/models/dialogs"
import {SceneManagerService} from "@template-editor/services/scene-manager.service"

@Component({
    selector: "cm-configurator-test",
    imports: [ConfiguratorComponent, ConfigMenuComponent, PdfDownloadButton, RoutedDialogComponent],
    templateUrl: "./configurator-test-components.component.html",
    providers: [SceneManagerService],
    styleUrl: "./configurator-test-components.component.scss",
})
export class ConfiguratorTestComponentsComponent {
    dialogSizes = DialogSize

    constructor() {
        // convertTemplates()
        // this.convertSingleTemplateRevision("77fcade0-f860-4089-90de-38af0b1f9961")
    }
}
