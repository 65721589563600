<div class="cm-container">
    <div class="cm-filters-container">
        <cm-search (valueChange)="searchText$.next($event)" placeholder="Name, description, ..."></cm-search>
        <cm-own-organizations-filter (selectionChanged)="organizationsIds$.next($event)"></cm-own-organizations-filter>
    </div>
    <cm-infinite-list
        [count]="$data().length"
        [intersectionStatusChange]="pageFilledStatusChange$"
        containerSelector=".cdk-overlay-container"
        class="cm-items-list"
        [listType]="'grid'"
        [gridSize]="$gridSize()"
        class="cm-item-list"
    >
        @for (item of $data(); track item.trackId) {
            @if (item.error) {
                <cm-card-error @fadeInPlaceholder></cm-card-error>
            } @else if (item.data) {
                <cm-template-add-card [data]="item.data" [node]="undefined" [action]="item.action" (onItemClicked)="onItemClicked.emit()">
                </cm-template-add-card>
            } @else {
                <cm-card-placeholder @fadeInPlaceholder></cm-card-placeholder>
            }
        }
    </cm-infinite-list>
</div>
