import {NgClass} from "@angular/common"
import {Component, DestroyRef, inject, Input, OnDestroy, OnInit, model, output} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatMenuModule} from "@angular/material/menu"
import {MatSnackBar} from "@angular/material/snack-bar"
import {ActivatedRoute, Router} from "@angular/router"
import {IsDefined} from "@cm/utils/filter"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextAreaComponent} from "@common/components/inputs/native/native-input-text-area/native-input-text-area.component"
import {ListItemDetailedComponent} from "@common/components/item/list-item-detailed/list-item-detailed.component"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {forceEnum} from "@common/helpers/utils/enum"
import {TimeAgoPipe} from "@common/pipes/time-ago/time-ago.pipe"
import {AuthService} from "@common/services/auth/auth.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {Enums} from "@enums"
import {ContentTypeModel, MutationUpdateTaskInput, TaskState, TaskType} from "@generated"
import {Labels} from "@labels"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {
    AddCommentToTaskListItemGQL,
    AddTaskListItemGQL,
    TaskListItemFragment,
    UpdateTaskListItemGQL,
} from "@platform/components/tasks/task-list/task-list-gql.generated"
import {TasksService} from "@platform/services/tasks/tasks.service"
import {BehaviorSubject, filter, switchMap} from "rxjs"

export type TaskListItemType = {id: string; __typename: string; organization?: {id: string} | null}

@Component({
    selector: "cm-task-list",
    templateUrl: "task-list.component.html",
    styleUrls: ["task-list.component.scss"],
    imports: [
        ButtonComponent,
        InputContainerComponent,
        ListItemDetailedComponent,
        MatMenuModule,
        NativeInputTextAreaComponent,
        NgClass,
        StateLabelComponent,
        PlaceholderComponent,
        TimeAgoPipe,
    ],
})
export class TaskListComponent implements OnInit, OnDestroy {
    readonly auth = inject(AuthService)
    readonly can = inject(PermissionsService)
    readonly destroyRef = inject(DestroyRef)
    readonly notifications = inject(NotificationsService)
    readonly permission = inject(PermissionsService)
    readonly refresh = inject(RefreshService)
    readonly route = inject(ActivatedRoute)
    readonly router = inject(Router)
    readonly snackBar = inject(MatSnackBar)
    readonly tasksService = inject(TasksService)
    $can = this.permission.$to

    readonly updateItemGql = inject(UpdateTaskListItemGQL)
    readonly addTaskGql = inject(AddTaskListItemGQL)
    readonly addCommentGql = inject(AddCommentToTaskListItemGQL)

    organizationId?: string
    item$ = new BehaviorSubject<TaskListItemType | null | undefined>(undefined)
    @Input({required: true}) set item(item: TaskListItemType | null | undefined) {
        this.item$.next(
            item
                ? {
                      ...item,
                      __typename: forceEnum(item.__typename, ContentTypeModel),
                  }
                : null,
        )
        this.organizationId = item?.organization?.id
    }
    tasks?: TaskListItemFragment[]

    ngOnInit() {
        this.refresh
            .observeItem$(this.item$)
            .pipe(
                filter(IsDefined),
                switchMap((item) => {
                    return this.tasksService.tasks$(item.id, forceEnum(item.__typename, ContentTypeModel))
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe((tasks) => {
                this.tasks = tasks
            })
        this.tasksService.selectedTaskId$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((taskId) => {
            this.$selectedTask.set(this.tasks?.find((task) => task?.id === taskId) || null)
        })
        this.refresh.observeAllContentTypeModel$(ContentTypeModel.Task, false).subscribe(() => {
            this.refresh.item(this.item$.value)
        })
    }

    readonly $selectedTask = model<TaskListItemFragment | null>(null, {alias: "selectedTask"})

    lastComment(task: TaskListItemFragment | null) {
        const comments = task?.comments
        if (comments?.length) {
            return comments[comments.length - 1]
        }
        return null
    }

    async updateTask(task: TaskListItemFragment, data: Omit<MutationUpdateTaskInput, "id">) {
        await mutateThrowingErrors(this.updateItemGql)({input: {id: task.id, ...data}})
        this.refresh.item(this.item$.value)
    }

    readonly addNewTask = output<void>()
    readonly mouseOverTask = output<TaskListItemFragment>()
    readonly clickToggleTask = output<boolean>()

    addingTask = false
    newTaskText = ""

    navigateToTask(task: {id: string}): void {
        void this.router.navigate(["tasks", task.id], {
            relativeTo: this.route,
            queryParamsHandling: "preserve",
            state: {task: task},
        })
    }

    toggleAddTask() {
        this.addingTask = !this.addingTask
        this.clickToggleTask.emit(this.addingTask)
    }

    async addTask(taskComment: string) {
        const itemId = this.item$.value?.id
        const contentTypeModel = this.item$.value?.__typename
        if (itemId && contentTypeModel) {
            try {
                const task = await mutateThrowingErrors(this.addTaskGql)({
                    input: {
                        objectId: itemId,
                        public: !this.$can().read.picture(null, "privateTasks"),
                        contentTypeModel: forceEnum(contentTypeModel, ContentTypeModel),
                        state: TaskState.InProgress,
                        // TODO: set correct type
                        type: TaskType.Generic,
                    },
                })
                if (task) {
                    this.newTaskText = ""
                    await mutateThrowingErrors(this.addCommentGql)({
                        input: {
                            text: taskComment,
                            taskId: task.createTask.id,
                        },
                    })
                    this.addNewTask.emit()
                }
                this.refresh.item(this.item$.value)
                // eslint-disable-next-line unused-imports/no-unused-vars
            } catch (_error: unknown) {
                this.snackBar.open("Could not add task.", "", {duration: 3000})
            }
        }
    }

    highlightTask(task: TaskListItemFragment) {
        this.tasksService.selectTask(task)
    }

    async archiveTask(task: TaskListItemFragment) {
        await this.notifications.withUserFeedback(async () => this.updateTask(task, {state: TaskState.Archived}), {
            success: "Task archived",
            error: "Could not archive task",
        })
    }

    ngOnDestroy() {
        // reset "show completed" state when a picture detail window is closed
        this.tasksService.setShowCompleted(false)
    }

    protected readonly Labels = Labels
    protected readonly Enums = Enums
}
