import {Matrix4, Vector3} from "@cm/math"
import {AreaLight, Camera, Object} from "@cm/template-nodes"
import {cameraLookAt} from "@cm/template-nodes/utils/camera-utils"
import {SceneManagerService, TemplateNodePart, TransformMode} from "../services/scene-manager.service"

export function constrainTransformTargetableNode(
    sceneManagerService: SceneManagerService,
    transform: Matrix4,
    transformedNodePart: {
        templateNode: Camera | AreaLight
        part: TemplateNodePart["part"]
    },
    mode: TransformMode,
): {transform: Matrix4; target?: Vector3} {
    const {templateNode, part} = transformedNodePart

    if (!templateNode.parameters.targeted) {
        if (part !== "root") throw new Error("Part must be root")
        return {transform: new Matrix4(transform.toArray())}
    }

    if (part !== "root" && part !== "target") throw new Error("Part must be root or target")

    if (mode === "translate") {
        if (part === "target") {
            const newTarget = transform.getTranslation()
            if (!(newTarget instanceof Vector3)) throw new Error("newTarget is not a Vector3")
            const newTransform = cameraLookAt(new Matrix4(templateNode.parameters.lockedTransform).getTranslation(), newTarget)
            return {transform: newTransform, target: newTarget}
        } else {
            const {target: targetV} = templateNode.parameters
            const target = new Vector3(targetV[0], targetV[1], targetV[2])
            const newPosition = transform.getTranslation()
            if (!(newPosition instanceof Vector3)) throw new Error("newPosition is not a Vector3")
            const newTransform = cameraLookAt(newPosition, target)
            return {transform: newTransform}
        }
    } else if (mode === "rotate") {
        if (part === "target") {
            throw new Error("Rotating around a target is currently not supported")
        } else {
            const {target: targetV} = templateNode.parameters
            const delta = new Matrix4(getTransformDelta(sceneManagerService, transform, templateNode)?.toArray())
            if (!delta) throw new Error("Delta is undefined")
            const curTarget = new Vector3(targetV[0], targetV[1], targetV[2])
            const lockedTransform = templateNode.parameters.lockedTransform
            const curPosition = new Matrix4(lockedTransform).getTranslation()
            const newTarget = delta.multiplyVector(curTarget.sub(curPosition)).add(curPosition)

            const newTranslation = transform.getTranslation()

            const newTransform = cameraLookAt(new Vector3(newTranslation.x, newTranslation.y, newTranslation.z), newTarget)
            return {transform: newTransform, target: newTarget}
        }
    } else {
        throw new Error("Scaling a camera or area light is currently not supported")
    }
}

export function getTransformDelta(sceneManagerService: SceneManagerService, transform: Matrix4, templateNode: Object) {
    const sceneNodeMatrix = sceneManagerService.getTransformAccessor(templateNode)?.getTransform()
    if (!sceneNodeMatrix) return undefined
    const delta = transform.multiply(sceneNodeMatrix.inverse())
    return delta
}
