import {JobNodes, makeJobTaskDescriptor} from "#job-nodes/job-nodes"

//TODO: unify this with backend/tasks/material-ai/validation.ts

export type ImageToPbrMapsInput = {
    inputImage: JobNodes.DataObjectReference
    dpi?: number
    filterRadius?: number // pixels
    generateDisplacement?: boolean
}

export type ImageToPbrMapsOutput = {
    diffuse: JobNodes.DataObjectReference
    normal: JobNodes.DataObjectReference
    roughness: JobNodes.DataObjectReference
    metalness: JobNodes.DataObjectReference
    anisotropy: JobNodes.DataObjectReference
    specularStrength: JobNodes.DataObjectReference
    displacement?: JobNodes.DataObjectReference
}

export const imageToPbrMaps = makeJobTaskDescriptor<ImageToPbrMapsInput, ImageToPbrMapsOutput>("MaterialAi.imageToPbrMaps", 1)
