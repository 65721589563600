import {isBlobLike} from "#browser-utils/blob"
import {WebSocketImplementation} from "@cm/utils/websocket/types"
import {WebSocketMessagingConnection} from "@cm/utils/websocket/websocket-messaging"
import {Observable, Subject} from "rxjs"

export interface BrowserWebSocketImplementation extends WebSocketImplementation<Blob> {
    MessageData: Blob
    WebSocket: WebSocket
}

export const createWebSocket = (url: string, _options?: {maxPayload: number}) => {
    return new WebSocket(url)
}

export const createWebSocketMessagingConnection = (
    url: string,
    maxPayload?: number,
): Observable<WebSocketMessagingConnection<BrowserWebSocketImplementation>> => {
    const socket = createWebSocket(url, maxPayload ? {maxPayload: maxPayload} : undefined)
    const connect$ = new Subject<WebSocketMessagingConnection<BrowserWebSocketImplementation>>()
    socket.onopen = (_event) => {
        connect$.next(new WebSocketMessagingConnection(socket, isBlobLike))
        connect$.complete()
    }
    socket.onerror = (event) => {
        connect$.error(event)
        connect$.complete()
    }
    return connect$
}
