import {Component, Input, input, model, output} from "@angular/core"
import {ScalarInputSocketComponent} from "@node-editor/components/scalar-input-socket/scalar-input-socket.component"
import {ScalarSocketRange} from "@node-editor/models"

@Component({
    selector: "cm-vector-input-socket",
    templateUrl: "./vector-input-socket.component.html",
    styleUrls: ["./vector-input-socket.component.scss"],
    imports: [ScalarInputSocketComponent],
})
export class VectorInputSocketComponent {
    readonly $label = input<string>(undefined, {alias: "label"})
    readonly $value = model<[number, number, number] | undefined>(undefined, {alias: "value"})

    updateValue(index: number, newValue: number) {
        const value = this.$value() || [0, 0, 0]
        value[index] = newValue
        this.$value.set(value)
        this.valueChange.emit(value)
    }

    _connected: {value: boolean} = {value: false}
    @Input() set connected(value: {value: boolean}) {
        this._connected = value
    }
    get connected(): {value: boolean} {
        return this._connected
    }

    readonly valueChange = output<[number, number, number]>()

    range: ScalarSocketRange = null
}
