import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SelectModelItemFragment = { __typename: 'Model', id: string, legacyId: number, name?: string | null, description?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type SelectModelItemsQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.ModelFilterInput>;
}>;


export type SelectModelItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Model', id: string, legacyId: number, name?: string | null, description?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export const SelectModelItemFragmentDoc = gql`
    fragment SelectModelItem on Model {
  id
  legacyId
  name
  description
  organization {
    id
    name
  }
  galleryImage {
    id
  }
}
    `;
export const SelectModelItemsDocument = gql`
    query selectModelItems($skip: Int, $take: Int, $filter: ModelFilterInput) {
  items: models(skip: $skip, take: $take, filter: $filter) {
    ...SelectModelItem
  }
  totalCount: modelsCount(filter: $filter)
}
    ${SelectModelItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SelectModelItemsGQL extends Apollo.Query<SelectModelItemsQuery, SelectModelItemsQueryVariables> {
    override document = SelectModelItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }