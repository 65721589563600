import {MaterialSocket} from "@material-editor/models/material-socket"

export const TexBrickOutputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "output", valueType: "output", label: "Color"},
    fac: {paramType: "socket", id: "Fac", type: "output", valueType: "output", label: "Fac"},
}

export const TexBrickInputs: Record<string, MaterialSocket> = {
    vector: {paramType: "socket", id: "Vector", type: "input", valueType: "input", label: "Vector"},
    color1: {paramType: "socket", id: "Color1", type: "input", valueType: "input", label: "Color1"},
    color2: {paramType: "socket", id: "Color2", type: "input", valueType: "input", label: "Color2"},
    mortar: {paramType: "socket", id: "Mortar", type: "input", valueType: "input", label: "Mortar"},
}
