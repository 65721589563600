import {HalImageChannelLayout, HalImageDataType, HalImageSourceData, NativeImageData, TypedArrayImage} from "@common/models/hal/hal-image/types"
import {assertNever} from "@cm/utils"
import {Size2Like} from "@cm/math"
import {HalImage, HalImagePhysical, HalImageView, HalImageVirtual} from "@common/models/hal/hal-image/index"

export function isHalImage(data: unknown): data is HalImage {
    return data != null && typeof data === "object" && "isHalImage" in data && data.isHalImage === true
}

export function isHalImagePhysical(data: unknown): data is HalImagePhysical {
    return data != null && typeof data === "object" && "isHalImagePhysical" in data && data.isHalImagePhysical === true
}

export function isHalImageVirtual(data: unknown): data is HalImageVirtual {
    return data != null && typeof data === "object" && "isHalImageVirtual" in data && data.isHalImageVirtual === true
}

export function isHalImageView(data: unknown): data is HalImageView {
    return data != null && typeof data === "object" && "isHalImageView" in data && data.isHalImageView === true
}

export function getNumChannels(layout: HalImageChannelLayout): number {
    switch (layout) {
        case "RGBA":
            return 4
        case "RGB":
            return 3
        case "R":
            return 1
    }
    assertNever(layout)
}

export function getBytesPerChannel(dataType: HalImageDataType): number {
    switch (dataType) {
        case "uint8":
        case "uint8srgb":
            return 1
        case "float16":
            return 2
        case "float32":
            return 4
    }
    assertNever(dataType)
}

export function isTypedArrayImage(data: unknown): data is TypedArrayImage {
    return data instanceof Object && "width" in data && "height" in data && "data" in data
}

export function getDataTypeFromTypedArray(data: TypedArrayImage["data"]) {
    if (data instanceof Uint8ClampedArray) {
        return "uint8"
    } else if (data instanceof Uint8Array) {
        return "uint8"
    } else if (data instanceof Uint16Array) {
        return "uint8"
    } else if (data instanceof Float32Array) {
        return "float32"
    } else {
        assertNever(data)
    }
}

export function getDataTypeFromImageSourceData(data: HalImageSourceData) {
    return isTypedArrayImage(data)
        ? getDataTypeFromTypedArray(data.data)
        : isNativeImageData(data)
          ? data.isSrgb
              ? "uint8srgb"
              : "uint8"
          : data.descriptor.dataType
}

export function getChannelLayoutFromImageSourceData(data: HalImageSourceData) {
    return isTypedArrayImage(data) ? data.channelLayout : isNativeImageData(data) ? "RGBA" : data.descriptor.channelLayout
}

export function createTypedArrayImage(
    width: number,
    height: number,
    layout: HalImageChannelLayout,
    data: Uint8ClampedArray | Uint8Array | Uint16Array | Float32Array,
): TypedArrayImage {
    const numChannels = getNumChannels(layout)
    if (data.length !== width * height * numChannels) {
        throw new Error("Invalid data length")
    }
    return {width, height, channelLayout: layout, data}
}

export function isNativeImageData(data: unknown): data is NativeImageData {
    return (
        data instanceof Object &&
        "isSrgb" in data &&
        "data" in data &&
        (data.data instanceof HTMLImageElement || data.data instanceof HTMLCanvasElement || data.data instanceof ImageData)
    )
}

export function createNativeImageData(isSrgb: boolean, data: HTMLImageElement | HTMLCanvasElement | ImageData): NativeImageData {
    return {isSrgb, data}
}

export function getSourceDataSize(sourceData: HalImageSourceData): Size2Like {
    if (isTypedArrayImage(sourceData)) {
        return {width: sourceData.width, height: sourceData.height}
    } else if (isNativeImageData(sourceData)) {
        return {width: sourceData.data.width, height: sourceData.data.height}
    } else {
        return {width: sourceData.descriptor.width, height: sourceData.descriptor.height}
    }
}
