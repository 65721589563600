import {HalImageChannelLayout, HalImageDataType, HalImageOptions} from "@common/models/hal/hal-image/types"
import {assertNever, castToFloat32Array, castToUint16Array, castToUint8Array, floatToHalfArray, halfToFloatArray} from "@cm/utils"
import {linearToSrgb, srgbToLinear} from "@cm/image-processing/tone-mapping"
import {Box2Like, Size2Like} from "@cm/math"
import {WebGl2ImagePhysical} from "@common/models/webgl2/webgl2-image-physical"
import {WebGl2ImageVirtual} from "@common/models/webgl2/webgl2-image-virtual"
import {WebGl2ImageView} from "@common/models/webgl2/webgl2-image-view"
import {WebGl2Image} from "@common/models/webgl2/webgl2-image"

export const isWebGl2Image = (data: unknown): data is WebGl2Image => {
    return data instanceof WebGl2ImagePhysical || data instanceof WebGl2ImageVirtual || data instanceof WebGl2ImageView
}

export const getInternalFormat = (gl: WebGL2RenderingContext, channelLayout: HalImageChannelLayout, dataType: HalImageDataType): number => {
    switch (dataType) {
        case "uint8":
            switch (channelLayout) {
                case "RGBA":
                    return gl.RGBA8
                case "RGB":
                    return gl.RGB8
                case "R":
                    return gl.R8
                default:
                    assertNever(channelLayout)
            }
            break
        case "uint8srgb":
            switch (channelLayout) {
                case "RGBA":
                    return gl.SRGB8_ALPHA8
                case "RGB":
                    return gl.SRGB8
                case "R":
                    throw Error("sRGB is not supported for R layout.")
                default:
                    assertNever(channelLayout)
            }
            break
        case "float32":
            switch (channelLayout) {
                case "RGBA":
                    return gl.RGBA32F
                case "RGB":
                    return gl.RGB32F
                case "R":
                    return gl.R32F
                default:
                    assertNever(channelLayout)
            }
            break
        case "float16":
            switch (channelLayout) {
                case "RGBA":
                    return gl.RGBA16F
                case "RGB":
                    return gl.RGB16F
                case "R":
                    return gl.R16F
                default:
                    assertNever(channelLayout)
            }
            break
        default:
            assertNever(dataType)
    }
}

export const convertRawData = (
    from: HalImageDataType,
    fromSrgb: boolean,
    to: HalImageDataType,
    toSrgb: boolean,
    data: Uint8Array | Uint16Array | Float32Array,
) => {
    switch (from) {
        case "uint8":
        case "uint8srgb":
            switch (to) {
                case "uint8":
                case "uint8srgb":
                    if (fromSrgb !== toSrgb) {
                        throw new Error("Cannot convert between sRGB and linear formats. Not implemented yet.")
                    }
                    return castToUint8Array(data)
                case "float16":
                    return floatToHalfArray(uint8ToFloatArray(data as Uint8Array, fromSrgb))
                case "float32":
                    return uint8ToFloatArray(data as Uint8Array, fromSrgb)
                default:
                    assertNever(to)
            }
            break
        case "float16":
            switch (to) {
                case "uint8":
                case "uint8srgb":
                    return floatToUint8Array(halfToFloatArray(data as Uint16Array), toSrgb)
                case "float16":
                    return castToUint16Array(data)
                case "float32":
                    return halfToFloatArray(data)
                default:
                    assertNever(to)
            }
            break
        case "float32":
            switch (to) {
                case "uint8":
                case "uint8srgb":
                    return floatToUint8Array(data as Float32Array, toSrgb)
                case "float16":
                    return floatToHalfArray(data)
                case "float32":
                    return castToFloat32Array(data)
                default:
                    assertNever(to)
            }
            break
        default:
            assertNever(from)
    }
}

export const floatToUint8Array = (data: Float32Array, isSrgbFormat: boolean): Uint8Array => {
    const uint8Data = new Uint8Array(data.length)
    for (let i = 0; i < data.length; i++) {
        let value = data[i]
        if (isSrgbFormat) {
            value = linearToSrgb(value)
        }
        uint8Data[i] = Math.max(0, Math.min(255, Math.round(value * 255)))
    }
    return uint8Data
}

export const uint8ToFloatArray = (data: Uint8Array, isSrgbFormat: boolean): Float32Array => {
    const floatData = new Float32Array(data.length)
    for (let i = 0; i < data.length; i++) {
        let value = data[i] / 255
        if (isSrgbFormat) {
            value = srgbToLinear(value)
        }
        floatData[i] = value
    }
    return floatData
}

// export type FormatBufferType<T extends HalImageDataType> = T extends "uint8" ? Uint8Array : T extends "float16" ? Uint16Array : Float32Array

// export const createBufferForFormat = <T extends HalImageDataType>(rawDataType: T, numElements: number): FormatBufferType<T> => {
//     switch (rawDataType) {
//         case "uint8":
//             return new Uint8Array(numElements) as FormatBufferType<T> // TODO Why is the type assertion necessary here?
//         case "float16":
//             return new Uint16Array(numElements) as FormatBufferType<T> // TODO Why is the type assertion necessary here?
//         case "float32":
//             return new Float32Array(numElements) as FormatBufferType<T> // TODO Why is the type assertion necessary here?
//         default:
//             assertNever(rawDataType)
//     }
// }
//
// export const createBufferForRawData = <T extends HalImageRawDataType>(rawDataType: T, numElements: number): HalImageRawDataBufferType<T> => {
//     switch (rawDataType) {
//         case "uint8":
//             return new Uint8Array(numElements) as HalImageRawDataBufferType<T> // TODO Why is the type assertion necessary here?
//         case "float16":
//             return new Uint16Array(numElements) as HalImageRawDataBufferType<T> // TODO Why is the type assertion necessary here?
//         case "float32":
//             return new Float32Array(numElements) as HalImageRawDataBufferType<T> // TODO Why is the type assertion necessary here?
//         default:
//             assertNever(rawDataType)
//     }
// }

export const getGlFormat = (gl: WebGL2RenderingContext, channelLayout: HalImageChannelLayout): number => {
    switch (channelLayout) {
        case "RGBA":
            return gl.RGBA
        case "RGB":
            return gl.RGB
        case "R":
            return gl.RED
        default:
            assertNever(channelLayout)
    }
}

export const getGlType = (gl: WebGL2RenderingContext, dataType: HalImageDataType): number => {
    switch (dataType) {
        case "uint8":
        case "uint8srgb":
            return gl.UNSIGNED_BYTE
        case "float16":
            return gl.HALF_FLOAT
        case "float32":
            return gl.FLOAT
        default:
            assertNever(dataType)
    }
}

export const extractSubregionImageData = (imageData: ImageData, region: Box2Like) => {
    const {width, height, data} = imageData

    if (region.width <= 0 || region.height <= 0) {
        throw new Error("Invalid region")
    }

    if (region.x === 0 && region.y === 0 && region.width === width && region.height === height) {
        return imageData
    }

    const subWidth = Math.max(0, Math.min(region.width, width - region.x))
    const subHeight = Math.max(0, Math.min(region.height, height - region.y))

    if (subWidth <= 0 || subHeight <= 0) {
        throw new Error("Invalid region")
    }

    // New buffer for subregion (RGBA, so 4 bytes per pixel)
    const subData = new Uint8ClampedArray(region.width * region.height * 4)

    // Copy each row efficiently using subarray and set()
    for (let row = 0; row < subHeight; row++) {
        const srcStart = ((region.y + row) * width + region.x) * 4 // Source index
        const srcEnd = srcStart + subWidth * 4 // End of the row
        const dstStart = row * region.width * 4 // Destination index
        subData.set(data.subarray(srcStart, srcEnd), dstStart)
    }

    return new ImageData(subData, subWidth, subHeight)
}

export const extractSubregionTypedArray = <T extends Uint8Array | Uint8ClampedArray | Int16Array | Uint16Array | Int32Array | Uint32Array | Float32Array>(
    imageArrayBuffer: T, // Any TypedArray (Float32Array, Uint8Array, etc.)
    arrayType: {new (size: number): T}, // TypedArray constructor (Float32Array, Uint8Array, etc.)
    imageSize: Size2Like, // Size of original image
    numChannels: number,
    region: Box2Like,
): T => {
    if (region.width <= 0 || region.height <= 0) {
        throw new Error("Invalid region")
    }

    if (region.x === 0 && region.y === 0 && region.width === imageSize.width && region.height === imageSize.height) {
        return imageArrayBuffer
    }

    const subWidth = Math.max(0, Math.min(region.width, imageSize.width - region.x))
    const subHeight = Math.max(0, Math.min(region.height, imageSize.height - region.y))

    if (subWidth <= 0 || subHeight <= 0) {
        throw new Error("Invalid region")
    }

    // Create a new buffer for the extracted subregion
    const subRegion = new arrayType(region.width * region.height * numChannels)

    for (let row = 0; row < subHeight; row++) {
        // Compute the starting index in the original array
        const srcStart = ((region.y + row) * imageSize.width + region.x) * numChannels
        const srcEnd = srcStart + subWidth * numChannels

        // Compute the starting index in the new subregion
        const dstStart = row * region.width * numChannels

        // Copy a row from the original array to the new subregion
        subRegion.set(imageArrayBuffer.subarray(srcStart, srcEnd), dstStart)
    }

    return subRegion
}

export const completeHalImageOptions = (options?: Partial<HalImageOptions>): HalImageOptions => {
    return {
        useMipMaps: options?.useMipMaps ?? false,
    }
}

export const getMipLevelSize = (fullSize: Size2Like, mipLevel: number): Size2Like => {
    const mipScale = 1 << mipLevel
    return {
        width: Math.ceil(fullSize.width / mipScale),
        height: Math.ceil(fullSize.height / mipScale),
    }
}
