<div class="cm-container">
    <div class="cm-left-panel">
        <!--"upload" | "configuration" | "primitive" | "decal" | "input" | "export" | "value" | "connection" | "other"-->
        <div class="cm-left-panel-option" [class.cm-selected]="$currentSection() === 'upload'" (click)="$currentSection.set('upload')">
            <i class="far fa-arrow-to-top"></i>
            Upload
        </div>
        <div class="cm-left-panel-option" [class.cm-selected]="$currentSection() === 'configuration'" (click)="$currentSection.set('configuration')">
            <i class="far fa-cog"></i>
            Configurations
        </div>
        <div class="cm-left-panel-option" [class.cm-selected]="$currentSection() === 'primitive'" (click)="$currentSection.set('primitive')">
            <i class="far fa-hexagon"></i>
            Primitives
        </div>
        <div class="cm-left-panel-option" [class.cm-selected]="$currentSection() === 'decal'" (click)="$currentSection.set('decal')">
            <i class="far fa-image"></i>
            Decals and Annotations
        </div>
        <div class="cm-left-panel-option" [class.cm-selected]="$currentSection() === 'input'" (click)="$currentSection.set('input')">
            <i class="far fa-sign-in"></i>
            Inputs
        </div>
        <div class="cm-left-panel-option" [class.cm-selected]="$currentSection() === 'export'" (click)="$currentSection.set('export')">
            <i class="far fa-file-export"></i>
            Outputs
        </div>
        <div class="cm-left-panel-option" [class.cm-selected]="$currentSection() === 'value'" (click)="$currentSection.set('value')">
            <i class="far fa-sign-out"></i>
            Values
        </div>
        <div class="cm-left-panel-option" [class.cm-selected]="$currentSection() === 'connection'" (click)="$currentSection.set('connection')">
            <i class="far fa-arrow-right-arrow-left"></i>
            Connections and Guides
        </div>
        <div class="cm-left-panel-option" [class.cm-selected]="$currentSection() === 'other'" (click)="$currentSection.set('other')">
            <i class="far fa-asterisk"></i>
            Other
        </div>
    </div>
    <div>
        <div class="cm-list-container">
            @for (sceneItem of $sceneItems(); track sceneItem.name) {
                <cm-template-add-card
                    [data]="sceneItem"
                    [node]="sceneItem.node"
                    [action]="sceneItem.action"
                    (onItemClicked)="onItemClicked.emit()"
                    [disabled]="sceneItem.disabled"
                >
                </cm-template-add-card>
            }
        </div>
    </div>
</div>
