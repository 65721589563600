import {Component, inject, OnInit, input} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatCardModule} from "@angular/material/card"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MatDialog} from "@angular/material/dialog"
import {MatIconModule} from "@angular/material/icon"
import {MatTooltipModule} from "@angular/material/tooltip"
import {RouterLink} from "@angular/router"
import {MutationUpdateScanJobInput} from "@generated"
import {ScanJobDialogComponent} from "@app/textures/texture-group-details/scan-job-dialog/scan-job-dialog.component"
import {ScanJobsForTextureSetGQL, ScanJobUpdateGQL, ScanJobFragment} from "@app/textures/texture-group-details/scan-job/scan-job.generated"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {DurationFromMillisecondsPipe} from "@common/pipes/duration/duration.pipe"
import {FormatDatePipe} from "@common/pipes/format-date/format-date.pipe"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {Enums} from "@enums"
import {Labels} from "@labels"
import {FilesService} from "@common/services/files/files.service"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"

@Component({
    imports: [
        DurationFromMillisecondsPipe,
        FormsModule,
        MatCardModule,
        MatCheckboxModule,
        MatIconModule,
        MatTooltipModule,
        RouterLink,
        StateLabelComponent,
        FormatDatePipe,
    ],
    selector: "cm-scan-job",
    styleUrls: ["./scan-job.component.scss"],
    templateUrl: "./scan-job.component.html",
})
export class ScanJobComponent implements OnInit {
    readonly $textureSetId = input<string>(undefined, {alias: "textureSetId"})

    scanJob: ScanJobFragment | undefined = undefined
    get activeSubJobsCount() {
        return this.scanJob?.activeSubJobsCount
    }
    get completedSubJobsCount() {
        return this.scanJob?.completedSubJobsCount
    }
    get scanJobDuration(): number | undefined {
        if (!this.scanJob?.started || !this.scanJob?.completed) {
            return undefined
        }
        return new Date(this.scanJob.completed).getTime() - new Date(this.scanJob.started).getTime()
    }

    readonly permission = inject(PermissionsService)
    readonly scanJobsForTextureSet = inject(ScanJobsForTextureSetGQL)
    readonly scanJobUpdate = inject(ScanJobUpdateGQL)

    $can = this.permission.$to

    constructor(private dialog: MatDialog) {}

    ngOnInit() {
        const textureSetId = this.$textureSetId()
        if (textureSetId) {
            fetchThrowingErrors(this.scanJobsForTextureSet)({id: textureSetId}).then((data) => {
                this.scanJob = data.textureSet?.scanJob ?? undefined
            })
        }
    }

    downloadLog(downloadUrl: string): void {
        FilesService.downloadFile("log.txt", downloadUrl)
    }

    updateScanJob(data: Omit<MutationUpdateScanJobInput, "id">) {
        const scanJobId = this.scanJob?.id
        if (scanJobId) {
            mutateThrowingErrors(this.scanJobUpdate)({input: {id: scanJobId, ...data}}).then(({updateScanJob}) => {
                this.scanJob = updateScanJob
            })
        }
    }

    showScanJobDialog(scanJob: ScanJobFragment): void {
        this.dialog.open(ScanJobDialogComponent, {
            panelClass: "cm-no-padding-dialog",
            width: "1700px",
            data: {
                scanJobId: scanJob.id,
            },
        })
    }

    protected readonly Enums = Enums
    protected readonly Labels = Labels
}
