import {animate, style, transition, trigger} from "@angular/animations"
import {ChangeDetectionStrategy, Component, inject, OnInit, input, output, signal} from "@angular/core"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltip} from "@angular/material/tooltip"
import {IsDefined} from "@cm/utils/filter"
import {CardErrorComponent, CardPlaceholderComponent} from "@common/components/cards"
import {CommentBoxesComponent} from "@common/components/comment-boxes/comment-boxes.component"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {ItemListGqlItem} from "@common/components/item"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {AuthService} from "@common/services/auth/auth.service"
import {MaterialsCardService} from "@common/services/material-card/materials-card.service"
import {MaterialsCardFragment, MaterialsCardInputFragment} from "@common/services/material-card/materials-card.service.generated"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {Enums} from "@enums"
import {ContentTypeModel, MutationUpdateMaterialInput, UserAssignmentState} from "@generated"
import {Labels} from "@labels"
import {PaymentStateLabelComponent} from "@platform/components/shared/payment-state-label/payment-state-label.component"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {TagLabelsComponent} from "@platform/components/tags/tag-labels/tag-labels.component"
import {AssignUserDialogComponent} from "@platform/components/users/assign-user-dialog/assign-user-dialog.component"
import {AssignUserComponent} from "@platform/components/users/assign-user/assign-user.component"
import {LoadedDataItem} from "@platform/models/data"
import {catchError, EMPTY, filter, map, of, switchMap} from "rxjs"
import {v4 as uuid4} from "uuid"

@Component({
    animations: [
        trigger("fadeInPlaceholder", [
            transition("void => *", [
                style({
                    opacity: 0,
                    scale: 0.9,
                }),
                animate("600ms", style({opacity: 0.4, scale: 0.98})),
            ]),
        ]),
        trigger("fadeInCard", [
            transition("void => *", [
                style({
                    opacity: 0.6,
                    scale: 0.98,
                }),
                animate("200ms", style({opacity: 1, scale: 1})),
            ]),
        ]),
    ],
    selector: "cm-materials-card",
    imports: [
        AssignUserComponent,
        CardErrorComponent,
        CardPlaceholderComponent,
        CommentBoxesComponent,
        EntityCardComponent,
        MatTooltip,
        StateLabelComponent,
        TagLabelsComponent,
        MatMenuModule,
        AssignUserDialogComponent,
        PlaceholderComponent,
        PaymentStateLabelComponent,
    ],
    templateUrl: "./materials-card.component.html",
    styleUrl: "./materials-card.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialsCardComponent implements OnInit {
    readonly $item = input.required<ItemListGqlItem<MaterialsCardInputFragment>>({alias: "item"})
    readonly updateItem = output<MutationUpdateMaterialInput>()
    readonly updateAssignedUser = output<{
        contentTypeModel: ContentTypeModel
        item: {
            id: string
            state: UserAssignmentState
        }
        user:
            | {
                  id: string
              }
            | undefined
    }>()
    readonly copyMaterial = output<{
        id: string
    }>()

    readonly auth = inject(AuthService)
    readonly permission = inject(PermissionsService)
    readonly refresh = inject(RefreshService)
    readonly materialsCardService = inject(MaterialsCardService)
    $can = this.permission.$to
    protected trackId = uuid4()

    $cardData = signal<LoadedDataItem<MaterialsCardFragment>>({trackId: this.trackId, data: null, error: null})

    public getTags(material: MaterialsCardFragment) {
        return material.tagAssignments.map((assignment) => assignment.tag)
    }

    public ngOnInit() {
        of(this.$item())
            .pipe(
                map((item) => item.data?.id),
                filter(IsDefined),
                switchMap((id) => this.refresh.observeItem$({id, __typename: ContentTypeModel.Material})),
                filter(IsDefined),
                switchMap((item) => this.materialsCardService.fetch({id: item.id})),
                catchError((error) => {
                    this.$cardData.set({trackId: this.trackId, data: null, error})
                    return EMPTY
                }),
            )
            .subscribe((item) => {
                if (item?.__typename) {
                    this.$cardData.set({trackId: this.trackId, data: item, error: null})
                }
            })
    }

    protected readonly Labels = Labels
    protected readonly Enums = Enums
}
