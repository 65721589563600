import {Inlet, NotReady, Outlet} from "#template-nodes/runtime-graph/slots"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {BuilderInlet, GraphBuilder} from "#template-nodes/runtime-graph/graph-builder"

const TD = TypeDescriptors

const mapOverListDescriptor = {
    input: TD.inlet(TD.Array(TD.Identity<any>())),
    fn: TD.inlet(TD.StaticFunction<((scope: GraphBuilderScope, elem: any, ctx: any, index: number) => any) | undefined>()),
    ctx: TD.inlet(TD.Identity<any>()),
    output: TD.outlet(TD.Array(TD.Identity<any>())),
    builder: TD.builder(),
}

export class MapOverList<T, U, Ctx> implements NodeClassImpl<typeof mapOverListDescriptor, typeof MapOverList> {
    static descriptor = mapOverListDescriptor
    static uniqueName = "MapOverList"
    input!: Inlet<T[]>
    fn!: Inlet<((scope: GraphBuilderScope, elem: T, ctx: BuilderInlet<Ctx>, index: number) => BuilderInlet<U>) | undefined>
    ctx!: Inlet<Ctx>
    output!: Outlet<U[]>
    builder!: GraphBuilder

    run() {
        const fn = this.fn
        const ctx = this.ctx
        if (this.input === NotReady || fn === NotReady || ctx === NotReady || fn === undefined) {
            this.output.emitIfChanged(NotReady)
            return
        }
        const scope = this.builder.scope()
        const elemOut: BuilderInlet<U>[] = []
        for (let idx = 0; idx < this.input.length; idx++) {
            const elem = this.input[idx]
            const elemScopeName = `${idx}`
            const elemScope = scope.scope(elemScopeName)
            const fnValue = fn(elemScope, elem, ctx, idx)
            elemOut.push(fnValue)
        }
        const outList = scope.list(elemOut)
        scope.output("output", outList)

        const bindFn = this.builder.finalizeChanges()

        const runFn = bindFn(this)
        runFn()
    }
}
