import {Annotation} from "#template-nodes/nodes/annotation"
import {AreaLight} from "#template-nodes/nodes/area-light"
import {CameraFwd} from "#template-nodes/nodes/camera"
import {ConfigGroupFwd} from "#template-nodes/nodes/config-group"
import {ConfigVariantFwd} from "#template-nodes/nodes/config-variant"
import {DataObjectReference} from "#template-nodes/nodes/data-object-reference"
import {DistanceTextureFwd} from "#template-nodes/nodes/distance-texture"
import {
    BooleanExport,
    CurveExport,
    ImageExport,
    JSONExport,
    MaterialExport,
    MeshExport,
    NumberExport,
    ObjectExport,
    StringExport,
    TemplateExport,
} from "#template-nodes/nodes/export"
import {FindMaterial} from "#template-nodes/nodes/find-material"
import {GroupFwd} from "#template-nodes/nodes/group"
import {HDRILight} from "#template-nodes/nodes/hdri-light"
import {ImageOperatorFwd} from "#template-nodes/nodes/image-operator"
import {
    BooleanInput,
    CurveInput,
    CurveInputFwd,
    ImageInput,
    ImageInputFwd,
    JSONInput,
    MaterialInput,
    MaterialInputFwd,
    MeshInput,
    MeshInputFwd,
    NodesInput,
    NodesInputFwd,
    NumberInput,
    ObjectInput,
    ObjectInputFwd,
    StringInput,
    TemplateInput,
    TemplateInputFwd,
} from "#template-nodes/nodes/input"
import {LightPortal} from "#template-nodes/nodes/light-portal"
import {LodType} from "#template-nodes/nodes/lod-type"
import {MaterialGraphReference} from "#template-nodes/nodes/material-graph-reference"
import {MaterialReference} from "#template-nodes/nodes/material-reference"
import {MeshCurve} from "#template-nodes/nodes/mesh-curve"
import {MeshDecal} from "#template-nodes/nodes/mesh-decal"
import {Nodes} from "#template-nodes/nodes/nodes"
import {
    BooleanOutput,
    BooleanOutputFwd,
    ImageOutput,
    ImageOutputFwd,
    JSONOutput,
    MaterialOutput,
    MaterialOutputFwd,
    NumberOutput,
    NumberOutputFwd,
    ObjectOutput,
    ObjectOutputFwd,
    MeshOutput,
    MeshOutputFwd,
    StringOutput,
    StringOutputFwd,
    TemplateOutput,
    TemplateOutputFwd,
    CurveOutputFwd,
    CurveOutput,
} from "#template-nodes/nodes/output"
import {OverlayMaterialColorFwd} from "#template-nodes/nodes/overlay-material-color"
import {PlaneGuide} from "#template-nodes/nodes/plane-guide"
import {PointGuide} from "#template-nodes/nodes/point-guide"
import {PostProcessRender} from "#template-nodes/nodes/post-process-render"
import {ProceduralMesh} from "#template-nodes/nodes/procedural-mesh"
import {RegexReplaceFwd} from "#template-nodes/nodes/regex-replace"
import {Render} from "#template-nodes/nodes/render"
import {RigidRelation} from "#template-nodes/nodes/rigid-relation"
import {SceneProperties} from "#template-nodes/nodes/scene-properties"
import {SeamFwd} from "#template-nodes/nodes/seam"
import {StoredMesh} from "#template-nodes/nodes/stored-mesh"
import {StringResolve} from "#template-nodes/nodes/string-resolve"
import {
    BooleanLikes,
    BooleanSwitch,
    BooleanSwitchFwd,
    CurveLikes,
    CurveSwitch,
    CurveSwitchFwd,
    ImageLikes,
    ImageSwitch,
    ImageSwitchFwd,
    JSONLikes,
    JSONSwitch,
    JSONSwitchFwd,
    MaterialLikes,
    MaterialSwitch,
    MaterialSwitchFwd,
    MeshLikes,
    MeshSwitch,
    MeshSwitchFwd,
    NumberLikes,
    NumberSwitch,
    NumberSwitchFwd,
    ObjectLikes,
    ObjectSwitch,
    ObjectSwitchFwd,
    StringLikes,
    StringSwitch,
    StringSwitchFwd,
    TemplateLikes,
    TemplateSwitch,
    TemplateSwitchFwd,
} from "#template-nodes/nodes/switch"
import {TemplateGraphFwd} from "#template-nodes/nodes/template-graph"
import {TemplateInstanceFwd} from "#template-nodes/nodes/template-instance"
import {TemplateReference} from "#template-nodes/nodes/template-reference"
import {TransientDataObject} from "#template-nodes/nodes/transient-data-object"
import {BooleanValue, JSONValue, NumberValue, StringValue} from "#template-nodes/nodes/value"
import {R1Variable, S1Variable, S3Variable} from "#template-nodes/nodes/variable"
import {AnyJSONValue, anyJsonValue, isAnyJSONValue, TemplateNode, templateNode} from "#template-nodes/types"
import {z} from "zod"
import {DimensionGuidesFwd} from "#template-nodes/nodes/dimension-guides"
import {ImageRgbCurveFwd} from "#template-nodes/nodes/image-rgb-curve"
import {MoveMaterialFwd} from "#template-nodes/nodes/move-material"
import {BackgroundMaterial} from "#template-nodes/nodes/background-material"
import {CutMesh} from "#template-nodes/nodes/cut-mesh"

export type Mesh = StoredMesh | ProceduralMesh | CutMesh
export type MeshLike = Mesh | MeshInputFwd | MeshSwitchFwd | MeshOutputFwd
export type Curve = MeshCurve
export type CurveLike = Curve | CurveInputFwd | CurveSwitchFwd | CurveOutputFwd
export type Guide = PlaneGuide | PointGuide
export type Light = AreaLight | LightPortal
export type Object = Mesh | TemplateInstanceFwd | Guide | Light | CameraFwd | Annotation | MeshDecal | Curve | SeamFwd | DimensionGuidesFwd
export type ObjectLike = Object | MeshLike | CurveLike | ObjectInputFwd | ObjectSwitchFwd | ObjectOutputFwd

export type MaterialLike =
    | FindMaterial
    | MaterialReference
    | MaterialGraphReference
    | OverlayMaterialColorFwd
    | MoveMaterialFwd
    | BackgroundMaterial
    | MaterialInputFwd
    | MaterialSwitchFwd
    | MaterialOutputFwd

export type TemplateLike = TemplateReference | TemplateGraphFwd | TemplateInputFwd | TemplateSwitchFwd | TemplateOutputFwd

export type ImageLike =
    | DataObjectReference
    | TransientDataObject
    | ImageOperatorFwd
    | ImageRgbCurveFwd
    | DistanceTextureFwd
    | ImageInputFwd
    | ImageSwitchFwd
    | ImageOutputFwd

export type StringLikeNode = StringResolve | RegexReplaceFwd | StringValue | StringInput | StringSwitchFwd | StringOutputFwd
export type StringLike = string | StringLikeNode
export type NumberLikeNode = NumberValue | NumberInput | NumberSwitchFwd | NumberOutputFwd
export type NumberLike = number | NumberLikeNode
export type BooleanLikeNode = BooleanValue | LodType | BooleanInput | BooleanSwitchFwd | BooleanOutputFwd
export type BooleanLike = boolean | BooleanLikeNode
export type JSONLikeNode = JSONValue | JSONInput | JSONSwitchFwd | JSONOutput
export type JSONLike = AnyJSONValue | JSONLikeNode

export type Variable = R1Variable | S1Variable | S3Variable

export type Input =
    | ObjectInput
    | MeshInput
    | CurveInput
    | MaterialInput
    | TemplateInput
    | ImageInput
    | StringInput
    | NumberInput
    | BooleanInput
    | JSONInput
    | NodesInput
export type Output =
    | ObjectOutput
    | MeshOutput
    | CurveOutput
    | MaterialOutput
    | TemplateOutput
    | ImageOutput
    | StringOutput
    | NumberOutput
    | BooleanOutput
    | JSONOutput
export type Export =
    | ObjectExport
    | MeshExport
    | CurveExport
    | MaterialExport
    | TemplateExport
    | ImageExport
    | StringExport
    | NumberExport
    | BooleanExport
    | JSONExport
export type Value = StringValue | NumberValue | BooleanValue | JSONValue

export type Switch =
    | ObjectSwitch
    | MeshSwitch
    | CurveSwitch
    | MaterialSwitch
    | TemplateSwitch
    | ImageSwitch
    | StringSwitch
    | NumberSwitch
    | BooleanSwitch
    | JSONSwitch
export type SwitchContainer =
    | ObjectLikes
    | MeshLikes
    | CurveLikes
    | MaterialLikes
    | TemplateLikes
    | ImageLikes
    | StringLikes
    | NumberLikes
    | BooleanLikes
    | JSONLikes

export type NodeOwner = TemplateGraphFwd | GroupFwd | ConfigVariantFwd | ConfigGroupFwd
export type TemplateOwningContainer = Nodes
export type TemplateReferenceContainer = SwitchContainer
export type TemplateContainer = TemplateOwningContainer | TemplateReferenceContainer

export type ValueNode =
    | Exclude<ObjectLike, Object>
    | MaterialLike
    | TemplateLike
    | ImageLike
    | StringLikeNode
    | NumberLikeNode
    | BooleanLikeNode
    | JSONLikeNode
    | Export
    | Variable

export type EvaluableNode =
    | MaterialLike
    | TemplateLike
    | ObjectLike
    | ImageLike
    | StringLikeNode
    | NumberLikeNode
    | BooleanLikeNode
    | JSONLikeNode
    | NodesInputFwd

export type Node =
    | ObjectLike
    | MaterialLike
    | TemplateLike
    | ImageLike
    | StringLikeNode
    | NumberLikeNode
    | BooleanLikeNode
    | JSONLikeNode
    | Export
    | RigidRelation
    | Variable
    | HDRILight
    | SceneProperties
    | Render
    | PostProcessRender
    | NodeOwner
    | NodesInput

///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const meshClasses = ["StoredMesh", "ProceduralMesh", "CutMesh"]
export const meshLikeClasses = [...meshClasses, "MeshInput", "MeshSwitch", "MeshOutput"]
export const curveClasses = ["MeshCurve"]
export const curveLikeClasses = [...curveClasses, "CurveInput", "CurveSwitch", "CurveOutput"]
export const lightClasses = ["AreaLight", "LightPortal"]
export const guideClasses = ["PlaneGuide", "PointGuide"]
export const objectClasses = [
    ...meshClasses,
    "TemplateInstance",
    ...guideClasses,
    ...lightClasses,
    "Camera",
    "Annotation",
    "MeshDecal",
    ...curveClasses,
    "Seam",
    "DimensionGuides",
]
export const objectLikeClasses = [...objectClasses, ...meshLikeClasses, ...curveLikeClasses, "ObjectInput", "ObjectSwitch", "ObjectOutput"]

export const materialLikeClasses = [
    "FindMaterial",
    "MaterialReference",
    "MaterialGraphReference",
    "OverlayMaterialColor",
    "MoveMaterial",
    "BackgroundMaterial",
    "MaterialInput",
    "MaterialSwitch",
    "MaterialOutput",
]

export const templateLikeClasses = ["TemplateReference", "TemplateGraph", "TemplateInput", "TemplateSwitch", "TemplateOutput"]

export const imageLikeClasses = [
    "DataObjectReference",
    "TransientDataObject",
    "ImageOperator",
    "ImageRgbCurve",
    "DistanceTexture",
    "ImageInput",
    "ImageSwitch",
    "ImageOutput",
]

export const stringLikeNodeClasses = ["StringResolve", "RegexReplace", "StringValue", "StringInput", "StringSwitch", "StringOutput"]
export const numberLikeNodeClasses = ["NumberValue", "NumberInput", "NumberSwitch", "NumberOutput"]
export const booleanLikeNodeClasses = ["BooleanValue", "LodType", "BooleanInput", "BooleanSwitch", "BooleanOutput"]
export const jsonLikeNodeClasses = ["JSONValue", "JSONInput", "JSONSwitch", "JSONOutput"]

export const variableClasses = ["R1Variable", "S1Variable", "S3Variable"]

export const inputClasses = [
    "ObjectInput",
    "MeshInput",
    "CurveInput",
    "MaterialInput",
    "TemplateInput",
    "ImageInput",
    "StringInput",
    "NumberInput",
    "BooleanInput",
    "JSONInput",
    "NodesInput",
]
export const outputClasses = [
    "ObjectOutput",
    "MeshOutput",
    "CurveOutput",
    "MaterialOutput",
    "TemplateOutput",
    "ImageOutput",
    "StringOutput",
    "NumberOutput",
    "BooleanOutput",
    "JSONOutput",
]
export const exportClasses = [
    "ObjectExport",
    "MeshExport",
    "CurveExport",
    "MaterialExport",
    "TemplateExport",
    "ImageExport",
    "StringExport",
    "NumberExport",
    "BooleanExport",
    "JSONExport",
]
export const valueClasses = ["StringValue", "NumberValue", "BooleanValue", "JSONValue"]

export const switchClasses = [
    "ObjectSwitch",
    "MeshSwitch",
    "CurveSwitch",
    "MaterialSwitch",
    "TemplateSwitch",
    "ImageSwitch",
    "StringSwitch",
    "NumberSwitch",
    "BooleanSwitch",
    "JSONSwitch",
]
export const switchContainerClasses = [
    "ObjectLikes",
    "MeshLikes",
    "CurveLikes",
    "MaterialLikes",
    "TemplateLikes",
    "ImageLikes",
    "StringLikes",
    "NumberLikes",
    "BooleanLikes",
    "JSONLikes",
]

export const nodeOwnerClasses = ["TemplateGraph", "Group", "ConfigVariant", "ConfigGroup"]
export const templateOwningContainerClasses = ["Nodes"]
export const templateReferenceContainerClasses = [...switchContainerClasses]
export const templateContainerClasses = [...templateOwningContainerClasses, ...templateReferenceContainerClasses]

export const valueNodeClasses = [
    ...objectLikeClasses.filter((c) => !objectClasses.includes(c)),
    ...materialLikeClasses,
    ...templateLikeClasses,
    ...imageLikeClasses,
    ...stringLikeNodeClasses,
    ...numberLikeNodeClasses,
    ...booleanLikeNodeClasses,
    ...jsonLikeNodeClasses,
    ...exportClasses,
    ...variableClasses,
]

export const evaluableNodeClasses = [
    ...materialLikeClasses,
    ...templateLikeClasses,
    ...objectLikeClasses,
    ...imageLikeClasses,
    ...stringLikeNodeClasses,
    ...numberLikeNodeClasses,
    ...booleanLikeNodeClasses,
    ...jsonLikeNodeClasses,
    "NodesInput",
]

export const nodeClasses = [
    ...objectLikeClasses,
    ...materialLikeClasses,
    ...templateLikeClasses,
    ...imageLikeClasses,
    ...stringLikeNodeClasses,
    ...numberLikeNodeClasses,
    ...booleanLikeNodeClasses,
    ...jsonLikeNodeClasses,
    ...exportClasses,
    "RigidRelation",
    ...variableClasses,
    "HDRILight",
    "SceneProperties",
    "Render",
    "PostProcessRender",
    ...nodeOwnerClasses,
    "NodesInput",
]

///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const isMesh = (instance: TemplateNode): instance is Mesh => meshClasses.includes(instance.getNodeClass())
export const isMeshLike = (instance: TemplateNode): instance is MeshLike => meshLikeClasses.includes(instance.getNodeClass())
export const isCurve = (instance: TemplateNode): instance is Curve => curveClasses.includes(instance.getNodeClass())
export const isCurveLike = (instance: TemplateNode): instance is CurveLike => curveLikeClasses.includes(instance.getNodeClass())
export const isGuide = (instance: TemplateNode): instance is Guide => guideClasses.includes(instance.getNodeClass())
export const isLight = (instance: TemplateNode): instance is Light => lightClasses.includes(instance.getNodeClass())
export const isObject = (instance: TemplateNode): instance is Object => objectClasses.includes(instance.getNodeClass())
export const isObjectLike = (instance: TemplateNode): instance is ObjectLike => objectLikeClasses.includes(instance.getNodeClass())

export const isMaterialLike = (instance: TemplateNode): instance is MaterialLike => materialLikeClasses.includes(instance.getNodeClass())

export const isTemplateLike = (instance: TemplateNode): instance is TemplateLike => templateLikeClasses.includes(instance.getNodeClass())

export const isImageLike = (instance: TemplateNode): instance is ImageLike => imageLikeClasses.includes(instance.getNodeClass())

export const isStringLikeNode = (instance: TemplateNode): instance is StringLikeNode => stringLikeNodeClasses.includes(instance.getNodeClass())
export const isStringLike = (instance: TemplateNode | string): instance is StringLike => typeof instance === "string" || isStringLikeNode(instance)
export const isNumberLikeNode = (instance: TemplateNode): instance is NumberLikeNode => numberLikeNodeClasses.includes(instance.getNodeClass())
export const isNumberLike = (instance: TemplateNode | number): instance is NumberLike => typeof instance === "number" || isNumberLikeNode(instance)
export const isBooleanLikeNode = (instance: TemplateNode): instance is BooleanLikeNode => booleanLikeNodeClasses.includes(instance.getNodeClass())
export const isBooleanLike = (instance: TemplateNode | boolean): instance is BooleanLike => typeof instance === "boolean" || isBooleanLikeNode(instance)
export const isJSONLikeNode = (instance: TemplateNode): instance is JSONLikeNode => jsonLikeNodeClasses.includes(instance.getNodeClass())
export const isJSONLike = (instance: TemplateNode | AnyJSONValue): instance is JSONLike => isAnyJSONValue(instance) || isJSONLikeNode(instance)

export const isVariable = (instance: TemplateNode): instance is Variable => variableClasses.includes(instance.getNodeClass())

export const isInput = (instance: TemplateNode): instance is Input => inputClasses.includes(instance.getNodeClass())
export const isOutput = (instance: TemplateNode): instance is Output => outputClasses.includes(instance.getNodeClass())
export const isExport = (instance: TemplateNode): instance is Export => exportClasses.includes(instance.getNodeClass())
export const isValue = (instance: TemplateNode): instance is Value => valueClasses.includes(instance.getNodeClass())

export const isSwitch = (instance: TemplateNode): instance is Switch => switchClasses.includes(instance.getNodeClass())
export const isSwitchContainer = (instance: TemplateNode): instance is SwitchContainer => switchContainerClasses.includes(instance.getNodeClass())

export const isNodeOwner = (instance: TemplateNode): instance is NodeOwner => nodeOwnerClasses.includes(instance.getNodeClass())
export const isTemplateOwningContainer = (instance: TemplateNode): instance is TemplateOwningContainer =>
    templateOwningContainerClasses.includes(instance.getNodeClass())
export const isTemplateReferenceContainer = (instance: TemplateNode): instance is TemplateReferenceContainer =>
    templateReferenceContainerClasses.includes(instance.getNodeClass())
export const isTemplateContainer = (instance: TemplateNode): instance is TemplateContainer => templateContainerClasses.includes(instance.getNodeClass())

export const isNodeValue = (instance: TemplateNode): instance is ValueNode => valueNodeClasses.includes(instance.getNodeClass())

export const isEvaluableNode = (instance: TemplateNode): instance is EvaluableNode => evaluableNodeClasses.includes(instance.getNodeClass())

export const isNode = (instance: TemplateNode): instance is Node => nodeClasses.includes(instance.getNodeClass())

///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const mesh = templateNode.refine(isMesh, {message: "Expected mesh"})
export const meshLike = templateNode.refine(isMeshLike, {message: "Expected mesh like"})
export const curve = templateNode.refine(isCurve, {message: "Expected curve"})
export const curveLike = templateNode.refine(isCurveLike, {message: "Expected curve like"})
export const guide = templateNode.refine(isGuide, {message: "Expected guide"})
export const light = templateNode.refine(isLight, {message: "Expected light"})
export const object = templateNode.refine(isObject, {message: "Expected object"})
export const objectLike = templateNode.refine(isObjectLike, {message: "Expected object like"})

export const materialLike = templateNode.refine(isMaterialLike, {message: "Expected material like"})

export const templateLike = templateNode.refine(isTemplateLike, {message: "Expected template definition"})

export const imageLike = templateNode.refine(isImageLike, {message: "Expected image like"})

export const stringLikeNode = templateNode.refine(isStringLikeNode, {message: "Expected string like node"})
export const stringLike = templateNode.or(z.string()).refine(isStringLike, {message: "Expected string like"})
export const numberLikeNode = templateNode.refine(isNumberLikeNode, {message: "Expected number like node"})
export const numberLike = templateNode.or(z.number()).refine(isNumberLike, {message: "Expected number like"})
export const booleanLikeNode = templateNode.refine(isBooleanLikeNode, {message: "Expected boolean like node"})
export const booleanLike = templateNode.or(z.boolean()).refine(isBooleanLike, {message: "Expected boolean like"})
export const jsonLikeNode = templateNode.refine(isJSONLikeNode, {message: "Expected JSON like node"})
export const jsonLike = templateNode.or(anyJsonValue).refine(isJSONLike, {message: "Expected JSON like"})

export const variable = templateNode.refine(isVariable, {message: "Expected variable"})

export const input = templateNode.refine(isInput, {message: "Expected input"})
export const output = templateNode.refine(isOutput, {message: "Expected output"})
export const exportNode = templateNode.refine(isExport, {message: "Expected export"})
export const value = templateNode.refine(isValue, {message: "Expected value"})

export const switchNode = templateNode.refine(isSwitch, {message: "Expected switch"})
export const switchContainer = templateNode.refine(isSwitchContainer, {message: "Expected switch container"})

export const nodeOwner = templateNode.refine(isNodeOwner, {message: "Expected node owner"})
export const templateOwningContainer = templateNode.refine(isTemplateOwningContainer, {message: "Expected template owning container"})
export const templateReferenceContainer = templateNode.refine(isTemplateReferenceContainer, {message: "Expected template reference container"})
export const templateNodeContainer = templateNode.refine(isTemplateContainer, {message: "Expected template node container"})

export const valueNode = templateNode.refine(isNodeValue, {message: "Expected value node"})

export const evaluableNode = templateNode.refine(isEvaluableNode, {message: "Expected evaluable template node"})

export const node = templateNode.refine(isNode, {message: "Expected node"})
