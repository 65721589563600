import {JobTaskState} from "@generated"
import {StateLabel} from "@platform/models/state-labels/state-label"

export const JobTaskStateLabels: Map<JobTaskState, StateLabel<JobTaskState>> = new Map<JobTaskState, StateLabel<JobTaskState>>([
    [JobTaskState.Cancelled, {state: JobTaskState.Cancelled, label: "Cancelled"}],
    [JobTaskState.Complete, {state: JobTaskState.Complete, label: "Complete"}],
    [JobTaskState.Failed, {state: JobTaskState.Failed, label: "Failed"}],
    [JobTaskState.Fatal, {state: JobTaskState.Fatal, label: "Fatal"}],
    [JobTaskState.Init, {state: JobTaskState.Init, label: "Init"}],
    [JobTaskState.Runnable, {state: JobTaskState.Runnable, label: "Runnable"}],
    [JobTaskState.Running, {state: JobTaskState.Running, label: "Running"}],
    [JobTaskState.Timeout, {state: JobTaskState.Timeout, label: "Timeout"}],
])
