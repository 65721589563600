import {Vector2, Vector2Like} from "@cm/math"
import {CrosshairToolboxItem} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/crosshair-toolbox-item"
import {CanvasBaseToolboxItem, ToolMouseEvent} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export class SelectCloneSourceToolboxItem extends CrosshairToolboxItem {
    constructor(parent: CanvasBaseToolboxItem) {
        super(parent)
        this.cursor = "crosshair"
        this.cloneSourcePosition = new Vector2(0, 0)
    }

    override hitTest(point: Vector2Like): CanvasBaseToolboxItem | null {
        if (this.selected && this.isPointInImage(point)) {
            return this
        }
        return null
    }

    override onMouseDown(event: ToolMouseEvent): boolean {
        super.onMouseDown(event)
        this.cloneSourcePosition = event.point.floor()
        return false
    }

    override onMouseDrag(event: ToolMouseEvent): boolean {
        super.onMouseDrag(event)
        this.cloneSourcePosition = event.point.floor()
        return false
    }

    set cloneSourcePosition(value: Vector2Like) {
        this._cloneSourcePosition.setFromVector2Like(value)
        this.crosshairPosition = this._cloneSourcePosition.add(new paper.Point(0.5, 0.5))
    }

    get cloneSourcePosition(): Vector2 {
        return this._cloneSourcePosition
    }

    private _cloneSourcePosition = new Vector2(0, 0)
}
