import {AsyncPipe} from "@angular/common"
import {AfterViewInit, Component, computed, inject, OnInit, signal, input, viewChild, output} from "@angular/core"
import {toObservable} from "@angular/core/rxjs-interop"
import {MatTooltipModule} from "@angular/material/tooltip"
import {RouterLink} from "@angular/router"
import {TagFilterInput, TagType} from "@generated"
import {IsDefined} from "@cm/utils"
import {DropdownComponent} from "@common/components/buttons/dropdown/dropdown.component"
import {FilterListComponent} from "@common/components/filters/filter-list/filter-list.component"
import {
    TagSearchFilterOrganizationsGQL,
    TagSearchFilterTagsGQL,
    TagSearchFilterUserGQL,
} from "@common/components/filters/tag-search-filter/tag-search-filter.generated"
import {SearchComponent} from "@common/components/inputs/search/search.component"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {fetchIgnoringErrors, fetchThrowingErrors} from "@common/helpers/api/fetch"
import {AuthService} from "@common/services/auth/auth.service"
import {FiltersService} from "@common/services/filters/filters.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {Labels, StateLabel} from "@labels"

@Component({
    selector: "cm-tag-search-filter",
    templateUrl: "./tag-search-filter.component.html",
    styleUrls: ["./tag-search-filter.component.scss"],
    imports: [MatTooltipModule, FilterListComponent, DropdownComponent, SearchComponent, RouterLink, AsyncPipe, PlaceholderComponent],
})
export class TagSearchFilterComponent implements OnInit, AfterViewInit {
    readonly $selectableTagTypes = input<TagType[]>([], {alias: "selectableTagTypes"})
    readonly $placeholder = input("Search...", {alias: "placeholder"})
    readonly change = output<string>()

    readonly $searchComponent = viewChild(SearchComponent)

    private readonly tagSearchFilterUser = inject(TagSearchFilterUserGQL)
    private readonly tagSearchFilterTags = inject(TagSearchFilterTagsGQL)
    private readonly tagSearchFilterOrganizations = inject(TagSearchFilterOrganizationsGQL)

    public focus = false
    private readonly $searchText = signal("")
    public searchText$ = toObservable(this.$searchText)
    public readonly $filteredTags = computed(() =>
        (this.$visibleTags() ?? [])
            .filter((tag) => tag.label.toLowerCase().search(this.$searchText().toLowerCase()) != -1)
            .filter((tag) => !(this.$selectedTags() ?? []).includes(tag))
            .slice(0, 5),
    )
    public readonly $filteredOrganizations = computed(() => {
        if (this.$can().read.menu("filterByOrganization")) {
            return (this.$visibleOrganizations() ?? [])
                .filter(
                    (option) =>
                        option.label.toLowerCase().search(this.$searchText().toLowerCase()) != -1 &&
                        (this.$selectedOrganizations() ?? []).every((selectedOrganization) => option.state !== selectedOrganization.state),
                )
                .slice(0, 2)
        } else {
            return []
        }
    })
    public readonly $selectedTagIds = signal<string[]>([])
    public readonly $selectedTags = computed(() => (this.$visibleTags() ?? []).filter((tag) => this.$selectedTagIds().includes(tag.state)))
    public readonly $selectedOrganizationIds = signal<string[]>([])
    public readonly $selectedOrganizations = computed(() =>
        (this.$visibleOrganizations() ?? []).filter((organization) => this.$selectedOrganizationIds().includes(organization.state)),
    )

    public readonly $showLoadingPlaceholders = computed(() => {
        return this.$visibleTags() === null || this.$visibleOrganizations() === null
    })

    private readonly auth = inject(AuthService)
    private readonly filtersService = inject(FiltersService)
    protected readonly permission = inject(PermissionsService)
    public readonly organizations = inject(OrganizationsService)
    $can = this.permission.$to

    private filterStates$ = this.filtersService.states
    readonly $visibleTags = signal<
        | {
              state: string
              label: string
              background?: string
              typeLabel?: string
          }[]
        | null
    >(null)
    readonly $visibleOrganizations = signal<
        | {
              state: string
              label: string
              background?: string
              typeLabel?: string
          }[]
        | null
    >(null)
    public readonly $showDropdown = computed(() => !!this.$searchText().length)
    public readonly $hasData = computed(() => {
        return (
            this.$filteredTags() === null ||
            this.$filteredTags()?.length > 0 ||
            this.$filteredOrganizations() === null ||
            this.$filteredOrganizations()?.length > 0
        )
    })
    currentOrganizationId: string | null = null

    ngOnInit() {
        this.currentOrganizationId = this.organizations.current?.id ?? null
    }

    ngAfterViewInit() {
        const initialSearchText = this.filtersService.currentStates.search ?? ""
        this.$searchText.set(initialSearchText)
        this.$searchComponent()?.updateValue(initialSearchText)

        this.filtersService.states.subscribe((states) => {
            this.$searchText.set(states.search ?? "")

            const selectedOrganizationIds = Array.from(states.criteria["organizationId"]?.values() ?? [])
            this.$selectedOrganizationIds.set(selectedOrganizationIds)

            const selectedTagIds = Array.from(states.criteria["tagId"]?.values() ?? [])
            this.$selectedTagIds.set(selectedTagIds)

            if (selectedOrganizationIds.length) {
                void this.loadOrganizations()
            }
            if (selectedTagIds.length) {
                void this.loadTags()
            }
        })
    }

    private isLoadingTags = false
    async loadTags() {
        try {
            const selectableTagTypes = this.$selectableTagTypes()
            if (!selectableTagTypes.length) {
                this.$visibleTags.set([])
            }
            if (this.$visibleTags() === null && !this.isLoadingTags) {
                this.isLoadingTags = true
                const filter: TagFilterInput = {tagType: selectableTagTypes}
                if (!this.auth.isStaff()) {
                    const userId = this.auth.$user()?.id
                    if (userId) {
                        const {user} = await fetchIgnoringErrors(this.tagSearchFilterUser)({id: userId})
                        filter.organizationId = {
                            in: user.memberships.map((membership) => membership.organization?.id).filter(IsDefined),
                        }
                    }
                }
                const {tags} = await fetchIgnoringErrors(this.tagSearchFilterTags)({filter})
                this.$visibleTags.set(
                    tags.filter(IsDefined).map((tag) => {
                        const tagStateLabel = Labels.TagType.get(tag.type)
                        return {
                            state: tag.id,
                            label: tag.name,
                            background: tagStateLabel?.background,
                            typeLabel: tagStateLabel?.label,
                        }
                    }),
                )
            }
        } finally {
            this.isLoadingTags = false
        }
    }
    private isLoadingOrganizations = false
    async loadOrganizations() {
        try {
            if (this.$visibleOrganizations() === null && !this.isLoadingOrganizations) {
                this.isLoadingOrganizations = true
                const {organizations} = await fetchIgnoringErrors(this.tagSearchFilterOrganizations)({filter: {visibleInFilters: true}})
                // we might also have organizations in the URL that are not visible in the filters
                // these need to be loaded separately
                const invisibleOrganizationsInUrl = this.$selectedOrganizationIds().filter(
                    (id) => !organizations.some((organization) => organization?.id === id),
                )
                if (invisibleOrganizationsInUrl.length) {
                    const {organizations: organizationsInUrl} = await fetchThrowingErrors(this.tagSearchFilterOrganizations)({
                        filter: {id: {in: invisibleOrganizationsInUrl}},
                    })
                    organizations.push(...organizationsInUrl)
                }
                this.$visibleOrganizations.set(
                    organizations
                        .filter(IsDefined)
                        .filter((organization) => !!organization.name)
                        .map((organization) => ({
                            state: organization.id,
                            label: organization.name!,
                        })),
                )
            }
        } finally {
            this.isLoadingOrganizations = false
        }
    }

    onFocus = async () => {
        this.focus = true
        void this.loadTags()
        void this.loadOrganizations()
    }

    onSearchTextChanged = async (text: string) => {
        await this.filtersService.updateSearchText(text)
        this.change.emit(text)
    }

    onSelectOrganization = async (organization: StateLabel<string>) => {
        await this.filtersService.updateCriteria(
            "organizationId",
            [...(this.$selectedOrganizations() ?? []), organization].map((stateLabel) => stateLabel.state),
        )
        this.$searchComponent()?.updateValue("")
    }

    onRemoveOrganization = async (organization: StateLabel<string>) => {
        const selectedOrganizations = (this.$selectedOrganizations() ?? []).filter((selectedOrganization) => selectedOrganization.state != organization.state)
        await this.filtersService.updateCriteria(
            "organizationId",
            selectedOrganizations.map((stateLabel) => stateLabel.state),
        )
        this.change.emit(this.$searchText())
    }

    onSelectTag = async (tag: StateLabel<string> & {typeLabel?: string}) => {
        await this.filtersService.updateCriteria(
            "tagId",
            [...(this.$selectedTags() ?? []), tag].map((stateLabel) => stateLabel.state),
        )
        this.$searchComponent()?.updateValue("")
    }

    onRemoveTag = async (tag: StateLabel<string> & {typeLabel?: string}) => {
        await this.filtersService.updateCriteria(
            "tagId",
            (this.$selectedTags() ?? []).filter((selectedTag) => selectedTag.state !== tag.state).map((stateLabel) => stateLabel.state),
        )
        this.change.emit(this.$searchText())
    }
}
