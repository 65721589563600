import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type JobsTableItemFragment = { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, output?: any | null, priority: number, progress?: number | null, started?: string | null, state: Types.JobState, updatedAt?: string | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, updatedBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } };

export type JobsTableItemsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.JobFilterInput>;
  orderBy?: Types.InputMaybe<Array<Types.JobOrderByInput> | Types.JobOrderByInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type JobsTableItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, output?: any | null, priority: number, progress?: number | null, started?: string | null, state: Types.JobState, updatedAt?: string | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, updatedBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } | null> };

export type JobsTableNudgeJobMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type JobsTableNudgeJobMutation = { __typename: 'Mutation', nudgeJob: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, output?: any | null, priority: number, progress?: number | null, started?: string | null, state: Types.JobState, updatedAt?: string | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, updatedBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type JobsTableUpdateJobMutationVariables = Types.Exact<{
  input: Types.MutationUpdateJobInput;
}>;


export type JobsTableUpdateJobMutation = { __typename: 'Mutation', updateJob: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, output?: any | null, priority: number, progress?: number | null, started?: string | null, state: Types.JobState, updatedAt?: string | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, updatedBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type JobsTableCancelJobMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type JobsTableCancelJobMutation = { __typename: 'Mutation', cancelJob: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, output?: any | null, priority: number, progress?: number | null, started?: string | null, state: Types.JobState, updatedAt?: string | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, updatedBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type JobsTableCleanJobMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type JobsTableCleanJobMutation = { __typename: 'Mutation', rerunAllTasksInJob: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, output?: any | null, priority: number, progress?: number | null, started?: string | null, state: Types.JobState, updatedAt?: string | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, updatedBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type JobsTableRestartJobMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type JobsTableRestartJobMutation = { __typename: 'Mutation', restartJob: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, output?: any | null, priority: number, progress?: number | null, started?: string | null, state: Types.JobState, updatedAt?: string | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, updatedBy?: { __typename: 'User', id: string, legacyId: number, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export const JobsTableItemFragmentDoc = gql`
    fragment JobsTableItem on Job {
  id
  legacyId
  cancelled
  createdAt
  finished
  message
  name
  output
  priority
  progress
  started
  state
  updatedAt
  createdBy {
    id
    legacyId
    name
    galleryImage {
      id
    }
  }
  updatedBy {
    id
    legacyId
    name
    galleryImage {
      id
    }
  }
  organization {
    id
    legacyId
    name
  }
}
    `;
export const JobsTableItemsDocument = gql`
    query jobsTableItems($filter: JobFilterInput, $orderBy: [JobOrderByInput!], $take: Int, $skip: Int) {
  items: jobs(filter: $filter, orderBy: $orderBy, take: $take, skip: $skip) {
    ...JobsTableItem
  }
  totalCount: jobsCount(filter: $filter)
}
    ${JobsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobsTableItemsGQL extends Apollo.Query<JobsTableItemsQuery, JobsTableItemsQueryVariables> {
    override document = JobsTableItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobsTableNudgeJobDocument = gql`
    mutation jobsTableNudgeJob($id: ID!) {
  nudgeJob(id: $id) {
    ...JobsTableItem
  }
}
    ${JobsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobsTableNudgeJobGQL extends Apollo.Mutation<JobsTableNudgeJobMutation, JobsTableNudgeJobMutationVariables> {
    override document = JobsTableNudgeJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobsTableUpdateJobDocument = gql`
    mutation jobsTableUpdateJob($input: MutationUpdateJobInput!) {
  updateJob(input: $input) {
    ...JobsTableItem
  }
}
    ${JobsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobsTableUpdateJobGQL extends Apollo.Mutation<JobsTableUpdateJobMutation, JobsTableUpdateJobMutationVariables> {
    override document = JobsTableUpdateJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobsTableCancelJobDocument = gql`
    mutation jobsTableCancelJob($id: ID!) {
  cancelJob(id: $id) {
    ...JobsTableItem
  }
}
    ${JobsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobsTableCancelJobGQL extends Apollo.Mutation<JobsTableCancelJobMutation, JobsTableCancelJobMutationVariables> {
    override document = JobsTableCancelJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobsTableCleanJobDocument = gql`
    mutation jobsTableCleanJob($id: ID!) {
  rerunAllTasksInJob(id: $id) {
    ...JobsTableItem
  }
}
    ${JobsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobsTableCleanJobGQL extends Apollo.Mutation<JobsTableCleanJobMutation, JobsTableCleanJobMutationVariables> {
    override document = JobsTableCleanJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobsTableRestartJobDocument = gql`
    mutation jobsTableRestartJob($id: ID!) {
  restartJob(id: $id) {
    ...JobsTableItem
  }
}
    ${JobsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobsTableRestartJobGQL extends Apollo.Mutation<JobsTableRestartJobMutation, JobsTableRestartJobMutationVariables> {
    override document = JobsTableRestartJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }