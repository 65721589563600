import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UserDetailsFragment = { __typename: 'User', id: string, legacyId: number, createdAt?: string | null, dateJoined?: string | null, email: string, emailNotifications: boolean, firstName?: string | null, isActive: boolean, isStaff: boolean, isSuperuser: boolean, lastName?: string | null, name: string, visibleInFilters: boolean, systemRole?: Types.SystemRole | null, galleryImage?: { __typename: 'DataObject', id: string } | null, memberships: Array<{ __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, name?: string | null } }> };

export type UserDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type UserDetailsQuery = { __typename: 'Query', item: { __typename: 'User', id: string, legacyId: number, createdAt?: string | null, dateJoined?: string | null, email: string, emailNotifications: boolean, firstName?: string | null, isActive: boolean, isStaff: boolean, isSuperuser: boolean, lastName?: string | null, name: string, visibleInFilters: boolean, systemRole?: Types.SystemRole | null, galleryImage?: { __typename: 'DataObject', id: string } | null, memberships: Array<{ __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, name?: string | null } }> } };

export type UpdateUserDetailsMutationVariables = Types.Exact<{
  input: Types.MutationUpdateUserInput;
}>;


export type UpdateUserDetailsMutation = { __typename: 'Mutation', item: { __typename: 'User', id: string, legacyId: number, createdAt?: string | null, dateJoined?: string | null, email: string, emailNotifications: boolean, firstName?: string | null, isActive: boolean, isStaff: boolean, isSuperuser: boolean, lastName?: string | null, name: string, visibleInFilters: boolean, systemRole?: Types.SystemRole | null, galleryImage?: { __typename: 'DataObject', id: string } | null, memberships: Array<{ __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, name?: string | null } }> } };

export type UserDetailsCreateMembershipMutationVariables = Types.Exact<{
  input: Types.MutationCreateMembershipInput;
}>;


export type UserDetailsCreateMembershipMutation = { __typename: 'Mutation', item: { __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, name?: string | null } } };

export type UserDetailsDeleteMembershipMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type UserDetailsDeleteMembershipMutation = { __typename: 'Mutation', item?: any | null };

export const UserDetailsFragmentDoc = gql`
    fragment UserDetails on User {
  id
  legacyId
  __typename
  createdAt
  dateJoined
  email
  emailNotifications
  firstName
  isActive
  isStaff
  isSuperuser
  lastName
  name
  systemRole: role
  visibleInFilters
  galleryImage {
    id
  }
  memberships {
    id
    organization {
      id
      name
    }
  }
}
    `;
export const UserDetailsDocument = gql`
    query userDetails($id: ID!) {
  item: user(id: $id) {
    ...UserDetails
  }
}
    ${UserDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserDetailsGQL extends Apollo.Query<UserDetailsQuery, UserDetailsQueryVariables> {
    override document = UserDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDetailsDocument = gql`
    mutation updateUserDetails($input: MutationUpdateUserInput!) {
  item: updateUser(input: $input) {
    ...UserDetails
  }
}
    ${UserDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserDetailsGQL extends Apollo.Mutation<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables> {
    override document = UpdateUserDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserDetailsCreateMembershipDocument = gql`
    mutation userDetailsCreateMembership($input: MutationCreateMembershipInput!) {
  item: createMembership(input: $input) {
    id
    organization {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserDetailsCreateMembershipGQL extends Apollo.Mutation<UserDetailsCreateMembershipMutation, UserDetailsCreateMembershipMutationVariables> {
    override document = UserDetailsCreateMembershipDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserDetailsDeleteMembershipDocument = gql`
    mutation userDetailsDeleteMembership($id: ID!) {
  item: deleteMembership(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserDetailsDeleteMembershipGQL extends Apollo.Mutation<UserDetailsDeleteMembershipMutation, UserDetailsDeleteMembershipMutationVariables> {
    override document = UserDetailsDeleteMembershipDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }