<div
    class="cm-viewer-container"
    (mousedown)="$onboardingHintVisible() ? hideOnboardingIcons() : null"
    (touchstart)="$onboardingHintVisible() ? hideOnboardingIcons() : null"
>
    @if ($runningOperation() !== "none") {
        <cm-loading-spinner class="cm-processing-overlay">
            <ng-container cm-text-bottom>
                @switch ($runningOperation()) {
                    @case ("gltf") {
                        Generating glTF...
                    }
                    @case ("stl") {
                        Generating STL files...
                    }
                    @case ("ar") {
                        Generating AR model...
                    }
                }
            </ng-container>
        </cm-loading-spinner>
    }
    @if ($onboardingHintVisible() && $runningOperation() === "none") {
        <cm-onboarding-hint></cm-onboarding-hint>
    }
    <cm-three-template-scene-provider (onInitialized)="onInititalizedThreeSceneManagerService($event)">
        <cm-three-template-scene-viewer #viewer [camera]="$camera()" [allowEdit]="false"></cm-three-template-scene-viewer>

        @if (showUi && $runningOperation() !== "loading") {
            <cm-action-menu
                class="cm-action-menu"
                [inFullscreen]="$inFullscreen()"
                [fullscreenEnabled]="$sceneProperties()?.parameters?.enableFullscreen ?? true"
                [stlDownloadEnabled]="$sceneProperties()?.parameters?.enableStlDownload ?? false"
                [gltfDownloadEnabled]="$sceneProperties()?.parameters?.enableGltfDownload ?? false"
                [snapshotEnabled]="$sceneProperties()?.parameters?.enableSnapshot ?? true"
                [arEnabled]="$sceneProperties()?.parameters?.enableAr ?? false"
                [pdfEnabled]="$sceneProperties()?.parameters?.enablePdfGeneration ?? false"
                [enableDimensionGuides]="$sceneProperties()?.parameters?.enableDimensionGuides ?? false"
                [dimensionGuidesActive]="localSceneManagerService.$showDimensionGuides()"
            >
                <ng-container cm-action-menu-additional>
                    <ng-content select="[cm-action-menu-additional]"></ng-content>
                </ng-container>
            </cm-action-menu>
            @if ($showMenu()) {
                <cm-config-menu
                    class="cm-config-menu"
                    exportparts="config-group-title, cm-icon-container, cm-configurator-menu, cm-icon-with-caption, cm-config-menu-icons"
                ></cm-config-menu>
            }
        }
    </cm-three-template-scene-provider>
</div>
