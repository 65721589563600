import {Component, Inject, inject} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {EditJobFarmInstanceDialogJobFarmGroupFragment} from "@app/platform/components/job-farm-instances/edit-job-farm-instance-dialog/edit-job-farm-instance-dialog.generated"
import {IsNonNull} from "@cm/utils/filter"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {EditJobFarmInstanceJobFarmGroupsGQL} from "@platform/components/job-farm-instances/edit-job-farm-instance-dialog/edit-job-farm-instance-dialog.generated"

export type JobFarmInstanceDialogResult = {
    id: string
    groupId: string | null
    delete?: boolean
}

@Component({
    selector: "cm-edit-job-farm-instance-dialog",
    templateUrl: "./edit-job-farm-instance-dialog.component.html",
    imports: [MatInputModule, MatSelectModule, MatDialogModule, FormsModule, MatButtonModule],
})
export class EditJobFarmInstanceDialogComponent {
    groups: EditJobFarmInstanceDialogJobFarmGroupFragment[] = []
    result: JobFarmInstanceDialogResult

    private readonly jobFarmGroupsGql = inject(EditJobFarmInstanceJobFarmGroupsGQL)

    constructor(
        public dialogRef: MatDialogRef<EditJobFarmInstanceDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public jobFarmInstance: {id: string; group: {id: string}},
    ) {
        this.result = {
            id: jobFarmInstance.id,
            groupId: jobFarmInstance.group?.id ?? null,
        }

        fetchThrowingErrors(this.jobFarmGroupsGql)({}).then((result) => {
            this.groups = result.jobFarmGroups.filter(IsNonNull)
        })
    }

    cancel(): void {
        this.dialogRef.close()
    }

    delete(): void {
        this.result.delete = true
        this.dialogRef.close(this.result)
    }

    update(): void {
        this.result.delete = false
        this.dialogRef.close(this.result)
    }
}
