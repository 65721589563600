<div class="cm-template-add-container">
    <div (click)="toggleSection('scene')" class="cm-library-button" [class.cm-active]="$currentSection() === 'scene'" cdkOverlayOrigin #origin>
        <i class="far fa-house"></i>
        Scenes
    </div>
    <div (click)="toggleSection('products')" class="cm-library-button" [class.cm-active]="$currentSection() === 'products'">
        <i class="far fa-couch"></i>
        Products
    </div>
    <div (click)="toggleSection('materials')" class="cm-library-button" [class.cm-active]="$currentSection() === 'materials'">
        <i class="far fa-game-board-alt"></i>
        Materials
    </div>
    <div (click)="toggleSection('scene-item')" class="cm-library-button" [class.cm-active]="$currentSection() === 'scene-item'">
        <i class="far fa-lightbulb"></i>
        Camera & Lights
    </div>
    <div (click)="toggleSection('other')" class="cm-library-button" [class.cm-active]="$currentSection() === 'other'">
        <i class="far fa-bars"></i>
        Other
    </div>
</div>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="origin" [cdkConnectedOverlayOpen]="$currentSection() !== undefined">
    <div class="cm-overlay-container">
        @switch ($currentSection()) {
            @case ("scene") {
                <cm-template-add-scene [gridSize]="'tiny'" (onItemClicked)="$currentSection.set(undefined)"></cm-template-add-scene>
            }
            @case ("products") {
                <cm-template-add-product [gridSize]="'tiny'" (onItemClicked)="$currentSection.set(undefined)"></cm-template-add-product>
            }
            @case ("materials") {
                <cm-template-add-material [gridSize]="'tiny'" (onItemClicked)="$currentSection.set(undefined)"></cm-template-add-material>
            }
            @case ("scene-item") {
                <cm-template-add-scene-item [gridSize]="'tiny'" (onItemClicked)="$currentSection.set(undefined)"></cm-template-add-scene-item>
            }
            @case ("other") {
                <cm-template-add-other (onItemClicked)="$currentSection.set(undefined)"></cm-template-add-other>
            }
        }
    </div>
</ng-template>
