import {inject, Injectable} from "@angular/core"
import {DownloadableFileFragment} from "@app/common/services/files/files.generated"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {DownloadableFileGQL} from "@common/services/files/files.generated"

@Injectable({providedIn: "root"})
export class FilesService {
    private readonly downloadableFile = inject(DownloadableFileGQL)

    static downloadFile(filename: string, file: File | Blob | string | undefined) {
        if (!file) {
            return
        }
        const link = document.createElement("a")
        document.body.appendChild(link)
        link.setAttribute("download", filename)
        let revokeURL: string | undefined
        if (file instanceof File || file instanceof Blob) {
            link.href = revokeURL = URL.createObjectURL(file)
        } else {
            link.href = file
        }
        link.style.display = "none"
        link.click()
        document.body.removeChild(link)
        if (revokeURL) {
            window.setTimeout(() => URL.revokeObjectURL(revokeURL!), 1000)
        }
    }

    static downloadDataObject(dataObject: DownloadableFileFragment): void {
        this.downloadFile(dataObject.originalFileName, dataObject.downloadUrl)
    }

    downloadDataObjectByLegacyId(legacyId: number) {
        fetchThrowingErrors(this.downloadableFile)({legacyId}).then(({dataObject}) => FilesService.downloadDataObject(dataObject))
    }

    downloadDataObjectById(id: string): void {
        fetchThrowingErrors(this.downloadableFile)({id}).then(({dataObject}) => FilesService.downloadDataObject(dataObject))
    }
}
