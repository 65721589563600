import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PictureViewerPictureFragment = { __typename: 'Picture', id: string, revisions: Array<{ __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', firstName?: string | null, lastName?: string | null } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, height?: number | null, imageColorProfile?: string | null, imageColorSpace?: Types.ImageColorSpace | null, mediaType?: string | null, originalFileName: string, size?: any | null, width?: number | null, downloadUrl: string, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }>, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: Types.DataObjectAssignmentType }> }> };

export type PictureViewerPictureRevisionFragment = { __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', firstName?: string | null, lastName?: string | null } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, height?: number | null, imageColorProfile?: string | null, imageColorSpace?: Types.ImageColorSpace | null, mediaType?: string | null, originalFileName: string, size?: any | null, width?: number | null, downloadUrl: string, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }>, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: Types.DataObjectAssignmentType }> };

export type PictureViewerDataObjectAssignmentFragment = { __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, height?: number | null, imageColorProfile?: string | null, imageColorSpace?: Types.ImageColorSpace | null, mediaType?: string | null, originalFileName: string, size?: any | null, width?: number | null, downloadUrl: string, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } };

export type PictureViewerPictureDataObjectThumbnailFragment = { __typename: 'DataObject', thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null };

export type PictureViewerPictureDataObjectFragment = { __typename: 'DataObject', id: string, height?: number | null, imageColorProfile?: string | null, imageColorSpace?: Types.ImageColorSpace | null, mediaType?: string | null, originalFileName: string, size?: any | null, width?: number | null, downloadUrl: string, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null };

export type PictureViewerItemQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type PictureViewerItemQuery = { __typename: 'Query', picture: { __typename: 'Picture', id: string, revisions: Array<{ __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', firstName?: string | null, lastName?: string | null } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, height?: number | null, imageColorProfile?: string | null, imageColorSpace?: Types.ImageColorSpace | null, mediaType?: string | null, originalFileName: string, size?: any | null, width?: number | null, downloadUrl: string, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }>, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: Types.DataObjectAssignmentType }> }> } };

export type PictureViewerPictureDataObjectThumbnailQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type PictureViewerPictureDataObjectThumbnailQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } };

export const PictureViewerPictureDataObjectThumbnailFragmentDoc = gql`
    fragment PictureViewerPictureDataObjectThumbnail on DataObject {
  thumbnail(fileType: jpg, resolution: High) {
    downloadUrl
  }
}
    `;
export const PictureViewerPictureDataObjectFragmentDoc = gql`
    fragment PictureViewerPictureDataObject on DataObject {
  id
  height
  imageColorProfile
  imageColorSpace
  mediaType
  originalFileName
  size
  width
  downloadUrl
  ...PictureViewerPictureDataObjectThumbnail
}
    `;
export const PictureViewerDataObjectAssignmentFragmentDoc = gql`
    fragment PictureViewerDataObjectAssignment on DataObjectAssignment {
  id
  dataObject {
    ...PictureViewerPictureDataObject
  }
}
    `;
export const PictureViewerPictureRevisionFragmentDoc = gql`
    fragment PictureViewerPictureRevision on PictureRevision {
  id
  createdAt
  createdBy {
    firstName
    lastName
  }
  number
  pictureDataAssignments: dataObjectAssignments(
    filter: {assignmentType: PictureData}
  ) {
    ...PictureViewerDataObjectAssignment
  }
  dataObjectAssignments {
    id
    assignmentType
  }
}
    `;
export const PictureViewerPictureFragmentDoc = gql`
    fragment PictureViewerPicture on Picture {
  id
  revisions {
    ...PictureViewerPictureRevision
  }
}
    `;
export const PictureViewerItemDocument = gql`
    query pictureViewerItem($id: ID!) {
  picture(id: $id) {
    ...PictureViewerPicture
  }
}
    ${PictureViewerPictureFragmentDoc}
${PictureViewerPictureRevisionFragmentDoc}
${PictureViewerDataObjectAssignmentFragmentDoc}
${PictureViewerPictureDataObjectFragmentDoc}
${PictureViewerPictureDataObjectThumbnailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureViewerItemGQL extends Apollo.Query<PictureViewerItemQuery, PictureViewerItemQueryVariables> {
    override document = PictureViewerItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureViewerPictureDataObjectThumbnailDocument = gql`
    query pictureViewerPictureDataObjectThumbnail($id: ID!) {
  dataObject(id: $id) {
    ...PictureViewerPictureDataObjectThumbnail
  }
}
    ${PictureViewerPictureDataObjectThumbnailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureViewerPictureDataObjectThumbnailGQL extends Apollo.Query<PictureViewerPictureDataObjectThumbnailQuery, PictureViewerPictureDataObjectThumbnailQueryVariables> {
    override document = PictureViewerPictureDataObjectThumbnailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }