import {Component, computed, inject, output} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {cardAnimations} from "@app/common/components/cards/animations"
import {BaseSelectComponent} from "@app/platform/components/base/base-select/base-select.component"
import {TemplateAddTemplateItemFragment} from "@app/template-editor/components/template-add-product/template-add-product.generated"
import {Matrix4} from "@cm/math"
import {Parameters, TemplateInstance, TemplateReference} from "@cm/template-nodes"
import {CardErrorComponent} from "@common/components/cards/card-error/card-error.component"
import {CardPlaceholderComponent} from "@common/components/cards/card-placeholder/card-placeholder.component"
import {OwnOrganizationsFilterComponent} from "@common/components/filters/own-organizations-filter/own-organizations-filter.component"
import {SearchComponent} from "@common/components/inputs/search/search.component"
import {InfiniteListComponent} from "@common/components/lists/infinite-list/infinite-list.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {TemplateFilterInput, TemplateType} from "@generated"
import {TemplateAddTemplateItemsGQL} from "@template-editor/components/template-add-product/template-add-product.generated"
import {v4 as uuid4} from "uuid"
import {TemplateAddCardComponent} from "../template-add-card/template-add-card.component"

@Component({
    selector: "cm-template-add-product",
    templateUrl: "./template-add-product.component.html",
    styleUrl: "./template-add-product.component.scss",
    animations: [...cardAnimations],
    imports: [
        SearchComponent,
        InfiniteListComponent,
        CardErrorComponent,
        TemplateAddCardComponent,
        CardPlaceholderComponent,
        OwnOrganizationsFilterComponent,
        MatTooltipModule,
    ],
})
export class TemplateAddProductComponent extends BaseSelectComponent<TemplateAddTemplateItemFragment, TemplateFilterInput> {
    readonly templatesGql = inject(TemplateAddTemplateItemsGQL)
    private rawData = this.implementDataLoader(fetchThrowingErrors(this.templatesGql))
    readonly $data = computed(() => this.rawData().items.map((item) => ({...item, node: item.data ? this.getTemplateInstance(item.data) : undefined})))
    readonly onItemClicked = output()

    constructor() {
        super()
        this.$customFilters.set({type: [TemplateType.Part, TemplateType.Product]})
    }

    private getTemplateInstance(data: TemplateAddTemplateItemFragment) {
        const templateRevisionId = data.latestRevision?.legacyId
        if (templateRevisionId === undefined) {
            return undefined
        }

        return new TemplateInstance({
            id: uuid4(),
            name: data.name ?? "Template Instance",
            lockedTransform: Matrix4.identity().toArray(),
            visible: true,
            template: new TemplateReference({
                templateRevisionId,
            }),
            parameters: new Parameters({}),
        })
    }
}
