import {Component, inject, input} from "@angular/core"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {BrushSettingsPanelComponent} from "@app/textures/texture-editor/operator-stack/operators/shared/panel/brush-settings-panel/brush-settings-panel.component"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {SliderComponent} from "@common/components/inputs/slider/slider.component"
import {BrushSettings} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"
import {
    BlendMode,
    LayerEditMode,
    LayerMoveMode,
    OperatorLayerAndMask,
} from "app/textures/texture-editor/operator-stack/operators/layer-and-mask/operator-layer-and-mask"
import {ViewMode} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/spatial-mapping-item"
import {MatTooltip} from "@angular/material/tooltip"
import {PermissionsService} from "@common/services/permissions/permissions.service"

@Component({
    selector: "cm-layer-and-mask-panel",
    templateUrl: "./layer-and-mask-panel.component.html",
    styleUrls: ["./layer-and-mask-panel.component.scss"],
    imports: [InspectorSectionComponent, ToggleComponent, SliderComponent, ButtonComponent, BrushSettingsPanelComponent, MatTooltip],
})
export class LayerAndMaskPanelComponent {
    readonly $operator = input.required<OperatorLayerAndMask>({alias: "operator"})
    readonly $disabled = input(false, {alias: "disabled"})

    protected readonly permission = inject(PermissionsService)
    $can = this.permission.$to

    constructor() {}

    set layerMinOpacity(value: number) {
        this.$operator().layerMinOpacity = value
    }

    get layerMinOpacity(): number {
        return this.$operator().layerMinOpacity
    }

    set layerMaxOpacity(value: number) {
        this.$operator().layerMaxOpacity = value
    }

    get layerMaxOpacity(): number {
        return this.$operator().layerMaxOpacity
    }

    set maskFeathering(value: number) {
        this.$operator().maskFeathering = value
    }

    get maskFeathering(): number {
        return this.$operator().maskFeathering
    }

    set layerEditMode(value: LayerEditMode) {
        this.$operator().layerEditMode = value
    }

    get layerEditMode(): LayerEditMode {
        return this.$operator().layerEditMode
    }

    set layerMoveMode(value: LayerMoveMode) {
        this.$operator().layerMoveMode = value
    }

    get layerMoveMode(): LayerMoveMode {
        return this.$operator().layerMoveMode
    }

    get brushSettings(): BrushSettings {
        return this.$operator().brushSettings
    }

    get blendMode(): BlendMode {
        return this.$operator().blendMode
    }

    set blendMode(value: BlendMode) {
        this.$operator().blendMode = value
    }

    set showGuides(value: boolean) {
        this.$operator().showGuides = value
    }

    get showGuides(): boolean {
        return this.$operator().showGuides
    }

    set showMask(value: boolean) {
        this.$operator().showMask = value
    }

    get showMask(): boolean {
        return this.$operator().showMask
    }

    invertMask(): void {
        this.$operator().invertMask()
    }

    layerEditModes = LayerEditMode
    layerMoveModes = LayerMoveMode
    protected readonly ViewMode = ViewMode
}
