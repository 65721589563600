import {Component, computed, inject, input, output, viewChild} from "@angular/core"
import {TemplateNodeComponent} from "../template-node/template-node.component"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {TemplateNodeDragService} from "@app/template-editor/services/template-node-drag.service"
import {isIdNode, isTemplateNode, Node, TemplateNode} from "@cm/template-nodes"
import {cardAnimations} from "@app/common/components/cards/animations"
import {MatTooltipModule} from "@angular/material/tooltip"
import {CardComponent} from "@common/components/cards/card/card.component"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {ThumbnailLayout} from "@app/common/models/enums/thumbnail-layout"
import {removeUndefinedEntriesFromObject} from "@cm/utils"
import {v4 as uuid4} from "uuid"

@Component({
    selector: "cm-template-add-card",
    templateUrl: "./template-add-card.component.html",
    styleUrl: "./template-add-card.component.scss",
    animations: [...cardAnimations],
    imports: [TemplateNodeComponent, MatTooltipModule, CardComponent, DataObjectThumbnailComponent],
})
export class TemplateAddCardComponent<
    EntityType extends {
        name?: string | null
        legacyId?: number
        organization?: {id?: string; name?: string | null} | null
        galleryImage?: {
            id: string
        } | null
        iconClass?: string
    },
> {
    readonly $data = input.required<EntityType | undefined>({alias: "data"})
    readonly $node = input.required<Node | undefined>({alias: "node"})
    readonly $disabled = input<boolean | undefined>(false, {alias: "disabled"})
    readonly $action = input<(() => Promise<void | false> | void | false) | undefined>(undefined, {alias: "action"})
    readonly onItemClicked = output()

    readonly $galleryImage = computed(() => this.$data()?.galleryImage)
    readonly $iconClass = computed(() => this.$data()?.iconClass)

    private readonly $dragImage = viewChild.required<TemplateNodeComponent>("dragImage")
    readonly drag = inject(TemplateNodeDragService)
    private readonly sceneManagerService = inject(SceneManagerService)

    private getNodeInstance() {
        const node = this.$node()
        if (!node) throw new Error("Node is undefined")

        return (node as TemplateNode).clone({
            cloneSubNode: () => true,
            parameterOverrides: (node) => {
                if (isTemplateNode(node))
                    return removeUndefinedEntriesFromObject({
                        id: isIdNode(node) ? uuid4() : undefined,
                    })
                else return undefined
            },
        }) as Node
    }

    addNode() {
        if (this.$disabled()) return

        this.sceneManagerService.modifyTemplateGraph((graph) => {
            graph.parameters.nodes.addEntry(this.getNodeInstance())
        })
    }

    dragStart(event: DragEvent) {
        event.dataTransfer?.setDragImage(this.$dragImage().getDragImage(), 0, 0)

        this.drag.dragStart(event, this.getNodeInstance())
    }

    async onClicked() {
        if (this.$disabled()) return

        const action = this.$action()
        if (action) {
            const result = await action()
            if (result === false) return
        } else {
            this.addNode()
        }

        this.onItemClicked.emit()
    }

    protected readonly ThumbnailLayout = ThumbnailLayout
}
