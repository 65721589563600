import {OperatorExecutionMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context"
import {ImageRef, ImageRefId, makeImageRef, ManagedImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {DescriptorWithoutSize, DrawableImageCache} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/drawable-image-cache"
import {DrawableImageHandle} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/drawable-image-handle"
import {HalImageDescriptor} from "@common/models/hal/hal-image/types"
import {Size2Like} from "@cm/math"

export abstract class ImageOpContextBase {
    protected constructor(
        readonly mode: OperatorExecutionMode,
        readonly drawableImageCache?: DrawableImageCache,
    ) {}

    abstract createDataObjectImageRef(dataObjectId: string): Promise<ManagedImageRef>

    createDrawableImageHandle(descriptorOrDataObject: HalImageDescriptor | (DescriptorWithoutSize & {dataObjectId: string})) {
        if (!this.drawableImageCache) {
            throw new Error("Drawable image cache is not set")
        }
        return this.drawableImageCache.createDrawableImage(descriptorOrDataObject)
    }

    createDrawableImageRef(drawableImageHandle: DrawableImageHandle): ImageRef {
        if (!this.drawableImageCache) {
            throw new Error("Drawable image cache is not set")
        }
        const descriptor = this.drawableImageCache.getImageDescriptor(drawableImageHandle.id)
        return makeImageRef("drawable", drawableImageHandle.id, descriptor, "createDrawableImage")
    }

    setDrawableImageSize(drawableImageHandle: DrawableImageHandle, size: Size2Like) {
        if (!this.drawableImageCache) {
            throw new Error("Drawable image cache is not set")
        }
        this.drawableImageCache.setImageSize(drawableImageHandle.id, size)
    }

    // async createDrawableImageHandle(handle: DrawableImageHandle, descriptor?: ImageDescriptor): Promise<ManagedImageRef> {
    //     if (!this.drawableImageCache) {
    //         throw new Error("Drawable image cache is not set")
    //     }
    //     descriptor ??= this.drawableImageCache.hasDrawableImage(drawableImageRef)
    //         ? await this.drawableImageCache.getImageDescriptor(drawableImageRef)
    //         : {
    //               width: 100,
    //               height: 100,
    //               channelLayout: "R",
    //               dataType: "uint8",
    //           }
    //     this.drawableImageCache.setImageDescriptor(drawableImageRef, descriptor)
    //     const imageRef = makeImageRef("drawable", this.nextImageRefId++, descriptor, "createDrawableImage")
    //     const newInfo: DrawableImageInfo = {imageRefId: imageRef.id, drawableImageRef, descriptor}
    //     this.drawableImageInfoByImageRefId.set(imageRef.id, newInfo)
    //     return imageRef
    // }

    protected fetchUniqueImageRefId(): ImageRefId {
        return this.nextImageRefId++
    }

    // protected getDrawableImageInfo(imageRefId: ImageRefId): DrawableImageInfo {
    //     const info = this.drawableImageInfoByImageRefId.get(imageRefId)
    //     if (!info) {
    //         throw new Error(`Drawable image ${imageRefId} not found`)
    //     }
    //     return info
    // }

    private nextImageRefId = 1
    // private drawableImageInfoByImageRefId = new Map<ImageRefId, DrawableImageInfo>()
}

// export type DrawableImageInfo = {imageRefId: ImageRefId; drawableImageRef: DrawableImageRef; descriptor: ImageDescriptor}
