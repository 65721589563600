import {Component, computed, effect, inject, Injector, signal} from "@angular/core"
import {toObservable, toSignal} from "@angular/core/rxjs-interop"
import {MatDialog} from "@angular/material/dialog"
import {ActivatedRoute, ParamMap, Router} from "@angular/router"
import {DialogComponent} from "@app/common/components/dialogs/dialog/dialog.component"
import {RoutedDialogComponent} from "@app/common/components/dialogs/routed-dialog/routed-dialog.component"
import {createLinkToEditorFromPictures, createLinkToEditorFromTemplates} from "@app/common/helpers/routes"
import {DialogSize} from "@app/common/models/dialogs"
import {TemplateEditComponent} from "@app/template-editor/components/template-edit/template-edit.component"
import {deserializeNodeGraph} from "@cm/graph"
import {Nodes, TemplateGraph} from "@cm/template-nodes"
import {LoadingSpinnerComponent} from "@common/components/progress/loading-spinner/loading-spinner.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {TemplateState, TemplateType} from "@generated"
import {
    CreateTemplateForTemplateEditorGQL,
    CreateTemplateRevisionForTemplateEditorGQL,
    GetLatestTemplateRevisionDetailsForTemplateEditorGQL,
    GetPictureDetailsForTemplateEditorGQL,
    GetTemplateDetailsForTemplateEditorGQL,
    GetTemplateDetailsForTemplateEditorQuery,
    GetTemplateRevisionDetailsForTemplateEditorGQL,
} from "@template-editor/components/template-editor/template-editor.generated"
import {UpdateTemplateRevisionGQL} from "@template-editor/services/template-serialization.service.generated"
import {catchError, combineLatest, distinctUntilChanged, map, Observable, of, switchMap} from "rxjs"
import {z} from "zod"

const getUuidParam = (params: ParamMap, key: string) => {
    const result = z.string().uuid().safeParse(params.get(key))
    if (result.success) return result.data
    return null
}

@Component({
    selector: "cm-template-editor",
    templateUrl: "./template-editor.component.html",
    styleUrl: "./template-editor.component.scss",
    imports: [RoutedDialogComponent, LoadingSpinnerComponent, TemplateEditComponent],
})
export class TemplateEditorComponent {
    readonly $templateGraphModified = signal(false)
    dialogSizes = DialogSize

    readonly permission = inject(PermissionsService)
    $can = this.permission.$to
    private readonly route = inject(ActivatedRoute)
    private readonly router = inject(Router)
    private readonly dialog = inject(MatDialog)
    private readonly notificationService = inject(NotificationsService)
    readonly injector = inject(Injector)
    readonly getTemplateRevisionDetailsForTemplateEditor = inject(GetTemplateRevisionDetailsForTemplateEditorGQL)
    readonly createTemplateRevisionForTemplateEditor = inject(CreateTemplateRevisionForTemplateEditorGQL)
    readonly getPictureDetailsForTemplateEditor = inject(GetPictureDetailsForTemplateEditorGQL)
    readonly getLatestTemplateRevisionDetailsForTemplateEditor = inject(GetLatestTemplateRevisionDetailsForTemplateEditorGQL)
    readonly updateTemplateRevisionNew = inject(UpdateTemplateRevisionGQL)
    readonly getTemplateDetailsForTemplateEditor = inject(GetTemplateDetailsForTemplateEditorGQL)
    readonly createTemplateForTemplateEditor = inject(CreateTemplateForTemplateEditorGQL)

    private readonly $routingData = toSignal(
        combineLatest([
            this.route.paramMap.pipe(
                map((params) => getUuidParam(params, "templateId")),
                distinctUntilChanged(),
            ),
            this.route.paramMap.pipe(
                map((params) => getUuidParam(params, "pictureId")),
                distinctUntilChanged(),
            ),
            this.route.paramMap.pipe(
                map((params) => getUuidParam(params, "templateRevisionId")),
                distinctUntilChanged(),
            ),
        ]).pipe(
            map(([templateId, pictureId, templateRevisionId]) => ({
                templateId,
                pictureId,
                templateRevisionId,
            })),
            catchError((error) => {
                console.error(error)
                return of(null)
            }),
        ),
        {
            initialValue: null,
        },
    )
    readonly $templateRevision = toSignal(
        toObservable(this.$routingData).pipe(
            switchMap((routingData) => {
                if (!routingData) return of(null)

                const revisionId = routingData.templateRevisionId
                if (revisionId !== null) return fetchThrowingErrors(this.getTemplateRevisionDetailsForTemplateEditor)({id: revisionId})
                else return of(null)
            }),
            map((result) => result?.templateRevision ?? null),
        ),
        {
            initialValue: null,
        },
    )
    protected readonly $templateGraph = computed(() => {
        const templateRevision = this.$templateRevision()
        if (!templateRevision) return null

        const {graph} = templateRevision
        if (!graph) return new TemplateGraph({name: "Untitled Template", nodes: new Nodes({list: []})})
        const templateGraph = deserializeNodeGraph(graph)
        if (!(templateGraph instanceof TemplateGraph)) throw new Error("Deserialized node is not a TemplateGraph")
        return templateGraph
    })

    constructor() {
        effect(async () => {
            const routingData = this.$routingData()
            if (!routingData) return

            const {templateId, pictureId, templateRevisionId} = routingData

            const getNewRouterPath = async () => {
                const getOrCreateLatestTemplateRevision = async (template: GetTemplateDetailsForTemplateEditorQuery["template"]) => {
                    const {revisions, id: templateId} = template
                    const latestRevision = revisions.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).at(0)

                    if (latestRevision) return latestRevision.id
                    else {
                        const savedRevision = (
                            await mutateThrowingErrors(this.createTemplateRevisionForTemplateEditor)({
                                input: {templateId: templateId, graph: null, completed: false},
                            })
                        ).createTemplateRevision
                        return savedRevision.id
                    }
                }

                if (templateId !== null && templateRevisionId === null) {
                    const {template} = await fetchThrowingErrors(this.getTemplateDetailsForTemplateEditor)({id: templateId})
                    return createLinkToEditorFromTemplates(this.router, templateId, await getOrCreateLatestTemplateRevision(template))
                } else if (pictureId !== null && templateRevisionId === null) {
                    const {picture} = await fetchThrowingErrors(this.getPictureDetailsForTemplateEditor)({id: pictureId})
                    const {template} = picture

                    if (!template) {
                        const savedTemplate = (
                            await mutateThrowingErrors(this.createTemplateForTemplateEditor)({
                                input: {
                                    organizationId: picture.organization.id,
                                    state: TemplateState.Draft,
                                    templateType: TemplateType.Scene,
                                    public: false,
                                    pictureId: picture.id,
                                },
                            })
                        ).createTemplate

                        const savedRevision = (
                            await mutateThrowingErrors(this.createTemplateRevisionForTemplateEditor)({
                                input: {templateId: savedTemplate.id, graph: null, completed: false},
                            })
                        ).createTemplateRevision
                        return createLinkToEditorFromPictures(pictureId, savedRevision.id)
                    } else {
                        return createLinkToEditorFromPictures(pictureId, await getOrCreateLatestTemplateRevision(template))
                    }
                } else return null
            }

            const newRouterPath = await getNewRouterPath()
            if (newRouterPath !== null)
                this.router.navigate(newRouterPath, {
                    queryParamsHandling: "preserve",
                })
        })

        effect(async () => {
            const templateRevision = this.$templateRevision()
            if (templateRevision?.inconsistencies?.length) {
                const dialogRef = this.dialog.open(DialogComponent, {
                    disableClose: true,
                    width: "400px",
                    data: {
                        cancelLabel: this.$can().open.inconsistentTemplate() ? "Ignore" : undefined,
                        confirmLabel: "Close Editor",
                        message: `This template will not work correctly due to the following issues:\n\n${templateRevision.inconsistencies.map((inconsistency) => "• " + inconsistency.message).join("\n")}`,
                        title: "Data inconsistencies",
                    },
                })
                dialogRef.afterClosed().subscribe(async (result) => {
                    if (result) {
                        await this.router.navigate([this.route.snapshot.data.closeNavigationPath ?? "../"], {
                            relativeTo: this.route,
                            queryParamsHandling: "preserve",
                        })
                    }
                })
            }
        })
    }

    handleTemplateGraphModified(modified: boolean) {
        this.$templateGraphModified.set(modified)
    }

    async handleSave(onSaved?: () => void) {
        const templateRevision = this.$templateRevision()
        if (!templateRevision) throw new Error("No template revision loaded")
        const templateGraph = this.$templateGraph()
        if (!templateGraph) throw new Error("No template graph loaded")

        await mutateThrowingErrors(this.updateTemplateRevisionNew)({
            input: {
                id: templateRevision.id,
                graph: templateGraph.serialize(),
            },
        })

        this.notificationService.showInfo("Template revision saved")

        onSaved?.()
    }
}
