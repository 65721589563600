import {Component, Inject} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatOptionModule} from "@angular/material/core"
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {MatMenuModule} from "@angular/material/menu"
import {MatSelectModule} from "@angular/material/select"
import {Labels} from "@labels"
import {EditTaskTypeDialogFragment} from "@platform/components/task-types/edit-task-type-dialog/edit-task-type-dialog.generated"

export type EditTaskTypeDialogData = {
    taskTypeInfo: EditTaskTypeDialogFragment
    delete?: boolean
    edit?: boolean
}

@Component({
    selector: "cm-edit-task-type-dialog",
    templateUrl: "../edit-task-type-dialog/edit-task-type-dialog.component.html",
    imports: [MatInputModule, FormsModule, MatDialogModule, MatButtonModule, MatMenuModule, MatFormFieldModule, MatOptionModule, MatSelectModule],
})
export class EditTaskTypeDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<EditTaskTypeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EditTaskTypeDialogData,
    ) {}

    onCancel(): void {
        this.dialogRef.close()
    }

    onDelete(): void {
        this.data.delete = true
        this.dialogRef.close(this.data)
    }

    protected readonly Labels = Labels
}
