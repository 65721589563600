import {Component, viewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {RgbOutputs} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-rgb-node",
    templateUrl: "./rgb-node.component.html",
    styleUrls: ["./rgb-node.component.scss"],
    imports: [NodeBaseComponent],
})
export class RgbNodeComponent {
    readonly $nodeBase = viewChild<MaterialNodeBase>("nodeBase")
    outputs = RgbOutputs
    typeInfo = RgbNodeType
}

export const RgbNodeType: MaterialNodeType<typeof RgbNodeComponent> = {
    id: "rgbNode",
    label: "RGB",
    color: "#4987af",
    name: "ShaderNodeRGB",
    inputs: [] as never[],
    outputs: [RgbOutputs.color],
    component: RgbNodeComponent,
}
