<div class="cm-container">
    <cm-entity-responsive-sidebar class="cm-entity-sidebar">
        <cm-item-filters>
            <cm-tag-search-filter placeholder="Name, description, ..." [selectableTagTypes]="[Enums.TagType.ProductRange]"></cm-tag-search-filter>
            <cm-checkboxes-filter label="State" name="templateState" [options]="Labels.TemplateState"></cm-checkboxes-filter>
            @if ($typeLabels().size > 1) {
                <cm-checkboxes-filter
                    [label]="$entityName()[0].toUpperCase() + $entityName().slice(1) + ' Type'"
                    name="templateType"
                    [options]="$typeLabels()"
                ></cm-checkboxes-filter>
            }
            @if ($can().read.filters("additional")) {
                <cm-checkboxes-filter label="Visibility" name="public" [options]="Labels.Public"></cm-checkboxes-filter>
            }
        </cm-item-filters>
    </cm-entity-responsive-sidebar>
    <cm-infinite-list [count]="$totalCount()" [intersectionStatusChange]="pageFilledStatusChange" class="cm-items-list">
        <cm-list-info
            cm-list-header
            [parentList]="this"
            [stateLabels]="stateLabels"
            [count]="$totalCount() ?? 0"
            [createItem]="openNewItemDialog.bind(this)"
        ></cm-list-info>
        @for (item of $items(); track item.trackId) {
            @if (item.data; as template) {
                <cm-entity-card
                    @fadeInCard
                    [entity]="template"
                    [entityLink]="templateLink(template)"
                    [entityQueryParamsHandling]="'preserve'"
                    [multiThumbnailIds]="multiThumbnailIds(template)"
                >
                    <ng-container cm-top-left>
                        <div (click)="$event.stopPropagation()" class="cm-action-container">
                            <a [routerLink]="['./', template.id]" queryParamsHandling="preserve" class="cm-action-icon-container">
                                <i class="far fa-circle-info cm-action-icon" [matTooltip]="'Show ' + $entityName() + ' details'"></i>
                            </a>
                            <a (click)="copyTemplate(template)" class="cm-action-icon-container">
                                <i class="far fa-copy cm-action-icon" matTooltip="Make a copy"></i>
                            </a>
                        </div>
                    </ng-container>
                    <ng-container cm-bottom-left>
                        @if ($can().update.template(template, "state")) {
                            <cm-state-label
                                [stateLabels]="Labels.TemplateState"
                                [state]="template.state"
                                [editable]="true"
                                (stateChange)="updateItem({id: template.id, state: $event})"
                            >
                            </cm-state-label>
                        }
                    </ng-container>
                </cm-entity-card>
            } @else if (item.placeholder) {
                <cm-card-placeholder @fadeInPlaceholder></cm-card-placeholder>
            } @else {
                <cm-card-error @fadeInPlaceholder></cm-card-error>
            }
        }
    </cm-infinite-list>
</div>
<router-outlet></router-outlet>
<ng-template #newItemDialog>
    <h2 mat-dialog-title>New {{ $entityName() }}</h2>
    <mat-dialog-content [style.width]="'350px'">
        <form class="cm-new-item-form">
            @if ($can().update.template(null, "organization")) {
                <cm-organization-select
                    [updateOrganization]="setNewTemplateOrganization.bind(this)"
                    [organizationId]="newItemData.organizationId ?? null"
                    [showLabel]="false"
                    [usedInAddEntityContext]="true"
                ></cm-organization-select>
            }
            <mat-form-field appearance="fill">
                <input matInput placeholder="Name" [(ngModel)]="newItemData.name" name="name" />
            </mat-form-field>
            @if (!newItemData.templateType || sortedTemplateTypeLabels.length > 1) {
                <mat-form-field appearance="fill">
                    <mat-select
                        [(ngModel)]="newItemData.templateType"
                        placeholder="Type"
                        name="templateType"
                        [disabled]="!$can().update.template(null, 'type')"
                    >
                        @for (templateType of sortedTemplateTypeLabels; track templateType.state) {
                            <mat-option [value]="templateType.state">{{ templateType.label }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            }
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button
            mat-button
            color="accent"
            [mat-dialog-close]="newItemData"
            [disabled]="!newItemData.name || !newItemData.organizationId || !newItemData.templateType"
        >
            Create
        </button>
    </mat-dialog-actions>
</ng-template>
