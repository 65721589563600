import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type QueryThumbnailQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  resolution: Types.DownloadResolution;
}>;


export type QueryThumbnailQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, thumbnailProcessingJob?: { __typename: 'Job', state: Types.JobState } | null } };

export type ThumbnailAvailableQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ThumbnailAvailableQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', thumbnailAvailable: boolean } };

export const QueryThumbnailDocument = gql`
    query queryThumbnail($id: ID!, $resolution: DownloadResolution!) {
  dataObject(id: $id) {
    thumbnail(fileType: jpg, resolution: $resolution) {
      downloadUrl
    }
    thumbnailProcessingJob {
      state
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryThumbnailGQL extends Apollo.Query<QueryThumbnailQuery, QueryThumbnailQueryVariables> {
    override document = QueryThumbnailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ThumbnailAvailableDocument = gql`
    query thumbnailAvailable($id: ID!) {
  dataObject(id: $id) {
    thumbnailAvailable
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ThumbnailAvailableGQL extends Apollo.Query<ThumbnailAvailableQuery, ThumbnailAvailableQueryVariables> {
    override document = ThumbnailAvailableDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }