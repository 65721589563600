import {Component, computed} from "@angular/core"
import {BaseInspectorComponent} from "@template-editor/components/inspectors/base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {ValueSlotComponent} from "@template-editor/components/value-slot/value-slot.component"
import {TemplateNode} from "@cm/template-nodes"
import {parseZodSchema} from "zod-key-parser"
import * as changeCase from "change-case"
import {isObject} from "@cm/template-nodes"
import {MaterialAssignments} from "@cm/template-nodes"
import {Parameters} from "@cm/template-nodes"

@Component({
    selector: "cm-generic-inspector",
    templateUrl: "./generic-inspector.component.html",
    styleUrl: "./generic-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent],
})
export class GenericInspectorComponent<T extends TemplateNode> extends BaseInspectorComponent<T> {
    readonly $parameterKeys = computed(() => {
        const node = this.$node()
        const schema = node.getParamsSchema()

        const parameters = node.parameters as T["parameters"]
        const keys = Object.keys(parseZodSchema(schema).keys) as (keyof T["parameters"])[]

        keys.sort((a, b) => {
            if (parameters[a] instanceof MaterialAssignments || parameters[a] instanceof Parameters) return 1
            else if (parameters[b] instanceof MaterialAssignments || parameters[b] instanceof Parameters) return -1
            return 0
        })

        if (isObject(this.$node())) {
            keys.splice(keys.indexOf("lockedTransform" as keyof T["parameters"]), 1)
            keys.splice(keys.indexOf("$defaultTransform" as keyof T["parameters"]), 1)
        }

        const nodesIndex = keys.indexOf("nodes" as keyof T["parameters"])
        if (nodesIndex !== -1) keys.splice(keys.indexOf("nodes" as keyof T["parameters"]), 1)

        return keys
    })

    getLabel(key: keyof T["parameters"]) {
        return changeCase.capitalCase(key as string)
    }
}
