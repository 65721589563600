export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DataInconsistency: { input: any; output: any; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: string; output: string; }
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  GraphQLBigInt: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  Json: { input: any; output: any; }
  /** Represents NULL values */
  Void: { input: any; output: any; }
};

export type ArStatistics = {
  __typename: 'ArStatistics';
  configString: Scalars['String']['output'];
  created?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  sceneRevision?: Maybe<SceneRevision>;
  templateRevision?: Maybe<TemplateRevision>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type ArStatisticsCreateDataInput = {
  configString: Scalars['String']['input'];
  created: Scalars['DateTime']['input'];
  uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type ArStatisticsFilterInput = {
  configString?: InputMaybe<StringFilterInput>;
  created?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdInFilter>;
  sceneRevisionId?: InputMaybe<IdInFilter>;
  sceneRevisionLegacyId?: InputMaybe<IntInFilter>;
  templateRevisionId?: InputMaybe<IdInFilter>;
  templateRevisionLegacyId?: InputMaybe<IntInFilter>;
};

export enum ArStatisticsOrderByCriteria {
  Id = 'id'
}

export type ArStatisticsOrderByInput = {
  direction: SortOrder;
  key: ArStatisticsOrderByCriteria;
};

export type Asset = {
  __typename: 'Asset';
  articleId?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  currentUserAssignment?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  galleryImage?: Maybe<DataObject>;
  id: Scalars['ID']['output'];
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  materialAssignments: Array<MaterialAssignment>;
  materialAssignmentsCount: Scalars['Int']['output'];
  materials: Array<Material>;
  model: Model;
  name?: Maybe<Scalars['String']['output']>;
  nextActor: NextActor;
  organization: Organization;
  pictureAssignments: Array<PictureAssetAssignment>;
  /** Pictures assigned to this asset via a PictureAssetAssignment. This is a convenience field that is equivalent to `pictureAssignments.picture`. */
  pictures: Array<Picture>;
  picturesCount: Scalars['Int']['output'];
  priorityOrder?: Maybe<Scalars['GraphQLBigInt']['output']>;
  state: AssetState;
  taskInfos: TaskInfos;
  template: Template;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  userAssignments: Array<UserAssignment>;
  userAssignmentsCount: Scalars['Int']['output'];
};


export type AssetDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type AssetJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type AssetMaterialsArgs = {
  where?: InputMaybe<MaterialFilterInput>;
};


export type AssetUserAssignmentsArgs = {
  filter?: InputMaybe<UserAssignmentFilterInput>;
};

export type AssetCreateInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  dataObjects?: InputMaybe<Array<DataObjectAssignmentCreateWithoutObjectInput>>;
  modelId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  nextActor: NextActor;
  organizationId: Scalars['ID']['input'];
  state: AssetState;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

export type AssetFilterInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<StringFilterInput>;
  hasAssignedUser?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  materialId?: InputMaybe<IdInFilter>;
  materialLegacyId?: InputMaybe<IntInFilter>;
  modelId?: InputMaybe<IdInFilter>;
  modelLegacyId?: InputMaybe<IntInFilter>;
  name?: InputMaybe<StringFilterInput>;
  nextActor?: InputMaybe<Array<NextActor>>;
  organizationId?: InputMaybe<IdInFilter>;
  organizationLegacyId?: InputMaybe<IntInFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<AssetState>>;
  userId?: InputMaybe<IdInFilter>;
};

export type AssetHistory = {
  __typename: 'AssetHistory';
  articleId?: Maybe<Scalars['String']['output']>;
  asset?: Maybe<Asset>;
  comment?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<User>;
  historyChangeReason?: Maybe<Scalars['String']['output']>;
  historyDate?: Maybe<Scalars['DateTime']['output']>;
  historyType: HistoryType;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nextActor: NextActor;
  state: AssetState;
};

export type AssetHistoryFilterInput = {
  articleId?: InputMaybe<StringFilterInput>;
  assetId?: InputMaybe<IdInFilter>;
  comment?: InputMaybe<StringFilterInput>;
  historyDate?: InputMaybe<DateTimeFilterInput>;
  modelId?: InputMaybe<IdInFilter>;
  name?: InputMaybe<StringFilterInput>;
  organizationId?: InputMaybe<IdInFilter>;
  state?: InputMaybe<Array<AssetState>>;
  templateId?: InputMaybe<IdInFilter>;
};

export enum AssetHistoryOrderByCriteria {
  HistoryDate = 'history_date',
  Id = 'id'
}

export type AssetHistoryOrderByInput = {
  direction: SortOrder;
  key: AssetHistoryOrderByCriteria;
};

export enum AssetOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Priority = 'priority'
}

export type AssetOrderByInput = {
  direction: SortOrder;
  key: AssetOrderByCriteria;
};

export enum AssetState {
  Completed = 'Completed',
  InfoReview = 'InfoReview',
  Review = 'Review',
  Shading = 'Shading'
}

export type AssetUpdateInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  modelId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  nextActor: NextActor;
  organizationId: Scalars['ID']['input'];
  state: AssetState;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

export enum ContentTypeModel {
  Asset = 'Asset',
  AssetHistory = 'AssetHistory',
  DataObject = 'DataObject',
  DataObjectAssignment = 'DataObjectAssignment',
  Hdri = 'Hdri',
  IdFromCatalog = 'IdFromCatalog',
  IdFromCatalogToPriceGraph = 'IdFromCatalogToPriceGraph',
  Job = 'Job',
  JobFarmGroup = 'JobFarmGroup',
  JobFarmInstance = 'JobFarmInstance',
  JobTask = 'JobTask',
  JsonFile = 'JsonFile',
  Material = 'Material',
  MaterialAssignment = 'MaterialAssignment',
  MaterialAssignmentHistory = 'MaterialAssignmentHistory',
  MaterialConnection = 'MaterialConnection',
  MaterialHistory = 'MaterialHistory',
  MaterialNode = 'MaterialNode',
  MaterialReference = 'MaterialReference',
  MaterialRevision = 'MaterialRevision',
  Model = 'Model',
  ModelHistory = 'ModelHistory',
  Organization = 'Organization',
  Picture = 'Picture',
  PictureAssetAssignmentHistory = 'PictureAssetAssignmentHistory',
  PictureHistory = 'PictureHistory',
  PictureRevision = 'PictureRevision',
  ProductionStatistics = 'ProductionStatistics',
  Project = 'Project',
  RenderJob = 'RenderJob',
  ScanJob = 'ScanJob',
  ScanSubJob = 'ScanSubJob',
  Scene = 'Scene',
  SceneRevision = 'SceneRevision',
  Set = 'Set',
  StorageDeleteTask = 'StorageDeleteTask',
  Tag = 'Tag',
  TagAssignment = 'TagAssignment',
  Task = 'Task',
  TaskComment = 'TaskComment',
  TaskPin = 'TaskPin',
  Template = 'Template',
  TemplateRevision = 'TemplateRevision',
  Texture = 'Texture',
  TextureGroup = 'TextureGroup',
  TextureReference = 'TextureReference',
  TextureRevision = 'TextureRevision',
  TextureSet = 'TextureSet',
  TextureSetRevision = 'TextureSetRevision',
  User = 'User',
  UserAssignment = 'UserAssignment',
  UserAssignmentHistory = 'UserAssignmentHistory'
}

export type CreateDataObjectAssignmentInlineInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  assignmentType: DataObjectAssignmentType;
  dataObject?: InputMaybe<CreateDataObjectInlineInput>;
  dataObjectId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateDataObjectInlineInput = {
  bucketName?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  imageColorProfile?: InputMaybe<Scalars['String']['input']>;
  imageColorSpace?: InputMaybe<ImageColorSpace>;
  imageDataType?: InputMaybe<ImageDataType>;
  mediaType?: InputMaybe<Scalars['String']['input']>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  originalFileName: Scalars['String']['input'];
  relatedToId?: InputMaybe<Scalars['ID']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  state: DataObjectState;
  type?: InputMaybe<DataObjectType>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export enum Currency {
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD'
}

export type DataObject = {
  __typename: 'DataObject';
  assignments: Array<DataObjectAssignment>;
  assignmentsCount: Scalars['Int']['output'];
  bucketName: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  downloadUrl: Scalars['String']['output'];
  hdris: Array<Hdri>;
  hdrisCount: Scalars['Int']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  imageColorProfile?: Maybe<Scalars['String']['output']>;
  /** The image's color space. Note that this does not match the color space reported by the OS or libraries like sharp. For example, rendered EXR files always have ImageColorSpace.Linear while sharp reports 'scrgb' and Mac OS 'RGB' */
  imageColorSpace?: Maybe<ImageColorSpace>;
  imageDataType?: Maybe<ImageDataType>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  materialRevisions: Array<MaterialRevision>;
  materialRevisionsCount: Scalars['Int']['output'];
  mediaType?: Maybe<Scalars['String']['output']>;
  metadataProcessingJob?: Maybe<Job>;
  objectName: Scalars['String']['output'];
  organization: Organization;
  originalFileName: Scalars['String']['output'];
  processedByVersion?: Maybe<Scalars['String']['output']>;
  related: Array<DataObject>;
  relatedCount: Scalars['Int']['output'];
  relatedTo?: Maybe<DataObject>;
  signedUploadUrl: Scalars['String']['output'];
  size?: Maybe<Scalars['GraphQLBigInt']['output']>;
  state: DataObjectState;
  textureRevisions: Array<TextureRevision>;
  textureRevisionsCount: Scalars['Int']['output'];
  textureSetRevisionMapAssignments: Array<TextureSetRevisionMapAssignment>;
  textureSetRevisionMapAssignmentsCount: Scalars['Int']['output'];
  textureSetRevisionSourceDataObjectReferences: Array<TextureSetRevisionSourceDataObjectReference>;
  textureSetRevisionSourceDataObjectReferencesCount: Scalars['Int']['output'];
  thumbnail?: Maybe<DataObject>;
  thumbnailAvailable: Scalars['Boolean']['output'];
  thumbnailProcessingJob?: Maybe<Job>;
  type?: Maybe<DataObjectType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  width?: Maybe<Scalars['Int']['output']>;
};


export type DataObjectDownloadUrlArgs = {
  fileType?: InputMaybe<DownloadFileType>;
  resolution?: InputMaybe<DownloadResolution>;
};


export type DataObjectSignedUploadUrlArgs = {
  allowCache?: InputMaybe<Scalars['Boolean']['input']>;
};


export type DataObjectThumbnailArgs = {
  fileType?: InputMaybe<DownloadFileType>;
  resolution?: InputMaybe<DownloadResolution>;
};

export type DataObjectAssignment = {
  __typename: 'DataObjectAssignment';
  assignedTo?: Maybe<DataObjectAssignmentSubject>;
  assignmentKey?: Maybe<Scalars['String']['output']>;
  assignmentType: DataObjectAssignmentType;
  contentTypeModel: ContentTypeModel;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  dataObject: DataObject;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  objectId: Scalars['String']['output'];
  objectLegacyId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type DataObjectAssignmentCreateWithoutObjectInput = {
  assignmentKey: Scalars['String']['input'];
  assignmentType: DataObjectAssignmentType;
  dataObject?: InputMaybe<DataObjectCreateInput>;
  dataObjectId?: InputMaybe<Scalars['String']['input']>;
  dataObjectType?: InputMaybe<Array<DataObjectType>>;
};

export type DataObjectAssignmentFilterInput = {
  assignmentKey?: InputMaybe<StringFilterInput>;
  assignmentType?: InputMaybe<Array<DataObjectAssignmentType>>;
  contentTypeModel?: InputMaybe<ContentTypeModel>;
  dataObjectId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export enum DataObjectAssignmentOrderByCriteria {
  AssignmentKey = 'assignmentKey',
  AssignmentType = 'assignmentType',
  Id = 'id'
}

export type DataObjectAssignmentOrderByInput = {
  direction: SortOrder;
  key: DataObjectAssignmentOrderByCriteria;
};

export type DataObjectAssignmentSubject = Asset | Hdri | JobTask | JsonFile | Material | MaterialRevision | Model | Organization | Picture | PictureRevision | Project | ScanJob | ScanSubJob | Scene | SceneRevision | Set | Tag | Template | TemplateRevision | Texture | TextureGroup | TextureRevision | TextureSet | User;

export enum DataObjectAssignmentType {
  Attachment = 'Attachment',
  BatchExport = 'BatchExport',
  CachedTemplateGltf = 'CachedTemplateGltf',
  CachedTemplateUsdz = 'CachedTemplateUsdz',
  CryptoMatte01 = 'CryptoMatte01',
  CryptoMatte02 = 'CryptoMatte02',
  CryptoMatte03 = 'CryptoMatte03',
  CustomerLogo = 'CustomerLogo',
  DrawingData = 'DrawingData',
  Font = 'Font',
  FontBold = 'FontBold',
  GalleryImage = 'GalleryImage',
  GalleryImageMain = 'GalleryImageMain',
  Icon = 'Icon',
  JobTaskData = 'JobTaskData',
  JobTaskInternalData = 'JobTaskInternalData',
  MaterialCetSimple = 'MaterialCetSimple',
  MaterialMapsExport = 'MaterialMapsExport',
  MaterialThumbnail_7x7Inches = 'MaterialThumbnail_7x7Inches',
  MaterialThumbnail_9x9Inches = 'MaterialThumbnail_9x9Inches',
  MaterialThumbnail_10x10 = 'MaterialThumbnail_10x10',
  MaterialThumbnail_16x16Inches = 'MaterialThumbnail_16x16Inches',
  MaterialThumbnail_20x20 = 'MaterialThumbnail_20x20',
  MaterialThumbnail_30x30 = 'MaterialThumbnail_30x30',
  MaterialTileableRender = 'MaterialTileableRender',
  MeshDataDrc = 'MeshDataDrc',
  MeshDataDrcProxy = 'MeshDataDrcProxy',
  MeshDataPly = 'MeshDataPly',
  PdfTemplate = 'PdfTemplate',
  PictureData = 'PictureData',
  PictureDataLinear = 'PictureDataLinear',
  PictureDataSecondary = 'PictureDataSecondary',
  PictureDataTertiary = 'PictureDataTertiary',
  ProfilePicture = 'ProfilePicture',
  ScanAssemblyWorkPackage = 'ScanAssemblyWorkPackage',
  ScanCalibrationData = 'ScanCalibrationData',
  ScanFittingWorkPackage = 'ScanFittingWorkPackage',
  ScanLog = 'ScanLog',
  ScanMergedFile = 'ScanMergedFile',
  ScanReferenceFile = 'ScanReferenceFile',
  TemplateConfigIcon = 'TemplateConfigIcon',
  TemplateDataOther = 'TemplateDataOther',
  TextureEditDescriptor = 'TextureEditDescriptor',
  TextureEditMask = 'TextureEditMask',
  TilingGraph = 'TilingGraph'
}

export type DataObjectAssignmentsFilterInput = {
  every?: InputMaybe<DataObjectAssignmentFilterInput>;
  none?: InputMaybe<DataObjectAssignmentFilterInput>;
  some?: InputMaybe<DataObjectAssignmentFilterInput>;
};

export type DataObjectCreateInput = {
  bucketName: Scalars['String']['input'];
  height?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageColorProfile?: InputMaybe<Scalars['String']['input']>;
  imageColorSpace?: InputMaybe<ImageColorSpace>;
  imageDataType?: InputMaybe<ImageDataType>;
  mediaType?: InputMaybe<Scalars['String']['input']>;
  objectName: Scalars['String']['input'];
  originalFileName: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Float']['input']>;
  state: DataObjectState;
  type?: InputMaybe<DataObjectType>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type DataObjectFilterInput = {
  assignedToContentType?: InputMaybe<ContentTypeModel>;
  assignedToId?: InputMaybe<Scalars['ID']['input']>;
  assignedToLegacyId?: InputMaybe<Scalars['Int']['input']>;
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  assignmentType?: InputMaybe<Array<DataObjectAssignmentType>>;
  bucketName?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdInFilter>;
  /** If true, only return objects that are related to a parent. If false, only return objects that are not related to a parent. */
  isRelated?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<IntFilterInput>;
  mediaType?: InputMaybe<StringFilterInput>;
  objectName?: InputMaybe<StringFilterInput>;
  organizationId?: InputMaybe<IdInFilter>;
  /** If provided, only return objects that are related to a parent matching the UUID filter. */
  relatedTo?: InputMaybe<IdInFilter>;
  state?: InputMaybe<Array<DataObjectState>>;
};

export enum DataObjectOrderByCriteria {
  Id = 'id',
  LegacyId = 'legacyId',
  ObjectName = 'objectName',
  OriginalFileName = 'originalFileName'
}

export type DataObjectOrderByInput = {
  direction: SortOrder;
  key: DataObjectOrderByCriteria;
};

export enum DataObjectState {
  Completed = 'Completed',
  Init = 'Init',
  Processing = 'Processing',
  ProcessingFailed = 'ProcessingFailed',
  UploadFinished = 'UploadFinished'
}

export enum DataObjectType {
  Archive = 'Archive',
  Image = 'Image',
  Mesh = 'Mesh',
  Model = 'Model',
  ModelDrc = 'ModelDrc',
  ModelObj = 'ModelObj',
  Other = 'Other',
  Pdf = 'Pdf',
  Scan = 'Scan',
  Text = 'Text',
  TextJson = 'TextJson',
  Texture = 'Texture',
  TmpFile = 'TmpFile',
  Video = 'Video'
}

export type DateTimeFilterInput = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum DownloadFileType {
  Exr = 'exr',
  Jpg = 'jpg',
  Png = 'png',
  Tiff = 'tiff'
}

export enum DownloadResolution {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  Tiny = 'Tiny'
}

export enum DownloadType {
  Cet = 'Cet',
  Pbr = 'Pbr',
  Thumbnail = 'Thumbnail',
  Tile = 'Tile'
}

export type FloatFilterInput = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<FloatFilterInput>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type Hdri = {
  __typename: 'Hdri';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataObject?: Maybe<DataObject>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  galleryImage?: Maybe<DataObject>;
  hdriReferences: Array<TemplateRevision>;
  id: Scalars['ID']['output'];
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  shareAssignments: Array<ShareAssignment>;
  shareAssignmentsCount: Scalars['Int']['output'];
  tagAssignments: Array<TagAssignment>;
  tagAssignmentsCount: Scalars['Int']['output'];
  taskInfos: TaskInfos;
  tasks: Array<Task>;
  tasksCount: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type HdriDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type HdriJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type HdriShareAssignmentsArgs = {
  filter?: InputMaybe<ShareAssignmentFilterInput>;
  orderBy?: InputMaybe<ShareAssignmentOrderByInput>;
};


export type HdriTagAssignmentsArgs = {
  tagTypes?: InputMaybe<Array<TagType>>;
};

export type HdriFilterInput = {
  created?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  organizationId?: InputMaybe<IdInFilter>;
  organizationLegacyId?: InputMaybe<IntInFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum HdriOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name'
}

export type HdriOrderByInput = {
  direction: SortOrder;
  key: HdriOrderByCriteria;
};

export enum HistoryType {
  Added = 'Added',
  Deleted = 'Deleted',
  Moved = 'Moved'
}

export type IdFilterInput = {
  contains?: InputMaybe<Scalars['ID']['input']>;
  endsWith?: InputMaybe<Scalars['ID']['input']>;
  equals?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<IdFilterInput>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  startsWith?: InputMaybe<Scalars['ID']['input']>;
};

export type IdFromCatalog = {
  __typename: 'IdFromCatalog';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  disambiguator?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  originalId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  uuid: Scalars['ID']['output'];
};

export type IdFromCatalogToPriceGraph = {
  __typename: 'IdFromCatalogToPriceGraph';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  idFromCatalog: IdFromCatalog;
  idFromCatalogUuid: Scalars['ID']['output'];
  priceGraphUuid: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type IdFromCatalogToPriceGraphFilterInput = {
  disambiguator?: InputMaybe<Scalars['String']['input']>;
  organizationUuid: Scalars['ID']['input'];
  originalIds: Array<Scalars['String']['input']>;
};

export enum IdFromCatalogToPriceGraphOrderByCriteria {
  PriceGraphUuid = 'priceGraphUuid'
}

export type IdFromCatalogToPriceGraphOrderByInput = {
  direction: SortOrder;
  key: IdFromCatalogToPriceGraphOrderByCriteria;
};

export type IdInFilter = {
  equals?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum ImageColorSpace {
  Gamma_2_0 = 'Gamma_2_0',
  Gamma_2_2 = 'Gamma_2_2',
  Linear = 'Linear',
  Srgb = 'Srgb',
  Unknown = 'Unknown'
}

export enum ImageDataType {
  Color = 'Color',
  NonColor = 'NonColor',
  Unknown = 'Unknown'
}

export type IntFilterInput = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  not?: InputMaybe<IntFilterInput>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntInFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum InvocationMethod {
  CloudTasks = 'CloudTasks',
  ComputeEngine = 'ComputeEngine',
  Http = 'Http',
  None = 'None'
}

export type Job = {
  __typename: 'Job';
  assignments: Array<JobAssignment>;
  cancelled?: Maybe<Scalars['DateTime']['output']>;
  claimedBy?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  executionPlan?: Maybe<Scalars['Json']['output']>;
  finished?: Maybe<Scalars['DateTime']['output']>;
  graph?: Maybe<Scalars['Json']['output']>;
  id: Scalars['ID']['output'];
  jobTasks: Array<JobTask>;
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
  metadataProcessingJobForDataObject?: Maybe<DataObject>;
  name?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  output?: Maybe<Scalars['Json']['output']>;
  priority: Scalars['Int']['output'];
  progress?: Maybe<Scalars['Int']['output']>;
  started?: Maybe<Scalars['DateTime']['output']>;
  state: JobState;
  thumbnailProcessingJobForDataObject?: Maybe<DataObject>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type JobDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type JobJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type JobAssignment = {
  __typename: 'JobAssignment';
  assignedTo?: Maybe<JobAssignmentSubject>;
  assignmentKey?: Maybe<Scalars['String']['output']>;
  contentTypeModel: ContentTypeModel;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  job: Job;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type JobAssignmentFilterInput = {
  assignmentKey?: InputMaybe<StringFilterInput>;
  contentTypeModel?: InputMaybe<ContentTypeModel>;
  id?: InputMaybe<Scalars['ID']['input']>;
  jobId?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export enum JobAssignmentOrderByCriteria {
  AssignmentKey = 'assignmentKey',
  Id = 'id'
}

export type JobAssignmentOrderByInput = {
  direction: SortOrder;
  key: JobAssignmentOrderByCriteria;
};

export type JobAssignmentSubject = Asset | Hdri | Material | Model | Picture | Project | Set | Template;

export type JobFarmGroup = {
  __typename: 'JobFarmGroup';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  instances: Array<JobFarmInstance>;
  name: Scalars['String']['output'];
  organization: Organization;
  otherConfig?: Maybe<Scalars['Json']['output']>;
  queueGroup: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type JobFarmGroupFilterInput = {
  id?: InputMaybe<IdInFilter>;
  name?: InputMaybe<StringFilterInput>;
  organizationId?: InputMaybe<IdInFilter>;
  queueGroup?: InputMaybe<StringInFilter>;
};

export enum JobFarmGroupOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name'
}

export type JobFarmGroupOrderByInput = {
  direction: SortOrder;
  key: JobFarmGroupOrderByCriteria;
};

export type JobFarmInstance = {
  __typename: 'JobFarmInstance';
  claim?: Maybe<JobTask>;
  /** @deprecated Not yet implemented */
  control: JobFarmInstanceControl;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  group?: Maybe<JobFarmGroup>;
  hwFingerprint: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastReport?: Maybe<Scalars['DateTime']['output']>;
  otherInfo?: Maybe<Scalars['Json']['output']>;
  state: JobFarmInstanceState;
  type: JobFarmInstanceType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  user: User;
  version: Scalars['String']['output'];
};

export enum JobFarmInstanceControl {
  ExitNow = 'ExitNow',
  ExitWhenIdle = 'ExitWhenIdle',
  Pause = 'Pause',
  Run = 'Run',
  UpdateNow = 'UpdateNow',
  UpdateWhenIdle = 'UpdateWhenIdle'
}

export type JobFarmInstanceFilterInput = {
  groupId?: InputMaybe<IdInFilter>;
  hwFingerprint?: InputMaybe<StringInFilter>;
  id?: InputMaybe<IdInFilter>;
  lastReport?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<JobFarmInstanceState>;
  type?: InputMaybe<JobFarmInstanceType>;
  userId?: InputMaybe<IdInFilter>;
  version?: InputMaybe<StringFilterInput>;
};

export enum JobFarmInstanceOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id'
}

export type JobFarmInstanceOrderByInput = {
  direction: SortOrder;
  key: JobFarmInstanceOrderByCriteria;
};

export enum JobFarmInstanceState {
  NotResponding = 'NotResponding',
  Paused = 'Paused',
  Running = 'Running',
  Stopped = 'Stopped',
  Updating = 'Updating'
}

export enum JobFarmInstanceType {
  RenderNodeApp = 'RenderNodeApp'
}

export type JobFilterInput = {
  createdByIds?: InputMaybe<IdInFilter>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  organizationId?: InputMaybe<IdInFilter>;
  organizationLegacyId?: InputMaybe<IntInFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<JobState>>;
};

export enum JobOrderByCriteria {
  Id = 'id',
  LegacyId = 'legacyId',
  Started = 'started'
}

export type JobOrderByInput = {
  direction: SortOrder;
  key: JobOrderByCriteria;
};

export enum JobState {
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Failed = 'Failed',
  Init = 'Init',
  Runnable = 'Runnable',
  Running = 'Running'
}

export type JobTask = {
  __typename: 'JobTask';
  assignments: Array<JobTaskAssignment>;
  attemptCount: Scalars['Int']['output'];
  cancelled?: Maybe<Scalars['DateTime']['output']>;
  claimedBy?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: User;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  deferredUntil?: Maybe<Scalars['DateTime']['output']>;
  finished?: Maybe<Scalars['DateTime']['output']>;
  graph?: Maybe<Scalars['Json']['output']>;
  hashInParent?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  job?: Maybe<JobTask>;
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  maxAttempts: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
  metadataProcessingJobForDataObject?: Maybe<DataObject>;
  name?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  output?: Maybe<Scalars['Json']['output']>;
  priority: Scalars['Int']['output'];
  progress?: Maybe<Scalars['Int']['output']>;
  queueGroup?: Maybe<Scalars['String']['output']>;
  queueToken?: Maybe<Scalars['String']['output']>;
  started?: Maybe<Scalars['DateTime']['output']>;
  state: JobTaskState;
  taskType?: Maybe<Scalars['String']['output']>;
  thumbnailProcessingJobForDataObject?: Maybe<DataObject>;
  updateDeadline?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  versionCounter: Scalars['Int']['output'];
};


export type JobTaskDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type JobTaskJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type JobTaskAssignment = {
  __typename: 'JobTaskAssignment';
  assignedTo?: Maybe<JobTaskAssignmentSubject>;
  assignmentKey?: Maybe<Scalars['String']['output']>;
  contentTypeModel: ContentTypeModel;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  jobTask: JobTask;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type JobTaskAssignmentFilterInput = {
  assignmentKey?: InputMaybe<StringFilterInput>;
  contentTypeModel?: InputMaybe<ContentTypeModel>;
  id?: InputMaybe<Scalars['ID']['input']>;
  jobTaskId?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export enum JobTaskAssignmentOrderByCriteria {
  AssignmentKey = 'assignmentKey',
  Id = 'id'
}

export type JobTaskAssignmentOrderByInput = {
  direction: SortOrder;
  key: JobTaskAssignmentOrderByCriteria;
};

export type JobTaskAssignmentSubject = Asset | Hdri | Material | Model | Picture | Project | Set | Template;

export type JobTaskFilterInput = {
  claimedBy?: InputMaybe<StringInFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<IdInFilter>;
  jobId?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  organizationId?: InputMaybe<IdInFilter>;
  organizationLegacyId?: InputMaybe<IntInFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<JobTaskState>>;
  taskType?: InputMaybe<Scalars['String']['input']>;
  versionCounter?: InputMaybe<Scalars['Int']['input']>;
};

export enum JobTaskOrderByCriteria {
  Id = 'id',
  LegacyId = 'legacyId',
  Started = 'started',
  VersionCounter = 'versionCounter'
}

export type JobTaskOrderByInput = {
  direction: SortOrder;
  key: JobTaskOrderByCriteria;
};

export type JobTaskRunnableFilterEntry = {
  /** @deprecated No longer used */
  maxVersion?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated No longer used */
  minVersion?: InputMaybe<Scalars['Int']['input']>;
  /** A string corresponding to a registered task type (e.g. `AssetRendering.ShaderBall.postRenderStep`). It must have a matching entry in the JobTaskType database table. No relation to the enum of the same name. */
  taskType: Scalars['String']['input'];
};

export type JobTaskRunnableFilterInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  queueGroup: Scalars['String']['input'];
  queueToken?: InputMaybe<Scalars['String']['input']>;
  taskTypes?: InputMaybe<Array<JobTaskRunnableFilterEntry>>;
};

export enum JobTaskState {
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Failed = 'Failed',
  Fatal = 'Fatal',
  Init = 'Init',
  Missing = 'Missing',
  Queued = 'Queued',
  Runnable = 'Runnable',
  Running = 'Running',
  Timeout = 'Timeout'
}

export type JobTaskType = {
  __typename: 'JobTaskType';
  cancelTimeout?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  defaultTimeout?: Maybe<Scalars['Int']['output']>;
  endpoint?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invocationMethod: InvocationMethod;
  maxTimeout?: Maybe<Scalars['Int']['output']>;
  queueGroup: Scalars['String']['output'];
  taskType: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type JobTaskTypeFilterInput = {
  endpoint?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdInFilter>;
  queueGroup?: InputMaybe<StringFilterInput>;
  taskType?: InputMaybe<StringFilterInput>;
};

export enum JobTaskTypeOrderByCriteria {
  LegacyId = 'legacyId',
  QueueGroup = 'queueGroup',
  TaskType = 'taskType'
}

export type JobTaskTypeOrderByInput = {
  direction: SortOrder;
  key: JobTaskTypeOrderByCriteria;
};

export type JsonFile = {
  __typename: 'JsonFile';
  content: Scalars['Json']['output'];
  created: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  jsonFileType: JsonFileType;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  organization: Organization;
  outputDataObject?: Maybe<DataObject>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type JsonFileDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type JsonFileAssignment = {
  __typename: 'JsonFileAssignment';
  assignedTo?: Maybe<JsonFileAssignmentSubject>;
  assignmentKey?: Maybe<Scalars['String']['output']>;
  assignmentType: JsonFileAssignmentType;
  contentTypeModel: ContentTypeModel;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  jsonFile: JsonFile;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  objectId: Scalars['String']['output'];
  objectLegacyId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type JsonFileAssignmentFilterInput = {
  assignmentKey?: InputMaybe<StringFilterInput>;
  assignmentType?: InputMaybe<Array<JsonFileAssignmentType>>;
  contentTypeModel?: InputMaybe<ContentTypeModel>;
  id?: InputMaybe<Scalars['ID']['input']>;
  jsonFileContent?: InputMaybe<Array<JsonFileConfigInput>>;
  jsonFileId?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type JsonFileAssignmentOrderByInput = {
  assignmentKey?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  objectId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type JsonFileAssignmentSubject = Asset | Hdri | JobTask | Material | MaterialRevision | Model | Organization | Picture | PictureRevision | Project | ScanJob | ScanSubJob | Scene | SceneRevision | Set | Tag | Template | TemplateRevision | Texture | TextureGroup | TextureRevision | TextureSet | User;

export enum JsonFileAssignmentType {
  MaterialMapsExportConfig = 'MaterialMapsExportConfig',
  TextureEdits = 'TextureEdits'
}

export type JsonFileConfigInput = {
  default?: InputMaybe<Scalars['Boolean']['input']>;
  engine?: InputMaybe<Scalars['String']['input']>;
  fileType?: InputMaybe<DownloadFileType>;
  name?: InputMaybe<Scalars['String']['input']>;
  normalY?: InputMaybe<Scalars['String']['input']>;
  resolution?: InputMaybe<DownloadResolution>;
  state?: InputMaybe<Scalars['String']['input']>;
  workflow?: InputMaybe<Scalars['String']['input']>;
};

export type JsonFileCreateWithoutAssignmentInput = {
  content: Scalars['Json']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  type: JsonFileType;
};

export type JsonFileFilterInput = {
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<IdInFilter>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  organizationId?: InputMaybe<IdInFilter>;
  type?: InputMaybe<Array<JsonFileType>>;
};

export enum JsonFileOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id'
}

export type JsonFileOrderByInput = {
  direction: SortOrder;
  key: JsonFileOrderByCriteria;
};

export enum JsonFileType {
  MaterialMapsExportConfig = 'MaterialMapsExportConfig',
  TextureEdits = 'TextureEdits'
}

export enum LoginProvider {
  Colormass = 'Colormass',
  Google = 'Google',
  Microsoft = 'Microsoft',
  None = 'None'
}

export type LoginResult = {
  __typename: 'LoginResult';
  /** JWT token for authentication. To use, add a header `Authorization: "Bearer <token>"`. Expires in 30 days. */
  token: Scalars['String']['output'];
  /** The user that has just been logged in */
  user: User;
};

export type Material = {
  __typename: 'Material';
  articleId?: Maybe<Scalars['String']['output']>;
  /** Assets assigned to this material via a MaterialAssignment. This is a convenience field that is equivalent to `materialAssignments.assets`. */
  assets: Array<Asset>;
  assetsCount: Scalars['Int']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  currentUserAssignment?: Maybe<User>;
  cyclesRevisions?: Maybe<Array<MaterialRevision>>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  galleryImage?: Maybe<DataObject>;
  id: Scalars['ID']['output'];
  internalName?: Maybe<Scalars['String']['output']>;
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  latestCyclesRevision?: Maybe<MaterialRevision>;
  latestRevision?: Maybe<MaterialRevision>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  /** Materials that are referenced by templates are locked. Locked materials cannot be changed from public to private, nor can their organization be changed. */
  locked: Scalars['Boolean']['output'];
  meshSpecific: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nextActor: NextActor;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']['output']>;
  outputForType?: Maybe<MaterialOutput>;
  outputs: Array<MaterialOutput>;
  paymentState: PaymentState;
  priorityOrder?: Maybe<Scalars['GraphQLBigInt']['output']>;
  public: Scalars['Boolean']['output'];
  revisions: Array<MaterialRevision>;
  revisionsCount: Scalars['Int']['output'];
  sampleArrival?: Maybe<Scalars['DateTime']['output']>;
  shareAssignments: Array<ShareAssignment>;
  shareAssignmentsCount: Scalars['Int']['output'];
  state: MaterialState;
  tagAssignments: Array<TagAssignment>;
  tagAssignmentsCount: Scalars['Int']['output'];
  taskInfos: TaskInfos;
  textureGroup?: Maybe<TextureGroup>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  userAssignments: Array<UserAssignment>;
  userAssignmentsCount: Scalars['Int']['output'];
};


export type MaterialDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type MaterialJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type MaterialOutputForTypeArgs = {
  type: MaterialOutputType;
};


export type MaterialShareAssignmentsArgs = {
  filter?: InputMaybe<ShareAssignmentFilterInput>;
  orderBy?: InputMaybe<ShareAssignmentOrderByInput>;
};


export type MaterialTagAssignmentsArgs = {
  tagTypes?: InputMaybe<Array<TagType>>;
};


export type MaterialUserAssignmentsArgs = {
  filter?: InputMaybe<UserAssignmentFilterInput>;
};

export type MaterialAssignment = {
  __typename: 'MaterialAssignment';
  asset: Asset;
  assetId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  material: Material;
  materialId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type MaterialAssignmentFilterInput = {
  asset?: InputMaybe<AssetFilterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  material?: InputMaybe<MaterialFilterInput>;
};

export type MaterialAssignmentHistory = {
  __typename: 'MaterialAssignmentHistory';
  asset?: Maybe<Asset>;
  createdBy?: Maybe<User>;
  historyChangeReason?: Maybe<Scalars['String']['output']>;
  historyDate?: Maybe<Scalars['DateTime']['output']>;
  historyType: HistoryType;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  material?: Maybe<Material>;
};

export type MaterialAssignmentHistoryFilterInput = {
  assetId?: InputMaybe<Scalars['ID']['input']>;
  assignmentId?: InputMaybe<IdInFilter>;
  historyChangeReason?: InputMaybe<StringFilterInput>;
  historyDate?: InputMaybe<DateTimeFilterInput>;
  historyType?: InputMaybe<Array<HistoryType>>;
  id?: InputMaybe<IdInFilter>;
  materialId?: InputMaybe<Scalars['ID']['input']>;
};

export enum MaterialAssignmentHistoryOrderByCriteria {
  ArticleId = 'articleId',
  HistoryDate = 'history_date',
  Id = 'id',
  Name = 'name',
  NextActor = 'nextActor',
  PaymentState = 'paymentState',
  PriorityOrder = 'priorityOrder',
  Public = 'public',
  State = 'state'
}

export type MaterialAssignmentHistoryOrderByInput = {
  direction: SortOrder;
  key: MaterialAssignmentHistoryOrderByCriteria;
};

export type MaterialConnection = {
  __typename: 'MaterialConnection';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  destination: MaterialNode;
  destinationParameter: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  materialRevision: MaterialRevision;
  source: MaterialNode;
  sourceParameter: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type MaterialConnectionFilterInput = {
  id?: InputMaybe<IdInFilter>;
  materialRevisionId?: InputMaybe<IdInFilter>;
};

export enum MaterialConnectionOrderByCriteria {
  Id = 'id',
  MaterialRevisionId = 'materialRevisionId'
}

export type MaterialConnectionOrderByInput = {
  direction: SortOrder;
  key: MaterialConnectionOrderByCriteria;
};

export type MaterialFilterInput = {
  articleId?: InputMaybe<StringInFilter>;
  comment?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  hasAssignedUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasCyclesMaterial?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<IdInFilter>;
  internalName?: InputMaybe<StringFilterInput>;
  legacyId?: InputMaybe<IntInFilter>;
  meshSpecific?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<StringFilterInput>;
  nextActor?: InputMaybe<Array<NextActor>>;
  organizationId?: InputMaybe<IdInFilter>;
  organizationLegacyId?: InputMaybe<IntInFilter>;
  paymentState?: InputMaybe<Array<PaymentState>>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  sampleArrived?: InputMaybe<Scalars['Boolean']['input']>;
  scannedByNone?: InputMaybe<Scalars['Boolean']['input']>;
  scannedByUserId?: InputMaybe<IdInFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<MaterialState>>;
  tagId?: InputMaybe<IdInFilter>;
  tagLegacyId?: InputMaybe<IntInFilter>;
  userId?: InputMaybe<IdInFilter>;
};

export type MaterialHistory = {
  __typename: 'MaterialHistory';
  articleId?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  historyChangeReason?: Maybe<Scalars['String']['output']>;
  historyDate?: Maybe<Scalars['DateTime']['output']>;
  historyType: HistoryType;
  id: Scalars['ID']['output'];
  internalName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  material?: Maybe<Material>;
  name?: Maybe<Scalars['String']['output']>;
  nextActor: NextActor;
  paymentState: PaymentState;
  public: Scalars['Boolean']['output'];
  sampleArrival?: Maybe<Scalars['DateTime']['output']>;
  state: MaterialState;
};

export type MaterialHistoryFilterInput = {
  articleId?: InputMaybe<StringInFilter>;
  comment?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  historyChangeReason?: InputMaybe<StringFilterInput>;
  historyDate?: InputMaybe<DateTimeFilterInput>;
  historyType?: InputMaybe<Array<HistoryType>>;
  id?: InputMaybe<IdInFilter>;
  internalName?: InputMaybe<StringFilterInput>;
  materialId?: InputMaybe<IdInFilter>;
  meshSpecific?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<StringFilterInput>;
  nextActor?: InputMaybe<Array<NextActor>>;
  organizationId?: InputMaybe<IdInFilter>;
  paymentState?: InputMaybe<Array<PaymentState>>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  sampleArrival?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<Array<MaterialState>>;
  userId?: InputMaybe<IdInFilter>;
};

export enum MaterialHistoryOrderByCriteria {
  ArticleId = 'articleId',
  HistoryDate = 'history_date',
  Id = 'id',
  Name = 'name',
  NextActor = 'nextActor',
  PaymentState = 'paymentState',
  PriorityOrder = 'priorityOrder',
  Public = 'public',
  State = 'state'
}

export type MaterialHistoryOrderByInput = {
  direction: SortOrder;
  key: MaterialHistoryOrderByCriteria;
};

export type MaterialNode = {
  __typename: 'MaterialNode';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  incoming: Array<MaterialConnection>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  materialRevision: MaterialRevision;
  name: Scalars['String']['output'];
  outgoing: Array<MaterialConnection>;
  parameters: Scalars['Json']['output'];
  textureRevision?: Maybe<TextureRevision>;
  textureSetRevision?: Maybe<TextureSetRevision>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type MaterialNodeFilterInput = {
  id?: InputMaybe<IdInFilter>;
  materialRevisionId?: InputMaybe<IdInFilter>;
  name?: InputMaybe<StringFilterInput>;
  textureRevisionId?: InputMaybe<IdInFilter>;
};

export enum MaterialNodeOrderByCriteria {
  Id = 'id',
  MaterialRevisionId = 'materialRevisionId'
}

export type MaterialNodeOrderByInput = {
  direction: SortOrder;
  key: MaterialNodeOrderByCriteria;
};

export enum MaterialOrderByCriteria {
  ArticleId = 'articleId',
  Id = 'id',
  LegacyId = 'legacyId',
  Name = 'name',
  NextActor = 'nextActor',
  PaymentState = 'paymentState',
  Priority = 'priority',
  Public = 'public',
  State = 'state'
}

export type MaterialOrderByInput = {
  direction: SortOrder;
  key: MaterialOrderByCriteria;
};

export type MaterialOutput = {
  __typename: 'MaterialOutput';
  config?: Maybe<JsonFile>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  job?: Maybe<Job>;
  material: Material;
  materialRevision?: Maybe<MaterialRevision>;
  result?: Maybe<DataObject>;
  type: MaterialOutputType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export enum MaterialOutputType {
  PbrExportCustom = 'PbrExportCustom',
  PbrExportDefaultExrOriginal = 'PbrExportDefaultExrOriginal',
  PbrExportDefaultJpegLow = 'PbrExportDefaultJpegLow',
  PbrExportDefaultJpegOriginal = 'PbrExportDefaultJpegOriginal',
  PbrExportDefaultTiffLow = 'PbrExportDefaultTiffLow',
  PbrExportDefaultTiffOriginal = 'PbrExportDefaultTiffOriginal',
  ShaderBall = 'ShaderBall',
  Thumbnail7x7inches = 'Thumbnail7x7inches',
  Thumbnail9x9inches = 'Thumbnail9x9inches',
  Thumbnail10x10cm = 'Thumbnail10x10cm',
  Thumbnail16x16inches = 'Thumbnail16x16inches',
  Thumbnail20x20cm = 'Thumbnail20x20cm',
  Thumbnail30x30cm = 'Thumbnail30x30cm',
  TileableImage = 'TileableImage'
}

export type MaterialReference = {
  __typename: 'MaterialReference';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  materialRevision: MaterialRevision;
  templateRevision: TemplateRevision;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type MaterialRevision = {
  __typename: 'MaterialRevision';
  comment?: Maybe<Scalars['String']['output']>;
  connections: Array<MaterialConnection>;
  connectionsCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  graphSchema?: Maybe<Scalars['String']['output']>;
  hasCyclesMaterial: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  matFileDataObject?: Maybe<DataObject>;
  material: Material;
  nodes: Array<MaterialNode>;
  nodesCount: Scalars['Int']['output'];
  number: Scalars['Int']['output'];
  /** All template revisions that reference this material revision via materialReferences */
  templateRevisions: Array<TemplateRevision>;
  templateRevisionsCount: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type MaterialRevisionDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type MaterialRevisionJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type MaterialRevisionFilterInput = {
  comment?: InputMaybe<StringFilterInput>;
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<IdInFilter>;
  hasCyclesMaterial?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  matFileDataObjectId?: InputMaybe<IntFilterInput>;
  materialId?: InputMaybe<IdInFilter>;
  number?: InputMaybe<IntFilterInput>;
};

export enum MaterialRevisionOrderByCriteria {
  Id = 'id',
  MaterialId = 'material_id',
  Number = 'number'
}

export type MaterialRevisionOrderByInput = {
  direction: SortOrder;
  key: MaterialRevisionOrderByCriteria;
};

export enum MaterialState {
  Completed = 'Completed',
  Draft = 'Draft',
  InfoReview = 'InfoReview',
  OnHold = 'OnHold',
  Review = 'Review',
  Scanning = 'Scanning',
  Shading = 'Shading',
  Tiling = 'Tiling'
}

export type Membership = {
  __typename: 'Membership';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  organization: Organization;
  packages: Array<Package>;
  role: MembershipRole;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  user: User;
};

export type MembershipCreateInput = {
  organizationId: Scalars['ID']['input'];
  role: MembershipRole;
};

export enum MembershipRole {
  Admin = 'Admin',
  Editor = 'Editor',
  Viewer = 'Viewer'
}

export type Model = {
  __typename: 'Model';
  activeUserAssignment?: Maybe<UserAssignment>;
  articleId?: Maybe<Scalars['String']['output']>;
  assets: Array<Asset>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  currentUserAssignment?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  galleryImage?: Maybe<DataObject>;
  id: Scalars['ID']['output'];
  internalName?: Maybe<Scalars['String']['output']>;
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nextActor: NextActor;
  organization?: Maybe<Organization>;
  paymentState: PaymentState;
  public: Scalars['Boolean']['output'];
  shareAssignments: Array<ShareAssignment>;
  shareAssignmentsCount: Scalars['Int']['output'];
  state: ModelState;
  tagAssignments: Array<TagAssignment>;
  tagAssignmentsCount: Scalars['Int']['output'];
  taskInfos: TaskInfos;
  tasks: Array<Task>;
  type: ModelType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  userAssignments: Array<UserAssignment>;
  userAssignmentsCount: Scalars['Int']['output'];
};


export type ModelDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ModelJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type ModelShareAssignmentsArgs = {
  filter?: InputMaybe<ShareAssignmentFilterInput>;
  orderBy?: InputMaybe<ShareAssignmentOrderByInput>;
};


export type ModelTagAssignmentsArgs = {
  tagTypes?: InputMaybe<Array<TagType>>;
};


export type ModelUserAssignmentsArgs = {
  filter?: InputMaybe<UserAssignmentFilterInput>;
};

export type ModelFilterInput = {
  hasAssignedUser?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  nextActor?: InputMaybe<Array<NextActor>>;
  organizationId?: InputMaybe<IdInFilter>;
  organizationLegacyId?: InputMaybe<IntInFilter>;
  paymentState?: InputMaybe<Array<PaymentState>>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<ModelState>>;
  tagId?: InputMaybe<IdInFilter>;
  userId?: InputMaybe<IdInFilter>;
};

export type ModelHistory = {
  __typename: 'ModelHistory';
  articleId?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  historyChangeReason?: Maybe<Scalars['String']['output']>;
  historyDate?: Maybe<Scalars['DateTime']['output']>;
  historyType: HistoryType;
  id: Scalars['ID']['output'];
  internalName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  model?: Maybe<Model>;
  name?: Maybe<Scalars['String']['output']>;
  nextActor: NextActor;
  organization?: Maybe<Organization>;
  paymentState: PaymentState;
  public: Scalars['Boolean']['output'];
  state: ModelState;
  type: ModelType;
};

export type ModelHistoryFilterInput = {
  articleId?: InputMaybe<StringInFilter>;
  comment?: InputMaybe<StringFilterInput>;
  date?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  historyChangeReason?: InputMaybe<StringFilterInput>;
  historyDate?: InputMaybe<DateTimeFilterInput>;
  historyType?: InputMaybe<Array<HistoryType>>;
  id?: InputMaybe<IdInFilter>;
  internalName?: InputMaybe<StringFilterInput>;
  modelId?: InputMaybe<IdInFilter>;
  name?: InputMaybe<StringFilterInput>;
  nextActor?: InputMaybe<Array<NextActor>>;
  organizationId?: InputMaybe<IdInFilter>;
  paymentState?: InputMaybe<Array<PaymentState>>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Array<ModelState>>;
  type?: InputMaybe<Array<ModelType>>;
  userId?: InputMaybe<IdInFilter>;
};

export enum ModelHistoryOrderByCriteria {
  ArticleId = 'articleId',
  HistoryDate = 'history_date',
  Id = 'id',
  Name = 'name',
  NextActor = 'nextActor',
  PaymentState = 'paymentState',
  PriorityOrder = 'priorityOrder',
  Public = 'public',
  State = 'state'
}

export type ModelHistoryOrderByInput = {
  direction: SortOrder;
  key: ModelHistoryOrderByCriteria;
};

export enum ModelOrderByCriteria {
  Id = 'id',
  LegacyId = 'legacyId',
  Name = 'name',
  PaymentState = 'paymentState',
  Priority = 'priority',
  Public = 'public',
  State = 'state'
}

export type ModelOrderByInput = {
  direction: SortOrder;
  key: ModelOrderByCriteria;
};

export enum ModelState {
  Completed = 'Completed',
  Draft = 'Draft',
  InfoReview = 'InfoReview',
  Modelling = 'Modelling',
  OnHold = 'OnHold',
  Review = 'Review'
}

export enum ModelType {
  Product = 'Product',
  Room = 'Room'
}

export type Mutation = {
  __typename: 'Mutation';
  batchUpdateArStatistics: Scalars['Int']['output'];
  batchUpdateAssets: Scalars['Int']['output'];
  batchUpdateDataObjectAssignments: Scalars['Int']['output'];
  batchUpdateMaterials: Scalars['Int']['output'];
  batchUpdateModels: Scalars['Int']['output'];
  batchUpdatePictures: Scalars['Int']['output'];
  batchUpdateRenderJobs: Scalars['Int']['output'];
  batchUpdateSceneStatistics: Scalars['Int']['output'];
  /** Mark as cancelled and process job graph */
  cancelJob: Job;
  /** Find an unclaimed, runnable job task matching filter criteria and claim it for processing */
  claimRunnableJobTask?: Maybe<JobTask>;
  /** Claim a specific task for processing. Failed tasks can be retried if claimed this way. */
  claimTask?: Maybe<JobTask>;
  /** Mark as completed and resynchronize data object assignments of type `JobTaskData` */
  completeJobTask: JobTask;
  createArStatistics: ArStatistics;
  createAsset: Asset;
  createDataObject: DataObject;
  createDataObjectAssignment: DataObjectAssignment;
  createHdri: Hdri;
  /** Create a new job and start it straightaway */
  createJob: Job;
  createJobAssignment: JobAssignment;
  createJobFarmGroup: JobFarmGroup;
  createJobFarmInstance: JobFarmInstance;
  /** Create a single job task. Useful for tests. */
  createJobTask: JobTask;
  createJobTaskAssignment: JobTaskAssignment;
  createJobTaskType: JobTaskType;
  createJsonFile: JsonFile;
  createJsonFileAssignment: JsonFileAssignment;
  createMaterial: Material;
  createMaterialAssignment: MaterialAssignment;
  createMaterialConnection: MaterialConnection;
  createMaterialNode: MaterialNode;
  createMaterialOutput: MaterialOutput;
  createMaterialReference: MaterialReference;
  createMaterialRevision: MaterialRevision;
  createMembership: Membership;
  createModel: Model;
  createOrUpdatePrices?: Maybe<Scalars['Void']['output']>;
  createOrganization: Organization;
  createPicture: Picture;
  createPictureAssetAssignment: PictureAssetAssignment;
  createPictureRevision: PictureRevision;
  createPriceGraph: PriceGraph;
  createPrices: Scalars['Void']['output'];
  createProject: Project;
  createRenderJob: RenderJob;
  createSalesEnquiry: SalesEnquiry;
  createScanJob: ScanJob;
  createScanSubJob: ScanSubJob;
  createScene: Scene;
  createSceneRevision: SceneRevision;
  createSceneStatistics: SceneStatistics;
  createSet: Set;
  createStorageDeleteTask: StorageDeleteTask;
  createTag: Tag;
  createTagAssignment: TagAssignment;
  createTask: Task;
  createTaskComment: TaskComment;
  createTaskPin: TaskPin;
  createTemplate: Template;
  createTemplateRevision: TemplateRevision;
  createTexture: Texture;
  createTextureEditGroup: TextureEditGroup;
  createTextureGroup: TextureGroup;
  createTextureRevision: TextureRevision;
  createTextureSet: TextureSet;
  createTextureSetRevision: TextureSetRevision;
  /** Create a raw job without starting it. Useful for tests and other cases that require tight control of the job's state. */
  createUnstartedJob: Job;
  createUser: User;
  createUserAssignment: UserAssignment;
  deleteArStatistics?: Maybe<Scalars['Void']['output']>;
  deleteAsset?: Maybe<Scalars['Void']['output']>;
  deleteDataObject?: Maybe<Scalars['Void']['output']>;
  deleteDataObjectAssignment?: Maybe<Scalars['Void']['output']>;
  deleteDataObjectAssignments: Scalars['Int']['output'];
  deleteHdri?: Maybe<Scalars['Void']['output']>;
  deleteIdFromCustomerToPriceGraph?: Maybe<Scalars['Void']['output']>;
  /** Delete a job and all of its children */
  deleteJob?: Maybe<Scalars['Void']['output']>;
  deleteJobAssignment?: Maybe<Scalars['Void']['output']>;
  deleteJobFarmGroup?: Maybe<Scalars['Void']['output']>;
  deleteJobFarmInstance?: Maybe<Scalars['Void']['output']>;
  deleteJobTaskAssignment?: Maybe<Scalars['Void']['output']>;
  deleteJobTaskType?: Maybe<Scalars['Void']['output']>;
  deleteJsonFile?: Maybe<Scalars['Void']['output']>;
  deleteJsonFileAssignment?: Maybe<Scalars['Void']['output']>;
  deleteMaterial?: Maybe<Scalars['Void']['output']>;
  deleteMaterialAssignment?: Maybe<Scalars['Void']['output']>;
  deleteMaterialConnection?: Maybe<Scalars['Void']['output']>;
  deleteMaterialNode?: Maybe<Scalars['Void']['output']>;
  deleteMaterialOutput?: Maybe<Scalars['Void']['output']>;
  deleteMaterialReference?: Maybe<Scalars['Void']['output']>;
  deleteMaterialRevision?: Maybe<Scalars['Void']['output']>;
  deleteMembership?: Maybe<Scalars['Void']['output']>;
  deleteModel?: Maybe<Scalars['Void']['output']>;
  deleteOrganization?: Maybe<Scalars['Void']['output']>;
  deletePicture?: Maybe<Scalars['Void']['output']>;
  deletePictureAssetAssignment?: Maybe<Scalars['Void']['output']>;
  deletePictureRevision?: Maybe<Scalars['Void']['output']>;
  deletePriceGraph?: Maybe<Scalars['Void']['output']>;
  deletePrices?: Maybe<Scalars['Void']['output']>;
  deleteProject?: Maybe<Scalars['Void']['output']>;
  deleteRenderJob?: Maybe<Scalars['Void']['output']>;
  deleteSalesEnquiry?: Maybe<Scalars['Void']['output']>;
  deleteScanJob?: Maybe<Scalars['Void']['output']>;
  deleteScanSubJob?: Maybe<Scalars['Void']['output']>;
  deleteScene?: Maybe<Scalars['Void']['output']>;
  deleteSceneRevision?: Maybe<Scalars['Void']['output']>;
  deleteSceneStatistics?: Maybe<Scalars['Void']['output']>;
  deleteSet?: Maybe<Scalars['Void']['output']>;
  deleteStorageDeleteTask?: Maybe<Scalars['Void']['output']>;
  deleteTag?: Maybe<Scalars['Void']['output']>;
  deleteTagAssignment?: Maybe<Scalars['Void']['output']>;
  deleteTask?: Maybe<Scalars['Void']['output']>;
  deleteTaskComment?: Maybe<Scalars['Void']['output']>;
  deleteTaskPin?: Maybe<Scalars['Void']['output']>;
  deleteTemplate?: Maybe<Scalars['Void']['output']>;
  deleteTemplateRevision?: Maybe<Scalars['Void']['output']>;
  deleteTexture?: Maybe<Scalars['Void']['output']>;
  deleteTextureEditGroup?: Maybe<Scalars['Void']['output']>;
  deleteTextureGroup?: Maybe<Scalars['Void']['output']>;
  deleteTextureRevision?: Maybe<Scalars['Void']['output']>;
  deleteTextureSet?: Maybe<Scalars['Void']['output']>;
  deleteTextureSetRevision: TextureSetRevision;
  deleteUser?: Maybe<Scalars['Void']['output']>;
  deleteUserAssignment?: Maybe<Scalars['Void']['output']>;
  deleteUserAssignments: Scalars['Int']['output'];
  /** Mark job task as failed */
  failJobTask: JobTask;
  /** Generate an API key for an API service user. Use in `X-Api-Key` header to authenticate API users. This type of key does not expire and should be rotated regularly. */
  generateApiKey: Scalars['String']['output'];
  /** Generate AR file for a template. */
  generateAr: Job;
  inviteUser: User;
  /** Log in a user. Select the `token` field to get a JWT token for authentication. */
  login: LoginResult;
  /** Trigger job graph processing for all active jobs to advance them more quickly */
  nudgeAllJobs: Scalars['Int']['output'];
  /** Trigger job graph processing to advance the job more quickly */
  nudgeJob: Job;
  /** Create and start a job to generate thumbnails and set metadata for a completed upload. */
  processUpload: Job;
  /** Delete and recreate all child tasks, then restart the job */
  rerunAllTasksInJob: Job;
  resetPassword: Scalars['String']['output'];
  /** Reprocess job from the top, keeping completed tasks */
  restartJob: Job;
  sendPasswordResetEmail: Scalars['Boolean']['output'];
  updateArStatistics: ArStatistics;
  updateAsset: Asset;
  updateDataObject: DataObject;
  updateDataObjectAssignment: DataObjectAssignment;
  updateHdri: Hdri;
  updateJob: Job;
  updateJobAssignment: JobAssignment;
  updateJobFarmGroup: JobFarmGroup;
  updateJobFarmInstance: JobFarmInstance;
  updateJobTask: JobTask;
  updateJobTaskAssignment: JobTaskAssignment;
  updateJobTaskType: JobTaskType;
  updateJsonFile: JsonFile;
  updateJsonFileAssignment: JsonFileAssignment;
  updateMaterial: Material;
  updateMaterialConnection: MaterialConnection;
  updateMaterialNode: MaterialNode;
  updateMaterialOutput: MaterialOutput;
  updateMaterialOutputByJob: MaterialOutput;
  updateMaterialReference: MaterialReference;
  updateMaterialRevision: MaterialRevision;
  updateModel: Model;
  updateOrganization: Organization;
  updatePicture: Picture;
  updatePictureRevision: PictureRevision;
  updatePriceGraph: PriceGraph;
  updatePrices: Scalars['Void']['output'];
  updateProject: Project;
  updateRenderJob: RenderJob;
  updateScanJob: ScanJob;
  updateScanSubJob: ScanSubJob;
  updateScene: Scene;
  updateSceneRevision: SceneRevision;
  updateSceneStatistics: SceneStatistics;
  updateSet: Set;
  updateStorageDeleteTask: StorageDeleteTask;
  updateTag: Tag;
  updateTagAssignment: TagAssignment;
  updateTask: Task;
  updateTaskComment: TaskComment;
  updateTaskPin: TaskPin;
  updateTemplate: Template;
  updateTemplateRevision: TemplateRevision;
  updateTexture: Texture;
  updateTextureEditGroup: TextureEditGroup;
  updateTextureGroup: TextureGroup;
  updateTextureRevision: TextureRevision;
  updateTextureSet: TextureSet;
  updateTextureSetRevision: TextureSetRevision;
  updateUser: User;
  updateUserAssignment: UserAssignment;
  /** Log in a user using an access token from the Google/Microsoft OAuth server. */
  verifyExternalLogin: LoginResult;
};


export type MutationBatchUpdateArStatisticsArgs = {
  filter?: InputMaybe<ArStatisticsFilterInput>;
  templateRevisionId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationBatchUpdateAssetsArgs = {
  addTagId?: InputMaybe<Scalars['ID']['input']>;
  assignUserId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<AssetFilterInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  nextActor?: InputMaybe<NextActor>;
  removeTagId?: InputMaybe<Scalars['ID']['input']>;
  removeUserAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<AssetState>;
};


export type MutationBatchUpdateDataObjectAssignmentsArgs = {
  contentTypeModel: ContentTypeModel;
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  objectId: Scalars['ID']['input'];
};


export type MutationBatchUpdateMaterialsArgs = {
  addTagId?: InputMaybe<Scalars['ID']['input']>;
  assignUserId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<MaterialFilterInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  nextActor?: InputMaybe<NextActor>;
  paymentState?: InputMaybe<PaymentState>;
  removeTagId?: InputMaybe<Scalars['ID']['input']>;
  removeUserAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<MaterialState>;
};


export type MutationBatchUpdateModelsArgs = {
  addTagId?: InputMaybe<Scalars['ID']['input']>;
  assignUserId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<ModelFilterInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  nextActor?: InputMaybe<NextActor>;
  paymentState?: InputMaybe<PaymentState>;
  removeTagId?: InputMaybe<Scalars['ID']['input']>;
  removeUserAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<ModelState>;
};


export type MutationBatchUpdatePicturesArgs = {
  addTagId?: InputMaybe<Scalars['ID']['input']>;
  assignUserId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<PictureFilterInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  nextActor?: InputMaybe<NextActor>;
  paymentState?: InputMaybe<PaymentState>;
  removeTagId?: InputMaybe<Scalars['ID']['input']>;
  removeUserAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<PictureState>;
};


export type MutationBatchUpdateRenderJobsArgs = {
  filter?: InputMaybe<RenderJobFilterInput>;
  templateRevisionId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationBatchUpdateSceneStatisticsArgs = {
  filter?: InputMaybe<SceneStatisticsFilterInput>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCancelJobArgs = {
  id: Scalars['ID']['input'];
  instanceId?: InputMaybe<Scalars['String']['input']>;
  queueToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationClaimRunnableJobTaskArgs = {
  filter: JobTaskRunnableFilterInput;
  instanceId: Scalars['String']['input'];
  updateTimeout: Scalars['Int']['input'];
};


export type MutationClaimTaskArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  instanceId: Scalars['String']['input'];
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  queueGroup: Scalars['String']['input'];
  queueToken?: InputMaybe<Scalars['String']['input']>;
  updateTimeout: Scalars['Int']['input'];
};


export type MutationCompleteJobTaskArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  instanceId?: InputMaybe<Scalars['String']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  output: Scalars['Json']['input'];
  queueToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateArStatisticsArgs = {
  input: MutationCreateArStatisticsInput;
};


export type MutationCreateAssetArgs = {
  input: MutationCreateAssetInput;
};


export type MutationCreateDataObjectArgs = {
  input?: InputMaybe<MutationCreateDataObjectInput>;
};


export type MutationCreateDataObjectAssignmentArgs = {
  input: MutationCreateDataObjectAssignmentInput;
};


export type MutationCreateHdriArgs = {
  input: MutationCreateHdriInput;
};


export type MutationCreateJobArgs = {
  input: MutationCreateJobInput;
};


export type MutationCreateJobAssignmentArgs = {
  input: MutationCreateJobAssignmentInput;
};


export type MutationCreateJobFarmGroupArgs = {
  input: MutationCreateJobFarmGroupInput;
};


export type MutationCreateJobFarmInstanceArgs = {
  input: MutationCreateJobFarmInstanceInput;
};


export type MutationCreateJobTaskArgs = {
  input: MutationCreateJobTaskInput;
};


export type MutationCreateJobTaskAssignmentArgs = {
  input: MutationCreateJobTaskAssignmentInput;
};


export type MutationCreateJobTaskTypeArgs = {
  input: MutationCreateJobTaskTypeInput;
};


export type MutationCreateJsonFileArgs = {
  input: MutationCreateJsonFileInput;
};


export type MutationCreateJsonFileAssignmentArgs = {
  input: MutationCreateJsonFileAssignmentInput;
};


export type MutationCreateMaterialArgs = {
  input: MutationCreateMaterialInput;
};


export type MutationCreateMaterialAssignmentArgs = {
  input: MutationCreateMaterialAssignmentInput;
};


export type MutationCreateMaterialConnectionArgs = {
  input: MutationCreateMaterialConnectionInput;
};


export type MutationCreateMaterialNodeArgs = {
  input: MutationCreateMaterialNodeInput;
};


export type MutationCreateMaterialOutputArgs = {
  input: MutationCreateMaterialOutputInput;
};


export type MutationCreateMaterialReferenceArgs = {
  input: MutationCreateMaterialReferenceInput;
};


export type MutationCreateMaterialRevisionArgs = {
  input: MutationCreateMaterialRevisionInput;
};


export type MutationCreateMembershipArgs = {
  input: MutationCreateMembershipInput;
};


export type MutationCreateModelArgs = {
  input: MutationCreateModelInput;
};


export type MutationCreateOrUpdatePricesArgs = {
  organizationId: Scalars['ID']['input'];
  prices: Array<PriceInput>;
};


export type MutationCreateOrganizationArgs = {
  input: MutationCreateOrganizationInput;
};


export type MutationCreatePictureArgs = {
  input: MutationCreatePictureInput;
};


export type MutationCreatePictureAssetAssignmentArgs = {
  input: MutationCreatePictureAssetAssignmentInput;
};


export type MutationCreatePictureRevisionArgs = {
  input: MutationCreatePictureRevisionInput;
};


export type MutationCreatePriceGraphArgs = {
  input: MutationCreatePriceGraphInput;
};


export type MutationCreatePricesArgs = {
  organizationId: Scalars['ID']['input'];
  prices: Array<PriceInput>;
};


export type MutationCreateProjectArgs = {
  input: MutationCreateProjectInput;
};


export type MutationCreateRenderJobArgs = {
  input: MutationCreateRenderJobInput;
};


export type MutationCreateSalesEnquiryArgs = {
  input: MutationCreateSalesEnquiryInput;
};


export type MutationCreateScanJobArgs = {
  input: MutationCreateScanJobInput;
};


export type MutationCreateScanSubJobArgs = {
  input: MutationCreateScanSubJobInput;
};


export type MutationCreateSceneArgs = {
  input: MutationCreateSceneInput;
};


export type MutationCreateSceneRevisionArgs = {
  input: MutationCreateSceneRevisionInput;
};


export type MutationCreateSceneStatisticsArgs = {
  input: MutationCreateSceneStatisticsInput;
};


export type MutationCreateSetArgs = {
  input: MutationCreateSetInput;
};


export type MutationCreateStorageDeleteTaskArgs = {
  input: MutationCreateStorageDeleteTaskInput;
};


export type MutationCreateTagArgs = {
  input: MutationCreateTagInput;
};


export type MutationCreateTagAssignmentArgs = {
  input: MutationCreateTagAssignmentInput;
};


export type MutationCreateTaskArgs = {
  input: MutationCreateTaskInput;
};


export type MutationCreateTaskCommentArgs = {
  input: MutationCreateTaskCommentInput;
};


export type MutationCreateTaskPinArgs = {
  input: MutationCreateTaskPinInput;
};


export type MutationCreateTemplateArgs = {
  input: MutationCreateTemplateInput;
};


export type MutationCreateTemplateRevisionArgs = {
  input: MutationCreateTemplateRevisionInput;
};


export type MutationCreateTextureArgs = {
  input: MutationCreateTextureInput;
};


export type MutationCreateTextureEditGroupArgs = {
  input: MutationCreateTextureEditGroupInput;
};


export type MutationCreateTextureGroupArgs = {
  input: MutationCreateTextureGroupInput;
};


export type MutationCreateTextureRevisionArgs = {
  input: MutationCreateTextureRevisionInput;
};


export type MutationCreateTextureSetArgs = {
  input: MutationCreateTextureSetInput;
};


export type MutationCreateTextureSetRevisionArgs = {
  input: MutationCreateTextureSetRevisionInput;
};


export type MutationCreateUnstartedJobArgs = {
  input: MutationCreateUnstartedJobInput;
};


export type MutationCreateUserArgs = {
  input: MutationCreateUserInput;
};


export type MutationCreateUserAssignmentArgs = {
  input: MutationCreateUserAssignmentInput;
};


export type MutationDeleteArStatisticsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteAssetArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDataObjectArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteDataObjectAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteDataObjectAssignmentsArgs = {
  input: MutationDeleteDataObjectAssignmentsInput;
};


export type MutationDeleteHdriArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteIdFromCustomerToPriceGraphArgs = {
  priceGraphId: Scalars['ID']['input'];
};


export type MutationDeleteJobArgs = {
  id: Scalars['ID']['input'];
  instanceId?: InputMaybe<Scalars['String']['input']>;
  queueToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteJobAssignmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteJobFarmGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteJobFarmInstanceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteJobTaskAssignmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteJobTaskTypeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteJsonFileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteJsonFileAssignmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMaterialArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMaterialAssignmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMaterialConnectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMaterialNodeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMaterialOutputArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMaterialReferenceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMaterialRevisionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMembershipArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteModelArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOrganizationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeletePictureArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePictureAssetAssignmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePictureRevisionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePriceGraphArgs = {
  priceGraphId: Scalars['ID']['input'];
};


export type MutationDeletePricesArgs = {
  organizationId: Scalars['ID']['input'];
  originalIds: Array<Scalars['String']['input']>;
};


export type MutationDeleteProjectArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteRenderJobArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSalesEnquiryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteScanJobArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteScanSubJobArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSceneArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSceneRevisionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSceneStatisticsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSetArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteStorageDeleteTaskArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTagArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteTagAssignmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTaskArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTaskCommentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTaskPinArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTemplateRevisionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTextureArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTextureEditGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTextureGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTextureRevisionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTextureSetArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTextureSetRevisionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteUserAssignmentsArgs = {
  input: MutationDeleteUserAssignmentsInput;
};


export type MutationFailJobTaskArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  instanceId?: InputMaybe<Scalars['String']['input']>;
  isFatal?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  queueToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGenerateApiKeyArgs = {
  input: MutationGenerateApiKeyInput;
};


export type MutationGenerateArArgs = {
  configString: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  templateLegacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationInviteUserArgs = {
  input: MutationInviteUserInput;
};


export type MutationLoginArgs = {
  input: MutationLoginInput;
};


export type MutationNudgeJobArgs = {
  id: Scalars['ID']['input'];
};


export type MutationProcessUploadArgs = {
  dataObjectId: Scalars['String']['input'];
};


export type MutationRerunAllTasksInJobArgs = {
  id: Scalars['ID']['input'];
  instanceId?: InputMaybe<Scalars['String']['input']>;
  queueToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationResetPasswordArgs = {
  input: MutationResetPasswordInput;
};


export type MutationRestartJobArgs = {
  graph?: InputMaybe<Scalars['Json']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationSendPasswordResetEmailArgs = {
  input: MutationSendPasswordResetEmailInput;
};


export type MutationUpdateArStatisticsArgs = {
  input: MutationUpdateArStatisticsInput;
};


export type MutationUpdateAssetArgs = {
  input: MutationUpdateAssetInput;
};


export type MutationUpdateDataObjectArgs = {
  input: MutationUpdateDataObjectInput;
};


export type MutationUpdateDataObjectAssignmentArgs = {
  input: MutationUpdateDataObjectAssignmentInput;
};


export type MutationUpdateHdriArgs = {
  input: MutationUpdateHdriInput;
};


export type MutationUpdateJobArgs = {
  input: MutationUpdateJobInput;
};


export type MutationUpdateJobAssignmentArgs = {
  input: MutationUpdateJobAssignmentInput;
};


export type MutationUpdateJobFarmGroupArgs = {
  input: MutationUpdateJobFarmGroupInput;
};


export type MutationUpdateJobFarmInstanceArgs = {
  input: MutationUpdateJobFarmInstanceInput;
};


export type MutationUpdateJobTaskArgs = {
  input: MutationUpdateJobTaskInput;
};


export type MutationUpdateJobTaskAssignmentArgs = {
  input: MutationUpdateJobTaskAssignmentInput;
};


export type MutationUpdateJobTaskTypeArgs = {
  input: MutationUpdateJobTaskTypeInput;
};


export type MutationUpdateJsonFileArgs = {
  input: MutationUpdateJsonFileInput;
};


export type MutationUpdateJsonFileAssignmentArgs = {
  input: MutationUpdateJsonFileAssignmentInput;
};


export type MutationUpdateMaterialArgs = {
  input: MutationUpdateMaterialInput;
};


export type MutationUpdateMaterialConnectionArgs = {
  input: MutationUpdateMaterialConnectionInput;
};


export type MutationUpdateMaterialNodeArgs = {
  input: MutationUpdateMaterialNodeInput;
};


export type MutationUpdateMaterialOutputArgs = {
  input: MutationUpdateMaterialOutputInput;
};


export type MutationUpdateMaterialOutputByJobArgs = {
  input: MutationUpdateMaterialOutputByJobInput;
};


export type MutationUpdateMaterialReferenceArgs = {
  input: MutationUpdateMaterialReferenceInput;
};


export type MutationUpdateMaterialRevisionArgs = {
  input: MutationUpdateMaterialRevisionInput;
};


export type MutationUpdateModelArgs = {
  input: MutationUpdateModelInput;
};


export type MutationUpdateOrganizationArgs = {
  input: MutationUpdateOrganizationInput;
};


export type MutationUpdatePictureArgs = {
  input: MutationUpdatePictureInput;
};


export type MutationUpdatePictureRevisionArgs = {
  input: MutationUpdatePictureRevisionInput;
};


export type MutationUpdatePriceGraphArgs = {
  input: MutationUpdatePriceGraphInput;
};


export type MutationUpdatePricesArgs = {
  organizationId: Scalars['ID']['input'];
  prices: Array<PriceInput>;
};


export type MutationUpdateProjectArgs = {
  input: MutationUpdateProjectInput;
};


export type MutationUpdateRenderJobArgs = {
  input: MutationUpdateRenderJobInput;
};


export type MutationUpdateScanJobArgs = {
  input: MutationUpdateScanJobInput;
};


export type MutationUpdateScanSubJobArgs = {
  input: MutationUpdateScanSubJobInput;
};


export type MutationUpdateSceneArgs = {
  input: MutationUpdateSceneInput;
};


export type MutationUpdateSceneRevisionArgs = {
  input: MutationUpdateSceneRevisionInput;
};


export type MutationUpdateSceneStatisticsArgs = {
  input: MutationUpdateSceneStatisticsInput;
};


export type MutationUpdateSetArgs = {
  input: MutationUpdateSetInput;
};


export type MutationUpdateStorageDeleteTaskArgs = {
  input: MutationUpdateStorageDeleteTaskInput;
};


export type MutationUpdateTagArgs = {
  input: MutationUpdateTagInput;
};


export type MutationUpdateTagAssignmentArgs = {
  input: MutationUpdateTagAssignmentInput;
};


export type MutationUpdateTaskArgs = {
  input: MutationUpdateTaskInput;
};


export type MutationUpdateTaskCommentArgs = {
  input: MutationUpdateTaskCommentInput;
};


export type MutationUpdateTaskPinArgs = {
  input: MutationUpdateTaskPinInput;
};


export type MutationUpdateTemplateArgs = {
  input: MutationUpdateTemplateInput;
};


export type MutationUpdateTemplateRevisionArgs = {
  input: MutationUpdateTemplateRevisionInput;
};


export type MutationUpdateTextureArgs = {
  input: MutationUpdateTextureInput;
};


export type MutationUpdateTextureEditGroupArgs = {
  input: MutationUpdateTextureEditGroupInput;
};


export type MutationUpdateTextureGroupArgs = {
  input: MutationUpdateTextureGroupInput;
};


export type MutationUpdateTextureRevisionArgs = {
  input: MutationUpdateTextureRevisionInput;
};


export type MutationUpdateTextureSetArgs = {
  input: MutationUpdateTextureSetInput;
};


export type MutationUpdateTextureSetRevisionArgs = {
  input: MutationUpdateTextureSetRevisionInput;
};


export type MutationUpdateUserArgs = {
  input: MutationUpdateUserInput;
};


export type MutationUpdateUserAssignmentArgs = {
  input: MutationUpdateUserAssignmentInput;
};


export type MutationVerifyExternalLoginArgs = {
  input: MutationVerifyExternalLoginInput;
};

export type MutationCreateArStatisticsInput = {
  configString: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  templateRevisionId?: InputMaybe<Scalars['ID']['input']>;
  templateRevisionLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateAssetInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  dataObjectAssignments?: InputMaybe<Array<CreateDataObjectAssignmentInlineInput>>;
  modelId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  nextActor: NextActor;
  organizationId: Scalars['ID']['input'];
  pictureIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  state: AssetState;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateDataObjectAssignmentInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  contentTypeModel: ContentTypeModel;
  dataObjectId?: InputMaybe<Scalars['ID']['input']>;
  dataObjectLegacyId?: InputMaybe<Scalars['Int']['input']>;
  legacyAssignmentType?: InputMaybe<Scalars['String']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<DataObjectAssignmentType>;
};

export type MutationCreateDataObjectInput = {
  bucketName?: InputMaybe<Scalars['String']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageColorProfile?: InputMaybe<Scalars['String']['input']>;
  imageColorSpace?: InputMaybe<ImageColorSpace>;
  imageDataType?: InputMaybe<ImageDataType>;
  mediaType?: InputMaybe<Scalars['String']['input']>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  originalFileName: Scalars['String']['input'];
  processedByVersion?: InputMaybe<Scalars['String']['input']>;
  relatedToId?: InputMaybe<Scalars['ID']['input']>;
  relatedToLegacyId?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated No longer used. Create and start a job to perform upload processing. */
  state?: InputMaybe<DataObjectState>;
  type?: InputMaybe<DataObjectType>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateHdriInput = {
  createdById?: InputMaybe<Scalars['ID']['input']>;
  dataObjectId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateJobAssignmentInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  contentTypeModel: ContentTypeModel;
  jobId?: InputMaybe<Scalars['ID']['input']>;
  jobLegacyId?: InputMaybe<Scalars['Int']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated Use `objectId` instead */
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateJobFarmGroupInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  otherConfig?: InputMaybe<Scalars['Json']['input']>;
  queueGroup: Scalars['String']['input'];
};

export type MutationCreateJobFarmInstanceInput = {
  groupId?: InputMaybe<Scalars['ID']['input']>;
  hwFingerprint: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  lastReport?: InputMaybe<Scalars['DateTime']['input']>;
  otherInfo?: InputMaybe<Scalars['Json']['input']>;
  state: JobFarmInstanceState;
  type: JobFarmInstanceType;
  userId?: InputMaybe<Scalars['ID']['input']>;
  version: Scalars['String']['input'];
};

export type MutationCreateJobInput = {
  graph?: InputMaybe<Scalars['Json']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  metadataProcessingJobForDataObjectId?: InputMaybe<Scalars['ID']['input']>;
  metadataProcessingJobForDataObjectLegacyId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated Use `organizationId` instead */
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<JobState>;
  thumbnailProcessingJobForDataObjectId?: InputMaybe<Scalars['ID']['input']>;
  thumbnailProcessingJobForDataObjectLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateJobTaskAssignmentInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  contentTypeModel: ContentTypeModel;
  jobTaskId?: InputMaybe<Scalars['ID']['input']>;
  jobTaskLegacyId?: InputMaybe<Scalars['Int']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated Use `objectId` instead */
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateJobTaskInput = {
  cancelled?: InputMaybe<Scalars['DateTime']['input']>;
  claimedBy?: InputMaybe<Scalars['String']['input']>;
  deferredUntil?: InputMaybe<Scalars['DateTime']['input']>;
  finished?: InputMaybe<Scalars['DateTime']['input']>;
  graph?: InputMaybe<Scalars['Json']['input']>;
  hashInParent?: InputMaybe<Scalars['String']['input']>;
  jobId?: InputMaybe<Scalars['ID']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated Use `organizationId` instead */
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  output?: InputMaybe<Scalars['Json']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  progress?: InputMaybe<Scalars['Int']['input']>;
  queueGroup?: InputMaybe<Scalars['String']['input']>;
  queueToken?: InputMaybe<Scalars['String']['input']>;
  started?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<JobTaskState>;
};

export type MutationCreateJobTaskTypeInput = {
  cancelTimeout?: InputMaybe<Scalars['Int']['input']>;
  defaultTimeout?: InputMaybe<Scalars['Int']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  invocationMethod: InvocationMethod;
  maxTimeout?: InputMaybe<Scalars['Int']['input']>;
  queueGroup: Scalars['String']['input'];
  /** The job task's type as a string. Note that this is different from a task's type field (of type `TaskType`) */
  taskType: Scalars['String']['input'];
};

export type MutationCreateJsonFileAssignmentInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  contentTypeModel: ContentTypeModel;
  jsonFile?: InputMaybe<JsonFileCreateWithoutAssignmentInput>;
  jsonFileId?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<JsonFileAssignmentType>;
};

export type MutationCreateJsonFileInput = {
  content: Scalars['Json']['input'];
  createdById?: InputMaybe<Scalars['ID']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<JsonFileType>;
};

export type MutationCreateMaterialAssignmentInput = {
  assetId: Scalars['ID']['input'];
  materialId: Scalars['ID']['input'];
};

export type MutationCreateMaterialConnectionInput = {
  destinationId: Scalars['ID']['input'];
  destinationParameter: Scalars['String']['input'];
  materialRevisionId: Scalars['ID']['input'];
  sourceId: Scalars['ID']['input'];
  sourceParameter: Scalars['String']['input'];
};

export type MutationCreateMaterialInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  assetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  internalName?: InputMaybe<Scalars['String']['input']>;
  meshSpecific?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  nextActor: NextActor;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  paymentState: PaymentState;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  sampleArrival?: InputMaybe<Scalars['DateTime']['input']>;
  state: MaterialState;
  tagAssignments?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationCreateMaterialNodeInput = {
  incomingIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  materialRevisionId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  outgoingIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  parameters: Scalars['Json']['input'];
  textureRevisionId?: InputMaybe<Scalars['ID']['input']>;
  textureSetRevisionId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateMaterialOutputInput = {
  configId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  jobId: Scalars['ID']['input'];
  materialId: Scalars['ID']['input'];
  materialRevisionId?: InputMaybe<Scalars['ID']['input']>;
  type: MaterialOutputType;
};

export type MutationCreateMaterialReferenceInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  materialRevisionId: Scalars['ID']['input'];
  templateRevisionId: Scalars['ID']['input'];
};

export type MutationCreateMaterialRevisionInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  connectionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  graphSchema: Scalars['String']['input'];
  matFileDataObjectId?: InputMaybe<Scalars['ID']['input']>;
  materialId: Scalars['ID']['input'];
  materialReferenceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  nodeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  number?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateMembershipInput = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationCreateModelInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  internalName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextActor: NextActor;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  paymentState: PaymentState;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  state: ModelState;
  type: ModelType;
};

export type MutationCreateOrganizationInput = {
  defaultScannerUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  salesEmail?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<OrganizationType>;
  url?: InputMaybe<Scalars['String']['input']>;
  visibleInFilters?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreatePictureAssetAssignmentInput = {
  assetId: Scalars['ID']['input'];
  pictureId?: InputMaybe<Scalars['ID']['input']>;
  pictureLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreatePictureInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextActor: NextActor;
  number?: InputMaybe<Scalars['Int']['input']>;
  paymentState: PaymentState;
  priorityOrder?: InputMaybe<Scalars['Int']['input']>;
  setId: Scalars['String']['input'];
  state?: InputMaybe<PictureState>;
};

export type MutationCreatePictureRevisionInlineDataObjectAssignmentInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  dataObjectId: Scalars['ID']['input'];
  type: DataObjectAssignmentType;
};

export type MutationCreatePictureRevisionInput = {
  createdById?: InputMaybe<Scalars['ID']['input']>;
  dataObjectAssignment?: InputMaybe<MutationCreatePictureRevisionInlineDataObjectAssignmentInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  pictureId: Scalars['ID']['input'];
};

export type MutationCreatePriceGraphInput = {
  graph: Scalars['Json']['input'];
  organizationId: Scalars['ID']['input'];
  originalIdsFromCatalog: Array<Scalars['String']['input']>;
  state?: InputMaybe<PriceGraphState>;
  templateId: Scalars['ID']['input'];
};

export type MutationCreateProjectInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  number?: InputMaybe<Scalars['Int']['input']>;
  organizationId: Scalars['ID']['input'];
};

export type MutationCreateRenderJobInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  instanceId?: InputMaybe<Scalars['ID']['input']>;
  log?: InputMaybe<Scalars['String']['input']>;
  progress?: InputMaybe<Scalars['Int']['input']>;
  samples?: InputMaybe<Scalars['Int']['input']>;
  started?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<RenderJobState>;
  statusMessage?: InputMaybe<Scalars['String']['input']>;
  templateRevisionId: Scalars['ID']['input'];
};

export type MutationCreateSalesEnquiryInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  contactName: Scalars['String']['input'];
  context?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateScanJobInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<Scalars['DateTime']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  instanceId?: InputMaybe<Scalars['ID']['input']>;
  lastFailedState?: InputMaybe<ScanJobState>;
  progress?: InputMaybe<Scalars['String']['input']>;
  retryCount?: InputMaybe<Scalars['Int']['input']>;
  sampleRotation?: InputMaybe<Scalars['Int']['input']>;
  started?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<ScanJobState>;
  stateStarted?: InputMaybe<Scalars['DateTime']['input']>;
  textureSetId: Scalars['ID']['input'];
};

export type MutationCreateScanSubJobInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<Scalars['DateTime']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  instanceId?: InputMaybe<Scalars['ID']['input']>;
  lastFailedState?: InputMaybe<ScanSubJobState>;
  progress?: InputMaybe<Scalars['String']['input']>;
  retryCount?: InputMaybe<Scalars['Int']['input']>;
  scanJobId: Scalars['ID']['input'];
  started?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<ScanSubJobState>;
  tileX: Scalars['Int']['input'];
  tileY: Scalars['Int']['input'];
};

export type MutationCreateSceneInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  pictureId: Scalars['ID']['input'];
  revisionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  revisions?: InputMaybe<Array<SceneCreateSceneRevisionInlineInput>>;
  statistics?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MutationCreateSceneRevisionInput = {
  created?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  graph?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  sceneId: Scalars['ID']['input'];
};

export type MutationCreateSceneStatisticsInput = {
  created?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  templateLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateSetInput = {
  created?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  number?: InputMaybe<Scalars['Int']['input']>;
  pictureIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectId: Scalars['ID']['input'];
};

export type MutationCreateStorageDeleteTaskInput = {
  created?: InputMaybe<Scalars['DateTime']['input']>;
  gcsBucketName: Scalars['String']['input'];
  gcsObjectName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateTagAssignmentInput = {
  contentTypeModel: ContentTypeModel;
  id?: InputMaybe<Scalars['ID']['input']>;
  objectId: Scalars['ID']['input'];
  tagId: Scalars['ID']['input'];
};

export type MutationCreateTagInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  otherInfo?: InputMaybe<Scalars['String']['input']>;
  tagAssignmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  tagReferenceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  type: TagType;
};

export type MutationCreateTaskCommentInput = {
  createdById?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  taskId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type MutationCreateTaskInput = {
  commentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  contentTypeModel: ContentTypeModel;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  objectId: Scalars['ID']['input'];
  pinIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  state: TaskState;
  title?: InputMaybe<Scalars['String']['input']>;
  type: TaskType;
};

export type MutationCreateTaskPinInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  taskId: Scalars['ID']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type MutationCreateTemplateInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  exSceneId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  pictureId?: InputMaybe<Scalars['ID']['input']>;
  pictureLegacyId?: InputMaybe<Scalars['Int']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  revisionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  revisions?: InputMaybe<Array<TemplateCreateTemplateRevisionInlineInput>>;
  state: TemplateState;
  statistics?: InputMaybe<Array<Scalars['ID']['input']>>;
  templateType: TemplateType;
};

export type MutationCreateTemplateRevisionInput = {
  completed: Scalars['Boolean']['input'];
  created?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  exSceneRevisionId?: InputMaybe<Scalars['Int']['input']>;
  graph?: InputMaybe<Scalars['Json']['input']>;
  templateId: Scalars['ID']['input'];
};

export type MutationCreateTextureEditGroupInput = {
  content?: InputMaybe<Scalars['Json']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  revisionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** @deprecated Prefer to use `revisionIds` instead */
  revisionLegacyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateTextureGroupInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  materialId?: InputMaybe<Scalars['ID']['input']>;
  meshSpecific?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  textureSetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Create texture sets inline. Overrides `textureSetIds`. */
  textureSets?: InputMaybe<Array<TextureSetInlineCreateInput>>;
};

export type MutationCreateTextureInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  revisionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  textureSetId: Scalars['ID']['input'];
  type: TextureType;
};

export type MutationCreateTextureRevisionInput = {
  createdById?: InputMaybe<Scalars['ID']['input']>;
  createdByVersion?: InputMaybe<Scalars['String']['input']>;
  dataObjectId: Scalars['ID']['input'];
  displacement?: InputMaybe<Scalars['Float']['input']>;
  height: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<TextureRevisionState>;
  textureId?: InputMaybe<Scalars['ID']['input']>;
  width: Scalars['Float']['input'];
};

export type MutationCreateTextureSetInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  scanJobId?: InputMaybe<Scalars['ID']['input']>;
  textureGroupId: Scalars['ID']['input'];
  textureIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MutationCreateTextureSetRevisionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  displacement?: InputMaybe<Scalars['Float']['input']>;
  editsJson?: InputMaybe<Scalars['Json']['input']>;
  editsProcessingJobId?: InputMaybe<Scalars['ID']['input']>;
  height: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  mapAssignments: Array<TextureSetRevisionMapAssignmentInput>;
  migratedFromTextureEditGroupId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  textureSetId: Scalars['ID']['input'];
  width: Scalars['Float']['input'];
};

export type MutationCreateUnstartedJobInput = {
  cancelled?: InputMaybe<Scalars['DateTime']['input']>;
  claimedBy?: InputMaybe<Scalars['String']['input']>;
  finished?: InputMaybe<Scalars['DateTime']['input']>;
  graph?: InputMaybe<Scalars['Json']['input']>;
  hashInParent?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  metadataProcessingJobForDataObjectId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated Use `organizationId` instead */
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  output?: InputMaybe<Scalars['Json']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  progress?: InputMaybe<Scalars['Int']['input']>;
  queueGroup?: InputMaybe<Scalars['String']['input']>;
  queueToken?: InputMaybe<Scalars['String']['input']>;
  started?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<JobState>;
  thumbnailProcessingJobForDataObjectId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateUserAssignmentInput = {
  contentTypeModel: ContentTypeModel;
  id?: InputMaybe<Scalars['String']['input']>;
  objectId: Scalars['ID']['input'];
  /** If true, remove all existing user assignments with the same combination of (objectId, contentTypeModel, state) before creating the new one. */
  removeExisting?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<UserAssignmentState>;
  type: UserAssignmentType;
  userId: Scalars['ID']['input'];
};

export type MutationCreateUserInput = {
  dateJoined?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  emailNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  memberships?: InputMaybe<Array<MembershipCreateInput>>;
  password: Scalars['String']['input'];
  systemRole?: InputMaybe<SystemRole>;
  visibleInFilters?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationDeleteDataObjectAssignmentsInput = {
  contentTypeModel: ContentTypeModel;
  objectId: Scalars['ID']['input'];
  type: DataObjectAssignmentType;
};

export type MutationDeleteUserAssignmentsInput = {
  contentTypeModel: ContentTypeModel;
  objectId: Scalars['ID']['input'];
  state: UserAssignmentState;
  type: UserAssignmentType;
};

export type MutationGenerateApiKeyInput = {
  email: Scalars['String']['input'];
  /** Optionally specify a value for the API key. If not specified, a random value will be generated and returned (recommended). */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MutationInviteUserInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  emailNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  memberships?: InputMaybe<Array<MembershipCreateInput>>;
  systemRole?: InputMaybe<SystemRole>;
  visibleInFilters?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationLoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationResetPasswordInput = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationSendPasswordResetEmailInput = {
  email: Scalars['String']['input'];
};

export type MutationUpdateArStatisticsInput = {
  configString?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  sceneRevisionId?: InputMaybe<Scalars['String']['input']>;
  templateRevisionId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateAssetInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  modelId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextActor?: InputMaybe<NextActor>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<AssetState>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateDataObjectAssignmentInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  type?: InputMaybe<DataObjectAssignmentType>;
};

export type MutationUpdateDataObjectInput = {
  bucketName?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageColorProfile?: InputMaybe<Scalars['String']['input']>;
  imageColorSpace?: InputMaybe<ImageColorSpace>;
  imageDataType?: InputMaybe<ImageDataType>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaType?: InputMaybe<Scalars['String']['input']>;
  metadataProcessingJobId?: InputMaybe<Scalars['ID']['input']>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  originalFileName?: InputMaybe<Scalars['String']['input']>;
  processedByVersion?: InputMaybe<Scalars['String']['input']>;
  relatedToId?: InputMaybe<Scalars['ID']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<DataObjectState>;
  thumbnailProcessingJobId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<DataObjectType>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateHdriInput = {
  dataObjectId?: InputMaybe<Scalars['ID']['input']>;
  dataObjectLegacyId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateJobAssignmentInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  contentTypeModel?: InputMaybe<ContentTypeModel>;
  id: Scalars['ID']['input'];
  jobId?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateJobFarmGroupInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationLegacyId?: InputMaybe<Scalars['Int']['input']>;
  otherConfig?: InputMaybe<Scalars['Json']['input']>;
  queueGroup: Scalars['String']['input'];
};

export type MutationUpdateJobFarmInstanceInput = {
  groupId?: InputMaybe<Scalars['ID']['input']>;
  hwFingerprint?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastReport?: InputMaybe<Scalars['DateTime']['input']>;
  otherInfo?: InputMaybe<Scalars['Json']['input']>;
  state?: InputMaybe<JobFarmInstanceState>;
  type?: InputMaybe<JobFarmInstanceType>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateJobInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateJobTaskAssignmentInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  contentTypeModel: ContentTypeModel;
  id: Scalars['ID']['input'];
  jobTaskId?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateJobTaskInput = {
  attemptCount?: InputMaybe<Scalars['Int']['input']>;
  attemptsRemaining?: InputMaybe<Scalars['Int']['input']>;
  cancelled?: InputMaybe<Scalars['DateTime']['input']>;
  finished?: InputMaybe<Scalars['DateTime']['input']>;
  hashInParent?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  instanceId?: InputMaybe<Scalars['String']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  output?: InputMaybe<Scalars['Json']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  progress?: InputMaybe<Scalars['Int']['input']>;
  queueGroup?: InputMaybe<Scalars['String']['input']>;
  queueToken?: InputMaybe<Scalars['String']['input']>;
  started?: InputMaybe<Scalars['DateTime']['input']>;
  taskType?: InputMaybe<Scalars['String']['input']>;
  updateDeadline?: InputMaybe<Scalars['DateTime']['input']>;
  versionCounter?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateJobTaskTypeInput = {
  /** @deprecated No longer used */
  cancelTimeout?: InputMaybe<Scalars['Int']['input']>;
  defaultTimeout?: InputMaybe<Scalars['Int']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  invocationMethod?: InputMaybe<InvocationMethod>;
  maxTimeout?: InputMaybe<Scalars['Int']['input']>;
  queueGroup?: InputMaybe<Scalars['String']['input']>;
  /** The job task's type as a string. Note that this is different from a task's type field (of type `TaskType`) */
  taskType?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateJsonFileAssignmentInput = {
  assignmentKey?: InputMaybe<Scalars['String']['input']>;
  contentTypeModel?: InputMaybe<ContentTypeModel>;
  id: Scalars['ID']['input'];
  jsonFileId?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  type: JsonFileAssignmentType;
};

export type MutationUpdateJsonFileInput = {
  content?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<JsonFileType>;
};

export type MutationUpdateMaterialConnectionInput = {
  created?: InputMaybe<Scalars['DateTime']['input']>;
  destinationId?: InputMaybe<Scalars['String']['input']>;
  destinationParameter?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  materialRevisionId?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['String']['input']>;
  sourceParameter?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateMaterialInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  assetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  internalName?: InputMaybe<Scalars['String']['input']>;
  meshSpecific?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextActor?: InputMaybe<NextActor>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  paymentState?: InputMaybe<PaymentState>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  sampleArrival?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<MaterialState>;
};

export type MutationUpdateMaterialNodeInput = {
  id: Scalars['ID']['input'];
  incomingId?: InputMaybe<Scalars['ID']['input']>;
  materialRevisionId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  outgoingId?: InputMaybe<Scalars['ID']['input']>;
  parameters?: InputMaybe<Scalars['Json']['input']>;
  textureRevisionId?: InputMaybe<Scalars['ID']['input']>;
  textureSetRevisionId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateMaterialOutputByJobInput = {
  jobId: Scalars['ID']['input'];
  materialId: Scalars['ID']['input'];
  resultId: Scalars['ID']['input'];
};

export type MutationUpdateMaterialOutputInput = {
  id: Scalars['ID']['input'];
  resultId: Scalars['ID']['input'];
};

export type MutationUpdateMaterialReferenceInput = {
  id: Scalars['ID']['input'];
  materialRevisionId?: InputMaybe<Scalars['ID']['input']>;
  templateRevisionId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateMaterialRevisionInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  connectionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  graphSchema?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  matFileDataObjectId?: InputMaybe<Scalars['ID']['input']>;
  materialId?: InputMaybe<Scalars['ID']['input']>;
  materialReferenceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  nodeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  number?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateModelInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  internalName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextActor?: InputMaybe<NextActor>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  paymentState?: InputMaybe<PaymentState>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<ModelState>;
  type?: InputMaybe<ModelType>;
};

export type MutationUpdateOrganizationInput = {
  defaultScannerUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  matExplorerMaterialId?: InputMaybe<Scalars['Int']['input']>;
  matExplorerTemplateId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nodeEditorTemplateId?: InputMaybe<Scalars['Int']['input']>;
  salesEmail?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<OrganizationType>;
  url?: InputMaybe<Scalars['String']['input']>;
  visibleInFilters?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdatePictureInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  nextActor?: InputMaybe<NextActor>;
  number?: InputMaybe<Scalars['Int']['input']>;
  paymentState?: InputMaybe<PaymentState>;
  priorityOrder?: InputMaybe<Scalars['Int']['input']>;
  regenerateNumber?: InputMaybe<Scalars['Boolean']['input']>;
  setId?: InputMaybe<Scalars['ID']['input']>;
  setLegacyId?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<PictureState>;
};

export type MutationUpdatePictureRevisionInput = {
  id: Scalars['ID']['input'];
  number?: InputMaybe<Scalars['Int']['input']>;
  pictureId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdatePriceGraphInput = {
  conflicts?: InputMaybe<Scalars['String']['input']>;
  graph?: InputMaybe<Scalars['Json']['input']>;
  organizationId: Scalars['ID']['input'];
  originalIdsFromCatalog: Array<Scalars['String']['input']>;
  priceGraphId: Scalars['ID']['input'];
  revisionNumber?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<PriceGraphState>;
};

export type MutationUpdateProjectInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateRenderJobInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  instanceId?: InputMaybe<Scalars['ID']['input']>;
  log?: InputMaybe<Scalars['String']['input']>;
  progress?: InputMaybe<Scalars['Int']['input']>;
  samples?: InputMaybe<Scalars['Int']['input']>;
  sceneRevisionId?: InputMaybe<Scalars['ID']['input']>;
  started?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<RenderJobState>;
  statusMessage?: InputMaybe<Scalars['String']['input']>;
  templateRevisionId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateScanJobInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  instanceId?: InputMaybe<Scalars['ID']['input']>;
  progress?: InputMaybe<Scalars['String']['input']>;
  sampleRotation?: InputMaybe<Scalars['Int']['input']>;
  started?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<ScanJobState>;
  stateStarted?: InputMaybe<Scalars['DateTime']['input']>;
  textureSetId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateScanSubJobInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<Scalars['DateTime']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  instanceId?: InputMaybe<Scalars['ID']['input']>;
  progress?: InputMaybe<Scalars['String']['input']>;
  scanJobId?: InputMaybe<Scalars['ID']['input']>;
  started?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<ScanSubJobState>;
  tileX?: InputMaybe<Scalars['Int']['input']>;
  tileY?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateSceneInput = {
  id: Scalars['String']['input'];
  pictureId?: InputMaybe<Scalars['String']['input']>;
  revisionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  statistics?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MutationUpdateSceneRevisionInput = {
  graph?: InputMaybe<Scalars['Json']['input']>;
  id: Scalars['ID']['input'];
  sceneId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateSceneStatisticsInput = {
  created?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  sceneId?: InputMaybe<Scalars['ID']['input']>;
  sceneLegacyId?: InputMaybe<Scalars['Int']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  templateLegacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateSetInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  pictureIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateStorageDeleteTaskInput = {
  created?: InputMaybe<Scalars['DateTime']['input']>;
  gcsBucketName?: InputMaybe<Scalars['String']['input']>;
  gcsObjectName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationUpdateTagAssignmentInput = {
  id: Scalars['ID']['input'];
  tagId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateTagInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  otherInfo?: InputMaybe<Scalars['String']['input']>;
  tagAssignmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  tagReferenceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  type?: InputMaybe<TagType>;
};

export type MutationUpdateTaskCommentInput = {
  id: Scalars['ID']['input'];
  taskId?: InputMaybe<Scalars['ID']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateTaskInput = {
  commentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  number?: InputMaybe<Scalars['Int']['input']>;
  pinIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<TaskState>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TaskType>;
};

export type MutationUpdateTaskPinInput = {
  id: Scalars['ID']['input'];
  taskId?: InputMaybe<Scalars['ID']['input']>;
  x?: InputMaybe<Scalars['Float']['input']>;
  y?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationUpdateTemplateInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  createdById?: InputMaybe<Scalars['String']['input']>;
  exSceneId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  pictureId?: InputMaybe<Scalars['String']['input']>;
  pictureLegacyId?: InputMaybe<Scalars['Int']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<TemplateState>;
  statistics?: InputMaybe<Array<Scalars['ID']['input']>>;
  templateType?: InputMaybe<TemplateType>;
};

export type MutationUpdateTemplateRevisionInput = {
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  graph?: InputMaybe<Scalars['Json']['input']>;
  id: Scalars['ID']['input'];
  number?: InputMaybe<Scalars['Int']['input']>;
  templateId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateTextureEditGroupInput = {
  content?: InputMaybe<Scalars['Json']['input']>;
  id: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateTextureGroupInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  materialId?: InputMaybe<Scalars['ID']['input']>;
  meshSpecific?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  textureSetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MutationUpdateTextureInput = {
  id: Scalars['ID']['input'];
  revisionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  textureSetId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<TextureType>;
};

export type MutationUpdateTextureRevisionInput = {
  createdByVersion?: InputMaybe<Scalars['String']['input']>;
  dataObjectId?: InputMaybe<Scalars['ID']['input']>;
  displacement?: InputMaybe<Scalars['Float']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  state?: InputMaybe<TextureRevisionState>;
  textureId?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationUpdateTextureSetInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  scanJobId?: InputMaybe<Scalars['ID']['input']>;
  textureGroupId?: InputMaybe<Scalars['ID']['input']>;
  textureIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MutationUpdateTextureSetRevisionInput = {
  autoRescaleSize?: InputMaybe<Scalars['Boolean']['input']>;
  displacement?: InputMaybe<Scalars['Float']['input']>;
  editsJson?: InputMaybe<Scalars['Json']['input']>;
  editsProcessingJobId?: InputMaybe<Scalars['ID']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  mapAssignments?: InputMaybe<Array<TextureSetRevisionMapAssignmentInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['ID']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationUpdateUserAssignmentInput = {
  id: Scalars['String']['input'];
  state?: InputMaybe<UserAssignmentState>;
  type?: InputMaybe<UserAssignmentType>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateUserInput = {
  dateJoined?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isSuperuser?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  systemRole?: InputMaybe<SystemRole>;
  visibleInFilters?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationVerifyExternalLoginInput = {
  code: Scalars['String']['input'];
  /** External identity provider. Use either Google or Microsoft. */
  provider: LoginProvider;
};

export enum NextActor {
  Customer = 'Customer',
  ProjectManager = 'ProjectManager',
  Team1 = 'Team1',
  Team2 = 'Team2'
}

export type Organization = {
  __typename: 'Organization';
  assets: Array<Asset>;
  assetsCount: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  dataObjects: Array<DataObject>;
  defaultScannerUrl?: Maybe<Scalars['String']['output']>;
  font?: Maybe<DataObject>;
  fontBold?: Maybe<DataObject>;
  galleryImage?: Maybe<DataObject>;
  hdris: Array<Hdri>;
  id: Scalars['ID']['output'];
  jobFarmGroups: Array<JobFarmGroup>;
  jobFarmGroupsCount: Scalars['Int']['output'];
  jobTasks: Array<JobTask>;
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  jsonFiles: Array<JsonFile>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  matExplorerMaterial?: Maybe<Material>;
  matExplorerTemplate?: Maybe<Template>;
  materials: Array<Material>;
  members: Array<Membership>;
  membersCount: Scalars['Int']['output'];
  models: Array<Model>;
  name?: Maybe<Scalars['String']['output']>;
  nodeEditorTemplate?: Maybe<Template>;
  pdfTemplate?: Maybe<DataObject>;
  projects: Array<Project>;
  projectsCount: Scalars['Int']['output'];
  salesEmail?: Maybe<Scalars['String']['output']>;
  salesEnquiries: Array<SalesEnquiry>;
  salesEnquiriesCount: Scalars['Int']['output'];
  tags: Array<Tag>;
  tagsCount: Scalars['Int']['output'];
  templates: Array<Template>;
  templatesCount: Scalars['Int']['output'];
  textureGroups: Array<TextureGroup>;
  textureGroupsCount: Scalars['Int']['output'];
  type?: Maybe<OrganizationType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  url?: Maybe<Scalars['String']['output']>;
  visibleInFilters?: Maybe<Scalars['Boolean']['output']>;
};


export type OrganizationDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type OrganizationProjectsArgs = {
  orderBy?: InputMaybe<Array<ProjectOrderByInput>>;
};

export type OrganizationFilterInput = {
  defaultScannerUrl?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntFilterInput>;
  matExplorerMaterialId?: InputMaybe<IntFilterInput>;
  matExplorerTemplateId?: InputMaybe<IntFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  nodeEditorTemplateId?: InputMaybe<IntFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<StringFilterInput>;
  visibleInFilters?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum OrganizationOrderByCriteria {
  Created = 'created',
  Id = 'id',
  Name = 'name',
  VisibleInFilters = 'visibleInFilters'
}

export type OrganizationOrderByInput = {
  direction: SortOrder;
  key: OrganizationOrderByCriteria;
};

export enum OrganizationType {
  FabricManufacturer = 'FabricManufacturer',
  Photographer = 'Photographer'
}

export type Package = {
  __typename: 'Package';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  membership: Membership;
  type: PackageType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  validUntil?: Maybe<Scalars['DateTime']['output']>;
};

export enum PackageType {
  Configurator = 'Configurator',
  Exports = 'Exports',
  ProjectManagement = 'ProjectManagement',
  Rendering = 'Rendering'
}

export type Partnership = {
  __typename: 'Partnership';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  material: Material;
  model: Model;
  objectId: Scalars['ID']['output'];
  objectType: PartnershipSubject;
  organization: Organization;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  user: User;
};

export enum PartnershipSubject {
  Material = 'Material',
  Model = 'Model',
  Organization = 'Organization'
}

export enum PaymentState {
  InvoiceDrafted = 'InvoiceDrafted',
  InvoiceIssued = 'InvoiceIssued',
  OrderPlaced = 'OrderPlaced',
  Paid = 'Paid'
}

export type Picture = {
  __typename: 'Picture';
  assetAssignments: Array<PictureAssetAssignment>;
  assetInfos: PictureAssetInfos;
  /** Assets assigned to this picture via a PictureAssetAssignment. This is a convenience field that is equivalent to `assetAssignments.asset`. */
  assets: Array<Asset>;
  assetsCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  currentUserAssignment?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  downloadUrl: Scalars['String']['output'];
  galleryImage?: Maybe<DataObject>;
  id: Scalars['ID']['output'];
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  latestRevision?: Maybe<PictureRevision>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  nextActor: NextActor;
  number: Scalars['Int']['output'];
  organization: Organization;
  paymentState: PaymentState;
  priorityOrder: Scalars['GraphQLBigInt']['output'];
  revisions: Array<PictureRevision>;
  scene?: Maybe<Scene>;
  set: Set;
  shareAssignments: Array<ShareAssignment>;
  shareAssignmentsCount: Scalars['Int']['output'];
  state: PictureState;
  tagAssignments: Array<TagAssignment>;
  tagAssignmentsCount: Scalars['Int']['output'];
  taskInfos: TaskInfos;
  tasks: Array<Task>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  userAssignments: Array<UserAssignment>;
  userAssignmentsCount: Scalars['Int']['output'];
};


export type PictureDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type PictureDownloadUrlArgs = {
  fileType?: InputMaybe<DownloadFileType>;
  resolution?: InputMaybe<DownloadResolution>;
};


export type PictureJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type PictureRevisionsArgs = {
  orderBy?: InputMaybe<Array<PictureRevisionOrderByInput>>;
};


export type PictureShareAssignmentsArgs = {
  filter?: InputMaybe<ShareAssignmentFilterInput>;
  orderBy?: InputMaybe<ShareAssignmentOrderByInput>;
};


export type PictureTagAssignmentsArgs = {
  tagTypes?: InputMaybe<Array<TagType>>;
};


export type PictureUserAssignmentsArgs = {
  filter?: InputMaybe<UserAssignmentFilterInput>;
};

export type PictureAssetAssignment = {
  __typename: 'PictureAssetAssignment';
  asset: Asset;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  picture: Picture;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type PictureAssetAssignmentFilterInput = {
  asset?: InputMaybe<AssetFilterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  picture?: InputMaybe<PictureFilterInput>;
};

export type PictureAssetAssignmentHistory = {
  __typename: 'PictureAssetAssignmentHistory';
  asset?: Maybe<Asset>;
  assignment?: Maybe<PictureAssetAssignment>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  picture?: Maybe<Picture>;
};

export type PictureAssetAssignmentHistoryFilterInput = {
  assetId?: InputMaybe<Scalars['ID']['input']>;
  assignmentId?: InputMaybe<IdInFilter>;
  historyChangeReason?: InputMaybe<StringFilterInput>;
  historyDate?: InputMaybe<DateTimeFilterInput>;
  historyType?: InputMaybe<Array<HistoryType>>;
  id?: InputMaybe<IdInFilter>;
  pictureId?: InputMaybe<Scalars['ID']['input']>;
};

export enum PictureAssetAssignmentHistoryOrderByCriteria {
  ArticleId = 'articleId',
  HistoryDate = 'history_date',
  Id = 'id',
  Name = 'name',
  NextActor = 'nextActor',
  PaymentState = 'paymentState',
  PriorityOrder = 'priorityOrder',
  Public = 'public',
  State = 'state'
}

export type PictureAssetAssignmentHistoryOrderByInput = {
  direction: SortOrder;
  key: PictureAssetAssignmentHistoryOrderByCriteria;
};

export type PictureAssetInfos = {
  __typename: 'PictureAssetInfos';
  completed: Scalars['Int']['output'];
  new: Scalars['Int']['output'];
  readyForShading: Scalars['Int']['output'];
  review: Scalars['Int']['output'];
  shading: Scalars['Int']['output'];
};

export type PictureFilterInput = {
  assetId?: InputMaybe<IdInFilter>;
  assetLegacyId?: InputMaybe<IntInFilter>;
  assetsCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssignedUser?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntFilterInput>;
  nextActor?: InputMaybe<Array<NextActor>>;
  organizationId?: InputMaybe<IdInFilter>;
  organizationLegacyId?: InputMaybe<IntInFilter>;
  organizationUrl?: InputMaybe<StringFilterInput>;
  paymentState?: InputMaybe<Array<PaymentState>>;
  projectId?: InputMaybe<IdInFilter>;
  projectLegacyId?: InputMaybe<IntFilterInput>;
  projectNumber?: InputMaybe<IntInFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  setId?: InputMaybe<IdInFilter>;
  setLegacyId?: InputMaybe<IntFilterInput>;
  setNumber?: InputMaybe<IntInFilter>;
  state?: InputMaybe<Array<PictureState>>;
  tagId?: InputMaybe<IdInFilter>;
  tagLegacyId?: InputMaybe<IntInFilter>;
  userId?: InputMaybe<IdInFilter>;
};

export type PictureHistory = {
  __typename: 'PictureHistory';
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  historyChangeReason?: Maybe<Scalars['String']['output']>;
  historyDate?: Maybe<Scalars['DateTime']['output']>;
  historyType: HistoryType;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  nextActor: NextActor;
  number: Scalars['Int']['output'];
  paymentState: PaymentState;
  picture?: Maybe<Picture>;
  priorityOrder: Scalars['GraphQLBigInt']['output'];
  set?: Maybe<Set>;
  state: PictureState;
};

export type PictureHistoryFilterInput = {
  description?: InputMaybe<StringFilterInput>;
  historyChangeReason?: InputMaybe<StringFilterInput>;
  historyDate?: InputMaybe<DateTimeFilterInput>;
  historyType?: InputMaybe<Array<HistoryType>>;
  id?: InputMaybe<IdInFilter>;
  name?: InputMaybe<StringFilterInput>;
  nextActor?: InputMaybe<Array<NextActor>>;
  number?: InputMaybe<IntFilterInput>;
  paymentState?: InputMaybe<Array<PaymentState>>;
  pictureId?: InputMaybe<IdInFilter>;
  priorityOrder?: InputMaybe<IntFilterInput>;
  setId?: InputMaybe<IdInFilter>;
  state?: InputMaybe<Array<PictureState>>;
  userId?: InputMaybe<IdInFilter>;
};

export enum PictureHistoryOrderByCriteria {
  ArticleId = 'articleId',
  HistoryDate = 'history_date',
  Id = 'id',
  Name = 'name',
  NextActor = 'nextActor',
  PaymentState = 'paymentState',
  PriorityOrder = 'priorityOrder',
  Public = 'public',
  State = 'state'
}

export type PictureHistoryOrderByInput = {
  direction: SortOrder;
  key: PictureHistoryOrderByCriteria;
};

export enum PictureOrderByCriteria {
  ArticleId = 'articleId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  NextActor = 'nextActor',
  Number = 'number',
  PaymentState = 'paymentState',
  PriorityOrder = 'priorityOrder',
  Public = 'public',
  SetNumber = 'setNumber',
  State = 'state'
}

export type PictureOrderByInput = {
  direction: SortOrder;
  key: PictureOrderByCriteria;
};

export type PictureRevision = {
  __typename: 'PictureRevision';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  number: Scalars['Int']['output'];
  picture: Picture;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type PictureRevisionDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type PictureRevisionJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type PictureRevisionFilterInput = {
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<IdInFilter>;
  id?: InputMaybe<IdInFilter>;
  number?: InputMaybe<IntFilterInput>;
  pictureId?: InputMaybe<IdInFilter>;
};

export enum PictureRevisionOrderByCriteria {
  Id = 'id',
  Number = 'number',
  PictureId = 'picture_id'
}

export type PictureRevisionOrderByInput = {
  direction: SortOrder;
  key: PictureRevisionOrderByCriteria;
};

export enum PictureState {
  Completed = 'Completed',
  Draft = 'Draft',
  FinalRender = 'FinalRender',
  InfoReview = 'InfoReview',
  OnHold = 'OnHold',
  Review = 'Review',
  Stage1 = 'Stage1',
  Stage2 = 'Stage2'
}

export type Price = {
  __typename: 'Price';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  currency: Currency;
  description?: Maybe<Scalars['String']['output']>;
  idFromCatalog: IdFromCatalog;
  price: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type PriceFilterInput = {
  organizationId: IdInFilter;
  originalId?: InputMaybe<IdInFilter>;
};

export type PriceGraph = {
  __typename: 'PriceGraph';
  conflicts?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  graph: Scalars['Json']['output'];
  id: Scalars['ID']['output'];
  revisionNumber: Scalars['Int']['output'];
  state: PriceGraphState;
  templateId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type PriceGraphFilterInput = {
  state?: InputMaybe<PriceGraphState>;
  templateId?: InputMaybe<IdInFilter>;
};

export enum PriceGraphOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  RevisionNumber = 'revisionNumber'
}

export type PriceGraphOrderByInput = {
  direction: SortOrder;
  key: PriceGraphOrderByCriteria;
};

export enum PriceGraphState {
  Draft = 'Draft',
  Invalid = 'Invalid',
  Ready = 'Ready'
}

export type PriceInput = {
  currency: Currency;
  description?: InputMaybe<Scalars['String']['input']>;
  disambiguator?: InputMaybe<Scalars['String']['input']>;
  originalId: Scalars['ID']['input'];
  price: Scalars['Float']['input'];
};

export enum PriceOrderByCriteria {
  CreatedAt = 'createdAt'
}

export type PriceOrderByInput = {
  direction: SortOrder;
  key: PriceOrderByCriteria;
};

export type Project = {
  __typename: 'Project';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  organization: Organization;
  sets: Array<Set>;
  setsCount: Scalars['Int']['output'];
  shareAssignments: Array<ShareAssignment>;
  shareAssignmentsCount: Scalars['Int']['output'];
  tagAssignments: Array<TagAssignment>;
  tagAssignmentsCount: Scalars['Int']['output'];
  taskInfos: TaskInfos;
  tasks: Array<Task>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type ProjectDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type ProjectSetsArgs = {
  orderBy?: InputMaybe<Array<SetOrderByInput>>;
};


export type ProjectShareAssignmentsArgs = {
  filter?: InputMaybe<ShareAssignmentFilterInput>;
  orderBy?: InputMaybe<ShareAssignmentOrderByInput>;
};


export type ProjectTagAssignmentsArgs = {
  tagTypes?: InputMaybe<Array<TagType>>;
};

export type ProjectFilterInput = {
  id?: InputMaybe<IdInFilter>;
  name?: InputMaybe<StringFilterInput>;
  number?: InputMaybe<IntFilterInput>;
  organizationId?: InputMaybe<IdInFilter>;
  setIds?: InputMaybe<IdInFilter>;
};

export enum ProjectOrderByCriteria {
  ArticleId = 'articleId',
  Id = 'id',
  LegacyId = 'legacyId',
  Name = 'name',
  NextActor = 'nextActor',
  Number = 'number',
  PaymentState = 'paymentState',
  PriorityOrder = 'priorityOrder',
  Public = 'public',
  State = 'state'
}

export type ProjectOrderByInput = {
  direction: SortOrder;
  key: ProjectOrderByCriteria;
};

export type Query = {
  __typename: 'Query';
  arStatisticsItem: ArStatistics;
  arStatisticsItems: Array<Maybe<ArStatistics>>;
  arStatisticsItemsCount: Scalars['Int']['output'];
  asset: Asset;
  assetHistoryItem: AssetHistory;
  assetHistoryItems: Array<Maybe<AssetHistory>>;
  assetHistoryItemsCount: Scalars['Int']['output'];
  assets: Array<Maybe<Asset>>;
  assetsCount: Scalars['Int']['output'];
  currentUser?: Maybe<User>;
  dataObject: DataObject;
  dataObjectAssignment: DataObjectAssignment;
  dataObjectAssignments: Array<Maybe<DataObjectAssignment>>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  dataObjects: Array<Maybe<DataObject>>;
  dataObjectsCount: Scalars['Int']['output'];
  hdri: Hdri;
  hdris: Array<Maybe<Hdri>>;
  hdrisCount: Scalars['Int']['output'];
  idFromCatalogToPriceGraphItems: Array<Maybe<IdFromCatalogToPriceGraph>>;
  idFromCatalogToPriceGraphItemsCount: Scalars['Int']['output'];
  job: Job;
  jobAssignment: JobAssignment;
  jobAssignments: Array<Maybe<JobAssignment>>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jobFarmGroup: JobFarmGroup;
  jobFarmGroups: Array<Maybe<JobFarmGroup>>;
  jobFarmGroupsCount: Scalars['Int']['output'];
  jobFarmInstance: JobFarmInstance;
  jobFarmInstances: Array<Maybe<JobFarmInstance>>;
  jobFarmInstancesCount: Scalars['Int']['output'];
  jobTask: JobTask;
  jobTaskAssignment: JobTaskAssignment;
  jobTaskAssignments: Array<Maybe<JobTaskAssignment>>;
  jobTaskAssignmentsCount: Scalars['Int']['output'];
  jobTaskType: JobTaskType;
  jobTaskTypes: Array<Maybe<JobTaskType>>;
  jobTaskTypesCount: Scalars['Int']['output'];
  jobTasks: Array<Maybe<JobTask>>;
  jobTasksCount: Scalars['Int']['output'];
  jobs: Array<Maybe<Job>>;
  jobsCount: Scalars['Int']['output'];
  jsonFile: JsonFile;
  jsonFileAssignment: JsonFileAssignment;
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFiles: Array<Maybe<JsonFile>>;
  jsonFilesCount: Scalars['Int']['output'];
  material: Material;
  materialAssignmentHistoryItem: MaterialAssignmentHistory;
  materialAssignmentHistoryItems: Array<Maybe<MaterialAssignmentHistory>>;
  materialAssignmentHistoryItemsCount: Scalars['Int']['output'];
  materialConnection: MaterialConnection;
  materialConnections: Array<Maybe<MaterialConnection>>;
  materialConnectionsCount: Scalars['Int']['output'];
  materialHistoryItem: MaterialHistory;
  materialHistoryItems: Array<Maybe<MaterialHistory>>;
  materialHistoryItemsCount: Scalars['Int']['output'];
  materialNode: MaterialNode;
  materialNodes: Array<Maybe<MaterialNode>>;
  materialNodesCount: Scalars['Int']['output'];
  materialRevision: MaterialRevision;
  materialRevisions: Array<Maybe<MaterialRevision>>;
  materialRevisionsCount: Scalars['Int']['output'];
  materials: Array<Maybe<Material>>;
  materialsCount: Scalars['Int']['output'];
  model: Model;
  modelHistoryItem: ModelHistory;
  modelHistoryItems: Array<Maybe<ModelHistory>>;
  modelHistoryItemsCount: Scalars['Int']['output'];
  models: Array<Maybe<Model>>;
  modelsCount: Scalars['Int']['output'];
  organization: Organization;
  organizations: Array<Maybe<Organization>>;
  organizationsCount: Scalars['Int']['output'];
  picture: Picture;
  pictureAssetAssignmentHistoryItem: PictureAssetAssignmentHistory;
  pictureAssetAssignmentHistoryItems: Array<Maybe<PictureAssetAssignmentHistory>>;
  pictureAssetAssignmentHistoryItemsCount: Scalars['Int']['output'];
  pictureHistoryItem: PictureHistory;
  pictureHistoryItems: Array<Maybe<PictureHistory>>;
  pictureHistoryItemsCount: Scalars['Int']['output'];
  pictureRevision: PictureRevision;
  pictureRevisions: Array<Maybe<PictureRevision>>;
  pictureRevisionsCount: Scalars['Int']['output'];
  pictures: Array<Maybe<Picture>>;
  picturesCount: Scalars['Int']['output'];
  price: Price;
  priceGraph: PriceGraph;
  priceGraphs: Array<Maybe<PriceGraph>>;
  priceGraphsCount: Scalars['Int']['output'];
  prices: Array<Maybe<Price>>;
  pricesCount: Scalars['Int']['output'];
  project: Project;
  projects: Array<Maybe<Project>>;
  projectsCount: Scalars['Int']['output'];
  renderJob: RenderJob;
  renderJobs: Array<Maybe<RenderJob>>;
  renderJobsCount: Scalars['Int']['output'];
  scanJob: ScanJob;
  scanJobs: Array<Maybe<ScanJob>>;
  scanJobsCount: Scalars['Int']['output'];
  scanSubJob: ScanSubJob;
  scanSubJobs: Array<Maybe<ScanSubJob>>;
  scanSubJobsCount: Scalars['Int']['output'];
  scene: Scene;
  sceneRevision: SceneRevision;
  sceneRevisions: Array<Maybe<SceneRevision>>;
  sceneRevisionsCount: Scalars['Int']['output'];
  sceneStatisticsItem: SceneStatistics;
  sceneStatisticsItems: Array<Maybe<SceneStatistics>>;
  sceneStatisticsItemsCount: Scalars['Int']['output'];
  scenes: Array<Maybe<Scene>>;
  scenesCount: Scalars['Int']['output'];
  set: Set;
  sets: Array<Maybe<Set>>;
  setsCount: Scalars['Int']['output'];
  storageDeleteTask: StorageDeleteTask;
  storageDeleteTasks: Array<Maybe<StorageDeleteTask>>;
  storageDeleteTasksCount: Scalars['Int']['output'];
  tag: Tag;
  tagAssignment: TagAssignment;
  tagAssignments: Array<Maybe<TagAssignment>>;
  tagAssignmentsCount: Scalars['Int']['output'];
  tags: Array<Maybe<Tag>>;
  tagsCount: Scalars['Int']['output'];
  task: Task;
  taskComment: TaskComment;
  taskComments: Array<Maybe<TaskComment>>;
  taskCommentsCount: Scalars['Int']['output'];
  taskPin: TaskPin;
  taskPins: Array<Maybe<TaskPin>>;
  taskPinsCount: Scalars['Int']['output'];
  tasks: Array<Maybe<Task>>;
  tasksCount: Scalars['Int']['output'];
  template: Template;
  templateRevision: TemplateRevision;
  templateRevisions: Array<Maybe<TemplateRevision>>;
  templateRevisionsCount: Scalars['Int']['output'];
  templates: Array<Maybe<Template>>;
  templatesCount: Scalars['Int']['output'];
  texture: Texture;
  textureEditGroup: TextureEditGroup;
  textureEditGroups: Array<Maybe<TextureEditGroup>>;
  textureEditGroupsCount: Scalars['Int']['output'];
  textureGroup: TextureGroup;
  textureGroups: Array<Maybe<TextureGroup>>;
  textureGroupsCount: Scalars['Int']['output'];
  textureRevision: TextureRevision;
  textureRevisions: Array<Maybe<TextureRevision>>;
  textureRevisionsCount: Scalars['Int']['output'];
  textureSet: TextureSet;
  textureSetRevision: TextureSetRevision;
  textureSetRevisions: Array<Maybe<TextureSetRevision>>;
  textureSetRevisionsCount: Scalars['Int']['output'];
  textureSets: Array<Maybe<TextureSet>>;
  textureSetsCount: Scalars['Int']['output'];
  textures: Array<Maybe<Texture>>;
  texturesCount: Scalars['Int']['output'];
  user: User;
  userAssignment: UserAssignment;
  userAssignmentHistoryItem: UserAssignmentHistory;
  userAssignmentHistoryItems: Array<Maybe<UserAssignmentHistory>>;
  userAssignmentHistoryItemsCount: Scalars['Int']['output'];
  userAssignments: Array<Maybe<UserAssignment>>;
  userAssignmentsCount: Scalars['Int']['output'];
  users: Array<Maybe<User>>;
  usersCount: Scalars['Int']['output'];
};


export type QueryArStatisticsItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryArStatisticsItemsArgs = {
  filter?: InputMaybe<ArStatisticsFilterInput>;
  orderBy?: InputMaybe<Array<ArStatisticsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryArStatisticsItemsCountArgs = {
  filter?: InputMaybe<ArStatisticsFilterInput>;
};


export type QueryAssetArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAssetHistoryItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAssetHistoryItemsArgs = {
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AssetHistoryFilterInput>;
  orderBy?: InputMaybe<Array<AssetHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAssetHistoryItemsCountArgs = {
  filter?: InputMaybe<AssetHistoryFilterInput>;
};


export type QueryAssetsArgs = {
  filter?: InputMaybe<AssetFilterInput>;
  orderBy?: InputMaybe<Array<AssetOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAssetsCountArgs = {
  filter?: InputMaybe<AssetFilterInput>;
};


export type QueryDataObjectArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDataObjectAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<Array<DataObjectAssignmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDataObjectAssignmentsCountArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
};


export type QueryDataObjectsArgs = {
  filter?: InputMaybe<DataObjectFilterInput>;
  orderBy?: InputMaybe<Array<DataObjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDataObjectsCountArgs = {
  filter?: InputMaybe<DataObjectFilterInput>;
};


export type QueryHdriArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHdrisArgs = {
  filter?: InputMaybe<HdriFilterInput>;
  orderBy?: InputMaybe<Array<HdriOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHdrisCountArgs = {
  filter?: InputMaybe<HdriFilterInput>;
};


export type QueryIdFromCatalogToPriceGraphItemsArgs = {
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IdFromCatalogToPriceGraphFilterInput>;
  orderBy?: InputMaybe<Array<IdFromCatalogToPriceGraphOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryIdFromCatalogToPriceGraphItemsCountArgs = {
  filter?: InputMaybe<IdFromCatalogToPriceGraphFilterInput>;
};


export type QueryJobArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobAssignmentsArgs = {
  filter?: InputMaybe<JobAssignmentFilterInput>;
  orderBy?: InputMaybe<Array<JobAssignmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobAssignmentsCountArgs = {
  filter?: InputMaybe<JobAssignmentFilterInput>;
};


export type QueryJobFarmGroupArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobFarmGroupsArgs = {
  filter?: InputMaybe<JobFarmGroupFilterInput>;
  orderBy?: InputMaybe<Array<JobFarmGroupOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobFarmGroupsCountArgs = {
  filter?: InputMaybe<JobFarmGroupFilterInput>;
};


export type QueryJobFarmInstanceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobFarmInstancesArgs = {
  filter?: InputMaybe<JobFarmInstanceFilterInput>;
  orderBy?: InputMaybe<Array<JobFarmInstanceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobFarmInstancesCountArgs = {
  filter?: InputMaybe<JobFarmInstanceFilterInput>;
};


export type QueryJobTaskArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobTaskAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobTaskAssignmentsArgs = {
  filter?: InputMaybe<JobTaskAssignmentFilterInput>;
  orderBy?: InputMaybe<Array<JobTaskAssignmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobTaskAssignmentsCountArgs = {
  filter?: InputMaybe<JobTaskAssignmentFilterInput>;
};


export type QueryJobTaskTypeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobTaskTypesArgs = {
  filter?: InputMaybe<JobTaskTypeFilterInput>;
  orderBy?: InputMaybe<Array<JobTaskTypeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobTaskTypesCountArgs = {
  filter?: InputMaybe<JobTaskTypeFilterInput>;
};


export type QueryJobTasksArgs = {
  filter?: InputMaybe<JobTaskFilterInput>;
  orderBy?: InputMaybe<Array<JobTaskOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobTasksCountArgs = {
  filter?: InputMaybe<JobTaskFilterInput>;
};


export type QueryJobsArgs = {
  filter?: InputMaybe<JobFilterInput>;
  orderBy?: InputMaybe<Array<JobOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobsCountArgs = {
  filter?: InputMaybe<JobFilterInput>;
};


export type QueryJsonFileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJsonFileAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
};


export type QueryJsonFilesArgs = {
  filter?: InputMaybe<JsonFileFilterInput>;
  orderBy?: InputMaybe<Array<JsonFileOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJsonFilesCountArgs = {
  filter?: InputMaybe<JsonFileFilterInput>;
};


export type QueryMaterialArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialAssignmentHistoryItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialAssignmentHistoryItemsArgs = {
  filter?: InputMaybe<MaterialAssignmentHistoryFilterInput>;
  orderBy?: InputMaybe<Array<MaterialAssignmentHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialAssignmentHistoryItemsCountArgs = {
  filter?: InputMaybe<MaterialAssignmentHistoryFilterInput>;
};


export type QueryMaterialConnectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialConnectionsArgs = {
  filter?: InputMaybe<MaterialConnectionFilterInput>;
  orderBy?: InputMaybe<Array<MaterialConnectionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialConnectionsCountArgs = {
  filter?: InputMaybe<MaterialConnectionFilterInput>;
};


export type QueryMaterialHistoryItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialHistoryItemsArgs = {
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<MaterialHistoryFilterInput>;
  orderBy?: InputMaybe<Array<MaterialHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialHistoryItemsCountArgs = {
  filter?: InputMaybe<MaterialHistoryFilterInput>;
};


export type QueryMaterialNodeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialNodesArgs = {
  filter?: InputMaybe<MaterialNodeFilterInput>;
  orderBy?: InputMaybe<Array<MaterialNodeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialNodesCountArgs = {
  filter?: InputMaybe<MaterialNodeFilterInput>;
};


export type QueryMaterialRevisionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialRevisionsArgs = {
  filter?: InputMaybe<MaterialRevisionFilterInput>;
  orderBy?: InputMaybe<Array<MaterialRevisionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialRevisionsCountArgs = {
  filter?: InputMaybe<MaterialRevisionFilterInput>;
};


export type QueryMaterialsArgs = {
  filter?: InputMaybe<MaterialFilterInput>;
  orderBy?: InputMaybe<Array<MaterialOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMaterialsCountArgs = {
  filter?: InputMaybe<MaterialFilterInput>;
};


export type QueryModelArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryModelHistoryItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryModelHistoryItemsArgs = {
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ModelHistoryFilterInput>;
  orderBy?: InputMaybe<Array<ModelHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryModelHistoryItemsCountArgs = {
  filter?: InputMaybe<ModelHistoryFilterInput>;
};


export type QueryModelsArgs = {
  filter?: InputMaybe<ModelFilterInput>;
  orderBy?: InputMaybe<Array<ModelOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryModelsCountArgs = {
  filter?: InputMaybe<ModelFilterInput>;
};


export type QueryOrganizationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrganizationsArgs = {
  filter?: InputMaybe<OrganizationFilterInput>;
  orderBy?: InputMaybe<Array<OrganizationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrganizationsCountArgs = {
  filter?: InputMaybe<OrganizationFilterInput>;
};


export type QueryPictureArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPictureAssetAssignmentHistoryItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPictureAssetAssignmentHistoryItemsArgs = {
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PictureAssetAssignmentHistoryFilterInput>;
  orderBy?: InputMaybe<Array<PictureAssetAssignmentHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPictureAssetAssignmentHistoryItemsCountArgs = {
  filter?: InputMaybe<PictureAssetAssignmentHistoryFilterInput>;
};


export type QueryPictureHistoryItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPictureHistoryItemsArgs = {
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PictureHistoryFilterInput>;
  orderBy?: InputMaybe<Array<PictureHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPictureHistoryItemsCountArgs = {
  filter?: InputMaybe<PictureHistoryFilterInput>;
};


export type QueryPictureRevisionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPictureRevisionsArgs = {
  filter?: InputMaybe<PictureRevisionFilterInput>;
  orderBy?: InputMaybe<Array<PictureRevisionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPictureRevisionsCountArgs = {
  filter?: InputMaybe<PictureRevisionFilterInput>;
};


export type QueryPicturesArgs = {
  filter?: InputMaybe<PictureFilterInput>;
  orderBy?: InputMaybe<Array<PictureOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPicturesCountArgs = {
  filter?: InputMaybe<PictureFilterInput>;
};


export type QueryPriceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPriceGraphArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPriceGraphsArgs = {
  filter?: InputMaybe<PriceGraphFilterInput>;
  orderBy?: InputMaybe<Array<PriceGraphOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPriceGraphsCountArgs = {
  filter?: InputMaybe<PriceGraphFilterInput>;
};


export type QueryPricesArgs = {
  filter?: InputMaybe<PriceFilterInput>;
  orderBy?: InputMaybe<Array<PriceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPricesCountArgs = {
  filter?: InputMaybe<PriceFilterInput>;
};


export type QueryProjectArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProjectsArgs = {
  filter?: InputMaybe<ProjectFilterInput>;
  orderBy?: InputMaybe<Array<ProjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProjectsCountArgs = {
  filter?: InputMaybe<ProjectFilterInput>;
};


export type QueryRenderJobArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRenderJobsArgs = {
  filter?: InputMaybe<RenderJobFilterInput>;
  orderBy?: InputMaybe<Array<RenderJobOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRenderJobsCountArgs = {
  filter?: InputMaybe<RenderJobFilterInput>;
};


export type QueryScanJobArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryScanJobsArgs = {
  filter?: InputMaybe<ScanJobFilterInput>;
  orderBy?: InputMaybe<Array<ScanJobOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryScanJobsCountArgs = {
  filter?: InputMaybe<ScanJobFilterInput>;
};


export type QueryScanSubJobArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryScanSubJobsArgs = {
  filter?: InputMaybe<ScanSubJobFilterInput>;
  orderBy?: InputMaybe<Array<ScanSubJobOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryScanSubJobsCountArgs = {
  filter?: InputMaybe<ScanSubJobFilterInput>;
};


export type QuerySceneArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySceneRevisionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySceneRevisionsArgs = {
  filter?: InputMaybe<SceneRevisionFilterInput>;
  orderBy?: InputMaybe<Array<SceneRevisionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySceneRevisionsCountArgs = {
  filter?: InputMaybe<SceneRevisionFilterInput>;
};


export type QuerySceneStatisticsItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySceneStatisticsItemsArgs = {
  filter?: InputMaybe<SceneStatisticsFilterInput>;
  orderBy?: InputMaybe<Array<SceneStatisticsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySceneStatisticsItemsCountArgs = {
  filter?: InputMaybe<SceneStatisticsFilterInput>;
};


export type QueryScenesArgs = {
  filter?: InputMaybe<SceneFilterInput>;
  orderBy?: InputMaybe<Array<SceneOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryScenesCountArgs = {
  filter?: InputMaybe<SceneFilterInput>;
};


export type QuerySetArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySetsArgs = {
  filter?: InputMaybe<SetFilterInput>;
  orderBy?: InputMaybe<Array<SetOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySetsCountArgs = {
  filter?: InputMaybe<SetFilterInput>;
};


export type QueryStorageDeleteTaskArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryStorageDeleteTasksArgs = {
  filter?: InputMaybe<StorageDeleteTaskFilterInput>;
  orderBy?: InputMaybe<Array<StorageDeleteTaskOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryStorageDeleteTasksCountArgs = {
  filter?: InputMaybe<StorageDeleteTaskFilterInput>;
};


export type QueryTagArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTagAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTagAssignmentsArgs = {
  filter?: InputMaybe<TagAssignmentFilterInput>;
  orderBy?: InputMaybe<Array<TagAssignmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTagAssignmentsCountArgs = {
  filter?: InputMaybe<TagAssignmentFilterInput>;
};


export type QueryTagsArgs = {
  filter?: InputMaybe<TagFilterInput>;
  orderBy?: InputMaybe<Array<TagOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTagsCountArgs = {
  filter?: InputMaybe<TagFilterInput>;
};


export type QueryTaskArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTaskCommentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTaskCommentsArgs = {
  filter?: InputMaybe<TaskCommentFilterInput>;
  orderBy?: InputMaybe<Array<TaskCommentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTaskCommentsCountArgs = {
  filter?: InputMaybe<TaskCommentFilterInput>;
};


export type QueryTaskPinArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTaskPinsArgs = {
  filter?: InputMaybe<TaskPinFilterInput>;
  orderBy?: InputMaybe<Array<TaskPinOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTaskPinsCountArgs = {
  filter?: InputMaybe<TaskPinFilterInput>;
};


export type QueryTasksArgs = {
  filter?: InputMaybe<TaskFilterInput>;
  orderBy?: InputMaybe<Array<TaskOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTasksCountArgs = {
  filter?: InputMaybe<TaskFilterInput>;
};


export type QueryTemplateArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTemplateRevisionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTemplateRevisionsArgs = {
  filter?: InputMaybe<TemplateRevisionFilterInput>;
  orderBy?: InputMaybe<Array<TemplateRevisionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTemplateRevisionsCountArgs = {
  filter?: InputMaybe<TemplateRevisionFilterInput>;
};


export type QueryTemplatesArgs = {
  filter?: InputMaybe<TemplateFilterInput>;
  orderBy?: InputMaybe<Array<TemplateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTemplatesCountArgs = {
  filter?: InputMaybe<TemplateFilterInput>;
};


export type QueryTextureArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureEditGroupArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureEditGroupsArgs = {
  filter?: InputMaybe<TextureEditGroupFilterInput>;
  orderBy?: InputMaybe<Array<TextureEditGroupOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureEditGroupsCountArgs = {
  filter?: InputMaybe<TextureEditGroupFilterInput>;
};


export type QueryTextureGroupArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureGroupsArgs = {
  filter?: InputMaybe<TextureGroupFilterInput>;
  orderBy?: InputMaybe<Array<TextureGroupOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureGroupsCountArgs = {
  filter?: InputMaybe<TextureGroupFilterInput>;
};


export type QueryTextureRevisionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureRevisionsArgs = {
  filter?: InputMaybe<TextureRevisionFilterInput>;
  orderBy?: InputMaybe<Array<TextureRevisionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureRevisionsCountArgs = {
  filter?: InputMaybe<TextureRevisionFilterInput>;
};


export type QueryTextureSetArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureSetRevisionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureSetRevisionsArgs = {
  filter?: InputMaybe<TextureSetRevisionFilterInput>;
  orderBy?: InputMaybe<Array<TextureSetRevisionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureSetRevisionsCountArgs = {
  filter?: InputMaybe<TextureSetRevisionFilterInput>;
};


export type QueryTextureSetsArgs = {
  filter?: InputMaybe<TextureSetFilterInput>;
  orderBy?: InputMaybe<Array<TextureSetOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTextureSetsCountArgs = {
  filter?: InputMaybe<TextureSetFilterInput>;
};


export type QueryTexturesArgs = {
  filter?: InputMaybe<TextureFilterInput>;
  orderBy?: InputMaybe<Array<TextureOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTexturesCountArgs = {
  filter?: InputMaybe<TextureFilterInput>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserAssignmentHistoryItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserAssignmentHistoryItemsArgs = {
  filter?: InputMaybe<UserAssignmentHistoryFilterInput>;
  orderBy?: InputMaybe<Array<UserAssignmentHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserAssignmentHistoryItemsCountArgs = {
  filter?: InputMaybe<UserAssignmentHistoryFilterInput>;
};


export type QueryUserAssignmentsArgs = {
  filter?: InputMaybe<UserAssignmentFilterInput>;
  orderBy?: InputMaybe<Array<UserAssignmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserAssignmentsCountArgs = {
  filter?: InputMaybe<UserAssignmentFilterInput>;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilterInput>;
  orderBy?: InputMaybe<Array<UserOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUsersCountArgs = {
  filter?: InputMaybe<UserFilterInput>;
};

export type RenderJob = {
  __typename: 'RenderJob';
  active: Scalars['Boolean']['output'];
  completed?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  instanceId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  log?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['Int']['output']>;
  samples: Scalars['Int']['output'];
  sceneRevision: SceneRevision;
  started?: Maybe<Scalars['DateTime']['output']>;
  state: RenderJobState;
  statusMessage?: Maybe<Scalars['String']['output']>;
  templateRevision?: Maybe<TemplateRevision>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type RenderJobFilterInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<DateTimeFilterInput>;
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdInFilter>;
  log?: InputMaybe<StringFilterInput>;
  progress?: InputMaybe<IntFilterInput>;
  samples?: InputMaybe<IntFilterInput>;
  sceneRevisionId?: InputMaybe<StringFilterInput>;
  started?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<Array<RenderJobState>>;
  statusMessage?: InputMaybe<StringFilterInput>;
  templateRevisionId?: InputMaybe<StringFilterInput>;
};

export enum RenderJobOrderByCriteria {
  Active = 'active',
  Completed = 'completed',
  CreatedAt = 'createdAt',
  Id = 'id',
  Started = 'started'
}

export type RenderJobOrderByInput = {
  direction: SortOrder;
  key: RenderJobOrderByCriteria;
};

export enum RenderJobState {
  Aborted = 'Aborted',
  Completed = 'Completed',
  Failed = 'Failed',
  Init = 'Init',
  Rendering = 'Rendering'
}

export type SalesEnquiry = {
  __typename: 'SalesEnquiry';
  companyName?: Maybe<Scalars['String']['output']>;
  contactName: Scalars['String']['output'];
  context?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  organization: Organization;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type ScanJob = {
  __typename: 'ScanJob';
  active: Scalars['Boolean']['output'];
  activeSubJobsCount: Scalars['Int']['output'];
  completed?: Maybe<Scalars['DateTime']['output']>;
  completedSubJobsCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  instanceId?: Maybe<Scalars['String']['output']>;
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  lastFailedState?: Maybe<ScanJobState>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  progress?: Maybe<Scalars['String']['output']>;
  retryCount: Scalars['Int']['output'];
  sampleRotation: Scalars['Int']['output'];
  started?: Maybe<Scalars['DateTime']['output']>;
  startedSubJobsCount: Scalars['Int']['output'];
  state: ScanJobState;
  stateStarted?: Maybe<Scalars['DateTime']['output']>;
  subJobs: Array<ScanSubJob>;
  subJobsCount: Scalars['Int']['output'];
  textureSet: TextureSet;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type ScanJobDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ScanJobJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type ScanJobSubJobsArgs = {
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ScanJobFilterInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<DateTimeFilterInput>;
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdInFilter>;
  instanceId?: InputMaybe<StringFilterInput>;
  progress?: InputMaybe<StringFilterInput>;
  sampleRotation?: InputMaybe<IntFilterInput>;
  started?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<Array<ScanJobState>>;
  stateStarted?: InputMaybe<DateTimeFilterInput>;
  textureSetId?: InputMaybe<StringFilterInput>;
};

export enum ScanJobOrderByCriteria {
  Active = 'active',
  Completed = 'completed',
  CreatedAt = 'createdAt',
  Id = 'id',
  Started = 'started'
}

export type ScanJobOrderByInput = {
  direction: SortOrder;
  key: ScanJobOrderByCriteria;
};

export enum ScanJobState {
  Aborted = 'Aborted',
  Assembly = 'Assembly',
  Completed = 'Completed',
  Failed = 'Failed',
  Fitting = 'Fitting',
  ImageProcessing = 'ImageProcessing',
  Init = 'Init',
  Preprocessing = 'Preprocessing',
  Scanning = 'Scanning',
  Uploading = 'Uploading'
}

export type ScanSubJob = {
  __typename: 'ScanSubJob';
  active: Scalars['Boolean']['output'];
  completed?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  id: Scalars['ID']['output'];
  instanceId?: Maybe<Scalars['String']['output']>;
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  lastFailedState?: Maybe<ScanSubJobState>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  progress?: Maybe<Scalars['String']['output']>;
  retryCount: Scalars['Int']['output'];
  scanJob: ScanJob;
  started?: Maybe<Scalars['DateTime']['output']>;
  state: ScanSubJobState;
  tileX: Scalars['Int']['output'];
  tileY: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type ScanSubJobDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ScanSubJobJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type ScanSubJobFilterInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<DateTimeFilterInput>;
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<IdInFilter>;
  id?: InputMaybe<IdInFilter>;
  instanceId?: InputMaybe<StringFilterInput>;
  progress?: InputMaybe<StringFilterInput>;
  scanJobId?: InputMaybe<IdInFilter>;
  started?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<Array<ScanSubJobState>>;
  tileX?: InputMaybe<IntFilterInput>;
  tileY?: InputMaybe<IntFilterInput>;
};

export enum ScanSubJobOrderByCriteria {
  Active = 'active',
  Completed = 'completed',
  CreatedAt = 'createdAt',
  Id = 'id',
  Started = 'started'
}

export type ScanSubJobOrderByInput = {
  direction: SortOrder;
  key: ScanSubJobOrderByCriteria;
};

export enum ScanSubJobState {
  Aborted = 'Aborted',
  Completed = 'Completed',
  Failed = 'Failed',
  Init = 'Init',
  Processing = 'Processing'
}

export type Scene = {
  __typename: 'Scene';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  galleryImage?: Maybe<DataObject>;
  id: Scalars['ID']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  latestRevision?: Maybe<SceneRevision>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  picture: Picture;
  revisions: Array<SceneRevision>;
  statistics: Array<SceneStatistics>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type SceneDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SceneJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type SceneCreateSceneRevisionInlineInput = {
  graph?: InputMaybe<Scalars['Json']['input']>;
};

export type SceneFilterInput = {
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntFilterInput>;
  pictureId?: InputMaybe<IdInFilter>;
  revisionIds?: InputMaybe<IdInFilter>;
  statisticsIds?: InputMaybe<IdInFilter>;
};

export enum SceneOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name'
}

export type SceneOrderByInput = {
  direction: SortOrder;
  key: SceneOrderByCriteria;
};

export type SceneRevision = {
  __typename: 'SceneRevision';
  arStatistics: Array<ArStatistics>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  graph?: Maybe<Scalars['Json']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  renderJobs: Array<RenderJob>;
  scene: Scene;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type SceneRevisionCreateOneWithoutArStatisticsInput = {
  assign: Scalars['Int']['input'];
};

export type SceneRevisionFilterInput = {
  arStatisticsIds?: InputMaybe<IdInFilter>;
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<IdInFilter>;
  id?: InputMaybe<Scalars['ID']['input']>;
  renderJobsIds?: InputMaybe<IdInFilter>;
  sceneId?: InputMaybe<IdInFilter>;
};

export enum SceneRevisionOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name'
}

export type SceneRevisionOrderByInput = {
  direction: SortOrder;
  key: SceneRevisionOrderByCriteria;
};

export type SceneStatistics = {
  __typename: 'SceneStatistics';
  created?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  scene?: Maybe<Scene>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type SceneStatisticsFilterInput = {
  created?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdInFilter>;
  sceneId?: InputMaybe<IdInFilter>;
  sceneLegacyId?: InputMaybe<IntInFilter>;
  templateId?: InputMaybe<IdInFilter>;
  templateLegacyId?: InputMaybe<IntInFilter>;
};

export enum SceneStatisticsOrderByCriteria {
  Id = 'id'
}

export type SceneStatisticsOrderByInput = {
  direction: SortOrder;
  key: SceneStatisticsOrderByCriteria;
};

export type Set = {
  __typename: 'Set';
  created: Scalars['DateTime']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  galleryImage?: Maybe<DataObject>;
  id: Scalars['ID']['output'];
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  pictures: Array<Picture>;
  project: Project;
  shareAssignments: Array<ShareAssignment>;
  shareAssignmentsCount: Scalars['Int']['output'];
  tagAssignments: Array<TagAssignment>;
  tagAssignmentsCount: Scalars['Int']['output'];
  taskInfos: TaskInfos;
  tasks: Array<Task>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type SetDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SetJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type SetPicturesArgs = {
  orderBy?: InputMaybe<Array<PictureOrderByInput>>;
};


export type SetShareAssignmentsArgs = {
  filter?: InputMaybe<ShareAssignmentFilterInput>;
  orderBy?: InputMaybe<ShareAssignmentOrderByInput>;
};


export type SetTagAssignmentsArgs = {
  tagTypes?: InputMaybe<Array<TagType>>;
};

export type SetFilterInput = {
  created?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdInFilter>;
  name?: InputMaybe<StringFilterInput>;
  number?: InputMaybe<IntFilterInput>;
  projectId?: InputMaybe<IdInFilter>;
};

export enum SetOrderByCriteria {
  ArticleId = 'articleId',
  Id = 'id',
  Name = 'name',
  NextActor = 'nextActor',
  Number = 'number',
  PaymentState = 'paymentState',
  PriorityOrder = 'priorityOrder',
  ProjectId = 'project_id',
  Public = 'public',
  State = 'state'
}

export type SetOrderByInput = {
  direction: SortOrder;
  key: SetOrderByCriteria;
};

export type ShareAssignment = {
  __typename: 'ShareAssignment';
  assignedTo?: Maybe<ShareAssignmentSubject>;
  contentTypeModel: ContentTypeModel;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  /** @deprecated Currently unused */
  permission: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  user: User;
};

export type ShareAssignmentFilterInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ShareAssignmentOrderByInput = {
  id?: InputMaybe<SortOrder>;
};

export type ShareAssignmentSubject = Asset | Hdri | Material | Model | Picture | Project | Set | Template;

export type SharingLink = {
  __typename: 'SharingLink';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  organization: Organization;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  user: User;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StorageDeleteTask = {
  __typename: 'StorageDeleteTask';
  created: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  gcsBucketName: Scalars['String']['output'];
  gcsObjectName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type StorageDeleteTaskFilterInput = {
  created?: InputMaybe<DateTimeFilterInput>;
  gcsBucketName?: InputMaybe<StringFilterInput>;
  gcsObjectName?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdInFilter>;
};

export enum StorageDeleteTaskOrderByCriteria {
  CreatedAt = 'createdAt',
  GcsBucketName = 'gcsBucketName',
  GcsObjectName = 'gcsObjectName',
  Id = 'id',
  LegacyId = 'legacyId'
}

export type StorageDeleteTaskOrderByInput = {
  direction: SortOrder;
  key: StorageDeleteTaskOrderByCriteria;
};

export type StringFilterInput = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<StringFilterInput>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringInFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum SystemRole {
  ArGenerationService = 'ArGenerationService',
  AssetRenderingService = 'AssetRenderingService',
  Configurator = 'Configurator',
  FileProcessingService = 'FileProcessingService',
  ImageProcessingService = 'ImageProcessingService',
  JobSystemService = 'JobSystemService',
  MaterialAi = 'MaterialAi',
  MaterialMapsExporterService = 'MaterialMapsExporterService',
  RenderingService = 'RenderingService',
  Scanner = 'Scanner',
  Staff = 'Staff',
  Superadmin = 'Superadmin',
  TilingService = 'TilingService',
  UtilityService = 'UtilityService',
  WebComponents = 'WebComponents'
}

export type Tag = {
  __typename: 'Tag';
  assignments: Array<TagAssignment>;
  assignmentsCount: Scalars['Int']['output'];
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: User;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  otherInfo?: Maybe<Scalars['String']['output']>;
  templateRevisions: Array<TemplateRevision>;
  templateRevisionsCount: Scalars['Int']['output'];
  type: TagType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type TagAssignmentsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type TagDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type TagJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type TagAssignment = {
  __typename: 'TagAssignment';
  assignedTo?: Maybe<TagAssignmentSubject>;
  contentTypeModel: ContentTypeModel;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  tag: Tag;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type TagAssignmentFilterInput = {
  contentTypeModel?: InputMaybe<ContentTypeModel>;
  id?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
  tagId?: InputMaybe<IdInFilter>;
};

export enum TagAssignmentOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id'
}

export type TagAssignmentOrderByInput = {
  direction: SortOrder;
  key: TagAssignmentOrderByCriteria;
};

export type TagAssignmentSubject = Asset | Hdri | Material | Model | Picture | Project | Set | Template;

export type TagFilterInput = {
  createdById?: InputMaybe<IdInFilter>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  organizationId?: InputMaybe<IdInFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  tagType?: InputMaybe<Array<TagType>>;
};

export enum TagOrderByCriteria {
  Id = 'id',
  LegacyId = 'legacyId',
  MaterialRevisionId = 'materialRevisionId',
  Name = 'name'
}

export type TagOrderByInput = {
  direction: SortOrder;
  key: TagOrderByCriteria;
};

export enum TagType {
  MaterialRange = 'MaterialRange',
  PdfSpecItem = 'PdfSpecItem',
  PictureAnnotation = 'PictureAnnotation',
  ProductCategory = 'ProductCategory',
  ProductRange = 'ProductRange',
  Production = 'Production'
}

export type Task = {
  __typename: 'Task';
  assignedTo?: Maybe<TaskAssignmentSubject>;
  comments: Array<TaskComment>;
  commentsCount: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  number: Scalars['Int']['output'];
  pins: Array<TaskPin>;
  pinsCount: Scalars['Int']['output'];
  public: Scalars['Boolean']['output'];
  state: TaskState;
  taskType: TaskType;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type TaskCommentsArgs = {
  orderBy?: InputMaybe<Array<TaskCommentOrderByInput>>;
};

export type TaskAssignmentSubject = Asset | Hdri | Material | Model | Picture | Project | Set | Template;

export type TaskComment = {
  __typename: 'TaskComment';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  task: Task;
  text: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type TaskCommentFilterInput = {
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<IdInFilter>;
  id?: InputMaybe<IdInFilter>;
  taskId?: InputMaybe<IdInFilter>;
  text?: InputMaybe<StringFilterInput>;
};

export enum TaskCommentOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  LegacyId = 'legacyId',
  Text = 'text'
}

export type TaskCommentOrderByInput = {
  direction: SortOrder;
  key: TaskCommentOrderByCriteria;
};

export type TaskFilterInput = {
  commentIds?: InputMaybe<IdInFilter>;
  contentTypeModel?: InputMaybe<ContentTypeModel>;
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<IdInFilter>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdInFilter>;
  number?: InputMaybe<IntFilterInput>;
  objectId?: InputMaybe<IdFilterInput>;
  objectLegacyId?: InputMaybe<Scalars['Int']['input']>;
  pinIds?: InputMaybe<IdInFilter>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Array<TaskState>>;
  title?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<Array<TaskType>>;
};

export type TaskInfos = {
  __typename: 'TaskInfos';
  archived: Scalars['Int']['output'];
  completed: Scalars['Int']['output'];
  inProgress: Scalars['Int']['output'];
  waitingForFeedback: Scalars['Int']['output'];
};

export enum TaskOrderByCriteria {
  Comments = 'comments',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  LegacyId = 'legacyId',
  Number = 'number',
  Public = 'public',
  Title = 'title'
}

export type TaskOrderByInput = {
  direction: SortOrder;
  key: TaskOrderByCriteria;
};

export type TaskPin = {
  __typename: 'TaskPin';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  task: Task;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type TaskPinFilterInput = {
  id?: InputMaybe<IdInFilter>;
  taskId?: InputMaybe<IdInFilter>;
  x?: InputMaybe<FloatFilterInput>;
  y?: InputMaybe<FloatFilterInput>;
};

export enum TaskPinOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Text = 'text'
}

export type TaskPinOrderByInput = {
  direction: SortOrder;
  key: TaskPinOrderByCriteria;
};

export enum TaskState {
  Archived = 'Archived',
  Completed = 'Completed',
  InProgress = 'InProgress',
  WaitingForFeedback = 'WaitingForFeedback'
}

export enum TaskType {
  Generic = 'Generic',
  Material = 'Material',
  Model = 'Model',
  Picture = 'Picture'
}

export type Template = {
  __typename: 'Template';
  assets: Array<Asset>;
  assetsCount: Scalars['Int']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  currentUserAssignment?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  exSceneId?: Maybe<Scalars['Int']['output']>;
  galleryImage?: Maybe<DataObject>;
  id: Scalars['ID']['output'];
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  latestRevision?: Maybe<TemplateRevision>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  /** Templates that reference or are referenced by other templates are locked. Locked templates cannot be changed from public to private, nor can their organization be changed. */
  locked: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  organizationId: Scalars['String']['output'];
  /** @deprecated Use organizationId instead */
  organizationLegacyId: Scalars['Int']['output'];
  picture: Picture;
  public: Scalars['Boolean']['output'];
  revisions: Array<TemplateRevision>;
  revisionsCount: Scalars['Int']['output'];
  shareAssignments: Array<ShareAssignment>;
  shareAssignmentsCount: Scalars['Int']['output'];
  state: TemplateState;
  statistics: Array<SceneStatistics>;
  tagAssignments: Array<TagAssignment>;
  tagAssignmentsCount: Scalars['Int']['output'];
  tasks: Array<Task>;
  type: TemplateType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  userAssignments: Array<UserAssignment>;
  userAssignmentsCount: Scalars['Int']['output'];
};


export type TemplateDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type TemplateJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type TemplateRevisionsArgs = {
  orderBy?: InputMaybe<Array<TemplateRevisionOrderByInput>>;
};


export type TemplateShareAssignmentsArgs = {
  filter?: InputMaybe<ShareAssignmentFilterInput>;
  orderBy?: InputMaybe<ShareAssignmentOrderByInput>;
};


export type TemplateTagAssignmentsArgs = {
  tagTypes?: InputMaybe<Array<TagType>>;
};


export type TemplateUserAssignmentsArgs = {
  filter?: InputMaybe<UserAssignmentFilterInput>;
};

export type TemplateCreateTemplateRevisionInlineInput = {
  graph?: InputMaybe<Scalars['Json']['input']>;
};

export type TemplateDataObjectAssignmentInput = {
  dataObjectId: Scalars['Int']['input'];
  types: Array<DataObjectAssignmentType>;
};

export type TemplateFilterInput = {
  exSceneId?: InputMaybe<Scalars['Int']['input']>;
  hasAssignedUser?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  organizationId?: InputMaybe<IdInFilter>;
  organizationLegacyId?: InputMaybe<IntInFilter>;
  pictureId?: InputMaybe<IntInFilter>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<TemplateState>>;
  statisticsIds?: InputMaybe<IdInFilter>;
  tagId?: InputMaybe<IdInFilter>;
  type?: InputMaybe<Array<TemplateType>>;
  userId?: InputMaybe<IdInFilter>;
};

export enum TemplateOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Public = 'public'
}

export type TemplateOrderByInput = {
  direction: SortOrder;
  key: TemplateOrderByCriteria;
};

export type TemplateRevision = {
  __typename: 'TemplateRevision';
  arStatistics: Array<ArStatistics>;
  completed: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  exSceneRevisionId?: Maybe<Scalars['Int']['output']>;
  graph?: Maybe<Scalars['Json']['output']>;
  hdris: Array<Hdri>;
  hdrisCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** List materials that are less accessible than the template (i.e. private and in a different organization) */
  inconsistencies: Array<Scalars['DataInconsistency']['output']>;
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  materialRevisions: Array<MaterialRevision>;
  materialRevisionsCount: Scalars['Int']['output'];
  number: Scalars['Int']['output'];
  referencedBy: Array<TemplateRevision>;
  referencedByCount: Scalars['Int']['output'];
  references: Array<TemplateRevision>;
  referencesCount: Scalars['Int']['output'];
  renderJobs: Array<RenderJob>;
  /** Up to four thumbnails based on the output of assigned job tasks */
  renderedImages: Array<DataObject>;
  tags: Array<Tag>;
  tagsCount: Scalars['Int']['output'];
  template: Template;
  textureRevisions: Array<TextureRevision>;
  textureRevisionsCount: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type TemplateRevisionDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type TemplateRevisionJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type TemplateRevisionRenderedImagesArgs = {
  orderBy?: InputMaybe<JobAssignmentOrderByInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type TemplateRevisionFilterInput = {
  arStatisticsIds?: InputMaybe<IdInFilter>;
  exSceneRevisionId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  number?: InputMaybe<Scalars['Int']['input']>;
  renderJobsIds?: InputMaybe<IdInFilter>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

export enum TemplateRevisionOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Number = 'number',
  TemplateId = 'template_id'
}

export type TemplateRevisionOrderByInput = {
  direction: SortOrder;
  key: TemplateRevisionOrderByCriteria;
};

export enum TemplateState {
  Completed = 'Completed',
  Draft = 'Draft',
  InProgress = 'InProgress',
  Review = 'Review'
}

export enum TemplateType {
  Part = 'Part',
  Product = 'Product',
  Room = 'Room',
  Scene = 'Scene'
}

export type Texture = {
  __typename: 'Texture';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  latestRevision?: Maybe<TextureRevision>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  revisions: Array<TextureRevision>;
  textureSet: TextureSet;
  type: TextureType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type TextureDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type TextureJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type TextureRevisionsArgs = {
  orderBy?: InputMaybe<Array<TextureRevisionOrderByInput>>;
};

/** High-level description of an edit made to multiple revisions within a texture set */
export type TextureEditGroup = {
  __typename: 'TextureEditGroup';
  assignments: Array<TextureEditGroupAssignment>;
  content?: Maybe<Scalars['Json']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** Are all of the originally assigned revisions still present? Returns false if at least one assigned revision has been deleted since creation of the texture edit group. */
  isComplete: Scalars['Boolean']['output'];
  latestCyclesRevision?: Maybe<TextureRevision>;
  /** The number of revisions assigned at creation time. */
  originalRevisionsCount: Scalars['Int']['output'];
  revisions: Array<TextureRevision>;
  textureSet: TextureSet;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type TextureEditGroupAssignment = {
  __typename: 'TextureEditGroupAssignment';
  editGroup: TextureEditGroup;
  revision: TextureRevision;
};

export type TextureEditGroupFilterInput = {
  id?: InputMaybe<IdInFilter>;
  textureSetId?: InputMaybe<IdInFilter>;
};

export enum TextureEditGroupOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Title = 'title'
}

export type TextureEditGroupOrderByInput = {
  direction: SortOrder;
  key: TextureEditGroupOrderByCriteria;
};

export type TextureFilterInput = {
  id?: InputMaybe<IdInFilter>;
  revisionId?: InputMaybe<IdInFilter>;
  textureSetId?: InputMaybe<IdInFilter>;
  type?: InputMaybe<Array<TextureType>>;
};

export type TextureGroup = {
  __typename: 'TextureGroup';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  galleryImage?: Maybe<DataObject>;
  id: Scalars['ID']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  material?: Maybe<Material>;
  meshSpecific: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  textureSets: Array<TextureSet>;
  textureSetsCount: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type TextureGroupDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type TextureGroupJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type TextureGroupFilterInput = {
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntFilterInput>;
  materialId?: InputMaybe<IdInFilter>;
  meshSpecific?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<IdInFilter>;
  organizationLegacyId?: InputMaybe<IntInFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum TextureGroupOrderByCriteria {
  Comment = 'comment',
  Id = 'id',
  LegacyId = 'legacyId',
  Name = 'name'
}

export type TextureGroupOrderByInput = {
  direction: SortOrder;
  key: TextureGroupOrderByCriteria;
};

export enum TextureOrderByCriteria {
  Id = 'id'
}

export type TextureOrderByInput = {
  direction: SortOrder;
  key: TextureOrderByCriteria;
};

/** A particular version of a texture. Subsequent edits will create new revisions of the same texture. */
export type TextureRevision = {
  __typename: 'TextureRevision';
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  createdByVersion?: Maybe<Scalars['String']['output']>;
  dataObject: DataObject;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  displacement?: Maybe<Scalars['Float']['output']>;
  editGroups: Array<TextureEditGroup>;
  group?: Maybe<Scalars['String']['output']>;
  height: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  materialNodes: Array<MaterialNode>;
  materialNodesCount: Scalars['Int']['output'];
  number?: Maybe<Scalars['Int']['output']>;
  state: TextureRevisionState;
  templateRevisions: Array<TemplateRevision>;
  texture?: Maybe<Texture>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  width: Scalars['Float']['output'];
};


/** A particular version of a texture. Subsequent edits will create new revisions of the same texture. */
export type TextureRevisionDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


/** A particular version of a texture. Subsequent edits will create new revisions of the same texture. */
export type TextureRevisionJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type TextureRevisionFilterInput = {
  id?: InputMaybe<IdInFilter>;
  legacyId?: InputMaybe<IntInFilter>;
  number?: InputMaybe<IntInFilter>;
  state?: InputMaybe<Array<TextureRevisionState>>;
  textureId?: InputMaybe<IdInFilter>;
};

export enum TextureRevisionOrderByCriteria {
  Id = 'id',
  Number = 'number',
  TextureId = 'texture_id'
}

export type TextureRevisionOrderByInput = {
  direction: SortOrder;
  key: TextureRevisionOrderByCriteria;
};

export enum TextureRevisionState {
  Completed = 'Completed',
  Draft = 'Draft'
}

export type TextureSet = {
  __typename: 'TextureSet';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  editGroups: Array<TextureEditGroup>;
  id: Scalars['ID']['output'];
  jobAssignments: Array<JobAssignment>;
  jobAssignmentsCount: Scalars['Int']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  scanJob?: Maybe<ScanJob>;
  textureGroup: TextureGroup;
  textureSetRevisions: Array<TextureSetRevision>;
  textures: Array<Texture>;
  texturesCount: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};


export type TextureSetDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type TextureSetJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};


export type TextureSetTextureSetRevisionsArgs = {
  orderBy?: InputMaybe<Array<TextureSetRevisionOrderByInput>>;
};

export type TextureSetFilterInput = {
  createdById?: InputMaybe<IdInFilter>;
  id?: InputMaybe<IdInFilter>;
  scanJobId?: InputMaybe<IdInFilter>;
  textureGroupId?: InputMaybe<IdInFilter>;
};

export type TextureSetInlineCreateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  scanJobId?: InputMaybe<Scalars['String']['input']>;
};

export enum TextureSetOrderByCriteria {
  Id = 'id'
}

export type TextureSetOrderByInput = {
  direction: SortOrder;
  key: TextureSetOrderByCriteria;
};

/** Revision of TextureSet describing all maps of PBR set as well as their edits */
export type TextureSetRevision = {
  __typename: 'TextureSetRevision';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  displacement?: Maybe<Scalars['Float']['output']>;
  editsJson?: Maybe<Scalars['Json']['output']>;
  editsProcessingJob?: Maybe<Job>;
  eligibleForMaterialAi: Scalars['Boolean']['output'];
  height: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  mapAssignments: Array<TextureSetRevisionMapAssignment>;
  materialNodes: Array<MaterialNode>;
  migratedFromTextureEditGroupUuid?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sourceDataObjectReferences: Array<TextureSetRevisionSourceDataObjectReference>;
  textureSet: TextureSet;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  width: Scalars['Float']['output'];
};

export type TextureSetRevisionFilterInput = {
  id?: InputMaybe<IdInFilter>;
  migratedFromTextureEditGroupId?: InputMaybe<IdInFilter>;
  textureSetId?: InputMaybe<IdInFilter>;
};

/** Assignment of texture revision to texture set revision for specified texture type */
export type TextureSetRevisionMapAssignment = {
  __typename: 'TextureSetRevisionMapAssignment';
  dataObject: DataObject;
  textureSetRevision: TextureSetRevision;
  textureType: TextureType;
};

export type TextureSetRevisionMapAssignmentInput = {
  dataObjectId: Scalars['ID']['input'];
  textureType: TextureType;
};

export enum TextureSetRevisionOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Title = 'title'
}

export type TextureSetRevisionOrderByInput = {
  direction: SortOrder;
  key: TextureSetRevisionOrderByCriteria;
};

/** Assignment of data-object reference to texture set revision */
export type TextureSetRevisionSourceDataObjectReference = {
  __typename: 'TextureSetRevisionSourceDataObjectReference';
  dataObject: DataObject;
  textureSetRevision: TextureSetRevision;
};

export enum TextureType {
  Anisotropy = 'Anisotropy',
  AnisotropyRotation = 'AnisotropyRotation',
  AnisotropyStrength = 'AnisotropyStrength',
  Diffuse = 'Diffuse',
  DiffuseRoughness = 'DiffuseRoughness',
  Displacement = 'Displacement',
  Error = 'Error',
  F0 = 'F0',
  Ior = 'IOR',
  Mask = 'Mask',
  Metalness = 'Metalness',
  Normal = 'Normal',
  Roughness = 'Roughness',
  SpecularStrength = 'SpecularStrength',
  SpecularTint = 'SpecularTint',
  Transmission = 'Transmission'
}

export type User = {
  __typename: 'User';
  assetHistories: Array<AssetHistory>;
  assetHistoriesCount: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  createdUserAssignments: Array<UserAssignment>;
  createdUserAssignmentsCount: Scalars['Int']['output'];
  dataObjectAssignments: Array<DataObjectAssignment>;
  dataObjectAssignmentsCount: Scalars['Int']['output'];
  dateJoined?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  emailNotifications: Scalars['Boolean']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  galleryImage?: Maybe<DataObject>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isStaff: Scalars['Boolean']['output'];
  isSuperuser: Scalars['Boolean']['output'];
  jsonFileAssignments: Array<JsonFileAssignment>;
  jsonFileAssignmentsCount: Scalars['Int']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  materialAssignmentHistories: Array<MaterialAssignmentHistory>;
  materialAssignmentHistoriesCount: Scalars['Int']['output'];
  materialHistories: Array<MaterialHistory>;
  materialHistoriesCount: Scalars['Int']['output'];
  memberships: Array<Membership>;
  modelHistories: Array<ModelHistory>;
  modelHistoriesCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  pictureAssetAssignmentHistories: Array<PictureAssetAssignmentHistory>;
  pictureAssetAssignmentHistoriesCount: Scalars['Int']['output'];
  pictureHistories: Array<PictureHistory>;
  pictureHistoriesCount: Scalars['Int']['output'];
  role?: Maybe<SystemRole>;
  shareAssignments: Array<ShareAssignment>;
  shareAssignmentsCount: Scalars['Int']['output'];
  taskInfos: TaskInfos;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  userAssignmentHistories: Array<UserAssignmentHistory>;
  userAssignmentHistoriesCount: Scalars['Int']['output'];
  userAssignments: Array<UserAssignment>;
  userAssignmentsCount: Scalars['Int']['output'];
  visibleInFilters: Scalars['Boolean']['output'];
};


export type UserDataObjectAssignmentsArgs = {
  filter?: InputMaybe<DataObjectAssignmentFilterInput>;
  orderBy?: InputMaybe<DataObjectAssignmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserJsonFileAssignmentsArgs = {
  filter?: InputMaybe<JsonFileAssignmentFilterInput>;
  orderBy?: InputMaybe<JsonFileAssignmentOrderByInput>;
};

export type UserAssignment = {
  __typename: 'UserAssignment';
  assignedTo?: Maybe<UserAssignmentSubject>;
  /** Currently, there is only one type of assignment: 'Generic'. */
  assignmentType: UserAssignmentType;
  contentTypeModel: ContentTypeModel;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  /** The state of the Model, Material, Asset or Picture the User is assigned to. */
  objectState?: Maybe<UserAssignmentState>;
  /** We can assign different users for each state. This is the state of the UserAssignment itself. The current assignment is the one whose state matches that of the object. */
  state?: Maybe<UserAssignmentState>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  user: User;
};

export type UserAssignmentFilterInput = {
  created?: InputMaybe<DateTimeFilterInput>;
  createdById?: InputMaybe<IdInFilter>;
  id?: InputMaybe<IdInFilter>;
  state?: InputMaybe<UserAssignmentState>;
  type?: InputMaybe<UserAssignmentType>;
  userId?: InputMaybe<IdInFilter>;
};

export type UserAssignmentHistory = {
  __typename: 'UserAssignmentHistory';
  assignment?: Maybe<UserAssignment>;
  /** Currently, there is only one type of assignment: 'Generic'. */
  assignmentType: UserAssignmentType;
  contentTypeModel?: Maybe<ContentTypeModel>;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  historyChangeReason?: Maybe<Scalars['String']['output']>;
  historyDate?: Maybe<Scalars['DateTime']['output']>;
  historyType: HistoryType;
  id: Scalars['ID']['output'];
  /** @deprecated Int-based IDs are deprecated in favor of UUIDs. Please use the `id` field instead. */
  legacyId: Scalars['Int']['output'];
  /** The state of the Model, Material, Asset or Picture at which a User is assigned to it. */
  state?: Maybe<UserAssignmentState>;
  user?: Maybe<User>;
};

export type UserAssignmentHistoryFilterInput = {
  assignmentId?: InputMaybe<IdInFilter>;
  created?: InputMaybe<DateTimeFilterInput>;
  historyChangeReason?: InputMaybe<StringFilterInput>;
  historyDate?: InputMaybe<DateTimeFilterInput>;
  historyType?: InputMaybe<Array<HistoryType>>;
  id?: InputMaybe<IdInFilter>;
  type?: InputMaybe<Array<UserAssignmentType>>;
  userId?: InputMaybe<IdInFilter>;
};

export enum UserAssignmentHistoryOrderByCriteria {
  CreatedAt = 'createdAt',
  HistoryDate = 'history_date',
  Id = 'id'
}

export type UserAssignmentHistoryOrderByInput = {
  direction: SortOrder;
  key: UserAssignmentHistoryOrderByCriteria;
};

export enum UserAssignmentOrderByCriteria {
  CreatedAt = 'createdAt',
  Id = 'id',
  Text = 'text'
}

export type UserAssignmentOrderByInput = {
  direction: SortOrder;
  key: UserAssignmentOrderByCriteria;
};

export enum UserAssignmentState {
  Completed = 'Completed',
  Draft = 'Draft',
  FinalRender = 'FinalRender',
  InfoReview = 'InfoReview',
  Modelling = 'Modelling',
  OnHold = 'OnHold',
  Review = 'Review',
  Scanning = 'Scanning',
  Shading = 'Shading',
  Stage1 = 'Stage1',
  Stage2 = 'Stage2',
  Tiling = 'Tiling'
}

export type UserAssignmentSubject = Asset | Hdri | Material | Model | Picture | Project | Set | Template;

export enum UserAssignmentType {
  Generic = 'Generic'
}

export type UserFilterInput = {
  dateJoined?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  firstName?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdInFilter>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isStaff?: InputMaybe<Scalars['Boolean']['input']>;
  lastLogin?: InputMaybe<DateTimeFilterInput>;
  lastName?: InputMaybe<StringFilterInput>;
  legacyId?: InputMaybe<IntFilterInput>;
  organizationId?: InputMaybe<IdInFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  systemRole?: InputMaybe<SystemRole>;
  visibleInFilters?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum UserOrderByCriteria {
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  LegacyId = 'legacyId'
}

export type UserOrderByInput = {
  direction: SortOrder;
  key: UserOrderByCriteria;
};
