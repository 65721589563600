import {Component, inject, Input, OnInit} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {Labels, StateLabel} from "@app/platform/models/state-labels"
import {IsDefined} from "@cm/utils"
import {
    AssetActivityItemsGQL,
    MaterialActivityItemsGQL,
    ModelActivityItemsGQL,
    PictureActivityItemsGQL,
} from "@common/components/entity/entity-activity/entity-activity.generated"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {pairwise} from "@common/helpers/array"
import {forceEnum} from "@common/helpers/utils/enum"
import {FormatDatePipe} from "@common/pipes/format-date/format-date.pipe"
import {TimeAgoPipe} from "@common/pipes/time-ago/time-ago.pipe"
import {HistoryType} from "@generated"
import {BehaviorSubject, firstValueFrom, Observable} from "rxjs"

export type EntityActivityType = {
    id: string
    __typename: string
    state: string
}

@Component({
    selector: "cm-entity-activity",
    templateUrl: "./entity-activity.component.html",
    styleUrls: ["./entity-activity.component.scss"],
    imports: [MatTooltipModule, PlaceholderComponent, FormatDatePipe, TimeAgoPipe],
})
export class EntityActivityComponent<EntityType extends EntityActivityType> implements OnInit {
    item$ = new BehaviorSubject<EntityType | null | undefined>(undefined)

    readonly assetActivityItems = inject(AssetActivityItemsGQL)
    readonly materialActivityItems = inject(MaterialActivityItemsGQL)
    readonly modelActivityItems = inject(ModelActivityItemsGQL)
    readonly pictureActivityItems = inject(PictureActivityItemsGQL)

    @Input({required: true}) set item(item: EntityType | null | undefined) {
        this.item$.next(item)
    }

    activities:
        | {
              id: string
              createdBy?: {firstName?: string | null; lastName?: string | null} | null
              historyDate?: string | null
              historyType: HistoryType
              stateLabel: string
              previousStateLabel?: string
          }[]
        | undefined = undefined

    ngOnInit(): void {
        this.item$.subscribe((item) => this.updateActivities(item))
    }

    async updateActivities(item: EntityType | null | undefined) {
        const dataFetcher = this.dataFetcher(item)
        if (dataFetcher && item) {
            const rawActivities = (await firstValueFrom(dataFetcher.fetch({id: item.id})).then(({data: {items}}) => items)).filter(IsDefined)
            const parsedActivities = rawActivities.map((activity) => {
                return {
                    id: activity.id,
                    createdBy: activity.createdBy,
                    historyDate: activity.historyDate,
                    historyType: forceEnum(activity.historyType, HistoryType),
                    stateLabel: this.labelForState(activity.state, item),
                }
            })

            if (parsedActivities.length) {
                this.activities = [
                    ...pairwise(parsedActivities).map(([activity, previousActivity]) => {
                        return {
                            ...activity,
                            previousStateLabel: previousActivity.stateLabel,
                        }
                    }),
                    {...parsedActivities?.[parsedActivities.length - 1], previousStateLabel: undefined},
                ].filter(
                    (activity) =>
                        activity.historyType !== HistoryType.Moved || (!!activity.previousStateLabel && activity.previousStateLabel !== activity.stateLabel),
                )
            } else {
                this.activities = []
            }
        } else {
            this.activities = undefined
        }
    }

    private dataFetcher = (
        item: EntityType | null | undefined,
    ): {
        fetch: (input: {id: string}) => Observable<{
            data: {
                items: ({
                    id: string
                    createdBy?: {__typename: "User"; id: string; firstName?: string | null; lastName?: string | null} | null
                    historyDate?: string | null
                    historyType: HistoryType
                    state: string
                } | null)[]
            }
        }>
    } | null => {
        if (!item) {
            return null
        }
        switch (item.__typename) {
            case "Asset":
                return this.assetActivityItems
            case "Material":
                return this.materialActivityItems
            case "Model":
                return this.modelActivityItems
            case "Picture":
                return this.pictureActivityItems
            default:
                throw new Error("Not implemented")
        }
    }

    private labelForState = (state: string, item: EntityType) => {
        const labelForState_ = (state: string, labels: Map<string, StateLabel<string>>) => {
            const label = labels.get(state)
            if (!label) {
                console.warn(`No label for state ${state}, using state as label`)
                return state
            }
            return label.label
        }

        switch (item.__typename) {
            case "Asset":
            case "Material":
            case "Model":
            case "Picture":
                return labelForState_(state, Labels.States[item.__typename])
            default:
                throw new Error("Not implemented")
        }
    }

    protected readonly HistoryType = HistoryType
}
