import * as Types from '../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type QueryTextureSetViewDataQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type QueryTextureSetViewDataQuery = { __typename: 'Query', textureSet: { __typename: 'TextureSet', id: string, textureGroup: { __typename: 'TextureGroup', organization: { __typename: 'Organization', legacyId: number } } } };

export type QueryTextureSetRevisionViewDataQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type QueryTextureSetRevisionViewDataQuery = { __typename: 'Query', textureSetRevision: { __typename: 'TextureSetRevision', name?: string | null, width: number, height: number, displacement?: number | null, textureSet: { __typename: 'TextureSet', id: string }, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', id: string } }> } };

export type TextureSetRevisionViewTextureSetFragment = { __typename: 'TextureSet', id: string, textureGroup: { __typename: 'TextureGroup', organization: { __typename: 'Organization', legacyId: number } } };

export type TextureSetRevisionViewCreateTextureSetRevisionMutationVariables = Types.Exact<{
  input: Types.MutationCreateTextureSetRevisionInput;
}>;


export type TextureSetRevisionViewCreateTextureSetRevisionMutation = { __typename: 'Mutation', createTextureSetRevision: { __typename: 'TextureSetRevision', id: string } };

export const TextureSetRevisionViewTextureSetFragmentDoc = gql`
    fragment TextureSetRevisionViewTextureSet on TextureSet {
  id
  textureGroup {
    organization {
      legacyId
    }
  }
}
    `;
export const QueryTextureSetViewDataDocument = gql`
    query queryTextureSetViewData($id: ID!) {
  textureSet(id: $id) {
    ...TextureSetRevisionViewTextureSet
  }
}
    ${TextureSetRevisionViewTextureSetFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureSetViewDataGQL extends Apollo.Query<QueryTextureSetViewDataQuery, QueryTextureSetViewDataQueryVariables> {
    override document = QueryTextureSetViewDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryTextureSetRevisionViewDataDocument = gql`
    query queryTextureSetRevisionViewData($id: ID!) {
  textureSetRevision(id: $id) {
    textureSet {
      id
    }
    name
    width
    height
    displacement
    mapAssignments {
      textureType
      dataObject {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureSetRevisionViewDataGQL extends Apollo.Query<QueryTextureSetRevisionViewDataQuery, QueryTextureSetRevisionViewDataQueryVariables> {
    override document = QueryTextureSetRevisionViewDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureSetRevisionViewCreateTextureSetRevisionDocument = gql`
    mutation textureSetRevisionViewCreateTextureSetRevision($input: MutationCreateTextureSetRevisionInput!) {
  createTextureSetRevision(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureSetRevisionViewCreateTextureSetRevisionGQL extends Apollo.Mutation<TextureSetRevisionViewCreateTextureSetRevisionMutation, TextureSetRevisionViewCreateTextureSetRevisionMutationVariables> {
    override document = TextureSetRevisionViewCreateTextureSetRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }