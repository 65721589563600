import {DeclareTemplateNodeTS, TemplateNodeImplementation, TemplateNodeMeta} from "#template-nodes/declare-template-node"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {IMaterialGraph} from "@cm/material-nodes/interfaces/material-data"
import {CurveObjectData, ObjectData, MeshObjectData} from "#template-nodes/interfaces/object-data"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {CurveLike, ImageLike, MaterialLike, MeshLike, ObjectLike, TemplateLike} from "#template-nodes/node-types"
import {namedNodeParameters, NamedNodeParameters} from "#template-nodes/nodes/named-node"
import {TemplateInstance} from "#template-nodes/nodes/template-instance"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {AnyJSONValue, EvaluatedTemplateOutputs, EvaluatedTemplateValueType, externalId, TemplateData, TemplateNode} from "#template-nodes/types"
import {nodeInstance} from "@cm/graph/instance"
import {NodeGraphClass} from "@cm/graph/node-graph"
import {registerNode} from "@cm/graph/register-node"
import {ImageGenerator} from "@cm/material-nodes/interfaces/image-generator"
import {z} from "zod"
import {Unresolved} from "#template-nodes/runtime-graph/types"

const outputParameters = namedNodeParameters.merge(
    z.object({
        template: nodeInstance(TemplateInstance),
        outputId: externalId,
    }),
)
type OutputParameters<T> = NamedNodeParameters & {template: TemplateInstance; outputId: string}

type OutputClass<T, E> = NodeGraphClass<TemplateNode<OutputParameters<T>> & EvaluableTemplateNode<E | null>>

const generateOutput = <T, E>(
    type: EvaluatedTemplateValueType,
    implementation: TemplateNodeImplementation<OutputParameters<T>>,
    meta: TemplateNodeMeta<OutputParameters<T>>,
): OutputClass<T, E> => {
    const retClass = class
        extends DeclareTemplateNodeTS<OutputParameters<T>>({validation: {paramsSchema: outputParameters}, ...implementation}, meta)
        implements EvaluableTemplateNode<E | null>
    {
        evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
            return scope.pureLambda(
                scope.tuple(
                    evaluator.templateScope.resolve<EvaluatedTemplateOutputs>(`templateOutputs-${evaluator.getLocalId(this.parameters.template)}`),
                    type,
                    this.parameters.outputId,
                ),
                ([outputs, type, outputId]) => {
                    if (outputs === Unresolved) return null
                    return (outputs[`output-${type}-${outputId}`] ?? null) as E | null
                },
                `output${this.instanceId}`,
            )
        }
    }
    return retClass
}

////////////////////////////////////////////////////////////////////

export interface ObjectOutputParameters extends OutputParameters<ObjectLike> {} // workaround for recursive type

@registerNode
export class ObjectOutput extends generateOutput<ObjectLike, ObjectData>("object", {}, {nodeClass: "ObjectOutput"}) {}

export type ObjectOutputFwd = TemplateNode<ObjectOutputParameters> & EvaluableTemplateNode<ObjectData>

////////////////////////////////////////////////////////////////////

export interface MeshOutputParameters extends OutputParameters<MeshLike> {} // workaround for recursive type

@registerNode
export class MeshOutput extends generateOutput<MeshLike, MeshObjectData>("object", {}, {nodeClass: "MeshOutput"}) {}

export type MeshOutputFwd = TemplateNode<MeshOutputParameters> & EvaluableTemplateNode<MeshObjectData>

////////////////////////////////////////////////////////////////////

export interface CurveOutputParameters extends OutputParameters<CurveLike> {} // workaround for recursive type

@registerNode
export class CurveOutput extends generateOutput<CurveLike, CurveObjectData>("object", {}, {nodeClass: "CurveOutput"}) {}

export type CurveOutputFwd = TemplateNode<CurveOutputParameters> & EvaluableTemplateNode<CurveObjectData>

////////////////////////////////////////////////////////////////////

export interface MaterialOutputParameters extends OutputParameters<MaterialLike> {} // workaround for recursive type

@registerNode
export class MaterialOutput extends generateOutput<MaterialLike, IMaterialGraph>("material", {}, {nodeClass: "MaterialOutput"}) {}

export type MaterialOutputFwd = TemplateNode<MaterialOutputParameters> & EvaluableTemplateNode<IMaterialGraph>

////////////////////////////////////////////////////////////////////

export interface TemplateOutputParameters extends OutputParameters<TemplateLike> {} // workaround for recursive type

@registerNode
export class TemplateOutput extends generateOutput<TemplateLike, TemplateData>("template", {}, {nodeClass: "TemplateOutput"}) {}

export type TemplateOutputFwd = TemplateNode<TemplateOutputParameters> & EvaluableTemplateNode<TemplateData>

////////////////////////////////////////////////////////////////////

export interface ImageOutputParameters extends OutputParameters<ImageLike> {} // workaround for recursive type

const ImageOutputBaseClass: NodeGraphClass<TemplateNode<OutputParameters<ImageLike>> & EvaluableTemplateNode<ImageGenerator | null>> = generateOutput<
    ImageLike,
    ImageGenerator
>("image", {}, {nodeClass: "ImageOutput"})

@registerNode
export class ImageOutput extends ImageOutputBaseClass {}

export type ImageOutputFwd = TemplateNode<ImageOutputParameters> & EvaluableTemplateNode<ImageGenerator>

////////////////////////////////////////////////////////////////////

export type StringOutputParameters = OutputParameters<string>

@registerNode
export class StringOutput extends generateOutput<string, string>("string", {}, {nodeClass: "StringOutput"}) {}

export type StringOutputFwd = TemplateNode<StringOutputParameters> & EvaluableTemplateNode<string>

////////////////////////////////////////////////////////////////////

export type NumberOutputParameters = OutputParameters<number>

@registerNode
export class NumberOutput extends generateOutput<number, number>("number", {}, {nodeClass: "NumberOutput"}) {}

export type NumberOutputFwd = TemplateNode<NumberOutputParameters> & EvaluableTemplateNode<number>

////////////////////////////////////////////////////////////////////

export type BooleanOutputParameters = OutputParameters<boolean>

@registerNode
export class BooleanOutput extends generateOutput<boolean, boolean>("boolean", {}, {nodeClass: "BooleanOutput"}) {}

export type BooleanOutputFwd = TemplateNode<BooleanOutputParameters> & EvaluableTemplateNode<boolean>

////////////////////////////////////////////////////////////////////

export type JSONOutputParameters = OutputParameters<AnyJSONValue>

@registerNode
export class JSONOutput extends generateOutput<AnyJSONValue, AnyJSONValue>("json", {}, {nodeClass: "JSONOutput"}) {}

export type JSONOutputFwd = TemplateNode<JSONOutputParameters> & EvaluableTemplateNode<AnyJSONValue>
