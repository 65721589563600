import {inject, Injectable, signal, Signal} from "@angular/core"
import {toObservable, toSignal} from "@angular/core/rxjs-interop"
import {ActivatedRoute, Router} from "@angular/router"
import {PagingParams} from "@common/models/data/types"
import {combineLatest, map, Observable, take} from "rxjs"

@Injectable({
    providedIn: "root",
})
export class PagingService {
    readonly route = inject(ActivatedRoute)
    readonly router = inject(Router)

    params$: Observable<PagingParams>
    page$: Observable<number>
    pageSize$: Observable<number>
    isPaged$: Observable<boolean>
    totalCount$: Observable<number>

    $params: Signal<PagingParams>
    $isPaged: Signal<boolean>
    $page: Signal<number>
    $pageSize: Signal<number>
    $totalCount = signal(0)

    constructor() {
        this.page$ = this.route.queryParams.pipe(map((params) => parseInt(params.page || "0")))
        this.pageSize$ = this.route.queryParams.pipe(map((params) => parseInt(params.pageSize || "10")))
        this.isPaged$ = this.route.queryParams.pipe(map((params) => parseInt(params.isPaged || "1") !== 0))
        this.params$ = combineLatest([this.isPaged$, this.page$, this.pageSize$]).pipe(
            map(([isPaged, page, pageSize]) => ({
                isPaged,
                page,
                pageSize,
            })),
        )
        this.totalCount$ = toObservable(this.$totalCount)

        this.$params = toSignal(this.params$, {
            initialValue: {isPaged: false, page: 0, pageSize: 10, totalCount: undefined},
        })
        this.$page = toSignal(this.page$, {initialValue: 0})
        this.$pageSize = toSignal(this.pageSize$, {initialValue: 10})
        this.$isPaged = toSignal(this.isPaged$, {initialValue: false})
    }

    changePage = async (page: number) => this.changeParams({page})
    changePageSize = async (pageSize: number) => this.changeParams({pageSize})
    changeParams = async (params: {page?: number; pageSize?: number}) => {
        return this.router.navigate([], {
            relativeTo: this.route,
            replaceUrl: true,
            queryParams: params,
            queryParamsHandling: "merge",
        })
    }

    setIsPaged = (isPaged: boolean) =>
        this.router.navigate([], {
            relativeTo: this.route,
            replaceUrl: true,
            queryParams: {isPaged: isPaged ? "1" : "0"},
            queryParamsHandling: "merge",
        })

    setTotalCount = (totalCount: number) => this.$totalCount.set(totalCount)

    get totalCount() {
        return this.$totalCount()
    }

    // set the initial page size - unless it is already set in the query params
    setDefaultPageSize(pageSize: number) {
        this.route.queryParams.pipe(take(1)).subscribe(async (params) => {
            if (params.pageSize === undefined) {
                await this.changePageSize(pageSize)
            }
        })
    }
}
