<div class="cm-page-not-found-container">
    <mat-card appearance="outlined">
        <mat-card-content>
            <div class="cm-icon-container">
                <i class="far fa-circle-exclamation"></i>
            </div>
        </mat-card-content>
        <mat-card-title>Page not found.</mat-card-title>
    </mat-card>
</div>
