import {CommonModule} from "@angular/common"
import {AfterViewInit, Component, computed, DestroyRef, ElementRef, inject, Input, input, OnDestroy, output, signal} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ImageColorSpace} from "@generated"
import {AuthService} from "@app/common/services/auth/auth.service"
import {UploadProcessingService} from "@app/common/services/upload/upload-processing.service"
import {
    TextureThumbnailViewDataObjectDetailsFragment,
    TextureThumbnailViewDataObjectFragment,
    TextureThumbnailViewQueryDataObjectGQL,
    TextureThumbnailViewUpdateDataObjectGQL,
} from "@app/textures/texture-set-revision-view/texture-thumbnail-view/texture-thumbnail-view.generated"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {Settings} from "@common/models/settings/settings"
import {FilesService} from "@common/services/files/files.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {interval, Subject, takeUntil} from "rxjs"
import {ThumbnailComponent} from "@common/components/thumbnail/thumbnail.component"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {ThumbnailLayout} from "@common/models/enums/thumbnail-layout"
import {MimeType} from "@legacy/helpers/utils"
import {DropFilesComponent} from "@common/components/files"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {deepCopy} from "@cm/utils"

const POLL_THUMBNAIL_TIMEOUT = 1000

@Component({
    selector: "cm-texture-thumbnail-view",
    templateUrl: "./texture-thumbnail-view.component.html",
    styleUrl: "./texture-thumbnail-view.component.scss",
    imports: [CommonModule, MatTooltipModule, MatMenuModule, ThumbnailComponent, DataObjectThumbnailComponent, DropFilesComponent],
})
export class TextureThumbnailViewComponent implements AfterViewInit, OnDestroy {
    readonly $label = input("", {alias: "label"})

    @Input() set dataObjectId(value: string | undefined) {
        void this.loadDataObject(value)
    }

    readonly $isUploading = input(false, {alias: "isUploading"})
    readonly $canDrop = input(false, {alias: "canDrop"})
    readonly init = output<EventTarget>()
    readonly load = output<Event>()
    readonly thumbnailAvailable = output<TextureThumbnailViewDataObjectDetailsFragment>()
    readonly fileDropped = output<File>()

    readonly permission = inject(PermissionsService)
    readonly organization = inject(OrganizationsService)
    $can = this.permission.$to

    readonly textureThumbnailViewQueryDataObject = inject(TextureThumbnailViewQueryDataObjectGQL)
    readonly textureThumbnailViewUpdateDataObject = inject(TextureThumbnailViewUpdateDataObjectGQL)

    protected readonly $mouseHoovering = signal<boolean>(false)
    protected readonly $showDropzone = computed(() => this.$mouseHoovering() && this.$canDrop())

    constructor(
        private destroyRef: DestroyRef,
        private elementRef: ElementRef,
        protected authService: AuthService,
        private uploadProcessingService: UploadProcessingService,
    ) {}

    ngAfterViewInit(): void {
        this.init.emit(this.elementRef.nativeElement)
    }

    ngOnDestroy(): void {
        this._loadingNewTexture.complete()
        this._dataObjectUpdated.complete()
    }

    private async loadDataObject(dataObjectId: string | undefined) {
        if (this._dataObjectId === dataObjectId) {
            return
        }
        this._loadingNewTexture.next()
        this._dataObjectId = dataObjectId
        if (this._dataObjectId) {
            const result = await fetchThrowingErrors(this.textureThumbnailViewQueryDataObject)({dataObjectId: this._dataObjectId})
            this.dataObject = deepCopy(result.dataObject) // we make a copy here to create a writeable object as we might wanna change the color space
            if (this.dataObject.thumbnailAvailable) {
                this.thumbnailAvailable.emit(this.dataObject)
            } else {
                interval(POLL_THUMBNAIL_TIMEOUT)
                    .pipe(takeUntilDestroyed(this.destroyRef), takeUntil(this._loadingNewTexture), takeUntil(this._dataObjectUpdated))
                    .subscribe(() => this.pollUpdatedDataObject(this.dataObject!))
            }
        } else {
            this.dataObject = undefined
        }
    }

    private async pollUpdatedDataObject(dataObject: TextureThumbnailViewDataObjectFragment) {
        const updatedDataObject = await fetchThrowingErrors(this.textureThumbnailViewQueryDataObject)({dataObjectId: dataObject.id})
        if (updatedDataObject.dataObject.thumbnailAvailable) {
            this.dataObject = updatedDataObject.dataObject
            this._dataObjectUpdated.next()
            this.thumbnailAvailable.emit(this.dataObject)
        }
    }

    protected colorSpaceIsUnknown(dataObject: TextureThumbnailViewDataObjectFragment): boolean {
        return (dataObject.imageColorSpace ?? ImageColorSpace.Unknown) === ImageColorSpace.Unknown
    }

    protected downloadDataObject(dataObject: TextureThumbnailViewDataObjectDetailsFragment): void {
        FilesService.downloadFile(dataObject.originalFileName, dataObject.downloadUrl)
    }

    protected describeDataObject(dataObject: TextureThumbnailViewDataObjectDetailsFragment): string {
        let typeName: string
        switch (dataObject.mediaType) {
            case "image/x-exr":
                typeName = "EXR"
                break
            case "image/tiff":
                typeName = "TIFF"
                break
            case "image/jpeg":
                typeName = "JPEG"
                break
            default:
                typeName = `${dataObject.mediaType}`
                break
        }
        const colorSpaceName = dataObject.imageColorSpace?.toString() ?? ImageColorSpace.Unknown.toString()
        return `${dataObject.width ?? "?"} x ${dataObject.height ?? "?"} ${typeName} (${colorSpaceName})`
    }

    protected async setOriginalColorspace(dataObject: TextureThumbnailViewDataObjectDetailsFragment, colorSpace: ImageColorSpace) {
        dataObject.imageColorSpace = colorSpace
        await mutateThrowingErrors(this.textureThumbnailViewUpdateDataObject)({input: {id: dataObject.id, imageColorSpace: colorSpace}})
        await this.uploadProcessingService.createUploadProcessingJob(dataObject.legacyId, dataObject.organization.id)
    }

    protected onFilesSelected(event: Event) {
        const inputElement = event.target as HTMLInputElement
        if (inputElement.files && inputElement.files.length > 0) {
            this.fileDropped.emit(inputElement.files[0])
        }
    }

    protected onFilesDropped(event: File[]) {
        if (event.length > 0) {
            this.fileDropped.emit(event[0])
        }
    }

    protected imageColorSpace = ImageColorSpace
    protected dataObject: TextureThumbnailViewDataObjectFragment | undefined = undefined

    private _loadingNewTexture = new Subject<void>()
    private _dataObjectUpdated = new Subject<void>()
    private _dataObjectId: string | undefined = undefined
    protected readonly Settings = Settings
    protected readonly ThumbnailLayout = ThumbnailLayout
    protected readonly MimeType = MimeType
}
