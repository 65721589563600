import {DeclareTemplateNodeTS} from "#template-nodes/declare-template-node"
import {TemplateNode} from "#template-nodes/types"
import {registerNode} from "@cm/graph/register-node"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {IMaterialGraph} from "@cm/material-nodes/interfaces/material-data"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {z} from "zod"
import {skipped, visitNone} from "@cm/graph/declare-visitor-node"
import {MaterialGraphNode, wrapNodeOutput} from "@cm/material-nodes/material-node-graph"
import {hashObject} from "@cm/utils"
import {imageLike} from "#template-nodes/node-types"

const moveMaterialParameters = z.object({
    backgroundImage: imageLike.nullable(),
})
export type MoveMaterialParameters = z.infer<typeof moveMaterialParameters>

@registerNode
export class BackgroundMaterial
    extends DeclareTemplateNodeTS<MoveMaterialParameters>(
        {
            validation: {paramsSchema: moveMaterialParameters},
            onVisited: {
                onFilterActive: ({parameters}) => {
                    if (parameters.backgroundImage === null) return skipped
                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "BackgroundMaterial"},
    )
    implements EvaluableTemplateNode<IMaterialGraph | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        const backgroundImage = evaluator.evaluateImage(scope, this.parameters.backgroundImage)

        return scope.pureLambda(
            backgroundImage,
            (backgroundImage) => {
                if (!backgroundImage) return null

                const texCoord: MaterialGraphNode<"ShaderNodeTexCoord"> = {
                    nodeType: "ShaderNodeTexCoord",
                }

                const mapping: MaterialGraphNode<"Mapping"> = {
                    nodeType: "Mapping",
                    inputs: {
                        Vector: wrapNodeOutput(texCoord, "Window"),
                    },
                    parameters: {
                        "internal.vectorType": "POINT",
                        "internal.translation": [0, 0, 0],
                        "internal.rotation": [0, 0, 0],
                        "internal.scale": [1, 1, 1],
                    },
                }

                const {color} = backgroundImage.imageNode.generator({
                    uv: wrapNodeOutput(mapping, "Vector"),
                    extension: "REPEAT",
                    interpolation: "Linear",
                    projection: "FLAT",
                })

                const shader: MaterialGraphNode<"ShaderNodeBsdfDiffuse"> = {
                    nodeType: "ShaderNodeBsdfDiffuse",
                    inputs: {
                        Color: color,
                    },
                    parameters: {},
                }

                const output: MaterialGraphNode<"OutputMaterial"> = {
                    nodeType: "OutputMaterial",
                    inputs: {
                        Surface: wrapNodeOutput(shader, "BSDF"),
                    },
                }

                return {
                    uniqueId: hashObject(output),
                    rootNode: output,
                    materialRevisionId: 0,
                    materialId: 0,
                    name: `BackgroundMaterial-${backgroundImage.imageNode.hash}`,
                }
            },
            `backgroundImage`,
        )
    }
}

export type BackgroundMaterialFwd = TemplateNode<MoveMaterialParameters> & EvaluableTemplateNode<IMaterialGraph | null>
