import {Primitives as Prim, Operators as Op, AttributeRef, ValueLiteral, GeomBuilderContext} from "#template-nodes/geometry-processing/geometry-graph"
import {Matrix4, Matrix3x2} from "@cm/math"

//////////////////////////////
//TODO: move these to some utility module, or unify with common vector types

export type Vec3Literal = readonly [number, number, number]
export type Vec2Literal = readonly [number, number]

export function orientation(a: Vec2Literal, b: Vec2Literal, c: Vec2Literal, eps = 1e-9) {
    const det = (b[1] - a[1]) * (c[0] - b[0]) - (b[0] - a[0]) * (c[1] - b[1])
    const ort = det > eps ? 1 : det < -eps ? -1 : 0
    return ort
}

export function centroid(points: Vec2Literal[]): Vec2Literal {
    let x = 0
    let y = 0
    for (const p of points) {
        x += p[0]
        y += p[1]
    }
    const sc = 1 / points.length
    return [x * sc, y * sc]
}

export function midpoint(a: Vec2Literal, b: Vec2Literal): Vec2Literal {
    return [(a[0] + b[0]) / 2, (a[1] + b[1]) / 2]
}

export function lerp(a: Vec2Literal, b: Vec2Literal, t: number): Vec2Literal {
    return [a[0] + (b[0] - a[0]) * t, a[1] + (b[1] - a[1]) * t]
}

export function scale(a: Vec2Literal, s: number): Vec2Literal {
    return [a[0] * s, a[1] * s]
}

//////////////////////////////

export type ShapeBasis = {
    posMatrix: Matrix4
    uvMatrix: Matrix3x2
}

export function makeShapeBasis(): ShapeBasis {
    return {
        posMatrix: Matrix4.identity(),
        uvMatrix: Matrix3x2.identity(),
    }
}

export function transformBasis2D(
    basis: ShapeBasis,
    posOrigin: Vec2Literal,
    x: Vec2Literal,
    y: Vec2Literal,
    uvOrigin?: Vec2Literal,
    u?: Vec2Literal,
    v?: Vec2Literal,
) {
    uvOrigin ??= posOrigin
    u ??= x
    v ??= y
    const posMatrix = Matrix4.fromArray([x[0], x[1], 0, 0, y[0], y[1], 0, 0, 0, 0, 1, 0, posOrigin[0], posOrigin[1], 0, 1])
    const uvMatrix = Matrix3x2.fromArray([u[0], u[1], v[0], v[1], uvOrigin[0], uvOrigin[1]])
    return {
        posMatrix: basis.posMatrix.multiply(posMatrix),
        uvMatrix: basis.uvMatrix.multiply(uvMatrix),
    }
}

export function transformBasis3D(
    basis: ShapeBasis,
    posOrigin: Vec3Literal,
    x: Vec3Literal,
    y: Vec3Literal,
    z: Vec3Literal,
    uvOrigin?: Vec2Literal,
    u?: Vec2Literal,
    v?: Vec2Literal,
) {
    uvOrigin ??= [posOrigin[0], posOrigin[1]]
    u ??= [x[0], x[1]]
    v ??= [y[0], y[1]]
    const posMatrix = Matrix4.fromArray([x[0], x[1], x[2], 0, y[0], y[1], y[2], 0, z[0], z[1], z[2], 0, posOrigin[0], posOrigin[1], posOrigin[2], 1])
    const uvMatrix = Matrix3x2.fromArray([u[0], u[1], v[0], v[1], uvOrigin[0], uvOrigin[1]])
    return {
        posMatrix: basis.posMatrix.multiply(posMatrix),
        uvMatrix: basis.uvMatrix.multiply(uvMatrix),
    }
}

function positionFromBasis(basis: ShapeBasis, x: AttributeRef, y: AttributeRef, z?: AttributeRef) {
    const e = basis.posMatrix.toArray()
    const basisX = e.slice(0, 3) as unknown as Vec3Literal
    const basisY = e.slice(4, 7) as unknown as Vec3Literal
    const basisZ = e.slice(8, 11) as unknown as Vec3Literal
    const origin = e.slice(12, 15) as unknown as Vec3Literal
    let pos: AttributeRef
    if (z) {
        pos = x.mul(basisX).add(y.mul(basisY)).add(z.mul(basisZ))
    } else {
        pos = x.mul(basisX).add(y.mul(basisY))
    }
    if (Math.abs(origin[0]) > 1e-9 || Math.abs(origin[1]) > 1e-9 || Math.abs(origin[2]) > 1e-9) {
        pos = pos.add(origin)
    }
    return pos
}

function texUVFromBasis(basis: ShapeBasis, u: AttributeRef, v: AttributeRef) {
    const e = basis.uvMatrix.toArray()
    const basisU = e.slice(0, 2) as unknown as Vec2Literal
    const basisV = e.slice(2, 4) as unknown as Vec2Literal
    const origin = e.slice(4, 6) as unknown as Vec2Literal
    let texUV = u.mul(basisU).add(v.mul(basisV))
    if (Math.abs(origin[0]) > 1e-9 || Math.abs(origin[1]) > 1e-9) {
        texUV = texUV.add(origin)
    }
    return texUV
}

function flipIfInverted<T>(geom: T, basis: ShapeBasis): T {
    if (basis.posMatrix.determinant() < 0) {
        return Op.flip(geom)
    } else {
        return geom
    }
}

export function makeLoop(ctx: GeomBuilderContext, segmentCount: number): AttributeRef {
    return Prim.line1(ctx, 0, 1, segmentCount)
}

export function flipLoop(loop: AttributeRef): AttributeRef {
    //TODO: cache flipped loops to avoid redundant computation
    return Op.flip(loop.oneMinus())
}

type BilinearWeights = {
    w00: AttributeRef
    w10: AttributeRef
    w01: AttributeRef
    w11: AttributeRef
}

function bilinearWeights(a: AttributeRef, b: AttributeRef) {
    const ia = a.oneMinus()
    const ib = b.oneMinus()
    const w00 = ia.mul(ib)
    const w10 = a.mul(ib)
    const w01 = ia.mul(b)
    const w11 = a.mul(b)
    return {w00, w10, w01, w11}
}

function applyBilinear(
    w: BilinearWeights,
    v00: AttributeRef | ValueLiteral,
    v10: AttributeRef | ValueLiteral,
    v01: AttributeRef | ValueLiteral,
    v11: AttributeRef | ValueLiteral,
) {
    return w.w00.mul(v00).add(w.w10.mul(v10)).add(w.w01.mul(v01)).add(w.w11.mul(v11))
}

function bilinear(x: AttributeRef, y: AttributeRef, v00: ValueLiteral, v10: ValueLiteral, v01: ValueLiteral, v11: ValueLiteral) {
    const a00 = x.constFloat(v00)
    const a10 = x.constFloat(v10)
    const a01 = x.constFloat(v01)
    const a11 = x.constFloat(v11)
    const ix = x.oneMinus()
    const iy = y.oneMinus()
    const a00_10 = a00.mul(ix).add(a10.mul(x))
    const a01_11 = a01.mul(ix).add(a11.mul(x))
    return a00_10.mul(iy).add(a01_11.mul(y))
}

export function square(basis: ShapeBasis, loopA: AttributeRef, loopB: AttributeRef, manCoords: readonly [Vec2Literal, Vec2Literal, Vec2Literal, Vec2Literal]) {
    const {a, b} = Op.disjointProduct(loopA, {a: loopA}, loopB, {b: loopB})
    return flipIfInverted(
        {
            pos: positionFromBasis(basis, a, b),
            texUV: texUVFromBasis(basis, a, b),
            manUV: bilinear(a, b, manCoords[0], manCoords[3], manCoords[1], manCoords[2]),
        },
        basis,
    )
}

export function quarterDisc(
    basis: ShapeBasis,
    loopA: AttributeRef,
    loopB: AttributeRef,
    loopC: AttributeRef,
    manCoords: readonly [Vec2Literal, Vec2Literal, Vec2Literal, Vec2Literal, Vec2Literal, Vec2Literal],
) {
    // loopA: u coord 0 to 0.5, angle from 0 to 45
    // loopB: v coord 0 to 0.5, angle from 90 to 45
    // loopC: u coord 0.5 to 1, v coord 0.5 to 1
    // manCoords[0]: origin
    // manCoords[1]: middle of left edge
    // manCoords[2]: start of curve / top of left edge
    // manCoords[3]: middle of curve
    // manCoords[4]: end of curve / right of bottom edge
    // manCoords[5]: middle of bottom edge

    const [mc0, mc1, mc2, mc3, mc4, mc5] = manCoords

    // mc[0] and mc[3] must be on opposite sides of the line from mc[1] to mc[5]
    if (orientation(mc1, mc5, mc0) === orientation(mc1, mc5, mc3)) {
        throw new Error(`Invalid corner coordinates: ${manCoords}`)
    }
    //TODO: estimate the warping caused by "inverted" mc shapes, find a better way to place intermediate points in the higher-level shapes

    const curvedPatch = (loopA: AttributeRef, loopR: AttributeRef, ma0r0: Vec2Literal, ma0r1: Vec2Literal, ma1r1: Vec2Literal, ma1r0: Vec2Literal) => {
        const {a, r} = Op.disjointProduct(loopA, {a: loopA}, loopR, {r: loopR})
        const k = 0.4267766953 // (sqrt(2)/4 + 1/2)/2
        const lin1 = a.mul([k, k - 0.5]).add([0, 0.5])
        const theta = a.mul(Math.PI / 4)
        const lin2 = theta
            .cos()
            .mul([0, 1])
            .add(theta.sin().mul([1, 0]))
        const xy = lin1.add(lin2.sub(lin1).mul(r))
        return {
            xy,
            manUV: bilinear(a, r, ma0r0, ma1r0, ma0r1, ma1r1),
        }
    }

    const flatPatch = (loopA: AttributeRef, loopB: AttributeRef, ma0b0: Vec2Literal, ma0b1: Vec2Literal, ma1b1: Vec2Literal, ma1b0: Vec2Literal) => {
        const {a, b} = Op.disjointProduct(loopA, {a: loopA}, loopB, {b: loopB})
        const k = 0.4267766953 // (sqrt(2)/4 + 1/2)/2
        const kofs = a.mul(b).mul(k * 2 - 1)
        return {
            xy: Op.pack(a, b).add(kofs).mul(0.5),
            manUV: bilinear(a, b, ma0b0, ma1b0, ma0b1, ma1b1),
        }
    }

    // choosing the centroid of the 1-3-5 triangle will always result in convex patches, but the shape may be heavily distorted
    const mcCentroid = centroid([mc1, mc3, mc5])

    const patch1 = curvedPatch(loopA, loopC, mc1, mc2, mc3, mcCentroid)
    const patch2 = curvedPatch(loopB, loopC, mc5, mc4, mc3, mcCentroid)
    const patch3 = flatPatch(loopA, loopB, mc0, mc1, mcCentroid, mc5)

    patch2.xy = patch2.xy.permute(1, 0)

    const merged = Op.merge(patch1, Op.flip(patch2), patch3)
    return flipIfInverted(
        {
            pos: positionFromBasis(basis, merged.xy.x, merged.xy.y),
            texUV: texUVFromBasis(basis, merged.xy.x, merged.xy.y),
            manUV: merged.manUV,
        },
        basis,
    )
}

export type RoundedRectBorderManifoldCoords = {
    outer: readonly [
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
    ]
    inner: readonly [Vec2Literal, Vec2Literal, Vec2Literal, Vec2Literal]
}

export function roundedRectBorder(
    basis: ShapeBasis,
    loopA: AttributeRef,
    loopB: AttributeRef,
    loopC: AttributeRef,
    loopD: AttributeRef,
    loopE: AttributeRef,
    loopF: AttributeRef,
    innerLoop: AttributeRef,
    innerW: number,
    innerH: number,
    radius: number,
    manCoords: RoundedRectBorderManifoldCoords,
) {
    let bottomLeft = quarterDisc(transformBasis2D(basis, [-innerW / 2, -innerH / 2], [-radius, 0], [0, -radius]), flipLoop(loopC), loopF, innerLoop, [
        manCoords.inner[0],
        midpoint(manCoords.inner[0], manCoords.outer[11]),
        manCoords.outer[11],
        manCoords.outer[0],
        manCoords.outer[1],
        midpoint(manCoords.outer[1], manCoords.inner[0]),
    ])
    let topLeft = quarterDisc(transformBasis2D(basis, [-innerW / 2, innerH / 2], [0, radius], [-radius, 0]), loopB, loopC, innerLoop, [
        manCoords.inner[1],
        midpoint(manCoords.inner[1], manCoords.outer[2]),
        manCoords.outer[2],
        manCoords.outer[3],
        manCoords.outer[4],
        midpoint(manCoords.outer[4], manCoords.inner[1]),
    ])
    let topRight = quarterDisc(transformBasis2D(basis, [innerW / 2, innerH / 2], [radius, 0], [0, radius]), loopE, flipLoop(loopB), innerLoop, [
        manCoords.inner[2],
        midpoint(manCoords.inner[2], manCoords.outer[5]),
        manCoords.outer[5],
        manCoords.outer[6],
        manCoords.outer[7],
        midpoint(manCoords.outer[7], manCoords.inner[2]),
    ])
    let bottomRight = quarterDisc(transformBasis2D(basis, [innerW / 2, -innerH / 2], [0, -radius], [radius, 0]), loopF, flipLoop(loopE), innerLoop, [
        manCoords.inner[3],
        midpoint(manCoords.inner[3], manCoords.outer[8]),
        manCoords.outer[8],
        manCoords.outer[9],
        manCoords.outer[10],
        midpoint(manCoords.outer[10], manCoords.inner[3]),
    ])
    let leftOuter = square(transformBasis2D(basis, [-innerW / 2 - radius / 2, -innerH / 2], [0, innerH], [-radius / 2, 0]), loopA, innerLoop, [
        midpoint(manCoords.outer[1], manCoords.inner[0]),
        manCoords.outer[1],
        manCoords.outer[2],
        midpoint(manCoords.inner[1], manCoords.outer[2]),
    ])
    let leftInner = square(transformBasis2D(basis, [-innerW / 2, -innerH / 2], [0, innerH], [-radius / 2, 0]), loopA, flipLoop(loopC), [
        manCoords.inner[0],
        midpoint(manCoords.outer[1], manCoords.inner[0]),
        midpoint(manCoords.inner[1], manCoords.outer[2]),
        manCoords.inner[1],
    ])
    let topOuter = square(transformBasis2D(basis, [-innerW / 2, innerH / 2 + radius / 2], [innerW, 0], [0, radius / 2]), loopD, innerLoop, [
        midpoint(manCoords.outer[4], manCoords.inner[1]),
        manCoords.outer[4],
        manCoords.outer[5],
        midpoint(manCoords.inner[2], manCoords.outer[5]),
    ])
    let topInner = square(transformBasis2D(basis, [-innerW / 2, innerH / 2], [innerW, 0], [0, radius / 2]), loopD, loopB, [
        manCoords.inner[1],
        midpoint(manCoords.outer[4], manCoords.inner[1]),
        midpoint(manCoords.inner[2], manCoords.outer[5]),
        manCoords.inner[2],
    ])
    let rightOuter = square(transformBasis2D(basis, [innerW / 2 + radius / 2, innerH / 2], [0, -innerH], [radius / 2, 0]), flipLoop(loopA), innerLoop, [
        midpoint(manCoords.outer[7], manCoords.inner[2]),
        manCoords.outer[7],
        manCoords.outer[8],
        midpoint(manCoords.inner[3], manCoords.outer[8]),
    ])
    let rightInner = square(transformBasis2D(basis, [innerW / 2, innerH / 2], [0, -innerH], [radius / 2, 0]), flipLoop(loopA), loopE, [
        manCoords.inner[2],
        midpoint(manCoords.outer[7], manCoords.inner[2]),
        midpoint(manCoords.inner[3], manCoords.outer[8]),
        manCoords.inner[3],
    ])
    let bottomOuter = square(transformBasis2D(basis, [innerW / 2, -innerH / 2 - radius / 2], [-innerW, 0], [0, -radius / 2]), flipLoop(loopD), innerLoop, [
        midpoint(manCoords.outer[10], manCoords.inner[3]),
        manCoords.outer[10],
        manCoords.outer[11],
        midpoint(manCoords.inner[0], manCoords.outer[11]),
    ])
    let bottomInner = square(transformBasis2D(basis, [innerW / 2, -innerH / 2], [-innerW, 0], [0, -radius / 2]), flipLoop(loopD), flipLoop(loopF), [
        manCoords.inner[3],
        midpoint(manCoords.outer[10], manCoords.inner[3]),
        midpoint(manCoords.inner[0], manCoords.outer[11]),
        manCoords.inner[0],
    ])
    return Op.merge(bottomLeft, topLeft, topRight, bottomRight, leftOuter, leftInner, topOuter, topInner, rightOuter, rightInner, bottomOuter, bottomInner)
}

export function dualRadiusQuarterCyl(
    basis: ShapeBasis,
    loopA: AttributeRef,
    loopB: AttributeRef,
    loopC: AttributeRef,
    h: number,
    manCoords: readonly [Vec2Literal, Vec2Literal, Vec2Literal, Vec2Literal, Vec2Literal, Vec2Literal],
) {
    // loopA: theta from 0 to 45
    // loopB: theta from 45 to 90
    // loopC: phi from 0 to 90
    // manCoords[0]: inside corner (center)
    // manCoords[1]: left center
    // manCoords[2]: top left
    // manCoords[3]: top right
    // manCoords[4]: bottom right
    // manCoords[5]: bottom center

    let {a1, b1} = Op.disjointProduct(loopC, {b1: loopC}, loopA, {a1: loopA})
    const man1 = bilinear(a1, b1, manCoords[2], manCoords[3], manCoords[1], manCoords[0])
    a1 = a1.mul(0.5)
    const theta1 = a1.mul(Math.PI / 2)
    const phi1 = b1.mul(Math.PI / 2)
    const p1 = phi1
        .cos()
        .mul(h)
        .add(1 - h)
    const theta1cos = theta1.cos()
    const theta1sin = theta1.sin()
    const y1 = p1.mul(theta1cos)
    const z1 = phi1.sin().mul(h)
    const x1 = p1.mul(theta1sin)
    const r1 = b1.mul((-h * Math.PI) / 2).add(1 + h * (Math.PI / 2 - 1)) // account for arc length
    const u1 = theta1sin.mul(r1)
    const v1 = theta1cos.mul(r1)

    let {a2, b2} = Op.disjointProduct(loopC, {b2: loopC}, loopB, {a2: loopB})
    const man2 = bilinear(a2, b2, manCoords[3], manCoords[4], manCoords[0], manCoords[5])
    a2 = a2.mul(0.5).add(0.5)
    const theta2 = a2.mul(Math.PI / 2)
    const phi2 = b2.mul(Math.PI / 2)
    const theta2cos = theta2.cos()
    const theta2sin = theta2.sin()
    const p2 = phi2
        .cos()
        .mul(h)
        .add(1 - h)
    const y2 = p2.mul(theta2cos)
    const z2 = phi2.sin().mul(h)
    const x2 = p2.mul(theta2sin)
    const r2 = b2.mul((-h * Math.PI) / 2).add(1 + h * (Math.PI / 2 - 1)) // account for arc length
    const u2 = theta2sin.mul(r2)
    const v2 = theta2cos.mul(r2)

    return flipIfInverted(
        Op.merge(
            {pos: positionFromBasis(basis, x1, y1, z1), texUV: texUVFromBasis(basis, u1, v1), manUV: man1},
            {pos: positionFromBasis(basis, x2, y2, z2), texUV: texUVFromBasis(basis, u2, v2), manUV: man2},
        ),
        basis,
    )
}

export type DualRadiusBorderManifoldCoords = {
    outer: readonly [
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
    ]
    inner: readonly [
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
    ]
}

export function dualRadiusBorder(
    basis: ShapeBasis,
    loopA: AttributeRef,
    loopB: AttributeRef,
    loopC: AttributeRef,
    loopD: AttributeRef,
    loopE: AttributeRef,
    loopF: AttributeRef,
    innerLoop: AttributeRef,
    innerW: number,
    innerH: number,
    thetaRadius: number,
    phiRadius: number,
    manCoords: DualRadiusBorderManifoldCoords,
) {
    const ratio = phiRadius / thetaRadius
    const bottomLeft = dualRadiusQuarterCyl(
        transformBasis3D(basis, [-innerW / 2, -innerH / 2, 0], [-thetaRadius, 0, 0], [0, -thetaRadius, 0], [0, 0, thetaRadius]),
        flipLoop(loopC),
        loopF,
        innerLoop,
        ratio,
        [manCoords.inner[0], manCoords.inner[11], manCoords.outer[11], manCoords.outer[0], manCoords.outer[1], manCoords.inner[1]],
    )
    const topLeft = dualRadiusQuarterCyl(
        transformBasis3D(basis, [-innerW / 2, innerH / 2, 0], [0, thetaRadius, 0], [-thetaRadius, 0, 0], [0, 0, thetaRadius]),
        loopB,
        loopC,
        innerLoop,
        ratio,
        [manCoords.inner[3], manCoords.inner[2], manCoords.outer[2], manCoords.outer[3], manCoords.outer[4], manCoords.inner[4]],
    )
    const topRight = dualRadiusQuarterCyl(
        transformBasis3D(basis, [innerW / 2, innerH / 2, 0], [thetaRadius, 0, 0], [0, thetaRadius, 0], [0, 0, thetaRadius]),
        loopE,
        flipLoop(loopB),
        innerLoop,
        ratio,
        [manCoords.inner[6], manCoords.inner[5], manCoords.outer[5], manCoords.outer[6], manCoords.outer[7], manCoords.inner[7]],
    )
    const bottomRight = dualRadiusQuarterCyl(
        transformBasis3D(basis, [innerW / 2, -innerH / 2, 0], [0, -thetaRadius, 0], [thetaRadius, 0, 0], [0, 0, thetaRadius]),
        loopF,
        flipLoop(loopE),
        innerLoop,
        ratio,
        [manCoords.inner[9], manCoords.inner[8], manCoords.outer[8], manCoords.outer[9], manCoords.outer[10], manCoords.inner[10]],
    )
    const left = roundedEdge(
        transformBasis3D(basis, [-(innerW / 2 + (thetaRadius - phiRadius)), innerH / 2, phiRadius], [-phiRadius, 0, 0], [0, -innerH, 0], [0, 0, phiRadius]),
        innerLoop,
        flipLoop(loopA),
        [manCoords.inner[2], manCoords.inner[1], manCoords.outer[1], manCoords.outer[2]],
    )
    const top = roundedEdge(
        transformBasis3D(basis, [innerW / 2, innerH / 2 + (thetaRadius - phiRadius), phiRadius], [0, phiRadius, 0], [-innerW, 0, 0], [0, 0, phiRadius]),
        innerLoop,
        loopD,
        [manCoords.inner[5], manCoords.inner[4], manCoords.outer[4], manCoords.outer[5]],
    )
    const right = roundedEdge(
        transformBasis3D(basis, [innerW / 2 + (thetaRadius - phiRadius), -innerH / 2, phiRadius], [phiRadius, 0, 0], [0, innerH, 0], [0, 0, phiRadius]),
        innerLoop,
        flipLoop(loopA),
        [manCoords.inner[8], manCoords.inner[7], manCoords.outer[7], manCoords.outer[8]],
    )
    const bottom = roundedEdge(
        transformBasis3D(basis, [-innerW / 2, -(innerH / 2 + (thetaRadius - phiRadius)), phiRadius], [0, -phiRadius, 0], [innerW, 0, 0], [0, 0, phiRadius]),
        innerLoop,
        flipLoop(loopD),
        [manCoords.inner[11], manCoords.inner[10], manCoords.outer[10], manCoords.outer[11]],
    )
    return Op.merge(bottomLeft, topLeft, topRight, bottomRight, left, top, right, bottom)
}

export function roundedEdge(
    basis: ShapeBasis,
    loopA: AttributeRef,
    loopB: AttributeRef,
    manCoords: readonly [Vec2Literal, Vec2Literal, Vec2Literal, Vec2Literal],
) {
    let {a, b} = Op.disjointProduct(loopA, {a: loopA}, loopB, {b: loopB})
    const phi = a.mul(Math.PI / 2)
    const y = b
    const x = phi.sin()
    const z = phi.cos().add(-1)
    const u = phi
    const v = b
    return flipIfInverted(
        {
            pos: positionFromBasis(basis, x, y, z),
            texUV: texUVFromBasis(basis, u, v),
            manUV: bilinear(a, b, manCoords[0], manCoords[3], manCoords[1], manCoords[2]),
        },
        basis,
    )
}

export function roundedHalfEdge(
    basis: ShapeBasis,
    loopA: AttributeRef,
    loopB: AttributeRef,
    manCoords: readonly [Vec2Literal, Vec2Literal, Vec2Literal, Vec2Literal],
) {
    let {a, b} = Op.disjointProduct(loopA, {a: loopA}, loopB, {b: loopB})
    const phi = a.mul(Math.PI / 4)
    const y = b
    const x = phi.sin()
    const z = phi.cos().add(-1)
    const u = phi
    const v = b
    return flipIfInverted(
        {
            pos: positionFromBasis(basis, x, y, z),
            texUV: texUVFromBasis(basis, u, v),
            manUV: bilinear(a, b, manCoords[0], manCoords[3], manCoords[1], manCoords[2]),
        },
        basis,
    )
}

export function puncturedSquare(
    basis: ShapeBasis,
    loopA: AttributeRef,
    loopB: AttributeRef,
    loopC: AttributeRef,
    innerW: number,
    innerH: number,
    outerManCoords: readonly [Vec2Literal, Vec2Literal, Vec2Literal, Vec2Literal],
    innerManCoords: readonly [Vec2Literal, Vec2Literal, Vec2Literal, Vec2Literal],
) {
    // loopA: horizontal
    // loopB: vertical
    // loopC: ring
    const ac = Op.disjointProduct(loopA, {a: loopA}, loopC, {c: loopC})
    const bc = Op.disjointProduct(loopB, {b: loopB}, loopC, {c: loopC})
    const acW = bilinearWeights(ac.a, ac.c)
    const bcW = bilinearWeights(bc.b, bc.c)
    const halfW = innerW / 2
    const halfH = innerH / 2
    const merged = Op.merge(
        {
            xy: applyBilinear(acW, [0, 0], [1, 0], [0.5 - halfW, 0.5 - halfH], [0.5 + halfW, 0.5 - halfH]),
            manUV: applyBilinear(acW, outerManCoords[0], outerManCoords[3], innerManCoords[0], innerManCoords[3]),
        },
        Op.flip({
            xy: applyBilinear(acW, [0, 1], [1, 1], [0.5 - halfW, 0.5 + halfH], [0.5 + halfW, 0.5 + halfH]),
            manUV: applyBilinear(acW, outerManCoords[1], outerManCoords[2], innerManCoords[1], innerManCoords[2]),
        }),
        Op.flip({
            xy: applyBilinear(bcW, [0, 0], [0, 1], [0.5 - halfW, 0.5 - halfH], [0.5 - halfW, 0.5 + halfH]),
            manUV: applyBilinear(bcW, outerManCoords[0], outerManCoords[1], innerManCoords[0], innerManCoords[1]),
        }),
        {
            xy: applyBilinear(bcW, [1, 0], [1, 1], [0.5 + halfW, 0.5 - halfH], [0.5 + halfW, 0.5 + halfH]),
            manUV: applyBilinear(bcW, outerManCoords[3], outerManCoords[2], innerManCoords[3], innerManCoords[2]),
        },
    )
    return flipIfInverted(
        {
            pos: positionFromBasis(basis, merged.xy.x, merged.xy.y),
            texUV: texUVFromBasis(basis, merged.xy.x, merged.xy.y),
            manUV: merged.manUV,
        },
        basis,
    )
}
