import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"
import {ColorLike, Vector2, Vector2Like} from "@cm/math"
import paper from "paper"
import {LineItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/basic/line-item"

export class ArrowItem extends CanvasBaseToolboxItemBase {
    constructor(parentItem: CanvasBaseToolboxItem, from: Vector2Like, to: Vector2Like, width: number, color: ColorLike) {
        super(parentItem)

        this._lineItem = new LineItem(this, [], width, color, "piecewise-linear")
        this._from = Vector2.fromVector2Like(from)
        this._to = Vector2.fromVector2Like(to)
        this._width = width
        this._color = new paper.Color(color.r, color.g, color.b, color.a)
        this.createArrowPath()

        this.viewChange.subscribe(() => this.updateZoomDependent())
    }

    private updateZoomDependent() {
        this.createArrowPath()
    }

    private createArrowPath() {
        this.beginPaperCreation()

        this._rayArrowLine?.remove()

        this._lineItem.setPoints([this._from, this._to])

        const dir = this._to.sub(this._from).normalized()
        const dirPerp = dir.perp()
        const arrowLength = this.arrowLengthInScreenSpace / this.zoomLevel
        const arrowWidth = arrowLength * 0.5
        this._rayArrowLine = new paper.Path([
            this._to.sub(dir.mul(arrowLength)).add(dirPerp.mul(arrowWidth)),
            this._to,
            this._to.sub(dir.mul(arrowLength)).add(dirPerp.mul(-arrowWidth)),
        ])
        this._rayArrowLine.strokeWidth = this._width / this.zoomLevel
        this._rayArrowLine.strokeColor = this._color
    }

    private readonly arrowLengthInScreenSpace = 16
    private _lineItem: LineItem
    private _rayArrowLine?: paper.Path
    private _from: Vector2
    private _to: Vector2
    private _width: number
    private _color: paper.Color
}
