import {Component, computed, DestroyRef, inject, OnInit} from "@angular/core"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"
import {MatButtonModule} from "@angular/material/button"
import {MatIconModule} from "@angular/material/icon"
import {MatMenuModule} from "@angular/material/menu"
import {MatTableModule} from "@angular/material/table"
import {MatTooltipModule} from "@angular/material/tooltip"
import {InfiniteScrollAnchorComponent, OverflowableTextComponent} from "@common/components/data"
import {TableCellComponent} from "@common/components/tables/table-cell/table-cell.component"
import {TypedMatCellDefDirective} from "@common/directives"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {keepLoadingMore} from "@common/helpers/data/keep-loading"
import {BreakpointsService} from "@common/services/breakpoints/breakpoints.service"
import {ContentTypeModel} from "@generated"
import {ContentLoaderModule} from "@ngneat/content-loader"
import {DeleteTagGQL} from "@pages/tags/tags-page.generated"
import {BaseTableComponent} from "@platform/components/base/base-table/base-table.component"
import {TagThumbnailComponent} from "@platform/components/tags/tag-thumbnail/tag-thumbnail.component"
import {GetTagsTableItemsGQL, TagsTableItemFragment} from "@platform/components/tags/tags-table/tags-table.generated"
import {combineLatest, of, Subject, switchMap, map} from "rxjs"
import {maybeConstrainTagFilterOnTagsPage} from "@common/helpers/filters/tag"
import {OrganizationsService} from "@app/common/services/organizations/organizations.service"

@Component({
    imports: [
        ContentLoaderModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatTableModule,
        MatTooltipModule,
        TypedMatCellDefDirective,
        TagThumbnailComponent,
        TableCellComponent,
        InfiniteScrollAnchorComponent,
        OverflowableTextComponent,
    ],
    selector: "cm-tags-table",
    styleUrl: "./tags-table.component.scss",
    templateUrl: "./tags-table.component.html",
})
export class TagsTableComponent extends BaseTableComponent<TagsTableItemFragment> implements OnInit {
    readonly breakpoints = inject(BreakpointsService)
    readonly destroyRef = inject(DestroyRef)

    needsToLoadMore = false

    readonly itemsGql = inject(GetTagsTableItemsGQL)
    readonly deleteTagGql = inject(DeleteTagGQL)

    readonly $columns = computed(() => {
        if (this.breakpoints.$isDesktop()) {
            return ["legacyId", "color", "name", "type", "organization", "description", "assignmentsCount", "actions"]
        } else {
            return ["legacyId", "color", "name", "type", "actions"]
        }
    })

    loadMore$ = new Subject<boolean>()
    organizations = inject(OrganizationsService)

    readonly $data = toSignal(
        this.dataLoader.batchedData$<TagsTableItemFragment>(
            combineLatest([this.filters.tagFilter$, this.sortOrder.tags$, this.refresh.observeAllContentTypeModel$(ContentTypeModel.Tag)]).pipe(
                map(([filter, order]) => [maybeConstrainTagFilterOnTagsPage(filter, this.organizations, this.permission), order] as const),
                switchMap(([filter, order]) =>
                    of((skip: number, take: number) => {
                        return fetchThrowingErrors(this.itemsGql)({skip, take, filter, order})
                    }),
                ),
            ),
            keepLoadingMore(this.loadMore$),
        ),
        {
            requireSync: true,
        },
    )

    async deleteItem(tag: TagsTableItemFragment) {
        const confirmed = await this.notification.confirmationDialog({
            title: `Delete ${tag.name}`,
            message: `Are you sure you want to delete ${tag.name}?`,
            confirm: "Delete",
            cancel: "Cancel",
            isDestructive: true,
        })
        if (confirmed) {
            await this.notification.withUserFeedback(
                async () => {
                    await mutateThrowingErrors(this.deleteTagGql)(tag)
                    // might as well refresh all, because the deleted tag was probably visible
                    // it's easiest to just refresh everything
                    this.refresh.contentTypeModel(ContentTypeModel.Tag)
                },
                {success: "Tag deleted.", error: "Tag could not be deleted. Is it still assigned to something?"},
            )
        }
    }

    loadMore(value: boolean) {
        this.loadMore$.next(value)
    }

    ngOnInit() {
        // subscribe to updates from the refresh service
        // this allows other components to trigger a refresh of a single item
        this.refresh.itemSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((_item) => this.refresh.contentTypeModel(ContentTypeModel.Tag))
    }
}
