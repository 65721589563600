import {Component, signal} from "@angular/core"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {ButtonComponent} from "@app/common/components/buttons/button/button.component"
import {RoutedDialogComponent} from "@app/common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@app/common/models/dialogs"
import {ConfiguratorTestIframeComponent} from "@app/test/manual-testing/configurator-test-iframe/configurator-test-iframe.component"
import {ConfiguratorTestWCComponent} from "@app/test/manual-testing/configurator-test-webcomponent/configurator-test-wc.component"
import {Source, sourceSchema} from "@app/test/manual-testing/types/types"
import {TippyDirective} from "@ngneat/helipopper"
import {MaterialDownloadTestComponent} from "@app/test/manual-testing/material-download/material-download.component"
import {SceneManagerService} from "@template-editor/services/scene-manager.service"

@Component({
    selector: "cm-test-base",
    imports: [
        RoutedDialogComponent,
        ButtonComponent,
        ConfiguratorTestIframeComponent,
        ConfiguratorTestWCComponent,
        TippyDirective,
        MatInputModule,
        MatSelectModule,
        MaterialDownloadTestComponent,
    ],
    templateUrl: "./test-base.component.html",
    providers: [SceneManagerService],
    styleUrl: "./test-base.component.scss",
})
export class TestBaseComponent {
    dialogSizes = DialogSize
    sources = sourceSchema.options

    readonly $viewType = signal<"ConfiguratorIframe" | "ConfiguratorWebcomponents" | "MaterialDownload">("ConfiguratorIframe")
    readonly $source = signal<Source>("prod")

    constructor() {}
}
