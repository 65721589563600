import * as Types from '../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TextureGroupDetailsFragment = { __typename: 'TextureGroup', id: string, legacyId: number, meshSpecific: boolean, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, material?: { __typename: 'Material', id: string, legacyId: number } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, name?: string | null, jobAssignments: Array<{ __typename: 'JobAssignment', id: string }> }> };

export type TextureGroupDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type TextureGroupDetailsQuery = { __typename: 'Query', item: { __typename: 'TextureGroup', id: string, legacyId: number, meshSpecific: boolean, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, material?: { __typename: 'Material', id: string, legacyId: number } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, name?: string | null, jobAssignments: Array<{ __typename: 'JobAssignment', id: string }> }> } };

export type CreateTextureGroupMutationVariables = Types.Exact<{
  input: Types.MutationCreateTextureGroupInput;
}>;


export type CreateTextureGroupMutation = { __typename: 'Mutation', createTextureGroup: { __typename: 'TextureGroup', id: string, legacyId: number, meshSpecific: boolean, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, material?: { __typename: 'Material', id: string, legacyId: number } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, name?: string | null, jobAssignments: Array<{ __typename: 'JobAssignment', id: string }> }> } };

export type TextureGroupDetailsDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type TextureGroupDetailsDeleteMutation = { __typename: 'Mutation', deleteTextureGroup?: any | null };

export type UpdateTextureGroupDetailsMutationVariables = Types.Exact<{
  input: Types.MutationUpdateTextureGroupInput;
}>;


export type UpdateTextureGroupDetailsMutation = { __typename: 'Mutation', item: { __typename: 'TextureGroup', id: string, legacyId: number, meshSpecific: boolean, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, material?: { __typename: 'Material', id: string, legacyId: number } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, textureSets: Array<{ __typename: 'TextureSet', id: string, legacyId: number, name?: string | null, jobAssignments: Array<{ __typename: 'JobAssignment', id: string }> }> } };

export type TextureGroupDetailsCreateTextureSetMutationVariables = Types.Exact<{
  input: Types.MutationCreateTextureSetInput;
}>;


export type TextureGroupDetailsCreateTextureSetMutation = { __typename: 'Mutation', createTextureSet: { __typename: 'TextureSet', id: string, legacyId: number, name?: string | null } };

export const TextureGroupDetailsFragmentDoc = gql`
    fragment TextureGroupDetails on TextureGroup {
  __typename
  id
  legacyId
  meshSpecific
  name
  galleryImage {
    id
  }
  material {
    id
    legacyId
  }
  organization {
    id
    legacyId
    name
  }
  textureSets {
    id
    legacyId
    name
    jobAssignments {
      id
    }
  }
}
    `;
export const TextureGroupDetailsDocument = gql`
    query textureGroupDetails($id: ID!) {
  item: textureGroup(id: $id) {
    ...TextureGroupDetails
  }
}
    ${TextureGroupDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureGroupDetailsGQL extends Apollo.Query<TextureGroupDetailsQuery, TextureGroupDetailsQueryVariables> {
    override document = TextureGroupDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTextureGroupDocument = gql`
    mutation createTextureGroup($input: MutationCreateTextureGroupInput!) {
  createTextureGroup(input: $input) {
    ...TextureGroupDetails
  }
}
    ${TextureGroupDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTextureGroupGQL extends Apollo.Mutation<CreateTextureGroupMutation, CreateTextureGroupMutationVariables> {
    override document = CreateTextureGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureGroupDetailsDeleteDocument = gql`
    mutation textureGroupDetailsDelete($id: ID!) {
  deleteTextureGroup(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureGroupDetailsDeleteGQL extends Apollo.Mutation<TextureGroupDetailsDeleteMutation, TextureGroupDetailsDeleteMutationVariables> {
    override document = TextureGroupDetailsDeleteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTextureGroupDetailsDocument = gql`
    mutation updateTextureGroupDetails($input: MutationUpdateTextureGroupInput!) {
  item: updateTextureGroup(input: $input) {
    ...TextureGroupDetails
  }
}
    ${TextureGroupDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTextureGroupDetailsGQL extends Apollo.Mutation<UpdateTextureGroupDetailsMutation, UpdateTextureGroupDetailsMutationVariables> {
    override document = UpdateTextureGroupDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureGroupDetailsCreateTextureSetDocument = gql`
    mutation textureGroupDetailsCreateTextureSet($input: MutationCreateTextureSetInput!) {
  createTextureSet(input: $input) {
    id
    legacyId
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureGroupDetailsCreateTextureSetGQL extends Apollo.Mutation<TextureGroupDetailsCreateTextureSetMutation, TextureGroupDetailsCreateTextureSetMutationVariables> {
    override document = TextureGroupDetailsCreateTextureSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }