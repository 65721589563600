import {StateLabel} from "@platform/models/state-labels/state-label"
import {ModelState} from "@generated"

export const ModelStateLabels: Map<ModelState, StateLabel<ModelState>> = new Map<ModelState, StateLabel<ModelState>>([
    [ModelState.Draft, {state: ModelState.Draft, label: "Draft", background: "#989898"}],
    [ModelState.InfoReview, {state: ModelState.InfoReview, label: "Info review", background: "#989898"}],
    [ModelState.Modelling, {state: ModelState.Modelling, label: "Modelling", background: "#ffab4a"}],
    [ModelState.Review, {state: ModelState.Review, label: "Review", background: "#7cbcb0"}],
    [ModelState.OnHold, {state: ModelState.OnHold, label: "On hold", background: "#000000"}],
    [ModelState.Completed, {state: ModelState.Completed, label: "Completed", background: "#7ec17a"}],
])
