<div class="cm-top-label cm-label">
    <ng-content select="[cm-top-label]"> </ng-content>
</div>
<div
    class="cm-input-container"
    [style.background]="$backgroundColor()"
    [style.border]="$borderColor().length > 0 ? '1px solid ' + $borderColor() : undefined"
    [class.cm-warning]="$warning()"
>
    <div class="cm-inline-label cm-label">
        <ng-content select="[cm-inline-label]"> </ng-content>
    </div>
    <div class="cm-input">
        <ng-content select="[cm-input]"> </ng-content>
    </div>
</div>
