import {ChangeDetectionStrategy, Component, Input, input, OnChanges} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {fullName} from "@common/helpers/utils/user"
import {NextActor} from "@generated"
import {NextActorLabels, StateLabel} from "@labels"
import {AssignUserFragment} from "@platform/components/users/assign-user/assign-user.generated"
import {UserThumbnailComponent} from "@platform/components/users/user-thumbnail/user-thumbnail.component"

@Component({
    selector: "cm-assign-user",
    templateUrl: "./assign-user.component.html",
    styleUrls: ["./assign-user.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatTooltipModule, UserThumbnailComponent],
})
export class AssignUserComponent implements OnChanges {
    $user = input<AssignUserFragment | undefined | null>(undefined, {alias: "user"})
    @Input() nextActor?: NextActor

    public nextActorLabel?: StateLabel<NextActor>
    public assignedUserName: string | undefined

    ngOnChanges() {
        this.update()
    }

    update() {
        const user = this.$user()
        this.assignedUserName = user ? fullName(user) : undefined
        this.nextActorLabel = this.nextActor ? NextActorLabels.get(this.nextActor) : undefined
    }
}
