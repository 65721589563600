import {Component, inject, input} from "@angular/core"
import {Params, RouterLink} from "@angular/router"
import {AuthService} from "@common/services/auth/auth.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"

@Component({
    selector: "cm-navigation-item",
    templateUrl: "./navigation-item.component.html",
    styleUrls: ["./navigation-item.component.scss"],
    imports: [RouterLink],
})
export class NavigationItemComponent {
    readonly $active = input<boolean | undefined>(false, {alias: "active"})
    readonly $link = input<string[] | null>(undefined, {alias: "link"})
    readonly $params = input<Params | null>(null, {alias: "params"})
    readonly $filterForCustomer = input<boolean | undefined>(false, {alias: "filterForCustomer"})

    readonly permission = inject(PermissionsService)
    readonly organizations = inject(OrganizationsService)
    $can = this.permission.$to

    constructor(public auth: AuthService) {}
}
