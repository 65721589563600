<cm-section [indentContent]="false">
    <ng-container cm-icon><i class="far fa-check-square"></i></ng-container>
    <ng-container cm-title>
        <div class="cm-task-title">
            Tasks
            <cm-toggle [toggle]="tasks.$showCompleted()" (toggleChange)="tasks.setShowCompleted($event)">
                <ng-container cm-text>Show completed</ng-container>
            </cm-toggle>
        </div>
    </ng-container>
    <ng-container cm-content>
        <cm-task-list [item]="$item()" (addNewTask)="onAddNewTask.emit()"></cm-task-list>
    </ng-container>
</cm-section>
