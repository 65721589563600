<ng-content></ng-content>
<cm-inspector-section>
    <ng-container cm-title> Post Process Render </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="$node()" [key]="'render'" [label]="'Render input'"></cm-value-slot>
        <cm-value-slot [node]="$node()" [key]="'ev'" [label]="'Exposure'" [decimalPlaces]="2"></cm-value-slot>
        <cm-value-slot [node]="$node()" [key]="'whiteBalance'" [label]="'White B.'" [decimalPlaces]="0"></cm-value-slot>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Tone Mapping </ng-container>
    <ng-container cm-content>
        <cm-tone-mapping-inspector [node]="$node()"> </cm-tone-mapping-inspector>
        <cm-value-slot [node]="$node()" [key]="'lutUrl'" [label]="'LUT'" [selectionPossibilities]="lutPossibilites"></cm-value-slot>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Background </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="$node()" [key]="'transparent'" [label]="'Transparent'"></cm-value-slot>
        <cm-value-slot [node]="$node()" [key]="'composite'" [label]="'Composite'"></cm-value-slot>
        @if ($parameters().composite) {
            <cm-value-slot [node]="$node()" [key]="'backgroundColor'" [label]="'Background Color'"></cm-value-slot>
            <div class="cm-mask-settings">
                <cm-value-slot [node]="$node()" [key]="'backgroundImage'" [label]="'Background Image'"></cm-value-slot>
                <cm-value-slot [node]="$node()" [key]="'mask'" [label]="'Background Mask'"></cm-value-slot>
            </div>
        }
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Shadows </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="$node()" [key]="'processShadows'" [label]="'Process Shadows'"></cm-value-slot>
        @if ($parameters().processShadows) {
            <cm-value-slot [node]="$node()" [key]="'shadowInner'" [label]="'Shadow Inner'" [decimalPlaces]="2"></cm-value-slot>
            <cm-value-slot [node]="$node()" [key]="'shadowOuter'" [label]="'Shadow Outer'" [decimalPlaces]="2"></cm-value-slot>
            <cm-value-slot [node]="$node()" [key]="'shadowFalloff'" [label]="'Shadow Falloff'" [decimalPlaces]="2"></cm-value-slot>
        }
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Cropping </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="$node()" [key]="'autoCrop'" [label]="'Auto Crop'"></cm-value-slot>
        @if ($parameters().autoCrop) {
            <cm-value-slot [node]="$node()" [key]="'autoCropMargin'" [label]="'Auto Crop Margin'" [decimalPlaces]="0"></cm-value-slot>
        }
    </ng-container>
</cm-inspector-section>
