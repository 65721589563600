import {Component, Inject, signal} from "@angular/core"
import {JobState, JobTaskState} from "@generated"
import {jobTaskStateIconClass} from "@common/helpers/jobs"
import {jobTaskStateColor} from "@common/helpers/jobs/color"
import {formatForDisplay, formatTimeDifference} from "@common/helpers/utils/dates"
import {TIPPY_REF, TippyInstance} from "@ngneat/helipopper"

@Component({
    selector: "cm-job-task-state-tooltip",
    imports: [],
    templateUrl: "./job-task-state-tooltip.component.html",
    styleUrl: "./job-task-state-tooltip.component.scss",
})
export class JobTaskStateTooltipComponent {
    readonly $jobTask = signal<{
        state: JobTaskState
        attemptCount?: number
        cancelled?: string
        deferredUntil?: string
        finished?: string
        maxAttempts?: number
        started?: string
        progress?: number
    } | null>(null)

    constructor(@Inject(TIPPY_REF) tippy: TippyInstance) {
        this.$jobTask.set(tippy.data)
    }

    protected readonly JobState = JobState
    protected readonly formatForDisplay = formatForDisplay
    protected readonly formatTimeDifference = formatTimeDifference
    protected readonly jobTaskStateIconClass = jobTaskStateIconClass
    protected readonly jobTaskStateColor = jobTaskStateColor
    protected readonly JobTaskState = JobTaskState
}
