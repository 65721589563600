import {registerNode} from "@cm/graph/register-node"
import {anyJsonValue} from "#template-nodes/types"
import {namedNodeParameters} from "#template-nodes/nodes/named-node"
import {DeclareMeshNode, maxSubdivisionLimit, TemplateMeshNode} from "#template-nodes/declare-mesh-node"
import {z} from "zod"
import {visitNone} from "@cm/graph/declare-visitor-node"
import {GenerateMesh} from "#template-nodes/runtime-graph/nodes/generate-mesh"
import {commonGeometryGraphTable} from "#template-nodes/geometry-processing/common-geometry-graphs"
import {geomToMesh} from "#template-nodes/geometry-processing/geometry-graph"
import {MeshNodes} from "@cm/render-nodes"
import {hashObject} from "@cm/utils"

function getGeometryGraph(geometryGraph: any, parameters: any): MeshNodes.Mesh | null {
    const fn = commonGeometryGraphTable[geometryGraph]
    return fn ? geomToMesh(fn(parameters ?? {})) : null
}

const proceduralMeshParameters = namedNodeParameters.merge(
    z.object({
        geometryGraph: z.string(),
        parameters: z.record(anyJsonValue),
    }),
)
export type ProceduralMeshParameters = z.infer<typeof proceduralMeshParameters>

@registerNode
export class ProceduralMesh extends DeclareMeshNode(
    {parameters: proceduralMeshParameters},
    {
        onVisited: {
            onCompile: function (this: ProceduralMeshFwd, {context, parameters}) {
                const {evaluator} = context
                const {templateContext} = evaluator
                const {sceneManager} = templateContext
                const {geometryGraph: presetGraphName, parameters: generateMeshParameters, subdivisionRenderIterations} = parameters

                const contentHash = hashObject({presetGraphName, generateMeshParameters, subdivisionRenderIterations}) // this is cheaper than hashing the graph itself
                const graph = getGeometryGraph(presetGraphName, generateMeshParameters)

                const displaySubdivLevel = this.getDisplaySubdivisionLevel(context)

                const renderSubdivLevel = Math.min(subdivisionRenderIterations ?? 0, maxSubdivisionLimit)

                if (graph) {
                    let displayGeometryGraph = graph
                    let renderGeometryGraph = graph

                    if (displaySubdivLevel > 0) {
                        displayGeometryGraph = {type: "subdivide", input: displayGeometryGraph, levels: displaySubdivLevel}
                    }

                    if (renderSubdivLevel > 0) {
                        renderGeometryGraph = {type: "subdivide", input: renderGeometryGraph, levels: renderSubdivLevel}
                    }

                    const scope = evaluator.getScope(this)
                    const {completeMeshData} = scope.node(GenerateMesh, {
                        sceneManager,
                        inputMeshData: {
                            contentHash,
                            displayGeometryGraph,
                            renderGeometryGraph,
                            displayGeometryGraphResources: {},
                        },
                    })

                    this.setupMesh(scope, context, completeMeshData, true)
                }

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "ProceduralMesh"},
) {}

export type ProceduralMeshFwd = TemplateMeshNode<ProceduralMeshParameters>
