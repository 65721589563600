import {PictureState} from "@generated"
import {StateLabel} from "@platform/models/state-labels/state-label"

export const PictureStateLabels: Map<PictureState, StateLabel<PictureState>> = new Map<PictureState, StateLabel<PictureState>>([
    [PictureState.Draft, {state: PictureState.Draft, label: "Draft", background: "#989898"}],
    [PictureState.InfoReview, {state: PictureState.InfoReview, label: "Info review", background: "#989898"}],
    [PictureState.Stage1, {state: PictureState.Stage1, label: "Concept", background: "#ffab4a"}],
    [PictureState.Stage2, {state: PictureState.Stage2, label: "Refinement", background: "#ffab4a"}],
    [PictureState.FinalRender, {state: PictureState.FinalRender, label: "Final render", background: "#7cbcb0"}],
    [PictureState.OnHold, {state: PictureState.OnHold, label: "On hold", background: "#000000"}],
    [PictureState.Completed, {state: PictureState.Completed, label: "Completed", background: "#7ec17a"}],
])
