import {Component, computed, input} from "@angular/core"
import {ActionPanelComponent} from "../../../common/components/menu/actions/action-panel/action-panel.component"
import {ActionPanelRowComponent} from "../../../common/components/menu/actions/action-panel-row/action-panel-row.component"
import {FilesizePipe} from "../../../common/pipes/filesize/filesize.pipe"
import {ActionBarComponent} from "../../../common/components/menu/actions/action-bar/action-bar.component"
import {ActionItemComponent} from "../../../common/components/menu/actions/action-item/action-item.component"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatMenuModule} from "@angular/material/menu"
import {TemplateImageViewerCanvasComponent} from "../template-image-viewer-canvas/template-image-viewer-canvas.component"
import {CanvasNavigation} from "@app/common/helpers/canvas/canvas-navigation"
import {isMobileDevice} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {FilesService} from "@app/common/services/files/files.service"

@Component({
    selector: "cm-template-image-viewer-controls",
    templateUrl: "./template-image-viewer-controls.component.html",
    styleUrl: "./template-image-viewer-controls.component.scss",
    imports: [ActionPanelComponent, ActionPanelRowComponent, FilesizePipe, ActionBarComponent, ActionItemComponent, MatTooltipModule, MatMenuModule],
})
export class TemplateImageViewerControlsComponent {
    readonly $parent = input.required<TemplateImageViewerCanvasComponent>({alias: "parent"})

    isMobileDevice = isMobileDevice

    readonly $postProcessedImageData = computed(() => {
        return this.$parent().$postProcessedImageData()
    })
    readonly $canvasBase = computed(() => {
        return this.$parent().$canvasBase()
    })
    navigation = (): CanvasNavigation | undefined => {
        return this.$canvasBase().navigation
    }
    readonly $dataObject = computed(() => {
        const postProcessedImageData = this.$postProcessedImageData()
        if (!postProcessedImageData) return undefined
        if (postProcessedImageData.type === "cached") {
            return postProcessedImageData.dataObject
        } else {
            return undefined
        }
    })
    readonly $imageData = computed(() => {
        const postProcessedImageData = this.$postProcessedImageData()
        if (!postProcessedImageData) return undefined
        if (postProcessedImageData.type === "computed") {
            return postProcessedImageData.imageData
        } else {
            return undefined
        }
    })
    readonly $fileName = computed(() => {
        return this.$dataObject()?.originalFileName
    })
    readonly $imageColorProfile = computed(() => {
        return this.$dataObject()?.imageColorProfile
    })
    readonly $size = computed(() => {
        return this.$imageData()?.data.length ?? this.$dataObject()?.size
    })
    readonly $width = computed(() => {
        return this.$imageData()?.width ?? this.$dataObject()?.width
    })
    readonly $height = computed(() => {
        return this.$imageData()?.height ?? this.$dataObject()?.height
    })

    getZoomLevel() {
        return Math.round((this.navigation()?.getPhysicalZoomLevel() ?? devicePixelRatio) * 100)
    }

    getFileTypeLabel() {
        const dataObject = this.$dataObject()
        if (!dataObject) return ""

        switch (dataObject.mediaType) {
            case "image/x-exr":
                return "EXR"
            case "image/tiff":
                return "TIFF"
            case "image/jpeg":
                return "JPEG"
            default:
                return dataObject.mediaType
        }
    }

    downloadOriginal() {
        const dataObject = this.$dataObject()
        if (!dataObject) return
        FilesService.downloadFile(dataObject.originalFileName, dataObject.downloadUrl)
    }

    downloadJpeg() {
        const dataObject = this.$dataObject()
        if (!dataObject) return
        const jpegUrl = dataObject.thumbnail?.downloadUrl ?? dataObject.downloadUrl
        FilesService.downloadFile(dataObject.originalFileName, jpegUrl)
    }

    downloadFromImageData(format: "png" | "jpeg") {
        const imageData = this.$imageData()
        if (!imageData) return

        const canvas = document.createElement("canvas")
        canvas.width = imageData.width
        canvas.height = imageData.height

        const context = canvas.getContext("2d")
        if (!context) return

        const type = `image/${format}`
        const ext = format === "jpeg" ? "jpg" : "png"

        context.putImageData(imageData, 0, 0)
        const dataUrl = canvas.toDataURL(type)
        FilesService.downloadFile(`snapshot.${ext}`, dataUrl.replace(type, "image/octet-stream"))
    }
}
