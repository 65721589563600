import {Component, input, computed, output} from "@angular/core"
import {RouterLink} from "@angular/router"
import {ButtonComponent} from "@common/components/buttons/button/button.component"

@Component({
    selector: "cm-show-assets-button",
    imports: [ButtonComponent, RouterLink],
    templateUrl: "./show-assets-button.component.html",
    styleUrl: "./show-assets-button.component.scss",
})
export class ShowAssetsButtonComponent {
    readonly $queryParamsKey = input<string | undefined>(undefined, {alias: "queryParamsKey"})
    readonly $entityId = input<string | undefined>(undefined, {alias: "entityId"})
    readonly $queryParams = computed(() => Object.fromEntries([[this.$queryParamsKey(), this.$entityId()]]))
    readonly activated = output<boolean>()
}
