import {getAllTemplateNodes} from "#template-nodes/utils"
import {deserializeNodeGraph, NodeParameters, SerializedNodeGraph} from "@cm/graph"
import {DataObjectReference, DataObjectReferenceParameters} from "@cm/template-nodes/nodes/data-object-reference"
import {HDRILight, HDRILightParameters} from "@cm/template-nodes/nodes/hdri-light"
import {MaterialReference, MaterialReferenceParameters} from "@cm/template-nodes/nodes/material-reference"
import {TemplateGraph} from "@cm/template-nodes/nodes/template-graph"
import {TemplateReference, TemplateReferenceParameters} from "@cm/template-nodes/nodes/template-reference"
import {DataObjectAssignmentType} from "@cm/utils/data-object-assignment"

export type TemplateGraphReferencedIds = {
    templateReferences: Set<number>
    materialReferences: Set<number>
    hdriReferences: Set<number>
    dataObjectAssignments: Map<number, Set<DataObjectAssignmentType>>
}

export function collectReferencesFromTemplateGraph(templateGraph: TemplateGraph): TemplateGraphReferencedIds {
    const templateReferences = new Set<number>()
    const materialReferences = new Set<number>()
    const hdriReferences = new Set<number>()
    const dataObjectAssignments = new Map<number, Set<DataObjectAssignmentType>>()

    for (const node of getAllTemplateNodes(templateGraph)) {
        if (node instanceof TemplateReference) {
            templateReferences.add(node.parameters.templateRevisionId)
        } else if (node instanceof MaterialReference) {
            materialReferences.add(node.parameters.materialRevisionId)
        } else if (node instanceof HDRILight) {
            hdriReferences.add(node.parameters.hdriId)
        } else if (node instanceof DataObjectReference) {
            dataObjectAssignments.set(
                node.parameters.dataObjectId,
                dataObjectAssignments.get(node.parameters.dataObjectId)?.add(DataObjectAssignmentType.TemplateDataOther) ??
                    new Set<DataObjectAssignmentType>([DataObjectAssignmentType.TemplateDataOther]),
            )
        }
    }

    return {templateReferences, materialReferences, hdriReferences, dataObjectAssignments}
}

export const loadTemplateGraph = (graphJson: any): TemplateGraph => {
    const templateGraph = deserializeNodeGraph(graphJson)
    if (!(templateGraph instanceof TemplateGraph)) throw new Error("Deserialized node is not a TemplateGraph")
    return templateGraph
}

/*Collects items referenced by a template. Items are extracted from the serialized node graph,
which makes this work even if the graph schemas are different.*/
export function collectReferencesFromSerializedNodeGraph(serializedGraph: SerializedNodeGraph) {
    const templateReferences = new Set<number>()
    const materialReferences = new Set<number>()
    const hdriReferences = new Set<number>()
    const dataObjectAssignments = new Map<number, Set<DataObjectAssignmentType>>()

    const traverse = (parameter: unknown) => {
        if (parameter instanceof Array) {
            for (const item of parameter) {
                traverse(item)
            }
        } else if (typeof parameter === "object" && parameter) {
            const {$class, $parameters, $refId, $version} = parameter as NodeParameters

            if (typeof $class === "string" && typeof $parameters === "object" && $parameters && typeof $refId === "number" && typeof $version === "number") {
                if ($class === TemplateReference.getNodeClass()) {
                    templateReferences.add(($parameters as TemplateReferenceParameters).templateRevisionId)
                } else if ($class === MaterialReference.getNodeClass()) {
                    materialReferences.add(($parameters as MaterialReferenceParameters).materialRevisionId)
                } else if ($class === HDRILight.getNodeClass()) {
                    hdriReferences.add(($parameters as HDRILightParameters).hdriId)
                } else if ($class === DataObjectReference.getNodeClass()) {
                    const dataObjectId = ($parameters as DataObjectReferenceParameters).dataObjectId
                    dataObjectAssignments.set(
                        dataObjectId,
                        dataObjectAssignments.get(dataObjectId)?.add(DataObjectAssignmentType.TemplateDataOther) ??
                            new Set<DataObjectAssignmentType>([DataObjectAssignmentType.TemplateDataOther]),
                    )
                }
                traverse($parameters)
            } else {
                Object.entries(parameter).forEach(([key, value]) => {
                    traverse(value)
                })
            }
        }
    }

    traverse(serializedGraph)

    return {templateReferences, materialReferences, hdriReferences, dataObjectAssignments}
}

export function compareTemplateGraphReferencedIds(result1: TemplateGraphReferencedIds, result2: TemplateGraphReferencedIds): void {
    function setsAreEqual<T>(set1: Set<T>, set2: Set<T>): boolean {
        if (set1.size !== set2.size) {
            return false
        }
        for (const item of set1) {
            if (!set2.has(item)) {
                return false
            }
        }
        return true
    }

    function mapsAreEqual<K, V>(map1: Map<K, Set<V>>, map2: Map<K, Set<V>>): boolean {
        if (map1.size !== map2.size) {
            return false
        }
        for (const [key, value] of map1) {
            if (!map2.has(key) || !setsAreEqual(value, map2.get(key)!)) {
                return false
            }
        }
        return true
    }

    if (!setsAreEqual(result1.templateReferences, result2.templateReferences)) throw new Error("templateReferences are not equal")
    if (!setsAreEqual(result1.materialReferences, result2.materialReferences)) throw new Error("materialReferences are not equal")
    if (!setsAreEqual(result1.hdriReferences, result2.hdriReferences)) throw new Error("hdriReferences are not equal")
    if (!mapsAreEqual(result1.dataObjectAssignments, result2.dataObjectAssignments)) throw new Error("dataObjectAssignments are not equal")
}
