import * as Types from '../../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TemplateDetailsForMaterialAssetsRenderingFragment = { __typename: 'Template', name?: string | null, legacyId: number, latestRevision?: { __typename: 'TemplateRevision', legacyId: number, graph?: any | null } | null };

export type GetTemplateDetailsForMaterialAssetsRenderingQueryVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type GetTemplateDetailsForMaterialAssetsRenderingQuery = { __typename: 'Query', template: { __typename: 'Template', name?: string | null, legacyId: number, latestRevision?: { __typename: 'TemplateRevision', legacyId: number, graph?: any | null } | null } };

export const TemplateDetailsForMaterialAssetsRenderingFragmentDoc = gql`
    fragment TemplateDetailsForMaterialAssetsRendering on Template {
  name
  legacyId
  latestRevision {
    legacyId
    graph
  }
}
    `;
export const GetTemplateDetailsForMaterialAssetsRenderingDocument = gql`
    query getTemplateDetailsForMaterialAssetsRendering($legacyId: Int!) {
  template(legacyId: $legacyId) {
    ...TemplateDetailsForMaterialAssetsRendering
  }
}
    ${TemplateDetailsForMaterialAssetsRenderingFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateDetailsForMaterialAssetsRenderingGQL extends Apollo.Query<GetTemplateDetailsForMaterialAssetsRenderingQuery, GetTemplateDetailsForMaterialAssetsRenderingQueryVariables> {
    override document = GetTemplateDetailsForMaterialAssetsRenderingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }