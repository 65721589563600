import {Component, viewChild} from "@angular/core"
import {ScannedTransmissionInputs, ScannedTransmissionOutputs} from "@app/material-editor/models/nodes/scanned-transmission"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-scanned-transmission-node",
    templateUrl: "./scanned-transmission-node.component.html",
    styleUrls: ["./scanned-transmission-node.component.scss"],
    imports: [NodeBaseComponent],
})
export class ScannedTransmissionNodeComponent {
    readonly $nodeBase = viewChild<MaterialNodeBase>("nodeBase")
    inputs = ScannedTransmissionInputs
    outputs = ScannedTransmissionOutputs
    typeInfo = ScannedTransmissionNodeType
}

export const ScannedTransmissionNodeType: MaterialNodeType<typeof ScannedTransmissionNodeComponent> = {
    id: "scannedTransmission",
    label: "Scanned Transmission",
    color: "#4987af",
    name: "ShaderNodeScannedTransmission",
    inputs: [
        ScannedTransmissionInputs.bsdf,
        ScannedTransmissionInputs.alpha,
        ScannedTransmissionInputs.normal,
        ScannedTransmissionInputs.transmission,
        ScannedTransmissionInputs.normalStrength,
        ScannedTransmissionInputs.minTransmission,
        ScannedTransmissionInputs.amount,
    ] as never[],
    outputs: [ScannedTransmissionOutputs.bsdf],
    component: ScannedTransmissionNodeComponent,
}
