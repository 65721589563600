import {HalImage, HalImageVirtual} from "@common/models/hal/hal-image"
import {HalContext} from "@common/models/hal/hal-context"
import {WebGl2Context, WebGl2ImagePhysical} from "@common/models/webgl2"
import {Box2Like} from "@cm/math"
import {WebGl2ImageView} from "@common/models/webgl2/webgl2-image-view"
import {WebGl2ImageVirtual} from "@common/models/webgl2/webgl2-image-virtual"
import {HalImageDescriptor} from "@common/models/hal/hal-image/types"

export const createHalImage = (context: HalContext, descriptor: HalImageDescriptor): HalImage => {
    if (context instanceof WebGl2Context) {
        return new WebGl2ImagePhysical(context, descriptor)
    }
    throw Error("Unsupported context")
}

export const createHalImageVirtual = (context: HalContext, descriptor: HalImageDescriptor): HalImageVirtual => {
    if (context instanceof WebGl2Context) {
        return new WebGl2ImageVirtual(context, descriptor)
    }
    throw Error("Unsupported context")
}

export const createHalImageView = (image: HalImage, region: Box2Like): HalImage => {
    if (image instanceof WebGl2ImagePhysical || image instanceof WebGl2ImageVirtual) {
        return new WebGl2ImageView(image, region)
    }
    throw Error("Unsupported context")
}
