import {NgTemplateOutlet} from "@angular/common"
import {Component, computed, DestroyRef, ElementRef, forwardRef, inject, input, OnInit, output, signal} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {NativeInputTextAreaComponent} from "@app/common/components/inputs/native/native-input-text-area/native-input-text-area.component"
import {getNodeIconClass, getNodeIconSeconaryClass} from "@app/template-editor/helpers/template-icons"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {TemplateNodeClipboardService} from "@app/template-editor/services/template-node-clipboard.service"
import {getDropPositionFromPosition, TemplateDropTarget, TemplateNodeDragService} from "@app/template-editor/services/template-node-drag.service"
import {jsonSchema, mapGraphParameters, NodeParameters} from "@cm/graph/node-graph"
import {Matrix4} from "@cm/math"
import {
    BooleanValue,
    DeclareTemplateNodeTS,
    getTemplateNodeClassLabel,
    imageLikeClasses,
    isImageLike,
    isMaterialLike,
    isMesh,
    isTemplateNode,
    JSONValue,
    MaterialAssignment,
    MaterialAssignments,
    MaterialReference,
    nodeClasses,
    NumberValue,
    Parameters,
    StringResolve,
    StringValue,
    TemplateInstance,
    TemplateNode,
} from "@cm/template-nodes"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {ColorInputComponent} from "@common/components/inputs/color-input/color-input.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {SliderComponent} from "@common/components/inputs/slider/slider.component"
import {StringInputComponent} from "@common/components/inputs/string-input/string-input.component"
import {ListItemComponent} from "@common/components/item/list-item/list-item.component"
import {JSONInputComponent} from "@common/components/json-input/json-input.component"
import {TemplateNodeComponent} from "app/template-editor/components/template-node/template-node.component"
import {z} from "zod"
import {ImageInspectorComponent} from "../inspectors/image-inspector/image-inspector.component"
import {MaterialAssignmentInspectorComponent} from "../inspectors/material-assignment-inspector/material-assignment-inspector.component"
import {MaterialAssignmentsInspectorComponent} from "../inspectors/material-assignments-inspector/material-assignments-inspector.component"
import {MaterialInspectorComponent} from "../inspectors/material-inspector/material-inspector.component"
import {NumericUpDownArrowsComponent} from "@common/components/inputs/numeric-up-down-arrows/numeric-up-down-arrows.component"

export type SelectionPossibilityValue<T = unknown> = T | (() => T)
export function resolveSelectionPossibilityValue<T>(value: SelectionPossibilityValue<T>): T {
    if (typeof value === "function") return (value as () => T)()
    return value
}
export type SelectionPossibility<T = unknown> = {
    name: string
    value: SelectionPossibilityValue<T>
    actions?: {iconClass: string; toolTip?: string; fn: () => void}[]
}
export type SelectionPossibilities<T = unknown> = SelectionPossibility<T>[]

const checkedNodeClasses = [...nodeClasses, MaterialAssignment.getNodeClass()]

@Component({
    selector: "cm-value-slot",
    templateUrl: "./value-slot.component.html",
    styleUrls: ["./value-slot.component.scss", "../../helpers/template-icons.scss"],
    imports: [
        MatTooltipModule,
        NumericInputComponent,
        NgTemplateOutlet,
        TemplateNodeComponent,
        forwardRef(() => MaterialAssignmentsInspectorComponent),
        forwardRef(() => MaterialAssignmentInspectorComponent),
        ImageInspectorComponent,
        StringInputComponent,
        ColorInputComponent,
        JSONInputComponent,
        InputContainerComponent,
        ToggleComponent,
        MatMenuModule,
        ListItemComponent,
        NativeInputTextAreaComponent,
        SliderComponent,
        MaterialInspectorComponent,
        NumericUpDownArrowsComponent,
    ],
})
export class ValueSlotComponent<T extends TemplateNode<ParamType>, ParamType extends {} = {}> implements OnInit {
    readonly $node = input.required<T>({alias: "node"})
    readonly $key = input.required<keyof T["parameters"]>({alias: "key"})
    readonly $subKey = input<string | number | Array<string | number> | undefined>(undefined, {alias: "subKey"})
    readonly $label = input<string | undefined>(undefined, {alias: "label"})
    readonly $icon = input<string | undefined>(undefined, {alias: "icon"})
    readonly $fallbackText = input("-", {alias: "fallbackText"})
    readonly $schema = input<z.ZodTypeAny | undefined>(undefined, {alias: "schema"})
    readonly $selectionPossibilities = input<SelectionPossibilities | undefined>(undefined, {alias: "selectionPossibilities"})
    resolveSelectionPossibilityValue = resolveSelectionPossibilityValue
    readonly $isSelected = input<(selectionPossibility: SelectionPossibility<any>, value: any) => boolean>(
        (selectionPossibility, value) => resolveSelectionPossibilityValue(selectionPossibility.value) === value,
        {alias: "isSelected"},
    )
    readonly $topLabel = input(false, {alias: "topLabel"})

    readonly $throttledUpdate = input(false, {alias: "throttledUpdate"})

    readonly $decimalPlaces = input(2, {alias: "decimalPlaces"})
    readonly $min = input<number | undefined>(undefined, {alias: "min"})
    readonly $max = input<number | undefined>(undefined, {alias: "max"})
    readonly $numberModifier = input<"slider" | "arrows" | undefined>(undefined, {alias: "numberModifier"})
    readonly $delta = input<number | undefined>(undefined, {alias: "delta"})
    readonly $validate = input<((x: string) => boolean) | undefined>(undefined, {alias: "validate"})
    readonly $minRows = input(1, {alias: "minRows"})
    readonly $changeSideEffect = input<((value: unknown) => void) | undefined>(undefined, {alias: "changeSideEffect"})

    readonly $overwrittenValue = input<unknown | undefined>(undefined, {alias: "overwrittenValue"})
    readonly updatedOverwrittenValue = output<unknown>()
    readonly onChanged = output<unknown>()

    readonly requestUpdate = output<unknown>()
    JSON = JSON

    protected readonly destroyRef = inject(DestroyRef)
    protected readonly sceneManagerService = inject(SceneManagerService)
    readonly drag = inject(TemplateNodeDragService)
    private readonly clipboardService = inject(TemplateNodeClipboardService)
    private readonly $triggerRecompute = signal(0)
    private elementRef = inject<ElementRef<HTMLElement>>(ElementRef)
    StringResolve = StringResolve

    readonly $labelText = computed(() => {
        const label = this.$label()
        if (label && label.length > 0) return `${label}: `
        return undefined
    })
    readonly $labelTooltipText = computed(() => {
        const label = this.$label()
        const acceptedValuesText = this.$acceptedValuesText()
        if (label && acceptedValuesText.length > 0) return `${label} (${acceptedValuesText})`
        else if (label) return label
        else if (acceptedValuesText.length > 0) return acceptedValuesText
        else return ""
    })
    readonly $tooltipText = computed(() => {
        const property = this.$property()
        if (property === undefined || property === null) return ""
        if (typeof property === "string") {
            if (property.length === 0) return ""
            else return property
        }
        return JSON.stringify(property, null, 2)
    })
    readonly $parameter = computed(() => {
        this.$triggerRecompute()
        return (this.$node().parameters as T["parameters"])[this.$key()]
    })
    readonly $property = computed(() => {
        const overwrittenValue = this.$overwrittenValue()
        if (overwrittenValue !== undefined) return this.$overwrittenValue()

        const subKey = this.$subKey()
        let parameter = this.$parameter() as unknown

        if (subKey !== undefined) {
            const path = Array.isArray(subKey) ? subKey : [subKey]
            if (path.length === 0) throw new Error(`Invalid subKey ${path.join(".")} for property ${String(this.$key())} ${JSON.stringify(this.$parameter())}`)

            for (const key of path) {
                if (parameter === undefined || parameter === null) return undefined
                if (typeof parameter === "object" && typeof key === "string") parameter = (parameter as Record<string, unknown>)[key]
                else if (Array.isArray(parameter) && typeof key === "number") parameter = parameter[key]
                else throw new Error(`Invalid subKey ${path.join(".")} for property ${String(this.$key())} ${JSON.stringify(this.$parameter())}`)
            }
        }

        return parameter
    })
    readonly $nodeProperty = computed(() => {
        const property = this.$property()
        return isTemplateNode(property) ? property : undefined
    })
    readonly $nodeArrayProperty = computed(() => {
        const property = this.$property()
        return Array.isArray(property) && property.every((x) => isTemplateNode(x)) ? property : undefined
    })
    readonly $isLinkedToAnyNodes = computed(() => {
        const nodeProperty = this.$nodeProperty()
        const nodeArrayProperty = this.$nodeArrayProperty()
        return nodeProperty || (nodeArrayProperty && nodeArrayProperty.length > 0)
    })
    readonly $materialLikeNode = computed(() => {
        if (!this.propertyAcceptsNodeClass(MaterialReference.getNodeClass())) return undefined

        const nodeProperty = this.$nodeProperty()
        if (!nodeProperty) return {materialLike: null}
        else if (isMaterialLike(nodeProperty)) return {materialLike: nodeProperty}
        else return undefined
    })
    readonly $materialAssignmentNode = computed(() => {
        if (!this.propertyAcceptsNodeClass(MaterialAssignment.getNodeClass())) return undefined

        const nodeProperty = this.$nodeProperty()
        if (!nodeProperty) return {materialAssignment: null}
        else if (nodeProperty instanceof MaterialAssignment) return {materialAssignment: nodeProperty}
        else return undefined
    })
    readonly $materialAssignmentsNode = computed(() => {
        if (!this.propertyAcceptsNodeClass(MaterialAssignments.getNodeClass())) return undefined

        const node = this.$node()
        const nodeProperty = this.$nodeProperty()
        if (isMesh(node) && nodeProperty instanceof MaterialAssignments) return {materialAssignments: nodeProperty, mesh: node}
        else return undefined
    })
    readonly $imageLikeNode = computed(() => {
        if (imageLikeClasses.every((nodeClass) => !this.propertyAcceptsNodeClass(nodeClass))) return undefined

        const nodeProperty = this.$nodeProperty()
        if (!nodeProperty) return {imageLike: null}
        else if (isTemplateNode(nodeProperty) && isImageLike(nodeProperty)) return {imageLike: nodeProperty}
        else return undefined
    })
    readonly $parametersNode = computed(() => {
        if (!this.propertyAcceptsNodeClass(Parameters.getNodeClass())) return undefined

        const node = this.$node()
        const nodeProperty = this.$nodeProperty()
        if (node instanceof TemplateInstance && nodeProperty instanceof Parameters) return {templateInstance: node, parameters: nodeProperty}
        else return undefined
    })
    readonly $acceptedNodeClasses = computed(() => {
        return [...new Set(checkedNodeClasses.filter((nodeClass) => this.propertyAcceptsNodeClass(nodeClass)))]
    })
    readonly $acceptedNodeClassArrays = computed(() => {
        return [...new Set(checkedNodeClasses.filter((nodeClass) => this.propertyAcceptsNodeClassArray(nodeClass)))]
    })
    readonly $propertyFilled = computed(() => this.$property() !== undefined && this.$property() !== null)
    readonly $droppedValueMapper = computed<((value: unknown) => unknown) | undefined>(() => {
        const materialAssignmentNode = this.$materialAssignmentNode()

        if (materialAssignmentNode) {
            const {materialAssignment} = materialAssignmentNode

            return (value) => {
                if (isTemplateNode(value) && isMaterialLike(value)) {
                    if (materialAssignment) return materialAssignment.clone({cloneSubNode: () => true, parameterOverrides: {node: value}})
                    else
                        return new MaterialAssignment({
                            node: value,
                            side: "front",
                        })
                } else return undefined
            }
        } else return undefined
    })

    readonly $propertyAcceptsNodes = computed(() => this.$acceptedNodeClasses().length > 0 || this.$acceptedNodeClassArrays().length > 0)
    readonly $propertyAcceptsNumber = computed(() => this.propertyAccepts(1))
    readonly $propertyAcceptsString = computed(() => this.propertyAccepts("Text"))
    readonly $propertyAcceptsBoolean = computed(() => this.propertyAccepts(true))
    readonly $propertyAcceptsJSON = computed(() => this.propertyAccepts({}))
    readonly $propertyAcceptsVector = computed(() => this.propertyAccepts([-1, -1, -1]))
    readonly $propertyAcceptsColor = computed(() => !this.$propertyAcceptsVector() && this.propertyAccepts([1, 1, 1]))
    readonly $propertyAcceptsMatrix = computed(() => this.propertyAccepts(Matrix4.identity().toArray()))

    readonly $acceptedValuesText = computed(() => {
        const extendedNodeClasses = [...this.$acceptedNodeClasses(), ...this.$acceptedNodeClassArrays()]
        if (!this.$selectionPossibilities()) {
            if (this.$propertyAcceptsNumber()) extendedNodeClasses.push("Number")
            if (this.$propertyAcceptsString()) extendedNodeClasses.push("Text")
            if (this.$propertyAcceptsBoolean()) extendedNodeClasses.push("Boolean")
            if (this.$propertyAcceptsJSON()) extendedNodeClasses.push("JSON")
            if (this.$propertyAcceptsVector()) extendedNodeClasses.push("Vector")
            if (this.$propertyAcceptsColor()) extendedNodeClasses.push("Color")
            if (this.$propertyAcceptsMatrix()) extendedNodeClasses.push("Matrix")
        }

        const uniqueNodeClasses = [...new Set(extendedNodeClasses.map((nodeClass) => getTemplateNodeClassLabel(nodeClass)))]
        return uniqueNodeClasses.join(", ")
    })

    readonly $acceptedValuesIconClass = computed(() => {
        const icon = this.$icon()
        if (icon) return icon

        const getIconFromClass = (nodeClass: string): string => getNodeIconSeconaryClass(nodeClass) ?? getNodeIconClass(nodeClass)

        const iconClasses = [...this.$acceptedNodeClasses(), ...this.$acceptedNodeClassArrays()].map((nodeClass) => getIconFromClass(nodeClass))
        if (this.$propertyAcceptsNumber()) iconClasses.push(getIconFromClass(NumberValue.getNodeClass()))
        if (this.$propertyAcceptsString()) iconClasses.push(getIconFromClass(StringValue.getNodeClass()))
        if (this.$propertyAcceptsBoolean()) iconClasses.push(getIconFromClass(BooleanValue.getNodeClass()))
        if (this.$propertyAcceptsJSON()) iconClasses.push(getIconFromClass(JSONValue.getNodeClass()))
        if (this.$propertyAcceptsVector()) iconClasses.push("far fa-solid fa-up-down-left-right")
        if (this.$propertyAcceptsColor()) iconClasses.push("far fa-palette")
        if (this.$propertyAcceptsMatrix()) iconClasses.push("far fa-table-cells")

        const invalidIcon = getNodeIconClass("invalid-node-class")

        const uniqueIconClasses = [...new Set(iconClasses.filter((iconClass) => iconClass !== invalidIcon))]
        return uniqueIconClasses.length > 0 ? uniqueIconClasses[0] : "far fa-circle-info"
    })

    readonly $numberValue = computed(() => {
        const value = this.$property()
        if (typeof value === "number") return value
        return undefined
    })
    readonly $stringValue = computed(() => {
        const value = this.$property()
        if (typeof value === "string") return value
        return undefined
    })
    readonly $selectionText = computed(() => {
        const selectionPossibilities = this.$selectionPossibilities()
        if (!selectionPossibilities) return undefined

        const isSelected = this.$isSelected()

        const value = this.$property()
        return selectionPossibilities.find((possibility) => isSelected(possibility, value))?.name ?? this.$fallbackText()
    })
    readonly $booleanValue = computed(() => {
        const value = this.$property()
        if (typeof value === "boolean") return value
        return undefined
    })
    readonly $jsonValue = computed(() => {
        const value = this.$property()
        const result = jsonSchema.safeParse(value)
        if (result.success) return result.data
        return undefined
    })
    readonly $colorValue = computed(() => {
        const value = this.$property()
        if (Array.isArray(value) && value.length === 3 && value.every((x) => typeof x === "number")) return value as [number, number, number]
        return undefined
    })

    private isDragging = false
    private valueChangedWhileDragging = false
    setDragging(isDragging: boolean) {
        this.isDragging = isDragging

        if (!this.isDragging && this.valueChangedWhileDragging) {
            this.sceneManagerService.endModifyTemplateGraph()
            this.valueChangedWhileDragging = false
        }
    }

    readonly $canClear = computed(() => {
        return (
            this.propertyAccepts(undefined) ||
            this.propertyAccepts(null) ||
            (this.$nodeProperty() &&
                (this.$propertyAcceptsJSON() || this.$propertyAcceptsNumber() || this.$propertyAcceptsBoolean() || this.$propertyAcceptsString()))
        )
    })
    readonly $canPaste = computed(() => {
        const nodes = this.clipboardService.$nodes()
        if (nodes.length === 0) return false

        if (this.$acceptedNodeClassArrays().length > 0) {
            const nodeArrayProperty = this.$nodeArrayProperty()
            if (!nodeArrayProperty) return this.propertyAccepts(nodes)
            else {
                const newNodeList = [...new Set([...nodeArrayProperty, ...nodes])]
                if (newNodeList.length === nodeArrayProperty.length) return false
                return this.propertyAccepts(newNodeList)
            }
        } else {
            if (nodes.length !== 1) return false
            return this.propertyAccepts(nodes[0])
        }
    })

    protected propertyAccepts(value: unknown) {
        const overwrittenSchema = this.$schema()
        if (overwrittenSchema) {
            const result = overwrittenSchema.safeParse(value)
            if (value === undefined && result.success) return result.data === undefined
            return result.success
        }

        const node = this.$node()
        const schema = node.getParamsSchema()

        const result = schema.safeParse({...node.parameters, ...{[this.$key()]: this.getParameterValue(value)}})
        if (value === undefined && result.success) return (result.data as T["parameters"])[this.$key()] === undefined

        return result.success
    }

    protected propertyAcceptsNodeClass(nodeClass: string) {
        class DummyClass extends DeclareTemplateNodeTS<{}>({}, {nodeClass}) {}
        return this.propertyAccepts(new DummyClass({}))
    }

    protected propertyAcceptsNodeClassArray(nodeClass: string) {
        class DummyClass extends DeclareTemplateNodeTS<{}>({}, {nodeClass}) {}
        return this.propertyAccepts([new DummyClass({})])
    }

    protected getParameterValue(value: unknown) {
        const subKey = this.$subKey()

        if (subKey !== undefined) {
            const modifiedParameter = mapGraphParameters(this.$parameter() as NodeParameters)
            let parameter: unknown = modifiedParameter

            const path = Array.isArray(subKey) ? subKey : [subKey]
            if (path.length === 0) throw new Error(`Invalid subKey ${path.join(".")} for property ${String(this.$key())} ${JSON.stringify(this.$parameter())}`)

            for (let i = 0; i < path.length - 1; i++) {
                const key = path[i]
                if (parameter === undefined || parameter === null)
                    throw new Error(`Invalid subKey ${path.join(".")} for property ${String(this.$key())} ${JSON.stringify(this.$parameter())}`)
                if (typeof parameter === "object" && typeof key === "string") parameter = (parameter as Record<string, unknown>)[key]
                else if (Array.isArray(parameter) && typeof key === "number") parameter = parameter[key]
                else throw new Error(`Invalid subKey ${path.join(".")} for property ${String(this.$key())} ${JSON.stringify(this.$parameter())}`)
            }

            const key = path[path.length - 1]

            if (typeof parameter === "object" && parameter !== null && typeof key === "string") (parameter as NodeParameters)[key] = value
            else if (Array.isArray(parameter) && typeof key === "number") parameter[key] = value
            else throw new Error(`Invalid subKey ${path.join(".")} for property ${String(this.$key())} ${JSON.stringify(this.$parameter())}`)

            return modifiedParameter
        }

        return value
    }

    protected setValue(value: unknown) {
        if (this.$overwrittenValue() !== undefined) this.updatedOverwrittenValue.emit(value)
        else {
            if (this.isDragging) {
                this.sceneManagerService.beginModifyTemplateGraph()
                this.valueChangedWhileDragging = true
            }

            this.sceneManagerService.modifyTemplateGraph(() => {
                const key = this.$key()
                const newParameter = this.getParameterValue(value)
                const node = this.$node()
                node.updateParameters({[key]: newParameter} as Partial<ParamType>)
                this.$changeSideEffect()?.(value)
                this.onChanged.emit(newParameter)
                if (this.sceneManagerService.$currentlyModifyingScene()) this.$triggerRecompute.update((x) => x + 1)
            }, !this.$throttledUpdate() || !this.sceneManagerService.$currentlyModifyingScene())
        }
    }

    ngOnInit() {
        this.sceneManagerService.templateTreeChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((differences) => {
            const node = this.$node()
            if (node && differences.modifiedNodes.has(node)) this.$triggerRecompute.update((x) => x + 1)

            const nodeProperty = this.$nodeProperty()
            if (nodeProperty && differences.modifiedNodes.has(nodeProperty)) this.$triggerRecompute.update((x) => x + 1)
        })

        this.drag.draggedItem$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({dragSource, dropTarget}) => {
            const {component} = dropTarget
            if (component === this.getDroppableComponent()) {
                const droppedValueMapper = this.$droppedValueMapper()
                const draggedNode = this.drag.draggableSourceToTemplateNode(dragSource)
                if (draggedNode) {
                    const finalDraggedNode = droppedValueMapper ? droppedValueMapper(draggedNode) : draggedNode

                    if (this.$acceptedNodeClassArrays().length > 0) {
                        const nodeArrayProperty = this.$nodeArrayProperty()
                        if (isTemplateNode(finalDraggedNode) && nodeArrayProperty) {
                            this.setValue([...nodeArrayProperty, finalDraggedNode])
                        } else this.setValue([finalDraggedNode])
                    } else this.setValue(finalDraggedNode)
                }
            }
        })
    }

    paste() {
        const nodes = this.clipboardService.pasteReferences()

        if (this.$acceptedNodeClassArrays().length > 0) {
            const nodeArrayProperty = this.$nodeArrayProperty()
            if (!nodeArrayProperty) return this.setValue(nodes)

            const newNodeList = [...new Set([...nodeArrayProperty, ...nodes])]

            return this.setValue(newNodeList)
        } else {
            if (nodes.length !== 1) return

            this.setValue(nodes[0])
        }
    }

    clear() {
        if (this.propertyAccepts(undefined)) this.setValue(undefined)
        else if (this.propertyAccepts(null)) this.setValue(null)
        else {
            if (this.$nodeProperty()) {
                if (this.$propertyAcceptsJSON()) this.setValue({})
                else if (this.$propertyAcceptsNumber()) this.setValue(0)
                else if (this.$propertyAcceptsBoolean()) this.setValue(false)
                else if (this.$propertyAcceptsString()) this.setValue("")
            }
        }
    }

    removeFromArray(node: TemplateNode) {
        const nodeArrayProperty = this.$nodeArrayProperty()
        if (!nodeArrayProperty) return
        this.setValue(nodeArrayProperty.filter((x) => x !== node))
    }

    updateValue(value: unknown) {
        const getMappedValue = (value: unknown) => {
            if (value === undefined || value === null) {
                if (!this.propertyAccepts(value)) {
                    const mappedValue: unknown = value === undefined ? null : undefined
                    if (this.propertyAccepts(mappedValue)) return mappedValue
                }
            }
            return value
        }

        const mappedValue = getMappedValue(value)

        if (!this.propertyAccepts(mappedValue)) {
            const subKey = this.$subKey()
            throw new Error(
                `Invalid value ${mappedValue} for property ${String(this.$key())}${subKey ? "." + subKey : ""} of node ${this.$node().getNodeClass()}`,
            )
        }

        this.setValue(mappedValue)
    }

    private getDroppableComponent() {
        return this as unknown as ValueSlotComponent<TemplateNode<{name: string}>>
    }

    dragOver(event: DragEvent) {
        event.stopPropagation()

        const dragSource = this.drag.$dragSource()
        if (!dragSource) return

        const draggedNode = this.drag.draggableSourceToTemplateNode(dragSource)
        if (!draggedNode) return

        this.drag.$dropTarget.update((previous) => {
            const droppedValueMapper = this.$droppedValueMapper()

            if (this.$acceptedNodeClassArrays().length > 0) {
                if (!this.propertyAccepts([draggedNode])) return null

                const nodeArrayProperty = this.$nodeArrayProperty()
                if (nodeArrayProperty) {
                    if (nodeArrayProperty.find((x) => x === draggedNode)) return null
                }
            } else {
                const finalDraggedNode = droppedValueMapper ? droppedValueMapper(draggedNode.clone({cloneSubNode: () => true})) : draggedNode

                if (!this.propertyAccepts(finalDraggedNode)) return null

                const nodeProperty = this.$nodeProperty()

                if (isTemplateNode(finalDraggedNode) && nodeProperty) {
                    if (finalDraggedNode === draggedNode) {
                        if (finalDraggedNode === nodeProperty) return null
                    } else {
                        if (finalDraggedNode.getHash() === nodeProperty.getHash()) return null
                    }
                }
            }

            const dropTarget = {component: this.getDroppableComponent(), position: "inside"} as TemplateDropTarget<
                ValueSlotComponent<TemplateNode<{name: string}>>
            >

            if (
                previous &&
                previous.component === dropTarget.component &&
                getDropPositionFromPosition(previous.position) === getDropPositionFromPosition(dropTarget.position)
            )
                return previous
            return dropTarget
        })

        if (this.drag.$dropTarget() !== null) event.preventDefault()
    }

    dragLeave(event: DragEvent) {
        event.stopPropagation()

        this.drag.dragLeave(event, this.getDroppableComponent(), this.elementRef.nativeElement)
    }

    isDropTarget() {
        const dropTarget = this.drag.$dropTarget()
        if (!dropTarget) return false
        const {component} = dropTarget

        return component === this.getDroppableComponent()
    }
}
