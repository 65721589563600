import {Component, inject, Injector, OnInit} from "@angular/core"
import {MatMenuModule} from "@angular/material/menu"
import {GetAssetsGQL, GetAssetsQuery, GetAssetsQueryVariables} from "@app/platform/components/assets/assets-grid/assets-grid.generated"
import {CardErrorComponent} from "@common/components/cards/card-error/card-error.component"
import {CardPlaceholderComponent} from "@common/components/cards/card-placeholder/card-placeholder.component"
import {CommentBoxesComponent} from "@common/components/comment-boxes/comment-boxes.component"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {ItemListComponent} from "@common/components/item/item-list/item-list.component"
import {ListInfoComponent} from "@common/components/item/list-info/list-info.component"
import {InfiniteListComponent} from "@common/components/lists/infinite-list/infinite-list.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {DialogSize} from "@common/models/dialogs"
import {BatchUpdateProperty} from "@common/models/item/list-item"
import {Settings} from "@common/models/settings/settings"
import {DialogService} from "@common/services/dialog/dialog.service"
import {AssetOrderByCriteria, AssetState, ContentTypeModel, MutationUpdateAssetInput, NextActor, SortOrder} from "@generated"
import {Labels, StateLabel} from "@labels"
import {AssetBoxesComponent} from "@platform/components/assets/asset-boxes/asset-boxes.component"
import {BatchUpdateAssetsGQL, CreateAssetGQL, UpdateAssetGQL, AssetListItemFragment} from "@platform/components/assets/assets-grid/assets-grid.generated"
import {SelectModelComponent} from "@platform/components/models/select-model/select-model.component"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {AssignUserDialogComponent} from "@platform/components/users/assign-user-dialog/assign-user-dialog.component"
import {AssignUserComponent} from "@platform/components/users/assign-user/assign-user.component"
import {CancellableRequest} from "@platform/models/data/cancellable-request"

@Component({
    selector: "cm-assets-grid",
    imports: [
        AssignUserComponent,
        CardErrorComponent,
        CardPlaceholderComponent,
        CommentBoxesComponent,
        EntityCardComponent,
        InfiniteListComponent,
        ListInfoComponent,
        StateLabelComponent,
        AssignUserDialogComponent,
        MatMenuModule,
        AssetBoxesComponent,
    ],
    templateUrl: "./assets-grid.component.html",
    styleUrl: "./assets-grid.component.scss",
})
export class AssetsGridComponent
    extends ItemListComponent<AssetListItemFragment, MutationUpdateAssetInput, {modelId: string; organizationId?: string}>
    implements OnInit
{
    readonly dialogService = inject(DialogService)
    readonly createAsset = inject(CreateAssetGQL)
    readonly assetsGql = inject(GetAssetsGQL)
    readonly injector = inject(Injector)

    public stateLabels: StateLabel<AssetState>[] = Array.from(Labels.AssetState.values())

    private fetchRequest = new CancellableRequest<GetAssetsQuery, GetAssetsQueryVariables>(this.assetsGql, this.injector, this.destroyRef)

    // OVERLOADS

    protected override _contentTypeModel = ContentTypeModel.Asset
    readonly batchUpdateAssetsGql = inject(BatchUpdateAssetsGQL)
    protected override _batchUpdate = (property: BatchUpdateProperty, value: string | boolean) =>
        mutateThrowingErrors(this.batchUpdateAssetsGql)({
            filter: this.filters.assetFilter(),
            [property]: value,
        }).then(({batchUpdateAssets: count}) => count)

    protected override _fetchList = ({skip, take}: {skip: number; take: number}) =>
        this.fetchRequest
            .fetch({
                take: take,
                skip: skip,
                filter: this.filters.assetFilter(),
                orderBy: [
                    {key: AssetOrderByCriteria.Priority, direction: SortOrder.Asc},
                    {key: AssetOrderByCriteria.Id, direction: SortOrder.Desc},
                ],
            })
            .then(({assets, assetsCount}) => ({
                items: assets,
                totalCount: assetsCount,
            }))

    readonly itemsGql = inject(GetAssetsGQL)
    protected override _refreshItem = ({id, legacyId}: {id?: string; legacyId?: number}) =>
        fetchThrowingErrors(this.itemsGql)({
            take: 1,
            filter: {...this.filters.assetFilter(), id: id ? {equals: id} : undefined, legacyId: legacyId ? {equals: legacyId} : undefined},
        }).then(({assets}) => assets?.[0] || undefined)

    readonly updateAssetGql = inject(UpdateAssetGQL)
    protected override _updateItem = (data: MutationUpdateAssetInput) =>
        mutateThrowingErrors(this.updateAssetGql)({
            input: data,
        }).then(({updateAsset}) => updateAsset)

    protected override _createItem = async (data: {modelId: string; organizationId?: string}) => {
        const organizationId = data.organizationId ?? this.organizations.$current()?.id
        if (!organizationId) {
            throw new Error("Organization is not set")
        }
        const {createAsset} = await mutateThrowingErrors(this.createAsset)({
            input: {
                modelId: data.modelId,
                comment: Settings.defaultCommentField,
                nextActor: this.auth.$actingAsCustomer() ? NextActor.Customer : NextActor.Team1,
                organizationId,
                state: AssetState.InfoReview,
            },
        })
        void this.router.navigate(["/assets", createAsset.id])
        return createAsset
    }

    openSelectMaterialDialog() {
        this.dialogService.selectInDialog(SelectModelComponent, {
            onSelect: (item) => this.createItem({modelId: item.id, organizationId: item.organization?.id}),
        })
    }

    protected readonly DialogSize = DialogSize
}
