import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TaskDetailsFragment = { __typename: 'Task', id: string, legacyId: number, public: boolean, comments: Array<{ __typename: 'TaskComment', id: string, createdAt?: string | null, text: string, createdBy?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null } | null }> };

export type TaskDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type TaskDetailsQuery = { __typename: 'Query', item: { __typename: 'Task', id: string, legacyId: number, public: boolean, comments: Array<{ __typename: 'TaskComment', id: string, createdAt?: string | null, text: string, createdBy?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null } | null }> } };

export type UpdateTaskDetailsMutationVariables = Types.Exact<{
  input: Types.MutationUpdateTaskInput;
}>;


export type UpdateTaskDetailsMutation = { __typename: 'Mutation', item: { __typename: 'Task', id: string, legacyId: number, public: boolean, comments: Array<{ __typename: 'TaskComment', id: string, createdAt?: string | null, text: string, createdBy?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null } | null }> } };

export type TaskDetailsCreateCommentMutationVariables = Types.Exact<{
  input: Types.MutationCreateTaskCommentInput;
}>;


export type TaskDetailsCreateCommentMutation = { __typename: 'Mutation', createTaskComment: { __typename: 'TaskComment', id: string } };

export type TaskDetailsDeleteCommentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type TaskDetailsDeleteCommentMutation = { __typename: 'Mutation', deleteTaskComment?: any | null };

export const TaskDetailsFragmentDoc = gql`
    fragment TaskDetails on Task {
  id
  legacyId
  __typename
  comments {
    id
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    text
  }
  public
}
    `;
export const TaskDetailsDocument = gql`
    query taskDetails($id: ID!) {
  item: task(id: $id) {
    ...TaskDetails
  }
}
    ${TaskDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaskDetailsGQL extends Apollo.Query<TaskDetailsQuery, TaskDetailsQueryVariables> {
    override document = TaskDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTaskDetailsDocument = gql`
    mutation updateTaskDetails($input: MutationUpdateTaskInput!) {
  item: updateTask(input: $input) {
    ...TaskDetails
  }
}
    ${TaskDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTaskDetailsGQL extends Apollo.Mutation<UpdateTaskDetailsMutation, UpdateTaskDetailsMutationVariables> {
    override document = UpdateTaskDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaskDetailsCreateCommentDocument = gql`
    mutation taskDetailsCreateComment($input: MutationCreateTaskCommentInput!) {
  createTaskComment(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TaskDetailsCreateCommentGQL extends Apollo.Mutation<TaskDetailsCreateCommentMutation, TaskDetailsCreateCommentMutationVariables> {
    override document = TaskDetailsCreateCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaskDetailsDeleteCommentDocument = gql`
    mutation taskDetailsDeleteComment($id: ID!) {
  deleteTaskComment(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TaskDetailsDeleteCommentGQL extends Apollo.Mutation<TaskDetailsDeleteCommentMutation, TaskDetailsDeleteCommentMutationVariables> {
    override document = TaskDetailsDeleteCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }