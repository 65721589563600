import {DestroyRef} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {DialogComponent} from "@app/common/components/dialogs/dialog/dialog.component"
import {DIALOG_DEFAULT_WIDTH} from "@app/template-editor/helpers/constants"
import {firstValueFrom, Observable, Subscription} from "rxjs"

/**
 * Blocks the UI with a modal dialog while waiting for an async operation to complete.
 * Returns, if the operation was cancelled.
 */
export async function waitForAsyncOperationWithModalDialog(
    matDialog: MatDialog,
    title: string,
    message: string,
    cancelLabel: string,
    operation: (isCancelled: () => boolean) => Promise<void>,
    onError: (error: unknown) => void,
): Promise<boolean> {
    let isCancelled: boolean | undefined = undefined

    const waitDialogRef: MatDialogRef<DialogComponent, boolean> = matDialog.open(DialogComponent, {
        disableClose: true,
        width: DIALOG_DEFAULT_WIDTH,
        data: {
            title,
            message,
            cancelLabel,
        },
    })

    firstValueFrom(waitDialogRef.afterClosed()).then((confirmedWait) => {
        isCancelled = confirmedWait !== undefined ? !confirmedWait : undefined
    })

    try {
        await operation(() => {
            return isCancelled ?? false
        })
        return isCancelled ?? false
    } catch (error) {
        onError(error)
        throw error
    } finally {
        waitDialogRef.close()
    }
}

/**
 * Blocks the UI with a modal dialog while waiting for an observable to complete.
 * Returns, if the operation was cancelled.
 */
export async function waitForObservableWithModalDialog<T>(
    matDialog: MatDialog,
    destroyRef: DestroyRef,
    title: string,
    message: string,
    cancelLabel: string,
    operation: () => Observable<T>,
    onNext: (result: T) => Promise<void> | void,
    onError: (error: unknown) => void,
    isDone: (result: T) => boolean,
): Promise<boolean> {
    const waitDialogRef: MatDialogRef<DialogComponent, boolean> = matDialog.open(DialogComponent, {
        disableClose: true,
        width: DIALOG_DEFAULT_WIDTH,
        data: {
            title,
            message,
            cancelLabel,
        },
    })

    try {
        const subscription: Subscription = operation().subscribe({
            next: (result) => {
                if (isDone(result)) {
                    subscription.unsubscribe()
                    waitDialogRef.close()
                    return
                }
                onNext(result)
            },
            error: (error) => {
                onError(error)
            },
        })

        const confirmedWait = await firstValueFrom(waitDialogRef.afterClosed().pipe(takeUntilDestroyed(destroyRef)))
        if (confirmedWait !== undefined && !confirmedWait) {
            subscription.unsubscribe()
            return true
        }
    } catch (error) {
        onError(error)
        throw error
    }

    return false
}
