import {AfterViewInit, Component, computed, inject, Injector} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MatInputModule} from "@angular/material/input"
import {MatMenu, MatMenuItem, MatMenuModule} from "@angular/material/menu"
import {MatSelectModule} from "@angular/material/select"
import {JobTaskDetailsFragment} from "@app/platform/components/jobs/job-task-details/job-task-details.generated"
import {formatRenderJobTaskClaimedByField, isClaimedRenderJobTask} from "@app/platform/models/job-task/render-job-task"
import {IsDefined} from "@cm/utils"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"
import {SectionComponent} from "@common/components/item/item-details/section/section.component"
import {IsLoadingDirective} from "@common/directives"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {DialogSize} from "@common/models/dialogs"
import {ContentTypeModel, MutationUpdateJobTaskInput} from "@generated"
import {BaseDetailsComponent} from "@platform/components/base/base-details/base-details.component"
import {FilesSectionComponent} from "@platform/components/details/files-section/files-section.component"
import {TitleSectionComponent} from "@platform/components/details/title-section/title-section.component"
import {JobTaskDetailsGQL, UpdateJobTaskDetailsGQL} from "@platform/components/jobs/job-task-details/job-task-details.generated"
import {DetailsDialogLayoutComponent} from "@platform/components/layouts/details-dialog-layout/details-dialog-layout.component"
import {CopyValueToClipboardMenuComponent} from "@platform/components/shared/copy-value-to-clipboard-menu/copy-value-to-clipboard-menu.component"
import {from, of, switchMap, tap} from "rxjs"

@Component({
    selector: "cm-job-task-details",
    templateUrl: "job-task-details.component.html",
    styleUrls: ["job-task-details.component.scss"],
    imports: [
        DetailsDialogLayoutComponent,
        SectionComponent,
        MatInputModule,
        MatSelectModule,
        IsLoadingDirective,
        FormsModule,
        MatCheckboxModule,
        MatButtonModule,
        RoutedDialogComponent,
        MatMenu,
        MatMenuItem,
        MatMenuModule,
        FilesSectionComponent,
        TitleSectionComponent,
        CopyValueToClipboardMenuComponent,
        OrganizationSelectComponent,
    ],
})
export class JobTaskDetailsComponent extends BaseDetailsComponent<JobTaskDetailsFragment, Omit<MutationUpdateJobTaskInput, "id">> implements AfterViewInit {
    override _contentTypeModel = ContentTypeModel.JobTask
    override _fetchItem = fetchThrowingErrors(inject(JobTaskDetailsGQL))
    override _updateItem = mutateThrowingErrors(inject(UpdateJobTaskDetailsGQL))

    override paramName = "jobTaskId"
    override displayName = "Job Task"

    readonly injector = inject(Injector)

    readonly $copyItems = computed(() => {
        const result: {value: string | undefined | (() => string | undefined); displayText: string; icon: string}[] = [
            {value: this.$item()?.legacyId?.toString(), displayText: "Legacy ID", icon: "fa-person-cane"},
            {value: this.$item()?.id, displayText: "ID", icon: "fa-id-badge"},
        ]
        return result
    })

    async closeDialog() {
        const jobLegacyId = this.$item()?.job?.legacyId
        if (jobLegacyId) {
            await this.router.navigate(["jobs", jobLegacyId], {queryParamsHandling: "preserve"})
        }
    }

    formattedClaimedByField: string | undefined | null = undefined
    ngAfterViewInit() {
        this.item$
            .pipe(
                switchMap((item) =>
                    IsDefined(item) && isClaimedRenderJobTask(item) ? from(formatRenderJobTaskClaimedByField(item, this.injector)) : of(item),
                ),
                tap((maybeItem) => {
                    this.formattedClaimedByField = maybeItem?.claimedBy ?? null
                    this.changeDetectorRef.detectChanges()
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe()
    }

    protected readonly DialogSize = DialogSize
    protected readonly ContentTypeModel = ContentTypeModel
}
