import {z} from "zod"
import {ErrorCode, ErrorCodeLookup, ErrorInfo} from "@common/models/errors"
import {maybeEnum} from "@common/helpers/utils/enum"

export const extractErrorInfo = (error: unknown, overrideMessageForCode: Record<number, string> = {}): ErrorInfo => {
    const errorMessage = error instanceof Error ? error.message : `${error}`

    if (error?.toString()?.startsWith("Error: You are not authorized to")) {
        return {message: "You are not authorized to perform this action", code: ErrorCode.Forbidden}
    }

    if (error?.toString()?.startsWith("Error: It's not allowed to")) {
        return {message: "You are not authorized to perform this action", code: ErrorCode.Forbidden}
    }

    if (error?.toString()?.startsWith("Error: Unexpected error.")) {
        return {message: "An unexpected error occurred", code: ErrorCode.Generic}
    }

    // depending on the context, GraphQL errors may consist of an object or a message containing a JSON string
    return (
        extractErrorInfoFromNetworkErrorResult(error) ??
        parseGraphQLErrorMessage(extractErrorFromStringifiedJson(errorMessage), overrideMessageForCode) ??
        parseGraphQLErrorMessage(extractErrorFromErrorResponse(error), overrideMessageForCode) ??
        extractApolloError(errorMessage) ??
        parseGraphQLErrorMessage(errorMessage, overrideMessageForCode) ?? {
            message: `${error}`,
            code: ErrorCode.Generic,
        }
    )
}

const parseGraphQLErrorMessage = (error: unknown, overrideMessageForCode: Record<number, string> = {}): ErrorInfo | undefined => {
    const messageForCode: Record<number, string> = {
        401: "You need to be logged in to perform this action",
        403: "You are not authorized to perform this action",
        409: "A data-consistency rule is preventing this operation",
        500: "This action failed due to a server issue",
        ...overrideMessageForCode,
    }

    try {
        const GraphQLErrorSchema = z.object({
            message: z.string(),
            extensions: z.object({code: z.number()}),
        })
        const parsedGraphQLError = GraphQLErrorSchema.parse(error)
        const numberCode = parsedGraphQLError.extensions.code
        const message = messageForCode[numberCode] ?? parsedGraphQLError.message
        const code = maybeEnum(numberCode, ErrorCodeLookup)
        return message && code ? {message, code} : undefined
    } catch {
        return undefined
    }
}

const extractErrorFromErrorResponse = (errorResponse: unknown): unknown | undefined => {
    try {
        return z
            .object({
                response: z.object({
                    errors: z.array(z.unknown()),
                }),
            })
            .parse(errorResponse)?.response?.errors?.[0]
        // eslint-disable-next-line unused-imports/no-unused-vars
    } catch (_error: unknown) {
        return undefined
    }
}

export const extractErrorInfoFromNetworkErrorResult = (errorResponse: unknown): ErrorInfo | undefined => {
    try {
        const message = z
            .object({
                networkError: z.object({
                    result: z.object({
                        errors: z.array(z.object({message: z.string()})),
                    }),
                }),
            })
            .parse(errorResponse)?.networkError?.result?.errors?.[0]?.message
        if (message) {
            return {
                message,
                code: ErrorCode.Generic,
            }
        }
        return undefined
        // eslint-disable-next-line unused-imports/no-unused-vars
    } catch (_error: unknown) {
        return undefined
    }
}

export const extractErrorFromStringifiedJson = (errorMessage?: string): unknown | undefined => {
    try {
        if (errorMessage) {
            const jsonObject = JSON.parse(errorMessage.replace("Unexpected error.: ", ""))
            return extractErrorFromErrorResponse(jsonObject)
        } else {
            return undefined
        }
        // eslint-disable-next-line unused-imports/no-unused-vars
    } catch (_error: unknown) {
        return undefined
    }
}

export const extractApolloError = (errorMessage: string) => {
    if (errorMessage.startsWith("ApolloError: ")) {
        return {message: errorMessage.replace(/^ApolloError: /, ""), code: ErrorCode.Generic}
    }
    return undefined
}
