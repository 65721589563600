import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {threeRGBColorNode, threeValueNode} from "#material-nodes/three-utils"
import {color, vec3} from "#material-nodes/types"
import {z} from "zod"

const ReturnTypeSchema = z.object({color: materialSlots, fac: materialSlots})
const InputTypeSchema = z.object({
    vector: materialSlots.optional(),
    color1: materialSlots.optional(),
    color2: materialSlots.optional(),
    mortar: materialSlots.optional(),
    mortarSize: materialSlots.optional(),
    mortarSmooth: materialSlots.optional(),
    bias: materialSlots.optional(),
    brickWidth: materialSlots.optional(),
    rowHeight: materialSlots.optional(),
})
const ParametersTypeSchema = z.object({
    vector: vec3.optional(),
    color1: color.optional(),
    color2: color.optional(),
    mortar: color.optional(),
    mortarSize: z.number().optional(),
    mortarSmooth: z.number().optional(),
    bias: z.number().optional(),
    brickWidth: z.number().optional(),
    rowHeight: z.number().optional(),
    offset: z.number().optional(),
    offsetFrequency: z.number().int().optional(),
    squash: z.number().optional(),
    squashFrequency: z.number().int().optional(),
})
export class TexBrick extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            console.warn("WARNING: Tex brick node not fully implemented!")
            return {color: threeRGBColorNode({r: 0, g: 0, b: 0}), fac: threeValueNode(0)}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
