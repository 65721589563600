import {AfterViewInit, Component, ElementRef, viewChild} from "@angular/core"
import {MatButtonModule} from "@angular/material/button"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@common/models/dialogs"
import {ActivatedRoute, Router} from "@angular/router"
import {ScanningService} from "@platform/services/scanning/scanning.service"

@Component({
    selector: "cm-scanning-logs",
    templateUrl: "./scanning-logs.component.html",
    styleUrls: ["./scanning-logs.component.scss"],
    imports: [RoutedDialogComponent, MatButtonModule],
})
export class ScanningLogsComponent implements AfterViewInit {
    readonly $logText = viewChild.required<ElementRef<HTMLDivElement>>("logText")
    dialogSizes = DialogSize

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private scanningService: ScanningService,
    ) {}

    ngAfterViewInit(): void {
        this.appendLog(this.scanningService.logs)
        this.scanningService.log.subscribe((log) => this.appendLog(log))
    }

    appendLog(log: string): void {
        const logTextDiv = this.$logText().nativeElement
        if (logTextDiv.innerText.length > 0) log = `\n${log}`
        logTextDiv.innerText += log
        logTextDiv.scrollTop = logTextDiv.scrollHeight
    }

    clearLogs(): void {
        this.scanningService.clearLogs()
        this.$logText().nativeElement.innerText = ""
    }

    overlayClosed() {
        this.router.navigate(["../"], {relativeTo: this.route, queryParamsHandling: "preserve"})
    }
}
