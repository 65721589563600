import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type FileDetailsFragment = { __typename: 'DataObject', id: string, legacyId: number, bucketName: string, downloadUrl: string, height?: number | null, mediaType?: string | null, originalFileName: string, state: Types.DataObjectState, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } };

export type FileDetailsQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type FileDetailsQuery = { __typename: 'Query', item: { __typename: 'DataObject', id: string, legacyId: number, bucketName: string, downloadUrl: string, height?: number | null, mediaType?: string | null, originalFileName: string, state: Types.DataObjectState, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type UpdateFileDetailsMutationVariables = Types.Exact<{
  input: Types.MutationUpdateDataObjectInput;
}>;


export type UpdateFileDetailsMutation = { __typename: 'Mutation', item: { __typename: 'DataObject', id: string, legacyId: number, bucketName: string, downloadUrl: string, height?: number | null, mediaType?: string | null, originalFileName: string, state: Types.DataObjectState, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } } };

export type DeleteFileMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  legacyId: Types.Scalars['Int']['input'];
}>;


export type DeleteFileMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export const FileDetailsFragmentDoc = gql`
    fragment FileDetails on DataObject {
  __typename
  id
  legacyId
  bucketName
  downloadUrl
  height
  mediaType
  originalFileName
  state
  thumbnail(resolution: High, fileType: jpg) {
    downloadUrl
  }
  width
  createdBy {
    id
    legacyId
    name
  }
  organization {
    id
    legacyId
    name
  }
}
    `;
export const FileDetailsDocument = gql`
    query fileDetails($id: ID, $legacyId: Int) {
  item: dataObject(id: $id, legacyId: $legacyId) {
    ...FileDetails
  }
}
    ${FileDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FileDetailsGQL extends Apollo.Query<FileDetailsQuery, FileDetailsQueryVariables> {
    override document = FileDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateFileDetailsDocument = gql`
    mutation updateFileDetails($input: MutationUpdateDataObjectInput!) {
  item: updateDataObject(input: $input) {
    ...FileDetails
  }
}
    ${FileDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFileDetailsGQL extends Apollo.Mutation<UpdateFileDetailsMutation, UpdateFileDetailsMutationVariables> {
    override document = UpdateFileDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteFileDocument = gql`
    mutation deleteFile($id: ID!, $legacyId: Int!) {
  deleteDataObject(id: $id, legacyId: $legacyId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFileGQL extends Apollo.Mutation<DeleteFileMutation, DeleteFileMutationVariables> {
    override document = DeleteFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }