import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { TemplateRevisionDetailsForTemplateEditorFragmentDoc } from '../template-editor/template-editor.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TemplateRevisionDetailsForTemplateTreeItemFragment = { __typename: 'TemplateRevision', id: string, legacyId: number, template: { __typename: 'Template', id: string, legacyId: number, type: Types.TemplateType, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null }> } };

export type GetTemplateRevisionDetailsForTemplateTreeItemQueryVariables = Types.Exact<{
  legacyIds?: Types.InputMaybe<Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']>;
}>;


export type GetTemplateRevisionDetailsForTemplateTreeItemQuery = { __typename: 'Query', templateRevisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number, template: { __typename: 'Template', id: string, legacyId: number, type: Types.TemplateType, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null }> } } | null> };

export type MaterialDetailsForTemplateTreeItemFragment = { __typename: 'Material', id: string, legacyId: number };

export type MaterialRevisionDetailsForTemplateTreeItemFragment = { __typename: 'MaterialRevision', id: string, legacyId: number, material: { __typename: 'Material', id: string, legacyId: number } };

export type GetMaterialRevisionDetailsForTemplateTreeItemQueryVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type GetMaterialRevisionDetailsForTemplateTreeItemQuery = { __typename: 'Query', materialRevision: { __typename: 'MaterialRevision', id: string, legacyId: number, material: { __typename: 'Material', id: string, legacyId: number } } };

export type CreateTemplateRevisionForTemplateTreeItemMutationVariables = Types.Exact<{
  input: Types.MutationCreateTemplateRevisionInput;
}>;


export type CreateTemplateRevisionForTemplateTreeItemMutation = { __typename: 'Mutation', createTemplateRevision: { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null, inconsistencies: Array<any>, number: number, template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number } } } };

export const TemplateRevisionDetailsForTemplateTreeItemFragmentDoc = gql`
    fragment TemplateRevisionDetailsForTemplateTreeItem on TemplateRevision {
  id
  legacyId
  template {
    id
    legacyId
    type
    revisions {
      id
      legacyId
      graph
    }
  }
}
    `;
export const MaterialDetailsForTemplateTreeItemFragmentDoc = gql`
    fragment MaterialDetailsForTemplateTreeItem on Material {
  id
  legacyId
}
    `;
export const MaterialRevisionDetailsForTemplateTreeItemFragmentDoc = gql`
    fragment MaterialRevisionDetailsForTemplateTreeItem on MaterialRevision {
  id
  legacyId
  material {
    ...MaterialDetailsForTemplateTreeItem
  }
}
    `;
export const GetTemplateRevisionDetailsForTemplateTreeItemDocument = gql`
    query getTemplateRevisionDetailsForTemplateTreeItem($legacyIds: [Int!]) {
  templateRevisions(filter: {legacyId: {in: $legacyIds}}, take: 1000) {
    ...TemplateRevisionDetailsForTemplateTreeItem
  }
}
    ${TemplateRevisionDetailsForTemplateTreeItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateRevisionDetailsForTemplateTreeItemGQL extends Apollo.Query<GetTemplateRevisionDetailsForTemplateTreeItemQuery, GetTemplateRevisionDetailsForTemplateTreeItemQueryVariables> {
    override document = GetTemplateRevisionDetailsForTemplateTreeItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMaterialRevisionDetailsForTemplateTreeItemDocument = gql`
    query getMaterialRevisionDetailsForTemplateTreeItem($legacyId: Int!) {
  materialRevision(legacyId: $legacyId) {
    ...MaterialRevisionDetailsForTemplateTreeItem
  }
}
    ${MaterialRevisionDetailsForTemplateTreeItemFragmentDoc}
${MaterialDetailsForTemplateTreeItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialRevisionDetailsForTemplateTreeItemGQL extends Apollo.Query<GetMaterialRevisionDetailsForTemplateTreeItemQuery, GetMaterialRevisionDetailsForTemplateTreeItemQueryVariables> {
    override document = GetMaterialRevisionDetailsForTemplateTreeItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTemplateRevisionForTemplateTreeItemDocument = gql`
    mutation createTemplateRevisionForTemplateTreeItem($input: MutationCreateTemplateRevisionInput!) {
  createTemplateRevision(input: $input) {
    ...TemplateRevisionDetailsForTemplateEditor
  }
}
    ${TemplateRevisionDetailsForTemplateEditorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTemplateRevisionForTemplateTreeItemGQL extends Apollo.Mutation<CreateTemplateRevisionForTemplateTreeItemMutation, CreateTemplateRevisionForTemplateTreeItemMutationVariables> {
    override document = CreateTemplateRevisionForTemplateTreeItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }