import {createHash} from "sha1-uint8array"

enum TypeTag {
    Undefined = 0,
    Null = 1,
    Boolean = 2,
    Number = 3,
    String = 4,
    Object = 5,
    Array = 6,
    ArrayBuffer = 7,
    ArrayBufferView = 8,
    Reference = 9,
}

export function hashObject(obj: any): string {
    const hasher = createHash()
    const buf = new ArrayBuffer(9)
    const dv = new DataView(buf)
    const buf1 = new Uint8Array(buf, 0, 1)
    const buf2 = new Uint8Array(buf, 0, 2)
    const buf5 = new Uint8Array(buf, 0, 5)
    const buf9 = new Uint8Array(buf, 0, 9)
    let nodeIdCounter = 0
    const visited = new Map<unknown, number>()

    const traverse = (data: any) => {
        const thisNodeId = nodeIdCounter++
        const type = typeof data
        switch (type) {
            case "undefined":
                dv.setUint8(0, TypeTag.Undefined)
                hasher.update(buf1)
                return
            case "boolean":
                dv.setUint8(0, TypeTag.Boolean)
                dv.setUint8(1, data ? 1 : 0)
                hasher.update(buf2)
                return
            case "number":
                dv.setUint8(0, TypeTag.Number)
                dv.setFloat64(1, data)
                hasher.update(buf9)
                return
            case "string":
                dv.setUint8(0, TypeTag.String)
                dv.setUint32(1, data.length)
                hasher.update(buf5)
                hasher.update(data)
                return
            case "object": {
                if (data === null) {
                    dv.setUint8(0, TypeTag.Null)
                    hasher.update(buf1)
                    return
                }
                const existingRef = visited.get(data)
                if (existingRef !== undefined) {
                    dv.setUint8(0, TypeTag.Reference)
                    dv.setUint32(1, existingRef)
                    hasher.update(buf5)
                    return
                }
                visited.set(data, thisNodeId)
                if (Array.isArray(data)) {
                    dv.setUint8(0, TypeTag.Array)
                    dv.setUint32(1, data.length)
                    hasher.update(buf5)
                    for (const item of data) {
                        traverse(item)
                    }
                } else if (data instanceof ArrayBuffer) {
                    dv.setUint8(0, TypeTag.ArrayBuffer)
                    dv.setUint32(1, data.byteLength)
                    hasher.update(buf5)
                    hasher.update(new Uint8Array(data))
                } else if (ArrayBuffer.isView(data)) {
                    dv.setUint8(0, TypeTag.ArrayBufferView)
                    dv.setUint32(1, data.byteLength)
                    hasher.update(buf5)
                    hasher.update(data)
                } else {
                    const sortedKeys = Object.keys(data).sort()
                    dv.setUint8(0, TypeTag.Object)
                    dv.setUint32(1, sortedKeys.length)
                    hasher.update(buf5)
                    for (const key of sortedKeys) {
                        traverse(key)
                        traverse(data[key])
                    }
                }
                return
            }
        }
        throw new Error(`Unsupported value type in hash: ${type}`)
    }
    traverse(obj)
    return hasher.digest("hex")
}
