import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MovePictureDialogProjectFragment = { __typename: 'Project', id: string, legacyId: number, name: string, number: number, sets: Array<{ __typename: 'Set', id: string, legacyId: number, name: string, number: number }> };

export type MovePictureProjectsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ProjectFilterInput>;
  orderBy?: Types.InputMaybe<Array<Types.ProjectOrderByInput> | Types.ProjectOrderByInput>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type MovePictureProjectsQuery = { __typename: 'Query', projects: Array<{ __typename: 'Project', id: string, legacyId: number, name: string, number: number, sets: Array<{ __typename: 'Set', id: string, legacyId: number, name: string, number: number }> } | null> };

export const MovePictureDialogProjectFragmentDoc = gql`
    fragment MovePictureDialogProject on Project {
  id
  legacyId
  name
  number
  sets {
    id
    legacyId
    name
    number
  }
}
    `;
export const MovePictureProjectsDocument = gql`
    query movePictureProjects($filter: ProjectFilterInput, $orderBy: [ProjectOrderByInput!], $skip: Int, $take: Int) {
  projects(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
    ...MovePictureDialogProject
  }
}
    ${MovePictureDialogProjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MovePictureProjectsGQL extends Apollo.Query<MovePictureProjectsQuery, MovePictureProjectsQueryVariables> {
    override document = MovePictureProjectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }