import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AssetBoxesFragment = { __typename: 'Asset', id: string, legacyId: number, materials: Array<{ __typename: 'Material', id: string, state: Types.MaterialState, galleryImage?: { __typename: 'DataObject', id: string } | null }>, model: { __typename: 'Model', state: Types.ModelState, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type AssetBoxesQueryVariables = Types.Exact<{
  assetId: Types.Scalars['ID']['input'];
}>;


export type AssetBoxesQuery = { __typename: 'Query', asset: { __typename: 'Asset', id: string, legacyId: number, materials: Array<{ __typename: 'Material', id: string, state: Types.MaterialState, galleryImage?: { __typename: 'DataObject', id: string } | null }>, model: { __typename: 'Model', state: Types.ModelState, galleryImage?: { __typename: 'DataObject', id: string } | null } } };

export const AssetBoxesFragmentDoc = gql`
    fragment AssetBoxes on Asset {
  id
  legacyId
  materials {
    id
    state
    galleryImage {
      id
    }
  }
  model {
    state
    galleryImage {
      id
    }
  }
}
    `;
export const AssetBoxesDocument = gql`
    query assetBoxes($assetId: ID!) {
  asset(id: $assetId) {
    ...AssetBoxes
  }
}
    ${AssetBoxesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssetBoxesGQL extends Apollo.Query<AssetBoxesQuery, AssetBoxesQueryVariables> {
    override document = AssetBoxesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }