import * as Types from '../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TemplateDetailsFragment = { __typename: 'Template', id: string, legacyId: number, comment?: string | null, locked: boolean, name?: string | null, public: boolean, state: Types.TemplateState, type: Types.TemplateType, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null, revisions: Array<{ __typename: 'TemplateRevision', id: string }>, organization: { __typename: 'Organization', id: string, legacyId: number } };

export type TemplateDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type TemplateDetailsQuery = { __typename: 'Query', item: { __typename: 'Template', id: string, legacyId: number, comment?: string | null, locked: boolean, name?: string | null, public: boolean, state: Types.TemplateState, type: Types.TemplateType, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null, revisions: Array<{ __typename: 'TemplateRevision', id: string }>, organization: { __typename: 'Organization', id: string, legacyId: number } } };

export type UpdateTemplateDetailsMutationVariables = Types.Exact<{
  input: Types.MutationUpdateTemplateInput;
}>;


export type UpdateTemplateDetailsMutation = { __typename: 'Mutation', item: { __typename: 'Template', id: string, legacyId: number, comment?: string | null, locked: boolean, name?: string | null, public: boolean, state: Types.TemplateState, type: Types.TemplateType, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null, revisions: Array<{ __typename: 'TemplateRevision', id: string }>, organization: { __typename: 'Organization', id: string, legacyId: number } } };

export type DeleteTemplateDetailsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteTemplateDetailsMutation = { __typename: 'Mutation', deleteTemplate?: any | null };

export type TemplateDetailsCreateTemplateMutationVariables = Types.Exact<{
  input: Types.MutationCreateTemplateInput;
}>;


export type TemplateDetailsCreateTemplateMutation = { __typename: 'Mutation', createTemplate: { __typename: 'Template', id: string, legacyId: number, comment?: string | null, locked: boolean, name?: string | null, public: boolean, state: Types.TemplateState, type: Types.TemplateType, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string, legacyId: number, name: string } | null, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null, revisions: Array<{ __typename: 'TemplateRevision', id: string }>, organization: { __typename: 'Organization', id: string, legacyId: number } } };

export type TemplateDetailsCreateTemplateRevisionMutationVariables = Types.Exact<{
  input: Types.MutationCreateTemplateRevisionInput;
}>;


export type TemplateDetailsCreateTemplateRevisionMutation = { __typename: 'Mutation', createTemplateRevision: { __typename: 'TemplateRevision', id: string } };

export type TemplateDetailsDeleteTemplateRevisionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type TemplateDetailsDeleteTemplateRevisionMutation = { __typename: 'Mutation', deleteTemplateRevision?: any | null };

export const TemplateDetailsFragmentDoc = gql`
    fragment TemplateDetails on Template {
  __typename
  id
  legacyId
  comment
  locked
  name
  public
  state
  type
  galleryImage {
    id
  }
  latestRevision {
    id
    legacyId
    createdAt
    createdBy {
      id
      legacyId
      name
    }
    graph
    renderedImages(take: 4) {
      id
    }
  }
  revisions {
    id
  }
  organization {
    id
    legacyId
  }
}
    `;
export const TemplateDetailsDocument = gql`
    query templateDetails($id: ID!) {
  item: template(id: $id) {
    ...TemplateDetails
  }
}
    ${TemplateDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateDetailsGQL extends Apollo.Query<TemplateDetailsQuery, TemplateDetailsQueryVariables> {
    override document = TemplateDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTemplateDetailsDocument = gql`
    mutation updateTemplateDetails($input: MutationUpdateTemplateInput!) {
  item: updateTemplate(input: $input) {
    ...TemplateDetails
  }
}
    ${TemplateDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTemplateDetailsGQL extends Apollo.Mutation<UpdateTemplateDetailsMutation, UpdateTemplateDetailsMutationVariables> {
    override document = UpdateTemplateDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTemplateDetailsDocument = gql`
    mutation deleteTemplateDetails($id: ID!) {
  deleteTemplate(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTemplateDetailsGQL extends Apollo.Mutation<DeleteTemplateDetailsMutation, DeleteTemplateDetailsMutationVariables> {
    override document = DeleteTemplateDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateDetailsCreateTemplateDocument = gql`
    mutation templateDetailsCreateTemplate($input: MutationCreateTemplateInput!) {
  createTemplate(input: $input) {
    ...TemplateDetails
  }
}
    ${TemplateDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateDetailsCreateTemplateGQL extends Apollo.Mutation<TemplateDetailsCreateTemplateMutation, TemplateDetailsCreateTemplateMutationVariables> {
    override document = TemplateDetailsCreateTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateDetailsCreateTemplateRevisionDocument = gql`
    mutation templateDetailsCreateTemplateRevision($input: MutationCreateTemplateRevisionInput!) {
  createTemplateRevision(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateDetailsCreateTemplateRevisionGQL extends Apollo.Mutation<TemplateDetailsCreateTemplateRevisionMutation, TemplateDetailsCreateTemplateRevisionMutationVariables> {
    override document = TemplateDetailsCreateTemplateRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateDetailsDeleteTemplateRevisionDocument = gql`
    mutation templateDetailsDeleteTemplateRevision($id: ID!) {
  deleteTemplateRevision(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateDetailsDeleteTemplateRevisionGQL extends Apollo.Mutation<TemplateDetailsDeleteTemplateRevisionMutation, TemplateDetailsDeleteTemplateRevisionMutationVariables> {
    override document = TemplateDetailsDeleteTemplateRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }