import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {ChannelLayout, ImageDescriptor, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {getImgProcDataType} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils-img-proc"
import {ImageProcessingNodes as Nodes} from "@cm/image-processing-nodes"
import {TypedImageData} from "@cm/utils/typed-image-data"
import {ColorLike} from "@cm/math"
import {assertNever} from "@cm/utils"

export type ParameterType = {
    color?: Partial<ColorLike> // default: (0,0,0,1)
    resultImage: ImageRef
}

export type ReturnType = ImageRef

const imageOpClear: ImageOpType<ParameterType, ReturnType> = {
    name: "Clear",

    WebGL2: ({cmdQueue, parameters: {color, resultImage}}) => {
        const painter = cmdQueue.createPainter(
            "compositor",
            "clear",
            `
            uniform vec4 u_color;

            vec4 computeColor(ivec2 targetPixel) {
                return u_color;
            }
        `,
        )
        cmdQueue.paint(painter, {
            parameters: {
                u_color: {type: "float4", value: {x: color?.r ?? 0, y: color?.g ?? 0, z: color?.b ?? 0, w: color?.a ?? 1}},
            },
            resultImage,
        })
        return resultImage
    },

    ImgProc: ({cmdQueue, parameters: {color, resultImage}}) => {
        const getImgProcColorSpace = (_descriptor: ImageDescriptor): TypedImageData["colorSpace"] => {
            return "linear"
        }
        const getColorFromChannelLayout = (channelLayout: ChannelLayout, fillColor: ColorLike): number | Nodes.RGBColor | Nodes.RGBAColor => {
            switch (channelLayout) {
                case "R":
                    return fillColor.r
                case "RGB":
                    return [fillColor.r, fillColor.g, fillColor.b]
                case "RGBA":
                    return [fillColor.r, fillColor.g, fillColor.b, fillColor.a ?? 1]
                default:
                    assertNever(channelLayout)
            }
        }
        const descriptor = resultImage.descriptor
        const resultNode = cmdQueue.createImage(descriptor, {
            type: "createImage",
            width: descriptor.width,
            height: descriptor.height,
            dataType: getImgProcDataType(descriptor.dataType),
            colorSpace: getImgProcColorSpace(descriptor),
            color: getColorFromChannelLayout(descriptor.channelLayout, {
                r: color?.r ?? 0,
                g: color?.g ?? 0,
                b: color?.b ?? 0,
                a: color?.a ?? 1,
            }),
        })
        return cmdQueue.copyToResultImage(resultNode, resultImage)
    },
}

export function clear(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpClear, parameters)
}
