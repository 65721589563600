export interface ArUrlParts {
    gcsObjectName: string
    vertical: boolean
}

export const assembleArUrl = (baseUrl: URL, gcsObjectName: string, vertical: boolean): string => {
    const path = `${baseUrl}/${gcsObjectName}`
    return vertical ? `${path}?vertical` : path
}

export const parseArUrl = (url: string): ArUrlParts => {
    const [path, queryParams] = url.split("?")

    const gcsObjectName = path.split("/").pop()
    if (!gcsObjectName) throw new Error("Invalid AR URL")

    const vertical = queryParams?.includes("vertical") || false

    return {
        gcsObjectName,
        vertical,
    }
}

export function generateAndroidLink(dataObjectUrl: string, vertical: boolean): string {
    return (
        `intent://arvr.google.com/scene-viewer/1.0?` +
        `mode=ar_only&` +
        `enable_vertical_placement=${vertical ? "true" : "false"}&` +
        `file=${dataObjectUrl}#Intent;` +
        `scheme=https;package=com.google.android.googlequicksearchbox;` +
        `action=android.intent.action.VIEW;` +
        `S.browser_fallback_url=https://developers.google.com/ar;end;`
    )
}
