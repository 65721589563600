import {DestroyRef, Injectable} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatDialog} from "@angular/material/dialog"
import {MatSnackBar} from "@angular/material/snack-bar"
import type {OperationVariables} from "@apollo/client"
import type {ApolloQueryResult, MutationOptions, QueryOptions} from "@apollo/client/core"
import {DialogComponent} from "@common/components/dialogs/dialog/dialog.component"
import {extractErrorInfo} from "@common/helpers/api/errors"
import {EmptyObject} from "@common/helpers/api/fetch"
import type {MutationResult} from "apollo-angular"
import {firstValueFrom, map, type Observable} from "rxjs"

@Injectable({
    providedIn: "root",
})
export class NotificationsService {
    constructor(
        private snackBar: MatSnackBar,
        private destroyRef: DestroyRef,
        private matDialog: MatDialog,
    ) {}

    showError(error: unknown, duration?: number, action = "") {
        const errorMessage = extractErrorInfo(error)
        return this.snackBar.open(errorMessage.message, action, {duration: duration ?? 5000})
    }

    showInfo(message: string, duration?: number, action = "") {
        return this.snackBar.open(message, action, {duration: duration ?? 5000})
    }

    withUndo(message: string, action: () => void | Promise<void>, undo?: () => void | Promise<void>) {
        let undoTriggered = false
        const undoSnackBarRef = this.showInfo(message, 3000, "Undo")

        undoSnackBarRef.onAction().subscribe(async () => {
            undoTriggered = true
            await undo?.()
        })

        undoSnackBarRef.afterDismissed().subscribe(async () => {
            if (undoTriggered) {
                return
            }
            await action()
        })
    }

    /**
     * Execute a callback and show a success message. If an error is thrown, show an error message instead.
     * @param callback Callback to be executed
     * @param success Message to be shown in snackbar if callback returns without error
     * @param error Message to be shown in snackbar if error is thrown
     */
    async withUserFeedback<ReturnType>(
        callback: () => Promise<ReturnType>,
        {
            success,
            error,
        }: {
            success?: string | (() => string | void)
            error?: string | ((err: unknown) => string | void)
        },
    ): Promise<ReturnType | undefined> {
        try {
            const returnValue = await callback()
            if (success) {
                const message = typeof success === "function" ? success() : success
                if (message) {
                    this.showInfo(message)
                }
            }
            return returnValue
        } catch (err) {
            console.error(err)
            if (error) {
                const message = typeof error === "function" ? error(err) : error
                if (message) {
                    this.showError(message)
                }
            }
            return undefined
        }
    }

    async offerUndo(message: string): Promise<boolean> {
        const undoSnackBarRef = this.snackBar.open(message, "Undo", {duration: 3000})

        let undoTriggered = false
        undoSnackBarRef
            .onAction()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                undoTriggered = true
                undoSnackBarRef.dismiss()
            })
        return firstValueFrom(
            undoSnackBarRef.afterDismissed().pipe(
                takeUntilDestroyed(this.destroyRef),
                map(() => undoTriggered),
            ),
        )
    }

    async confirmationDialog(options: {title?: string; message?: string; confirm?: string; cancel?: string; isDestructive?: boolean}): Promise<boolean> {
        const dialogRef = this.matDialog.open(DialogComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: options?.title ?? "Are you sure?",
                message: options?.message ?? "This action <strong>cannot be undone</strong>.<br><br>Are you sure you want to continue?",
                confirmLabel: options?.confirm ?? "Confirm",
                cancelLabel: options?.cancel ?? "Cancel",
                isDestructive: options?.isDestructive ?? false,
            },
        })
        return firstValueFrom(dialogRef.afterClosed().pipe(takeUntilDestroyed(this.destroyRef)))
    }

    async fetchWithUserFeedback<ResultType = object, VariablesType extends OperationVariables = EmptyObject>(
        query: {
            fetch: (variables?: VariablesType, options?: QueryOptions<VariablesType, ResultType>) => Observable<ApolloQueryResult<ResultType>>
        },
        variables: VariablesType,
        {
            success: successMessageOrCallback,
            error: errorMessageOrCallback,
            options,
        }: {
            success?: string | (() => string | void)
            error?: string | ((err: unknown) => string | void)
            options?: QueryOptions<VariablesType, ResultType>
        },
    ): Promise<ResultType | undefined> {
        const {data, error} = await firstValueFrom(query.fetch(variables, options))
        if (error) {
            console.error(error)
            const message = typeof errorMessageOrCallback === "function" ? errorMessageOrCallback(error) : errorMessageOrCallback
            if (message) {
                this.showError(message)
            }
            return undefined
        }
        const message = typeof successMessageOrCallback === "function" ? successMessageOrCallback() : successMessageOrCallback
        if (message) {
            this.showInfo(message)
        }
        return data
    }

    async mutateWithUserFeedback<ResultType = object, VariablesType extends OperationVariables = EmptyObject>(
        mutation: {
            mutate: (variables?: VariablesType, options?: MutationOptions<ResultType, VariablesType>) => Observable<MutationResult<ResultType>>
        },
        variables: VariablesType,
        {
            success: successMessageOrCallback,
            error: errorMessageOrCallback,
            options,
        }: {
            success?: string | (() => string | void)
            error?: string | ((err: unknown) => string | void)
            options?: Omit<MutationOptions<ResultType, VariablesType>, "errorPolicy">
        },
    ): Promise<ResultType | null | undefined> {
        const {data, errors} = await firstValueFrom(mutation.mutate(variables, options)).catch((error) => ({
            data: null,
            errors: [error],
        }))
        const firstError = errors?.[0]
        if (firstError) {
            const errorMessage = extractErrorInfo(firstError)
            const message = typeof errorMessageOrCallback === "function" ? errorMessageOrCallback(firstError) : (errorMessageOrCallback ?? errorMessage.message)
            if (message) {
                this.showError(message)
            }
            return undefined
        }
        const message = typeof successMessageOrCallback === "function" ? successMessageOrCallback() : successMessageOrCallback
        if (message) {
            this.showInfo(message)
        }
        return data
    }
}
