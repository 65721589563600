import {DeclareTemplateNodeTS} from "#template-nodes/declare-template-node"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {ImageLike, imageLike} from "#template-nodes/node-types"
import {NamedNodeParameters, namedNodeParameters} from "#template-nodes/nodes/named-node"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {TemplateNode} from "#template-nodes/types"
import {skipped, visitNone} from "@cm/graph/declare-visitor-node"
import {registerNode} from "@cm/graph/register-node"
import {ImageGenerator} from "@cm/material-nodes/interfaces/image-generator"
import {wrapNodeOutput} from "@cm/material-nodes/material-node-graph"
import {Vec2, vec2} from "@cm/material-nodes/types"
import {hashObject} from "@cm/utils/hashing"
import {z} from "zod"

const curveControlPoints = z.array(vec2)
type CurveControlPoints = z.infer<typeof curveControlPoints>

const imageRgbCurveParameters = namedNodeParameters.merge(
    z.object({
        input: imageLike.nullable(),
        r: curveControlPoints,
        g: curveControlPoints,
        b: curveControlPoints,
        rgb: curveControlPoints,
    }),
)
export type ImageRgbCurveParameters = NamedNodeParameters & {
    input: ImageLike | null
    r: CurveControlPoints
    g: CurveControlPoints
    b: CurveControlPoints
    rgb: CurveControlPoints
}

@registerNode
export class ImageRgbCurve
    extends DeclareTemplateNodeTS<ImageRgbCurveParameters>(
        {
            validation: {paramsSchema: imageRgbCurveParameters},
            onVisited: {
                onFilterActive: ({parameters}) => {
                    const {input} = parameters
                    if (input === null) return skipped
                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "ImageRgbCurve"},
    )
    implements EvaluableTemplateNode<ImageGenerator | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        const {input, ...rest} = this.parameters
        const inputImage = evaluator.evaluateImage(scope, input)

        return scope.pureLambda<[ImageGenerator | null, CurveControlPoints, CurveControlPoints, CurveControlPoints, CurveControlPoints], ImageGenerator | null>(
            scope.tuple(inputImage, rest.r, rest.g, rest.b, rest.rgb),
            ([inputImage, r, g, b, rgb]) => {
                if (!inputImage) return null

                const {imageNode, metadata} = inputImage

                return {
                    imageNode: {
                        generator: (generatorData) => {
                            const image = imageNode.generator(generatorData)

                            const toCurveParameters = (curve: Vec2[], index: number) => {
                                const sortedCurve = [...curve].sort((a, b) => a.x - b.x)
                                const retVal: Record<string, [number, number]> = {}
                                for (let i = 0; i < sortedCurve.length; i++) {
                                    retVal[`internal.mapping.curves[${index}].points[${i}].location`] = [sortedCurve[i].x, sortedCurve[i].y]
                                }
                                return retVal
                            }

                            return {
                                color: wrapNodeOutput(
                                    {
                                        nodeType: "ShaderNodeRGBCurve",
                                        inputs: {
                                            Color: image.color,
                                        },
                                        parameters: {
                                            Fac: 1,
                                            ...toCurveParameters(r, 0),
                                            ...toCurveParameters(g, 1),
                                            ...toCurveParameters(b, 2),
                                            ...toCurveParameters(rgb, 3),
                                        },
                                    },
                                    "Color",
                                ),
                                alpha: image.alpha,
                            }
                        },
                        hash: hashObject({
                            type: "ImageRgbCurve",
                            inputImage: imageNode.hash,
                            r,
                            g,
                            b,
                            rgb,
                        }),
                    },
                    metadata,
                }
            },
            "imageRgbCurve",
        )
    }
}

export type ImageRgbCurveFwd = TemplateNode<ImageRgbCurveParameters> & EvaluableTemplateNode<ImageGenerator | null>
