import {Component, DestroyRef, inject, Injector, input} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {ButtonComponent} from "@app/common/components/buttons/button/button.component"
import {DialogComponent} from "@app/common/components/dialogs/dialog/dialog.component"
import {fetchThrowingErrors} from "@app/common/helpers/api/fetch"
import {mutateThrowingErrors} from "@app/common/helpers/api/mutate"
import {
    assignWebKeysToExistingArModel,
    generateNewArModels,
    getArAssignmentKey,
    getParametersForArGeneration,
    runBatchedArJobs,
} from "@app/common/helpers/ar/ar"
import {waitForAsyncOperationWithModalDialog, waitForObservableWithModalDialog} from "@app/common/helpers/dialog/dialog"
import {Settings} from "@app/common/models/settings/settings"
import {FilesService} from "@app/common/services/files/files.service"
import {GltfExportService} from "@app/common/services/gltf-export/gltf-export.service"
import {NotificationsService} from "@app/common/services/notifications/notifications.service"
import {StlExportService} from "@app/common/services/stl-export/stl-export.service"
import {DeleteDataObjectGQL, GetDataObjectAssignmentsGQL} from "@app/template-editor/components/ar-configurator-actions/ar-configurator-actions.generated"
import {ConfigurationInfo, gatherAllVariations} from "@app/template-editor/helpers/all-variations"
import {DIALOG_DEFAULT_WIDTH} from "@app/template-editor/helpers/constants"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {Parameters, TemplateGraph} from "@cm/template-nodes"
import {LodType} from "@cm/template-nodes/types"
import {ContentTypeModel, DataObjectAssignmentType} from "@generated"
import {ThreeSceneManagerService} from "@template-editor/services/three-scene-manager.service"
import {firstValueFrom} from "rxjs"
import {ThreeTemplateSceneProviderComponent} from "../three-template-scene-provider/three-template-scene-provider.component"

@Component({
    selector: "cm-ar-configurator-actions",
    imports: [ButtonComponent, ThreeTemplateSceneProviderComponent],
    templateUrl: "./ar-configurator-actions.component.html",
    styleUrl: "./ar-configurator-actions.component.scss",
    providers: [SceneManagerService],
})
export class ArConfiguratorActionsComponent {
    readonly $templateId = input.required<string>({alias: "templateId"})
    readonly $templateRevisionId = input.required<string>({alias: "templateRevisionId"})
    readonly $defaultCustomerId = input.required<number>({alias: "defaultCustomerId"})
    readonly $templateGraph = input.required<TemplateGraph>({alias: "templateGraph"})
    readonly $parameters = input.required<Parameters>({alias: "parameters"})
    readonly $configuratorUrlParams = input.required<Promise<string>>({alias: "configuratorUrlParams"})
    private readonly localSceneManagerService = inject(SceneManagerService)
    private threeSceneManagerService: ThreeSceneManagerService | undefined
    private readonly stlExportService = inject(StlExportService)
    private readonly gltfExportService = inject(GltfExportService)
    private readonly notificationsService = inject(NotificationsService)
    private readonly getDataObjectAssignments = inject(GetDataObjectAssignmentsGQL)
    private readonly deleteDataObject = inject(DeleteDataObjectGQL)
    private readonly injector = inject(Injector)
    private readonly matDialog = inject(MatDialog)
    private readonly destroyRef = inject(DestroyRef)

    constructor() {}

    //This is not required for every operation, so do not use an effect on the inputs
    private async updateLocalSceneManagerService(lodType: LodType) {
        if (!this.$defaultCustomerId()) throw new Error("No default customer id set")

        this.localSceneManagerService.$exposeClaimedSubTemplateInputs.set(true) //required for correct AR variants
        this.localSceneManagerService.$lodType.set(lodType)
        this.localSceneManagerService.$templateRevisionId.set(this.$templateRevisionId())
        this.localSceneManagerService.$defaultCustomerId.set(this.$defaultCustomerId())
        this.localSceneManagerService.$instanceParameters.set(this.$parameters())
        const clonedTemplateGraph = this.$templateGraph().clone({cloneSubNode: () => true})
        this.localSceneManagerService.$templateGraph.set(clonedTemplateGraph)
        this.localSceneManagerService.compileTemplate()

        await this.localSceneManagerService.sync(true)
    }

    setThreeSceneManagerService(threeSceneManagerService: ThreeSceneManagerService | undefined) {
        if (threeSceneManagerService) {
            threeSceneManagerService.$showGrid.set(false)
            threeSceneManagerService.$ambientLight.set(false)
        }

        this.threeSceneManagerService = threeSceneManagerService
    }

    async downloadStl() {
        const binaryStl = await this.stlExportService.exportStlFiles(this.$templateGraph(), this.$parameters(), this.localSceneManagerService)
        FilesService.downloadFile("stl-export.zip", binaryStl)
    }

    async copyConfiguratorUrl() {
        await navigator.clipboard.writeText(Settings.CONFIGURATOR_URL + "&templateId=" + this.$templateId() + (await this.$configuratorUrlParams()))
        this.notificationsService.showInfo("Configurator URL copied to clipboard")
    }

    async clearArForCurrentVariant() {
        const clearCurrent = async () => {
            await this.updateLocalSceneManagerService("web")

            const {dataObjectAssignments} = await fetchThrowingErrors(this.getDataObjectAssignments)({
                filter: {
                    contentTypeModel: ContentTypeModel.TemplateRevision,
                    objectId: this.$templateRevisionId(),
                    assignmentKey: {equals: getArAssignmentKey(getParametersForArGeneration(this.localSceneManagerService))},
                    assignmentType: [DataObjectAssignmentType.CachedTemplateGltf],
                },
            })

            dataObjectAssignments.forEach(async (dataObjectAssignment) => {
                if (dataObjectAssignment) {
                    await mutateThrowingErrors(this.deleteDataObject)({id: dataObjectAssignment?.dataObject.id})
                    console.log("Deleted data object assignment", dataObjectAssignment)
                }
            })
        }

        waitForAsyncOperationWithModalDialog(this.matDialog, "Clearing AR models", "Please wait...", "", clearCurrent, (error) => {
            console.error("Error clearing AR models:", error)
        })
    }

    async clearArForAllVariants() {
        /*A data object might be referenced by multiple assignment keys*/
        const collectAllDataObjects = async (isCancelled: () => boolean): Promise<Set<string>> => {
            const dataObjects = new Set<string>()
            let skip = 0
            const take = 100
            while (true) {
                const {dataObjectAssignments} = await fetchThrowingErrors(this.getDataObjectAssignments)({
                    take,
                    skip,
                    filter: {
                        contentTypeModel: ContentTypeModel.TemplateRevision,
                        objectId: this.$templateRevisionId(),
                        assignmentType: [DataObjectAssignmentType.CachedTemplateGltf],
                    },
                })

                if (isCancelled()) {
                    dataObjects.clear()
                    break
                }

                skip += take

                if (dataObjectAssignments.length === 0) break

                dataObjectAssignments.forEach((dataObjectAssignment) => {
                    const dataObjectId = dataObjectAssignment?.dataObject.id
                    if (dataObjectId) dataObjects.add(dataObjectId)
                })
            }

            return dataObjects
        }

        const clearAll = async (isCancelled: () => boolean) => {
            const dataObjectIds = await collectAllDataObjects(isCancelled)
            for (const dataObjectId of dataObjectIds) {
                await mutateThrowingErrors(this.deleteDataObject)({id: dataObjectId})
                console.log("Deleted data object ", dataObjectId)

                if (isCancelled()) return
            }
        }

        waitForAsyncOperationWithModalDialog(this.matDialog, "Clearing AR models", "Please wait...", "Cancel", clearAll, (error) => {
            console.error("Error clearing AR models:", error)
        })
    }

    async createArForCurrentVariant() {
        await this.updateLocalSceneManagerService("web")
        await generateNewArModels(
            this.$templateId(),
            this.$templateRevisionId(),
            getParametersForArGeneration(this.localSceneManagerService),
            this.$defaultCustomerId(),
            this.injector,
        )
    }

    //AR creation of all variations will be moved to "All Variations" view soon
    async createArForAllVariants() {
        await this.updateLocalSceneManagerService("web")
        const configurationsWeb: ConfigurationInfo[] = []

        let operationCancelled = await waitForObservableWithModalDialog(
            this.matDialog,
            this.destroyRef,
            "Collecting AR variations",
            "Please wait...",
            "Cancel",
            () => {
                return gatherAllVariations(this.localSceneManagerService, (progress) => {})
            },
            async (configInfo) => {
                if (configInfo !== "done") configurationsWeb.push(configInfo)
            },
            (error) => {
                console.error("Error gathering variations:", error)
            },
            (result) => result === "done",
        )

        if (operationCancelled) return

        //----------------------------------------------
        const paramsArLod = new Map<string, Parameters>()
        const webKeyToArKey = new Map<string, string>()

        operationCancelled = await waitForAsyncOperationWithModalDialog(
            this.matDialog,
            "Computing AR parameters",
            "Please wait...",
            "Cancel",
            async (isCancelled) => {
                await this.updateLocalSceneManagerService("ar")
                for (const webConfig of configurationsWeb) {
                    this.localSceneManagerService.$instanceParameters.set(webConfig.arAssignmentParameters)
                    this.localSceneManagerService.compileTemplate()
                    await this.localSceneManagerService.sync(true)

                    if (isCancelled()) return

                    const webAssignmentKey = getArAssignmentKey(webConfig.arAssignmentParameters)
                    const arAssignmentKey = getArAssignmentKey(getParametersForArGeneration(this.localSceneManagerService))
                    webKeyToArKey.set(webAssignmentKey, arAssignmentKey)

                    paramsArLod.set(arAssignmentKey, getParametersForArGeneration(this.localSceneManagerService))
                }
            },
            (error) => {
                console.error("Error computing AR parameters:", error)
            },
        )

        if (paramsArLod.size === 0 || operationCancelled) return
        //----------------------------------------------

        const submitDialogRef: MatDialogRef<DialogComponent, boolean> = this.matDialog.open(DialogComponent, {
            disableClose: false,
            width: DIALOG_DEFAULT_WIDTH,
            data: {
                title: "Submit jobs",
                message: `You will submit ${paramsArLod.size} AR generation jobs and link ${configurationsWeb.length} configurations. Continue?`,
                confirmLabel: "Submit jobs",
                cancelLabel: "Cancel",
            },
        })

        const confirmed = await firstValueFrom(submitDialogRef.afterClosed().pipe(takeUntilDestroyed(this.destroyRef)))
        if (confirmed !== true) return

        const paramsWebLod = configurationsWeb.map((config) => config.arAssignmentParameters)

        operationCancelled = await waitForAsyncOperationWithModalDialog(
            this.matDialog,
            "Creating AR models",
            "Please wait...",
            "Cancel",
            async () => {
                await runBatchedArJobs(
                    Array.from(paramsArLod.values()),
                    this.$templateId(),
                    this.$templateRevisionId(),
                    this.$defaultCustomerId(),
                    10,
                    this.injector,
                ) //create all ar models
                //await runBatchedArJobs(paramsWebLod, this.$templateId(), this.$templateRevisionId(), this.$defaultCustomerId(), 20, this.injector) //link all web models via job system
                await assignWebKeysToExistingArModel(this.$templateRevisionId(), webKeyToArKey, this.injector) //link all web models directly (faster, less errors)
            },
            (error) => {
                console.error("Error creating AR models:", error)
            },
        )
    }

    async downloadGltf() {
        if (!this.threeSceneManagerService) throw new Error("ThreeSceneManagerService not set")
        const binaryGltf = await this.gltfExportService.exportGltfFile(this.$templateGraph(), this.$parameters(), this.threeSceneManagerService, "web")
        FilesService.downloadFile("glb-export.glb", new Blob([binaryGltf]))
    }
}
