import * as Types from '../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TextureGroupTextureSetIdsFragment = { __typename: 'TextureGroup', id: string, textureSets: Array<{ __typename: 'TextureSet', id: string }>, organization: { __typename: 'Organization', id: string } };

export type QueryTextureGroupTextureSetIdsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type QueryTextureGroupTextureSetIdsQuery = { __typename: 'Query', textureGroup: { __typename: 'TextureGroup', id: string, textureSets: Array<{ __typename: 'TextureSet', id: string }>, organization: { __typename: 'Organization', id: string } } };

export const TextureGroupTextureSetIdsFragmentDoc = gql`
    fragment TextureGroupTextureSetIds on TextureGroup {
  id
  textureSets {
    id
  }
  organization {
    id
  }
}
    `;
export const QueryTextureGroupTextureSetIdsDocument = gql`
    query queryTextureGroupTextureSetIds($id: ID!) {
  textureGroup(id: $id) {
    ...TextureGroupTextureSetIds
  }
}
    ${TextureGroupTextureSetIdsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureGroupTextureSetIdsGQL extends Apollo.Query<QueryTextureGroupTextureSetIdsQuery, QueryTextureGroupTextureSetIdsQueryVariables> {
    override document = QueryTextureGroupTextureSetIdsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }