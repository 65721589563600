import {SceneNodes, colorEqual} from "@cm/template-nodes"
import {ThreeObject, mathIsEqual, setThreeObjectPart, updateTransform} from "@template-editor/helpers/three-object"
import {Three as THREE} from "@cm/material-nodes/three"
import {anyDifference, objectFieldsDifferent} from "@template-editor/helpers/change-detection"
import {configThreeHelperObjectLayers} from "@template-editor/helpers/helper-objects"

export class ThreeGrid extends ThreeObject<SceneNodes.Grid> {
    protected override renderObject: THREE.GridHelper | undefined = undefined

    override setup(sceneNode: SceneNodes.Grid) {
        return anyDifference([
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["size", "divisions", "color1", "color2"],
                (valueA, valueB) => {
                    if (typeof valueA === "object" && typeof valueB === "object") return colorEqual(valueA, valueB)
                    return valueA === valueB
                },
                ({size, divisions, color1, color2}) => {
                    this.dispose()
                    this.renderObject = new THREE.GridHelper(size, divisions, new THREE.Color(...color1), new THREE.Color(...color2))
                    this.renderObject.renderOrder = 1
                    configThreeHelperObjectLayers(this)
                    setThreeObjectPart(this.renderObject, this)
                },
            ),
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["transform"],
                (valueA, valueB) => mathIsEqual(valueA, valueB),
                ({transform}) => {
                    if (this.renderObject) updateTransform(transform, this.renderObject)
                },
            ),
        ])
    }

    override dispose() {
        if (this.renderObject) {
            this.renderObject.dispose()
            this.renderObject = undefined
        }
    }
}
