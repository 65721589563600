import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {copyRegion} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-copy-region"
import {createImage} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-create-image"
import {InterpolationType, resize} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-resize"
import {assertNever} from "@cm/utils"

// Down-samples an image. Considers odd sizes correctly.

const SCOPE_NAME = "DownSample"

export type ParameterType = {
    sourceImage: ImageRef
    interpolation?: InterpolationType // default: "cubic"
    oddPixelStrategy?: OddPixelStrategy // default: "nearest"
}

export type ReturnType = ImageRef

// strategy used to fill missing pixel values for odd pixel sizes
export type OddPixelStrategy =
    | "zero" // use zero
    | "nearest" // use the value of the nearest pixel
    | "stretch" // stretch the odd size to target size

export function downSample(cmdQueue: ImageOpCommandQueue, {sourceImage, interpolation, oddPixelStrategy}: ParameterType): ReturnType {
    cmdQueue.beginScope(SCOPE_NAME)
    interpolation ??= "cubic"
    oddPixelStrategy ??= "nearest"
    const isOdd = {
        x: (sourceImage.descriptor.width & 1) === 1,
        y: (sourceImage.descriptor.height & 1) === 1,
    }
    if (oddPixelStrategy !== "stretch" && (isOdd.x || isOdd.y)) {
        let evenedSourceImage = createImage(cmdQueue, {
            imageOrDescriptor: {
                ...sourceImage.descriptor,
                width: sourceImage.descriptor.width + (isOdd.x ? 1 : 0),
                height: sourceImage.descriptor.height + (isOdd.y ? 1 : 0),
            },
            fillColor: oddPixelStrategy === "zero" ? {r: 0, g: 0, b: 0, a: 0} : undefined, // TODO better to use clear after copyRegion below, but needs view-clear which is not properly supported by ImgProcQueue yet (because of return-value not being used)
        })
        switch (oddPixelStrategy) {
            case "zero": {
                evenedSourceImage = copyRegion(cmdQueue, {
                    sourceImage: sourceImage,
                    resultImageOrDataType: evenedSourceImage,
                })
                break
            }
            case "nearest": {
                evenedSourceImage = copyRegion(cmdQueue, {
                    sourceImage: sourceImage,
                    resultImageOrDataType: evenedSourceImage,
                })
                if (isOdd.x) {
                    evenedSourceImage = copyRegion(cmdQueue, {
                        sourceImage: sourceImage,
                        sourceRegion: {
                            x: sourceImage.descriptor.width - 1,
                            y: 0,
                            width: 1,
                            height: sourceImage.descriptor.height,
                        },
                        targetOffset: {
                            x: evenedSourceImage.descriptor.width - 1,
                            y: 0,
                        },
                        resultImageOrDataType: evenedSourceImage,
                    })
                }
                if (isOdd.y) {
                    evenedSourceImage = copyRegion(cmdQueue, {
                        sourceImage: sourceImage,
                        sourceRegion: {
                            x: 0,
                            y: sourceImage.descriptor.height - 1,
                            width: sourceImage.descriptor.width,
                            height: 1,
                        },
                        targetOffset: {
                            x: 0,
                            y: evenedSourceImage.descriptor.height - 1,
                        },
                        resultImageOrDataType: evenedSourceImage,
                    })
                }
                if (isOdd.x && isOdd.y) {
                    evenedSourceImage = copyRegion(cmdQueue, {
                        sourceImage: sourceImage,
                        sourceRegion: {
                            x: sourceImage.descriptor.width - 1,
                            y: sourceImage.descriptor.height - 1,
                            width: 1,
                            height: 1,
                        },
                        targetOffset: {
                            x: evenedSourceImage.descriptor.width - 1,
                            y: evenedSourceImage.descriptor.height - 1,
                        },
                        resultImageOrDataType: evenedSourceImage,
                    })
                }
                break
            }
            default:
                assertNever(oddPixelStrategy)
        }
        sourceImage = evenedSourceImage
    }
    const result = resize(cmdQueue, {
        sourceImage: sourceImage,
        interpolation,
        resultSize: {
            width: Math.ceil(sourceImage.descriptor.width / 2),
            height: Math.ceil(sourceImage.descriptor.height / 2),
        },
    })
    cmdQueue.endScope(SCOPE_NAME)
    return result
}
