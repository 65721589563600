import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TaskTypesTableItemFragment = { __typename: 'JobTaskType', id: string, cancelTimeout?: number | null, defaultTimeout?: number | null, endpoint?: string | null, invocationMethod: Types.InvocationMethod, maxTimeout?: number | null, queueGroup: string, taskType: string };

export type TaskTypesTableItemsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.JobTaskTypeFilterInput>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type TaskTypesTableItemsQuery = { __typename: 'Query', itemsCount: number, items: Array<{ __typename: 'JobTaskType', id: string, cancelTimeout?: number | null, defaultTimeout?: number | null, endpoint?: string | null, invocationMethod: Types.InvocationMethod, maxTimeout?: number | null, queueGroup: string, taskType: string } | null> };

export type TaskTypesTableUpdateTaskTypeMutationVariables = Types.Exact<{
  input: Types.MutationUpdateJobTaskTypeInput;
}>;


export type TaskTypesTableUpdateTaskTypeMutation = { __typename: 'Mutation', updateJobTaskType: { __typename: 'JobTaskType', id: string, cancelTimeout?: number | null, defaultTimeout?: number | null, endpoint?: string | null, invocationMethod: Types.InvocationMethod, maxTimeout?: number | null, queueGroup: string, taskType: string } };

export type TaskTypesTableCreateTaskTypeMutationVariables = Types.Exact<{
  input: Types.MutationCreateJobTaskTypeInput;
}>;


export type TaskTypesTableCreateTaskTypeMutation = { __typename: 'Mutation', createJobTaskType: { __typename: 'JobTaskType', id: string, cancelTimeout?: number | null, defaultTimeout?: number | null, endpoint?: string | null, invocationMethod: Types.InvocationMethod, maxTimeout?: number | null, queueGroup: string, taskType: string } };

export type TaskTypesTableDeleteTaskTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type TaskTypesTableDeleteTaskTypeMutation = { __typename: 'Mutation', deleteJobTaskType?: any | null };

export const TaskTypesTableItemFragmentDoc = gql`
    fragment TaskTypesTableItem on JobTaskType {
  id
  cancelTimeout
  defaultTimeout
  endpoint
  invocationMethod
  maxTimeout
  queueGroup
  taskType
}
    `;
export const TaskTypesTableItemsDocument = gql`
    query taskTypesTableItems($filter: JobTaskTypeFilterInput, $skip: Int, $take: Int) {
  items: jobTaskTypes(filter: $filter, skip: $skip, take: $take) {
    ...TaskTypesTableItem
  }
  itemsCount: jobTaskTypesCount(filter: $filter)
}
    ${TaskTypesTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaskTypesTableItemsGQL extends Apollo.Query<TaskTypesTableItemsQuery, TaskTypesTableItemsQueryVariables> {
    override document = TaskTypesTableItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaskTypesTableUpdateTaskTypeDocument = gql`
    mutation taskTypesTableUpdateTaskType($input: MutationUpdateJobTaskTypeInput!) {
  updateJobTaskType(input: $input) {
    ...TaskTypesTableItem
  }
}
    ${TaskTypesTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaskTypesTableUpdateTaskTypeGQL extends Apollo.Mutation<TaskTypesTableUpdateTaskTypeMutation, TaskTypesTableUpdateTaskTypeMutationVariables> {
    override document = TaskTypesTableUpdateTaskTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaskTypesTableCreateTaskTypeDocument = gql`
    mutation taskTypesTableCreateTaskType($input: MutationCreateJobTaskTypeInput!) {
  createJobTaskType(input: $input) {
    ...TaskTypesTableItem
  }
}
    ${TaskTypesTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaskTypesTableCreateTaskTypeGQL extends Apollo.Mutation<TaskTypesTableCreateTaskTypeMutation, TaskTypesTableCreateTaskTypeMutationVariables> {
    override document = TaskTypesTableCreateTaskTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaskTypesTableDeleteTaskTypeDocument = gql`
    mutation taskTypesTableDeleteTaskType($id: ID!) {
  deleteJobTaskType(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TaskTypesTableDeleteTaskTypeGQL extends Apollo.Mutation<TaskTypesTableDeleteTaskTypeMutation, TaskTypesTableDeleteTaskTypeMutationVariables> {
    override document = TaskTypesTableDeleteTaskTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }