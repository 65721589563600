import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AssignUserDialogUserFragment = { __typename: 'User', id: string, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type AssignUserDialogVisibleUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AssignUserDialogVisibleUsersQuery = { __typename: 'Query', users: Array<{ __typename: 'User', id: string, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export const AssignUserDialogUserFragmentDoc = gql`
    fragment AssignUserDialogUser on User {
  id
  name
  galleryImage {
    id
  }
}
    `;
export const AssignUserDialogVisibleUsersDocument = gql`
    query assignUserDialogVisibleUsers {
  users(filter: {visibleInFilters: true}, take: 1000) {
    ...AssignUserDialogUser
  }
}
    ${AssignUserDialogUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssignUserDialogVisibleUsersGQL extends Apollo.Query<AssignUserDialogVisibleUsersQuery, AssignUserDialogVisibleUsersQueryVariables> {
    override document = AssignUserDialogVisibleUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }