import {Component, viewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {TangentOutputs} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-tangent-node",
    templateUrl: "./tangent-node.component.html",
    styleUrls: ["./tangent-node.component.scss"],
    imports: [NodeBaseComponent],
})
export class TangentNodeComponent {
    readonly $nodeBase = viewChild<MaterialNodeBase>("nodeBase")
    outputs = TangentOutputs
    typeInfo = TangentNodeType
}

export const TangentNodeType: MaterialNodeType<typeof TangentNodeComponent> = {
    id: "tangent",
    label: "Tangent",
    color: "#9e343e",
    name: "ShaderNodeTangent",
    inputs: [] as never[],
    outputs: [TangentOutputs.tangent],
    component: TangentNodeComponent,
}
