<div>
    <mat-form-field>
        <mat-label>{{ $name() }}</mat-label>
        <mat-select [ngModel]="$value()" (ngModelChange)="onChanged.emit($event)" [disabled]="$disabled()">
            @for (option of $optionsArray(); track option.state) {
                <mat-option [value]="option.state">{{ option.label }}</mat-option>
            }
        </mat-select>
    </mat-form-field>
</div>
