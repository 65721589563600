import {Component} from "@angular/core"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {ValueSlotComponent} from "../../value-slot/value-slot.component"
import {LightPortal} from "@cm/template-nodes"

@Component({
    selector: "cm-light-portal-inspector",
    templateUrl: "./light-portal-inspector.component.html",
    styleUrl: "./light-portal-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent],
})
export class LightPortalInspectorComponent extends BaseInspectorComponent<LightPortal> {}
