import {Component, inject, input, OnDestroy, OnInit, signal, viewChild} from "@angular/core"
import {toObservable} from "@angular/core/rxjs-interop"
import {ConfiguratorComponent} from "@app/common/components/viewers/configurator/configurator/configurator.component"
import {IMaterialGraph} from "@cm/material-nodes"
import {IsNonNull} from "@cm/utils"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {OrganizationsWithNodeEditorTemplateIdGQL} from "@material-editor/components/material-editor/material-editor.generated"
import {combineLatest} from "rxjs"
import {outputToObservable} from "@angular/core/rxjs-interop"

@Component({
    selector: "cm-preview-scene",
    templateUrl: "./preview-scene.component.html",
    styleUrls: ["./preview-scene.component.scss"],
    imports: [ConfiguratorComponent],
})
export class PreviewSceneComponent implements OnInit, OnDestroy {
    readonly $configurator = viewChild.required<ConfiguratorComponent>("configurator")

    readonly organizations = inject(OrganizationsService)

    readonly $templateId = signal<string | undefined>(undefined)
    readonly $materialGraph = input.required<IMaterialGraph>({alias: "materialGraph"})
    materialGraph$ = toObservable(this.$materialGraph)

    readonly organizationsWithNodeEditorTemplateId = inject(OrganizationsWithNodeEditorTemplateIdGQL)

    async ngOnInit() {
        this.$templateId.set(await this.getOrganizationTemplate())

        combineLatest([outputToObservable(this.$configurator().loadingCompleted), this.materialGraph$]).subscribe({
            next: ([_, materialGraph]) => {
                this.$configurator().setMaterialGraphToFirstInput(materialGraph)
            },
        })
    }

    async getOrganizationTemplate() {
        const organizationIds = this.organizations.$own()?.map((org) => org.id) ?? []
        const {organizations} = await fetchThrowingErrors(this.organizationsWithNodeEditorTemplateId)({organizationIds})
        const templateIds = organizations.map((org) => org?.nodeEditorTemplate?.id).filter(IsNonNull)
        return templateIds[0]
    }

    ngOnDestroy() {}
}
