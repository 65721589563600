import {SmoothingIndicatorToolboxItem} from "@app/textures/texture-editor/operator-stack/operators/highpass/toolbox/smoothing-indicator-toolbox-item"
import {OperatorToolboxBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-toolbox-base"
import {OperatorHighpass} from "app/textures/texture-editor/operator-stack/operators/highpass/operator-highpass"
import {DrawableImageBrushToolboxItem} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/drawable-image-brush-toolbox-item"
import {takeUntil} from "rxjs"
import {Vector2} from "@cm/math"
import {SelectionMode} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {outputToObservable} from "@angular/core/rxjs-interop"

export class HighpassToolbox extends OperatorToolboxBase<OperatorHighpass> {
    constructor(operator: OperatorHighpass) {
        super(operator, SelectionMode.None, true)

        this.brushToolboxItem = new DrawableImageBrushToolboxItem(this, operator.callback.imageOpContextWebGL2)
        this.brushToolboxItem.brushSettings = operator.brushSettings
        this.brushToolboxItem.brushStrokeUpdated.subscribe(() => {
            operator.requestEval()
            operator.markEdited()
        })
        this.brushToolboxItem.selected = false

        this.smoothingIndicatorToolboxItem = new SmoothingIndicatorToolboxItem(this)
        this.smoothingIndicatorToolboxItem.visible = true

        outputToObservable(this.canvasBase.canvasBoundsChange)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.updateLineGuides())
        this.updateLineGuides()
    }

    // setMaskReference(handle: DrawableImageHandle | undefined, boundingBoxHandling: "reset" | "fill") {
    //     this.brushToolboxItem.selected = handle !== undefined
    //     this.brushToolboxItem.setDrawableImage(handle, boundingBoxHandling)
    // }

    set showGuides(value: boolean) {
        this.smoothingIndicatorToolboxItem.visible = value
    }

    get showGuides(): boolean {
        return this.smoothingIndicatorToolboxItem.visible
    }

    updateLineGuides(): void {
        const tileBounds = this.getTileBounds()
        this.smoothingIndicatorToolboxItem.position = new Vector2(tileBounds.width / 2, tileBounds.height / 2)
        this.smoothingIndicatorToolboxItem.angle = this.operator.angleOffset
        this.smoothingIndicatorToolboxItem.width = this.operator.useH ? this.operator.smoothingDistanceH : 0
        this.smoothingIndicatorToolboxItem.height = this.operator.useV ? this.operator.smoothingDistanceV : 0
    }

    private smoothingIndicatorToolboxItem: SmoothingIndicatorToolboxItem
    readonly brushToolboxItem: DrawableImageBrushToolboxItem
}
