import {inject, Injectable, Injector} from "@angular/core"
import {MaterialAssetsRenderingMaterialFragment} from "@app/common/services/material-assets-rendering/material-assets-rendering.generated"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {CreateJobGraphData} from "@cm/job-nodes/job-nodes"
import {SceneNodes} from "@cm/template-nodes"
import {graphToJson} from "@cm/utils/graph-json"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {
    CM_TO_MM,
    MAX_PIXEL_PER_MM,
    renderGraphForTemplateGraph,
    setupTemplateGraphForPlaneRender,
} from "@common/helpers/rendering/material/material-assets-rendering/common"
import {jobGraphFn_thumbnail} from "@common/helpers/rendering/material/material-assets-rendering/material-thumbnail"
import {jobGraphFn_shaderBall, setupTemplateGraphForShaderBallRender} from "@common/helpers/rendering/material/material-assets-rendering/shader-ball"
import {jobGraphFn_tile, tileGenerationParamsForMaterial} from "@common/helpers/rendering/material/material-assets-rendering/tileable-material"
import {JobGraphMaterial} from "@common/models/material-assets-rendering/job-graph-material"
import {
    MaterialAssetsRenderingCreateJobTaskGQL,
    MaterialAssetsRenderingMaterialGQL,
} from "@common/services/material-assets-rendering/material-assets-rendering.generated"
import {MaterialGraphService} from "@common/services/material-graph/material-graph.service"
import {NameAssetFromSchemaService} from "@common/services/name-asset-from-schema/name-asset-from-schema.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {environment} from "@environment"
import {FlatOption} from "@labels"

const FOV = 0.005 //Smaller FOV moves the camera further away from the plane when generating thumbnails & tileable images. At distances > ~100km, we get rendering artifacts.
const SENSOR_SIZE = 36
const SAMPLES = environment.rendering.materialAssets.samples
const SAMPLES_FOR_SHADER_BALL_RENDER = environment.rendering.shaderBall.samples
const SENSOR_SIZE_FOR_SHADER_BALL_RENDER = 26

@Injectable({
    providedIn: "root",
})
export class MaterialAssetsRenderingService {
    private readonly nameAssetFromSchemaService = inject(NameAssetFromSchemaService)
    private readonly uploadGqlService = inject(UploadGqlService)
    private readonly materialGraphService = inject(MaterialGraphService)
    private readonly materialAssetsRenderingMaterial = inject(MaterialAssetsRenderingMaterialGQL)
    private readonly injector = inject(Injector)

    checkThumbnailSizeValid(resolution: number, size_in_cm: number): boolean {
        return Math.floor(resolution / size_in_cm / CM_TO_MM) < MAX_PIXEL_PER_MM
    }

    async fetchAndVerifyMaterialDetails(materialId: string): Promise<
        MaterialAssetsRenderingMaterialFragment &
            JobGraphMaterial & {
                latestCyclesRevision: {legacyId: number}
            }
    > {
        const detail = (await fetchThrowingErrors(this.materialAssetsRenderingMaterial)({materialId})).material
        if (!detail.name) throw new Error(`Missing name field for material with id: ${materialId}`)
        if (!detail.latestCyclesRevision) throw new Error(`Missing latest cycles revision field for material with id: ${materialId}`)
        if (!detail.organization) throw new Error(`Missing organization field for material with id: ${materialId}`)
        return {
            ...detail,
            latestCyclesRevision: detail.latestCyclesRevision,
            name: detail.name,
            organization: detail.organization,
        }
    }

    private getOptions(options?: {useGpu: boolean; useCloud: boolean}) {
        return {
            useGpu: options?.useGpu ?? true,
            useCloud: options?.useCloud ?? true,
        }
    }

    async generateShaderBallThumbnail(
        materialId: string,
        resolution: number,
        sceneManagerService: SceneManagerService,
        options?: {useGpu: boolean; useCloud: boolean},
    ) {
        const materialDetails = await this.fetchAndVerifyMaterialDetails(materialId)
        const jobName = `Shader ball generation for material ${materialDetails.legacyId}`
        const {useGpu, useCloud} = this.getOptions(options)

        const sceneNodesFn = () =>
            setupTemplateGraphForShaderBallRender(
                materialDetails.latestCyclesRevision.legacyId,
                resolution,
                resolution,
                SAMPLES_FOR_SHADER_BALL_RENDER,
                SENSOR_SIZE_FOR_SHADER_BALL_RENDER,
                this.injector,
                useGpu,
                useCloud,
                sceneManagerService,
            )

        const jobGraphFn = async (renderGraphId: number, materialDetails: JobGraphMaterial, useGpu: boolean, useCloud: boolean) =>
            jobGraphFn_shaderBall(renderGraphId, materialDetails, useGpu, useCloud)

        return this.prepareAndSubmitJob(jobName, materialDetails, sceneNodesFn, jobGraphFn, useGpu, useCloud)
    }

    async generateThumbnail(
        materialId: string,
        flatOption: FlatOption,
        sceneManagerService: SceneManagerService,
        options?: {useGpu: boolean; useCloud: boolean},
    ) {
        const materialDetails = await this.fetchAndVerifyMaterialDetails(materialId)
        const jobName = `Thumbnail generation for material ${materialDetails.legacyId}`
        const {useGpu, useCloud} = this.getOptions(options)

        const defaultNameBase = `Thumbnail - ${materialDetails.name} - ${flatOption.size.toFixed(2).replace(/\./gi, ",")}cm x ${flatOption.size
            .toFixed(2)
            .replace(/\./gi, ",")}cm`
        const filenames = await this.nameAssetFromSchemaService.getMaterialThumbnailNameSet(
            materialDetails.id,
            flatOption.dataObjectAssignmentType,
            defaultNameBase,
        )

        const sceneNodesFn = async () =>
            setupTemplateGraphForPlaneRender(
                materialDetails.latestCyclesRevision.legacyId,
                flatOption.resolution,
                flatOption.resolution,
                SAMPLES,
                flatOption.size,
                flatOption.size,
                FOV,
                SENSOR_SIZE,
                useGpu,
                useCloud,
                sceneManagerService,
            )

        const jobGraphFn = async (renderGraphId: number, materialDetails: JobGraphMaterial, useGpu: boolean, useCloud: boolean) =>
            jobGraphFn_thumbnail(renderGraphId, materialDetails, flatOption.resolution, flatOption.size, filenames, useGpu, useCloud)

        return this.prepareAndSubmitJob(jobName, materialDetails, sceneNodesFn, jobGraphFn, useGpu, useCloud)
    }

    async generateTile(materialId: string, sceneManagerService: SceneManagerService, options?: {useGpu: boolean; useCloud: boolean}): Promise<string> {
        const materialDetails = await this.fetchAndVerifyMaterialDetails(materialId)
        const jobName = `Tileable image generation for material ${materialDetails.legacyId}`
        const {useGpu, useCloud} = this.getOptions(options)

        const {
            repeatWidth,
            repeatHeight,
            repeatWidthCm,
            repeatHeightCm,
            paddingWidth,
            paddingHeight,
            paddedWidth,
            paddedHeight,
            paddedWidthCm,
            paddedHeightCm,
            offsetX,
            offsetY,
        } = await tileGenerationParamsForMaterial(materialDetails, this.materialGraphService)

        const defaultNameBase = `Tileable image - ${materialDetails.name} - ${repeatWidthCm.toFixed(2).replace(/\./gi, ",")}cm x ${repeatHeightCm
            .toFixed(2)
            .replace(/\./gi, ",")}cm`
        const filenames = await this.nameAssetFromSchemaService.getMaterialTileableRenderNameSet(materialDetails.id, defaultNameBase)

        const sceneNodesFn = async () =>
            setupTemplateGraphForPlaneRender(
                materialDetails.latestCyclesRevision.legacyId,
                paddedWidth,
                paddedHeight,
                SAMPLES,
                paddedWidthCm,
                paddedHeightCm,
                FOV,
                SENSOR_SIZE,
                useGpu,
                useCloud,
                sceneManagerService,
                offsetX,
                offsetY,
            )

        const jobGraphFn = async (renderGraphId: number, materialDetails: JobGraphMaterial, useGpu: boolean, useCloud: boolean) =>
            jobGraphFn_tile(
                renderGraphId,
                materialDetails,
                repeatWidthCm,
                repeatHeightCm,
                repeatWidth,
                repeatHeight,
                paddingWidth,
                paddingHeight,
                filenames,
                useGpu,
                useCloud,
            )

        return this.prepareAndSubmitJob(jobName, materialDetails, sceneNodesFn, jobGraphFn, useGpu, useCloud)
    }

    async prepareAndSubmitJob(
        jobName: string,
        materialDetails: {legacyId: number; name: string; organization: {id: string; legacyId: number}},
        sceneNodesFn: () => Promise<SceneNodes.SceneNode[]>,
        jobGraphFn: (
            renderGraphId: number,
            material: {legacyId: number; name: string; organization: {id: string; legacyId: number}},
            useGpu: boolean,
            useCloud: boolean,
        ) => Promise<CreateJobGraphData>,
        useGpu: boolean,
        useCloud: boolean,
    ): Promise<string> {
        const templateGraph = await sceneNodesFn()
        const renderGraph = await renderGraphForTemplateGraph(templateGraph, this.injector)

        const uploadResult = await this.uploadGqlService.createAndUploadDataObject(
            new File([JSON.stringify(graphToJson(renderGraph, console))], "render.json", {type: "application/json"}),
            {
                organizationId: materialDetails.organization.id,
            },
            {showUploadToolbar: false, processUpload: true},
        )
        const jobGraph = await jobGraphFn(uploadResult.legacyId, materialDetails, useGpu, useCloud)

        const materialAssetsRenderingCreateJobTask = this.injector.get(MaterialAssetsRenderingCreateJobTaskGQL)
        const {createJob} = await mutateThrowingErrors(materialAssetsRenderingCreateJobTask)({
            input: {
                name: jobName,
                organizationId: materialDetails.organization.id,
                graph: graphToJson(jobGraph, console),
            },
        })
        return createJob.id
    }
}
