import {Component, computed, effect, ElementRef, inject, Injector, input, viewChild} from "@angular/core"
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser"
import {getArAssignmentKey, getParametersForArGeneration} from "@app/common/helpers/ar/ar"
import {DialogSize} from "@app/common/models/dialogs"
import {NotificationsService} from "@app/common/services/notifications/notifications.service"
import {removeCachedArData} from "@app/platform/pages/ar-generation/helpers/helpers"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {GetTemplateRevisionIdForConfiguratorIframeTestGQL} from "@app/test/manual-testing/configurator-test-iframe/configurator-test-iframe.generated"
import {getImageParamDataFromUrl, sendMessage, setParameter, TestCaseEntry} from "@app/test/manual-testing/helpers/configurator-test-helpers"
import {TestContainerComponent} from "@app/test/manual-testing/test-container/test-container.component"
import {Source} from "@app/test/manual-testing/types/types"
import {loadTemplateGraph} from "@cm/template-nodes/utils/serialization-utils"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {GetTemplateRevisionForBackendArGenerationGQL} from "@pages/ar-generation/backend-ar-generation.generated"

@Component({
    selector: "cm-configurator-test-iframe",
    templateUrl: "./configurator-test-iframe.component.html",
    styleUrls: ["./configurator-test-iframe.component.scss"],
    providers: [SceneManagerService],
    imports: [TestContainerComponent],
})
export class ConfiguratorTestIframeComponent {
    readonly $source = input.required<Source>({alias: "source"})

    readonly $configuratorIframe = viewChild.required<ElementRef<HTMLIFrameElement>>("configuratorIframe")
    readonly sanitizer = inject(DomSanitizer)
    readonly injector = inject(Injector)
    readonly sceneManagerService = inject(SceneManagerService)
    readonly notifications = inject(NotificationsService)

    readonly templateRevisionGql = inject(GetTemplateRevisionForBackendArGenerationGQL)
    readonly templateRevisionIdGql = inject(GetTemplateRevisionIdForConfiguratorIframeTestGQL)

    dialogSizes = DialogSize
    safeUrl: SafeResourceUrl | undefined
    arTestTemplateId = "1499ef45-504e-4782-bdf1-3175f207a96f"

    readonly $configuratorUrl = computed(() => {
        const source = this.$source()
        switch (source) {
            case "prod":
                return "https://configurator.colormass.com"
            case "dev":
                return "https://configurator-dev.colormass.com"
            case "qa":
                return "https://configurator-qa.colormass.com"
            case "local":
                return "http://localhost:4300"
            default:
                throw new Error(`Unknown source ${source}`)
        }
    })

    constructor() {
        effect(() => {
            this.safeUrl = this.initConfiguratorUrl()
        })
    }

    private initConfiguratorUrl(): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(
            this.$configuratorUrl() + "?apiVersion=2&sceneId=3720&param(items/furniture)=config(pk9)&param(items/textile)=material-article-id(466569152)",
        )
    }

    ngOnInit() {
        this.safeUrl = this.initConfiguratorUrl()
    }

    testCases: TestCaseEntry[] = [
        {
            type: "info",
            name: "What you should see",
            tooltip:
                "A green chair that is fully visible, used on Maharam's website. This verifies, that material-article-id and config url parameters work, because the template is loaded with default parameters in the url. If the chair is not fully visible, there is a problem with the auto target camera.",
            fct: () => {},
        },
        {
            type: "test",
            name: "Material Article ID",
            tooltip:
                "Change material programmatically using an id defined by the customer. This verifies type material-article-id in setParameter and the FindMaterial node.",
            fct: () => setParameter(this.$configuratorIframe().nativeElement, "items/textile", "material-article-id", "466569047"),
        },
        {
            type: "test",
            name: "Material ID",
            tooltip: "Change material programmatically using a material uuid. This verifies type material in setParameter and the MaterialReference node. ",
            fct: () => setParameter(this.$configuratorIframe().nativeElement, "items/textile", "material", "f27b4ee3-c4b0-47cb-a709-87fdf8a3ac38"),
        },
        {
            type: "test",
            name: "Config ID",
            tooltip: "Change configuration to a different piece of furniture. This verifies the type config in setParameter.",
            fct: () => setParameter(this.$configuratorIframe().nativeElement, "items/furniture", "config", "odin"),
        },
        {
            type: "test",
            name: "Load DDS template",
            tooltip: "Load the template used for designtex' digital studio.",
            fct: () => {
                this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                    this.$configuratorUrl() + "?apiVersion=2&templateId=a2aaeee6-4001-4b98-ad40-a411402284bb",
                )
            },
        },
        {
            type: "info",
            name: "DDS info",
            tooltip:
                "Designtex digial studio uses different templates with different inputs. The tests in the following only verify that the general input logic works, but does not cover the individual substrates, input names and templates.",
            fct: () => {},
        },
        {
            type: "test",
            name: "Set in-memory image",
            tooltip: "Load the template used for designtex' digital studio",
            fct: async () => {
                const imageData = await getImageParamDataFromUrl(
                    "https://storage.googleapis.com/cm-platform-prod-media/7qL3Zr8XmivnHMdp0HNGqqcLdccZtqDLfJrY4FA4ZYW8sDHNm8ctlf8EhbnwqRFF0uWx8kuNsuPPVb5nyFBQagoZ67XRRRB3jbefabKaH1ysg1z0kPDI9mfO5vDXkwHT",
                )
                setParameter(this.$configuratorIframe().nativeElement, "overlayImage", "image", imageData)
            },
        },
        {
            type: "test",
            name: "Change substrate",
            tooltip: "Change substrate material: The color pattern remains, but the fabric structure should change.",
            fct: async () => {
                setParameter(this.$configuratorIframe().nativeElement, "substrate", "config", "gamut-dt02")
            },
        },
        {
            type: "test",
            name: "Change width/height",
            tooltip: "Change width/height of the pattern",
            fct: async () => {
                setParameter(this.$configuratorIframe().nativeElement, "width", "number", 30)
                setParameter(this.$configuratorIframe().nativeElement, "height", "number", 30)
            },
        },
        {
            type: "test",
            name: "Zoom in",
            tooltip: "Zoom in the camera",
            fct: () => {
                sendMessage(this.$configuratorIframe().nativeElement, "zoomIn", {value: 0.1})
            },
        },
        {
            type: "test",
            name: "Zoom out",
            tooltip: "Zoom out the camera",
            fct: () => {
                sendMessage(this.$configuratorIframe().nativeElement, "zoomOut", {value: 0.1})
            },
        },
        {
            type: "test",
            name: "Reset camera",
            tooltip: "Reset the camera to the default position",
            fct: () => {
                sendMessage(this.$configuratorIframe().nativeElement, "resetCamera")
            },
        },
        {
            type: "test",
            name: "Save snapshot",
            tooltip: "Save a snapshot of the current view using the configurator's api.",
            fct: () => {
                sendMessage(this.$configuratorIframe().nativeElement, "saveSnapshot")
            },
        },
        {
            type: "info",
            name: "Save snapshot 2",
            tooltip: "Save a snapshot with the button inside the configurator.",
            fct: () => {},
        },
        {
            type: "test",
            name: "Switch template",
            tooltip: `Switch to a different template for generating AR/STL/glTF files.`,
            fct: () => {
                this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.$configuratorUrl()}?templateId=${this.arTestTemplateId}`)
            },
        },
        {
            type: "test",
            name: "AR generation",
            tooltip: `Click to delete the previous AR model. Then click the AR button in the configurator and see if the model is re-generated.`,
            fct: async () => {
                const templateRevisionDataForHash = (await fetchThrowingErrors(this.templateRevisionIdGql)({templateId: this.arTestTemplateId})).template
                    .latestRevision
                if (!templateRevisionDataForHash) throw new Error("No template revision found.")

                this.sceneManagerService.$templateGraph.set(loadTemplateGraph(templateRevisionDataForHash.graph))
                await this.sceneManagerService.compileTemplate()
                await this.sceneManagerService.sync(true)
                const parameters = getParametersForArGeneration(this.sceneManagerService)
                const assignmentKey = getArAssignmentKey(parameters)

                const {templateRevision: templateRevisionDatForAr} = await fetchThrowingErrors(this.templateRevisionGql)({
                    id: templateRevisionDataForHash.id,
                    assignmentKey: {equals: assignmentKey},
                })

                await removeCachedArData(templateRevisionDatForAr, this.injector)

                this.notifications.showInfo("Ar data was successfully removed", 2000)
            },
        },
        {
            type: "info",
            name: "Stl download",
            tooltip: `Click the STL download button in the configurator and see if the STL file is downloaded.`,
            fct: () => {},
        },
        {
            type: "info",
            name: "glTF download",
            tooltip: `Click the glTF download button in the configurator and see if the glTF file is downloaded.`,
            fct: () => {},
        },
        {
            type: "info",
            name: "Fullscreen",
            tooltip: `Click the fullscreen button inside the configurator.`,
            fct: () => {},
        },
        {
            type: "info",
            name: "Change configurations",
            tooltip: `Change configurations in the internal menu and check, if a loading spinner appears on the button and in the action menu.`,
            fct: () => {},
        },
        {
            type: "info",
            name: "Labels",
            tooltip: `Is the test group in the configurator's menu displayed with two black labels? They do not do anything, but should be visible.`,
            fct: () => {},
        },
    ]
}
