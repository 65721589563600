import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ModelDetailsFragment = { __typename: 'Model', id: string, legacyId: number, articleId?: string | null, description?: string | null, comment?: string | null, name?: string | null, state: Types.ModelState, public: boolean, organization?: { __typename: 'Organization', id: string, legacyId: number } | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type ModelDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ModelDetailsQuery = { __typename: 'Query', item: { __typename: 'Model', id: string, legacyId: number, articleId?: string | null, description?: string | null, comment?: string | null, name?: string | null, state: Types.ModelState, public: boolean, organization?: { __typename: 'Organization', id: string, legacyId: number } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type UpdateModelDetailsMutationVariables = Types.Exact<{
  input: Types.MutationUpdateModelInput;
}>;


export type UpdateModelDetailsMutation = { __typename: 'Mutation', item: { __typename: 'Model', id: string, legacyId: number, articleId?: string | null, description?: string | null, comment?: string | null, name?: string | null, state: Types.ModelState, public: boolean, organization?: { __typename: 'Organization', id: string, legacyId: number } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type DeleteModelDetailsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteModelDetailsMutation = { __typename: 'Mutation', deleteModel?: any | null };

export const ModelDetailsFragmentDoc = gql`
    fragment ModelDetails on Model {
  __typename
  id
  legacyId
  articleId
  description
  comment
  name
  state
  public
  organization {
    id
    legacyId
  }
  galleryImage {
    id
  }
}
    `;
export const ModelDetailsDocument = gql`
    query modelDetails($id: ID!) {
  item: model(id: $id) {
    ...ModelDetails
  }
}
    ${ModelDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ModelDetailsGQL extends Apollo.Query<ModelDetailsQuery, ModelDetailsQueryVariables> {
    override document = ModelDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateModelDetailsDocument = gql`
    mutation updateModelDetails($input: MutationUpdateModelInput!) {
  item: updateModel(input: $input) {
    ...ModelDetails
  }
}
    ${ModelDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateModelDetailsGQL extends Apollo.Mutation<UpdateModelDetailsMutation, UpdateModelDetailsMutationVariables> {
    override document = UpdateModelDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteModelDetailsDocument = gql`
    mutation deleteModelDetails($id: ID!) {
  deleteModel(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteModelDetailsGQL extends Apollo.Mutation<DeleteModelDetailsMutation, DeleteModelDetailsMutationVariables> {
    override document = DeleteModelDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }