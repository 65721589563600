<p class="cm-list-title">
    {{ $label() }}
</p>
<div class="cm-list">
    @if (options) {
        @for (option of options; track option.state) {
            <cm-native-checkbox [connectedSelectionList]="selectedOptions" [value]="option.state" (valueChange)="onCheckboxToggled(option.state, $event)">
                <ng-container cm-label>
                    {{ option.label }}
                </ng-container>
            </cm-native-checkbox>
        }
    } @else {
        <cm-placeholder></cm-placeholder>
        <cm-placeholder></cm-placeholder>
        <cm-placeholder></cm-placeholder>
        <cm-placeholder></cm-placeholder>
    }
    <ng-content></ng-content>
</div>
