<mat-table [dataSource]="$data().items" #matTable="matTable">
    <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
        <mat-cell *matCellDef="let item; table: matTable">
            <cm-table-cell [item]="item">
                @if (item.data) {
                    <button
                        mat-icon-button
                        class="cm-copy-reference-button"
                        matTooltip="ID: {{ item.data.id }}"
                        (click)="utils.copyToClipboard(item.data.id); $event.stopPropagation()"
                    >
                        <i class="far fa-copy"></i>
                    </button>
                }
            </cm-table-cell>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="organization">
        <mat-header-cell *matHeaderCellDef>Organization</mat-header-cell>
        <mat-cell *matCellDef="let item; table: matTable">
            <cm-table-cell [item]="item">
                {{ item.data?.organization?.name }}
            </cm-table-cell>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let item; table: matTable">
            <cm-table-cell [item]="item">
                {{ item.data?.name }}
            </cm-table-cell>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="queueGroup">
        <mat-header-cell *matHeaderCellDef>Queue group</mat-header-cell>
        <mat-cell *matCellDef="let item; table: matTable">
            <cm-table-cell [item]="item">
                {{ item.data?.queueGroup }}
            </cm-table-cell>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="$columns()"></mat-header-row>
    <mat-row class="cm-list-row" (click)="$event.stopPropagation(); rowClick(row.data)" *matRowDef="let row; columns: $columns()"></mat-row>
</mat-table>

<router-outlet></router-outlet>
