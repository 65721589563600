import {Component, computed, DestroyRef, effect, inject, input, model, OnInit, Signal, signal, viewChild, WritableSignal} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatOptionModule} from "@angular/material/core"
import {MatDialog} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {RouterModule} from "@angular/router"
import {
    MaterialOutputFragment,
    MaterialOutputJobStateGQL,
    MaterialOutputsDataFragment,
    MaterialOutputsUpdateResultGQL,
} from "@app/platform/components/materials/material-outputs/material-outputs.generated"
import {MaterialMapsExporter} from "@cm/material-nodes/material-maps-exporter"
import {IsDefined} from "@cm/utils/filter"
import {DialogComponent} from "@common/components/dialogs/dialog/dialog.component"
import {HintComponent} from "@common/components/hint/hint.component"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {ImageViewerComponent} from "@common/components/viewers"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {getDefaultExportRequests} from "@common/helpers/material-maps-exporter"
import {AuthService} from "@common/services/auth/auth.service"
import {Hotkeys} from "@common/services/hotkeys/hotkeys.service"
import {ExportSummary, MaterialMapsExporterService} from "@common/services/material-maps-exporter/material-maps-exporter.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {ContentTypeModel, JobState, MaterialOutputType} from "@generated"
import {FlatThumbnailOptionLabels, PbrExportOptionLabels} from "@labels"
import {MaterialOutputsDataGQL} from "@platform/components/materials/material-outputs/material-outputs.generated"
import {catchError, of, Subject, switchMap, takeUntil, timer} from "rxjs"
import {
    OutputButtonProps,
    MaterialOutputButtonComponent,
    RenderEngineOptions,
    SharedOutputButtonProps,
} from "@platform/components/materials/material-output-button/material-output-button.component"
import {DropdownButtonComponent} from "@app/common/components/buttons/dropdown-button/dropdown-button.component"
import {DropdownInlineButtonComponent} from "@common/components/buttons/dropdown-inline-button/dropdown-inline-button.component"
import {MaterialOutputCustomPbrExportFormComponent} from "@platform/components/materials/material-output-custom-pbr-export-form/material-output-custom-pbr-export-form.component"
import {MaterialOutputPbrExportDetailComponent} from "@platform/components/materials/material-output-pbr-export-detail/material-output-pbr-export-detail.component"
import {RenameDialogComponent} from "@app/common/components/dialogs/rename-dialog/rename-dialog.component"
import {MaterialOutputService} from "@app/platform/services/material/material-output.service"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"

type MaterialOutputWithState = MaterialOutputFragment & {state?: JobState | "Processing"}

@Component({
    selector: "cm-material-outputs",
    imports: [
        MatTooltipModule,
        MatMenuModule,
        RouterModule,
        MatButtonModule,
        DropdownButtonComponent,
        DropdownInlineButtonComponent,
        ImageViewerComponent,
        MaterialOutputButtonComponent,
        MatInputModule,
        MatOptionModule,
        FormsModule,
        HintComponent,
        PlaceholderComponent,
        DropdownInlineButtonComponent,
        MaterialOutputCustomPbrExportFormComponent,
        MaterialOutputPbrExportDetailComponent,
    ],
    providers: [SceneManagerService],
    templateUrl: "./material-outputs.component.html",
    styleUrl: "./material-outputs.component.scss",
})
export class MaterialOutputsComponent implements OnInit {
    readonly $materialId = input.required<string>({alias: "materialId"})
    readonly $imageViewer = viewChild.required<ImageViewerComponent>("imageViewer")
    $needsConfirmationToClose = model.required<boolean>({alias: "needsConfirmationToClose"})

    $material: Signal<MaterialOutputsDataFragment | undefined> = signal(undefined)
    isDebugEnabled: boolean = false

    $customPbrExports: Signal<MaterialOutputWithState[]> = signal([])
    $defaultPbrExports: Signal<MaterialOutputWithState[]> = signal([])
    $flatImageDataObjectIds: Signal<string[]> = signal([])
    $outputsWithState: WritableSignal<MaterialOutputWithState[]> = signal([])
    $localOutputsInProgress: WritableSignal<{id: string; type: MaterialOutputType; outputCreated: boolean}[]> = signal([])

    stopPollingJobs$ = new Subject<void>()

    readonly permission = inject(PermissionsService)
    readonly matDialog = inject(MatDialog)
    readonly materialMapsExporterService = inject(MaterialMapsExporterService)
    readonly materialOutputService = inject(MaterialOutputService)
    readonly notifications = inject(NotificationsService)
    readonly refresh = inject(RefreshService)
    readonly auth = inject(AuthService)
    readonly hotkeys = inject(Hotkeys)
    readonly destroyRef = inject(DestroyRef)

    $can = this.permission.$to

    readonly dataGql = inject(MaterialOutputsDataGQL)
    readonly materialOutputJobStateGql = inject(MaterialOutputJobStateGQL)
    readonly updateMaterialOutputResultGql = inject(MaterialOutputsUpdateResultGQL)

    // TODO: Tileable images were downloaded as only zip files in the past.
    //  For those contents, no download image menu should appear and they should be downloaded directly.
    //  This checking is done with this method. When such old contents are replaced this method will be removed.
    get tileableImageIsZipFile(): boolean {
        const tileableImageExport = this.$material()?.outputs?.find((materialOutput) => materialOutput.type === MaterialOutputType.TileableImage)
        return tileableImageExport?.result?.mediaType === "application/zip"
    }

    constructor() {
        this.hotkeys
            .addShortcut(["control.shift.d"])
            .pipe(takeUntilDestroyed())
            .subscribe(() => {
                if (this.auth.isStaff()) {
                    this.isDebugEnabled = !this.isDebugEnabled
                    if (this.isDebugEnabled) {
                        console.log("DEBUG MODE ENABLED")
                    } else {
                        console.log("DEBUG MODE DISABLED")
                    }
                }
            })

        effect(() => {
            if (this.$localOutputsInProgress().length > 0) {
                this.$needsConfirmationToClose.update(() => true)
            } else {
                this.$needsConfirmationToClose.update(() => false)
            }
        })
    }

    ngOnInit() {
        void this.loadMaterialOutputs().then((material) => {
            this.refresh.observeItem$(material, false).subscribe(() => {
                void this.loadMaterialOutputs()
            })
        })
    }

    /**
     * Periodically checks the status of running output jobs.
     * Once the job is finished, the status is updated and the output is available for download.
     */
    async startJobStatusPolling(materialOutputs: MaterialOutputFragment[]) {
        this.stopPollingJobs$.next()
        materialOutputs.forEach((materialOutput) => {
            if (materialOutput.job && this.isJobRunning(materialOutput.job.state)) {
                timer(5000, 10 * 1000)
                    .pipe(
                        takeUntilDestroyed(this.destroyRef),
                        takeUntil(this.stopPollingJobs$),
                        switchMap(async () => {
                            const {job} = await fetchThrowingErrors(this.materialOutputJobStateGql)({id: materialOutput.job!.id})
                            if (!this.isJobRunning(job.state)) {
                                // this will reload the material outputs as well
                                this.refresh.item({id: this.$materialId(), __typename: ContentTypeModel.Material})
                            }
                        }),
                    )
                    .subscribe()
            }
        })
    }

    async loadMaterialOutputs() {
        const {material} = await fetchThrowingErrors(this.dataGql)({id: this.$materialId()})
        this.$material = signal(material)

        const outputsWithState: MaterialOutputWithState[] = material.outputs.map((materialOutput) => {
            let outputState
            if (materialOutput.job?.state) {
                outputState = materialOutput.job?.state
            } else if (materialOutput.result) {
                // migrated existing outputs will have no job connected
                outputState = JobState.Complete
            }

            return {
                ...materialOutput,
                state: outputState,
            }
        })

        // remove local outputs that were created in the backend
        this.$localOutputsInProgress.update((outputs) => outputs.filter((output) => !output.outputCreated))
        const localOutputsInProgress: MaterialOutputWithState[] = this.$localOutputsInProgress()
            .filter((output) => !output.outputCreated)
            .map((output) => ({
                id: output.id,
                __typename: "MaterialOutput",
                type: output.type,
                state: "Processing",
            }))
        this.$outputsWithState.set(outputsWithState.concat(localOutputsInProgress))

        this.startJobStatusPolling(material.outputs ?? [])

        this.$flatImageDataObjectIds = computed(() =>
            this.$outputsWithState()
                .filter((outputItem) => FlatThumbnailOptionLabels.has(outputItem.type))
                .map((outputItem) => outputItem.result?.id)
                .filter(IsDefined),
        )

        this.$defaultPbrExports = computed(() =>
            this.$outputsWithState()
                .filter((outputItem) => PbrExportOptionLabels.has(outputItem.type))
                .filter(IsDefined),
        )

        this.$customPbrExports = computed(() => this.$outputsWithState().filter((outputItem) => outputItem.type === MaterialOutputType.PbrExportCustom))

        return material
    }

    openImageViewer(dataObjectId: string, dataObjectIds: string[]): void {
        void this.$imageViewer().openViewer(dataObjectId, dataObjectIds)
    }

    generateCustomPbrExport = async (conversionRequest: MaterialMapsExporter.ConversionRequest) => {
        const exportName = this.$material()?.name ?? ""
        const exportRequest = MaterialMapsExporter.exportRequest(
            MaterialMapsExporter.exportFolder([conversionRequest, MaterialMapsExporter.conversionInfoRequest(conversionRequest, "info", "text")], exportName),
            {source: "materialRevision"},
        )
        return this.createOutput(MaterialOutputType.PbrExportCustom, undefined, exportRequest)
    }

    renamePbrExport = async (materialOutput: MaterialOutputWithState) => {
        if (materialOutput.state != "Complete" || !materialOutput.result) {
            this.notifications.showInfo("Cannot rename PBR export without output data object")
            return
        }

        this.matDialog
            .open(RenameDialogComponent, {
                width: "400px",
                data: {
                    currentName: materialOutput.result.originalFileName.replace(".zip", ""),
                },
            })
            .afterClosed()
            .pipe(
                switchMap(async (filename) => {
                    if (!filename) {
                        return
                    }
                    await mutateThrowingErrors(this.updateMaterialOutputResultGql)({
                        input: {
                            id: materialOutput!.result!.id,
                            originalFileName: `${filename}.zip`,
                        },
                    })
                    this.notifications.showInfo("PBR export renamed")
                    this.refresh.item({id: this.$materialId(), __typename: ContentTypeModel.Material})
                }),
                catchError((err) => {
                    this.notifications.showInfo(`Failed to rename PBR export: ${err}`, undefined, "Dismiss")
                    return of()
                }),
            )
            .subscribe()
    }

    downloadOutputResult(materialOutput: MaterialOutputWithState) {
        if (materialOutput.result) {
            const anchor = document.createElement("a")
            anchor.href = materialOutput.result.downloadUrl
            anchor.click()
        }
    }

    // Computed button state signals
    $flatThumbnailButtonsState: Signal<OutputButtonProps[]> = computed(() => {
        return Array.from(FlatThumbnailOptionLabels.entries()).map(([type, {label}]) => {
            const sharedButtonProps: SharedOutputButtonProps = {
                buttonClass: "cm-text-button",
                label,
            }
            const materialOutput = this.$outputsWithState().find((materialOutput) => materialOutput.type === type)
            switch (materialOutput?.state) {
                case "Processing":
                    return {
                        ...sharedButtonProps,
                        canCancel: false,
                        cancelTooltip: "Processing...",
                        state: "loading",
                    }
                case "Init": // falls-through
                case "Runnable": // falls-through
                case "Running":
                    return {
                        ...sharedButtonProps,
                        canCancel: true,
                        cancelTooltip: "Cancel job",
                        onCancel: () => this.cancelOutput(materialOutput),
                        state: "loading",
                    }
                case "Cancelled":
                    return {
                        ...sharedButtonProps,
                        onRemove: () => this.removeOutput(materialOutput),
                        onRetry: () => this.restartOutput(materialOutput),
                        removeTooltip: "Delete cancelled output",
                        retryTooltip: "Restart cancelled job",
                        state: "failed",
                    }
                case "Failed":
                    return {
                        ...sharedButtonProps,
                        onRemove: () => this.removeOutput(materialOutput),
                        onRetry: () => this.restartOutput(materialOutput),
                        removeTooltip: "Delete failed output",
                        retryTooltip: "Restart failed job",
                        state: "failed",
                    }
                case "Complete": {
                    return {
                        ...sharedButtonProps,
                        canPreview: true,
                        canRemove: true,
                        downloadTooltip: "Download thumbnail",
                        onPreview: () => this.openImageViewer(materialOutput.result!.id, this.$flatImageDataObjectIds()),
                        onRemove: () => this.removeOutput(materialOutput),
                        previewTooltip: "View thumbnail",
                        removeTooltip: "Delete thumbnail",
                        result: materialOutput.result,
                        state: "complete",
                    }
                }
                default: {
                    return {
                        ...sharedButtonProps,
                        createTooltip: `Generate ${label} flat image`,
                        onCreate: () => this.createOutput(materialOutput?.type ?? type),
                        state: "initial",
                    }
                }
            }
        })
    })

    $tileableImageButtonState: Signal<OutputButtonProps> = computed(() => {
        const materialOutput = this.$outputsWithState().find((materialOutput) => materialOutput.type === MaterialOutputType.TileableImage)
        const sharedButtonProps: SharedOutputButtonProps = {
            buttonClass: "cm-button",
            icon: "fa-regular fa-image",
            label: "Tileable image",
        }
        switch (materialOutput?.state) {
            case "Processing":
                return {
                    ...sharedButtonProps,
                    canCancel: false,
                    cancelTooltip: "Processing...",
                    state: "loading",
                }
            case "Init": // falls-through
            case "Runnable": // falls-through
            case "Running":
                return {
                    ...sharedButtonProps,
                    canCancel: true,
                    cancelTooltip: "Cancel job",
                    onCancel: () => this.cancelOutput(materialOutput),
                    state: "loading",
                }
            case "Cancelled":
                return {
                    ...sharedButtonProps,
                    onRemove: () => this.removeOutput(materialOutput),
                    onRetry: () => this.restartOutput(materialOutput),
                    removeTooltip: "Delete cancelled output",
                    retryTooltip: "Restart cancelled job",
                    state: "failed",
                }
            case "Failed":
                return {
                    ...sharedButtonProps,
                    onRemove: () => this.removeOutput(materialOutput),
                    onRetry: () => this.restartOutput(materialOutput),
                    removeTooltip: "Delete failed output",
                    retryTooltip: "Restart failed job",
                    state: "failed",
                }
            case "Complete": {
                return {
                    ...sharedButtonProps,
                    canRemove: true,
                    downloadTooltip: "Download tileable image",
                    onDownload: () => this.downloadOutputResult(materialOutput),
                    onRemove: () => this.removeOutput(materialOutput),
                    removeTooltip: "Delete tileable image",
                    result: materialOutput.result,
                    state: "complete",
                }
            }
            default: {
                return {
                    ...sharedButtonProps,
                    createTooltip: "Generate tileable image",
                    onCreate: (options) => this.createOutput(MaterialOutputType.TileableImage, options),
                    state: "initial",
                }
            }
        }
    })

    $shaderBallButtonState: Signal<OutputButtonProps> = computed(() => {
        const sharedButtonProps: SharedOutputButtonProps = {
            buttonClass: "cm-button",
            icon: "fa-solid fa-circle-small",
            label: "Shader ball",
        }
        const materialOutput = this.$outputsWithState().find((materialOutput) => materialOutput.type === MaterialOutputType.ShaderBall)
        switch (materialOutput?.state) {
            case "Processing":
                return {
                    ...sharedButtonProps,
                    canCancel: false,
                    cancelTooltip: "Processing...",
                    state: "loading",
                }
            case "Init": // falls-through
            case "Runnable": // falls-through
            case "Running":
                return {
                    ...sharedButtonProps,
                    canCancel: true,
                    cancelTooltip: "Cancel job",
                    onCancel: () => this.cancelOutput(materialOutput),
                    state: "loading",
                }
            case "Cancelled":
                return {
                    ...sharedButtonProps,
                    onRemove: () => this.removeOutput(materialOutput),
                    onRetry: () => this.restartOutput(materialOutput),
                    removeTooltip: "Delete cancelled output",
                    retryTooltip: "Restart cancelled job",
                    state: "failed",
                }
            case "Failed":
                return {
                    ...sharedButtonProps,
                    onRemove: () => this.removeOutput(materialOutput),
                    onRetry: () => this.restartOutput(materialOutput),
                    removeTooltip: "Delete failed output",
                    retryTooltip: "Restart failed job",
                    state: "failed",
                }
            case "Complete": {
                return {
                    ...sharedButtonProps,
                    canRemove: true,
                    downloadTooltip: "Download shader ball image",
                    onRemove: () => this.removeOutput(materialOutput),
                    removeTooltip: "Delete shader ball image",
                    result: materialOutput?.result,
                    state: "complete",
                }
            }
            default:
                return {
                    ...sharedButtonProps,
                    createTooltip: "Generate shader ball image",
                    onCreate: () => this.createOutput(MaterialOutputType.ShaderBall),
                    state: "initial",
                }
        }
    })

    $defaultPbrExportsButtonStates: Signal<(OutputButtonProps & {exportSummary?: ExportSummary; sourceInfo?: string})[]> = computed(() => {
        return getDefaultExportRequests().map((exportRequest) => {
            const materialOutput = this.$outputsWithState().find((materialOutput) => materialOutput.type === exportRequest.materialOutputType)
            const sharedButtonProps: SharedOutputButtonProps & {exportSummary?: ExportSummary; sourceInfo?: string} = {
                buttonClass: "cm-text-button",
                label: exportRequest.displayName,
                exportSummary: materialOutput?.config?.content
                    ? this.materialMapsExporterService.getSummaryForExportConfigOrExportRequest(materialOutput?.config?.content)
                    : undefined,
                sourceInfo: `Material revision ${materialOutput?.materialRevision?.number || materialOutput?.config?.content["sourceInfoRequest"]?.sourceId}`,
            }
            switch (materialOutput?.state) {
                case "Processing":
                    return {
                        ...sharedButtonProps,
                        canCancel: false,
                        cancelTooltip: "Processing...",
                        state: "loading",
                    }
                case "Init": // falls-through
                case "Runnable": // falls-through
                case "Running":
                    return {
                        ...sharedButtonProps,
                        canCancel: true,
                        cancelTooltip: "Cancel job",
                        onCancel: () => this.cancelOutput(materialOutput),
                        state: "loading",
                    }
                case "Cancelled":
                    return {
                        ...sharedButtonProps,
                        onRemove: () => this.removeOutput(materialOutput),
                        onRetry: () => this.restartOutput(materialOutput),
                        removeTooltip: "Delete cancelled output",
                        retryTooltip: "Restart cancelled job",
                        state: "failed",
                    }
                case "Failed":
                    return {
                        ...sharedButtonProps,
                        onRemove: () => this.removeOutput(materialOutput),
                        onRetry: () => this.restartOutput(materialOutput),
                        removeTooltip: "Delete failed output",
                        retryTooltip: "Restart failed job",
                        state: "failed",
                    }
                case "Complete": {
                    return {
                        ...sharedButtonProps,
                        canEdit: this.$can().update.material(null, "name"),
                        canRemove: this.$can().delete.materialMapsExport(),
                        downloadTooltip: `Download ${exportRequest.displayName} export`,
                        editTooltip: "Rename PBR export",
                        hasUpdate:
                            this.$can().read.material(null, "sourceUpdates") &&
                            this.$material()?.latestCyclesRevision?.id !== materialOutput.materialRevision?.id,
                        onDownload: () => this.downloadOutputResult(materialOutput),
                        onEdit: () => this.renamePbrExport(materialOutput),
                        onRemove: () => this.removeOutput(materialOutput),
                        removeTooltip: "Delete PBR export",
                        state: "complete",
                        updateTooltip: "Source updates available",
                    }
                }
                default: {
                    return {
                        ...sharedButtonProps,
                        createTooltip: `Generate ${exportRequest.displayName} export`,
                        onCreate: () => this.createOutput(exportRequest.materialOutputType, undefined, exportRequest),
                        state: "initial",
                    }
                }
            }
        })
    })

    $customPbrExportsButtonStates: Signal<(OutputButtonProps & {exportSummary?: ExportSummary; sourceInfo?: string})[]> = computed(() => {
        return this.$customPbrExports().map((materialOutput) => {
            const sharedButtonProps: SharedOutputButtonProps & {exportSummary?: ExportSummary; sourceInfo?: string} = {
                buttonClass: "cm-text-button",
                label: this.$material()?.name ?? "Custom export",
                exportSummary: materialOutput?.config?.content
                    ? this.materialMapsExporterService.getSummaryForExportConfigOrExportRequest(materialOutput?.config?.content)
                    : undefined,
                sourceInfo: `Material revision ${materialOutput?.materialRevision?.number || materialOutput?.config?.content["sourceInfoRequest"]?.sourceId}`,
            }
            switch (materialOutput?.state) {
                case "Processing":
                    return {
                        ...sharedButtonProps,
                        canCancel: false,
                        cancelTooltip: "Processing...",
                        state: "loading",
                    }
                case "Init": // falls-through
                case "Runnable": // falls-through
                case "Running":
                    return {
                        ...sharedButtonProps,
                        canCancel: true,
                        cancelTooltip: "Cancel job",
                        onCancel: () => this.cancelOutput(materialOutput),
                        state: "loading",
                    }
                case "Cancelled":
                    return {
                        ...sharedButtonProps,
                        onRemove: () => this.removeOutput(materialOutput),
                        onRetry: () => this.restartOutput(materialOutput),
                        removeTooltip: "Delete cancelled output",
                        retryTooltip: "Restart cancelled job",
                        state: "failed",
                    }
                case "Failed":
                    return {
                        ...sharedButtonProps,
                        onRemove: () => this.removeOutput(materialOutput),
                        onRetry: () => this.restartOutput(materialOutput),
                        removeTooltip: "Delete failed output",
                        retryTooltip: "Restart failed job",
                        state: "failed",
                    }
                case "Complete": {
                    return {
                        ...sharedButtonProps,
                        canEdit: this.$can().update.material(null, "name"),
                        canRemove: this.$can().delete.materialMapsExport(),
                        downloadTooltip: "Download PBR export",
                        editTooltip: "Rename PBR export",
                        hasUpdate:
                            this.$can().read.material(null, "sourceUpdates") &&
                            this.$material()?.latestCyclesRevision?.id !== materialOutput.materialRevision?.id,
                        onDownload: () => this.downloadOutputResult(materialOutput),
                        onEdit: () => this.renamePbrExport(materialOutput),
                        onRemove: () => this.removeOutput(materialOutput),
                        removeTooltip: "Delete PBR export",
                        state: "complete",
                        updateTooltip: "Source updates available",
                    }
                }
                default: {
                    // "initial" state does not exist for custom PBR exports
                    return {
                        ...sharedButtonProps,
                        createTooltip: "Processing...",
                        onCreate: () => {},
                        state: "initial",
                    }
                }
            }
        })
    })

    async createOutput(type: MaterialOutputType, options?: RenderEngineOptions, exportRequest?: MaterialMapsExporter.Request) {
        const materialRevisionId = this.$material()?.latestCyclesRevision?.id
        if (materialRevisionId) {
            // update local state immediatly, disables the button
            const tempId = crypto.randomUUID()
            this.$localOutputsInProgress.update((outputs) => [
                ...outputs,
                {
                    id: tempId,
                    type,
                    outputCreated: false,
                },
            ])
            this.$outputsWithState.update((outputs) => [
                ...outputs,
                {
                    id: tempId,
                    __typename: "MaterialOutput",
                    type,
                    state: "Processing",
                },
            ])

            await this.materialOutputService.createOutput({
                materialId: this.$materialId(),
                materialRevisionId,
                type,
                options,
                exportRequest,
                showNotification: true,
            })

            // mark local output as ready to be replaced by the backend output
            this.$localOutputsInProgress.update((outputs) =>
                outputs.map((output) => {
                    if (output.id === tempId) {
                        return {
                            ...output,
                            outputCreated: true,
                        }
                    }
                    return output
                }),
            )

            // refresh
            await this.loadMaterialOutputs()
        }
    }

    async cancelOutput(materialOutput: MaterialOutputWithState) {
        this.$outputsWithState.update((outputs) =>
            outputs.map((outputItem) => (outputItem.id === materialOutput.id ? {...outputItem, state: "Processing"} : outputItem)),
        )
        await this.materialOutputService.cancelAndRemoveOutput({
            materialOutput,
            showNotification: true,
        })
        await this.loadMaterialOutputs()
    }

    async restartOutput(materialOutput: MaterialOutputWithState) {
        this.$outputsWithState.update((outputs) =>
            outputs.map((outputItem) => (outputItem.id === materialOutput.id ? {...outputItem, state: "Processing"} : outputItem)),
        )
        await this.materialOutputService.restartOutput({
            materialOutput,
            showNotification: true,
        })
        await this.loadMaterialOutputs()
    }

    async removeOutput(materialOutput: MaterialOutputWithState) {
        const dialogRef = this.matDialog.open(DialogComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: "Delete item",
                message: "Are you sure you want to remove the file?",
                confirmLabel: "Delete item",
                cancelLabel: "Cancel",
            },
        })

        dialogRef.afterClosed().subscribe(async (confirmed) => {
            if (!confirmed) {
                return
            }
            this.cancelOutput(materialOutput)
        })
    }

    private isJobRunning(jobState: JobState) {
        return jobState === JobState.Runnable || jobState === JobState.Running || jobState === JobState.Init
    }
}
