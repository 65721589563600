import {Component, input} from "@angular/core"

@Component({
    selector: "cm-card-error",
    imports: [],
    templateUrl: "./card-error.component.html",
    styleUrl: "./card-error.component.scss",
})
export class CardErrorComponent {
    readonly $squareFormat = input(false, {alias: "squareFormat"})
    readonly $error = input<Error>(new Error("Not accessible"), {alias: "error"})

    get message() {
        return this.$error().message
    }
}
