// TODO do we have a double linked list implementation in the codebase?
export class DoublyLinkedList<T> {
    head: Node<T> | null = null
    tail: Node<T> | null = null

    // Clear the list
    clear(): void {
        this.head = this.tail = null
    }

    // Add a new node at the end
    append(value: T): Node<T> {
        const newNode = new Node(value)
        if (!this.tail) {
            this.head = this.tail = newNode
        } else {
            this.tail.next = newNode
            newNode.prev = this.tail
            this.tail = newNode
        }
        return newNode
    }

    // Remove a node
    remove(node: Node<T>): void {
        if (node.prev) {
            node.prev.next = node.next
        } else {
            this.head = node.next
        }
        if (node.next) {
            node.next.prev = node.prev
        } else {
            this.tail = node.prev
        }
        node.prev = null
        node.next = null
    }

    // Move a node to the end (most recently used)
    moveToEnd(node: Node<T>): void {
        if (this.tail === node) {
            return // It's already the last node
        }
        this.remove(node)
        if (this.tail) {
            this.tail.next = node
            node.prev = this.tail
            this.tail = node
        } else {
            this.head = this.tail = node
        }
    }

    // Move a node to the front (least recently used)
    moveToFront(node: Node<T>): void {
        if (this.head === node) {
            return // It's already the first node
        }
        this.remove(node)
        if (this.head) {
            this.head.prev = node
            node.next = this.head
            this.head = node
        } else {
            this.head = this.tail = node
        }
    }

    // Optional: For debugging purposes, to view the list state
    printList(): void {
        let current = this.head
        const values = []
        while (current) {
            values.push(current.value)
            current = current.next
        }
        console.log(values.join(" <-> "))
    }
}

export class Node<T> {
    value: T
    prev: Node<T> | null = null
    next: Node<T> | null = null

    constructor(value: T) {
        this.value = value
    }
}
