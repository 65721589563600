import {KeyValuePipe} from "@angular/common"
import {Component, DestroyRef, inject, input} from "@angular/core"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {OperatorListItemComponent} from "@app/textures/texture-editor/operators-panel/operator-list-item/operator-list-item.component"
import {ListItemComponent} from "@common/components/item"
import {Operator, OperatorType} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {byOperatorType, OperatorDesc} from "app/textures/texture-editor/operators-panel/operator-desc"
import {OperatorStack} from "app/textures/texture-editor/operator-stack/operator-stack"
import {DialogComponent} from "@app/common/components/dialogs/dialog/dialog.component"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatDialog} from "@angular/material/dialog"
import {AutoTilingState, OperatorAutoTiling} from "app/textures/texture-editor/operator-stack/operators/auto-tiling/operator-auto-tiling"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {HintComponent} from "@common/components/hint/hint.component"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {SliderComponent} from "@common/components/inputs/slider/slider.component"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"

@Component({
    selector: "cm-operators-panel",
    templateUrl: "./operators-panel.component.html",
    styleUrls: ["./operators-panel.component.scss"],
    imports: [
        OperatorListItemComponent,
        MatTooltipModule,
        ListItemComponent,
        MatMenuModule,
        KeyValuePipe,
        HintComponent,
        InspectorSectionComponent,
        SliderComponent,
        ToggleComponent,
    ],
})
export class OperatorsPanelComponent {
    readonly $disabled = input(false, {alias: "disabled"})
    readonly $operatorStack = input.required<OperatorStack>({alias: "operatorStack"})

    protected readonly permission = inject(PermissionsService)
    $can = this.permission.$to

    constructor(
        private destroyRef: DestroyRef,
        private dialog: MatDialog,
    ) {}

    ngOnInit() {
        this.$operatorStack()
            .selectedOperatorChanged.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.onSelectedOperatorChanged())
    }

    get maxCacheSizeGB(): number {
        const setCacheSize = this.$operatorStack().imageCacheWebGL2.maxCacheSize
        if (setCacheSize === "auto") {
            console.log("Cache size is set to auto which is not supported in the UI")
            return 64
        }
        return setCacheSize / (1024 * 1024 * 1024)
    }

    set maxCacheSizeGB(value: number) {
        this.$operatorStack().imageCacheWebGL2.maxCacheSize = value * 1024 * 1024 * 1024
    }

    protected get operators(): readonly Operator[] {
        return this.$operatorStack().operators
    }

    protected get selectedOperator(): Operator | null {
        return this.$operatorStack().selectedOperator
    }

    protected get operatorDescByType(): {[Key in OperatorType]?: OperatorDesc} {
        return byOperatorType
    }

    protected get canAddAutoTiling(): boolean {
        return !this.$disabled() && !this.$operatorStack().hasAppliedOperators() // we can only add an auto-tiling operator if there are no other operators yet
    }

    protected get canAddOperator(): boolean {
        const autoTilingOperator = this.operators.find((operator) => operator instanceof OperatorAutoTiling) as OperatorAutoTiling | undefined
        return (
            !this.$disabled() &&
            this.$operatorStack().hasAppliedOperators() &&
            (!autoTilingOperator || autoTilingOperator.autoTilingState === AutoTilingState.Complete)
        ) // we can only add non-auto-tiling operators if there is a auto-tiling operator and it has completed (or any other for backwards compatibility)
    }

    protected isOperatorTypeAllowed(operatorType: OperatorType): boolean {
        if (operatorType === "operator-initial") {
            return false
        }
        if (operatorType === "operator-test") {
            return this.$operatorStack().isDebugEnabled && this.$can().create.testOperator()
        }
        return operatorType !== "operator-auto-tiling" && operatorType !== "operator-tiling" // from the operator menu we omit the auto-tiling and tiling operator (it is handled as a special case)
    }

    protected async addOperator(operatorType: OperatorType) {
        const operatorStack = this.$operatorStack()
        operatorStack.muteUpdates = true // creation and selection causes reevaluation of the operator stack, so we mute updates to avoid it
        const operator = await operatorStack.createOperator(operatorType)
        operator.markEdited()
        this.selectOperator(operator)
        operatorStack.muteUpdates = false
    }

    protected selectOperator(operator: Operator): void {
        this.$operatorStack().selectedOperator = operator
    }

    protected lockOperator(operator: Operator): void {
        this.$operatorStack().setOperatorLocked(operator, !operator.locked)
    }

    protected enableOperator(operator: Operator): void {
        this.$operatorStack().setOperatorEnabled(operator, !operator.enabled)
    }

    protected async duplicateOperator(operator: Operator) {
        await this.$operatorStack().duplicateOperator(operator, true)
    }

    protected deleteOperator(operator: Operator) {
        const dialogRef = this.dialog.open(DialogComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: "Remove operator",
                message:
                    "The currently selected operator will be removed. " +
                    "This action <strong>cannot be undone</strong>.<br><br>Are you sure you want to continue?",
                confirmLabel: "Remove operator",
                cancelLabel: "Cancel",
            },
        })
        dialogRef
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((confirmed) => {
                if (confirmed) {
                    this.$operatorStack().removeOperator(operator)
                }
            })
    }

    protected selectedOperatorIndex = 0

    private onSelectedOperatorChanged() {
        this.selectedOperatorIndex = this.selectedOperator ? this.operators.indexOf(this.selectedOperator) : -1
    }
}
