<div class="cm-node-flex-container">
    @if ($priceContainerNode()) {
        <div class="cm-node-icon" matTooltip="Price Container" matTooltipPosition="above">
            <i class="fa-solid fa-layer-group"></i>
        </div>
        <span class="cm-node-text">{{ $description() }}</span>
        @if ($priceContainerNode()?.parameters?.condition; as condition) {
            <div class="cm-tools-right">
                <cm-variant-condition [variantConditionNode]="condition" (conditionRemoved)="conditionRemoved.emit()"></cm-variant-condition>
            </div>
        }
    }
</div>
