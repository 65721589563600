<div class="cm-canvas-with-ruler-container" [ngClass]="showRulers ? 'cm-container-with-rulers' : 'cm-container-without-rulers'">
    @if (showRulers) {
        <div class="cm-cell-ruler-corner">
            <div class="cm-ruler-corner" [matMenuTriggerFor]="rulerMenu">
                {{ measurementUnit }}
            </div>
        </div>
        <div class="cm-cell-ruler-x">
            <cm-ruler
                class="cm-ruler"
                orientation="horizontal"
                [pixelsPerUnit]="rulerPixelsPerUnit"
                [originOffset]="rulerXOriginUnitOffset"
                [cursorPosition]="rulerCursorPositionX"
                [showCursorLabel]="showCursorLabel"
            ></cm-ruler>
        </div>
        <div class="cm-cell-ruler-y">
            <cm-ruler
                class="cm-ruler"
                orientation="vertical"
                [pixelsPerUnit]="rulerPixelsPerUnit"
                [originOffset]="rulerYOriginUnitOffset"
                [cursorPosition]="rulerCursorPositionY"
                [showCursorLabel]="showCursorLabel"
            ></cm-ruler>
        </div>
    }
    <div class="cm-canvas-container">
        <canvas #canvasElement class="cm-base-canvas" resize (contextmenu)="(false)"></canvas>
        <cm-webgl2-canvas #webGlCanvas class="cm-webgl-canvas"></cm-webgl2-canvas>
        <ng-content></ng-content>
    </div>
</div>

<mat-menu #rulerMenu="matMenu">
    @for (measurementUnitOption of measurementUnitOptions; track measurementUnitOption) {
        <button mat-menu-item (click)="measurementUnit = measurementUnitOption">
            <span>{{ measurementUnitOption }}</span>
        </button>
    }
    <mat-divider />
    <button mat-menu-item (click)="showCursorLabel = !showCursorLabel">
        <span>{{ showCursorLabel ? "Hide cursor label" : "Show cursor label" }}</span>
    </button>
</mat-menu>
