import {Component, DestroyRef, inject} from "@angular/core"
import {ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from "@angular/material/card"
import {MatInputModule} from "@angular/material/input"
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner"
import {MatSnackBar} from "@angular/material/snack-bar"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ActivatedRoute, Router} from "@angular/router"
import {SiloService} from "@common/services/auth/silo.service"
import {MutationLoginInput} from "@generated"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {getPasswordHash} from "@common/helpers/auth/hash"
import {AuthService} from "@common/services/auth/auth.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {LoginResetPasswordGQL, LoginSendPasswordResetEmailGQL} from "@pages/login/login-page.generated"
import {NgOptimizedImage} from "@angular/common"
import {environment} from "@environment"
import {Settings} from "@app/common/models/settings/settings"
import {v4 as uuid4} from "uuid"

@Component({
    selector: "cm-login-page",
    templateUrl: "login-page.component.html",
    styleUrls: ["login-page.component.scss"],
    imports: [
        MatCardModule,
        MatInputModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        InputContainerComponent,
        MatTooltipModule,
        NgOptimizedImage,
    ],
})
export class LoginPageComponent {
    credentials: MutationLoginInput
    showProgressCircle = false
    passwordResetEmail = ""
    forgotPassword = false
    passwordVariantOne = ""
    passwordVariantTwo = ""
    passwordToken?: string

    loginForm = new UntypedFormGroup({
        loginEmailFormControl: new UntypedFormControl("", [Validators.required, Validators.email]),
        loginPasswordFormControl: new UntypedFormControl("", [Validators.required]),
    })

    forgotPasswordForm = new UntypedFormGroup({
        passwordResetEmailFormControl: new UntypedFormControl("", [Validators.required, Validators.email]),
    })

    passwordResetForm = new UntypedFormGroup({
        passwordVariantOneFormControl: new UntypedFormControl("", [Validators.required]),
        passwordVariantTwoFormControl: new UntypedFormControl("", [Validators.required]),
    })

    cardHeading = "Reset Password"

    googleSsoLink?: string
    microsoftSsoLink?: string

    readonly destroyRef = inject(DestroyRef)
    readonly organizations = inject(OrganizationsService)
    readonly silo = inject(SiloService)

    readonly notifications = inject(NotificationsService)
    readonly sendPasswordResetEmailGql = inject(LoginSendPasswordResetEmailGQL)
    readonly resetPasswordGql = inject(LoginResetPasswordGQL)

    constructor(
        public auth: AuthService,
        private snackBar: MatSnackBar,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this.credentials = {email: "", password: ""}
        this.showProgressCircle = false
        this.route.queryParams.subscribe((params) => {
            if (params.token) {
                this.passwordToken = params.token
            }
            if (params.flow === "welcome") {
                this.cardHeading = "Set your password"
            }
        })
        this.googleSsoLink = this.getGoogleSsoLink()
        this.microsoftSsoLink = this.getMicrosoftSsoLink()
    }

    async login() {
        this.showProgressCircle = true
        try {
            const success = await this.auth.logIn(this.credentials)
            if (success) {
                const returnUrl = this.route.snapshot.queryParams.returnUrl
                if (returnUrl) {
                    void this.router.navigateByUrl(returnUrl)
                } else {
                    await this.auth.navigateToDefaultView()
                }
            }
        } catch (error) {
            console.error(error)
            // TODO: deal with other errors (e.g. server unavailable) ?
            this.snackBar.open("Login failed. Wrong email or password.", "", {duration: 3000})
        } finally {
            this.showProgressCircle = false
        }
    }

    async sendForgotPasswordEmail(email: string) {
        try {
            this.showProgressCircle = true
            await mutateThrowingErrors(this.sendPasswordResetEmailGql)({input: {email}})
            this.notifications.showInfo("You will receive an email to help you reset your password, please check your inbox in a bit.", 10000)
        } catch (error) {
            this.notifications.showError(error)
        } finally {
            this.showProgressCircle = false
        }
    }

    async setPassword(newPassword: string) {
        this.showProgressCircle = true
        // Make sure to clear existing tokens.
        this.auth.logOut()
        const newPasswordHash: string = getPasswordHash(newPassword)
        const token = this.passwordToken
        if (token) {
            await this.notifications.withUserFeedback(
                async () => {
                    const email = (
                        await mutateThrowingErrors(this.resetPasswordGql)({
                            input: {
                                token,
                                password: newPasswordHash,
                            },
                        })
                    ).resetPassword
                    this.credentials.email = email
                    this.credentials.password = newPassword
                    void this.login()
                    this.showProgressCircle = false
                },
                {
                    success: "Your password has been changed successfully",
                    error: "Cannot change password",
                },
            )
        }
    }

    getGoogleSsoLink() {
        const clientId = environment.auth.google.clientId
        if (clientId) {
            const queryParams = new URLSearchParams({
                client_id: clientId,
                // redirect to the SSO callback handler page after the login with the identity provider
                redirect_uri: `${environment.platformDeployUrl}/sso/google`,
                response_type: "code",
                scope: "openid email",
                // this redirectUri comes from the query params and
                // refers to a page on the platform the user should be taken to after completion of all login steps
                state: btoa(JSON.stringify({csrfToken: this.auth.csrfToken, redirectUri: this.route.snapshot.queryParams.returnUrl})),
            })
            return `${Settings.GOOGLE_OAUTH_ENDPOINT}?${queryParams}`
        }
        return undefined
    }

    getMicrosoftSsoLink() {
        const clientId = environment.auth.microsoft.clientId
        if (clientId) {
            const queryParams = new URLSearchParams({
                client_id: clientId,
                redirect_uri: `${environment.platformDeployUrl}/sso/microsoft`,
                response_type: "code",
                scope: "openid profile email offline_access",
                state: btoa(JSON.stringify({csrfToken: this.auth.csrfToken, redirectUri: this.route.snapshot.queryParams.returnUrl})),
                response_mode: "fragment",
                nonce: uuid4(),
            })
            return `${Settings.MICROSOFT_OAUTH_ENDPOINT}?${queryParams}`
        }
        return undefined
    }
}
