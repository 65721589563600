import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DataObjectDetailsForImageInspectorFragment = { __typename: 'DataObject', id: string };

export type GetDataObjectForImageInspectorQueryVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type GetDataObjectForImageInspectorQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string } };

export const DataObjectDetailsForImageInspectorFragmentDoc = gql`
    fragment DataObjectDetailsForImageInspector on DataObject {
  id
}
    `;
export const GetDataObjectForImageInspectorDocument = gql`
    query getDataObjectForImageInspector($legacyId: Int!) {
  dataObject(legacyId: $legacyId) {
    ...DataObjectDetailsForImageInspector
  }
}
    ${DataObjectDetailsForImageInspectorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDataObjectForImageInspectorGQL extends Apollo.Query<GetDataObjectForImageInspectorQuery, GetDataObjectForImageInspectorQueryVariables> {
    override document = GetDataObjectForImageInspectorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }