export function extractBlobs(data: any, isBlobLike: (x: unknown) => boolean): [message: any, blobs: any[]] {
    const blobs: any[] = []
    const check_key = (k: any) => {
        if (typeof k !== "string") throw new Error(`Dict keys not a string: ${k}`)
        return k
    }
    const traverse = (x: any): any => {
        if (typeof x === "object") {
            if (Array.isArray(x)) {
                return x.map(traverse)
            } else if (isBlobLike(x)) {
                for (let idx = 0; idx < blobs.length; idx++) {
                    if (blobs[idx] === x) {
                        return {$blob: idx}
                    }
                }
                const idx = blobs.length
                blobs.push(x)
                return {$blob: idx}
            } else if (x === null) {
                return null
            } else {
                const ret: any = {}
                for (const k in x) {
                    ret[check_key(k)] = traverse(x[k])
                }
                return ret
            }
        } else {
            return x
        }
    }
    return [traverse(data), blobs]
}

export function resolveBlobs(msg: any, blobs: ArrayBufferView[]) {
    const traverse = (x: any): any => {
        if (typeof x === "object") {
            if (Array.isArray(x)) {
                return x.map(traverse)
            } else if (x === null) {
                return x
            } else if ("$blob" in x) {
                return blobs[x["$blob"]]
            } else {
                const ret: any = {}
                for (const k in x) {
                    ret[k] = traverse(x[k])
                }
                return ret
            }
        } else {
            return x
        }
    }
    return traverse(msg)
}
