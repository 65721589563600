import {Component, input} from "@angular/core"

@Component({
    selector: "cm-hint",
    templateUrl: "./hint.component.html",
    styleUrls: ["./hint.component.scss"],
})
export class HintComponent {
    readonly $hintType = input<number>(HintTypes.Info, {alias: "hintType"})
    hintTypes = HintTypes
}

export enum HintTypes {
    Info = 0,
    Warning = 10,
}
