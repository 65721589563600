import {ThreeAnnotationBase} from "@app/template-editor/helpers/three-annotation-base"
import {SceneNodes} from "@cm/template-nodes"
import {anyDifference, objectFieldsDifferent} from "@template-editor/helpers/change-detection"
import {ThreeObject, mathIsEqual, setThreeObjectPart, updateTransform} from "@template-editor/helpers/three-object"
import {ThreeSceneManagerService} from "@template-editor/services/three-scene-manager.service"
import {TemplateNodePart} from "../services/scene-manager.service"
import {AnnotationStyleRegistry} from "@app/template-editor/helpers/annotation-style-registry"

const css = `
.cm-annotation-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    background-color: #00000080;
    box-shadow: 0 0 0 2px #ffffff80;
    font-size: 14px;
    font-weight: bold;
    color: white;
    border-radius: 50%;
}
.cm-annotation-container {
    position: absolute;
    cursor: pointer;
    user-select: none;
    pointer-events: auto;
}
.cm-annotation-container:hover .cm-annotation-label {
    color: #40c4ff;
    box-shadow: 0 0 0 2px #40c4ff;
}`

AnnotationStyleRegistry.getInstance().registerStyles("annotation-styles", css)

export class ThreeAnnotationObject extends ThreeAnnotationBase {
    private readonly labelContainerElement: HTMLDivElement
    private readonly labelElement: HTMLDivElement
    private readonly descriptionElem: HTMLDivElement

    constructor(
        protected override threeSceneManagerService: ThreeSceneManagerService,
        protected override templateNodePart: TemplateNodePart,
        private readonly concealable: boolean,
    ) {
        super(threeSceneManagerService, templateNodePart)

        this.labelElement = document.createElement("div")
        this.labelElement.className = "cm-annotation-label"
        this.descriptionElem = document.createElement("div")

        this.labelContainerElement = document.createElement("div")
        this.labelContainerElement.className = "cm-annotation-container"
        this.labelContainerElement.appendChild(this.labelElement)

        this.init()
    }

    protected updateStyle(highlighted: boolean, selected: boolean): void {
        const backgroundColor = highlighted ? "red" : ""
        const pointerEvents = selected ? "none" : "auto"

        if (this.labelElement.style.backgroundColor !== backgroundColor || this.labelContainerElement.style.pointerEvents !== pointerEvents) {
            this.labelElement.style.backgroundColor = backgroundColor
            this.labelContainerElement.style.pointerEvents = pointerEvents
            this.dispose(false)
        }
    }

    isConcealable() {
        return this.concealable
    }

    updateLabels(label: string, description: string) {
        this.labelElement.innerHTML = label
        this.descriptionElem.innerHTML = description
        this.dispose(false)
    }

    protected getContainer(): HTMLDivElement {
        return this.labelContainerElement
    }

    getTranslationOffset() {
        return {x: "-50%", y: "-50%"}
    }

    getOcclusionOpacity(): number {
        return 0.2
    }
}

export class ThreeAnnotation extends ThreeObject<SceneNodes.Annotation> {
    protected override renderObject: ThreeAnnotationObject | undefined = undefined

    override setup(sceneNode: SceneNodes.Annotation) {
        if (this.renderObject === undefined) {
            const {sceneManagerService} = this.threeSceneManagerService
            const templateNodePart = sceneManagerService.sceneNodePartToTemplateNodePart({
                sceneNode,
                part: "root",
            })

            if (!templateNodePart) throw new Error("Template node part not found")

            this.renderObject = new ThreeAnnotationObject(this.threeSceneManagerService, templateNodePart, true)
            setThreeObjectPart(this.renderObject, this)
        }

        const renderObject = this.renderObject

        return anyDifference([
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["transform"],
                (valueA, valueB) => {
                    return mathIsEqual(valueA, valueB)
                },
                ({transform}) => {
                    updateTransform(transform, renderObject)
                },
            ),
            objectFieldsDifferent(sceneNode, this.parameters, ["label", "description"], undefined, ({label, description}) => {
                renderObject.updateLabels(label, description)
            }),
        ])
    }

    override dispose() {
        if (this.renderObject) this.renderObject.dispose(true)
    }
}
