import {inject, Injectable} from "@angular/core"
import {JobNodes} from "@cm/job-nodes/job-nodes"
import {cmRenderTaskForPassNames, PictureRenderJobOutput, RenderOutputForPassName, renderTaskQueueDomain} from "@cm/job-nodes/rendering"
import {RenderNodes} from "@cm/render-nodes"
import {SceneNodes} from "@cm/template-nodes"
import {buildRenderGraph} from "@cm/template-nodes/render-graph/scene-graph-to-render-graph"
import {graphToJson} from "@cm/utils/graph-json"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {Settings} from "@common/models/settings/settings"
import {HdriForRenderingGQL, RenderingServiceCreateJobGQL} from "@common/services/rendering/rendering.generated"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"

type Color = readonly [number, number, number]
type Vector = readonly [number, number, number]
type ShaderExprInlet<T> = ShaderExpr | T

class ShaderExpr {
    private constructor(
        readonly node: RenderNodes.ShaderNode,
        readonly output?: string,
    ) {}

    static node(
        type: string,
        inputs?: {[name: string]: ShaderExprInlet<number | boolean | string | Color | Vector | RenderNodes.Image>},
        output?: string,
    ): ShaderExpr {
        const node: RenderNodes.ShaderNode = {
            type,
        }
        if (inputs) {
            for (const [key, value] of Object.entries(inputs)) {
                if (value instanceof ShaderExpr) {
                    if (!node.inputs) node.inputs = {}
                    if (!value.output) throw Error("Output parameter not defined")
                    node.inputs[key] = [value.node, value.output]
                } else if (value != null) {
                    if (typeof value === "object" && (value as {type: string}).type !== undefined) {
                        if (!node.resources) node.resources = {}
                        node.resources[key] = value as RenderNodes.Image
                    } else {
                        if (!node.parameters) node.parameters = {}
                        node.parameters[key] = value
                    }
                }
            }
        }
        return new ShaderExpr(node, output)
    }

    compile(): RenderNodes.ShaderNode {
        return this.node
    }

    select(name: string) {
        return new ShaderExpr(this.node, name)
    }

    private static mathOp(name: string, value1: ShaderExprInlet<number>, value2?: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.node(
            "math",
            {
                math_type: name,
                value1: value1,
                ...(value2
                    ? {
                          value2: value2,
                      }
                    : {}),
            },
            "value",
        )
    }

    private static vectorMathOp(name: string, value1: ShaderExprInlet<Vector>, value2?: ShaderExprInlet<Vector>): ShaderExpr {
        return ShaderExpr.node(
            "vector_math",
            {
                math_type: name,
                vector1: value1,
                ...(value2
                    ? {
                          value2: value2,
                      }
                    : {}),
            },
            "vector",
        )
    }

    static dot(vector1: ShaderExprInlet<Vector>, vector2: ShaderExprInlet<Vector>): ShaderExpr {
        return ShaderExpr.node(
            "vector_math",
            {
                math_type: "dot_product",
                vector1,
                vector2,
            },
            "value",
        )
    }

    static value(v: number): ShaderExpr {
        return ShaderExpr.node("value", {value: v}, "value")
    }

    static color(c: Color): ShaderExpr {
        return ShaderExpr.node("color", {value: c}, "color")
    }

    vadd(b: ShaderExprInlet<Vector>): ShaderExpr {
        return ShaderExpr.vectorMathOp("add", this, b)
    }

    vsub(b: ShaderExprInlet<Vector>): ShaderExpr {
        return ShaderExpr.vectorMathOp("subtract", this, b)
    }

    vmul(b: ShaderExprInlet<Vector>): ShaderExpr {
        return ShaderExpr.vectorMathOp("multiply", this, b)
    }

    vdiv(b: ShaderExprInlet<Vector>): ShaderExpr {
        return ShaderExpr.vectorMathOp("divide", this, b)
    }

    dot(b: ShaderExprInlet<Vector>): ShaderExpr {
        return ShaderExpr.dot(this, b)
    }

    norm(): ShaderExpr {
        return ShaderExpr.node(
            "vector_math",
            {
                math_type: "length",
                vector1: this,
            },
            "value",
        )
    }

    scale(scale: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.node(
            "vector_math",
            {
                math_type: "scale",
                vector1: this,
                scale,
            },
            "vector",
        )
    }

    normalize(): ShaderExpr {
        return ShaderExpr.node(
            "vector_math",
            {
                math_type: "normalize",
                vector1: this,
            },
            "vector",
        )
    }

    add(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("add", this, b)
    }

    sub(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("subtract", this, b)
    }

    mul(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("multiply", this, b)
    }

    div(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("divide", this, b)
    }

    // TODO: mul_add
    sin(): ShaderExpr {
        return ShaderExpr.mathOp("sine", this)
    }

    cos(): ShaderExpr {
        return ShaderExpr.mathOp("cosine", this)
    }

    tan(): ShaderExpr {
        return ShaderExpr.mathOp("tangent", this)
    }

    sinh(): ShaderExpr {
        return ShaderExpr.mathOp("sinh", this)
    }

    cosh(): ShaderExpr {
        return ShaderExpr.mathOp("cosh", this)
    }

    tanh(): ShaderExpr {
        return ShaderExpr.mathOp("tanh", this)
    }

    asin(): ShaderExpr {
        return ShaderExpr.mathOp("arcsine", this)
    }

    acos(): ShaderExpr {
        return ShaderExpr.mathOp("arccosine", this)
    }

    atan(): ShaderExpr {
        return ShaderExpr.mathOp("arctangent", this)
    }

    pow(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("power", this, b)
    }

    log(): ShaderExpr {
        return ShaderExpr.mathOp("logarithm", this)
    }

    min(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("minimum", this, b)
    }

    max(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("maximum", this, b)
    }

    round(): ShaderExpr {
        return ShaderExpr.mathOp("round", this)
    }

    lt(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("less_than", this, b)
    }

    gt(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("greater_than", this, b)
    }

    mod(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("modulo", this, b)
    }

    abs(): ShaderExpr {
        return ShaderExpr.mathOp("absolute", this)
    }

    atan2(): ShaderExpr {
        return ShaderExpr.mathOp("arctan2", this)
    }

    floor(): ShaderExpr {
        return ShaderExpr.mathOp("floor", this)
    }

    ceil(): ShaderExpr {
        return ShaderExpr.mathOp("ceil", this)
    }

    frac(): ShaderExpr {
        return ShaderExpr.mathOp("fraction", this)
    }

    trunc(): ShaderExpr {
        return ShaderExpr.mathOp("trunc", this)
    }

    sqrt(): ShaderExpr {
        return ShaderExpr.mathOp("sqrt", this)
    }

    rsqrt(): ShaderExpr {
        return ShaderExpr.mathOp("inversesqrt", this)
    }

    sign(): ShaderExpr {
        return ShaderExpr.mathOp("sign", this)
    }

    exp(): ShaderExpr {
        return ShaderExpr.mathOp("exponent", this)
    }

    radians(): ShaderExpr {
        return ShaderExpr.mathOp("radians", this)
    }

    degrees(): ShaderExpr {
        return ShaderExpr.mathOp("degrees", this)
    }

    oneMinus(): ShaderExpr {
        return ShaderExpr.mathOp("subtract", 1.0, this)
    }

    neg(): ShaderExpr {
        return ShaderExpr.mathOp("multiply", this, -1.0)
    }

    square(): ShaderExpr {
        return this.mul(this)
    }

    separateRGB() {
        const sep = ShaderExpr.node("separate_rgb", {color: this})
        return {
            r: sep.select("r"),
            g: sep.select("g"),
            b: sep.select("b"),
        }
    }

    separateXYZ() {
        const sep = ShaderExpr.node("separate_xyz", {vector: this})
        return {
            x: sep.select("x"),
            y: sep.select("y"),
            z: sep.select("z"),
        }
    }

    static combineRGB(r: ShaderExprInlet<number>, g: ShaderExprInlet<number>, b: ShaderExprInlet<number>) {
        return ShaderExpr.node("combine_rgb", {r, g, b}, "image")
    }

    static combineXYZ(x: ShaderExprInlet<number>, y: ShaderExprInlet<number>, z: ShaderExprInlet<number>) {
        return ShaderExpr.node("combine_xyz", {x, y, z}, "vector")
    }

    // static cameraViewDirection(): ShaderExpr {
    //     // pointing _away_ from the camera!
    //     let vector = ShaderExpr.node('camera_info', undefined, 'view_vector');
    //     return ShaderExpr.node('vector_transform', {
    //         vector,
    //         transform_type: 'vector',
    //         convert_from: 'camera',
    //         convert_to: 'world'
    //     }, 'vector');
    // }

    static geometryInfo() {
        const expr = ShaderExpr.node("geometry")
        return {
            position: expr.select("position"),
            normal: expr.select("normal"),
            // tangent: expr.select('tangent'),
            incoming: expr.select("incoming"),
            backfacing: expr.select("backfacing"),
        }
    }

    static emissionBRDF(color: ShaderExprInlet<Color>, strength: ShaderExprInlet<number>) {
        return ShaderExpr.node("emission", {color, strength}, "emission")
    }

    static transparentBRDF() {
        return ShaderExpr.node("transparent_bsdf", undefined, "BSDF")
    }

    static backgroundBRDF(color: ShaderExprInlet<Color>, strength: ShaderExprInlet<number>) {
        return ShaderExpr.node("background_shader", {color, strength}, "background")
    }

    static addBRDF(a: ShaderExpr, b: ShaderExpr) {
        return ShaderExpr.node("add_closure", {closure1: a, closure2: b}, "closure")
    }

    static mapping(
        vector: ShaderExprInlet<Vector>,
        parameters: {
            rotation?: ShaderExprInlet<Vector>
            scale?: ShaderExprInlet<Vector>
        },
    ) {
        return ShaderExpr.node("mapping", {vector, ...parameters}, "vector")
    }

    static environmentTexture(vector: ShaderExprInlet<Vector>, image: RenderNodes.Image) {
        return ShaderExpr.node("environment_texture", {vector, image}, "color")
    }

    static imageTexture(vector: ShaderExprInlet<Vector>, image: RenderNodes.Image) {
        return ShaderExpr.node("image_texture", {vector, image}, "color")
    }

    static materialOutput(surface: ShaderExpr | null, parameters = {use_mis: true}) {
        return ShaderExpr.node("material_output", {...(surface ? {surface} : {}), use_mis: parameters.use_mis})
    }
}

@Injectable({
    providedIn: "root",
})
export class RenderingService {
    private readonly createJobGql = inject(RenderingServiceCreateJobGQL)
    private readonly hdriGql = inject(HdriForRenderingGQL)
    private readonly uploadGqlService = inject(UploadGqlService)

    async submitRenderJob(data: {nodes: SceneNodes.SceneNode[]; final: boolean; name: string; organizationLegacyId: number}) {
        const jobName = `RenderJob: ${data.name}`
        const {mainRenderTask, shadowMaskRenderTask, combinedRenderTask} = await this.createRenderTask(data)
        const jobGraph = JobNodes.jobGraph<PictureRenderJobOutput>(combinedRenderTask, {
            platformVersion: Settings.APP_VERSION,
            progress: JobNodes.progressGroupNoWeights(shadowMaskRenderTask ? [mainRenderTask, shadowMaskRenderTask] : [mainRenderTask], {
                output: mainRenderTask,
            }),
        })

        return mutateThrowingErrors(this.createJobGql)({
            input: {
                name: jobName,
                organizationLegacyId: data.organizationLegacyId,
                graph: graphToJson(jobGraph, console),
            },
        }).then(({createJob}) => createJob)
    }

    async createRenderTask(data: {nodes: SceneNodes.SceneNode[]; final: boolean; name: string; organizationLegacyId: number}) {
        const resolveFns = {
            getHdriDataObjectIdDetails: async (hdriIdDetails: {legacyId: number}): Promise<{legacyId: number}> => {
                const dataObjectIdDetails = (await fetchThrowingErrors(this.hdriGql)(hdriIdDetails)).hdri.dataObject
                if (!dataObjectIdDetails) throw Error("Failed to query hdri data object id details")
                return dataObjectIdDetails
            },
        }
        const mainRenderGraph = await buildRenderGraph({...data, verifySchema: true}, resolveFns)
        const mainRenderTask = await this._createRenderTask(mainRenderGraph, data)

        let shadowMaskRenderTask: JobNodes.TypedTaskNode<RenderOutputForPassName<"ShadowCatcher">> | null = null
        if (mainRenderGraph.session.options.passes?.includes("ShadowCatcher")) {
            const shadowMaskRenderGraph = await buildRenderGraph(
                {
                    ...data,
                    aoMaskPass: true,
                    verifySchema: true,
                },
                resolveFns,
            )
            shadowMaskRenderTask = await this._createRenderTask(shadowMaskRenderGraph, data, ["ShadowCatcher"])
        }

        return {
            mainRenderTask,
            shadowMaskRenderTask,
            combinedRenderTask: JobNodes.struct({
                renderPasses: JobNodes.get(mainRenderTask, "renderPasses"),
                preview: JobNodes.get(mainRenderTask, "preview"),
                metadata: JobNodes.get(mainRenderTask, "metadata"),
                aoShadowMaskPass: shadowMaskRenderTask
                    ? JobNodes.get(JobNodes.get(shadowMaskRenderTask, "renderPasses"), "ShadowCatcher")
                    : JobNodes.value(undefined),
            }),
        }
    }

    private async _createRenderTask<T extends RenderNodes.PassName>(
        graph: RenderNodes.Render,
        data: {
            organizationLegacyId: number
        },
        passes: T[] = [],
    ) {
        const uploadResult = await this.uploadGqlService.createAndUploadDataObject(
            new File([JSON.stringify(graphToJson(graph, console))], "render.json", {type: "application/json"}),
            {
                organizationLegacyId: data.organizationLegacyId,
            },
            {showUploadToolbar: false, processUpload: true},
        )

        return JobNodes.task(cmRenderTaskForPassNames(...passes), {
            input: JobNodes.value({
                renderGraph: JobNodes.dataObjectReference(uploadResult.legacyId),
                customerId: data.organizationLegacyId,
            }),
            queueDomain: renderTaskQueueDomain(graph.session.options.gpu, graph.session.options.cloud),
        })
    }
}
