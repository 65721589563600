<div [title]="$label()">{{ $label() }}</div>
<div [hidden]="connected.value" class="cm-vector-inputs">
    <cm-scalar-input-socket
        [label]="'X'"
        [range]="range"
        [hideBarFill]="true"
        [connected]="connected"
        [value]="$value()?.[0] ?? 0"
        (valueChange)="updateValue(0, $event)"
        type="input"
    >
    </cm-scalar-input-socket>
    <cm-scalar-input-socket
        [label]="'Y'"
        [range]="range"
        [hideBarFill]="true"
        [connected]="connected"
        [value]="$value()?.[1] ?? 0"
        (valueChange)="updateValue(1, $event)"
        type="input"
    >
    </cm-scalar-input-socket>
    <cm-scalar-input-socket
        [label]="'Z'"
        [range]="range"
        [hideBarFill]="true"
        [connected]="connected"
        [value]="$value()?.[2] ?? 0"
        (valueChange)="updateValue(2, $event)"
        type="input"
    >
    </cm-scalar-input-socket>
</div>
