import {Component, Inject} from "@angular/core"
import {MatButtonModule} from "@angular/material/button"
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle} from "@angular/material/dialog"
import {StateLabel} from "@labels"

@Component({
    selector: "cm-dialog",
    templateUrl: "dialog.component.html",
    styleUrls: ["dialog.component.scss"],
    imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatButtonModule],
})
export class DialogComponent {
    title = "Title not set."
    message = "Message not set."
    confirmLabel = "No confirm label set."
    cancelLabel = "No cancel label set."
    otherActions: StateLabel<string>[] | undefined
    isDestructive = false

    constructor(
        public dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string
            message: string
            confirmLabel: string
            cancelLabel: string
            isDestructive: boolean
            otherActions?: StateLabel<string>[]
        },
    ) {
        this.title = this.data.title
        this.message = this.data.message
        this.confirmLabel = this.data.confirmLabel
        this.cancelLabel = this.data.cancelLabel
        this.isDestructive = this.data.isDestructive
        this.otherActions = this.data.otherActions
    }

    cancelAction(): void {
        this.dialogRef.close(false)
    }

    confirmAction(value?: string): void {
        this.dialogRef.close(value ?? true)
    }
}
