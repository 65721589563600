import {Component} from "@angular/core"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {ValueSlotComponent} from "../../value-slot/value-slot.component"
import {OverlayMaterialColor} from "@cm/template-nodes"

@Component({
    selector: "cm-overlay-material-color-inspector",
    templateUrl: "./overlay-material-color-inspector.component.html",
    styleUrl: "./overlay-material-color-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent],
})
export class OverlayMaterialColorInspectorComponent extends BaseInspectorComponent<OverlayMaterialColor, {}> {}
