import {Component, ElementRef, Input, output, SimpleChanges, viewChild} from "@angular/core"

@Component({
    selector: "cm-native-checkbox",
    templateUrl: "./native-checkbox.component.html",
    styleUrls: ["./native-checkbox.component.scss"],
})
export class NativeCheckboxComponent {
    @Input({required: true}) value!: string
    @Input() checked?: boolean
    @Input() connectedSelectionList?: Set<string>
    readonly $checkbox = viewChild.required<ElementRef<HTMLInputElement>>("inputelement")
    readonly valueChange = output<boolean>()

    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges.connectedSelectionList) {
            this.updateCheckbox()
        }
    }

    updateValue(checked: boolean) {
        if (this.connectedSelectionList) {
            if (checked) {
                this.connectedSelectionList.add(this.value)
            } else {
                this.connectedSelectionList.delete(this.value)
            }
        }
        this.valueChange.emit(checked)
    }

    updateCheckbox() {
        const checkbox = this.$checkbox()
        if (checkbox) {
            checkbox.nativeElement.checked = this.connectedSelectionList?.has(this.value) ?? false
        }
    }
}
