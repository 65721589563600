import {DataType, ImageRef, ManagedImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {Box2, Size2, Vector2} from "@cm/math"
import {deepEqual} from "@cm/utils"
import {BrushSettings} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"
import {createHalGeometry} from "@common/models/hal/hal-geometry/create"
import {ImageOpContextWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-webgl2"
import {PainterPrimitiveRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/painter-ref"
import {HalGeometry} from "@common/models/hal/hal-geometry"
import {ImageOpCommandQueueWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-webgl2"
import {assertSameContext} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"

export class BrushShapeGenerator {
    constructor(readonly context: ImageOpContextWebGL2) {
        this.painter = this.context.createPainter(
            "primitive",
            "brushShapeGenerator",
            `
            uniform float u_brushWidth;
            uniform float u_brushHardness;
        
            float computeGaussian(float x, float radius) {
                float sigma = radius / 4.0;
                return exp((-x * x) / (2.0 * sigma * sigma));
            }
        
            vec4 computeColor(vec2 position, vec2 uv, vec4 color) {
                float r = length(uv);
                float maxHardness = 1.0 - 2.0 / u_brushWidth; // always do some anti-aliasing
                float hardness = min(u_brushHardness, maxHardness);
                float v = (r <= hardness) ? 1.0 : computeGaussian(r - hardness, 1.0 - hardness);
                return vec4(v, 0, 0, 0);
            }
        `,
        )
        this.geometry = createHalGeometry(this.context.halContext)
    }

    dispose(): void {
        this.context.releasePainter(this.painter)
        this.geometry.dispose()
        this.brushShapeImage?.release()
    }

    getBrushShape(
        cmdQueue: ImageOpCommandQueue,
        args: {
            brushSettings: BrushSettings
            dataType: DataType
        },
    ): ImageRef {
        if (!(cmdQueue instanceof ImageOpCommandQueueWebGL2)) {
            throw new Error("Unsupported command queue")
        }
        assertSameContext(cmdQueue, this.context)
        if (!this.brushShapeImage || !this.brushShapeSettings || !deepEqual(this.brushShapeSettings, args.brushSettings)) {
            this.brushShapeSettings = {
                ...args.brushSettings,
            }
            const brushShapeImageDimensions = this.getBrushShapeImageDimensions(args.brushSettings)
            const brushShapeImage = cmdQueue.createImage({
                ...brushShapeImageDimensions,
                dataType: args.dataType,
                channelLayout: "R",
            })
            this.brushShapeImage?.release()
            this.brushShapeImage = cmdQueue.keepAlive(brushShapeImage)
            this.geometry.clear()
            this.geometry.addRect(
                Box2.fromPositionAndSize(new Vector2(0, 0), new Vector2(brushShapeImage.descriptor.width, brushShapeImage.descriptor.height)),
                Box2.fromPositionAndSize(new Vector2(-1, -1), new Vector2(2, 2)),
            )
            cmdQueue.paint(this.painter, {
                resultImage: brushShapeImage,
                geometry: this.geometry,
                parameters: {
                    u_brushWidth: {type: "float", value: args.brushSettings.brushWidth},
                    u_brushHardness: {type: "float", value: args.brushSettings.brushHardness},
                },
            })
            return brushShapeImage
        } else {
            return this.brushShapeImage.ref
        }
    }

    private getBrushShapeImageDimensions(brushSettings: BrushSettings): Size2 {
        return new Size2(Math.ceil(brushSettings.brushWidth), Math.ceil(brushSettings.brushWidth))
    }

    private painter: PainterPrimitiveRef
    private geometry: HalGeometry
    private brushShapeImage?: ManagedImageRef
    private brushShapeSettings: BrushSettings | undefined = undefined
}
