export function b64toBlob(b64Data: string, contentType: string, sliceSize?: number) {
    contentType = contentType || ""
    sliceSize = sliceSize || 1024 * 128

    const byteCharacters = atob(b64Data)
    const byteArrays: Uint8Array[] = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray: Uint8Array = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }
    return new Blob(byteArrays, {type: contentType})
}

export function dataURLToBlob(dataUrl: string, contentType?: string): Blob {
    const [header, data] = dataUrl.split(",")
    if (contentType === undefined) {
        contentType = header.match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)![0]
    }
    return b64toBlob(data, contentType)
}

export const isBlobLike = (x: unknown) => {
    return ArrayBuffer.isView(x) || x instanceof ArrayBuffer || x instanceof Blob
}
