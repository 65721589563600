import {Component, viewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {AmbientOcclusionInputs, AmbientOcclusionOutputs} from "@material-editor/models/nodes/ambient-occlusion"

@Component({
    selector: "cm-ambient-occlusion-node",
    templateUrl: "./ambient-occlusion-node.component.html",
    styleUrls: ["./ambient-occlusion-node.component.scss"],
    imports: [NodeBaseComponent],
})
export class AmbientOcclusionNodeComponent {
    readonly $nodeBase = viewChild<MaterialNodeBase>("nodeBase")
    outputs = AmbientOcclusionOutputs
    inputs = AmbientOcclusionInputs
    typeInfo = AmbientOcclusionNodeType
}

export const AmbientOcclusionNodeType: MaterialNodeType<typeof AmbientOcclusionNodeComponent> = {
    id: "ambientOcclusion",
    label: "Amb Occlusion",
    color: "#8d802f",
    name: "ShaderNodeAmbientOcclusion",
    inputs: [AmbientOcclusionOutputs.color, AmbientOcclusionOutputs.distance, AmbientOcclusionOutputs.normal],
    outputs: [AmbientOcclusionOutputs.color, AmbientOcclusionOutputs.ao],
    component: AmbientOcclusionNodeComponent,
}
