<div class="cm-config-dropdowns-menu">
    @for (desc of $interfaceDescriptors(); track desc.props.id) {
        @if (isConfigInput(desc)) {
            <mat-form-field>
                <mat-label>{{ desc.props.name }}</mat-label>
                <mat-select [value]="desc.props.value?.id">
                    @for (variant of desc.props.variants; track variant.id) {
                        <mat-option [value]="variant.id" (click)="configMenuService.emitConfigSelected({config: desc, variant})">
                            {{ variant.name }}
                        </mat-option>
                    }
                </mat-select>
            </mat-form-field>
        }
        @if (isMaterialInput(desc)) {
            {{ desc.props.name }}
            <cm-select-material-button [materialInfo]="desc"></cm-select-material-button>
        }
    }
</div>
