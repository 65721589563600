import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UploadProcessingDataObjectFragment = { __typename: 'DataObject', downloadUrl: string, objectName: string, mediaType?: string | null, related: Array<{ __typename: 'DataObject', downloadUrl: string, objectName: string, mediaType?: string | null }>, metadataProcessingJob?: { __typename: 'Job', id: string } | null, thumbnailProcessingJob?: { __typename: 'Job', id: string } | null };

export type UploadProcessingDataObjectQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type UploadProcessingDataObjectQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', downloadUrl: string, objectName: string, mediaType?: string | null, related: Array<{ __typename: 'DataObject', downloadUrl: string, objectName: string, mediaType?: string | null }>, metadataProcessingJob?: { __typename: 'Job', id: string } | null, thumbnailProcessingJob?: { __typename: 'Job', id: string } | null } };

export type UploadProcessingCreateJobMutationVariables = Types.Exact<{
  input: Types.MutationCreateJobInput;
}>;


export type UploadProcessingCreateJobMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string } };

export const UploadProcessingDataObjectFragmentDoc = gql`
    fragment UploadProcessingDataObject on DataObject {
  downloadUrl
  objectName
  mediaType
  related {
    downloadUrl
    objectName
    mediaType
  }
  metadataProcessingJob {
    id
  }
  thumbnailProcessingJob {
    id
  }
}
    `;
export const UploadProcessingDataObjectDocument = gql`
    query uploadProcessingDataObject($id: ID, $legacyId: Int) {
  dataObject(id: $id, legacyId: $legacyId) {
    ...UploadProcessingDataObject
  }
}
    ${UploadProcessingDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadProcessingDataObjectGQL extends Apollo.Query<UploadProcessingDataObjectQuery, UploadProcessingDataObjectQueryVariables> {
    override document = UploadProcessingDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadProcessingCreateJobDocument = gql`
    mutation uploadProcessingCreateJob($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadProcessingCreateJobGQL extends Apollo.Mutation<UploadProcessingCreateJobMutation, UploadProcessingCreateJobMutationVariables> {
    override document = UploadProcessingCreateJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }