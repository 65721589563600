import {registerNode} from "@cm/graph/register-node"
import {AnyJSONValue, anyJsonValue, TemplateNode} from "#template-nodes/types"
import {evaluableNode, Node, node} from "#template-nodes/node-types"
import {z} from "zod"
import {DeclareTemplateNodeTS} from "#template-nodes/declare-template-node"

export type TemplateParameterValue = AnyJSONValue | Node
export type ParametersParameters = {[paramId: string]: TemplateParameterValue}

@registerNode
export class Parameters extends DeclareTemplateNodeTS<ParametersParameters>(
    {
        validation: {paramsSchema: z.record(z.string(), z.union([node, z.array(evaluableNode), anyJsonValue]))}, //anyJsonValue can be expensive for large data, thus put it last
    },
    {nodeClass: "Parameters"},
) {}
export type ParametersFwd = TemplateNode<ParametersParameters>
