import {Component, computed, signal} from "@angular/core"
import {ValueSlotComponent} from "../../value-slot/value-slot.component"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {ImageRgbCurve} from "@cm/template-nodes/nodes/image-rgb-curve"
import {RgbCurvesComponent} from "../../rgb-curves/rgb-curves.component"
import {Vector2Like} from "@cm/math"

@Component({
    selector: "cm-image-rgb-curve-inspector",
    templateUrl: "./image-rgb-curve-inspector.component.html",
    styleUrl: "./image-rgb-curve-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent, RgbCurvesComponent],
})
export class ImageRgbCurveInspectorComponent extends BaseInspectorComponent<ImageRgbCurve> {
    $controlPoints = computed(() => {
        const parameters = this.$parameters()
        const {rgb, r, g, b} = parameters
        return {rgb, r, g, b}
    })
    private readonly $isDragging = signal(false)
    private readonly $isMovingControlPoint = signal(false)

    addControlPoint(curveType: "rgb" | "r" | "g" | "b", point: Vector2Like) {
        const node = this.$node()

        this.sceneManagerService.modifyTemplateGraph(() => {
            node.updateParameters({
                [curveType]: [...this.$controlPoints()[curveType], point],
            })
        })
    }

    removeControlPoint(curveType: "rgb" | "r" | "g" | "b", pointId: number) {
        const node = this.$node()

        this.sceneManagerService.modifyTemplateGraph(() => {
            node.updateParameters({
                [curveType]: this.$controlPoints()[curveType].filter((_, i) => i !== pointId),
            })
        })
    }

    beginDrag() {
        this.$isDragging.set(true)
    }

    changeControlPoint(curveType: "rgb" | "r" | "g" | "b", pointId: number, point: Vector2Like) {
        const node = this.$node()

        if (!this.$isDragging()) return

        if (!this.$isMovingControlPoint()) {
            this.sceneManagerService.beginModifyTemplateGraph()
            this.$isMovingControlPoint.set(true)
        }

        this.sceneManagerService.modifyTemplateGraph(() => {
            node.updateParameters({
                [curveType]: this.$controlPoints()[curveType].map((p, i) => (i === pointId ? point : p)),
            })
            this.onUpdatedParameters()
        })
    }

    endDrag() {
        if (this.$isMovingControlPoint()) {
            this.sceneManagerService.endModifyTemplateGraph()
            this.$isMovingControlPoint.set(false)
        }
        this.$isDragging.set(false)
    }
}
