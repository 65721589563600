import {BoundsData, CompleteMeshData, UntransformedBoundsData} from "#template-nodes/geometry-processing/mesh-data"
import {ObjectId, SceneNodes} from "#template-nodes/interfaces/scene-object"
import {SolverData} from "#template-nodes/runtime-graph/nodes/solver/data"
import {SolverObjectData} from "#template-nodes/runtime-graph/nodes/solver/object-data"
import {Matrix4, Vector3} from "@cm/math"

export interface ObjectData {
    type?: string
    id: ObjectId
    topLevelObjectId: ObjectId
    bounds: BoundsData
    untransformedBounds: UntransformedBoundsData
    solverObject: SolverObjectData
    matrix: Matrix4
    preDisplayList: SceneNodes.SceneNode[]
    displayList: SceneNodes.SceneNode[]
    solverData: SolverData
    visible: boolean
}
export type MeshData = {
    type: "mesh"
    completeMeshData: CompleteMeshData
    isProcedural: boolean
    visibleDirectly: boolean
    visibleInReflections: boolean
    visibleInRefractions: boolean
    receiveRealtimeShadows: boolean
    castRealtimeShadows: boolean
}

export type MeshObjectData = ObjectData & MeshData

export function isMeshObjectData(objectData: ObjectData): objectData is MeshObjectData {
    return objectData.type === "mesh"
}

export type ControlPoint = {position: Vector3; normal: Vector3; corner: boolean}

export type CurvePoints = {
    points: Float32Array
    normals: Float32Array
    tangents: Float32Array
    segments: Float32Array
    scales: Float32Array
}

export type CurveData = {
    type: "curve"
    visible: boolean
    controlPoints: ControlPoint[]
    closed: boolean
    curvePoints: CurvePoints | null
    matrix: Matrix4
    meshId: ObjectId
}

export type CurveObjectData = ObjectData & CurveData

export function isCurveObjectData(objectData: ObjectData): objectData is CurveObjectData {
    return objectData.type === "curve"
}
