import {Component, input} from "@angular/core"

@Component({
    selector: "cm-image-placeholder",
    imports: [],
    templateUrl: "./image-placeholder.component.html",
    styleUrl: "./image-placeholder.component.scss",
})
export class ImagePlaceholderComponent {
    readonly $dark = input(false, {alias: "dark"})
    readonly $loading = input(true, {alias: "loading"})
}
