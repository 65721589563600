<div class="cm-content">
    <cm-value-slot
        [node]="$node()"
        [key]="'toneMapping'"
        [label]="'Tone mapping'"
        [selectionPossibilities]="toneMappingPossibilites"
        [isSelected]="toneMappingSelected"
    ></cm-value-slot>
    @if ($parameters().toneMapping?.mode === "filmic-advanced") {
        <div class="cm-deprecated">
            <i class="far fa-circle-exclamation"></i>
            This tonemapping is deprecated and should not be used anymore.
        </div>
    }
    <ng-content></ng-content>
    @if ($toneMappingProperties().length > 0) {
        <div class="cm-tone-mapping-settings">
            @for (toneMappingProperty of $toneMappingProperties(); track toneMappingProperty.key) {
                <cm-value-slot
                    [node]="$node()"
                    [key]="'toneMapping'"
                    [subKey]="toneMappingProperty.key"
                    [label]="toneMappingProperty.label"
                    [decimalPlaces]="2"
                ></cm-value-slot>
            }
        </div>
    }
</div>
