import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SelectAssetItemFragment = { __typename: 'Asset', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type SelectAssetItemsQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.AssetFilterInput>;
}>;


export type SelectAssetItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Asset', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export const SelectAssetItemFragmentDoc = gql`
    fragment SelectAssetItem on Asset {
  id
  id
  legacyId
  name
  organization {
    id
    name
  }
  galleryImage {
    id
  }
}
    `;
export const SelectAssetItemsDocument = gql`
    query selectAssetItems($skip: Int, $take: Int, $filter: AssetFilterInput) {
  items: assets(skip: $skip, take: $take, filter: $filter) {
    ...SelectAssetItem
  }
  totalCount: assetsCount(filter: $filter)
}
    ${SelectAssetItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SelectAssetItemsGQL extends Apollo.Query<SelectAssetItemsQuery, SelectAssetItemsQueryVariables> {
    override document = SelectAssetItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }