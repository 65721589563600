import * as Types from '../../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DataObjectForArServiceFragment = { __typename: 'DataObject', id: string, downloadUrl: string, objectName: string, mediaType?: string | null };

export type DataObjectWithRelatedForArServiceFragment = { __typename: 'DataObject', id: string, downloadUrl: string, objectName: string, mediaType?: string | null, related: Array<{ __typename: 'DataObject', id: string, downloadUrl: string, objectName: string, mediaType?: string | null }> };

export type GetDataObjectAssignmentsForArServiceQueryVariables = Types.Exact<{
  filter: Types.DataObjectAssignmentFilterInput;
}>;


export type GetDataObjectAssignmentsForArServiceQuery = { __typename: 'Query', dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, downloadUrl: string, objectName: string, mediaType?: string | null, related: Array<{ __typename: 'DataObject', id: string, downloadUrl: string, objectName: string, mediaType?: string | null }> } } | null> };

export type GetTemplateIdForArServiceQueryVariables = Types.Exact<{
  templateRevisionId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type GetTemplateIdForArServiceQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', id: string, template: { __typename: 'Template', id: string, legacyId: number, organizationId: string, organizationLegacyId: number } } };

export const DataObjectForArServiceFragmentDoc = gql`
    fragment DataObjectForArService on DataObject {
  id
  downloadUrl
  objectName
  mediaType
}
    `;
export const DataObjectWithRelatedForArServiceFragmentDoc = gql`
    fragment DataObjectWithRelatedForArService on DataObject {
  ...DataObjectForArService
  related {
    ...DataObjectForArService
  }
}
    `;
export const GetDataObjectAssignmentsForArServiceDocument = gql`
    query getDataObjectAssignmentsForArService($filter: DataObjectAssignmentFilterInput!) {
  dataObjectAssignments(filter: $filter) {
    id
    dataObject {
      ...DataObjectWithRelatedForArService
    }
  }
}
    ${DataObjectWithRelatedForArServiceFragmentDoc}
${DataObjectForArServiceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDataObjectAssignmentsForArServiceGQL extends Apollo.Query<GetDataObjectAssignmentsForArServiceQuery, GetDataObjectAssignmentsForArServiceQueryVariables> {
    override document = GetDataObjectAssignmentsForArServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateIdForArServiceDocument = gql`
    query getTemplateIdForArService($templateRevisionId: ID) {
  templateRevision(id: $templateRevisionId) {
    id
    template {
      id
      legacyId
      organizationId
      organizationLegacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateIdForArServiceGQL extends Apollo.Query<GetTemplateIdForArServiceQuery, GetTemplateIdForArServiceQueryVariables> {
    override document = GetTemplateIdForArServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }