import {MaterialSocket} from "@material-editor/models/material-socket"

export const MapRangeOutputs: Record<string, MaterialSocket> = {
    vector: {paramType: "socket", id: "Value", type: "output", valueType: "output", label: "Value"},
}

export const MapRangeInputs: Record<string, MaterialSocket> = {
    vector: {paramType: "socket", id: "Value", type: "input", valueType: "scalar", label: "Value"},
    fromMin: {paramType: "socket", id: "From Min", type: "input", valueType: "scalar", label: "From Min"},
    fromMax: {paramType: "socket", id: "From Max", type: "input", valueType: "scalar", label: "From Max"},
    toMin: {paramType: "socket", id: "To Min", type: "input", valueType: "scalar", label: "To Min"},
    toMax: {paramType: "socket", id: "To Max", type: "input", valueType: "scalar", label: "To Max"},
    steps: {paramType: "socket", id: "Steps", type: "input", valueType: "scalar", label: "Steps"},
}
