import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DownloadableFileFragment = { __typename: 'DataObject', downloadUrl: string, originalFileName: string };

export type DownloadableFileQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type DownloadableFileQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', downloadUrl: string, originalFileName: string } };

export const DownloadableFileFragmentDoc = gql`
    fragment DownloadableFile on DataObject {
  downloadUrl
  originalFileName
}
    `;
export const DownloadableFileDocument = gql`
    query downloadableFile($id: ID, $legacyId: Int) {
  dataObject(id: $id, legacyId: $legacyId) {
    ...DownloadableFile
  }
}
    ${DownloadableFileFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DownloadableFileGQL extends Apollo.Query<DownloadableFileQuery, DownloadableFileQueryVariables> {
    override document = DownloadableFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }