import {NgClass} from "@angular/common"
import {Component, input} from "@angular/core"

@Component({
    selector: "cm-action-panel-row",
    templateUrl: "./action-panel-row.component.html",
    styleUrls: ["./action-panel-row.component.scss"],
    imports: [NgClass],
})
export class ActionPanelRowComponent {
    readonly $cmIconClass = input<string>(undefined, {alias: "cmIconClass"})
}
