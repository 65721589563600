import {Component, viewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {BrightContrastInputs, BrightContrastOutputs} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-bright-contrast-node",
    templateUrl: "./bright-contrast-node.component.html",
    styleUrls: ["./bright-contrast-node.component.scss"],
    imports: [NodeBaseComponent],
})
export class BrightContrastNodeComponent {
    readonly $nodeBase = viewChild<MaterialNodeBase>("nodeBase")
    outputs = BrightContrastOutputs
    inputs = BrightContrastInputs
    typeInfo = BrightContrastNodeType
}

export const BrightContrastNodeType: MaterialNodeType<typeof BrightContrastNodeComponent> = {
    id: "brightcontrast",
    label: "Bright/Contrast",
    color: "#6e5eb2",
    name: "ShaderNodeBrightContrast",
    inputs: [BrightContrastInputs.color, BrightContrastInputs.bright, BrightContrastInputs.contrast],
    outputs: [BrightContrastOutputs.color],
    component: BrightContrastNodeComponent,
}
