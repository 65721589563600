import {CommonModule} from "@angular/common"
import {Component, computed, inject, model, ModelSignal, OnInit, Signal, TemplateRef, viewChild} from "@angular/core"
import {FormsModule, ReactiveFormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker"
import {MatDialog, MatDialogModule} from "@angular/material/dialog"
import {MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field"
import {MatIconModule} from "@angular/material/icon"
import {MatInput} from "@angular/material/input"
import {MatMenu, MatMenuItem, MatMenuModule} from "@angular/material/menu"
import {MatTooltip} from "@angular/material/tooltip"
import {RouterModule} from "@angular/router"
import {Settings} from "@app/common/models/settings/settings"
import {UploadGqlService} from "@app/common/services/upload/upload.gql.service"
import {ThumbnailsService} from "@app/platform/services/thumbnails/thumbnails.service"
import {TextureSetsComponent} from "@app/textures/texture-sets/texture-sets.component"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {CopyLegacyIdButtonComponent} from "@common/components/buttons/copy-legacy-id-button/copy-legacy-id-button.component"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {DropFilesComponent} from "@common/components/files"
import {HintComponent} from "@common/components/hint/hint.component"
import {BooleanLabelSelectComponent} from "@common/components/inputs/select/boolean-label-select/boolean-label-select.component"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"
import {TagSelectComponent} from "@common/components/inputs/select/tag-select/tag-select.component"
import {SectionComponent, SectionContentComponent} from "@common/components/item"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateReturningDataAndErrors, mutateThrowingErrors} from "@common/helpers/api/mutate"
import {DialogSize} from "@common/models/dialogs"
import {TimeAgoPipe} from "@common/pipes/time-ago/time-ago.pipe"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {ContentTypeModel, MutationUpdateMaterialInput} from "@generated"
import {ContentLoaderModule} from "@ngneat/content-loader"
import {BaseDetailsComponent} from "@platform/components/base/base-details/base-details.component"
import {ActivitySectionComponent} from "@platform/components/details/activity-section/activity-section.component"
import {ArticleIdSectionComponent} from "@platform/components/details/article-id-section/article-id-section.component"
import {CommentSectionComponent} from "@platform/components/details/comment-section/comment-section.component"
import {FilesSectionComponent} from "@platform/components/details/files-section/files-section.component"
import {GalleryImageComponent} from "@platform/components/details/gallery-image/gallery-image.component"
import {ProjectManagementTasksSectionComponent} from "@platform/components/details/project-management-tasks-section/project-management-tasks-section.component"
import {ShowAssetsButtonComponent} from "@platform/components/details/show-assets-button/show-assets-button.component"
import {SidebarDescriptionSectionComponent} from "@platform/components/details/sidebar-description-section/sidebar-description-section.component"
import {TemplatesSectionComponent} from "@platform/components/details/templates-section/templates-section.component"
import {TitleSectionComponent} from "@platform/components/details/title-section/title-section.component"
import {CornersLayoutComponent} from "@platform/components/layouts/corners-layout/corners-layout.component"
import {DetailsDialogLayoutComponent} from "@platform/components/layouts/details-dialog-layout/details-dialog-layout.component"
import {
    GetMaterialDetailsGQL,
    MaterialDetailsCreateIconAssignmentGQL,
    MaterialDetailsCreateTextureGroupGQL,
    MaterialDetailsCreateTextureSetGQL,
    MaterialDetailsDeleteGQL,
    MaterialDetailsDeleteIconAssignmentGQL,
    MaterialDetailsFragment,
    UpdateMaterialDetailsGQL,
} from "@platform/components/materials/material-details/material-details.generated"
import {MaterialOutputsComponent} from "@platform/components/materials/material-outputs/material-outputs.component"
import {MaterialRevisionsHistoryDialogComponent} from "@platform/components/materials/material-revisions-history-dialog/material-revisions-history-dialog.component"
import {PaymentStateLabelComponent} from "@platform/components/shared/payment-state-label/payment-state-label.component"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"

@Component({
    selector: "cm-material-details",
    templateUrl: "./material-details.component.html",
    styleUrls: ["./material-details.component.scss"],
    imports: [
        CommonModule,
        ContentLoaderModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        RouterModule,
        DetailsDialogLayoutComponent,
        ButtonComponent,
        DropFilesComponent,
        HintComponent,
        MatDatepicker,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatFormField,
        MatInput,
        MatLabel,
        MatMenu,
        MatMenuItem,
        MatSuffix,
        MatTooltip,
        ReactiveFormsModule,
        SectionComponent,
        TagSelectComponent,
        BooleanLabelSelectComponent,
        OrganizationSelectComponent,
        CornersLayoutComponent,
        StateLabelComponent,
        GalleryImageComponent,
        MatMenuModule,
        FormsModule,
        RoutedDialogComponent,
        SectionContentComponent,
        MaterialOutputsComponent,
        CopyLegacyIdButtonComponent,
        CommentSectionComponent,
        SidebarDescriptionSectionComponent,
        FilesSectionComponent,
        ActivitySectionComponent,
        ProjectManagementTasksSectionComponent,
        ArticleIdSectionComponent,
        TitleSectionComponent,
        MaterialRevisionsHistoryDialogComponent,
        ShowAssetsButtonComponent,
        TextureSetsComponent,
        PaymentStateLabelComponent,
        DataObjectThumbnailComponent,
        TimeAgoPipe,
        TemplatesSectionComponent,
    ],
})
export class MaterialDetailsComponent extends BaseDetailsComponent<MaterialDetailsFragment, Omit<MutationUpdateMaterialInput, "id">> implements OnInit {
    override _contentTypeModel = ContentTypeModel.Material
    override _fetchItem = fetchThrowingErrors(inject(GetMaterialDetailsGQL))
    override _updateItem = mutateThrowingErrors(inject(UpdateMaterialDetailsGQL))
    override _deleteItem = mutateReturningDataAndErrors(inject(MaterialDetailsDeleteGQL))
    readonly $materialRevisionHistoryDialog = viewChild<TemplateRef<MaterialRevisionsHistoryDialogComponent>>("materialRevisionHistoryDialog")

    override displayName = "material"

    get materialIconAssignments() {
        return this.$item()?.iconAssignments
    }

    readonly matDialog = inject(MatDialog)
    readonly organizations = inject(OrganizationsService)
    readonly upload = inject(UploadGqlService)
    readonly thumbnailsService = inject(ThumbnailsService)

    readonly createTextureGroupGql = inject(MaterialDetailsCreateTextureGroupGQL)
    readonly createTextureSetGql = inject(MaterialDetailsCreateTextureSetGQL)
    readonly createIconAssignmentGql = inject(MaterialDetailsCreateIconAssignmentGQL)
    readonly deleteIconAssignmentGql = inject(MaterialDetailsDeleteIconAssignmentGQL)

    nodeGraphMenu = false

    sampleArrivalMaxDate = new Date()

    $materialOutputsNeedConfirmationToClose: ModelSignal<boolean> = model<boolean>(false)
    $needsConfirmationToClose: Signal<boolean> = computed(() => this.$materialOutputsNeedConfirmationToClose())

    showRevisionHistoryDialog() {
        const materialRevisionHistoryDialog = this.$materialRevisionHistoryDialog()
        if (!materialRevisionHistoryDialog) {
            throw new Error("Missing materialRevisionHistoryDialog ref in template")
        }
        this.matDialog.open(materialRevisionHistoryDialog, {
            data: {material: {id: this.$item()?.id}},
            width: "800px",
        })
    }

    async createTextureGroup() {
        const material = this.$item()
        if (material) {
            await this.notifications.withUserFeedback(
                async () => {
                    const {createTextureGroup} = await mutateThrowingErrors(this.createTextureGroupGql)({
                        input: {
                            name: material.name,
                            organizationId: material.organization?.id,
                            materialId: material.id,
                            meshSpecific: false,
                        },
                    })
                    await mutateThrowingErrors(this.createTextureSetGql)({
                        input: {textureGroupId: createTextureGroup.id},
                    })
                    this.refresh.item(this.$item())
                },
                {
                    error: "Cannot create new texture group.",
                },
            )
        }
    }

    onNewMaterialClicked(textureGroup: {textureSets: {id: string}[]} | null | undefined) {
        if (!textureGroup || textureGroup.textureSets.length === 0)
            void this.router.navigate(["setup"], {
                relativeTo: this.route,
                queryParamsHandling: "preserve",
            })
        else {
            if (textureGroup.textureSets.length === 1) {
                void this.router.navigate(["setup", textureGroup.textureSets[0].id], {
                    relativeTo: this.route,
                    queryParamsHandling: "preserve",
                })
            } else if (textureGroup.textureSets.length > 1) {
                this.nodeGraphMenu = !this.nodeGraphMenu
            }
        }
    }

    async uploadIcon(files: File[]) {
        const item = this.$item()
        if (!item) {
            throw Error("Gql material not set")
        }
        if (files.length > 1) {
            throw Error("Only single file upload is supported.")
        }
        const organizationId = item.organization?.id
        if (!organizationId) {
            throw Error("Organization not set")
        }

        const dataObjectDetails = await this.upload.createAndUploadDataObject(files[0], {organizationId}, {showUploadToolbar: true, processUpload: true})
        await mutateThrowingErrors(this.createIconAssignmentGql)({
            objectId: item.id,
            dataObjectId: dataObjectDetails.id,
        })
        this.refresh.item(this.$item())
        await this.thumbnailsService.waitUntilAvailable(dataObjectDetails.id)
    }

    deleteIcon() {
        const assignmentId = this.materialIconAssignments?.[0]?.id
        if (assignmentId) {
            mutateThrowingErrors(this.deleteIconAssignmentGql)({
                dataObjectAssignmentId: assignmentId,
            })
            this.refresh.item(this.$item())
        }
    }

    protected readonly DialogSize = DialogSize
    protected readonly Settings = Settings
}
