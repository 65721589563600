import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CreateJobMutationVariables = Types.Exact<{
  input: Types.MutationCreateJobInput;
}>;


export type CreateJobMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string } };

export type GetJobTaskQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetJobTaskQuery = { __typename: 'Query', jobTask: { __typename: 'JobTask', id: string, state: Types.JobTaskState, output?: any | null } };

export type GetAllGlftAssignmentsForDataObjectQueryVariables = Types.Exact<{
  dataObjectId: Types.Scalars['ID']['input'];
}>;


export type GetAllGlftAssignmentsForDataObjectQuery = { __typename: 'Query', dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string } | null> };

export type GetGltfDataObjectAssignmentsQueryVariables = Types.Exact<{
  templateRevisionId: Types.Scalars['ID']['input'];
  assignmentKey: Types.Scalars['String']['input'];
}>;


export type GetGltfDataObjectAssignmentsQuery = { __typename: 'Query', dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } } | null> };

export type CreateDataObjectAssignmentMutationVariables = Types.Exact<{
  input: Types.MutationCreateDataObjectAssignmentInput;
}>;


export type CreateDataObjectAssignmentMutation = { __typename: 'Mutation', createDataObjectAssignment: { __typename: 'DataObjectAssignment', id: string } };

export type DeleteDataObjectAssignmentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteDataObjectAssignmentMutation = { __typename: 'Mutation', deleteDataObjectAssignment?: any | null };

export type DeleteDataObjectArHelperMutationVariables = Types.Exact<{
  dataObjectId: Types.Scalars['ID']['input'];
}>;


export type DeleteDataObjectArHelperMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export const CreateJobDocument = gql`
    mutation createJob($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateJobGQL extends Apollo.Mutation<CreateJobMutation, CreateJobMutationVariables> {
    override document = CreateJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetJobTaskDocument = gql`
    query getJobTask($id: ID!) {
  jobTask(id: $id) {
    id
    state
    output
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetJobTaskGQL extends Apollo.Query<GetJobTaskQuery, GetJobTaskQueryVariables> {
    override document = GetJobTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllGlftAssignmentsForDataObjectDocument = gql`
    query getAllGlftAssignmentsForDataObject($dataObjectId: ID!) {
  dataObjectAssignments(
    filter: {dataObjectId: $dataObjectId, assignmentType: CachedTemplateGltf}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllGlftAssignmentsForDataObjectGQL extends Apollo.Query<GetAllGlftAssignmentsForDataObjectQuery, GetAllGlftAssignmentsForDataObjectQueryVariables> {
    override document = GetAllGlftAssignmentsForDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetGltfDataObjectAssignmentsDocument = gql`
    query getGltfDataObjectAssignments($templateRevisionId: ID!, $assignmentKey: String!) {
  dataObjectAssignments(
    filter: {contentTypeModel: TemplateRevision, objectId: $templateRevisionId, assignmentKey: {equals: $assignmentKey}, assignmentType: CachedTemplateGltf}
  ) {
    id
    dataObject {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGltfDataObjectAssignmentsGQL extends Apollo.Query<GetGltfDataObjectAssignmentsQuery, GetGltfDataObjectAssignmentsQueryVariables> {
    override document = GetGltfDataObjectAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateDataObjectAssignmentDocument = gql`
    mutation createDataObjectAssignment($input: MutationCreateDataObjectAssignmentInput!) {
  createDataObjectAssignment(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateDataObjectAssignmentGQL extends Apollo.Mutation<CreateDataObjectAssignmentMutation, CreateDataObjectAssignmentMutationVariables> {
    override document = CreateDataObjectAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDataObjectAssignmentDocument = gql`
    mutation deleteDataObjectAssignment($id: ID!) {
  deleteDataObjectAssignment(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDataObjectAssignmentGQL extends Apollo.Mutation<DeleteDataObjectAssignmentMutation, DeleteDataObjectAssignmentMutationVariables> {
    override document = DeleteDataObjectAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDataObjectArHelperDocument = gql`
    mutation deleteDataObjectArHelper($dataObjectId: ID!) {
  deleteDataObject(id: $dataObjectId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDataObjectArHelperGQL extends Apollo.Mutation<DeleteDataObjectArHelperMutation, DeleteDataObjectArHelperMutationVariables> {
    override document = DeleteDataObjectArHelperDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }