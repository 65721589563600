import {Component, OnInit, input, model, output} from "@angular/core"
import {RouterModule} from "@angular/router"

@Component({
    selector: "cm-tabs",
    templateUrl: "./tabs.component.html",
    styleUrls: ["./tabs.component.scss"],
    imports: [RouterModule],
})
export class TabsComponent<TabType extends string> implements OnInit {
    readonly $activeTab = model<TabType | undefined>(undefined, {alias: "activeTab"})
    readonly $tabs = input<
        {
            title: string
            icon: string
            id: TabType
        }[]
    >([], {alias: "tabs"})
    readonly onChangeTab = output<TabType>()

    ngOnInit() {
        this.$activeTab.update((activeTab) => activeTab ?? this.$tabs()?.[0]?.id)
    }
}
