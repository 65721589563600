import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MaterialGraphRevisionFragment = { __typename: 'MaterialRevision', legacyId: number, material: { __typename: 'Material', name?: string | null, legacyId: number } };

export type MaterialGraphTextureSetRevisionFragment = { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> };

export type MaterialGraphNodeFragment = { __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null };

export type MaterialGraphConnectionFragment = { __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', id: string, legacyId: number }, destination: { __typename: 'MaterialNode', id: string, legacyId: number } };

export type MaterialGraphExtendedRevisionFragment = { __typename: 'MaterialRevision', legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', id: string, legacyId: number }, destination: { __typename: 'MaterialNode', id: string, legacyId: number } }>, material: { __typename: 'Material', name?: string | null, legacyId: number } };

export type ThumbnailFragment = { __typename: 'DataObject', thumbnail?: { __typename: 'DataObject', legacyId: number, imageColorSpace?: Types.ImageColorSpace | null, downloadUrl: string } | null };

export type MaterialRevisionForMaterialGraphQueryVariables = Types.Exact<{
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type MaterialRevisionForMaterialGraphQuery = { __typename: 'Query', materialRevision: { __typename: 'MaterialRevision', legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', id: string, legacyId: number }, destination: { __typename: 'MaterialNode', id: string, legacyId: number } }>, material: { __typename: 'Material', name?: string | null, legacyId: number } } };

export type MaterialRevisionsForMaterialGraphQueryVariables = Types.Exact<{
  legacyIds?: Types.InputMaybe<Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']>;
}>;


export type MaterialRevisionsForMaterialGraphQuery = { __typename: 'Query', materialRevisions: Array<{ __typename: 'MaterialRevision', legacyId: number, nodes: Array<{ __typename: 'MaterialNode', id: string, legacyId: number, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', legacyId: number } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', legacyId: number, width?: number | null, height?: number | null } }> } | null }>, connections: Array<{ __typename: 'MaterialConnection', sourceParameter: string, destinationParameter: string, source: { __typename: 'MaterialNode', id: string, legacyId: number }, destination: { __typename: 'MaterialNode', id: string, legacyId: number } }>, material: { __typename: 'Material', name?: string | null, legacyId: number } } | null> };

export type MaterialGraphDataObjectDetailsFragment = { __typename: 'DataObject', legacyId: number, imageColorSpace?: Types.ImageColorSpace | null, mediaType?: string | null, width?: number | null, height?: number | null, downloadUrl: string, related: Array<{ __typename: 'DataObject', legacyId: number, imageColorSpace?: Types.ImageColorSpace | null, width?: number | null, height?: number | null, mediaType?: string | null, downloadUrl: string }> };

export type MaterialGraphTextureRevisionFragment = { __typename: 'TextureRevision', id: string, legacyId: number, width: number, height: number, displacement?: number | null, dataObject: { __typename: 'DataObject', legacyId: number, imageColorSpace?: Types.ImageColorSpace | null, mediaType?: string | null, width?: number | null, height?: number | null, downloadUrl: string, related: Array<{ __typename: 'DataObject', legacyId: number, imageColorSpace?: Types.ImageColorSpace | null, width?: number | null, height?: number | null, mediaType?: string | null, downloadUrl: string }> } };

export type TextureRevisionForMaterialGraphQueryVariables = Types.Exact<{
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type TextureRevisionForMaterialGraphQuery = { __typename: 'Query', textureRevision: { __typename: 'TextureRevision', id: string, legacyId: number, width: number, height: number, displacement?: number | null, dataObject: { __typename: 'DataObject', legacyId: number, imageColorSpace?: Types.ImageColorSpace | null, mediaType?: string | null, width?: number | null, height?: number | null, downloadUrl: string, related: Array<{ __typename: 'DataObject', legacyId: number, imageColorSpace?: Types.ImageColorSpace | null, width?: number | null, height?: number | null, mediaType?: string | null, downloadUrl: string }> } } };

export type TextureRevisionsForMaterialGraphQueryVariables = Types.Exact<{
  legacyIds?: Types.InputMaybe<Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']>;
}>;


export type TextureRevisionsForMaterialGraphQuery = { __typename: 'Query', textureRevisions: Array<{ __typename: 'TextureRevision', id: string, legacyId: number, width: number, height: number, displacement?: number | null, dataObject: { __typename: 'DataObject', legacyId: number, imageColorSpace?: Types.ImageColorSpace | null, mediaType?: string | null, width?: number | null, height?: number | null, downloadUrl: string, related: Array<{ __typename: 'DataObject', legacyId: number, imageColorSpace?: Types.ImageColorSpace | null, width?: number | null, height?: number | null, mediaType?: string | null, downloadUrl: string }> } } | null> };

export type DataObjectForMaterialGraphQueryVariables = Types.Exact<{
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type DataObjectForMaterialGraphQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', legacyId: number, imageColorSpace?: Types.ImageColorSpace | null, mediaType?: string | null, width?: number | null, height?: number | null, downloadUrl: string, related: Array<{ __typename: 'DataObject', legacyId: number, imageColorSpace?: Types.ImageColorSpace | null, width?: number | null, height?: number | null, mediaType?: string | null, downloadUrl: string }> } };

export type DataObjectsForMaterialGraphQueryVariables = Types.Exact<{
  legacyIds?: Types.InputMaybe<Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']>;
}>;


export type DataObjectsForMaterialGraphQuery = { __typename: 'Query', dataObjects: Array<{ __typename: 'DataObject', legacyId: number, imageColorSpace?: Types.ImageColorSpace | null, mediaType?: string | null, width?: number | null, height?: number | null, downloadUrl: string, related: Array<{ __typename: 'DataObject', legacyId: number, imageColorSpace?: Types.ImageColorSpace | null, width?: number | null, height?: number | null, mediaType?: string | null, downloadUrl: string }> } | null> };

export type RelatedDataObjectForMaterialGraphQueryVariables = Types.Exact<{
  fileType: Types.DownloadFileType;
  resolution: Types.DownloadResolution;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type RelatedDataObjectForMaterialGraphQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', thumbnail?: { __typename: 'DataObject', legacyId: number, imageColorSpace?: Types.ImageColorSpace | null, downloadUrl: string } | null } };

export const MaterialGraphRevisionFragmentDoc = gql`
    fragment MaterialGraphRevision on MaterialRevision {
  legacyId
  material {
    name
    legacyId
  }
}
    `;
export const MaterialGraphTextureSetRevisionFragmentDoc = gql`
    fragment MaterialGraphTextureSetRevision on TextureSetRevision {
  id
  width
  height
  displacement
  mapAssignments {
    textureType
    dataObject {
      legacyId
      width
      height
    }
  }
}
    `;
export const MaterialGraphNodeFragmentDoc = gql`
    fragment MaterialGraphNode on MaterialNode {
  id
  legacyId
  name
  textureRevision {
    legacyId
  }
  textureSetRevision {
    ...MaterialGraphTextureSetRevision
  }
  parameters
}
    `;
export const MaterialGraphConnectionFragmentDoc = gql`
    fragment MaterialGraphConnection on MaterialConnection {
  source {
    id
    legacyId
  }
  sourceParameter
  destination {
    id
    legacyId
  }
  destinationParameter
}
    `;
export const MaterialGraphExtendedRevisionFragmentDoc = gql`
    fragment MaterialGraphExtendedRevision on MaterialRevision {
  ...MaterialGraphRevision
  nodes {
    ...MaterialGraphNode
  }
  connections {
    ...MaterialGraphConnection
  }
}
    `;
export const ThumbnailFragmentDoc = gql`
    fragment Thumbnail on DataObject {
  thumbnail(fileType: $fileType, resolution: $resolution) {
    legacyId
    imageColorSpace
    downloadUrl
  }
}
    `;
export const MaterialGraphDataObjectDetailsFragmentDoc = gql`
    fragment MaterialGraphDataObjectDetails on DataObject {
  legacyId
  imageColorSpace
  mediaType
  width
  height
  downloadUrl
  related {
    legacyId
    imageColorSpace
    width
    height
    mediaType
    downloadUrl
  }
}
    `;
export const MaterialGraphTextureRevisionFragmentDoc = gql`
    fragment MaterialGraphTextureRevision on TextureRevision {
  id
  legacyId
  width
  height
  displacement
  dataObject {
    ...MaterialGraphDataObjectDetails
  }
}
    `;
export const MaterialRevisionForMaterialGraphDocument = gql`
    query materialRevisionForMaterialGraph($legacyId: Int, $id: ID) {
  materialRevision(legacyId: $legacyId, id: $id) {
    ...MaterialGraphExtendedRevision
  }
}
    ${MaterialGraphExtendedRevisionFragmentDoc}
${MaterialGraphRevisionFragmentDoc}
${MaterialGraphNodeFragmentDoc}
${MaterialGraphTextureSetRevisionFragmentDoc}
${MaterialGraphConnectionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialRevisionForMaterialGraphGQL extends Apollo.Query<MaterialRevisionForMaterialGraphQuery, MaterialRevisionForMaterialGraphQueryVariables> {
    override document = MaterialRevisionForMaterialGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialRevisionsForMaterialGraphDocument = gql`
    query materialRevisionsForMaterialGraph($legacyIds: [Int!]) {
  materialRevisions(filter: {legacyId: {in: $legacyIds}}, take: 1000) {
    ...MaterialGraphExtendedRevision
  }
}
    ${MaterialGraphExtendedRevisionFragmentDoc}
${MaterialGraphRevisionFragmentDoc}
${MaterialGraphNodeFragmentDoc}
${MaterialGraphTextureSetRevisionFragmentDoc}
${MaterialGraphConnectionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialRevisionsForMaterialGraphGQL extends Apollo.Query<MaterialRevisionsForMaterialGraphQuery, MaterialRevisionsForMaterialGraphQueryVariables> {
    override document = MaterialRevisionsForMaterialGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureRevisionForMaterialGraphDocument = gql`
    query textureRevisionForMaterialGraph($legacyId: Int, $id: ID) {
  textureRevision(legacyId: $legacyId, id: $id) {
    ...MaterialGraphTextureRevision
  }
}
    ${MaterialGraphTextureRevisionFragmentDoc}
${MaterialGraphDataObjectDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureRevisionForMaterialGraphGQL extends Apollo.Query<TextureRevisionForMaterialGraphQuery, TextureRevisionForMaterialGraphQueryVariables> {
    override document = TextureRevisionForMaterialGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TextureRevisionsForMaterialGraphDocument = gql`
    query textureRevisionsForMaterialGraph($legacyIds: [Int!]) {
  textureRevisions(filter: {legacyId: {in: $legacyIds}}, take: 1000) {
    ...MaterialGraphTextureRevision
  }
}
    ${MaterialGraphTextureRevisionFragmentDoc}
${MaterialGraphDataObjectDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TextureRevisionsForMaterialGraphGQL extends Apollo.Query<TextureRevisionsForMaterialGraphQuery, TextureRevisionsForMaterialGraphQueryVariables> {
    override document = TextureRevisionsForMaterialGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DataObjectForMaterialGraphDocument = gql`
    query dataObjectForMaterialGraph($legacyId: Int, $id: ID) {
  dataObject(legacyId: $legacyId, id: $id) {
    ...MaterialGraphDataObjectDetails
  }
}
    ${MaterialGraphDataObjectDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DataObjectForMaterialGraphGQL extends Apollo.Query<DataObjectForMaterialGraphQuery, DataObjectForMaterialGraphQueryVariables> {
    override document = DataObjectForMaterialGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DataObjectsForMaterialGraphDocument = gql`
    query dataObjectsForMaterialGraph($legacyIds: [Int!]) {
  dataObjects(filter: {legacyId: {in: $legacyIds}}, take: 1000) {
    ...MaterialGraphDataObjectDetails
  }
}
    ${MaterialGraphDataObjectDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DataObjectsForMaterialGraphGQL extends Apollo.Query<DataObjectsForMaterialGraphQuery, DataObjectsForMaterialGraphQueryVariables> {
    override document = DataObjectsForMaterialGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RelatedDataObjectForMaterialGraphDocument = gql`
    query relatedDataObjectForMaterialGraph($fileType: DownloadFileType!, $resolution: DownloadResolution!, $legacyId: Int, $id: ID) {
  dataObject(legacyId: $legacyId, id: $id) {
    ...Thumbnail
  }
}
    ${ThumbnailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RelatedDataObjectForMaterialGraphGQL extends Apollo.Query<RelatedDataObjectForMaterialGraphQuery, RelatedDataObjectForMaterialGraphQueryVariables> {
    override document = RelatedDataObjectForMaterialGraphDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }