import {ConfiguratorParameter} from "#template-nodes/configurator-parameters"
import {IMaterialGraph} from "@cm/material-nodes/interfaces/material-data"
import {CurveObjectData, ObjectData, MeshObjectData} from "#template-nodes/interfaces/object-data"
import {TemplateParameterValue} from "#template-nodes/nodes/parameters"
import {AnyJSONValue, ColorValue, EvaluatedTemplateInput, ExternalId, TemplateData} from "#template-nodes/types"
import {ImageGenerator} from "@cm/material-nodes/interfaces/image-generator"

export type InterfaceId = string
export type VariantId = string

export type DescriptorProps<V, T extends object = {}> = {
    id: InterfaceId
    name: string
    isSetByTemplate?: boolean
    type: "input" | "output"
    value: V
    origin: TemplateParameterValue
} & T

export abstract class InterfaceDescriptor<V, T extends object = {}> {
    constructor(public props: DescriptorProps<V, T>) {}
    clone(id: string, isSetByTemplate?: boolean): this {
        throw new Error("Method not implemented.")
    }
    toConfiguratorParameter(): ConfiguratorParameter {
        throw new Error("Method not implemented.")
    }
}

export type VariantInfo = {
    id: VariantId
    name: string
    iconDataObjectId?: number
    iconColor?: ColorValue
    excludeFromPermutations?: boolean
}

export type ConfigInfoParameters = {
    variants: VariantInfo[]
    displayWithLabels: boolean
}

export class ConfigInfo extends InterfaceDescriptor<VariantInfo | null, ConfigInfoParameters> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new ConfigInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }

    override toConfiguratorParameter(): ConfiguratorParameter {
        return {
            id: this.props.id,
            type: "config",
            name: this.props.name,
            value: this.props.value?.id,
            values: this.props.variants.map((v) => ({id: v.id, name: v.name})),
        }
    }
}

export class ObjectInfo extends InterfaceDescriptor<ObjectData | null> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new ObjectInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }
}

export class MeshInfo extends InterfaceDescriptor<MeshObjectData | null> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new MeshInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }
}

export class CurveInfo extends InterfaceDescriptor<CurveObjectData | null> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new CurveInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }
}

export class MaterialInfo extends InterfaceDescriptor<IMaterialGraph | null> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new MaterialInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }

    override toConfiguratorParameter(): ConfiguratorParameter {
        return {
            id: this.props.id,
            type: "material",
            name: this.props.name,
            value: this.props.value?.materialId,
        }
    }
}

export class TemplateInfo extends InterfaceDescriptor<TemplateData | null> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new TemplateInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }
}

export class ImageInfo extends InterfaceDescriptor<ImageGenerator | null> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new ImageInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }
}

export class StringInfo extends InterfaceDescriptor<string | null> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new StringInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }
}

export class NumberInfo extends InterfaceDescriptor<number | null> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new NumberInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }
}

export class BooleanInfo extends InterfaceDescriptor<boolean | null> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new BooleanInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }
}

export class JSONInfo extends InterfaceDescriptor<AnyJSONValue | null> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new JSONInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }
}

export class NodesInfo extends InterfaceDescriptor<EvaluatedTemplateInput[] | null> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new NodesInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }
}

export function wrapInterfaceId(prefix: ExternalId, id: InterfaceId): InterfaceId {
    return `${prefix}/${id}`
}

export function unwrapInterfaceId(id: InterfaceId): [ExternalId | null, InterfaceId] {
    const idx = id.lastIndexOf("/")
    if (idx === -1) return [null, id]
    return [id.slice(0, idx), id.slice(idx + 1)]
}

export function getInterfaceIdPrefix(id: InterfaceId): [ExternalId | null, ExternalId] {
    const idx = id.indexOf("/")
    if (idx === -1) return [null, id]
    return [id.slice(0, idx), id.slice(idx + 1)]
}

export function isConfigInput(descriptor: InterfaceDescriptor<unknown, {}>): descriptor is ConfigInfo {
    return descriptor instanceof ConfigInfo && descriptor.props.type === "input"
}

export function isMaterialInput(descriptor: InterfaceDescriptor<unknown, {}>): descriptor is MaterialInfo {
    return descriptor instanceof MaterialInfo && descriptor.props.type === "input"
}
