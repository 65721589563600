import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {
    ChannelLayout,
    DataType,
    ImageDescriptor,
    ImageRef,
    isImageDescriptor,
} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {getImgProcDataType} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils-img-proc"
import {ImageProcessingNodes as Nodes} from "@cm/image-processing-nodes"
import {TypedImageData} from "@cm/utils/typed-image-data"
import {ColorLike} from "@cm/math"
import {assertNever} from "@cm/utils"

export type ParameterType = {
    imageOrDescriptor: ImageRef | ImageDescriptor
    fillColor: Partial<ColorLike> | undefined // ATTENTION: using undefined causes random data in the image - only use when all pixels are written afterwards !
    resultImageOrDataType?: ImageRef | DataType
}

export type ReturnType = ImageRef

const imageOpCreateImage: ImageOpType<ParameterType, ReturnType> = {
    name: "CreateImage",

    WebGL2: ({cmdQueue, parameters: {imageOrDescriptor, fillColor, resultImageOrDataType}}) => {
        const descriptor = isImageDescriptor(imageOrDescriptor) ? imageOrDescriptor : imageOrDescriptor.descriptor
        resultImageOrDataType = cmdQueue.prepareResultImage(resultImageOrDataType, descriptor)
        if (fillColor) {
            const painter = cmdQueue.createPainter(
                "compositor",
                "fill",
                `
                uniform vec4 u_fillColor;
    
                vec4 computeColor(ivec2 targetPixel) {
                    return u_fillColor;
                }
                `,
            )
            cmdQueue.paint(painter, {
                parameters: {
                    u_fillColor: {type: "float4", value: {x: fillColor?.r ?? 0, y: fillColor?.g ?? 0, z: fillColor?.b ?? 0, w: fillColor?.a ?? 1}},
                },
                resultImage: resultImageOrDataType,
            })
        }
        return resultImageOrDataType
    },

    ImgProc: ({cmdQueue, parameters: {imageOrDescriptor, fillColor, resultImageOrDataType}}) => {
        const getImgProcColorSpace = (_descriptor: ImageDescriptor): TypedImageData["colorSpace"] => {
            return "linear"
        }
        const getColorFromChannelLayout = (channelLayout: ChannelLayout, fillColor: ColorLike): number | Nodes.RGBColor | Nodes.RGBAColor => {
            switch (channelLayout) {
                case "R":
                    return fillColor.r
                case "RGB":
                    return [fillColor.r, fillColor.g, fillColor.b]
                case "RGBA":
                    return [fillColor.r, fillColor.g, fillColor.b, fillColor.a ?? 1]
                default:
                    assertNever(channelLayout)
            }
        }
        const descriptor = isImageDescriptor(imageOrDescriptor) ? imageOrDescriptor : imageOrDescriptor.descriptor
        const resultNode = cmdQueue.createImage(descriptor, {
            type: "createImage",
            width: descriptor.width,
            height: descriptor.height,
            dataType: getImgProcDataType(descriptor.dataType),
            colorSpace: getImgProcColorSpace(descriptor),
            color: getColorFromChannelLayout(descriptor.channelLayout, {
                r: fillColor?.r ?? 0,
                g: fillColor?.g ?? 0,
                b: fillColor?.b ?? 0,
                a: fillColor?.a ?? 1,
            }),
        })
        return cmdQueue.copyToResultImage(resultNode, resultImageOrDataType)
    },
}

export function createImage(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpCreateImage, parameters)
}
