import {Component, viewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {ShaderToRgbInputs, ShaderToRgbOutputs} from "@material-editor/models/nodes/shader-to-rgb"

@Component({
    selector: "cm-shader-to-rgb-node",
    templateUrl: "./shader-to-rgb.component.html",
    styleUrls: ["./shader-to-rgb.component.scss"],
    imports: [NodeBaseComponent],
})
export class ShaderToRgbNodeComponent {
    readonly $nodeBase = viewChild<MaterialNodeBase>("nodeBase")
    inputs = ShaderToRgbInputs
    outputs = ShaderToRgbOutputs
    typeInfo = ShaderToRgbNodeType
}

export const ShaderToRgbNodeType: MaterialNodeType<typeof ShaderToRgbNodeComponent> = {
    id: "shaderToRgb",
    label: "Shader to RGB",
    color: "#4987af",
    name: "ShaderNodeShaderToRGB",
    inputs: [ShaderToRgbInputs.shader] as never[],
    outputs: [ShaderToRgbOutputs.color, ShaderToRgbOutputs.alpha],
    component: ShaderToRgbNodeComponent,
}
