import {Component, computed, inject} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {ContentTypeModel, DownloadResolution, MutationUpdateHdriInput} from "@generated"
import {HdriDetailsFragment} from "@app/platform/components/hdris/hdri-details/hdri-details.generated"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {AttachmentsComponent} from "@common/components/files/attachments/attachments.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateReturningDataAndErrors, mutateThrowingErrors} from "@common/helpers/api/mutate"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {HdriService} from "@app/platform/components/hdris/hdri-service/hdri.service"
import {BaseDetailsComponent} from "@platform/components/base/base-details/base-details.component"
import {DeleteHdriGQL, HdriDetailsGQL, UpdateHdriDetailsGQL} from "@platform/components/hdris/hdri-details/hdri-details.generated"
import {DetailsDialogLayoutComponent} from "@platform/components/layouts/details-dialog-layout/details-dialog-layout.component"
import {SectionComponent} from "@common/components/item/item-details/section/section.component"
import {IsLoadingDirective} from "@common/directives"
import {DropFilesComponent} from "@app/common/components/files"
import {MatMenu, MatMenuItem, MatMenuModule} from "@angular/material/menu"
import {TitleSectionComponent} from "@platform/components/details/title-section/title-section.component"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {DialogSize} from "@app/common/models/dialogs"
import {CopyValueToClipboardMenuComponent} from "@platform/components/shared/copy-value-to-clipboard-menu/copy-value-to-clipboard-menu.component"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"

@Component({
    selector: "cm-hdri-details",
    templateUrl: "hdri-details.component.html",
    styleUrls: ["hdri-details.component.scss"],
    imports: [
        DetailsDialogLayoutComponent,
        SectionComponent,
        AttachmentsComponent,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        IsLoadingDirective,
        DropFilesComponent,
        RoutedDialogComponent,
        MatMenu,
        MatMenuItem,
        MatMenuModule,
        TitleSectionComponent,
        DataObjectThumbnailComponent,
        CopyValueToClipboardMenuComponent,
        OrganizationSelectComponent,
    ],
})
export class HdriDetailsComponent extends BaseDetailsComponent<HdriDetailsFragment, Omit<MutationUpdateHdriInput, "id">> {
    override _contentTypeModel = ContentTypeModel.Hdri
    override _fetchItem = fetchThrowingErrors(inject(HdriDetailsGQL))
    override _updateItem = mutateThrowingErrors(inject(UpdateHdriDetailsGQL))
    override _deleteItem = mutateReturningDataAndErrors(inject(DeleteHdriGQL))

    readonly organizations = inject(OrganizationsService)
    readonly hdriService = inject(HdriService)

    readonly deleteHdri = inject(DeleteHdriGQL)

    readonly $canAddHdri = computed(() => {
        const item = this.$item()
        const organizationId = item?.organization?.id
        return this.$can().create.dataObject(organizationId ? {organization: {id: organizationId}} : null) && !!item && this.$can().update.item(item)
    })

    protected DownloadResolution = DownloadResolution

    performDelete = async () => {
        const item = this.$item()
        if (item) {
            await mutateThrowingErrors(this.deleteHdri)(item)
            await this.router.navigate(["/hdris"])
            this.refresh.item(item)
        }
    }

    async uploadHdri(files: File[]) {
        if (files.length !== 1) throw new Error("Upload of multiple files not supported")
        const item = this.$item()
        if (!item) throw new Error("Hdri details not set")
        await this.hdriService.uploadHdriFromFile(files[0], item)
        this.refresh.item(item)
    }

    deleteConfirmationMessage() {
        const item = this.$item()
        return (
            (item ? `The HDRI '${item.name}'` : "This HDRI") +
            " will be deleted. This action <strong>cannot be undone</strong>.<br><br>Are you sure you want to continue?"
        )
    }

    readonly $copyItems = computed(() => {
        const result: {value: string | undefined | (() => string | undefined); displayText: string; icon: string}[] = [
            {value: this.$item()?.legacyId?.toString(), displayText: "Legacy ID", icon: "fa-person-cane"},
            {value: this.$item()?.id, displayText: "ID", icon: "fa-id-badge"},
        ]
        return result
    })

    protected readonly DialogSize = DialogSize
}
