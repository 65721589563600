@if ($asLabel()) {
    <div
        [attr.part]="$selectedVariant()?.id === $variant()?.id ? 'cm-config-label-selected' : 'cm-config-label'"
        class="cm-config-label"
        [ngClass]="$selectedVariant()?.id === $variant()?.id ? 'cm-selected' : 'cm-regular'"
    >
        {{ $variant()?.name }}
    </div>
} @else {
    <div part="cm-icon-with-caption" class="cm-icon-with-caption">
        <div
            part="cm-icon-container"
            class="cm-icon cm-icon-shadow"
            [tp]="$variant()?.name ?? $notAvailableTooltip()"
            [tpAppendTo]="$any($tooltipContainer())"
            [style.background]="$variant()?.iconDataObjectId ? 'none' : getConfigVariantColor()"
            draggable="true"
            (dragstart)="onDragStart($event, $variant(), $config())"
        >
            @if (fetchThumbnailForDataObject($variant()?.iconDataObjectId) | async; as thumbnailUrl) {
                <img alt="Variant icon" [src]="thumbnailUrl" />
            }

            @if ($loading()) {
                <div class="cm-mini-spinner-container">
                    <cm-loading-spinner-icon class="cm-mini-spinner"></cm-loading-spinner-icon>
                </div>
            } @else {
                <i class="far fa-check" [class.cm-active-variant]="$selectedVariant()?.id === $variant()?.id"></i>
            }
        </div>
        @if ($useCaptions()) {
            <div class="cm-caption">{{ $variant()?.name }}</div>
        }
    </div>
}
