import {EventEmitter} from "@angular/core"
import {TextureType} from "@generated"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {rotateAngleMap} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/rotate-angle-map"
import {rotateVectorMap} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/rotate-vector-map"
import {affineTransform} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-affine-transform"
import {Matrix3x2} from "@cm/math"
import {deepCopy} from "@cm/utils"
import {
    OperatorCanvasToolboxType,
    OperatorFlags,
    OperatorInput,
    OperatorOutput,
    OperatorPanelComponentType,
    OperatorProcessingHints,
} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-base"
import {OperatorCallback} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {RotatePanelComponent} from "app/textures/texture-editor/operator-stack/operators/rotate/panel/rotate-panel.component"
import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"

export class OperatorRotate extends OperatorBase<TextureEditNodes.OperatorRotate> {
    // OperatorBase
    override readonly flags = new Set<OperatorFlags>(["apply-to-all-texture-types"]) // rotation is not preserving texture size, so it must be applied to all texture types

    readonly angleInDegreesChanged = new EventEmitter<number>()

    readonly panelComponentType: OperatorPanelComponentType = RotatePanelComponent
    readonly canvasToolbox: OperatorCanvasToolboxType = null

    readonly type = "operator-rotate" as const

    constructor(callback: OperatorCallback, node: TextureEditNodes.OperatorRotate | null) {
        super(
            callback,
            deepCopy(node) ?? {
                type: "operator-rotate",
                enabled: true,
                angleInDegrees: 0,
            },
        )
    }

    // OperatorBase
    override dispose(): void {
        super.dispose()
    }

    // OperatorBase
    async clone() {
        const clonedOperator = new OperatorRotate(this.callback, deepCopy(this.node))
        await clonedOperator.init()
        return clonedOperator
    }

    // OperatorBase
    async queueImageOps(cmdQueue: ImageOpCommandQueue, input: OperatorInput, hints: OperatorProcessingHints): Promise<OperatorOutput> {
        if (this.angleInDegrees === 0) {
            // don't do anything if angle is 0
            return {resultImage: input}
        } else {
            cmdQueue.beginScope(this.type)
            let resultImage = affineTransform(cmdQueue, {
                sourceImage: input,
                transform: new Matrix3x2().rotate(this.angleInDegrees),
            })
            if (hints.textureType === TextureType.Normal) {
                // normal maps need to be rotated as well to keep consistent
                resultImage = rotateVectorMap(cmdQueue, {sourceImage: resultImage, angleInDegrees: this.angleInDegrees, angleFactor: -1}) // we need to rotate the opposite direction
            } else if (hints.textureType === TextureType.Anisotropy) {
                // anisotropy maps need to be rotated as well to keep consistent
                resultImage = rotateVectorMap(cmdQueue, {sourceImage: resultImage, angleInDegrees: this.angleInDegrees, angleFactor: -2}) // we need to rotate by twice the angle because anisotropy is periodic in 180°
            } else if (hints.textureType === TextureType.AnisotropyRotation) {
                // anisotropy rotation map need to be rotated as well to keep consistent
                resultImage = rotateAngleMap(cmdQueue, {sourceImage: resultImage, angleInDegrees: this.angleInDegrees, angleFactor: -1}) // anisotropy is periodic in 180° but the map stores only 0..0.5 range
            }
            cmdQueue.endScope(this.type)
            return {resultImage}
        }
    }

    get angleInDegrees(): number {
        return this.node.angleInDegrees
    }

    set angleInDegrees(value: number) {
        if (this.node.angleInDegrees === value) {
            return
        }
        this.node.angleInDegrees = value
        this.markEdited()
        this.requestEval()
        this.angleInDegreesChanged.emit(value)
    }
}
