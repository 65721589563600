import * as Types from '../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetMaterialsDetailsForSceneManagerServiceQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.MaterialFilterInput>;
  orderBy?: Types.InputMaybe<Array<Types.MaterialOrderByInput> | Types.MaterialOrderByInput>;
}>;


export type GetMaterialsDetailsForSceneManagerServiceQuery = { __typename: 'Query', materials: Array<{ __typename: 'Material', id: string, legacyId: number, articleId?: string | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null } | null> };

export type GetHdriDetailsForSceneManagerServiceQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetHdriDetailsForSceneManagerServiceQuery = { __typename: 'Query', hdri: { __typename: 'Hdri', dataObject?: { __typename: 'DataObject', mediaType?: string | null, bucketName: string, objectName: string, originalFileName: string } | null } };

export type DataObjectAssignmentDetailsForTemplateImageViewerFragment = { __typename: 'DataObjectAssignment', id: string };

export type CreateDataObjectAssignmentForSceneManagerServiceMutationVariables = Types.Exact<{
  input: Types.MutationCreateDataObjectAssignmentInput;
}>;


export type CreateDataObjectAssignmentForSceneManagerServiceMutation = { __typename: 'Mutation', createDataObjectAssignment: { __typename: 'DataObjectAssignment', id: string } };

export const DataObjectAssignmentDetailsForTemplateImageViewerFragmentDoc = gql`
    fragment DataObjectAssignmentDetailsForTemplateImageViewer on DataObjectAssignment {
  id
}
    `;
export const GetMaterialsDetailsForSceneManagerServiceDocument = gql`
    query getMaterialsDetailsForSceneManagerService($skip: Int, $take: Int, $filter: MaterialFilterInput, $orderBy: [MaterialOrderByInput!]) {
  materials(skip: $skip, take: $take, filter: $filter, orderBy: $orderBy) {
    __typename
    id
    legacyId
    articleId
    latestCyclesRevision {
      id
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialsDetailsForSceneManagerServiceGQL extends Apollo.Query<GetMaterialsDetailsForSceneManagerServiceQuery, GetMaterialsDetailsForSceneManagerServiceQueryVariables> {
    override document = GetMaterialsDetailsForSceneManagerServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHdriDetailsForSceneManagerServiceDocument = gql`
    query getHDRIDetailsForSceneManagerService($id: ID, $legacyId: Int) {
  hdri(id: $id, legacyId: $legacyId) {
    dataObject {
      mediaType
      bucketName
      objectName
      originalFileName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHdriDetailsForSceneManagerServiceGQL extends Apollo.Query<GetHdriDetailsForSceneManagerServiceQuery, GetHdriDetailsForSceneManagerServiceQueryVariables> {
    override document = GetHdriDetailsForSceneManagerServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateDataObjectAssignmentForSceneManagerServiceDocument = gql`
    mutation createDataObjectAssignmentForSceneManagerService($input: MutationCreateDataObjectAssignmentInput!) {
  createDataObjectAssignment(input: $input) {
    ...DataObjectAssignmentDetailsForTemplateImageViewer
  }
}
    ${DataObjectAssignmentDetailsForTemplateImageViewerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateDataObjectAssignmentForSceneManagerServiceGQL extends Apollo.Mutation<CreateDataObjectAssignmentForSceneManagerServiceMutation, CreateDataObjectAssignmentForSceneManagerServiceMutationVariables> {
    override document = CreateDataObjectAssignmentForSceneManagerServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }