import * as Types from '../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type QueryDataObjectLegacyIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type QueryDataObjectLegacyIdQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', legacyId: number } };

export type DeleteTextureSetRevisionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteTextureSetRevisionMutation = { __typename: 'Mutation', deleteTextureSetRevision: { __typename: 'TextureSetRevision', id: string } };

export type QueryTextureSetRevisionTextureRevisionIdsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type QueryTextureSetRevisionTextureRevisionIdsQuery = { __typename: 'Query', textureSetRevision: { __typename: 'TextureSetRevision', textureSet: { __typename: 'TextureSet', id: string, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string }>, textures: Array<{ __typename: 'Texture', id: string, revisions: Array<{ __typename: 'TextureRevision', id: string }> }> } } };

export type QueryDataObjectAssignmentForDeletionQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type QueryDataObjectAssignmentForDeletionQuery = { __typename: 'Query', dataObjectAssignment: { __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, assignmentsCount: number } } };

export type DeleteDataObjectAssignmentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteDataObjectAssignmentMutation = { __typename: 'Mutation', deleteDataObjectAssignment?: any | null };

export type DeleteDataObjectMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteDataObjectMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export type DeleteTextureRevisionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteTextureRevisionMutation = { __typename: 'Mutation', deleteTextureRevision?: any | null };

export type DeleteTextureSetMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteTextureSetMutation = { __typename: 'Mutation', deleteTextureSet?: any | null };

export type DeleteTextureMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteTextureMutation = { __typename: 'Mutation', deleteTexture?: any | null };

export type DeleteScanJobMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteScanJobMutation = { __typename: 'Mutation', deleteScanJob?: any | null };

export type DeleteScanSubJobMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteScanSubJobMutation = { __typename: 'Mutation', deleteScanSubJob?: any | null };

export type QueryTextureSetDeletionDataQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type QueryTextureSetDeletionDataQuery = { __typename: 'Query', textureSet: { __typename: 'TextureSet', id: string, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string }>, scanJob?: { __typename: 'ScanJob', id: string, subJobs: Array<{ __typename: 'ScanSubJob', id: string }> } | null, textures: Array<{ __typename: 'Texture', id: string, revisions: Array<{ __typename: 'TextureRevision', id: string }> }>, textureGroup: { __typename: 'TextureGroup', id: string } } };

export type QueryDataObjectReferencesAndRelatedQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type QueryDataObjectReferencesAndRelatedQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', assignmentsCount: number, hdrisCount: number, materialRevisionsCount: number, textureSetRevisionMapAssignmentsCount: number, textureSetRevisionSourceDataObjectReferencesCount: number, textureRevisionsCount: number, related: Array<{ __typename: 'DataObject', id: string }> } };

export type ResolveTextureRevisionLegacyIdQueryVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type ResolveTextureRevisionLegacyIdQuery = { __typename: 'Query', textureRevision: { __typename: 'TextureRevision', id: string } };

export type TextureSetDataFragment = { __typename: 'TextureSet', id: string, legacyId: number, textureGroup: { __typename: 'TextureGroup', organization: { __typename: 'Organization', id: string, legacyId: number } }, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string, name?: string | null, createdAt: string }> };

export type TextureSetRevisionDescriptorFragment = { __typename: 'TextureSetRevision', id: string, name?: string | null, createdAt: string };

export type TextureSetRevisionDataFragment = { __typename: 'TextureSetRevision', id: string, createdAt: string, updatedAt?: string | null, name?: string | null, width: number, height: number, displacement?: number | null, editsJson?: any | null, createdBy?: { __typename: 'User', id: string, name: string } | null, updatedBy?: { __typename: 'User', id: string, name: string } | null, editsProcessingJob?: { __typename: 'Job', id: string, state: Types.JobState, progress?: number | null } | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', id: string, width?: number | null, height?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }>, textureSet: { __typename: 'TextureSet', id: string, legacyId: number, textureGroup: { __typename: 'TextureGroup', organization: { __typename: 'Organization', id: string, legacyId: number } }, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string, name?: string | null, createdAt: string }> }, materialNodes: Array<{ __typename: 'MaterialNode', materialRevision: { __typename: 'MaterialRevision', id: string } }> };

export type QueryTextureSetRevisionDataQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type QueryTextureSetRevisionDataQuery = { __typename: 'Query', textureSetRevision: { __typename: 'TextureSetRevision', id: string, createdAt: string, updatedAt?: string | null, name?: string | null, width: number, height: number, displacement?: number | null, editsJson?: any | null, createdBy?: { __typename: 'User', id: string, name: string } | null, updatedBy?: { __typename: 'User', id: string, name: string } | null, editsProcessingJob?: { __typename: 'Job', id: string, state: Types.JobState, progress?: number | null } | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', id: string, width?: number | null, height?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }>, textureSet: { __typename: 'TextureSet', id: string, legacyId: number, textureGroup: { __typename: 'TextureGroup', organization: { __typename: 'Organization', id: string, legacyId: number } }, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string, name?: string | null, createdAt: string }> }, materialNodes: Array<{ __typename: 'MaterialNode', materialRevision: { __typename: 'MaterialRevision', id: string } }> } };

export type TextureEditorDataObjectFragment = { __typename: 'DataObject', id: string, legacyId: number, width?: number | null, height?: number | null, size?: any | null, originalFileName: string, mediaType?: string | null, imageDataType?: Types.ImageDataType | null, imageColorSpace?: Types.ImageColorSpace | null, originalDownloadUrl: string, jpgDataObject?: { __typename: 'DataObject', downloadUrl: string } | null, tiffDataObject?: { __typename: 'DataObject', downloadUrl: string } | null };

export type QueryTextureEditorDataObjectQueryVariables = Types.Exact<{
  dataObjectId: Types.Scalars['ID']['input'];
}>;


export type QueryTextureEditorDataObjectQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, legacyId: number, width?: number | null, height?: number | null, size?: any | null, originalFileName: string, mediaType?: string | null, imageDataType?: Types.ImageDataType | null, imageColorSpace?: Types.ImageColorSpace | null, originalDownloadUrl: string, jpgDataObject?: { __typename: 'DataObject', downloadUrl: string } | null, tiffDataObject?: { __typename: 'DataObject', downloadUrl: string } | null } };

export type UpdateDataObjectImageDimensionsMutationVariables = Types.Exact<{
  dataObjectId: Types.Scalars['ID']['input'];
  width: Types.Scalars['Int']['input'];
  height: Types.Scalars['Int']['input'];
}>;


export type UpdateDataObjectImageDimensionsMutation = { __typename: 'Mutation', updateDataObject: { __typename: 'DataObject', id: string } };

export type TexturesApiDeleteDataObjectMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type TexturesApiDeleteDataObjectMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export type QueryTextureEditDataObjectLegacyIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type QueryTextureEditDataObjectLegacyIdQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', legacyId: number } };

export type QueryTextureLoaderDataObjectImageDescriptorQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type QueryTextureLoaderDataObjectImageDescriptorQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, legacyId: number, width?: number | null, height?: number | null, imageDataType?: Types.ImageDataType | null, imageColorSpace?: Types.ImageColorSpace | null, downloadUrlExr: string, jpgDataObject?: { __typename: 'DataObject', downloadUrl: string } | null } };

export type CreateMaterialAiJobMutationVariables = Types.Exact<{
  input: Types.MutationCreateJobInput;
}>;


export type CreateMaterialAiJobMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string, legacyId: number } };

export type CreateMaterialAiJobAssignmentMutationVariables = Types.Exact<{
  input: Types.MutationCreateJobAssignmentInput;
}>;


export type CreateMaterialAiJobAssignmentMutation = { __typename: 'Mutation', createJobAssignment: { __typename: 'JobAssignment', id: string } };

export type CancelMaterialAiJobMutationVariables = Types.Exact<{
  jobId: Types.Scalars['ID']['input'];
}>;


export type CancelMaterialAiJobMutation = { __typename: 'Mutation', cancelJob: { __typename: 'Job', id: string } };

export const TextureSetRevisionDescriptorFragmentDoc = gql`
    fragment TextureSetRevisionDescriptor on TextureSetRevision {
  id
  name
  createdAt
}
    `;
export const TextureSetDataFragmentDoc = gql`
    fragment TextureSetData on TextureSet {
  id
  legacyId
  textureGroup {
    organization {
      id
      legacyId
    }
  }
  textureSetRevisions {
    ...TextureSetRevisionDescriptor
  }
}
    `;
export const TextureSetRevisionDataFragmentDoc = gql`
    fragment TextureSetRevisionData on TextureSetRevision {
  id
  createdAt
  createdBy {
    id
    name
  }
  updatedAt
  updatedBy {
    id
    name
  }
  name
  width
  height
  displacement
  editsJson
  editsProcessingJob {
    id
    state
    progress
  }
  mapAssignments {
    textureType
    dataObject {
      id
      thumbnail: thumbnail(fileType: jpg, resolution: Tiny) {
        downloadUrl
      }
      width
      height
    }
  }
  textureSet {
    ...TextureSetData
  }
  materialNodes {
    materialRevision {
      id
    }
  }
}
    `;
export const TextureEditorDataObjectFragmentDoc = gql`
    fragment TextureEditorDataObject on DataObject {
  id
  legacyId
  width
  height
  size
  originalFileName
  mediaType
  imageDataType
  imageColorSpace
  originalDownloadUrl: downloadUrl
  jpgDataObject: thumbnail(fileType: jpg, resolution: High) {
    downloadUrl
  }
  tiffDataObject: thumbnail(fileType: tiff, resolution: High) {
    downloadUrl
  }
}
    `;
export const QueryDataObjectLegacyIdDocument = gql`
    query queryDataObjectLegacyId($id: ID!) {
  dataObject(id: $id) {
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryDataObjectLegacyIdGQL extends Apollo.Query<QueryDataObjectLegacyIdQuery, QueryDataObjectLegacyIdQueryVariables> {
    override document = QueryDataObjectLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTextureSetRevisionDocument = gql`
    mutation deleteTextureSetRevision($id: ID!) {
  deleteTextureSetRevision(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTextureSetRevisionGQL extends Apollo.Mutation<DeleteTextureSetRevisionMutation, DeleteTextureSetRevisionMutationVariables> {
    override document = DeleteTextureSetRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryTextureSetRevisionTextureRevisionIdsDocument = gql`
    query queryTextureSetRevisionTextureRevisionIds($id: ID!) {
  textureSetRevision(id: $id) {
    textureSet {
      id
      textureSetRevisions {
        id
      }
      textures {
        id
        revisions {
          id
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureSetRevisionTextureRevisionIdsGQL extends Apollo.Query<QueryTextureSetRevisionTextureRevisionIdsQuery, QueryTextureSetRevisionTextureRevisionIdsQueryVariables> {
    override document = QueryTextureSetRevisionTextureRevisionIdsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryDataObjectAssignmentForDeletionDocument = gql`
    query queryDataObjectAssignmentForDeletion($id: ID!) {
  dataObjectAssignment(id: $id) {
    id
    dataObject {
      id
      assignmentsCount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryDataObjectAssignmentForDeletionGQL extends Apollo.Query<QueryDataObjectAssignmentForDeletionQuery, QueryDataObjectAssignmentForDeletionQueryVariables> {
    override document = QueryDataObjectAssignmentForDeletionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDataObjectAssignmentDocument = gql`
    mutation deleteDataObjectAssignment($id: ID!) {
  deleteDataObjectAssignment(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDataObjectAssignmentGQL extends Apollo.Mutation<DeleteDataObjectAssignmentMutation, DeleteDataObjectAssignmentMutationVariables> {
    override document = DeleteDataObjectAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDataObjectDocument = gql`
    mutation deleteDataObject($id: ID!) {
  deleteDataObject(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDataObjectGQL extends Apollo.Mutation<DeleteDataObjectMutation, DeleteDataObjectMutationVariables> {
    override document = DeleteDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTextureRevisionDocument = gql`
    mutation deleteTextureRevision($id: ID!) {
  deleteTextureRevision(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTextureRevisionGQL extends Apollo.Mutation<DeleteTextureRevisionMutation, DeleteTextureRevisionMutationVariables> {
    override document = DeleteTextureRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTextureSetDocument = gql`
    mutation deleteTextureSet($id: ID!) {
  deleteTextureSet(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTextureSetGQL extends Apollo.Mutation<DeleteTextureSetMutation, DeleteTextureSetMutationVariables> {
    override document = DeleteTextureSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTextureDocument = gql`
    mutation deleteTexture($id: ID!) {
  deleteTexture(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTextureGQL extends Apollo.Mutation<DeleteTextureMutation, DeleteTextureMutationVariables> {
    override document = DeleteTextureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteScanJobDocument = gql`
    mutation deleteScanJob($id: ID!) {
  deleteScanJob(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteScanJobGQL extends Apollo.Mutation<DeleteScanJobMutation, DeleteScanJobMutationVariables> {
    override document = DeleteScanJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteScanSubJobDocument = gql`
    mutation deleteScanSubJob($id: ID!) {
  deleteScanSubJob(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteScanSubJobGQL extends Apollo.Mutation<DeleteScanSubJobMutation, DeleteScanSubJobMutationVariables> {
    override document = DeleteScanSubJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryTextureSetDeletionDataDocument = gql`
    query queryTextureSetDeletionData($id: ID!) {
  textureSet(id: $id) {
    id
    textureSetRevisions {
      id
    }
    scanJob {
      id
      subJobs {
        id
      }
    }
    textures {
      id
      revisions {
        id
      }
    }
    textureGroup {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureSetDeletionDataGQL extends Apollo.Query<QueryTextureSetDeletionDataQuery, QueryTextureSetDeletionDataQueryVariables> {
    override document = QueryTextureSetDeletionDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryDataObjectReferencesAndRelatedDocument = gql`
    query queryDataObjectReferencesAndRelated($id: ID, $legacyId: Int) {
  dataObject(id: $id, legacyId: $legacyId) {
    assignmentsCount
    hdrisCount
    materialRevisionsCount
    textureSetRevisionMapAssignmentsCount
    textureSetRevisionSourceDataObjectReferencesCount
    textureRevisionsCount
    related {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryDataObjectReferencesAndRelatedGQL extends Apollo.Query<QueryDataObjectReferencesAndRelatedQuery, QueryDataObjectReferencesAndRelatedQueryVariables> {
    override document = QueryDataObjectReferencesAndRelatedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResolveTextureRevisionLegacyIdDocument = gql`
    query resolveTextureRevisionLegacyId($legacyId: Int!) {
  textureRevision(legacyId: $legacyId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResolveTextureRevisionLegacyIdGQL extends Apollo.Query<ResolveTextureRevisionLegacyIdQuery, ResolveTextureRevisionLegacyIdQueryVariables> {
    override document = ResolveTextureRevisionLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryTextureSetRevisionDataDocument = gql`
    query queryTextureSetRevisionData($id: ID!) {
  textureSetRevision(id: $id) {
    ...TextureSetRevisionData
  }
}
    ${TextureSetRevisionDataFragmentDoc}
${TextureSetDataFragmentDoc}
${TextureSetRevisionDescriptorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureSetRevisionDataGQL extends Apollo.Query<QueryTextureSetRevisionDataQuery, QueryTextureSetRevisionDataQueryVariables> {
    override document = QueryTextureSetRevisionDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryTextureEditorDataObjectDocument = gql`
    query queryTextureEditorDataObject($dataObjectId: ID!) {
  dataObject(id: $dataObjectId) {
    ...TextureEditorDataObject
  }
}
    ${TextureEditorDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureEditorDataObjectGQL extends Apollo.Query<QueryTextureEditorDataObjectQuery, QueryTextureEditorDataObjectQueryVariables> {
    override document = QueryTextureEditorDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDataObjectImageDimensionsDocument = gql`
    mutation updateDataObjectImageDimensions($dataObjectId: ID!, $width: Int!, $height: Int!) {
  updateDataObject(input: {id: $dataObjectId, width: $width, height: $height}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDataObjectImageDimensionsGQL extends Apollo.Mutation<UpdateDataObjectImageDimensionsMutation, UpdateDataObjectImageDimensionsMutationVariables> {
    override document = UpdateDataObjectImageDimensionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TexturesApiDeleteDataObjectDocument = gql`
    mutation texturesApiDeleteDataObject($id: ID!) {
  deleteDataObject(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TexturesApiDeleteDataObjectGQL extends Apollo.Mutation<TexturesApiDeleteDataObjectMutation, TexturesApiDeleteDataObjectMutationVariables> {
    override document = TexturesApiDeleteDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryTextureEditDataObjectLegacyIdDocument = gql`
    query queryTextureEditDataObjectLegacyId($id: ID!) {
  dataObject(id: $id) {
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureEditDataObjectLegacyIdGQL extends Apollo.Query<QueryTextureEditDataObjectLegacyIdQuery, QueryTextureEditDataObjectLegacyIdQueryVariables> {
    override document = QueryTextureEditDataObjectLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryTextureLoaderDataObjectImageDescriptorDocument = gql`
    query queryTextureLoaderDataObjectImageDescriptor($id: ID!) {
  dataObject(id: $id) {
    id
    legacyId
    width
    height
    imageDataType
    imageColorSpace
    downloadUrlExr: downloadUrl
    jpgDataObject: thumbnail(fileType: jpg, resolution: High) {
      downloadUrl
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureLoaderDataObjectImageDescriptorGQL extends Apollo.Query<QueryTextureLoaderDataObjectImageDescriptorQuery, QueryTextureLoaderDataObjectImageDescriptorQueryVariables> {
    override document = QueryTextureLoaderDataObjectImageDescriptorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMaterialAiJobDocument = gql`
    mutation createMaterialAiJob($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMaterialAiJobGQL extends Apollo.Mutation<CreateMaterialAiJobMutation, CreateMaterialAiJobMutationVariables> {
    override document = CreateMaterialAiJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMaterialAiJobAssignmentDocument = gql`
    mutation createMaterialAiJobAssignment($input: MutationCreateJobAssignmentInput!) {
  createJobAssignment(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMaterialAiJobAssignmentGQL extends Apollo.Mutation<CreateMaterialAiJobAssignmentMutation, CreateMaterialAiJobAssignmentMutationVariables> {
    override document = CreateMaterialAiJobAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CancelMaterialAiJobDocument = gql`
    mutation cancelMaterialAiJob($jobId: ID!) {
  cancelJob(id: $jobId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CancelMaterialAiJobGQL extends Apollo.Mutation<CancelMaterialAiJobMutation, CancelMaterialAiJobMutationVariables> {
    override document = CancelMaterialAiJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }