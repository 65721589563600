import {AfterViewInit, Component, ElementRef, inject, OnDestroy, output} from "@angular/core"
import {INFINITE_SCROLL_ROOT_MARGIN, INFINITE_SCROLL_THRESHOLD} from "@common/models/data/constants"

@Component({
    selector: "cm-infinite-scroll-anchor",
    imports: [],
    templateUrl: "./infinite-scroll-anchor.component.html",
    styleUrl: "./infinite-scroll-anchor.component.scss",
})
export class InfiniteScrollAnchorComponent implements AfterViewInit, OnDestroy {
    readonly intersectionChange = output<boolean>()
    private intersectionObserver?: IntersectionObserver

    readonly elementRef = inject(ElementRef)

    ngAfterViewInit() {
        this.intersectionObserver = new IntersectionObserver(
            (entries) => {
                const latestEntry = entries?.[entries.length - 1]
                const isIntersecting = latestEntry?.isIntersecting ?? false
                this.intersectionChange.emit(isIntersecting)
            },
            {
                root: document.querySelector(".cm-app-main-container"),
                rootMargin: INFINITE_SCROLL_ROOT_MARGIN,
                threshold: INFINITE_SCROLL_THRESHOLD,
            },
        )
        this.intersectionObserver.observe(this.elementRef.nativeElement)
    }

    ngOnDestroy() {
        this.intersectionObserver?.unobserve(this.elementRef.nativeElement)
    }
}
