import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SelectMaterialItemFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, description?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type SelectMaterialItemsQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.MaterialFilterInput>;
}>;


export type SelectMaterialItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Material', id: string, legacyId: number, name?: string | null, description?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export const SelectMaterialItemFragmentDoc = gql`
    fragment SelectMaterialItem on Material {
  id
  legacyId
  name
  description
  organization {
    id
    name
  }
  galleryImage {
    id
  }
}
    `;
export const SelectMaterialItemsDocument = gql`
    query selectMaterialItems($skip: Int, $take: Int, $filter: MaterialFilterInput) {
  items: materials(skip: $skip, take: $take, filter: $filter) {
    ...SelectMaterialItem
  }
  totalCount: materialsCount(filter: $filter)
}
    ${SelectMaterialItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SelectMaterialItemsGQL extends Apollo.Query<SelectMaterialItemsQuery, SelectMaterialItemsQueryVariables> {
    override document = SelectMaterialItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }