import {AfterContentInit, Component, OnDestroy, input, contentChildren} from "@angular/core"
import {ActionItemComponent} from "@common/components/menu/actions/action-item/action-item.component"
import {outputToObservable} from "@angular/core/rxjs-interop"
import {Subject, takeUntil} from "rxjs"

@Component({
    selector: "cm-action-bar",
    templateUrl: "./action-bar.component.html",
    styleUrls: ["./action-bar.component.scss"],
    imports: [],
})
export class ActionBarComponent implements AfterContentInit, OnDestroy {
    private unsubscribe = new Subject<void>()
    readonly actionItems = contentChildren(ActionItemComponent)
    readonly $visible = input(true, {alias: "visible"})
    readonly $activeItem = input<ActionItemComponent>(undefined, {alias: "activeItem"})

    ngAfterContentInit() {
        for (const actionItem of this.actionItems()) {
            outputToObservable(actionItem.itemClick)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                    if (actionItem.$panel() == null) return
                    this.actionItems().forEach((item) => (item.$panel() != null && item != actionItem ? (item.active = false) : null))
                })
            outputToObservable(actionItem.activeChange)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((active) => {
                    if (!active) return
                    this.actionItems().forEach((item) => (item.$panel() != null && item != actionItem ? (item.active = false) : null))
                })
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }
}
