import {NgStyle} from "@angular/common"
import {Component, computed, DestroyRef, effect, ElementRef, HostListener, inject, input, OnDestroy, OnInit, signal, Signal, viewChild} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {arrayDifferent, objectDifferent, objectFieldsDifferent} from "@app/template-editor/helpers/change-detection"
import {HELPER_OBJECTS_LAYER} from "@app/template-editor/helpers/helper-objects"
import {HelperObjectsPass} from "@app/template-editor/helpers/helper-objects-pass"
import {ImageDataRenderPass, ThreeImageData} from "@app/template-editor/helpers/image-data-render-pass"
import {OutlinePass} from "@app/template-editor/helpers/outline-pass"
import {SceneViewRenderPass} from "@app/template-editor/helpers/scene-view-render-pass"
import {ThreeMeshCurveControl} from "@app/template-editor/helpers/three-mesh-curve-control"
import {
    getNextThreeObjectPart,
    getThreeObjectPart,
    mathIsEqual,
    mathIsWithinEpsilon,
    ThreeObjectPart,
    threeObjectPartToSceneNodePart,
} from "@app/template-editor/helpers/three-object"
import {
    CameraParameters,
    fromThreeMatrix,
    MAX_FAR_CLIP,
    MIN_NEAR_CLIP,
    threeCameraToSceneNode,
    toThreeVector,
    updateThreeCamera,
} from "@app/template-editor/helpers/three-utils"
import {ToneMappingRenderPass} from "@app/template-editor/helpers/tone-mapping-render-pass"
import {constrainTransformTargetableNode, getTransformDelta} from "@app/template-editor/helpers/transform"
import {
    getControlPointPartNumber,
    getGroupPartNumber,
    isControlPointPart,
    isGroupPart,
    SceneManagerService,
    SceneNodePart,
    TemplateNodePart,
} from "@app/template-editor/services/scene-manager.service"
import {
    getTemplateNodePartFromPosition,
    getVectorFromPosition,
    TemplateDropTarget,
    TemplateNodeDragService,
} from "@app/template-editor/services/template-node-drag.service"
import {ThreeSceneManagerService} from "@app/template-editor/services/three-scene-manager.service"
import {EffectComposer, nodeFrame, OrbitControls, OutputPass, Three as THREE, TransformControls} from "@cm/material-nodes/three"
import {Matrix4, Vector3} from "@cm/math"
import {GlobalRenderConstants, SceneNodes} from "@cm/template-nodes/interfaces/scene-object"
import {ToneMapping} from "@cm/template-nodes"
import {isMaterialLike, isMesh, isObject, Object} from "@cm/template-nodes/node-types"
import {AreaLight} from "@cm/template-nodes/nodes/area-light"
import {Camera} from "@cm/template-nodes/nodes/camera"
import {MaterialAssignment} from "@cm/template-nodes/nodes/material-assignment"
import {MeshCurve} from "@cm/template-nodes/nodes/mesh-curve"
import {getNodeOwner} from "@cm/template-nodes/utils"
import {DimensionGuides} from "@cm/template-nodes"
import {colorToString} from "@cm/utils"
import {TypedImageData} from "@cm/utils/typed-image-data"
import {concatMap, defer, from, range, Subject, switchMap, timer} from "rxjs"
import {ThreeAnnotationRenderer} from "../three-annotation-renderer/three-annotation-renderer"

const TAA_FRAMES = 64

type Dimensions = {
    width: number
    height: number
}

function findScrollableParent(element: HTMLElement): HTMLElement | null {
    let parent = element.parentElement
    while (parent && parent !== document.body) {
        if (parent.scrollHeight > parent.clientHeight) return parent
        parent = parent.parentElement
    }

    return null
}

function typedImageDataToThree(data: TypedImageData, premultipliedAlpha: boolean): ThreeImageData {
    if (data.colorSpace !== "linear") {
        throw new Error(`Unsupported color space: ${data.colorSpace}`)
    }
    return {
        buffer: ((): BufferSource => {
            switch (data.dataType) {
                case "uint8":
                    return new Uint8Array(data.data.buffer, data.data.byteOffset, data.data.byteLength)
                case "uint16":
                    return new Uint16Array(data.data.buffer, data.data.byteOffset, data.data.byteLength / 2)
                case "float16":
                    return new Uint16Array(data.data.buffer, data.data.byteOffset, data.data.byteLength / 2)
                case "float32":
                    return new Float32Array(data.data.buffer, data.data.byteOffset, data.data.byteLength / 4)
                default:
                    throw new Error(`Unsupported data type: ${data.dataType}`)
            }
        })(),
        width: data.width,
        height: data.height,
        format: ((): THREE.PixelFormat => {
            switch (data.channelLayout) {
                case "RGBA":
                    return THREE.RGBAFormat
                case "L":
                    return THREE.LuminanceFormat
                default:
                    throw new Error(`Unsupported channel layout: ${data.channelLayout}`)
            }
        })(),
        type: ((): THREE.TextureDataType => {
            switch (data.dataType) {
                case "uint8":
                    return THREE.UnsignedByteType
                case "uint16":
                    return THREE.UnsignedShortType
                case "float16":
                    return THREE.HalfFloatType
                case "float32":
                    return THREE.FloatType
                default:
                    throw new Error(`Unsupported data type: ${data.dataType}`)
            }
        })(),
        premultipliedAlpha,
    }
}

export type SceneCameraParameters = CameraParameters &
    Pick<
        SceneNodes.Camera,
        | "target"
        | "targeted"
        | "autoFocus"
        | "focalDistance"
        | "enablePanning"
        | "screenSpacePanning"
        | "minPolarAngle"
        | "maxPolarAngle"
        | "minAzimuthAngle"
        | "maxAzimuthAngle"
        | "minDistance"
        | "maxDistance"
        | "fStop"
        | "toneMapping"
        | "exposure"
    >
export type SceneCamera = {parameters: SceneCameraParameters; transform?: Matrix4; ignoreAspectRatio?: boolean}

type ThreeObjectPartClickEventTarget = {
    threeObjectPart: ThreeObjectPart
    intersection: THREE.Intersection<THREE.Object3D>
}

export type TransformedNodePart = {
    templateNode: Object
    part: TemplateNodePart["part"]
}

@Component({
    selector: "cm-three-template-scene-viewer",
    imports: [NgStyle],
    templateUrl: "./three-template-scene-viewer.component.html",
    styleUrl: "./three-template-scene-viewer.component.scss",
})
export class ThreeTemplateSceneViewerComponent implements OnInit, OnDestroy {
    readonly sceneManagerService = inject(SceneManagerService)
    private readonly threeSceneManagerService = inject(ThreeSceneManagerService)
    readonly drag = inject(TemplateNodeDragService)

    private readonly $canvas = viewChild.required<ElementRef<HTMLDivElement>>("canvas")
    private readonly $controls = viewChild.required<ElementRef<HTMLDivElement>>("controls")
    private readonly $annotations = viewChild.required<ElementRef<HTMLDivElement>>("annotations")
    private readonly $canvasContainer = viewChild.required<ElementRef<HTMLDivElement>>("canvasContainer")
    private resizeObserver: ResizeObserver

    private readonly destroyRef = inject(DestroyRef)
    isDestroyed = false
    scrollableParent: HTMLElement | null = null

    private defaultCameraSettings = {
        nearClip: MIN_NEAR_CLIP,
        farClip: MAX_FAR_CLIP,
        filmGauge: 36,
        focalLength: 50,
        shiftX: 0,
        shiftY: 0,
    }
    private threeCamera: THREE.PerspectiveCamera

    private composer!: EffectComposer
    private sceneViewRenderPass!: SceneViewRenderPass
    private toneMappingRenderPass!: ToneMappingRenderPass
    private imageDataRenderPass!: ImageDataRenderPass
    private helperObjectsPass!: HelperObjectsPass
    private outputPass!: OutputPass
    private outlinePass!: OutlinePass
    private annotationRenderer!: ThreeAnnotationRenderer

    private orbitControls!: OrbitControls
    private readonly $orbitIsDragging = signal(false)
    private transformControls!: TransformControls
    private transformObject = new THREE.Group()
    private readonly $transformIsDragging = signal(false)

    private raycaster = new THREE.Raycaster()

    private threeDepthTexture: THREE.DepthTexture

    private readonly $containerDimensions = signal<Dimensions>({width: 1, height: 1})
    readonly $renderDimensions = computed(() => {
        const selectedCameraNode = this.$camera()
        let {width, height} = this.$containerDimensions()
        const pixelRatio = this.threeSceneManagerService.getRenderer().getPixelRatio()

        if (selectedCameraNode && selectedCameraNode.ignoreAspectRatio !== true) {
            const getCameraCutout = () => {
                const {aspectRatio} = selectedCameraNode.parameters

                const w1 = width
                const h1 = w1 / aspectRatio
                if (h1 <= height) return {x: 0, y: (height - h1) / 2, width: w1, height: h1}

                const h2 = height
                const w2 = h2 * aspectRatio
                if (w2 <= width) return {x: (width - w2) / 2, y: 0, width: w2, height: h2}

                throw new Error("Invalid aspect ratio")
            }

            const cutOut = getCameraCutout()
            width = cutOut.width
            height = cutOut.height
        }

        return {
            width,
            height,
            pixelRatio,
        }
    })

    readonly $transformedNodeParts = computed(() => {
        const selectedNodeParts = this.sceneManagerService.$selectedNodeParts()
        const selectedObjectParts = selectedNodeParts.filter((selectedNodePart): selectedNodePart is TransformedNodePart => {
            const {templateNode} = selectedNodePart
            if (this.sceneManagerService.getSceneNodeParts(selectedNodePart).length === 0) return false
            if (isObject(templateNode) && templateNode.parameters.lockedTransform !== undefined) return true
            if (templateNode instanceof MeshCurve) return true
            return false
        })

        if (selectedObjectParts.length > 1)
            return selectedObjectParts.filter(
                ({templateNode}) =>
                    !(
                        templateNode instanceof DimensionGuides ||
                        templateNode instanceof Camera ||
                        templateNode instanceof AreaLight ||
                        templateNode instanceof MeshCurve
                    ),
            )
        else if (selectedObjectParts.length === 1) {
            const {templateNode, part} = selectedObjectParts[0]

            if (part === "target") {
                if (this.sceneManagerService.$transformMode() === "rotate") return []
            } else if (part === "root") {
                if (templateNode instanceof MeshCurve || templateNode instanceof DimensionGuides) return []
            }
        }

        return selectedObjectParts
    })

    private updatePendingFrameId: number | null = null

    private renderingFinished = new Subject<void>()
    private resolutionChanged = new Subject<void>()

    private autoFocusUpdatePending = new Subject<void>()

    readonly $camera = input.required<SceneCamera | undefined>({alias: "camera"})
    readonly $allowEdit = input<boolean>(true, {alias: "allowEdit"})
    readonly $previewImageData = input<TypedImageData | null>(null, {alias: "previewImageData"})

    private readonly $viewCameraMatrix = signal<Matrix4>(new Matrix4(), {equal: mathIsWithinEpsilon})

    constructor() {
        this.raycaster.params.Line.threshold = 0.4

        this.threeCamera = new THREE.PerspectiveCamera()
        this.threeCamera.position.set(-200, 150, 200)
        this.threeCamera.lookAt(0, 0, 0)
        this.$viewCameraMatrix.set(fromThreeMatrix(this.threeCamera.matrix))

        /*Using an effect for this will cause flickering, because the effect is executed too late*/
        this.resolutionChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            const {width, height} = this.$renderDimensions()
            this.outlinePass.setSize(width, height)
            this.composer.setSize(width, height)
            this.annotationRenderer.setSize(width, height)

            this.renderImmediately()
        })

        effect(() => {
            const imageData = this.$previewImageData()
            const renderHelperObjectsSeparately = !!imageData
            this.sceneViewRenderPass.renderHelperObjects = !renderHelperObjectsSeparately
            this.helperObjectsPass.enabled = renderHelperObjectsSeparately
            this.imageDataRenderPass.setImageData(imageData && typedImageDataToThree(imageData, false))
            this.renderImmediately()
        })

        let previousOutlineObjects: ThreeObjectPart[] = []
        effect(() => {
            const hoveredObjects = this.threeSceneManagerService.$hoveredObjects()
            const outlineObjects = hoveredObjects.length !== 0 ? hoveredObjects : this.threeSceneManagerService.$selectedObjects()

            if (arrayDifferent(outlineObjects, previousOutlineObjects, (a, b) => a.threeObject === b.threeObject && a.part === b.part)) {
                const outlineSelectedObjects = outlineObjects
                    .map((threeObjectPart) => {
                        const {threeObject, part} = threeObjectPart
                        const renderObject = threeObject.getRenderObject()

                        const objects: THREE.Object3D[] = []
                        const queryTreeObjectPart = (object: THREE.Object3D) => {
                            const threeObjectPart = getThreeObjectPart(object)
                            if (threeObjectPart && threeObjectPart.threeObject === threeObject && threeObjectPart.part === part) {
                                objects.push(object)
                                return
                            }

                            object.children.forEach((child) => queryTreeObjectPart(child))
                        }
                        queryTreeObjectPart(renderObject)

                        return objects
                    })
                    .flat()

                this.outlinePass.selectedObjects = outlineSelectedObjects
                this.outlinePass.enabled = this.outlinePass.selectedObjects.length > 0

                this.render()
            }

            previousOutlineObjects = outlineObjects
        })

        effect(() => {
            const transformIsDragging = this.$transformIsDragging()
            if (!transformIsDragging) this.propagateTransformedNodeToObject()
        })

        effect(() => {
            this.threeSceneManagerService.$orbitIsDragging.set(this.$orbitIsDragging())
        })

        const isOrbitEnabled = () =>
            this.$camera()?.parameters?.targeted !== false &&
            (this.threeSceneManagerService.$displayMode() === "configurator" || (this.$camera()?.transform === undefined && !this.$transformIsDragging()))
        effect(() => {
            this.orbitControls.enabled = isOrbitEnabled()
        })

        const previousCameraDifferent = (
            current: SceneCameraParameters | undefined,
            previous: SceneCameraParameters | undefined,
            fields: (keyof SceneCameraParameters)[],
        ) => {
            function isToneMappingData(obj: object): obj is ToneMapping {
                return "mode" in obj
            }

            if (current === undefined) return previous !== undefined
            else
                return objectFieldsDifferent(current, previous, fields, (valueA, valueB) => {
                    if (typeof valueA === "object" && typeof valueB === "object") {
                        if (!isToneMappingData(valueA) && !isToneMappingData(valueB)) return mathIsEqual(valueA, valueB)
                        else if (isToneMappingData(valueA) && isToneMappingData(valueB)) return !objectDifferent(valueA, valueB, undefined)
                        else return false
                    }

                    return valueA === valueB
                })
        }
        const previousTransformDifferent = (current: Matrix4 | undefined, previous: Matrix4 | undefined) => {
            if (current === undefined) return previous !== undefined
            else if (previous === undefined) return true
            else return !mathIsEqual(current, previous)
        }

        let previousCamera: SceneCamera | undefined = undefined
        effect(() => {
            const camera = this.$camera()
            const parameters = camera?.parameters
            const transform = camera?.transform

            let orbitControlNeedsUpdate = false

            const orbitEnabled = isOrbitEnabled()

            const previousCameraTransform = this.threeCamera.matrix
            const renderDimensions = this.$renderDimensions()
            if (camera) {
                if (this.threeSceneManagerService.$displayMode() === "editor" && previousCamera === undefined) this.orbitControls.saveState()
                const newTransform = previousTransformDifferent(transform, previousCamera?.transform) ? transform : undefined
                updateThreeCamera(this.threeCamera, {...camera.parameters, aspectRatio: renderDimensions.width / renderDimensions.height}, newTransform)
            } else {
                updateThreeCamera(this.threeCamera, {...this.defaultCameraSettings, aspectRatio: renderDimensions.width / renderDimensions.height})
                if (orbitEnabled && this.threeSceneManagerService.$displayMode() === "editor" && previousCamera !== undefined) this.orbitControls.reset()
            }

            if (!previousCameraTransform.equals(this.threeCamera.matrix)) orbitControlNeedsUpdate = true

            if (previousCameraDifferent(parameters, previousCamera?.parameters, ["target"])) {
                const target = parameters?.target
                if (target) {
                    this.orbitControls.target = new THREE.Vector3(target.x, target.y, target.z)
                    orbitControlNeedsUpdate = true
                }
            }

            if (
                previousCameraDifferent(parameters, previousCamera?.parameters, [
                    "enablePanning",
                    "screenSpacePanning",
                    "minPolarAngle",
                    "maxPolarAngle",
                    "minAzimuthAngle",
                    "maxAzimuthAngle",
                    "minDistance",
                    "maxDistance",
                ])
            ) {
                this.orbitControls.enablePan = parameters?.enablePanning ?? true
                this.orbitControls.screenSpacePanning = parameters?.screenSpacePanning ?? true
                this.orbitControls.minPolarAngle = (parameters?.minPolarAngle ?? 0) * (Math.PI / 180)
                this.orbitControls.maxPolarAngle = (parameters?.maxPolarAngle ?? 180) * (Math.PI / 180)
                this.orbitControls.minAzimuthAngle = (parameters?.minAzimuthAngle ?? -Infinity) * (Math.PI / 180)
                this.orbitControls.maxAzimuthAngle = (parameters?.maxAzimuthAngle ?? Infinity) * (Math.PI / 180)
                this.orbitControls.minDistance = parameters?.minDistance ?? 0
                this.orbitControls.maxDistance = parameters?.maxDistance ?? 10000
                orbitControlNeedsUpdate = true
            }

            if (orbitControlNeedsUpdate) {
                if (orbitEnabled) {
                    this.orbitControls.update()
                    this.onOrbitChanged()
                    //enables reset of the camera through configurator api
                    if (this.threeSceneManagerService.$displayMode() === "configurator") this.orbitControls.saveState()
                }
                this.render()
            }

            if (previousCameraDifferent(parameters, previousCamera?.parameters, ["toneMapping"])) {
                const toneMapping = parameters?.toneMapping
                this.toneMappingRenderPass.setToneMapping(toneMapping ?? {mode: "linear"})
                this.render()
            }

            if (previousCameraDifferent(parameters, previousCamera?.parameters, ["exposure"])) {
                const exposure = parameters?.exposure ?? 1
                this.toneMappingRenderPass.setExposure(exposure)
                this.render()
            }

            if (
                previousCameraDifferent(parameters, previousCamera?.parameters, ["focalDistance", "focalLength", "fStop"]) ||
                previousTransformDifferent(transform, previousCamera?.transform)
            ) {
                if (!parameters) this.sceneViewRenderPass.setDepthOfField(undefined)
                else {
                    const {focalDistance, focalLength, fStop} = parameters

                    if (!transform) this.sceneViewRenderPass.setDepthOfField(undefined)
                    else {
                        this.sceneViewRenderPass.setDepthOfField({
                            apertureSize: (focalLength * 0.1) / fStop,
                            focalDistance,
                        })
                    }
                }

                this.render()
            }

            this.$viewCameraMatrix.set(fromThreeMatrix(this.threeCamera.matrix))

            previousCamera = camera
        })

        effect(() => {
            this.transformControls.setMode(this.sceneManagerService.$transformMode())
        })

        effect(() => {
            const scene = this.sceneManagerService.$scene()
            const sceneOptions = scene.find(SceneNodes.SceneOptions.is)
            const backgroundColor = sceneOptions?.backgroundColor

            const bgColorString = !backgroundColor ? "transparent" : colorToString(backgroundColor)
            this.$canvas().nativeElement.style.background = bgColorString
        })

        effect(() => {
            const allowEdit = this.$allowEdit()
            if (allowEdit) {
                this.composer.addPass(this.outlinePass)
                this.threeSceneManagerService.modifyBaseScene((scene) => {
                    scene.add(this.transformControls)
                    scene.add(this.transformObject)
                })
            } else {
                this.composer.removePass(this.outlinePass)
                this.threeSceneManagerService.modifyBaseScene((scene) => {
                    scene.remove(this.transformControls)
                    scene.remove(this.transformObject)
                })
            }
        })

        this.threeDepthTexture = new THREE.DepthTexture(1.0, 1.0)
        this.threeDepthTexture.format = THREE.DepthFormat
        this.threeDepthTexture.type = THREE.FloatType // Using HalfFloatType can cause artifacts at large distances

        this.resizeObserver = new ResizeObserver((entries) => {
            this.resizeView()
        })
    }

    $viewCamera: Signal<SceneNodes.Camera> = computed((): SceneNodes.Camera => {
        this.$viewCameraMatrix() // dependency
        return {
            ...threeCameraToSceneNode(this.threeCamera, "viewCamera", "viewCamera"),
            ...this.$camera()?.parameters,
        }
    })

    private propagateTransformedNodeToObject() {
        const transformedNodeParts = this.$transformedNodeParts()

        if (transformedNodeParts.length === 0) {
            if (this.transformControls.object) {
                this.transformControls.detach()
                this.render()
            }
        } else {
            const transformedNodePart = transformedNodeParts[0]

            const {templateNode, part} = transformedNodePart

            const getMatrixData = () => {
                if (part === "target" && (templateNode instanceof Camera || templateNode instanceof AreaLight)) {
                    const {target} = templateNode.parameters
                    return new THREE.Matrix4().setPosition(new THREE.Vector3(target[0], target[1], target[2]))
                } else if (isControlPointPart(part) && templateNode instanceof MeshCurve) {
                    const controlPoint = templateNode.parameters.controlPoints.at(getControlPointPartNumber(part))
                    if (!controlPoint) return new THREE.Matrix4()

                    const matrix = this.sceneManagerService.getTransformAccessor(templateNode)?.getTransform() ?? Matrix4.identity()
                    const transformedPoint = matrix.multiplyVectorXYZW(controlPoint.position[0], controlPoint.position[1], controlPoint.position[2], 1.0)
                    return new THREE.Matrix4().setPosition(new THREE.Vector3(transformedPoint[0], transformedPoint[1], transformedPoint[2]))
                }

                const matrix = this.sceneManagerService.getTransformAccessor(templateNode)?.getTransform() ?? Matrix4.identity()
                return new THREE.Matrix4().fromArray(matrix.toArray())
            }

            const newMatrix = getMatrixData()

            if (this.transformControls.object !== this.transformObject || !newMatrix.equals(this.transformObject.matrix)) {
                this.transformObject.matrix = newMatrix
                this.transformObject.matrix.decompose(this.transformObject.position, this.transformObject.quaternion, this.transformObject.scale)

                this.transformControls.attach(this.transformObject)
                this.render()
            }
        }
    }

    private transformControlsDraggingChanged = (
        event: {
            value: unknown
        } & THREE.Event<"dragging-changed", TransformControls>,
    ) => {
        const started: boolean = event.value as boolean
        this.$transformIsDragging.set(started)

        if (started) this.sceneManagerService.beginModifyTemplateGraph()
        else this.sceneManagerService.endModifyTemplateGraph()

        this.transformControls.visible = !started
        if (this.transformControls.visible) this.propagateTransformedNodeToObject()
    }

    private transformNodes(transform: Matrix4) {
        const mode = this.sceneManagerService.$transformMode()

        const transformedNodeParts = this.$transformedNodeParts()
        if (transformedNodeParts.length === 0) return

        if (transformedNodeParts.length === 1) {
            const {templateNode, part} = transformedNodeParts[0]

            if (templateNode instanceof Camera || templateNode instanceof AreaLight) {
                const {transform: constrainedTransform, target: constrainedTarget} = constrainTransformTargetableNode(
                    this.sceneManagerService,
                    transform,
                    {templateNode, part},
                    mode,
                )
                if (constrainedTransform)
                    this.sceneManagerService.modifyTemplateGraph(() => templateNode.updateParameters({lockedTransform: constrainedTransform.toArray()}))
                if (constrainedTarget) this.sceneManagerService.modifyTemplateGraph(() => templateNode.updateParameters({target: constrainedTarget.toArray()}))
            } else if (templateNode instanceof MeshCurve) {
                if (isControlPointPart(part)) {
                    const sceneNodeMatrix = this.sceneManagerService.getTransformAccessor(templateNode)?.getTransform()
                    if (!sceneNodeMatrix) return

                    const projection = toThreeVector(transform.getTranslation()).project(this.threeCamera)
                    const {width, height} = this.getCanvasRect()
                    const pointX = (projection.x * 0.5 + 0.5) * width
                    const pointY = (-projection.y * 0.5 + 0.5) * height

                    const sceneNodeParts = this.sceneManagerService.getSceneNodeParts({templateNode, part: "root"})
                    if (sceneNodeParts.length !== 1) return
                    const meshCurveControl = sceneNodeParts[0].sceneNode
                    if (!SceneNodes.MeshCurveControl.is(meshCurveControl)) return

                    const meshIntersection = this.getSceneNodePartsUnderCursor(pointX, pointY, false).find(
                        ({sceneNodePart}) => sceneNodePart.sceneNode.topLevelObjectId === meshCurveControl.mesh.topLevelObjectId,
                    )

                    if (!meshIntersection) return

                    const {intersection} = meshIntersection
                    const {point, normal} = intersection

                    if (!normal) return

                    const surfacePoint = Vector3.fromArray(sceneNodeMatrix.inverse().multiplyVectorXYZW(point.x, point.y, point.z, 1.0))
                    const surfaceNormal = new Vector3(normal.x, normal.y, normal.z).normalized()

                    const controlPoints = [...templateNode.parameters.controlPoints]

                    const controlPoint = controlPoints[getControlPointPartNumber(part)]

                    controlPoint.position = surfacePoint.toArray()
                    controlPoint.normal = surfaceNormal.toArray()

                    this.sceneManagerService.modifyTemplateGraph(() => {
                        templateNode.updateParameters({controlPoints})
                    })
                }
            } else {
                const delta = getTransformDelta(this.sceneManagerService, transform, templateNode)
                if (!delta) return
                this.sceneManagerService.modifyTemplateGraph(() =>
                    templateNode.updateParameters({lockedTransform: delta.multiply(new Matrix4(templateNode.parameters.lockedTransform)).toArray()}),
                )
            }
        } else {
            const firstTransformedNode = transformedNodeParts[0].templateNode
            const delta = getTransformDelta(this.sceneManagerService, transform, firstTransformedNode)
            if (!delta) return

            for (const transformedNodePart of transformedNodeParts) {
                const {templateNode} = transformedNodePart

                if (
                    templateNode instanceof Camera ||
                    templateNode instanceof AreaLight ||
                    templateNode instanceof MeshCurve ||
                    templateNode instanceof DimensionGuides
                )
                    continue

                const lockedTransform = templateNode.parameters.lockedTransform
                if (lockedTransform)
                    this.sceneManagerService.modifyTemplateGraph(() =>
                        templateNode.updateParameters({lockedTransform: delta.multiply(new Matrix4(lockedTransform)).toArray()}),
                    )
            }
        }
    }

    private transformControlsObjectChanged = () => {
        this.transformNodes(fromThreeMatrix(this.transformObject.matrix))
    }

    private scrolled = () => {
        this.render()
    }

    ngOnInit() {
        this.scrollableParent = findScrollableParent(this.$canvasContainer().nativeElement)
        if (this.scrollableParent) this.scrollableParent.addEventListener("scroll", this.scrolled)

        this.threeSceneManagerService.requestedRedraw$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.render())
        this.threeSceneManagerService.requestedResize$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.resizeView())

        const renderer = this.threeSceneManagerService.getRenderer()

        this.composer = new EffectComposer(renderer)

        this.sceneViewRenderPass = new SceneViewRenderPass(this.threeSceneManagerService.getSceneReference(), this.threeCamera, this.threeDepthTexture)
        this.composer.addPass(this.sceneViewRenderPass)

        this.imageDataRenderPass = new ImageDataRenderPass()
        this.composer.addPass(this.imageDataRenderPass)

        this.helperObjectsPass = new HelperObjectsPass(this.threeSceneManagerService.getSceneReference(), this.threeCamera, this.threeDepthTexture)
        this.helperObjectsPass.enabled = false
        this.composer.addPass(this.helperObjectsPass)

        this.toneMappingRenderPass = new ToneMappingRenderPass({mode: "linear"}, 1.0)
        this.composer.addPass(this.toneMappingRenderPass)

        this.outputPass = new OutputPass()
        this.composer.addPass(this.outputPass)

        const {width, height} = this.$renderDimensions()
        this.outlinePass = new OutlinePass(new THREE.Vector2(width, height), this.threeSceneManagerService.getSceneReference(), this.threeCamera)
        this.outlinePass.visibleEdgeColor = new THREE.Color(0x03a9f4)
        this.outlinePass.hiddenEdgeColor = new THREE.Color(0x03a9f4)
        this.outlinePass.edgeStrength = 4
        this.outlinePass.edgeThickness = 1
        this.outlinePass.edgeGlow = 0.25
        this.outlinePass.overlayMaterial.blending = THREE.CustomBlending

        const controls = this.$controls()
        this.orbitControls = new OrbitControls(this.threeCamera, controls.nativeElement)
        this.orbitControls.addEventListener("start", this.onOrbitStart)
        this.orbitControls.addEventListener("change", this.onOrbitChanged)
        this.orbitControls.addEventListener("end", this.onOrbitEnd)
        this.threeSceneManagerService.requestedRedraw$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            if (this.threeSceneManagerService.$displayMode() === "configurator") this.updateAutoFocus()
        })
        this.transformControls = new TransformControls(this.threeCamera, controls.nativeElement)
        this.transformControls.size = 2.0 / 5.0
        this.transformControls.addEventListener("dragging-changed", this.transformControlsDraggingChanged)
        this.transformControls.addEventListener("objectChange", this.transformControlsObjectChanged)
        this.transformControls.addEventListener("change", this.render)

        this.transformControls.getRaycaster().layers.set(HELPER_OBJECTS_LAYER)
        this.transformControls.traverse((child) => child.layers.set(HELPER_OBJECTS_LAYER))
        this.raycaster.layers.enable(HELPER_OBJECTS_LAYER)

        this.annotationRenderer = new ThreeAnnotationRenderer({element: this.$annotations().nativeElement})

        this.resizeView()

        const taaUpdate = async (iteration: number) => {
            return new Promise<void>((resolve) => {
                requestAnimationFrame(() => {
                    this.renderInRect(() => {
                        this.sceneViewRenderPass.taaMode = true
                        this.composer.render()
                        this.sceneViewRenderPass.taaMode = false
                    })
                    resolve()
                })
            })
        }

        //If for 100ms there are no other rendering finished events, start accumulating 32 TAA, stop if another rendering finished event is emitted
        this.renderingFinished
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap(() => timer(100).pipe(switchMap(() => range(TAA_FRAMES).pipe(concatMap((iteration) => defer(() => from(taaUpdate(iteration)))))))),
            )
            .subscribe()

        this.autoFocusUpdatePending
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap(() => timer(100)),
            )
            .subscribe(() => {
                const camera = this.$camera()
                if (camera) {
                    const {parameters} = camera
                    if (parameters.autoFocus) {
                        const {focalLength, fStop} = parameters
                        const apertureSize = (focalLength * 0.1) / fStop

                        //A too small aperture size will not yield any DOF effect, so we skip the update
                        if (apertureSize < 0.1) return

                        this.raycaster.setFromCamera(new THREE.Vector2(0, 0), this.threeCamera)

                        const intersections = this.raycaster.intersectObjects(this.threeSceneManagerService.getSceneReference().children, true)

                        if (intersections.length > 0) {
                            const intersection = intersections[0]
                            const {distance: focalDistance} = intersection

                            this.sceneViewRenderPass.setDepthOfField({
                                apertureSize,
                                focalDistance,
                            })

                            console.log("Auto focus updated")

                            this.render()
                        }
                    }
                }
            })

        this.drag.draggedItem$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({dragSource, dropTarget}) => {
            const {component, position} = dropTarget
            if (component === this) {
                const draggedNode = this.drag.draggableSourceToTemplateNode(dragSource)
                if (!draggedNode) return

                if (isMaterialLike(draggedNode)) {
                    this.sceneManagerService.hoverNode(undefined)

                    const {templateNode, part} = getTemplateNodePartFromPosition(position)
                    if (isMesh(templateNode)) {
                        if (isGroupPart(part)) {
                            const slot = `${getGroupPartNumber(part)}`

                            const materialAssignment = templateNode.parameters.materialAssignments.parameters[slot]
                            if (materialAssignment)
                                this.sceneManagerService.modifyTemplateGraph(() =>
                                    templateNode.parameters.materialAssignments.updateParameters({
                                        [slot]: materialAssignment.clone({cloneSubNode: () => true, parameterOverrides: {node: draggedNode}}),
                                    }),
                                )
                            else
                                this.sceneManagerService.modifyTemplateGraph(() =>
                                    templateNode.parameters.materialAssignments.updateParameters({
                                        [slot]: new MaterialAssignment({node: draggedNode, side: "front"}),
                                    }),
                                )
                        }
                    }
                } else if (isObject(draggedNode)) {
                    this.threeSceneManagerService.$cursorPosition.set(undefined)

                    const vector = getVectorFromPosition(position)

                    if (!getNodeOwner(draggedNode)) {
                        const transform = new Matrix4()
                        transform.setTranslation(vector)

                        this.sceneManagerService.modifyTemplateGraph((templateGraph) =>
                            templateGraph.parameters.nodes.addEntry(
                                draggedNode.clone({cloneSubNode: () => true, parameterOverrides: {lockedTransform: transform.toArray()}}),
                            ),
                        )
                    } else {
                        const matrix = new Matrix4(draggedNode.parameters.lockedTransform) ?? new Matrix4()
                        const {position, quaternion, scale} = matrix.decompose()
                        const newMatrix = Matrix4.compose(vector, quaternion, scale)
                        this.sceneManagerService.modifyTemplateGraph(() => draggedNode.updateParameters({lockedTransform: newMatrix.toArray()}))
                    }
                }
            }
        })
    }

    ngAfterViewInit() {
        this.resizeObserver.observe(this.$canvasContainer().nativeElement)
    }

    clear() {
        this.renderInRect((renderer) => renderer.clear(), this.getCanvasContainerRect())
    }

    ngOnDestroy() {
        this.clear()

        this.threeSceneManagerService.modifyBaseScene((scene) => {
            scene.remove(this.transformControls)
            scene.remove(this.transformObject)
        })
        this.transformControls.removeEventListener("dragging-changed", this.transformControlsDraggingChanged)
        this.transformControls.removeEventListener("objectChange", this.transformControlsObjectChanged)
        this.transformControls.removeEventListener("change", this.render)
        this.transformControls.dispose()
        this.orbitControls.removeEventListener("start", this.onOrbitStart)
        this.orbitControls.removeEventListener("change", this.onOrbitChanged)
        this.orbitControls.removeEventListener("end", this.onOrbitEnd)
        this.orbitControls.dispose()
        this.composer.dispose()
        this.helperObjectsPass.dispose()
        this.outlinePass.dispose()
        this.outputPass.dispose()
        this.toneMappingRenderPass.dispose()
        this.sceneViewRenderPass.dispose()
        if (this.scrollableParent) this.scrollableParent.removeEventListener("scroll", this.scrolled)
        this.threeDepthTexture.dispose()

        this.isDestroyed = true
    }

    private previousDrawRect: DOMRect | null = null
    private renderInRect(callback: (renderer: THREE.WebGLRenderer) => void, rect?: DOMRect) {
        if (this.isDestroyed) return

        const renderer = this.threeSceneManagerService.getRenderer()

        const previousScissorTest = renderer.getScissorTest()
        const previousViewport = new THREE.Vector4()
        renderer.getViewport(previousViewport)
        const previousScissor = new THREE.Vector4()
        renderer.getScissor(previousScissor)

        const scrollableParentRect = this.getScrollableParentRect()
        const clientRect = rect ?? this.getCanvasRect()
        const rendererRect = renderer.domElement.getBoundingClientRect()

        const x = clientRect.left - rendererRect.left
        const y = rendererRect.height - clientRect.height - (clientRect.top - rendererRect.top)

        renderer.setScissorTest(true)
        renderer.setViewport(x, y, clientRect.width, clientRect.height)

        const drawRect = ((): DOMRect => {
            if (scrollableParentRect) {
                const outsideLeft = Math.max(0, scrollableParentRect.left - clientRect.left)
                const outsideTop = Math.max(0, scrollableParentRect.top - clientRect.top)
                const outsideRight = Math.max(0, clientRect.right - scrollableParentRect.right)
                const outsideBottom = Math.max(0, clientRect.bottom - scrollableParentRect.bottom)

                return new DOMRect(
                    Math.max(x + outsideLeft, 0),
                    Math.max(y + outsideBottom, 0),
                    Math.max(clientRect.width - outsideRight, 0),
                    Math.max(clientRect.height - outsideTop, 0),
                )
            } else return new DOMRect(Math.max(x, 0), Math.max(y, 0), clientRect.width, clientRect.height)
        })()

        if (this.previousDrawRect) {
            const {x, y, width, height} = drawRect
            const {x: previousX, y: previousY, width: previousWidth, height: previousHeight} = this.previousDrawRect
            if (x !== previousX || y !== previousY || width !== previousWidth || height !== previousHeight) {
                renderer.setScissor(previousX, previousY, previousWidth, previousHeight)
                renderer.clear()
            }
        }

        renderer.setScissor(drawRect.x, drawRect.y, drawRect.width, drawRect.height)

        callback(renderer)
        this.previousDrawRect = drawRect

        renderer.setScissor(previousScissor.x, previousScissor.y, previousScissor.z, previousScissor.w)
        renderer.setViewport(previousViewport.x, previousViewport.y, previousViewport.z, previousViewport.w)
        renderer.setScissorTest(previousScissorTest)
    }

    private updateAutoFocus() {
        this.autoFocusUpdatePending.next()
    }

    private onOrbitStart = () => {
        this.$orbitIsDragging.set(true)
    }

    private onOrbitChanged = () => {
        if (this.orbitControls.enabled) {
            if (this.threeSceneManagerService.$displayMode() === "configurator") this.updateAutoFocus()
            this.$viewCameraMatrix.set(fromThreeMatrix(this.threeCamera.matrix))

            this.render()
        }
    }

    private onOrbitEnd = () => {
        this.$orbitIsDragging.set(false)
    }

    private renderImmediately() {
        nodeFrame.update()
        console.log("render")

        this.renderInRect(() => this.composer.render())
        this.annotationRenderer.render(this.threeSceneManagerService.getSceneReference(), this.threeCamera)
        this.renderingFinished.next()
    }

    private render = () => {
        if (this.updatePendingFrameId !== null) return
        else
            this.updatePendingFrameId = requestAnimationFrame(() => {
                this.renderImmediately()
                this.updatePendingFrameId = null
            })
    }

    private getCanvasRect() {
        return this.$canvas().nativeElement.getBoundingClientRect()
    }

    private getCanvasContainerRect() {
        return this.$canvasContainer().nativeElement.getBoundingClientRect()
    }

    private getScrollableParentRect() {
        return this.scrollableParent?.getBoundingClientRect()
    }

    getViewportSize() {
        const {width, height} = this.getCanvasContainerRect()
        return [width, height]
    }

    private resizeView() {
        const {width, height} = this.getCanvasContainerRect()
        this.$containerDimensions.set({width, height})
        this.resolutionChanged.next()
    }

    @HostListener("mousedown", ["$event"])
    onMouseDown(downEvent: MouseEvent) {
        const {nativeElement} = this.$canvas()
        let isDragging = false

        const onMouseMove = (moveEvent: MouseEvent) => {
            isDragging = true
        }

        const onMouseUp = (upEvent: MouseEvent): void => {
            if (!isDragging) {
                const rect = nativeElement.getBoundingClientRect()
                this.onMouseClick(upEvent.clientX - rect.left, upEvent.clientY - rect.top, upEvent.shiftKey, upEvent.ctrlKey)
            }

            nativeElement.removeEventListener("mousemove", onMouseMove)
            nativeElement.removeEventListener("mouseup", onMouseUp)
        }

        nativeElement.addEventListener("mousemove", onMouseMove)
        nativeElement.addEventListener("mouseup", onMouseUp)
    }

    @HostListener("mousemove", ["$event"])
    onMouseMove(moveEvent: MouseEvent) {
        if (this.sceneManagerService.watchingForClickedSceneNodePart()) {
            const {nativeElement} = this.$canvas()
            const rect = nativeElement.getBoundingClientRect()
            const templateNodePartsUnderCursor = this.getTemplateNodePartsUnderCursor(moveEvent.clientX - rect.left, moveEvent.clientY - rect.top, false)
            if (templateNodePartsUnderCursor.length > 0) this.sceneManagerService.hoverNode(templateNodePartsUnderCursor[0].templateNodePart)
            else this.sceneManagerService.hoverNode(undefined)
        }
    }

    protected onMouseClick(pointX: number, pointY: number, shiftKey: boolean, ctrlKey: boolean) {
        if (!this.$allowEdit() && !this.sceneManagerService.watchingForClickedSceneNodePart()) return

        if (this.sceneManagerService.watchingForClickedSceneNodePart()) this.sceneManagerService.hoverNode(undefined)

        const sceneNodePartsUnderCursor = this.getSceneNodePartsUnderCursor(pointX, pointY, false)

        this.sceneManagerService.handleClickEvent({
            target: sceneNodePartsUnderCursor,
            modifiers: {shiftKey, ctrlKey},
        })
    }

    protected getObjectsUnderCursor(pointX: number, pointY: number, meshGroups: boolean): ThreeObjectPartClickEventTarget[] {
        const {width, height} = this.getCanvasRect()
        const x = (pointX / width) * 2 - 1
        const y = -((pointY / height) * 2 - 1)

        this.raycaster.setFromCamera(new THREE.Vector2(x, y), this.threeCamera)

        const intersectedObjects: ThreeObjectPartClickEventTarget[] = []
        this.raycaster
            .intersectObjects(this.threeSceneManagerService.getManagedThreeReferences(), true)
            .filter(
                (intersection) => intersection.object.visible && intersection.distance > this.threeCamera.near && intersection.distance < this.threeCamera.far,
            )
            .forEach((intersection) => {
                const {object} = intersection
                const threeObjectPart = getNextThreeObjectPart(object)
                if (threeObjectPart) {
                    const mappedThreeObjectPart = (threeObjectPart: ThreeObjectPart): ThreeObjectPart => {
                        if (!meshGroups) {
                            const {part} = threeObjectPart
                            if (part.match(/group\d+/)) return {threeObject: threeObjectPart.threeObject, part: "root"}
                        }

                        return threeObjectPart
                    }

                    intersectedObjects.push({threeObjectPart: mappedThreeObjectPart(threeObjectPart), intersection})
                }
            })

        return intersectedObjects
    }

    protected getSceneNodePartsUnderCursor(pointX: number, pointY: number, meshGroups: boolean) {
        const objectsUnderCursor = this.getObjectsUnderCursor(pointX, pointY, meshGroups)

        const isPriorizedPart = (threeObjectPart: ThreeObjectPart) =>
            threeObjectPart.part === "target" || threeObjectPart.threeObject instanceof ThreeMeshCurveControl

        const targetPriorizedObjectsUnderCursor = [
            ...objectsUnderCursor.filter(({threeObjectPart}) => isPriorizedPart(threeObjectPart)),
            ...objectsUnderCursor.filter(({threeObjectPart}) => !isPriorizedPart(threeObjectPart)),
        ]

        return targetPriorizedObjectsUnderCursor.map(({threeObjectPart, intersection}) => {
            const sceneNodePart = threeObjectPartToSceneNodePart(threeObjectPart)
            return {sceneNodePart, intersection}
        })
    }

    protected getTemplateNodePartsUnderCursor(pointX: number, pointY: number, meshGroups: boolean) {
        const sceneNodePartsUnderCursor = this.getSceneNodePartsUnderCursor(pointX, pointY, meshGroups)

        const result: {templateNodePart: TemplateNodePart; intersection: THREE.Intersection}[] = []
        for (const {sceneNodePart, intersection} of sceneNodePartsUnderCursor) {
            const templateNodePart = this.sceneManagerService.sceneNodePartToTemplateNodePart(sceneNodePart)
            if (templateNodePart)
                result.push({
                    templateNodePart,
                    intersection,
                })
        }

        return result
    }

    dragOver(event: DragEvent) {
        const dragSource = this.drag.$dragSource()
        if (!dragSource) return

        const draggedNode = this.drag.draggableSourceToTemplateNode(dragSource)
        if (!draggedNode) return

        if (isMaterialLike(draggedNode)) {
            this.drag.$dropTarget.update((previous) => {
                const {nativeElement} = this.$canvas()
                const rect = nativeElement.getBoundingClientRect()
                const templateNodePartsUnderCursor = this.getTemplateNodePartsUnderCursor(event.clientX - rect.left, event.clientY - rect.top, true)

                if (templateNodePartsUnderCursor.length === 0) {
                    this.sceneManagerService.hoverNode(undefined)
                    return null
                }

                const {templateNodePart} = templateNodePartsUnderCursor[0]
                const {templateNode, part} = templateNodePart

                if (!isMesh(templateNode)) {
                    this.sceneManagerService.hoverNode(undefined)
                    return null
                }

                this.sceneManagerService.hoverNode(templateNodePart)

                if (!isGroupPart(part)) return null

                const slot = `${getGroupPartNumber(part)}`
                const materialAssignment = templateNode.parameters.materialAssignments.parameters[slot]

                if (materialAssignment && materialAssignment.parameters.node === draggedNode) return null

                const dropTarget = {component: this, position: templateNodePart} as TemplateDropTarget<ThreeTemplateSceneViewerComponent>
                const templateNodePartEqual = (a: TemplateNodePart, b: TemplateNodePart) => a.part === b.part && a.templateNode === b.templateNode

                if (
                    previous &&
                    previous.component === dropTarget.component &&
                    templateNodePartEqual(getTemplateNodePartFromPosition(previous.position), getTemplateNodePartFromPosition(dropTarget.position))
                )
                    return previous

                return dropTarget
            })
        } else if (isObject(draggedNode)) {
            this.drag.$dropTarget.update((previous) => {
                const {nativeElement} = this.$canvas()
                const rect = nativeElement.getBoundingClientRect()
                const templateNodePartsUnderCursor = this.getTemplateNodePartsUnderCursor(event.clientX - rect.left, event.clientY - rect.top, false)

                if (templateNodePartsUnderCursor.length === 0) {
                    this.threeSceneManagerService.$cursorPosition.set(undefined)
                    return null
                }

                const {intersection} = templateNodePartsUnderCursor[0]
                const {point} = intersection

                const vector = new Vector3(point.x, point.y, point.z)
                this.threeSceneManagerService.$cursorPosition.set(vector)

                const dropTarget = {component: this, position: vector} as TemplateDropTarget<ThreeTemplateSceneViewerComponent>

                if (
                    previous &&
                    previous.component === dropTarget.component &&
                    mathIsEqual(getVectorFromPosition(previous.position), getVectorFromPosition(dropTarget.position))
                )
                    return previous

                return dropTarget
            })
        } else return

        if (this.drag.$dropTarget() !== null) event.preventDefault()
    }

    dragLeave(event: DragEvent) {
        event.stopPropagation()

        const {nativeElement} = this.$canvas()

        this.sceneManagerService.hoverNode(undefined)
        this.threeSceneManagerService.$cursorPosition.set(undefined)

        this.drag.dragLeave(event, this, nativeElement)
    }

    zoomIn(value: number) {
        if (this.orbitControls.enabled) {
            //@ts-ignore
            this.orbitControls.dollyIn(value > 1 || value < 0 ? 1.0 : 1 - value)
            this.render()
        } else throw new Error("Orbit controls are disabled, zooming is not possible.")
    }

    zoomOut(value: number) {
        if (this.orbitControls.enabled) {
            //@ts-ignore
            this.orbitControls.dollyOut(value > 1 || value < 0 ? 1.0 : 1 - value)
            this.render()
        } else throw new Error("Orbit controls are disabled, zooming is not possible.")
    }

    resetCamera() {
        if (this.orbitControls.enabled) {
            this.orbitControls.reset()
            this.render()
        } else throw new Error("Orbit controls are disabled, resetting the camera is not possible.")
    }
}
