import {CompleteMeshData} from "#template-nodes/geometry-processing/mesh-data"
import {MeshNodes} from "@cm/render-nodes"
import {ISceneManager} from "#template-nodes/interfaces/scene-manager"
import {Inlet, NotReady, Outlet} from "#template-nodes/runtime-graph/slots"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {defer, map, Subscription} from "rxjs"

const TD = TypeDescriptors

const loadMeshDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManager>()),
    data: TD.inlet(TD.Identity<Uint8Array>()),
    drcDataObjectId: TD.inlet(TD.Number),
    plyDataObjectId: TD.inlet(TD.Number),
    displaySubdivisionLevel: TD.inlet(TD.Number),
    renderSubdivisionLevel: TD.inlet(TD.Number),
    completeMeshData: TD.outlet(TD.CompleteMeshData),
}

export class LoadMesh implements NodeClassImpl<typeof loadMeshDescriptor, typeof LoadMesh> {
    static descriptor = loadMeshDescriptor
    static uniqueName = "LoadMesh"
    sceneManager!: Inlet<ISceneManager>
    data!: Inlet<Uint8Array>
    drcDataObjectId!: Inlet<number>
    plyDataObjectId!: Inlet<number>
    displaySubdivisionLevel!: Inlet<number>
    renderSubdivisionLevel!: Inlet<number>
    completeMeshData!: Outlet<CompleteMeshData>

    private pending: Subscription | null = null

    run() {
        this.completeMeshData.emitIfChanged(NotReady)
        this.pending?.unsubscribe()

        if (
            this.sceneManager === NotReady ||
            this.data === NotReady ||
            this.drcDataObjectId === NotReady ||
            this.plyDataObjectId === NotReady ||
            this.displaySubdivisionLevel === NotReady ||
            this.renderSubdivisionLevel === NotReady
        ) {
            return
        }

        const {displaySubdivisionLevel, renderSubdivisionLevel, sceneManager, data, drcDataObjectId, plyDataObjectId} = this

        const displayGeometryGraphLow: MeshNodes.Mesh = {
            type: "loadMesh",
            data: {
                type: "dataObjectReference",
                dataObjectId: drcDataObjectId,
            },
        }

        const displayGeometryGraphHigh: MeshNodes.Mesh | null =
            displaySubdivisionLevel > 0
                ? {
                      type: "subdivide",
                      input: displayGeometryGraphLow,
                      levels: displaySubdivisionLevel,
                  }
                : null

        let renderGeometryGraph: MeshNodes.Mesh = {
            type: "loadMesh",
            data: {
                type: "dataObjectReference",
                dataObjectId: plyDataObjectId,
            },
        }

        if (renderSubdivisionLevel > 0) {
            renderGeometryGraph = {
                type: "subdivide",
                input: renderGeometryGraph,
                levels: renderSubdivisionLevel,
            }
        }

        const displayGeometryGraphResources = {[drcDataObjectId]: {contentType: "application/draco", data}}

        //TODO: unify subdivision application with ProceduralMesh
        const cachedReifiedHigh = displayGeometryGraphHigh ? sceneManager.getCachedMesh(displayGeometryGraphHigh) : null
        if (displayGeometryGraphHigh && cachedReifiedHigh) {
            this.completeMeshData.emitIfChanged({
                reified: cachedReifiedHigh,
                abstract: {
                    contentHash: cachedReifiedHigh.contentHash,
                    displayGeometryGraph: displayGeometryGraphHigh,
                    renderGeometryGraph,
                    displayGeometryGraphResources,
                },
            })
        } else {
            this.pending = this.sceneManager.addTask({
                description: `loadMesh(${drcDataObjectId})`,
                task: defer(() => sceneManager.evaluateMesh(displayGeometryGraphLow, displayGeometryGraphResources)).pipe(
                    map((reifiedLow) => {
                        this.pending = null
                        this.completeMeshData.emitIfChanged({
                            reified: reifiedLow,
                            abstract: {
                                contentHash: reifiedLow.contentHash,
                                displayGeometryGraph: displayGeometryGraphLow,
                                renderGeometryGraph,
                                displayGeometryGraphResources,
                            },
                        })
                        if (displayGeometryGraphHigh) {
                            this.pending = sceneManager.addTask({
                                description: `loadMeshHigh(${drcDataObjectId})`,
                                task: defer(() => sceneManager.evaluateMesh(displayGeometryGraphHigh, displayGeometryGraphResources)).pipe(
                                    map((reifiedHigh) => {
                                        this.pending = null
                                        this.completeMeshData.emitIfChanged({
                                            reified: reifiedHigh,
                                            abstract: {
                                                contentHash: reifiedHigh.contentHash,
                                                displayGeometryGraph: displayGeometryGraphHigh,
                                                renderGeometryGraph,
                                                displayGeometryGraphResources,
                                            },
                                        })
                                    }),
                                ),
                                critical: false,
                            })
                        }
                    }),
                ),
                critical: true,
            })
        }
    }

    cleanup() {
        this.pending?.unsubscribe()
    }
}
