import * as Types from '../../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TemplateRevisionDetailsForConfiguratorFragment = { __typename: 'TemplateRevision', id: string, graph?: any | null };

export type TemplateDetailsForConfiguratorFragment = { __typename: 'Template', id: string, organizationLegacyId: number, latestRevision?: { __typename: 'TemplateRevision', id: string, graph?: any | null } | null };

export type GetTemplateDetailsForConfiguratorQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetTemplateDetailsForConfiguratorQuery = { __typename: 'Query', template: { __typename: 'Template', id: string, organizationLegacyId: number, latestRevision?: { __typename: 'TemplateRevision', id: string, graph?: any | null } | null } };

export type GetTemplateUuidFromSceneLegacyIdForConfiguratorQueryVariables = Types.Exact<{
  sceneLegacyId: Types.Scalars['Int']['input'];
}>;


export type GetTemplateUuidFromSceneLegacyIdForConfiguratorQuery = { __typename: 'Query', scene: { __typename: 'Scene', picture: { __typename: 'Picture', template?: { __typename: 'Template', id: string } | null } } };

export type LatestRevisionFromMaterialIdForConfiguratorQueryVariables = Types.Exact<{
  materialId: Types.Scalars['ID']['input'];
}>;


export type LatestRevisionFromMaterialIdForConfiguratorQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null } };

export type LatestRevisionFromMaterialLegacyIdForConfiguratorQueryVariables = Types.Exact<{
  materialLegacyId: Types.Scalars['Int']['input'];
}>;


export type LatestRevisionFromMaterialLegacyIdForConfiguratorQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null } };

export type MaterialIdFromLegacyIdQueryVariables = Types.Exact<{
  materialLegacyId: Types.Scalars['Int']['input'];
}>;


export type MaterialIdFromLegacyIdQuery = { __typename: 'Query', material: { __typename: 'Material', id: string } };

export const TemplateRevisionDetailsForConfiguratorFragmentDoc = gql`
    fragment TemplateRevisionDetailsForConfigurator on TemplateRevision {
  id
  graph
}
    `;
export const TemplateDetailsForConfiguratorFragmentDoc = gql`
    fragment TemplateDetailsForConfigurator on Template {
  id
  organizationLegacyId
  latestRevision {
    ...TemplateRevisionDetailsForConfigurator
  }
}
    `;
export const GetTemplateDetailsForConfiguratorDocument = gql`
    query getTemplateDetailsForConfigurator($id: ID!) {
  template(id: $id) {
    ...TemplateDetailsForConfigurator
  }
}
    ${TemplateDetailsForConfiguratorFragmentDoc}
${TemplateRevisionDetailsForConfiguratorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateDetailsForConfiguratorGQL extends Apollo.Query<GetTemplateDetailsForConfiguratorQuery, GetTemplateDetailsForConfiguratorQueryVariables> {
    override document = GetTemplateDetailsForConfiguratorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateUuidFromSceneLegacyIdForConfiguratorDocument = gql`
    query getTemplateUuidFromSceneLegacyIdForConfigurator($sceneLegacyId: Int!) {
  scene(legacyId: $sceneLegacyId) {
    picture {
      template {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateUuidFromSceneLegacyIdForConfiguratorGQL extends Apollo.Query<GetTemplateUuidFromSceneLegacyIdForConfiguratorQuery, GetTemplateUuidFromSceneLegacyIdForConfiguratorQueryVariables> {
    override document = GetTemplateUuidFromSceneLegacyIdForConfiguratorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LatestRevisionFromMaterialIdForConfiguratorDocument = gql`
    query latestRevisionFromMaterialIdForConfigurator($materialId: ID!) {
  material(id: $materialId) {
    id
    latestCyclesRevision {
      id
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LatestRevisionFromMaterialIdForConfiguratorGQL extends Apollo.Query<LatestRevisionFromMaterialIdForConfiguratorQuery, LatestRevisionFromMaterialIdForConfiguratorQueryVariables> {
    override document = LatestRevisionFromMaterialIdForConfiguratorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LatestRevisionFromMaterialLegacyIdForConfiguratorDocument = gql`
    query latestRevisionFromMaterialLegacyIdForConfigurator($materialLegacyId: Int!) {
  material(legacyId: $materialLegacyId) {
    id
    latestCyclesRevision {
      id
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LatestRevisionFromMaterialLegacyIdForConfiguratorGQL extends Apollo.Query<LatestRevisionFromMaterialLegacyIdForConfiguratorQuery, LatestRevisionFromMaterialLegacyIdForConfiguratorQueryVariables> {
    override document = LatestRevisionFromMaterialLegacyIdForConfiguratorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialIdFromLegacyIdDocument = gql`
    query materialIdFromLegacyId($materialLegacyId: Int!) {
  material(legacyId: $materialLegacyId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialIdFromLegacyIdGQL extends Apollo.Query<MaterialIdFromLegacyIdQuery, MaterialIdFromLegacyIdQueryVariables> {
    override document = MaterialIdFromLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }