import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ScanJobDialogFragment = { __typename: 'ScanJob', id: string, legacyId: number };

export type ScanJobDialogSubJobQueryVariables = Types.Exact<{
  scanSubJobId: Types.Scalars['ID']['input'];
}>;


export type ScanJobDialogSubJobQuery = { __typename: 'Query', scanSubJob: { __typename: 'ScanSubJob', id: string, active: boolean, completed?: string | null, started?: string | null, state: Types.ScanSubJobState, tileX: number, tileY: number, progress?: string | null, logAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }>, fittingAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }>, assemblyAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }> } };

export type ScanJobDialogSubJobsQueryVariables = Types.Exact<{
  scanJobId: Types.Scalars['ID']['input'];
  skip: Types.Scalars['Int']['input'];
  take: Types.Scalars['Int']['input'];
}>;


export type ScanJobDialogSubJobsQuery = { __typename: 'Query', items: Array<{ __typename: 'ScanSubJob', id: string, active: boolean, completed?: string | null, started?: string | null, state: Types.ScanSubJobState, tileX: number, tileY: number, progress?: string | null, logAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }>, fittingAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }>, assemblyAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }> } | null> };

export type ScanJobDialogSubJobFragment = { __typename: 'ScanSubJob', id: string, active: boolean, completed?: string | null, started?: string | null, state: Types.ScanSubJobState, tileX: number, tileY: number, progress?: string | null, logAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }>, fittingAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }>, assemblyAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', downloadUrl: string } }> };

export type ScanJobDialogUpdateSubJobMutationVariables = Types.Exact<{
  input: Types.MutationUpdateScanSubJobInput;
}>;


export type ScanJobDialogUpdateSubJobMutation = { __typename: 'Mutation', updateScanSubJob: { __typename: 'ScanSubJob', id: string } };

export type ScanJobDialogQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ScanJobDialogQuery = { __typename: 'Query', item: { __typename: 'ScanJob', id: string, legacyId: number, subJobs: Array<{ __typename: 'ScanSubJob', id: string }> } };

export const ScanJobDialogFragmentDoc = gql`
    fragment ScanJobDialog on ScanJob {
  id
  legacyId
  __typename
}
    `;
export const ScanJobDialogSubJobFragmentDoc = gql`
    fragment ScanJobDialogSubJob on ScanSubJob {
  id
  active
  completed
  started
  state
  tileX
  tileY
  progress
  logAssignments: dataObjectAssignments(filter: {assignmentType: ScanLog}) {
    dataObject {
      downloadUrl
    }
  }
  fittingAssignments: dataObjectAssignments(
    filter: {assignmentType: ScanFittingWorkPackage}
  ) {
    dataObject {
      downloadUrl
    }
  }
  assemblyAssignments: dataObjectAssignments(
    filter: {assignmentType: ScanAssemblyWorkPackage}
  ) {
    dataObject {
      downloadUrl
    }
  }
}
    `;
export const ScanJobDialogSubJobDocument = gql`
    query scanJobDialogSubJob($scanSubJobId: ID!) {
  scanSubJob(id: $scanSubJobId) {
    ...ScanJobDialogSubJob
  }
}
    ${ScanJobDialogSubJobFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScanJobDialogSubJobGQL extends Apollo.Query<ScanJobDialogSubJobQuery, ScanJobDialogSubJobQueryVariables> {
    override document = ScanJobDialogSubJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScanJobDialogSubJobsDocument = gql`
    query scanJobDialogSubJobs($scanJobId: ID!, $skip: Int!, $take: Int!) {
  items: scanSubJobs(
    take: $take
    skip: $skip
    filter: {scanJobId: {equals: $scanJobId}}
  ) {
    ...ScanJobDialogSubJob
  }
}
    ${ScanJobDialogSubJobFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScanJobDialogSubJobsGQL extends Apollo.Query<ScanJobDialogSubJobsQuery, ScanJobDialogSubJobsQueryVariables> {
    override document = ScanJobDialogSubJobsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScanJobDialogUpdateSubJobDocument = gql`
    mutation scanJobDialogUpdateSubJob($input: MutationUpdateScanSubJobInput!) {
  updateScanSubJob(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ScanJobDialogUpdateSubJobGQL extends Apollo.Mutation<ScanJobDialogUpdateSubJobMutation, ScanJobDialogUpdateSubJobMutationVariables> {
    override document = ScanJobDialogUpdateSubJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScanJobDialogDocument = gql`
    query scanJobDialog($id: ID!) {
  item: scanJob(id: $id) {
    subJobs {
      id
    }
    ...ScanJobDialog
  }
}
    ${ScanJobDialogFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScanJobDialogGQL extends Apollo.Query<ScanJobDialogQuery, ScanJobDialogQueryVariables> {
    override document = ScanJobDialogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }