import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MaterialDetailsForMaterialInspectorFragment = { __typename: 'MaterialRevision', material: { __typename: 'Material', galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type GetMaterialDetailsForMaterialInspectorQueryVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type GetMaterialDetailsForMaterialInspectorQuery = { __typename: 'Query', materialRevision: { __typename: 'MaterialRevision', material: { __typename: 'Material', galleryImage?: { __typename: 'DataObject', id: string } | null } } };

export const MaterialDetailsForMaterialInspectorFragmentDoc = gql`
    fragment MaterialDetailsForMaterialInspector on MaterialRevision {
  material {
    galleryImage {
      id
    }
  }
}
    `;
export const GetMaterialDetailsForMaterialInspectorDocument = gql`
    query getMaterialDetailsForMaterialInspector($legacyId: Int!) {
  materialRevision(legacyId: $legacyId) {
    ...MaterialDetailsForMaterialInspector
  }
}
    ${MaterialDetailsForMaterialInspectorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialDetailsForMaterialInspectorGQL extends Apollo.Query<GetMaterialDetailsForMaterialInspectorQuery, GetMaterialDetailsForMaterialInspectorQueryVariables> {
    override document = GetMaterialDetailsForMaterialInspectorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }