import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AssetActivityItemsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type AssetActivityItemsQuery = { __typename: 'Query', items: Array<{ __typename: 'AssetHistory', id: string, historyChangeReason?: string | null, historyDate?: string | null, historyType: Types.HistoryType, state: Types.AssetState, createdBy?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null> };

export type MaterialActivityItemsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MaterialActivityItemsQuery = { __typename: 'Query', items: Array<{ __typename: 'MaterialHistory', id: string, historyChangeReason?: string | null, historyDate?: string | null, historyType: Types.HistoryType, state: Types.MaterialState, createdBy?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null> };

export type ModelActivityItemsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ModelActivityItemsQuery = { __typename: 'Query', items: Array<{ __typename: 'ModelHistory', id: string, historyChangeReason?: string | null, historyDate?: string | null, historyType: Types.HistoryType, state: Types.ModelState, createdBy?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null> };

export type PictureActivityItemsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type PictureActivityItemsQuery = { __typename: 'Query', items: Array<{ __typename: 'PictureHistory', id: string, historyChangeReason?: string | null, historyDate?: string | null, historyType: Types.HistoryType, state: Types.PictureState, createdBy?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null> };

export const AssetActivityItemsDocument = gql`
    query assetActivityItems($id: ID!) {
  items: assetHistoryItems(
    filter: {assetId: {equals: $id}}
    orderBy: [{key: history_date, direction: desc}]
    take: 1000
  ) {
    id
    historyChangeReason
    historyDate
    historyType
    state
    createdBy {
      id
      firstName
      lastName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssetActivityItemsGQL extends Apollo.Query<AssetActivityItemsQuery, AssetActivityItemsQueryVariables> {
    override document = AssetActivityItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialActivityItemsDocument = gql`
    query materialActivityItems($id: ID!) {
  items: materialHistoryItems(
    filter: {materialId: {equals: $id}}
    orderBy: [{key: history_date, direction: desc}]
    take: 1000
  ) {
    id
    historyChangeReason
    historyDate
    historyType
    state
    createdBy {
      id
      firstName
      lastName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialActivityItemsGQL extends Apollo.Query<MaterialActivityItemsQuery, MaterialActivityItemsQueryVariables> {
    override document = MaterialActivityItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ModelActivityItemsDocument = gql`
    query modelActivityItems($id: ID!) {
  items: modelHistoryItems(
    filter: {modelId: {equals: $id}}
    orderBy: [{key: history_date, direction: desc}]
    take: 1000
  ) {
    id
    historyChangeReason
    historyDate
    historyType
    state
    createdBy {
      id
      firstName
      lastName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ModelActivityItemsGQL extends Apollo.Query<ModelActivityItemsQuery, ModelActivityItemsQueryVariables> {
    override document = ModelActivityItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureActivityItemsDocument = gql`
    query pictureActivityItems($id: ID!) {
  items: pictureHistoryItems(
    filter: {pictureId: {equals: $id}}
    orderBy: [{key: history_date, direction: desc}]
    take: 1000
  ) {
    id
    historyChangeReason
    historyDate
    historyType
    state
    createdBy {
      id
      firstName
      lastName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureActivityItemsGQL extends Apollo.Query<PictureActivityItemsQuery, PictureActivityItemsQueryVariables> {
    override document = PictureActivityItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }