import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetDataObjectAssignmentsQueryVariables = Types.Exact<{
  filter: Types.DataObjectAssignmentFilterInput;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetDataObjectAssignmentsQuery = { __typename: 'Query', dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, related: Array<{ __typename: 'DataObject', id: string }> } } | null> };

export type DeleteDataObjectMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteDataObjectMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export const GetDataObjectAssignmentsDocument = gql`
    query getDataObjectAssignments($filter: DataObjectAssignmentFilterInput!, $take: Int, $skip: Int) {
  dataObjectAssignments(filter: $filter, take: $take, skip: $skip) {
    id
    dataObject {
      id
      related {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDataObjectAssignmentsGQL extends Apollo.Query<GetDataObjectAssignmentsQuery, GetDataObjectAssignmentsQueryVariables> {
    override document = GetDataObjectAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDataObjectDocument = gql`
    mutation deleteDataObject($id: ID!) {
  deleteDataObject(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDataObjectGQL extends Apollo.Mutation<DeleteDataObjectMutation, DeleteDataObjectMutationVariables> {
    override document = DeleteDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }