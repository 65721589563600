import {Component, DestroyRef, inject, OnInit, input, model, EventEmitter, Output} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatPaginatorModule, PageEvent} from "@angular/material/paginator"
import {PagingService} from "@common/services/paging/paging.service"

@Component({
    selector: "cm-paginator",
    imports: [MatPaginatorModule],
    templateUrl: "./paginator.component.html",
    styleUrl: "./paginator.component.scss",
})
export class PaginatorComponent implements OnInit {
    readonly destroyRef = inject(DestroyRef)
    readonly paging = inject(PagingService)

    readonly $totalCount = model<number | undefined>(undefined, {alias: "totalCount"})
    readonly $page = input<number | null>(null, {alias: "page"})
    readonly $pageSize = input<number | null>(null, {alias: "pageSize"})
    readonly $pageSizeOptions = input<number[]>([10, 20, 50, 100], {alias: "pageSizeOptions"})

    @Output() pageChange = new EventEmitter<number>()
    @Output() pageSizeChange = new EventEmitter<number>()

    ngOnInit() {
        if (this.$totalCount() === undefined) {
            this.paging.totalCount$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((totalCount) => {
                this.$totalCount.set(totalCount)
            })
        }
    }

    async setPage(pageEvent: PageEvent) {
        if (this.pageChange.observed || this.pageSizeChange.observed) {
            this.pageSizeChange.emit(pageEvent.pageSize)
            this.pageChange.emit(pageEvent.pageIndex)
        } else {
            await this.paging.changePage(pageEvent.pageIndex)
            await this.paging.changePageSize(pageEvent.pageSize)
        }
    }
}
