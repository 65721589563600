import {PagingParams} from "@common/models/data"
import {NotVisibleError} from "@common/models/errors"
import {ERROR_ITEMS, PLACEHOLDER_ITEMS} from "@platform/models/data/constants"
import {DataWatcher, LoadedData} from "@platform/models/data"
import {catchError, combineLatest, identity, map, Observable, of, startWith, switchMap, tap} from "rxjs"
import {v4 as uuid4} from "uuid"

export const pagedData$ = <EntityType extends {id: string}>(
    loader$: Observable<DataWatcher<EntityType>>,
    paging$: Observable<PagingParams>,
    refresh$: Observable<boolean>,
    batchSizes?: {initial: number},
): Observable<LoadedData<EntityType>> => {
    let totalCount: number | undefined = undefined
    return combineLatest([
        loader$.pipe(
            tap(() => {
                totalCount = undefined
            }),
        ),
        paging$,
        refresh$,
    ]).pipe(
        switchMap(([loader, {page, pageSize}, clearPrevious]) =>
            loader(page * pageSize, pageSize)
                .pipe(
                    map((result) => {
                        totalCount = result.totalCount
                        return {
                            items: result.items.map((dataItem) => {
                                const trackId = uuid4()
                                if (dataItem) {
                                    return {trackId, data: dataItem, error: null}
                                } else {
                                    // TODO: more precise error handling
                                    // we currently assume that a null data item means that the item is not visible
                                    return {trackId, data: null, error: new NotVisibleError()}
                                }
                            }),
                            error: null,
                            complete: true,
                            totalCount: result.totalCount,
                        }
                    }),
                    catchError((error) => {
                        return of(ERROR_ITEMS<EntityType>(pageSize, error))
                    }),
                )
                .pipe(clearPrevious ? startWith({...PLACEHOLDER_ITEMS<EntityType>(batchSizes?.initial ?? pageSize), totalCount}) : identity),
        ),
    )
}
