import * as FBXParser from "fbx-parser"

export type FBXMeshData = {
    name: string
    verticesPerFace: Uint32Array
    position: Float32Array
    normal: Float32Array
    materialID: Uint32Array
    uvs: Float32Array[]
}

type FBXNode = FBXParser.FBXNode

type LayerElementData = {
    mappingType: string
    referenceType: string
    directArray: number[] | null
    indexArray: number[] | null
    componentCount: number
}

export function extractMeshData(fbxNodes: FBXParser.FBXData): FBXMeshData[] {
    const objectsNode = fbxNodes.find((n) => n.name === "Objects")
    if (!objectsNode) {
        throw new Error("No 'Objects' node found in FBX data.")
    }

    const geometryNodes = findNodesByName(objectsNode.nodes, "Geometry")
    const results: FBXMeshData[] = []

    for (const geomNode of geometryNodes) {
        const [objectID, geomName, geomType] = geomNode.props
        if (geomType !== "Mesh") continue
        const name = (geomName as string).replace(/^Geometry::/, "")

        const verticesNode = findNodeByName(geomNode.nodes, "Vertices")
        if (!verticesNode) throw new Error("No Vertices node found in Geometry.")
        const vertexArray = verticesNode.props[0] as number[]

        const polygons = getPolygonIndices(geomNode)
        const numPolygons = polygons.length
        const polygonVertexCount = polygons.reduce((sum, poly) => sum + poly.length, 0)

        // Layer elements (Normals and UVs)
        const normalData = findNormalLayer(geomNode)
        const uvData = findUVLayers(geomNode)

        // Materials
        const {materialMappingType, materialRefType, materialIndex} = getMaterialData(geomNode)

        // Prepare final arrays
        const finalPositions = new Float32Array(polygonVertexCount * 3)
        const finalNormals = normalData.directArray ? new Float32Array(polygonVertexCount * 3) : new Float32Array(0)
        const finalUVs = uvData.map((uvData) => (uvData.directArray ? new Float32Array(polygonVertexCount * 2) : new Float32Array(0)))
        const finalFaceMaterialID = new Uint32Array(numPolygons)
        const finalVerticesPerFace = new Uint32Array(numPolygons)

        // Fill data
        let pvCounter = 0
        for (let p = 0; p < numPolygons; p++) {
            const poly = polygons[p]
            finalVerticesPerFace[p] = poly.length

            // Material
            finalFaceMaterialID[p] = getMaterialForPolygon(materialMappingType, materialRefType, materialIndex, p)

            for (let v = 0; v < poly.length; v++) {
                const vertexIndex = poly[v]
                // Positions are always by control point
                const px = vertexArray[vertexIndex * 3 + 0]
                const py = vertexArray[vertexIndex * 3 + 1]
                const pz = vertexArray[vertexIndex * 3 + 2]
                finalPositions[pvCounter * 3 + 0] = px
                finalPositions[pvCounter * 3 + 1] = py
                finalPositions[pvCounter * 3 + 2] = pz

                // Normals
                if (normalData.directArray) {
                    fillAttributeData(normalData, finalNormals, pvCounter, vertexIndex, p, pvCounter)
                }

                // UVs
                for (let i = 0; i < uvData.length; i++) {
                    if (uvData[i].directArray) {
                        fillAttributeData(uvData[i], finalUVs[i], pvCounter, vertexIndex, p, pvCounter)
                    }
                }

                pvCounter++
            }
        }

        results.push({
            name,
            verticesPerFace: finalVerticesPerFace,
            position: finalPositions,
            normal: finalNormals,
            materialID: expandFaceMaterialIDsToVertices(finalFaceMaterialID, finalVerticesPerFace),
            uvs: finalUVs,
        })
    }

    return results
}

function getPolygonIndices(geomNode: FBXNode): number[][] {
    const polygonIndexNode = findNodeByName(geomNode.nodes, "PolygonVertexIndex")
    if (!polygonIndexNode) throw new Error("No PolygonVertexIndex node found in Geometry.")
    const polygonIndices = polygonIndexNode.props[0] as number[]

    const polygons: number[][] = []
    let currentPoly: number[] = []
    for (let i = 0; i < polygonIndices.length; i++) {
        const idx = polygonIndices[i]
        if (idx < 0) {
            const v = -idx - 1
            currentPoly.push(v)
            polygons.push(currentPoly)
            currentPoly = []
        } else {
            currentPoly.push(idx)
        }
    }
    return polygons
}

function findNormalLayer(geomNode: FBXNode): LayerElementData {
    const layerNode = findNodeByName(geomNode.nodes, "LayerElementNormal")
    if (!layerNode) {
        return {
            mappingType: "ByPolygonVertex",
            referenceType: "Direct",
            directArray: null,
            indexArray: null,
            componentCount: 3,
        }
    }
    return getLayerElementData(layerNode, "Normals", "NormalsIndex", 3)
}

function findUVLayers(geomNode: FBXNode): LayerElementData[] {
    const layerNodes = findNodesByName(geomNode.nodes, "LayerElementUV")
    return layerNodes.map((layerNode) => getLayerElementData(layerNode, "UV", "UVIndex", 2))
}

function getLayerElementData(layerNode: FBXNode, directNodeName: string, indexNodeName: string, componentCount: number): LayerElementData {
    // const layerNode = findNodeByName(geomNode.nodes, elementName)
    if (!layerNode) {
        return {
            mappingType: "ByPolygonVertex",
            referenceType: "Direct",
            directArray: null,
            indexArray: null,
            componentCount,
        }
    }

    const mappingType = (findNodeByName(layerNode.nodes, "MappingInformationType")?.props[0] as string | undefined) || "ByPolygonVertex"
    const referenceType = (findNodeByName(layerNode.nodes, "ReferenceInformationType")?.props[0] as string | undefined) || "Direct"

    const directNode = findNodeByName(layerNode.nodes, directNodeName)
    const indexNode = findNodeByName(layerNode.nodes, indexNodeName)

    const directArray = directNode ? (directNode.props[0] as number[]) : null
    const indexArray = indexNode ? (indexNode.props[0] as number[]) : null

    return {
        mappingType,
        referenceType,
        directArray,
        indexArray,
        componentCount,
    }
}

function getMaterialData(geomNode: FBXNode) {
    const layerMat = findNodeByName(geomNode.nodes, "LayerElementMaterial")
    let materialMappingType = "AllSame"
    let materialRefType = "IndexToDirect"
    let materialIndex: number[] | null = null

    if (layerMat) {
        materialMappingType = (findNodeByName(layerMat.nodes, "MappingInformationType")?.props[0] as string | undefined) || materialMappingType
        materialRefType = (findNodeByName(layerMat.nodes, "ReferenceInformationType")?.props[0] as string | undefined) || materialRefType
        const matsNode = findNodeByName(layerMat.nodes, "Materials")
        if (matsNode) {
            materialIndex = matsNode.props[0] as number[]
        }
    }

    return {materialMappingType, materialRefType, materialIndex}
}

function getMaterialForPolygon(mappingType: string, referenceType: string, materialIndex: number[] | null, polygonIndex: number): number {
    if (!materialIndex || materialIndex.length === 0) {
        return 0
    }

    // For materials, typically "AllSame" means materialIndex[0] for all,
    // "ByPolygon" means materialIndex[polygonIndex]
    // Usually referenceType is "IndexToDirect" for materials, meaning index is direct material ID.
    switch (mappingType) {
        case "AllSame":
            return materialIndex[0]
        case "ByPolygon":
            return materialIndex[polygonIndex]
        default:
            // Other modes are rare for materials
            return materialIndex[0]
    }
}

function fillAttributeData(
    data: LayerElementData,
    targetArray: Float32Array | Uint32Array,
    polygonVertexAbsoluteIndex: number,
    controlPointIndex: number,
    polygonIndex: number,
    polygonVertexIndex: number,
) {
    const elementIndex = getElementIndex(
        data.mappingType,
        data.referenceType,
        data.directArray!,
        data.indexArray,
        controlPointIndex,
        polygonIndex,
        polygonVertexAbsoluteIndex,
    )

    for (let c = 0; c < data.componentCount; c++) {
        targetArray[polygonVertexAbsoluteIndex * data.componentCount + c] = data.directArray![elementIndex * data.componentCount + c]
    }
}

function getElementIndex(
    mappingType: string,
    referenceType: string,
    directArray: number[],
    indexArray: number[] | null,
    controlPointIndex: number,
    polygonIndex: number,
    polygonVertexIndex: number,
): number {
    let finalIndex: number

    switch (mappingType) {
        case "ByControlPoint":
        case "ByVertex":
        case "ByVertice":
            finalIndex = controlPointIndex
            break
        case "ByPolygonVertex":
            finalIndex = polygonVertexIndex
            break
        case "ByPolygon":
            finalIndex = polygonIndex
            break
        case "AllSame":
            finalIndex = 0
            break
        case "ByEdge":
            throw new Error("MappingInformationType 'ByEdge' not supported.")
        default:
            throw new Error(`Unsupported MappingInformationType: ${mappingType}`)
    }

    if (referenceType === "IndexToDirect") {
        if (!indexArray) throw new Error("Index array missing for IndexToDirect reference")
        finalIndex = indexArray[finalIndex]
    } else if (referenceType !== "Direct") {
        throw new Error(`Unsupported ReferenceInformationType: ${referenceType}`)
    }

    return finalIndex
}

function findNodeByName(nodes: FBXNode[], name: string): FBXNode | undefined {
    return nodes.find((n) => n.name === name)
}

function findNodesByName(nodes: FBXNode[], name: string): FBXNode[] {
    return nodes.filter((n) => n.name === name)
}

function expandFaceMaterialIDsToVertices(materialID: Uint32Array, verticesPerFace: Uint32Array): Uint32Array {
    let vCounter = 0
    for (let i = 0; i < verticesPerFace.length; i++) {
        vCounter += verticesPerFace[i]
    }
    const result = new Uint32Array(vCounter)
    let vIndex = 0
    for (let i = 0; i < verticesPerFace.length; i++) {
        for (let j = 0; j < verticesPerFace[i]; j++) {
            result[vIndex++] = materialID[i]
        }
    }
    return result
}

export function importMeshesFromFBXFile(buffer: ArrayBufferView) {
    let fbx: FBXParser.FBXData
    try {
        fbx = FBXParser.parseBinary(new Uint8Array(buffer.buffer, buffer.byteOffset, buffer.byteLength))
    } catch (e) {
        console.warn("Failed to parse FBX file as binary, trying text parser")
        fbx = FBXParser.parseText(new TextDecoder().decode(buffer))
    }
    return extractMeshData(fbx)
}
