import {HalContext, HalEntity} from "@common/models/hal/hal-context"
import {HalImage} from "@common/models/hal/hal-image"
import {HalPaintable} from "@common/models/hal/hal-paintable"
import {HalPainterImageCompositor} from "@common/models/hal/hal-painter-image-compositor"
import {createHalPainterImageCompositor} from "@common/models/hal/hal-painter-image-compositor/create"

const COMPOSITING_FUNCTION = `
        vec4 computeColor(ivec2 targetPixel) {
            return 1.0 - texelFetch0(targetPixel);
        }
`

export class HalInvertMask implements HalEntity {
    constructor(readonly context: HalContext) {
        this.halPainterImageCompositor = createHalPainterImageCompositor(this.context, COMPOSITING_FUNCTION)
    }

    // HalEntity
    dispose(): void {
        this.halPainterImageCompositor.dispose()
    }

    paint(target: HalPaintable, source: HalImage) {
        this.halPainterImageCompositor.paint({
            target,
            sourceImages: source,
        })
    }

    private halPainterImageCompositor: HalPainterImageCompositor
}
