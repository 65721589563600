import {Component, input} from "@angular/core"

@Component({
    selector: "cm-section-content",
    templateUrl: "./section-content.component.html",
    styleUrls: ["./section-content.component.scss"],
})
export class SectionContentComponent {
    readonly $indent = input(true, {alias: "indent"})
}
