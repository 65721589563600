import {Component, OnDestroy, OnInit, inject, output} from "@angular/core"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"

@Component({
    selector: "cm-template-scene-provider",
    templateUrl: "./template-scene-provider.component.html",
    styleUrl: "./template-scene-provider.component.scss",
    providers: [SceneManagerService],
})
export class TemplateSceneProviderComponent implements OnInit, OnDestroy {
    readonly onInitialized = output<SceneManagerService | undefined>()

    private readonly localSceneManagerService = inject(SceneManagerService)

    constructor() {}

    ngOnInit() {
        this.onInitialized.emit(this.localSceneManagerService)
    }

    ngOnDestroy() {
        this.onInitialized.emit(undefined)
    }
}
