import {Directive, input} from "@angular/core"
import {OperatorBase} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator-base"
import * as TextureEditNodes from "@app/textures/texture-editor/texture-edit-nodes"
import {MeasurementTypes} from "@common/models/settings/settings"

@Directive()
export class OperatorPanelBase<OperatorClass extends OperatorBase<TextureEditNodes.Operator | undefined>> {
    readonly $operator = input.required<OperatorClass>({alias: "operator"})
    readonly $disabled = input(false, {alias: "disabled"})

    get measureUnit(): string {
        switch (this.measurementType) {
            case MeasurementTypes.Metric:
                return "cm"
            case MeasurementTypes.Imperial:
                return "in"
            default:
                throw new Error("Unknown measurement type")
        }
    }

    px2measure(px: number): number {
        const pixelsPerCm = this.$operator().callback.tilingCanvas.$physicalInfo().pixelsPerCm
        if (!pixelsPerCm) {
            throw new Error("Pixels per cm not set")
        }
        const cm = px / pixelsPerCm
        return this.measurementType.convertFromMetric(cm)
    }

    measure2px(measure: number): number {
        const pixelsPerCm = this.$operator().callback.tilingCanvas.$physicalInfo().pixelsPerCm
        if (!pixelsPerCm) {
            throw new Error("Pixels per cm not set")
        }
        const cm = this.measurementType.convertToMetric(measure)
        return cm * pixelsPerCm
    }

    private measurementType = MeasurementTypes.Metric // TODO make this switchable
}
