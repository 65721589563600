import {AsyncPipe, NgClass} from "@angular/common"
import {Component, DestroyRef, inject, OnInit, signal} from "@angular/core"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"
import {MatDialog} from "@angular/material/dialog"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ActivatedRoute, Router} from "@angular/router"
import {
    PictureRevisionSelectorPictureFragment,
    PictureRevisionSelectorRevisionFragment,
} from "@app/platform/components/pictures/picture-revision-selector/picture-revision-selector.generated"
import {CardComponent} from "@common/components/cards"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {DialogComponent} from "@common/components/dialogs/dialog/dialog.component"
import {DropFilesComponent} from "@common/components/files"
import {SidenavComponent} from "@common/components/navigation"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {TimeAgoPipe} from "@common/pipes/time-ago/time-ago.pipe"
import {FilesService} from "@common/services/files/files.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {Enums} from "@enums"
import {ContentTypeModel, DataObjectAssignmentType, MutationUpdatePictureInput} from "@generated"
import {Labels} from "@labels"
import {MimeType} from "@legacy/helpers/utils"
import {FeedbackCanvasComponent} from "@platform/components/pictures/feedback-canvas/feedback-canvas.component"
import {PictureDetailsDeleteRevisionGQL} from "@platform/components/pictures/picture-details/picture-details.generated"
import {
    PictureRevisionCreateDataObjectAssignmentGQL,
    PictureRevisionSelectorCreateRevisionGQL,
    PictureRevisionSelectorItemGQL,
    PictureRevisionSelectorUpdatePictureGQL,
} from "@platform/components/pictures/picture-revision-selector/picture-revision-selector.generated"
import {PaymentStateLabelComponent} from "@platform/components/shared/payment-state-label/payment-state-label.component"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {DrawingService} from "@platform/services/pictures/drawing.service"
import {ThumbnailsService} from "@platform/services/thumbnails/thumbnails.service"
import {catchError, combineLatest, distinctUntilChanged, map, of, switchMap} from "rxjs"

@Component({
    imports: [
        AsyncPipe,
        CardComponent,
        FeedbackCanvasComponent,
        MatTooltipModule,
        NgClass,
        SidenavComponent,
        StateLabelComponent,
        DropFilesComponent,
        PaymentStateLabelComponent,
        DataObjectThumbnailComponent,
        TimeAgoPipe,
    ],
    selector: "cm-picture-revision-selector",
    styleUrl: "./picture-revision-selector.component.scss",
    templateUrl: "./picture-revision-selector.component.html",
})
export class PictureRevisionSelectorComponent implements OnInit {
    revisionSidebarOpen = false

    organizationId?: string

    readonly destroyRef = inject(DestroyRef)
    readonly drawing = inject(DrawingService)
    readonly mdDialog = inject(MatDialog)
    readonly notifications = inject(NotificationsService)
    readonly permission = inject(PermissionsService)
    readonly refresh = inject(RefreshService)
    readonly route = inject(ActivatedRoute)
    readonly router = inject(Router)
    readonly thumbnails = inject(ThumbnailsService)
    readonly uploadService = inject(UploadGqlService)
    $can = this.permission.$to

    readonly $picture = signal<PictureRevisionSelectorPictureFragment | undefined | null>(undefined)
    readonly $currentRevision = signal<PictureRevisionSelectorRevisionFragment | undefined | null>(undefined)
    readonly $isDrawing = toSignal(this.drawing.isDrawing$)

    readonly itemGql = inject(PictureRevisionSelectorItemGQL)
    readonly deletePictureRevisionGql = inject(PictureDetailsDeleteRevisionGQL)
    readonly updatePictureGql = inject(PictureRevisionSelectorUpdatePictureGQL)
    readonly createRevisionGql = inject(PictureRevisionSelectorCreateRevisionGQL)
    readonly createDataObjectAssignmentGql = inject(PictureRevisionCreateDataObjectAssignmentGQL)

    ngOnInit() {
        combineLatest([
            this.route.paramMap.pipe(
                map((params) => params.get("itemId") ?? ""),
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyRef),
            ),
            this.route.paramMap.pipe(
                map((params) => parseInt(params.get("revisionNumber") ?? "", 10)),
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyRef),
            ),
        ])
            .pipe(
                switchMap(([itemId, revisionNumber]) =>
                    this.refresh
                        .keepFetched$(itemId, ContentTypeModel.PictureRevision, (id) => fetchThrowingErrors(this.itemGql)(id))
                        .pipe(map((picture) => ({picture, revisionNumber}))),
                ),
                catchError((error) => {
                    console.error(error)
                    return of({picture: null, revisionNumber: null})
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe((item) => {
                this.$picture.set(item.picture)
                this.organizationId = item.picture?.organization?.id
                this.$currentRevision.set(
                    item.picture?.revisions?.find((revision: PictureRevisionSelectorRevisionFragment) => revision.number === item.revisionNumber) ??
                        item.picture?.latestRevision ??
                        null,
                )
            })
    }

    get currentRevisionId() {
        const revision = this.$currentRevision()
        switch (revision) {
            case null:
                return null
            case undefined:
                return undefined
            default:
                return revision.id
        }
    }

    async navigateToRevision(revisionNumber: string | number) {
        await this.router.navigate(["..", revisionNumber], {relativeTo: this.route, queryParamsHandling: "merge"})
    }

    openPictureViewer(): void {
        void this.router.navigate(["view"], {relativeTo: this.route, queryParamsHandling: "merge"})
    }

    isLatestRevision(): boolean {
        return this.$picture()?.latestRevision?.id === this.$currentRevision()?.id
    }

    initDeleteRevision(revision: {id: string; number: number}): void {
        const dialogRef = this.mdDialog.open(DialogComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: "Delete revision",
                message:
                    "Revision " +
                    revision.number +
                    " including all the comments will be deleted. " +
                    "This action <strong>cannot be undone</strong>.<br><br>Are you sure you want to continue?",
                confirmLabel: "Delete revision",
                cancelLabel: "Cancel",
            },
        })

        dialogRef
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(async (confirmed) => {
                if (confirmed) {
                    await this.deleteRevision(revision)
                }
            })
    }

    async deleteRevision(revision: {id: string; number: number}) {
        return this.notifications.withUserFeedback(
            async () => {
                await mutateThrowingErrors(this.deletePictureRevisionGql)({id: revision.id})
                if (revision.number === 1) {
                    await this.navigateToRevision("new")
                } else {
                    await this.navigateToRevision(revision.number - 1)
                }
            },
            {error: "Cannot delete picture revision."},
        )
    }

    downloadFile = FilesService.downloadFile

    async updatePicture(data: Omit<MutationUpdatePictureInput, "id">) {
        const pictureId = this.$picture()?.id
        if (pictureId) {
            await this.notifications.withUserFeedback(
                () =>
                    mutateThrowingErrors(this.updatePictureGql)({
                        input: {
                            id: pictureId,
                            ...data,
                        },
                    }),
                {
                    success: "Picture updated.",
                    error: "Failed to update picture.",
                },
            )
            this.refresh.item(this.$picture())
        }
    }

    initClearDrawing(): void {
        const dialogRef = this.mdDialog.open(DialogComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: "Clear drawing",
                message: "The drawing is going to be deleted. " + "This action <strong>cannot be undone</strong>.<br><br>Are you sure you want to continue?",
                confirmLabel: "Clear drawing",
                cancelLabel: "Cancel",
                isDestructive: true,
            },
        })

        dialogRef
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((confirmed) => {
                if (confirmed) {
                    this.drawing.clear()
                }
            })
    }

    uploadFiles(files: File[]) {
        const pictureId = this.$picture()?.id
        if (!pictureId) {
            this.notifications.showError("Cannot upload picture without picture ID")
            return
        }
        if (!this.organizationId) {
            this.notifications.showError("Cannot upload picture without organization ID")
            return
        }
        if (!this.permission.$to().update.picture({organization: {id: this.organizationId}})) {
            this.notifications.showError("Insufficient permissions to upload to picture")
            return
        }
        this.uploadService.createAndUploadDataObject(files[0], {organizationId: this.organizationId}, {processUpload: true}).then((dataObject) => {
            return this.notifications.withUserFeedback(
                async () => {
                    const {createPictureRevision: pictureRevision} = await mutateThrowingErrors(this.createRevisionGql)({
                        input: {
                            pictureId,
                        },
                    })
                    await mutateThrowingErrors(this.createDataObjectAssignmentGql)({
                        input: {
                            type: DataObjectAssignmentType.PictureData,
                            dataObjectId: dataObject.id,
                            objectId: pictureRevision.id,
                            contentTypeModel: ContentTypeModel.PictureRevision,
                        },
                    })
                    this.navigateToRevision(pictureRevision.number)
                    await this.thumbnails.waitUntilAvailable(dataObject.id)
                    this.refresh.item(this.$picture())
                    this.$currentRevision.set(pictureRevision)
                },
                {
                    error: "Cannot create picture revision",
                },
            )
        })
    }

    protected readonly Labels = Labels
    protected readonly Enums = Enums

    protected readonly MimeType = MimeType
}
