import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type OrganizationsWithScanningUrlQueryVariables = Types.Exact<{
  organizationIds: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type OrganizationsWithScanningUrlQuery = { __typename: 'Query', organizations: Array<{ __typename: 'Organization', id: string, legacyId: number, defaultScannerUrl?: string | null } | null> };

export const OrganizationsWithScanningUrlDocument = gql`
    query organizationsWithScanningUrl($organizationIds: [ID!]!) {
  organizations(filter: {id: {in: $organizationIds}}) {
    id
    legacyId
    defaultScannerUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationsWithScanningUrlGQL extends Apollo.Query<OrganizationsWithScanningUrlQuery, OrganizationsWithScanningUrlQueryVariables> {
    override document = OrganizationsWithScanningUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }