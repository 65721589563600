import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type HdriDetailsForHdriLightInspectorFragment = { __typename: 'Hdri', id: string, legacyId: number, name?: string | null };

export type GetHdriDetailsForHdriLightInspectorQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetHdriDetailsForHdriLightInspectorQuery = { __typename: 'Query', hdris: Array<{ __typename: 'Hdri', id: string, legacyId: number, name?: string | null } | null> };

export const HdriDetailsForHdriLightInspectorFragmentDoc = gql`
    fragment HDRIDetailsForHDRILightInspector on Hdri {
  id
  legacyId
  name
}
    `;
export const GetHdriDetailsForHdriLightInspectorDocument = gql`
    query getHDRIDetailsForHDRILightInspector {
  hdris(take: 9999, orderBy: [{key: name, direction: asc}]) {
    ...HDRIDetailsForHDRILightInspector
  }
}
    ${HdriDetailsForHdriLightInspectorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHdriDetailsForHdriLightInspectorGQL extends Apollo.Query<GetHdriDetailsForHdriLightInspectorQuery, GetHdriDetailsForHdriLightInspectorQueryVariables> {
    override document = GetHdriDetailsForHdriLightInspectorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }