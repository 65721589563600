import {ReifiedMeshData, BoundsData} from "#template-nodes/geometry-processing/mesh-data"
import {Matrix4, Vector3} from "@cm/math"
import {z} from "zod"

export type AABBData = [[number, number, number], [number, number, number]]
export const boundsEdgeDimension = z.enum(["x", "y", "z"] as const)
export type BoundsEdgeDimension = z.infer<typeof boundsEdgeDimension>
export const boundsEdgeNumber = z.union([z.literal(0), z.literal(1), z.literal(2), z.literal(3)])
export type BoundsEdgeNumber = z.infer<typeof boundsEdgeNumber>

export const boundsEdgeDirectionToIndex = (dir: BoundsEdgeDimension): number => {
    switch (dir) {
        case "x":
            return 0
        case "y":
            return 1
        case "z":
            return 2
    }
}

export const getBoundsEdge = (dir: BoundsEdgeDimension, number: BoundsEdgeNumber, bounds: BoundsData): AABBData => {
    const [min, max] = bounds.aabb
    const start: [number, number, number] = [0, 0, 0]
    const end: [number, number, number] = [0, 0, 0]

    switch (dir) {
        case "x":
            start[0] = min[0]
            end[0] = max[0]
            start[1] = number < 2 ? min[1] : max[1]
            end[1] = start[1]
            start[2] = number % 2 ? max[2] : min[2]
            end[2] = start[2]
            break
        case "y":
            start[0] = number < 2 ? min[0] : max[0]
            end[0] = start[0]
            start[1] = number % 2 ? max[1] : min[1]
            end[1] = start[1]
            start[2] = min[2]
            end[2] = max[2]
            break
        case "z":
            start[0] = number < 2 ? min[0] : max[0]
            end[0] = start[0]
            start[1] = min[1]
            end[1] = max[1]
            start[2] = number % 2 ? max[2] : min[2]
            end[2] = start[2]
            break
    }

    return [start, end]
}

export class CentroidAccumulator {
    private centroidSum: [number, number, number] = [0, 0, 0]
    private totalArea = 0

    constructor() {}

    accumulate(data: ReifiedMeshData | BoundsData, matrix?: Matrix4): void {
        if (!data) return
        let centroid: readonly [number, number, number, ...any[]] = data.centroid
        if (matrix) {
            centroid = matrix.multiplyVectorXYZW(centroid[0], centroid[1], centroid[2], 1)
        }
        for (let i = 0; i < 3; i++) this.centroidSum[i] += centroid[i] * data.surfaceArea
        this.totalArea += data.surfaceArea
    }

    finalize() {
        if (this.totalArea > 0) {
            for (let i = 0; i < 3; i++) this.centroidSum[i] *= 1 / this.totalArea
        }
        return {
            centroid: this.centroidSum,
            surfaceArea: this.totalArea,
        }
    }
}

export class AABBAccumulator {
    private minX = Infinity
    private minY = Infinity
    private minZ = Infinity
    private maxX = -Infinity
    private maxY = -Infinity
    private maxZ = -Infinity

    accumulatePoint(x: number, y: number, z: number, matrix?: Matrix4): void {
        if (matrix) {
            ;[x, y, z] = matrix.multiplyVectorXYZW(x, y, z, 1)
        }
        this.minX = Math.min(this.minX, x)
        this.minY = Math.min(this.minY, y)
        this.minZ = Math.min(this.minZ, z)
        this.maxX = Math.max(this.maxX, x)
        this.maxY = Math.max(this.maxY, y)
        this.maxZ = Math.max(this.maxZ, z)
    }

    accumulate(aabb: AABBData) {
        const [[minX, minY, minZ], [maxX, maxY, maxZ]] = aabb
        this.minX = Math.min(this.minX, minX)
        this.minY = Math.min(this.minY, minY)
        this.minZ = Math.min(this.minZ, minZ)
        this.maxX = Math.max(this.maxX, maxX)
        this.maxY = Math.max(this.maxY, maxY)
        this.maxZ = Math.max(this.maxZ, maxZ)
    }

    finalize(): AABBData {
        return [
            [this.minX, this.minY, this.minZ],
            [this.maxX, this.maxY, this.maxZ],
        ]
    }
}

export function transformBounds(data: BoundsData, matrix: Matrix4): BoundsData {
    const [cx, cy, cz] = matrix.multiplyVectorXYZW(...data.centroid, 1)
    const [[minX, minY, minZ], [maxX, maxY, maxZ]] = data.aabb
    const aabb = new AABBAccumulator()
    aabb.accumulatePoint(minX, minY, minZ, matrix)
    aabb.accumulatePoint(maxX, minY, minZ, matrix)
    aabb.accumulatePoint(minX, maxY, minZ, matrix)
    aabb.accumulatePoint(maxX, maxY, minZ, matrix)
    aabb.accumulatePoint(minX, minY, maxZ, matrix)
    aabb.accumulatePoint(maxX, minY, maxZ, matrix)
    aabb.accumulatePoint(minX, maxY, maxZ, matrix)
    aabb.accumulatePoint(maxX, maxY, maxZ, matrix)
    return {
        centroid: [cx, cy, cz],
        surfaceArea: data.surfaceArea,
        aabb: aabb.finalize(),
        radii: {xy: 0, xz: 0, yz: 0, xyz: 0}, //TODO: transform bounding radii
    }
}

export class BoundsAccumulator {
    private acc = new CentroidAccumulator()
    private aabb = new AABBAccumulator()
    private count = 0

    constructor() {}

    accumulate(data: BoundsData, matrix?: Matrix4): void {
        if (!data) return
        ++this.count
        if (matrix) {
            data = transformBounds(data, matrix)
        }
        this.acc.accumulate(data)
        this.aabb.accumulate(data.aabb)
        //TODO: merge radii
    }

    finalize(): BoundsData {
        if (this.count === 0) {
            return {
                centroid: [0, 0, 0],
                surfaceArea: 0,
                aabb: [
                    [0, 0, 0],
                    [0, 0, 0],
                ],
                radii: {xy: 0, xz: 0, yz: 0, xyz: 0},
            }
        } else {
            return {
                ...this.acc.finalize(),
                aabb: this.aabb.finalize(),
                radii: {xy: 0, xz: 0, yz: 0, xyz: 0},
            }
        }
    }
}

export function mergeBounds(boundsList: BoundsData[]): BoundsData {
    const acc = new BoundsAccumulator()
    for (const bounds of boundsList) {
        acc.accumulate(bounds)
    }
    return acc.finalize()
}
