import {Component, computed, forwardRef, input, output} from "@angular/core"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MaterialAssignment, MaterialAssignmentParameters, MaterialReference} from "@cm/template-nodes"
import {BaseInspectorComponent} from "@template-editor/components/inspectors/base-inspector/base-inspector.component"
import {SelectionPossibilities, ValueSlotComponent} from "app/template-editor/components/value-slot/value-slot.component"
import {MaterialInspectorComponent} from "../material-inspector/material-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"

@Component({
    selector: "cm-material-assignment-inspector",
    templateUrl: "./material-assignment-inspector.component.html",
    styleUrl: "./material-assignment-inspector.component.scss",
    imports: [MatMenuModule, MatTooltipModule, MaterialInspectorComponent, InspectorSectionComponent, forwardRef(() => ValueSlotComponent)],
})
export class MaterialAssignmentInspectorComponent extends BaseInspectorComponent<MaterialAssignment | null> {
    readonly $label = input<string | undefined>(undefined, {alias: "label"})
    readonly $labelText = computed(() => this.$label() ?? "Material Assignment")
    readonly $highlighted = input(false, {alias: "highlighted"})
    requestUpdateMaterialAssignment = output<MaterialAssignment | null>()

    readonly $material = computed(() => {
        const parameters = this.$parameters()
        if (!parameters) return null
        return parameters.node
    })

    readonly $sideLabel = computed(() => {
        const parameters = this.$parameters()
        if (!parameters) return undefined
        return this.sidePossibilities.find((x) => x.value === parameters.side)?.name ?? "Not set"
    })

    onMaterialUpdated(materialReference: MaterialReference | null) {
        if (!materialReference) return this.requestUpdateMaterialAssignment.emit(null)

        const materialAssignment = this.$node()

        if (materialAssignment)
            return this.requestUpdateMaterialAssignment.emit(
                materialAssignment.clone({cloneSubNode: () => true, parameterOverrides: {node: materialReference}}),
            )
        else return this.requestUpdateMaterialAssignment.emit(new MaterialAssignment({node: materialReference, side: "front"}))
    }

    sidePossibilities: SelectionPossibilities<MaterialAssignmentParameters["side"]> = [
        {value: "front", name: "Front"},
        {value: "back", name: "Back"},
        {value: "double", name: "Double"},
    ]
}
