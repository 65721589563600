import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TagAssignmentsItemFragment = { __typename: 'TagAssignment', id: string, legacyId: number, contentTypeModel: Types.ContentTypeModel, assignedTo?: { __typename: 'Asset', id: string, legacyId: number, name?: string | null } | { __typename: 'Hdri', id: string, legacyId: number, name?: string | null } | { __typename: 'Material', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | { __typename: 'Model', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | { __typename: 'Picture', id: string, legacyId: number, altName: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | { __typename: 'Project', id: string, legacyId: number, altName: string } | { __typename: 'Set', id: string, legacyId: number, altName: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | { __typename: 'Template', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null };

export type TagAssignmentsLoadPageQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.TagAssignmentFilterInput>;
  take: Types.Scalars['Int']['input'];
  skip: Types.Scalars['Int']['input'];
}>;


export type TagAssignmentsLoadPageQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'TagAssignment', id: string, legacyId: number, contentTypeModel: Types.ContentTypeModel, assignedTo?: { __typename: 'Asset', id: string, legacyId: number, name?: string | null } | { __typename: 'Hdri', id: string, legacyId: number, name?: string | null } | { __typename: 'Material', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | { __typename: 'Model', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | { __typename: 'Picture', id: string, legacyId: number, altName: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | { __typename: 'Project', id: string, legacyId: number, altName: string } | { __typename: 'Set', id: string, legacyId: number, altName: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | { __typename: 'Template', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export type TagAssignmentsDeleteAssignmentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type TagAssignmentsDeleteAssignmentMutation = { __typename: 'Mutation', deleteTagAssignment?: any | null };

export const TagAssignmentsItemFragmentDoc = gql`
    fragment TagAssignmentsItem on TagAssignment {
  id
  legacyId
  contentTypeModel
  assignedTo {
    __typename
    ... on Asset {
      id
      legacyId
      name
    }
    ... on Hdri {
      id
      legacyId
      name
    }
    ... on Material {
      id
      legacyId
      name
      galleryImage {
        id
      }
    }
    ... on Model {
      id
      legacyId
      name
      galleryImage {
        id
      }
    }
    ... on Picture {
      id
      legacyId
      altName: name
      galleryImage {
        id
      }
    }
    ... on Project {
      id
      legacyId
      altName: name
    }
    ... on Set {
      id
      legacyId
      altName: name
      galleryImage {
        id
      }
    }
    ... on Template {
      id
      legacyId
      name
      galleryImage {
        id
      }
    }
  }
}
    `;
export const TagAssignmentsLoadPageDocument = gql`
    query tagAssignmentsLoadPage($filter: TagAssignmentFilterInput, $take: Int!, $skip: Int!) {
  items: tagAssignments(filter: $filter, take: $take, skip: $skip) {
    ...TagAssignmentsItem
  }
  totalCount: tagAssignmentsCount(filter: $filter)
}
    ${TagAssignmentsItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TagAssignmentsLoadPageGQL extends Apollo.Query<TagAssignmentsLoadPageQuery, TagAssignmentsLoadPageQueryVariables> {
    override document = TagAssignmentsLoadPageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TagAssignmentsDeleteAssignmentDocument = gql`
    mutation tagAssignmentsDeleteAssignment($id: ID!) {
  deleteTagAssignment(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TagAssignmentsDeleteAssignmentGQL extends Apollo.Mutation<TagAssignmentsDeleteAssignmentMutation, TagAssignmentsDeleteAssignmentMutationVariables> {
    override document = TagAssignmentsDeleteAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }