import {ISceneManager} from "#template-nodes/interfaces/scene-manager"
import {Inlet, NotReady, Outlet} from "#template-nodes/runtime-graph/slots"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {IDataObject} from "@cm/material-nodes/interfaces/data-object"
import {defer, map, Subscription} from "rxjs"

const TD = TypeDescriptors

const loadDataDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManager>()),
    dataObject: TD.inlet(TD.DataObject),
    data: TD.outlet(TD.Identity<Uint8Array>()),
}

export class LoadData implements NodeClassImpl<typeof loadDataDescriptor, typeof LoadData> {
    static descriptor = loadDataDescriptor
    static uniqueName = "LoadData"
    sceneManager!: Inlet<ISceneManager>
    dataObject!: Inlet<IDataObject>
    data!: Outlet<Uint8Array>

    private pending: Subscription | null = null

    run() {
        this.data.emitIfChanged(NotReady)
        this.pending?.unsubscribe()

        if (this.sceneManager === NotReady || this.dataObject === NotReady) {
            return
        }

        const {dataObject, sceneManager} = this

        this.pending = sceneManager.addTask({
            description: `loadData(${dataObject.legacyId})`,
            task: defer(() =>
                (async () => {
                    const response = await fetch(dataObject.downloadUrl)
                    if (!response.ok) {
                        throw new Error(`Failed to download data: ${response.statusText}`)
                    }
                    const buffer = await response.arrayBuffer()
                    return new Uint8Array(buffer)
                })(),
            ).pipe(
                map((data) => {
                    this.pending = null
                    this.data.emitIfChanged(data)
                }),
            ),
            critical: true,
        })
    }

    cleanup() {
        this.pending?.unsubscribe()
    }
}
