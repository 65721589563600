import {CommonModule, NgClass, NgTemplateOutlet} from "@angular/common"
import {AfterContentInit, Component, HostListener, Input, input, output} from "@angular/core"
import {MatMenuModule, MatMenuPanel} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ActionPanelComponent} from "@common/components/menu/actions/action-panel/action-panel.component"

@Component({
    selector: "cm-action-item",
    templateUrl: "./action-item.component.html",
    styleUrls: ["./action-item.component.scss"],
    imports: [CommonModule, MatMenuModule, NgClass, MatTooltipModule, NgTemplateOutlet],
})
export class ActionItemComponent implements AfterContentInit {
    readonly $cmIconClass = input<string>(undefined, {alias: "cmIconClass"})
    readonly $tooltip = input<string>(undefined, {alias: "tooltip"})
    readonly $panel = input<ActionPanelComponent>(undefined, {alias: "panel"})
    readonly $disabled = input(false, {alias: "disabled"})
    readonly $showPanelByDefault = input(false, {alias: "showPanelByDefault"})
    readonly $menuTriggerFor = input<MatMenuPanel | null>(
        null, // use this instead of matMenuTriggerFor to trigger menus which respect the item's disabled state
        {alias: "menuTriggerFor"},
    ) // use this instead of matMenuTriggerFor to trigger menus which respect the item's disabled state

    readonly activeChange = output<boolean>()

    private _active = false
    get active(): boolean {
        return this._active
    }

    @Input() set active(value: boolean) {
        this._active = value
        if (value) this.$panel()?.show()
        else this.$panel()?.hide()
        this.activeChange.emit(value)
    }

    readonly itemClick = output<void>()

    @HostListener("click", ["$event"]) onClick() {
        if (this.$disabled()) return
        if (this.$panel()) {
            this.active = !this.active
        }
        this.itemClick.emit()
    }

    ngAfterContentInit() {
        if (this.$showPanelByDefault()) {
            this.active = true
        }
    }
}
