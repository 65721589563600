import {Component, inject, input} from "@angular/core"
import {RouterLink} from "@angular/router"
import {AssetBoxesFragment} from "@app/platform/components/assets/asset-boxes/asset-boxes.generated"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {DownloadResolution} from "@generated"
import {Labels} from "@labels"
import {AssetBoxesGQL} from "@platform/components/assets/asset-boxes/asset-boxes.generated"

@Component({
    selector: "cm-asset-boxes",
    imports: [RouterLink, DataObjectThumbnailComponent],
    templateUrl: "./asset-boxes.component.html",
    styleUrl: "./asset-boxes.component.scss",
})
export class AssetBoxesComponent {
    readonly $assetId = input.required<string>({alias: "assetId"})

    asset?: AssetBoxesFragment

    readonly assetBoxes = inject(AssetBoxesGQL)

    protected readonly Labels = Labels

    ngOnInit() {
        fetchThrowingErrors(this.assetBoxes)({assetId: this.$assetId()}).then(({asset}) => {
            this.asset = asset
        })
    }

    protected readonly DownloadResolution = DownloadResolution
}
