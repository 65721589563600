import {Component, OnInit, Input, input, model, output} from "@angular/core"

@Component({
    selector: "cm-numeric-input",
    templateUrl: "./numeric-input.component.html",
    styleUrls: ["./numeric-input.component.scss"],
})
export class NumericInputComponent implements OnInit {
    readonly $value = input<number>(undefined, {alias: "value"})
    readonly $decimalPlaces = input<number>(undefined, {alias: "decimalPlaces"})
    readonly $type = input<"int" | "float">("float", {alias: "type"})
    readonly $emptyValue = model<null | undefined | number | "disallow">("disallow", {alias: "emptyValue"})
    readonly $convert = input<{
        toDisplay: (x: number) => number
        fromDisplay: (x: number) => number
    }>(undefined, {alias: "convert"})
    readonly valueChange = output<number | null | undefined>()
    readonly $placeholder = input<string>(undefined, {alias: "placeholder"})
    $readonly = input(false, {alias: "readonly"})
    readonly $isButton = input(false, {alias: "isButton"})

    private editing = false
    private editString: string | null = null

    constructor() {}

    @Input() set optional(x: boolean) {
        this.$emptyValue.set(x ? undefined : "disallow")
    }

    set displayString(valueStr: string) {
        if (this.editing) {
            this.editString = valueStr
        }
        if (valueStr == null) {
            const emptyValue = this.$emptyValue()
            if (emptyValue === null) {
                this.valueChange.emit(null)
            } else if (emptyValue === undefined) {
                this.valueChange.emit(undefined)
            } else if (typeof emptyValue === "number") {
                this.valueChange.emit(emptyValue)
            } else {
                return // not allowed
            }
        }
        let value = this.$type() === "int" ? parseInt(valueStr) : parseFloat(valueStr)
        if (value == null || isNaN(value)) return
        const convert = this.$convert()
        if (convert) value = convert.fromDisplay(value)
        if (value == null || isNaN(value)) return
        this.valueChange.emit(value)
    }

    get displayString(): string | null {
        if (this.editing) {
            return this.editString
        }
        let value = this.$value() ?? 0
        const convert = this.$convert()
        const places = this.$type() === "int" ? 0 : this.$decimalPlaces()
        if (convert) value = convert.toDisplay(value)
        if (this.$value() == null) return null
        if (places != null) {
            return Number(value).toFixed(places)
        } else {
            return value.toString()
        }
    }

    onFocus() {
        this.editing = false
        this.editString = this.displayString
        this.editing = true
    }

    onBlur() {
        this.editing = false
        this.editString = null
    }

    ngOnInit(): void {}
}
