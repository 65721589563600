<div class="container">
    @switch ($icon()) {
        @case ("search") {
            <i class="icon far fa-magnifying-glass"></i>
        }
        @case ("error") {
            <i class="icon far fa-circle-exclamation"></i>
        }
        @case ("no-files") {
            <i class="icon far fa-file-slash"></i>
        }
        @case ("google") {
            <i class="icon fa-brands fa-google"></i>
        }
        @case ("microsoft") {
            <i class="icon fa-brands fa-microsoft"></i>
        }
        @case ("id") {
            <i class="icon far fa-id-badge"></i>
        }
    }
    <div class="text">{{ $text() }}</div>
</div>
