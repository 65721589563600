import {DataType, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {Box2Like, Size2Like, Vector2Like} from "@cm/math"
import {createGaussianPyramid} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/create-gaussian-pyramid"
import {InterpolationType} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-resize"
import {math} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-math"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {getMostPreciseDataType} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"
import {upSample} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/up-sample"
import {ReturnType as ImagePyramidReturnType} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/create-image-pyramid"
import {OddPixelStrategy} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/down-sample"
import {BorderMode} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-convolve"

export const SCOPE_NAME = "CreateLaplacianPyramid"

export type ParameterType = {
    sourceImage: ImageRef
    sourceRegion?: Box2Like // default: {x: 0, y: 0, width: sourceImage.width, height: sourceImage.height}
    sigma: Vector2Like | number // sigma for the gaussian blur
    oddPixelStrategy?: OddPixelStrategy // default: "nearest"
    borderMode?: BorderMode // default: "renormalize"
    maxLevels?: number // defaults to full pyramid down to 1x1
    minResolution?: Size2Like // defaults to 1x1
    upSamplingInterpolation?: InterpolationType // default: "cubic"
    resultDataType?: DataType // default: sourceImage.dataType
}

export type ReturnType = {
    gaussianPyramid: ImagePyramidReturnType
    upSampledGaussianPyramid: ImagePyramidReturnType
    laplacianPyramid: ImagePyramidReturnType
}

export const createLaplacianPyramid = (
    cmdQueue: ImageOpCommandQueue,
    {sourceImage, sourceRegion, sigma, oddPixelStrategy, borderMode, maxLevels, minResolution, upSamplingInterpolation, resultDataType}: ParameterType,
): ReturnType => {
    cmdQueue.beginScope(SCOPE_NAME)

    upSamplingInterpolation ??= "cubic"
    const gaussianPyramid = createGaussianPyramid(cmdQueue, {
        sourceImage,
        sourceRegion,
        sigma,
        oddPixelStrategy,
        borderMode,
        maxLevels,
        minResolution,
        resultDataType,
    })

    const upSampledGaussianPyramid: ImagePyramidReturnType = {
        descriptor: {
            width: sourceImage.descriptor.width,
            height: sourceImage.descriptor.height,
            levels: gaussianPyramid.descriptor.levels - 1,
        },
        resultImages: [],
    }
    for (let i = 0; i < upSampledGaussianPyramid.descriptor.levels; i++) {
        const image = gaussianPyramid.resultImages[i]
        const coarserImage = gaussianPyramid.resultImages[i + 1]
        const upScaledImage = upSample(cmdQueue, {
            sourceImage: coarserImage,
            higherLevelSize: {
                width: image.descriptor.width,
                height: image.descriptor.height,
            },
            interpolation: upSamplingInterpolation,
            oddPixelStrategy,
        })
        upSampledGaussianPyramid.resultImages.push(upScaledImage)
    }

    resultDataType ??= getMostPreciseDataType(sourceImage.descriptor.dataType, "float16")
    if (resultDataType === "uint8") {
        console.warn("createLaplacianPyramid: resultDataType is uint8, which causes clamping of negative values and precision loss")
    }

    const laplacianPyramid: ImagePyramidReturnType = {
        descriptor: {
            width: sourceImage.descriptor.width,
            height: sourceImage.descriptor.height,
            levels: upSampledGaussianPyramid.descriptor.levels,
        },
        resultImages: [],
    }
    for (let i = 0; i < laplacianPyramid.descriptor.levels; i++) {
        const image = gaussianPyramid.resultImages[i]
        const upScaledCoarserImage = upSampledGaussianPyramid.resultImages[i]
        const difference = math(cmdQueue, {
            operator: "-",
            operandA: image,
            operandB: upScaledCoarserImage,
            resultImageOrDataType: resultDataType,
        })
        laplacianPyramid.resultImages.push(difference)
    }

    cmdQueue.endScope(SCOPE_NAME)
    return {gaussianPyramid, upSampledGaussianPyramid, laplacianPyramid}
}
