import {Directive, TemplateRef, input} from "@angular/core"
import {MatTable} from "@angular/material/table"

@Directive({selector: "[matCellDef]"})
export class TypedMatCellDefDirective<T> {
    readonly matCellDefTable = input.required<MatTable<T>>()

    constructor(private contentTemplate: TemplateRef<T>) {}

    static ngTemplateContextGuard<T>(dir: TypedMatCellDefDirective<T>, ctx: unknown): ctx is {$implicit: T} {
        return true
    }
}
