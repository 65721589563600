import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { BasicTagInfoFragmentDoc } from '../../../../common/services/tags/tags.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ScanningActionBarTagFragment = { __typename: 'Tag', description?: string | null, id: string, legacyId: number, color?: string | null, name: string, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null };

export type ScanningActionBarTagQueryVariables = Types.Exact<{
  filter: Types.TagFilterInput;
}>;


export type ScanningActionBarTagQuery = { __typename: 'Query', tags: Array<{ __typename: 'Tag', description?: string | null, id: string, legacyId: number, color?: string | null, name: string, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } | null> };

export type MaterialWithRangeTagsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MaterialWithRangeTagsQuery = { __typename: 'Query', material: { __typename: 'Material', tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', description?: string | null, id: string, legacyId: number, color?: string | null, name: string, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } }> } };

export const ScanningActionBarTagFragmentDoc = gql`
    fragment ScanningActionBarTag on Tag {
  ...BasicTagInfo
  description
}
    `;
export const ScanningActionBarTagDocument = gql`
    query scanningActionBarTag($filter: TagFilterInput!) {
  tags(filter: $filter, take: 1) {
    ...ScanningActionBarTag
  }
}
    ${ScanningActionBarTagFragmentDoc}
${BasicTagInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScanningActionBarTagGQL extends Apollo.Query<ScanningActionBarTagQuery, ScanningActionBarTagQueryVariables> {
    override document = ScanningActionBarTagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialWithRangeTagsDocument = gql`
    query materialWithRangeTags($id: ID!) {
  material(id: $id) {
    tagAssignments(tagTypes: [MaterialRange]) {
      tag {
        ...ScanningActionBarTag
      }
    }
  }
}
    ${ScanningActionBarTagFragmentDoc}
${BasicTagInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialWithRangeTagsGQL extends Apollo.Query<MaterialWithRangeTagsQuery, MaterialWithRangeTagsQueryVariables> {
    override document = MaterialWithRangeTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }