import {Component, DestroyRef, effect, inject, input, OnInit, output, viewChild} from "@angular/core"
import {CanvasBaseComponent} from "../../../common/components/canvas/canvas-base/canvas-base.component"
import {outputToObservable, takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {Hotkeys} from "@app/common/services/hotkeys/hotkeys.service"
import {PostProcessedImageData} from "../template-image-viewer/template-image-viewer.component"

@Component({
    selector: "cm-template-image-viewer-canvas",
    templateUrl: "./template-image-viewer-canvas.component.html",
    styleUrl: "./template-image-viewer-canvas.component.scss",
    imports: [CanvasBaseComponent],
})
export class TemplateImageViewerCanvasComponent implements OnInit {
    private readonly hotkeys = inject(Hotkeys)
    private readonly destroyRef = inject(DestroyRef)

    readonly $postProcessedImageData = input.required<PostProcessedImageData>({alias: "postProcessedImageData"})

    readonly $canvasBase = viewChild.required<CanvasBaseComponent>("canvasBase")
    readonly loadingComplete = output<void>()

    constructor() {
        effect(() => {
            const canvasBase = this.$canvasBase()
            const postProcessedData = this.$postProcessedImageData()

            if (postProcessedData.type === "cached") {
                const imageUrl = postProcessedData.dataObject.thumbnail?.downloadUrl
                if (!imageUrl) throw new Error("No download URL found")
                canvasBase.imageUrl = imageUrl
            } else canvasBase.imageData = postProcessedData.imageData
            canvasBase.requestRedraw()

            outputToObservable(canvasBase.loadingComplete)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(() => {
                    canvasBase.navigation.zoomToFitImage()

                    this.loadingComplete.emit()
                })
        })
    }

    ngOnInit() {
        this.hotkeys
            .addShortcut("Space")
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                const canvasBase = this.$canvasBase()

                const zoomLevel: number = canvasBase.navigation.getPhysicalZoomLevel()
                // This is needed because of the rounding errors, since the zoom level at 100% is often 0.99999999 instead of 1.
                const epsilon: number = Math.abs(zoomLevel - 1)
                if (epsilon < 0.01) {
                    canvasBase.navigation.zoomToFitImage()
                } else {
                    canvasBase.navigation.physicalZoomTo(1)
                }
            })
    }
}
