import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetTemplateDetailsForTemplateViewerQueryVariables = Types.Exact<{
  pictureId: Types.Scalars['ID']['input'];
}>;


export type GetTemplateDetailsForTemplateViewerQuery = { __typename: 'Query', picture: { __typename: 'Picture', template?: { __typename: 'Template', id: string } | null } };

export const GetTemplateDetailsForTemplateViewerDocument = gql`
    query getTemplateDetailsForTemplateViewer($pictureId: ID!) {
  picture(id: $pictureId) {
    template {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateDetailsForTemplateViewerGQL extends Apollo.Query<GetTemplateDetailsForTemplateViewerQuery, GetTemplateDetailsForTemplateViewerQueryVariables> {
    override document = GetTemplateDetailsForTemplateViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }