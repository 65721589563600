import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {threeConvert, threeValueNode} from "#material-nodes/three-utils"
import {vec3} from "#material-nodes/types"
import {getAll} from "@cm/graph"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({normal: materialSlots.optional()})
const InputTypeSchema = z.object({
    height: materialSlots.optional(),
    distance: materialSlots.optional(),
    strength: materialSlots.optional(),
    normal: materialSlots.optional(),
})
const ParametersTypeSchema = z.object({
    distance: z.number().optional(),
    invert: z.boolean().optional(),
    normal: vec3.optional(),
    strength: z.number().optional(),
})

export class Bump extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {height, strength} = await getAll(inputs, get)
            const heightValue = height ?? threeValueNode(0)
            const strengthValue = strength ?? threeConvert(parameters.strength, threeValueNode) ?? threeValueNode(1.0)

            if (!(heightValue instanceof THREENodes.TextureNode)) {
                console.warn("Bump node cannot use non-texture node as input")
                return {normal: undefined}
            }

            //@ts-expect-error TODO: fix this
            return {normal: THREENodes.bumpMap(heightValue, strengthValue)}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
