import {MeshNodes} from "@cm/render-nodes" //TODO: move these into ts-lib/geometry-processing?

type OpExpr = MeshNodes.GeometryExpr
type Expr = number | string | OpExpr | Expr[]

type ValueType = "float" | "float2" | "float3" | "uint32"

export class GeomBuilderContext {
    private _exprCache = new Map<string, OpExpr>()
    private _objIds = new Map<unknown, number>()

    constructor() {}

    makeOpExpr(op: string, ...args: Expr[]): OpExpr {
        let key = op
        for (const arg of args) {
            let id = this._objIds.get(arg)
            if (id === undefined) {
                id = this._objIds.size
                this._objIds.set(arg, id)
            }
            key += ` ${id}`
        }
        let expr = this._exprCache.get(key)
        if (!expr) {
            expr = {op, args}
            this._exprCache.set(key, expr)
            const id = this._objIds.size
            this._objIds.set(expr, id)
            // console.log(`New expr ${id}:`, key)
        }
        return expr
    }

    makeAttributeRef(expr: OpExpr, type: ValueType | null) {
        if (type === null) throw new Error("Invalid attribute type")
        return new AttributeRef(this, expr, type)
    }

    list(elems: Expr[]): OpExpr {
        return this.makeOpExpr("list", ...elems)
    }

    tuple(...elems: Expr[]): OpExpr {
        return this.makeOpExpr("list", ...elems)
    }

    get(idx: number, expr: Expr): OpExpr {
        return this.makeOpExpr("get", idx, expr)
    }

    first(expr: Expr): OpExpr {
        return this.makeOpExpr("first", expr)
    }

    second(expr: Expr): OpExpr {
        return this.makeOpExpr("second", expr)
    }
}

function elemType(type: ValueType): ValueType | null {
    switch (type) {
        case "float":
            return null
        case "float2":
            return "float"
        case "float3":
            return "float"
        case "uint32":
            return null
    }
}

function isFloatType(type: ValueType): boolean {
    switch (type) {
        case "float":
        case "float2":
        case "float3":
            return true
        default:
            return false
    }
}

function unifyTypes(typeA: ValueType, typeB: ValueType, _flip = true): ValueType {
    if (typeA === typeB) return typeA
    else if (typeA === "float3" && typeB == "float") return "float3"
    else if (typeA === "float2" && typeB == "float") return "float2"
    else if (typeA === "float3" && typeB == "uint32") return "float3"
    else if (typeA === "float2" && typeB == "uint32") return "float2"
    else if (typeA === "float" && typeB == "uint32") return "float"
    else if (_flip) return unifyTypes(typeB, typeA, false)
    else throw new Error(`Cannot unify types: ${typeA} <=> ${typeB}`)
}

function isVectorLiteral(x: any): x is readonly [number, number] | readonly [number, number, number] {
    if (Array.isArray(x)) {
        if (x.length === 2 || x.length === 3) {
            return true
        } else {
            throw new Error("Invalid vector literal")
        }
    }
    return false
}

function isValueLiteral(x: ExprRef): x is ValueLiteral {
    return typeof x === "number" || isVectorLiteral(x)
}

function isAttributeRef(x: ExprRef): x is AttributeRef {
    return x instanceof AttributeRef
}

function isValueRef(x: ExprRef): x is ValueRef {
    return x instanceof ValueRef
}

function promoteType(x: ExprRef, type: ValueType): ExprRef {
    if (isValueLiteral(x)) {
        if (typeof x === "number") {
            switch (type) {
                case "float":
                case "uint32":
                    return x
                case "float2":
                    return [x, x]
                case "float3":
                    return [x, x, x]
                default:
                    throw new Error("Invalid type promotion")
            }
        } else if (x.length === 2) {
            switch (type) {
                case "float2":
                    return x
                default:
                    throw new Error("Invalid type promotion")
            }
        } else if (x.length === 3) {
            switch (type) {
                case "float3":
                    return x
                default:
                    throw new Error("Invalid type promotion")
            }
        } else {
            throw new Error("Invalid vector literal")
        }
    } else {
        return x.promote(type)
    }
}

function getContext(x: ExprRef): GeomBuilderContext | undefined {
    if (isAttributeRef(x) || isValueRef(x)) {
        return x._ctx
    } else {
        return undefined
    }
}

function unifyAndPromote(a: ExprRef, b: ExprRef): [GeomBuilderContext, ExprRef, ExprRef] {
    let ctx = getContext(a) ?? getContext(b)
    if (!ctx) throw new Error("No context found")
    if (isAttributeRef(a)) {
        ctx = a._ctx
        if (!isAttributeRef(b)) {
            b = a.constLike(b)
        }
    } else if (isAttributeRef(b)) {
        ctx = b._ctx
        a = b.constLike(a)
    }
    const type = unifyTypes(getArgType(a), getArgType(b))
    return [ctx, promoteType(a, type), promoteType(b, type)]
}

function getArgType(x: ExprRef): ValueType {
    if (isValueLiteral(x)) {
        throw new Error("Ambiguous value type")
        // if (typeof x === "number") {
        //     return "float"
        // } else {
        //     return `float${x.length}` as ValueType
        // }
    } else {
        return x._type
    }
}

function getArgExpr(x: ExprRef): Expr {
    if (isValueLiteral(x)) {
        return x as Expr
    } else {
        return x._expr
    }
}

function unaryOp(opName: string, arg: AttributeRef, newType?: ValueType) {
    const ctx = arg._ctx
    return ctx.makeAttributeRef(ctx.makeOpExpr(opName, arg._expr), newType !== undefined ? newType : arg._type)
}

function binaryOp(opName: string, scalarOpName: string | null, arg1: ExprRef, arg2: ExprRef, newType?: ValueType) {
    if (typeof arg2 === "number" && scalarOpName) {
        if (isValueLiteral(arg1)) {
            throw new Error("Cannot apply scalar operation to literal")
        }
        const ctx = arg1._ctx
        return ctx.makeAttributeRef(ctx.makeOpExpr(scalarOpName, getArgExpr(arg1), getArgExpr(arg2)), newType !== undefined ? newType : getArgType(arg1))
    } else {
        let ctx: GeomBuilderContext
        ;[ctx, arg1, arg2] = unifyAndPromote(arg1, arg2)
        return ctx.makeAttributeRef(ctx.makeOpExpr(opName, getArgExpr(arg1), getArgExpr(arg2)), newType !== undefined ? newType : getArgType(arg1))
    }
}

export type ValueLiteral = number | readonly [number, number] | readonly [number, number, number]

export type ExprRef = AttributeRef | ValueLiteral | ValueRef

export class ValueRef {
    constructor(
        readonly _ctx: GeomBuilderContext,
        readonly _expr: Expr,
        public _type: ValueType,
    ) {}

    promote(type: ValueType): ValueRef {
        if (this._type === type) return this
        else if (this._type === "float" && type == "uint32") return new ValueRef(this._ctx, this._expr, "uint32")
        else if (this._type === "uint32" && type == "float") return new ValueRef(this._ctx, this._expr, "float")
        else throw new Error(`Cannot promote value type ${this._type} to ${type}`)
    }
}

export class AttributeRef {
    constructor(
        readonly _ctx: GeomBuilderContext,
        readonly _expr: Expr,
        public _type: ValueType,
    ) {}

    getContext() {
        return this._ctx
    }

    get(idx: number): AttributeRef {
        if (idx === 0) {
            if (this._type === "float2" || this._type === "float3") {
                return this._ctx.makeAttributeRef(this._ctx.makeOpExpr("get0", this._expr), elemType(this._type))
            } else {
                return this
            }
        } else if (idx === 1) {
            if (this._type === "float2" || this._type === "float3") {
                return this._ctx.makeAttributeRef(this._ctx.makeOpExpr("get1", this._expr), elemType(this._type))
            }
        } else if (idx === 2) {
            if (this._type === "float3") {
                return this._ctx.makeAttributeRef(this._ctx.makeOpExpr("get2", this._expr), elemType(this._type))
            }
        }
        throw new Error(`Invalid get() index ${idx} for type ${this._type}`)
    }

    get x() {
        return this.get(0)
    }
    get y() {
        return this.get(1)
    }
    get z() {
        return this.get(2)
    }
    get xy() {
        return Operators.pack(this.x, this.y)
    }
    get xz() {
        return Operators.pack(this.x, this.z)
    }
    get yz() {
        return Operators.pack(this.y, this.z)
    }
    get yx() {
        return Operators.pack(this.y, this.x)
    }
    get zx() {
        return Operators.pack(this.z, this.x)
    }
    get zy() {
        return Operators.pack(this.z, this.y)
    }
    add(x: ExprRef) {
        return binaryOp("add", null, this, x)
    }
    sub(x: ExprRef) {
        return binaryOp("sub", null, this, x)
    }
    mul(x: ExprRef) {
        return binaryOp("mul", "muls", this, x)
    }
    div(x: ExprRef) {
        return binaryOp("div", null, this, x)
    }
    mod(x: ExprRef) {
        return binaryOp("mod", null, this, x)
    }
    gt(x: ExprRef) {
        return binaryOp("gt", null, this, x, "uint32")
    }
    lt(x: ExprRef) {
        return binaryOp("lt", null, this, x, "uint32")
    }
    eq(x: ExprRef) {
        return binaryOp("eq", null, this, x, "uint32")
    }
    ne(x: ExprRef) {
        return binaryOp("ne", null, this, x, "uint32")
    }
    lte(x: ExprRef) {
        return binaryOp("lte", null, this, x, "uint32")
    }
    and(x: ExprRef) {
        return binaryOp("and", null, this, x, "uint32")
    }
    or(x: ExprRef) {
        return binaryOp("or", null, this, x, "uint32")
    }
    gte(x: ExprRef) {
        return binaryOp("gte", null, this, x, "uint32")
    }
    not() {
        return unaryOp("not", this, "uint32")
    }
    sin() {
        return unaryOp("sin", this)
    }
    cos() {
        return unaryOp("cos", this)
    }
    norm() {
        return unaryOp("norm", this, elemType(this._type) ?? undefined)
    }
    normalize() {
        return unaryOp("normalize", this)
    }
    sqrt() {
        return unaryOp("sqrt", this)
    }
    recip() {
        return unaryOp("recip", this)
    }
    recipSqrt() {
        return unaryOp("recipSqrt", this)
    }
    exp() {
        return unaryOp("exp", this)
    }
    log() {
        return unaryOp("log", this)
    }
    neg() {
        return unaryOp("neg", this)
    }

    pack2() {
        return Operators.pack(this, this)
    }
    pack3() {
        return Operators.pack(this, this, this)
    }

    unpack2() {
        return [this.x, this.y] as const
    }
    unpack3() {
        return [this.x, this.y, this.z] as const
    }

    permute(...idxs: number[]) {
        if (idxs.length === 1) {
            return this.get(idxs[0])
        } else if (idxs.length === 2) {
            return Operators.pack(this.get(idxs[0]), this.get(idxs[1]))
        } else if (idxs.length === 3) {
            return Operators.pack(this.get(idxs[0]), this.get(idxs[1]), this.get(idxs[2]))
        } else {
            throw new Error(`Invalid permute length for type ${this._type}`)
        }
    }

    weld(tolerance: number) {
        return this._ctx.makeAttributeRef(this._ctx.makeOpExpr("weld", this._expr, tolerance), this._type)
    }

    normals(smoothingAttr?: AttributeRef | number) {
        if (smoothingAttr !== undefined) {
            if (typeof smoothingAttr === "number") {
                smoothingAttr = this.constInt(smoothingAttr)
            }
            return this._ctx.makeAttributeRef(this._ctx.makeOpExpr("smoothNormals", this._expr, smoothingAttr._expr), this._type)
        } else {
            return this._ctx.makeAttributeRef(this._ctx.makeOpExpr("flatNormals", this._expr), this._type)
        }
    }

    constFloat(x: ValueLiteral | ValueRef): AttributeRef {
        if (typeof x === "number") {
            return this._ctx.makeAttributeRef(this._ctx.makeOpExpr("constFloat", this._expr, x), "float")
        } else if (isVectorLiteral(x)) {
            //TODO: optimized constant vector
            if (x.length === 2) {
                return Operators.pack(this.constFloat(x[0]), this.constFloat(x[1]))
            } else if (x.length === 3) {
                return Operators.pack(this.constFloat(x[0]), this.constFloat(x[1]), this.constFloat(x[2]))
            } else {
                throw new Error("Invalid vector literal")
            }
        } else if (x._type === "float") {
            return this._ctx.makeAttributeRef(this._ctx.makeOpExpr("constFloat", this._expr, x._expr), "float")
        } else {
            throw new Error("Invalid constant type")
        }
    }
    constInt(x: number | ValueRef) {
        if (typeof x === "number") {
            return this._ctx.makeAttributeRef(this._ctx.makeOpExpr("constInt", this._expr, x), "uint32")
        } else if (x._type === "uint32") {
            return this._ctx.makeAttributeRef(this._ctx.makeOpExpr("constInt", this._expr, x._expr), "uint32")
        } else {
            throw new Error("Invalid constant type")
        }
    }
    constLike(x: ValueLiteral | ValueRef) {
        if (isFloatType(this._type)) {
            return this.constFloat(x)
        } else if (typeof x === "number") {
            return this.constInt(x)
        } else {
            throw new Error("Invalid constant type")
        }
    }

    hashFloat() {
        return this._ctx.makeAttributeRef(this._ctx.makeOpExpr("hashFloat", this._expr), "float")
    }
    hashInt() {
        return this._ctx.makeAttributeRef(this._ctx.makeOpExpr("hashInt", this._expr), "uint32")
    }

    castFloat() {
        return this._ctx.makeAttributeRef(this._ctx.makeOpExpr("castFloat", this._expr), "float")
    }
    castInt() {
        return this._ctx.makeAttributeRef(this._ctx.makeOpExpr("castInt", this._expr), "uint32")
    }

    flatten() {
        return this._ctx.makeAttributeRef(this._ctx.makeOpExpr("flatten", this._expr), elemType(this._type))
    }

    promote(type: ValueType) {
        if (this._type === type) return this
        else if (this._type === "float" && type == "float2") return this.pack2()
        else if (this._type === "float" && type == "float3") return this.pack3()
        else if (this._type === "uint32" && type == "float") return this.castFloat()
        else if (this._type === "uint32" && type == "float2") return this.castFloat().pack2()
        else if (this._type === "uint32" && type == "float3") return this.castFloat().pack3()
        else throw new Error(`Cannot promote attribute type ${this._type} to ${type}`)
    }

    oneMinus() {
        return this.neg().add(1)
    }

    select(a: ExprRef, b: ExprRef) {
        return this.mul(a).add(this.oneMinus().mul(b))
    }
}

type ReplaceLeafNodeTypes<TreeT, FindT, ReplaceT> = {[K in keyof TreeT]: TreeT[K] extends FindT ? ReplaceT : ReplaceLeafNodeTypes<TreeT[K], FindT, ReplaceT>}

class ExprTreeToken {
    constructor(
        public index: number,
        public type: ValueType,
    ) {}
}

function attrTreeToListExpr<T>(
    primaryAttr: AttributeRef | undefined,
    attrs: T,
    consolidateMultipleReferences = true,
): readonly [GeomBuilderContext, ReplaceLeafNodeTypes<T, AttributeRef, ExprTreeToken>, OpExpr] {
    const flatList: [AttributeRef, ExprTreeToken][] = []
    const tokenMap: Map<AttributeRef, ExprTreeToken> = new Map()
    let didFindPrimary = false
    let ctx: GeomBuilderContext | undefined = primaryAttr?._ctx
    const traverse = (node: any): unknown => {
        if (node instanceof AttributeRef) {
            let token = tokenMap.get(node)
            if (token && consolidateMultipleReferences) {
                return token
            }
            token = new ExprTreeToken(-1, node._type)
            if (node === primaryAttr) {
                didFindPrimary = true
                flatList.unshift([node, token])
            } else {
                flatList.push([node, token])
            }
            if (!ctx) {
                ctx = node._ctx
            } else if (ctx !== node._ctx) {
                throw new Error("attrTreeToListExpr: Incompatible contexts")
            }
            return token
        } else if (Array.isArray(node)) {
            return node.map(traverse)
        } else if (typeof node === "object") {
            const ret: any = {}
            for (const key in node) {
                ret[key] = traverse(node[key])
            }
            return ret
        } else {
            throw new Error("attrTreeToListExpr: Invalid node type")
        }
    }
    const tokens = traverse(attrs) as ReplaceLeafNodeTypes<T, AttributeRef, ExprTreeToken>
    if (primaryAttr !== undefined && !didFindPrimary) {
        throw new Error("Primary attribute not found")
    }
    for (let i = 0; i < flatList.length; i++) {
        flatList[i][1].index = i
    }
    if (!ctx) {
        throw new Error("No context found")
    }
    return [ctx, tokens, ctx.list(flatList.map((x) => x[0]._expr))]
}

function listExprToAttrTree<T>(ctx: GeomBuilderContext, tokens: ReplaceLeafNodeTypes<T, AttributeRef, ExprTreeToken>, listExpr: Expr): T {
    const traverse = (node: any): unknown => {
        if (node instanceof ExprTreeToken) {
            return ctx.makeAttributeRef(ctx.makeOpExpr("get", node.index, listExpr), node.type)
        } else if (Array.isArray(node)) {
            return node.map(traverse)
        } else if (typeof node === "object") {
            const ret: any = {}
            for (const key in node) {
                ret[key] = traverse(node[key])
            }
            return ret
        } else {
            return node
        }
    }
    return traverse(tokens) as T
}

function listExprToList(ctx: GeomBuilderContext, count: number, listExpr: Expr, type: ValueType): AttributeRef[] {
    const list: AttributeRef[] = []
    for (let i = 0; i < count; i++) {
        list.push(ctx.makeAttributeRef(ctx.makeOpExpr("get", i, listExpr), type))
    }
    return list
}

export namespace Operators {
    export function triangulate<T>(primaryAttr: AttributeRef, attrs: T): T {
        const [ctx, tokens, listExpr] = attrTreeToListExpr(primaryAttr, attrs)
        return listExprToAttrTree<T>(ctx, tokens, ctx.makeOpExpr("triangulate", listExpr))
    }

    export function project<TA, TB>(primaryAttrA: AttributeRef, attrSetA: TA, primaryAttrB: AttributeRef, attrSetB: TB): TB {
        const [ctx, tokensB, exprListB] = attrTreeToListExpr(primaryAttrB, attrSetB)
        const res = ctx.makeOpExpr("project", primaryAttrA._expr, exprListB)
        return listExprToAttrTree<TB>(ctx, tokensB, res)
    }

    export function join<TA, TB>(primaryAttrA: AttributeRef, attrSetA: TA, primaryAttrB: AttributeRef, attrSetB: TB): [TA, TB] {
        const [ctx, tokensA, exprListA] = attrTreeToListExpr(primaryAttrA, attrSetA)
        const [ctx2, tokensB, exprListB] = attrTreeToListExpr(primaryAttrB, attrSetB)
        if (ctx !== ctx2) {
            throw new Error("join: Incompatible contexts")
        }
        const expr = ctx.makeOpExpr("join", exprListA, exprListB)
        return [listExprToAttrTree<TA>(ctx, tokensA, ctx.first(expr)), listExprToAttrTree<TB>(ctx, tokensB, ctx.second(expr))]
    }

    export function product<TA, TB>(primaryAttrA: AttributeRef, attrSetA: TA, primaryAttrB: AttributeRef, attrSetB: TB): [TA, TB] {
        const [ctx, tokensA, exprListA] = attrTreeToListExpr(primaryAttrA, attrSetA)
        const [ctx2, tokensB, exprListB] = attrTreeToListExpr(primaryAttrB, attrSetB)
        if (ctx !== ctx2) {
            throw new Error("product: Incompatible contexts")
        }
        const expr = ctx.makeOpExpr("product", exprListA, exprListB)
        return [listExprToAttrTree<TA>(ctx, tokensA, ctx.first(expr)), listExprToAttrTree<TB>(ctx, tokensB, ctx.second(expr))]
    }

    export function disjointProduct<TA, TB>(primaryAttrA: AttributeRef, attrSetA: TA, primaryAttrB: AttributeRef, attrSetB: TB): TA & TB {
        const [outA, outB] = product(primaryAttrA, attrSetA, primaryAttrB, attrSetB)
        return {...outA, ...outB}
    }

    export function cut<TA, TB>(
        uvAttrA: AttributeRef,
        attrSetA: TA,
        uvAttrB: AttributeRef,
        groupAttrB: AttributeRef,
        groupIDs: number[],
    ): readonly [TA, AttributeRef[]] {
        const [ctx, tokensA, exprListA] = attrTreeToListExpr(uvAttrA, attrSetA)
        const exprListB = ctx.tuple(uvAttrB._expr, groupAttrB._expr)
        const expr = ctx.makeOpExpr("cut", exprListA, exprListB, ctx.list(groupIDs))
        const ret1 = ctx.first(expr)
        const ret2 = ctx.second(expr)
        return [listExprToAttrTree<TA>(ctx, tokensA, ret1), listExprToList(ctx, groupIDs.length, ret2, "float")] as const
    }

    export function getCutThreshold(windingAttr: AttributeRef): ValueRef {
        const ctx = windingAttr._ctx
        return new ValueRef(ctx, ctx.makeOpExpr("getCutThreshold", windingAttr._expr), "float")
    }

    export function filter<T>(primaryAttr: AttributeRef, attrs: T): T {
        const [ctx, tokens, listExpr] = attrTreeToListExpr(primaryAttr, attrs)
        return listExprToAttrTree<T>(ctx, tokens, ctx.makeOpExpr("filter", listExpr))
    }

    export function partition<T>(primaryAttr: AttributeRef, attrs: T): readonly [T, T] {
        const [ctx, tokens, listExpr] = attrTreeToListExpr(primaryAttr, attrs)
        const parts = ctx.makeOpExpr("partition", listExpr)
        const a = listExprToAttrTree<T>(ctx, tokens, ctx.first(parts))
        const b = listExprToAttrTree<T>(ctx, tokens, ctx.second(parts))
        return [a, b]
    }

    export function boundary<T>(primaryAttr: AttributeRef, attrs: T): T {
        const [ctx, tokens, listExpr] = attrTreeToListExpr(primaryAttr, attrs)
        return listExprToAttrTree<T>(ctx, tokens, ctx.makeOpExpr("boundary", listExpr))
    }

    export function flip<T>(attrs: T): T {
        if (attrs instanceof AttributeRef) {
            return flip({attr: attrs}).attr
        } else {
            const [ctx, tokens, listExpr] = attrTreeToListExpr(undefined, attrs)
            return listExprToAttrTree<T>(ctx, tokens, ctx.makeOpExpr("flip", listExpr))
        }
    }

    export function split<T>(primaryAttr: AttributeRef, attrs: T, count: number): T[] {
        const [ctx, tokens, listExpr] = attrTreeToListExpr(primaryAttr, attrs)
        const parts = ctx.makeOpExpr("split", listExpr)
        const ret: T[] = []
        for (let i = 0; i < count; i++) {
            ret.push(listExprToAttrTree<T>(ctx, tokens, ctx.get(i, parts)))
        }
        return ret
    }

    export function merge<T>(...sets: T[]): T {
        if (sets.length === 0) {
            throw new Error("merge: No sets to merge")
        } else if (sets.length === 1) {
            return sets[0]
        }
        const first = sets[0]
        const rest = sets.slice(1)

        const [ctx, tokens, firstExpr] = attrTreeToListExpr(undefined, first, false)

        const traverse = (tokenNode: any, attrNode: any, exprList: Expr[]): void => {
            if (tokenNode instanceof ExprTreeToken) {
                if (tokenNode.index > exprList.length) {
                    exprList.length = tokenNode.index + 1
                }
                exprList[tokenNode.index] = attrNode._expr
            } else if (Array.isArray(tokenNode)) {
                if (!Array.isArray(attrNode) || tokenNode.length !== attrNode.length) {
                    throw new Error("merge: Incompatible sets")
                }
                for (let i = 0; i < tokenNode.length; i++) {
                    traverse(tokenNode[i], attrNode[i], exprList)
                }
            } else if (typeof tokenNode === "object") {
                for (const key in tokenNode) {
                    const attrNodeForKey = attrNode[key]
                    if (attrNodeForKey === undefined) {
                        throw new Error("merge: Incompatible sets")
                    }
                    traverse(tokenNode[key], attrNodeForKey, exprList)
                }
            } else {
                throw new Error("merge: Invalid node type")
            }
        }

        const listArgs: Expr[] = [firstExpr]
        for (const set of rest) {
            const setExprs: Expr[] = []
            traverse(tokens, set, setExprs)
            // check that all elements are present
            if (setExprs.length !== firstExpr.args.length) {
                throw new Error("merge: Incompatible sets")
            }
            for (let i = 0; i < setExprs.length; i++) {
                if (setExprs[i] === undefined) {
                    throw new Error("merge: Incompatible sets")
                }
            }
            listArgs.push(ctx.list(setExprs))
        }
        return listExprToAttrTree<T>(ctx, tokens, ctx.makeOpExpr("merge", ctx.list(listArgs)))
    }

    export function pack(arg1_: AttributeRef | number, arg2_: AttributeRef | number, arg3_?: AttributeRef | number): AttributeRef {
        let tmp = unifyAndPromote(arg1_, arg2_)
        let ctx = tmp[0]
        let arg1 = tmp[1]
        let arg2 = tmp[2]
        if (arg3_ !== undefined) {
            tmp = unifyAndPromote(arg2, arg3_)
            ctx = tmp[0]
            arg2 = tmp[1]
            let arg3 = tmp[2]
            const arg1Type = getArgType(arg1)
            const arg2Type = getArgType(arg2)
            const arg3Type = getArgType(arg3)
            if (arg1Type !== arg2Type || arg2Type != arg3Type) throw new Error(`Incompatible attribute types for pack3: ${arg1Type}, ${arg2Type}, ${arg3Type}`)
            if (arg1Type !== "float") throw new Error("`Cannot pack non-float types: ${arg1Type}, ${arg2Type}, ${arg3Type}`)")
            return ctx.makeAttributeRef(ctx.makeOpExpr("pack3", getArgExpr(arg1), getArgExpr(arg2), getArgExpr(arg3)), "float3")
        } else {
            const arg1Type = getArgType(arg1)
            const arg2Type = getArgType(arg2)
            if (arg1Type !== arg2Type) throw new Error(`Incompatible attribute types for pack2: ${arg1Type}, ${arg2Type}`)
            if (arg1Type !== "float") throw new Error(`Cannot pack non-float types: ${arg1Type}, ${arg2Type}`)
            return ctx.makeAttributeRef(ctx.makeOpExpr("pack2", getArgExpr(arg1), getArgExpr(arg2)), "float2")
        }
    }
}

export namespace Primitives {
    //TODO: empty0, empty1, empty2 operators

    type FloatAttributeData = readonly ["float", number[][]]
    type Float2AttributeData = readonly ["float2", (readonly [number, number])[][]]
    type Float3AttributeData = readonly ["float3", (readonly [number, number, number])[][]]
    type Uint32AttributeData = readonly ["uint32", number[][]]
    export type AttributeData = FloatAttributeData | Float2AttributeData | Float3AttributeData | Uint32AttributeData

    export function curve<T extends Record<string, AttributeData>>(ctx: GeomBuilderContext, attrs: T): {[K in keyof T]: AttributeRef} {
        const tokens: ReplaceLeafNodeTypes<T, AttributeData, ExprTreeToken> = {} as any
        const args: Expr[] = []
        for (const key in attrs) {
            const [attrType, attrData] = attrs[key]
            //@ts-expect-error - TODO: clean up types
            tokens[key] = new ExprTreeToken(args.length, attrType)
            args.push([attrType, attrData as Expr])
        }
        //@ts-expect-error - TODO: clean up types
        return listExprToAttrTree<unknown>(ctx, tokens, ctx.makeOpExpr("curve", args))
    }

    export function mesh<T extends Record<string, AttributeData>>(ctx: GeomBuilderContext, attrs: T): ReplaceLeafNodeTypes<T, AttributeData, ExprTreeToken> {
        const tokens: ReplaceLeafNodeTypes<T, AttributeData, ExprTreeToken> = {} as any
        const args: Expr[] = []
        for (const key in attrs) {
            const [attrType, attrData] = attrs[key]
            //@ts-expect-error - TODO: clean up types
            tokens[key] = new ExprTreeToken(args.length, attrType)
            args.push([attrType, attrData as Expr])
        }
        //@ts-expect-error - TODO: clean up types
        return listExprToAttrTree<unknown>(ctx, tokens, ctx.makeOpExpr("mesh", args))
    }

    export function grid2(
        ctx: GeomBuilderContext,
        originX: number,
        originY: number,
        basis1X: number,
        basis1Y: number,
        basis2X: number,
        basis2Y: number,
        numXSegments: number,
        numYSegments: number,
    ) {
        return ctx.makeAttributeRef(ctx.makeOpExpr("grid2", originX, originY, basis1X, basis1Y, basis2X, basis2Y, numXSegments, numYSegments), "float2")
    }

    export function linePoints1(ctx: GeomBuilderContext, x0: number, x1: number, numPoints: number) {
        return ctx.makeAttributeRef(ctx.makeOpExpr("linePoints1", x0, x1, numPoints), "float")
    }

    export function linePoints2(ctx: GeomBuilderContext, x0: number, y0: number, x1: number, y1: number, numPoints: number) {
        return ctx.makeAttributeRef(ctx.makeOpExpr("linePoints2", x0, y0, x1, y1, numPoints), "float2")
    }

    export function linePoints3(ctx: GeomBuilderContext, x0: number, y0: number, z0: number, x1: number, y1: number, z1: number, numPoints: number) {
        return ctx.makeAttributeRef(ctx.makeOpExpr("linePoints3", x0, y0, z0, x1, y1, z1, numPoints), "float3")
    }

    export function line1(ctx: GeomBuilderContext, x0: number, x1: number, numSeg: number) {
        return ctx.makeAttributeRef(ctx.makeOpExpr("line1", x0, x1, numSeg), "float")
    }

    export function line2(ctx: GeomBuilderContext, x0: number, y0: number, x1: number, y1: number, numSeg: number) {
        //TODO: doesn't need to be a primitive
        return ctx.makeAttributeRef(ctx.makeOpExpr("line2", x0, y0, x1, y1, numSeg), "float2")
    }

    export function line3(ctx: GeomBuilderContext, x0: number, y0: number, z0: number, x1: number, y1: number, z1: number, numSeg: number) {
        //TODO: doesn't need to be a primitive
        return ctx.makeAttributeRef(ctx.makeOpExpr("line3", x0, y0, z0, x1, y1, z1, numSeg), "float3")
    }

    export function uvSphere(ctx: GeomBuilderContext, r = 1, numU = 10, numV = 10) {
        //TODO: weld poles
        const uv = grid2(ctx, 0.0, 0.0001, 1, 0, 0, 0.9998, numU, numV)
        const phi = uv.x.mul(2 * Math.PI)
        const theta = uv.y.mul(Math.PI)
        const theta_sin = theta.sin()
        const position = Operators.pack(phi.sin().mul(theta_sin).mul(-r), theta.cos().mul(-r), phi.cos().mul(theta_sin).mul(-r))
        return {position, uv}
    }
}

export namespace Tilings {
    export function hexagonalTiling(ctx: GeomBuilderContext, x0: number, y0: number, x1: number, y1: number, r: number, inset: number) {
        const expr = ctx.makeOpExpr("hexagonalTiling", x0, y0, x1, y1, r, inset)
        return {
            uv: ctx.makeAttributeRef(ctx.makeOpExpr("get", 0, expr), "float2"),
            tileID: ctx.makeAttributeRef(ctx.makeOpExpr("get", 1, expr), "uint32"),
        }
    }

    export function rectangularTiling(
        ctx: GeomBuilderContext,
        x0: number,
        y0: number,
        x1: number,
        y1: number,
        w: number,
        h: number,
        skew: number,
        inset: number,
    ) {
        const expr = ctx.makeOpExpr("rectangularTiling", x0, y0, x1, y1, w, h, skew, inset)
        return {
            uv: ctx.makeAttributeRef(ctx.makeOpExpr("get", 0, expr), "float2"),
            tileID: ctx.makeAttributeRef(ctx.makeOpExpr("get", 1, expr), "uint32"),
        }
    }

    export function dashTiling(ctx: GeomBuilderContext, x0: number, x1: number, w: number, inset: number) {
        const expr = ctx.makeOpExpr("dashTiling", x0, x1, w, inset)
        return {
            u: ctx.makeAttributeRef(ctx.makeOpExpr("get", 0, expr), "float"),
            tileID: ctx.makeAttributeRef(ctx.makeOpExpr("get", 1, expr), "uint32"),
        }
    }
}

export type StandardGeometryAttributes = {
    position: AttributeRef
    normal: AttributeRef
    materialID: AttributeRef
    uvs: AttributeRef[]
}

export function geomToMesh(attrs: StandardGeometryAttributes): MeshNodes.Mesh {
    const ctx = attrs.position._ctx
    const attrList: Expr[] = [attrs.position._expr, attrs.normal._expr, attrs.materialID._expr]
    for (const uv of attrs.uvs) {
        attrList.push(uv._expr)
    }
    return {
        type: "geomGraph",
        graph: ctx.list(attrList),
    }
}

export function meshToGeom(ctx: GeomBuilderContext, mesh: MeshNodes.Mesh, numUVChannels: number): StandardGeometryAttributes {
    // @ts-expect-error - TODO: clean up types
    const attrSet: Expr = ctx.makeOpExpr("meshToGeom", mesh)
    return {
        position: ctx.makeAttributeRef(ctx.makeOpExpr("get", 0, attrSet), "float3"),
        normal: ctx.makeAttributeRef(ctx.makeOpExpr("get", 1, attrSet), "float3"),
        materialID: ctx.makeAttributeRef(ctx.makeOpExpr("get", 2, attrSet), "uint32"),
        uvs: (() => {
            const uvs: AttributeRef[] = []
            for (let i = 0; i < numUVChannels; i++) {
                uvs.push(ctx.makeAttributeRef(ctx.makeOpExpr("get", 3 + i, attrSet), "float2"))
            }
            return uvs
        })(),
    }
}

export function continuousCurveAttr(arr: Float32Array, elemSize: number): Primitives.AttributeData {
    let elemType: Primitives.AttributeData[0]
    if (elemSize === 1) {
        elemType = "float"
    } else if (elemSize === 2) {
        elemType = "float2"
    } else if (elemSize === 3) {
        elemType = "float3"
    } else {
        throw new Error("Unsupported element size")
    }
    const numSegments = arr.length / elemSize - 1
    const segments: number[][][] = []
    for (let i = 0; i < numSegments; i++) {
        const segment: number[][] = [[], []]
        for (let j = 0; j < elemSize; j++) {
            segment[0].push(arr[i * elemSize + j])
            segment[1].push(arr[(i + 1) * elemSize + j])
        }
        segments.push(segment)
    }
    return [elemType, segments] as any as Primitives.AttributeData
}
