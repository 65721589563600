import {UpperCasePipe} from "@angular/common"
import {Component, input, OnDestroy} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatOptionModule} from "@angular/material/core"
import {MatInputModule} from "@angular/material/input"
import {MatSelect} from "@angular/material/select"
import {LoadingSpinnerIconComponent} from "@app/common/components/progress"
import {
    defaultExportEngine,
    defaultExportFormat,
    defaultExportNormalY,
    defaultExportResolution,
    defaultExportWorkflow,
} from "@app/common/helpers/material-maps-exporter"
import {MaterialMapsExporter} from "@cm/material-nodes/material-maps-exporter"

@Component({
    selector: "cm-material-output-custom-pbr-export-form",
    imports: [FormsModule, LoadingSpinnerIconComponent, MatInputModule, MatOptionModule, MatSelect, UpperCasePipe],
    templateUrl: "./material-output-custom-pbr-export-form.component.html",
    styleUrl: "./material-output-custom-pbr-export-form.component.scss",
})
export class MaterialOutputCustomPbrExportFormComponent implements OnDestroy {
    readonly $onSubmit = input.required<(conversionRequest: MaterialMapsExporter.ConversionRequest) => Promise<void>>({alias: "onSubmit"})

    selectedExportWorkflow = defaultExportWorkflow
    selectedExportEngine = defaultExportEngine
    selectedExportNormalY = defaultExportNormalY
    selectedExportFormat = defaultExportFormat
    selectedExportResolution = defaultExportResolution
    exportWorkflows = MaterialMapsExporter.workflows
    exportEngines = MaterialMapsExporter.engines
    exportNormalsY = MaterialMapsExporter.normalsY
    exportFormats = MaterialMapsExporter.formats
    exportResolutions = MaterialMapsExporter.resolutions

    isLoading = false
    timeout?: Timer

    async submit() {
        clearTimeout(this.timeout)
        this.isLoading = true
        const conversionRequest = MaterialMapsExporter.conversionRequest(
            this.selectedExportWorkflow,
            this.selectedExportEngine,
            this.selectedExportNormalY,
            this.selectedExportFormat,
            this.selectedExportResolution,
        )
        await this.$onSubmit()(conversionRequest)
        this.timeout = setTimeout(() => {
            this.isLoading = false
        }, 300)
    }

    ngOnDestroy() {
        clearTimeout(this.timeout)
    }

    protected readonly MaterialMapsExporter = MaterialMapsExporter
}
