import {HalImageDescriptor} from "@common/models/hal/hal-image/types"

export function isHalImageDescriptor(obj?: unknown): obj is HalImageDescriptor {
    return (
        obj != undefined &&
        typeof obj === "object" &&
        "width" in obj &&
        obj.width != undefined &&
        "height" in obj &&
        obj.height != undefined &&
        "channelLayout" in obj &&
        obj.channelLayout != undefined &&
        "dataType" in obj &&
        obj.dataType != undefined
    )
}
