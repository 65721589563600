@if (_uiStyle != "hidden" && interfaceDescriptors.length > 0) {
    <div class="cm-configurator-menu">
        <div class="cm-menu-content">
            @if (minimized) {
                <div (click)="minimized = !minimized" class="cm-minimized-menu">
                    <i class="far fa-sliders-h"></i>
                </div>
            } @else {
                @if (_uiStyle === "default") {
                    <cm-default-menu [interfaceDescriptors]="interfaceDescriptors"></cm-default-menu>
                }
                @if (_uiStyle === "accordion" || _uiStyle === "icons") {
                    <cm-icons-accordion-menu
                        [useCaptions]="$useCaptions()"
                        [interfaceDescriptors]="interfaceDescriptors"
                        [uiStyle]="_uiStyle"
                        [tooltipContainer]="tooltipContainer"
                        [accordionStyle]="$accordionStyle()"
                    ></cm-icons-accordion-menu>
                }
            }
        </div>
        <div class="cm-minimize-menu-container">
            @if (!minimized && !$externalMenu()) {
                <div (click)="minimized = !minimized" class="cm-minimize-menu">
                    <i class="far fa-chevron-down"></i>
                </div>
            }
        </div>

        <div class="tooltip-container" #tooltipContainer></div>
    </div>
}
