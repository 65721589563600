import {Component, computed, inject, Injector, output, signal} from "@angular/core"
import {MatDialog} from "@angular/material/dialog"
import {RenameDialogComponent} from "@app/common/components/dialogs/rename-dialog/rename-dialog.component"
import {quaternionToAngleDegrees} from "@app/common/helpers/utils/math-utils"
import {UploadGqlService} from "@app/common/services/upload/upload.gql.service"
import {WebAssemblyWorkerService} from "@app/template-editor/services/webassembly-worker.service"
import {DIALOG_DEFAULT_WIDTH} from "@app/template-editor/helpers/constants"
import {getNodeIconClass} from "@app/template-editor/helpers/template-icons"
import {selectFile, uploadImage, uploadMeshToGroup} from "@app/template-editor/helpers/upload"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {TemplateNodeDragService} from "@app/template-editor/services/template-node-drag.service"
import {Matrix4, Vector3} from "@cm/math"
import {
    Annotation,
    BooleanExport,
    BooleanInput,
    BooleanValue,
    CentroidAccumulator,
    ConfigGroup,
    CutMesh,
    FixedRotation,
    getNodeOwner,
    Group,
    ImageExport,
    ImageInput,
    ImageRgbCurve,
    isNamedNode,
    isNode,
    isObject,
    JSONExport,
    JSONInput,
    JSONValue,
    MaterialAssignments,
    MaterialExport,
    MaterialInput,
    MeshDecal,
    Node,
    NodeOwner,
    Nodes,
    NumberExport,
    NumberInput,
    NumberValue,
    Object,
    ObjectExport,
    ObjectInput,
    OverlayMaterialColor,
    Parameters,
    PlaneGuide,
    PointGuide,
    ProceduralMesh,
    RigidRelation,
    SceneNodes,
    StoredMesh,
    StringExport,
    StringInput,
    StringValue,
    TemplateExport,
    TemplateGraph,
    TemplateInput,
    TemplateInstance,
    TemplateNode,
    Translation,
} from "@cm/template-nodes"
import {DataObjectReference} from "@cm/template-nodes/nodes/data-object-reference"
import {DistanceTexture} from "@cm/template-nodes/nodes/distance-texture"
import {ImageOperator} from "@cm/template-nodes/nodes/image-operator"
import {LodType} from "@cm/template-nodes/nodes/lod-type"
import {MeshCurve} from "@cm/template-nodes/nodes/mesh-curve"
import {Seam} from "@cm/template-nodes/nodes/seam"
import {tap} from "rxjs"
import {v4 as uuid4} from "uuid"
import {TemplateAddCardComponent} from "../template-add-card/template-add-card.component"
import {initDimensionGuides} from "@cm/template-nodes/utils/dimension-guide-utils"
import {MoveMaterial} from "@cm/template-nodes/nodes/move-material"
import {BackgroundMaterial} from "@cm/template-nodes/nodes/background-material"
import {CurveInput, MeshInput, NodesInput} from "@cm/template-nodes/nodes/input"
import {CurveExport, MeshExport} from "@cm/template-nodes/nodes/export"

type SceneItem = {
    name: string
    node?: Node
    action?: () => Promise<false | void> | false | void
    iconClass: string
    disabled?: boolean
}

@Component({
    selector: "cm-template-add-other",
    templateUrl: "./template-add-other.component.html",
    styleUrl: "./template-add-other.component.scss",
    imports: [TemplateAddCardComponent],
})
export class TemplateAddOtherComponent {
    readonly drag = inject(TemplateNodeDragService)
    private readonly dialog = inject(MatDialog)
    private readonly sceneManagerService = inject(SceneManagerService)
    private readonly injector = inject(Injector)
    private readonly uploadService = inject(UploadGqlService)
    private readonly workerService = inject(WebAssemblyWorkerService)
    readonly onItemClicked = output()

    readonly $currentSection = signal<"upload" | "configuration" | "primitive" | "decal" | "input" | "export" | "value" | "connection" | "other">("upload")

    private readonly $selectedNodes = computed(() => this.sceneManagerService.$selectedNodeParts().map((node) => node.templateNode))
    private readonly $selectedObjects = computed(() => this.$selectedNodes().filter((node): node is Object => isObject(node)))
    private readonly $selectedPointGuideNodes = computed(() =>
        this.sceneManagerService
            .$selectedNodeParts()
            .map((templateNodePart) => this.sceneManagerService.getSceneNodeParts(templateNodePart))
            .flat()
            .map((node) => node.sceneNode)
            .filter((x): x is SceneNodes.Mesh => SceneNodes.Mesh.is(x)),
    )

    readonly $uploadSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Upload Mesh",
                action: () => this.uploadMesh(),
                iconClass: getNodeIconClass(StoredMesh.getNodeClass()),
            },
            {
                name: "Upload Image",
                action: () => this.uploadImage(),
                iconClass: getNodeIconClass(DataObjectReference.getNodeClass()),
            },
        ]
    })

    readonly $configurationSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Config Group",
                node: new ConfigGroup({name: "Config Group", nodes: new Nodes({list: []}), id: uuid4(), displayWithLabels: false}),
                iconClass: getNodeIconClass(ConfigGroup.getNodeClass()),
            },
            {
                name: "Group",
                node: new Group({name: "Group", nodes: new Nodes({list: []}), active: true}),
                iconClass: getNodeIconClass(Group.getNodeClass()),
            },
        ]
    })

    readonly $primitiveSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Plane",
                node: new ProceduralMesh({
                    name: "Plane",
                    geometryGraph: "plane",
                    parameters: {width: 1000, height: 1000},
                    materialAssignments: new MaterialAssignments({"0": null}),
                    materialSlotNames: {},
                    lockedTransform: Matrix4.identity().toArray(),
                    visible: true,
                    visibleDirectly: true,
                    visibleInReflections: true,
                    visibleInRefractions: true,
                    castRealtimeShadows: true,
                    receiveRealtimeShadows: true,
                }),
                iconClass: getNodeIconClass(ProceduralMesh.getNodeClass()),
            },
            {
                name: "Box",
                node: new ProceduralMesh({
                    name: "Box",
                    geometryGraph: "box",
                    parameters: {width: 100, height: 100, depth: 100, inside: false, faceMaterials: false},
                    materialAssignments: new MaterialAssignments({"0": null, "1": null, "2": null}),
                    materialSlotNames: {"0": "Bottom", "1": "Outside", "2": "Top"},
                    lockedTransform: Matrix4.identity().toArray(),
                    visible: true,
                    visibleDirectly: true,
                    visibleInReflections: true,
                    visibleInRefractions: true,
                    castRealtimeShadows: true,
                    receiveRealtimeShadows: true,
                }),
                iconClass: getNodeIconClass(ProceduralMesh.getNodeClass()),
            },
            {
                name: "Sphere",
                node: new ProceduralMesh({
                    name: "Sphere",
                    geometryGraph: "sphere",
                    parameters: {radius: 10, numU: 64, numV: 32},
                    materialAssignments: new MaterialAssignments({"0": null}),
                    materialSlotNames: {},
                    lockedTransform: Matrix4.identity().toArray(),
                    visible: true,
                    visibleDirectly: true,
                    visibleInReflections: true,
                    visibleInRefractions: true,
                    castRealtimeShadows: true,
                    receiveRealtimeShadows: true,
                }),
                iconClass: getNodeIconClass(ProceduralMesh.getNodeClass()),
            },
            {
                name: "Studio",
                node: new ProceduralMesh({
                    name: "Studio",
                    geometryGraph: "simpleStudioRoom",
                    parameters: {height: 5, length: 10, width: 10, showCeiling: true, showFloor: true, showWalls: true},
                    materialAssignments: new MaterialAssignments({"0": null, "1": null, "2": null}),
                    materialSlotNames: {"0": "Floor", "1": "Walls", "2": "Ceiling"},
                    lockedTransform: Matrix4.identity().toArray(),
                    visible: true,
                    visibleDirectly: true,
                    visibleInReflections: true,
                    visibleInRefractions: true,
                    castRealtimeShadows: true,
                    receiveRealtimeShadows: true,
                }),
                iconClass: getNodeIconClass(ProceduralMesh.getNodeClass()),
            },
        ]
    })

    readonly $decalSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Mesh Decal",
                node: new MeshDecal({
                    name: "Mesh Decal",
                    mesh: null,
                    offset: [0, 0],
                    rotation: 0,
                    size: [10, 10],
                    distance: 0.01,
                    mask: undefined,
                    invertMask: false,
                    maskType: "binary",
                    materialAssignment: null,
                    visible: true,
                }),
                iconClass: getNodeIconClass(MeshDecal.getNodeClass()),
            },
            {
                name: "Mesh Curve",
                node: new MeshCurve({
                    name: "Mesh Curve",
                    mesh: null,
                    closed: false,
                    controlPoints: [],
                    visible: true,
                }),
                iconClass: getNodeIconClass(MeshCurve.getNodeClass()),
            },
            {
                name: "Seam",
                node: new Seam({
                    name: "Seam",
                    item: null,
                    curves: [],
                    allowScaling: false,
                    visible: true,
                }),
                iconClass: getNodeIconClass(Seam.getNodeClass()),
            },
            {
                name: "Cut Mesh",
                node: new CutMesh({
                    name: "Cut Mesh",
                    mesh: null,
                    curves: [],
                    uvChannel: 0,
                    materialAssignments: new MaterialAssignments({"0": null}),
                    materialSlotNames: {},
                    lockedTransform: Matrix4.identity().toArray(),
                    visible: true,
                    visibleDirectly: true,
                    visibleInReflections: true,
                    visibleInRefractions: true,
                    castRealtimeShadows: true,
                    receiveRealtimeShadows: true,
                }),
                iconClass: getNodeIconClass(CutMesh.getNodeClass()),
            },
            {
                name: "Annotation",
                node: new Annotation({
                    name: "Annotation",
                    id: uuid4(),
                    label: "Label",
                    description: "",
                    lockedTransform: Matrix4.identity().toArray(),
                    visible: true,
                }),
                iconClass: getNodeIconClass(Annotation.getNodeClass()),
            },
            {
                name: "Dimension Guide",
                node: initDimensionGuides("Dimension Guide", null),
                iconClass: getNodeIconClass(Annotation.getNodeClass()),
            },
        ]
    })

    readonly $inputSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Object Input",
                node: new ObjectInput({
                    name: "Object Input",
                    id: uuid4(),
                }),
                iconClass: getNodeIconClass(ObjectInput.getNodeClass()),
            },
            {
                name: "Mesh Input",
                node: new MeshInput({
                    name: "Mesh Input",
                    id: uuid4(),
                }),
                iconClass: getNodeIconClass(MeshInput.getNodeClass()),
            },
            {
                name: "Curve Input",
                node: new CurveInput({
                    name: "Curve Input",
                    id: uuid4(),
                }),
                iconClass: getNodeIconClass(CurveInput.getNodeClass()),
            },
            {
                name: "Material Input",
                node: new MaterialInput({
                    name: "Material Input",
                    id: uuid4(),
                }),
                iconClass: getNodeIconClass(MaterialInput.getNodeClass()),
            },
            {
                name: "Template Input",
                node: new TemplateInput({
                    name: "Template Input",
                    id: uuid4(),
                }),
                iconClass: getNodeIconClass(TemplateInput.getNodeClass()),
            },
            {
                name: "Image Input",
                node: new ImageInput({
                    name: "Image Input",
                    id: uuid4(),
                }),
                iconClass: getNodeIconClass(ImageInput.getNodeClass()),
            },
            {
                name: "String Input",
                node: new StringInput({
                    name: "String Input",
                    id: uuid4(),
                }),
                iconClass: getNodeIconClass(StringInput.getNodeClass()),
            },
            {
                name: "Number Input",
                node: new NumberInput({
                    name: "Number Input",
                    id: uuid4(),
                }),
                iconClass: getNodeIconClass(NumberInput.getNodeClass()),
            },
            {
                name: "Boolean Input",
                node: new BooleanInput({
                    name: "Boolean Input",
                    id: uuid4(),
                }),
                iconClass: getNodeIconClass(BooleanInput.getNodeClass()),
            },
            {
                name: "JSON Input",
                node: new JSONInput({
                    name: "JSON Input",
                    id: uuid4(),
                }),
                iconClass: getNodeIconClass(JSONInput.getNodeClass()),
            },
            {
                name: "Nodes Input",
                node: new NodesInput({
                    name: "Nodes Input",
                    id: uuid4(),
                }),
                iconClass: getNodeIconClass(NodesInput.getNodeClass()),
            },
        ]
    })

    readonly $exportSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Object Output",
                node: new ObjectExport({
                    name: "Object Output",
                    id: uuid4(),
                    node: null,
                }),
                iconClass: getNodeIconClass(ObjectExport.getNodeClass()),
            },
            {
                name: "Mesh Output",
                node: new MeshExport({
                    name: "Mesh Output",
                    id: uuid4(),
                    node: null,
                }),
                iconClass: getNodeIconClass(MeshExport.getNodeClass()),
            },
            {
                name: "Curve Output",
                node: new CurveExport({
                    name: "Curve Output",
                    id: uuid4(),
                    node: null,
                }),
                iconClass: getNodeIconClass(CurveExport.getNodeClass()),
            },
            {
                name: "Material Output",
                node: new MaterialExport({
                    name: "Material Output",
                    id: uuid4(),
                    node: null,
                }),
                iconClass: getNodeIconClass(MaterialExport.getNodeClass()),
            },
            {
                name: "Template Output",
                node: new TemplateExport({
                    name: "Template Output",
                    id: uuid4(),
                    node: null,
                }),
                iconClass: getNodeIconClass(TemplateExport.getNodeClass()),
            },
            {
                name: "Image Output",
                node: new ImageExport({
                    name: "Image Output",
                    id: uuid4(),
                    node: null,
                }),
                iconClass: getNodeIconClass(ImageExport.getNodeClass()),
            },
            {
                name: "String Output",
                node: new StringExport({
                    name: "String Output",
                    id: uuid4(),
                    node: null,
                }),
                iconClass: getNodeIconClass(StringExport.getNodeClass()),
            },
            {
                name: "Number Output",
                node: new NumberExport({
                    name: "Number Output",
                    id: uuid4(),
                    node: null,
                }),
                iconClass: getNodeIconClass(NumberExport.getNodeClass()),
            },
            {
                name: "Boolean Output",
                node: new BooleanExport({
                    name: "Boolean Output",
                    id: uuid4(),
                    node: null,
                }),
                iconClass: getNodeIconClass(BooleanExport.getNodeClass()),
            },
            {
                name: "JSON Output",
                node: new JSONExport({
                    name: "JSON Output",
                    id: uuid4(),
                    node: null,
                }),
                iconClass: getNodeIconClass(JSONExport.getNodeClass()),
            },
        ]
    })

    readonly $valueSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "String Value",
                node: new StringValue({
                    value: "",
                }),
                iconClass: getNodeIconClass(StringValue.getNodeClass()),
            },
            {
                name: "Number Value",
                node: new NumberValue({
                    value: 0,
                }),
                iconClass: getNodeIconClass(NumberValue.getNodeClass()),
            },
            {
                name: "Boolean Value",
                node: new BooleanValue({
                    value: false,
                }),
                iconClass: getNodeIconClass(BooleanValue.getNodeClass()),
            },
            {
                name: "JSON Value",
                node: new JSONValue({
                    value: {},
                }),
                iconClass: getNodeIconClass(JSONValue.getNodeClass()),
            },
        ]
    })

    readonly $connectionSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Rigid Relation",
                node: new RigidRelation({
                    translation: new Translation({x: 0, y: 0, z: 0}),
                    rotation: new FixedRotation({x: 0, y: 0, z: 0}),
                    targetA: null,
                    targetB: null,
                }),
                iconClass: getNodeIconClass(RigidRelation.getNodeClass()),
            },
            {
                name: "Rigidly Connect Selection",
                action: () => this.rigidlyConnectSelection(),
                iconClass: "far fa-arrow-right-arrow-left",
                disabled: this.$selectedObjects().length <= 1,
            },
            {
                name: "Point Guide from Selection",
                action: () => this.addPointGuideForSelection(),
                iconClass: getNodeIconClass(PointGuide.getNodeClass()),
                disabled: this.$selectedPointGuideNodes().length === 0,
            },
            {
                name: "Floor Guide",
                node: new PlaneGuide({
                    name: "Floor Guide",
                    width: 100,
                    height: 100,
                    lockedTransform: Matrix4.identity().toArray(),
                    visible: true,
                }),
                iconClass: getNodeIconClass(PlaneGuide.getNodeClass()),
            },
            {
                name: "Wall Guide",
                node: new PlaneGuide({
                    name: "Wall Guide",
                    width: 100,
                    height: 100,
                    lockedTransform: Matrix4.rotationX(90).toArray(),
                    visible: true,
                }),
                iconClass: getNodeIconClass(PlaneGuide.getNodeClass()),
            },
        ]
    })

    readonly $otherSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Overlay Material Color",
                node: new OverlayMaterialColor({
                    material: null,
                    overlay: null,
                    size: [10, 10],
                }),
                iconClass: getNodeIconClass(OverlayMaterialColor.getNodeClass()),
            },
            {
                name: "Move Material",
                node: new MoveMaterial({
                    material: null,
                }),
                iconClass: getNodeIconClass(MoveMaterial.getNodeClass()),
            },
            {
                name: "Background Material",
                node: new BackgroundMaterial({
                    backgroundImage: null,
                }),
                iconClass: getNodeIconClass(BackgroundMaterial.getNodeClass()),
            },
            {
                name: "LOD Type",
                node: new LodType({
                    lodType: "web",
                }),
                iconClass: getNodeIconClass(LodType.getNodeClass()),
            },
            {
                name: "Image Operator",
                node: new ImageOperator({
                    name: "Image Operator",
                    input: null,
                    operation: "ADD",
                    value: 0,
                }),
                iconClass: getNodeIconClass(ImageOperator.getNodeClass()),
            },
            {
                name: "Image RGB Curve",
                node: new ImageRgbCurve({
                    name: "Image RGB Curve",
                    input: null,
                    r: [
                        {x: 0, y: 0},
                        {x: 1, y: 1},
                    ],
                    g: [
                        {x: 0, y: 0},
                        {x: 1, y: 1},
                    ],
                    b: [
                        {x: 0, y: 0},
                        {x: 1, y: 1},
                    ],
                    rgb: [
                        {x: 0, y: 0},
                        {x: 1, y: 1},
                    ],
                }),
                iconClass: getNodeIconClass(ImageOperator.getNodeClass()),
            },
            {
                name: "Distance Texture",
                node: new DistanceTexture({
                    name: "Distance Texture",
                    source: null,
                    uvChannel: 0,
                    range: 1,
                    mergeRange: 0,
                    width: 1024,
                    height: 1024,
                    targets: [],
                    smoothPasses: 0,
                }),
                iconClass: getNodeIconClass(DistanceTexture.getNodeClass()),
            },
        ]
    })

    readonly $sceneItems = computed<SceneItem[]>(() => {
        const currentSection = this.$currentSection()
        switch (currentSection) {
            case "upload":
                return this.$uploadSceneItems()
            case "primitive":
                return this.$primitiveSceneItems()
            case "configuration":
                return this.$configurationSceneItems()
            case "decal":
                return this.$decalSceneItems()
            case "input":
                return this.$inputSceneItems()
            case "export":
                return this.$exportSceneItems()
            case "value":
                return this.$valueSceneItems()
            case "connection":
                return this.$connectionSceneItems()
            case "other":
                return this.$otherSceneItems()
        }
    })

    addItem(node: Node) {
        this.sceneManagerService.modifyTemplateGraph((graph) => {
            graph.parameters.nodes.addEntry(node)
        })
    }

    dragStart(event: DragEvent, node: Node) {
        this.drag.dragStart(event, node)
    }

    private async uploadMesh(): Promise<false | void> {
        const file = await selectFile("Select a mesh file for upload", ".cmm,.obj,.ply,.fbx")
        if (!file) return false

        const action = async () => {
            const group = await uploadMeshToGroup(this.injector, this.uploadService, this.workerService, this.sceneManagerService, file)
            if (!group) return

            this.addItem(group)
        }

        action()
    }

    private async uploadImage(): Promise<false | void> {
        const file = await selectFile("Select an image file for upload", "image/*")
        if (!file) return false

        const action = async () => {
            const image = await uploadImage(this.injector, this.uploadService, this.sceneManagerService, this.dialog, file)
            if (!image) return

            this.addItem(image)
        }

        action()
    }

    private rigidlyConnectSelection() {
        const selectedObjects = this.$selectedObjects()
        if (selectedObjects.length < 2) return

        const primaryObj = selectedObjects[0]

        const nodeOwner = getNodeOwner(primaryObj)
        if (!nodeOwner) throw new Error("Cannot find node owner for selected object")

        this.sceneManagerService.modifyTemplateGraph(() => {
            const matrixA = this.sceneManagerService.getTransformAccessor(primaryObj)?.getTransform()

            if (!matrixA) return

            for (let i = 1; i < selectedObjects.length; i++) {
                const secondaryObject = selectedObjects[i]
                const matrixB = this.sceneManagerService.getTransformAccessor(secondaryObject)?.getTransform()

                if (!matrixB) continue

                const {position, quaternion} = matrixA.inverse().multiply(matrixB).decompose()
                const angles = quaternionToAngleDegrees(quaternion)

                nodeOwner.parameters.nodes.addEntry(
                    new RigidRelation({
                        translation: new Translation({x: position.x, y: position.y, z: position.z}),
                        rotation: new FixedRotation({x: angles[0], y: angles[1], z: angles[2]}),
                        targetA: primaryObj,
                        targetB: secondaryObject,
                    }),
                )
                secondaryObject.updateParameters({lockedTransform: undefined})
            }
        })
    }

    private addPointGuideForSelection() {
        const selectedPointGuideNodes = this.$selectedPointGuideNodes()

        if (selectedPointGuideNodes.length === 0) return

        const centroidAccumulator = new CentroidAccumulator()
        selectedPointGuideNodes.forEach((sceneNode) => centroidAccumulator.accumulate(sceneNode.completeMeshData.reified, sceneNode.transform))
        const centroid = Vector3.fromArray(centroidAccumulator.finalize().centroid)
        const transform = Matrix4.translation(centroid.x, centroid.y, centroid.z).toArray()

        this.addItem(
            new PointGuide({
                name: "Point Guide",
                lockedTransform: transform,
                $defaultTransform: transform,
                visible: true,
            }),
        )
    }
}
