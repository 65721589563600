import {ChangeDetectorRef, Component, computed, DestroyRef, inject, input, OnInit, signal, viewChild, ViewContainerRef} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatTooltipModule} from "@angular/material/tooltip"
import {getNodeIconClass, getNodeIconSeconaryClass} from "@app/template-editor/helpers/template-icons"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {TemplateNodeDragService} from "@app/template-editor/services/template-node-drag.service"
import {isOutput, isValue, valueNodeClasses} from "@cm/template-nodes"
import {LodType} from "@cm/template-nodes"
import {isNamedNode} from "@cm/template-nodes"
import {RigidRelation} from "@cm/template-nodes"
import {isTemplateNode, TemplateNode} from "@cm/template-nodes"
import {getTemplateNodeClassLabel, getTemplateNodeLabel} from "@cm/template-nodes"

@Component({
    selector: "cm-template-node",
    imports: [MatTooltipModule],
    templateUrl: "./template-node.component.html",
    styleUrls: ["./template-node.component.scss", "./../../helpers/template-icons.scss"],
})
export class TemplateNodeComponent implements OnInit {
    readonly $node = input.required<TemplateNode>({alias: "node"})
    readonly $noDrag = input(false, {alias: "noDrag"})
    readonly $name = input<string | undefined>(undefined, {alias: "name"})
    readonly $alwaysEnabled = input(false, {alias: "alwaysEnabled"})
    readonly $iconClass = computed(() => getNodeIconClass(this.$node().getNodeClass()))
    readonly $secondaryIconClass = computed(() => getNodeIconSeconaryClass(this.$node().getNodeClass()))
    readonly $classLabel = computed(() => getTemplateNodeClassLabel(this.$node()))
    private readonly $triggerRecomputeLabel = signal(0)
    readonly $label = computed(() => {
        this.$triggerRecomputeLabel()
        return this.$name() ?? getTemplateNodeLabel(this.$node())
    })
    readonly $disabled = computed(() => {
        if (this.$alwaysEnabled()) return false
        return !this.sceneManagerService.isNodeActive(this.$node())
    })
    private readonly sceneManagerService = inject(SceneManagerService)
    private readonly destroyRef = inject(DestroyRef)
    private readonly cdr = inject(ChangeDetectorRef)
    readonly drag = inject(TemplateNodeDragService)
    readonly $nodeReference = computed(() => {
        const node = this.$node()
        if (isOutput(node)) return node.parameters.template
        else return node
    })
    readonly $notClickable = input(false, {alias: "notClickable"})

    readonly $isNodeValue = computed(() => valueNodeClasses.includes(this.$node().getNodeClass()))

    private readonly $dragPlaceholder = viewChild.required("dragPlaceholder", {read: ViewContainerRef})

    ngOnInit() {
        this.sceneManagerService.templateTreeChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((differences) => {
            for (const [node, difference] of differences.modifiedNodes) {
                if (isTemplateNode(node) && node === this.$node()) {
                    if (
                        (isNamedNode(node) && difference.find((x) => x.path === "name") !== undefined) ||
                        (node instanceof RigidRelation && difference.find((x) => x.path === "targetA" || x.path === "targetB")) ||
                        (isValue(node) && difference.find((x) => x.path === "value")) ||
                        (node instanceof LodType && difference.find((x) => x.path === "lodType"))
                    ) {
                        this.$triggerRecomputeLabel.update((x) => x + 1)
                        return
                    }
                }
            }
        })
    }

    onMouseEnter() {
        this.sceneManagerService.highlightNode(this.$nodeReference(), true)
    }

    onMouseLeave() {
        this.sceneManagerService.highlightNode(this.$nodeReference(), false)
    }

    onClick(event: MouseEvent) {
        if (this.$notClickable()) return

        event.stopPropagation()
        const node = this.$nodeReference()

        this.onMouseLeave()
        this.sceneManagerService.selectNode({templateNode: node, part: "root"})
    }

    getDragImage() {
        const dragPlaceholder = this.$dragPlaceholder()

        const dragImage = document.createElement("div")

        dragImage.style.display = "flex"
        dragImage.style.justifyContent = "center"
        dragImage.style.alignItems = "center"
        dragImage.style.position = "absolute"
        dragImage.style.zIndex = "1000"
        dragImage.style.left = "-1000px"
        dragImage.style.backgroundColor = "rgb(255, 255, 255)"
        dragImage.style.border = "1px solid black"
        dragImage.style.padding = "5px"
        dragImage.style.fontSize = "0.75rem"
        dragImage.style.fontWeight = "500"
        dragImage.style.color = "black"

        const templateNodeComponent = dragPlaceholder.createComponent(TemplateNodeComponent)
        templateNodeComponent.setInput("node", this.$node())
        this.cdr.detectChanges()

        dragImage.appendChild(templateNodeComponent.location.nativeElement)

        const nativeElement = dragPlaceholder.element.nativeElement as HTMLElement

        nativeElement.appendChild(dragImage)

        setTimeout(() => {
            nativeElement.removeChild(dragImage)
        }, 0)

        return dragImage
    }

    dragStart(event: DragEvent) {
        const dragImage = this.getDragImage()

        event.dataTransfer?.setDragImage(dragImage, 0, 0)

        this.drag.dragStart(event, this.$node())
    }
}
