import * as Types from '../../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ScanRegionMaterialFragment = { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, name?: string | null, state: Types.MaterialState, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type ScanRegionMaterialQueryVariables = Types.Exact<{
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type ScanRegionMaterialQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, legacyId: number, articleId?: string | null, name?: string | null, state: Types.MaterialState, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export const ScanRegionMaterialFragmentDoc = gql`
    fragment ScanRegionMaterial on Material {
  id
  legacyId
  articleId
  name
  state
  galleryImage {
    id
  }
}
    `;
export const ScanRegionMaterialDocument = gql`
    query scanRegionMaterial($legacyId: Int, $id: ID) {
  material(legacyId: $legacyId, id: $id) {
    ...ScanRegionMaterial
  }
}
    ${ScanRegionMaterialFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScanRegionMaterialGQL extends Apollo.Query<ScanRegionMaterialQuery, ScanRegionMaterialQueryVariables> {
    override document = ScanRegionMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }