import {TextureType} from "#material-nodes/types"
import {MaterialGraphNode, UnwrappedMaterialGraphNode, wrapNodeOutput, WrappedMaterialGraphNode} from "#material-nodes/material-node-graph"
import {rotateNormalMapVector} from "#material-nodes/material-node-graph-transformations"
import {CubicBezierSpline, Knot, Vector2, Vector2Like, InterpolationMode, OutOfBoundsMode, SampledFunction} from "@cm/math"

export function computeRgbCurveSampledFunction(controlPoints: Vector2Like[]): SampledFunction {
    const numCurveSamples = 256
    const knots = controlPoints.map((point) => new Knot(point))
    const spline = new CubicBezierSpline(knots)
    const points = spline.evaluatePoints(numCurveSamples)
    return new SampledFunction(points, InterpolationMode.Linear, OutOfBoundsMode.Extrapolate)
}

// returns a [lutSize][3] array of numbers which map inputs for R, G and B (mapped between 0 and 1) to their respective output values
export function computeRgbCurveLUT(lutSize: number, getParamFn: (paramName: string) => [number, number] | undefined): number[][] {
    const sampledFunctions: SampledFunction[] = []
    for (let curveIndex = 0; curveIndex < 4; curveIndex++) {
        const controlPoints: Vector2[] = []
        for (let i = 0; ; i++) {
            const pointLocation: [number, number] | undefined = getParamFn(`internal.mapping.curves[${curveIndex}].points[${i}].location`)
            if (!pointLocation) {
                break
            }
            controlPoints.push(new Vector2(pointLocation[0], pointLocation[1]))
        }
        const sampledFunction = computeRgbCurveSampledFunction(controlPoints)
        sampledFunctions.push(sampledFunction)
    }
    const lut: number[][] = new Array(lutSize).fill(0).map(() => new Array(3).fill(0))
    for (let c = 0; c < 3; c++) {
        for (let i = 0; i < lut.length; i++) {
            const input = i / (lut.length - 1)
            const output = sampledFunctions[c].evaluate(sampledFunctions[3].evaluate(input)!)! // we use "extrapolate" so it can not be undefined
            lut[i][c] = output
        }
    }
    return lut
}

function createConstantValue(value: number) {
    return wrapNodeOutput(
        {
            nodeType: "ShaderNodeValue",
            parameters: {
                Value: value,
            },
        },
        "Value",
    )
}

function createConstantColor(color: [number, number, number]) {
    return wrapNodeOutput(
        {
            nodeType: "ShaderNodeRGB",
            parameters: {
                Color: color,
            },
        },
        "Color",
    )
}

function createMappingNode(
    uvNode: WrappedMaterialGraphNode | undefined,
    scale: [number, number, number] = [1, 1, 1],
    rotation: [number, number, number] = [0, 0, 0],
    location: [number, number, number] = [0, 0, 0],
) {
    return wrapNodeOutput(
        {
            nodeType: "Mapping",
            parameters: {
                "internal.vector_type": "TEXTURE",
                Location: location,
                Rotation: rotation,
                Scale: scale,
            },
            inputs: uvNode ? {Vector: uvNode} : undefined,
        },
        "Vector",
    )
}

function createTexImageNodeForNodeImageResourceSlot(
    node: UnwrappedMaterialGraphNode,
    resourceSlotIdx: number,
    mappingNode: WrappedMaterialGraphNode,
    interpolation: "Linear" | "Cubic" | "Closest" | "Smart",
) {
    if (!node.resolvedResources) {
        throw new Error("Expected resolvedResources")
    }
    return wrapNodeOutput(
        {
            nodeType: "TexImage",
            inputs: {
                Vector: mappingNode,
            },
            parameters: {
                "internal.extension": "REPEAT",
                "internal.interpolation": interpolation,
                "internal.projection": "FLAT",
            },
            resolvedResources: [node.resolvedResources[resourceSlotIdx]],
        },
        "Color",
    )
}

export type ImageTextureSetNodes = {
    baseColor: WrappedMaterialGraphNode
    metallic: WrappedMaterialGraphNode
    specular: WrappedMaterialGraphNode
    roughness: WrappedMaterialGraphNode
    anisotropic: WrappedMaterialGraphNode
    anisotropicRotation: WrappedMaterialGraphNode
    alpha: WrappedMaterialGraphNode
    normal: WrappedMaterialGraphNode
    displacement: WrappedMaterialGraphNode
    transmission: WrappedMaterialGraphNode
}

function stripUndefined<T>(record: Record<string, T | undefined>): Record<string, T> {
    const result: Record<string, unknown> = {}
    for (const [key, value] of Object.entries(record)) {
        if (value !== undefined) {
            result[key] = value
        }
    }
    return result as Record<string, T>
}

function mathOp(
    op: string,
    a: WrappedMaterialGraphNode | number | undefined,
    b: WrappedMaterialGraphNode | number | undefined,
    clamp: boolean | undefined = undefined,
) {
    return wrapNodeOutput(
        {
            nodeType: "ShaderNodeMath",
            inputs: stripUndefined({
                Value: typeof a !== "number" ? a : undefined,
                Value_001: typeof b !== "number" ? b : undefined,
            }),
            parameters: stripUndefined({
                "internal.operation": op,
                "internal.use_clamp": clamp,
                Value: typeof a === "number" ? a : undefined,
                Value_001: typeof b === "number" ? b : undefined,
            }),
        },
        "Value",
    )
}

function vectorMathOp(
    op: string,
    a: WrappedMaterialGraphNode | number | [number, number, number] | undefined,
    b: WrappedMaterialGraphNode | number | [number, number, number] | undefined,
) {
    const isAConst = typeof a === "number" || Array.isArray(a)
    const isBConst = typeof b === "number" || Array.isArray(b)
    return wrapNodeOutput(
        {
            nodeType: "ShaderNodeVectorMath",
            inputs: stripUndefined({
                Vector: isAConst ? undefined : a,
                Vector_001: isBConst ? undefined : b,
            }),
            parameters: stripUndefined({
                "internal.operation": op,
                Vector: isAConst ? (typeof a === "number" ? [a, a, a] : a) : undefined,
                Vector_001: isBConst ? (typeof b === "number" ? [b, b, b] : b) : undefined,
            }),
        },
        "Vector",
    )
}

function rescaleAndClamp(value: WrappedMaterialGraphNode, minValue: WrappedMaterialGraphNode | number, maxValue: WrappedMaterialGraphNode | number) {
    return mathOp("DIVIDE", mathOp("SUBTRACT", value, minValue), mathOp("SUBTRACT", maxValue, minValue), true)
}

function rescaleColorValue(color: WrappedMaterialGraphNode, minValue: WrappedMaterialGraphNode | number, maxValue: WrappedMaterialGraphNode | number) {
    const hsv: MaterialGraphNode = {
        nodeType: "ShaderNodeSeparateHSV",
        inputs: {
            Image: color,
        },
    }
    return wrapNodeOutput(
        {
            nodeType: "ShaderNodeCombineHSV",
            inputs: {
                H: wrapNodeOutput(hsv, "H"),
                S: wrapNodeOutput(hsv, "S"),
                V: rescaleAndClamp(wrapNodeOutput(hsv, "V"), minValue, maxValue),
            },
        },
        "Color",
    )
}

function scalarMul(a: WrappedMaterialGraphNode, b: WrappedMaterialGraphNode | number) {
    return mathOp("MULTIPLY", a, b)
}

function vectorAdd(a: WrappedMaterialGraphNode, b: WrappedMaterialGraphNode) {
    return vectorMathOp("ADD", a, b)
}

function vectorSub(a: WrappedMaterialGraphNode, b: WrappedMaterialGraphNode) {
    return vectorMathOp("SUBTRACT", a, b)
}

function vectorMul(a: WrappedMaterialGraphNode, b: WrappedMaterialGraphNode | number) {
    return vectorMathOp("MULTIPLY", a, b)
}

function normalize(vector: WrappedMaterialGraphNode) {
    return vectorMathOp("NORMALIZE", vector, undefined)
}

function adjustWorldNormalStrength(worldNormal: WrappedMaterialGraphNode, strength: WrappedMaterialGraphNode | number) {
    if (strength === 1) {
        return worldNormal
    }
    const geomNormal = wrapNodeOutput({nodeType: "ShaderNodeNewGeometry"}, "Normal")
    if (strength === 0) {
        return geomNormal
    }
    return normalize(vectorAdd(vectorMul(vectorSub(worldNormal, geomNormal), strength), geomNormal))
}

function addShader(a: WrappedMaterialGraphNode, b: WrappedMaterialGraphNode) {
    return wrapNodeOutput(
        {
            nodeType: "ShaderNodeAddShader",
            inputs: {
                Shader: a,
                Shader_001: b,
            },
        },
        "Shader",
    )
}

function applyIfBothDefined<T1, T2, R>(opFn: (a: T1, b: T2) => R, a: T1 | undefined, b: T2 | undefined): T1 | T2 | R | undefined {
    if (a === undefined) {
        return b
    } else if (b === undefined) {
        return a
    } else {
        return opFn(a, b)
    }
}

export function createScannedTransmissionNodes(node: MaterialGraphNode): WrappedMaterialGraphNode {
    if (node.nodeType !== "ShaderNodeScannedTransmission") {
        throw new Error("Expected ShaderNodeScannedTransmission node")
    }
    const bsdf = node.inputs?.BSDF ?? createConstantValue(0.0)
    const alpha = node.inputs?.Alpha as WrappedMaterialGraphNode | undefined
    const normal = node.inputs?.Normal as WrappedMaterialGraphNode | undefined
    const transmission = node.inputs?.Transmission as WrappedMaterialGraphNode | undefined
    const normalStrength = (node.inputs?.NormalStrength ?? node.parameters?.NormalStrength) as WrappedMaterialGraphNode | number | undefined
    const minTransmission = (node.inputs?.MinTransmission ?? node.parameters?.MinTransmission) as WrappedMaterialGraphNode | number | undefined
    const amount = node.parameters?.Amount as WrappedMaterialGraphNode | number | undefined

    if (!transmission) {
        return bsdf
    }

    const translucentBSDF = wrapNodeOutput(
        {
            nodeType: "ShaderNodeBsdfTranslucent",
            inputs: stripUndefined({
                Color: vectorMul(rescaleColorValue(transmission, minTransmission ?? 0, 1), applyIfBothDefined(scalarMul, alpha, amount) ?? 1),
                Normal: normal ? adjustWorldNormalStrength(normal, normalStrength ?? 1) : undefined,
            }),
        },
        "BSDF",
    )
    return addShader(bsdf, translucentBSDF)
}

export function createSetImageTextureNode(node: MaterialGraphNode): WrappedMaterialGraphNode {
    if (node.nodeType !== "ShaderNodeSetTexture") {
        throw new Error("Expected ShaderNodeSetTexture node")
    }

    const slotIndex = 0
    const imageResource = node.resolvedResources?.[slotIndex]
    const widthCm = imageResource?.metadata?.widthCm
    const heightCm = imageResource?.metadata?.heightCm
    if (!widthCm || !heightCm) return createConstantValue(0.0)

    const inUV = node.inputs?.UV
    const mapping = createMappingNode(inUV, [widthCm ?? 1, heightCm ?? 1, 1])
    return createTexImageNodeForNodeImageResourceSlot(node, slotIndex, mapping, "Closest")
}

// this is the original material template transformation for all the texture maps in code (including the initial mapping node)
export function createImageTextureSetNodes(node: MaterialGraphNode): ImageTextureSetNodes {
    if (node.nodeType !== "ShaderNodeTextureSet") {
        throw new Error("Expected ShaderNodeTextureSet node")
    }

    const createDefaultUV = () => wrapNodeOutput({nodeType: "ShaderNodeTexCoord"}, "UV")
    const createDefaultBaseColor = () => createConstantColor([0.5, 0.5, 0.5])
    const createDefaultMetallic = () => createConstantValue(0)
    const createDefaultSpecular = () => createConstantValue(0)
    const createDefaultRoughness = () => createConstantValue(1)
    const createDefaultAnisotropic = () => createConstantValue(0)
    const createDefaultAnisotropicRotation = () => createConstantValue(0)
    const createDefaultAlpha = () => createConstantValue(1)
    const createDefaultNormal = () =>
        wrapNodeOutput(
            {
                nodeType: "ShaderNodeNormalMap",
                inputs: {
                    Color: createConstantColor([0.5, 0.5, 1]),
                },
                parameters: {
                    Strength: 1,
                    attribute: "UVMap0",
                },
            },
            "Normal",
        )
    const createDefaultDisplacement = () => createConstantValue(0)
    const createDefaultTransmission = () => createConstantColor([0, 0, 0])
    const textureSetRevisionId = node.parameters?.TextureSetRevisionId as string | undefined

    const imageResource = node.resolvedResources?.[0]
    const widthCm = imageResource?.metadata?.widthCm
    const heightCm = imageResource?.metadata?.heightCm
    const displacementCm = imageResource?.metadata?.displacementCm

    if (!textureSetRevisionId || !widthCm || !heightCm) {
        return {
            baseColor: createDefaultBaseColor(),
            metallic: createDefaultMetallic(),
            specular: createDefaultSpecular(),
            roughness: createDefaultRoughness(),
            anisotropic: createDefaultAnisotropic(),
            anisotropicRotation: createDefaultAnisotropicRotation(),
            alpha: createDefaultAlpha(),
            normal: createDefaultNormal(),
            displacement: createDefaultDisplacement(),
            transmission: createDefaultTransmission(),
        }
    }

    const inUV = node.inputs?.UV ?? createDefaultUV()
    const mapping = createMappingNode(inUV, [widthCm, heightCm, 1])
    const inNormalStrength = node.inputs?.NormalStrength ?? createConstantValue(node.parameters?.NormalStrength ?? 1)

    const createTexImageForTextureType = (textureType: TextureType) => {
        const slotIndex = node.resolvedResources?.findIndex((resource) => resource.metadata?.textureType === textureType)
        return typeof slotIndex === "number" && slotIndex >= 0
            ? createTexImageNodeForNodeImageResourceSlot(node, slotIndex, mapping, textureType === TextureType.Displacement ? "Cubic" : "Closest")
            : undefined
    }

    const createAnisotropicFromAnisotrophy = (colorMassAnisotrophy: WrappedMaterialGraphNode | undefined) => {
        if (!colorMassAnisotrophy) {
            return undefined
        }
        return wrapNodeOutput(
            {
                nodeType: "ShaderNodeVectorMath",
                inputs: {
                    Vector: wrapNodeOutput(
                        {
                            nodeType: "ShaderNodeVectorMath",
                            inputs: {
                                Vector: wrapNodeOutput(
                                    {
                                        nodeType: "ShaderNodeVectorMath",
                                        inputs: {
                                            Vector: colorMassAnisotrophy,
                                        },
                                        parameters: {
                                            "internal.operation": "ADD",
                                            Vector_001: [-0.5, -0.5, 0],
                                        },
                                    },
                                    "Vector",
                                ),
                            },
                            parameters: {
                                "internal.operation": "MULTIPLY",
                                Vector_001: [2, -2, 0],
                            },
                        },
                        "Vector",
                    ),
                },
                parameters: {
                    "internal.operation": "LENGTH",
                },
            },
            "Value",
        )
    }
    const createAnisotropicRotationFromAnisotrophy = (colorMassAnisotrophy: WrappedMaterialGraphNode | undefined) => {
        if (!colorMassAnisotrophy) {
            return undefined
        }
        const xyz: UnwrappedMaterialGraphNode = {
            nodeType: "ShaderNodeSeparateXYZ",
            inputs: {
                Vector: wrapNodeOutput(
                    {
                        nodeType: "ShaderNodeVectorMath",
                        inputs: {
                            Vector: wrapNodeOutput(
                                {
                                    nodeType: "ShaderNodeVectorMath",
                                    inputs: {
                                        Vector: colorMassAnisotrophy,
                                    },
                                    parameters: {
                                        "internal.operation": "ADD",
                                        Vector_001: [-0.5, -0.5, 0],
                                    },
                                },
                                "Vector",
                            ),
                        },
                        parameters: {
                            "internal.operation": "MULTIPLY",
                            Vector_001: [2, -2, 0],
                        },
                    },
                    "Vector",
                ),
            },
        }
        const angle = wrapNodeOutput(
            {
                nodeType: "ShaderNodeMath",
                inputs: {
                    Value: wrapNodeOutput(xyz, "Y"),
                    Value_001: wrapNodeOutput(xyz, "X"),
                },
                parameters: {
                    "internal.operation": "ARCTAN2",
                },
            },
            "Value",
        )
        return wrapNodeOutput(
            {
                nodeType: "ShaderNodeMath",
                inputs: {
                    Value: wrapNodeOutput(
                        {
                            nodeType: "ShaderNodeMath",
                            inputs: {
                                Value: wrapNodeOutput(
                                    {
                                        nodeType: "ShaderNodeMath",
                                        inputs: {
                                            Value: wrapNodeOutput(
                                                {
                                                    nodeType: "ShaderNodeMath",
                                                    inputs: {
                                                        Value: angle,
                                                    },
                                                    parameters: {
                                                        "internal.operation": "GREATER_THAN",
                                                        Value_001: 0,
                                                    },
                                                },
                                                "Value",
                                            ),
                                            Value_001: angle,
                                        },
                                        parameters: {
                                            "internal.operation": "MULTIPLY",
                                        },
                                    },
                                    "Value",
                                ),
                                Value_001: wrapNodeOutput(
                                    {
                                        nodeType: "ShaderNodeMath",
                                        inputs: {
                                            Value: wrapNodeOutput(
                                                {
                                                    nodeType: "ShaderNodeMath",
                                                    inputs: {
                                                        Value: angle,
                                                    },
                                                    parameters: {
                                                        "internal.operation": "LESS_THAN",
                                                        Value_001: 0,
                                                    },
                                                },
                                                "Value",
                                            ),
                                            Value_001: wrapNodeOutput(
                                                {
                                                    nodeType: "ShaderNodeMath",
                                                    inputs: {
                                                        Value: angle,
                                                    },
                                                    parameters: {
                                                        "internal.operation": "ADD",
                                                        Value_001: Math.PI * 2,
                                                    },
                                                },
                                                "Value",
                                            ),
                                        },
                                        parameters: {
                                            "internal.operation": "MULTIPLY",
                                        },
                                    },
                                    "Value",
                                ),
                            },
                            parameters: {
                                "internal.operation": "ADD",
                            },
                        },
                        "Value",
                    ),
                },
                parameters: {
                    "internal.operation": "DIVIDE",
                    Value_001: Math.PI * 2,
                },
            },
            "Value",
        )
    }
    const convertNormal = (normalMap: WrappedMaterialGraphNode | undefined, normalRotation: number) => {
        if (!normalMap) {
            return undefined
        }
        return wrapNodeOutput(
            {
                nodeType: "ShaderNodeNormalMap",
                inputs: {
                    Color: rotateNormalMapVector(
                        wrapNodeOutput(
                            {
                                nodeType: "ShaderNodeRGBCurve",
                                inputs: {
                                    Color: normalMap,
                                },
                                parameters: {
                                    Fac: 1,
                                    // R
                                    "internal.mapping.curves[0].points[0].location": [0, 0],
                                    "internal.mapping.curves[0].points[1].location": [1, 1],
                                    // G (inverted)
                                    "internal.mapping.curves[1].points[0].location": [0, 1],
                                    "internal.mapping.curves[1].points[1].location": [1, 0],
                                    // B
                                    "internal.mapping.curves[2].points[0].location": [0, 0],
                                    "internal.mapping.curves[2].points[1].location": [1, 1],
                                    // RGB
                                    "internal.mapping.curves[3].points[0].location": [0, 0],
                                    "internal.mapping.curves[3].points[1].location": [1, 1],
                                },
                            },
                            "Color",
                        ),
                        normalRotation,
                    ),
                    Strength: inNormalStrength,
                },
            },
            "Normal",
        )
    }
    const convertDisplacement = (displacementMap: WrappedMaterialGraphNode | undefined) => {
        if (!displacementMap) {
            return undefined
        }

        return wrapNodeOutput(
            {
                nodeType: "ShaderNodeDisplacement",
                inputs: {
                    Height: displacementMap,
                    Scale: wrapNodeOutput(
                        {
                            nodeType: "ShaderNodeMath",
                            inputs: {
                                Value: inNormalStrength,
                            },
                            parameters: {
                                "internal.operation": "MULTIPLY",
                                Value_001: displacementCm ?? 0,
                            },
                        },
                        "Value",
                    ),
                },
                parameters: {
                    MidLevel: 0.5,
                },
            },
            "Displacement",
        )
    }
    const createMetallicFromF0 = (colormassF0: WrappedMaterialGraphNode | undefined) => {
        if (!colormassF0) {
            return undefined
        }
        return wrapNodeOutput(
            {
                nodeType: "ShaderNodeSeparateRGB",
                inputs: {
                    Image: colormassF0,
                },
            },
            "G",
        )
    }
    const createSpecularFromF0 = (colormassF0: WrappedMaterialGraphNode | undefined) => {
        if (!colormassF0) {
            return undefined
        }
        return wrapNodeOutput(
            {
                nodeType: "ShaderNodeSeparateRGB",
                inputs: {
                    Image: colormassF0,
                },
            },
            "R",
        )
    }
    const createAnisotropicFromAnisotrophyStrength = (colorMassAnisotrophyStrength: WrappedMaterialGraphNode | undefined) => {
        if (!colorMassAnisotrophyStrength) {
            return undefined
        }
        return wrapNodeOutput(
            {
                nodeType: "ShaderNodeMath",
                inputs: {
                    Value: wrapNodeOutput(
                        {
                            nodeType: "ShaderNodeMath",
                            inputs: {
                                Value: colorMassAnisotrophyStrength,
                            },
                            parameters: {
                                "internal.operation": "ADD",
                                Value_001: -0.5,
                            },
                        },
                        "Value",
                    ),
                },
                parameters: {
                    "internal.operation": "MULTIPLY",
                    Value_001: 2,
                },
            },
            "Value",
        )
    }

    // current set
    const sourceMapDiffuse = createTexImageForTextureType(TextureType.Diffuse)
    const sourceMapMetalness = createTexImageForTextureType(TextureType.Metalness)
    const sourceMapSpecularStrength = createTexImageForTextureType(TextureType.SpecularStrength)
    const sourceMapRoughness = createTexImageForTextureType(TextureType.Roughness)
    const sourceMapAnisotrophy = createTexImageForTextureType(TextureType.Anisotropy)
    const sourceMapNormal = createTexImageForTextureType(TextureType.Normal)
    const sourceMapDisplacement = createTexImageForTextureType(TextureType.Displacement)
    // legacy set
    const sourceMapF0 = createTexImageForTextureType(TextureType.F0)
    const sourceMapAnisotrophyStrength = createTexImageForTextureType(TextureType.AnisotropyStrength)
    const sourceMapAnisotrophyRotation = createTexImageForTextureType(TextureType.AnisotropyRotation)
    // special maps
    const sourceMapMask = createTexImageForTextureType(TextureType.Mask)
    const sourceMapTransmission = createTexImageForTextureType(TextureType.Transmission)

    let normalRotation = 0
    if (node.parameters && typeof node.parameters["internal.normal_rotation"] === "number") {
        normalRotation = node.parameters["internal.normal_rotation"]
    }

    const convertTransmissionToAlpha = (transmissionMap: WrappedMaterialGraphNode | undefined, minValue: number, maxValue: number, useLuminance = false) => {
        if (!transmissionMap) {
            return undefined
        }
        // rescale and invert ([minValue...maxValue] -> [1...0])
        return wrapNodeOutput(
            {
                nodeType: "ShaderNodeMath",
                inputs: {
                    Value: wrapNodeOutput(
                        {
                            nodeType: "ShaderNodeMath",
                            inputs: {
                                Value: useLuminance
                                    ? wrapNodeOutput(
                                          {
                                              nodeType: "ShaderNodeRGBToBW",
                                              inputs: {
                                                  Color: transmissionMap,
                                              },
                                          },
                                          "Value",
                                      )
                                    : wrapNodeOutput(
                                          {
                                              nodeType: "ShaderNodeSeparateHSV",
                                              inputs: {
                                                  Image: transmissionMap,
                                              },
                                          },
                                          "V",
                                      ),
                            },
                            parameters: {
                                "internal.operation": "ADD",
                                "internal.use_clamp": false,
                                Value_001: -maxValue, // offset by _max_ value, since the output is inverted
                            },
                        },
                        "Value",
                    ),
                },
                parameters: {
                    "internal.operation": "MULTIPLY",
                    "internal.use_clamp": true,
                    Value_001: 1 / (minValue - maxValue),
                },
            },
            "Value",
        )
    }

    const transmissionMin = (node.parameters?.TransmissionMin as number | undefined) ?? 0
    const transmissionMax = (node.parameters?.TransmissionMax as number | undefined) ?? 1

    // create the nodes
    const baseColor = sourceMapDiffuse ?? createDefaultBaseColor()
    const metallic = sourceMapMetalness ?? createMetallicFromF0(sourceMapF0) ?? createDefaultMetallic()
    const specular = sourceMapSpecularStrength ?? createSpecularFromF0(sourceMapF0) ?? createDefaultSpecular()
    const roughness = sourceMapRoughness ?? createDefaultRoughness()
    const anisotropic =
        createAnisotropicFromAnisotrophy(sourceMapAnisotrophy) ??
        createAnisotropicFromAnisotrophyStrength(sourceMapAnisotrophyStrength) ??
        createDefaultAnisotropic()
    const anisotropicRotation =
        createAnisotropicRotationFromAnisotrophy(sourceMapAnisotrophy) ?? sourceMapAnisotrophyRotation ?? createDefaultAnisotropicRotation()
    const alpha = sourceMapMask ?? convertTransmissionToAlpha(sourceMapTransmission, transmissionMin, transmissionMax) ?? createDefaultAlpha()
    const normal = convertNormal(sourceMapNormal, normalRotation) ?? createDefaultNormal()
    const displacement = convertDisplacement(sourceMapDisplacement) ?? createDefaultDisplacement()
    const transmission = sourceMapTransmission ?? createDefaultTransmission()

    return {
        baseColor,
        metallic,
        specular,
        roughness,
        anisotropic,
        anisotropicRotation,
        alpha,
        normal,
        displacement,
        transmission,
    }
}
