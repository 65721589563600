import {inject, Injectable} from "@angular/core"
import {EndpointUrls} from "@app/common/models/constants/urls"
import {HdriDetailsFragment, UpdateHdriDetailsGQL} from "@app/platform/components/hdris/hdri-details/hdri-details.generated"
import {HdriForEditorSceneGQL} from "@app/platform/components/hdris/hdri-service/hdri.generated"
import {contentTypeForExtension, extensionForContentType as extensionForMediaType, ExtensionT as Extension} from "@cm/utils/content-types"
import {MediaTypeSchema} from "@cm/utils/data-object"
import {IdDetails} from "@cm/utils/id-details"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {UtilsService} from "@legacy/helpers/utils"
import {firstValueFrom} from "rxjs"
import {z} from "zod"

export interface IHdriManager {
    getHdriAsBufferAndExtension(hdriIdDetails: IdDetails): Promise<{buffer: Uint8Array; extension: Extension | "hdr"} | null>
}

@Injectable({
    providedIn: "root",
})
export class HdriService implements IHdriManager {
    private readonly hdriForEditorScene = inject(HdriForEditorSceneGQL)
    private readonly utilsService = inject(UtilsService)
    private readonly uploadService = inject(UploadGqlService)
    private readonly updateHdriDetails = inject(UpdateHdriDetailsGQL)

    async getHdriAsBufferAndExtension(hdriIdDetails: IdDetails) {
        const details = (await fetchThrowingErrors(this.hdriForEditorScene)(hdriIdDetails)).hdri
        const parsedDetails = z.object({dataObject: z.object({mediaType: MediaTypeSchema, bucketName: z.string(), objectName: z.string()})}).safeParse(details)
        if (!parsedDetails.success) return null

        const buffer = await firstValueFrom(
            this.utilsService.getResourceAsBuffer(
                EndpointUrls.GoogleStorage(parsedDetails.data.dataObject.bucketName, parsedDetails.data.dataObject.objectName),
            ),
        )

        // If the media type is application/octet-stream we assume it's an HDRI
        const extension =
            parsedDetails.data.dataObject.mediaType === "application/octet-stream"
                ? ("hdr" as const)
                : extensionForMediaType(parsedDetails.data.dataObject.mediaType)
        return {buffer: new Uint8Array(buffer), extension}
    }

    async uploadHdriFromFile(file: File, hdriDetails: Pick<HdriDetailsFragment, "id" | "organization">) {
        const contentType = contentTypeForExtension(file.name.split(".").pop() ?? "")
        const dataObject = await this.uploadService.createAndUploadDataObject(
            file,
            {
                mediaType: contentType,
                organizationId: hdriDetails.organization.id,
            },
            {showUploadToolbar: true, processUpload: true},
        )
        return mutateThrowingErrors(this.updateHdriDetails)({input: {id: hdriDetails.id, dataObjectId: dataObject.id}})
    }
}
