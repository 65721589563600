import {MultichannelLayouts, TypedImageData} from "@cm/utils/typed-image-data"
import {ImageProcessingNodes, ImageProcessingNodes as Nodes} from "@cm/image-processing-nodes"
import {ImageProcessingLibvips} from "#image-processing/image-processing-libvips"
import {CryptomatteId, CryptomatteManifest, MatteProcessing} from "#image-processing/matte-processing"
import {compileLUT, linearToSrgbNoClip, loadCubeLUT, LUTData, srgbToLinearNoClip} from "#image-processing/tone-mapping"
import {createImageData, getBytesPerPixel} from "#image-processing/utils/typed-image-data-utils"
import {Color, ColorLike, Matrix3x2, Size2Like, Vector2, Vector2Like} from "@cm/math"
import {
    assertNever,
    castToFloat32Array,
    castToUint16Array,
    castToUint8Array,
    CmLogger,
    halfToFloatArray,
    mapFields,
    promiseAllProperties,
    wrap,
    zip,
} from "@cm/utils"
import {compileFunctionGraph, FunctionInfo} from "@cm/utils/function-graph-compiler"

const _fetch = fetch

const colorTempTableR = [
    2.52432244e3, -1.06185848e-3, 3.11067539, 3.37763626e3, -4.34581697e-4, 1.64843306, 4.10671449e3, -8.61949938e-5, 6.41423749e-1, 4.668498e3, 2.85655028e-5,
    1.29075375e-1, 4.6012477e3, 2.89727618e-5, 1.48001316e-1, 3.78765709e3, 9.36026367e-6, 3.98995841e-1,
]

const colorTempTableG = [
    -7.50343014e2, 3.15679613e-4, 4.73464526e-1, -1.00402363e3, 1.29189794e-4, 9.08181524e-1, -1.22075471e3, 2.56245413e-5, 1.20753416, -1.42546105e3,
    -4.01730887e-5, 1.44002695, -1.18134453e3, -2.18913373e-5, 1.30656109, -5.00279505e2, -4.5974539e-6, 1.09090465,
]

const colorTempTableB = [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, -2.02524603e-11, 1.7943586e-7, -2.60561875e-4, -1.41761141e-2, -2.22463426e-13, -1.55078698e-8,
    3.8167516e-4, -7.30646033e-1, 6.72595954e-13, -2.73059993e-8, 4.24068546e-4, -7.52204323e-1,
]

function allNearOne(...xs: number[]) {
    for (const x of xs) {
        if (Math.abs(1 - x) > 1e-6) return false
    }
    return true
}

type CompiledFunctionInfo = FunctionInfo & {engine?: "libvips"}

export namespace ImageProcessing {
    export type ImageData = TypedImageData
    export type MaskData = ImageData & {channelLayout: "L"}

    export type ImageIOEncoder = (image: ImageProcessing.ImageData, options?: unknown) => Promise<Uint8Array> | Uint8Array
    export type ImageIODecoder = (data: Uint8Array, options?: unknown) => Promise<ImageProcessing.ImageData> | ImageProcessing.ImageData

    export type ImageIOFunctionEntry = {
        encoder?: ImageIOEncoder
        decoder?: ImageIODecoder
    }
    export type ImageIOFunctions = Record<string, ImageIOFunctionEntry>

    type RGBColor = Nodes.RGBColor
    type RGBAColor = Nodes.RGBAColor
    type BBox = Nodes.BBox
    type Vec2 = Nodes.Vec2

    /**
     * Helper class to iterate over the pixels of an image of different channel layouts as well as constants in a unified way.
     * Supports single constants (number), single pixel images (ImageData with width=1 and height=1) and multi-pixel images for single and multi-channel layouts.
     */
    class SourcePixelData {
        constructor(value: number | ImageData) {
            if (typeof value === "number") {
                this.buffer = new Float32Array([value])
                this.offsetR = 0
                this.offsetG = 0
                this.offsetB = 0
                this.offsetA = 0
                this.pixelStride = 0
            } else {
                this.buffer = getImageAsFloat32Array(value)
                this.pixelStride = value.width > 1 || value.height > 1 ? value.channelLayout.length : 0
                this.offsetR = 0
                this.offsetG = this.pixelStride > 1 ? 1 : 0
                this.offsetB = this.pixelStride > 2 ? 2 : 0
                this.offsetA = this.pixelStride > 3 ? 3 : 0
            }
        }

        get r(): number {
            return this.buffer[this.currPos + this.offsetR]
        }

        get g(): number {
            return this.buffer[this.currPos + this.offsetG]
        }

        get b(): number {
            return this.buffer[this.currPos + this.offsetB]
        }

        get a(): number {
            return this.buffer[this.currPos + this.offsetA]
        }

        nextPixel() {
            this.currPos += this.pixelStride
        }

        private buffer: Float32Array
        private offsetR: number
        private offsetG: number
        private offsetB: number
        private offsetA: number
        private pixelStride: number
        private currPos = 0
    }

    // in case the image is float32 it will return the image buffer, otherwise it will return a converted copy
    function getImageAsFloat32Array(image: ImageData): Float32Array {
        if (image.dataType === "float32") {
            return castToFloat32Array(image.data)
        } else if (image.dataType === "float16") {
            return halfToFloatArray(image.data)
        } else if (image.dataType === "uint16") {
            return uint16ToFloat32(castToUint16Array(image.data))
        } else if (image.dataType === "uint8") {
            return uint8ToFloat32(castToUint8Array(image.data))
        } else {
            throw new Error(`Invalid image data type: ${image.dataType}`)
        }
    }

    // in case the image is float32 it will return the image buffer, otherwise it will return a converted copy
    function getImageAsRGBAFloat32Array(image: ImageData): Float32Array {
        const dataFloat32 = getImageAsFloat32Array(image)
        if (image.channelLayout === "RGBA") {
            return dataFloat32
        } else {
            if (image.channelLayout !== "RGB" && image.channelLayout !== "L") {
                throw new Error(`getImageAsRGBAFloat32Array: Unsupported source image channel layout: ${image.channelLayout}`)
            }
            const stride = image.channelLayout.length
            const length = dataFloat32.length / stride
            const buffer = new Float32Array(length * 4)
            for (let idx = 0; idx < length; idx++) {
                const r = dataFloat32[idx * stride + 0]
                const g = stride > 1 ? dataFloat32[idx * stride + 1] : r
                const b = stride > 2 ? dataFloat32[idx * stride + 2] : r
                const a = stride > 3 ? dataFloat32[idx * stride + 3] : 1
                buffer[idx * 4 + 0] = r
                buffer[idx * 4 + 1] = g
                buffer[idx * 4 + 2] = b
                buffer[idx * 4 + 3] = a
            }
            return buffer
        }
    }

    export function float32ToUint8(input: Float32Array, output?: Uint8Array, dither = true): Uint8Array {
        const length = input.length
        output ??= new Uint8Array(length)
        const clamped = new Uint8ClampedArray(output.buffer, output.byteOffset, output.byteLength)
        if (dither) {
            for (let idx = 0; idx < length; idx++) {
                clamped[idx] = Math.floor(input[idx] * 0xff + Math.random()) // Math.random is the best combination of speed+quality, at least on Chrome...
            }
        } else {
            for (let idx = 0; idx < length; idx++) {
                clamped[idx] = Math.floor(input[idx] * 0xff)
            }
        }
        return output
    }

    function float32ToUint16(input: Float32Array, output?: Uint16Array, dither = true): Uint16Array {
        const length = input.length
        output ??= new Uint16Array(length)
        if (dither) {
            for (let idx = 0; idx < length; idx++) {
                let x = input[idx]
                x = x * 0xffff + Math.random()
                if (x < 0) x = 0
                if (x > 0xffff) x = 0xffff
                output[idx] = x
            }
        } else {
            for (let idx = 0; idx < length; idx++) {
                let x = input[idx]
                if (x < 0) x = 0
                if (x > 1) x = 1
                output[idx] = x * 0xffff
            }
        }
        return output
    }

    function uint8ToFloat32(input: Uint8Array, output?: Float32Array): Float32Array {
        const length = input.length
        output ??= new Float32Array(length)
        const sc = 1 / 0xff
        for (let idx = 0; idx < length; idx++) {
            output[idx] = input[idx] * sc
        }
        return output
    }

    function uint16ToFloat32(input: Uint16Array, output?: Float32Array): Float32Array {
        const length = input.length
        output ??= new Float32Array(length)
        const sc = 1 / 0xffff
        for (let idx = 0; idx < length; idx++) {
            output[idx] = input[idx] * sc
        }
        return output
    }

    export function uint16ToUint8(input: Uint16Array, output?: Uint8Array, dither = true): Uint8Array {
        const length = input.length
        output ??= new Uint8Array(length)
        const clamped = new Uint8ClampedArray(output.buffer, output.byteOffset, output.byteLength)
        if (dither) {
            for (let idx = 0; idx < length; idx++) {
                clamped[idx] = (input[idx] >> 8) + Math.random() // Math.random is the best combination of speed+quality, at least on Chrome...
            }
        } else {
            for (let idx = 0; idx < length; idx++) {
                clamped[idx] = input[idx] >> 8
            }
        }
        return output
    }

    function getImageBufferRGBAFloat32(image: ImageData): Float32Array {
        if (image.dataType !== "float32") {
            throw new Error(`Invalid image data type: ${image.dataType}`)
        } else if (image.channelLayout !== "RGBA") {
            throw new Error(`Invalid image channel layout: ${image.channelLayout}`)
        }
        return castToFloat32Array(image.data)
    }

    function getImageBufferFloat32(image: ImageData): Float32Array {
        if (image.dataType !== "float32") {
            throw new Error(`Invalid image data type: ${image.dataType}`)
        }
        return castToFloat32Array(image.data)
    }

    function getImageBufferLFloat32(image: ImageData): Float32Array {
        if (image.dataType !== "float32") {
            throw new Error(`Invalid image data type: ${image.dataType}`)
        } else if (image.channelLayout !== "L") {
            throw new Error(`Invalid image channel layout: ${image.channelLayout}`)
        }
        return castToFloat32Array(image.data)
    }

    function checkSameSize(a: ImageData, b: ImageData): void {
        if (a.width !== b.width || a.height !== b.height) throw new Error("Image sizes do not match")
    }

    function ensureOutputLike(image: Omit<ImageData, "data">, output: ImageData | undefined): ImageData {
        if (!output) {
            output = createImageData(image.width, image.height, image.channelLayout, image.dataType, image.colorSpace, image.dpi)
        }
        return output
    }

    type ProcessFunctionInfo = Omit<CompiledFunctionInfo, "fn">
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    const processFunctionInfoMap = new Map<Function, ProcessFunctionInfo>()

    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    function registerProcessFunction(fn: Function, info: ProcessFunctionInfo) {
        processFunctionInfoMap.set(fn, info)
    }

    function _colorTemperatureToRGB(t: number): RGBColor {
        if (t >= 12000) return [0.82627, 0.994479, 1.56626] as const
        else if (t < 965) return [4.70367, 0, 0] as const
        const i = t >= 6365.0 ? 5 : t >= 3315.0 ? 4 : t >= 1902.0 ? 3 : t >= 1449.0 ? 2 : t >= 1167.0 ? 1 : 0
        const r0 = colorTempTableR[i * 3 + 0]
        const r1 = colorTempTableR[i * 3 + 1]
        const r2 = colorTempTableR[i * 3 + 2]
        const g0 = colorTempTableG[i * 3 + 0]
        const g1 = colorTempTableG[i * 3 + 1]
        const g2 = colorTempTableG[i * 3 + 2]
        const b0 = colorTempTableB[i * 4 + 0]
        const b1 = colorTempTableB[i * 4 + 1]
        const b2 = colorTempTableB[i * 4 + 2]
        const b3 = colorTempTableB[i * 4 + 3]
        const it = 1 / t
        let r = r0 * it + r1 * t + r2
        let g = g0 * it + g1 * t + g2
        let b = ((b0 * t + b1) * t + b2) * t + b3
        const max = Math.max(r, g, b)
        r /= max
        g /= max
        b /= max
        return [r, g, b] as const
    }

    function colorTemperatureToRGB(t: number, refT = 6500): RGBColor {
        const [refR, refG, refB] = _colorTemperatureToRGB(refT)
        let [r, g, b] = _colorTemperatureToRGB(t)
        r = r / refR
        g = g / refG
        b = b / refB
        const sc = 1 / Math.max(r, g, b)
        r *= sc
        g *= sc
        b *= sc
        return [r, g, b] as const
    }

    function copy(image: ImageData, output?: ImageData): ImageData {
        if (image === output) return output
        return {
            ...image,
            data: castToFloat32Array(image.data).slice(0),
        }
    }

    registerProcessFunction(copy, {inPlaceArgs: [0], memCost: 1, cpuCost: 0.1})

    function scaleRGB(image: ImageData, [r, g, b]: [number, number, number], output?: ImageData): ImageData {
        if (allNearOne(r, g, b)) return copy(image, output)
        output = ensureOutputLike(image, output)
        const buffer = getImageBufferRGBAFloat32(image)
        const outBuffer = getImageBufferRGBAFloat32(output)
        const length = buffer.length
        for (let idx = 0; idx < length; idx += 4) {
            outBuffer[idx + 0] = buffer[idx + 0] * r
            outBuffer[idx + 1] = buffer[idx + 1] * g
            outBuffer[idx + 2] = buffer[idx + 2] * b
            outBuffer[idx + 3] = buffer[idx + 3]
        }
        return output
    }

    registerProcessFunction(scaleRGB, {inPlaceArgs: [0], memCost: 1, cpuCost: 0.2})

    function scaleRGBA(image: ImageData, [r, g, b, a]: [number, number, number, number], output?: ImageData): ImageData {
        if (allNearOne(r, g, b)) return copy(image, output)
        output = ensureOutputLike(image, output)
        const buffer = getImageBufferRGBAFloat32(image)
        const outBuffer = getImageBufferRGBAFloat32(output)
        const length = buffer.length
        r *= a
        g *= a
        b *= a
        for (let idx = 0; idx < length; idx += 4) {
            outBuffer[idx + 0] = buffer[idx + 0] * r
            outBuffer[idx + 1] = buffer[idx + 1] * g
            outBuffer[idx + 2] = buffer[idx + 2] * b
            outBuffer[idx + 3] = buffer[idx + 3] * a
        }
        return output
    }

    registerProcessFunction(scaleRGBA, {inPlaceArgs: [0], memCost: 1, cpuCost: 0.2})

    function adjustExposure(image: ImageData, ev: number, output?: ImageData): ImageData {
        const sc = Math.pow(2, ev)
        return scaleRGB(image, [sc, sc, sc], output)
    }

    registerProcessFunction(adjustExposure, {inPlaceArgs: [0], memCost: 1, cpuCost: 0.2})

    function whiteBalance(image: ImageData, whitePoint: RGBColor, output?: ImageData): ImageData {
        const sr = 1 / whitePoint[0]
        const sg = 1 / whitePoint[1]
        const sb = 1 / whitePoint[2]
        return scaleRGB(image, [sr, sg, sb], output)
    }

    registerProcessFunction(whiteBalance, {inPlaceArgs: [0], memCost: 1, cpuCost: 0.2})

    function linearToSrgb(image: ImageData, output?: ImageData): ImageData {
        if (image.colorSpace === "sRGB") return image
        output = ensureOutputLike(image, output)
        if (image.channelLayout === "L") {
            const buffer = getImageBufferLFloat32(image)
            const outBuffer = getImageBufferLFloat32(output)
            const length = buffer.length
            for (let idx = 0; idx < length; ++idx) {
                outBuffer[idx] = linearToSrgbNoClip(buffer[idx])
            }
        } else {
            const buffer = getImageBufferRGBAFloat32(image)
            const outBuffer = getImageBufferRGBAFloat32(output)
            const length = buffer.length
            for (let idx = 0; idx < length; idx += 4) {
                outBuffer[idx + 0] = linearToSrgbNoClip(buffer[idx + 0])
                outBuffer[idx + 1] = linearToSrgbNoClip(buffer[idx + 1])
                outBuffer[idx + 2] = linearToSrgbNoClip(buffer[idx + 2])
                outBuffer[idx + 3] = buffer[idx + 3]
            }
        }
        return {...output, colorSpace: "sRGB"}
    }

    registerProcessFunction(linearToSrgb, {inPlaceArgs: [0], memCost: 1, cpuCost: 0.5})

    function srgbToLinear(image: ImageData, output?: ImageData): ImageData {
        if (image.colorSpace === "linear") return image
        output = ensureOutputLike(image, output)
        if (image.channelLayout === "L") {
            const buffer = getImageBufferLFloat32(image)
            const outBuffer = getImageBufferLFloat32(output)
            const length = buffer.length
            for (let idx = 0; idx < length; ++idx) {
                outBuffer[idx] = srgbToLinearNoClip(buffer[idx])
            }
        } else if (image.channelLayout === "RGB") {
            const buffer = getImageBufferFloat32(image)
            const outBuffer = getImageBufferFloat32(output)
            const length = buffer.length
            for (let idx = 0; idx < length; idx += 3) {
                outBuffer[idx + 0] = srgbToLinearNoClip(buffer[idx + 0])
                outBuffer[idx + 1] = srgbToLinearNoClip(buffer[idx + 1])
                outBuffer[idx + 2] = srgbToLinearNoClip(buffer[idx + 2])
            }
        } else if (image.channelLayout === "RGBA") {
            const buffer = getImageBufferFloat32(image)
            const outBuffer = getImageBufferFloat32(output)
            const length = buffer.length
            for (let idx = 0; idx < length; idx += 4) {
                outBuffer[idx + 0] = srgbToLinearNoClip(buffer[idx + 0])
                outBuffer[idx + 1] = srgbToLinearNoClip(buffer[idx + 1])
                outBuffer[idx + 2] = srgbToLinearNoClip(buffer[idx + 2])
                outBuffer[idx + 3] = buffer[idx + 3]
            }
        } else {
            throw Error(`Unsupported channel layout: ${image.channelLayout}`)
        }
        return {...output, colorSpace: "linear"}
    }

    registerProcessFunction(srgbToLinear, {inPlaceArgs: [0], memCost: 1, cpuCost: 0.5})

    function applyLUT(image: ImageData, LUT: LUTData, output?: ImageData): ImageData {
        const inGain = 1.0
        const mode: "closest" | "trilinear" | "tetrahedral" = "tetrahedral" as any

        output = ensureOutputLike(image, output)
        const buffer = getImageBufferRGBAFloat32(image)
        const outBuffer = getImageBufferRGBAFloat32(output)

        if (LUT.channels !== 3) {
            throw new Error("TODO: lut channels != 3")
        }

        const sz = Math.round(LUT.size)
        const sz_1 = Math.round(LUT.size - 1)
        const lutData = LUT.data
        const inScale = Math.fround((inGain * sz_1) / LUT.range)

        const zStride = sz * sz * 3
        const yStride = sz * 3
        const xStride = 3

        const minMaxMapped = (v: number, i: number) => {
            if (v < 0) return 0
            else if (v > sz_1) return 1
            else return lutData[i]
        }

        const getLUTValue = (x: number, y: number, z: number) => {
            const xi = Math.max(0, Math.min(sz_1, x))
            const yi = Math.max(0, Math.min(sz_1, y))
            const zi = Math.max(0, Math.min(sz_1, z))
            const i = xi * xStride + yi * yStride + zi * zStride

            return [minMaxMapped(x, i + 0), minMaxMapped(y, i + 1), minMaxMapped(z, i + 2)]
        }

        if (mode === "closest") {
            for (let ofs = 0; ofs < buffer.length; ofs += 4) {
                const x = buffer[ofs + 0] * inScale // x = input red
                const y = buffer[ofs + 1] * inScale // y = input green
                const z = buffer[ofs + 2] * inScale // z = input blue
                const [r, g, b] = getLUTValue(Math.round(x), Math.round(y), Math.round(z))
                outBuffer[ofs + 0] = r
                outBuffer[ofs + 1] = g
                outBuffer[ofs + 2] = b
                outBuffer[ofs + 3] = buffer[ofs + 3]
            }
        } else if (mode === "trilinear") {
            // trilinear interpolation of 3d LUT for silky smooth colors ヽ(°◇° )ノ
            for (let ofs = 0; ofs < buffer.length; ofs += 4) {
                const x = buffer[ofs + 0] * inScale // x = input red
                const y = buffer[ofs + 1] * inScale // y = input green
                const z = buffer[ofs + 2] * inScale // z = input blue
                const x0i = Math.floor(x)
                const y0i = Math.floor(y)
                const z0i = Math.floor(z)
                const x1i = Math.ceil(x)
                const y1i = Math.ceil(y)
                const z1i = Math.ceil(z)

                const [r000, g000, b000] = getLUTValue(x0i, y0i, z0i)
                const [r001, g001, b001] = getLUTValue(x0i, y0i, z1i)
                const [r010, g010, b010] = getLUTValue(x0i, y1i, z0i)
                const [r011, g011, b011] = getLUTValue(x0i, y1i, z1i)
                const [r100, g100, b100] = getLUTValue(x1i, y0i, z0i)
                const [r101, g101, b101] = getLUTValue(x1i, y0i, z1i)
                const [r110, g110, b110] = getLUTValue(x1i, y1i, z0i)
                const [r111, g111, b111] = getLUTValue(x1i, y1i, z1i)

                const xf1 = x - x0i
                const yf1 = y - y0i
                const zf1 = z - z0i
                const xf0 = 1 - xf1
                const yf0 = 1 - yf1
                const zf0 = 1 - zf1

                const r00 = r000 * zf0 + r001 * zf1
                const g00 = g000 * zf0 + g001 * zf1
                const b00 = b000 * zf0 + b001 * zf1

                const r01 = r010 * zf0 + r011 * zf1
                const g01 = g010 * zf0 + g011 * zf1
                const b01 = b010 * zf0 + b011 * zf1

                const r10 = r100 * zf0 + r101 * zf1
                const g10 = g100 * zf0 + g101 * zf1
                const b10 = b100 * zf0 + b101 * zf1

                const r11 = r110 * zf0 + r111 * zf1
                const g11 = g110 * zf0 + g111 * zf1
                const b11 = b110 * zf0 + b111 * zf1

                const r0 = r00 * yf0 + r01 * yf1
                const g0 = g00 * yf0 + g01 * yf1
                const b0 = b00 * yf0 + b01 * yf1

                const r1 = r10 * yf0 + r11 * yf1
                const g1 = g10 * yf0 + g11 * yf1
                const b1 = b10 * yf0 + b11 * yf1

                const r = r0 * xf0 + r1 * xf1
                const g = g0 * xf0 + g1 * xf1
                const b = b0 * xf0 + b1 * xf1

                outBuffer[ofs + 0] = r
                outBuffer[ofs + 1] = g
                outBuffer[ofs + 2] = b
                outBuffer[ofs + 3] = buffer[ofs + 3]
            }
        } else if (mode === "tetrahedral") {
            for (let ofs = 0; ofs < buffer.length; ofs += 4) {
                const x = buffer[ofs + 0] * inScale // x = input red
                const y = buffer[ofs + 1] * inScale // y = input green
                const z = buffer[ofs + 2] * inScale // z = input blue
                const x0i = Math.floor(x)
                const y0i = Math.floor(y)
                const z0i = Math.floor(z)
                const x1i = Math.ceil(x)
                const y1i = Math.ceil(y)
                const z1i = Math.ceil(z)

                const [r000, g000, b000] = getLUTValue(x0i, y0i, z0i)
                const [r001, g001, b001] = getLUTValue(x0i, y0i, z1i)
                const [r010, g010, b010] = getLUTValue(x0i, y1i, z0i)
                const [r011, g011, b011] = getLUTValue(x0i, y1i, z1i)
                const [r100, g100, b100] = getLUTValue(x1i, y0i, z0i)
                const [r101, g101, b101] = getLUTValue(x1i, y0i, z1i)
                const [r110, g110, b110] = getLUTValue(x1i, y1i, z0i)
                const [r111, g111, b111] = getLUTValue(x1i, y1i, z1i)

                const xf = x - x0i
                const yf = y - y0i
                const zf = z - z0i

                let r: number
                let g: number
                let b: number

                if (xf > yf) {
                    if (yf > zf) {
                        // r > g > b
                        r = r000 + xf * (r100 - r000) + yf * (r110 - r100) + zf * (r111 - r110)
                        g = g000 + xf * (g100 - g000) + yf * (g110 - g100) + zf * (g111 - g110)
                        b = b000 + xf * (b100 - b000) + yf * (b110 - b100) + zf * (b111 - b110)
                    } else if (xf > zf) {
                        // r > b > g
                        r = r000 + xf * (r100 - r000) + yf * (r111 - r101) + zf * (r101 - r100)
                        g = g000 + xf * (g100 - g000) + yf * (g111 - g101) + zf * (g101 - g100)
                        b = b000 + xf * (b100 - b000) + yf * (b111 - b101) + zf * (b101 - b100)
                    } else {
                        // b > r > g
                        r = r000 + xf * (r101 - r001) + yf * (r111 - r101) + zf * (r001 - r000)
                        g = g000 + xf * (g101 - g001) + yf * (g111 - g101) + zf * (g001 - g000)
                        b = b000 + xf * (b101 - b001) + yf * (b111 - b101) + zf * (b001 - b000)
                    }
                } else {
                    if (zf > yf) {
                        // b > g > r
                        r = r000 + xf * (r111 - r011) + yf * (r011 - r001) + zf * (r001 - r000)
                        g = g000 + xf * (g111 - g011) + yf * (g011 - g001) + zf * (g001 - g000)
                        b = b000 + xf * (b111 - b011) + yf * (b011 - b001) + zf * (b001 - b000)
                    } else if (zf > xf) {
                        // g > b > r
                        r = r000 + xf * (r111 - r011) + yf * (r010 - r000) + zf * (r011 - r010)
                        g = g000 + xf * (g111 - g011) + yf * (g010 - g000) + zf * (g011 - g010)
                        b = b000 + xf * (b111 - b011) + yf * (b010 - b000) + zf * (b011 - b010)
                    } else {
                        // g > r > b
                        r = r000 + xf * (r110 - r010) + yf * (r010 - r000) + zf * (r111 - r110)
                        g = g000 + xf * (g110 - g010) + yf * (g010 - g000) + zf * (g111 - g110)
                        b = b000 + xf * (b110 - b010) + yf * (b010 - b000) + zf * (b111 - b110)
                    }
                }

                outBuffer[ofs + 0] = r
                outBuffer[ofs + 1] = g
                outBuffer[ofs + 2] = b
                outBuffer[ofs + 3] = buffer[ofs + 3]
            }
        }
        return output
    }

    registerProcessFunction(applyLUT, {inPlaceArgs: [0], memCost: 1, cpuCost: 1})

    const lutCache = new Map<string, Promise<LUTData>>()

    async function getLUTFromURL(url: string): Promise<LUTData> {
        let pending = lutCache.get(url)
        if (!pending) {
            pending = (async () => {
                // console.log("Fetching LUT data");
                return loadCubeLUT(await (await _fetch(url)).text())
            })()
            lutCache.set(url, pending)
        }
        return await pending
    }

    registerProcessFunction(getLUTFromURL, {memCost: 0.1, cpuCost: 0.2})

    function applyMask(image: ImageData, mask: MaskData, output?: ImageData): ImageData {
        output = ensureOutputLike(image, output)
        const buffer = getImageBufferRGBAFloat32(image)
        const maskBuffer = getImageBufferLFloat32(mask)
        const outBuffer = getImageBufferRGBAFloat32(output)
        const length = outBuffer.length
        let maskIdx = 0
        let maskVal: number
        for (let idx = 0; idx < length; idx += 4) {
            maskVal = maskBuffer[maskIdx]
            outBuffer[idx + 0] = buffer[idx + 0] * maskVal
            outBuffer[idx + 1] = buffer[idx + 1] * maskVal
            outBuffer[idx + 2] = buffer[idx + 2] * maskVal
            outBuffer[idx + 3] = buffer[idx + 3] * maskVal
            ++maskIdx
        }
        return output
    }

    registerProcessFunction(applyMask, {inPlaceArgs: [0], memCost: 1, cpuCost: 0.3})

    function applyMaskUniformColor([r, g, b]: RGBColor, mask: MaskData, output?: ImageData): ImageData {
        output = ensureOutputLike({...mask, channelLayout: "RGBA", dataType: "float32"}, output)
        const maskBuffer = getImageBufferLFloat32(mask)
        const outBuffer = getImageBufferRGBAFloat32(output)
        const length = outBuffer.length
        let maskIdx = 0
        let maskVal: number
        for (let idx = 0; idx < length; idx += 4) {
            maskVal = maskBuffer[maskIdx]
            outBuffer[idx + 0] = r * maskVal
            outBuffer[idx + 1] = g * maskVal
            outBuffer[idx + 2] = b * maskVal
            outBuffer[idx + 3] = maskVal
            ++maskIdx
        }
        return output
    }

    registerProcessFunction(applyMaskUniformColor, {memCost: 1, cpuCost: 0.2})

    function alphaToMask(image: ImageData, output?: MaskData): MaskData {
        if (!output) {
            output = {
                ...image,
                data: new Float32Array(image.width * image.height),
                channelLayout: "L",
                dataType: "float32",
            }
        }
        const buffer = getImageBufferRGBAFloat32(image)
        const outBuffer = getImageBufferLFloat32(output)
        const length = buffer.length
        let maskIdx = 0
        for (let idx = 0; idx < length; idx += 4) {
            outBuffer[maskIdx++] = buffer[idx + 3]
        }
        return output
    }

    registerProcessFunction(alphaToMask, {memCost: 0.25, cpuCost: 0.1})

    function colorToMask(image: ImageData, output?: MaskData): MaskData {
        // assumes premultiplied alpha
        if (image.channelLayout.length !== 3 && image.channelLayout.length !== 4) {
            throw new Error("Image must have 3 or 4 channels")
        }
        if (!output) {
            output = {
                ...image,
                data: new Float32Array(image.width * image.height),
                channelLayout: "L",
                dataType: "float32",
            }
        }
        const buffer = getImageBufferFloat32(image)
        const outBuffer = getImageBufferLFloat32(output)
        const length = buffer.length
        const numChannels = image.channelLayout.length
        const sc = 1.0 / 3
        let maskIdx = 0
        for (let idx = 0; idx < length; idx += numChannels) {
            outBuffer[maskIdx++] = (buffer[idx + 0] + buffer[idx + 1] + buffer[idx + 2]) * sc
        }
        return output
    }

    registerProcessFunction(colorToMask, {memCost: 0.25, cpuCost: 0.2})

    // TODO these should be more flexible
    function addAlpha(image: ImageData, output?: ImageData): ImageData {
        if (image.channelLayout !== "RGB") {
            throw new Error("addAlpha: Image must have RGB channel layout")
        }
        if (!output) {
            output = {
                ...image,
                data: new Float32Array(image.width * image.height * 4),
                channelLayout: "RGBA",
                dataType: "float32",
            }
        }
        const buffer = getImageBufferFloat32(image)
        const outBuffer = getImageBufferFloat32(output)
        const numInChannels = image.channelLayout.length
        const numElements = buffer.length / numInChannels
        const numOutChannels = output.channelLayout.length
        for (let idx = 0; idx < numElements; idx++) {
            for (let i = 0; i < numInChannels; i++) {
                outBuffer[idx * numOutChannels + i] = buffer[idx * numInChannels + i]
            }
            outBuffer[idx * numOutChannels + 3] = 1
        }
        return output
    }

    // TODO these should be more flexible
    function removeAlpha(image: ImageData, output?: ImageData): ImageData {
        if (image.channelLayout !== "RGBA") {
            throw new Error("addAlpha: Image must have RGBA channel layout")
        }
        if (!output) {
            output = {
                ...image,
                data: new Float32Array(image.width * image.height * 3),
                channelLayout: "RGB",
                dataType: "float32",
            }
        }
        const buffer = getImageBufferFloat32(image)
        const outBuffer = getImageBufferFloat32(output)
        const numInChannels = image.channelLayout.length
        const numElements = buffer.length / numInChannels
        const numOutChannels = output.channelLayout.length
        for (let idx = 0; idx < numElements; idx++) {
            for (let i = 0; i < numOutChannels; i++) {
                outBuffer[idx * numOutChannels + i] = buffer[idx * numInChannels + i]
            }
        }
        return output
    }

    function toColor(mask: MaskData, outputChannelLayout: TypedImageData["channelLayout"], output?: ImageData): ImageData {
        // assumes premultiplied alpha
        if (!output) {
            output = {
                ...mask,
                data: new Float32Array(mask.width * mask.height * 4),
                channelLayout: outputChannelLayout,
                dataType: "float32",
            }
        }
        const buffer = getImageBufferLFloat32(mask)
        const outBuffer = getImageBufferFloat32(output)
        const numOutChannels = outputChannelLayout.length
        const includeAlpha = numOutChannels > 3
        const numOutColorChannels = includeAlpha ? numOutChannels - 1 : numOutChannels
        const length = outBuffer.length
        let v: number
        let maskIdx = 0
        for (let idx = 0; idx < length; idx += numOutChannels) {
            v = buffer[maskIdx++]
            for (let i = 0; i < numOutColorChannels; i++) {
                outBuffer[idx + i] = v
            }
            if (includeAlpha) {
                outBuffer[idx + numOutColorChannels] = 1
            }
        }
        return output
    }

    registerProcessFunction(toColor, {memCost: 1, cpuCost: 0.1})

    function blendOver(foreground: ImageData, background: ImageData, amount: number, output?: ImageData): ImageData {
        // assumes premultiplied alpha
        checkSameSize(foreground, background)
        output = ensureOutputLike(foreground, output)
        const fgBuffer = getImageBufferRGBAFloat32(foreground)
        const bgBuffer = getImageBufferRGBAFloat32(background)
        const outBuffer = getImageBufferRGBAFloat32(output)
        const length = outBuffer.length
        let a: number, ia: number
        if (amount === 1.0) {
            for (let idx = 0; idx < length; idx += 4) {
                a = fgBuffer[idx + 3]
                ia = 1 - a
                outBuffer[idx + 0] = fgBuffer[idx + 0] + bgBuffer[idx + 0] * ia
                outBuffer[idx + 1] = fgBuffer[idx + 1] + bgBuffer[idx + 1] * ia
                outBuffer[idx + 2] = fgBuffer[idx + 2] + bgBuffer[idx + 2] * ia
                outBuffer[idx + 3] = a + bgBuffer[idx + 3] * ia
            }
        } else {
            for (let idx = 0; idx < length; idx += 4) {
                a = fgBuffer[idx + 3] * amount
                ia = 1 - a
                outBuffer[idx + 0] = fgBuffer[idx + 0] * amount + bgBuffer[idx + 0] * ia
                outBuffer[idx + 1] = fgBuffer[idx + 1] * amount + bgBuffer[idx + 1] * ia
                outBuffer[idx + 2] = fgBuffer[idx + 2] * amount + bgBuffer[idx + 2] * ia
                outBuffer[idx + 3] = a + bgBuffer[idx + 3] * ia
            }
        }
        return output
    }

    registerProcessFunction(blendOver, {inPlaceArgs: [0, 1], memCost: 1, cpuCost: 0.3})

    function blendOverUniformColor(foreground: ImageData, color: RGBColor, amount: number, output?: ImageData): ImageData {
        // assumes premultiplied alpha
        output = ensureOutputLike(foreground, output)
        const fgBuffer = getImageBufferRGBAFloat32(foreground)
        const outBuffer = getImageBufferRGBAFloat32(output)
        const length = outBuffer.length
        const [r, g, b] = color
        let ia: number
        if (amount === 1.0) {
            for (let idx = 0; idx < length; idx += 4) {
                ia = 1 - fgBuffer[idx + 3]
                outBuffer[idx + 0] = fgBuffer[idx + 0] + r * ia
                outBuffer[idx + 1] = fgBuffer[idx + 1] + g * ia
                outBuffer[idx + 2] = fgBuffer[idx + 2] + b * ia
                outBuffer[idx + 3] = 1
            }
        } else {
            for (let idx = 0; idx < length; idx += 4) {
                ia = 1 - fgBuffer[idx + 3] * amount
                outBuffer[idx + 0] = fgBuffer[idx + 0] * amount + r * ia
                outBuffer[idx + 1] = fgBuffer[idx + 1] * amount + g * ia
                outBuffer[idx + 2] = fgBuffer[idx + 2] * amount + b * ia
                outBuffer[idx + 3] = 1
            }
        }
        return output
    }

    registerProcessFunction(blendOverUniformColor, {inPlaceArgs: [0], memCost: 1, cpuCost: 0.2})

    function blendAdd(foreground: ImageData, background: ImageData, amount: number, output?: ImageData): ImageData {
        // assumes premultiplied alpha
        checkSameSize(foreground, background)
        output = ensureOutputLike(foreground, output)
        const fgBuffer = getImageBufferRGBAFloat32(foreground)
        const bgBuffer = getImageBufferRGBAFloat32(background)
        const outBuffer = getImageBufferRGBAFloat32(output)
        const length = outBuffer.length
        if (amount === 1.0) {
            for (let idx = 0; idx < length; idx += 4) {
                outBuffer[idx + 0] = bgBuffer[idx + 0] + fgBuffer[idx + 0]
                outBuffer[idx + 1] = bgBuffer[idx + 1] + fgBuffer[idx + 1]
                outBuffer[idx + 2] = bgBuffer[idx + 2] + fgBuffer[idx + 2]
                outBuffer[idx + 3] = bgBuffer[idx + 3]
            }
        } else {
            for (let idx = 0; idx < length; idx += 4) {
                outBuffer[idx + 0] = bgBuffer[idx + 0] + fgBuffer[idx + 0] * amount
                outBuffer[idx + 1] = bgBuffer[idx + 1] + fgBuffer[idx + 1] * amount
                outBuffer[idx + 2] = bgBuffer[idx + 2] + fgBuffer[idx + 2] * amount
                outBuffer[idx + 3] = bgBuffer[idx + 3]
            }
        }
        return output
    }

    registerProcessFunction(blendAdd, {inPlaceArgs: [0, 1], memCost: 1, cpuCost: 0.3})

    function blendMultiply(foreground: ImageData, background: ImageData, amount: number, output?: ImageData): ImageData {
        // assumes premultiplied alpha
        checkSameSize(foreground, background)
        output = ensureOutputLike(foreground, output)
        const fgBuffer = getImageBufferRGBAFloat32(foreground)
        const bgBuffer = getImageBufferRGBAFloat32(background)
        const outBuffer = getImageBufferRGBAFloat32(output)
        const length = outBuffer.length
        if (amount === 1.0) {
            for (let idx = 0; idx < length; ++idx) {
                outBuffer[idx] = bgBuffer[idx] * fgBuffer[idx]
            }
        } else {
            const oneMinusAmount = 1 - amount
            for (let idx = 0; idx < length; ++idx) {
                outBuffer[idx] = bgBuffer[idx] * (fgBuffer[idx] * amount + oneMinusAmount)
            }
        }
        return output
    }

    registerProcessFunction(blendMultiply, {inPlaceArgs: [0, 1], memCost: 1, cpuCost: 0.3})

    function blendScreen(foreground: ImageData, background: ImageData, amount: number, output?: ImageData): ImageData {
        // assumes premultiplied alpha
        checkSameSize(foreground, background)
        output = ensureOutputLike(foreground, output)
        const fgBuffer = getImageBufferRGBAFloat32(foreground)
        const bgBuffer = getImageBufferRGBAFloat32(background)
        const outBuffer = getImageBufferRGBAFloat32(output)
        const length = outBuffer.length
        let fgVal: number
        //TODO: clip?
        if (amount === 1.0) {
            for (let idx = 0; idx < length; ++idx) {
                fgVal = fgBuffer[idx]
                outBuffer[idx] = bgBuffer[idx] * (1 - fgVal) + fgVal
            }
        } else {
            for (let idx = 0; idx < length; ++idx) {
                fgVal = fgBuffer[idx] * amount
                outBuffer[idx] = bgBuffer[idx] * (1 - fgVal) + fgVal
            }
        }
        return output
    }

    registerProcessFunction(blendScreen, {inPlaceArgs: [0, 1], memCost: 1, cpuCost: 0.3})

    function blendLighten(foreground: ImageData, background: ImageData, amount: number, output?: ImageData): ImageData {
        // assumes premultiplied alpha
        checkSameSize(foreground, background)
        output = ensureOutputLike(foreground, output)
        const fgBuffer = getImageBufferRGBAFloat32(foreground)
        const bgBuffer = getImageBufferRGBAFloat32(background)
        const outBuffer = getImageBufferRGBAFloat32(output)
        const length = outBuffer.length
        if (amount === 1.0) {
            for (let idx = 0; idx < length; ++idx) {
                outBuffer[idx] = Math.max(bgBuffer[idx], fgBuffer[idx])
            }
        } else {
            const oneMinusAmount = 1 - amount
            let bg: number
            for (let idx = 0; idx < length; ++idx) {
                bg = bgBuffer[idx]
                outBuffer[idx] = bg * oneMinusAmount + Math.max(bg, fgBuffer[idx]) * amount
            }
        }
        return output
    }

    registerProcessFunction(blendLighten, {inPlaceArgs: [0, 1], memCost: 1, cpuCost: 0.3})

    function blendDarken(foreground: ImageData, background: ImageData, amount: number, output?: ImageData): ImageData {
        // assumes premultiplied alpha
        checkSameSize(foreground, background)
        output = ensureOutputLike(foreground, output)
        const fgBuffer = getImageBufferRGBAFloat32(foreground)
        const bgBuffer = getImageBufferRGBAFloat32(background)
        const outBuffer = getImageBufferRGBAFloat32(output)
        const length = outBuffer.length
        if (amount === 1.0) {
            for (let idx = 0; idx < length; ++idx) {
                outBuffer[idx] = Math.min(bgBuffer[idx], fgBuffer[idx])
            }
        } else {
            const oneMinusAmount = 1 - amount
            let bg: number
            for (let idx = 0; idx < length; ++idx) {
                bg = bgBuffer[idx]
                outBuffer[idx] = bg * oneMinusAmount + Math.min(bg, fgBuffer[idx]) * amount
            }
        }
        return output
    }

    registerProcessFunction(blendDarken, {inPlaceArgs: [0, 1], memCost: 1, cpuCost: 0.4})

    function clamp(image: ImageData, params?: {min: number; max: number}, output?: ImageData): ImageData {
        // assumes premultiplied alpha
        const min = params?.min ?? 0
        const max = params?.max ?? 1
        output = ensureOutputLike(image, output)
        if (image.channelLayout === "L") {
            const buffer = getImageBufferLFloat32(image)
            const outBuffer = getImageBufferLFloat32(output)
            const length = outBuffer.length
            for (let idx = 0; idx < length; ++idx) {
                outBuffer[idx] = Math.min(Math.max(buffer[idx], min), max)
            }
        } else {
            const buffer = getImageBufferRGBAFloat32(image)
            const outBuffer = getImageBufferRGBAFloat32(output)
            const length = outBuffer.length
            for (let idx = 0; idx < length; idx += 4) {
                // this isn't really correct for premultiplied alpha
                outBuffer[idx + 0] = Math.min(Math.max(buffer[idx + 0], min), max)
                outBuffer[idx + 1] = Math.min(Math.max(buffer[idx + 1], min), max)
                outBuffer[idx + 2] = Math.min(Math.max(buffer[idx + 2], min), max)
                outBuffer[idx + 3] = buffer[idx + 3]
            }
        }
        return output
    }

    registerProcessFunction(clamp, {inPlaceArgs: [0], memCost: 1, cpuCost: 0.4})

    function invert(image: ImageData, output?: ImageData): ImageData {
        // assumes premultiplied alpha
        output = ensureOutputLike(image, output)
        if (image.channelLayout === "L") {
            const buffer = getImageBufferLFloat32(image)
            const outBuffer = getImageBufferLFloat32(output)
            const length = outBuffer.length
            for (let idx = 0; idx < length; ++idx) {
                outBuffer[idx] = 1 - buffer[idx]
            }
        } else {
            const buffer = getImageBufferRGBAFloat32(image)
            const outBuffer = getImageBufferRGBAFloat32(output)
            const length = buffer.length
            let a: number
            for (let idx = 0; idx < length; idx += 4) {
                a = buffer[idx + 3]
                outBuffer[idx + 0] = a - buffer[idx + 0]
                outBuffer[idx + 1] = a - buffer[idx + 1]
                outBuffer[idx + 2] = a - buffer[idx + 2]
                outBuffer[idx + 3] = buffer[idx + 3]
            }
        }
        return output
    }

    registerProcessFunction(invert, {inPlaceArgs: [0], memCost: 1, cpuCost: 0.2})

    function levels(image: ImageData, blackLevel: number | ImageData, whiteLevel: number | ImageData, gamma: number, output?: ImageData): ImageData {
        const toOffset = (blackLevel: number, whiteLevel: number) => -blackLevel
        const toScale = (blackLevel: number, whiteLevel: number) => 1 / Math.max(1e-3, whiteLevel - blackLevel)
        // assumes premultiplied alpha
        const sourceBlackLevel = new SourcePixelData(blackLevel)
        const sourceWhiteLevel = new SourcePixelData(whiteLevel)
        output = ensureOutputLike(image, output)
        if (image.channelLayout === "L") {
            const buffer = getImageBufferLFloat32(image)
            const outBuffer = getImageBufferLFloat32(output)
            const length = outBuffer.length
            if (gamma !== 1.0) {
                for (let idx = 0; idx < length; ++idx) {
                    const bl = sourceBlackLevel.r
                    const wl = sourceWhiteLevel.r
                    const offset = toOffset(bl, wl)
                    const scale = toScale(bl, wl)
                    outBuffer[idx] = Math.pow(Math.max(0, buffer[idx] + offset) * scale, gamma)
                    sourceBlackLevel.nextPixel()
                    sourceWhiteLevel.nextPixel()
                }
            } else {
                for (let idx = 0; idx < length; ++idx) {
                    const bl = sourceBlackLevel.r
                    const wl = sourceWhiteLevel.r
                    const offset = toOffset(bl, wl)
                    const scale = toScale(bl, wl)
                    outBuffer[idx] = Math.max(0, buffer[idx] + offset) * scale
                    sourceBlackLevel.nextPixel()
                    sourceWhiteLevel.nextPixel()
                }
            }
        } else if (image.channelLayout === "RGB" || image.channelLayout === "RGBA") {
            const buffer = getImageBufferFloat32(image)
            const outBuffer = getImageBufferFloat32(output)
            const length = buffer.length
            const numChannels = image.channelLayout.length
            if (gamma !== 1.0) {
                for (let idx = 0; idx < length; idx += numChannels) {
                    const blR = sourceBlackLevel.r
                    const wlR = sourceWhiteLevel.r
                    const offsetR = toOffset(blR, wlR)
                    const scaleR = toScale(blR, wlR)
                    const blG = sourceBlackLevel.g
                    const wlG = sourceWhiteLevel.g
                    const offsetG = toOffset(blG, wlG)
                    const scaleG = toScale(blG, wlG)
                    const blB = sourceBlackLevel.b
                    const wlB = sourceWhiteLevel.b
                    const offsetB = toOffset(blB, wlB)
                    const scaleB = toScale(blB, wlB)
                    outBuffer[idx + 0] = Math.pow(Math.max(0, buffer[idx + 0] + offsetR) * scaleR, gamma)
                    outBuffer[idx + 1] = Math.pow(Math.max(0, buffer[idx + 1] + offsetG) * scaleG, gamma)
                    outBuffer[idx + 2] = Math.pow(Math.max(0, buffer[idx + 2] + offsetB) * scaleB, gamma)
                    if (numChannels > 3) {
                        outBuffer[idx + 3] = buffer[idx + 3]
                    }
                    sourceBlackLevel.nextPixel()
                    sourceWhiteLevel.nextPixel()
                }
            } else {
                for (let idx = 0; idx < length; idx += numChannels) {
                    const blR = sourceBlackLevel.r
                    const wlR = sourceWhiteLevel.r
                    const offsetR = toOffset(blR, wlR)
                    const scaleR = toScale(blR, wlR)
                    const blG = sourceBlackLevel.g
                    const wlG = sourceWhiteLevel.g
                    const offsetG = toOffset(blG, wlG)
                    const scaleG = toScale(blG, wlG)
                    const blB = sourceBlackLevel.b
                    const wlB = sourceWhiteLevel.b
                    const offsetB = toOffset(blB, wlB)
                    const scaleB = toScale(blB, wlB)
                    outBuffer[idx + 0] = Math.max(0, buffer[idx + 0] + offsetR) * scaleR
                    outBuffer[idx + 1] = Math.max(0, buffer[idx + 1] + offsetG) * scaleG
                    outBuffer[idx + 2] = Math.max(0, buffer[idx + 2] + offsetB) * scaleB
                    if (numChannels > 3) {
                        outBuffer[idx + 3] = buffer[idx + 3]
                    }
                    sourceBlackLevel.nextPixel()
                    sourceWhiteLevel.nextPixel()
                }
            }
        } else {
            throw new Error("Unsupported channel layout: " + image.channelLayout)
        }
        return output
    }

    registerProcessFunction(levels, {inPlaceArgs: [0], memCost: 1, cpuCost: 0.5})

    function crop(image: ImageData, [x0, y0, w, h]: BBox, constrainRegion: boolean): ImageData {
        x0 = Math.round(x0)
        y0 = Math.round(y0)
        w = Math.round(w)
        h = Math.round(h)
        if (constrainRegion) {
            const x1 = Math.min(x0 + w, image.width)
            const y1 = Math.min(y0 + h, image.height)
            x0 = Math.max(x0, 0)
            y0 = Math.max(y0, 0)
            w = x1 - x0
            h = y1 - y0
        }
        const output = ensureOutputLike({...image, width: w, height: h}, undefined)
        const sx = Math.max(0, x0)
        const sy = Math.max(0, y0)
        const sw = Math.min(w, image.width - sx)
        const sh = Math.min(h, image.height - sy)
        const dx = Math.max(0, -x0)
        const dy = Math.max(0, -y0)
        copyRegion(image, [sx, sy, sw, sh], output, [dx, dy])
        return output
    }

    registerProcessFunction(crop, {memCost: 0.75, cpuCost: 0.1})

    registerProcessFunction(ImageProcessingLibvips.resize, {engine: "libvips"})
    registerProcessFunction(ImageProcessingLibvips.create, {engine: "libvips"})

    function copyRegion(source: ImageData, sourceRegion?: BBox, target?: ImageData, targetOffset?: [number, number]): ImageData {
        sourceRegion ??= [0, 0, source.width, source.height]
        targetOffset ??= [0, 0]
        const [sourceRegionX, sourceRegionY, sourceRegionW, sourceRegionH] = sourceRegion
        const [targetX, targetY] = targetOffset
        target ??= createImageData(targetX + sourceRegionW, targetY + sourceRegionH, source.channelLayout, source.dataType, source.colorSpace, source.dpi)
        if (
            !Number.isInteger(sourceRegionX) ||
            !Number.isInteger(sourceRegionY) ||
            !Number.isInteger(sourceRegionW) ||
            !Number.isInteger(sourceRegionH) ||
            !Number.isInteger(targetX) ||
            !Number.isInteger(targetY)
        ) {
            throw new Error(
                `copyRegion: sourceRegionX (${sourceRegionX}), sourceRegionY (${sourceRegionY}), sourceRegionW (${sourceRegionW}), sourceRegionH (${sourceRegionH}), targetX (${targetX}), targetY (${targetY}) must be integers`,
            )
        }
        if (sourceRegionX < 0 || sourceRegionY < 0 || sourceRegionW < 0 || sourceRegionH < 0 || targetX < 0 || targetY < 0) {
            throw new Error(
                `copyRegion: sourceRegionX (${sourceRegionX}), sourceRegionY (${sourceRegionY}), sourceRegionW (${sourceRegionW}), sourceRegionH (${sourceRegionH}), targetX (${targetX}), targetY (${targetY}) must be positive`,
            )
        }
        if (sourceRegionX + sourceRegionW > source.width || sourceRegionY + sourceRegionH > source.height) {
            throw new Error(
                `copyRegion: sourceRegionX (${sourceRegionX}), sourceRegionY (${sourceRegionY}), sourceRegionW (${sourceRegionW}), sourceRegionH (${sourceRegionH}) must be within source bounds`,
            )
        }
        if (targetX + sourceRegionW > target.width || targetY + sourceRegionH > target.height) {
            throw new Error(
                `copyRegion: targetX (${targetX}), targetY (${targetY}), sourceRegionW (${sourceRegionW}), sourceRegionH (${sourceRegionH}) must be within target bounds`,
            )
        }
        const pixelSz = getBytesPerPixel(source)
        const outPixelSz = getBytesPerPixel(target)
        if (pixelSz !== outPixelSz) {
            throw new Error(`copyRegion: source and target pixel size mismatch: ${pixelSz} vs ${outPixelSz}`)
        }
        const buffer = castToUint8Array(source.data)
        const outBuffer = castToUint8Array(target.data)
        const inLineSz = source.width * pixelSz
        const outLineSz = target.width * pixelSz
        const regionLineSz = sourceRegionW * pixelSz
        let inLineOfs = sourceRegionX * pixelSz + sourceRegionY * inLineSz
        let outLineOfs = targetX * pixelSz + targetY * outLineSz
        for (let oy = 0; oy < sourceRegionH; ++oy) {
            let inPxOfs = inLineOfs
            let outPxOfs = outLineOfs
            for (let ofs = 0; ofs < regionLineSz; ++ofs) {
                outBuffer[outPxOfs++] = buffer[inPxOfs++]
            }
            inLineOfs += inLineSz
            outLineOfs += outLineSz
        }
        return target
    }

    registerProcessFunction(copyRegion, {memCost: 0.75, cpuCost: 0.1})

    function shift(image: ImageData, [offsetX, offsetY]: [number, number], borderMode: Nodes.Shift["borderMode"]): ImageData {
        if (!Number.isInteger(offsetX) || !Number.isInteger(offsetY)) {
            throw new Error(`shift: offsetX (${offsetX}) and offsetY (${offsetY}) must be integers`)
        }
        if (borderMode === "wrap") {
            while (offsetX < 0) {
                offsetX += image.width
            }
            while (offsetY < 0) {
                offsetY += image.height
            }
            offsetX %= image.width
            offsetY %= image.height
        }
        if (offsetX === 0 && offsetY === 0) {
            return image
        } else {
            switch (borderMode) {
                case "wrap": {
                    const region00: BBox = [0, 0, image.width - offsetX, image.height - offsetY]
                    const targetPos00: [number, number] = [offsetX, offsetY]
                    const region10: BBox = [image.width - offsetX, 0, offsetX, image.height - offsetY]
                    const targetPos10: [number, number] = [0, offsetY]
                    const region01: BBox = [0, image.height - offsetY, image.width - offsetX, offsetY]
                    const targetPos01: [number, number] = [offsetX, 0]
                    const region11: BBox = [image.width - offsetX, image.height - offsetY, offsetX, offsetY]
                    const targetPos11: [number, number] = [0, 0]
                    const output = ensureOutputLike(image, undefined)
                    copyRegion(image, region00, output, targetPos00)
                    copyRegion(image, region10, output, targetPos10)
                    copyRegion(image, region01, output, targetPos01)
                    copyRegion(image, region11, output, targetPos11)
                    return output
                    // TODO implement more border modes
                }
            }
        }
    }

    registerProcessFunction(shift, {memCost: 0.75, cpuCost: 0.1}) // TODO what is the cost?

    function affineTransform(
        image: ImageData,
        matrixValues: [number, number, number, number, number, number],
        borderMode: ImageProcessingNodes.PixelAddressMode,
        target?: ImageData,
    ): ImageData {
        const computeResultSize = (sourceSize: Size2Like, transform: Matrix3x2) => {
            const p00 = new Vector2(0, 0)
            const p10 = new Vector2(sourceSize.width, 0)
            const p01 = new Vector2(0, sourceSize.height)
            const p11 = new Vector2(sourceSize.width, sourceSize.height)
            const p00t = transform.multiplyVector(p00)
            const p10t = transform.multiplyVector(p10)
            const p01t = transform.multiplyVector(p01)
            const p11t = transform.multiplyVector(p11)
            const minX = Math.min(p00t.x, p10t.x, p01t.x, p11t.x)
            const maxX = Math.max(p00t.x, p10t.x, p01t.x, p11t.x)
            const minY = Math.min(p00t.y, p10t.y, p01t.y, p11t.y)
            const maxY = Math.max(p00t.y, p10t.y, p01t.y, p11t.y)
            return [Math.round(maxX - minX), Math.round(maxY - minY)]
        }
        const transform = Matrix3x2.fromMatrix3x2Like(matrixValues)
        const invTransform = transform.inverse()
        const [w, h] = computeResultSize(image, transform)
        const output = ensureOutputLike({...image, width: w, height: h}, target)
        for (let y = 0; y < output.height; ++y) {
            for (let x = 0; x < output.width; ++x) {
                const sourcePixel = invTransform.multiplyVector({x: x + 0.5, y: y + 0.5}) // we offset by 0.5 because getPixelInterpolated samples at the center of the pixel
                for (let c = 0; c < output.channelLayout.length; ++c) {
                    const value = getPixelInterpolated(image, sourcePixel.x, sourcePixel.y, c, borderMode)
                    setPixel(output, x, y, c, borderMode, value)
                }
            }
        }
        return output
    }

    registerProcessFunction(shift, {memCost: 0.75, cpuCost: 0.1}) // TODO what is the cost?

    type BarycentricCoordinate = {
        u: number
        v: number
        w: number
    }

    function rasterizeGeometry(geometry: ImageProcessingNodes.Geometry, texture?: ImageData, target?: ImageData): ImageData {
        if (geometry.topology !== "triangleList") {
            throw new Error(`rasterizeGeometry: unsupported topology: ${geometry.topology}`)
        }
        if (geometry.vertices.uvs && geometry.vertices.uvs.length !== geometry.vertices.positions.length) {
            throw new Error(
                `rasterizeGeometry: number of uvs (${geometry.vertices.uvs.length}) must match number of positions (${geometry.vertices.positions.length})`,
            )
        }
        if (geometry.vertices.colors && geometry.vertices.colors.length !== geometry.vertices.positions.length) {
            throw new Error(
                `rasterizeGeometry: number of colors (${geometry.vertices.colors.length}) must match number of positions (${geometry.vertices.positions.length})`,
            )
        }
        let indices = geometry.indices
        if (!indices) {
            indices = []
            for (let i = 0; i < geometry.vertices.positions.length; ++i) {
                indices.push(i)
            }
        }
        const numTriangles = Math.floor(indices.length / 3)
        if (numTriangles * 3 !== indices.length) {
            throw new Error(`rasterizeGeometry: number of indices (${indices.length}) must be a multiple of 3`)
        }
        const computeResultSize = (vertexPositions: Vec2[], target?: Size2Like) => {
            if (target) {
                return [target.width, target.height]
            } else {
                let maxX = 0
                let maxY = 0
                for (let i = 0; i < vertexPositions.length; ++i) {
                    const p = Vector2.fromArray(vertexPositions[i])
                    maxX = Math.max(maxX, p.x)
                    maxY = Math.max(maxY, p.y)
                }
                return [Math.ceil(maxX), Math.ceil(maxY)]
            }
        }
        const [w, h] = computeResultSize(geometry.vertices.positions, target)
        const output = ensureOutputLike(
            texture
                ? {...texture, width: w, height: h, dataType: "float32"}
                : {
                      width: w,
                      height: h,
                      channelLayout: "RGBA",
                      dataType: "float32",
                      colorSpace: "linear",
                  },
            target,
        )
        const textureFloat32RGBA = texture ? getImageAsRGBAFloat32Array(texture) : undefined
        if (output.dataType !== "float32") {
            throw new Error(`rasterizeGeometry: output must have dataType "float32"`)
        }
        type Vertex = {
            position: Vec2
            uv?: Vec2
            color?: RGBColor | RGBAColor
        }
        const rasterTriangle = (
            output: ImageData,
            vertices: [Vertex, Vertex, Vertex],
            shadingFn: (vertex: [Vertex, Vertex, Vertex], barycentricCoordinate: BarycentricCoordinate) => ColorLike,
        ) => {
            const outputBuffer = getImageBufferFloat32(output)
            if (output.channelLayout !== "RGBA" && output.channelLayout !== "RGB" && output.channelLayout !== "L") {
                throw new Error(`rasterizeGeometry: output must have channelLayout "RGBA", "RGB" or "L"`)
            }
            const outputChannels = output.channelLayout.length

            const min = new Vector2(Number.MAX_VALUE, Number.MAX_VALUE)
            const max = new Vector2(-Number.MAX_VALUE, -Number.MAX_VALUE)
            for (let i = 0; i < 3; i++) {
                const p = Vector2.fromArray(vertices[i].position)
                min.minInPlace(p)
                max.maxInPlace(p)
            }

            const computeBarycentricCoordinates = (vertices: [Vertex, Vertex, Vertex], position: Vector2Like): BarycentricCoordinate => {
                const a = Vector2.fromArray(vertices[0].position)
                const b = Vector2.fromArray(vertices[1].position)
                const c = Vector2.fromArray(vertices[2].position)
                const d = position

                const edgeFunction = (a: Vector2Like, b: Vector2Like, p: Vector2Like) => {
                    return Vector2.cross(Vector2.sub(b, a), Vector2.sub(p, a))
                }

                const areaABC = edgeFunction(a, b, c)
                const areaPBC = edgeFunction(b, c, d)
                const areaPCA = edgeFunction(c, a, d)

                const u = areaPBC / areaABC // alpha
                const v = areaPCA / areaABC // beta
                const w = 1 - u - v // gamma
                return {u, v, w}
            }

            const xMin = Math.max(0, Math.min(output.width - 1, Math.floor(min.x)))
            const yMin = Math.max(0, Math.min(output.height - 1, Math.floor(min.y)))
            const xMax = Math.max(0, Math.min(output.width - 1, Math.ceil(max.x)))
            const yMax = Math.max(0, Math.min(output.height - 1, Math.ceil(max.y)))
            for (let y = yMin, endY = yMax; y <= endY; y++) {
                for (let x = xMin, endX = xMax; x <= endX; x++) {
                    const barycentricCoordinate = computeBarycentricCoordinates(vertices, new Vector2(x + 0.5, y + 0.5))
                    if (
                        !(
                            0.0 <= barycentricCoordinate.u &&
                            barycentricCoordinate.u <= 1.0 &&
                            0.0 <= barycentricCoordinate.v &&
                            barycentricCoordinate.v <= 1.0 &&
                            0.0 <= barycentricCoordinate.w &&
                            barycentricCoordinate.w <= 1.0
                        )
                    ) {
                        continue
                    }
                    const color = shadingFn(vertices, barycentricCoordinate)
                    const bufferIndex = (y * output.width + x) * outputChannels
                    if (outputChannels === 1) {
                        outputBuffer[bufferIndex] = 0.2126 * color.r + 0.7152 * color.g + 0.0722 * color.b
                    } else {
                        outputBuffer[bufferIndex] = color.r
                        outputBuffer[bufferIndex + 1] = color.g
                        outputBuffer[bufferIndex + 2] = color.b
                        if (outputChannels >= 4) {
                            outputBuffer[bufferIndex + 3] = color.a ?? 1
                        }
                    }
                }
            }
        }
        const interpolateBarycentricVector2 = (a: Vector2Like, b: Vector2Like, c: Vector2Like, barycentricCoordinate: BarycentricCoordinate) => {
            return new Vector2(
                a.x * barycentricCoordinate.u + b.x * barycentricCoordinate.v + c.x * barycentricCoordinate.w,
                a.y * barycentricCoordinate.u + b.y * barycentricCoordinate.v + c.y * barycentricCoordinate.w,
            )
        }
        const interpolateBarycentricColor = (a: ColorLike, b: ColorLike, c: ColorLike, barycentricCoordinate: BarycentricCoordinate) => {
            return new Color(
                a.r * barycentricCoordinate.u + b.r * barycentricCoordinate.v + c.r * barycentricCoordinate.w,
                a.g * barycentricCoordinate.u + b.g * barycentricCoordinate.v + c.g * barycentricCoordinate.w,
                a.b * barycentricCoordinate.u + b.b * barycentricCoordinate.v + c.b * barycentricCoordinate.w,
                (a.a ?? 1) * barycentricCoordinate.u + (b.a ?? 1) * barycentricCoordinate.v + (c.a ?? 1) * barycentricCoordinate.w,
            )
        }
        const sampleTexture = (
            textureWidth: number,
            textureHeight: number,
            textureDataFloat32RGBA: Float32Array,
            texelPosition: Vector2Like,
            addressMode: "wrap" | "clamp" | "border",
            interpolationMode: "nearest" | "linear",
        ) => {
            // re-align texel position from center to top-left
            texelPosition.x -= 0.5
            texelPosition.y -= 0.5
            const sample = (x: number, y: number) => {
                switch (addressMode) {
                    case "wrap":
                        x = wrap(x, textureWidth)
                        y = wrap(y, textureHeight)
                        break
                    case "clamp":
                        x = Math.min(Math.max(0, x), textureWidth - 1)
                        y = Math.min(Math.max(0, y), textureHeight - 1)
                        break
                    case "border":
                        if (x < 0 || x >= textureWidth || y < 0 || y >= textureHeight) {
                            return new Color(0, 0, 0, 0)
                        }
                        break
                    default:
                        throw new Error("Unsupported address mode: " + addressMode)
                }
                const bufferIndex = (y * textureWidth + x) * 4
                const r = textureDataFloat32RGBA[bufferIndex]
                const g = textureDataFloat32RGBA[bufferIndex + 1]
                const b = textureDataFloat32RGBA[bufferIndex + 2]
                const a = textureDataFloat32RGBA[bufferIndex + 3]
                return new Color(r, g, b, a)
            }
            if (interpolationMode === "nearest") {
                return sample(Math.round(texelPosition.x), Math.round(texelPosition.y))
            } else if (interpolationMode === "linear") {
                const xFloor = Math.floor(texelPosition.x)
                const yFloor = Math.floor(texelPosition.y)
                const xCeil = xFloor + 1
                const yCeil = yFloor + 1
                const xFraction = texelPosition.x - xFloor
                const yFraction = texelPosition.y - yFloor
                const sample00 = sample(xFloor, yFloor)
                const sample10 = sample(xCeil, yFloor)
                const sample01 = sample(xFloor, yCeil)
                const sample11 = sample(xCeil, yCeil)
                const sample0 = {
                    r: sample00.r * (1 - xFraction) + sample10.r * xFraction,
                    g: sample00.g * (1 - xFraction) + sample10.g * xFraction,
                    b: sample00.b * (1 - xFraction) + sample10.b * xFraction,
                    a: sample00.a * (1 - xFraction) + sample10.a * xFraction,
                }
                const sample1 = {
                    r: sample01.r * (1 - xFraction) + sample11.r * xFraction,
                    g: sample01.g * (1 - xFraction) + sample11.g * xFraction,
                    b: sample01.b * (1 - xFraction) + sample11.b * xFraction,
                    a: sample01.a * (1 - xFraction) + sample11.a * xFraction,
                }
                return new Color(
                    sample0.r * (1 - yFraction) + sample1.r * yFraction,
                    sample0.g * (1 - yFraction) + sample1.g * yFraction,
                    sample0.b * (1 - yFraction) + sample1.b * yFraction,
                    sample0.a * (1 - yFraction) + sample1.a * yFraction,
                )
            } else {
                throw new Error("Unsupported interpolation mode: " + interpolationMode)
            }
        }
        const shadingFn = (vertex: [Vertex, Vertex, Vertex], barycentricCoordinate: BarycentricCoordinate) => {
            const color0 = {r: vertex[0].color?.[0] ?? 1, g: vertex[0].color?.[1] ?? 1, b: vertex[0].color?.[2] ?? 1, a: vertex[0].color?.[3] ?? 1}
            const color1 = {r: vertex[1].color?.[0] ?? 1, g: vertex[1].color?.[1] ?? 1, b: vertex[1].color?.[2] ?? 1, a: vertex[1].color?.[3] ?? 1}
            const color2 = {r: vertex[2].color?.[0] ?? 1, g: vertex[2].color?.[1] ?? 1, b: vertex[2].color?.[2] ?? 1, a: vertex[2].color?.[3] ?? 1}
            const color = interpolateBarycentricColor(color0, color1, color2, barycentricCoordinate)
            if (texture && textureFloat32RGBA) {
                const uv = interpolateBarycentricVector2(
                    Vector2.fromArray(vertex[0].uv ?? [0, 0]),
                    Vector2.fromArray(vertex[1].uv ?? [0, 0]),
                    Vector2.fromArray(vertex[2].uv ?? [0, 0]),
                    barycentricCoordinate,
                )
                const texColor = sampleTexture(texture.width, texture.height, textureFloat32RGBA, uv, "wrap", "linear")
                color.mulInPlace(texColor)
            }
            return color
        }
        const extractVertex = (index: number): Vertex => {
            return {
                position: geometry.vertices.positions[index],
                uv: geometry.vertices.uvs ? geometry.vertices.uvs[index] : undefined,
                color: geometry.vertices.colors ? geometry.vertices.colors[index] : undefined,
            }
        }
        for (let i = 0; i < numTriangles; i++) {
            rasterTriangle(output, [extractVertex(indices[i * 3]), extractVertex(indices[i * 3 + 1]), extractVertex(indices[i * 3 + 2])], shadingFn)
        }
        return output
    }

    registerProcessFunction(shift, {memCost: 0.75, cpuCost: 0.75}) // TODO what is the cost?

    async function convolve(
        image: ImageData,
        kernel: Nodes.Convolve["kernel"],
        borderMode: Nodes.Convolve["borderMode"],
        subSamplingFactorX: number,
        subSamplingFactorY: number,
    ): Promise<ImageData> {
        if (!Number.isInteger(kernel.width) || !Number.isInteger(kernel.height)) {
            throw new Error(`convolve: kernel width (${kernel.width}) and height (${kernel.height}) must be integers`)
        }
        if (kernel.width % 2 !== 1 || kernel.height % 2 !== 1) {
            throw new Error(`convolve: kernel width (${kernel.width}) and height (${kernel.height}) must be odd`)
        }
        if (!Number.isInteger(subSamplingFactorX) || !Number.isInteger(subSamplingFactorY)) {
            throw new Error("convolve: subSamplingFactorX and subSamplingFactorY must be integers")
        }
        if (subSamplingFactorX < 1 || subSamplingFactorY < 1) {
            throw new Error("convolve: subSamplingFactorX and subSamplingFactorY must be >= 1")
        }

        let convWidth = image.width
        let convHeight = image.height
        let convImage = image
        // sub-sampling
        if (subSamplingFactorX > 1 || subSamplingFactorY > 1) {
            convWidth = Math.ceil(convWidth / subSamplingFactorX)
            convHeight = Math.ceil(convHeight / subSamplingFactorY)
            console.log(`convolve: sub-sampling to ${convWidth}x${convHeight}`)
            const libvipsImageData = ImageProcessingLibvips.toLibvipsImageData(image)
            libvipsImageData.data.resize(convWidth, convHeight, {fit: "fill"})
            convImage = await ImageProcessingLibvips.fromLibvipsImageData(libvipsImageData)
        }
        // convolve
        const convImageSource = getImageBufferFloat32(convImage)
        const convImageOutput = ensureOutputLike(convImage, undefined)
        const convImageTarget = getImageBufferFloat32(convImageOutput)
        const numChannels = convImage.channelLayout.length
        const kernelOfsX = (kernel.width - 1) / 2
        const kernelOfsY = (kernel.height - 1) / 2
        for (let y = 0; y < convHeight; ++y) {
            for (let x = 0; x < convWidth; ++x) {
                for (let c = 0; c < numChannels; ++c) {
                    let conv = 0
                    let kernelSum = 0
                    for (let ky = 0; ky < kernel.height; ++ky) {
                        for (let kx = 0; kx < kernel.width; ++kx) {
                            const k = kernel.data[kx + ky * kernel.width]
                            let sx = x + kx - kernelOfsX
                            let sy = y + ky - kernelOfsY
                            let sourceValue: number
                            switch (borderMode) {
                                case "wrap":
                                    sx = wrap(sx, convWidth)
                                    sy = wrap(sy, convHeight)
                                    sourceValue = convImageSource[(sx + sy * convWidth) * numChannels + c]
                                    break
                                case "clamp":
                                    sx = Math.max(0, Math.min(convWidth - 1, sx))
                                    sy = Math.max(0, Math.min(convHeight - 1, sy))
                                    sourceValue = convImageSource[(sx + sy * convWidth) * numChannels + c]
                                    break
                                case "wrap-mirrored":
                                    if (sx < 0) sx++
                                    if (sy < 0) sy++
                                    sx = Math.abs(sx) % (convWidth * 2)
                                    sy = Math.abs(sy) % (convHeight * 2)
                                    if (sx >= convWidth) sx = convWidth * 2 - 1 - sx
                                    if (sy >= convHeight) sy = convHeight * 2 - 1 - sy
                                    sourceValue = convImageSource[(sx + sy * convWidth) * numChannels + c]
                                    break
                                case "zero":
                                    if (sx >= 0 && sy >= 0 && sx < convWidth && sy < convHeight) {
                                        sourceValue = convImageSource[(sx + sy * convWidth) * numChannels + c]
                                    } else {
                                        sourceValue = 0
                                    }
                                    break
                                case "renormalize":
                                    if (sx >= 0 && sy >= 0 && sx < convWidth && sy < convHeight) {
                                        sourceValue = convImageSource[(sx + sy * convWidth) * numChannels + c]
                                        kernelSum += k
                                    } else {
                                        sourceValue = 0
                                    }
                                    break
                                default:
                                    throw new Error(`convolve: unsupported border mode: ${borderMode}`)
                            }
                            conv += k * sourceValue
                        }
                    }
                    if (kernelSum !== 0) {
                        conv /= kernelSum
                    }
                    convImageTarget[(x + y * convWidth) * numChannels + c] = conv
                }
            }
        }
        // super-sampling
        if (subSamplingFactorX > 1 || subSamplingFactorY > 1) {
            console.log(`convolve: super-sampling to ${image.width}x${image.height}`)
            const libvipsImageData = ImageProcessingLibvips.toLibvipsImageData(convImageOutput)
            libvipsImageData.data.resize(image.width, image.height, {fit: "fill"})
            const output = await ImageProcessingLibvips.fromLibvipsImageData(libvipsImageData)
            return output
        } else {
            return convImageOutput
        }
    }

    registerProcessFunction(convolve, {memCost: 0.75, cpuCost: 1.0}) // TODO what is the cost?

    function computeImageBufferIndex(
        image: ImageData,
        x: number,
        y: number,
        c: number,
        addressMode: ImageProcessingNodes.PixelAddressMode,
    ): number | undefined {
        switch (addressMode) {
            case "wrap":
                x = wrap(x, image.width)
                y = wrap(y, image.height)
                break
            case "clamp":
                x = Math.max(0, Math.min(image.width - 1, x))
                y = Math.max(0, Math.min(image.height - 1, y))
                break
            case "border":
                if (x < 0 || x >= image.width || y < 0 || y >= image.height) {
                    return undefined
                }
                break
            default:
                assertNever(addressMode)
        }
        if (!Number.isInteger(c)) {
            throw new Error(`computeImageBufferIndex: channel ${c} is not an integer`)
        }
        if (c < 0 || c >= image.channelLayout.length) {
            throw new Error(`computeImageBufferIndex: channel ${c} is out of bounds`)
        }
        if (!Number.isInteger(x) || !Number.isInteger(y)) {
            throw new Error(`computeImageBufferIndex: pixel (${x}, ${y}) is not an integer`)
        }
        if (x < 0 || x >= image.width || y < 0 || y >= image.height) {
            throw new Error(`computeImageBufferIndex: pixel (${x}, ${y}) is out of bounds`)
        }
        return (x + y * image.width) * image.channelLayout.length + c
    }

    // TODO consider performance improvements
    function getPixelInterpolated(
        image: ImageData,
        x: number,
        y: number,
        c: number,
        addressMode: ImageProcessingNodes.PixelAddressMode,
        borderValue = 0,
    ): number {
        // we offset by 0.5 to sample at the center of the texel instead of the corner
        x -= 0.5
        y -= 0.5
        const ix = Math.floor(x)
        const iy = Math.floor(y)
        const fx = x - ix
        const fy = y - iy
        const ifx = 1 - fx
        const ify = 1 - fy
        const buffer = getImageBufferFloat32(image)
        const index00 = computeImageBufferIndex(image, ix + 0, iy + 0, c, addressMode)
        const index10 = computeImageBufferIndex(image, ix + 1, iy + 0, c, addressMode)
        const index01 = computeImageBufferIndex(image, ix + 0, iy + 1, c, addressMode)
        const index11 = computeImageBufferIndex(image, ix + 1, iy + 1, c, addressMode)
        const value00 = index00 !== undefined ? buffer[index00] : borderValue
        const value10 = index10 !== undefined ? buffer[index10] : borderValue
        const value01 = index01 !== undefined ? buffer[index01] : borderValue
        const value11 = index11 !== undefined ? buffer[index11] : borderValue
        const value = value00 * ifx * ify + value10 * fx * ify + value01 * ifx * fy + value11 * fx * fy
        return value
    }

    function setPixel(image: ImageData, x: number, y: number, c: number, addressMode: ImageProcessingNodes.PixelAddressMode, value: number): void {
        const buffer = getImageBufferFloat32(image)
        const index = computeImageBufferIndex(image, x, y, c, addressMode)
        if (index !== undefined) {
            buffer[index] = value
        }
    }

    function detectMaskRegion(image: ImageData, threshold = 1e-3): BBox {
        const buffer = getImageBufferLFloat32(image)
        const length = buffer.length
        const w = image.width
        const h = image.width
        let ofs = 0
        let minX = w
        let minY = h
        let maxX = 0
        let maxY = 0
        for (let y = 0; y < h; ++y) {
            for (let x = 0; x < w; ++x) {
                if (buffer[ofs++] > threshold) {
                    if (x < minX) minX = x
                    if (y < minY) minY = y
                    if (x > maxX) maxX = x
                    if (y > maxY) maxY = y
                }
            }
        }
        if (minX > maxX || minY > maxY) return [0, 0, 0, 0]
        return [minX, minY, maxX - minX + 1, maxY - minY + 1]
    }

    registerProcessFunction(detectMaskRegion, {memCost: 0, cpuCost: 0.2})

    type SummaryData = {
        min: number
        max: number
        mean: number
        stdDev: number
    }

    function summarizeRegions(image: ImageData, regions: BBox[]): SummaryData {
        const buffer = getImageBufferLFloat32(image)
        const stride = image.width
        let min = Infinity
        let max = -Infinity
        let sum = 0
        let sumSq = 0
        let count = 0
        for (const [x0, y0, w, h] of regions) {
            const x1 = x0 + w
            const y1 = y0 + h
            let lineOfs = x0 + y0 * stride
            let v: number
            for (let y = y0; y < y1; ++y) {
                let ofs = lineOfs
                for (let x = x0; x < x1; ++x) {
                    v = buffer[ofs++]
                    if (v > max) max = v
                    if (v < min) min = v
                    sum += v
                    sumSq += v * v
                    count += 1
                }
                lineOfs += stride
            }
        }
        const mean = sum / count
        const stdDev = Math.sqrt(sumSq / count - mean * mean)
        return {
            min,
            max,
            mean,
            stdDev,
        }
    }

    registerProcessFunction(summarizeRegions, {memCost: 0, cpuCost: 0.3})

    function borderRegions(image: ImageData, borderSize: number): BBox[] {
        const {width: w, height: h} = image
        return [
            [0, 0, w, borderSize],
            [0, h - borderSize, w, borderSize],
            [0, borderSize, borderSize, h - borderSize * 2],
            [w - borderSize, borderSize, borderSize, h - borderSize * 2],
        ]
    }

    registerProcessFunction(borderRegions, {memCost: 0, cpuCost: 0})

    function dilateRegion([x, y, w, h]: BBox, amount: number): BBox {
        return [x - amount, y - amount, w + amount * 2, h + amount * 2]
    }

    registerProcessFunction(dilateRegion, {memCost: 0, cpuCost: 0})

    function setupCryptomatte(manifest: CryptomatteManifest, passes: ImageData[]): MatteProcessing {
        return new MatteProcessing(manifest, passes)
    }

    registerProcessFunction(setupCryptomatte, {memCost: 0, cpuCost: 2})

    function cryptomatteMask(data: MatteProcessing, ids: CryptomatteId[]): MaskData {
        return data.generateCoverageMask(ids)
    }

    registerProcessFunction(cryptomatteMask, {memCost: 0.25, cpuCost: 0.4})

    function convertDataType(image: ImageData, dataType: ImageData["dataType"]): ImageData {
        if (image.dataType === dataType) return image
        const float32ImageData = getImageAsFloat32Array(image)

        if (dataType === "uint8") {
            return {
                ...image,
                data: float32ToUint8(float32ImageData),
                dataType,
            }
        } else if (dataType === "uint16") {
            return {
                ...image,
                data: float32ToUint16(float32ImageData),
                dataType,
            }
        } else if (dataType === "float32") {
            return {
                ...image,
                data: float32ImageData,
                dataType,
            }
        } else {
            throw new Error(`Invalid image conversion: ${image.dataType} -> ${dataType}`)
        }
    }

    registerProcessFunction(convertDataType, {memCost: 0.25, cpuCost: 0.3})

    function trace(message: string, input: ImageData): ImageData {
        console.log(`${message}: ${input.width}x${input.height} ${input.dataType} ${input.channelLayout} ${input.colorSpace} ${input.dpi} dpi`)
        return input
    }

    registerProcessFunction(trace, {memCost: 0.0, cpuCost: 0.0})

    function toGrayscale(image: ImageData, mode: Nodes.ToGrayscale["mode"]): ImageData {
        if (image.dataType !== "float32") {
            throw new Error(`Expected 'float32' dataType, got: ${image.dataType}`) // TODO extend to other dataTypes ?
        }
        if (image.channelLayout === "L") {
            return image // already grayscale
        }
        if (image.channelLayout !== "RGBA" && image.channelLayout !== "RGB") {
            throw new Error(`Expected 'RGBA' or 'RGB' channelLayout, got: ${image.channelLayout}`)
        }
        const output = ensureOutputLike({...image, channelLayout: "L"}, undefined)
        const srcBuffer = getImageBufferFloat32(image)
        const dstBuffer = getImageBufferLFloat32(output)
        const length = srcBuffer.length
        const numChannels = image.channelLayout.length
        if (mode === "average") {
            for (let idx = 0, outIdx = 0; idx < length; idx += numChannels, ++outIdx) {
                dstBuffer[outIdx] = (srcBuffer[idx + 0] + srcBuffer[idx + 1] + srcBuffer[idx + 2]) / 3
            }
        } else if (mode === "luminance") {
            for (let idx = 0, outIdx = 0; idx < length; idx += numChannels, ++outIdx) {
                dstBuffer[outIdx] = 0.2126 * srcBuffer[idx + 0] + 0.7152 * srcBuffer[idx + 1] + 0.0722 * srcBuffer[idx + 2]
            }
        } else {
            throw new Error(`Unsupported mode: ${mode}`)
        }
        return output
    }

    registerProcessFunction(toGrayscale, {memCost: 0.75, cpuCost: 0.1})

    function mathOperation(operation: Nodes.Math["operation"], inputs: [ImageData] | [ImageData, ImageData | number], output?: ImageData): ImageData {
        const firstInput = inputs[0]
        const secondInput = inputs.length > 1 ? inputs[1] : undefined

        if (firstInput.dataType !== "float32") {
            throw new Error(`Expected 'float32' dataType, got: ${firstInput.dataType}`) // TODO extend to other dataTypes ?
        }

        let firstIsImageConstant = false
        let secondIsImageConstant = false
        if (secondInput && typeof secondInput !== "number") {
            firstIsImageConstant = firstInput.width === 1 && firstInput.height === 1
            secondIsImageConstant = secondInput.width === 1 && secondInput.height === 1
            if (
                secondInput.dataType !== firstInput.dataType ||
                ((secondInput.height !== firstInput.height || secondInput.width !== firstInput.width) && !firstIsImageConstant && !secondIsImageConstant)
            ) {
                throw Error(`Image data type mismatch for math operation: ${operation}:
                        dataType: ${firstInput.dataType} : ${secondInput.dataType}
                        width: ${firstInput.width} : ${secondInput.width}
                        height: ${firstInput.height} : ${secondInput.height}
                    `)
            }
        }

        let secondInputRequired: boolean
        let fn: ((x: number, y: number) => number) | ((x: number) => number)
        switch (operation) {
            case "+":
                fn = (x: number, y: number) => x + y
                secondInputRequired = true
                break
            case "-":
                fn = (x: number, y: number) => x - y
                secondInputRequired = true
                break
            case "*":
                fn = (x: number, y: number) => x * y
                secondInputRequired = true
                break
            case "/":
                fn = (x: number, y: number) => x / y
                secondInputRequired = true
                break
            case "/safe":
                fn = (x: number, y: number) => (y === 0 ? 0 : x / y)
                secondInputRequired = true
                break
            case ">":
                fn = (x: number, y: number) => (x > y ? 1 : 0)
                secondInputRequired = true
                break
            case "<":
                fn = (x: number, y: number) => (x < y ? 1 : 0)
                secondInputRequired = true
                break
            case ">=":
                fn = (x: number, y: number) => (x >= y ? 1 : 0)
                secondInputRequired = true
                break
            case "<=":
                fn = (x: number, y: number) => (x <= y ? 1 : 0)
                secondInputRequired = true
                break
            case "==":
                fn = (x: number, y: number) => (x == y ? 1 : 0)
                secondInputRequired = true
                break
            case "sqrt":
                fn = (x: number) => Math.pow(x, 0.5)
                secondInputRequired = false
                break
            case "square":
                fn = (x: number) => Math.pow(x, 2.0)
                secondInputRequired = false
                break
            case "abs":
                fn = Math.abs
                secondInputRequired = false
                break
            case "pow":
                fn = Math.pow
                secondInputRequired = true
                break
            case "mod":
                fn = (x: number, y: number) => x % y
                secondInputRequired = true
                break
            case "cos":
                fn = Math.cos
                secondInputRequired = false
                break
            case "sin":
                fn = Math.sin
                secondInputRequired = false
                break
            case "atan2":
                fn = Math.atan2
                secondInputRequired = true
                break
            case "max":
                fn = Math.max
                secondInputRequired = false
                break
            case "min":
                fn = Math.min
                secondInputRequired = false
                break
            case "clip01":
                fn = (x: number) => Math.max(0, Math.min(1, x))
                secondInputRequired = false
                break
            case "constLike":
                if (typeof secondInput !== "number" && !secondIsImageConstant) {
                    throw Error("Expected number or image-constant for second input!")
                }
                if (typeof secondInput === "number") {
                    fn = (x: number) => secondInput
                } else {
                    fn = (x: number, y: number) => y
                }
                secondInputRequired = true
                break
            case "isNaN":
                fn = (x: number) => (!Number.isFinite(x) ? 1 : 0)
                secondInputRequired = false
                break
            default:
                throw Error(`Unsupported operation: ${operation}`)
        }

        if (secondInputRequired && typeof secondInput === "undefined") throw Error("Second input required")

        const outputLikeFirstInput =
            secondIsImageConstant ||
            !secondInputRequired ||
            typeof secondInput === "number" ||
            firstInput.channelLayout.length > (secondInput as ImageData).channelLayout.length
        output = ensureOutputLike(outputLikeFirstInput ? firstInput : (secondInput as ImageData), output)

        const firstInputBuf = getImageBufferFloat32(firstInput)
        const outputBuf = getImageBufferFloat32(output)
        const length = firstInputBuf.length

        if (!secondInputRequired) {
            for (let idx = 0; idx < length; idx++) outputBuf[idx] = (fn as (x: number) => number)(firstInputBuf[idx])
        } else if (typeof secondInput === "number") {
            for (let idx = 0; idx < length; idx++) outputBuf[idx] = fn(firstInputBuf[idx], secondInput)
        } else {
            const secondInputBuf = getImageBufferFloat32(secondInput as ImageData)
            if (secondInput?.channelLayout === firstInput.channelLayout) {
                if (firstIsImageConstant || secondIsImageConstant) {
                    const channels = output.channelLayout.length
                    const numElements = output.width * output.height
                    for (let idx = 0; idx < numElements; idx++) {
                        for (let cidx = 0; cidx < channels; cidx++) {
                            const idx_ = idx * channels + cidx
                            if (firstIsImageConstant) {
                                outputBuf[idx_] = fn(firstInputBuf[cidx], secondInputBuf[idx_])
                            } else {
                                outputBuf[idx_] = fn(firstInputBuf[idx_], secondInputBuf[cidx])
                            }
                        }
                    }
                } else {
                    for (let idx = 0; idx < length; idx++) outputBuf[idx] = fn(firstInputBuf[idx], secondInputBuf[idx])
                }
            } else {
                if (secondIsImageConstant) {
                    throw Error("Second input is image constant, but channel layout does not match!")
                }
                let idx_: number
                if (outputLikeFirstInput) {
                    const channels = firstInput.channelLayout.length
                    for (let idx = 0; idx < secondInputBuf.length; idx++) {
                        for (let cidx = 0; cidx < channels; cidx++) {
                            idx_ = idx * channels + cidx
                            outputBuf[idx_] = fn(firstInputBuf[idx_], secondInputBuf[idx])
                        }
                    }
                } else {
                    const channels = (secondInput as ImageData).channelLayout.length
                    for (let idx = 0; idx < secondInputBuf.length; idx++) {
                        for (let cidx = 0; cidx < channels; cidx++) {
                            idx_ = idx * channels + cidx
                            outputBuf[idx_] = fn(firstInputBuf[idx], secondInputBuf[idx_])
                        }
                    }
                }
            }
        }
        return output
    }

    registerProcessFunction(mathOperation, {inPlaceArgs: [1]}) // FIXME

    function reduce(operation: Nodes.Reduce["operation"], input: ImageData, initial: number): ImageData {
        if (input.dataType !== "float32") {
            throw new Error(`reduce: Invalid data type: ${input.dataType}`)
        }
        const buffer = getImageBufferFloat32(input)
        const numChannels = input.channelLayout.length
        const numElements = buffer.length / numChannels
        let accuFn: (x: number, y: number) => number
        let finalFn: (x: number) => number
        switch (operation) {
            case "sum":
                accuFn = (x: number, y: number) => x + y
                finalFn = (x: number) => x
                break
            case "min":
                accuFn = (x: number, y: number) => Math.min(x, y)
                finalFn = (x: number) => x
                break
            case "max":
                accuFn = (x: number, y: number) => Math.max(x, y)
                finalFn = (x: number) => x
                break
            case "mean":
                accuFn = (x: number, y: number) => x + y
                finalFn = (x: number) => x / numElements
                break
            case "sum-square":
                accuFn = (x: number, y: number) => x + y * y
                finalFn = (x: number) => x
                break
            case "mean-square":
                accuFn = (x: number, y: number) => x + y * y
                finalFn = (x: number) => x / numElements
                break
            case "root-mean-square":
                accuFn = (x: number, y: number) => x + y * y
                finalFn = (x: number) => Math.sqrt(x / numElements)
                break
            default:
                assertNever(operation)
        }
        const result: number[] = []
        for (let c = 0; c < numChannels; ++c) {
            result[c] = initial
        }
        let i = 0
        for (let n = 0; n < numElements; n++) {
            for (let c = 0; c < numChannels; ++c) {
                result[c] = accuFn(result[c], buffer[i++])
            }
        }
        const output = createImageData(1, 1, input.channelLayout, input.dataType, input.colorSpace)
        const outputBuffer = getImageBufferFloat32(output)
        let s = ""
        for (let c = 0; c < numChannels; ++c) {
            result[c] = finalFn(result[c])
            outputBuffer[c] = result[c]
            s += `${result[c]} `
        }
        return output
    }

    registerProcessFunction(reduce, {memCost: 0.01, cpuCost: 0.1})

    function extractChannel(input: ImageData, channel: number): ImageData {
        const channels = input.channelLayout.length
        if (channel < 0 || channels - 1 < channel) throw Error(`Invalid channel idx ${channel}`)
        const bytesPerChannel = input.data.byteLength / (input.width * input.height * channels)
        const output = createImageData(input.width, input.height, "L", input.dataType, input.colorSpace, input.dpi)

        const inputBuffer = new Uint8Array(input.data.buffer)
        const outputBuffer = new Uint8Array(output.data.buffer)

        for (let i = 0; i < input.width * input.height; i++) {
            for (let j = 0; j < bytesPerChannel; j++)
                outputBuffer[i * bytesPerChannel + j] = inputBuffer[i * channels * bytesPerChannel + channel * bytesPerChannel + j]
        }
        return output
    }

    registerProcessFunction(extractChannel, {})
    registerProcessFunction(ImageProcessingLibvips.extractChannel, {engine: "libvips"})

    // function separateChannels(input: ImageData): ImageData[] {
    //     const channels = input.channelLayout.length;
    //     const bytesPerChannel = input.data.byteLength / (input. width * input.height * channels);
    //     const outputs: ImageData[] = [];
    //     for (let idx = 0; idx < channels; idx++) {
    //         outputs.push({
    //             data: new Uint8Array(input.data.byteLength / channels),
    //             width: input.width,
    //             height: input.height,
    //             channelLayout: 'L',
    //             dataType: input.dataType
    //         })
    //     }

    //     const inputBuffer = new Uint8Array(input.data.buffer);
    //     const outputBuffers = outputs.map(x => new Uint8Array(x.data.buffer));

    //     for (let i = 0; i < input.width * input.height; i++) {
    //         for (let j = 0; j < channels; j++) {
    //             for (let k = 0; k < bytesPerChannel; k++) outputBuffers[j][i * bytesPerChannel + k] = inputBuffer[i * channels * bytesPerChannel + j * bytesPerChannel + k];
    //         }
    //     }
    //     return outputs;
    // }
    // registerProcessFunction(separateChannels, {})

    function combineChannels(input: ImageData[], channelLayout: MultichannelLayouts): ImageData {
        if (channelLayout.length !== input.length) throw Error(`Received ${input.length} inputs for ${channelLayout} layout. Expected ${channelLayout.length}`)
        const first = input[0]
        const channels = channelLayout.length
        for (let idx = 1; idx < input.length; idx++) {
            const current = input[idx]
            if (
                current.width !== first.width ||
                current.height !== first.height ||
                current.channelLayout !== first.channelLayout ||
                current.dataType !== first.dataType
            ) {
                throw Error(
                    `Non-matching inputs (${current.width}x${current.height} ${current.channelLayout} ${current.dataType} vs ${first.width}x${first.height} ${first.channelLayout} ${first.dataType})`,
                )
            }
        }

        const output = createImageData(first.width, first.height, channelLayout, first.dataType, first.colorSpace, first.dpi)

        const bytesPerChannel = output.data.byteLength / (output.width * output.height * channels)

        const inputBuffers = input.map((x) => new Uint8Array(x.data.buffer))
        const outputBuffer = new Uint8Array(output.data.buffer)
        for (let i = 0; i < first.width * first.height; i++) {
            for (let j = 0; j < channels; j++) {
                for (let k = 0; k < bytesPerChannel; k++)
                    outputBuffer[i * channels * bytesPerChannel + j * bytesPerChannel + k] = inputBuffers[j][i * bytesPerChannel + k]
            }
        }
        return output
    }

    registerProcessFunction(combineChannels, {})

    function compileGraph(root: Nodes.Node, ioFunctions: ImageIOFunctions, logger: CmLogger) {
        type BaseTypeInfo<Tag> = {
            type: Tag
        }

        type ListTypeInfo = BaseTypeInfo<"list"> & {}

        type StructTypeInfo = BaseTypeInfo<"struct"> & {
            argIndices: Record<string, number>
        }

        type ImageTypeInfo = BaseTypeInfo<"image"> & {}

        type EncodedDataTypeInfo = BaseTypeInfo<"encodedData"> & {
            mediaType: Nodes.Encode["mediaType"]
        }

        type TypeInfo =
            | ImageTypeInfo
            | EncodedDataTypeInfo
            | ListTypeInfo
            | StructTypeInfo
            | BaseTypeInfo<"color" | "lut" | "region" | "offset" | "string" | "number" | "json" | "cryptomatteData" | "boolean">

        type CompiledNode<T = TypeInfo> = {
            fn: CompiledFunctionInfo
            args: CompiledNode<any>[]
            typeInfo: T
        }

        const checkType = <T extends TypeInfo = TypeInfo>(x: CompiledNode, type: T["type"]): CompiledNode<T> => {
            if (x.typeInfo.type !== type) throw Error(`Expected type ${type}, got ${x.typeInfo.type}`)
            return x as any
        }

        const ensureEngineFormat = <T extends TypeInfo = TypeInfo>(node: CompiledNode<T>, targetEngine?: CompiledNode["fn"]["engine"]): CompiledNode<T> => {
            if (node.typeInfo.type === "list" || node.typeInfo.type === "struct") {
                const {args, ...nodeData} = node
                return {...nodeData, args: node.args.map((x) => ensureEngineFormat(x, targetEngine))}
            }
            if (node.typeInfo.type !== "image") return node
            if (node.fn.engine === targetEngine) return node

            let fn: CompiledFunctionInfo["fn"]
            if (!node.fn.engine && targetEngine === "libvips") {
                fn = ImageProcessingLibvips.toLibvipsImageData
            } else if (node.fn.engine === "libvips" && !targetEngine) {
                fn = ImageProcessingLibvips.fromLibvipsImageData
            } else {
                throw Error(`No function to convert between engine formats: ${node.fn.engine} and ${targetEngine}`)
            }

            return {fn: {fn: fn}, args: [node], typeInfo: node.typeInfo}
        }

        const applyFn = <RetT>(fn: any, args: CompiledNode<any>[], returnType: RetT): CompiledNode<RetT> => {
            const fnInfo = {fn, ...processFunctionInfoMap.get(fn)}
            return {
                fn: fnInfo,
                args: args.map((arg) => ensureEngineFormat(arg, fnInfo.engine)),
                typeInfo: returnType,
            }
        }

        const applyLambda = <RetT>(fn: any, info: ProcessFunctionInfo, args: CompiledNode<any>[], returnType: RetT): CompiledNode<RetT> => {
            return {
                fn: {fn, ...info},
                args: args.map((arg) => ensureEngineFormat(arg, info.engine)),
                typeInfo: returnType,
            }
        }

        const inputImage = (image: TypedImageData): CompiledNode<ImageTypeInfo> => {
            return {
                fn: {fn: () => image, memCost: 1, cpuCost: 0},
                args: [],
                typeInfo: {
                    type: "image",
                },
            }
        }

        const inputEncodedData = (data: Nodes.EncodedData): CompiledNode<EncodedDataTypeInfo> => {
            return {
                fn: {fn: () => data, memCost: 1, cpuCost: 0},
                args: [],
                typeInfo: {
                    type: "encodedData",
                    mediaType: data.mediaType,
                },
            }
        }

        const constValue = <T extends string>(type: T, value: any): CompiledNode<BaseTypeInfo<T>> => {
            return {
                fn: {fn: () => value, memCost: 0, cpuCost: 0},
                args: [],
                typeInfo: {
                    type,
                },
            }
        }

        const list = (values: CompiledNode[]): CompiledNode<ListTypeInfo> => {
            return {
                fn: {fn: (...values: any[]) => values, memCost: 0, cpuCost: 0},
                args: values,
                typeInfo: {
                    type: "list",
                },
            }
        }

        const struct = (fields: Record<string, CompiledNode>): CompiledNode<StructTypeInfo> => {
            const keys = Object.keys(fields)
            const values = Object.values(fields)
            return {
                fn: {fn: (...values: any[]) => Object.fromEntries(zip(keys, values)), memCost: 0, cpuCost: 0},
                args: values,
                typeInfo: {
                    type: "struct",
                    argIndices: Object.fromEntries(
                        zip(
                            keys,
                            values.map((x, idx) => idx),
                        ),
                    ),
                },
            }
        }

        const toColorImage = (x: CompiledNode, outputChannelLayout: TypedImageData["channelLayout"]): CompiledNode<ImageTypeInfo> => {
            const inputImage = checkType<ImageTypeInfo>(x, "image")

            const toColorFn = (img: TypedImageData, outputChannelLayout: TypedImageData["channelLayout"]): TypedImageData => {
                let image = img
                if (image.dataType !== "float32") {
                    image = convertDataType(image, "float32")
                }
                if (image.channelLayout !== outputChannelLayout) {
                    if (image.channelLayout === "L") {
                        image = toColor({...image, channelLayout: "L"}, outputChannelLayout)
                    } else if (image.channelLayout === "RGB" && outputChannelLayout === "RGBA") {
                        image = addAlpha(image)
                    } else if (image.channelLayout === "RGBA" && outputChannelLayout === "RGB") {
                        image = removeAlpha(image)
                    } else {
                        throw Error(`Cannot convert image channelLayout ${image.channelLayout} to ${outputChannelLayout}`)
                    }
                }
                return image
            }

            return applyLambda(toColorFn, {memCost: 1.25, cpuCost: 0.4}, [inputImage, constValue("outputChannelLayout", outputChannelLayout)], {type: "image"})
        }

        const toMaskImage = (x: CompiledNode): CompiledNode<ImageTypeInfo> => {
            const inputImage = checkType<ImageTypeInfo>(x, "image")

            const toMaskImageFn = (img: TypedImageData): TypedImageData => {
                let image = img
                if (image.dataType !== "float32") {
                    image = convertDataType(image, "float32")
                }
                if (image.channelLayout.length === 3 || image.channelLayout.length === 4) {
                    image = colorToMask(image)
                } else if (image.channelLayout !== "L") {
                    throw Error(`Cannot convert image channelLayout ${image.channelLayout} to mask`)
                }
                return image
            }

            return applyLambda(toMaskImageFn, {memCost: 0.5, cpuCost: 0.5}, [inputImage], {type: "image"})
        }

        const toLinearColorImage = (x: CompiledNode, outputChannelLayout: TypedImageData["channelLayout"]): CompiledNode<ImageTypeInfo> => {
            let image = toColorImage(x, outputChannelLayout)
            image = applyFn(srgbToLinear, [image], {...image.typeInfo})
            return image
        }

        const toSRGBColorImage = (x: CompiledNode, outputChannelLayout: TypedImageData["channelLayout"]): CompiledNode<ImageTypeInfo> => {
            let image = toColorImage(x, outputChannelLayout)
            image = applyFn(linearToSrgb, [image], {...image.typeInfo})
            return image
        }

        const toLinearMask = (x: CompiledNode): CompiledNode<ImageTypeInfo> => {
            let image = toMaskImage(x)
            image = applyFn(srgbToLinear, [image], {...image.typeInfo})
            return image
        }

        const toSRGBMask = (x: CompiledNode): CompiledNode<ImageTypeInfo> => {
            let image = toMaskImage(x)
            image = applyFn(linearToSrgb, [image], {...image.typeInfo})

            return image
        }

        let evalNode: (node: Nodes.Node) => CompiledNode
        const _evalNode: (node: Nodes.Node) => CompiledNode = (node) => {
            if (node.type === "createImage") {
                return applyLambda(
                    () => ImageProcessingLibvips.create(node.width, node.height, node.dataType, node.colorSpace, node.color, node.dpi),
                    {memCost: 1.0, cpuCost: 1.0, engine: "libvips"},
                    [],
                    {type: "image"},
                )
            } else if (node.type === "input") {
                return inputImage(node.image)
            } else if (node.type === "externalData") {
                const resolvedData = node.resolvedData
                node.resolvedData = undefined // this will release some memory which is useful for the material exporter. however this cases an error when the externalData node is referenced multiple times !

                if (resolvedData === undefined) {
                    throw new Error("Unresolved external data")
                } else if (node.resolveTo === "encodedData") {
                    return inputEncodedData(resolvedData as Nodes.EncodedData)
                } else if (node.resolveTo === "typedImageData") {
                    return inputImage(resolvedData as TypedImageData)
                } else if (node.resolveTo === "cryptomatteManifest") {
                    return constValue("json", resolvedData)
                } else if (node.resolveTo === "geometry") {
                    return constValue("json", resolvedData)
                } else {
                    throw Error(`Invalid external data type: ${node.resolveTo}`)
                }
            } else if (node.type === "adjustExposure") {
                const image = toLinearColorImage(evalNode(node.input), "RGBA")
                const ev = constValue("number", node.ev)
                return applyFn(adjustExposure, [image, ev], image.typeInfo)
            } else if (node.type === "whiteBalance") {
                const image = toLinearColorImage(evalNode(node.input), "RGBA")
                const whitePoint = checkType(evalNode(node.whitePoint), "color")
                return applyFn(whiteBalance, [image, whitePoint], image.typeInfo)
            } else if (node.type === "toneMap") {
                const image = toSRGBColorImage(evalNode(node.input), "RGBA")
                const compileLUTFn = () => {
                    return compileLUT(node, node.lutResolution ?? 64, 2, true, true)
                }
                const lut = applyLambda(compileLUTFn, {memCost: 0.2, cpuCost: 3}, [], {type: "lut"})
                return applyFn(applyLUT, [image, lut], image.typeInfo)
            } else if (node.type === "applyLUT") {
                const image = toSRGBColorImage(evalNode(node.input), "RGBA")
                const lut = checkType(evalNode(node.lut), "lut")
                return applyFn(applyLUT, [image, lut], image.typeInfo)
            } else if (node.type === "blend") {
                const castFn = (node.linear ?? true) ? toLinearColorImage : toSRGBColorImage
                const foreground = castFn(evalNode(node.foreground), "RGBA")
                const background = castFn(evalNode(node.background), "RGBA")
                const amount = constValue("number", node.amount ?? 1)
                let blendFn: typeof blendOver
                switch (node.mode) {
                    case "normal":
                        blendFn = blendOver
                        break
                    case "add":
                        blendFn = blendAdd
                        break
                    case "multiply":
                        blendFn = blendMultiply
                        break
                    case "darken":
                        blendFn = blendDarken
                        break
                    case "lighten":
                        blendFn = blendLighten
                        break
                    case "screen":
                        blendFn = blendScreen
                        break
                }
                const blended = applyFn(blendFn, [foreground, background, amount], background.typeInfo)
                if (node.clamp) {
                    return applyFn(clamp, [blended], blended.typeInfo)
                } else {
                    return blended
                }
            } else if (node.type === "composite") {
                const castFn = (node.linear ?? true) ? toLinearColorImage : toSRGBColorImage
                const foreground = castFn(evalNode(node.foreground), "RGBA")
                let background = evalNode(node.background)
                const amount = constValue("number", 1)
                if (background.typeInfo.type === "color") {
                    return applyFn(blendOverUniformColor, [foreground, background, amount], foreground.typeInfo)
                } else {
                    background = castFn(background, "RGBA")
                    return applyFn(blendOver, [foreground, background, amount], background.typeInfo)
                }
            } else if (node.type === "applyMask") {
                const imageOrColor = evalNode(node.input)
                const mask = toLinearMask(evalNode(node.mask))
                if (imageOrColor.typeInfo.type === "color") {
                    return applyFn(applyMaskUniformColor, [imageOrColor, mask], {...mask.typeInfo, channelLayout: "RGBA"})
                } else {
                    const image = toLinearColorImage(imageOrColor, "RGBA")
                    return applyFn(applyMask, [image, mask], image.typeInfo)
                }
            } else if (node.type === "linearRGB") {
                return constValue("color", node.color)
            } else if (node.type === "sRGB") {
                if (node.color.length === 3)
                    return constValue("color", [srgbToLinearNoClip(node.color[0]), srgbToLinearNoClip(node.color[1]), srgbToLinearNoClip(node.color[2])])
                else if (node.color.length === 4)
                    return constValue("color", [
                        srgbToLinearNoClip(node.color[0]),
                        srgbToLinearNoClip(node.color[1]),
                        srgbToLinearNoClip(node.color[2]),
                        node.color[3],
                    ])
                else throw Error("Invalid number of channels for sRGB color conversion node")
            } else if (node.type === "colorTemperature") {
                return constValue("color", colorTemperatureToRGB(node.temperature))
            } else if (node.type === "predefinedLUT") {
                return applyFn(getLUTFromURL, [constValue("string", node.url)], {type: "lut"})
            } else if (node.type === "cryptomatteData") {
                let manifest: CompiledNode
                if (node.manifest.type === "value") {
                    manifest = constValue("json", node.manifest.value)
                } else {
                    manifest = evalNode(node.manifest)
                }

                const passes = checkType(evalNode(node.passes), "list")
                return applyFn(setupCryptomatte, [manifest, passes], {type: "cryptomatteData"})
            } else if (node.type === "cryptomatteMask") {
                const data = checkType(evalNode(node.data), "cryptomatteData")
                const ids = constValue("json", node.ids)
                return applyFn(cryptomatteMask, [data, ids], {type: "image"})
            } else if (node.type === "detectMaskRegion") {
                const mask = toLinearMask(evalNode(node.input))
                return applyFn(detectMaskRegion, [mask], {type: "region"})
            } else if (node.type === "dilateRegion") {
                const region = checkType(evalNode(node.region), "region")
                const amount = constValue("number", node.amount)
                return applyFn(dilateRegion, [region, amount], {type: "region"})
            } else if (node.type === "region") {
                return constValue("region", node.region)
            } else if (node.type === "offset") {
                return constValue("offset", node.offset)
            } else if (node.type === "crop") {
                const image = checkType(evalNode(node.input), "image")
                const region = checkType(evalNode(node.region), "region")
                const constrainRegion = constValue("boolean", node.constrainRegion ?? true)
                return applyFn(crop, [image, region, constrainRegion], image.typeInfo)
            } else if (node.type === "resize") {
                const image = checkType(evalNode(node.input), "image")
                if (!(node.width || node.height)) throw Error("Height or width params not specified!")
                const width = constValue("number", node.width)
                const height = constValue("number", node.height)
                const mode = constValue("resizeMode", node.mode ?? "absolute")
                const interpolationMode = constValue("interpolationMode", node.interpolationMode)
                return applyFn(ImageProcessingLibvips.resize, [image, width, height, mode, interpolationMode], image.typeInfo)
            } else if (node.type === "copyRegion") {
                const source = checkType(evalNode(node.source), "image")
                const sourceRegion = node.sourceRegion ? checkType(evalNode(node.sourceRegion), "region") : constValue("region", undefined)
                const target = node.target ? checkType(evalNode(node.target), "image") : constValue("image", undefined)
                const targetOffset = node.targetOffset ? checkType(evalNode(node.targetOffset), "offset") : constValue("targetOffset", undefined)
                return applyFn(copyRegion, [source, sourceRegion, target, targetOffset], source.typeInfo)
            } else if (node.type === "shift") {
                const image = checkType(evalNode(node.input), "image")
                const offset = checkType(evalNode(node.offset), "offset")
                const borderMode = constValue("borderMode", node.borderMode)
                return applyFn(shift, [image, offset, borderMode], image.typeInfo)
            } else if (node.type === "affineTransform") {
                const image = checkType(evalNode(node.input), "image")
                const transform = constValue("transform", node.transform)
                const borderMode = constValue("borderMode", node.borderMode)
                const target = node.target ? checkType(evalNode(node.target), "image") : constValue("image", undefined)
                return applyFn(affineTransform, [image, transform, borderMode, target], image.typeInfo)
            } else if (node.type === "geometry") {
                return constValue("json", node)
            } else if (node.type === "rasterizeGeometry") {
                const geometry = checkType(evalNode(node.geometry), "json")
                const texture = node.texture ? checkType(evalNode(node.texture), "image") : constValue("image", undefined)
                const target = node.target ? checkType(evalNode(node.target), "image") : constValue("image", undefined)
                const resultTypeInfo = target?.typeInfo ?? texture?.typeInfo ?? {type: "image"}
                return applyFn(rasterizeGeometry, [geometry, texture, target], resultTypeInfo)
            } else if (node.type === "convolve") {
                const image = checkType(evalNode(node.input), "image")
                const kernel = constValue("kernel", node.kernel)
                const borderMode = constValue("borderMode", node.borderMode)
                const subSamplingFactorX = constValue(
                    "number",
                    typeof node.subSamplingFactor === "number" ? node.subSamplingFactor : (node.subSamplingFactor?.x ?? 1),
                )
                const subSamplingFactorY = constValue(
                    "number",
                    typeof node.subSamplingFactor === "number" ? node.subSamplingFactor : (node.subSamplingFactor?.y ?? 1),
                )
                return applyFn(convolve, [image, kernel, borderMode, subSamplingFactorX, subSamplingFactorY], image.typeInfo)
            } else if (node.type === "colorToMask") {
                return toMaskImage(evalNode(node.input))
            } else if (node.type === "alphaToMask") {
                const image = toColorImage(evalNode(node.input), "RGBA")
                return applyFn(alphaToMask, [image], {...image.typeInfo, channelLayout: "L"})
            } else if (node.type === "toColor") {
                return toColorImage(evalNode(node.input), "RGBA")
            } else if (node.type === "clamp") {
                const image = checkType(evalNode(node.input), "image")
                return applyFn(clamp, [image], image.typeInfo)
            } else if (node.type === "invert") {
                const image = checkType(evalNode(node.input), "image")
                return applyFn(invert, [image], image.typeInfo)
            } else if (node.type === "levels") {
                const image = checkType(evalNode(node.input), "image")
                const blackLevel = typeof node.blackLevel === "number" ? constValue("number", node.blackLevel) : checkType(evalNode(node.blackLevel), "image")
                const whiteLevel = typeof node.whiteLevel === "number" ? constValue("number", node.whiteLevel) : checkType(evalNode(node.whiteLevel), "image")
                const gamma = constValue("number", Math.max(0.01, node.gamma ?? 1))
                return applyFn(levels, [image, blackLevel, whiteLevel, gamma], image.typeInfo)
            } else if (node.type === "adjustShadowMask") {
                let mask = toLinearMask(evalNode(node.input))
                const falloffParam = Math.max(node.falloff, 0)
                const innerParam = Math.max(Math.min(node.inner ?? 0, 0.99), 0)
                const outerParam = Math.max(Math.min(node.outer ?? 0, 0.99), 0)
                let blackLevel: CompiledNode
                let whiteLevel: CompiledNode
                let gamma: CompiledNode
                if (node.autoMargin) {
                    const borderSz = constValue("number", Math.max(1, node.autoMargin))
                    const regions = applyFn(borderRegions, [mask, borderSz], {type: "regionList"})
                    const summary = applyFn(summarizeRegions, [mask, regions], {type: "summary"})
                    whiteLevel = applyLambda((summary: SummaryData) => summary.min * (1 - outerParam) + 0.5 * outerParam, {memCost: 0, cpuCost: 0}, [summary], {
                        type: "number",
                    })
                    blackLevel = applyLambda(
                        (summary: SummaryData, whiteLevel: number) => Math.min(0.5, whiteLevel * 0.75) * (1 - innerParam) + whiteLevel * innerParam,
                        {memCost: 0, cpuCost: 0},
                        [summary, whiteLevel],
                        {type: "number"},
                    )
                    gamma = constValue("number", Math.max(0.01, 1 + falloffParam))
                } else {
                    const wl = 1 * (1 - outerParam) + 0.5 * outerParam
                    whiteLevel = constValue("number", wl)
                    blackLevel = constValue("number", 0.5 * (1 - innerParam) + wl * innerParam)
                    gamma = constValue("number", Math.max(0.01, 1 + falloffParam))
                }
                mask = applyFn(levels, [mask, blackLevel, whiteLevel, gamma], mask.typeInfo)
                mask = applyFn(clamp, [mask], mask.typeInfo)
                return mask
            } else if (node.type === "toGrayscale") {
                const image = checkType(evalNode(node.input), "image")
                const mode = constValue("grayscaleMode", node.mode)
                return applyFn(toGrayscale, [image, mode], {...image.typeInfo, channelLayout: "L"})
            } else if (node.type === "math") {
                const inputs: CompiledNode[] = [checkType<ImageTypeInfo>(evalNode(node.firstInput), "image")]
                if (typeof node.secondInput !== "undefined")
                    inputs.push(typeof node.secondInput === "number" ? constValue("number", node.secondInput) : evalNode(node.secondInput))
                return applyFn(mathOperation, [constValue("string", node.operation), list(inputs)], inputs[0].typeInfo)
            } else if (node.type === "reduce") {
                const inputImage = checkType<ImageTypeInfo>(evalNode(node.input), "image")
                return applyFn(reduce, [constValue("string", node.operation), inputImage, constValue("number", node.initialValue ?? 0)], inputImage.typeInfo)
            } else if (node.type === "extractChannel") {
                const input = checkType<ImageTypeInfo>(evalNode(node.input), "image")
                return applyFn(extractChannel, [input, constValue("number", node.channel)], {...input.typeInfo, channelLayout: "L"})

                // } else if (node.type === 'separateChannels') {

                //     const input = checkType<ImageTypeInfo>(evalNode(node.input), 'image');
                //     return applyFn(separateChannels, [input], { type: 'list', elemType: { ...input.typeInfo, channelLayout: 'L'} })
            } else if (node.type === "combineChannels") {
                const input = node.input.map((x) => checkType<ImageTypeInfo>(evalNode(x), "image"))
                return applyFn(combineChannels, [list(input), constValue("string", node.channelLayout)], {
                    ...input[0].typeInfo,
                    channelLayout: node.channelLayout,
                })
            } else if (node.type === "convert") {
                let inputImage = checkType<ImageTypeInfo>(evalNode(node.input), "image")

                if (node.channelLayout.length === 3 || node.channelLayout.length === 4) {
                    inputImage = node.sRGB ? toSRGBColorImage(inputImage, node.channelLayout) : toLinearColorImage(inputImage, node.channelLayout)
                } else if (node.channelLayout === "L") {
                    inputImage = node.sRGB ? toSRGBMask(inputImage) : toLinearMask(inputImage)
                } else {
                    throw Error(`Invalid output channel layout: ${node.channelLayout}`)
                }

                return applyFn(convertDataType, [inputImage, constValue("string", node.dataType)], {...inputImage.typeInfo})
            } else if (node.type === "setDpi") {
                const image = checkType<ImageTypeInfo>(evalNode(node.input), "image")
                const setDpiFn = async (imageData: ImageData): Promise<ImageData> => {
                    return {
                        ...imageData,
                        dpi: node.dpi,
                    }
                }

                return applyLambda(setDpiFn, {cpuCost: 0, memCost: 0}, [image], {type: "image"})
            } else if (node.type === "encode") {
                const image = checkType<ImageTypeInfo>(evalNode(node.input), "image")
                const options = constValue("json", node.options ?? {})
                const {mediaType} = node

                const {encoder} = ioFunctions[mediaType]
                if (!encoder) throw Error(`No encoder for format: ${mediaType}`)

                const encodeFn = async (imageData: ImageData): Promise<Nodes.EncodedData> => {
                    return {
                        ...imageData,
                        data: await encoder(imageData, node.options),
                        mediaType: node.mediaType,
                    }
                }

                return applyLambda(encodeFn, {cpuCost: 1, memCost: 1}, [image, options], {type: "encodedData", mediaType: node.mediaType})
            } else if (node.type === "decode") {
                const encodedData = checkType<EncodedDataTypeInfo>(evalNode(node.input), "encodedData")
                const {mediaType} = encodedData.typeInfo

                const decoder = ioFunctions[mediaType]?.decoder
                if (!decoder) throw Error(`No decoder for format: ${mediaType}`)

                const decodeFn = async (encodedData: Nodes.EncodedData) => {
                    const data = encodedData.data
                    const result = await decoder(data, node.options)
                    return {...result, colorSpace: encodedData.colorSpace}
                }

                return applyLambda(decodeFn, {cpuCost: 1, memCost: 1}, [encodedData], {type: "image"})
            } else if (node.type === "trace") {
                const input = checkType<ImageTypeInfo>(evalNode(node.input), "image")
                return applyFn(trace, [constValue("string", node.message), input], input.typeInfo)
            } else if (node.type === "list") {
                return list(node.items.map(evalNode))
            } else if (node.type === "struct") {
                const fields = Object.fromEntries(Object.entries(node.fields).map(([k, v]) => [k, evalNode(v)] as const))
                return struct(fields)
            } else {
                throw new Error(`Invalid image processing node: ${node["type"] ?? "undefined type"}`)
            }
        }

        const nodeMap = new Map<Nodes.Node, CompiledNode>()
        evalNode = (node) => {
            let cached = nodeMap.get(node)
            if (cached) return cached
            cached = _evalNode(node)
            nodeMap.set(node, cached)
            return cached
        }

        const evaledRoot = evalNode(root)
        const compiledFn = compileFunctionGraph(evaledRoot, logger)

        // wrap raw output value using evaluated type info
        const wrapOutput = async (x: any, curNode: CompiledNode<TypeInfo>): Promise<EvaledTypeOf<Nodes.Node>> => {
            const t = curNode.typeInfo
            if (t.type === "image") {
                const {engine} = curNode.fn
                if (engine === undefined) return {type: "image", image: x}
                else if (engine === "libvips") return {type: "image", image: await ImageProcessingLibvips.fromLibvipsImageData(x)}
                else throw Error(`Unhandled engine ${engine} in wrapOutput`)
            } else if (t.type === "encodedData") {
                return {
                    ...x,
                    type: "encodedData",
                    mediaType: t.mediaType,
                }
            } else if (t.type === "list") {
                return {
                    type: "list",
                    items: await Promise.all((x as any[]).map((x, idx) => wrapOutput(x, curNode.args[idx]))),
                }
            } else if (t.type === "struct") {
                return {type: "struct", fields: await promiseAllProperties(mapFields(t.argIndices, (idx, k) => wrapOutput(x[k], curNode.args[idx])))}
            } else {
                throw Error(`Invalid output node type: ${t.type}`)
            }
        }

        return async () => {
            return wrapOutput(await compiledFn(), evaledRoot)
        }
    }

    export type EvaledImage = {
        type: "image"
        image: ImageData
    }
    export type EvaledEncodedData = TypedImageData<Uint8Array> & {
        type: "encodedData"
        mediaType: Nodes.Encode["mediaType"]
    }
    export type EvaledList<T> = {
        type: "list"
        items: EvaledTypeOf<T>[]
    }
    export type EvaledStruct<T> = {
        type: "struct"
        fields: {[K in keyof T]: EvaledTypeOf<T[K]>}
    }

    export type EvaledTypeOf<T> = T extends Nodes.ImageNode
        ? EvaledImage
        : never | T extends Nodes.EncodedDataNode
          ? EvaledEncodedData
          : never | T extends Nodes.List<infer U>
            ? EvaledList<U>
            : never | T extends Nodes.Struct<infer U>
              ? EvaledStruct<U>
              : never

    export function evalGraph<T extends Nodes.Node>(graph: T, ioFunctions: ImageIOFunctions, logger: CmLogger): Promise<EvaledTypeOf<T>> {
        return compileGraph(graph, ioFunctions, logger)() as Promise<EvaledTypeOf<T>>
    }

    export type EvaledNode = ReturnType<typeof ImageProcessing.evalGraph> extends Promise<infer T> ? T : never
}

export namespace ImageProcessingUtils {
    type ValueType = any

    export async function resolveExternalData<T extends Nodes.Node>(graph: T, resolveFn: (node: Nodes.ExternalData<any>) => Promise<void>): Promise<T> {
        const predicate = (value: ValueType): boolean => {
            return "type" in value && value["type"] === "externalData"
        }

        const traverse = async <T>(value: ValueType, predicate: (value: ValueType) => boolean, fn: (x: T) => Promise<void>, traversedObjects: Set<unknown>) => {
            if (typeof value !== "object") return
            if (traversedObjects.has(value)) return
            traversedObjects.add(value)
            if (Array.isArray(value) || value instanceof Array) {
                for (const val of Object.values(value)) await traverse(val, predicate, fn, traversedObjects)
            } else if (ArrayBuffer.isView(value) || value instanceof ArrayBuffer) {
                return
            } else {
                if (predicate(value)) await fn(value)
                for (const val of Object.values(value)) await traverse(val, predicate, fn, traversedObjects)
            }
        }

        await traverse(graph, predicate, resolveFn, new Set())

        return graph
    }

    export async function traverseEvaledGraph(
        node: ImageProcessing.EvaledNode,
        fn: (node: ImageProcessing.EvaledNode, parent: ImageProcessing.EvaledNode | null) => Promise<void>,
    ): Promise<void> {
        const traverse = async (fn_: typeof fn, node_: typeof node, parent_?: typeof node) => {
            await fn_(node_, parent_ ?? null)
            if (node_.type === "list") {
                for (const n_ of Object.values(node_.items)) await traverse(fn_, n_, node_)
            } else if (node_.type === "struct") {
                for (const n_ of Object.values(node_.fields)) await traverse(fn_, n_, node_)
            }
        }
        await traverse(fn, node)
    }
}
