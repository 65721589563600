import {Injector} from "@angular/core"
import {RENDERING_TASK_NAME} from "@cm/job-nodes"
import {IsTruthy} from "@cm/utils"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {parseOtherInfo} from "@platform/models/job-farm-instance/other-info"
import {RenderJobTaskJobFarmInstanceGQL} from "@platform/models/job-task/render-job-task.generated"

export async function formatRenderJobTaskClaimedByField<T extends {claimedBy?: string | null}>(renderJobTask: T, injector: Injector) {
    if (!renderJobTask.claimedBy) return renderJobTask
    const jobFarmInstanceGql = injector.get(RenderJobTaskJobFarmInstanceGQL)
    const maybeJobFarmInstance = (await fetchThrowingErrors(jobFarmInstanceGql)({hwFingerprint: renderJobTask.claimedBy})).jobFarmInstances?.find(IsTruthy)
    return {...renderJobTask, claimedBy: parseOtherInfo(maybeJobFarmInstance?.otherInfo)?.hostName}
}

export function isClaimedRenderJobTask<T extends {taskType?: string | null; claimedBy?: string | null}>(jobTask: T) {
    // TODO once cloud rendering introduced, correctly narrow down to only render task claimed by desktop render nodes
    return jobTask.taskType === RENDERING_TASK_NAME && !!jobTask.claimedBy
}
