@if ($tabs().length > 1) {
    <div class="cm-tab-container">
        @for (tab of $tabs(); track tab.id) {
            <a
                class="cm-tab"
                [class.cm-tab-active]="$activeTab() === tab.id"
                (click)="$activeTab.set(tab.id); onChangeTab.emit(tab.id)"
                [routerLink]="['./']"
                [queryParams]="{tab: tab.id}"
            >
                <i [class]="'far fa-' + tab.icon + ' cm-tab-icon'"></i>
                <span class="cm-tab-text">{{ tab.title }}</span>
            </a>
        }
    </div>
}
