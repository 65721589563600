import {DeclareObjectNodeTS, ObjectNode, TemplateObjectNode} from "#template-nodes/declare-object-node"
import {TemplateNodeImplementation, TemplateNodeMeta, TemplateNodeTSImplementation} from "#template-nodes/declare-template-node"
import {MeshRenderSettings, SceneNodes} from "#template-nodes/interfaces/scene-object"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {MeshData, MeshObjectData} from "#template-nodes/interfaces/object-data"
import {imageLike} from "#template-nodes/node-types"
import {MaterialAssignments} from "#template-nodes/nodes/material-assignment"
import {BuilderInlet, BuilderOutlet} from "#template-nodes/runtime-graph/graph-builder"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {OnCompileContext} from "#template-nodes/types"
import {nodeInstance} from "@cm/graph/instance"
import {NodeGraphClass, NodeParameters, nodeParameters} from "@cm/graph/node-graph"
import {z} from "zod"
import {CompleteMeshData} from "#template-nodes/geometry-processing/mesh-data"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {Inlet, NotReady, Outlet} from "#template-nodes/runtime-graph/slots"
import {keyForMaterialData} from "@cm/material-nodes/interfaces/material-data"
import {deepEqual} from "@cm/utils"

const meshNode = z.object({
    subdivisionRenderIterations: z.number().optional(),
    displacementTexture: imageLike.optional(),
    displacementUvChannel: z.number().optional(),
    displacementMin: z.number().optional(),
    displacementMax: z.number().optional(),
    displacementNormalStrength: z.number().optional(),
    displacementNormalSmoothness: z.number().optional(),
    displacementNormalOriginalResolution: z.boolean().optional(),
    materialAssignments: nodeInstance(MaterialAssignments),
    materialSlotNames: z.record(z.string()),
    visibleDirectly: z.boolean(),
    visibleInReflections: z.boolean(),
    visibleInRefractions: z.boolean(),
    receiveRealtimeShadows: z.boolean(),
    castRealtimeShadows: z.boolean(),
})
export type MeshNode = z.infer<typeof meshNode>

export function DeclareMeshNode<ZodParamTypes extends z.ZodType<NodeParameters>>(
    definition: {
        parameters: ZodParamTypes
    },
    implementation: TemplateNodeImplementation<z.infer<typeof definition.parameters> & MeshNode & ObjectNode>,
    meta: TemplateNodeMeta<z.infer<typeof definition.parameters> & MeshNode & ObjectNode>,
) {
    const {parameters: paramsSchema} = definition
    type ParamTypes = z.infer<typeof paramsSchema>

    return DeclareMeshNodeTS<ParamTypes>({...implementation, validation: {paramsSchema}}, meta)
}

export const maxSubdivisionLimit = 5

const TD = TypeDescriptors

const applyMeshRenderSettingsDescriptor = {
    mesh: TD.inlet(TD.Identity<SceneNodes.Mesh>()),
    meshRenderSettings: TD.inlet(TD.Identity<MeshRenderSettings>()),
    output: TD.outlet<SceneNodes.Mesh>({
        deepCompare: (a, b) => {
            if (a && b) {
                const jsonData = (mesh: SceneNodes.Mesh) => {
                    const {completeMeshData, meshRenderSettings, transform, materialMap, ...rest} = mesh
                    const {displacementImage, ...restMeshRenderSettings} = meshRenderSettings
                    return {
                        ...rest,
                        reifiedHash: completeMeshData.reified.contentHash,
                        abstractHash: completeMeshData.abstract.contentHash,
                        meshRenderSettings: {...restMeshRenderSettings, displacementImageHash: displacementImage?.imageNode.hash},
                        transform: transform.toArray(),
                        materialMap: Object.fromEntries([...materialMap.entries()].map(([k, v]) => [k, v ? keyForMaterialData(v) : null])),
                    }
                }

                return deepEqual(jsonData(a), jsonData(b))
            }
            if (a || b) return false
            else return true
        },
    }),
}

class ApplyMeshRenderSettings implements NodeClassImpl<typeof applyMeshRenderSettingsDescriptor, typeof ApplyMeshRenderSettings> {
    static descriptor = applyMeshRenderSettingsDescriptor
    static uniqueName = "ApplyMeshRenderSettings"
    mesh!: Inlet<SceneNodes.Mesh>
    meshRenderSettings!: Inlet<MeshRenderSettings>
    output!: Outlet<SceneNodes.Mesh>

    run() {
        if (this.mesh === NotReady) {
            this.output.emitIfChanged(NotReady)
        } else if (this.meshRenderSettings === NotReady) {
            this.output.emitIfChanged(this.mesh)
        } else {
            this.output.emitIfChanged({...this.mesh, meshRenderSettings: this.meshRenderSettings})
        }
    }
}

export function DeclareMeshNodeTS<ParamTypes extends NodeParameters>(
    implementation: TemplateNodeTSImplementation<ParamTypes & MeshNode & ObjectNode>,
    meta: TemplateNodeMeta<ParamTypes & MeshNode & ObjectNode>,
): NodeGraphClass<Omit<TemplateMeshNode<ParamTypes>, "evaluate"> & EvaluableTemplateNode<MeshObjectData | null>> {
    const retClass = class
        extends DeclareObjectNodeTS<ParamTypes & MeshNode & ObjectNode>(
            {...implementation, validation: {paramsSchema: meshNode.and(implementation.validation?.paramsSchema ?? nodeParameters)}},
            meta,
        )
        implements EvaluableTemplateNode<MeshObjectData | null>
    {
        getDisplaySubdivisionLevel(context: OnCompileContext) {
            const {evaluator, sceneProperties} = context
            const {templateContext} = evaluator
            const {sceneManager} = templateContext
            const {subdivisionRenderIterations} = this.parameters

            const maxSubdivisionLevel =
                sceneManager.isMobileDevice() || sceneManager.arMode()
                    ? (sceneProperties?.parameters.maxSubdivisionLevelOnMobile ?? sceneProperties?.parameters.maxSubdivisionLevel)
                    : sceneProperties?.parameters.maxSubdivisionLevel

            const defaultDeviceSubdivisionLimit = sceneManager.isMobileDevice() || sceneManager.arMode() ? 0 : maxSubdivisionLimit
            const displaySubdivLevel = Math.min(subdivisionRenderIterations ?? 0, maxSubdivisionLevel ?? defaultDeviceSubdivisionLimit, maxSubdivisionLimit)

            return displaySubdivLevel
        }

        setupMesh(
            scope: GraphBuilderScope,
            context: OnCompileContext,
            completeMeshData: BuilderOutlet<CompleteMeshData>,
            isProcedural: boolean,
            invalidator?: BuilderOutlet<null>,
        ) {
            const {evaluator, topLevelObjectId} = context
            const {templateScope} = evaluator

            const {
                displacementTexture,
                displacementUvChannel,
                displacementMin,
                displacementMax,
                displacementNormalStrength,
                displacementNormalSmoothness,
                displacementNormalOriginalResolution,
                visibleDirectly,
                visibleInReflections,
                visibleInRefractions,
                materialAssignments,
                receiveRealtimeShadows,
                castRealtimeShadows,
            } = this.parameters

            const materialMap = materialAssignments.setupMaterialAssignments(scope, context)

            const displacementImage = scope.pureLambda(
                evaluator.evaluateImage(scope, displacementTexture ?? null),
                (displacementImage) => {
                    if (!displacementImage) return undefined
                    return displacementImage
                },
                "displacementImageNode",
            )

            const meshRenderSettings = scope.struct<MeshRenderSettings>("MeshRenderSettings", {
                displacementUvChannel,
                displacementMin,
                displacementMax,
                displacementNormalStrength,
                displacementNormalSmoothness,
                displacementNormalOriginalResolution,
                displacementImage,
                // cryptoMatteObjectName: topLevelObjectId ?? objProps.id,
                cryptoMatteAssetName: topLevelObjectId ?? undefined,
            })

            const meshData = scope.struct<MeshData>("MeshObjectData", {
                type: "mesh",
                completeMeshData,
                isProcedural,
                visibleDirectly,
                visibleInReflections,
                visibleInRefractions,
                receiveRealtimeShadows,
                castRealtimeShadows,
            })
            templateScope.alias(meshData, `meshData-${evaluator.getLocalId(this)}`)

            this.setupObject(
                scope,
                context,
                "Mesh",
                completeMeshData,
                undefined,
                (objectProps) => {
                    return scope.node(ApplyMeshRenderSettings, {
                        mesh: scope.struct<SceneNodes.Mesh>("Mesh", {
                            type: "Mesh",
                            ...objectProps,
                            completeMeshData,
                            materialMap,
                            meshRenderSettings: {},
                            visibleDirectly,
                            visibleInReflections,
                            visibleInRefractions,
                            receiveRealtimeShadows,
                            castRealtimeShadows,
                            isDecal: false,
                            parent: null,
                            isProcedural,
                        }),
                        meshRenderSettings,
                    }).output
                },
                invalidator,
            )
        }

        override evaluate = (scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<MeshObjectData | null> => {
            const objectData = super.evaluate(scope, evaluator)
            const meshData = scope.unresolvedToNull(evaluator.templateScope.resolve<MeshData>(`meshData-${evaluator.getLocalId(this)}`))

            return scope.pureLambda(
                scope.tuple(objectData, meshData),
                ([objectData, meshData]) => {
                    if (objectData === null || meshData === null) return null
                    return {
                        ...objectData,
                        ...meshData,
                    }
                },
                "evaluatedMesh",
            )
        }
    }
    return retClass
}

export type TemplateMeshNode<ParamTypes extends NodeParameters = {}> = TemplateObjectNode<ParamTypes & MeshNode> & {
    getDisplaySubdivisionLevel(context: OnCompileContext): number
    setupMesh(
        scope: GraphBuilderScope,
        context: OnCompileContext,
        completeMeshData: BuilderOutlet<CompleteMeshData>,
        isProcedural: boolean,
        invalidator?: BuilderOutlet<null>,
    ): void
} & EvaluableTemplateNode<MeshObjectData | null>
