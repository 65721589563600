import {
    getFullScopeName,
    ImageOpCommandQueueBase,
    ImageOpScope,
} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-base"
import {ImageOpContextWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-webgl2"
import {
    AddressSpace,
    DataType,
    ImageDescriptor,
    ImageRef,
    ImageRefId,
    isImageDescriptor,
    isImageRef,
    ManagedImageRef,
    RefCountedImageRef,
    resolveImageRefRegion,
} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {
    PainterBlitterRef,
    PainterCompositorRef,
    PainterPrimitiveRef,
    PainterRefByType,
    PainterType,
} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/painter-ref"
import {ImageViewPtrWebGl2, ImageViewWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/image-view-webgl2"
import {ImagePtrWebGl2} from "@app/textures/texture-editor/operator-stack/image-op-system/image-webgl2"
import {ColorLike, Vector2Like} from "@cm/math"
import {assertNever, IsDefined} from "@cm/utils"
import {ReplaceAWithB} from "@cm/utils/type"
import {HalPainterImageCompositor} from "@common/models/hal/hal-painter-image-compositor"
import {HalPainterImageCompositorOptions} from "@common/models/hal/hal-painter-image-compositor/types"
import {HalPainterPrimitive, HalPainterSourceImage} from "@common/models/hal/hal-painter-primitive"
import {HalPainterPrimitiveOptions} from "@common/models/hal/hal-painter-primitive/types"
import {HalPainterParameterValueType} from "@common/models/hal/hal-painter/types"
import {HalGeometry} from "@common/models/hal/hal-geometry"
import {HalPainterImageBlit, HalPainterImageBlitOptions} from "@common/models/hal/common/hal-painter-image-blit"
import {TextureEditorSettings} from "@app/textures/texture-editor/texture-editor-settings"
import {createHalImageView} from "@common/models/hal/hal-image/create"

const TRACE = TextureEditorSettings.EnableFullTrace

export class ImageOpCommandQueueWebGL2 extends ImageOpCommandQueueBase {
    constructor(readonly context: ImageOpContextWebGL2) {
        super(context)
    }

    // convenience method
    prepareResultImage(
        resultImageOrDataType: ImageRef | DataType | undefined,
        descriptorOrImageRef: ImageDescriptor | ImageRef,
        verifyAndSetSize = true,
    ): ImageRef {
        if (resultImageOrDataType) {
            const descriptor = isImageDescriptor(descriptorOrImageRef) ? descriptorOrImageRef : descriptorOrImageRef.descriptor
            if (isImageRef(resultImageOrDataType)) {
                // check for compatible size and set batch size
                if (verifyAndSetSize) {
                    if (descriptor.width !== resultImageOrDataType.descriptor.width || descriptor.height !== resultImageOrDataType.descriptor.height) {
                        throw new Error("Result image size does not match descriptor")
                    }
                    resultImageOrDataType.descriptor.batching = descriptor.batching
                }
                return resultImageOrDataType
            } else {
                return this.createImage({
                    ...descriptor,
                    dataType: resultImageOrDataType,
                })
            }
        } else {
            return this.createImage(descriptorOrImageRef)
        }
    }

    clear() {
        this.queueEntries = []
    }

    createImage(descriptorOrImageRef: ImageDescriptor | ImageRef): ImageRef {
        const descriptor = isImageDescriptor(descriptorOrImageRef) ? descriptorOrImageRef : descriptorOrImageRef.descriptor
        return this.context.createTemporaryImage(descriptor)
    }

    createPainter<T extends PainterType>(type: T, name: string, source: string): PainterRefByType<T> {
        return this.context.createPainter(type, name, source)
    }

    lambda<Parameters>(parameters: Parameters, lambdaFn: LambdaFunction<Parameters>) {
        const collectImageRefs = (value: unknown): ImageRef[] => {
            if (isImageRef(value)) {
                return [value]
            } else if (Array.isArray(value)) {
                return value.flatMap(collectImageRefs)
            } else if (typeof value === "object" && value != null) {
                return Object.values(value).flatMap(collectImageRefs)
            } else {
                return []
            }
        }
        const referencedImageRefs = collectImageRefs(parameters)
        this.queueEntries.push({type: "lambda", referencedImageRefs, parameters, lambdaFn: lambdaFn as LambdaFunction<unknown>})
    }

    // TODO improve type safety (currently you can still pass in the wrong parameters for a given painter type)
    paint<T extends PainterType>(painterRef: PainterRefByType<T>, parameters: PaintParametersByType<T>) {
        switch (painterRef.type) {
            case "blitter":
                this.queueEntries.push({...(parameters as PaintParametersBlitter), painterRef, type: "paint", paintType: "blitter", scope: this.scope})
                break
            case "compositor":
                this.queueEntries.push({...(parameters as PaintParametersCompositor), painterRef, type: "paint", paintType: "compositor", scope: this.scope})
                break
            case "primitive":
                this.queueEntries.push({...(parameters as PaintParametersPrimitive), painterRef, type: "paint", paintType: "primitive", scope: this.scope})
                break
            default:
                assertNever(painterRef)
        }
    }

    // keep a imageRef alive until it is manually released via the returned ManagedImageRef (useful for caching intermediate results which are later used in an independent queue)
    keepAlive(imageRef: ImageRef): ManagedImageRef {
        const promisedImage = this.context.getImage(imageRef)
        const refCountedImageRef = new RefCountedImageRef(imageRef.addressSpace, imageRef.id, imageRef.descriptor, () =>
            promisedImage.then((image) => image.release()),
        )
        const managedImageRef = new ManagedImageRef(refCountedImageRef)
        refCountedImageRef.release()
        return managedImageRef
    }

    async execute(imageRefsToEvaluate: ImageRef[], options?: {waitForCompletion?: boolean}): Promise<ExecutionResult<ImageRef[]>> {
        const getImageRefDesc = (imageRef: ImageRef | undefined) => imageRef?.addressSpace + "[" + imageRef?.id + "]"

        if (TRACE) {
            console.log("Executing ImageOpCommandQueueWebGL2")
            console.log(`ImageRefs to evaluate: ${imageRefsToEvaluate.map((imageRef) => getImageRefDesc(imageRef)).join(", ")}`)
        }

        const waitForCompletion = options?.waitForCompletion ?? false // default: false

        // collect at which queue index each result image is referenced last (either as a source or as a result)
        const imageRefHandleByAddressSpaceByImageRefId = new Map<AddressSpace, Map<ImageRefId, ImageRefHandle>>()
        const mapImageRefToHandle = (imageRef: ImageRef) => {
            let imageRefHandleByImageRef = imageRefHandleByAddressSpaceByImageRefId.get(imageRef.addressSpace)
            if (!imageRefHandleByImageRef) {
                imageRefHandleByImageRef = new Map()
                imageRefHandleByAddressSpaceByImageRefId.set(imageRef.addressSpace, imageRefHandleByImageRef)
            }
            let handle = imageRefHandleByImageRef.get(imageRef.id)
            if (!handle) {
                handle = {addressSpace: imageRef.addressSpace, id: imageRef.id}
                imageRefHandleByImageRef.set(imageRef.id, handle)
            }
            return handle
        }

        const imageRefHandlesToReleaseByQueueIndex = new Map<number, Set<ImageRefHandle>>()
        const checkedImageRefHandles = new Set<ImageRefHandle>(imageRefsToEvaluate.map(mapImageRefToHandle))
        for (let queueIndex = this.queueEntries.length - 1; queueIndex >= 0; queueIndex--) {
            const queueEntry = this.queueEntries[queueIndex]
            let referencedImageRefs: ImageRef[]
            switch (queueEntry.type) {
                case "paint": {
                    const sourceImageRefs = queueEntry.sourceImages
                        ? Array.isArray(queueEntry.sourceImages)
                            ? queueEntry.sourceImages
                            : [queueEntry.sourceImages]
                        : []
                    const resultImageRef = queueEntry.resultImage
                    referencedImageRefs = [resultImageRef, ...sourceImageRefs.filter(IsDefined)]
                    break
                }
                case "lambda": {
                    referencedImageRefs = queueEntry.referencedImageRefs
                    break
                }
                default:
                    assertNever(queueEntry)
            }
            referencedImageRefs.forEach((imageRef) => {
                const handle = mapImageRefToHandle(imageRef)
                if (!checkedImageRefHandles.has(handle)) {
                    let resultImageRefHandlesToRelease = imageRefHandlesToReleaseByQueueIndex.get(queueIndex)
                    if (!resultImageRefHandlesToRelease) {
                        resultImageRefHandlesToRelease = new Set()
                        imageRefHandlesToReleaseByQueueIndex.set(queueIndex, resultImageRefHandlesToRelease)
                    }
                    resultImageRefHandlesToRelease.add(handle)
                    checkedImageRefHandles.add(handle)
                }
            })
        }

        // TODO prune paintCalls that do not contribute to the result

        // run queue
        const imagePtrByImageRefHandle = new Map<ImageRefHandle, ImagePtrWebGl2>()
        const getOrQueryHalImage = async (imageRef: ImageRef) => {
            const imageHandle = mapImageRefToHandle(imageRef)
            let imagePtrWebGl2 = imagePtrByImageRefHandle.get(imageHandle)
            if (!imagePtrWebGl2) {
                imagePtrWebGl2 = await this.context.getImage(imageRef)
                imagePtrByImageRefHandle.set(imageHandle, imagePtrWebGl2)
            }
            const resultRegion = resolveImageRefRegion(imageRef)
            return createHalImageView(imagePtrWebGl2.ref.halImage, resultRegion)
        }
        let queueIndex = 0
        for (const queueEntry of this.queueEntries) {
            if (queueEntry.type === "lambda") {
                const result = queueEntry.lambdaFn(queueEntry.parameters)
                if (result instanceof Promise) {
                    await result
                }
            } else if (queueEntry.type === "paint") {
                // get source images
                const sourceImageRefs = queueEntry.sourceImages
                    ? Array.isArray(queueEntry.sourceImages)
                        ? queueEntry.sourceImages
                        : [queueEntry.sourceImages]
                    : []
                const halSourceImageViews = await Promise.all(
                    sourceImageRefs.map(async (imageRef) => {
                        if (!imageRef) {
                            return undefined
                        }
                        return await getOrQueryHalImage(imageRef)
                    }),
                )
                const painter = await this.context.getPainter(queueEntry.painterRef)
                // paint
                if (TRACE) {
                    console.log(`[${queueIndex}] Painting ${queueEntry.painterRef.name}`)
                    console.log(`  Scope: ${getFullScopeName(queueEntry.scope)}`)
                    console.log(`  Parameters: ${JSON.stringify(queueEntry.parameters)}`)
                    console.log(`  Source images: ${sourceImageRefs.map((imageRef) => imageRef?.addressSpace + "[" + imageRef?.id + "]").join(", ")}`)
                    console.log(`  Options: ${JSON.stringify(queueEntry.options)}`)
                    console.log(`  Target image: ${queueEntry.resultImage.addressSpace}[${queueEntry.resultImage.id}]`)
                }
                // get target image
                const halResultImageView = await getOrQueryHalImage(queueEntry.resultImage)
                // execute painter
                switch (queueEntry.paintType) {
                    case "blitter": {
                        const painterBlitter = painter as HalPainterImageBlit
                        if (halSourceImageViews.length < 1 || halSourceImageViews[0] === undefined) {
                            throw new Error("Blitter requires at least one source image")
                        }
                        painterBlitter.paint({
                            target: halResultImageView,
                            parameters: queueEntry.parameters,
                            sourceImages: halSourceImageViews as [HalPainterSourceImage, ...(HalPainterSourceImage | undefined)[]],
                            options: queueEntry.options,
                        })
                        break
                    }
                    case "compositor": {
                        const painterCompositor = painter as HalPainterImageCompositor
                        painterCompositor.paint({
                            target: halResultImageView,
                            parameters: queueEntry.parameters,
                            sourceImages: halSourceImageViews,
                            options: queueEntry.options,
                        })
                        break
                    }
                    case "primitive": {
                        const painterPrimitive = painter as HalPainterPrimitive
                        let geometry: HalGeometry
                        if (isInlineGeometry(queueEntry.geometry)) {
                            // for inline geometry we reuse the same geometry object; TODO this will cause stalls - improve !
                            geometry = this.context.inlineGeometry
                            geometry.clear()
                            geometry.addVertices(queueEntry.geometry.vertices.positions, queueEntry.geometry.vertices.uvs, queueEntry.geometry.vertices.colors)
                            geometry.addIndices(queueEntry.geometry.indices)
                        } else {
                            geometry = queueEntry.geometry
                        }
                        painterPrimitive.paint({
                            target: halResultImageView,
                            geometry: geometry,
                            parameters: queueEntry.parameters,
                            sourceImages: halSourceImageViews,
                            options: queueEntry.options,
                        })
                        break
                    }
                    default:
                        assertNever(queueEntry)
                }
                // release source images
                // sourceImages.forEach((sourceImage) => sourceImage?.image.release())
            } else {
                assertNever(queueEntry)
            }
            // release images which are not referenced by later queue entries
            const imageRefHandlesToRelease = imageRefHandlesToReleaseByQueueIndex.get(queueIndex)
            if (imageRefHandlesToRelease) {
                if (TRACE) {
                    console.log(`[${queueIndex}] Releasing images`)
                    console.log(
                        `  Images: ${Array.from(imageRefHandlesToRelease)
                            .map((imageRefHandle) => imageRefHandle.addressSpace + "[" + imageRefHandle.id + "]")
                            .join(", ")}`,
                    )
                }
                imageRefHandlesToRelease.forEach((imageRefHandle) => {
                    const resultImagePtr = imagePtrByImageRefHandle.get(imageRefHandle)
                    if (!resultImagePtr) {
                        throw new Error("Image not found")
                    }
                    imagePtrByImageRefHandle.delete(imageRefHandle)
                    resultImagePtr.release()
                })
            }
            queueIndex++
        }

        // collect result
        const result = await Promise.all(
            imageRefsToEvaluate.map(async (imageRef) => {
                const handle = mapImageRefToHandle(imageRef)
                let resultImagePtr = imagePtrByImageRefHandle.get(handle)
                if (!resultImagePtr) {
                    // the image was not the result of a paint call, so it must be a source image
                    resultImagePtr = await this.context.getImage(imageRef)
                } else {
                    imagePtrByImageRefHandle.delete(handle)
                }
                // create a smart-ptr view for the result image and release the other smart-ptrs
                const imageView = new ImageViewWebGL2(resultImagePtr.ref, resolveImageRefRegion(imageRef))
                const imageViewPtr = new ImageViewPtrWebGl2(imageView)
                imageView.release()
                resultImagePtr.release()
                return imageViewPtr
            }),
        )
        if (TRACE) {
            console.log("Result:")
            result.forEach((image) => {
                console.log(`  ${image.ref.addressSpace}[${image.ref.id}]`)
            })
        }

        if (imagePtrByImageRefHandle.size !== 0) {
            throw new Error("Result images not fully released")
        }

        // flush
        await this.context.flush(waitForCompletion)

        return result
    }

    private queueEntries: QueueEntry[] = []
}

type ExecutionResult<RootNodeType> = ReplaceAWithB<RootNodeType, ImageRef, ImageViewPtrWebGl2>

export type PaintParametersBase = {
    readonly parameters?: {[key: string]: HalPainterParameterValueType}
    readonly sourceImages?: ImageRef | (ImageRef | undefined)[]
    readonly resultImage: ImageRef
}

export type PaintParametersBlitter = PaintParametersBase & {
    readonly options?: HalPainterImageBlitOptions
}

export type PaintParametersCompositor = PaintParametersBase & {
    readonly options?: HalPainterImageCompositorOptions
}

export type PaintParametersPrimitive = PaintParametersBase & {
    readonly geometry: HalGeometry | InlineGeometry
    readonly options?: HalPainterPrimitiveOptions
}

export type InlineGeometry = {
    readonly vertices: {
        readonly positions: Vector2Like[]
        readonly uvs?: Vector2Like[]
        readonly colors?: ColorLike | ColorLike[]
    }
    readonly indices: number[]
}

export type PaintParametersByType<T extends PainterType> = T extends "blitter"
    ? PaintParametersBlitter
    : T extends "compositor"
      ? PaintParametersCompositor
      : T extends "primitive"
        ? PaintParametersPrimitive
        : never

export type PaintCallBase = {
    readonly type: "paint"
    readonly scope: ImageOpScope | undefined
}

export type PaintCallBlitter = PaintCallBase & {
    readonly paintType: "blitter"
    readonly painterRef: PainterBlitterRef
} & PaintParametersBlitter

export type PaintCallCompositor = PaintCallBase & {
    readonly paintType: "compositor"
    readonly painterRef: PainterCompositorRef
} & PaintParametersCompositor

export type PaintCallPrimitive = PaintCallBase & {
    readonly paintType: "primitive"
    readonly painterRef: PainterPrimitiveRef
} & PaintParametersPrimitive

export type QueueEntryPaintCall = PaintCallBlitter | PaintCallCompositor | PaintCallPrimitive

export type LambdaFunction<Parameters> = (parameters: Parameters) => Promise<void> | void

export type QueueEntryLambda<Parameters> = {
    readonly type: "lambda"
    readonly referencedImageRefs: ImageRef[]
    readonly parameters: Parameters
    readonly lambdaFn: LambdaFunction<Parameters>
}

export type QueueEntry = QueueEntryPaintCall | QueueEntryLambda<unknown>

type ImageRefHandle = {
    readonly addressSpace: AddressSpace
    readonly id: ImageRefId
}

function _isPaintQueueEntry(entry: QueueEntry): entry is QueueEntryPaintCall {
    return entry.type === "paint"
}

function _isLambdaQueueEntry(entry: QueueEntry): entry is QueueEntryLambda<unknown> {
    return entry.type === "lambda"
}

function isInlineGeometry(geometry: HalGeometry | InlineGeometry): geometry is InlineGeometry {
    return "vertices" in geometry
}
