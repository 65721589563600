<cm-inspector-section>
    <ng-container cm-title> Angle</ng-container>
    <ng-container cm-content>
        <div class="cm-angles">
            <cm-button
                class="cm-angle"
                [class.cm-active]="angleInDegrees === 0"
                [class.cm-disabled]="$disabled()"
                (click)="angleInDegrees = 0"
                [disabled]="$disabled()"
            >
                <ng-container cm-text>0°</ng-container>
            </cm-button>
            <cm-button
                class="cm-angle"
                [class.cm-active]="angleInDegrees === 90"
                [class.cm-disabled]="$disabled()"
                (click)="angleInDegrees = 90"
                [disabled]="$disabled()"
            >
                <ng-container cm-text>90°</ng-container>
            </cm-button>
            <cm-button
                class="cm-angle"
                [class.cm-active]="angleInDegrees === 180"
                [class.cm-disabled]="$disabled()"
                (click)="angleInDegrees = 180"
                [disabled]="$disabled()"
            >
                <ng-container cm-text>180°</ng-container>
            </cm-button>
            <cm-button
                class="cm-angle"
                [class.cm-active]="angleInDegrees === -90"
                [class.cm-disabled]="$disabled()"
                (click)="angleInDegrees = -90"
                [disabled]="$disabled()"
            >
                <ng-container cm-text>-90°</ng-container>
            </cm-button>
        </div>
    </ng-container>
</cm-inspector-section>
