import {Component, Input, OnChanges, OnInit} from "@angular/core"
import {jobTaskIcon} from "@common/helpers/jobs"
import {jobTaskStateColor} from "@common/helpers/jobs/color"
import {JobTaskState} from "@generated"
import {ExecutionPlanNodeType} from "@platform/components/jobs/execution-plan/graph/types"

@Component({
    selector: "cm-execution-plan-tooltip",
    templateUrl: "execution-plan-tooltip.component.html",
    styleUrls: ["execution-plan-tooltip.component.sass"],
})
export class ExecutionPlanTooltipComponent implements OnInit, OnChanges {
    @Input() node?: ExecutionPlanNodeType

    public title?: string
    public infoText?: string
    public taskType?: string
    public taskState?: JobTaskState
    public inputLength?: number
    public outputLength?: number
    public progress?: number
    public errorText?: string
    public message?: string

    public isWaiting?: boolean

    ngOnInit() {
        this.update()
    }

    ngOnChanges(): void {
        this.update()
    }

    update() {
        this.reset()

        if (!this.node) {
            this.title = "Missing node"
            return
        }

        this.inputLength = this.node.inputLength
        this.outputLength = this.node.outputLength
        this.errorText = this.node.error

        switch (this.node.type) {
            case "task": {
                this.title = "Task node"
                this.taskType = this.node.taskType
                this.taskState = this.node.dbState
                this.progress = this.node.progress ? Math.round(this.node.progress) : undefined
                break
            }
            case "externalJob": {
                this.title = "External job node"
                this.taskType = this.node.taskType
                this.taskState = this.node.dbState
                this.progress = this.node.progress ? Math.round(this.node.progress) : undefined
                break
            }
            case "progressGroup": {
                this.title = "Progress group node"
                this.taskState = this.node.dbState
                this.progress = this.node.progress ? Math.round(this.node.progress) : undefined
                break
            }
            case "jobDefinition": {
                this.title = "Job definition node"
                this.taskState = this.node.dbState
                this.progress = this.node.progress ? Math.round(this.node.progress) : undefined
                break
            }
            case "get": {
                this.title = "Get node"
                break
            }
            case "value": {
                this.title = "Value node"
                break
            }
            case "input": {
                this.title = "Input node"
                break
            }
            case "struct": {
                this.title = "Struct node"
                break
            }
            case "list": {
                this.title = "List node"
                break
            }
        }
    }

    reset() {
        this.taskType = undefined
        this.infoText = undefined
        this.taskType = undefined
        this.taskState = undefined
        this.inputLength = undefined
        this.outputLength = undefined
        this.progress = undefined
        this.errorText = undefined
        this.message = undefined
        this.isWaiting = undefined
    }

    displayState(state: JobTaskState) {
        switch (state) {
            case JobTaskState.Queued:
                return "Invoked"
            case JobTaskState.Missing:
                return "Not yet created"
            default:
                return state
        }
    }

    public readonly jobTaskIcon = jobTaskIcon
    protected readonly jobTaskStateColor = jobTaskStateColor
}
