import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetSceneStatisticsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.SceneStatisticsFilterInput>;
}>;


export type GetSceneStatisticsQuery = { __typename: 'Query', sceneStatisticsItemsCount: number };

export type CreateSceneStatisticsMutationVariables = Types.Exact<{
  input: Types.MutationCreateSceneStatisticsInput;
}>;


export type CreateSceneStatisticsMutation = { __typename: 'Mutation', createSceneStatistics: { __typename: 'SceneStatistics', id: string } };

export type SceneStatisticsDetailsFragment = { __typename: 'SceneStatistics', id: string, created?: string | null, scene?: { __typename: 'Scene', id: string } | null };

export type GetArStatisticsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ArStatisticsFilterInput>;
}>;


export type GetArStatisticsQuery = { __typename: 'Query', arStatisticsItemsCount: number };

export type CreateArStatisticsMutationVariables = Types.Exact<{
  input: Types.MutationCreateArStatisticsInput;
}>;


export type CreateArStatisticsMutation = { __typename: 'Mutation', createArStatistics: { __typename: 'ArStatistics', id: string } };

export type ArStatisticsDetailsFragment = { __typename: 'ArStatistics', id: string, created?: string | null, sceneRevision?: { __typename: 'SceneRevision', id: string } | null };

export type AssetStatisticsFragment = { __typename: 'AssetHistory', id: string, nextActor: Types.NextActor, state: Types.AssetState, asset?: { __typename: 'Asset', id: string } | null, object?: { __typename: 'Asset', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null };

export type MaterialStatisticsFragment = { __typename: 'MaterialHistory', id: string, nextActor: Types.NextActor, state: Types.MaterialState, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, material?: { __typename: 'Material', id: string } | null, object?: { __typename: 'Material', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null };

export type ModelStatisticsFragment = { __typename: 'ModelHistory', id: string, nextActor: Types.NextActor, state: Types.ModelState, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, model?: { __typename: 'Model', id: string } | null, object?: { __typename: 'Model', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null };

export type PictureStatisticsFragment = { __typename: 'PictureHistory', id: string, nextActor: Types.NextActor, state: Types.PictureState, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, object?: { __typename: 'Picture', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, picture?: { __typename: 'Picture', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null };

export type StatisticsAssetsHistoryQueryVariables = Types.Exact<{
  fromDate: Types.Scalars['DateTime']['input'];
  toDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
}>;


export type StatisticsAssetsHistoryQuery = { __typename: 'Query', assetHistoryItems: Array<{ __typename: 'AssetHistory', id: string, nextActor: Types.NextActor, state: Types.AssetState, asset?: { __typename: 'Asset', id: string } | null, object?: { __typename: 'Asset', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export type StatisticsPreviousAssetStatesQueryVariables = Types.Exact<{
  fromDate: Types.Scalars['DateTime']['input'];
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type StatisticsPreviousAssetStatesQuery = { __typename: 'Query', previousAssetHistoryItems: Array<{ __typename: 'AssetHistory', id: string, nextActor: Types.NextActor, state: Types.AssetState, asset?: { __typename: 'Asset', id: string } | null, object?: { __typename: 'Asset', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export type StatisticsMaterialsHistoryQueryVariables = Types.Exact<{
  fromDate: Types.Scalars['DateTime']['input'];
  toDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
}>;


export type StatisticsMaterialsHistoryQuery = { __typename: 'Query', materialHistoryItems: Array<{ __typename: 'MaterialHistory', id: string, nextActor: Types.NextActor, state: Types.MaterialState, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, material?: { __typename: 'Material', id: string } | null, object?: { __typename: 'Material', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export type StatisticsPreviousMaterialStatesQueryVariables = Types.Exact<{
  fromDate: Types.Scalars['DateTime']['input'];
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type StatisticsPreviousMaterialStatesQuery = { __typename: 'Query', previousMaterialHistoryItems: Array<{ __typename: 'MaterialHistory', id: string, nextActor: Types.NextActor, state: Types.MaterialState, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, material?: { __typename: 'Material', id: string } | null, object?: { __typename: 'Material', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export type StatisticsModelsHistoryQueryVariables = Types.Exact<{
  fromDate: Types.Scalars['DateTime']['input'];
  toDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
}>;


export type StatisticsModelsHistoryQuery = { __typename: 'Query', modelHistoryItems: Array<{ __typename: 'ModelHistory', id: string, nextActor: Types.NextActor, state: Types.ModelState, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, model?: { __typename: 'Model', id: string } | null, object?: { __typename: 'Model', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export type StatisticsPreviousModelStatesQueryVariables = Types.Exact<{
  fromDate: Types.Scalars['DateTime']['input'];
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type StatisticsPreviousModelStatesQuery = { __typename: 'Query', previousModelHistoryItems: Array<{ __typename: 'ModelHistory', id: string, nextActor: Types.NextActor, state: Types.ModelState, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, model?: { __typename: 'Model', id: string } | null, object?: { __typename: 'Model', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export type StatisticsPicturesHistoryQueryVariables = Types.Exact<{
  fromDate: Types.Scalars['DateTime']['input'];
  toDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
}>;


export type StatisticsPicturesHistoryQuery = { __typename: 'Query', pictureHistoryItems: Array<{ __typename: 'PictureHistory', id: string, nextActor: Types.NextActor, state: Types.PictureState, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, object?: { __typename: 'Picture', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, picture?: { __typename: 'Picture', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export type StatisticsPreviousPictureStatesQueryVariables = Types.Exact<{
  fromDate: Types.Scalars['DateTime']['input'];
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type StatisticsPreviousPictureStatesQuery = { __typename: 'Query', previousPictureHistoryItems: Array<{ __typename: 'PictureHistory', id: string, nextActor: Types.NextActor, state: Types.PictureState, createdBy?: { __typename: 'User', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, object?: { __typename: 'Picture', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, picture?: { __typename: 'Picture', id: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export const SceneStatisticsDetailsFragmentDoc = gql`
    fragment SceneStatisticsDetails on SceneStatistics {
  id
  scene {
    id
  }
  created
}
    `;
export const ArStatisticsDetailsFragmentDoc = gql`
    fragment ArStatisticsDetails on ArStatistics {
  id
  sceneRevision {
    id
  }
  created
}
    `;
export const AssetStatisticsFragmentDoc = gql`
    fragment AssetStatistics on AssetHistory {
  id
  asset {
    id
  }
  nextActor
  state
  object: asset {
    id
    galleryImage {
      id
    }
  }
  createdBy {
    id
    galleryImage {
      id
    }
  }
}
    `;
export const MaterialStatisticsFragmentDoc = gql`
    fragment MaterialStatistics on MaterialHistory {
  id
  createdBy {
    id
    galleryImage {
      id
    }
  }
  material {
    id
  }
  nextActor
  object: material {
    id
    galleryImage {
      id
    }
  }
  state
}
    `;
export const ModelStatisticsFragmentDoc = gql`
    fragment ModelStatistics on ModelHistory {
  id
  createdBy {
    id
    galleryImage {
      id
    }
  }
  model {
    id
  }
  nextActor
  object: model {
    id
    galleryImage {
      id
    }
  }
  state
}
    `;
export const PictureStatisticsFragmentDoc = gql`
    fragment PictureStatistics on PictureHistory {
  id
  createdBy {
    id
    galleryImage {
      id
    }
  }
  nextActor
  object: picture {
    id
    galleryImage {
      id
    }
  }
  picture {
    id
    galleryImage {
      id
    }
  }
  state
}
    `;
export const GetSceneStatisticsDocument = gql`
    query getSceneStatistics($filter: SceneStatisticsFilterInput) {
  sceneStatisticsItemsCount(filter: $filter)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSceneStatisticsGQL extends Apollo.Query<GetSceneStatisticsQuery, GetSceneStatisticsQueryVariables> {
    override document = GetSceneStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSceneStatisticsDocument = gql`
    mutation createSceneStatistics($input: MutationCreateSceneStatisticsInput!) {
  createSceneStatistics(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSceneStatisticsGQL extends Apollo.Mutation<CreateSceneStatisticsMutation, CreateSceneStatisticsMutationVariables> {
    override document = CreateSceneStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetArStatisticsDocument = gql`
    query getArStatistics($filter: ArStatisticsFilterInput) {
  arStatisticsItemsCount(filter: $filter)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetArStatisticsGQL extends Apollo.Query<GetArStatisticsQuery, GetArStatisticsQueryVariables> {
    override document = GetArStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateArStatisticsDocument = gql`
    mutation createArStatistics($input: MutationCreateArStatisticsInput!) {
  createArStatistics(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateArStatisticsGQL extends Apollo.Mutation<CreateArStatisticsMutation, CreateArStatisticsMutationVariables> {
    override document = CreateArStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatisticsAssetsHistoryDocument = gql`
    query statisticsAssetsHistory($fromDate: DateTime!, $toDate: DateTime) {
  assetHistoryItems(
    filter: {historyDate: {gt: $fromDate, lte: $toDate}}
    orderBy: {key: history_date, direction: asc}
    take: 9999
  ) {
    ...AssetStatistics
  }
}
    ${AssetStatisticsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StatisticsAssetsHistoryGQL extends Apollo.Query<StatisticsAssetsHistoryQuery, StatisticsAssetsHistoryQueryVariables> {
    override document = StatisticsAssetsHistoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatisticsPreviousAssetStatesDocument = gql`
    query statisticsPreviousAssetStates($fromDate: DateTime!, $ids: [ID!]) {
  previousAssetHistoryItems: assetHistoryItems(
    filter: {historyDate: {lte: $fromDate}, assetId: {in: $ids}}
    distinct: true
    orderBy: {key: history_date, direction: desc}
    take: 9999
  ) {
    ...AssetStatistics
  }
}
    ${AssetStatisticsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StatisticsPreviousAssetStatesGQL extends Apollo.Query<StatisticsPreviousAssetStatesQuery, StatisticsPreviousAssetStatesQueryVariables> {
    override document = StatisticsPreviousAssetStatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatisticsMaterialsHistoryDocument = gql`
    query statisticsMaterialsHistory($fromDate: DateTime!, $toDate: DateTime) {
  materialHistoryItems(
    filter: {historyDate: {gt: $fromDate, lte: $toDate}}
    orderBy: {key: history_date, direction: asc}
    take: 9999
  ) {
    ...MaterialStatistics
  }
}
    ${MaterialStatisticsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StatisticsMaterialsHistoryGQL extends Apollo.Query<StatisticsMaterialsHistoryQuery, StatisticsMaterialsHistoryQueryVariables> {
    override document = StatisticsMaterialsHistoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatisticsPreviousMaterialStatesDocument = gql`
    query statisticsPreviousMaterialStates($fromDate: DateTime!, $ids: [ID!]) {
  previousMaterialHistoryItems: materialHistoryItems(
    filter: {historyDate: {lte: $fromDate}, materialId: {in: $ids}}
    distinct: true
    orderBy: {key: history_date, direction: desc}
    take: 9999
  ) {
    ...MaterialStatistics
  }
}
    ${MaterialStatisticsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StatisticsPreviousMaterialStatesGQL extends Apollo.Query<StatisticsPreviousMaterialStatesQuery, StatisticsPreviousMaterialStatesQueryVariables> {
    override document = StatisticsPreviousMaterialStatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatisticsModelsHistoryDocument = gql`
    query statisticsModelsHistory($fromDate: DateTime!, $toDate: DateTime) {
  modelHistoryItems(
    filter: {historyDate: {gt: $fromDate, lte: $toDate}}
    orderBy: {key: history_date, direction: asc}
    take: 9999
  ) {
    ...ModelStatistics
  }
}
    ${ModelStatisticsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StatisticsModelsHistoryGQL extends Apollo.Query<StatisticsModelsHistoryQuery, StatisticsModelsHistoryQueryVariables> {
    override document = StatisticsModelsHistoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatisticsPreviousModelStatesDocument = gql`
    query statisticsPreviousModelStates($fromDate: DateTime!, $ids: [ID!]) {
  previousModelHistoryItems: modelHistoryItems(
    filter: {historyDate: {lte: $fromDate}, modelId: {in: $ids}}
    distinct: true
    orderBy: {key: history_date, direction: desc}
    take: 9999
  ) {
    ...ModelStatistics
  }
}
    ${ModelStatisticsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StatisticsPreviousModelStatesGQL extends Apollo.Query<StatisticsPreviousModelStatesQuery, StatisticsPreviousModelStatesQueryVariables> {
    override document = StatisticsPreviousModelStatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatisticsPicturesHistoryDocument = gql`
    query statisticsPicturesHistory($fromDate: DateTime!, $toDate: DateTime) {
  pictureHistoryItems(
    filter: {historyDate: {gt: $fromDate, lte: $toDate}}
    orderBy: {key: history_date, direction: asc}
    take: 9999
  ) {
    ...PictureStatistics
  }
}
    ${PictureStatisticsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StatisticsPicturesHistoryGQL extends Apollo.Query<StatisticsPicturesHistoryQuery, StatisticsPicturesHistoryQueryVariables> {
    override document = StatisticsPicturesHistoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatisticsPreviousPictureStatesDocument = gql`
    query statisticsPreviousPictureStates($fromDate: DateTime!, $ids: [ID!]) {
  previousPictureHistoryItems: pictureHistoryItems(
    filter: {historyDate: {lte: $fromDate}, pictureId: {in: $ids}}
    distinct: true
    orderBy: {key: history_date, direction: desc}
    take: 9999
  ) {
    ...PictureStatistics
  }
}
    ${PictureStatisticsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StatisticsPreviousPictureStatesGQL extends Apollo.Query<StatisticsPreviousPictureStatesQuery, StatisticsPreviousPictureStatesQueryVariables> {
    override document = StatisticsPreviousPictureStatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }