import {Component, ElementRef, Input, input, viewChild, output} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {parseColor, colorToString} from "@cm/utils"

@Component({
    selector: "cm-color-input-socket",
    templateUrl: "./color-input-socket.component.html",
    styleUrls: ["./color-input-socket.component.scss"],
    imports: [FormsModule],
})
export class ColorInputSocketComponent {
    readonly $colorPicker = viewChild.required<ElementRef<HTMLInputElement>>("colorPicker")

    readonly $label = input.required<string>({alias: "label"})

    private _value!: [number, number, number]
    @Input() set value(value: [number, number, number]) {
        if (!value) value = [0.5, 0.5, 0.5]
        this._value = value
        this._valueString = colorToString(value)
    }
    get value(): [number, number, number] {
        return this._value
    }

    readonly $type = input.required<string>({alias: "type"})
    readonly $disableEditingIfConnected = input(true, {alias: "disableEditingIfConnected"})

    readonly valueChange = output<[number, number, number]>()

    private _valueString!: string
    get valueString(): string {
        return this._valueString
    }

    set valueString(str: string) {
        this.value = parseColor(str)
        this._valueString = str
        this.valueChange.emit(this.value)
    }

    _connected: {value: boolean} = {value: false}
    get connected(): {value: boolean} {
        return this._connected
    }

    @Input() set connected(value: {value: boolean}) {
        this._connected = value
    }

    get editingEnabled(): boolean {
        return !this.$disableEditingIfConnected() || !this.connected.value
    }
}
