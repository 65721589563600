import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AssociatedJobsTableItemFragment = { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, progress?: number | null, started?: string | null, state: Types.JobState, createdBy?: { __typename: 'User', id: string, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null };

export type AssociatedJobsTableJobAssignmentFragment = { __typename: 'JobAssignment', id: string, job: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, progress?: number | null, started?: string | null, state: Types.JobState, createdBy?: { __typename: 'User', id: string, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } };

export type AssociatedJobsTableItemsQueryVariables = Types.Exact<{
  objectId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  objectLegacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  contentTypeModel?: Types.InputMaybe<Types.ContentTypeModel>;
}>;


export type AssociatedJobsTableItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'JobAssignment', id: string, job: { __typename: 'Job', id: string, legacyId: number, cancelled?: string | null, createdAt?: string | null, finished?: string | null, message?: string | null, name?: string | null, progress?: number | null, started?: string | null, state: Types.JobState, createdBy?: { __typename: 'User', id: string, name: string, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } } | null> };

export const AssociatedJobsTableItemFragmentDoc = gql`
    fragment AssociatedJobsTableItem on Job {
  id
  legacyId
  cancelled
  createdAt
  finished
  message
  name
  progress
  started
  state
  createdBy {
    id
    name
    galleryImage {
      id
    }
  }
}
    `;
export const AssociatedJobsTableJobAssignmentFragmentDoc = gql`
    fragment AssociatedJobsTableJobAssignment on JobAssignment {
  id
  job {
    ...AssociatedJobsTableItem
  }
}
    `;
export const AssociatedJobsTableItemsDocument = gql`
    query associatedJobsTableItems($objectId: ID, $objectLegacyId: Int, $contentTypeModel: ContentTypeModel) {
  items: jobAssignments(
    filter: {objectId: $objectId, objectLegacyId: $objectLegacyId, contentTypeModel: $contentTypeModel}
    take: 1000
  ) {
    ...AssociatedJobsTableJobAssignment
  }
  totalCount: jobAssignmentsCount(
    filter: {objectId: $objectId, objectLegacyId: $objectLegacyId, contentTypeModel: $contentTypeModel}
  )
}
    ${AssociatedJobsTableJobAssignmentFragmentDoc}
${AssociatedJobsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssociatedJobsTableItemsGQL extends Apollo.Query<AssociatedJobsTableItemsQuery, AssociatedJobsTableItemsQueryVariables> {
    override document = AssociatedJobsTableItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }