import {Component, viewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {TexGradientInputs, TexGradientOutputs} from "@material-editor/models/nodes/tex-gradient"

@Component({
    selector: "cm-tex-gradient-node",
    templateUrl: "./tex-gradient-node.component.html",
    styleUrls: ["./tex-gradient-node.component.scss"],
    imports: [NodeBaseComponent],
})
export class TexGradientNodeComponent {
    readonly $nodeBase = viewChild<MaterialNodeBase>("nodeBase")
    inputs = TexGradientInputs
    outputs = TexGradientOutputs
    typeInfo = TexGradientNodeType
}

export const TexGradientNodeType: MaterialNodeType<typeof TexGradientNodeComponent> = {
    id: "texGradient",
    label: "Gradient Texture",
    color: "#4987af",
    name: "ShaderNodeTexGradient",
    inputs: [TexGradientInputs.vector] as never[],
    outputs: [TexGradientOutputs.color, TexGradientOutputs.fac],
    component: TexGradientNodeComponent,
}
