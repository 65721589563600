import {DecimalPipe} from "@angular/common"
import {ChangeDetectorRef, Component, ElementRef, inject, OnInit, viewChild} from "@angular/core"
import {MatMenuModule} from "@angular/material/menu"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {Enums} from "@enums"
import {TextureRevisionState} from "@generated"
import {Labels} from "@labels"
import {
    ImageTextureNodeTextureFragment,
    ImageTextureNodeTextureGQL,
    ImageTextureNodeTextureRevisionFragment,
} from "@material-editor/components/nodes/image-texture-node/image-texture-node.generated"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {ImageTextureInputs, ImageTextureOutputs} from "@material-editor/models/nodes"
import {TextureInfoService} from "@material-editor/services/texture-info.service"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {firstValueFrom, ReplaySubject} from "rxjs"
import tippy from "tippy.js"

@Component({
    selector: "cm-image-texture-node",
    templateUrl: "./image-texture-node.component.html",
    styleUrls: ["./image-texture-node.component.scss"],
    imports: [NumericInputComponent, InputContainerComponent, MatMenuModule, DecimalPipe, NodeBaseComponent],
})
export class ImageTextureNodeComponent implements OnInit {
    readonly $nodeBase = viewChild.required<MaterialNodeBase>("nodeBase")
    readonly $textureIcon = viewChild<ElementRef<HTMLElement>>("textureIcon")
    readonly $textureInfoTemplate = viewChild<ElementRef<HTMLElement>>("textureInfoTemplate")

    outputs = ImageTextureOutputs
    inputs = ImageTextureInputs
    typeInfo = ImageTextureNodeType

    thumbnailUrl?: string
    texture?: ImageTextureNodeTextureFragment | null
    textureRevision?: ImageTextureNodeTextureRevisionFragment
    textureGroupId?: string

    private readonly imageTextureNodeTexture = inject(ImageTextureNodeTextureGQL)

    constructor(
        private textureInfoService: TextureInfoService,
        private changeDetectionRef: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        if (this.$nodeBase()?.node?.textureRevision) {
            void this.loadTextureRevision()
        }
    }

    private textureInfoReady: ReplaySubject<void> = new ReplaySubject<void>(1)

    ngAfterViewInit() {
        this.textureInfoReady.next()
        this.textureInfoReady.complete()
    }

    setupTextureInfo() {
        const textureInfoTemplateValue = this.$textureInfoTemplate()
        if (!textureInfoTemplateValue || !textureInfoTemplateValue.nativeElement) throw Error("textureInfo template not found")
        const textureInfoTemplate = textureInfoTemplateValue.nativeElement
        const textureIcon = this.$textureIcon()
        if (!textureIcon || !textureIcon.nativeElement) throw Error("textureIcon not found")
        textureInfoTemplate.style.display = "block"
        tippy(textureIcon.nativeElement, {
            content: textureInfoTemplate,
            allowHTML: true,
            placement: "right",
            arrow: false,
            offset: [0, 20],
            interactive: false,
            interactiveBorder: 30,
            trigger: "mouseenter",
        })
    }

    async loadTextureRevision() {
        const nodeBase = this.$nodeBase()
        if (!nodeBase?.node.textureRevision) {
            return
        }
        const {textureRevision} = await fetchThrowingErrors(this.imageTextureNodeTexture)({
            textureRevisionLegacyId: nodeBase.node.textureRevision,
        })
        this.textureRevision = textureRevision
        this.texture = textureRevision.texture
        this.thumbnailUrl =
            this.textureRevision.thumbnailAssignments?.[0]?.dataObject?.thumbnail?.downloadUrl ?? this.textureRevision.dataObject.thumbnail?.downloadUrl
        this.changeDetectionRef.detectChanges()
        await firstValueFrom(this.textureInfoReady)
        this.setupTextureInfo()

        if (!this.texture) {
            throw Error("Texture undefined.")
        }
        if (!this.textureRevision) {
            throw Error("TextureRevision undefined.")
        }
        this.textureInfoService.setTextureSizeInfo({
            textureTypeLabel: Labels.TextureType.get(this.texture.type)?.label ?? "",
            width: this.textureRevision.width,
            height: this.textureRevision.height,
        })

        this.textureGroupId = this.texture.textureSet.textureGroup.id
        this.triggerGraphUpdate()
    }

    // FIXME: this is a quick hack to update the material graph after changing the texture revision.
    triggerGraphUpdate(): void {
        const nodeBase = this.$nodeBase()
        nodeBase?.emitParameterChange({
            node: nodeBase.node,
            type: "updateGraph",
            parameter: {id: "", type: "string", value: ""},
        })
    }

    protected readonly Enums = Enums
    protected readonly TextureRevisionState = TextureRevisionState
    protected readonly Labels = Labels
}

export const ImageTextureNodeType: MaterialNodeType<typeof ImageTextureNodeComponent> = {
    id: "imageTexture",
    label: "Image Texture",
    color: "#9e6034",
    name: "TexImage",
    inputs: [ImageTextureInputs.vector],
    outputs: [ImageTextureOutputs.color, ImageTextureOutputs.alpha],
    component: ImageTextureNodeComponent,
}
