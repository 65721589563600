import {Matrix4, Vector3} from "@cm/math"

export function from3dsMaxTransform(t: Vector3 | undefined, r: Vector3 | undefined, s: Vector3 | undefined, orientation: boolean): Matrix4 {
    // cameras have a different orientation in Max and threeJS, fix that by leaving out the initial transform
    let matrix = orientation ? Matrix4.identity() : Matrix4.fromArray([1, 0, 0, 0, 0, 0, 1, 0, 0, -1, 0, 0, 0, 0, 0, 1]) // from native to Max
    if (s) {
        matrix = Matrix4.scaling(s.x, s.y, s.z).multiply(matrix)
    }
    if (r) {
        matrix = Matrix4.rotationX(r.x * (180 / Math.PI)).multiply(matrix)
        matrix = Matrix4.rotationY(r.y * (180 / Math.PI)).multiply(matrix)
        matrix = Matrix4.rotationZ(r.z * (180 / Math.PI)).multiply(matrix)
    }
    if (t) {
        matrix = Matrix4.translation(t.x, t.y, t.z).multiply(matrix)
    }
    matrix = Matrix4.fromArray([1, 0, 0, 0, 0, 0, -1, 0, 0, 1, 0, 0, 0, 0, 0, 1]).multiply(matrix) // from Max to native
    return matrix
}

export function from3dsCoordinate(t: Vector3) {
    return new Vector3(t.x, t.z, -t.y)
}

export function to3dsMaxTransform(
    matrix: Matrix4,
    orientation: boolean,
): {
    position: Vector3
    rotation: Vector3
    scale: Vector3
} {
    const nm = Matrix4.fromArray([1, 0, 0, 0, 0, 0, 1, 0, 0, -1, 0, 0, 0, 0, 0, 1]) // from native to Max
    const mn = Matrix4.fromArray([1, 0, 0, 0, 0, 0, -1, 0, 0, 1, 0, 0, 0, 0, 0, 1]) // from Max to native

    // cameras have a different orientation in Max and threeJS, fix that by leaving out the initial transform
    const {position, quaternion, scale} = orientation ? nm.multiply(matrix).decompose() : nm.multiply(matrix).multiply(mn).decompose()
    const rotation = quaternion.toEuler("ZYX")

    return {position, rotation, scale}
}

export function to3dsCoordinate(t: Vector3) {
    return new Vector3(t.x, -t.z, t.y)
}
