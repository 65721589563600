@if ($allowBrushModeSelection()) {
    <div class="cm-move-modes">
        <cm-button
            class="cm-move-mode"
            [class.cm-active]="$brushSettings().brushMode === BrushMode.Add"
            (click)="$brushSettings().brushMode = BrushMode.Add"
            [disabled]="$disabled()"
        >
            <ng-container cm-left-icon><i class="far fa-plus"></i></ng-container>
            <ng-container cm-text>Add</ng-container>
        </cm-button>
        <cm-button
            class="cm-move-mode"
            [class.cm-active]="$brushSettings().brushMode === BrushMode.Subtract"
            (click)="$brushSettings().brushMode = BrushMode.Subtract"
            [disabled]="$disabled()"
        >
            <ng-container cm-left-icon><i class="far fa-minus"></i></ng-container>
            <ng-container cm-text>Subtract</ng-container>
        </cm-button>
    </div>
}
<div>
    <span class="cm-label">Opacity</span>
    <cm-slider [min]="0" [max]="1" [(value)]="$brushSettings().brushOpacity" [disabled]="$disabled()"></cm-slider>
</div>
<div>
    <span class="cm-label">Size</span>
    <cm-slider [min]="1" [max]="1000" [exponent]="4" [(value)]="$brushSettings().brushWidth" [disabled]="$disabled()"></cm-slider>
</div>
<div>
    <span class="cm-label">Hardness</span>
    <cm-slider [min]="0" [max]="1" [(value)]="$brushSettings().brushHardness" [disabled]="$disabled()"></cm-slider>
</div>
