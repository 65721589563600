<div class="cm-statistics-container">
    @if ($templateId() || $templateRevisionId()) {
        <div class="cm-date-picker">
            <input
                class="cm-date-input"
                readonly
                [(ngModel)]="fromDate"
                (ngModelChange)="updateStatistics()"
                [matDatepicker]="fromDateElement"
                [max]="toDate"
                matInput
            />
            <div (click)="fromDateElement.open()" class="cm-calendar-icon">
                <i class="far fa-calendar-day"></i>
            </div>
            <input
                class="cm-date-input"
                readonly
                [(ngModel)]="toDate"
                (ngModelChange)="updateStatistics()"
                [matDatepicker]="toDateElement"
                [min]="fromDate"
                [max]="datePickerMaxValue"
                matInput
            />
            <div (click)="toDateElement.open()" class="cm-calendar-icon">
                <i class="far fa-calendar-day"></i>
            </div>
        </div>
        <mat-datepicker #fromDateElement></mat-datepicker>
        <mat-datepicker #toDateElement></mat-datepicker>
        <div class="cm-statistics-text">
            <div>Scene Views: {{ sceneStatisticsItemsCount }}</div>
            <div>AR Views: {{ arStatisticsItemsCount }}</div>
        </div>
    } @else {
        <span class="cm-statistics-no-data-text">No template or template revision</span>
    }
</div>
