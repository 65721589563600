import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GalleryImageAssignmentsQueryVariables = Types.Exact<{
  objectId: Types.Scalars['ID']['input'];
  contentTypeModel?: Types.InputMaybe<Types.ContentTypeModel>;
  assignmentType?: Types.InputMaybe<Array<Types.DataObjectAssignmentType> | Types.DataObjectAssignmentType>;
}>;


export type GalleryImageAssignmentsQuery = { __typename: 'Query', galleryImageAssignments: Array<{ __typename: 'DataObjectAssignment', id: string } | null> };

export type GalleryImageAssignmentRemoveMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GalleryImageAssignmentRemoveMutation = { __typename: 'Mutation', deleteDataObjectAssignment?: any | null };

export type GalleryImageCountQueryVariables = Types.Exact<{
  objectId: Types.Scalars['ID']['input'];
  contentTypeModel: Types.ContentTypeModel;
  assignmentType?: Types.InputMaybe<Array<Types.DataObjectAssignmentType> | Types.DataObjectAssignmentType>;
}>;


export type GalleryImageCountQuery = { __typename: 'Query', galleryImageCount: number };

export const GalleryImageAssignmentsDocument = gql`
    query galleryImageAssignments($objectId: ID!, $contentTypeModel: ContentTypeModel, $assignmentType: [DataObjectAssignmentType!]) {
  galleryImageAssignments: dataObjectAssignments(
    filter: {objectId: $objectId, contentTypeModel: $contentTypeModel, assignmentType: $assignmentType}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GalleryImageAssignmentsGQL extends Apollo.Query<GalleryImageAssignmentsQuery, GalleryImageAssignmentsQueryVariables> {
    override document = GalleryImageAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GalleryImageAssignmentRemoveDocument = gql`
    mutation galleryImageAssignmentRemove($id: ID!) {
  deleteDataObjectAssignment(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GalleryImageAssignmentRemoveGQL extends Apollo.Mutation<GalleryImageAssignmentRemoveMutation, GalleryImageAssignmentRemoveMutationVariables> {
    override document = GalleryImageAssignmentRemoveDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GalleryImageCountDocument = gql`
    query galleryImageCount($objectId: ID!, $contentTypeModel: ContentTypeModel!, $assignmentType: [DataObjectAssignmentType!]) {
  galleryImageCount: dataObjectAssignmentsCount(
    filter: {objectId: $objectId, contentTypeModel: $contentTypeModel, assignmentType: $assignmentType}
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GalleryImageCountGQL extends Apollo.Query<GalleryImageCountQuery, GalleryImageCountQueryVariables> {
    override document = GalleryImageCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }