import {OverlayContainer} from "@angular/cdk/overlay"
import {provideHttpClient} from "@angular/common/http"
import {enableProdMode, ErrorHandler, Injectable, NgZone, provideZoneChangeDetection} from "@angular/core"
import {getAnalytics, provideAnalytics} from "@angular/fire/analytics"
import {initializeApp, provideFirebaseApp} from "@angular/fire/app"
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE, provideNativeDateAdapter} from "@angular/material/core"
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field"
import {bootstrapApplication, HAMMER_GESTURE_CONFIG} from "@angular/platform-browser"
import {provideAnimations} from "@angular/platform-browser/animations"
import {provideRouter, RouteReuseStrategy, withComponentInputBinding, withRouterConfig} from "@angular/router"
import {AppComponent} from "@app/app.component"
import {cmApolloConfig} from "@common/config/apollo"
import {CM_COLORMASS_CLIENT} from "@common/config/client-injector"
import {HammerConfig} from "@common/config/hammer"
import {cmTippyConfig} from "@common/config/tippy"
import {CM_DATE_FORMATS} from "@common/models/settings/settings"
import {ErrorHandlerService} from "@common/services/error-handler-service/error-handler-service.service"
import {NavigationBarService} from "@common/services/navigation-bar/navigation-bar-service"
import {environment} from "@environment"
import {provideTippyConfig} from "@ngneat/helipopper"
import {routes} from "@platform/config/routes"
import {RouteStrategy} from "@platform/services/route-strategy/route-strategy"
import {SceneManagerService} from "@template-editor/services/scene-manager.service"
import {provideApollo} from "apollo-angular"

if (environment.production) {
    enableProdMode()
}

@Injectable({providedIn: "root"})
export class CdkOverlayContainer extends OverlayContainer {}

void bootstrapApplication(AppComponent, {
    providers: [
        {
            provide: ErrorHandler,
            useClass: ErrorHandlerService,
        },
        {
            provide: NgZone,
            useValue: new NgZone({shouldCoalesceEventChangeDetection: false}),
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerConfig,
        },
        {provide: MAT_DATE_FORMATS, useValue: CM_DATE_FORMATS},
        {provide: MAT_DATE_LOCALE, useValue: "en-GB"},
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: "outline",
                floatLabel: "always",
                subscriptSizing: "dynamic",
            },
        },
        {
            provide: RouteReuseStrategy,
            useClass: RouteStrategy,
        },
        {provide: CM_COLORMASS_CLIENT, useValue: "platform"},
        provideAnalytics(() => getAnalytics()),
        provideAnimations(),
        provideApollo(cmApolloConfig),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideHttpClient(),
        provideNativeDateAdapter(),
        provideRouter(routes, withRouterConfig({paramsInheritanceStrategy: "always"}), withComponentInputBinding()),
        provideTippyConfig(cmTippyConfig),
        NavigationBarService,
        SceneManagerService,
        provideZoneChangeDetection({eventCoalescing: true}),
    ],
}).catch((err) => console.error(err))
