<!--Workflow-->
<mat-form-field class="cm-infos-field">
    <mat-select placeholder="Workflow" [(ngModel)]="selectedExportWorkflow">
        @for (exportWorkflow of exportWorkflows; track exportWorkflow) {
            <mat-option [value]="exportWorkflow">{{ MaterialMapsExporter.workflowToString(exportWorkflow) }} </mat-option>
        }
    </mat-select>
</mat-form-field>
<!--Engine-->
<mat-form-field class="cm-infos-field">
    <mat-select placeholder="Engine" [(ngModel)]="selectedExportEngine">
        @for (exportEngine of exportEngines; track exportEngine) {
            <mat-option [value]="exportEngine">{{ MaterialMapsExporter.engineToString(exportEngine) }} </mat-option>
        }
    </mat-select>
</mat-form-field>
<!--NormalY-->
<mat-form-field class="cm-infos-field">
    <mat-select placeholder="Normal" [(ngModel)]="selectedExportNormalY">
        @for (exportNormalY of exportNormalsY; track exportNormalY) {
            <mat-option [value]="exportNormalY">
                {{ MaterialMapsExporter.normalYToString(exportNormalY) }}
            </mat-option>
        }
    </mat-select>
</mat-form-field>
<!--Format-->
<mat-form-field class="cm-infos-field">
    <mat-select placeholder="Format" [(ngModel)]="selectedExportFormat">
        @for (exportFormat of exportFormats; track exportFormat) {
            <mat-option [value]="exportFormat">
                {{ exportFormat | uppercase }}
            </mat-option>
        }
    </mat-select>
</mat-form-field>
<!--Format-->
<mat-form-field class="cm-infos-field">
    <mat-select placeholder="Resolution" [(ngModel)]="selectedExportResolution">
        @for (exportResolution of exportResolutions; track exportResolution) {
            <mat-option [value]="exportResolution">
                {{ MaterialMapsExporter.resolutionToString(exportResolution) }}
            </mat-option>
        }
    </mat-select>
</mat-form-field>
<button class="cm-button" matTooltip="Export with current settings" (click)="submit()" [disabled]="isLoading">
    Create custom export
    @if (isLoading) {
        <cm-loading-spinner-icon></cm-loading-spinner-icon>
    } @else {
        <i class="fa-regular fa-play"></i>
    }
</button>
