import {Component} from "@angular/core"
import {PostProcessRender} from "@cm/template-nodes"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {SelectionPossibilities, ValueSlotComponent} from "../../value-slot/value-slot.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {ToneMappingInspectorComponent} from "../tone-mapping-inspector/tone-mapping-inspector.component"

@Component({
    selector: "cm-post-process-render-inspector",
    templateUrl: "./post-process-render-inspector.component.html",
    styleUrl: "./post-process-render-inspector.component.scss",
    imports: [ValueSlotComponent, InspectorSectionComponent, ToneMappingInspectorComponent],
})
export class PostProcessRenderInspectorComponent extends BaseInspectorComponent<PostProcessRender, {}> {
    lutPossibilites: SelectionPossibilities<string> = [
        {value: "https://storage.googleapis.com/cm-platform-prod-static/LUTs/Kim_Amland_Photographic_01.cube", name: "Photographic 1"},
        {value: "https://storage.googleapis.com/cm-platform-prod-static/LUTs/Kim_Amland_Photographic_02.cube", name: "Photographic 2"},
        {value: "https://storage.googleapis.com/cm-platform-prod-static/LUTs/Kim_Amland_Photographic_03.cube", name: "Photographic 3"},
    ]
}
