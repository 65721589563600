import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MaterialOutputsDataFragment = { __typename: 'Material', id: string, name?: string | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, number: number } | null, textureGroup?: { __typename: 'TextureGroup', id: string } | null, organization?: { __typename: 'Organization', id: string } | null, outputs: Array<{ __typename: 'MaterialOutput', id: string, type: Types.MaterialOutputType, job?: { __typename: 'Job', id: string, state: Types.JobState } | null, config?: { __typename: 'JsonFile', id: string, content: any } | null, materialRevision?: { __typename: 'MaterialRevision', id: string, number: number } | null, result?: { __typename: 'DataObject', id: string, downloadUrl: string, mediaType?: string | null, originalFileName: string, jpeg?: { __typename: 'DataObject', downloadUrl: string } | null, tiff?: { __typename: 'DataObject', downloadUrl: string } | null } | null }> };

export type MaterialOutputFragment = { __typename: 'MaterialOutput', id: string, type: Types.MaterialOutputType, job?: { __typename: 'Job', id: string, state: Types.JobState } | null, config?: { __typename: 'JsonFile', id: string, content: any } | null, materialRevision?: { __typename: 'MaterialRevision', id: string, number: number } | null, result?: { __typename: 'DataObject', id: string, downloadUrl: string, mediaType?: string | null, originalFileName: string, jpeg?: { __typename: 'DataObject', downloadUrl: string } | null, tiff?: { __typename: 'DataObject', downloadUrl: string } | null } | null };

export type MaterialOutputsDataQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MaterialOutputsDataQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, name?: string | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, number: number } | null, textureGroup?: { __typename: 'TextureGroup', id: string } | null, organization?: { __typename: 'Organization', id: string } | null, outputs: Array<{ __typename: 'MaterialOutput', id: string, type: Types.MaterialOutputType, job?: { __typename: 'Job', id: string, state: Types.JobState } | null, config?: { __typename: 'JsonFile', id: string, content: any } | null, materialRevision?: { __typename: 'MaterialRevision', id: string, number: number } | null, result?: { __typename: 'DataObject', id: string, downloadUrl: string, mediaType?: string | null, originalFileName: string, jpeg?: { __typename: 'DataObject', downloadUrl: string } | null, tiff?: { __typename: 'DataObject', downloadUrl: string } | null } | null }> } };

export type MaterialOutputJobStateQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MaterialOutputJobStateQuery = { __typename: 'Query', job: { __typename: 'Job', id: string, state: Types.JobState } };

export type MaterialOutputsUpdateResultMutationVariables = Types.Exact<{
  input: Types.MutationUpdateDataObjectInput;
}>;


export type MaterialOutputsUpdateResultMutation = { __typename: 'Mutation', updateDataObject: { __typename: 'DataObject', id: string } };

export const MaterialOutputFragmentDoc = gql`
    fragment MaterialOutput on MaterialOutput {
  id
  type
  job {
    id
    state
  }
  config {
    id
    content
  }
  materialRevision {
    id
    number
  }
  result {
    id
    downloadUrl
    mediaType
    originalFileName
    jpeg: thumbnail(fileType: jpg, resolution: High) {
      downloadUrl
    }
    tiff: thumbnail(fileType: tiff, resolution: High) {
      downloadUrl
    }
  }
}
    `;
export const MaterialOutputsDataFragmentDoc = gql`
    fragment MaterialOutputsData on Material {
  id
  __typename
  name
  latestCyclesRevision {
    id
    number
  }
  textureGroup {
    id
  }
  organization {
    id
  }
  outputs {
    ...MaterialOutput
  }
}
    `;
export const MaterialOutputsDataDocument = gql`
    query materialOutputsData($id: ID!) {
  material(id: $id) {
    ...MaterialOutputsData
  }
}
    ${MaterialOutputsDataFragmentDoc}
${MaterialOutputFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialOutputsDataGQL extends Apollo.Query<MaterialOutputsDataQuery, MaterialOutputsDataQueryVariables> {
    override document = MaterialOutputsDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialOutputJobStateDocument = gql`
    query materialOutputJobState($id: ID!) {
  job(id: $id) {
    id
    state
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialOutputJobStateGQL extends Apollo.Query<MaterialOutputJobStateQuery, MaterialOutputJobStateQueryVariables> {
    override document = MaterialOutputJobStateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialOutputsUpdateResultDocument = gql`
    mutation materialOutputsUpdateResult($input: MutationUpdateDataObjectInput!) {
  updateDataObject(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialOutputsUpdateResultGQL extends Apollo.Mutation<MaterialOutputsUpdateResultMutation, MaterialOutputsUpdateResultMutationVariables> {
    override document = MaterialOutputsUpdateResultDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }