import {Observable, from, firstValueFrom} from "rxjs"
import {ReifiedMeshData, importMeshesFromFBXFile} from "@cm/template-nodes"
import {WebAssemblyWorkerService} from "@app/template-editor/services/webassembly-worker.service"
import {createTaskOperator} from "@common/helpers/tasks/observable-task-manager"
import {MeshNodes, DataNodes} from "@cm/render-nodes"

export {WebAssemblyWorkerService}

export type ConvertedDracoData = {
    name?: string
    exporterVersion: string
    drcData: Uint8Array
    defaultPosition: [number, number, number]
    centered: boolean
    dracoBitDepth: number
    dracoResolution: number
}

export type ConvertedDracoAndPLYData = ConvertedDracoData & {
    plyData: Uint8Array
}

export type RawPolyMeshData = {
    polyVertexCount: Uint32Array
    position: Float32Array
    normal: Float32Array
    materialID: Uint32Array //NOTE: per vertex!
    uvs: Float32Array[]
}

export function compressMeshGLTF(
    workerService: WebAssemblyWorkerService,
    positionAttr: Float32Array,
    normalAttr: Float32Array,
    uvAttr: Float32Array,
    bitDepth: number,
): Observable<Uint8Array> {
    return workerService
        .invokeFunction<Uint8Array>("compressDracoFileGLTF", [positionAttr, normalAttr, uvAttr, bitDepth])
        .pipe(createTaskOperator("compressMesh"))
}

export function convertRawPolyMeshToDracoAndPLY(
    workerService: WebAssemblyWorkerService,
    rawPolyMeshData: RawPolyMeshData,
    resolution: number,
    center: boolean,
): Observable<ConvertedDracoAndPLYData> {
    return workerService
        .invokeFunction<ConvertedDracoAndPLYData>("convertRawPolyMeshToDracoAndPLY", [rawPolyMeshData, resolution, center])
        .pipe(createTaskOperator("convertRawPolyMeshToDracoAndPLY"))
}

export function convertOBJToDracoAndPLY(
    workerService: WebAssemblyWorkerService,
    buffer: ArrayBuffer,
    resolution: number,
    center: boolean,
): Observable<ConvertedDracoAndPLYData[]> {
    return workerService
        .invokeFunction<ConvertedDracoAndPLYData[]>("convertOBJToDracoAndPLY", [buffer, resolution, center])
        .pipe(createTaskOperator("convertOBJToDracoAndPLY"))
}

export function convertPLYToDraco(
    workerService: WebAssemblyWorkerService,
    buffer: ArrayBuffer,
    resolution: number,
    center: boolean,
): Observable<ConvertedDracoData> {
    return workerService.invokeFunction<ConvertedDracoData>("convertPLYToDraco", [buffer, resolution, center]).pipe(createTaskOperator("convertPLYToDraco"))
}

export function convertFBXToDracoAndPLY(
    workerService: WebAssemblyWorkerService,
    buffer: ArrayBuffer,
    resolution: number,
    center: boolean,
): Observable<ConvertedDracoAndPLYData[]> {
    const allMeshData = importMeshesFromFBXFile(new Uint8Array(buffer))
    return from(
        Promise.all(
            allMeshData.map(async (meshData) => ({
                ...(await firstValueFrom(
                    convertRawPolyMeshToDracoAndPLY(
                        workerService,
                        {
                            polyVertexCount: meshData.verticesPerFace,
                            position: meshData.position,
                            normal: meshData.normal,
                            materialID: meshData.materialID,
                            uvs: meshData.uvs,
                        },
                        resolution,
                        center,
                    ),
                )),
                name: meshData.name,
            })),
        ),
    )
}

export function evaluateMesh(
    workerService: WebAssemblyWorkerService,
    graph: MeshNodes.Mesh,
    resources: DataNodes.EvaluationResources,
): Observable<ReifiedMeshData> {
    return workerService.invokeFunction<ReifiedMeshData>("evaluateMesh", [graph, resources]).pipe(createTaskOperator("evaluateMesh"))
}

export function clipAndOffsetMeshForDecal(
    workerService: WebAssemblyWorkerService,
    meshData: ReifiedMeshData,
    uvCenter: [number, number],
    uvRotation: number,
    size: [number, number],
    offset: number,
): Observable<ReifiedMeshData> {
    return workerService
        .invokeFunction<ReifiedMeshData>("clipAndOffsetMeshForDecal", [meshData, uvCenter, uvRotation, size, offset])
        .pipe(createTaskOperator("clipAndOffsetMeshForDecal"))
}
