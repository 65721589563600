import {inject, Injectable, Injector} from "@angular/core"
import {PriceGraphState} from "@generated"
import {ConfigMenuService} from "@app/common/components/viewers/configurator/config-menu/services/config-menu.service"
import {UnCachedNodeGraphResult} from "@cm/graph/evaluators/uncached-node-graph-result"

//It has happened that some nodes were not registered in the factory after graph deserialization, so we import them here to make sure they are registered
import "@cm/pricing/register-nodes"
import {PricedItem, PricingContext} from "@cm/pricing"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {loadPricingData} from "@pricing/helpers"
import {PriceGraphAccessor} from "@pricing/models/pricing/price-graph"
import {PricingServiceGetTemplateGQL} from "@pricing/services/pricing.generated"

@Injectable({
    providedIn: "root",
})
export class PricingService {
    templateUuid: string | undefined = undefined
    priceGraphAccessor: PriceGraphAccessor | undefined = undefined
    pricingContext: PricingContext | undefined = undefined

    readonly injector = inject(Injector)

    readonly templateGql = inject(PricingServiceGetTemplateGQL)

    readonly configMenuService = inject(ConfigMenuService)

    async load(templateUuid: string) {
        this.pricingContext = undefined
        this.priceGraphAccessor = undefined

        const {template} = await fetchThrowingErrors(this.templateGql)({templateId: templateUuid})
        const organizationId = template?.organizationId
        if (!organizationId) return

        const pricingData = await loadPricingData(this.injector, organizationId, templateUuid, PriceGraphState.Ready)
        if (!pricingData) return

        this.priceGraphAccessor = new PriceGraphAccessor(pricingData.rootNode)
        this.pricingContext = pricingData.pricingContext
    }

    getPricesAsList(): Promise<PricedItem[]> {
        if (!this.priceGraphAccessor || !this.pricingContext) return Promise.resolve([])
        this.setSelectedVariants()
        return new UnCachedNodeGraphResult(this.priceGraphAccessor.rootNode, this.pricingContext).run()
    }

    static computeTotalPrice(prices: PricedItem[]): number {
        return prices.reduce((acc, item) => acc + item.price * item.amount, 0)
    }

    private setSelectedVariants() {
        if (!this.priceGraphAccessor || !this.pricingContext) return

        const selectedVariants = this.configMenuService.getSelectedVariants()

        if (selectedVariants.length > 0) this.priceGraphAccessor.setCurrentConfigurations(selectedVariants)
    }
}
