import {AfterViewInit, Component, computed, CUSTOM_ELEMENTS_SCHEMA, effect, ElementRef, input, viewChild} from "@angular/core"
import {TestCaseEntry, updateIframe} from "@app/test/manual-testing/helpers/configurator-test-helpers"
import {getBasicPage} from "@app/test/manual-testing/material-download/helpers/pages"
import {TestContainerComponent} from "@app/test/manual-testing/test-container/test-container.component"
import {Source} from "@app/test/manual-testing/types/types"

@Component({
    selector: "cm-material-download-test",
    templateUrl: "./material-download.component.html",
    styleUrls: ["./material-download.component.scss"],
    imports: [TestContainerComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MaterialDownloadTestComponent implements AfterViewInit {
    readonly $source = input.required<Source>({alias: "source"})

    private readonly $iframe = viewChild.required<ElementRef<HTMLIFrameElement>>("iframe")

    readonly $moduleUrl = computed(() => {
        const source = this.$source()
        switch (source) {
            case "prod":
                return "https://material-download.colormass.com/"
            case "dev":
                return "https://cm-material-download-dev.web.app"
            case "qa":
                return "https://cm-material-download-qa-b6f44.web.app"
            case "local":
                return "http://localhost:8080/"
            default:
                throw new Error(`Unknown source ${source}`)
        }
    })

    testCases: TestCaseEntry[] = [
        {
            type: "info",
            name: "Download something",
            tooltip: "Check, if the links are working.",
            fct: () => {},
        },
    ]

    ngAfterViewInit() {
        this.loadDefaultPage()
    }

    constructor() {
        effect(() => {
            this.loadDefaultPage()
        })
    }

    private loadDefaultPage() {
        const _doc = this.$iframe().nativeElement.contentDocument
        updateIframe(this.$iframe().nativeElement, getBasicPage(this.$moduleUrl()))
    }
}
