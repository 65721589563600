<div
    class="cm-node"
    (click)="onClick($event)"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    [class.cm-icon-disabled]="$disabled()"
    [draggable]="$noDrag() !== true"
    (dragstart)="dragStart($event)"
    (dragend)="drag.dragEnd($event)"
>
    <i [class]="$iconClass()" [matTooltip]="$classLabel()" [class.cm-icon-disabled]="$disabled()"></i>
    <span class="cm-offset-icon" [class.cm-value-node]="$isNodeValue()" [matTooltip]="$label()">{{ $label() }}</span>
    @if ($secondaryIconClass(); as secondaryIconClass) {
        <i class="cm-offset-icon" [class]="secondaryIconClass" [matTooltip]="$classLabel()"></i>
    }
</div>
<div #dragPlaceholder></div>
