import {Component, OnInit, input} from "@angular/core"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {OperatorRotate} from "app/textures/texture-editor/operator-stack/operators/rotate/operator-rotate"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"

@Component({
    selector: "cm-rotate-panel",
    templateUrl: "./rotate-panel.component.html",
    styleUrls: ["./rotate-panel.component.scss"],
    imports: [ButtonComponent, InspectorSectionComponent],
})
export class RotatePanelComponent implements OnInit {
    readonly $operator = input.required<OperatorRotate>({alias: "operator"})
    readonly $disabled = input(false, {alias: "disabled"})

    constructor() {}

    ngOnInit(): void {}

    get angleInDegrees(): number {
        return this.$operator().angleInDegrees
    }

    set angleInDegrees(value: number) {
        this.$operator().angleInDegrees = value
    }
}
