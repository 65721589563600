<table>
    @if ($sourceInfo()) {
        <tr>
            <td>{{ "Source" }}</td>
            <td>{{ $sourceInfo() }}</td>
        </tr>
    }
    @if ($exportSummary().workflow.length > 0) {
        <tr>
            <td>Workflow</td>
            <td>{{ mapWorkflows($exportSummary().workflow).join(", ") }}</td>
        </tr>
    }
    @if ($exportSummary().engine.length > 0) {
        <tr>
            <td>Engine</td>
            <td>{{ mapEngines($exportSummary().engine).join(", ") }}</td>
        </tr>
    }
    @if ($exportSummary().normalY.length > 0) {
        <tr>
            <td>Normal</td>
            <td>{{ mapNormalYs($exportSummary().normalY).join(", ") }}</td>
        </tr>
    }
    @if ($exportSummary().format.length > 0) {
        <tr>
            <td>Format</td>
            <td>{{ $exportSummary().format.join(", ") | uppercase }}</td>
        </tr>
    }
    @if ($exportSummary().resolution.length > 0) {
        <tr>
            <td>Resolution</td>
            <td>{{ mapResolutions($exportSummary().resolution).join(", ") }}</td>
        </tr>
    }
</table>
