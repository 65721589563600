import {Component, EventEmitter, inject, OnInit, Output} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonToggleModule} from "@angular/material/button-toggle"
import {MatTooltipModule} from "@angular/material/tooltip"
import {IsDefined} from "@cm/utils/filter"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {NextActor} from "@generated"
import {AssignUserDialogVisibleUsersGQL} from "@platform/components/users/assign-user-dialog/assign-user-dialog.generated"
import {AssignUserComponent} from "@platform/components/users/assign-user/assign-user.component"
import {AssignUserFragment} from "@platform/components/users/assign-user/assign-user.generated"
import {UserThumbnailComponent} from "@platform/components/users/user-thumbnail/user-thumbnail.component"
import {UserListItemFragment} from "@platform/components/users/users-grid/users-grid.generated"

@Component({
    selector: "cm-assign-user-dialog",
    templateUrl: "./assign-user-dialog.component.html",
    styleUrls: ["./assign-user-dialog.component.scss"],
    imports: [MatTooltipModule, MatButtonToggleModule, FormsModule, UserThumbnailComponent],
})
export class AssignUserDialogComponent extends AssignUserComponent implements OnInit {
    @Output() userChange = new EventEmitter<{id: string} | undefined>()
    @Output() nextActorChange = new EventEmitter<NextActor>()
    readonly NextActor = NextActor

    readonly visibleUsersGql = inject(AssignUserDialogVisibleUsersGQL)

    visibleUsers: AssignUserFragment[] | null = null

    updateNextActor(nextActor: NextActor) {
        this.nextActor = nextActor
        this.nextActorChange.emit(nextActor)
        this.update()
    }

    async assignUser(user: Pick<UserListItemFragment, "id" | "firstName" | "lastName" | "galleryImage">) {
        this.userChange.emit(user)
        this.update()
    }

    async deleteAssignedUser() {
        this.userChange.emit(undefined)
        this.update()
    }

    ngOnInit() {
        fetchThrowingErrors(this.visibleUsersGql)({}).then(({users}) => {
            this.visibleUsers = users.filter(IsDefined)
        })
    }
}
