import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AddMaterialDialogTagFragment = { __typename: 'Tag', id: string, name: string, organization?: { __typename: 'Organization', id: string } | null };

export type AddMaterialDialogMaterialRangeTagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AddMaterialDialogMaterialRangeTagsQuery = { __typename: 'Query', tags: Array<{ __typename: 'Tag', id: string, name: string, organization?: { __typename: 'Organization', id: string } | null } | null> };

export const AddMaterialDialogTagFragmentDoc = gql`
    fragment AddMaterialDialogTag on Tag {
  id
  name
  organization {
    id
  }
}
    `;
export const AddMaterialDialogMaterialRangeTagsDocument = gql`
    query addMaterialDialogMaterialRangeTags {
  tags(filter: {tagType: MaterialRange}, take: 9999) {
    ...AddMaterialDialogTag
  }
}
    ${AddMaterialDialogTagFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddMaterialDialogMaterialRangeTagsGQL extends Apollo.Query<AddMaterialDialogMaterialRangeTagsQuery, AddMaterialDialogMaterialRangeTagsQueryVariables> {
    override document = AddMaterialDialogMaterialRangeTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }