import {CurveVizItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/basic/curve-viz-item"
import {BoundaryDirection, BoundaryItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-item"
import {BoundaryCurveControlPoint} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-curve-control-point"
import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {Vector2, Vector2Like} from "@cm/math"
import {LinearInterpolator} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/linear-interpolator"
import {EventEmitter} from "@angular/core"
import {auditTime, merge, Subject, takeUntil} from "rxjs"
import {BoundaryControlPoint} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-control-point"
import {HelperLineItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/helper-lines/helper-line-item"
import {CurveInterpolator} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/curve-interpolator"

export type ChangeEvent = {
    type: "point-added" | "point-removed" | "point-source-position-moved" | "point-result-uv-moved"
}

export class BoundaryCurveItem extends CanvasBaseToolboxItemBase<BoundaryItem> {
    readonly curveChanged = new EventEmitter<ChangeEvent>()

    constructor(
        readonly boundary: BoundaryItem,
        flipLabelSide: boolean,
    ) {
        super(boundary)
        this._curveVisItem = new CurveVizItem(this, 2, flipLabelSide)

        merge(this.spatialMapping.mappingChanged, this.spatialMapping.viewModeChanged)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.onDisplayedMappingChanged())

        // update guiding helper line when helper-lines change
        this.spatialMapping.boundaryFollowsHelperLinesEnabledChanged.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.invalidateGuidingHelperLine())
        this.spatialMapping.helperLinesBagItem.helperLineFinalized.pipe(takeUntil(this.unsubscribe)).subscribe((helperLine) => {
            this.updateHelperLineInfo(helperLine)
            this.invalidateGuidingHelperLine()
        })
        this.spatialMapping.helperLinesBagItem.helperLineRemoved.pipe(takeUntil(this.unsubscribe)).subscribe((helperLine) => {
            this.removeHelperLineInfo(helperLine)
            this.invalidateGuidingHelperLine()
        })
        this.spatialMapping.helperLinesBagItem.helperLines.forEach((helperLine) => this.updateHelperLineInfo(helperLine))

        this._synchronizeDisplay$.pipe(auditTime(0), takeUntil(this.unsubscribe)).subscribe(() => this.updateDisplay())
        this._synchronizeDisplay$.next()
    }

    get spatialMapping() {
        return this.boundary.spatialMapping
    }

    createControlPoint(controlPoint: BoundaryControlPoint, t: number) {
        controlPoint.item.itemRemove.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            const index = this._curveControlPoints.findIndex((curveControlPoint) => curveControlPoint.controlPoint === controlPoint)
            if (index < 0) {
                throw new Error("Control point not found")
            }
            this._curveControlPoints.splice(index, 1)
            this.removeHelperLineInfo(controlPoint)
            this.invalidate()
            this.curveChanged.emit({type: "point-removed"})
        })
        const curveControlPoint = new BoundaryCurveControlPoint(this, controlPoint, t)
        curveControlPoint.controlPoint.sourcePositionChanged.subscribe(() => this.onControlPointSourcePositionChanged(controlPoint))
        curveControlPoint.controlPoint.resultUVChanged.subscribe(() => this.onControlPointResultUVChanged(controlPoint))
        const insertionIndex = this._curveControlPoints.findIndex((curveControlPoint) => curveControlPoint.t > t)
        this._curveControlPoints.splice(insertionIndex === -1 ? this._curveControlPoints.length : insertionIndex, 0, curveControlPoint)
        this.updateHelperLineInfo(controlPoint)
        this.invalidate()
        this.curveChanged.emit({type: "point-added"})
        return curveControlPoint
    }

    computeClosestT(point: Vector2Like) {
        return this.interpolator.solveForT(point)
    }

    getPoint(t: number) {
        return this.interpolator.evaluate(t)
    }

    getTangent(t: number) {
        return this.interpolator.evaluateTangent(t)
    }

    computeCurveCumulativeArcLengths(): number[] {
        const interpolator = this.interpolator
        const numSegments = this._curveControlPoints.length - 1
        const tValues = this.tValues
        const cumulativeControlPointArcLengths = new Array<number>(numSegments + 1)
        cumulativeControlPointArcLengths[0] = 0
        let tValueIndex = 0
        while (tValues[tValueIndex] < this._curveControlPoints[0].t) {
            tValueIndex++
        }
        for (let i = 0; i < numSegments; i++) {
            const tStart = this._curveControlPoints[i].t
            const tEnd = this._curveControlPoints[i + 1].t
            let segmentLength = 0
            let lastPosition = interpolator.evaluate(tStart)
            for (;;) {
                const t = tValues[tValueIndex]
                if (t >= tEnd) {
                    break
                }
                const position = interpolator.evaluate(tValues[tValueIndex])
                if (lastPosition) {
                    segmentLength += Vector2.distance(lastPosition, position)
                }
                lastPosition = position
                tValueIndex++
            }
            if (lastPosition) {
                const endPosition = interpolator.evaluate(tEnd)
                segmentLength += Vector2.distance(lastPosition, endPosition)
            }
            cumulativeControlPointArcLengths[i + 1] = cumulativeControlPointArcLengths[i] + segmentLength
        }

        return cumulativeControlPointArcLengths
    }

    get curveControlPoints() {
        return this._curveControlPoints
    }

    get curveVisItem() {
        return this._curveVisItem
    }

    get interpolator(): CurveInterpolator {
        this.update()
        if (!this._curveInterpolator) {
            throw new Error("Curve interpolator not initialized")
        }
        return this._curveInterpolator
    }

    get tValues() {
        this.update()
        if (!this._curveInterpolator) {
            throw new Error("Curve interpolator not initialized")
        }
        return this._curveInterpolator.tValues
    }

    private onControlPointSourcePositionChanged(controlPoint: BoundaryControlPoint) {
        this.updateHelperLineInfo(controlPoint)
        this.invalidateGuidingHelperLine()
    }

    private onControlPointResultUVChanged(_controlPoint: BoundaryControlPoint) {
        this.invalidate()
        this.curveChanged.emit({type: "point-result-uv-moved"})
    }

    private invalidateGuidingHelperLine() {
        this._guidingHelperLine = undefined
        this.invalidate()
        this.curveChanged.emit({type: "point-source-position-moved"})
    }

    private updateDisplay() {
        this.update()
        const tValues = this.tValues
        const points = new Array<Vector2>(tValues.length)
        const isHorizontal = this.boundary.direction === BoundaryDirection.Horizontal
        const otherT = this.boundary.curveMin === this ? 0 : 1
        tValues.forEach((t, i) => {
            const uv = isHorizontal ? new Vector2(t, otherT) : new Vector2(otherT, t)
            points[i] = this.spatialMapping.mapUVToScreenSpace(uv)
        })
        this._curveVisItem.setCurvePoints(points, tValues)
    }

    private onDisplayedMappingChanged() {
        this._synchronizeDisplay$.next()
    }

    private invalidate() {
        this._needsUpdate = true
        this._synchronizeDisplay$.next()
    }

    private determineBestGuidingHelperLine() {
        if (!this.spatialMapping.boundaryFollowsHelperLinesEnabled) {
            return null
        } else {
            // pick the helper line that is closest to the curve
            const computePrincipalDirection = (points: Vector2[]) => {
                if (points.length < 2) {
                    throw new Error("Not enough points")
                }
                return points[points.length - 1].sub(points[0]).normalized()
            }
            const curvePrincipalDirection = computePrincipalDirection(
                this._curveControlPoints.map((curveControlPoint) => curveControlPoint.controlPoint.sourcePosition),
            )
            const bestHelperLineAndDistance = Array.from(this._helperLineInfoByControlPointByGuidingHelperLine.keys())
                .filter((helperLine) => {
                    // reject helper lines that have less than 2 points
                    if (helperLine.points.length < 2) {
                        return false
                    }
                    // reject helper lines that are not somewhat aligned to the curve
                    const helperLinePrincipalDirection = computePrincipalDirection(helperLine.points)
                    if (Math.abs(Vector2.dot(helperLinePrincipalDirection, curvePrincipalDirection)) < 0.5) {
                        return false
                    }
                    return true
                })
                .reduce(
                    (bestHelperLineAndSum, helperLine) => {
                        const helperLineInfoByControlPoint = this._helperLineInfoByControlPointByGuidingHelperLine.get(helperLine)
                        if (!helperLineInfoByControlPoint) {
                            throw new Error("Helper line info not found")
                        }
                        const avgDistance =
                            this.curveControlPoints.reduce((sum, curveControlPoint) => {
                                const info = helperLineInfoByControlPoint.get(curveControlPoint.controlPoint)
                                if (!info) {
                                    throw new Error("Helper line info not found")
                                }
                                const distance = info.distance
                                return sum + distance
                            }, 0) / this.curveControlPoints.length
                        if (!bestHelperLineAndSum || avgDistance < bestHelperLineAndSum[1]) {
                            return [helperLine, avgDistance] as const
                        } else {
                            return bestHelperLineAndSum
                        }
                    },
                    null as readonly [HelperLineItem, number] | null,
                )
            const maxDistance = 1024
            if (bestHelperLineAndDistance && bestHelperLineAndDistance[1] < maxDistance) {
                return bestHelperLineAndDistance[0]
            } else {
                return null
            }
        }
    }

    private insertGuidingHelperLinePositions(helperLine: HelperLineItem, curvePositions: Vector2[], curveTValues: number[]) {
        const helperLineInfoPerControlPoint = this._helperLineInfoByControlPointByGuidingHelperLine.get(helperLine)
        if (!helperLineInfoPerControlPoint) {
            throw new Error("Helper line info not found")
        }
        let currentInsertionIndex = 0
        for (let i = 1; i < this._curveControlPoints.length; i++) {
            currentInsertionIndex++
            const controlPoint0 = this._curveControlPoints[i - 1]
            const controlPoint1 = this._curveControlPoints[i]
            const helperLineInfo0 = helperLineInfoPerControlPoint.get(controlPoint0.controlPoint)
            const helperLineInfo1 = helperLineInfoPerControlPoint.get(controlPoint1.controlPoint)
            if (!helperLineInfo0 || !helperLineInfo1) {
                throw new Error("Helper line info not found")
            }
            const controlSegmentDistance0 = Vector2.dot(helperLineInfo0.normal, controlPoint0.controlPoint.sourcePosition.sub(helperLineInfo0.closestPoint))
            const controlSegmentDistance1 = Vector2.dot(helperLineInfo1.normal, controlPoint1.controlPoint.sourcePosition.sub(helperLineInfo1.closestPoint))
            const helperLinePoints = helperLine.getPointsForInterval(helperLineInfo0.offset, helperLineInfo1.offset) // TODO speed this up
            if (helperLinePoints.length < 2) {
                continue
            }
            let helperLineSegmentLength = 0
            for (let j = 1; j < helperLinePoints.length; j++) {
                helperLineSegmentLength += Vector2.distance(helperLinePoints[j - 1], helperLinePoints[j])
            }
            const positionsToInsert: Vector2[] = []
            const tValuesToInsert: number[] = []
            let currentHelperLineLength = 0
            for (let j = 1; j < helperLinePoints.length - 1; j++) {
                currentHelperLineLength += Vector2.distance(helperLinePoints[j - 1], helperLinePoints[j])
                const t = currentHelperLineLength / helperLineSegmentLength
                const helperLinePoint = helperLinePoints[j]
                const helperLineTangent = helperLine
                    .getTangentAtOffset(helperLineInfo0.offset + (helperLineInfo1.offset - helperLineInfo0.offset) * t)
                    .normalized()
                const helperLineNormal = helperLineTangent.perp()
                const distance = controlSegmentDistance0 + (controlSegmentDistance1 - controlSegmentDistance0) * t
                const targetPosition = helperLinePoint.add(helperLineNormal.mul(distance))
                const curveT = controlPoint0.t * (1 - t) + controlPoint1.t * t
                positionsToInsert.push(targetPosition)
                tValuesToInsert.push(curveT)
            }
            curvePositions.splice(currentInsertionIndex, 0, ...positionsToInsert)
            curveTValues.splice(currentInsertionIndex, 0, ...tValuesToInsert)
            currentInsertionIndex += positionsToInsert.length
        }
    }

    private update() {
        if (!this._needsUpdate) {
            return
        }
        this._needsUpdate = false

        const controlPointPositions = this._curveControlPoints.map((curveControlPoint) => curveControlPoint.controlPoint.sourcePosition)
        const controlPointTValues = this._curveControlPoints.map((controlPoint) => controlPoint.t)

        // align segments to guiding helper line
        if (this._guidingHelperLine === undefined) {
            this._guidingHelperLine = this.determineBestGuidingHelperLine()
        }
        if (this._guidingHelperLine) {
            this.insertGuidingHelperLinePositions(this._guidingHelperLine, controlPointPositions, controlPointTValues)
        }

        this._curveInterpolator = new LinearInterpolator(controlPointPositions, controlPointTValues)
    }

    private removeHelperLineInfo(item: BoundaryControlPoint | HelperLineItem) {
        if (item instanceof BoundaryControlPoint) {
            this._helperLineInfoByControlPointByGuidingHelperLine.forEach((helperLineInfoByControlPoint) => helperLineInfoByControlPoint.delete(item))
        } else {
            this._helperLineInfoByControlPointByGuidingHelperLine.delete(item)
        }
    }

    private updateHelperLineInfo(item: BoundaryControlPoint | HelperLineItem) {
        const computeInfo = (helperLine: HelperLineItem, controlPoint: BoundaryControlPoint): HelperLineInfo => {
            const closestPointAndOffset = helperLine.getClosestPointAndOffsetToLocation(controlPoint.sourcePosition)
            const distance = Vector2.distance(closestPointAndOffset.closestPoint, controlPoint.sourcePosition)
            const tangent = helperLine.getTangentAtOffset(closestPointAndOffset.offset).normalized()
            const normal = tangent.perp()
            return {...closestPointAndOffset, distance, tangent, normal}
        }
        if (item instanceof BoundaryControlPoint) {
            this._helperLineInfoByControlPointByGuidingHelperLine.forEach((helperLineInfoByControlPoint, helperLine) =>
                helperLineInfoByControlPoint.set(item, computeInfo(helperLine, item)),
            )
        } else {
            this._helperLineInfoByControlPointByGuidingHelperLine.set(
                item,
                new Map(
                    this._curveControlPoints.map((curveControlPoint) => [curveControlPoint.controlPoint, computeInfo(item, curveControlPoint.controlPoint)]),
                ),
            )
        }
    }

    private _needsUpdate = true
    private _curveControlPoints = new Array<BoundaryCurveControlPoint>()
    private _curveVisItem: CurveVizItem
    private _curveInterpolator?: LinearInterpolator
    private _synchronizeDisplay$ = new Subject<void>()
    private _guidingHelperLine?: HelperLineItem | null
    private _helperLineInfoByControlPointByGuidingHelperLine = new Map<HelperLineItem, Map<BoundaryControlPoint, HelperLineInfo>>()
}

type HelperLineInfo = {
    closestPoint: Vector2
    offset: number
    distance: number
    tangent: Vector2
    normal: Vector2
}
