import {Component, DestroyRef, ElementRef, inject, Input, input, OnInit, viewChild} from "@angular/core"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatMenuModule} from "@angular/material/menu"
import {CardComponent} from "@common/components/cards"
import {RenameDialogComponent} from "@common/components/dialogs/rename-dialog/rename-dialog.component"
import {AddFileCardComponent} from "@common/components/files/add-file-card/add-file-card.component"
import {DropFilesComponent} from "@common/components/files/drop-files/drop-files.component"
import {FullPageFeedbackComponent} from "@common/components/full-page-feedback/full-page-feedback.component"
import {GridListComponent} from "@common/components/lists"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {ImageViewerComponent} from "@common/components/viewers"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateIgnoringErrors} from "@common/helpers/api/mutate"
import {ColormassAbilityTypename, ColormassEntity} from "@common/models/abilities"
import {GridSize} from "@legacy/helpers/utils"
import {Settings} from "@common/models/settings/settings"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {Enums} from "@enums"
import {BehaviorSubject, distinctUntilChanged, from, map, tap, switchMap, filter} from "rxjs"
import {IsDefined} from "@cm/utils"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {forceEnum} from "@common/helpers/utils/enum"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {TabStateService} from "@common/services/tab-state/tab-state.service"
import {DataObjectAssignmentType, ContentTypeModel} from "@generated"
import {
    UpdateAttachmentGQL,
    CreateAttachmentGQL,
    DeleteAttachedFileGQL,
    AttachmentRenameDataObjectGQL,
    AttachmentFragment,
    AttachedDataObjectFragment,
    AttachmentsGQL,
} from "@app/common/components/files/attachments/attachments.generated"

export type AttachmentsItemType = {
    __typename: ColormassAbilityTypename
    id: string
    organization?: {
        id: string
    } | null
} & ColormassEntity

@Component({
    selector: "cm-attachments",
    templateUrl: "attachments.component.html",
    styleUrls: ["attachments.component.scss"],
    imports: [
        DropFilesComponent,
        GridListComponent,
        CardComponent,
        MatMenuModule,
        AddFileCardComponent,
        FullPageFeedbackComponent,
        ImageViewerComponent,
        PlaceholderComponent,
        MatTooltipModule,
        DataObjectThumbnailComponent,
    ],
})
export class AttachmentsComponent implements OnInit {
    item$ = new BehaviorSubject<AttachmentsItemType | null | undefined>(undefined)

    @Input({required: true}) set item(item: AttachmentsItemType | null | undefined) {
        this.item$.next(item)
    }
    // optional organizationId input, will default to item.organization.id if not provided
    readonly $organizationId = input<string | null>(null, {alias: "organizationId"})

    readonly $showSetGalleryImageOption = input(true, {alias: "showSetGalleryImageOption"})
    readonly $showSetCustomerLogoOption = input(false, {alias: "showSetCustomerLogoOption"})
    readonly $assignmentTypes = input<DataObjectAssignmentType[]>([DataObjectAssignmentType.Attachment, DataObjectAssignmentType.GalleryImage], {
        alias: "assignmentTypes",
    })

    attachments: AttachmentFragment[] | null = null

    readonly $gridList = viewChild.required<ElementRef>("gridList")
    readonly $imageViewer = viewChild.required<ImageViewerComponent>("imageViewer")

    dropZoneActive = {value: false}
    gridSizes = GridSize

    canAddFiles = false
    canChangeFiles = false

    readonly permission = inject(PermissionsService)
    readonly destroyRef = inject(DestroyRef)
    readonly notifications = inject(NotificationsService)
    readonly refresh = inject(RefreshService)
    readonly attachmentsGql = inject(AttachmentsGQL)
    readonly tabState = inject(TabStateService)
    $can = this.permission.$to

    private readonly updateAttachment = inject(UpdateAttachmentGQL)
    private readonly createAttachment = inject(CreateAttachmentGQL)
    private readonly deleteAttachedFile = inject(DeleteAttachedFileGQL)
    private readonly attachmentRenameDataObject = inject(AttachmentRenameDataObjectGQL)

    constructor(
        private uploadGqlService: UploadGqlService,
        public dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.item$
            .pipe(
                filter(IsDefined),
                tap((item) => {
                    const organizationId = item?.organization?.id
                    this.canAddFiles =
                        this.$can().create.dataObject(organizationId ? {organization: {id: organizationId}} : null) && item && this.$can().update.item(item)
                    this.canChangeFiles = !!organizationId && this.$can().update.dataObject({organization: {id: organizationId}})
                }),
                map((item) => item.id),
                distinctUntilChanged(),
                this.refresh.reEmitWhenItemIsRefreshed(),
                this.tabState.reEmitOnActivation(),
                switchMap((id) =>
                    from(
                        fetchThrowingErrors(this.attachmentsGql)({
                            filter: {
                                contentTypeModel: forceEnum(this.item$.value!.__typename, ContentTypeModel),
                                objectId: id,
                                assignmentType: this.$assignmentTypes(),
                            },
                        }).then(({dataObjectAssignments}) => dataObjectAssignments),
                    ),
                ),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe((dataObjectAssignments) => {
                this.attachments = dataObjectAssignments?.filter(IsDefined) ?? []
            })
    }

    async uploadFiles(files: File[]) {
        const organizationId = this.$organizationId() ?? this.item$.value?.organization?.id
        if (organizationId) {
            for (const file of files) {
                const dataObject = await this.uploadGqlService.createAndUploadDataObject(file, {organizationId}, {showUploadToolbar: true, processUpload: true})
                await this.assignDataObject(dataObject.legacyId, DataObjectAssignmentType.Attachment)
                this.refresh.item(this.item$.value)
            }
        }
    }

    setPdfTemplate = async (attachment: AttachmentFragment) => {
        await mutateIgnoringErrors(this.updateAttachment)({
            input: {
                id: attachment.id,
                type: DataObjectAssignmentType.PdfTemplate,
            },
        })
        this.refresh.item(this.item$.value)
    }

    setCorporateFont = (attachment: AttachmentFragment, bold: boolean) =>
        mutateIgnoringErrors(this.updateAttachment)({
            input: {
                id: attachment.id,
                type: bold ? DataObjectAssignmentType.FontBold : DataObjectAssignmentType.Font,
            },
        })

    setGalleryImage = async (attachment: AttachmentFragment) => {
        await mutateIgnoringErrors(this.updateAttachment)({
            input: {
                id: attachment.id,
                type: DataObjectAssignmentType.GalleryImage,
            },
        })
        this.refresh.item(this.item$.value)
    }

    setCustomerLogo = async (attachment: AttachmentFragment) => {
        await mutateIgnoringErrors(this.updateAttachment)({
            input: {
                id: attachment.id,
                type: DataObjectAssignmentType.CustomerLogo,
            },
        })
        this.refresh.item(this.item$.value)
    }

    async assignDataObject(dataObjectLegacyId: number, assignmentType: DataObjectAssignmentType) {
        const itemId = this.item$.value?.id
        if (itemId) {
            await this.notifications.withUserFeedback(
                async () => {
                    const result = await mutateIgnoringErrors(this.createAttachment)({
                        input: {
                            dataObjectLegacyId,
                            objectId: itemId,
                            contentTypeModel: forceEnum(this.item$.value!.__typename, ContentTypeModel),
                            type: assignmentType,
                        },
                    })
                    this.refresh.item(this.item$.value)
                    return result?.createDataObjectAssignment
                },
                {
                    // don't show a success message in the snackbar, we already have the background items list
                    error: "Cannot upload file.",
                },
            )
        }
    }

    deleteDataObject(attachment: AttachmentFragment): void {
        const previousAttachments = this.attachments
        this.attachments = this.attachments?.filter((_assignment) => _assignment.id !== attachment.id) ?? null

        void this.notifications.withUndo(
            "File deleted.",
            async () => {
                await this.notifications.mutateWithUserFeedback(
                    this.deleteAttachedFile,
                    {id: attachment.dataObject.id},
                    {
                        error: "Cannot delete file.",
                    },
                )
                this.refresh.item(this.item$.value)
            },
            () => {
                this.attachments = previousAttachments
            },
        )
    }

    isImage(dataObject: AttachedDataObjectFragment): boolean {
        return dataObject.mediaType?.startsWith("image/") ?? false
    }

    isPdf(dataObject: AttachedDataObjectFragment): boolean {
        return dataObject.mediaType === "application/pdf"
    }

    isFont(dataObject: AttachedDataObjectFragment): boolean {
        return dataObject.mediaType === "font/ttf"
    }

    openImageViewer(dataObject: AttachedDataObjectFragment): void {
        if (this.attachments?.length) {
            void this.$imageViewer().openViewer(
                dataObject.id,
                this.attachments.map(({dataObject}) => dataObject.id),
            )
        } else {
            this.notifications.showInfo("No images to display")
        }
    }

    openRenameDialog(dataObject: AttachedDataObjectFragment): void {
        const dialogRef: MatDialogRef<RenameDialogComponent> = this.dialog.open(RenameDialogComponent, {
            width: "400px",
            data: {
                currentName: dataObject.originalFileName,
            },
        })
        dialogRef.afterClosed().subscribe((newName) => {
            if (newName) {
                void this.renameDataObject(dataObject, newName)
            }
        })
    }

    async renameDataObject(dataObject: AttachedDataObjectFragment, newName: string) {
        await this.notifications.mutateWithUserFeedback(
            this.attachmentRenameDataObject,
            {
                input: {
                    id: dataObject.id,
                    originalFileName: newName,
                },
            },
            {
                success: "File renamed",
                error: "Cannot rename file",
            },
        )
        this.refresh.item(this.item$.value)
    }

    protected readonly Settings = Settings
    protected readonly Enums = Enums
}
