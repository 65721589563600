import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "#template-nodes/runtime-graph/slots"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"
import {ISceneManager} from "#template-nodes/interfaces/scene-manager"
import {IMaterialGraph} from "@cm/material-nodes/interfaces/material-data"
import {Subscription, defer, map} from "rxjs"

const TD = TypeDescriptors

const findMaterialDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManager>()),
    customerId: TD.inlet(TD.Nullable(TD.Number)),
    articleId: TD.inlet(TD.Nullable(TD.String)),
    materialGraph: TD.outlet(TD.Nullable(TD.MaterialGraph)),
}

export class FindMaterial implements NodeClassImpl<typeof findMaterialDescriptor, typeof FindMaterial> {
    static descriptor = findMaterialDescriptor
    static uniqueName = "FindMaterial"
    sceneManager!: Inlet<ISceneManager>
    customerId!: Inlet<number | null>
    articleId!: Inlet<string | null>
    materialGraph!: Outlet<IMaterialGraph | null>

    private pending: Subscription | null = null

    run() {
        this.materialGraph.emitIfChanged(NotReady)
        this.pending?.unsubscribe()

        if (this.sceneManager === NotReady || this.articleId === NotReady || this.customerId === NotReady) return

        if (this.articleId === null) {
            this.materialGraph.emitIfChanged(null)
            return
        }

        const {sceneManager, articleId, customerId} = this

        this.pending = this.sceneManager.addTask({
            description: `findMaterial(${this.articleId}, ${this.customerId})`,
            task: defer(() => sceneManager.findMaterial(articleId, customerId)).pipe(
                map((materialGraph) => {
                    this.pending = null
                    this.materialGraph.emitIfChanged(materialGraph)
                }),
            ),
            critical: true,
        })
    }

    cleanup() {
        this.pending?.unsubscribe()
    }
}
