import {Context, context, UnmodedContext, VisitMode, visitMode} from "#template-nodes/types"
import {
    DeclareModedVisitorNodeTS,
    DeclareVisiteableListNodeTS,
    ModedNodeContext,
    ModedVisitorNodeImplementation,
    ModedVisitorNodeTSImplementation,
    NodeGraph,
    NodeGraphClass,
    NodeParameters,
    VisiteableListNode,
    visitNone,
    VisitorNodeContext,
    VisitorNodeMeta,
    VisitorNodeResult,
} from "@cm/graph"
import {z} from "zod"

export type TemplateNodeImplementation<ParamTypes extends NodeParameters> = ModedVisitorNodeImplementation<VisitMode, UnmodedContext, ParamTypes>

export type TemplateNodeTSImplementation<ParamTypes extends NodeParameters> = Omit<
    ModedVisitorNodeTSImplementation<VisitMode, UnmodedContext, ParamTypes>,
    "validation"
> & {validation?: {paramsSchema?: z.ZodType<NodeParameters>}}

export type TemplateNodeMeta<ParamTypes extends NodeParameters> = VisitorNodeMeta<ParamTypes> & {nodeClass: string}

export function DeclareTemplateNode<ZodParamTypes extends z.ZodType<NodeParameters>>(
    definition: {
        parameters: ZodParamTypes
    },
    implementation: TemplateNodeImplementation<z.infer<typeof definition.parameters>>,
    meta: TemplateNodeMeta<z.infer<typeof definition.parameters>>,
) {
    const {parameters: paramsSchema} = definition
    type ParamTypes = z.infer<typeof paramsSchema>

    return DeclareTemplateNodeTS<ParamTypes>({...implementation, validation: {paramsSchema}}, meta)
}

export type TemplateNodeClass<ParamTypes extends NodeParameters> = NodeGraphClass<
    NodeGraph<VisitorNodeResult<ParamTypes>, VisitorNodeContext<ModedNodeContext<VisitMode, UnmodedContext>>, ParamTypes>
>

export function DeclareTemplateNodeTS<ParamTypes extends NodeParameters>(
    implementation: TemplateNodeTSImplementation<ParamTypes>,
    meta: TemplateNodeMeta<ParamTypes>,
): TemplateNodeClass<ParamTypes> {
    const {onVisited, ...rest} = implementation
    return DeclareModedVisitorNodeTS<VisitMode, UnmodedContext, ParamTypes>(
        {
            ...rest,
            onVisited: {
                onCompile: ({parameters}) => visitNone(parameters),
                onFilterActive: ({parameters}) => visitNone(parameters),
                ...onVisited,
            },
            validation: {paramsSchema: implementation.validation?.paramsSchema, contextSchema: context, visitModeSchema: visitMode},
        },
        meta,
    )
}

export function DeclareTemplateListNode<ZodItemType extends z.ZodType>(
    definition: {
        item: ZodItemType
    },
    meta: TemplateNodeMeta<{list: z.infer<typeof definition.item>[]}>,
) {
    const {item: itemSchema} = definition
    type ItemType = z.infer<typeof itemSchema>

    return DeclareTemplateListNodeTS<ItemType>({itemSchema}, meta)
}

export function DeclareTemplateListNodeTS<ItemType>(
    validation: {
        itemSchema?: z.ZodType
    },
    meta: TemplateNodeMeta<{list: ItemType[]}>,
) {
    return DeclareVisiteableListNodeTS<Context, ItemType>({...validation, contextSchema: context}, meta)
}

export type TemplateListNode<ItemType> = VisiteableListNode<Context, ItemType>
