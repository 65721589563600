<cm-routed-dialog [dialogSize]="dialogSizes.Large" [dialogScroll]="false" (close)="overlayClosed()">
    <ng-container cm-overlay-content>
        <div class="cm-container">
            @if (loading) {
                <cm-loading-spinner>
                    <ng-container cm-text-bottom>
                        <span class="cm-texture-loading-text"> Creating texture... </span>
                    </ng-container>
                </cm-loading-spinner>
            }
            @if (showSizeOptions) {
                <div class="cm-dimensions">
                    Size of the texture
                    <div class="cm-property-container">
                        <mat-form-field class="cm-input-medium">
                            <mat-label>Width (in)</mat-label>
                            <input type="number" matInput min="1" max="200" step="0.1" #widthInput [(ngModel)]="parameterWidth" />
                        </mat-form-field>
                        <i class="far fa-times"></i>
                        <mat-form-field class="cm-input-medium">
                            <mat-label>Height (in)</mat-label>
                            <input type="number" matInput min="1" max="200" step="0.1" #heightInput [(ngModel)]="parameterHeight" />
                        </mat-form-field>
                    </div>
                    <mat-form-field class="cm-input-medium">
                        <mat-label>Rot. (deg)</mat-label>
                        <input type="number" matInput min="0" max="360" step="1" #rotationInput [(ngModel)]="parameterRotation" />
                    </mat-form-field>
                    <div class="cm-button" (click)="updateSize()">Update size</div>
                </div>
            }
            @if (materialExplorerItem) {
                <cm-configurator [templateUuid]="organizationTemplateId" [showUi]="true" #configurator
                    ><ng-container cm-action-menu-additional>
                        <a class="cm-icon-container">
                            <i class="far fa-folder-open"></i>
                            <input class="cm-select-file" (change)="filesSelectedForUpload($event)" type="file" accept="image/*" />
                        </a> </ng-container
                ></cm-configurator>
            }
        </div>
    </ng-container>
</cm-routed-dialog>
