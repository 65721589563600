import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PdfDataForPdfServiceFragment = { __typename: 'Organization', id: string, pdfTemplate?: { __typename: 'DataObject', downloadUrl: string } | null, font?: { __typename: 'DataObject', downloadUrl: string } | null, fontBold?: { __typename: 'DataObject', downloadUrl: string } | null };

export type GetPdfDataForPdfServiceOrgaIdQueryVariables = Types.Exact<{
  organizationLegacyId: Types.Scalars['Int']['input'];
}>;


export type GetPdfDataForPdfServiceOrgaIdQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, pdfTemplate?: { __typename: 'DataObject', downloadUrl: string } | null, font?: { __typename: 'DataObject', downloadUrl: string } | null, fontBold?: { __typename: 'DataObject', downloadUrl: string } | null } };

export type GetPdfDataForPdfServiceTemplateRevisionIdQueryVariables = Types.Exact<{
  templateRevisionId: Types.Scalars['ID']['input'];
}>;


export type GetPdfDataForPdfServiceTemplateRevisionIdQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', id: string, template: { __typename: 'Template', organization: { __typename: 'Organization', id: string, pdfTemplate?: { __typename: 'DataObject', downloadUrl: string } | null, font?: { __typename: 'DataObject', downloadUrl: string } | null, fontBold?: { __typename: 'DataObject', downloadUrl: string } | null } } } };

export const PdfDataForPdfServiceFragmentDoc = gql`
    fragment PdfDataForPdfService on Organization {
  id
  pdfTemplate {
    downloadUrl
  }
  font {
    downloadUrl
  }
  fontBold {
    downloadUrl
  }
}
    `;
export const GetPdfDataForPdfServiceOrgaIdDocument = gql`
    query getPdfDataForPdfServiceOrgaId($organizationLegacyId: Int!) {
  organization(legacyId: $organizationLegacyId) {
    ...PdfDataForPdfService
  }
}
    ${PdfDataForPdfServiceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPdfDataForPdfServiceOrgaIdGQL extends Apollo.Query<GetPdfDataForPdfServiceOrgaIdQuery, GetPdfDataForPdfServiceOrgaIdQueryVariables> {
    override document = GetPdfDataForPdfServiceOrgaIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPdfDataForPdfServiceTemplateRevisionIdDocument = gql`
    query getPdfDataForPdfServiceTemplateRevisionId($templateRevisionId: ID!) {
  templateRevision(id: $templateRevisionId) {
    id
    template {
      organization {
        ...PdfDataForPdfService
      }
    }
  }
}
    ${PdfDataForPdfServiceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPdfDataForPdfServiceTemplateRevisionIdGQL extends Apollo.Query<GetPdfDataForPdfServiceTemplateRevisionIdQuery, GetPdfDataForPdfServiceTemplateRevisionIdQueryVariables> {
    override document = GetPdfDataForPdfServiceTemplateRevisionIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }