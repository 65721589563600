import {DataObjectAssignmentType, MaterialOutputType} from "@generated"
import {StateLabel} from "@platform/models/state-labels/state-label"

export type FlatOption = {
    size: number
    resolution: number
    dataObjectAssignmentType:
        | DataObjectAssignmentType.MaterialThumbnail_10x10
        | DataObjectAssignmentType.MaterialThumbnail_20x20
        | DataObjectAssignmentType.MaterialThumbnail_30x30
        | DataObjectAssignmentType.MaterialThumbnail_7x7Inches
        | DataObjectAssignmentType.MaterialThumbnail_9x9Inches
        | DataObjectAssignmentType.MaterialThumbnail_16x16Inches
    materialOutputType:
        | MaterialOutputType.Thumbnail10x10cm
        | MaterialOutputType.Thumbnail20x20cm
        | MaterialOutputType.Thumbnail30x30cm
        | MaterialOutputType.Thumbnail7x7inches
        | MaterialOutputType.Thumbnail9x9inches
        | MaterialOutputType.Thumbnail16x16inches
}

export const FlatThumbnailOptionLabels = new Map<MaterialOutputType, StateLabel<FlatOption>>([
    [
        MaterialOutputType.Thumbnail10x10cm,
        {
            state: {
                size: 10,
                resolution: 3000,
                dataObjectAssignmentType: DataObjectAssignmentType.MaterialThumbnail_10x10,
                materialOutputType: MaterialOutputType.Thumbnail10x10cm,
            },
            label: "10cm x 10cm",
        },
    ],
    [
        MaterialOutputType.Thumbnail20x20cm,
        {
            state: {
                size: 20,
                resolution: 3000,
                dataObjectAssignmentType: DataObjectAssignmentType.MaterialThumbnail_20x20,
                materialOutputType: MaterialOutputType.Thumbnail20x20cm,
            },
            label: "20cm x 20cm",
        },
    ],
    [
        MaterialOutputType.Thumbnail30x30cm,
        {
            state: {
                size: 30,
                resolution: 3000,
                dataObjectAssignmentType: DataObjectAssignmentType.MaterialThumbnail_30x30,
                materialOutputType: MaterialOutputType.Thumbnail30x30cm,
            },
            label: "30cm x 30cm",
        },
    ],
    [
        MaterialOutputType.Thumbnail7x7inches,
        {
            state: {
                size: 17.78,
                resolution: 2100,
                dataObjectAssignmentType: DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
                materialOutputType: MaterialOutputType.Thumbnail7x7inches,
            },
            label: "7in x 7in",
        },
    ],
    [
        MaterialOutputType.Thumbnail9x9inches,
        {
            state: {
                size: 22.86,
                resolution: 3000,
                dataObjectAssignmentType: DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
                materialOutputType: MaterialOutputType.Thumbnail9x9inches,
            },
            label: "9in x 9in",
        },
    ],
    [
        MaterialOutputType.Thumbnail16x16inches,
        {
            state: {
                size: 40.64,
                resolution: 4800,
                dataObjectAssignmentType: DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
                materialOutputType: MaterialOutputType.Thumbnail16x16inches,
            },
            label: "16in x 16in",
        },
    ],
])
