<cm-inspector-section>
    <ng-container cm-title> Visibility</ng-container>
    <ng-container cm-content>
        <cm-toggle class="cm-span2" [(toggle)]="showMask" [disabled]="$disabled()">
            <ng-container cm-text><span [class.cm-disabled]="$disabled()">Show mask</span></ng-container>
        </cm-toggle>
        <cm-toggle class="cm-span2" [(toggle)]="showGuides" [disabled]="$disabled()">
            <ng-container cm-text><span [class.cm-disabled]="$disabled()">Show guides</span></ng-container>
        </cm-toggle>
    </ng-container>
</cm-inspector-section>
<cm-inspector-section>
    <ng-container cm-title> Settings</ng-container>
    <ng-container cm-content>
        <div>
            <span class="cm-label">Layer Min Opacity</span>
            <cm-slider [min]="0" [max]="1" [(value)]="layerMinOpacity" [disabled]="$disabled()"></cm-slider>
        </div>
        <div>
            <span class="cm-label">Layer Max Opacity</span>
            <cm-slider [min]="0" [max]="1" [(value)]="layerMaxOpacity" [disabled]="$disabled()"></cm-slider>
        </div>
        <div>
            <span class="cm-label">Mask feathering</span>
            <cm-slider [min]="0" [max]="2000" [exponent]="4" [(value)]="maskFeathering" [disabled]="$disabled()"></cm-slider>
        </div>
        @if ($can().create.experimentalTextureEditorFeatures()) {
            <span class="cm-label">Blending mode</span>
            <div class="cm-mode-selections">
                <cm-button
                    class="cm-mode-selection"
                    [class.cm-active]="blendMode === 'alpha'"
                    matTooltip="Use simple alpha blending"
                    (click)="blendMode = 'alpha'"
                    [disabled]="$disabled()"
                >
                    <ng-container cm-text>Simple</ng-container>
                </cm-button>
                <cm-button
                    class="cm-mode-selection"
                    [class.cm-active]="blendMode === 'laplacian-pyramid'"
                    matTooltip="Use advanced laplacian pyramid blending"
                    (click)="blendMode = 'laplacian-pyramid'"
                    [disabled]="$disabled()"
                >
                    <ng-container cm-text>Advanced</ng-container>
                </cm-button>
            </div>
        }
    </ng-container>
</cm-inspector-section>
<cm-inspector-section>
    <ng-container cm-title> Modes</ng-container>
    <ng-container cm-content>
        <div>
            <div>
                <cm-button
                    class="cm-mode"
                    [class.cm-active]="layerEditMode === layerEditModes.Move"
                    (click)="layerEditMode = layerEditModes.Move"
                    [disabled]="$disabled()"
                >
                    <ng-container cm-left-icon><i class="far fa-up-down-left-right"></i></ng-container>
                    <ng-container cm-text>Move</ng-container>
                </cm-button>
                <cm-button
                    class="cm-mode"
                    [class.cm-active]="layerEditMode === layerEditModes.Draw"
                    (click)="layerEditMode = layerEditModes.Draw"
                    [disabled]="$disabled()"
                >
                    <ng-container cm-left-icon><i class="far fa-paintbrush-pencil"></i></ng-container>
                    <ng-container cm-text>Draw</ng-container>
                </cm-button>
            </div>
            <div class="cm-mode-content">
                @if (layerEditMode === layerEditModes.Move) {
                    <div class="cm-move-modes">
                        <cm-button
                            class="cm-move-mode"
                            [class.cm-active]="layerMoveMode === layerMoveModes.ImageAndMask"
                            (click)="layerMoveMode = layerMoveModes.ImageAndMask"
                            [disabled]="$disabled()"
                        >
                            <ng-container cm-left-icon><i class="far fa-image"></i></ng-container>
                            <ng-container cm-text>Image & Mask</ng-container>
                        </cm-button>
                        <cm-button
                            class="cm-move-mode"
                            [class.cm-active]="layerMoveMode === layerMoveModes.ImageOnly"
                            (click)="layerMoveMode = layerMoveModes.ImageOnly"
                            [disabled]="$disabled()"
                        >
                            <ng-container cm-left-icon><i class="far fa-image"></i></ng-container>
                            <ng-container cm-text>Image</ng-container>
                        </cm-button>
                        <cm-button
                            class="cm-move-mode"
                            [class.cm-active]="layerMoveMode === layerMoveModes.MaskOnly"
                            (click)="layerMoveMode = layerMoveModes.MaskOnly"
                            [disabled]="$disabled()"
                        >
                            <ng-container cm-left-icon><i class="far fa-mask"></i></ng-container>
                            <ng-container cm-text>Mask</ng-container>
                        </cm-button>
                    </div>
                }
                @if (layerEditMode === layerEditModes.Draw) {
                    <cm-brush-settings-panel [brushSettings]="brushSettings"></cm-brush-settings-panel>
                    <cm-button class="cm-move-mode" (click)="invertMask()" [disabled]="$disabled()">
                        <ng-container cm-left-icon><i class="far fa-circle-half-stroke"></i></ng-container>
                        <ng-container cm-text> Invert mask</ng-container>
                    </cm-button>
                }
            </div>
        </div>
    </ng-container>
</cm-inspector-section>
