import {ChangeDetectionStrategy, Component, inject, input, output} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {UploadServiceDataObjectFragment} from "@common/services/upload/upload.generated"
import {CardComponent, CardPlaceholderComponent} from "@common/components/cards"
import {DropFilesComponent} from "@common/components/files"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {Subject} from "rxjs"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {ThumbnailLayout} from "@common/models/enums/thumbnail-layout"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {FourSquareLayoutComponent} from "@common/components/layouts/four-square-layout/four-square-layout.component"

/**
 * Card showing a thumbnail, some text and optional overlaid UI elements
 */
@Component({
    selector: "cm-entity-card",
    templateUrl: "./entity-card.component.html",
    styleUrls: ["./entity-card.component.scss"],
    imports: [DropFilesComponent, CardComponent, CardPlaceholderComponent, MatTooltipModule, DataObjectThumbnailComponent, FourSquareLayoutComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityCardComponent<
    EntityType extends {
        name?: string | null
        legacyId?: number
        organization?: {id?: string; name?: string | null} | null
        galleryImage?: {
            id: string
        } | null
    },
> {
    readonly $entityLink = input<Array<unknown>>(undefined, {alias: "entityLink"})
    readonly $entityQueryParamsHandling = input<"merge" | "preserve" | "" | null>(null, {alias: "entityQueryParamsHandling"})
    readonly $squareFormat = input(false, {alias: "squareFormat"})
    readonly selectEntity = output<EntityType>()
    readonly $enableDropZone = input(false, {alias: "enableDropZone"})
    readonly dropZoneUpload = output<UploadServiceDataObjectFragment>()
    dropZoneActive = {value: false}
    unsubscribe = new Subject<void>()
    // Once support for default content is added: https://github.com/angular/angular/issues/12530 the default inputs should be removed.
    readonly $defaultTitle = input(true, {alias: "defaultTitle"})
    readonly $defaultSubtitle = input(true, {alias: "defaultSubtitle"})
    readonly $defaultThumbnail = input(true, {alias: "defaultThumbnail"})
    readonly $showTooltip = input(true, {alias: "showTooltip"})
    readonly $multiThumbnailIds = input<string[] | null>(undefined, {alias: "multiThumbnailIds"})

    readonly $entity = input<EntityType | undefined>(undefined, {alias: "entity"})

    protected readonly permission = inject(PermissionsService)
    private readonly uploadService = inject(UploadGqlService)
    $can = this.permission.$to

    hasName(entity: EntityType): entity is EntityType & {name: string} {
        return "name" in entity
    }

    hasCustomer(entity: EntityType): entity is EntityType & {customer: number} {
        return "customer" in entity
    }

    hasArticleId(entity: EntityType): entity is EntityType & {articleId: number} {
        return "articleId" in entity
    }

    async handleDroppedFiles(files: File[]) {
        if (files.length > 1) throw new Error("Only one file can be uploaded at a time")
        const organizationId = this.$entity()?.organization?.id
        if (organizationId) {
            const dataObject = await this.uploadService.createAndUploadDataObject(files[0], {organizationId}, {showUploadToolbar: true, processUpload: true})
            this.dropZoneUpload.emit(dataObject)
        } else {
            console.warn("Cannot handle dropped files because of missing customer ID")
            return
        }
    }

    protected readonly ThumbnailLayout = ThumbnailLayout
}
