import {Observable, of as observableOf, Subject, take} from "rxjs"
import {INodeInstance} from "#template-nodes/runtime-graph/types"
import {DeferredTaskImplementation} from "@cm/utils"

export class GraphScheduler {
    private scheduledGroups = new Set<INodeInstance[]>()
    private pendingUpdate: number | null = null
    private idle$ = new Subject<void>()
    private root: GraphScheduler
    private pendingUpdateForAllCount = 0
    errorFlag = false

    constructor(
        root: GraphScheduler | undefined,
        private deferredTask: DeferredTaskImplementation,
    ) {
        if (root) {
            this.root = root
        } else {
            this.root = this
        }
    }

    newScope(): GraphScheduler {
        return new GraphScheduler(this.root, this.deferredTask)
    }

    private clearPendingUpdate() {
        if (this.pendingUpdate) {
            this.pendingUpdate = null
            this.root.pendingUpdateForAllCount -= 1
            if (this.root.pendingUpdateForAllCount === 0) {
                this.deferredTask.queueDeferredTask(() => {
                    if (this.root.pendingUpdateForAllCount === 0) {
                        this.root.idle$.next()
                    }
                })
            }
        }
    }

    scheduleToRun(node: INodeInstance) {
        if (node.$scheduled) return
        node.$scheduled = true
        this.scheduledGroups.add(node.$scheduleGroup!)
        if (this.pendingUpdate !== null) return
        this.pendingUpdate = this.deferredTask.queueDeferredTask(this.runScheduledNodes.bind(this))
        this.root.pendingUpdateForAllCount += 1
    }

    private runScheduledNodes() {
        let totalCount = 0
        let group: INodeInstance[]
        while (true) {
            ;[group] = this.scheduledGroups
            if (!group) break
            while (true) {
                let count = 0
                for (const node of group) {
                    if (node.$scheduled) {
                        node.$scheduled = false
                        //console.log(`Running node ${nodeInst.$id}`);
                        try {
                            node.run()
                        } catch (e) {
                            console.error(`Error running node`, node, e)
                            this.errorFlag = true
                        }
                        ++count
                    }
                }
                if (count === 0) break
                totalCount += count
            }
            this.scheduledGroups.delete(group)
        }
        // console.log(`Ran ${totalCount} nodes`);
        this.clearPendingUpdate()
    }

    get hasPendingUpdates() {
        return this.pendingUpdateForAllCount > 0
    }

    sync(): Observable<void> {
        if (this.pendingUpdateForAllCount > 0) {
            return this.idle$.pipe(take(1))
        } else {
            return observableOf(undefined)
        }
    }

    destroy() {
        if (this.pendingUpdate !== null) {
            this.deferredTask.cancelDeferredTask(this.pendingUpdate)
            this.clearPendingUpdate()
        }
        this.idle$.complete()
    }
}
