import {DecimalPipe} from "@angular/common"
import {Component, computed, ElementRef, input, output, signal, viewChild} from "@angular/core"
import {clamp} from "@app/legacy/helpers/utils"
import {computeRgbCurveSampledFunction} from "@cm/material-nodes/utils"
import {Vector2Like} from "@cm/math"

@Component({
    selector: "cm-spline-curve",
    imports: [DecimalPipe],
    templateUrl: "./spline-curve.component.html",
    styleUrl: "./spline-curve.component.scss",
})
export class SplineCurveComponent {
    readonly $curveContainer = viewChild.required<ElementRef<HTMLElement>>("curveContainer")
    readonly $controlPoints = input.required<Vector2Like[]>({alias: "controlPoints"})
    readonly $selectedPoint = signal<number | undefined>(undefined)
    readonly $width = input(100, {alias: "width"})
    readonly $height = input(100, {alias: "height"})
    readonly $path = computed(() => {
        const controlPoints = [...this.$controlPoints()]
        controlPoints.sort((a, b) => a.x - b.x)
        const sampledFunction = computeRgbCurveSampledFunction(controlPoints)

        const width = this.$width()
        const height = this.$height()

        let path = ""

        for (let x = 0; x < 1.0 + 0.01; x += 0.01) {
            const y = sampledFunction.evaluate(x)
            if (y === undefined) continue

            const screenX = x * width
            const screenY = (1 - y) * height

            if (path === "") path = `M ${screenX} ${screenY}`
            else path += ` L ${screenX} ${screenY}`
        }

        return path
    })
    readonly $isDown = signal(false)

    readonly onControlPointAdded = output<Vector2Like>()
    readonly onControlPointRemoved = output<number>()
    readonly onBeginControlPointDrag = output<void>()
    readonly onControlPointChanged = output<{pointId: number; point: Vector2Like}>()
    readonly onEndControlPointDrag = output<void>()

    addControlPoint(event: MouseEvent) {
        const x = event.offsetX / this.$width()
        const y = 1 - event.offsetY / this.$height()
        this.onControlPointAdded.emit({x, y})
    }

    onMouseDown(event: MouseEvent, pointId: number): void {
        this.onBeginControlPointDrag.emit()
        this.$isDown.set(true)
        this.$selectedPoint.set(pointId)
    }

    onMouseUp(event: MouseEvent) {
        this.$isDown.set(false)
        this.onEndControlPointDrag.emit()
    }

    onMouseMove(event: MouseEvent) {
        const selectedPoint = this.$selectedPoint()
        if (!this.$isDown() || selectedPoint === undefined) return

        event.preventDefault()
        event.stopPropagation()

        const squareElement = this.$curveContainer().nativeElement
        const squareRect = squareElement.getBoundingClientRect()

        const x = event.clientX - squareRect.left
        const y = event.clientY - squareRect.top

        const newX = clamp(x / this.$width(), 0, 1)
        const newY = clamp(1 - y / this.$height(), 0, 1)

        this.onControlPointChanged.emit({pointId: selectedPoint, point: {x: newX, y: newY}})
    }
}
