import {Component, OnInit} from "@angular/core"
import {ItemListComponent} from "@common/components/item/item-list/item-list.component"

/**
 * Generic list of items that can be resorted via drag 'n' drop. Implement a subclass for each item type.
 */
@Component({
    template: "",
})
export abstract class DraggableItemListComponent<
        EntityType extends {id: string; legacyId: number; priorityOrder: number},
        UpdateType extends {id?: string | null; priorityOrder?: number | null},
        CreateType,
    >
    extends ItemListComponent<EntityType, UpdateType, CreateType>
    implements OnInit
{
    dragSource: EntityType | undefined = undefined
    dragTarget: EntityType | undefined = undefined

    isSorting = false

    dragStart(event: DragEvent, item: EntityType) {
        //Firefox drag fix: https://stackoverflow.com/questions/3977596/how-to-make-divs-in-html5-draggable-for-firefox
        event.dataTransfer?.setData("text", "")
        this.isSorting = true
        this.dragSource = item
    }

    dragEnter(event: DragEvent, item: EntityType) {
        if (event.dataTransfer?.types?.indexOf("Files") !== -1) {
            return
        }
        this.dragTarget = item
        event.preventDefault()
    }

    async drop(event: DragEvent, item: EntityType) {
        if (event.dataTransfer?.types?.indexOf("Files") !== -1) {
            return
        }

        this.dragTarget = item
        const dragSource = this.dragSource
        if (dragSource == undefined) {
            this.resetDragState()
            return
        } else {
            const indexSource = this.$items().findIndex((item) => item.data?.id === this.dragSource?.id)
            const indexTarget = this.$items().findIndex((item) => item.data?.id === this.dragTarget?.id)
            // Items can not be found
            if (indexSource === -1 || indexTarget === -1) {
                this.resetDragState()
                return
            }
            // No new ordering
            else if (indexTarget == indexSource || indexTarget - 1 === indexSource) {
                this.resetDragState()
                return
            }
            // Old item is before the new item
            else if (indexSource > indexTarget) {
                const {trackId} = this.$items()[indexSource]
                this.$items.update((items) => {
                    const newItems = [...items]
                    newItems.splice(indexSource, 1)
                    newItems.splice(indexTarget, 0, {trackId, data: dragSource, placeholder: false, error: null})
                    return newItems
                })
            }
            // New item is before the old item
            else if (indexTarget > indexSource) {
                this.$items.update((items) => {
                    const {trackId} = items[indexSource]
                    const newItems = [...items]
                    newItems.splice(indexTarget, 0, {trackId, data: dragSource, placeholder: false, error: null})
                    newItems.splice(indexSource, 1)
                    return newItems
                })
            }
            await this.notifications.withUserFeedback(
                async () => {
                    if (this.dragSource === undefined || this.dragTarget === undefined) throw new Error("Source or target is undefined")

                    if (!this.updateItem) throw new Error("updateItem is undefined")

                    await this.updateItem({
                        id: this.dragSource.id,
                        priorityOrder: this.dragTarget.priorityOrder,
                    } as UpdateType)
                },
                {
                    success: "Changes saved.",
                    error: "Cannot save changes.",
                },
            )
            this.resetDragState()
        }
    }

    dragEnd() {
        this.isSorting = false
    }

    resetDragState() {
        this.dragTarget = undefined
        this.dragSource = undefined
    }
}
