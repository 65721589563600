<!-- ugly duplication for now as matMenuTriggerFor does not allow for null/undefined and adding the property conditional didn't work -->
@if ($menuTriggerFor()) {
    <div style="display: inline-block" [matTooltip]="$tooltip() ?? ''" matTooltipPosition="above">
        <a
            [ngClass]="['cm-action-icon-container', active ? 'cm-action-icon-container-active' : '']"
            [class.cm-disabled]="$disabled()"
            [matMenuTriggerFor]="$menuTriggerFor()"
        >
            <ng-container *ngTemplateOutlet="insides"></ng-container>
        </a>
    </div>
} @else {
    <div style="display: inline-block" [matTooltip]="$tooltip() ?? ''" matTooltipPosition="above">
        <a [ngClass]="['cm-action-icon-container', active ? 'cm-action-icon-container-active' : '']" [class.cm-disabled]="$disabled()">
            <ng-container *ngTemplateOutlet="insides"></ng-container>
        </a>
    </div>
}
<ng-template #insides>
    @if ($cmIconClass()) {
        <i [ngClass]="[$cmIconClass(), 'cm-action-icon']"></i>
    }
    <ng-content></ng-content>
</ng-template>
