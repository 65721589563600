import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TagsGridItemFragment = { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null };

export type GetTagsGridItemsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.TagFilterInput>;
  order?: Types.InputMaybe<Array<Types.TagOrderByInput> | Types.TagOrderByInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetTagsGridItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } | null> };

export const TagsGridItemFragmentDoc = gql`
    fragment TagsGridItem on Tag {
  id
  legacyId
  color
  description
  name
  organization {
    id
    legacyId
    name
  }
}
    `;
export const GetTagsGridItemsDocument = gql`
    query getTagsGridItems($filter: TagFilterInput, $order: [TagOrderByInput!], $take: Int, $skip: Int) {
  items: tags(filter: $filter, orderBy: $order, take: $take, skip: $skip) {
    ...TagsGridItem
  }
  totalCount: tagsCount(filter: $filter)
}
    ${TagsGridItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagsGridItemsGQL extends Apollo.Query<GetTagsGridItemsQuery, GetTagsGridItemsQueryVariables> {
    override document = GetTagsGridItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }