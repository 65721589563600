import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetTagsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.TagFilterInput>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetTagsQuery = { __typename: 'Query', tagsCount: number, tags: Array<{ __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: Types.TagType, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } | null> };

export type GetTagsListQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.TagFilterInput>;
  order?: Types.InputMaybe<Array<Types.TagOrderByInput> | Types.TagOrderByInput>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetTagsListQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: Types.TagType, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } | null> };

export type TagListItemFragment = { __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: Types.TagType, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null };

export type CreateTagMutationVariables = Types.Exact<{
  input: Types.MutationCreateTagInput;
}>;


export type CreateTagMutation = { __typename: 'Mutation', createTag: { __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: Types.TagType, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } };

export type DeleteTagMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteTagMutation = { __typename: 'Mutation', deleteTag?: any | null };

export type ChangeTagMutationVariables = Types.Exact<{
  input: Types.MutationUpdateTagInput;
}>;


export type ChangeTagMutation = { __typename: 'Mutation', updateTag: { __typename: 'Tag', id: string } };

export type GetOrganizationByLegacyIdQueryVariables = Types.Exact<{
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetOrganizationByLegacyIdQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string } };

export const TagListItemFragmentDoc = gql`
    fragment TagListItem on Tag {
  id
  legacyId
  name
  organization {
    id
    legacyId
    name
  }
  description
  color
  type
}
    `;
export const GetTagsDocument = gql`
    query getTags($filter: TagFilterInput, $skip: Int, $take: Int) {
  tags(filter: $filter, skip: $skip, take: $take) {
    ...TagListItem
  }
  tagsCount(filter: $filter)
}
    ${TagListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagsGQL extends Apollo.Query<GetTagsQuery, GetTagsQueryVariables> {
    override document = GetTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagsListDocument = gql`
    query getTagsList($filter: TagFilterInput, $order: [TagOrderByInput!], $skip: Int, $take: Int) {
  items: tags(filter: $filter, orderBy: $order, skip: $skip, take: $take) {
    ...TagListItem
  }
  totalCount: tagsCount(filter: $filter)
}
    ${TagListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagsListGQL extends Apollo.Query<GetTagsListQuery, GetTagsListQueryVariables> {
    override document = GetTagsListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTagDocument = gql`
    mutation createTag($input: MutationCreateTagInput!) {
  createTag(input: $input) {
    ...TagListItem
  }
}
    ${TagListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTagGQL extends Apollo.Mutation<CreateTagMutation, CreateTagMutationVariables> {
    override document = CreateTagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTagDocument = gql`
    mutation deleteTag($id: ID!) {
  deleteTag(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTagGQL extends Apollo.Mutation<DeleteTagMutation, DeleteTagMutationVariables> {
    override document = DeleteTagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangeTagDocument = gql`
    mutation changeTag($input: MutationUpdateTagInput!) {
  updateTag(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangeTagGQL extends Apollo.Mutation<ChangeTagMutation, ChangeTagMutationVariables> {
    override document = ChangeTagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetOrganizationByLegacyIdDocument = gql`
    query getOrganizationByLegacyId($legacyId: Int) {
  organization(legacyId: $legacyId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOrganizationByLegacyIdGQL extends Apollo.Query<GetOrganizationByLegacyIdQuery, GetOrganizationByLegacyIdQueryVariables> {
    override document = GetOrganizationByLegacyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }