import {DeclareTemplateNodeTS, TemplateNodeClass} from "#template-nodes/declare-template-node"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {ObjectData, MeshObjectData} from "#template-nodes/interfaces/object-data"
import {SceneNodes} from "#template-nodes/interfaces/scene-object"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {MeshLike, meshLike, ObjectLike, objectLike} from "#template-nodes/node-types"
import {NamedNodeParameters, namedNodeParameters} from "#template-nodes/nodes/named-node"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {TemplateNode} from "#template-nodes/types"
import {skipped, visitNone, VisitorNodeVersion} from "@cm/graph/declare-visitor-node"
import {CircularRefNode, versionChain} from "@cm/graph/node-graph"
import {registerNode} from "@cm/graph/register-node"
import {ImageGenerator} from "@cm/material-nodes/interfaces/image-generator"
import {wrapNodeOutput} from "@cm/material-nodes/material-node-graph"
import {hashObject} from "@cm/utils"
import {z} from "zod"

const distanceTextureParameters = namedNodeParameters.merge(
    z.object({
        source: meshLike.nullable().default(null),
        uvChannel: z.number().default(0),
        range: z.number(),
        mergeRange: z.number().default(0),
        width: z.number(),
        height: z.number(),
        targets: z.array(objectLike),
        innerValue: z.number().optional(),
        smoothPasses: z.number().default(0),
    }),
)
export type DistanceTextureParameters = NamedNodeParameters & {
    source: MeshLike | null
    uvChannel: number
    range: number
    mergeRange: number
    width: number
    height: number
    targets: ObjectLike[]
    innerValue?: number
    smoothPasses: number
}

type V0 = NamedNodeParameters & {
    range: number
    width: number
    height: number
    target: ObjectLike | null
    innerValue?: number
}
type V1 = Omit<V0, "target"> & {source: MeshLike | null; uvChannel: number; targets: ObjectLike[]; mergeRange: number; smoothPasses: number}
export const v0: VisitorNodeVersion<V0, V1> = {
    toNextVersion: (parameters) => {
        const {target, ...rest} = parameters
        if (target instanceof CircularRefNode) throw new Error("Cannot resolve circular references when going from v0 to v1 in distance texture node")
        return {
            source: null,
            uvChannel: 0,
            mergeRange: 0,
            targets: target ? [target] : [],
            ...parameters,
            smoothPasses: 0,
        }
    },
}

@registerNode
export class DistanceTexture
    extends (DeclareTemplateNodeTS<DistanceTextureParameters>(
        {
            validation: {paramsSchema: distanceTextureParameters},
            onVisited: {
                onFilterActive: ({parameters}) => {
                    const {source, targets} = parameters
                    if (!source || targets.length === 0) return skipped
                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "DistanceTexture", versionChain: versionChain([v0])},
    ) as TemplateNodeClass<DistanceTextureParameters>)
    implements EvaluableTemplateNode<ImageGenerator | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        const {source, targets, ...rest} = this.parameters

        const evaluatedSource = evaluator.evaluateMesh(scope, source)
        const evaluatedTargets = scope.filterInvalid(scope.list(targets.map((target) => evaluator.evaluateObject(scope.scope(target.instanceId), target))))

        return scope.pureLambda<
            [MeshObjectData | null, number, ObjectData[], number, number, number, number, number | undefined, number],
            ImageGenerator | null
        >(
            scope.tuple(
                evaluatedSource,
                rest.uvChannel,
                evaluatedTargets,
                rest.range,
                rest.mergeRange,
                rest.width,
                rest.height,
                rest.innerValue,
                rest.smoothPasses,
            ),
            ([inputSource, uvChannel, inputTarget, range, mergeRange, width, height, innerValue, smoothPasses]) => {
                if (!inputSource) return null

                const targets = [...new Set(inputTarget.map((target) => [...target.preDisplayList, ...target.displayList]).flat())].filter(
                    (target) => SceneNodes.Mesh.is(target) || SceneNodes.Seam.is(target),
                )
                targets.sort((a, b) => a.id.localeCompare(b.id))
                targets.forEach((target) => {
                    if (SceneNodes.Seam.is(target)) target.item.sort((a, b) => a.id.localeCompare(b.id))
                })

                const getTargetHashableObject = (target: SceneNodes.Mesh | SceneNodes.Seam, inputSource: MeshObjectData) => {
                    const relativeTransform = target.transform.multiply(inputSource.matrix.inverse())
                    if (SceneNodes.Mesh.is(target)) return {id: target.completeMeshData.abstract.contentHash, transform: relativeTransform}
                    else
                        return {
                            ids: target.item.map((x) => x.completeMeshData.abstract.contentHash),
                            curvePoints: target.curvePoints?.segments,
                            transform: relativeTransform,
                        }
                }

                if (targets.length === 0) return null

                return {
                    imageNode: {
                        generator: ({uv, extension, interpolation, projection, forceOriginalResolution}) => {
                            return {
                                color: wrapNodeOutput(
                                    {
                                        nodeType: "DistanceTexture",
                                        inputs: {
                                            Vector: uv,
                                        },
                                        parameters: {
                                            "internal.extension": extension,
                                            "internal.interpolation": interpolation,
                                            "internal.projection": projection,
                                            "internal.meshObjectData": inputSource,
                                            "internal.range": range,
                                            "internal.mergeRange": mergeRange,
                                            "internal.width": width,
                                            "internal.height": height,
                                            "internal.force_original_resolution": forceOriginalResolution,
                                            "internal.uvChannel": uvChannel,
                                            "internal.targets": targets,
                                            "internal.innerValue": innerValue,
                                            "internal.smoothPasses": smoothPasses,
                                        },
                                    },
                                    "Color",
                                ),
                                alpha: wrapNodeOutput(
                                    {
                                        nodeType: "ShaderNodeValue",
                                        parameters: {
                                            Value: 1,
                                        },
                                    },
                                    "Value",
                                ),
                            }
                        },
                        hash: hashObject({
                            type: "DistanceTexture",
                            //As long as the distance texture is not used in threejs, we don't need a varying hash to prevent unnecessary updates
                            /*input: inputSource.meshData.id,
                            targets: targets.map((target) => getTargetHashableObject(target, inputSource)),
                            range,
                            width,
                            height,
                            innerValue,*/
                        }),
                    },
                    metadata: {
                        width,
                        height,
                    },
                }
            },
            "distanceTexture",
        )
    }
}

export type DistanceTextureFwd = TemplateNode<DistanceTextureParameters> & EvaluableTemplateNode<ImageGenerator | null>
