import {Operators as Op, AttributeRef, StandardGeometryAttributes, GeomBuilderContext} from "#template-nodes/geometry-processing/geometry-graph"
import {
    Vec2Literal,
    ShapeBasis,
    scale,
    lerp,
    makeLoop,
    makeShapeBasis,
    transformBasis2D,
    transformBasis3D,
    square,
    roundedHalfEdge,
    puncturedSquare,
    roundedRectBorder,
    dualRadiusBorder,
    flipLoop,
} from "#template-nodes/geometry-processing/shapes"
import {Matrix4, Matrix3x2} from "@cm/math"

type ComponentGeometry = {
    pos: AttributeRef
    manUV: AttributeRef
    texUV: AttributeRef
}

type TwoLoopRingInterface = {
    // clockwise:
    // left: A+
    // top: D+
    // right: A-
    // bottom: D-
    loops: readonly [AttributeRef, AttributeRef] // A, D
    // clockwise:
    // left start
    // left end, top start
    // ...
    manifoldUVs: readonly [Vec2Literal, Vec2Literal, Vec2Literal, Vec2Literal]
}

type SixLoopRingInterface = {
    // clockwise:
    // left: A+
    // top left before corner: B+
    // top left after corner: C+
    // top: D+
    // top right before corner: E+
    // top right after corner: B-
    // right: A-
    // bottom right before corner: F+
    // bottom right after corner: E-
    // bottom: D-
    // bottom left before corner: C-
    // bottom left after corner: F-
    loops: readonly [AttributeRef, AttributeRef, AttributeRef, AttributeRef, AttributeRef, AttributeRef] // A, B, C, D, E, F
    // clockwise:
    // left start
    // left end / top left before corner start
    // top left before corner end / top left after corner start
    // ...
    manifoldUVs: readonly [
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
        Vec2Literal,
    ]
}

function transformAndOffsetComponent(basis: ShapeBasis, invert: boolean, ofs: number): ShapeBasis {
    const posOrigin = [0, ofs, 0]
    const x = [1, 0, 0]
    const y = [0, 0, -1]
    const z = [0, invert ? -1 : 1, 0]
    const uvOrigin = [0, 0]
    const u = [invert ? -1 : 1, 0]
    const v = [0, 1]

    const posMatrix = Matrix4.fromArray([x[0], x[1], x[2], 0, y[0], y[1], y[2], 0, z[0], z[1], z[2], 0, posOrigin[0], posOrigin[1], posOrigin[2], 1])
    const uvMatrix = Matrix3x2.fromArray([u[0], u[1], v[0], v[1], uvOrigin[0], uvOrigin[1]])
    return {
        posMatrix: basis.posMatrix.multiply(posMatrix),
        uvMatrix: basis.uvMatrix.multiply(uvMatrix),
    }
}

type BottomSquareParams = {
    width: number
    length: number
    punctureSizeFactor: number
    widthSegmentCount: number
    lengthSegmentCount: number
    innerLoopSegmentCount: number
}

type RectangleInterface = TwoLoopRingInterface & {
    width: number
    length: number
    offset: number
}

type RoundedRectangleInterface = SixLoopRingInterface & {
    width: number
    length: number
    radius: number
    offset: number
}

function bottomSquare(ctx: GeomBuilderContext, params: BottomSquareParams): readonly [ComponentGeometry, RectangleInterface] {
    const c0 = 1
    const c1 = 0.8
    const topology: RectangleInterface = {
        loops: [makeLoop(ctx, params.widthSegmentCount), makeLoop(ctx, params.lengthSegmentCount)],
        manifoldUVs: [
            [-c1, -c1],
            [-c1, +c1],
            [+c1, +c1],
            [+c1, -c1],
        ],
        width: params.width,
        length: params.length,
        offset: 0,
    }
    const loopA = topology.loops[0]
    const loopB = topology.loops[1]
    const innerLoop = makeLoop(ctx, params.innerLoopSegmentCount)
    const basis = transformAndOffsetComponent(makeShapeBasis(), true, topology.offset)
    let punctured = puncturedSquare(
        transformBasis2D(basis, [-params.width / 2, -params.length / 2], [params.width, 0], [0, params.length]),
        loopA,
        loopB,
        innerLoop,
        params.punctureSizeFactor,
        params.punctureSizeFactor,
        topology.manifoldUVs,
        [
            [-c0, -c0],
            [-c0, +c0],
            [+c0, +c0],
            [+c0, -c0],
        ],
    )
    const punctureW = params.punctureSizeFactor * params.width
    const punctureH = params.punctureSizeFactor * params.length
    let fill = square(transformBasis2D(basis, [-punctureW / 2, -punctureH / 2], [punctureW, 0], [0, punctureH]), loopA, loopB, [
        [-1.2, -1.2],
        [-1.2, -1.1],
        [-1.1, -1.1],
        [-1.1, -1.2],
    ])
    const geom = Op.merge(punctured, fill)
    return [geom, topology]
}

type BottomCornerRadiusParams = {
    innerCornerRadius: number
    radiusParallelSegmentCount: number
    radiusAngularSegmentCount: number
}

function bottomCornerRadius(
    ctx: GeomBuilderContext,
    params: BottomCornerRadiusParams,
    prev: RectangleInterface,
): readonly [ComponentGeometry, RoundedRectangleInterface] {
    const loopCount1 = Math.round(params.radiusAngularSegmentCount * 0.5)
    const loopCount2 = params.radiusAngularSegmentCount - loopCount1
    const outer = prev.manifoldUVs
    const sc = 0.8
    const sc2 = 0.2
    const innerCorners: Vec2Literal[] = [scale(outer[0], sc), scale(outer[1], sc), scale(outer[2], sc), scale(outer[3], sc)]
    const topology: RoundedRectangleInterface = {
        loops: [prev.loops[0], makeLoop(ctx, loopCount1), makeLoop(ctx, loopCount2), prev.loops[1], makeLoop(ctx, loopCount2), makeLoop(ctx, loopCount1)],
        manifoldUVs: [
            innerCorners[0],
            lerp(innerCorners[0], innerCorners[1], sc2),
            lerp(innerCorners[1], innerCorners[0], sc2),
            innerCorners[1],
            lerp(innerCorners[1], innerCorners[2], sc2),
            lerp(innerCorners[2], innerCorners[1], sc2),
            innerCorners[2],
            lerp(innerCorners[2], innerCorners[3], sc2),
            lerp(innerCorners[3], innerCorners[2], sc2),
            innerCorners[3],
            lerp(innerCorners[3], innerCorners[0], sc2),
            lerp(innerCorners[0], innerCorners[3], sc2),
        ],
        width: prev.width + params.innerCornerRadius * 2,
        length: prev.length + params.innerCornerRadius * 2,
        radius: params.innerCornerRadius,
        offset: prev.offset,
    }
    const innerLoop = makeLoop(ctx, params.radiusParallelSegmentCount)
    const basis = transformAndOffsetComponent(makeShapeBasis(), true, topology.offset)
    let geom = roundedRectBorder(
        basis,
        topology.loops[0],
        topology.loops[1],
        topology.loops[2],
        topology.loops[3],
        topology.loops[4],
        topology.loops[5],
        innerLoop,
        prev.width,
        prev.length,
        params.innerCornerRadius,
        {outer: topology.manifoldUVs, inner: outer},
    ) // inverted!
    return [geom, topology]
}

type EdgeRadiusParams = {
    edgeRadius: number
    radiusAngularSegmentCount: number
}

function bottomEdgeRadius(
    ctx: GeomBuilderContext,
    params: EdgeRadiusParams,
    prev: RoundedRectangleInterface,
): readonly [ComponentGeometry, RoundedRectangleInterface] {
    const outer = prev.manifoldUVs
    const sc = 0.8
    const topology: RoundedRectangleInterface = {
        loops: prev.loops,
        manifoldUVs: outer.map((p) => scale(p, sc)) as unknown as SixLoopRingInterface["manifoldUVs"],
        width: prev.width + params.edgeRadius * 2,
        length: prev.length + params.edgeRadius * 2,
        radius: prev.radius + params.edgeRadius,
        offset: prev.offset + params.edgeRadius,
    }
    const innerLoop = makeLoop(ctx, params.radiusAngularSegmentCount)
    const basis = transformAndOffsetComponent(makeShapeBasis(), true, topology.offset)
    let geom = dualRadiusBorder(
        basis,
        topology.loops[0],
        topology.loops[1],
        topology.loops[2],
        topology.loops[3],
        topology.loops[4],
        topology.loops[5],
        innerLoop,
        prev.width - prev.radius * 2,
        prev.length - prev.radius * 2,
        topology.radius, // _new_ outer radius
        params.edgeRadius,
        {outer: topology.manifoldUVs, inner: outer},
    ) // inverted!
    return [geom, topology]
}

function topEdgeRadius(
    ctx: GeomBuilderContext,
    params: EdgeRadiusParams,
    prev: RoundedRectangleInterface,
): readonly [ComponentGeometry, RoundedRectangleInterface] {
    const outer = prev.manifoldUVs
    const sc = 0.8
    const topology: RoundedRectangleInterface = {
        loops: prev.loops,
        manifoldUVs: outer.map((p) => scale(p, sc)) as unknown as SixLoopRingInterface["manifoldUVs"],
        width: prev.width - params.edgeRadius * 2,
        length: prev.length - params.edgeRadius * 2,
        radius: prev.radius - params.edgeRadius,
        offset: prev.offset + params.edgeRadius,
    }
    const innerLoop = makeLoop(ctx, params.radiusAngularSegmentCount)
    const basis = transformAndOffsetComponent(makeShapeBasis(), false, prev.offset)
    let geom = dualRadiusBorder(
        basis,
        topology.loops[0],
        topology.loops[1],
        topology.loops[2],
        topology.loops[3],
        topology.loops[4],
        topology.loops[5],
        innerLoop,
        prev.width - prev.radius * 2,
        prev.length - prev.radius * 2,
        prev.radius, // _prev_ outer radius
        params.edgeRadius,
        {outer, inner: topology.manifoldUVs},
    )
    return [geom, topology]
}

type TopSidesParams = {
    topRadius: number
    height: number
    loopSegmentCount: number
}

function topSide(
    basis: ShapeBasis,
    hLoop1: AttributeRef,
    hLoop2: AttributeRef,
    hLoop3: AttributeRef,
    vLoop: AttributeRef,
    hSize: number,
    vSize: number,
    cornerRadius: number,
    manCoords: Vec2Literal[],
) {
    let halfCorner1 = roundedHalfEdge(
        transformBasis3D(
            basis,
            [0, vSize, 0], // posOrigin
            [-cornerRadius, 0, 0], // x
            [0, -vSize, 0], // y
            [0, 0, cornerRadius], // z
            [0, vSize], // uvOrigin
            [-cornerRadius, 0], // u
            [0, -vSize], // v
        ),
        flipLoop(hLoop1),
        vLoop,
        [manCoords[2], manCoords[7], manCoords[0], manCoords[1]],
    )
    let side = square(
        transformBasis3D(
            basis,
            [0, 0, 0], // posOrigin
            [hSize - cornerRadius * 2, 0, 0], // x
            [0, vSize, 0], // y
            [0, 0, 1], // z
            [0, 0], // uvOrigin
            [hSize - cornerRadius * 2, 0], // u
            [0, vSize], // v
        ),
        hLoop2,
        vLoop,
        [manCoords[7], manCoords[2], manCoords[3], manCoords[6]],
    )
    let halfCorner2 = roundedHalfEdge(
        transformBasis3D(
            basis,
            [hSize - cornerRadius * 2, 0, 0], // posOrigin
            [cornerRadius, 0, 0], // x
            [0, vSize, 0], // y
            [0, 0, cornerRadius], // z
            [hSize - cornerRadius * 2, 0], // uvOrigin
            [cornerRadius, 0], // u
            [0, vSize], // v
        ),
        hLoop3,
        vLoop,
        [manCoords[6], manCoords[3], manCoords[4], manCoords[5]],
    )
    return Op.merge(halfCorner1, side, halfCorner2)
}

function topSides(ctx: GeomBuilderContext, params: TopSidesParams, prev: RoundedRectangleInterface): readonly [ComponentGeometry, RoundedRectangleInterface] {
    const outer = prev.manifoldUVs
    const sc = 0.8
    const inner = outer.map((p) => scale(p, sc)) as unknown as SixLoopRingInterface["manifoldUVs"]
    const topRadiusCircumfrenceAdjust = params.topRadius * (Math.PI / 2 - 1)
    const {height, topRadius} = params
    const {radius: cornerRadius, length, width} = prev
    const innerLoop = makeLoop(ctx, params.loopSegmentCount)
    const basis = transformAndOffsetComponent(makeShapeBasis(), false, prev.offset)
    const uStartLeft = -width / 2 - topRadiusCircumfrenceAdjust
    const vStartTop = length / 2 + topRadiusCircumfrenceAdjust
    const uStartRight = width / 2 + topRadiusCircumfrenceAdjust
    const vStartBottom = -length / 2 - topRadiusCircumfrenceAdjust
    const left = topSide(
        transformBasis3D(
            basis,
            [-width / 2, -(length / 2 - cornerRadius), height], // posOrigin
            [0, 1, 0], // x
            [0, 0, -1], // y
            [-1, 0, 0], // z
            [uStartLeft, -(length / 2 - cornerRadius)], // uvOrigin
            [0, 1], // u
            [-1, 0], // v
        ),
        prev.loops[5],
        prev.loops[0],
        prev.loops[1],
        innerLoop,
        length,
        height,
        cornerRadius,
        [inner[0], outer[0], outer[1], outer[2], outer[3], inner[3], inner[2], inner[1]],
    )
    const right = topSide(
        transformBasis3D(
            basis,
            [width / 2, length / 2 - cornerRadius, height], // posOrigin
            [0, -1, 0], // x
            [0, 0, -1], // y
            [1, 0, 0], // z
            [uStartRight, length / 2 - cornerRadius], // uvOrigin
            [0, -1], // u
            [1, 0], // v
        ),
        flipLoop(prev.loops[1]),
        flipLoop(prev.loops[0]),
        prev.loops[5],
        innerLoop,
        length,
        height,
        cornerRadius,
        [inner[6], outer[6], outer[7], outer[8], outer[9], inner[9], inner[8], inner[7]],
    )
    const top = topSide(
        transformBasis3D(
            basis,
            [-(width / 2 - cornerRadius), length / 2, height], // posOrigin
            [1, 0, 0], // x
            [0, 0, -1], // y
            [0, 1, 0], // z
            [width / 2 - cornerRadius, vStartTop], // uvOrigin
            [1, 0], // u
            [0, 1], // v
        ),
        prev.loops[2],
        prev.loops[3],
        prev.loops[4],
        innerLoop,
        width,
        height,
        cornerRadius,
        [inner[3], outer[3], outer[4], outer[5], outer[6], inner[6], inner[5], inner[4]],
    )
    const bottom = topSide(
        transformBasis3D(
            basis,
            [width / 2 - cornerRadius, -length / 2, height], // posOrigin
            [-1, 0, 0], // x
            [0, 0, -1], // y
            [0, -1, 0], // z
            [-(width / 2 - cornerRadius), vStartBottom], // uvOrigin
            [-1, 0], // u
            [0, -1], // v
        ),
        flipLoop(prev.loops[4]),
        flipLoop(prev.loops[3]),
        flipLoop(prev.loops[2]),
        innerLoop,
        width,
        height,
        cornerRadius,
        [inner[9], outer[9], outer[10], outer[11], outer[0], inner[0], inner[11], inner[10]],
    )
    const merged = Op.merge(left, right, top, bottom)
    return [
        merged,
        {
            loops: prev.loops,
            manifoldUVs: inner,
            width,
            length,
            radius: cornerRadius,
            offset: prev.offset + height,
        },
    ]
}

type TopCornerRadiusParams = {
    radiusParallelSegmentCount: number
}

function topCornerRadius(
    ctx: GeomBuilderContext,
    params: TopCornerRadiusParams,
    prev: RoundedRectangleInterface,
): readonly [ComponentGeometry, RectangleInterface] {
    const outer = prev.manifoldUVs
    const sc = 0.8
    const topology: RectangleInterface = {
        loops: [prev.loops[0], prev.loops[3]],
        manifoldUVs: [scale(outer[0], sc), scale(outer[3], sc), scale(outer[6], sc), scale(outer[9], sc)],
        width: prev.width - prev.radius * 2,
        length: prev.length - prev.radius * 2,
        offset: prev.offset,
    }
    const innerLoop = makeLoop(ctx, params.radiusParallelSegmentCount)
    const basis = transformAndOffsetComponent(makeShapeBasis(), false, topology.offset)
    let geom = roundedRectBorder(
        basis,
        prev.loops[0],
        prev.loops[1],
        prev.loops[2],
        prev.loops[3],
        prev.loops[4],
        prev.loops[5],
        innerLoop,
        topology.width,
        topology.length,
        prev.radius,
        {outer, inner: topology.manifoldUVs},
    )
    return [geom, topology]
}

function topSquare(ctx: GeomBuilderContext, prev: RectangleInterface): ComponentGeometry {
    const basis = transformAndOffsetComponent(makeShapeBasis(), false, prev.offset)
    return square(
        transformBasis2D(basis, [-prev.width / 2, -prev.length / 2], [prev.width, 0], [0, prev.length]),
        prev.loops[0],
        prev.loops[1],
        prev.manifoldUVs,
    )
}

export type MattressParams = {
    cornerRadiusSegments?: number
    topRadiusSegments?: number
    bottomRadiusSegments?: number
    widthSegments?: number
    heightSegments?: number
    lengthSegments?: number
    topRadius?: number
    cornerRadius?: number
    bottomRadius?: number
    width?: number
    length?: number
    height?: number
    resolutionMultiplier?: number
}

export function mattress(params: MattressParams): StandardGeometryAttributes {
    const ctx = new GeomBuilderContext()
    const cornerRadiusSegments = params.cornerRadiusSegments ?? 10
    const topRadiusSegments = params.topRadiusSegments ?? 10
    const bottomRadiusSegments = params.bottomRadiusSegments ?? 10
    const widthSegments = params.widthSegments ?? 10
    const heightSegments = params.heightSegments ?? 10
    const lengthSegments = params.lengthSegments ?? 10
    let topRadius = params.topRadius ?? 5
    let cornerRadius = params.cornerRadius ?? 10
    let bottomRadius = params.bottomRadius ?? 5
    const width = params.width ?? 50
    const length = params.length ?? 100
    const height = params.height ?? 25
    const bottomLoopSegments = Math.max(widthSegments, lengthSegments)
    const cornerInnerLoopSegments = 10

    const eps = 1e-3

    cornerRadius = Math.max(eps, Math.min(cornerRadius, width / 2 - eps, length / 2 - eps))
    topRadius = Math.max(eps, Math.min(topRadius, height / 2 - eps))
    bottomRadius = Math.max(eps, Math.min(bottomRadius, height / 2 - eps))

    // start with the bottom (punctured) square
    const [geom1, iface1] = bottomSquare(ctx, {
        width: width - cornerRadius * 2,
        length: length - cornerRadius * 2,
        punctureSizeFactor: 0.01,
        widthSegmentCount: widthSegments,
        lengthSegmentCount: lengthSegments,
        innerLoopSegmentCount: bottomLoopSegments,
    })

    // ...then add the bottom inner corner radius
    const [geom2, iface2] = bottomCornerRadius(
        ctx,
        {
            innerCornerRadius: cornerRadius - bottomRadius,
            radiusAngularSegmentCount: cornerRadiusSegments,
            radiusParallelSegmentCount: cornerInnerLoopSegments,
        },
        iface1,
    )

    // ...then curve upwards with the bottom radius
    const [geom3, iface3] = bottomEdgeRadius(
        ctx,
        {
            radiusAngularSegmentCount: bottomRadiusSegments,
            edgeRadius: bottomRadius,
        },
        iface2,
    )

    // ...then add the top sides
    const [geom4, iface4] = topSides(
        ctx,
        {
            topRadius,
            height,
            loopSegmentCount: heightSegments,
        },
        iface3,
    )

    // ..then curve inwards with the top radius
    const [geom5, iface5] = topEdgeRadius(
        ctx,
        {
            radiusAngularSegmentCount: topRadiusSegments,
            edgeRadius: topRadius,
        },
        iface4,
    )

    // ...then remove the top inner corner radius
    const [geom6, iface6] = topCornerRadius(
        ctx,
        {
            radiusParallelSegmentCount: cornerInnerLoopSegments,
        },
        iface5,
    )

    /// ...finally close the top with a square
    const geom7 = topSquare(ctx, iface6)

    // merge and weld
    let {pos, texUV, manUV} = Op.merge(geom1, geom2, geom3, geom4, geom5, geom6, geom7)
    pos = pos.weld(1e-4)
    texUV = texUV.weld(1e-4)
    manUV = manUV.weld(1e-5)

    return {
        position: pos,
        normal: pos.normals(1),
        materialID: pos.constInt(0),
        uvs: [texUV, manUV],
    }
}
