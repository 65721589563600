import {RenderDataObject, RendererImage, RendererInfo, RendererStatus, RenderMetadata, RenderNodes} from "@cm/render-nodes"
import {CmLogger} from "@cm/utils"
import {RemoteMessagingConnection} from "@cm/utils/websocket/remote-messaging"
import {WebSocketImplementation} from "@cm/utils/websocket/types"
import {CreateWebSocketMessagingConnection, WebSocketMessagingConnection} from "@cm/utils/websocket/websocket-messaging"
import {firstValueFrom} from "rxjs"
import {graphToJson} from "@cm/utils/graph-json"

export type CacheableEntity = RenderDataObject

export type InvalidatableEntity = Omit<RenderDataObject, "data" | "localPath" | "contentType">

export const connectRenderEngineMessaging =
    <RuntimeImplementation extends WebSocketImplementation>(
        createWebSocketMessagingConnection: CreateWebSocketMessagingConnection<RuntimeImplementation>,
        logger: CmLogger,
    ) =>
    (url: string, maxPayload?: number) => {
        return firstValueFrom(createWebSocketMessagingConnection(url, maxPayload)).then((messaging: WebSocketMessagingConnection<RuntimeImplementation>) => {
            return new RenderEngineMessagingConnection(messaging, logger)
        })
    }

export class RenderEngineMessagingConnection<RuntimeImplementation extends WebSocketImplementation> extends RemoteMessagingConnection<RuntimeImplementation> {
    public constructor(
        messaging: WebSocketMessagingConnection<RuntimeImplementation>,
        protected override logger: CmLogger,
    ) {
        super(messaging, "Remote renderer", logger)
    }

    getInfo(): Promise<RendererInfo> {
        return this.doTransaction({command: "getInfo"})
    }

    async update(graph: RenderNodes.Render) {
        await this.doTransaction({command: "update", graph: graphToJson(graph, this.logger)})
    }

    async stop(): Promise<void> {
        await this.doTransaction({command: "stop"})
    }

    async cacheEntity(entity: CacheableEntity) {
        await this.doTransaction({command: "cacheEntity", entity})
    }

    async invalidateEntity(entity: InvalidatableEntity) {
        await this.doTransaction({command: "invalidateEntity", entity})
    }

    async clearCaches() {
        await this.doTransaction({command: "clearCaches"})
    }

    getStatus(): Promise<RendererStatus> {
        return this.doTransaction({command: "getStatus"})
    }

    getRenderMetadata(): Promise<RenderMetadata> {
        return this.doTransaction({command: "getRenderMetadata"})
    }

    getPreviewImage(format: "raw" | "exr16"): Promise<RendererImage> {
        return this.doTransaction({command: "getPreviewImage", format: format})
    }

    getFinalImage(passName: string, format: "raw" | "exr16" | "exr32"): Promise<RendererImage> {
        return this.doTransaction({command: "getFinalImage", pass: passName, format})
    }
}
