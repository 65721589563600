import type {OperationVariables} from "@apollo/client"
import type {ApolloQueryResult} from "@apollo/client/core"
import type {QueryOptions as CoreQueryOptions} from "@apollo/client/core/watchQueryOptions"
import {firstValueFrom, type Observable} from "rxjs"

export type EmptyObject = {
    [key: string]: unknown
}
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface QueryOptionsAlone<TVariables = EmptyObject, TData = unknown> extends Omit<CoreQueryOptions<TVariables, TData>, "query" | "variables"> {}

export const fetchIgnoringErrors =
    <ResultType = object, VariablesType extends OperationVariables = EmptyObject>(query: {
        fetch: (variables?: VariablesType, options?: QueryOptionsAlone<VariablesType, ResultType>) => Observable<ApolloQueryResult<ResultType>>
    }) =>
    (variables?: VariablesType, options?: QueryOptionsAlone<VariablesType, ResultType>) =>
        firstValueFrom(query.fetch(variables, options)).then(({data}) => data)

export const fetchThrowingErrors =
    <ResultType = object, VariablesType extends OperationVariables = EmptyObject>(query: {
        fetch: (variables?: VariablesType, options?: QueryOptionsAlone<VariablesType, ResultType>) => Observable<ApolloQueryResult<ResultType>>
    }): ((variables: VariablesType, options?: QueryOptionsAlone<VariablesType, ResultType>) => Promise<ResultType>) =>
    (variables?: VariablesType, options?: QueryOptionsAlone<VariablesType, ResultType>) =>
        firstValueFrom(query.fetch(variables, options)).then(({data, error}) => {
            if (error) {
                throw error
            }
            return data
        })
