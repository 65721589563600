import {Component, DestroyRef, OnInit, computed, inject, input, signal} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {TemplateNode} from "@cm/template-nodes"
import {PermissionsService} from "@common/services/permissions/permissions.service"

@Component({
    selector: "cm-base-inspector",
    imports: [],
    template: "",
})
export class BaseInspectorComponent<T extends TemplateNode<ParamType> | null, ParamType extends {} = {}> implements OnInit {
    readonly permission = inject(PermissionsService)
    $can = this.permission.$to

    readonly $node = input.required<T>({alias: "node"})
    private readonly $triggerRecompute = signal(0)
    readonly $parameters = computed<T extends null ? NonNullable<T>["parameters"] | null : NonNullable<T>["parameters"]>(
        () => {
            this.$triggerRecompute()
            const node = this.$node()
            if (!node) return null as T extends null ? NonNullable<T>["parameters"] | null : NonNullable<T>["parameters"]
            return node.parameters as T extends null ? NonNullable<T>["parameters"] | null : NonNullable<T>["parameters"]
        },
        {equal: () => false},
    )

    protected readonly sceneManagerService = inject(SceneManagerService)
    protected readonly destroyRef = inject(DestroyRef)

    ngOnInit() {
        this.sceneManagerService.templateTreeChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((differences) => {
            const node = this.$node()
            if (node && differences.modifiedNodes.has(node)) this.$triggerRecompute.update((x) => x + 1)
        })
    }

    onUpdatedParameters() {
        this.$triggerRecompute.update((x) => x + 1)
    }
}
