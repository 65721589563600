<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> Data Object Reference </ng-container>
    <ng-container cm-content>
        <cm-card [defaultThumbnail]="false" (click)="onClick()">
            <ng-container cm-thumbnail>
                @if ($thumbnailObject(); as thumbnailData) {
                    <cm-data-object-thumbnail [dataObjectId]="thumbnailData.id"></cm-data-object-thumbnail>
                } @else {
                    @if ($thumbnailUrl(); as thumbnailData) {
                        <cm-thumbnail [url]="thumbnailData"></cm-thumbnail>
                    }
                }
            </ng-container>
            <ng-container cm-title>
                {{ $title() }}
            </ng-container></cm-card
        >
        <cm-value-slot [node]="$node()" [key]="'name'" [label]="'Name'"></cm-value-slot>
        @if ($dataObject(); as dataObject) {
            <div>
                <cm-action-panel-row cmIconClass="far fa-user">
                    <span matTooltip="{{ dataObject.createdAt | cmFormatDate }}" matTooltipPosition="above"
                        >{{ dataObject.createdBy?.name }}
                        @if (dataObject.createdAt) {
                            ({{ dataObject.createdAt | cmTimeAgo }})
                        }
                    </span>
                </cm-action-panel-row>
                <cm-action-panel-row cmIconClass="far fa-image-polaroid">
                    {{ dataObject.width ?? "?" }} px × {{ dataObject.height ?? "?" }} px
                </cm-action-panel-row>
                <cm-action-panel-row cmIconClass="far fa-palette">
                    @if (dataObject.imageColorSpace) {
                        {{ Labels.ImageColorSpace.get(dataObject.imageColorSpace)?.label ?? "Not set" }}
                    } @else {
                        Not set
                    }
                    @if (dataObject.size; as size) {
                        ({{ size | filesize: {base: 2} }})
                    }
                </cm-action-panel-row>
            </div>
        }
    </ng-container>
</cm-inspector-section>
