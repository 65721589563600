import {getParametersForArGeneration} from "@app/common/helpers/ar/ar"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {ConfigInfo, Parameters} from "@cm/template-nodes"
import {hashObject} from "@cm/utils/hashing"
import {Observable} from "rxjs"

export type ConfigurationInfo = {
    name: string
    parameters: Parameters
    arAssignmentParameters: Parameters /*Ar assignment parameters must always be the global configuration, even if we iterate over the local*/
    hash: string
}

export function gatherAllVariations(
    localSceneManagerService: SceneManagerService,
    progress: (progress: number) => void,
    configInfoSideEffect?: (configInfo: ConfigInfo) => void,
) {
    const includeAllSubTemplateInputs = localSceneManagerService.$exposeClaimedSubTemplateInputs()

    return new Observable<ConfigurationInfo | "done">((observer) => {
        let isAborted = false

        ;(async () => {
            type PermutationProposal = Record<string, string>

            const allConfigurations = new Set<string>()
            const visitedProposals = new Set<string>()
            const pendingProposals: PermutationProposal[] = [{}]
            let countUpperBound = 1

            progress(0)
            while (true) {
                if (isAborted) break

                const proposal = pendingProposals.shift()
                if (!proposal) break

                localSceneManagerService.$instanceParameters.set(new Parameters(proposal))

                console.log("Compiling template graph")
                localSceneManagerService.compileTemplate()
                try {
                    await localSceneManagerService.sync(true)
                } catch (e) {
                    console.error("Error while syncing template graph", e)
                    continue
                }
                console.log("Compiled template graph")

                const currentConfiguration = includeAllSubTemplateInputs
                    ? localSceneManagerService.$currentGlobalConfiguration()
                    : localSceneManagerService.$currentLocalConfiguration()
                const hash = currentConfiguration.getHash()

                if (!allConfigurations.has(hash)) {
                    const descriptors = localSceneManagerService
                        .getDescriptorsForNode(localSceneManagerService.$templateInstance())
                        .filter((descriptor): descriptor is ConfigInfo => descriptor instanceof ConfigInfo)

                    const curConfigs: PermutationProposal = {}

                    const configNames: string[] = []
                    for (const descriptor of descriptors) {
                        const {id, name, value} = descriptor.props

                        if (!value) continue

                        curConfigs[id] = value.id
                        configNames.push(`${name}: ${value.name}`)
                    }

                    const newConfiguration: ConfigurationInfo = {
                        name: configNames.length > 0 ? configNames.join(", ") : "Single Variation",
                        parameters: currentConfiguration,
                        arAssignmentParameters: getParametersForArGeneration(localSceneManagerService),
                        hash,
                    }

                    observer.next(newConfiguration)

                    allConfigurations.add(hash)

                    for (const descriptor of descriptors) {
                        const {id, variants} = descriptor.props

                        for (const variant of variants.filter((x) => !x.excludeFromPermutations)) {
                            const proposal: PermutationProposal = {
                                ...curConfigs,
                                [id]: variant.id,
                            }

                            const key = hashObject(proposal)
                            if (!visitedProposals.has(key)) {
                                countUpperBound += 1
                                visitedProposals.add(key)
                                pendingProposals.push(proposal)
                            }
                        }
                    }

                    if (configInfoSideEffect) descriptors.forEach(configInfoSideEffect)
                }

                console.log(`Proposals remaining: ${pendingProposals.length} / ${countUpperBound}`)
                progress(((countUpperBound - pendingProposals.length) / countUpperBound) * 100)
            }

            observer.next("done")
            observer.complete()
        })()

        return () => {
            isAborted = true
        }
    })
}
