import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {gaussianBlur} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/gaussian-blur"
import {mean} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/mean"
import {affineTransform} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-affine-transform"
import {blend} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-blend"
import {convert} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-convert"
import {createImage} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-create-image"
import {drawableImage} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-drawable-image"
import {math} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-math"
import {toGrayscale} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-to-grayscale"
import {descriptorByTextureType} from "@app/textures/utils/texture-type-descriptor"
import {Matrix3x2, Vector2} from "@cm/math"
import {assertNever, deepCopy} from "@cm/utils"
import {
    Operator,
    OperatorInput,
    OperatorOutput,
    OperatorPanelComponentType,
    OperatorProcessingHints,
} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-base"
import {OperatorCallback} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {HighpassPanelComponent} from "app/textures/texture-editor/operator-stack/operators/highpass/panel/highpass-panel.component"
import {HighpassToolbox} from "app/textures/texture-editor/operator-stack/operators/highpass/toolbox/highpass-toolbox"
import {BrushSettings} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"
import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"
import {ManagedDrawableImageHandle} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/drawable-image-handle"

export class OperatorHighpass extends OperatorBase<TextureEditNodes.OperatorHighpass> {
    readonly panelComponentType: OperatorPanelComponentType = HighpassPanelComponent
    readonly canvasToolbox: HighpassToolbox

    readonly type = "operator-highpass" as const

    constructor(callback: OperatorCallback, node: TextureEditNodes.OperatorHighpass | null) {
        super(
            callback,
            deepCopy(node) ?? {
                type: "operator-highpass",
                enabled: true,
                blurSettingsH: {
                    enabled: true,
                    smoothingDistance: 150,
                },
                blurSettingsV: {
                    enabled: true,
                    smoothingDistance: 150,
                },
                intensity: 1,
                wrapAround: false,
                angleOffset: 0,
                maskReference: undefined,
                correctionMode: "modulation",
            },
        )

        // convert legacy smoothingDistance to blurSettings
        if (this.node.smoothingDistance) {
            this.node.blurSettingsH = {
                enabled: true,
                smoothingDistance: this.node.smoothingDistance.x,
            }
            this.node.blurSettingsV = {
                enabled: true,
                smoothingDistance: this.node.smoothingDistance.y,
            }
            this.node.angleOffset = 0
            delete this.node.smoothingDistance
        }

        this.canvasToolbox = new HighpassToolbox(this)
    }

    // OperatorBase
    override async init() {
        await super.init()
        await this.updateDrawableImageRef()
    }

    // OperatorBase
    override dispose(): void {
        super.dispose()
        this._maskDrawableImageHandle?.release()
        this.canvasToolbox.remove()
    }

    // OperatorBase
    async clone(): Promise<Operator> {
        const clonedOperator = new OperatorHighpass(this.callback, deepCopy(this.node))
        await clonedOperator.init()
        if (this._maskDrawableImageHandle) {
            await clonedOperator.addMask()
            if (!clonedOperator._maskDrawableImageHandle) {
                throw new Error("Failed to clone mask")
            }
            await this.callback.drawableImageCache.copyDrawableImage({
                sourceId: this._maskDrawableImageHandle.ref.id,
                targetId: clonedOperator._maskDrawableImageHandle.ref.id,
            })
        }
        return clonedOperator
    }

    // OperatorBase
    async queueImageOps(cmdQueue: ImageOpCommandQueue, input: OperatorInput, hints: OperatorProcessingHints): Promise<OperatorOutput> {
        cmdQueue.beginScope(this.type)

        if (this._maskDrawableImageHandle) {
            this.canvasToolbox.brushToolboxItem.selected = true
            this.canvasToolbox.brushToolboxItem.flushBrushStrokesExt(cmdQueue, input.descriptor)
        } else {
            this.canvasToolbox.brushToolboxItem.selected = false
        }

        const sourceImage = input
        const blurSettingsH = {
            enabled: this.node.blurSettingsH.enabled,
            smoothingDistance: Math.round(this.node.blurSettingsH.smoothingDistance),
        }
        const blurSettingsV = {
            enabled: this.node.blurSettingsV.enabled,
            smoothingDistance: Math.round(this.node.blurSettingsV.smoothingDistance),
        }
        const wrapAround = this.node.wrapAround
        const intensity = this.node.intensity ?? 1
        const useAngleOffset = (blurSettingsH.enabled || blurSettingsV.enabled) && blurSettingsH.smoothingDistance !== blurSettingsV.smoothingDistance
        const angleOffset = useAngleOffset ? this.node.angleOffset : 0

        const applySourceImageConversion = (sourceImage: OperatorInput) => {
            const correctLuminanceOnly = false
            if (correctLuminanceOnly) {
                const grayscaleConversionMode = descriptorByTextureType(hints.textureType).isColorData ? "luminance" : "average"
                return toGrayscale(cmdQueue, {
                    sourceImage: sourceImage,
                    mode: grayscaleConversionMode,
                })
            } else {
                return sourceImage
            }
        }
        const filterSourceImage = applySourceImageConversion(sourceImage)

        // blur to compute low-pass
        const rotationTransform = new Matrix3x2().rotate(angleOffset)
        const applyRotationAndBlur = (image: ImageRef, angle: number) => {
            let blurSource: ImageRef
            if (angle != 0) {
                const expandedSize = (() => {
                    const p00 = rotationTransform.multiplyVector(new Vector2(-image.descriptor.width, -image.descriptor.height).mulInPlace(0.5))
                    const p10 = rotationTransform.multiplyVector(new Vector2(image.descriptor.width, -image.descriptor.height).mulInPlace(0.5))
                    const p01 = rotationTransform.multiplyVector(new Vector2(-image.descriptor.width, image.descriptor.height).mulInPlace(0.5))
                    const p11 = rotationTransform.multiplyVector(new Vector2(image.descriptor.width, image.descriptor.height).mulInPlace(0.5))
                    const min = Vector2.min(p00, Vector2.min(p10, Vector2.min(p01, p11)))
                    const max = Vector2.max(p00, Vector2.max(p10, Vector2.max(p01, p11)))
                    return max.sub(min).add(new Vector2(blurSettingsH.smoothingDistance, blurSettingsV.smoothingDistance)).ceil()
                })()
                blurSource = affineTransform(cmdQueue, {
                    sourceImage: image,
                    transform: new Matrix3x2()
                        .translate(expandedSize.mul(0.5))
                        .rotate(angle)
                        .translate(new Vector2(image.descriptor.width, image.descriptor.height).mul(-0.5)),
                    addressMode: wrapAround ? "wrap" : "border",
                    resultImageOrDataType: createImage(cmdQueue, {
                        imageOrDescriptor: {
                            ...image.descriptor,
                            width: expandedSize.x,
                            height: expandedSize.y,
                        },
                        fillColor: undefined,
                    }),
                })
            } else {
                blurSource = image
            }
            return gaussianBlur(cmdQueue, {
                sourceImage: blurSource,
                sigma: {
                    x: (blurSettingsH.enabled ? blurSettingsH.smoothingDistance : sourceImage.descriptor.width) / 3,
                    y: (blurSettingsV.enabled ? blurSettingsV.smoothingDistance : sourceImage.descriptor.height) / 3,
                },
                borderMode: wrapAround ? "wrap" : angle != 0 ? "border" : "renormalize",
            })
        }
        let lowpassImage: OperatorInput = applyRotationAndBlur(filterSourceImage, -angleOffset)
        if (angleOffset != 0) {
            if (!wrapAround) {
                // manual renormalization for rotated border
                const imageMask = createImage(cmdQueue, {
                    imageOrDescriptor: {
                        width: filterSourceImage.descriptor.width,
                        height: filterSourceImage.descriptor.height,
                        channelLayout: "R",
                        dataType: "uint8",
                    },
                    fillColor: {r: 1},
                })
                const blurredRotatedImageMask = applyRotationAndBlur(imageMask, -angleOffset)
                lowpassImage = math(cmdQueue, {
                    operator: "/safe",
                    operandA: lowpassImage,
                    operandB: blurredRotatedImageMask,
                })
            }
            // rotate back
            lowpassImage = affineTransform(cmdQueue, {
                sourceImage: lowpassImage,
                transform: new Matrix3x2()
                    .translate(new Vector2(sourceImage.descriptor.width, sourceImage.descriptor.height).mul(0.5))
                    .rotate(angleOffset ?? 0)
                    .translate(Vector2.fromSize2Like(lowpassImage.descriptor).mul(-0.5)),
                resultImageOrDataType: createImage(cmdQueue, {
                    imageOrDescriptor: sourceImage.descriptor,
                    fillColor: undefined,
                }),
            })
        }

        // get optional mask
        const mask = this._maskDrawableImageHandle
            ? drawableImage(cmdQueue, {
                  drawableImageHandle: this._maskDrawableImageHandle.ref,
              })
            : undefined
        if (mask && cmdQueue.mode === "preview" && this.selected && this.showMask) {
            cmdQueue.endScope(this.type)
            return {
                resultImage: mask,
                options: {
                    stopEvaluation: true,
                },
            }
        } else if (cmdQueue.mode === "preview" && this.selected && this.showLowpass) {
            cmdQueue.endScope(this.type)
            return {
                resultImage: lowpassImage,
                options: {
                    stopEvaluation: true,
                },
            }
        } else {
            // compute mean
            const sourceImageMean = mean(cmdQueue, {
                sourceImage: filterSourceImage,
                resultDataType: "float16",
            }).mean

            // compute highpass
            let highpassImage: OperatorInput
            if (this.correctionMode === "offset") {
                const meanMinusLowpass = math(cmdQueue, {
                    operator: "-",
                    operandA: sourceImageMean,
                    operandB: lowpassImage,
                })
                highpassImage = math(cmdQueue, {
                    operator: "+",
                    operandA: sourceImage,
                    operandB: meanMinusLowpass,
                })
            } else if (this.correctionMode === "modulation") {
                const meanDivLowpass = math(cmdQueue, {
                    operator: "/safe",
                    operandA: sourceImageMean,
                    operandB: lowpassImage,
                })
                highpassImage = math(cmdQueue, {
                    operator: "*",
                    operandA: sourceImage,
                    operandB: meanDivLowpass,
                })
            } else {
                assertNever(this.correctionMode)
            }

            // optionally apply mask
            let resultImage: OperatorInput
            if (!mask) {
                resultImage = highpassImage
            } else {
                resultImage = blend(cmdQueue, {
                    backgroundImage: sourceImage,
                    foregroundImage: highpassImage,
                    alpha: mask,
                    blendMode: "normal",
                    premultipliedAlpha: false,
                })
            }

            // optionally blend by intensity
            if (intensity !== 1) {
                resultImage = blend(cmdQueue, {
                    backgroundImage: sourceImage,
                    foregroundImage: resultImage,
                    alpha: intensity,
                    blendMode: "normal",
                    premultipliedAlpha: false,
                })
            }

            // convert back to original data type
            resultImage = convert(cmdQueue, {
                sourceImage: resultImage,
                dataType: sourceImage.descriptor.dataType,
            })

            cmdQueue.endScope(this.type)
            return {
                resultImage,
            }
        }
    }

    set showGuides(value: boolean) {
        this.canvasToolbox.showGuides = value
    }

    get showGuides(): boolean {
        return this.canvasToolbox.showGuides
    }

    set showLowpass(value: boolean) {
        if (this._showLowpass === value) {
            return
        }
        this._showLowpass = value
        if (this.showLowpass) {
            this.showMask = false
        }
        this.requestEval()
    }

    get showLowpass(): boolean {
        return this._showLowpass
    }

    set showMask(value: boolean) {
        if (this._showMask === value) {
            return
        }
        this._showMask = value
        if (this.showMask) {
            this.showLowpass = false
        }
        this.requestEval()
    }

    get showMask(): boolean {
        return this._showMask
    }

    set intensity(value: number) {
        if (this.node.intensity === value) {
            return
        }
        this.node.intensity = value
        this.markEdited()
        this.requestEval()
    }

    get intensity(): number {
        return this.node.intensity ?? 1
    }

    set useWrapAround(value: boolean) {
        if (this.node.wrapAround === value) {
            return
        }
        this.node.wrapAround = value
        this.markEdited()
        this.requestEval()
    }

    get useWrapAround(): boolean {
        return this.node.wrapAround
    }

    get isIsotropic(): boolean {
        return this.useH && this.useV && this.smoothingDistanceH === this.smoothingDistanceV
    }

    set useH(value: boolean) {
        if (this.useH === value) {
            return
        }
        this.node.blurSettingsH.enabled = value
        this.markEdited()
        this.requestEval()
        this.canvasToolbox.updateLineGuides()
    }

    get useH(): boolean {
        return this.node.blurSettingsH.enabled
    }

    set smoothingDistanceH(value: number) {
        if (this.smoothingDistanceH === value) {
            return
        }
        this.node.blurSettingsH.smoothingDistance = value
        this.markEdited()
        this.requestEval()
        this.canvasToolbox.updateLineGuides()
    }

    get smoothingDistanceH(): number {
        return this.node.blurSettingsH.smoothingDistance
    }

    set useV(value: boolean) {
        if (this.useV === value) {
            return
        }
        this.node.blurSettingsV.enabled = value
        this.markEdited()
        this.requestEval()
        this.canvasToolbox.updateLineGuides()
    }

    get useV(): boolean {
        return this.node.blurSettingsV.enabled
    }

    set smoothingDistanceV(value: number) {
        if (this.smoothingDistanceV === value) {
            return
        }
        this.node.blurSettingsV.smoothingDistance = value
        this.markEdited()
        this.requestEval()
        this.canvasToolbox.updateLineGuides()
    }

    get smoothingDistanceV(): number {
        return this.node.blurSettingsV.smoothingDistance
    }

    set angleOffset(value: number) {
        if (this.angleOffset === value) {
            return
        }
        this.node.angleOffset = value
        this.markEdited()
        this.requestEval()
        this.canvasToolbox.updateLineGuides()
    }

    get angleOffset(): number {
        return this.node.angleOffset
    }

    get hasMask(): boolean {
        return this.node.maskReference !== undefined
    }

    get brushSettings(): BrushSettings {
        return this._brushSettings
    }

    set correctionMode(value: TextureEditNodes.OperatorHighpassCorrectionMode) {
        if (this.node.correctionMode === value) {
            return
        }
        this.node.correctionMode = value
        this.markEdited()
        this.requestEval()
    }

    get correctionMode(): TextureEditNodes.OperatorHighpassCorrectionMode {
        return this.node.correctionMode ?? "offset"
    }

    async addMask() {
        if (this.hasMask) {
            return
        }
        this.node.maskReference = {
            type: "data-object-reference",
            dataObjectId: "",
        }
        await this.updateDrawableImageRef()
        this.markEdited()
        this.requestEval()
    }

    async removeMask() {
        if (!this.hasMask) {
            return
        }
        this.node.maskReference = undefined
        this.showMask = false
        await this.updateDrawableImageRef()
        this.markEdited()
        this.requestEval()
    }

    override async save(processingJobId: string) {
        if (this.node.maskReference) {
            if (!this._maskDrawableImageHandle) {
                throw new Error("Mask drawable image handle is missing")
            }
            this.node.maskReference.dataObjectId = await this.callback.drawableImageCache.getDataObjectId(this._maskDrawableImageHandle.ref.id)
        }
        return super.save(processingJobId)
    }

    private async updateDrawableImageRef() {
        this._maskDrawableImageHandle?.release()
        this._maskDrawableImageHandle = undefined
        this._maskDrawableImageHandle = this.node.maskReference
            ? await this.callback.drawableImageCache.createDrawableImage(
                  this.node.maskReference.dataObjectId
                      ? {
                            dataObjectId: this.node.maskReference.dataObjectId,
                            channelLayout: "R",
                            dataType: "uint8",
                        }
                      : {
                            width: 1,
                            height: 1,
                            channelLayout: "R",
                            dataType: "uint8",
                        },
              )
            : undefined
        this.canvasToolbox.brushToolboxItem.setDrawableImage(this._maskDrawableImageHandle?.ref, this.node.maskReference?.dataObjectId ? "fill" : "reset")
    }

    private _showLowpass = false
    private _showMask = false
    private _brushSettings = new BrushSettings()
    private _maskDrawableImageHandle: ManagedDrawableImageHandle | undefined
}
