import {inject, Injectable} from "@angular/core"
import {mutateThrowingErrors} from "@app/common/helpers/api/mutate"
import {MaterialAssetsRenderingService} from "@app/common/services/material-assets-rendering/material-assets-rendering.service"
import {MaterialMapsExporterService} from "@app/common/services/material-maps-exporter/material-maps-exporter.service"
import {NotificationsService} from "@app/common/services/notifications/notifications.service"
import {PermissionsService} from "@app/common/services/permissions/permissions.service"
import {RenderEngineOptions} from "@app/platform/components/materials/material-output-button/material-output-button.component"
import {MaterialOutputFragment} from "@app/platform/components/materials/material-outputs/material-outputs.generated"
import {FlatThumbnailOptionLabels} from "@app/platform/models/state-labels"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {MaterialMapsExporter} from "@cm/material-nodes/material-maps-exporter"
import {JobState, MaterialOutputType} from "@generated"
import {
    MaterialOutputsCancelJobGQL,
    MaterialOutputsCreateMaterialOutputGQL,
    MaterialOutputsDeleteDataObjectGQL,
    MaterialOutputsDeleteJsonFileGQL,
    MaterialOutputsDeleteOutputGQL,
    MaterialOutputsRestartJobGQL,
} from "@platform/services/material/material-output.generated"

type MaterialOutputPartial = Pick<MaterialOutputFragment, "id" | "job" | "result" | "config">

@Injectable({
    providedIn: "root",
})
export class MaterialOutputService {
    readonly materialAssetsRenderingService = inject(MaterialAssetsRenderingService)
    readonly materialMapsExporterService = inject(MaterialMapsExporterService)
    readonly notifications = inject(NotificationsService)
    readonly permission = inject(PermissionsService)
    readonly sceneManagerService = inject(SceneManagerService)

    readonly cancelMaterialOutputJobGql = inject(MaterialOutputsCancelJobGQL)
    readonly createMaterialOutputGql = inject(MaterialOutputsCreateMaterialOutputGQL)
    readonly deleteDataObjectGql = inject(MaterialOutputsDeleteDataObjectGQL)
    readonly deleteJsonFileGql = inject(MaterialOutputsDeleteJsonFileGQL)
    readonly deleteMaterialOutputGql = inject(MaterialOutputsDeleteOutputGQL)
    readonly restartMaterialOutputJobGql = inject(MaterialOutputsRestartJobGQL)

    public constructor() {}

    async createOutput({
        exportRequest,
        materialId,
        materialRevisionId,
        options,
        showNotification,
        type,
    }: {
        exportRequest?: MaterialMapsExporter.Request
        materialId: string
        materialRevisionId: string
        options?: RenderEngineOptions
        showNotification: boolean
        type: MaterialOutputType
    }) {
        let jobId: string | undefined
        let configJsonObjectId: string | undefined
        switch (type) {
            case MaterialOutputType.TileableImage:
                jobId = await this.materialAssetsRenderingService.generateTile(materialId, this.sceneManagerService, options)
                if (showNotification) {
                    this.notifications.showInfo("Tileable image generation: Job submitted", undefined, "Dismiss")
                }
                break
            case MaterialOutputType.ShaderBall: {
                const resolution = 2000
                jobId = await this.materialAssetsRenderingService.generateShaderBallThumbnail(materialId, resolution, this.sceneManagerService, options)
                if (showNotification) {
                    this.notifications.showInfo("Shader ball generation: Job submitted", undefined, "Dismiss")
                }
                break
            }
            case MaterialOutputType.Thumbnail10x10cm:
            case MaterialOutputType.Thumbnail20x20cm:
            case MaterialOutputType.Thumbnail30x30cm:
            case MaterialOutputType.Thumbnail7x7inches:
            case MaterialOutputType.Thumbnail9x9inches:
            case MaterialOutputType.Thumbnail16x16inches: {
                const flatOption = FlatThumbnailOptionLabels.get(type)?.state
                if (flatOption && this.materialAssetsRenderingService.checkThumbnailSizeValid(flatOption.resolution, flatOption.size)) {
                    jobId = await this.materialAssetsRenderingService.generateThumbnail(materialId, flatOption, this.sceneManagerService)
                    if (showNotification) {
                        this.notifications.showInfo("Flat thumbnail generation: Job submitted", undefined, "Dismiss")
                    }
                } else {
                    if (showNotification) {
                        this.notifications.showInfo("Requested resolution too high", 3000)
                    }
                }
                break
            }
            case MaterialOutputType.PbrExportDefaultJpegLow:
            case MaterialOutputType.PbrExportDefaultJpegOriginal:
            case MaterialOutputType.PbrExportDefaultTiffLow:
            case MaterialOutputType.PbrExportDefaultTiffOriginal:
            case MaterialOutputType.PbrExportDefaultExrOriginal:
            case MaterialOutputType.PbrExportCustom: {
                if (exportRequest) {
                    try {
                        const mapsExportIds = await this.materialMapsExporterService.generateMapsExport(exportRequest, {id: materialId})
                        jobId = mapsExportIds.jobId
                        configJsonObjectId = mapsExportIds.configJsonObjectId
                        if (showNotification) {
                            this.notifications.showInfo(`PBR export generation: Job submitted (${jobId})`)
                        }
                    } catch (error) {
                        if (showNotification) {
                            this.notifications.showInfo(`PBR export generation: Failed (${error})`)
                        }
                    }
                } else {
                    if (showNotification) {
                        this.notifications.showInfo("PBR export request missing", 3000)
                    }
                }
                break
            }
            default:
                break
        }

        if (jobId) {
            await mutateThrowingErrors(this.createMaterialOutputGql)({
                input: {
                    configId: configJsonObjectId,
                    jobId,
                    materialId,
                    materialRevisionId,
                    type,
                },
            })
        } else {
            if (showNotification) {
                this.notifications.showInfo("Error creating job", 3000)
            }
        }
    }

    async cancelAndRemoveOutput({materialOutput, showNotification}: {materialOutput: MaterialOutputPartial; showNotification: boolean}) {
        if (materialOutput.job?.state === JobState.Init || materialOutput.job?.state === JobState.Runnable || materialOutput.job?.state === JobState.Running) {
            await mutateThrowingErrors(this.cancelMaterialOutputJobGql)({id: materialOutput.job.id})
        }

        if (materialOutput.result) {
            await mutateThrowingErrors(this.deleteDataObjectGql)({id: materialOutput.result.id}).catch(() => {
                if (showNotification) {
                    this.notifications.showInfo("Cannot delete file.", 3000)
                }
            })
        }

        if (materialOutput.config) {
            await mutateThrowingErrors(this.deleteJsonFileGql)({id: materialOutput.config.id}).catch(() => {
                if (showNotification) {
                    this.notifications.showInfo("Cannot delete config.", 3000)
                }
            })
        }

        await mutateThrowingErrors(this.deleteMaterialOutputGql)({id: materialOutput.id})
    }

    async restartOutput({materialOutput, showNotification}: {materialOutput: MaterialOutputPartial; showNotification: boolean}) {
        if (materialOutput.job?.id) {
            await mutateThrowingErrors(this.restartMaterialOutputJobGql)({id: materialOutput.job.id})
        } else {
            if (showNotification) {
                this.notifications.showInfo("Cannot restart job without job id", 3000)
            }
        }
    }
}
