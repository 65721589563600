import {NgClass} from "@angular/common"
import {Component, computed, DestroyRef, inject, input, OnInit, output, signal} from "@angular/core"
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop"
import {jobIsActive} from "@common/helpers/jobs/states"
import {refetchWithExponentialBackoff} from "@common/helpers/utils/exponential-backoff"
import {ThumbnailLayout} from "@common/models/enums/thumbnail-layout"
import {Settings} from "@common/models/settings/settings"
import {DataObjectThumbnailService} from "@common/services/data-object-thumbnail/data-object-thumbnail.service"
import {DataObjectThumbnailFragment} from "@common/services/data-object-thumbnail/data-object-thumbnail.service.generated"
import {TabStateService} from "@common/services/tab-state/tab-state.service"
import {DataObjectState, DownloadResolution} from "@generated"
import {ThumbnailComponent} from "app/common/components/thumbnail/thumbnail.component"
import {combineLatest, filter, tap} from "rxjs"

type Data = {url: string; icon: string; isPlaceholder: boolean; label?: string; layout: ThumbnailLayout; isImage: boolean}

const defaultData: Data = {
    url: Settings.IMAGE_URL,
    icon: Settings.IMAGE_ICON,
    isPlaceholder: true,
    layout: ThumbnailLayout.Contain,
    isImage: true,
}

@Component({
    selector: "cm-data-object-thumbnail",
    imports: [ThumbnailComponent, NgClass],
    templateUrl: "./data-object-thumbnail.component.html",
    styleUrl: "./data-object-thumbnail.component.scss",
})
export class DataObjectThumbnailComponent implements OnInit {
    readonly $dataObjectId = input<string | undefined>(undefined, {alias: "dataObjectId"})
    readonly $resolution = input(DownloadResolution.Low, {alias: "resolution"})
    readonly $allowLabel = input<boolean | "auto">("auto", {alias: "allowLabel"}) // auto will allow labels if resolution is not tiny
    readonly $requestedLayout = input(ThumbnailLayout.Contain, {alias: "layout"})

    dataObjectId$ = toObservable(this.$dataObjectId)
    resolution$ = toObservable(this.$resolution)

    readonly load = output<Event>()

    readonly dataObjectThumbnail = inject(DataObjectThumbnailService)
    readonly destroyRef = inject(DestroyRef)
    readonly tabState = inject(TabStateService)

    protected readonly $shouldShowLabel = computed(() => {
        const allowLabel = this.$allowLabel()
        return allowLabel === "auto" ? this.$resolution() !== DownloadResolution.Tiny : allowLabel
    })

    protected readonly $data = signal<Data>(defaultData)

    getData(dataObject: DataObjectThumbnailFragment | undefined): Data {
        if (!dataObject) {
            return defaultData
        }

        const isImage = this.isImage(dataObject)

        switch (dataObject?.state) {
            case DataObjectState.ProcessingFailed: {
                return {
                    url: Settings.CLOUD_BROKEN_URL,
                    icon: Settings.CLOUD_BROKEN_ICON,
                    isPlaceholder: true,
                    label: "Processing failed",
                    layout: ThumbnailLayout.Contain,
                    isImage,
                }
            }
            case DataObjectState.Init:
                if (this.thumbnailJobIsProcessing(dataObject)) {
                    return {
                        url: Settings.CLOUD_PROCESSING_URL,
                        icon: Settings.CLOUD_PROCESSING_ICON,
                        isPlaceholder: true,
                        label: "Processing...",
                        layout: ThumbnailLayout.Contain,
                        isImage,
                    }
                } else {
                    return {
                        url: Settings.QUEUED_URL,
                        icon: Settings.QUEUED_ICON,
                        isPlaceholder: true,
                        label: "Processing queued",
                        layout: ThumbnailLayout.Contain,
                        isImage,
                    }
                }
            case DataObjectState.Processing: {
                if (this.thumbnailJobIsProcessing(dataObject)) {
                    return {
                        url: Settings.CLOUD_PROCESSING_URL,
                        icon: Settings.CLOUD_PROCESSING_ICON,
                        isPlaceholder: true,
                        label: "Processing...",
                        layout: ThumbnailLayout.Contain,
                        isImage,
                    }
                } else {
                    return {
                        url: Settings.CLOUD_BROKEN_URL,
                        icon: Settings.CLOUD_BROKEN_ICON,
                        isPlaceholder: true,
                        label: "Not processed",
                        layout: ThumbnailLayout.Contain,
                        isImage,
                    }
                }
            }
            case DataObjectState.Completed: {
                const url = this.thumbnailUrl(dataObject)
                const icon = this.fileTypeIcon(dataObject)
                return {
                    url: url ?? Settings.IMAGE_INVALID_URL,
                    icon: icon ?? Settings.IMAGE_INVALID_ICON,
                    isPlaceholder: !(url || icon),
                    label: url || icon ? undefined : "Invalid thumbnail",
                    layout: this.$requestedLayout(),
                    isImage,
                }
            }
            case DataObjectState.UploadFinished: {
                return {
                    url: Settings.QUEUED_URL,
                    icon: Settings.QUEUED_ICON,
                    isPlaceholder: true,
                    label: "Uploaded",
                    layout: ThumbnailLayout.Contain,
                    isImage,
                }
            }
            default: {
                return defaultData
            }
        }
    }

    // if we have already fetched a completed thumbnail, we don't need to fetch it again when the tab is reactivated
    hasFetchedCompletedThumbnail = false
    ngOnInit() {
        combineLatest([this.dataObjectId$, this.resolution$])
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap(() => {
                    this.$data.set(defaultData)
                    this.hasFetchedCompletedThumbnail = false
                }),
                this.tabState.reEmitOnActivation(),
                filter(() => !this.hasFetchedCompletedThumbnail),
                refetchWithExponentialBackoff(
                    ([id, resolution]) => {
                        if (id) {
                            return this.dataObjectThumbnail.fetch({id, resolution})
                        } else {
                            return Promise.resolve(undefined)
                        }
                    },
                    (dataObject) => !this.thumbnailJobIsAbsent(dataObject) && this.thumbnailJobIsProcessing(dataObject),
                ),
            )
            .subscribe((dataObject) => {
                if (this.thumbnailJobIsAbsent(dataObject) || !this.thumbnailJobIsProcessing(dataObject)) {
                    this.hasFetchedCompletedThumbnail = true
                }
                const previousData = this.$data()
                const newData = this.getData(dataObject)
                if (
                    previousData.url !== newData.url ||
                    previousData.icon !== newData.icon ||
                    previousData.label !== newData.label ||
                    previousData.layout !== newData.layout ||
                    previousData.isImage !== newData.isImage
                ) {
                    this.$data.set(newData)
                    this.isLoading = true
                }
            })
    }

    isLoading = true

    protected onLoad(event: Event) {
        this.load.emit(event)
        this.isLoading = false
    }

    // TODO for certain image formats we currently don't generate thumbnails, treat these as non image data for now
    imageMimeTypeWithoutThumbnail = ["image/vnd.dwg", "image/svg+xml"]

    protected isImage = (dataObject: DataObjectThumbnailFragment) => {
        if (!dataObject.mediaType) throw new Error("Invalid data object, missing media type")
        return dataObject.mediaType.startsWith("image/") && !this.imageMimeTypeWithoutThumbnail.includes(dataObject.mediaType)
    }

    protected thumbnailJobIsAbsent = (dataObject: DataObjectThumbnailFragment | undefined) => {
        return !!dataObject && !dataObject.thumbnailProcessingJob
    }

    protected thumbnailJobIsProcessing = (dataObject: DataObjectThumbnailFragment | undefined) => {
        return jobIsActive(dataObject?.thumbnailProcessingJob ?? undefined)
    }

    protected thumbnailUrl = (dataObject: DataObjectThumbnailFragment) => {
        return dataObject.thumbnail?.downloadUrl
    }

    protected fileTypeIcon = (dataObject: DataObjectThumbnailFragment) => {
        switch (dataObject.mediaType) {
            case "application/pdf":
                return "fal fa-file-pdf"
            case "application/x-zip-compressed":
                return "fal fa-file-archive"
            case "text/plain":
                return "fal fa-file-alt"
            default:
                if (dataObject.mediaType?.startsWith("image/")) {
                    return "fal fa-file-image"
                } else if (dataObject.mediaType?.startsWith("video/")) {
                    return "fal fa-file-video"
                }
        }
        return "fal fa-file"
    }

    protected readonly DataObjectState = DataObjectState
}
