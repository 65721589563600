<div class="cm-folders-container">
    @if ($can().read.filters("organization")) {
        <mat-form-field class="cm-customer-input">
            <input
                type="text"
                matInput
                #customerAutocompleteInput
                [matAutocomplete]="customerAutocomplete"
                [formControl]="customerInputControl"
                placeholder="Select customer"
                (focusin)="customerAutocompleteInputFocusin()"
                (focusout)="customerAutocompleteInputFocusout()"
            />
            <mat-autocomplete
                #customerAutocomplete
                (optionSelected)="customerSelected($event); customerAutocompleteInput.blur()"
                [displayWith]="customerDisplayFunction"
                class="cm-customer-autocomplete"
                (opened)="customerAutocompleteOpened()"
                (closed)="customerAutocompleteClosed()"
            >
                @for (option of filteredOrganizations | async; track option.id) {
                    <mat-option [value]="option" class="cm-customer-auto-option">{{ option.name }}</mat-option>
                }
            </mat-autocomplete>
        </mat-form-field>
    }
    <cm-list-item (click)="addProject()">
        <ng-container cm-title>
            <span class="cm-add-folder">+ Add a project</span>
        </ng-container>
    </cm-list-item>
    <cdk-tree [dataSource]="dataSource ?? []" [treeControl]="treeControl">
        <!-- Set nodes -->
        <cdk-nested-tree-node *cdkTreeNodeDef="let set" class="project-tree-node">
            <cm-list-item
                (click)="onToggleExpanded(set, 'set')"
                [routerLink]="['./']"
                [selected]="this.$currentSetId() !== undefined && this.$currentSetId() === set.id"
                [queryParams]="{tab: 'projects', organizationId: $organization()?.id, setId: set?.id}"
            >
                <ng-container cm-pre-title>
                    <div class="cm-set-image-container">
                        <div class="cm-set-image-link">
                            <cm-data-object-thumbnail
                                class="cm-set-image"
                                [dataObjectId]="expandedNodes.has(set.project.id) ? set.galleryImage?.id : undefined"
                                [resolution]="DownloadResolution.Tiny"
                                [layout]="ThumbnailLayout.Cover"
                            ></cm-data-object-thumbnail>
                        </div>
                    </div>
                </ng-container>
                <ng-container cm-title> Set {{ set.number }}: {{ set.name }}</ng-container>
                <ng-container cm-icons>
                    @if ($can().update.set(set)) {
                        <i [matMenuTriggerFor]="setMenu" [matMenuTriggerData]="{node: set}" matTooltip="Edit" class="far fa-pen cm-action-icons"> </i>
                    }
                    <i (click)="deleteSet(set)" matTooltip="Delete" class="far fa-trash-alt cm-action-icons"> </i>
                </ng-container>
            </cm-list-item>
        </cdk-nested-tree-node>
        <!-- Project nodes -->
        <cdk-nested-tree-node *cdkTreeNodeDef="let project; when: hasChild" class="project-tree-node">
            <div [attr.aria-label]="'toggle ' + project.name">
                <cm-list-item
                    (click)="onToggleExpanded(project, 'project')"
                    [routerLink]="['./']"
                    [queryParams]="{tab: 'projects', organizationId: $organization()?.id, projectId: project?.id}"
                    [selected]="this.$currentSetId() === undefined && this.$currentProjectId() !== undefined && this.$currentProjectId() === project.id"
                >
                    <ng-container cm-title>
                        <i
                            class="far"
                            style="width: 1rem"
                            cdkTreeNodeToggle
                            [class.fa-angle-right]="!treeControl.isExpanded(project)"
                            [class.fa-angle-down]="treeControl.isExpanded(project)"
                        >
                        </i>
                        <span>{{ project.number }}:</span> {{ project.name }}
                    </ng-container>
                    <ng-container cm-icons>
                        @if ($can().update.project(project)) {
                            <i (click)="updateProjectName(project)" matTooltip="Edit" class="far fa-pen cm-action-icons"> </i>
                        }
                        <i (click)="deleteProject(project)" matTooltip="Delete" class="far fa-trash-alt cm-action-icons"></i>
                    </ng-container>
                </cm-list-item>
            </div>
            <div [class.project-tree-invisible]="!treeControl.isExpanded(project)">
                <ng-container cdkTreeNodeOutlet></ng-container>
                <cm-list-item (click)="addSet(project.id)">
                    <ng-container cm-title>
                        <span class="cm-add-folder cm-add-subfolder">+ Add a set</span>
                    </ng-container>
                </cm-list-item>
            </div>
        </cdk-nested-tree-node>
    </cdk-tree>
    <mat-menu #setMenu="matMenu">
        <ng-template matMenuContent let-node="node">
            @if ($can().update.picture(null, "priority")) {
                <button [matMenuTriggerData]="{node: node}" [matMenuTriggerFor]="priority" mat-menu-item>
                    <span>Priority</span>
                </button>
            }
            <button mat-menu-item (click)="updateSetName(node)">
                <span>Rename</span>
            </button>
        </ng-template>
    </mat-menu>
    <mat-menu #priority="matMenu">
        <ng-template matMenuContent let-node="node">
            <button mat-menu-item (click)="setHighestPriority(node)">
                <span>First priority</span>
            </button>
        </ng-template>
    </mat-menu>
</div>
