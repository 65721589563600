import {Observable, of as observableOf, shareReplay, Subject} from "rxjs"
import {queueDeferredTask} from "@cm/browser-utils"

export class RequestBatcher<K, V extends {id: K}> {
    private batchedKeys: K[] = []
    readonly pending = new Map<K, [Subject<V>, Observable<V>]>()
    private didQueueTask = false
    public enabled = true

    constructor(
        private getSingle: (key: K) => Observable<V>,
        private getBatch: (keys: K[]) => Observable<V[]>,
    ) {}

    request(key: K): Observable<V> {
        if (this.enabled) {
            const entry = this.pending.get(key)
            if (entry) {
                return entry[1]
            }
            const pendingSubject = new Subject<V>()
            const pendingObservable = pendingSubject.pipe(shareReplay(1))
            this.pending.set(key, [pendingSubject, pendingObservable])
            this.batchedKeys.push(key)
            if (!this.didQueueTask) {
                this.didQueueTask = true
                queueDeferredTask(() => {
                    const keys = this.batchedKeys
                    this.didQueueTask = false
                    this.batchedKeys = []
                    this.getBatch(keys).subscribe((values) => {
                        //NOTE: the order of values does not match the keys! Need to find the matching objects in the array:
                        for (const key of keys) {
                            const value = values.find((x) => x.id === key)
                            const entry = this.pending.get(key)
                            if (entry) {
                                this.pending.delete(key)
                                entry[0].next(value!)
                                entry[0].complete()
                            }
                        }
                    })
                })
            }
            return pendingObservable
        } else {
            return this.getSingle(key)
        }
    }
}

export class AsyncCache<K, V> {
    readonly cached = new Map<K, V>()
    readonly pending = new Map<K, Observable<V>>()
    public enabled = true

    constructor() {}

    set(key: K, value: V): void {
        if (!this.enabled) return
        this.cached.set(key, value)
    }

    getOrFetch(key: K, getFn: () => Observable<V>): Observable<V> {
        if (key === undefined || key === null) return observableOf(undefined as V)
        if (!this.enabled) return getFn()
        const cached = this.cached.get(key)
        if (cached) {
            return observableOf(cached)
        }
        let pending = this.pending.get(key)
        if (!pending) {
            const pendingSubject = new Subject<V>()
            pending = pendingSubject.pipe(shareReplay(1))
            pending.subscribe()
            this.pending.set(key, pending)
            getFn().subscribe(
                (value) => {
                    this.pending.delete(key)
                    const existing = this.cached.get(key)
                    if (existing) {
                        //console.warn("Cache value was set while request was pending. this/existing:", value, existing);
                        value = existing
                    } else {
                        this.cached.set(key, value)
                    }
                    pendingSubject.next(value)
                    pendingSubject.complete()
                },
                (err: unknown) => {
                    console.error("Cache get failed", err)
                },
            )
        }
        return pending
    }

    getSync(key: K) {
        if (!this.enabled) return undefined
        return this.cached.get(key)
    }

    has(key: K) {
        return this.cached.has(key)
    }

    clear() {
        this.cached.clear()
        //TODO: cancel pending requests?
        this.pending.clear()
    }

    delete(key: K): void {
        this.pending.delete(key)
        this.cached.delete(key)
    }
}

export class AsyncCacheMap<K, V, Extra = void> extends AsyncCache<K, V> {
    constructor(private getFn: (key: K, extra: Extra) => Observable<V>) {
        super()
    }

    get(key: K, extra?: Extra) {
        return super.getOrFetch(key, () => this.getFn(key, extra!))
    }
}
