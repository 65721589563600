import {BreakpointObserver} from "@angular/cdk/layout"
import {inject, Injectable, signal} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"

@Injectable({
    providedIn: "root",
})
export class BreakpointsService {
    readonly breakpointObserver = inject(BreakpointObserver)

    $isMobileSmall = signal(false)
    $isMobile = signal(false)
    $isMobileLandscape = signal(false)
    $isTablet = signal(false)
    $isDesktop = signal(false)

    constructor() {
        this.breakpointObserver
            .observe(["(min-width: 320px)", "(min-width: 478px)", "(min-width: 767px)", "(min-width: 992px)", "(min-width: 1200px)"])
            .pipe(takeUntilDestroyed())
            .subscribe((result) => {
                this.$isMobileSmall.set(result.breakpoints["(min-width: 320px)"])
                this.$isMobile.set(result.breakpoints["(min-width: 478px)"])
                this.$isMobileLandscape.set(result.breakpoints["(min-width: 767px)"])
                this.$isTablet.set(result.breakpoints["(min-width: 992px)"])
                this.$isDesktop.set(result.breakpoints["(min-width: 1200px)"])
            })
    }
}
