import {UpperCasePipe} from "@angular/common"
import {Component, input} from "@angular/core"
import {ExportSummary, MaterialMapsExportQueryResult} from "@app/common/services/material-maps-exporter/material-maps-exporter.service"
import {MaterialMapsExporter} from "@cm/material-nodes/material-maps-exporter"

@Component({
    selector: "cm-material-output-pbr-export-detail",
    imports: [UpperCasePipe],
    templateUrl: "./material-output-pbr-export-detail.component.html",
    styleUrl: "./material-output-pbr-export-detail.component.scss",
})
export class MaterialOutputPbrExportDetailComponent {
    readonly $exportSummary = input.required<ExportSummary>({alias: "exportSummary"})
    readonly $sourceInfo = input.required<string>({alias: "sourceInfo"})

    mapWorkflows(workflows: MaterialMapsExportQueryResult["summary"]["workflow"]) {
        return workflows.map((x) => MaterialMapsExporter.workflowToString(x))
    }

    mapEngines(engines: MaterialMapsExportQueryResult["summary"]["engine"]) {
        return engines.map((x) => MaterialMapsExporter.engineToString(x))
    }

    mapNormalYs(normalY: MaterialMapsExportQueryResult["summary"]["normalY"]) {
        return normalY.map((x) => MaterialMapsExporter.normalYToString(x))
    }

    mapResolutions(resolutions: MaterialMapsExportQueryResult["summary"]["resolution"]) {
        return resolutions.map((x) => MaterialMapsExporter.resolutionToString(x))
    }
}
