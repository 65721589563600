<div class="cm-content">
    <cm-value-slot [node]="$node()" [key]="'parent'" [label]="'Attach to'"></cm-value-slot>
    <cm-value-slot [node]="$node()" [key]="'offset'" [label]="'Arrow offset'"></cm-value-slot>
    <cm-value-slot [node]="$node()" [key]="'unitForDisplay'" [label]="'Unit'" [selectionPossibilities]="$unitOptions()"></cm-value-slot>
    <cm-value-slot [node]="$node()" [key]="'guides'" [subKey]="[0, 'show']" [label]="'Show X-guide'"></cm-value-slot>
    <cm-value-slot
        [node]="$node()"
        [key]="'guides'"
        [subKey]="[0, 'boundsEdge']"
        [label]="'X-edge'"
        (onChanged)="changedEdge('x')"
        [selectionPossibilities]="$edgeOptions()"
    ></cm-value-slot>
    <cm-value-slot [node]="$node()" [key]="'guides'" [subKey]="[1, 'show']" [label]="'Show Y-guide'"></cm-value-slot>
    <cm-value-slot
        [node]="$node()"
        [key]="'guides'"
        [subKey]="[1, 'boundsEdge']"
        [label]="'Y-edge'"
        (onChanged)="changedEdge('y')"
        [selectionPossibilities]="$edgeOptions()"
    ></cm-value-slot>
    <cm-value-slot [node]="$node()" [key]="'guides'" [subKey]="[2, 'show']" [label]="'Show Z-guide'"></cm-value-slot>
    <cm-value-slot
        [node]="$node()"
        [key]="'guides'"
        [subKey]="[2, 'boundsEdge']"
        [label]="'Z-edge'"
        (onChanged)="changedEdge('z')"
        [selectionPossibilities]="$edgeOptions()"
    ></cm-value-slot>
</div>
