import {DrawArgs, HalPaintable} from "@common/models/hal/hal-paintable"
import {ColorLike} from "@cm/math"
import {WebGl2Context} from "@common/models/webgl2/webgl2-context"

export function clearPaintable(paintable: HalPaintable, color?: ColorLike, mipLevel?: number) {
    mipLevel ??= 0
    const context = paintable.context
    if (!(context instanceof WebGl2Context)) {
        throw Error("Invalid context")
    }
    const drawArgs: DrawArgs = {
        region: {x: 0, y: 0, width: paintable.width, height: paintable.height},
        mipLevel: mipLevel,
        hints: {fullWrite: true},
    }
    const numPasses = paintable.beginDraw(drawArgs)
    const gl = context.gl
    gl.clearColor(color?.r ?? 0, color?.g ?? 0, color?.b ?? 0, color?.a ?? 0)
    for (let pass = 0; pass < numPasses; pass++) {
        paintable.beginDrawPass(drawArgs, pass)
        gl.clear(gl.COLOR_BUFFER_BIT)
        paintable.endDrawPass(drawArgs, pass)
    }
    paintable.endDraw(drawArgs)
}
