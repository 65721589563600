import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type NavigationBarOrganizationFragment = { __typename: 'Organization', id: string, name?: string | null, type?: Types.OrganizationType | null };

export type NavigationBarAllOrganizationsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OrganizationFilterInput>;
}>;


export type NavigationBarAllOrganizationsQuery = { __typename: 'Query', organizations: Array<{ __typename: 'Organization', id: string, name?: string | null, type?: Types.OrganizationType | null } | null> };

export type NavigationBarUserMembershipsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type NavigationBarUserMembershipsQuery = { __typename: 'Query', user: { __typename: 'User', memberships: Array<{ __typename: 'Membership', organization: { __typename: 'Organization', id: string, name?: string | null, type?: Types.OrganizationType | null } }> } };

export type NavigationBarColormassOrganizationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type NavigationBarColormassOrganizationQuery = { __typename: 'Query', organizations: Array<{ __typename: 'Organization', id: string, name?: string | null, type?: Types.OrganizationType | null } | null> };

export const NavigationBarOrganizationFragmentDoc = gql`
    fragment NavigationBarOrganization on Organization {
  id
  name
  type
}
    `;
export const NavigationBarAllOrganizationsDocument = gql`
    query navigationBarAllOrganizations($filter: OrganizationFilterInput) {
  organizations(filter: $filter, take: 9999) {
    ...NavigationBarOrganization
  }
}
    ${NavigationBarOrganizationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NavigationBarAllOrganizationsGQL extends Apollo.Query<NavigationBarAllOrganizationsQuery, NavigationBarAllOrganizationsQueryVariables> {
    override document = NavigationBarAllOrganizationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NavigationBarUserMembershipsDocument = gql`
    query navigationBarUserMemberships($id: ID!) {
  user(id: $id) {
    memberships {
      organization {
        ...NavigationBarOrganization
      }
    }
  }
}
    ${NavigationBarOrganizationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NavigationBarUserMembershipsGQL extends Apollo.Query<NavigationBarUserMembershipsQuery, NavigationBarUserMembershipsQueryVariables> {
    override document = NavigationBarUserMembershipsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NavigationBarColormassOrganizationDocument = gql`
    query navigationBarColormassOrganization {
  organizations(filter: {url: {equals: "colormass"}}) {
    ...NavigationBarOrganization
  }
}
    ${NavigationBarOrganizationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NavigationBarColormassOrganizationGQL extends Apollo.Query<NavigationBarColormassOrganizationQuery, NavigationBarColormassOrganizationQueryVariables> {
    override document = NavigationBarColormassOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }