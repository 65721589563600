import {DataObject, ImageResource, IMaterialNodeGraphTransientDataObject as ITransientDataObjectOrg} from "#material-nodes/material-node-graph"

export type IDataObject = DataObject & {
    related: DataObject[]
}

export function isIDataObject(x: IDataObject | ITransientDataObject): x is IDataObject {
    return x.hasOwnProperty("legacyId")
}

export interface ITransientDataObject extends ITransientDataObjectOrg {
    data: Uint8Array
    mediaType: string
}

export function dataObjectToImageRessource(dataObject: IDataObject | ITransientDataObject): ImageResource {
    if (isIDataObject(dataObject)) {
        const {related, ...rest} = dataObject
        return {mainDataObject: rest, relatedDataObjects: related}
    } else return {transientDataObject: dataObject}
}
