import {Component, viewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {CombineHsvInputs, CombineHsvOutputs} from "@material-editor/models/nodes/combine-hsv"

@Component({
    selector: "cm-combine-hsv-node",
    templateUrl: "./combine-hsv-node.component.html",
    styleUrls: ["./combine-hsv-node.component.scss"],
    imports: [NodeBaseComponent],
})
export class CombineHsvNodeComponent {
    readonly $nodeBase = viewChild<MaterialNodeBase>("nodeBase")
    outputs = CombineHsvInputs
    inputs = CombineHsvOutputs
    typeInfo = CombineHsvNodeType
}

export const CombineHsvNodeType: MaterialNodeType<typeof CombineHsvNodeComponent> = {
    id: "combineHsv",
    label: "Combine HSV",
    color: "#4987af",
    name: "ShaderNodeCombineHSV",
    inputs: [CombineHsvInputs.h, CombineHsvInputs.s, CombineHsvInputs.v],
    outputs: [CombineHsvOutputs.color],
    component: CombineHsvNodeComponent,
}
