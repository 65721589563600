import {HttpClient, HttpEvent, HttpEventType} from "@angular/common/http"
import {DestroyRef, inject, Injectable} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {BackgroundOperationState} from "@app/common/models/background-operation"
import {BackgroundOperationService} from "@app/platform/services/background-operation/background-operation.service"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateIgnoringErrors, mutateThrowingErrors} from "@common/helpers/api/mutate"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {UploadProcessingService} from "@common/services/upload/upload-processing.service"
import {
    DataObjectGQL,
    UploadServiceCreateDataObjectGQL,
    UploadServiceDataObjectFragment,
    UploadServiceDeleteDataObjectGQL,
    UploadServiceGetDataObjectGQL,
} from "@common/services/upload/upload.generated"
import {environment} from "@environment"
import {DataObjectState, DataObjectType, MutationCreateDataObjectInput} from "@generated"
import {MimeType, UtilsService} from "@legacy/helpers/utils"
import {catchError, filter, firstValueFrom, last, lastValueFrom, map, Observable, of, Subject, tap} from "rxjs"

/**
 * GQL-based service for uploading files and managing related data objects.
 * Prefer using/extending this service over the legacy `UploadService` if possible.
 */
@Injectable({
    providedIn: "root",
})
export class UploadGqlService {
    readonly notifications = inject(NotificationsService)
    readonly organization = inject(OrganizationsService)

    readonly uploadServiceGetDataObject = inject(UploadServiceGetDataObjectGQL)
    readonly uploadServiceCreateDataObject = inject(UploadServiceCreateDataObjectGQL)
    readonly uploadServiceDeleteDataObject = inject(UploadServiceDeleteDataObjectGQL)
    public readonly getDataObject = inject(DataObjectGQL)

    constructor(
        private backgroundOperationService: BackgroundOperationService,
        private httpClient: HttpClient,
        private utils: UtilsService,
        private uploadProcessingService: UploadProcessingService,
    ) {}

    /**
     * Creates a new data object and uploads the given file to the signed upload URL.
     * May throw an error if unsuccessful.
     */
    public async createAndUploadDataObject(
        file: File,
        input: Omit<MutationCreateDataObjectInput, "originalFileName"> & ({organizationId: string} | {organizationLegacyId: number}),
        options?: {
            showUploadToolbar?: boolean
            processUpload?: boolean
        },
    ): Promise<UploadServiceDataObjectFragment & {thumbnailProcessingJobId?: string}> {
        const getMediaTypeFromFileName = (fileName: string) => {
            const lowerCase = fileName.toLowerCase()
            if (lowerCase.endsWith(".exr")) return "image/x-exr"
            if (lowerCase.endsWith(".ttf")) return "font/ttf"
            return "application/octet-stream"
        }
        const mediaType = input.mediaType ?? (file.type || getMediaTypeFromFileName(file.name))
        const {createDataObject: createdDataObject} = await mutateThrowingErrors(this.uploadServiceCreateDataObject)({
            input: {
                mediaType,
                size: file.size,
                ...input,
                originalFileName: file.name,
            },
        })
        if (!createdDataObject?.signedUploadUrl) {
            throw new Error("Failed to create data object")
        }
        const {success, error} = await this.uploadFileToUrl(file, createdDataObject.signedUploadUrl, options?.showUploadToolbar ?? true, mediaType)
        if (success) {
            const {dataObject} = await fetchThrowingErrors(this.uploadServiceGetDataObject)({
                id: createdDataObject.id,
            })
            if ((options?.processUpload ?? false) && !!dataObject?.legacyId) {
                const job = await this.uploadProcessingService.createUploadProcessingJob(dataObject.legacyId, input.organizationId ?? undefined)
                return {...dataObject, thumbnailProcessingJobId: job.id}
            }
            return dataObject
        } else {
            await mutateIgnoringErrors(this.uploadServiceDeleteDataObject)({id: createdDataObject.id})
            throw error
        }
    }

    /**
     * Uploads a file to a remote url, e.g. a signed upload URL from Google Cloud Storage.
     * This method does not depend on GCS, nor on Django/API models.
     *
     * TODO: move this into an SDK service
     */
    public uploadFileToUrl(
        file: File,
        url: string,
        showUploadToolbar = true,
        mediaType = "application/octet-stream",
        allowCache = true,
    ): Promise<{
        success: boolean
        error?: Error
    }> {
        // TODO: support cancellation (!)
        const uploadToolbarItem = showUploadToolbar ? this.backgroundOperationService.addBackgroundOperationToList("Upload", file.name, false) : undefined

        // we need PUT for dev-local storage, POST for GCS
        const uploadRequest = environment.storage?.usePostToUpload
            ? this.httpClient.post(url, file, {observe: "events", reportProgress: true})
            : this.httpClient.put(url, file, {
                  observe: "events",
                  reportProgress: true,
                  headers: {"Content-Type": mediaType, ...(allowCache ? {} : {"Cache-Control": "no-store"})},
              })
        return lastValueFrom(
            uploadRequest.pipe(
                // extract the current state & progress
                map((event: HttpEvent<unknown>) => {
                    switch (event.type) {
                        case HttpEventType.Sent: {
                            return {state: BackgroundOperationState.InProgress, progress: 0}
                        }
                        case HttpEventType.DownloadProgress:
                        case HttpEventType.UploadProgress: {
                            const progress = Math.round((event.loaded / (event.total || 1)) * 100)
                            return {state: BackgroundOperationState.InProgress, progress}
                        }
                        default: {
                            return {state: BackgroundOperationState.Completed, progress: 100}
                        }
                    }
                }),
                // show current state in upload bar
                tap(({state}) => {
                    if (uploadToolbarItem) {
                        uploadToolbarItem.state = state
                    }
                }),
                // report progress
                tap(({progress}) => uploadToolbarItem?.progressSubject?.next(progress)),
                last(),
                // the observable has completed, so we should hide the progress bar
                tap(() => {
                    uploadToolbarItem?.progressSubject?.complete()
                }),
                map(() => ({success: true})),
                catchError((error) => {
                    console.error("Cannot upload file.", error)
                    this.notifications.showError("Cannot upload file")
                    return of({success: false, error})
                }),
            ),
        )
    }

    /**
     * Initializes a drop zone and uploads the files automatically.
     * It makes sure to remove the handler before initializing them, so it can be called multiple times if necessary.
     * @param dropZone The HTML element which receives the drag/drop etc. events.
     * @param organizationIdGetter Getter function for the customerId to which the file will belong.
     * @param maxFiles Maximum number of files allowed.
     * @param dropZoneActive An object containing a boolean property which is going to be set to true or false depending on the drag events.
     * @param mimeTypeFilter Defines which files are accepted. Everything is accepted by default.
     * @param dataObjectType The type of the data object or undefined if not known.
     * @returns {Observable<DataObject>}
     */
    initDropZone(
        dropZone: EventTarget,
        dropZoneActive: {[value: string]: boolean} = {},
        organizationIdGetter: () => string | undefined,
        maxFiles = 100,
        mimeTypeFilter: MimeType = MimeType.All,
        dataObjectType: DataObjectType | undefined = undefined,
    ): Observable<UploadServiceDataObjectFragment> {
        const uploadedDataObject = new Subject<UploadServiceDataObjectFragment>()
        const uploadedDataObject$ = uploadedDataObject.asObservable()

        this.utils.initDropZoneHelper(dropZone, dropZoneActive, maxFiles, mimeTypeFilter).subscribe(async (droppedFile: File) => {
            const organizationId = organizationIdGetter()
            if (!organizationId) {
                throw new Error("Organization ID is not defined")
            }
            const dataObject = await this.createAndUploadDataObject(
                droppedFile,
                {organizationId, type: dataObjectType},
                {processUpload: true, showUploadToolbar: true},
            )
            if (dataObject) {
                uploadedDataObject.next(dataObject)
            }
        })
        return uploadedDataObject$
    }

    public waitForUploadProcessing(dataObjectId: string, destroyRef?: DestroyRef) {
        return firstValueFrom(
            this.getDataObject.watch({id: dataObjectId}, {pollInterval: 1000}).valueChanges.pipe(
                takeUntilDestroyed(destroyRef),
                map(({data: subscriptionData}) => subscriptionData?.dataObject),
                filter((dataObject) => dataObject?.state === DataObjectState.Completed || dataObject?.state === DataObjectState.ProcessingFailed),
                map((dataObject) => dataObject!.id),
            ),
            {defaultValue: null},
        )
    }
}
