import {CommonModule} from "@angular/common"
import {Component, DestroyRef, inject, Input, OnDestroy, signal, WritableSignal, input, model, output} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ActivatedRoute, Router, RouterModule} from "@angular/router"
import {ButtonComponent} from "@app/common/components/buttons/button/button.component"
import {HintComponent, HintTypes} from "@app/common/components/hint/hint.component"
import {NumericInputComponent} from "@app/common/components/inputs/numeric-input/numeric-input.component"
import {NgVar} from "@app/common/directives"
import {AuthService} from "@app/common/services/auth/auth.service"
import {RefreshService} from "@app/common/services/refresh/refresh.service"
import {UploadGqlService} from "@app/common/services/upload/upload.gql.service"
import {
    QueryTextureSetRevisionViewDataGQL,
    QueryTextureSetViewDataGQL,
    TextureSetRevisionViewCreateTextureSetRevisionGQL,
    TextureSetRevisionViewTextureSetFragment,
} from "@app/textures/texture-set-revision-view/texture-set-revision-view.generated"
import {
    UploadTextureSettingDialogComponent,
    UploadTextureSettingDialogResult,
} from "@app/textures/texture-set-revision-view/upload-texture-settings-dialog/upload-texture-settings-dialog.component"
import {imageDataTypeFromTextureType} from "@app/textures/utils/color-space"
import {descriptorByTextureType, textureTypes} from "@app/textures/utils/texture-type-descriptor"
import {Size2Like} from "@cm/math"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {StringInputComponent} from "@common/components/inputs/string-input/string-input.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {UploadServiceDataObjectFragment} from "@common/services/upload/upload.generated"
import {ContentTypeModel, DataObjectType, TextureType} from "@generated"
import {MimeType, UtilsService} from "@legacy/helpers/utils"
import {TextureThumbnailViewComponent} from "app/textures/texture-set-revision-view/texture-thumbnail-view/texture-thumbnail-view.component"
import {filter, firstValueFrom} from "rxjs"

@Component({
    selector: "cm-texture-set-revision-view",
    templateUrl: "./texture-set-revision-view.component.html",
    styleUrl: "./texture-set-revision-view.component.scss",
    imports: [
        CommonModule,
        MatTooltipModule,
        MatMenuModule,
        RouterModule,
        NgVar,
        TextureThumbnailViewComponent,
        HintComponent,
        ButtonComponent,
        NumericInputComponent,
        StringInputComponent,
        InputContainerComponent,
    ],
})
export class TextureSetRevisionViewComponent implements OnDestroy {
    readonly $textureSetId = input<string>(undefined, {alias: "textureSetId"})

    @Input() set textureSetRevisionId(value: string | undefined) {
        void this.loadTextureSetRevision(value)
    }

    readonly $showEmptyTextures = model<boolean | undefined>(undefined, {alias: "showEmptyTextures"})
    readonly $canSelectRevisionId = input<boolean>(true, {alias: "canSelectRevisionId"})
    readonly showEmptyTexturesChange = output<boolean>()

    readonly queryTextureSetViewData = inject(QueryTextureSetViewDataGQL)
    readonly queryTextureSetRevisionViewData = inject(QueryTextureSetRevisionViewDataGQL)
    readonly createTextureSetRevisionGql = inject(TextureSetRevisionViewCreateTextureSetRevisionGQL)

    constructor(
        private destroyRef: DestroyRef,
        private router: Router,
        private route: ActivatedRoute,
        protected authService: AuthService,
        private utils: UtilsService,
        private refreshService: RefreshService,
        private uploadService: UploadGqlService,
        private notificationService: NotificationsService,
        private dialog: MatDialog,
    ) {
        this.refreshService.itemSubject
            .pipe(
                filter(({id}) => id === this._textureSetRevisionId),
                takeUntilDestroyed(),
            )
            .subscribe(({id}) => this.loadTextureSetRevision(id))
    }

    ngOnDestroy() {
        this.cleanUpAndReset(this.data)
        this.data = undefined
    }

    get textureSetRevisionId(): string | undefined {
        return this._textureSetRevisionId
    }

    private async loadTextureSetRevision(textureSetRevisionId: string | undefined) {
        this.cleanUpAndReset(this.data)
        this.data = undefined
        const textureSetId = this.$textureSetId()
        if (!textureSetId) {
            throw new Error("Texture set ID is not set")
        }
        this._textureSetRevisionId = textureSetRevisionId

        const textureSet = await fetchThrowingErrors(this.queryTextureSetViewData)({id: textureSetId}).then((result) => result.textureSet)

        let textureSetRevisionInfo: TextureSetRevisionInfo
        const isExistingTextureSetRevision = textureSetRevisionId !== undefined
        if (isExistingTextureSetRevision) {
            const result = await fetchThrowingErrors(this.queryTextureSetRevisionViewData)({id: textureSetRevisionId})
            if (result.textureSetRevision.textureSet.id !== textureSetId) {
                throw new Error("Texture set revision does not belong to the texture set")
            }
            const dataObjectByTextureType = new Map(
                result.textureSetRevision.mapAssignments.map((mapAssignment) => [mapAssignment.textureType, mapAssignment.dataObject]),
            )
            this._originalDataObjectByTextureType = new Map(
                Array.from(dataObjectByTextureType.entries()).map(([textureType, dataObject]) => [textureType, {id: dataObject.id}]),
            )
            this._originalName = result.textureSetRevision.name ?? ""
            textureSetRevisionInfo = {
                width: result.textureSetRevision.width,
                height: result.textureSetRevision.height,
                displacement: result.textureSetRevision.displacement ?? undefined,
            }
        } else {
            textureSetRevisionInfo = {
                width: 30,
                height: 30,
                displacement: undefined,
            }
        }

        this.data = {
            textureSet,
            dataObjectByTextureType: new Map(this._originalDataObjectByTextureType),
            name: this._originalName,
            changed: false,
            textureSetRevisionInfo,
            uploadedDataObjectByTextureType: new Map(),
            pendingUploads: new Map(),
            newWidthEntered: isExistingTextureSetRevision,
            newHeightEntered: isExistingTextureSetRevision,
        }
    }

    protected get allowTextureClick(): boolean {
        return this.data != null && !this.data.changed // don't allow clicks if there are unsaved changes
    }

    protected set shouldShowEmptyTextures(value: boolean) {
        this.$showEmptyTextures.set(value)
        this.showEmptyTexturesChange.emit(value)
    }

    protected get shouldShowEmptyTextures(): boolean {
        const showEmptyTextures = this.$showEmptyTextures()
        if (showEmptyTextures !== undefined) {
            return showEmptyTextures
        } else {
            return this.data?.dataObjectByTextureType.size === 0
        }
    }

    protected isUploading(textureType: TextureType): boolean {
        return this.data?.pendingUploads.has(textureType) ?? false
    }

    protected getDataObjectId(textureType: TextureType): string | undefined {
        return this.data?.dataObjectByTextureType.get(textureType)?.id
    }

    protected onTextureDropped(textureType: TextureType, file: File) {
        if (!this.data) {
            return
        }
        this.uploadNewTextureRevision(this.data, textureType, file)
    }

    protected onTextureClicked(_textureType: TextureType) {
        if (this.data?.changed) {
            // we're in uploading mode; let's choose a file for upload
        }
    }

    protected onThumbnailAvailable(textureType: TextureType, dataObject: {id: string; width?: number | null; height?: number | null}) {
        const mapsToCheck = [this._originalDataObjectByTextureType, this.data?.dataObjectByTextureType, this.data?.uploadedDataObjectByTextureType]
        let dataObjectSizeUpdated = false
        mapsToCheck.forEach((map) => {
            const dataObjectInfo = map?.get(textureType)
            if (dataObjectInfo && dataObjectInfo.id === dataObject.id) {
                dataObjectInfo.width = dataObject.width ?? undefined
                dataObjectInfo.height = dataObject.height ?? undefined
                dataObjectSizeUpdated = true
            }
        })
        if (dataObjectSizeUpdated) {
            this.updateStatus()
        }
    }

    private updateStatus() {
        if (!this.data) {
            return
        }
        if (this.data.pendingUploads.size === 0 && this.data.uploadedDataObjectByTextureType.size === 0) {
            this.$status.set("viewing")
            return
        }
        if (this.data.pendingUploads.size > 0) {
            this.$status.set("processing")
            return
        }
        let firstSize: Size2Like | undefined = undefined
        for (const info of this.data.dataObjectByTextureType.values()) {
            if (info.width == null || info.height == null) {
                this.$status.set("processing")
                return
            }
            if (!firstSize) {
                firstSize = {width: info.width, height: info.height}
            } else if (firstSize.width !== info.width || firstSize.height !== info.height) {
                this.$status.set("inconsistent-sizes")
                return
            }
        }
        this.$status.set("valid")
    }

    protected get needsSave(): boolean {
        return this.data?.changed === true
    }

    protected get textureSetRevisionWidth() {
        return this.data?.newWidthEntered ? this.data?.textureSetRevisionInfo.width : undefined
    }

    protected set textureSetRevisionWidth(value: number | undefined) {
        if (!this.data) {
            throw new Error("Data is not set")
        }
        if (value != null && value > 0) {
            this.data.textureSetRevisionInfo.width = value
            this.data.newWidthEntered = true
        } else {
            this.data.newWidthEntered = false
        }
    }

    protected get textureSetRevisionHeight() {
        return this.data?.newHeightEntered ? this.data?.textureSetRevisionInfo.height : undefined
    }

    protected set textureSetRevisionHeight(value: number | undefined) {
        if (!this.data) {
            throw new Error("Data is not set")
        }
        if (value != null && value > 0) {
            this.data.textureSetRevisionInfo.height = value
            this.data.newHeightEntered = true
        } else {
            this.data.newHeightEntered = false
        }
    }

    protected get physicalDimensionsSet() {
        if (!this.data) {
            return false
        }
        return this.data.newWidthEntered && this.data.newHeightEntered
    }

    protected get canSave(): boolean {
        if (!this.data) {
            return false
        }
        return this.data.changed && this.data.dataObjectByTextureType?.size > 0 && this.$status() === "valid" && this.physicalDimensionsSet
    }

    protected onRevert() {
        this.cleanUpAndReset(this.data)
    }

    protected onSave() {
        this.save(false)
    }

    protected onSaveAndEdit() {
        this.save(true)
    }

    protected async save(navigateToEditor: boolean) {
        const data = this.data
        if (!data) {
            return
        }
        const textureTypeAndDataObjects = Array.from(data.dataObjectByTextureType.entries())
        if (textureTypeAndDataObjects.length === 0) {
            return
        }
        if (!data.textureSetRevisionInfo) {
            throw new Error("Texture set revision info is missing")
        }
        // correct physical width and height to match the image aspect ratio
        const dataObjectInfo = textureTypeAndDataObjects[0][1]
        if (!dataObjectInfo.width || !dataObjectInfo.height) {
            throw new Error("Data object width or height is missing")
        }
        const imageAspectRatio = dataObjectInfo.width / dataObjectInfo.height
        const physicalAspectRatio = data.textureSetRevisionInfo.width / data.textureSetRevisionInfo.height
        // inform user about correction if ratios don't match
        if (Math.abs(imageAspectRatio - physicalAspectRatio) > 0.0001) {
            this.notificationService.showInfo(
                `Physical width and height don't match the image aspect ratio. They will be corrected to match the image aspect ratio.`,
            )
        }
        // correct physical width and height equally to match the image aspect ratio
        const aspectCorrectionFactor =
            (Math.sqrt(imageAspectRatio) * Math.sqrt(data.textureSetRevisionInfo.height)) / Math.sqrt(data.textureSetRevisionInfo.width)
        data.textureSetRevisionInfo.width = data.textureSetRevisionInfo.width * aspectCorrectionFactor
        data.textureSetRevisionInfo.height = data.textureSetRevisionInfo.height / aspectCorrectionFactor
        // create new texture set revision
        const textureSetRevisionId = await mutateThrowingErrors(this.createTextureSetRevisionGql)({
            input: {
                width: data.textureSetRevisionInfo.width,
                height: data.textureSetRevisionInfo.height,
                displacement: data.textureSetRevisionInfo.displacement,
                textureSetId: data.textureSet.id,
                mapAssignments: textureTypeAndDataObjects.map(([textureType, dataObject]) => ({
                    textureType,
                    dataObjectId: dataObject.id,
                })),
            },
        }).then((result) => result.createTextureSetRevision.id)
        data.uploadedDataObjectByTextureType.clear() // clear uploaded textures list to prevent them from being cleaned up
        data.changed = false

        if (navigateToEditor) {
            this.navigateToRevision(textureSetRevisionId, undefined)
        }

        // broadcast refresh event
        this.refreshService.item({id: data.textureSet.id, __typename: ContentTypeModel.TextureSet})
    }

    private navigateToRevision(textureSetRevisionId: string | undefined, textureType: TextureType | undefined) {
        if (!this.data) {
            return
        }
        this.router.navigate(["set-revisions", textureSetRevisionId], {
            relativeTo: this.route,
            queryParams: {
                textureType: textureType,
            },
            queryParamsHandling: "merge",
        })
    }

    private async uploadNewTextureRevision(data: Data, textureType: TextureType, file: File): Promise<void> {
        // show dialog to select texture properties
        const dialogRef: MatDialogRef<UploadTextureSettingDialogComponent, UploadTextureSettingDialogResult | undefined> = this.dialog.open(
            UploadTextureSettingDialogComponent,
            {
                width: "350px",
                data: {
                    showDisplacementSetting: textureType === TextureType.Displacement,
                    displacement: textureType === TextureType.Displacement ? data.textureSetRevisionInfo?.displacement : undefined,
                },
            },
        )
        const result = await firstValueFrom(dialogRef.afterClosed())
        if (!result) {
            return
        }

        // if this is the first texture and we automatically show empty textures when there are none, switch to showing empty textures explicitly such that the view doesn't suddenly hides them
        if (data.dataObjectByTextureType.size === 0 && this.$showEmptyTextures() === undefined) {
            this.$showEmptyTextures.set(true)
            this.showEmptyTexturesChange.emit(true)
        }

        try {
            if (!data.changed) {
                data.name = this._originalName ? this._originalName + " (modified)" : "Uploaded revision"
                data.changed = true
            }

            // set new displacement value if given
            if (result.displacement !== undefined) {
                data.textureSetRevisionInfo!.displacement = result.displacement
            }

            // remove previously uploaded texture revision
            const previouslyUploadedTextureRevision = data.uploadedDataObjectByTextureType.get(textureType)
            if (previouslyUploadedTextureRevision) {
                this.revertUploadedDataObject(previouslyUploadedTextureRevision)
                data.uploadedDataObjectByTextureType.delete(textureType)
            }
            data.dataObjectByTextureType.delete(textureType)

            // upload data object
            // TODO allow cancellation (currently not supported by the upload service)
            const pendingUpload = this.uploadService.createAndUploadDataObject(
                file,
                {
                    organizationLegacyId: data.textureSet.textureGroup.organization.legacyId,
                    type: DataObjectType.Texture,
                    imageDataType: imageDataTypeFromTextureType(textureType),
                    imageColorSpace: result.colorSpace,
                },
                {processUpload: true, showUploadToolbar: true},
            )
            data.pendingUploads.set(textureType, pendingUpload)
            this.updateStatus()
            const dataObject = await pendingUpload

            data.uploadedDataObjectByTextureType.set(textureType, {
                id: dataObject.id,
            })
            data.dataObjectByTextureType.set(textureType, {id: dataObject.id})
            this.updateStatus()
        } catch (e: unknown) {
            let message: string
            if (typeof e === "string") {
                message = e
            } else if (e instanceof Error) {
                message = e.message
            } else {
                message = "Unknown error"
            }
            this.notificationService.showError("Error uploading texture: " + message)
        } finally {
            data.pendingUploads.delete(textureType)
            // TODO: there is probably a better way to do this
            if (data != this.data) {
                // some new data was loaded in the meantime, lets clean up and reset
                this.cleanUpAndReset(data)
            }
        }
    }

    private async revertUploadedDataObject(uploadedDataObjectInfo: DataObjectInfo) {
        console.log(`Cleaning up data-object ${uploadedDataObjectInfo.id}...`)
        // this.texturesApi.deleteDataObjectIfNotReferenced(uploadedDataObjectInfo.dataObjectId)
    }

    private cleanUpAndReset(data: Data | undefined) {
        if (!data) {
            return
        }
        for (const textureRevisionInfo of data.uploadedDataObjectByTextureType.values()) {
            this.revertUploadedDataObject(textureRevisionInfo)
        }
        data.uploadedDataObjectByTextureType.clear()
        data.dataObjectByTextureType = new Map(this._originalDataObjectByTextureType)
        data.name = this._originalName
        data.changed = false
    }

    protected HintTypes = HintTypes
    protected textureTypes = textureTypes
    protected descriptorByTextureType = descriptorByTextureType
    protected data: Data | undefined = undefined
    protected $status: WritableSignal<Status> = signal("viewing")

    private _textureSetRevisionId: string | undefined
    private _originalDataObjectByTextureType: Map<TextureType, DataObjectInfo> | undefined = undefined
    private _originalName: string = ""
    protected readonly MimeType = MimeType
}

type TextureSetRevisionInfo = {
    width: number
    height: number
    displacement?: number
}

type DataObjectInfo = {
    id: string
    width?: number
    height?: number
}

type Data = {
    textureSet: TextureSetRevisionViewTextureSetFragment
    dataObjectByTextureType: Map<TextureType, DataObjectInfo>
    name: string
    changed: boolean
    textureSetRevisionInfo: TextureSetRevisionInfo
    uploadedDataObjectByTextureType: Map<TextureType, DataObjectInfo>
    pendingUploads: Map<TextureType, Promise<UploadServiceDataObjectFragment>>
    newWidthEntered: boolean
    newHeightEntered: boolean
}

type Status = "viewing" | "processing" | "inconsistent-sizes" | "valid"
