import {Component, inject, computed, model, signal, input} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatDialogModule} from "@angular/material/dialog"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatIconModule} from "@angular/material/icon"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {MatButtonModule} from "@angular/material/button"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {MutationCreateModelInput} from "@generated"

type AddModelData = Partial<MutationCreateModelInput>

// TODO this has a lot of overlap with AddMaterialDialogComponent, factor out common base component?

@Component({
    selector: "cm-add-model-dialog",
    imports: [FormsModule, MatIconModule, MatDialogModule, MatFormFieldModule, MatSelectModule, MatInputModule, OrganizationSelectComponent, MatButtonModule],
    templateUrl: "./add-model-dialog.component.html",
    styleUrl: "./add-model-dialog.component.scss",
})
export class AddModelDialogComponent {
    readonly $addModel = input.required<
        | ((data: AddModelData) => Promise<
              | {
                    id: string
                    legacyId: number
                }
              | undefined
          >)
        | undefined
    >({alias: "addModel"})

    readonly organizations = inject(OrganizationsService)

    readonly $updateSelectedOrganization = signal<{id: string} | undefined | null>(null)
    readonly $selectedOrganization = computed(() => {
        const updateSelectedOrganization = this.$updateSelectedOrganization()
        if (updateSelectedOrganization) return updateSelectedOrganization
        return this.organizations.$current()
    })
    readonly $name = model<string>("", {alias: "name"})

    readonly $data = computed<AddModelData>(() => {
        return {
            name: this.$name(),
            organizationId: this.$selectedOrganization()?.id,
        }
    })

    canConfirmCreate = () => !!this.$data().name && !!this.$data().organizationId

    updateOrganization = async (data: {organizationId: string}) => {
        this.$updateSelectedOrganization.set({id: data.organizationId})
    }

    confirmCreate = () => {
        const addModel = this.$addModel()
        if (addModel === undefined) throw new Error("Missing addModel function in add-model-button component")
        return addModel(this.$data())
    }
}
