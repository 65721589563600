import {Component} from "@angular/core"
import {MatCardModule} from "@angular/material/card"
import {MatIconModule} from "@angular/material/icon"
import {MatTooltipModule} from "@angular/material/tooltip"

@Component({
    selector: "cm-unauthorized",
    imports: [MatCardModule, MatIconModule, MatTooltipModule],
    templateUrl: "./unauthorized.component.html",
    styleUrl: "./unauthorized.component.scss",
})
export class UnauthorizedComponent {}
