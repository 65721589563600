import {
    ChangeDetectorRef,
    Component,
    computed,
    DestroyRef,
    ElementRef,
    inject,
    Injectable,
    input,
    OnDestroy,
    OnInit,
    signal,
    viewChild,
    ViewContainerRef,
} from "@angular/core"
import {takeUntilDestroyed, toObservable, toSignal} from "@angular/core/rxjs-interop"
import {MatDialog} from "@angular/material/dialog"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {Router} from "@angular/router"
import {TemplateState, TemplateType} from "@generated"
import {RenameDialogComponent} from "@app/common/components/dialogs/rename-dialog/rename-dialog.component"
import {BatchApiCall} from "@app/common/helpers/batch-api-call/batch-api-call"
import {createLinkToEditorFromTemplatesForType} from "@app/common/helpers/routes"
import {ApiRequest} from "@app/common/models/api-request/api-request"
import {FilesService} from "@app/common/services/files/files.service"
import {TemplateMenuSectionComponent} from "@app/template-editor/components/template-menu-section/template-menu-section.component"
import {TemplateMenuComponent} from "@app/template-editor/components/template-menu/template-menu.component"
import {DIALOG_DEFAULT_WIDTH} from "@app/template-editor/helpers/constants"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {TemplateNodeClipboardService} from "@app/template-editor/services/template-node-clipboard.service"
import {
    DraggableSource,
    DropPosition,
    getDropPositionFromPosition,
    TemplateDropTarget,
    TemplateNodeDragService,
} from "@app/template-editor/services/template-node-drag.service"
import {Matrix4} from "@cm/math"
import {
    BooleanSwitch,
    DataObjectReference,
    getNodeOwner,
    getTemplateNodeClassLabel,
    getTemplateNodeLabel,
    getTemplateSwitchItemLabel,
    Group,
    ImageOperator,
    ImageSwitch,
    isBooleanLikeNode,
    isImageLike,
    isJSONLikeNode,
    isMaterialLike,
    isMesh,
    isNamedNode,
    isNode,
    isNodeOwner,
    isNodeValue,
    isNumberLikeNode,
    isObject,
    isObjectLike,
    isStringLikeNode,
    isSwitch,
    isTemplateLike,
    JSONSwitch,
    MaterialReference,
    MaterialSwitch,
    MeshDecal,
    NodeOwner,
    Nodes,
    NumberSwitch,
    ObjectSwitch,
    Parameters,
    StoredMesh,
    StringSwitch,
    TemplateGraph,
    TemplateInstance,
    TemplateListNode,
    TemplateNode,
    TemplateReference,
    TemplateSwitch,
    Node,
    isMeshLike,
} from "@cm/template-nodes"
import {MeshCurve} from "@cm/template-nodes/nodes/mesh-curve"
import {Seam} from "@cm/template-nodes/nodes/seam"
import {insertAfter, insertBefore} from "@cm/utils"
import {IsUnique} from "@cm/utils/filter"
import {ListItemComponent} from "@common/components/item"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {TemplateMenuItemComponent} from "@template-editor/components/template-menu-item/template-menu-item.component"
import {
    CreateTemplateRevisionForTemplateTreeItemGQL,
    GetMaterialRevisionDetailsForTemplateTreeItemGQL,
    GetTemplateRevisionDetailsForTemplateTreeItemGQL,
    TemplateRevisionDetailsForTemplateTreeItemFragment,
} from "@template-editor/components/template-tree-item/template-tree-item.generated"
import {hasChildren, TemplateTreeComponent, TemplateTreeNode} from "@template-editor/components/template-tree/template-tree.component"
import {getNodeIconClass, getNodeIconSeconaryClass} from "@template-editor/helpers/template-icons"
import {from, of} from "rxjs"
import {map, switchMap, tap, catchError} from "rxjs/operators"
import {v4 as uuid4} from "uuid"
import {TemplateNodeComponent} from "../template-node/template-node.component"
import {TemplateTreeObjectTransformComponent} from "../template-tree-object-transform/template-tree-object-transform.component"
import {DimensionGuides} from "@cm/template-nodes"
import {initDimensionGuides} from "@cm/template-nodes/utils/dimension-guide-utils"
import {ImageRgbCurve} from "@cm/template-nodes/nodes/image-rgb-curve"
import {TemplateAddDialogComponent, TemplateAddDialogComponentData} from "../template-add-dialog/template-add-dialog.component"
import {OrganizationsService} from "@app/common/services/organizations/organizations.service"
import {getAllTemplateNodes} from "@cm/template-nodes/utils"
import {CurveSwitch, MeshSwitch} from "@cm/template-nodes/nodes/switch"
import {isCurveLike} from "@cm/template-nodes/node-types"

type RequestPayload = {
    legacyId: number
}

type ResponsePayload = TemplateRevisionDetailsForTemplateTreeItemFragment

type BatchedRequestPayload = {
    requests: ApiRequest<RequestPayload, ResponsePayload>[]
}

@Injectable({
    providedIn: "root",
})
class TemplateRevisionDetailBatchApiCallService extends BatchApiCall<RequestPayload, ResponsePayload, BatchedRequestPayload> {
    protected readonly templateRevisionGql = inject(GetTemplateRevisionDetailsForTemplateTreeItemGQL)

    constructor() {
        super()
    }

    protected dispatchResponses(batchedPayload: BatchedRequestPayload, responses: ResponsePayload[]) {
        const requestsById = new Map<number, ApiRequest<RequestPayload, ResponsePayload>[]>()
        batchedPayload.requests.forEach((request) => {
            if (!requestsById.has(request.payload.legacyId)) {
                requestsById.set(request.payload.legacyId, [])
            }
            requestsById.get(request.payload.legacyId)!.push(request)
        })
        responses.forEach((response) => {
            const requests = requestsById.get(response.legacyId)
            if (!requests) {
                throw new Error("No request not found")
            }
            requests.forEach((request) => request.resolve(response))
            requestsById.delete(response.legacyId)
        })
        requestsById.forEach((requests) => requests.forEach((request) => request.reject("No response received")))
    }

    protected batchRequests(requests: ApiRequest<RequestPayload, ResponsePayload>[]): BatchedRequestPayload[] {
        return [{requests}]
    }

    protected async callApi(payload: BatchedRequestPayload): Promise<(ResponsePayload | undefined | null)[]> {
        const legacyIds = payload.requests.map((request) => request.payload.legacyId).filter(IsUnique)
        return fetchThrowingErrors(this.templateRevisionGql)({
            legacyIds,
        }).then((response) => response.templateRevisions)
    }
}

@Component({
    selector: "cm-template-tree-item",
    templateUrl: "./template-tree-item.component.html",
    styleUrls: ["./template-tree-item.component.scss", "./../../helpers/template-icons.scss"],
    imports: [
        ListItemComponent,
        MatMenuModule,
        TemplateMenuComponent,
        TemplateMenuSectionComponent,
        MatTooltipModule,
        TemplateMenuItemComponent,
        TemplateTreeObjectTransformComponent,
    ],
})
export class TemplateTreeItemComponent implements OnInit, OnDestroy {
    readonly sceneManagerService = inject(SceneManagerService)
    private readonly templateRevisionDetailBatchApiCallService = inject(TemplateRevisionDetailBatchApiCallService)
    private readonly router = inject(Router)
    private readonly notifications = inject(NotificationsService)
    private readonly dialog = inject(MatDialog)
    private elementRef = inject<ElementRef<HTMLElement>>(ElementRef)
    private readonly cdr = inject(ChangeDetectorRef)
    readonly clipboardService = inject(TemplateNodeClipboardService)
    readonly drag = inject(TemplateNodeDragService)
    readonly files = inject(FilesService)
    protected readonly destroyRef = inject(DestroyRef)
    readonly materialRevisionGql = inject(GetMaterialRevisionDetailsForTemplateTreeItemGQL)
    readonly organizations = inject(OrganizationsService)
    readonly createTemplateRevision = inject(CreateTemplateRevisionForTemplateTreeItemGQL)
    private readonly notificationService = inject(NotificationsService)
    TemplateInstance = TemplateInstance
    MeshDecal = MeshDecal
    MeshCurve = MeshCurve
    DimensionGuides = DimensionGuides
    Seam = Seam
    ImageOperator = ImageOperator
    ImageRgbCurve = ImageRgbCurve

    private readonly $triggerRecompute = signal(0)
    readonly $treeNode = input.required<TemplateTreeNode>({alias: "treeNode"})
    readonly $node = computed(() => this.$treeNode().node)
    readonly $isNodeValue = computed(() => isNodeValue(this.$node()))
    readonly $isRootNode = computed(() => this.$node() === this.sceneManagerService.$templateGraph())
    readonly $parent = computed(() => this.$treeNode().parent)
    readonly $isHighlighted = computed(() => this.$templateTree().isHighlighted(this.$treeNode()) || this.sceneManagerService.isHighlightedNode(this.$node()))
    readonly $expandable = computed(() => hasChildren(this.$treeNode()))
    readonly $disabled = computed(() => !this.sceneManagerService.isNodeActive(this.$node()))
    readonly $selected = computed(() =>
        this.sceneManagerService.$selectedNodeParts().some((selectedNodePart) => selectedNodePart.templateNode === this.$node()),
    )
    readonly $templateTree = input.required<TemplateTreeComponent>({alias: "templateTree"})
    readonly $nodeIconClass = computed(() => getNodeIconClass(this.$node().getNodeClass()))
    readonly $secondaryNodeIconClass = computed(() => getNodeIconSeconaryClass(this.$node().getNodeClass()))
    readonly $class = computed(() => getTemplateNodeClassLabel(this.$node()))
    readonly $label = computed(() => getTemplateNodeLabel(this.$node()))
    readonly $storedMeshNode = computed(() => {
        const node = this.$node()
        if (node instanceof StoredMesh) return node
        else return undefined
    })
    readonly $dataObjectReferenceNode = computed(() => {
        const node = this.$node()
        if (node instanceof DataObjectReference) return node
        else return undefined
    })
    readonly $objectNode = computed(() => {
        const node = this.$node()
        if (isObject(node)) return node
        else return undefined
    })
    readonly $imageLikeNode = computed(() => {
        const node = this.$node()
        if (isImageLike(node)) return node
        else return undefined
    })
    readonly $templateReferenceNode = computed(
        () => {
            const node = this.$node()
            this.$triggerRecompute()
            if (node instanceof TemplateReference) return node
            else if (node instanceof TemplateInstance && node.parameters.template instanceof TemplateReference) return node.parameters.template
            else return undefined
        },
        {equal: () => false},
    )
    readonly $templateReferenceNodeLink = toSignal(
        toObservable(this.$templateReferenceNode).pipe(
            switchMap((templateReferenceNode) => {
                if (!templateReferenceNode) return of(undefined)

                return from(
                    (async () => {
                        const {templateRevisionId} = templateReferenceNode.parameters
                        const {template} = await this.templateRevisionDetailBatchApiCallService.fetch({legacyId: templateRevisionId})
                        const {revisions} = template
                        const revision = revisions.find((revision) => revision.legacyId === templateRevisionId)

                        if (!revision) throw new Error("Revision not found")

                        const getType = () => {
                            if (template.type === TemplateType.Part || template.type === TemplateType.Product) {
                                return "products"
                            } else if (template.type === TemplateType.Room) {
                                return "scenes"
                            } else {
                                return "templates"
                            }
                        }

                        return createLinkToEditorFromTemplatesForType(getType(), template.id, revision.id)
                    })(),
                )
            }),
        ),
        {
            initialValue: undefined,
        },
    )
    readonly $materialReferenceNode = computed(
        () => {
            const node = this.$node()
            this.$triggerRecompute()
            if (node instanceof MaterialReference) return node
            else return undefined
        },
        {equal: () => false},
    )
    readonly $materialReferenceNodeLink = toSignal(
        toObservable(this.$materialReferenceNode).pipe(
            switchMap((materialReferenceNode) => {
                if (!materialReferenceNode) return of(undefined)
                return from(fetchThrowingErrors(this.materialRevisionGql)({legacyId: materialReferenceNode.parameters.materialRevisionId}))
            }),
            map((materialRevisionData) => {
                if (!materialRevisionData) return undefined
                return ["/materials", materialRevisionData.materialRevision.material.id, "edit", materialRevisionData.materialRevision.id]
            }),
            catchError((error) => {
                return of(undefined)
            }),
        ),
        {
            initialValue: undefined,
        },
    )
    readonly $nodeOwnerNode = computed(() => {
        const node = this.$node()
        if (isNodeOwner(node)) return node
        else return undefined
    })
    readonly $templateLikeNode = computed(() => {
        const node = this.$node()
        if (isTemplateLike(node)) return node
        else return undefined
    })
    readonly $templateGraphNode = computed(() => {
        const node = this.$node()
        if (node instanceof TemplateGraph) return node
        else return undefined
    })
    readonly $templateInstanceNode = computed(() => {
        const node = this.$node()
        if (node instanceof TemplateInstance) return node
        else return undefined
    })
    readonly $namedNode = computed(() => {
        const node = this.$node()
        if (isNamedNode(node)) return node
        else return undefined
    })
    readonly $groupNode = computed(() => {
        const node = this.$node()
        if (node instanceof Group) return node
        else return undefined
    })
    readonly $meshNode = computed(() => {
        const node = this.$node()
        if (isMesh(node)) return node
        else return undefined
    })
    readonly $meshCurveNode = computed(() => {
        const node = this.$node()
        if (node instanceof MeshCurve) return node
        else return undefined
    })
    readonly $switchNode = computed(() => {
        const node = this.$node()
        if (isSwitch(node)) return node
        else return undefined
    })
    readonly $parentSwitchNode = computed(() => {
        const parent = this.$parent()
        if (!parent) return undefined
        const {node} = parent
        if (isSwitch(node)) return node
        else return undefined
    })
    readonly $objectProcessingNodes = computed(() => [...this.getProcessingNodes(false)].filter(isObject))
    getSwitchItemLabel = getTemplateSwitchItemLabel

    private readonly $dragPlaceholder = viewChild.required("dragPlaceholder", {read: ViewContainerRef})

    ngOnInit() {
        this.$templateTree().registerChild(this)

        this.sceneManagerService.templateTreeChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((differences) => {
            const materialReferenceNode = this.$materialReferenceNode()
            if (materialReferenceNode && differences.modifiedNodes.has(materialReferenceNode)) this.$triggerRecompute.update((x) => x + 1)

            const templateReferenceNode = this.$templateReferenceNode()
            if (templateReferenceNode && differences.modifiedNodes.has(templateReferenceNode)) this.$triggerRecompute.update((x) => x + 1)
        })
    }

    ngOnDestroy() {
        this.$templateTree().unregisterChild(this)
    }

    onClickedNode(mouseEvent: MouseEvent) {
        const templateNodePart = {templateNode: this.$node(), part: "root" as const}
        if (mouseEvent.shiftKey) this.sceneManagerService.addNodeToSelection(templateNodePart)
        else if (mouseEvent.ctrlKey) this.sceneManagerService.removeNodeFromSelection(templateNodePart)
        else this.sceneManagerService.selectNode(templateNodePart)
    }

    onMouseEnter() {
        this.sceneManagerService.$hoveredNodePart.set({templateNode: this.$node(), part: "root"})
    }

    onMouseLeave() {
        this.sceneManagerService.$hoveredNodePart.set(undefined)
    }

    dragOver(event: DragEvent) {
        event.stopPropagation()

        const getDropPosition = (): DropPosition => {
            const element = this.elementRef.nativeElement
            const targetRect = element.getBoundingClientRect()

            const node = this.$treeNode()

            if (hasChildren(node)) {
                const offset = targetRect.height / 3
                const targetCenterY = targetRect.top + offset
                if (event.clientY < targetCenterY) return "before"
                else if (event.clientY < targetCenterY + offset) return "inside"
                else return "after"
            } else {
                const targetCenterY = targetRect.top + targetRect.height / 2
                return event.clientY < targetCenterY ? "before" : "after"
            }
        }

        const getDropTarget = (): TemplateDropTarget<TemplateTreeItemComponent> | null => {
            const dragSource = this.drag.$dragSource()
            if (!dragSource) return null

            const dropTarget = {component: this, position: this.forceDropPosition() ?? getDropPosition()}

            if (!this.isMovingToAllowed(dragSource, dropTarget.position)) return null
            return dropTarget
        }

        this.drag.$dropTarget.update((previous) => {
            const dropTarget = getDropTarget()
            if (previous === dropTarget) return previous
            if (
                previous &&
                dropTarget &&
                previous.component === dropTarget.component &&
                getDropPositionFromPosition(previous.position) === getDropPositionFromPosition(dropTarget.position)
            )
                return previous
            return dropTarget
        })

        if (this.drag.$dropTarget() !== null) event.preventDefault()
    }

    dragLeave(event: DragEvent) {
        event.stopPropagation()

        this.drag.dragLeave(event, this, this.elementRef.nativeElement)
    }

    isDropTarget(dropPosition: DropPosition) {
        if (!this.drag.$dragSource()) return false

        const dropTarget = this.drag.$dropTarget()
        if (!dropTarget) return false

        const {component} = dropTarget
        if (!(component instanceof TemplateTreeItemComponent)) return false

        const position = getDropPositionFromPosition(dropTarget.position)
        const dropTargetNode = component.$treeNode()

        const node = this.$treeNode()
        if (dropTargetNode === node) return position === dropPosition
        else if (position === "inside" && hasChildren(dropTargetNode)) {
            if (dropPosition === "after") {
                const children = dropTargetNode.node.parameters.nodes.parameters.list
                if (children.length > 0) {
                    const lastChild = children[children.length - 1]
                    return node.node === lastChild && node.parent === dropTargetNode
                }
            }
        }
        return false
    }

    private async allowLeavePage() {
        if (!this.sceneManagerService.$templateGraphModified()) return true

        const confirmed = await this.notifications.confirmationDialog({
            title: "Leave page ?",
            message: "Changes you made may not be saved. Are you sure you want to leave?",
            confirm: "Leave",
            cancel: "Stay",
        })

        return confirmed
    }

    async openTemplateReference() {
        const templateReferenceNodeLink = this.$templateReferenceNodeLink()
        if (!templateReferenceNodeLink) return

        if (!(await this.allowLeavePage())) return

        this.router.navigate(templateReferenceNodeLink, {
            queryParamsHandling: "preserve",
        })
    }

    async openMaterialReference() {
        const materialReferenceNodeLink = this.$materialReferenceNodeLink()
        if (!materialReferenceNodeLink) return

        if (!(await this.allowLeavePage())) return

        this.router.navigate(materialReferenceNodeLink, {
            queryParamsHandling: "preserve",
        })
    }

    collapseAll() {
        const treeControl = this.$templateTree().treeControl
        const treeNode = this.$treeNode()
        treeControl.collapseDescendants(treeNode)
        treeControl.expand(treeNode)
    }

    renameNode() {
        const namedNode = this.$namedNode()
        if (!namedNode) return

        const dialogRef = this.dialog.open(RenameDialogComponent, {
            width: DIALOG_DEFAULT_WIDTH,
            data: {
                currentName: namedNode.parameters.name,
            },
        })

        dialogRef
            .afterClosed()
            .pipe(
                tap((newValue) => {
                    if (newValue) {
                        this.sceneManagerService.modifyTemplateGraph(() => {
                            namedNode.updateParameters({name: newValue})
                        })
                    }
                }),
            )
            .subscribe()
    }

    private getProcessingNodes(includeRoot: boolean) {
        const selectedNodes = this.sceneManagerService.$selectedNodeParts().map((selectedNodePart) => selectedNodePart.templateNode)
        const node = this.$node()
        const copyNodes = selectedNodes.includes(node) ? selectedNodes : [node]
        return includeRoot ? copyNodes : copyNodes.filter((node) => node !== this.sceneManagerService.$templateGraph())
    }

    copySelection() {
        this.clipboardService.copy([...this.getProcessingNodes(true)])
    }

    pasteCopies() {
        const nodeOwner = this.$nodeOwnerNode()
        if (!nodeOwner || !this.clipboardService.valid()) return

        this.sceneManagerService.modifyTemplateGraph(() =>
            this.clipboardService
                .pasteDuplicates()
                .filter(isNode)
                .forEach((node) => this.addReference(nodeOwner.parameters.nodes, node)),
        )
    }

    private addReference<T>(nodes: TemplateListNode<T>, node: T, position?: {node: T; location: "before" | "after"}) {
        const {list} = nodes.parameters

        if (list.includes(node)) return
        if (!position) nodes.addEntry(node)
        else {
            const {location} = position
            const newList = [...list]
            if (location === "before") insertBefore(newList, position.node, node)
            else if (location === "after") insertAfter(newList, position.node, node)
            else throw new Error(`Invalid location: ${location}`)
            nodes.replaceParameters({list: newList})
        }
    }

    pasteReferences() {
        const switchNode = this.$switchNode()
        if (!switchNode || !this.clipboardService.valid()) return

        const pastedNodes = this.clipboardService.pasteReferences().filter((node) => node !== switchNode)

        if (pastedNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() => {
            if (switchNode instanceof ObjectSwitch) pastedNodes.filter(isObjectLike).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof MeshSwitch) pastedNodes.filter(isMeshLike).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof CurveSwitch)
                pastedNodes.filter((node) => node instanceof MeshCurve).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof MaterialSwitch)
                pastedNodes.filter(isMaterialLike).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof TemplateSwitch)
                pastedNodes.filter(isTemplateLike).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof ImageSwitch) pastedNodes.filter(isImageLike).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof StringSwitch)
                pastedNodes.filter(isStringLikeNode).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof NumberSwitch)
                pastedNodes.filter(isNumberLikeNode).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof BooleanSwitch)
                pastedNodes.filter(isBooleanLikeNode).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof JSONSwitch)
                pastedNodes.filter(isJSONLikeNode).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else throw new Error("Unsupported switch node type")
        })
    }

    duplicateNode() {
        const processingNodes = [...this.getProcessingNodes(false)]
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() => {
            const originalNodes = this.clipboardService.pasteReferences()

            this.clipboardService.copy(processingNodes)
            const copiedNodes = this.clipboardService.pasteDuplicates()

            processingNodes.forEach((node, index) => {
                const copiedNode = copiedNodes[index]

                if (isNode(node) && isNode(copiedNode))
                    getNodeOwner(node, (nodeOwner) => this.addReference(nodeOwner.parameters.nodes, copiedNode, {node, location: "after"}))
            })

            this.clipboardService.copy(originalNodes)
        })
    }

    private getAllSubNodes = (node: TemplateNode, subNodes: Set<TemplateNode>) => {
        subNodes.add(node)
        if (isNodeOwner(node)) node.parameters.nodes.parameters.list.forEach((childNode) => this.getAllSubNodes(childNode, subNodes))
    }

    private moveNodesToNewNodeOwner = (targetNode: NodeOwner, nodes: Node[], targetInsertLocation?: Node) => {
        const allSubNodes = new Set<TemplateNode>()
        nodes.forEach((node) => this.getAllSubNodes(node, allSubNodes))

        const firstUnselectedNodeOwner = (node: TemplateNode) => {
            if (!isNode(node)) throw new Error("Invalid node type")

            const nodeOwner = getNodeOwner(node)
            if (!nodeOwner) throw new Error("Invalid node owner")

            if (allSubNodes.has(nodeOwner)) return firstUnselectedNodeOwner(nodeOwner)
            return {nodeOwner, node}
        }

        if (targetInsertLocation) {
            const {nodeOwner, node} = firstUnselectedNodeOwner(targetInsertLocation)
            this.addReference(nodeOwner.parameters.nodes, targetNode, {node, location: "after"})
        }

        nodes.forEach((node) => {
            if (node === targetNode) return

            const {nodeOwner, node: currentNode} = firstUnselectedNodeOwner(node)
            if (currentNode !== node) return

            if (nodeOwner !== targetNode) {
                nodeOwner.parameters.nodes.removeEntry(node)
                targetNode.parameters.nodes.addEntry(node)
            }
        })
    }

    createTemplate() {
        const processingNodes = [...this.getProcessingNodes(false)]
        if (processingNodes.length === 0) return

        const selectedNode = this.$node()
        if (!isNode(selectedNode)) return

        const allSubNodes = new Set<TemplateNode>()
        processingNodes.forEach((node) => this.getAllSubNodes(node, allSubNodes))
        const allObjects = [...allSubNodes].filter(isObject)

        const selectedObjects = processingNodes.filter(isObject)

        const primaryObj = selectedObjects.at(0) ?? allObjects.at(0)

        const name = processingNodes.find(isNamedNode)?.parameters.name ?? "Template Graph"

        const dialogRef = this.dialog.open(RenameDialogComponent, {
            width: DIALOG_DEFAULT_WIDTH,
            data: {
                currentName: name,
                caption: "Create Template From Selection",
            },
        })

        dialogRef
            .afterClosed()
            .pipe(
                tap((newValue) => {
                    if (newValue) {
                        this.sceneManagerService.modifyTemplateGraph(() => {
                            const matrixA = primaryObj ? this.sceneManagerService.getTransformAccessor(primaryObj)?.getTransform() : undefined

                            if (matrixA) {
                                for (const secondaryObject of allObjects) {
                                    const matrixB = this.sceneManagerService.getTransformAccessor(secondaryObject)?.getTransform()

                                    if (!matrixB) continue

                                    const newTransform = matrixA.inverse().multiply(matrixB).toArray()

                                    if (secondaryObject.parameters.lockedTransform) secondaryObject.updateParameters({lockedTransform: newTransform})
                                    else secondaryObject.updateParameters({$defaultTransform: newTransform})
                                }
                            }

                            const templateSubGraph = new TemplateGraph({
                                name: newValue,
                                nodes: new Nodes({list: []}),
                            })

                            this.moveNodesToNewNodeOwner(templateSubGraph, processingNodes.filter(isNode), selectedNode)

                            if (matrixA) {
                                const instance = new TemplateInstance({
                                    name: newValue + " instance",
                                    id: uuid4(),
                                    template: templateSubGraph,
                                    parameters: new Parameters({}),
                                    lockedTransform: matrixA.toArray(),
                                    visible: true,
                                })

                                getNodeOwner(templateSubGraph, (nodeOwner) =>
                                    this.addReference(nodeOwner.parameters.nodes, instance, {node: templateSubGraph, location: "after"}),
                                )
                            }
                        })
                    }
                }),
            )
            .subscribe()
    }

    createInstance() {
        const processingNodes = this.getProcessingNodes(false)
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() =>
            processingNodes.forEach((node) => {
                if (isTemplateLike(node)) {
                    const instance = new TemplateInstance({
                        name: isNamedNode(node) ? node.parameters.name + " instance" : "Template Instance",
                        id: uuid4(),
                        template: node,
                        parameters: new Parameters({}),
                        lockedTransform: Matrix4.identity().toArray(),
                        visible: true,
                    })
                    getNodeOwner(node, (nodeOwner) => {
                        this.addReference(nodeOwner.parameters.nodes, instance, {node, location: "after"})
                    })
                }
            }),
        )
    }

    async promoteToLibraryTemplate() {
        const templateSubGraph = this.$templateGraphNode()
        if (!templateSubGraph) throw new Error("Invalid node type")
        const defaultCustomerId = this.sceneManagerService.$defaultCustomerId()

        const orginizationId = this.organizations.$all()?.find((organization) => organization.legacyId === defaultCustomerId)?.id
        if (!orginizationId) throw Error("Fitting organization found")

        const dialogRef = this.dialog.open<TemplateAddDialogComponent, TemplateAddDialogComponentData, boolean>(TemplateAddDialogComponent, {
            disableClose: false,
            data: {
                organizationId: orginizationId,
                name: templateSubGraph.parameters.name,
                state: TemplateState.Draft,
                templateType: TemplateType.Product,
            },
        })

        dialogRef.componentInstance.onTemplateCreated.subscribe((templateData) => {
            this.createTemplateRevision
                .mutate({input: {templateId: templateData.id, graph: templateSubGraph.serialize(), completed: false}})
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(({data}) => {
                    if (!data) return

                    this.sceneManagerService.modifyTemplateGraph((templateGraph) => {
                        getNodeOwner(templateSubGraph, (nodeOwner) => {
                            nodeOwner.parameters.nodes.removeEntry(templateSubGraph)
                        })
                        getAllTemplateNodes(templateGraph).forEach((node) => {
                            if (node instanceof TemplateInstance) {
                                if (node.parameters.template === templateSubGraph) {
                                    node.updateParameters({template: new TemplateReference({templateRevisionId: data.createTemplateRevision.legacyId})})
                                }
                            }
                        })
                    })

                    this.notificationService.showInfo(`New template ${templateData.name} saved, id: ${data.createTemplateRevision.id}`)
                })
        })
    }

    deleteReference() {
        const parentSwitchNode = this.$parentSwitchNode()
        if (!parentSwitchNode) return
        const processingNodes = this.getProcessingNodes(false)
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() =>
            processingNodes.forEach((node) => {
                ;(parentSwitchNode.parameters.nodes as TemplateListNode<TemplateNode>).removeEntry(node)
            }),
        )
    }

    deleteNode() {
        const processingNodes = this.getProcessingNodes(false)
        this.sceneManagerService.deleteNodes(processingNodes.map((node) => ({templateNode: node, part: "root"})))
    }

    moveSelectionToNewGroup() {
        const processingNodes = this.getProcessingNodes(false)
        if (processingNodes.length === 0) return

        const selectedNode = this.$node()
        if (!isNode(selectedNode)) return

        const name = processingNodes.find(isNamedNode)?.parameters.name ?? "Group"

        this.sceneManagerService.modifyTemplateGraph(() => {
            const group = new Group({name, nodes: new Nodes({list: []}), active: true})
            this.moveNodesToNewNodeOwner(group, processingNodes.filter(isNode), selectedNode)
        })
    }

    dissolveGroup() {
        const processingNodes = this.getProcessingNodes(false)
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() =>
            processingNodes.forEach((node) => {
                if (isNodeOwner(node)) {
                    getNodeOwner(node, (nodeOwner) => {
                        const {list: childNodes} = node.parameters.nodes.parameters
                        const childNodesCopy = [...childNodes]
                        childNodesCopy.forEach((childNode) => {
                            node.parameters.nodes.removeEntry(childNode)
                            this.addReference(nodeOwner.parameters.nodes, childNode, {node, location: "before"})
                        })
                        nodeOwner.parameters.nodes.removeEntry(node)
                    })
                }
            }),
        )
    }

    addDecal() {
        const processingNodes = this.getProcessingNodes(false)
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() =>
            processingNodes.forEach((node) => {
                if (isMesh(node)) {
                    const decal = new MeshDecal({
                        name: node.parameters.name + " decal",
                        mesh: node,
                        offset: [0, 0],
                        rotation: 0,
                        size: [10, 10],
                        distance: 0.01,
                        mask: undefined,
                        invertMask: false,
                        maskType: "binary",
                        materialAssignment: null,
                        visible: true,
                    })
                    getNodeOwner(node, (nodeOwner) => this.addReference(nodeOwner.parameters.nodes, decal, {node, location: "after"}))
                }
            }),
        )
    }

    addCurve() {
        const processingNodes = this.getProcessingNodes(false)
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() =>
            processingNodes.forEach((node) => {
                if (isMesh(node)) {
                    const curve = new MeshCurve({
                        name: node.parameters.name + " curve",
                        mesh: node,
                        closed: false,
                        controlPoints: [],
                        visible: true,
                    })
                    getNodeOwner(node, (nodeOwner) => this.addReference(nodeOwner.parameters.nodes, curve, {node, location: "after"}))
                }
            }),
        )
    }

    addDimensionGuides() {
        const processingNodes = this.getProcessingNodes(false)
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() =>
            processingNodes.forEach((node) => {
                if (isObject(node)) {
                    const name = isNamedNode(node) ? node.parameters.name + " dimension guide" : "Dimension guide"
                    const dimensionGuide = initDimensionGuides(name, node)
                    getNodeOwner(node, (nodeOwner) => this.addReference(nodeOwner.parameters.nodes, dimensionGuide, {node, location: "after"}))
                }
            }),
        )
    }

    addSeam() {
        const processingNodes = this.getProcessingNodes(false)
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() =>
            processingNodes.forEach((node) => {
                if (node instanceof MeshCurve) {
                    const curve = new Seam({
                        name: node.parameters.name + " seam",
                        curves: [node],
                        item: null,
                        allowScaling: false,
                        visible: true,
                    })
                    getNodeOwner(node, (nodeOwner) => this.addReference(nodeOwner.parameters.nodes, curve, {node, location: "after"}))
                }
            }),
        )
    }

    addImageOperator() {
        const processingNodes = this.getProcessingNodes(false)
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() =>
            processingNodes.forEach((node) => {
                if (isImageLike(node)) {
                    const operator = new ImageOperator({
                        name: isNamedNode(node) ? node.parameters.name + " operator" : "Image Operator",
                        input: node,
                        operation: "ADD",
                        value: 0,
                    })
                    getNodeOwner(node, (nodeOwner) => this.addReference(nodeOwner.parameters.nodes, operator, {node, location: "after"}))
                }
            }),
        )
    }

    addImageRgbCurve() {
        const processingNodes = this.getProcessingNodes(false)
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() =>
            processingNodes.forEach((node) => {
                if (isImageLike(node)) {
                    const operator = new ImageRgbCurve({
                        name: isNamedNode(node) ? node.parameters.name + " rgb curve" : "Image RGB Curve",
                        input: node,
                        r: [
                            {x: 0, y: 0},
                            {x: 1, y: 1},
                        ],
                        g: [
                            {x: 0, y: 0},
                            {x: 1, y: 1},
                        ],
                        b: [
                            {x: 0, y: 0},
                            {x: 1, y: 1},
                        ],
                        rgb: [
                            {x: 0, y: 0},
                            {x: 1, y: 1},
                        ],
                    })
                    getNodeOwner(node, (nodeOwner) => this.addReference(nodeOwner.parameters.nodes, operator, {node, location: "after"}))
                }
            }),
        )
    }

    forceDropPosition(): DropPosition | undefined {
        if (this.$isRootNode()) return "inside"
        return undefined
    }

    private targetSwitchNode(position: DropPosition) {
        if (position === "inside") return this.$switchNode()
        return this.$parentSwitchNode()
    }

    isMovingToAllowed(source: DraggableSource, position: DropPosition) {
        const isMovingToAllowedSingle = (source: TemplateNode | TemplateTreeItemComponent) => {
            const sourceNode = this.drag.draggableSourceToTemplateNode(source)
            if (!sourceNode) return false

            if (source instanceof TemplateTreeItemComponent) {
                // Prevent dropping on itself
                if (source === this) return false
            }

            const targetSwitchNode = this.targetSwitchNode(position)

            //Prevent mixing references and non-references
            const sourceIsReference =
                source instanceof TemplateTreeItemComponent ? source.$parentSwitchNode() !== undefined : isNode(sourceNode) && getNodeOwner(sourceNode) !== null
            const targetIsReference = targetSwitchNode !== undefined
            if (sourceIsReference !== targetIsReference) return false

            //Prevent dropping a node into a parent it is already part of
            if (source instanceof TemplateTreeItemComponent) if (position === "inside" && source.$parent() === this.$treeNode()) return false

            //Prevent dropping a node into a switch node that does not support it
            if (targetSwitchNode) {
                if (targetSwitchNode instanceof ObjectSwitch) {
                    if (!isObjectLike(sourceNode)) return false
                } else if (targetSwitchNode instanceof MeshSwitch) {
                    if (!isMeshLike(sourceNode)) return false
                } else if (targetSwitchNode instanceof CurveSwitch) {
                    if (!isCurveLike(sourceNode)) return false
                } else if (targetSwitchNode instanceof MaterialSwitch) {
                    if (!isMaterialLike(sourceNode)) return false
                } else if (targetSwitchNode instanceof TemplateSwitch) {
                    if (!isTemplateLike(sourceNode)) return false
                } else if (targetSwitchNode instanceof ImageSwitch) {
                    if (!isImageLike(sourceNode)) return false
                } else if (targetSwitchNode instanceof StringSwitch) {
                    if (!isStringLikeNode(sourceNode)) return false
                } else if (targetSwitchNode instanceof NumberSwitch) {
                    if (!isNumberLikeNode(sourceNode)) return false
                } else if (targetSwitchNode instanceof BooleanSwitch) {
                    if (!isBooleanLikeNode(sourceNode)) return false
                } else if (targetSwitchNode instanceof JSONSwitch) {
                    if (!isJSONLikeNode(sourceNode)) return false
                } else throw new Error("Unsupported switch node type")
            } else if (!isNode(sourceNode)) return false

            //Prevent dropping a node into one of its descendants
            if (source instanceof TemplateTreeItemComponent) {
                const sourceTreeNode = source.$treeNode()
                let {parent} = this.$treeNode()
                while (parent) {
                    if (parent === sourceTreeNode) return false
                    parent = parent.parent
                }
            }

            return true
        }

        if (source instanceof Array) {
            return source.every((source) => isMovingToAllowedSingle(source))
        } else return isMovingToAllowedSingle(source)
    }

    moveNodeTo(source: DraggableSource, position: DropPosition) {
        if (!this.isMovingToAllowed(source, position)) return

        const targetNode = this.$node()
        const targetSwitchNode = this.targetSwitchNode(position)

        const moveNodeToSingle = (source: TemplateNode | TemplateTreeItemComponent) => {
            const sourceNode = this.drag.draggableSourceToTemplateNode(source)
            if (!sourceNode) return

            if (targetSwitchNode) {
                const sourceSwitchParent = source instanceof TemplateTreeItemComponent ? source.$parentSwitchNode() : undefined
                if (sourceSwitchParent) {
                    ;(sourceSwitchParent.parameters.nodes as TemplateListNode<TemplateNode>).removeEntry(sourceNode)
                }

                const getPosition = <T>(nodes: TemplateListNode<T>) => {
                    if (position === "inside") return undefined
                    const item = nodes.parameters.list.find((x) => x === targetNode)
                    if (!item) return undefined
                    return {node: item, location: position}
                }

                if (targetSwitchNode instanceof ObjectSwitch) {
                    if (isObjectLike(sourceNode))
                        this.addReference(targetSwitchNode.parameters.nodes, sourceNode, getPosition(targetSwitchNode.parameters.nodes))
                    else throw new Error("Invalid node type")
                } else if (targetSwitchNode instanceof MeshSwitch) {
                    if (isMeshLike(sourceNode)) this.addReference(targetSwitchNode.parameters.nodes, sourceNode, getPosition(targetSwitchNode.parameters.nodes))
                    else throw new Error("Invalid node type")
                } else if (targetSwitchNode instanceof CurveSwitch) {
                    if (isCurveLike(sourceNode))
                        this.addReference(targetSwitchNode.parameters.nodes, sourceNode, getPosition(targetSwitchNode.parameters.nodes))
                    else throw new Error("Invalid node type")
                } else if (targetSwitchNode instanceof MaterialSwitch) {
                    if (isMaterialLike(sourceNode))
                        this.addReference(targetSwitchNode.parameters.nodes, sourceNode, getPosition(targetSwitchNode.parameters.nodes))
                    else throw new Error("Invalid node type")
                } else if (targetSwitchNode instanceof TemplateSwitch) {
                    if (isTemplateLike(sourceNode))
                        this.addReference(targetSwitchNode.parameters.nodes, sourceNode, getPosition(targetSwitchNode.parameters.nodes))
                    else throw new Error("Invalid node type")
                } else if (targetSwitchNode instanceof ImageSwitch) {
                    if (isImageLike(sourceNode))
                        this.addReference(targetSwitchNode.parameters.nodes, sourceNode, getPosition(targetSwitchNode.parameters.nodes))
                    else throw new Error("Invalid node type")
                } else if (targetSwitchNode instanceof StringSwitch) {
                    if (isStringLikeNode(sourceNode))
                        this.addReference(targetSwitchNode.parameters.nodes, sourceNode, getPosition(targetSwitchNode.parameters.nodes))
                    else throw new Error("Invalid node type")
                } else if (targetSwitchNode instanceof NumberSwitch) {
                    if (isNumberLikeNode(sourceNode))
                        this.addReference(targetSwitchNode.parameters.nodes, sourceNode, getPosition(targetSwitchNode.parameters.nodes))
                    else throw new Error("Invalid node type")
                } else if (targetSwitchNode instanceof BooleanSwitch) {
                    if (isBooleanLikeNode(sourceNode))
                        this.addReference(targetSwitchNode.parameters.nodes, sourceNode, getPosition(targetSwitchNode.parameters.nodes))
                    else throw new Error("Invalid node type")
                } else if (targetSwitchNode instanceof JSONSwitch) {
                    if (isJSONLikeNode(sourceNode))
                        this.addReference(targetSwitchNode.parameters.nodes, sourceNode, getPosition(targetSwitchNode.parameters.nodes))
                    else throw new Error("Invalid node type")
                } else throw new Error("Unsupported switch node type")
            } else {
                if (!isNode(sourceNode)) throw new Error("Source node is not of type node")

                const sourceNodeOwner = getNodeOwner(sourceNode)
                if (sourceNodeOwner) sourceNodeOwner.parameters.nodes.removeEntry(sourceNode)

                if (position === "inside") {
                    if (!isNodeOwner(targetNode)) throw new Error("Target node is not of type node parent")
                    this.addReference(targetNode.parameters.nodes, sourceNode)
                } else {
                    if (!isNode(targetNode)) throw new Error("Target node is not of type node")
                    const targetNodeOwner = getNodeOwner(targetNode)
                    if (!targetNodeOwner) throw new Error("Target node is not of type node parent")
                    this.addReference(targetNodeOwner.parameters.nodes, sourceNode, {node: targetNode, location: position})
                }
            }
        }

        this.sceneManagerService.modifyTemplateGraph(() => {
            if (source instanceof Array) source.forEach((source) => moveNodeToSingle(source))
            else moveNodeToSingle(source)
        })
    }

    gotoReference() {
        if (!this.$parentSwitchNode()) return
        this.sceneManagerService.selectNode({templateNode: this.$node(), part: "root"})
    }

    setVisibility(visible: boolean) {
        const processingNodes = this.$objectProcessingNodes()
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() => {
            processingNodes.forEach((templateNode) => {
                templateNode.updateParameters({
                    visible,
                })
            })
        })
    }

    private getDragImage() {
        const dragPlaceholder = this.$dragPlaceholder()

        const dragImage = document.createElement("div")

        dragImage.style.display = "flex"
        dragImage.style.flexDirection = "column"
        dragImage.style.justifyContent = "center"
        dragImage.style.alignItems = "left"
        dragImage.style.position = "absolute"
        dragImage.style.zIndex = "1000"
        dragImage.style.left = "-1000px"
        dragImage.style.backgroundColor = "rgb(255, 255, 255)"
        dragImage.style.border = "1px solid black"
        dragImage.style.padding = "5px"
        dragImage.style.fontSize = "0.75rem"
        dragImage.style.fontWeight = "500"
        dragImage.style.color = "black"
        dragImage.style.gap = "5px"

        const draggedComponents = this.getDraggedComponents()

        for (const draggedComponent of draggedComponents) {
            const templateNodeComponent = dragPlaceholder.createComponent(TemplateNodeComponent)
            templateNodeComponent.setInput("node", draggedComponent.$node())
            dragImage.appendChild(templateNodeComponent.location.nativeElement)
        }

        this.cdr.detectChanges()

        const nativeElement = dragPlaceholder.element.nativeElement as HTMLElement

        nativeElement.appendChild(dragImage)

        setTimeout(() => {
            nativeElement.removeChild(dragImage)
        }, 0)

        return dragImage
    }

    private getDraggedComponents() {
        const processingNodes = this.getProcessingNodes(false)

        const templateTreeItems = this.$templateTree().$children()

        const draggedParentSwitchNode = this.$parentSwitchNode()

        return processingNodes
            .map((node) => {
                return templateTreeItems.find((child) => {
                    if (child.$node() !== node) return false
                    if (child.$parentSwitchNode() !== draggedParentSwitchNode) return false
                    return true
                })
            })
            .filter((child): child is TemplateTreeItemComponent => child !== undefined)
    }

    dragStart(event: DragEvent) {
        const dragImage = this.getDragImage()

        event.dataTransfer?.setDragImage(dragImage, 0, 0)

        this.drag.dragStart(event, this.getDraggedComponents())
    }
}
