import {EventEmitter} from "@angular/core"
import {ImageRef, ManagedImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {Box2, Vector2, Vector2Like} from "@cm/math"
import {CanvasBaseToolboxItem, ToolMouseEvent} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"
import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import * as paper from "paper"
import {ImageOpContextWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-webgl2"
import {BrushStrokeGeometry} from "@app/textures/texture-editor/operator-stack/operators/shared/image-ops/brush-stroke-geometry"
import {PainterBlitterRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/painter-ref"
import {BrushBlendCurrentStroke} from "@app/textures/texture-editor/operator-stack/operators/shared/image-ops/brush-blend-current-stroke"
import {ImageOpCommandQueueWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-webgl2"
import {createImage} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-create-image"
import {copyRegion} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-copy-region"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {TextureEditorSettings} from "@app/textures/texture-editor/texture-editor-settings"

const DISPLAY_BOUNDING_BOX = TextureEditorSettings.EnableFullTrace

export class BrushToolboxItem extends CanvasBaseToolboxItemBase {
    readonly hoverPositionChanged = new EventEmitter<Vector2 | undefined>()
    readonly brushStrokeUpdated = new EventEmitter<void>()
    readonly brushStrokeCompleted = new EventEmitter<void>()

    constructor(
        parent: CanvasBaseToolboxItem,
        readonly imageOpContext: ImageOpContextWebGL2,
    ) {
        super(parent)
        this.cursor = "crosshair"

        this.brushStrokeGeometry = new BrushStrokeGeometry(this.imageOpContext, true)
        this.brushBlendCurrentStroke = new BrushBlendCurrentStroke(this.imageOpContext)
        this.blitter = this.imageOpContext.createPainter("blitter", "brushToolboxBlitter")

        this.brushLine = new paper.Path.Line(new paper.Point(0, 0), new paper.Point(0, 0))
        this.brushLine.strokeColor = new paper.Color("white")
        this.brushLine.visible = false

        this.viewChange.subscribe(() => this.updateBrushCircle(true))
        this.selectedChange.subscribe((selected) => this.hoverPositionChanged.emit(selected ? this.lastHoverPosition : undefined))
        this.hoverPositionChanged.subscribe(() => this.updateBrushCircle())
    }

    override remove(): void {
        super.remove()
        this.freeData()
        this.brushStrokeGeometry.dispose()
        this.brushBlendCurrentStroke.dispose()
        this.imageOpContext.releasePainter(this.blitter)
    }

    override hitTest(point: Vector2Like): CanvasBaseToolboxItem | null {
        if (this.selected && this.isPointInImage(point)) {
            this.lastHoverPosition = Vector2.fromVector2Like(point)
            return this
        }
        if (this.brushCircle) {
            this.brushCircle.visible = false
        }
        this.brushLine.visible = false
        this.lastHoverPosition = undefined
        return null
    }

    override onMouseLeave(_event: ToolMouseEvent): boolean {
        this.lastHoverPosition = undefined
        return false
    }

    set brushSettings(value: BrushSettings) {
        this._brushSettings = value
    }

    get brushSettings(): BrushSettings {
        return this._brushSettings
    }

    get isDrawing(): boolean {
        return this._isDrawing
    }

    get brushStartPosition(): Vector2 | null {
        return this._brushStartPosition
    }

    get brushCurrentPosition(): Vector2 | null {
        return this._brushCurrentPosition
    }

    get brushCursorOffset(): Vector2 {
        return this._brushCursorOffset
    }

    set brushCursorOffset(value: Vector2) {
        this._brushCursorOffset = value
    }

    set boundingBox(value: Box2) {
        this._boundingBox = value
    }

    get boundingBox(): Box2 {
        return this._boundingBox
    }

    getBrushShape(cmdQueue: ImageOpCommandQueue): ImageRef {
        return this.brushStrokeGeometry.getBrushShape(cmdQueue, this.brushSettings)
    }

    flushBrushStrokes(cmdQueue: ImageOpCommandQueue, resultImage: ImageRef) {
        if (!(cmdQueue instanceof ImageOpCommandQueueWebGL2)) {
            return false
        }
        if (!this.strokePathChanged) {
            return false
        }

        if (!this.currentStrokeImage || this.isNewStroke) {
            this.currentStrokeImage?.release()
            this.currentStrokeImage = cmdQueue.keepAlive(
                createImage(cmdQueue, {
                    imageOrDescriptor: resultImage,
                    fillColor: {r: 0, g: 0, b: 0, a: 0},
                }),
            )
        }
        if (!this.preStrokeImage || this.isNewStroke) {
            this.preStrokeImage?.release()
            this.preStrokeImage = cmdQueue.keepAlive(
                copyRegion(cmdQueue, {
                    sourceImage: resultImage,
                }),
            )
        }

        this.strokePathChanged = false
        this.isNewStroke = false

        // update current stroke
        const strokeBoundingBox = this.brushStrokeGeometry.paint(cmdQueue, {
            resultImage: this.currentStrokeImage.ref,
            brushSettings: this._brushSettings,
            removeDrawnPoints: true,
        })
        this._boundingBox.expandByBoxInPlace(strokeBoundingBox)

        // blend current stroke into brush stroke
        this.brushBlendCurrentStroke.paint(cmdQueue, {
            resultImage: resultImage,
            preStrokeBrushedImage: this.preStrokeImage.ref,
            currentStrokeImage: this.currentStrokeImage.ref,
            brushOpacity: this.brushSettings.brushOpacity,
            brushMode: this.brushSettings.brushMode,
            boundingBox: strokeBoundingBox,
        })

        if (DISPLAY_BOUNDING_BOX) {
            this.beginPaperCreation()

            if (this.boundingBoxRect) {
                this.boundingBoxRect.remove()
            }
            this.boundingBoxRect = new paper.Path.Rectangle(
                this._boundingBox.min.sub(this._brushCursorOffset),
                this._boundingBox.max.sub(this._brushCursorOffset),
            )
            this.boundingBoxRect.strokeColor = new paper.Color("red")
            this.boundingBoxRect.strokeWidth = 1 / this.zoomLevel

            if (this.currStrokeBoundingBoxRect) {
                this.currStrokeBoundingBoxRect.remove()
            }
            this.currStrokeBoundingBoxRect = new paper.Path.Rectangle(
                strokeBoundingBox.min.sub(this._brushCursorOffset),
                strokeBoundingBox.max.sub(this._brushCursorOffset),
            )
            this.currStrokeBoundingBoxRect.strokeColor = new paper.Color("green")
            this.currStrokeBoundingBoxRect.strokeWidth = 1 / this.zoomLevel
        }

        return true
    }

    override onMouseDown(event: ToolMouseEvent): boolean {
        super.onMouseDown(event)
        this.startBrushStroke(event.point)
        return false
    }

    override onMouseUp(event: ToolMouseEvent): boolean {
        super.onMouseUp(event)
        this.endBrushStroke(event.point)
        return false
    }

    override onMouseDrag(event: ToolMouseEvent): boolean {
        super.onMouseDrag(event)
        this.lastHoverPosition = Vector2.fromVector2Like(event.point)
        this.drawBrushStroke(event.lastPoint, event.point)
        return false
    }

    override onMouseMove(event: ToolMouseEvent): boolean {
        super.onMouseMove(event)
        this.lastHoverPosition = Vector2.fromVector2Like(event.point)
        return false
    }

    override onKeyDown(event: KeyboardEvent): boolean {
        super.onKeyDown(event)
        if (event.key === this.drawLineKey) {
            this.drawLine = true
        }
        return false
    }

    override onKeyUp(event: KeyboardEvent): boolean {
        super.onKeyUp(event)
        if (event.key === this.drawLineKey) {
            this.drawLine = false
        }
        return false
    }

    private set lastHoverPosition(value: Vector2 | undefined) {
        if ((!this._lastHoverPosition && !value) || (this._lastHoverPosition && value && this._lastHoverPosition.equals(value))) {
            return
        }
        this._lastHoverPosition = value
        this.hoverPositionChanged.emit(value)
    }

    get lastHoverPosition(): Vector2 | undefined {
        return this._lastHoverPosition
    }

    private freeData() {
        this.preStrokeImage?.release()
        this.preStrokeImage = undefined
        this.currentStrokeImage?.release()
        this.currentStrokeImage = undefined
    }

    private getCursorPosition(toolPosition: Vector2Like): Vector2 {
        return new Vector2(Math.floor(this.brushCursorOffset.x + toolPosition.x), Math.floor(this.brushCursorOffset.y + toolPosition.y))
    }

    private updateBrushCircle(force = false) {
        const radius = this.brushSettings.brushWidth / 2
        if (force || !this.brushCircle || this.brushCircleRadius !== radius) {
            this.brushCircleRadius = radius
            if (this.brushCircle) {
                this.brushCircle.remove()
            }
            this.beginPaperCreation()
            this.brushCircle = new paper.Path.Circle(new paper.Point(0, 0), radius)
            this.brushCircle.strokeColor = new paper.Color("white")
            this.brushCircle.strokeWidth = 1 / this.zoomLevel
            // this.brushCircle.blendMode = "difference"   // this has no effect for some reason (probably because the circle and the background image are on different canvases)
            this.brushCircle.visible = false
        }
        const cursorPosition = this.canvasCursorPosition
        this.brushCircle.visible = this._lastHoverPosition !== undefined && this.selected
        if (this.brushCircle.visible) {
            this.brushCircle.position = new paper.Point(cursorPosition)
        }
        this.brushLine.visible = this.brushCircle.visible && this.drawLine
        if (this.brushLine.visible) {
            this.brushLine.strokeWidth = 1 / this.zoomLevel
            this.brushLine.segments[0].point = new paper.Point(this.lastStrokePoint!.x, this.lastStrokePoint!.y)
            this.brushLine.segments[1].point = new paper.Point(cursorPosition.x, cursorPosition.y)
        }
    }

    private startBrushStroke(point: Vector2Like) {
        const position = this.getCursorPosition(point)
        if (!this._brushStartPosition) {
            this._brushStartPosition = position
            this._brushCurrentPosition = this._brushStartPosition.clone()
        }
        if (this.drawLine) {
            this.brushStrokeGeometry.startNewStroke()
            this.brushStrokeGeometry.pushStrokePoints(Vector2.fromVector2Like(this.lastStrokePoint).addInPlace(this.brushCursorOffset), position)
        } else {
            this._isDrawing = true
            this.brushStrokeGeometry.startNewStroke()
            this.brushStrokeGeometry.pushStrokePoints(position)
        }
        this.lastStrokePoint = point
        this.isNewStroke = true
        this.onStrokePathChanged()
    }

    private drawBrushStroke(_lastPoint: Vector2Like, point: Vector2Like) {
        if (!this._isDrawing) {
            return
        }
        const position = this.getCursorPosition(point)
        const minStrokeDistanceInScreenPixels = 3
        const minStrokeDistance = minStrokeDistanceInScreenPixels / this.zoomLevel
        const delta = Vector2.fromVector2Like(point).sub(this.lastStrokePoint)
        if (delta.norm() >= minStrokeDistance) {
            this.lastStrokePoint = point
            // set up stroke geometry
            this.brushStrokeGeometry.pushStrokePoints(position)
            this.onStrokePathChanged()
        }
        this._brushCurrentPosition?.setFromVector2Like(position)
    }

    private endBrushStroke(_point: Vector2Like) {
        if (!this._isDrawing) {
            return
        }
        this.finalizeStroke()
        this._brushStartPosition = null
        this._isDrawing = false
    }

    private onStrokePathChanged() {
        this.strokePathChanged = true
        this.brushStrokeUpdated.emit()
    }

    private finalizeStroke() {
        this.brushStrokeCompleted.emit()
    }

    private _brushSettings = new BrushSettings()
    private lastStrokePoint: Vector2Like = {x: 0, y: 0}
    private _isDrawing = false
    private strokePathChanged = false

    private brushStrokeGeometry: BrushStrokeGeometry
    private brushBlendCurrentStroke: BrushBlendCurrentStroke
    protected blitter: PainterBlitterRef
    private preStrokeImage?: ManagedImageRef
    private currentStrokeImage?: ManagedImageRef
    private isNewStroke = true
    private _boundingBox = new Box2()

    private _lastHoverPosition: Vector2 | undefined = undefined
    private _brushStartPosition: Vector2 | null = null
    private _brushCurrentPosition: Vector2 | null = null
    private _brushCursorOffset = new Vector2(0, 0)
    private brushCircle: paper.Path.Circle | null = null
    private brushLine: paper.Path.Line
    private brushCircleRadius = 0
    private boundingBoxRect: paper.Path.Rectangle | null = null
    private currStrokeBoundingBoxRect: paper.Path.Rectangle | null = null
    private drawLine = false
    private readonly drawLineKey = "Shift"
}

export enum BrushMode {
    Add = "add",
    Subtract = "subtract",
}

export class BrushSettings {
    brushMode = BrushMode.Add
    brushOpacity = 1
    brushWidth = 150
    brushHardness = 1
}
