<cm-drop-files (filesDropped)="handleDroppedFiles($event)">
    <ng-content select="[cm-background]"></ng-content>
    @if ($entity(); as entity) {
        <cm-card
            [link]="$entityLink()"
            [queryParamsHandling]="$entityQueryParamsHandling() ?? ''"
            [squareFormat]="$squareFormat()"
            [defaultThumbnail]="false"
            (click)="selectEntity.emit(entity)"
        >
            <ng-container cm-thumbnail>
                @if ($multiThumbnailIds(); as multiThumbnailIds) {
                    @if (multiThumbnailIds.length <= 1) {
                        <cm-data-object-thumbnail [dataObjectId]="multiThumbnailIds.at(0)" [layout]="ThumbnailLayout.Cover"></cm-data-object-thumbnail>
                    } @else {
                        <cm-four-square-layout>
                            @for (index of [0, 1, 2, 3]; track index) {
                                <cm-data-object-thumbnail
                                    [dataObjectId]="multiThumbnailIds.at(index)"
                                    [layout]="ThumbnailLayout.Cover"
                                ></cm-data-object-thumbnail>
                            }
                        </cm-four-square-layout>
                    }
                } @else {
                    @if ($defaultThumbnail()) {
                        <cm-data-object-thumbnail [dataObjectId]="entity.galleryImage?.id" [layout]="ThumbnailLayout.Cover"></cm-data-object-thumbnail>
                    }
                }
            </ng-container>
            <ng-container cm-top-left>
                <ng-content select="[cm-top-left]"></ng-content>
            </ng-container>
            <ng-container cm-top-right>
                <ng-content select="[cm-top-right]"></ng-content>
            </ng-container>
            <ng-container cm-bottom-left>
                <div class="cm-bottom-left-container">
                    <ng-content select="[cm-bottom-left]"></ng-content>
                </div>
            </ng-container>
            <ng-container cm-bottom-right>
                <ng-content select="[cm-bottom-right]"></ng-content>
            </ng-container>
            <ng-container cm-title>
                @if ($defaultTitle()) {
                    {{ entity.legacyId ?? "" }} -
                    @if (hasName(entity)) {
                        <span [matTooltip]="$showTooltip() ? entity.name : ''">
                            {{ entity.name }}
                        </span>
                    } @else {
                        (Untitled)
                    }
                }
                <!--Custom title-->
                <ng-content select="[cm-title]"></ng-content>
            </ng-container>
            <ng-container cm-subtitle>
                <!--Default subtitle-->
                @if ($defaultSubtitle()) {
                    @if ($can().read.organization()) {
                        @if (entity?.organization) {
                            <span class="cm-customer">
                                {{ entity.organization?.name ?? "(No customer)" }}
                            </span>
                        }
                    } @else {
                        <div class="cm-article-id">
                            @if (hasArticleId(entity)) {
                                {{ entity.articleId }}
                            } @else {
                                (No Article ID)
                            }
                        </div>
                    }
                }
                <!--Custom subtitle-->
                <ng-content select="[cm-subtitle]"></ng-content>
            </ng-container>
            <ng-container cm-details-bottom-right>
                <ng-content select="[cm-details-bottom-right]"></ng-content>
            </ng-container>
        </cm-card>
    }
    @if (!$entity(); as entity) {
        <cm-card-placeholder></cm-card-placeholder>
    }
</cm-drop-files>
