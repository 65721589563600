<div class="cm-template-tree-container">
    <cdk-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="cm-template-tree-node">
            <cm-template-tree-item [treeNode]="node" [templateTree]="this"> </cm-template-tree-item>
            @if (hasChildren(node)) {
                <div [class.cm-template-tree-invisible]="!treeControl.isExpanded(node)" class="cm-child-nodes">
                    <ng-container cdkTreeNodeOutlet></ng-container>
                    @if (needsAddWidget(node)) {
                        <cm-template-tree-add
                            [treeNode]="node"
                            [templateTree]="this"
                            (mouseenter)="highlightNode(node, 'nodeHover', true)"
                            (mouseleave)="highlightNode(node, 'nodeHover', false)"
                        ></cm-template-tree-add>
                    }
                </div>
            }
        </cdk-nested-tree-node>
    </cdk-tree>
</div>
<cm-template-tree-filler [templateTree]="this"></cm-template-tree-filler>
