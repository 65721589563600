<cm-section-title>
    <ng-container cm-icon>
        <ng-content select="[cm-icon]"> </ng-content>
    </ng-container>
    <ng-container cm-title>
        <ng-content select="[cm-title]"> </ng-content>
    </ng-container>
</cm-section-title>
<cm-section-content [class.cm-section-content--indent]="$indentContent()">
    <ng-container cm-content>
        <ng-content select="[cm-content]"> </ng-content>
    </ng-container>
</cm-section-content>
