import {AsyncPipe, NgClass} from "@angular/common"
import {Component, DestroyRef, inject, input, signal} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {LoadingSpinnerIconComponent} from "@app/common/components/progress"
import {NamedConfiguratorVariant} from "@cm/pricing"
import {ConfigInfo, unwrapInterfaceId, VariantInfo} from "@cm/template-nodes"
import {ConfigMenuService} from "@common/components/viewers/configurator/config-menu/services/config-menu.service"
import {TippyDirective} from "@ngneat/helipopper"
import {Observable} from "rxjs"

@Component({
    selector: "cm-config-button",
    templateUrl: "./config-button.component.html",
    styleUrls: ["./config-button.component.scss"],
    imports: [TippyDirective, AsyncPipe, NgClass, LoadingSpinnerIconComponent],
})
export class ConfigButtonComponent {
    readonly $asLabel = input.required<boolean>({alias: "asLabel"})
    readonly $tooltipContainer = input<HTMLDivElement | undefined>(undefined, {alias: "tooltipContainer"})
    readonly $config = input.required<ConfigInfo | undefined>({alias: "config"})
    readonly $variant = input.required<VariantInfo | undefined>({alias: "variant"})
    readonly $selectedVariant = input<VariantInfo | undefined>(undefined, {alias: "selectedVariant"})
    readonly $notAvailableTooltip = input<string>("Variant not available", {alias: "notAvailableTooltip"})
    readonly $useCaptions = input<boolean>(false, {alias: "useCaptions"})

    readonly $loading = signal<boolean>(false)

    readonly configMenuService = inject(ConfigMenuService)
    readonly destroyRef = inject(DestroyRef)

    constructor() {
        this.configMenuService.synchronizing$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((synchronizing) => {
            if (!synchronizing) this.$loading.set(false)
        })

        this.configMenuService.configSelected$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({config, variant}) => {
            const buttonConfig = this.$config()
            if (!buttonConfig) return
            const buttonVariant = this.$variant()
            if (!buttonVariant) return

            if (config.props.id === buttonConfig.props.id && variant.id === buttonVariant.id) this.$loading.set(true)
        })
    }

    getConfigVariantColor(): string {
        const variant = this.$variant()
        if (!variant) return "#898989"
        return this.configMenuService.configVariantColors[variant.id]
    }

    fetchThumbnailForDataObject(id?: number): Observable<string | null> {
        return this.configMenuService.fetchThumbnailForDataObject(id)
    }

    /**The CDK dragging tools did not work well here. They make the items themselves move and dragging outside of the parent
     * was problematic. So we use the native drag and drop API instead.
     *
     * Unwrapped ids are required, because a config group can be referenced from different templates, which changes the external id.
     * Since the group id is used to identify nodes in the pricing graph, only the internal / unwrapped id works.
     */
    onDragStart(event: DragEvent, variant: VariantInfo | undefined, config: ConfigInfo | undefined): void {
        if (!variant || !config) {
            return
        }
        const configuratorVariant: NamedConfiguratorVariant = {
            groupId: unwrapInterfaceId(config.props.id)[1],
            groupName: config.props.name,
            variantId: variant.id,
            variantName: variant.name,
        }
        if (event.dataTransfer) {
            event.dataTransfer.setData("text/plain", JSON.stringify(configuratorVariant))
        }
    }
}
