export class AnnotationStyleRegistry {
    private static instance: AnnotationStyleRegistry
    private styles: Map<string, string> = new Map()

    static getInstance(): AnnotationStyleRegistry {
        if (!AnnotationStyleRegistry.instance) {
            AnnotationStyleRegistry.instance = new AnnotationStyleRegistry()
        }
        return AnnotationStyleRegistry.instance
    }

    registerStyles(identifier: string, css: string): void {
        if (this.styles.has(identifier)) return
        this.injectSingleStyleIntoNode(document.head, identifier, css) //head is always available, add the style once
        this.styles.set(identifier, css)
    }

    injectIntoHead(): void {
        this.injectAllStylesIntoNode(document.head)
    }

    injectIntoShadowDom(): void {
        const configuratorMain = document.querySelector("cm-configurator-main")
        const configuratorNew = document.querySelector("cm-configurator")

        if (configuratorMain?.shadowRoot) this.injectAllStylesIntoNode(configuratorMain.shadowRoot)
        if (configuratorNew?.shadowRoot) this.injectAllStylesIntoNode(configuratorNew.shadowRoot)
    }

    private injectSingleStyleIntoNode(node: ShadowRoot | HTMLHeadElement, identifier: string, css: string): void {
        const style = node.querySelector(`style[data-style-id="${identifier}"]`)
        if (style) {
            style.textContent = css
        } else {
            const newStyle = document.createElement("style")
            newStyle.textContent = css
            newStyle.dataset.styleId = identifier
            node.appendChild(newStyle)
        }
    }

    private injectAllStylesIntoNode(node: ShadowRoot | HTMLHeadElement): void {
        this.styles.forEach((css, identifier) => {
            this.injectSingleStyleIntoNode(node, identifier, css)
        })
    }
}
