import {Component, inject} from "@angular/core"
import {SelectModelItemFragment} from "@app/platform/components/models/select-model/select-model.generated"
import {SelectDialogComponent} from "@common/components/dialogs/select-dialog/select-dialog.component"
import {SearchComponent} from "@common/components/inputs/search/search.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {ModelFilterInput} from "@generated"
import {BaseSelectComponent} from "@platform/components/base/base-select/base-select.component"
import {SelectModelItemsGQL} from "@platform/components/models/select-model/select-model.generated"

@Component({
    selector: "cm-select-model",
    templateUrl: "./select-model.component.html",
    styleUrls: ["./select-model.component.scss"],
    imports: [SelectDialogComponent, SearchComponent],
})
export class SelectModelComponent extends BaseSelectComponent<SelectModelItemFragment, ModelFilterInput> {
    $data = this.implementDataLoader(fetchThrowingErrors(inject(SelectModelItemsGQL)))
}
