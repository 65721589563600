<cm-routed-dialog [dialogSize]="DialogSize.Small">
    <ng-container cm-overlay-content>
        <cm-details-dialog-layout [loadError]="$loadError()" [isLoaded]="!!$item()">
            <ng-container cm-title>
                <cm-title-section [title]="title" [isLoaded]="$item() !== undefined" (onClosed)="closeModal()"></cm-title-section>
            </ng-container>
            <ng-container cm-buttons>
                @if ($item(); as jobTask) {
                    <button class="cm-simple-window-header-button cm-item-menu" [matMenuTriggerFor]="menuOptions">
                        <i class="fas fa-ellipsis-v"></i>
                    </button>
                    <mat-menu #menuOptions="matMenu">
                        <div mat-menu-item [style.cursor]="'pointer'" [matMenuTriggerFor]="copyItemsMenu">
                            <i class="far fa-copy"></i>
                            Copy
                        </div>
                        <cm-copy-value-to-clipboard-menu #copyItemsMenu [copyItems]="$copyItems()"></cm-copy-value-to-clipboard-menu>
                    </mat-menu>
                }
            </ng-container>
            <ng-container cm-main>
                <cm-section>
                    <ng-container cm-icon><i class="far fa-message"></i></ng-container>
                    <ng-container cm-title>Message</ng-container>
                    <ng-container cm-content>
                        <mat-form-field [cmIsLoading]="!$item()">
                            <textarea matInput id="message" [ngModel]="$item()?.message ?? ''" disabled></textarea>
                        </mat-form-field>
                    </ng-container>
                </cm-section>
                <cm-files-section [item]="$item()" class="cm-files"></cm-files-section>
            </ng-container>
            <cm-organization-select
                cm-sidebar
                [cmIsLoading]="!$item()"
                [updateOrganization]="updateItem.bind(this)"
                [organizationId]="$item()?.organization?.id ?? null"
                [disabled]="true"
            ></cm-organization-select>
            <mat-form-field cm-sidebar [cmIsLoading]="!$item()">
                <mat-label>Created by</mat-label>
                <input matInput id="createdBy" [ngModel]="$item()?.createdBy?.name" disabled />
            </mat-form-field>
            <mat-form-field cm-sidebar [cmIsLoading]="formattedClaimedByField === undefined">
                <mat-label>Claimed by</mat-label>
                <input matInput id="claimedBy" [ngModel]="formattedClaimedByField" disabled />
            </mat-form-field>
            <mat-form-field cm-sidebar [cmIsLoading]="!$item()">
                <mat-label>State</mat-label>
                <input matInput id="state" [ngModel]="$item()?.state" disabled />
            </mat-form-field>
            <mat-form-field cm-sidebar [cmIsLoading]="!$item()">
                <mat-label>Started</mat-label>
                <input matInput id="started" [ngModel]="$item()?.started" disabled />
            </mat-form-field>
            <mat-form-field cm-sidebar [cmIsLoading]="!$item()">
                <mat-label>Finished</mat-label>
                <input matInput id="finished" [ngModel]="$item()?.finished" disabled />
            </mat-form-field>
            @if ($item()?.deferredUntil; as deferredUntil) {
                <mat-form-field cm-sidebar [cmIsLoading]="!$item()">
                    <mat-label>Deferred until</mat-label>
                    <input matInput id="deferredUntil" [ngModel]="deferredUntil" disabled />
                </mat-form-field>
            }
        </cm-details-dialog-layout>
    </ng-container>
</cm-routed-dialog>
