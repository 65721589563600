import {extensionForContentType, isValidContentType} from "@cm/utils/content-types"
import {DataObjectDefinitions} from "@cm/utils/data-object"
import {MaterialMapsExporter} from "@cm/material-nodes/material-maps-exporter"
import {DataObjectAssignmentType} from "@cm/utils/data-object-assignment"

const CM_TO_INCH = 1 / 2.54

export namespace SchemaDerivedFilename {
    export type MapType = MaterialMapsExporter.TextureType

    const RENDER_ASSET_TYPE = [
        DataObjectAssignmentType.MaterialTileableRender,
        DataObjectAssignmentType.MaterialThumbnail_10x10,
        DataObjectAssignmentType.MaterialThumbnail_20x20,
        DataObjectAssignmentType.MaterialThumbnail_30x30,
        DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
        DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
        DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
    ] as const

    export const MAPS_EXPORT_MAP_ASSET_TYPE = `${DataObjectAssignmentType.MaterialMapsExport}Map` as const
    export const MAPS_EXPORT_INFO_ASSET_TYPE = `${DataObjectAssignmentType.MaterialMapsExport}Info` as const

    export type RenderAssetType = (typeof RENDER_ASSET_TYPE)[number]
    export type MapsExportAssetType = typeof DataObjectAssignmentType.MaterialMapsExport
    export type MapsExportMapAssetType = typeof MAPS_EXPORT_MAP_ASSET_TYPE
    export type MapsExportInfoAssetType = typeof MAPS_EXPORT_INFO_ASSET_TYPE
    export type AssetType = RenderAssetType | MapsExportAssetType | MapsExportMapAssetType | MapsExportInfoAssetType

    export function isAssignmentTypeValidAssetType(x: DataObjectAssignmentType): x is RenderAssetType | MapsExportAssetType {
        return ([...RENDER_ASSET_TYPE, DataObjectAssignmentType.MaterialMapsExport] as Readonly<DataObjectAssignmentType[]>).includes(x)
    }

    export function isRenderAssetType(x: AssetType): x is RenderAssetType {
        return RENDER_ASSET_TYPE.includes(x as RenderAssetType)
    }

    export type MaterialData = {
        articleId: string | null
        name: string | null
        rangeInfo: {
            name: string | null
            otherInfo: string | null
        }
        widthCm: number | null
        heightCm: number | null
    }

    type AssetDataT_<T extends AssetType> = {
        type: T
        organizationName: string
        materialData: MaterialData
    }
    export type ImageResolution = DataObjectDefinitions.DerivedImageResolution | "original"
    export type ImageFormat = DataObjectDefinitions.ImageFormat

    export type AssetDataT<T extends AssetType> = T extends MapsExportAssetType
        ? AssetDataT_<T> & {exportConfig: MaterialMapsExporter.Config | MaterialMapsExporter.Request}
        : T extends MapsExportMapAssetType
          ? AssetDataT_<T> & {mapType: MapType; conversionConfig: MaterialMapsExporter.ConversionConfig | MaterialMapsExporter.ConversionRequest}
          : T extends MapsExportInfoAssetType
            ? AssetDataT_<T> & {conversionInfo: MaterialMapsExporter.ConversionInfoRequest}
            : AssetDataT_<T> & {imageResolution: ImageResolution; imageFormat: ImageFormat}
    export type AssetData<T extends AssetType = AssetType> = AssetDataT<T>

    export type AssetDataMapsExport = AssetData<MapsExportAssetType>

    export const UNDEFINED_FIELD_SYMBOL = "UNDEFINED"
    export type FilenameSchema = string
    export type OrganizationName = string

    type FilenameSchemaEntry_<T extends AssetType> = {
        type: T
        schema: FilenameSchema
        isOtherInfoSet?: boolean
    }
    type FilenameSchemaEntry<T extends AssetType = AssetType> = T extends MapsExportAssetType
        ? FilenameSchemaEntry_<T> & {
              exportFormat?: MaterialMapsExporter.ConversionConfig["format"]
              exportResolution?: MaterialMapsExporter.ConversionConfig["resolution"]
          }
        : T extends MapsExportMapAssetType
          ? FilenameSchemaEntry_<T> & {
                exportFormat?: MaterialMapsExporter.ConversionConfig["format"]
                exportResolution?: MaterialMapsExporter.ConversionConfig["resolution"]
                mapType?: MapType
            }
          : T extends MapsExportInfoAssetType
            ? FilenameSchemaEntry_<T> & {
                  exportFormat?: MaterialMapsExporter.ConversionConfig["format"]
                  exportResolution?: MaterialMapsExporter.ConversionConfig["resolution"]
                  fileFormat?: MaterialMapsExporter.ConversionInfoRequest["format"]
              }
            : FilenameSchemaEntry_<T> & {
                  imageFormat?: AssetData<RenderAssetType>["imageFormat"]
                  imageResolution?: AssetData<RenderAssetType>["imageResolution"]
              }

    export const ASSETS_FILENAME_SCHEMAS_FOR_ORGANIZATION: Record<OrganizationName, FilenameSchemaEntry[]> = {
        maharam: [
            // maps exports
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "MHRM_$MATERIAL_RANGE_NAME$_$MATERIAL_FORMATTED_ARTICLE_ID$_$EXPORT_FORMAT$_$EXPORT_RESOLUTION$",
                isOtherInfoSet: false,
                exportResolution: "original",
            },
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "MHRM_$MATERIAL_RANGE_NAME$_$MATERIAL_FORMATTED_ARTICLE_ID$_$EXPORT_FORMAT$_72dpi",
                isOtherInfoSet: false,
                exportResolution: "dpi72",
            },
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "MHRM_$MATERIAL_RANGE_OTHER_INFO$_$MATERIAL_RANGE_NAME$_$MATERIAL_FORMATTED_ARTICLE_ID$_$EXPORT_FORMAT$_$EXPORT_RESOLUTION$",
                isOtherInfoSet: true,
                exportResolution: "original",
            },
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "MHRM_$MATERIAL_RANGE_OTHER_INFO$_$MATERIAL_RANGE_NAME$_$MATERIAL_FORMATTED_ARTICLE_ID$_$EXPORT_FORMAT$_72dpi",
                isOtherInfoSet: true,
                exportResolution: "dpi72",
            },

            // tileable renders
            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "MHRM_$MATERIAL_RANGE_NAME$_$MATERIAL_FORMATTED_ARTICLE_ID$_tile.$IMAGE_FORMAT$",
                isOtherInfoSet: false,
                imageResolution: "original",
            },
            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "MHRM_$MATERIAL_RANGE_OTHER_INFO$_$MATERIAL_RANGE_NAME$_$MATERIAL_FORMATTED_ARTICLE_ID$_tile.$IMAGE_FORMAT$",
                isOtherInfoSet: true,
                imageResolution: "original",
            },

            // thumbnails
            // 7x7 inches
            {
                type: DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
                schema: "MHRM_$MATERIAL_RANGE_NAME$_$MATERIAL_FORMATTED_ARTICLE_ID$_detail.$IMAGE_FORMAT$",
                isOtherInfoSet: false,
                imageResolution: "original",
            },
            {
                type: DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
                schema: "MHRM_$MATERIAL_RANGE_OTHER_INFO$_$MATERIAL_RANGE_NAME$_$MATERIAL_FORMATTED_ARTICLE_ID$_detail.$IMAGE_FORMAT$",
                isOtherInfoSet: true,
                imageResolution: "original",
            },

            // 9x9 inches
            {
                type: DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
                schema: "MHRM_$MATERIAL_RANGE_NAME$_$MATERIAL_FORMATTED_ARTICLE_ID$_detail.$IMAGE_FORMAT$",
                isOtherInfoSet: false,
            },
            {
                type: DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
                schema: "MHRM_$MATERIAL_RANGE_OTHER_INFO$_$MATERIAL_RANGE_NAME$_$MATERIAL_FORMATTED_ARTICLE_ID$_detail.$IMAGE_FORMAT$",
                isOtherInfoSet: true,
            },

            // 10x10 cm
            {
                type: DataObjectAssignmentType.MaterialThumbnail_10x10,
                schema: "MHRM_$MATERIAL_RANGE_NAME$_$MATERIAL_FORMATTED_ARTICLE_ID$_detail.$IMAGE_FORMAT$",
                isOtherInfoSet: false,
            },
            {
                type: DataObjectAssignmentType.MaterialThumbnail_10x10,
                schema: "MHRM_$MATERIAL_RANGE_OTHER_INFO$_$MATERIAL_RANGE_NAME$_$MATERIAL_FORMATTED_ARTICLE_ID$_detail.$IMAGE_FORMAT$",
                isOtherInfoSet: true,
            },

            // 16x16 inches
            {
                type: DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
                schema: "MHRM_$MATERIAL_RANGE_NAME$_$MATERIAL_FORMATTED_ARTICLE_ID$_detail.$IMAGE_FORMAT$",
                isOtherInfoSet: false,
            },
            {
                type: DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
                schema: "MHRM_$MATERIAL_RANGE_OTHER_INFO$_$MATERIAL_RANGE_NAME$_$MATERIAL_FORMATTED_ARTICLE_ID$_detail.$IMAGE_FORMAT$",
                isOtherInfoSet: true,
            },

            // 20x20 cm
            {
                type: DataObjectAssignmentType.MaterialThumbnail_20x20,
                schema: "MHRM_$MATERIAL_RANGE_NAME$_$MATERIAL_FORMATTED_ARTICLE_ID$_detail.$IMAGE_FORMAT$",
                isOtherInfoSet: false,
            },
            {
                type: DataObjectAssignmentType.MaterialThumbnail_20x20,
                schema: "MHRM_$MATERIAL_RANGE_OTHER_INFO$_$MATERIAL_RANGE_NAME$_$MATERIAL_FORMATTED_ARTICLE_ID$_detail.$IMAGE_FORMAT$",
                isOtherInfoSet: true,
            },

            // 30x30 cm
            {
                type: DataObjectAssignmentType.MaterialThumbnail_30x30,
                schema: "MHRM_$MATERIAL_RANGE_NAME$_$MATERIAL_FORMATTED_ARTICLE_ID$_detail.$IMAGE_FORMAT$",
                isOtherInfoSet: false,
            },
            {
                type: DataObjectAssignmentType.MaterialThumbnail_30x30,
                schema: "MHRM_$MATERIAL_RANGE_OTHER_INFO$_$MATERIAL_RANGE_NAME$_$MATERIAL_FORMATTED_ARTICLE_ID$_detail.$IMAGE_FORMAT$",
                isOtherInfoSet: true,
            },
        ],

        designtex: [
            // maps exports
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "$MATERIAL_FORMATTED_ARTICLE_ID$-PBR-EXR-high-resolution",
                exportFormat: "exr",
                exportResolution: "original",
            },
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "$MATERIAL_FORMATTED_ARTICLE_ID$-PBR-JPG-high-resolution",
                exportFormat: "jpeg",
                exportResolution: "original",
            },
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "$MATERIAL_FORMATTED_ARTICLE_ID$-PBR-TIFF-high-resolution",
                exportFormat: "tiff",
                exportResolution: "original",
            },
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "$MATERIAL_FORMATTED_ARTICLE_ID$-PBR-PNG-high-resolution",
                exportFormat: "png",
                exportResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "$MATERIAL_FORMATTED_ARTICLE_ID$-PBR-EXR-low-resolution",
                exportFormat: "exr",
                exportResolution: "dpi72",
            },
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "$MATERIAL_FORMATTED_ARTICLE_ID$-PBR-JPG-low-resolution",
                exportFormat: "jpeg",
                exportResolution: "dpi72",
            },
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "$MATERIAL_FORMATTED_ARTICLE_ID$-PBR-TIFF-low-resolution",
                exportFormat: "tiff",
                exportResolution: "dpi72",
            },
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "$MATERIAL_FORMATTED_ARTICLE_ID$-PBR-PNG-low-resolution",
                exportFormat: "png",
                exportResolution: "dpi72",
            },

            // tileable renders
            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "$MATERIAL_FORMATTED_ARTICLE_ID$-Tileable-high-resolution.$IMAGE_FORMAT$",
                imageResolution: "original",
            },
            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "$MATERIAL_FORMATTED_ARTICLE_ID$-Tileable-low-resolution.$IMAGE_FORMAT$",
                imageResolution: "px1000",
            },
        ],

        keilhauer: [
            // maps exports
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "Keilhauer_$MATERIAL_RANGE_NAME$_$MATERIAL_NAME$_PBR_EXR_high_resolution",
                exportFormat: "exr",
                exportResolution: "original",
            },
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "Keilhauer_$MATERIAL_RANGE_NAME$_$MATERIAL_NAME$_PBR_JPG_high_resolution",
                exportFormat: "jpeg",
                exportResolution: "original",
            },
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "Keilhauer_$MATERIAL_RANGE_NAME$_$MATERIAL_NAME$_PBR_TIFF_high_resolution",
                exportFormat: "tiff",
                exportResolution: "original",
            },
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "Keilhauer_$MATERIAL_RANGE_NAME$_$MATERIAL_NAME$_PBR_PNG_high_resolution",
                exportFormat: "png",
                exportResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "Keilhauer_$MATERIAL_RANGE_NAME$_$MATERIAL_NAME$_PBR_EXR_low_resolution",
                exportFormat: "exr",
                exportResolution: "dpi72",
            },
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "Keilhauer_$MATERIAL_RANGE_NAME$_$MATERIAL_NAME$_PBR_JPG_low_resolution",
                exportFormat: "jpeg",
                exportResolution: "dpi72",
            },
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "Keilhauer_$MATERIAL_RANGE_NAME$_$MATERIAL_NAME$_PBR_TIFF_low_resolution",
                exportFormat: "tiff",
                exportResolution: "dpi72",
            },
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "Keilhauer_$MATERIAL_RANGE_NAME$_$MATERIAL_NAME$_PBR_PNG_low_resolution",
                exportFormat: "png",
                exportResolution: "dpi72",
            },

            // tileable renders
            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "Keilhauer_$MATERIAL_RANGE_NAME$_$MATERIAL_NAME$_Tileable_high_resolution.$IMAGE_FORMAT$",
                imageResolution: "original",
            },
            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "Keilhauer_$MATERIAL_RANGE_NAME$_$MATERIAL_NAME$_Tileable_low_resolution.$IMAGE_FORMAT$",
                imageResolution: "px1000",
            },
        ],

        teknion: [
            // maps exports
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "$MATERIAL_NAME$",
            },

            // maps exports maps
            {
                type: MAPS_EXPORT_MAP_ASSET_TYPE,
                schema: "$MATERIAL_NAME$-$MAP_TYPE$.exr",
                exportFormat: "exr",
            },

            {
                type: MAPS_EXPORT_MAP_ASSET_TYPE,
                schema: "$MATERIAL_NAME$-$MAP_TYPE$.jpg",
                exportFormat: "jpeg",
            },

            {
                type: MAPS_EXPORT_MAP_ASSET_TYPE,
                schema: "$MATERIAL_NAME$-$MAP_TYPE$.tif",
                exportFormat: "tiff",
            },

            // maps exports info
            {
                type: MAPS_EXPORT_INFO_ASSET_TYPE,
                schema: "$MATERIAL_NAME$-info.$INFO_FILE_FORMAT$",
            },

            // tileable renders
            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "$MATERIAL_NAME$.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "$MATERIAL_NAME$.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "$MATERIAL_NAME$.tif",
                imageFormat: "tif",
            },

            // thumbnails
            // 7x7 inches
            {
                type: DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
                schema: "$MATERIAL_NAME$.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
                schema: "$MATERIAL_NAME$.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
                schema: "$MATERIAL_NAME$.tif",
                imageFormat: "tif",
            },

            // 9x9 inches
            {
                type: DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
                schema: "$MATERIAL_NAME$.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
                schema: "$MATERIAL_NAME$.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
                schema: "$MATERIAL_NAME$.tif",
                imageFormat: "tif",
            },

            // 10x10 cm
            {
                type: DataObjectAssignmentType.MaterialThumbnail_10x10,
                schema: "$MATERIAL_NAME$.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_10x10,
                schema: "$MATERIAL_NAME$.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_10x10,
                schema: "$MATERIAL_NAME$.tif",
                imageFormat: "tif",
            },

            // 16x16 inches
            {
                type: DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
                schema: "$MATERIAL_NAME$.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
                schema: "$MATERIAL_NAME$.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
                schema: "$MATERIAL_NAME$.tif",
                imageFormat: "tif",
            },

            // 20x20 cm
            {
                type: DataObjectAssignmentType.MaterialThumbnail_20x20,
                schema: "$MATERIAL_NAME$.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_20x20,
                schema: "$MATERIAL_NAME$.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_20x20,
                schema: "$MATERIAL_NAME$.tif",
                imageFormat: "tif",
            },

            // 30x30 cm
            {
                type: DataObjectAssignmentType.MaterialThumbnail_30x30,
                schema: "$MATERIAL_NAME$.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_30x30,
                schema: "$MATERIAL_NAME$.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_30x30,
                schema: "$MATERIAL_NAME$.tif",
                imageFormat: "tif",
            },
        ],

        "luum textiles": [
            // maps exports
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "$MATERIAL_NAME$",
            },

            // maps exports maps
            {
                type: MAPS_EXPORT_MAP_ASSET_TYPE,
                schema: "$MATERIAL_NAME$-$MAP_TYPE$.exr",
                exportFormat: "exr",
            },

            {
                type: MAPS_EXPORT_MAP_ASSET_TYPE,
                schema: "$MATERIAL_NAME$-$MAP_TYPE$.jpg",
                exportFormat: "jpeg",
            },

            {
                type: MAPS_EXPORT_MAP_ASSET_TYPE,
                schema: "$MATERIAL_NAME$-$MAP_TYPE$.tif",
                exportFormat: "tiff",
            },

            // maps exports info
            {
                type: MAPS_EXPORT_INFO_ASSET_TYPE,
                schema: "$MATERIAL_NAME$-info.$INFO_FILE_FORMAT$",
            },

            // tileable renders
            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "$MATERIAL_NAME$.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "$MATERIAL_NAME$.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "$MATERIAL_NAME$.tif",
                imageFormat: "tif",
            },

            // thumbnails
            // 7x7 inches
            {
                type: DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
                schema: "$MATERIAL_NAME$.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
                schema: "$MATERIAL_NAME$.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
                schema: "$MATERIAL_NAME$.tif",
                imageFormat: "tif",
            },

            // 9x9 inches
            {
                type: DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
                schema: "$MATERIAL_NAME$.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
                schema: "$MATERIAL_NAME$.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
                schema: "$MATERIAL_NAME$.tif",
                imageFormat: "tif",
            },

            // 10x10 cm
            {
                type: DataObjectAssignmentType.MaterialThumbnail_10x10,
                schema: "$MATERIAL_NAME$.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_10x10,
                schema: "$MATERIAL_NAME$.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_10x10,
                schema: "$MATERIAL_NAME$.tif",
                imageFormat: "tif",
            },

            // 16x16 inches
            {
                type: DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
                schema: "$MATERIAL_NAME$.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
                schema: "$MATERIAL_NAME$.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
                schema: "$MATERIAL_NAME$.tif",
                imageFormat: "tif",
            },

            // 20x20 cm
            {
                type: DataObjectAssignmentType.MaterialThumbnail_20x20,
                schema: "$MATERIAL_NAME$.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_20x20,
                schema: "$MATERIAL_NAME$.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_20x20,
                schema: "$MATERIAL_NAME$.tif",
                imageFormat: "tif",
            },

            // 30x30 cm
            {
                type: DataObjectAssignmentType.MaterialThumbnail_30x30,
                schema: "$MATERIAL_NAME$.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_30x30,
                schema: "$MATERIAL_NAME$.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_30x30,
                schema: "$MATERIAL_NAME$.tif",
                imageFormat: "tif",
            },
        ],

        kvadrat: [
            // maps exports
            {
                type: DataObjectAssignmentType.MaterialMapsExport,
                schema: "PBR_$MATERIAL_ARTICLE_ID$_$WIDTH_CM$x$HEIGHT_CM$cm",
            },

            // maps exports maps
            {
                type: MAPS_EXPORT_MAP_ASSET_TYPE,
                schema: "$MATERIAL_ARTICLE_ID$_$MAP_TYPE_UNDERSCORE_SEPARATED$.$EXPORT_FORMAT$",
            },

            // tileable renders
            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "3D_$MATERIAL_ARTICLE_ID$_$WIDTH_CM$x$HEIGHT_CM$cm.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "3D_$MATERIAL_ARTICLE_ID$_$WIDTH_CM$x$HEIGHT_CM$cm.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "3D_$MATERIAL_ARTICLE_ID$_$WIDTH_CM$x$HEIGHT_CM$cm.tiff",
                imageFormat: "tif",
            },

            // thumbnails
            // 7x7 inches
            {
                type: DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
                schema: "F_$MATERIAL_ARTICLE_ID$_7x7in.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
                schema: "F_$MATERIAL_ARTICLE_ID$_7x7in.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
                schema: "F_$MATERIAL_ARTICLE_ID$_7x7in.tiff",
                imageFormat: "tif",
            },

            // 9x9 inches
            {
                type: DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
                schema: "F_$MATERIAL_ARTICLE_ID$_9x9in.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
                schema: "F_$MATERIAL_ARTICLE_ID$_9x9in.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
                schema: "F_$MATERIAL_ARTICLE_ID$_9x9in.tiff",
                imageFormat: "tif",
            },

            // 10x10 cm
            {
                type: DataObjectAssignmentType.MaterialThumbnail_10x10,
                schema: "F_$MATERIAL_ARTICLE_ID$_10x10cm.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_10x10,
                schema: "F_$MATERIAL_ARTICLE_ID$_10x10cm.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_10x10,
                schema: "F_$MATERIAL_ARTICLE_ID$_10x10cm.tiff",
                imageFormat: "tif",
            },

            // 16x16 inches
            {
                type: DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
                schema: "F_$MATERIAL_ARTICLE_ID$_16x16in.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
                schema: "F_$MATERIAL_ARTICLE_ID$_16x16in.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
                schema: "F_$MATERIAL_ARTICLE_ID$_16x16in.tiff",
                imageFormat: "tif",
            },

            // 20x20 cm
            {
                type: DataObjectAssignmentType.MaterialThumbnail_20x20,
                schema: "F_$MATERIAL_ARTICLE_ID$_20x20cm.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_20x20,
                schema: "F_$MATERIAL_ARTICLE_ID$_20x20cm.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_20x20,
                schema: "F_$MATERIAL_ARTICLE_ID$_20x20cm.tiff",
                imageFormat: "tif",
            },

            // 30x30 cm
            {
                type: DataObjectAssignmentType.MaterialThumbnail_30x30,
                schema: "F_$MATERIAL_ARTICLE_ID$_30x30cm.exr",
                imageFormat: "exr",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_30x30,
                schema: "F_$MATERIAL_ARTICLE_ID$_30x30cm.jpg",
                imageFormat: "jpg",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_30x30,
                schema: "F_$MATERIAL_ARTICLE_ID$_30x30cm.tiff",
                imageFormat: "tif",
            },
        ],

        "ldi interiors": [
            //tileable renders
            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "Tileable image - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.exr",
                imageFormat: "exr",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "Tileable image - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.tiff",
                imageFormat: "tif",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "Tileable image - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.jpg",
                imageFormat: "jpg",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "Tileable image - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-1000.jpg",
                imageFormat: "jpg",
                imageResolution: "px1000",
            },

            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "Tileable image - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-2000.jpg",
                imageFormat: "jpg",
                imageResolution: "px2000",
            },

            {
                type: DataObjectAssignmentType.MaterialTileableRender,
                schema: "Tileable image - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-500.jpg",
                imageFormat: "jpg",
                imageResolution: "px500",
            },

            // thumbnails
            // 7x7 inches
            {
                type: DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.exr",
                imageFormat: "exr",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.tiff",
                imageFormat: "tif",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.jpg",
                imageFormat: "jpg",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-1000.jpg",
                imageFormat: "jpg",
                imageResolution: "px1000",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-2000.jpg",
                imageFormat: "jpg",
                imageResolution: "px2000",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-500.jpg",
                imageFormat: "jpg",
                imageResolution: "px500",
            },

            // 9x9 inches
            {
                type: DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.exr",
                imageFormat: "exr",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.tiff",
                imageFormat: "tif",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.jpg",
                imageFormat: "jpg",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-1000.jpg",
                imageFormat: "jpg",
                imageResolution: "px1000",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-2000.jpg",
                imageFormat: "jpg",
                imageResolution: "px2000",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-500.jpg",
                imageFormat: "jpg",
                imageResolution: "px500",
            },

            // 10x10 cm
            {
                type: DataObjectAssignmentType.MaterialThumbnail_10x10,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.exr",
                imageFormat: "exr",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_10x10,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.tiff",
                imageFormat: "tif",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_10x10,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.jpg",
                imageFormat: "jpg",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_10x10,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-1000.jpg",
                imageFormat: "jpg",
                imageResolution: "px1000",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_10x10,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-2000.jpg",
                imageFormat: "jpg",
                imageResolution: "px2000",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_10x10,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-500.jpg",
                imageFormat: "jpg",
                imageResolution: "px500",
            },

            // 16x16 inches
            {
                type: DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.exr",
                imageFormat: "exr",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.tiff",
                imageFormat: "tif",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.jpg",
                imageFormat: "jpg",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-1000.jpg",
                imageFormat: "jpg",
                imageResolution: "px1000",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-2000.jpg",
                imageFormat: "jpg",
                imageResolution: "px2000",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-500.jpg",
                imageFormat: "jpg",
                imageResolution: "px500",
            },

            // 20x20 cm
            {
                type: DataObjectAssignmentType.MaterialThumbnail_20x20,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.exr",
                imageFormat: "exr",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_20x20,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.tiff",
                imageFormat: "tif",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_20x20,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.jpg",
                imageFormat: "jpg",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_20x20,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-1000.jpg",
                imageFormat: "jpg",
                imageResolution: "px1000",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_20x20,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-2000.jpg",
                imageFormat: "jpg",
                imageResolution: "px2000",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_20x20,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-500.jpg",
                imageFormat: "jpg",
                imageResolution: "px500",
            },

            // 30x30 cm
            {
                type: DataObjectAssignmentType.MaterialThumbnail_30x30,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.exr",
                imageFormat: "exr",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_30x30,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.tiff",
                imageFormat: "tif",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_30x30,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm.jpg",
                imageFormat: "jpg",
                imageResolution: "original",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_30x30,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-1000.jpg",
                imageFormat: "jpg",
                imageResolution: "px1000",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_30x30,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-2000.jpg",
                imageFormat: "jpg",
                imageResolution: "px2000",
            },

            {
                type: DataObjectAssignmentType.MaterialThumbnail_30x30,
                schema: "Thumbnail - $MATERIAL_NAME$ - $MATERIAL_ARTICLE_ID$ - $WIDTH_CM$cm x $HEIGHT_CM$cm-jpg-500.jpg",
                imageFormat: "jpg",
                imageResolution: "px500",
            },
        ],
    }

    function formatArticleId(articleId: string) {
        // ArticleId contains rangeId/productId and colorId, possibly separated by a dash
        // ColorId is always represented by the last three digits of the articleId
        // Format articleId to always use dash between the rangeId/productId and colorId
        return articleId.at(-4) === "-" ? articleId : `${articleId.slice(0, -3)}-${articleId.slice(-3)}`
    }

    function canonicalImageFormatName(imageFormat: AssetData<RenderAssetType>["imageFormat"] | MaterialMapsExporter.ConversionConfig["format"]) {
        return imageFormat === "jpeg" ? "jpg" : imageFormat
    }

    function canonicalInfoFileFormatName(fileFormat: MaterialMapsExporter.ConversionInfoRequest["format"]) {
        return fileFormat === "text" ? "txt" : fileFormat
    }

    export function ensureValidMediaType(mediaType: string | undefined | null) {
        if (isValidContentType(mediaType)) return extensionForContentType(mediaType)
        throw Error(`Invalid media type: ${mediaType}`)
    }

    function getRelevantPhysicalDimensions(assetData: AssetData) {
        const fallback = {widthCm: null, heightCm: null, widthIn: null, heightIn: null}

        const allUnitsFromIn = (widthIn: number, heightIn: number) => {
            return {
                widthCm: widthIn / CM_TO_INCH,
                heightCm: heightIn / CM_TO_INCH,
                widthIn,
                heightIn,
            }
        }

        const allUnitsFromCm = (widthCm: number, heightCm: number) => {
            return {
                widthCm,
                heightCm,
                widthIn: widthCm * CM_TO_INCH,
                heightIn: heightCm * CM_TO_INCH,
            }
        }

        switch (assetData.type) {
            case DataObjectAssignmentType.MaterialTileableRender:
            case DataObjectAssignmentType.MaterialMapsExport:
            case MAPS_EXPORT_INFO_ASSET_TYPE:
            case MAPS_EXPORT_MAP_ASSET_TYPE:
                if (assetData.materialData.widthCm === null || assetData.materialData.heightCm === null) return fallback
                return allUnitsFromCm(assetData.materialData.widthCm, assetData.materialData.heightCm)
            case DataObjectAssignmentType.MaterialThumbnail_10x10:
                return allUnitsFromCm(10, 10)
            case DataObjectAssignmentType.MaterialThumbnail_20x20:
                return allUnitsFromCm(20, 20)
            case DataObjectAssignmentType.MaterialThumbnail_30x30:
                return allUnitsFromCm(30, 30)
            case DataObjectAssignmentType.MaterialThumbnail_7x7Inches:
                return allUnitsFromIn(7, 7)
            case DataObjectAssignmentType.MaterialThumbnail_9x9Inches:
                return allUnitsFromIn(9, 9)
            case DataObjectAssignmentType.MaterialThumbnail_16x16Inches:
                return allUnitsFromIn(16, 16)
            default:
                return fallback
        }
    }

    function formatFloat(value: number) {
        return value.toFixed(2).toString().replace(".", ",")
    }

    function formatInt(value: number) {
        return parseInt(value.toString()).toString()
    }

    export function getFilenameForAssetData(assetData: AssetData) {
        if (!Object.keys(ASSETS_FILENAME_SCHEMAS_FOR_ORGANIZATION).includes(assetData.organizationName)) return null

        const isMapsExportAssetData = (x: AssetData): x is AssetDataT<MapsExportAssetType> => {
            return x.type === DataObjectAssignmentType.MaterialMapsExport
        }

        const isMapsExportMapAssetData = (x: AssetData): x is AssetDataT<MapsExportMapAssetType> => {
            return x.type === MAPS_EXPORT_MAP_ASSET_TYPE
        }

        const isMapsExportInfoAssetData = (x: AssetData): x is AssetDataT<MapsExportInfoAssetType> => {
            return x.type === MAPS_EXPORT_INFO_ASSET_TYPE
        }

        const isRenderAssetData = (x: AssetData): x is AssetDataT<RenderAssetType> => {
            return isRenderAssetType(x.type)
        }

        const isMapsExportFilenameSchemaEntry = (x: FilenameSchemaEntry): x is FilenameSchemaEntry<MapsExportAssetType> => {
            return x.type === DataObjectAssignmentType.MaterialMapsExport
        }

        const isMapsExportMapFilenameSchemaEntry = (x: FilenameSchemaEntry): x is FilenameSchemaEntry<MapsExportMapAssetType> => {
            return x.type === MAPS_EXPORT_MAP_ASSET_TYPE
        }

        const isMapsExportInfoFilenameSchemaEntry = (x: FilenameSchemaEntry): x is FilenameSchemaEntry<MapsExportInfoAssetType> => {
            return x.type === MAPS_EXPORT_INFO_ASSET_TYPE
        }

        const isRenderAssetFilenameSchemaEntry = (x: FilenameSchemaEntry): x is FilenameSchemaEntry<RenderAssetType> => {
            return isRenderAssetType(x.type)
        }

        // TODO Correctly narrow down T
        const checkPropertyMatch = <T>(entryProperty: T | undefined, dataProperty: T) => {
            return entryProperty === undefined || entryProperty === dataProperty
        }

        const conversionConfigFromMapsExportAssetData = (assetData: AssetData<MapsExportAssetType>) => {
            const conversionConfigs = MaterialMapsExporter.collectConversionConfigsForExportConfig(assetData.exportConfig)
            if (conversionConfigs.length !== 1) {
                throw new Error(`Expected exactly one conversion config for MaterialMapsExport`)
            }
            return conversionConfigs[0]
        }

        const schemaEntry = ASSETS_FILENAME_SCHEMAS_FOR_ORGANIZATION[assetData.organizationName]
            .filter((entry) => entry.type === assetData.type)
            .find((entry) => {
                let check = checkPropertyMatch(entry.isOtherInfoSet, assetData.materialData.rangeInfo.otherInfo !== null)
                if (isMapsExportAssetData(assetData) && isMapsExportFilenameSchemaEntry(entry)) {
                    const conversionConfig = conversionConfigFromMapsExportAssetData(assetData)
                    check =
                        check &&
                        checkPropertyMatch(entry.exportFormat, conversionConfig.format) &&
                        checkPropertyMatch(entry.exportResolution, conversionConfig.resolution)
                } else if (isMapsExportMapAssetData(assetData) && isMapsExportMapFilenameSchemaEntry(entry)) {
                    check =
                        check &&
                        checkPropertyMatch(entry.exportFormat, assetData.conversionConfig.format) &&
                        checkPropertyMatch(entry.exportResolution, assetData.conversionConfig.resolution) &&
                        checkPropertyMatch(entry.mapType, assetData.mapType)
                } else if (isMapsExportInfoAssetData(assetData) && isMapsExportInfoFilenameSchemaEntry(entry)) {
                    check =
                        check &&
                        checkPropertyMatch(entry.exportFormat, assetData.conversionInfo.conversionRequest.format) &&
                        checkPropertyMatch(entry.exportResolution, assetData.conversionInfo.conversionRequest.resolution) &&
                        checkPropertyMatch(entry.fileFormat, assetData.conversionInfo.format)
                } else if (isRenderAssetData(assetData) && isRenderAssetFilenameSchemaEntry(entry)) {
                    check =
                        check &&
                        checkPropertyMatch(entry.imageFormat, assetData.imageFormat) &&
                        checkPropertyMatch(entry.imageResolution, assetData.imageResolution)
                } else {
                    throw Error("Invalid assetData/schemaFilenameEntry combination")
                }
                return check
            })

        if (schemaEntry === undefined) return null

        let filename = `${schemaEntry.schema}`

        const {widthCm, heightCm, widthIn, heightIn} = getRelevantPhysicalDimensions(assetData)

        const articleId = assetData.materialData.articleId
        const materialName = assetData.materialData.name
        const rangeName = assetData.materialData.rangeInfo.name
        const rangeOtherInfo = assetData.materialData.rangeInfo.otherInfo

        const articleId_ = articleId === null || articleId === "" ? `${UNDEFINED_FIELD_SYMBOL}` : articleId
        const formattedArticleId_ = articleId === null || articleId === "" ? `${UNDEFINED_FIELD_SYMBOL}` : formatArticleId(articleId)
        const materialName_ = materialName === null || materialName === "" ? `${UNDEFINED_FIELD_SYMBOL}` : materialName
        const rangeName_ = rangeName === null || rangeName === "" ? UNDEFINED_FIELD_SYMBOL : rangeName
        const rangeOtherInfo_ = rangeOtherInfo === null || rangeOtherInfo === "" ? UNDEFINED_FIELD_SYMBOL : rangeOtherInfo

        const widthCm_ = widthCm === null ? `${UNDEFINED_FIELD_SYMBOL}` : formatFloat(widthCm)
        const heightCm_ = heightCm === null ? `${UNDEFINED_FIELD_SYMBOL}` : formatFloat(heightCm)
        const widthIn_ = widthIn === null ? `${UNDEFINED_FIELD_SYMBOL}` : formatFloat(widthIn)
        const heightIn_ = heightIn === null ? `${UNDEFINED_FIELD_SYMBOL}` : formatFloat(heightIn)

        const replaceAll = (str: string, pattern: string, newValue: string): string => {
            // TODO: use regex (a bit tricky due to the usage of $ symbol)
            return str.includes(pattern) ? replaceAll(str.replace(pattern, newValue), pattern, newValue) : str
        }

        filename = replaceAll(filename, "$MATERIAL_ARTICLE_ID$", articleId_)
        filename = replaceAll(filename, "$MATERIAL_FORMATTED_ARTICLE_ID$", formattedArticleId_)
        filename = replaceAll(filename, "$MATERIAL_NAME$", materialName_)
        filename = replaceAll(filename, "$MATERIAL_RANGE_NAME$", rangeName_)
        filename = replaceAll(filename, "$MATERIAL_RANGE_OTHER_INFO$", rangeOtherInfo_)
        filename = replaceAll(filename, "$WIDTH_CM$", widthCm_)
        filename = replaceAll(filename, "$HEIGHT_CM$", heightCm_)
        filename = replaceAll(filename, "$WIDTH_IN$", widthIn_)
        filename = replaceAll(filename, "$HEIGHT_IN$", heightIn_)

        if (isMapsExportAssetData(assetData)) {
            const conversionConfig = conversionConfigFromMapsExportAssetData(assetData)
            filename = replaceAll(filename, "$EXPORT_FORMAT$", canonicalImageFormatName(conversionConfig.format))
            filename = replaceAll(filename, "$EXPORT_RESOLUTION$", conversionConfig.resolution)
        } else if (isMapsExportMapAssetData(assetData)) {
            filename = replaceAll(filename, "$EXPORT_FORMAT$", canonicalImageFormatName(assetData.conversionConfig.format))
            filename = replaceAll(filename, "$EXPORT_RESOLUTION$", assetData.conversionConfig.resolution)
            filename = replaceAll(filename, "$MAP_TYPE$", assetData.mapType)
            filename = replaceAll(filename, "$MAP_TYPE_UNDERSCORE_SEPARATED$", assetData.mapType.replace("-", "_"))
        } else if (isMapsExportInfoAssetData(assetData)) {
            filename = replaceAll(filename, "$EXPORT_FORMAT$", canonicalImageFormatName(assetData.conversionInfo.conversionRequest.format))
            filename = replaceAll(filename, "$EXPORT_RESOLUTION$", assetData.conversionInfo.conversionRequest.resolution)
            filename = replaceAll(filename, "$INFO_FILE_FORMAT$", canonicalInfoFileFormatName(assetData.conversionInfo.format))
        } else if (isRenderAssetData(assetData)) {
            filename = replaceAll(filename, "$IMAGE_FORMAT$", assetData.imageFormat)
            filename = replaceAll(filename, "$IMAGE_RESOLUTION$", assetData.imageResolution)
        } else {
            throw Error("Invalid schemaFilenameEntry type")
        }
        return filename
    }

    // ----------- Validation ------------- //

    export const validateFilenameSchemasForOrganization = (organizationName: string) => {
        const schemasEntryList = ASSETS_FILENAME_SCHEMAS_FOR_ORGANIZATION[organizationName]
        const validatedAssetTypes: AssetType[] = []

        let allOrganizationSchemasValid = true
        schemasEntryList.forEach((schemaEntry) => {
            if (validatedAssetTypes.includes(schemaEntry.type)) return

            const schemaEntryListForAssetType = schemasEntryList.filter((x) => x.type === schemaEntry.type)

            const fullKeySet = new Set<string>()
            schemaEntryListForAssetType.forEach((schemaEntry) => Object.keys(schemaEntry).map((entry) => fullKeySet.add(entry)))

            let schemaValid = true
            schemaEntryListForAssetType.forEach((schemaEntry) => {
                const entryKeySet = Object.keys(schemaEntry)
                const missingKeys = Array.from(fullKeySet).filter((key) => !entryKeySet.includes(key))

                if (missingKeys.length > 0) {
                    schemaValid = schemaValid && false
                    console.log(`SchemaEntry:\n${JSON.stringify(schemaEntry)}\nMissing keys:\n${missingKeys.join(", ")}`)
                }
            })
            allOrganizationSchemasValid = allOrganizationSchemasValid && schemaValid

            validatedAssetTypes.push(schemaEntry.type)
        })
        console.log(`Schema validation for organization ${organizationName} ${allOrganizationSchemasValid ? "PASSED" : "FAILED"}`)
        return allOrganizationSchemasValid
    }
}
