import {EventEmitter} from "@angular/core"
import {ColorLike, Vector2, Vector2Like} from "@cm/math"
import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {LabelToolboxItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/basic/label-item"

import {LineItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/basic/line-item"
import {CanvasBaseToolboxItem, ToolMouseEvent} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export class CurveVizItem extends CanvasBaseToolboxItemBase {
    readonly clicked = new EventEmitter<{position: Vector2; t: number}>()
    readonly dragging = new EventEmitter<ToolMouseEvent>()
    readonly dragFinished = new EventEmitter<void>()

    constructor(
        parentItem: CanvasBaseToolboxItemBase,
        readonly width: number,
        private flipLabelSide: boolean,
    ) {
        super(parentItem)

        this._lineItem = new LineItem(this, [], width, {r: 1, g: 0, b: 0, a: 1}, "piecewise-linear")
        this._label = new LabelToolboxItem(this)
        this.updateCurveLabel()

        this.sendToBack()
    }

    override remove() {
        super.remove()
    }

    override hitTest(point: Vector2Like): CanvasBaseToolboxItem | null {
        return this._lineItem.hitTest(point) ? this : null
    }

    override onMouseDown(event: ToolMouseEvent): boolean {
        super.onMouseDown(event)
        if (this._lineItem) {
            const t = this.computeClosestT(event.downPoint)
            this.clicked.emit({position: Vector2.fromVector2Like(event.downPoint), t})
            this._isDragging = true
            return true
        }
        return false
    }

    override onMouseUp(event: ToolMouseEvent): boolean {
        super.onMouseUp(event)
        if (this._isDragging) {
            this._isDragging = false
            this.dragFinished.emit()
            return false
        }
        return true
    }

    override onMouseDrag(event: ToolMouseEvent): boolean {
        super.onMouseDrag(event)
        if (this.selected && this._isDragging) {
            this.dragging.emit(event)
            return false
        }
        return true
    }

    set showLabel(value: boolean) {
        this._label.visible = value
    }

    get showLabel() {
        return this._label.visible
    }

    set color(color: ColorLike) {
        this._lineItem.color = color
    }

    get color() {
        return this._lineItem.color
    }

    setCurvePoints(positions: Vector2Like[], tValues: number[]) {
        this._positions = positions
        this._tValues = tValues
        this._lineItem.setPoints(positions)
        this.updateCurveLabel()
    }

    private computeClosestT(point: Vector2Like) {
        if (!this._lineItem || !this._lineItem.path) {
            throw new Error("Curve not found")
        }
        const curve = this._lineItem.path
        const curvePoint = curve.getNearestPoint(point)
        const location = curve.getLocationOf(curvePoint)
        const p0 = this._positions[location.index]
        const p1 = this._positions[location.index + 1]
        const t = curvePoint.getDistance(p0) / Vector2.distance(p0, p1) // compute t value between p0 and p1 based on the distance between curvePoint and p0 (as the location.time value is non-linear)
        const t0 = this._tValues[location.index]
        const t1 = this._tValues[location.index + 1]
        return t0 + (t1 - t0) * t
    }

    private updateCurveLabel() {
        if (this._positions.length < 2) {
            return
        }
        const firstPosition = this._positions[0]
        const lastPosition = this._positions[this._positions.length - 1]
        const direction = Vector2.fromVector2Like(lastPosition).subInPlace(firstPosition).normalized()
        const directionPerp = direction.perp()
        if (this.flipLabelSide) {
            directionPerp.negateInPlace()
        }
        let angle = (Math.atan2(direction.y, direction.x) / Math.PI) * 180
        if (90 < angle && angle < 180) {
            angle -= 180
        } else if (-180 < angle && angle < -90) {
            angle += 180
        }
        const center = Vector2.fromVector2Like(firstPosition).addInPlace(lastPosition).divInPlace(2)
        const minCurveDeviationAlongDirectionPerp = this._positions.reduce((minDeviation, position) => {
            const deviation = Vector2.fromVector2Like(position).subInPlace(center).dot(directionPerp)
            return Math.min(minDeviation, deviation)
        }, 0)
        const labelPosition = center.add(directionPerp.mul(minCurveDeviationAlongDirectionPerp))
        const getCentimetersLabel = (pixels: number) => {
            if (!this.physicalInfo) {
                return "??? cm"
            }
            const cm: number = Math.round((pixels / this.physicalInfo.pixelsPerCm) * 100) / 100
            return `${cm.toFixed(2)} cm`
        }
        const curveLength = this._lineItem.length
        const text = `${getCentimetersLabel(curveLength)} (${Math.round(curveLength)} px)`
        this._label.text$.next(text)
        this._label.angle$.next(angle)
        this._label.position$.next(labelPosition)
        this._label.screenSpaceOffset$.next(directionPerp.mul(-(this._label.baseFontSize / 2 + 10)))
    }

    private _positions: Vector2Like[] = []
    private _tValues: number[] = []
    private _lineItem: LineItem
    private _label: LabelToolboxItem
    private _isDragging = false
}
