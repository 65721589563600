import {Injector} from "@angular/core"
import {PriceInput, MutationUpdatePriceGraphInput, PriceGraphState} from "@generated"
import {Catalog, CatalogDiff} from "@cm/pricing/catalogs/catalog-interface"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {
    GetPriceGraphsForCatalogIdsGQL,
    PriceMappingOrganizationIdFromTemplateIdGQL,
    PriceMappingUpdatePriceGraphGQL,
    PriceMappingIdFromCatalogToPriceGraphFragment,
} from "@pricing/components/price-mapping/price-mapping.generated"
import {
    PricingHelperCreateOrUpdatePricesGQL,
    PricingHelperDeleteIdFromCustomerToPriceGraphGQL,
    PricingHelperDeletePricesGQL,
} from "@pricing/helpers/pricing.helper.generated"

export async function importCatalog(catalog: Catalog, templateId: string, injector: Injector): Promise<void> {
    const allPrices = catalog.getAllPrices()

    const priceInput: PriceInput[] = allPrices.map((price) => {
        return {
            originalId: price.uniqueId,
            price: price.price,
            currency: price.currency,
        }
    })

    if (!templateId) throw new Error("No template uuid provided")

    const organizationIdFromTemplateIdGql = injector.get(PriceMappingOrganizationIdFromTemplateIdGQL)
    const organizationId = (await fetchThrowingErrors(organizationIdFromTemplateIdGql)({templateId: templateId})).template?.organizationId
    if (!organizationId) throw new Error("Organization uuid not found")

    await createOrUpdatePricesChunked(organizationId, priceInput, injector)
}

//the backend has a data size limit, do not transmit everything at once
async function createOrUpdatePricesChunked(organizationId: string, prices: PriceInput[], injector: Injector, chunkSize: number = 250) {
    for (let i = 0; i < prices.length; i += chunkSize) {
        const chunk = prices.slice(i, i + chunkSize)
        const createOrUpdatePricesGql = injector.get(PricingHelperCreateOrUpdatePricesGQL)
        const result = await mutateThrowingErrors(createOrUpdatePricesGql)({
            prices: chunk,
            organizationId,
        })
        console.log("Create price result", result)
    }
}

function computeProblemsPerGraph(priceGraphsWithConflicts: PriceMappingIdFromCatalogToPriceGraphFragment[], diff: CatalogDiff): Map<string, string> {
    const problemsPerGraph = new Map<string, string>()

    for (const priceGraph of priceGraphsWithConflicts) {
        if (!priceGraph.idFromCatalog.originalId) continue
        const conflictDescription = diff.conflicts.get(priceGraph.idFromCatalog.originalId)

        if (conflictDescription) {
            const existingProblems = problemsPerGraph.get(priceGraph.priceGraphUuid) ?? ""
            const separator = existingProblems ? ", " : ""
            problemsPerGraph.set(priceGraph.priceGraphUuid, existingProblems + separator + priceGraph.idFromCatalog.originalId + ": " + conflictDescription)
        }
    }

    return problemsPerGraph
}

async function getConflictsChunked(organizationId: string, idFromCustomerWithConflicts: string[], injector: Injector, chunkSize: number = 250) {
    let priceGraphsWithConflicts: PriceMappingIdFromCatalogToPriceGraphFragment[] = []

    for (let i = 0; i < idFromCustomerWithConflicts.length; i += chunkSize) {
        const idChunk = idFromCustomerWithConflicts.slice(i, i + chunkSize)
        const priceGraphsForCatalogIdsGql = injector.get(GetPriceGraphsForCatalogIdsGQL)
        const result = await fetchThrowingErrors(priceGraphsForCatalogIdsGql)({
            organizationUuid: organizationId,
            originalIds: idChunk,
            take: chunkSize,
        })
        const items = result.idFromCatalogToPriceGraphItems.filter((item): item is PriceMappingIdFromCatalogToPriceGraphFragment => item !== null)
        priceGraphsWithConflicts = priceGraphsWithConflicts.concat(items)
    }

    return priceGraphsWithConflicts
}

export async function updatePrices(organizationId: string, oldCatalog: Catalog, newCatalog: Catalog, injector: Injector) {
    const diff = oldCatalog.diff(newCatalog)
    console.log("Old catalog size", oldCatalog.getAllPrices().length)
    console.log("New catalog size", newCatalog.getAllPrices().length)
    if (newCatalog.getAllPrices().length != oldCatalog.getAllPrices().length - diff.deletedEntries.size + diff.newEntries.size)
        throw new Error("Diff is not correct")
    console.log("Diff", diff)

    const idFromCustomerWithConflicts = [...diff.conflicts.keys()]

    const priceGraphsWithConflicts: PriceMappingIdFromCatalogToPriceGraphFragment[] = await getConflictsChunked(
        organizationId,
        idFromCustomerWithConflicts,
        injector,
    )

    const problemsPerGraph = computeProblemsPerGraph(priceGraphsWithConflicts, diff)
    console.log("Num used ids with problems", priceGraphsWithConflicts.length)
    console.log("Num problematic graphs", problemsPerGraph.size)
    console.log("Problems per graph", problemsPerGraph)

    for (const [priceGraphUuid, problems] of problemsPerGraph) {
        const updatePriceGraphInput: MutationUpdatePriceGraphInput = {
            originalIdsFromCatalog: [],
            priceGraphId: priceGraphUuid,
            organizationId: organizationId,
            state: PriceGraphState.Invalid,
            conflicts: problems,
        }

        const updatePriceGraphGql = injector.get(PriceMappingUpdatePriceGraphGQL)
        await mutateThrowingErrors(updatePriceGraphGql)({input: updatePriceGraphInput})
        const deleteIdFromCustomerToPriceGraphGql = injector.get(PricingHelperDeleteIdFromCustomerToPriceGraphGQL)
        await mutateThrowingErrors(deleteIdFromCustomerToPriceGraphGql)({priceGraphId: priceGraphUuid}) //after the graph was flagged invalid, references to it are not needed anymore.
    }

    const deletePricesGql = injector.get(PricingHelperDeletePricesGQL)
    await mutateThrowingErrors(deletePricesGql)({organizationId: organizationId, originalIds: [...diff.deletedEntries.keys()]})

    const priceInput: PriceInput[] = [...Array.from(diff.newEntries), ...Array.from(diff.updatedEntries)].map((price) => ({
        originalId: price[0],
        price: price[1].price,
        currency: price[1].currency,
    }))

    await createOrUpdatePricesChunked(organizationId, priceInput, injector)
}
