import {CompleteMeshData} from "#template-nodes/geometry-processing/mesh-data"
import {MeshNodes} from "@cm/render-nodes"
import {ISceneManager} from "#template-nodes/interfaces/scene-manager"
import {Inlet, NotReady, Outlet} from "#template-nodes/runtime-graph/slots"
import {TypeDescriptors} from "#template-nodes/runtime-graph/type-descriptors"
import {NodeClassImpl} from "#template-nodes/runtime-graph/types"
import {defer, map, Subscription} from "rxjs"
import {geomToMesh, meshToGeom, GeomBuilderContext} from "#template-nodes/geometry-processing/geometry-graph"
import {clipAndOffsetMeshForDecal} from "#template-nodes/geometry-processing/common-geometry-graphs"

const TD = TypeDescriptors

const getMeshDecalDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManager>()),
    inputCompleteMeshData: TD.inlet(TD.CompleteMeshData),
    offset: TD.inlet(TD.Tuple<[number, number]>()),
    rotation: TD.inlet(TD.Number),
    size: TD.inlet(TD.Tuple<[number, number]>()),
    distance: TD.inlet(TD.Number),
    completeMeshData: TD.outlet(TD.CompleteMeshData),
}

function applyDecalToGraph(graph: MeshNodes.Mesh, offset: [number, number], rotation: number, size: [number, number], distance: number): MeshNodes.Mesh {
    const ctx = new GeomBuilderContext()
    const clipped = clipAndOffsetMeshForDecal(meshToGeom(ctx, graph, 1), offset, rotation, size, distance)
    // force all material IDs to 0 (assumed to be the only material slot for decals)
    clipped.materialID = clipped.materialID.constInt(0)
    return geomToMesh(clipped)
}

export class GetMeshDecal implements NodeClassImpl<typeof getMeshDecalDescriptor, typeof GetMeshDecal> {
    static descriptor = getMeshDecalDescriptor
    static uniqueName = "GetMeshDecal"
    sceneManager!: Inlet<ISceneManager>
    inputCompleteMeshData!: Inlet<CompleteMeshData>
    offset!: Inlet<[number, number]>
    rotation!: Inlet<number>
    size!: Inlet<[number, number]>
    distance!: Inlet<number>
    completeMeshData!: Outlet<CompleteMeshData>

    private pending: Subscription | null = null

    run() {
        this.completeMeshData.emitIfChanged(NotReady)
        this.pending?.unsubscribe()

        if (
            this.sceneManager === NotReady ||
            this.inputCompleteMeshData === NotReady ||
            this.offset === NotReady ||
            this.rotation === NotReady ||
            this.size === NotReady ||
            this.distance === NotReady
        ) {
            return
        }

        const {sceneManager, inputCompleteMeshData: inputMeshData, offset, rotation, size, distance} = this
        const displayGeometryGraph = applyDecalToGraph(inputMeshData.abstract.displayGeometryGraph, offset, rotation, size, distance)
        const renderGeometryGraph = applyDecalToGraph(inputMeshData.abstract.renderGeometryGraph, offset, rotation, size, distance)
        this.pending = this.sceneManager.addTask({
            description: `clipAndOffsetMeshForDecal(${this.inputCompleteMeshData.reified.contentHash})`,
            task: defer(() => sceneManager.clipAndOffsetMeshForDecal(inputMeshData.reified, offset, rotation, size, distance)).pipe(
                map((reified) => {
                    this.pending = null
                    this.completeMeshData.emitIfChanged({
                        reified,
                        abstract: {
                            contentHash: reified.contentHash,
                            displayGeometryGraph,
                            renderGeometryGraph,
                            displayGeometryGraphResources: inputMeshData.abstract.displayGeometryGraphResources,
                        },
                    })
                }),
            ),
            critical: true,
        })
    }

    cleanup() {
        this.pending?.unsubscribe()
    }
}
