import {Component, inject, input, signal} from "@angular/core"
import {ContentTypeModel} from "@generated"
import {ScanJobComponent} from "@app/textures/texture-group-details/scan-job/scan-job.component"
import {TextureGroupTextureSetIdsFragment, QueryTextureGroupTextureSetIdsGQL} from "@app/textures/texture-sets/texture-sets.generated"
import {TextureGroupDetailsCreateTextureSetGQL} from "@app/textures/texture-group-details/texture-group-details.generated"
import {TextureSetComponent} from "@app/textures/texture-set/texture-set.component"
import {AssociatedJobsTableComponent} from "@app/platform/components/jobs/associated-jobs-table/associated-jobs-table.component"
import {RefreshService} from "@app/common/services/refresh/refresh.service"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {filter, map, merge} from "rxjs"
import {NotificationsService} from "@app/common/services/notifications/notifications.service"
import {PermissionsService} from "@app/common/services/permissions/permissions.service"
import {toObservable} from "@angular/core/rxjs-interop"
import {SectionComponent} from "@common/components/item"
import {MatTooltipModule} from "@angular/material/tooltip"

@Component({
    imports: [ScanJobComponent, TextureSetComponent, AssociatedJobsTableComponent, SectionComponent, MatTooltipModule],
    selector: "cm-texture-sets",
    templateUrl: "./texture-sets.component.html",
    styleUrl: "./texture-sets.component.scss",
})
export class TextureSetsComponent {
    readonly $textureGroupId = input<string | undefined>(undefined, {alias: "textureGroupId"})
    readonly $showAssociatedJobs = input(false, {alias: "showAssociatedJobs"})

    protected readonly permission = inject(PermissionsService)
    $can = this.permission.$to

    private readonly textureGroupDetailsCreateTextureSet = inject(TextureGroupDetailsCreateTextureSetGQL)
    private readonly textureGroupTextureSetIdsGql = inject(QueryTextureGroupTextureSetIdsGQL)

    protected ContentTypeModel = ContentTypeModel
    protected readonly $textureGroup = signal<TextureGroupTextureSetIdsFragment | undefined>(undefined)

    constructor(
        private notifications: NotificationsService,
        private refreshService: RefreshService,
    ) {
        merge(
            toObservable(this.$textureGroupId),
            this.refreshService.itemSubject.pipe(
                filter(({id}) => id === this.$textureGroupId()),
                map(({id}) => id),
            ),
        ).subscribe((id) => this.loadTextureGroup(id))
    }

    private async loadTextureGroup(textureGroupId: string | undefined) {
        if (!textureGroupId) {
            this.$textureGroup.set(undefined)
        } else {
            await fetchThrowingErrors(this.textureGroupTextureSetIdsGql)({id: textureGroupId})
                .then((result) => {
                    this.$textureGroup.set(result.textureGroup)
                })
                .catch((error) => {
                    this.notifications.showError(error)
                    this.$textureGroup.set(undefined)
                })
        }
    }

    protected async addTextureSet() {
        const textureGroupId = this.$textureGroupId()
        if (textureGroupId) {
            await this.notifications.mutateWithUserFeedback(
                this.textureGroupDetailsCreateTextureSet,
                {
                    input: {
                        textureGroupId,
                    },
                },
                {
                    error: "Cannot create new texture set",
                },
            )
            this.refreshService.item({id: textureGroupId, __typename: ContentTypeModel.TextureGroup})
        }
    }
}
