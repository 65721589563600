import {Component, OnInit, input} from "@angular/core"
import {LoadingSpinnerIconComponent} from "@app/common/components/progress"

@Component({
    selector: "cm-loading-spinner",
    templateUrl: "./loading-spinner.component.html",
    styleUrls: ["./loading-spinner.component.scss"],
    imports: [LoadingSpinnerIconComponent],
})
export class LoadingSpinnerComponent implements OnInit {
    constructor() {}

    readonly $showSpinner = input(true, {alias: "showSpinner"})

    ngOnInit(): void {}
}
