import {NgClass} from "@angular/common"
import {Component, DestroyRef, OnInit, computed, inject, input} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {ArDialogComponent} from "@app/common/components/dialogs/ar-dialog/ar-dialog.component"
import {LoadingSpinnerIconComponent} from "@app/common/components/progress/loading-spinner-icon/loading-spinner-icon.component"
import {ActionMenuService} from "@app/common/components/viewers/configurator/action-menu/services/action-menu.service"
import {ConfigMenuService} from "@app/common/components/viewers/configurator/config-menu/services/config-menu.service"
import {getParametersForArGeneration} from "@app/common/helpers/ar/ar"
import {ArService} from "@app/common/components/viewers/configurator/services/ar.service"
import {isFullscreenApiAvailable} from "@app/common/helpers/fullscreen/fullscreen"
import {GltfExportService} from "@app/common/services/gltf-export/gltf-export.service"
import {ThreeTemplateSceneProviderComponent} from "@app/template-editor/components/three-template-scene-provider/three-template-scene-provider.component"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {ThreeSceneManagerService} from "@app/template-editor/services/three-scene-manager.service"
import {TippyDirective} from "@ngneat/helipopper"
import {TemplateSceneProviderComponent} from "@app/template-editor/components/template-scene-provider/template-scene-provider.component"

@Component({
    selector: "cm-action-menu",
    templateUrl: "./action-menu.component.html",
    styleUrls: ["./action-menu.component.scss"],
    imports: [NgClass, TippyDirective, LoadingSpinnerIconComponent, ArDialogComponent, ThreeTemplateSceneProviderComponent, TemplateSceneProviderComponent],
})
export class ActionMenuComponent implements OnInit {
    readonly $inFullscreen = input.required<boolean>({
        alias: "inFullscreen",
    })
    readonly $fullscreenEnabled = input<boolean>(false, {
        alias: "fullscreenEnabled",
    })
    readonly $arEnabled = input<boolean>(false, {
        alias: "arEnabled",
    })
    readonly $stlDownloadEnabled = input<boolean>(false, {
        alias: "stlDownloadEnabled",
    })
    readonly $gltfDownloadEnabled = input<boolean>(false, {
        alias: "gltfDownloadEnabled",
    })
    readonly $snapshotEnabled = input<boolean>(false, {
        alias: "snapshotEnabled",
    })
    readonly $pdfEnabled = input<boolean>(false, {
        alias: "pdfEnabled",
    })
    $enableDimensionGuides = input<boolean>(false, {
        alias: "enableDimensionGuides",
    })
    $dimensionGuidesActive = input<boolean>(false, {
        alias: "dimensionGuidesActive",
    })

    tpOffset: [number, number] = [0, 20]
    desktopArUrl: string | undefined

    protected readonly sceneManagerService = inject(SceneManagerService)
    private readonly arService = inject(ArService)
    private readonly destroyRef = inject(DestroyRef)
    private readonly configMenuService = inject(ConfigMenuService)
    protected readonly actionMenuService = inject(ActionMenuService)
    protected localSceneManagerService: SceneManagerService | undefined
    protected readonly threeSceneManagerService = inject(ThreeSceneManagerService)
    protected localThreeSceneManagerService: ThreeSceneManagerService | undefined
    protected readonly gltfExportService = inject(GltfExportService)

    readonly $fullscreenAvailable = computed(() => {
        return isFullscreenApiAvailable() && this.$fullscreenEnabled()
    })

    $showMenu() {
        return (
            this.$fullscreenAvailable() ||
            this.$arEnabled() ||
            this.$stlDownloadEnabled() ||
            this.$snapshotEnabled() ||
            this.sceneManagerService.$numPendingTasks() > 0 ||
            this.$pdfEnabled()
        )
    }

    constructor() {
        this.arService.desktopArUrl$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((desktopArUrl) => {
            this.desktopArUrl = desktopArUrl
        })

        this.configMenuService.configSelected$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.desktopArUrl = undefined
        })
    }

    ngOnInit(): void {}

    toggleFullscreen() {
        this.actionMenuService.toggleFullscreen()
    }

    setSceneManagerService(sceneManagerService: SceneManagerService | undefined) {
        this.localSceneManagerService = sceneManagerService
    }

    async exportStlClicked() {
        if (!this.localSceneManagerService) throw new Error("SceneManagerService not set")
        this.actionMenuService.downloadStl(
            this.sceneManagerService.$templateGraph(),
            this.sceneManagerService.$instanceParameters(),
            this.localSceneManagerService,
        )
    }

    async exportGltfClicked() {
        if (!this.localThreeSceneManagerService) throw new Error("ThreeSceneManagerService not set")
        this.actionMenuService.downloadGltf(
            this.sceneManagerService.$templateGraph(),
            this.sceneManagerService.$instanceParameters(),
            this.localThreeSceneManagerService,
        )
    }

    async viewInArClicked() {
        const templateRevisionId = this.sceneManagerService.$templateRevisionId()
        if (!templateRevisionId) return
        this.arService.viewArModel(templateRevisionId, getParametersForArGeneration(this.sceneManagerService), this.sceneManagerService.$verticalArPlacement())
    }

    async downloadPdf() {
        const templateRevisionId = this.sceneManagerService.$templateRevisionId()
        if (!templateRevisionId) throw new Error("Template revision id not set.")
        this.actionMenuService.downloadPdf(this.threeSceneManagerService, templateRevisionId)
    }

    toggleDimensionGuides() {
        this.actionMenuService.toggleDimensionGuides()
    }

    setThreeSceneManagerService(threeSceneManagerService: ThreeSceneManagerService | undefined) {
        this.localThreeSceneManagerService = threeSceneManagerService
    }
}
