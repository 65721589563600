@if (asset) {
    <div [routerLink]="['/assets', asset.id]" [queryParamsHandling]="'preserve'" class="cm-elements-container">
        <div class="cm-elements">
            <div [style.background-color]="Labels.ModelState.get(asset.model.state)?.background" class="cm-elements-label"></div>
            <cm-data-object-thumbnail [dataObjectId]="asset.model.galleryImage?.id" [resolution]="DownloadResolution.Tiny"></cm-data-object-thumbnail>
        </div>
        @for (material of asset.materials; track material.id) {
            <div class="cm-elements">
                <div [style.background-color]="Labels.MaterialState.get(material.state)?.background" class="cm-elements-label"></div>
                <cm-data-object-thumbnail [dataObjectId]="material.galleryImage?.id" [resolution]="DownloadResolution.Tiny"></cm-data-object-thumbnail>
            </div>
        }
    </div>
}
