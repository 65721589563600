import {Component, OnInit, inject, DestroyRef, viewChild} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {MappingInputs, MappingOutputs, VectorType} from "@material-editor/models/nodes"
import {TextureInfoService} from "@material-editor/services/texture-info.service"
import {NodeIoComponent} from "@node-editor/components/node-io/node-io.component"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {ParameterValue} from "@node-editor/models/events"
import {map} from "rxjs"

@Component({
    selector: "cm-mapping-node",
    templateUrl: "./mapping-node.component.html",
    styleUrls: ["./mapping-node.component.scss"],
    imports: [NodeBaseComponent],
})
export class MappingNodeComponent implements OnInit {
    readonly $nodeBase = viewChild.required<MaterialNodeBase>("nodeBase")
    outputs = MappingOutputs
    inputs = MappingInputs
    typeInfo = MappingNodeType
    settings = [VectorType]

    readonly destroyRef = inject(DestroyRef)

    constructor(private textureInfoService: TextureInfoService) {}

    ngOnInit() {
        //TODO: Move parameter creation out of the UI class into a separate "functional" node layer, see https://github.com/colormass/platform/issues/1307#issuecomment-1566146464
        const nodeBase = this.$nodeBase()
        if (!nodeBase.node.parameters.find((parameter: ParameterValue) => parameter.name === "internal.vector_type"))
            nodeBase.node.parameters.push({name: "internal.vector_type", type: "string", value: "TEXTURE"})
        this.setupIsDiffuseTextureSizeUpdated()
    }

    isDiffuseTextureSizeUpdated: boolean = false

    setupIsDiffuseTextureSizeUpdated() {
        this.textureInfoService
            .getDiffuseTextureSizeInfo()
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                map((currDiffuseTextureSize) => {
                    const scaleNodeIo = this.$nodeBase().nodeIos?.filter((nodeIo: NodeIoComponent) => nodeIo.$socket().id === "Scale")[0]
                    const value = scaleNodeIo ? scaleNodeIo.$value() : undefined
                    this.isDiffuseTextureSizeUpdated =
                        scaleNodeIo && Array.isArray(value) ? !(value[0] === currDiffuseTextureSize.width && value[1] === currDiffuseTextureSize.height) : false
                }),
            )
            .subscribe()
    }

    updateScaleToDiffuseTextureSize() {
        const currDiffuseTextureSize = this.textureInfoService.getDiffuseTextureSizeInfo().getValue()
        const scaleNodeIo = this.$nodeBase().nodeIos?.filter((nodeIo: NodeIoComponent) => nodeIo.$socket().id === "Scale")[0]

        if (scaleNodeIo) {
            const value = scaleNodeIo.$value()
            if (value && Array.isArray(value) && value.length >= 2) {
                const newValue = [...value]
                newValue[0] = currDiffuseTextureSize.width
                newValue[1] = currDiffuseTextureSize.height
                scaleNodeIo.$value.set(newValue)
            }
            this.isDiffuseTextureSizeUpdated = false
        }
    }
}

export const MappingNodeType: MaterialNodeType<typeof MappingNodeComponent> = {
    id: "mapping",
    label: "Mapping",
    color: "#6e5eb2",
    name: "Mapping",
    inputs: [MappingInputs.vector, MappingInputs.location, MappingInputs.rotation, MappingInputs.scale],
    outputs: [MappingOutputs.vector],
    component: MappingNodeComponent,
}
