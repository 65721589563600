import {MaterialSocket} from "@material-editor/models/material-socket"
import {NodeSettings} from "@material-editor/models/material-nodes"

export const MixInputs: Record<string, MaterialSocket> = {
    factorFloat: {paramType: "socket", id: "Factor_Float", type: "input", valueType: "scalar", label: "Factor (Float)"},
    //   factorVector: { paramType: "socket", id: "Factor_Vector", type: "input", valueType: "vector", label: "Factor (Vector)" },
    //   aFloat: { paramType: "socket", id: "A_Float", type: "input", valueType: "scalar", label: "A (Float)" },
    //   bFloat: { paramType: "socket", id: "B_Float", type: "input", valueType: "scalar", label: "B (Float)" },
    //   aVector: { paramType: "socket", id: "A_Vector", type: "input", valueType: "vector", label: "A (Vector)" },
    //   bVector: { paramType: "socket", id: "B_Vector", type: "input", valueType: "vector", label: "B (Vector)" },
    aColor: {paramType: "socket", id: "A_Color", type: "input", valueType: "color", label: "A (Color)"},
    bColor: {paramType: "socket", id: "B_Color", type: "input", valueType: "color", label: "B (Color)"},
}

export const MixOutputs: Record<string, MaterialSocket> = {
    // resultFloat: {paramType: "socket", id: "Result_Float", type: "output", valueType: "output", label: "Result (Float)"},
    // resultVector: {paramType: "socket", id: "Result_Vector", type: "output", valueType: "output", label: "Result (Vector)"},
    resultColor: {paramType: "socket", id: "Result_Color", type: "output", valueType: "output", label: "Result"},
}

export const blendMode: NodeSettings = {
    id: "internal.blend_type",
    valueType: "string",
    options: [
        {label: "Mix", value: "MIX"},
        {label: "Add", value: "ADD"},
        {label: "Subtract", value: "SUBTRACT"},
        {label: "Difference", value: "DIFFERENCE"},
        {label: "Multiply", value: "MULTIPLY"},
        {label: "Screen", value: "SCREEN"},
        {label: "Divide", value: "DIVIDE"},
        {label: "Lighten", value: "LIGHTEN"},
        {label: "Darken", value: "DARKEN"},
        {label: "Soft Light", value: "SOFT_LIGHT"},
        {label: "Linear Light", value: "LINEAR_LIGHT"},
        {label: "Color", value: "COLOR"},
        {label: "Hue", value: "HUE"},
        {label: "Saturation", value: "SATURATION"},
        {label: "Value", value: "VALUE"},
        {label: "Color Burn", value: "COLOR_BURN"},
        {label: "Color Dodge", value: "COLOR_DODGE"},
        {label: "Overlay", value: "OVERLAY"},
    ],
}
