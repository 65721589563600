import {Component, input} from "@angular/core"

@Component({
    selector: "cm-input-container",
    templateUrl: "./input-container.component.html",
    styleUrls: ["./input-container.component.scss"],
})
export class InputContainerComponent {
    readonly $showLabel = input(true, {alias: "showLabel"})
    readonly $backgroundColor = input("white", {alias: "backgroundColor"})
    readonly $borderColor = input<string>("", {alias: "borderColor"})
    readonly $warning = input(false, {alias: "warning"})
}
