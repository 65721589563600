import {Component, OnInit, input, output} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ListItemComponent} from "@common/components/item"
import {Operator} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {byOperatorType, OperatorDesc} from "app/textures/texture-editor/operators-panel/operator-desc"

@Component({
    selector: "cm-operator-list-item",
    templateUrl: "./operator-list-item.component.html",
    styleUrls: ["./operator-list-item.component.scss"],
    imports: [ListItemComponent, MatTooltipModule],
})
export class OperatorListItemComponent implements OnInit {
    readonly $disabled = input(false, {alias: "disabled"})
    readonly $isInactive = input(false, {alias: "isInactive"})
    readonly $operator = input.required<Operator>({alias: "operator"})

    readonly selectOperatorClick = output<Operator>()
    readonly lockOperatorClick = output<Operator>()
    readonly enableOperatorClick = output<Operator>()
    readonly deleteOperatorClick = output<Operator>()
    readonly duplicateOperatorClick = output<Operator>()

    constructor() {}

    ngOnInit(): void {
        this.desc = byOperatorType[this.$operator().type]
    }

    get label(): string {
        return this.desc.label
    }

    get icon(): string {
        return this.desc.icon
    }

    get selected(): boolean {
        return this.$operator().selected
    }

    get locked(): boolean {
        return this.$disabled() || this.$operator().locked
    }

    get enabled(): boolean {
        return this.$operator().enabled
    }

    get edited(): boolean {
        return this.$operator().edited
    }

    get isInternalOperator(): boolean {
        return this.$operator().flags.has("is-internal")
    }

    get canDuplicate(): boolean {
        return !this.$operator().flags.has("no-clone")
    }

    get canDisable(): boolean {
        return !this.$operator().flags.has("no-disable")
    }

    selectOperator() {
        this.selectOperatorClick.emit(this.$operator())
    }

    toggleLocked(): void {
        this.lockOperatorClick.emit(this.$operator())
    }

    toggleEnabled(): void {
        this.enableOperatorClick.emit(this.$operator())
    }

    deleteOperator(): void {
        this.deleteOperatorClick.emit(this.$operator())
    }

    duplicateOperator(): void {
        this.duplicateOperatorClick.emit(this.$operator())
    }

    private desc!: OperatorDesc
}
