import {CommonModule} from "@angular/common"
import {Component, inject, TemplateRef, input, viewChild} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatDialog, MatDialogContent, MatDialogModule} from "@angular/material/dialog"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatIconModule} from "@angular/material/icon"
import {MatInputModule} from "@angular/material/input"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ActivatedRoute, Router, RouterModule} from "@angular/router"
import {ContentTypeModel} from "@generated"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {RefreshService} from "@common/services/refresh/refresh.service"

@Component({
    selector: "cm-add-entity-button",
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        RouterModule,
        FormsModule,
        MatDialogContent,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    templateUrl: "./add-entity-button.component.html",
    styleUrl: "./add-entity-button.component.scss",
})
export class AddEntityButtonComponent<CreateType> {
    readonly dialog = inject(MatDialog)
    readonly notification = inject(NotificationsService)
    readonly refresh = inject(RefreshService)
    readonly route = inject(ActivatedRoute)
    readonly router = inject(Router)

    readonly $newItemDialog = viewChild<
        TemplateRef<{
            data: CreateType
        }>
    >("newItemDialog")

    readonly $initialData = input<CreateType>(undefined, {alias: "initialData"})
    readonly $title = input("Add item", {alias: "title"})
    readonly $formTemplate = input<
        TemplateRef<{
            item: CreateType
        }>
    >(undefined, {alias: "formTemplate"})
    readonly $confirm = input<
        (data: CreateType) => Promise<{
            id: string
            legacyId: number
        }>
    >(undefined, {alias: "confirm"})
    readonly $canConfirm = input<(data: CreateType) => boolean>(() => true, {alias: "canConfirm"})
    readonly $contentTypeModel = input.required<ContentTypeModel>({alias: "contentTypeModel"})

    openDialog() {
        if (!this.$formTemplate()) {
            throw new Error("Missing formTemplate ref in add-entity-button component")
        }
        const newItemDialog = this.$newItemDialog()
        if (!newItemDialog) {
            throw new Error("Missing newItemDialog ref in add-entity-button component")
        }
        this.dialog
            .open<{data: CreateType}>(newItemDialog, {
                data: this.$initialData() ?? {},
            })
            .afterClosed()
            .subscribe(async (data) => {
                if (data) {
                    await this.notification.withUserFeedback(
                        async () => {
                            const confirm = this.$confirm()
                            if (!confirm) {
                                throw new Error("Missing confirm function in add-entity-button component")
                            }
                            const newItem = await confirm(data)
                            if (newItem) {
                                await this.router.navigate([newItem.id], {relativeTo: this.route, queryParamsHandling: "preserve"})

                                // need to reload the entire page to know where to insert the item and whether it is shown at all
                                // (it might not match the current filter)
                                this.refresh.contentTypeModel(this.$contentTypeModel())

                                return newItem
                            }
                            return undefined
                        },
                        {
                            error: "Cannot create new item.",
                        },
                    )
                }
            })
    }
}
