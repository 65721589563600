import {Injector} from "@angular/core"
import {Currency, PriceGraphState} from "@generated"
import {deserializeNodeGraph} from "@cm/graph"
import {FlattenPriceList, PricingContext} from "@cm/pricing"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {PricingHelperGetPriceGraphsGQL, PricingHelperGetPricesGQL} from "@pricing/helpers/pricing.helper.generated"

export interface PriceGraphWithContext {
    rootNode: FlattenPriceList
    pricingContext: PricingContext
}

export async function getPricingContext(injector: Injector, organizationId: string, originalIds: string[]): Promise<PricingContext> {
    const numPrices = 2000 //overrides the standard value of 25 and should be sufficient for all cases. Since price graphs are verified on save, this is ok for now.
    const pricesGql = injector.get(PricingHelperGetPricesGQL)
    const {prices} = await fetchThrowingErrors(pricesGql)({
        filter: {originalId: {in: originalIds}, organizationId: {equals: organizationId}},
        take: numPrices,
    })

    if (prices?.length !== originalIds.length) console.warn("Not all prices found")

    let commonCurrency: Currency | undefined = undefined
    const priceMap = new Map()

    for (const price of prices) {
        if (price === null) continue //the null comes from implementFindManyQuery, should not happen here though.
        if (!price.idFromCatalog.originalId) throw new Error("Price object is missing originalId")

        if (commonCurrency === undefined) commonCurrency = price.currency
        else if (price.currency !== commonCurrency) throw new Error(`Mismatched currencies found: ${price.currency} and ${commonCurrency}`)

        priceMap.set(price.idFromCatalog.originalId, price.price)
    }

    if (commonCurrency === undefined) throw new Error("No valid prices found to determine currency")
    if (priceMap.size < prices.length) throw new Error("Duplicate ids")

    return new PricingContext(priceMap, commonCurrency)
}

export async function loadPriceGraph(injector: Injector, templateId: string, state: PriceGraphState): Promise<FlattenPriceList | null> {
    const priceGraphsGql = injector.get(PricingHelperGetPriceGraphsGQL)
    const {priceGraphs} = await fetchThrowingErrors(priceGraphsGql)({filter: {templateId: {equals: templateId}, state: state}})

    if (priceGraphs.length > 1) throw new Error("More than one price graph found, revisions not implemented yet")
    if (priceGraphs.length === 0) return null

    const priceGraph = deserializeNodeGraph(priceGraphs[0]!.graph)
    if (!(priceGraph instanceof FlattenPriceList)) throw new Error("Deserialized node is not a FlattenPriceList")

    return priceGraph
}

export async function loadPricingData(
    injector: Injector,
    organizationId: string,
    templateId: string,
    state: PriceGraphState,
): Promise<PriceGraphWithContext | null> {
    const priceGraph = await loadPriceGraph(injector, templateId, state)
    if (!priceGraph) return null

    const originalIds = priceGraph.getOriginalIdsFromCatalog()
    const pricingContext = await getPricingContext(injector, organizationId, originalIds)

    return {
        rootNode: priceGraph,
        pricingContext: pricingContext,
    }
}
