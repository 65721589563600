import {inject, Injectable, Injector} from "@angular/core"
import {DataObjectForArServiceFragment} from "@app/common/components/viewers/configurator/services/ar.service.generated"
import {ArContentModel, generateNewArModels, getArAssignmentKey, waitForJobToFinish} from "@app/common/helpers/ar/ar"
import {isAndroid, isIoS} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {triggerDOMLink} from "@app/common/helpers/routes"
import {Parameters} from "@cm/template-nodes"
import {assembleArUrl, generateAndroidLink} from "@cm/utils/ar-url"
import {GetDataObjectAssignmentsForArServiceGQL, GetTemplateIdForArServiceGQL} from "@common/components/viewers/configurator/services/ar.service.generated"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {EndpointUrls} from "@common/models/constants"
import {ContentTypeModel, DataObjectAssignmentType} from "@generated"
import {Subject} from "rxjs"

@Injectable({
    providedIn: "root",
})
export class ArService {
    //Events
    private desktopArUrl: Subject<string | undefined> = new Subject()
    desktopArUrl$ = this.desktopArUrl.asObservable()

    private creatingArSubject = new Subject<boolean>()
    creatingAr$ = this.creatingArSubject.asObservable()

    private readonly getDataObjectAssignmentsForArService = inject(GetDataObjectAssignmentsForArServiceGQL)
    private readonly getTemplateIdForArService = inject(GetTemplateIdForArServiceGQL)
    private readonly injector = inject(Injector)

    private async getExistingArModelDataObject(
        templateRevisionId: string,
        parameters: Parameters,
        contentType: ArContentModel,
    ): Promise<DataObjectForArServiceFragment | undefined> {
        const {dataObjectAssignments} = await fetchThrowingErrors(this.getDataObjectAssignmentsForArService)({
            filter: {
                contentTypeModel: ContentTypeModel.TemplateRevision,
                objectId: templateRevisionId,
                assignmentKey: {equals: getArAssignmentKey(parameters)},
                assignmentType: [DataObjectAssignmentType.CachedTemplateGltf],
            },
        })

        if (dataObjectAssignments.length > 1)
            console.warn(
                `Multiple data object assignments found for template revision ${templateRevisionId} and assignment key ${getArAssignmentKey(parameters)}`,
            )

        const gltfDataObject = dataObjectAssignments[0]?.dataObject
        if (!gltfDataObject) return undefined

        /*The gltf is the main model, the usdz file is derived from this and thus attached as related, see processUsdz() in tasks.*/
        switch (contentType) {
            case "model/gltf-binary":
                return gltfDataObject
            case "model/vnd.usdz+zip":
                return gltfDataObject.related.find((x) => x.mediaType === "model/vnd.usdz+zip")
            default:
                throw Error(`Unknown type: ${contentType}.`)
        }
    }

    private async getArModelDataObject(
        templateRevisionId: string,
        parameters: Parameters,
        contentType: ArContentModel,
    ): Promise<DataObjectForArServiceFragment> {
        let existingArModel = await this.getExistingArModelDataObject(templateRevisionId, parameters, contentType)
        if (existingArModel) return existingArModel

        await this.generateNewArModels(templateRevisionId, parameters)

        existingArModel = await this.getExistingArModelDataObject(templateRevisionId, parameters, contentType)
        if (!existingArModel) throw new Error("Ar generation failed")

        return existingArModel
    }

    private async generateNewArModels(templateRevisionId: string, currentParams: Parameters) {
        const {templateRevision} = await fetchThrowingErrors(this.getTemplateIdForArService)({templateRevisionId})
        const {createJob: arGenerationJob} = await generateNewArModels(
            templateRevision.template.id,
            templateRevisionId,
            currentParams,
            templateRevision.template.organizationLegacyId,
            this.injector,
        )
        await waitForJobToFinish(arGenerationJob.id, this.injector)
    }

    async viewArModel(templateRevisionId: string, parameters: Parameters, vertical: boolean) {
        this.creatingArSubject.next(true)

        const arModelDataObject = await this.getArModelDataObject(templateRevisionId, parameters, isIoS ? "model/vnd.usdz+zip" : "model/gltf-binary")

        if (isAndroid) {
            this.viewInArAndroid(arModelDataObject, vertical)
        } else if (isIoS) {
            this.viewInArIos(arModelDataObject)
        } else {
            this.desktopArUrl.next(assembleArUrl(EndpointUrls.AR_REDIRECT_URL, arModelDataObject.objectName, vertical))
        }

        this.creatingArSubject.next(false)
    }

    private viewInArAndroid(gltfDataObject: DataObjectForArServiceFragment, vertical: boolean) {
        const dataObjectURL = gltfDataObject.downloadUrl
        triggerDOMLink({
            href: generateAndroidLink(dataObjectURL, vertical),
        })
    }

    private viewInArIos(usdzDataObject: DataObjectForArServiceFragment) {
        const dataObjectURL = usdzDataObject.downloadUrl
        // need to create a link with an <img> tag inside, as noted here: https://cwervo.com/writing/quicklook-web
        const a = document.createElement("a")
        a.href = dataObjectURL
        a.rel = "ar"
        const img = document.createElement("img")
        a.appendChild(img)
        document.body.appendChild(a)
        a.style.display = "none"
        a.click()
        a.remove()
    }
}
