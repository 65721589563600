<ng-template matMenuContent>
    <div class="cm-copy-value-to-clipboard-menu-container">
        @for (copyItem of $copyItems(); track copyItem.displayText) {
            <button mat-menu-item (click)="copyValueToClipboard($event, copyItem)">
                @if (copyItem.icon) {
                    <mat-icon>{{ copyItem.icon }}</mat-icon>
                }
                <span>{{ copyItem.displayText }}</span>
            </button>
        }
    </div>
</ng-template>
