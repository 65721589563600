import {DestroyRef, Injector} from "@angular/core"
import {LocalStorageService} from "@common/services/local-storage/local-storage.service"
import * as Apollo from "apollo-angular"
import {GraphQLClient, Variables} from "graphql-request"
import {deepEqual} from "@cm/utils"
import {environment} from "@environment"
import {InputMaybe} from "@generated"

/**
 * Manages a request that can be cancelled when the user navigates away
 */
export class CancellableRequest<
    ResultType,
    VariablesType extends {skip?: InputMaybe<number> | undefined; take?: InputMaybe<number> | undefined} = {
        skip?: InputMaybe<number> | undefined
        take?: InputMaybe<number> | undefined
    },
> {
    private fetchAbortController = new AbortController()
    // we cancel previous fetches that used different variables, but skip and take should be ignored,
    // so that multiple batches of the same data can be fetched in parallel
    private groupInfo: Omit<Variables, "skip" | "take"> | null = null
    client: GraphQLClient
    headers: {[p: string]: string} = {}

    constructor(
        private query: Apollo.Query<ResultType, VariablesType>,
        private injector: Injector,
        private destroyRef: DestroyRef,
    ) {
        this.destroyRef.onDestroy(() => this.cancel())
        this.client = new GraphQLClient(`${environment.gqlApiEndpoint}/graphql`, {errorPolicy: "none"})
        const localStorageService = injector.get(LocalStorageService)
        this.headers = localStorageService.headers
    }

    async fetch(variables: VariablesType): Promise<ResultType> {
        const groupInfo = {...variables}
        delete groupInfo.skip
        delete groupInfo.take
        if (!deepEqual(this.groupInfo, groupInfo)) {
            this.groupInfo = groupInfo
            this.fetchAbortController.abort()
            this.fetchAbortController = new AbortController()
        }
        return this.client.request<ResultType>({
            document: this.query.document,
            requestHeaders: this.headers,
            signal: this.fetchAbortController.signal,
            variables,
        })
    }

    cancel() {
        this.fetchAbortController.abort()
    }
}
