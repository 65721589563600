import {HalContext} from "@common/models/hal/hal-context"
import {WebGl2Context, WebGl2PainterPrimitive} from "@common/models/webgl2"
import {HalPainterPrimitive} from "@common/models/hal/hal-painter-primitive/index"

export const createHalPainterPrimitive = (context: HalContext, shadingFunction?: string): HalPainterPrimitive => {
    if (context instanceof WebGl2Context) {
        return new WebGl2PainterPrimitive(context, shadingFunction)
    }
    throw Error("Unsupported context")
}
