import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AddTagMutationVariables = Types.Exact<{
  input: Types.MutationCreateTagInput;
}>;


export type AddTagMutation = { __typename: 'Mutation', createTag: { __typename: 'Tag', id: string, legacyId: number } };

export const AddTagDocument = gql`
    mutation addTag($input: MutationCreateTagInput!) {
  createTag(input: $input) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddTagGQL extends Apollo.Mutation<AddTagMutation, AddTagMutationVariables> {
    override document = AddTagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }