<cm-routed-dialog [dialogSize]="dialogSizes.Large" [dialogScroll]="false" [needsConfirmationToClose]="modified" closeRoute="/templates">
    <ng-container cm-overlay-content>
        <div class="cm-main-card">
            <div class="cm-column">
                <div class="cm-row">
                    <div class="cm-caption">General</div>
                    <div class="cm-general-controls">
                        <input type="text" placeholder="Enter template uuid..." [(ngModel)]="templateId" (keyup.enter)="load()" />
                        <input type="text" placeholder="Enter search term..." (keyup.enter)="searchCatalog(searchTerm.value)" #searchTerm />
                        <select [(ngModel)]="priceGraphState">
                            @for (state of priceGraphStates; track state) {
                                <option [value]="state">
                                    {{ state }}
                                </option>
                            }
                        </select>
                        <input type="file" (change)="initCatalogFromLocalFile($event)" hidden #fileInput />
                        <input type="file" (change)="initNewCatalogFromLocalFile($event)" hidden #fileInputNew />
                        <button mat-raised-button (click)="fileInput.click()">Load Catalog</button>
                        <button mat-raised-button (click)="fileInputNew.click()">Load New Catalog</button>
                        <button mat-raised-button (click)="verifyAndSave()">Save</button>
                        <button mat-raised-button (click)="deletePriceGraph()">Delete</button>
                        <button mat-raised-button (click)="updateCatalog()">Update Catalog</button>
                    </div>
                </div>
                <div class="cm-row cm-equal">
                    <div class="cm-caption">Search Results</div>
                    <mat-list>
                        @for (item of searchResults; track item.uniqueId) {
                            <mat-list-item
                                style="user-select: none"
                                [class.cm-selected]="item.uniqueId === selectedSearchItem?.uniqueId"
                                (click)="onSearchItemClick(item)"
                                (dblclick)="onSearchItemDoubleClick(item)"
                                >{{ item.description }}</mat-list-item
                            >
                        }
                    </mat-list>
                </div>

                <div class="cm-row cm-equal">
                    <div class="cm-caption">Api Output</div>
                    <cm-price-table [items]="currentPrices" [error]="error"></cm-price-table>
                </div>
            </div>
            <div class="cm-column">
                <div class="cm-row cm-equal" style="overflow-y: hidden">
                    <div class="cm-caption">Pricing Graph</div>
                    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="cm-tree">
                        <!-- This is the tree node template for leaf nodes -->
                        <mat-tree-node
                            [class.cm-selected]="nodeSelected(node)"
                            [class.no-children]="!hasChild(0, node)"
                            *matTreeNodeDef="let node"
                            matTreeNodeToggle
                            (click)="onTreeNodeClick(node, $event)"
                            (dblclick)="onTreeNodeDoubleClick(node, $event)"
                            (dragover)="onDragOver($event, node)"
                            (drop)="onDrop($event, node)"
                        >
                            @if (pricingContext) {
                                <cm-price-node-visualizer
                                    [node]="node"
                                    [pricingContext]="pricingContext"
                                    (conditionRemoved)="updatePrice()"
                                ></cm-price-node-visualizer>
                            }
                        </mat-tree-node>
                        <!-- This is the tree node template for expandable nodes -->
                        <mat-nested-tree-node
                            [class.cm-selected]="nodeSelected(node)"
                            [class.no-children]="!hasChild(0, node)"
                            *matTreeNodeDef="let node; when: hasChild"
                            (dragover)="onDragOver($event, node)"
                            (drop)="onDrop($event, node)"
                        >
                            <div
                                class="mat-tree-node"
                                [class.cm-selected]="nodeSelected(node)"
                                (click)="onTreeNodeClick(node, $event)"
                                (dblclick)="onTreeNodeDoubleClick(node, $event)"
                            >
                                <button mat-icon-button matTreeNodeToggle class="cm-tree-toggle-button">
                                    <i class="far mat-icon-rtl-mirror" [class]="treeControl.isExpanded(node) ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
                                </button>
                                @if (pricingContext) {
                                    <cm-price-node-visualizer
                                        [node]="node"
                                        [pricingContext]="pricingContext"
                                        (conditionRemoved)="updatePrice()"
                                    ></cm-price-node-visualizer>
                                }
                            </div>
                            <div [class.cm-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </div>
                        </mat-nested-tree-node>
                    </mat-tree>
                </div>

                <div class="cm-row">
                    <div class="cm-caption">Node Settings</div>
                    <div class="cm-content">
                        <cm-node-settings [node]="selectedTreeNode"></cm-node-settings>
                    </div>
                </div>
            </div>
            <div class="cm-column">
                <div class="cm-caption">Configurator</div>
                <div class="cm-menu"><cm-config-menu #configuratorMenu [externalMenu]="true"></cm-config-menu></div>
                <div class="cm-configurator">
                    <cm-configurator
                        [showUi]="false"
                        (loadingCompleted)="loadPriceGraph()"
                        (configurationLoaded)="updateCurrentConfigurations()"
                        #configurator
                    ></cm-configurator>
                </div>
            </div>
        </div>
    </ng-container>
</cm-routed-dialog>
