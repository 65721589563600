import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { BasicTagInfoFragmentDoc } from '../../../../common/services/tags/tags.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MaterialsGridItemFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, articleId?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type MaterialRevisionForCopyFragment = { __typename: 'MaterialRevision', id: string, number: number, graphSchema?: string | null, comment?: string | null, hasCyclesMaterial: boolean, nodes: Array<{ __typename: 'MaterialNode', id: string, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', id: string } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string } | null }>, connections: Array<{ __typename: 'MaterialConnection', id: string, destinationParameter: string, sourceParameter: string, destination: { __typename: 'MaterialNode', id: string }, source: { __typename: 'MaterialNode', id: string } }> };

export type GetMaterialForMaterialExplorerQueryVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type GetMaterialForMaterialExplorerQuery = { __typename: 'Query', material: { __typename: 'Material', revisions: Array<{ __typename: 'MaterialRevision', legacyId: number, nodes: Array<{ __typename: 'MaterialNode', name: string, legacyId: number }> }>, textureGroup?: { __typename: 'TextureGroup', textureSets: Array<{ __typename: 'TextureSet', legacyId: number }> } | null } };

export type GetMaterialsQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.MaterialFilterInput>;
  orderBy?: Types.InputMaybe<Array<Types.MaterialOrderByInput> | Types.MaterialOrderByInput>;
}>;


export type GetMaterialsQuery = { __typename: 'Query', materialsCount: number, materials: Array<{ __typename: 'Material', id: string, legacyId: number, name?: string | null, articleId?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export type GetMaterialIdsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.MaterialFilterInput>;
}>;


export type GetMaterialIdsQuery = { __typename: 'Query', materials: Array<{ __typename: 'Material', id: string } | null> };

export type GetMaterialForCopyQueryVariables = Types.Exact<{
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type GetMaterialForCopyQuery = { __typename: 'Query', material: { __typename: 'Material', legacyId: number, articleId?: string | null, name?: string | null, nextActor: Types.NextActor, public: boolean, meshSpecific: boolean, sampleArrival?: string | null, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string, legacyId: number, name: string, description?: string | null, color?: string | null, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string, legacyId: number } | null } }>, revisions: Array<{ __typename: 'MaterialRevision', id: string, number: number, graphSchema?: string | null, comment?: string | null, hasCyclesMaterial: boolean, nodes: Array<{ __typename: 'MaterialNode', id: string, name: string, parameters: any, textureRevision?: { __typename: 'TextureRevision', id: string } | null, textureSetRevision?: { __typename: 'TextureSetRevision', id: string } | null }>, connections: Array<{ __typename: 'MaterialConnection', id: string, destinationParameter: string, sourceParameter: string, destination: { __typename: 'MaterialNode', id: string }, source: { __typename: 'MaterialNode', id: string } }> }> } };

export type BatchUpdateMaterialsMutationVariables = Types.Exact<{
  filter: Types.MaterialFilterInput;
  state?: Types.InputMaybe<Types.MaterialState>;
  addTagId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  removeTagId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  paymentState?: Types.InputMaybe<Types.PaymentState>;
  assignUserId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  removeUserAssignment?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  nextActor?: Types.InputMaybe<Types.NextActor>;
}>;


export type BatchUpdateMaterialsMutation = { __typename: 'Mutation', batchUpdateMaterials: number };

export type CreateMaterialMutationVariables = Types.Exact<{
  input: Types.MutationCreateMaterialInput;
}>;


export type CreateMaterialMutation = { __typename: 'Mutation', createMaterial: { __typename: 'Material', id: string, legacyId: number, name?: string | null, articleId?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type UpdateMaterialMutationVariables = Types.Exact<{
  input: Types.MutationUpdateMaterialInput;
}>;


export type UpdateMaterialMutation = { __typename: 'Mutation', updateMaterial: { __typename: 'Material', id: string, legacyId: number, name?: string | null, articleId?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type CreateMaterialRevisionMutationVariables = Types.Exact<{
  input: Types.MutationCreateMaterialRevisionInput;
}>;


export type CreateMaterialRevisionMutation = { __typename: 'Mutation', createMaterialRevision: { __typename: 'MaterialRevision', id: string } };

export type CreateMaterialNodeMutationVariables = Types.Exact<{
  input: Types.MutationCreateMaterialNodeInput;
}>;


export type CreateMaterialNodeMutation = { __typename: 'Mutation', createMaterialNode: { __typename: 'MaterialNode', id: string } };

export type CreateMaterialConnectionMutationVariables = Types.Exact<{
  input: Types.MutationCreateMaterialConnectionInput;
}>;


export type CreateMaterialConnectionMutation = { __typename: 'Mutation', createMaterialConnection: { __typename: 'MaterialConnection', id: string } };

export type MaterialsGridDeleteThumbnailMutationVariables = Types.Exact<{
  dataObjectId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type MaterialsGridDeleteThumbnailMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export type MaterialsGridUpdateMaterialMutationVariables = Types.Exact<{
  input: Types.MutationUpdateMaterialInput;
}>;


export type MaterialsGridUpdateMaterialMutation = { __typename: 'Mutation', updateMaterial: { __typename: 'Material', id: string } };

export type MaterialsGridCreateDataObjectAssignmentMutationVariables = Types.Exact<{
  input: Types.MutationCreateDataObjectAssignmentInput;
}>;


export type MaterialsGridCreateDataObjectAssignmentMutation = { __typename: 'Mutation', createDataObjectAssignment: { __typename: 'DataObjectAssignment', id: string } };

export type MaterialsGridMaterialRangeTagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MaterialsGridMaterialRangeTagsQuery = { __typename: 'Query', tags: Array<{ __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } | null> };

export const MaterialsGridItemFragmentDoc = gql`
    fragment MaterialsGridItem on Material {
  __typename
  id
  legacyId
  name
  articleId
  organization {
    id
    name
  }
  galleryImage {
    id
  }
}
    `;
export const MaterialRevisionForCopyFragmentDoc = gql`
    fragment MaterialRevisionForCopy on MaterialRevision {
  id
  number
  graphSchema
  comment
  hasCyclesMaterial
  nodes {
    id
    name
    parameters
    textureRevision {
      id
    }
    textureSetRevision {
      id
    }
  }
  connections {
    id
    destination {
      id
    }
    destinationParameter
    source {
      id
    }
    sourceParameter
  }
}
    `;
export const GetMaterialForMaterialExplorerDocument = gql`
    query getMaterialForMaterialExplorer($legacyId: Int!) {
  material(legacyId: $legacyId) {
    revisions {
      legacyId
      nodes {
        name
        legacyId
      }
    }
    textureGroup {
      textureSets {
        legacyId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialForMaterialExplorerGQL extends Apollo.Query<GetMaterialForMaterialExplorerQuery, GetMaterialForMaterialExplorerQueryVariables> {
    override document = GetMaterialForMaterialExplorerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMaterialsDocument = gql`
    query getMaterials($skip: Int, $take: Int, $filter: MaterialFilterInput, $orderBy: [MaterialOrderByInput!]) {
  materials(skip: $skip, take: $take, filter: $filter, orderBy: $orderBy) {
    ...MaterialsGridItem
  }
  materialsCount(filter: $filter)
}
    ${MaterialsGridItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialsGQL extends Apollo.Query<GetMaterialsQuery, GetMaterialsQueryVariables> {
    override document = GetMaterialsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMaterialIdsDocument = gql`
    query getMaterialIds($filter: MaterialFilterInput) {
  materials(filter: $filter) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialIdsGQL extends Apollo.Query<GetMaterialIdsQuery, GetMaterialIdsQueryVariables> {
    override document = GetMaterialIdsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMaterialForCopyDocument = gql`
    query getMaterialForCopy($legacyId: Int, $id: ID) {
  material(legacyId: $legacyId, id: $id) {
    legacyId
    articleId
    name
    nextActor
    organization {
      id
      legacyId
      name
    }
    tagAssignments {
      tag {
        id
        legacyId
        name
      }
    }
    tagAssignments {
      id
      tag {
        id
        legacyId
        createdBy {
          id
        }
        name
        description
        color
        type
        organization {
          id
          legacyId
        }
      }
    }
    public
    meshSpecific
    sampleArrival
    revisions {
      ...MaterialRevisionForCopy
    }
  }
}
    ${MaterialRevisionForCopyFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialForCopyGQL extends Apollo.Query<GetMaterialForCopyQuery, GetMaterialForCopyQueryVariables> {
    override document = GetMaterialForCopyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchUpdateMaterialsDocument = gql`
    mutation batchUpdateMaterials($filter: MaterialFilterInput!, $state: MaterialState, $addTagId: ID, $removeTagId: ID, $paymentState: PaymentState, $assignUserId: ID, $removeUserAssignment: Boolean, $nextActor: NextActor) {
  batchUpdateMaterials(
    filter: $filter
    addTagId: $addTagId
    assignUserId: $assignUserId
    paymentState: $paymentState
    removeTagId: $removeTagId
    removeUserAssignment: $removeUserAssignment
    state: $state
    nextActor: $nextActor
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchUpdateMaterialsGQL extends Apollo.Mutation<BatchUpdateMaterialsMutation, BatchUpdateMaterialsMutationVariables> {
    override document = BatchUpdateMaterialsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMaterialDocument = gql`
    mutation createMaterial($input: MutationCreateMaterialInput!) {
  createMaterial(input: $input) {
    ...MaterialsGridItem
  }
}
    ${MaterialsGridItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMaterialGQL extends Apollo.Mutation<CreateMaterialMutation, CreateMaterialMutationVariables> {
    override document = CreateMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMaterialDocument = gql`
    mutation updateMaterial($input: MutationUpdateMaterialInput!) {
  updateMaterial(input: $input) {
    ...MaterialsGridItem
  }
}
    ${MaterialsGridItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMaterialGQL extends Apollo.Mutation<UpdateMaterialMutation, UpdateMaterialMutationVariables> {
    override document = UpdateMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMaterialRevisionDocument = gql`
    mutation createMaterialRevision($input: MutationCreateMaterialRevisionInput!) {
  createMaterialRevision(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMaterialRevisionGQL extends Apollo.Mutation<CreateMaterialRevisionMutation, CreateMaterialRevisionMutationVariables> {
    override document = CreateMaterialRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMaterialNodeDocument = gql`
    mutation createMaterialNode($input: MutationCreateMaterialNodeInput!) {
  createMaterialNode(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMaterialNodeGQL extends Apollo.Mutation<CreateMaterialNodeMutation, CreateMaterialNodeMutationVariables> {
    override document = CreateMaterialNodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMaterialConnectionDocument = gql`
    mutation createMaterialConnection($input: MutationCreateMaterialConnectionInput!) {
  createMaterialConnection(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMaterialConnectionGQL extends Apollo.Mutation<CreateMaterialConnectionMutation, CreateMaterialConnectionMutationVariables> {
    override document = CreateMaterialConnectionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialsGridDeleteThumbnailDocument = gql`
    mutation materialsGridDeleteThumbnail($dataObjectId: ID) {
  deleteDataObject(id: $dataObjectId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialsGridDeleteThumbnailGQL extends Apollo.Mutation<MaterialsGridDeleteThumbnailMutation, MaterialsGridDeleteThumbnailMutationVariables> {
    override document = MaterialsGridDeleteThumbnailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialsGridUpdateMaterialDocument = gql`
    mutation materialsGridUpdateMaterial($input: MutationUpdateMaterialInput!) {
  updateMaterial(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialsGridUpdateMaterialGQL extends Apollo.Mutation<MaterialsGridUpdateMaterialMutation, MaterialsGridUpdateMaterialMutationVariables> {
    override document = MaterialsGridUpdateMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialsGridCreateDataObjectAssignmentDocument = gql`
    mutation materialsGridCreateDataObjectAssignment($input: MutationCreateDataObjectAssignmentInput!) {
  createDataObjectAssignment(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialsGridCreateDataObjectAssignmentGQL extends Apollo.Mutation<MaterialsGridCreateDataObjectAssignmentMutation, MaterialsGridCreateDataObjectAssignmentMutationVariables> {
    override document = MaterialsGridCreateDataObjectAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialsGridMaterialRangeTagsDocument = gql`
    query materialsGridMaterialRangeTags {
  tags(filter: {tagType: MaterialRange}) {
    ...BasicTagInfo
  }
}
    ${BasicTagInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialsGridMaterialRangeTagsGQL extends Apollo.Query<MaterialsGridMaterialRangeTagsQuery, MaterialsGridMaterialRangeTagsQueryVariables> {
    override document = MaterialsGridMaterialRangeTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }