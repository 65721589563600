import {inject, Injectable} from "@angular/core"
import {ImageProcessingActions} from "@cm/image-processing/image-processing-actions"
import {ImageProcessingNodes} from "@cm/image-processing-nodes"
import {ImageProcessingInput, imageProcessingTask} from "@cm/job-nodes/image-processing"
import {JobNodes} from "@cm/job-nodes/job-nodes"
import {graphToJson} from "@cm/utils/graph-json"
import {TypedImageData} from "@cm/utils/typed-image-data"
import {jsonToFile} from "@legacy/helpers/utils"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {WebAssemblyWorkerService} from "@app/template-editor/services/webassembly-worker.service"
import {map, Observable} from "rxjs"

@Injectable({
    providedIn: "root",
})
export class ImageProcessingService extends ImageProcessingActions {
    readonly uploadService = inject(UploadGqlService)

    constructor(workerService: WebAssemblyWorkerService) {
        super(workerService)
    }

    toCanvasImageData(image: TypedImageData): Observable<ImageData | null> {
        return this.convertToUint8RGBA(image).pipe(
            map((data) => (data ? new ImageData(new Uint8ClampedArray(data.buffer, data.byteOffset, data.byteLength), image.width, image.height) : null)),
        )
    }

    async createImageProcessingTask(graph: ImageProcessingNodes.Output, data: {organizationId: string}, filename = "image-processing.json") {
        const file: File = jsonToFile(graphToJson(graph, console), filename)
        const dataObject = await this.uploadService.createAndUploadDataObject(
            file,
            {organizationId: data.organizationId},
            {showUploadToolbar: false, processUpload: false},
        )
        const input: ImageProcessingInput = {
            graphDataObjectReference: JobNodes.dataObjectReference(dataObject.legacyId),
        }
        return JobNodes.task(imageProcessingTask, {
            input: JobNodes.value(input),
        })
    }
}
