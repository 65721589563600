import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { HdriDetailsFragmentDoc } from '../hdri-details/hdri-details.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type HdrisGridItemFragment = { __typename: 'Hdri', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, dataObject?: { __typename: 'DataObject', state: Types.DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type HdrisGridItemsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.HdriFilterInput>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type HdrisGridItemsQuery = { __typename: 'Query', hdrisCount: number, hdris: Array<{ __typename: 'Hdri', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, dataObject?: { __typename: 'DataObject', state: Types.DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export type CreateHdrisGridItemMutationVariables = Types.Exact<{
  input: Types.MutationCreateHdriInput;
}>;


export type CreateHdrisGridItemMutation = { __typename: 'Mutation', createHdri: { __typename: 'Hdri', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, dataObject?: { __typename: 'DataObject', state: Types.DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type UpdateHdriMutationVariables = Types.Exact<{
  input: Types.MutationUpdateHdriInput;
}>;


export type UpdateHdriMutation = { __typename: 'Mutation', updateHdri: { __typename: 'Hdri', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, dataObject?: { __typename: 'DataObject', state: Types.DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export const HdrisGridItemFragmentDoc = gql`
    fragment HdrisGridItem on Hdri {
  id
  legacyId
  name
  organization {
    id
    legacyId
    name
  }
  dataObject {
    state
    bucketName
    objectName
    mediaType
  }
  galleryImage {
    id
  }
}
    `;
export const HdrisGridItemsDocument = gql`
    query hdrisGridItems($filter: HdriFilterInput, $skip: Int, $take: Int) {
  hdris(filter: $filter, skip: $skip, take: $take) {
    ...HdrisGridItem
  }
  hdrisCount(filter: $filter)
}
    ${HdrisGridItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class HdrisGridItemsGQL extends Apollo.Query<HdrisGridItemsQuery, HdrisGridItemsQueryVariables> {
    override document = HdrisGridItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateHdrisGridItemDocument = gql`
    mutation createHdrisGridItem($input: MutationCreateHdriInput!) {
  createHdri(input: $input) {
    ...HdrisGridItem
  }
}
    ${HdrisGridItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateHdrisGridItemGQL extends Apollo.Mutation<CreateHdrisGridItemMutation, CreateHdrisGridItemMutationVariables> {
    override document = CreateHdrisGridItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHdriDocument = gql`
    mutation updateHdri($input: MutationUpdateHdriInput!) {
  updateHdri(input: $input) {
    ...HdriDetails
  }
}
    ${HdriDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHdriGQL extends Apollo.Mutation<UpdateHdriMutation, UpdateHdriMutationVariables> {
    override document = UpdateHdriDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }