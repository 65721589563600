import {TagFilterInput, TagType} from "@generated"
import {PermissionsService} from "@app/common/services/permissions/permissions.service"
import {OrganizationsService} from "@app/common/services/organizations/organizations.service"
import {stringInFilter, arrayFilter} from "@app/common/services/filters/filters.service"
import {Labels} from "@labels"

export function maybeConstrainTagFilterOnTagsPage(
    filter: TagFilterInput,
    organizations: OrganizationsService,
    permissions: PermissionsService,
): TagFilterInput {
    if (permissions.$to().read.tagsPage("all")) return filter

    const organizationId = organizations.$current()?.id
    if (!organizationId) return filter

    const allowedTagTypes = Array.from(Labels.TagType.values())
        .filter((label) => permissions.$to().read.tagType(label.state))
        .map((label) => label.state)
    const tagType = filter.tagType ? Array.from(filter.tagType).filter((type) => allowedTagTypes.includes(type)) : allowedTagTypes

    return {
        ...filter,
        organizationId: stringInFilter(new Set([organizationId])),
        tagType: arrayFilter(new Set(tagType)) as TagType[],
    }
}
