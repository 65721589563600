<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> Render </ng-container>
    <ng-container cm-content>
        <div class="cm-resolution">
            <cm-value-slot [node]="$node()" [key]="'width'" [label]="'W'" [decimalPlaces]="0"></cm-value-slot>
            <div class="cm-multiplier">×</div>
            <cm-value-slot [node]="$node()" [key]="'height'" [label]="'H'" [decimalPlaces]="0"></cm-value-slot>
        </div>
        <cm-value-slot [node]="$node()" [key]="'samples'" [label]="'Samples'" [decimalPlaces]="0"></cm-value-slot>
        @if ($can().read.menu("gpuRender")) {
            <cm-value-slot [node]="$node()" [key]="'gpu'" [label]="'GPU'"></cm-value-slot>
        }
        @if ($can().read.menu("cloudRender")) {
            <cm-value-slot [node]="$node()" [key]="'cloud'" [label]="'Cloud'"></cm-value-slot>
        }
    </ng-container>
</cm-inspector-section>
