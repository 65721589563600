import {EventEmitter} from "@angular/core"
import {ImageProcessingService} from "@app/common/services/rendering/image-processing.service"
import {TexturesApiService} from "@app/textures/service/textures-api.service"
import {ImageCacheWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-cache-webgl2"
import {DataType} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageProcessingNodes as Nodes} from "@cm/image-processing-nodes"
import {JobNodes} from "@cm/job-nodes/job-nodes"
import {TypedImageData} from "@cm/utils/typed-image-data"
import {HalPainterImageBlit} from "@common/models/hal/common/hal-painter-image-blit"
import {HalImage} from "@common/models/hal/hal-image"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {ImageImgProc} from "app/textures/texture-editor/operator-stack/image-op-system/image-imgproc"
import {ImageWebGL2} from "app/textures/texture-editor/operator-stack/image-op-system/image-webgl2"
import {firstValueFrom, switchMap} from "rxjs"
import {
    DrawableImageHandleId,
    ManagedDrawableImageHandle,
    RefCountedDrawableImageHandle,
} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/drawable-image-handle"
import {HalImageDescriptor} from "@common/models/hal/hal-image/types"
import {Size2Like} from "@cm/math"
import {isHalImageDescriptor} from "@common/helpers/hal"
import {UploadServiceDataObjectFragment} from "@common/services/upload/upload.generated"
import {ImageColorSpace} from "@generated"

export class DrawableImageCache {
    readonly imageInstanceUpdated = new EventEmitter<{id: DrawableImageHandleId; isEmpty: boolean}>()
    readonly imageRemoved = new EventEmitter<DrawableImageHandleId>()

    constructor(
        private customerLegacyIdFn: () => number,
        private imageCacheWebGL2: ImageCacheWebGL2,
        private imageProcessingService: ImageProcessingService,
        private uploadGqlService: UploadGqlService,
        private texturesApi: TexturesApiService,
    ) {
        this.halBlitter = new HalPainterImageBlit(imageCacheWebGL2.halContext)
    }

    dispose(): void {
        const handles = this.infoById.keys()
        for (;;) {
            const handle = handles.next().value
            if (!handle) {
                break
            }
            this.removeDrawableImage(handle)
        }
        this.halBlitter.dispose()
    }

    get contentStats(): string {
        return `${this.infoById.size} cached`
    }

    hasDrawableImage(id: DrawableImageHandleId): boolean {
        return this.infoById.has(id)
    }

    async createDrawableImage(descriptorOrDataObject: HalImageDescriptor | (DescriptorWithoutSize & {dataObjectId: string})) {
        const id = this.nextId++
        const handle = new RefCountedDrawableImageHandle(id, () => this.removeDrawableImage(id))
        let descriptor: HalImageDescriptor
        let dataObjectId: string | undefined
        if (isHalImageDescriptor(descriptorOrDataObject)) {
            descriptor = descriptorOrDataObject
            dataObjectId = undefined
        } else {
            descriptor = await this.texturesApi.getDataObjectImageDescriptor(descriptorOrDataObject.dataObjectId, {requireJpg: false}).then((dataObject) => {
                const {dataObjectId: _, ...descriptor} = descriptorOrDataObject
                return {
                    ...descriptor,
                    width: dataObject.width,
                    height: dataObject.height,
                }
            })
            dataObjectId = descriptorOrDataObject.dataObjectId
        }
        const info: HandleInfo = {descriptor, dataObjectId}
        this.infoById.set(id, info)
        const managedHandle = new ManagedDrawableImageHandle(handle)
        handle.release()
        return managedHandle
    }

    async copyDrawableImage(args: {sourceId: DrawableImageHandleId; targetId: DrawableImageHandleId}) {
        const {sourceId, targetId} = args
        const sourceWebGlImage = await this.getHalPaintableImage(sourceId)
        if (!this.hasDrawableImage(targetId)) {
            throw Error("Target drawable image does not exist")
        }
        // make sure the target image matches the source image in size
        this.setImageSize(targetId, {width: sourceWebGlImage.descriptor.width, height: sourceWebGlImage.descriptor.height})
        const targetWebGlImage = await this.getHalPaintableImage(targetId)
        if (!this.isDescriptorCompatible(sourceWebGlImage.descriptor, targetWebGlImage.descriptor)) {
            throw Error("Drawable image formats do not match")
        }
        this.halBlitter.paint({
            target: targetWebGlImage,
            sourceImages: sourceWebGlImage,
        })
        const sourceInfo = this.getInfo(sourceId)
        const targetInfo = this.getInfo(targetId)
        targetInfo.dataObjectId = sourceInfo.dataObjectId
    }

    setImageSize(id: DrawableImageHandleId, size: Size2Like) {
        const info = this.getInfo(id)
        if (info.descriptor && info.descriptor.width === size.width && info.descriptor.height === size.height) {
            return
        }
        info.descriptor = {
            ...info.descriptor,
            width: size.width,
            height: size.height,
        }
        if (info.webGlImage) {
            const resizeImage = async (halImage: HalImage, size: Size2Like) => {
                const newHalImage = this.imageCacheWebGL2.getImage({...halImage.descriptor, ...size}, {clear: true})
                this.halBlitter.paint({
                    target: newHalImage,
                    sourceImages: halImage,
                })
                this.imageCacheWebGL2.releaseImage(halImage)
                this.markDirty(id)
                return newHalImage
            }
            if (info.webGlImage instanceof Promise) {
                info.webGlImage = info.webGlImage.then((halImage) => resizeImage(halImage, size))
            } else {
                info.webGlImage = resizeImage(info.webGlImage, size)
            }
        }
    }

    getImageDescriptor(id: DrawableImageHandleId): HalImageDescriptor {
        const info = this.getInfo(id)
        return info.descriptor
    }

    async getImageWebGL2(id: DrawableImageHandleId): Promise<ImageWebGL2> {
        const halImage = await this.getHalPaintableImage(id)
        return new ImageWebGL2(
            "drawable",
            id,
            undefined,
            halImage,
            {
                ...halImage.descriptor,
            },
            "DrawableImageCache",
        )
    }

    async getImageImgProc(id: DrawableImageHandleId): Promise<ImageImgProc> {
        const dataObjectId = await this.uploadDrawableImage(id)
        const dataObjectLegacyId = await this.texturesApi.getDataObjectLegacyId(dataObjectId)
        const dataObjectReference = JobNodes.dataObjectReference(dataObjectLegacyId)
        return Nodes.decode(Nodes.externalData(dataObjectReference, "encodedData"))
    }

    async getDataObjectId(id: DrawableImageHandleId) {
        const info = this.getInfo(id)
        if (!info.dataObjectId) {
            throw Error("Data object id not available")
        }
        return info.dataObjectId
    }

    markDirty(id: DrawableImageHandleId): void {
        const info = this.getInfo(id)
        info.dataObjectId = undefined
    }

    private isDescriptorCompatible(a: HalImageDescriptor, b: HalImageDescriptor): boolean {
        return a.width === b.width && a.height === b.height && a.channelLayout === b.channelLayout && a.dataType === b.dataType
    }

    private getInfo(id: DrawableImageHandleId): HandleInfo {
        const info = this.infoById.get(id)
        if (!info) {
            throw Error(`Drawable image handle with id ${id} not found`)
        }
        return info
    }

    private getHalPaintableImage(id: DrawableImageHandleId) {
        return this.downloadOrCreateWebGlImage(id)
    }

    private removeDrawableImage(id: DrawableImageHandleId): void {
        const info = this.getInfo(id)
        if (info.webGlImage) {
            if (info.webGlImage instanceof Promise) {
                info.webGlImage.then((halImage) => this.imageCacheWebGL2.releaseImage(halImage))
            } else {
                this.imageCacheWebGL2.releaseImage(info.webGlImage)
            }
        }
        this.infoById.delete(id)
        this.imageRemoved.emit(id)
    }

    // creates a new webgl image from the downloaded data-object by the given handle
    private async downloadOrCreateWebGlImage(id: DrawableImageHandleId) {
        const info = this.getInfo(id)
        if (info.webGlImage instanceof Promise) {
            info.webGlImage = await info.webGlImage
        } else if (!info.webGlImage) {
            if (info.dataObjectId) {
                if (info.dataObjectId instanceof Promise) {
                    throw Error("Data object id is a promise which should never happen")
                }
                info.webGlImage = this.downloadImage(info.dataObjectId, info.descriptor.dataType)
            } else {
                info.webGlImage = this.imageCacheWebGL2.getImage(info.descriptor, {clear: true})
            }
            info.webGlImage = await info.webGlImage
            this.imageInstanceUpdated.emit({id, isEmpty: false})
        }
        if (!info.webGlImage) {
            throw Error("WebGlImage for drawable image not available")
        }
        if (info.webGlImage.descriptor.width !== info.descriptor.width || info.webGlImage.descriptor.height !== info.descriptor.height) {
            console.warn(
                `DrawableImage size has been changed during fetch from ${info.descriptor.width}x${info.descriptor.height} to ${info.webGlImage.descriptor.width}x${info.webGlImage.descriptor.height}`,
            )
        }
        return info.webGlImage
    }

    // uploads (if needed) the webgl image to a new data-object and updates the id
    private async uploadDrawableImage(id: DrawableImageHandleId): Promise<string> {
        const info = this.getInfo(id)
        if (info.dataObjectId instanceof Promise) {
            await info.dataObjectId
        } else if (!info.dataObjectId) {
            const webGlImage = await this.getHalPaintableImage(id)
            info.dataObjectId = this.uploadImage(this.customerLegacyIdFn(), webGlImage).then((dataObject) => dataObject.id)
            info.dataObjectId = await info.dataObjectId
        }
        if (!info.dataObjectId) {
            throw Error("Drawable image could not been uploaded to data object")
        }
        return info.dataObjectId
    }

    private async downloadImage(dataObjectId: string, dataType: DataType): Promise<HalImage> {
        return this.texturesApi.createHalImageFromDataObject(this.imageCacheWebGL2, dataObjectId, {
            downloadFormat: "exr",
            preferredOutputFormat: dataType,
        })
    }

    private async uploadImage(customerId: number, webGlImage: HalImage): Promise<UploadServiceDataObjectFragment> {
        const maskImageData = await this.imageDataFromHalImage(webGlImage)
        const convertedMaskAlpha = Nodes.convert(Nodes.input(maskImageData), "float32", "L", false)
        const encodedMaskAlpha = Nodes.encode(convertedMaskAlpha, "image/x-exr")
        return firstValueFrom(
            this.imageProcessingService.evalGraph(encodedMaskAlpha).pipe(
                switchMap((evaledEncodedData) => {
                    const dataFile = new File([evaledEncodedData.data], "mask.exr")
                    return this.uploadGqlService.createAndUploadDataObject(
                        dataFile,
                        {
                            organizationLegacyId: customerId,
                            mediaType: "image/x-exr",
                            imageColorSpace: evaledEncodedData.colorSpace === "linear" ? ImageColorSpace.Linear : ImageColorSpace.Srgb,
                            width: evaledEncodedData.width,
                            height: evaledEncodedData.height,
                        },
                        {
                            showUploadToolbar: false,
                            processUpload: false,
                        },
                    )
                }),
            ),
        )
    }

    private async imageDataFromHalImage(halImage: HalImage): Promise<TypedImageData> {
        if (halImage.descriptor.channelLayout !== "R") {
            throw Error("webGlImage layout must be L")
        }
        return {
            data: await halImage.readImageDataFloat(),
            width: halImage.descriptor.width,
            height: halImage.descriptor.height,
            channelLayout: "L",
            dataType: "float32",
            colorSpace: halImage.descriptor.dataType === "uint8srgb" ? "sRGB" : "linear",
            dpi: undefined,
        }
    }

    private nextId = 1
    private infoById = new Map<DrawableImageHandleId, HandleInfo>()
    private halBlitter: HalPainterImageBlit
}

type HandleInfo = {
    descriptor: HalImageDescriptor
    dataObjectId?: string | Promise<string>
    webGlImage?: HalImage | Promise<HalImage>
}

export type DescriptorWithoutSize = Omit<HalImageDescriptor, "width" | "height">
