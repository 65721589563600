import {Component, inject, signal} from "@angular/core"
import {ActivatedRoute, Router} from "@angular/router"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {GetTemplateDetailsForTemplateViewerGQL} from "@common/components/dialogs/template-viewer-dialog/template-viewer-dialog.generated"
import {DialogSize} from "@common/models/dialogs"
import {ConfiguratorComponent} from "@app/common/components/viewers/configurator/configurator/configurator.component"
import {firstValueFrom} from "rxjs"

@Component({
    selector: "cm-template-viewer-dialog",
    templateUrl: "./template-viewer-dialog.component.html",
    styleUrls: ["./template-viewer-dialog.component.scss"],
    imports: [RoutedDialogComponent, ConfiguratorComponent],
})
export class TemplateViewerDialogComponent {
    readonly $templateId = signal<string | undefined>(undefined)

    dialogSizes = DialogSize
    templateLegacyId?: number
    closeNavigationPath: string

    readonly router = inject(Router)
    readonly route = inject(ActivatedRoute)
    readonly getTemplateDetailsForTemplateViewer = inject(GetTemplateDetailsForTemplateViewerGQL)

    constructor() {
        void this.initTemplateId()
        this.closeNavigationPath = this.route.snapshot.data.closeNavigationPath ?? "../"
    }

    async initTemplateId() {
        let templateId: string | null | undefined = this.route.snapshot.paramMap.get("templateId")

        if (!templateId) {
            const pictureId = this.route.snapshot.paramMap.get("pictureId")
            if (pictureId)
                templateId = (await firstValueFrom(this.getTemplateDetailsForTemplateViewer.fetch({pictureId: pictureId}))).data?.picture?.template?.id
        }

        if (!templateId) throw new Error("No templateId found in route")

        this.$templateId.set(templateId)
    }

    overlayClosed() {
        void this.router.navigate([this.closeNavigationPath], {relativeTo: this.route, queryParamsHandling: "preserve"})
    }
}
