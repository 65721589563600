import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AssetDetailsFragment = { __typename: 'Asset', id: string, legacyId: number, articleId?: string | null, comment?: string | null, name?: string | null, nextActor: Types.NextActor, state: Types.AssetState, galleryImage?: { __typename: 'DataObject', id: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, materialAssignments: Array<{ __typename: 'MaterialAssignment', id: string, material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, state: Types.MaterialState, galleryImage?: { __typename: 'DataObject', id: string } | null, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } }>, model: { __typename: 'Model', id: string, legacyId: number, name?: string | null, state: Types.ModelState, galleryImage?: { __typename: 'DataObject', id: string } | null, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } };

export type AssetDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type AssetDetailsQuery = { __typename: 'Query', item: { __typename: 'Asset', id: string, legacyId: number, articleId?: string | null, comment?: string | null, name?: string | null, nextActor: Types.NextActor, state: Types.AssetState, galleryImage?: { __typename: 'DataObject', id: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, materialAssignments: Array<{ __typename: 'MaterialAssignment', id: string, material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, state: Types.MaterialState, galleryImage?: { __typename: 'DataObject', id: string } | null, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } }>, model: { __typename: 'Model', id: string, legacyId: number, name?: string | null, state: Types.ModelState, galleryImage?: { __typename: 'DataObject', id: string } | null, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } } };

export type UpdateAssetDetailsMutationVariables = Types.Exact<{
  input: Types.MutationUpdateAssetInput;
}>;


export type UpdateAssetDetailsMutation = { __typename: 'Mutation', item: { __typename: 'Asset', id: string, legacyId: number, articleId?: string | null, comment?: string | null, name?: string | null, nextActor: Types.NextActor, state: Types.AssetState, galleryImage?: { __typename: 'DataObject', id: string } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, materialAssignments: Array<{ __typename: 'MaterialAssignment', id: string, material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, state: Types.MaterialState, galleryImage?: { __typename: 'DataObject', id: string } | null, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } }>, model: { __typename: 'Model', id: string, legacyId: number, name?: string | null, state: Types.ModelState, galleryImage?: { __typename: 'DataObject', id: string } | null, organization?: { __typename: 'Organization', id: string, legacyId: number, name?: string | null } | null } } };

export type DeleteAssetDetailsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteAssetDetailsMutation = { __typename: 'Mutation', deleteAsset?: any | null };

export type CreateAssetDetailsMaterialAssignmentMutationVariables = Types.Exact<{
  input: Types.MutationCreateMaterialAssignmentInput;
}>;


export type CreateAssetDetailsMaterialAssignmentMutation = { __typename: 'Mutation', createMaterialAssignment: { __typename: 'MaterialAssignment', id: string } };

export type DeleteAssetDetailsMaterialAssignmentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteAssetDetailsMaterialAssignmentMutation = { __typename: 'Mutation', deleteMaterialAssignment?: any | null };

export const AssetDetailsFragmentDoc = gql`
    fragment AssetDetails on Asset {
  id
  legacyId
  __typename
  articleId
  comment
  name
  nextActor
  state
  galleryImage {
    id
  }
  organization {
    id
    legacyId
    name
  }
  materialAssignments {
    id
    material {
      id
      legacyId
      galleryImage {
        id
      }
      name
      state
      organization {
        id
        legacyId
        name
      }
    }
  }
  model {
    id
    legacyId
    galleryImage {
      id
    }
    name
    state
    organization {
      id
      legacyId
      name
    }
  }
}
    `;
export const AssetDetailsDocument = gql`
    query assetDetails($id: ID!) {
  item: asset(id: $id) {
    ...AssetDetails
  }
}
    ${AssetDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssetDetailsGQL extends Apollo.Query<AssetDetailsQuery, AssetDetailsQueryVariables> {
    override document = AssetDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAssetDetailsDocument = gql`
    mutation updateAssetDetails($input: MutationUpdateAssetInput!) {
  item: updateAsset(input: $input) {
    ...AssetDetails
  }
}
    ${AssetDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAssetDetailsGQL extends Apollo.Mutation<UpdateAssetDetailsMutation, UpdateAssetDetailsMutationVariables> {
    override document = UpdateAssetDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAssetDetailsDocument = gql`
    mutation deleteAssetDetails($id: ID!) {
  deleteAsset(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAssetDetailsGQL extends Apollo.Mutation<DeleteAssetDetailsMutation, DeleteAssetDetailsMutationVariables> {
    override document = DeleteAssetDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAssetDetailsMaterialAssignmentDocument = gql`
    mutation createAssetDetailsMaterialAssignment($input: MutationCreateMaterialAssignmentInput!) {
  createMaterialAssignment(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAssetDetailsMaterialAssignmentGQL extends Apollo.Mutation<CreateAssetDetailsMaterialAssignmentMutation, CreateAssetDetailsMaterialAssignmentMutationVariables> {
    override document = CreateAssetDetailsMaterialAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAssetDetailsMaterialAssignmentDocument = gql`
    mutation deleteAssetDetailsMaterialAssignment($id: ID!) {
  deleteMaterialAssignment(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAssetDetailsMaterialAssignmentGQL extends Apollo.Mutation<DeleteAssetDetailsMaterialAssignmentMutation, DeleteAssetDetailsMaterialAssignmentMutationVariables> {
    override document = DeleteAssetDetailsMaterialAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }