import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ImageTextureSetNodeTextureSetRevisionInfoFragment = { __typename: 'TextureSetRevision', id: string, createdAt: string, name?: string | null };

export type ImageTextureSetNodeTextureSetFragment = { __typename: 'TextureSet', id: string, legacyId: number, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string, createdAt: string, name?: string | null }> };

export type ImageTextureSetNodeTextureSetRevisionFragment = { __typename: 'TextureSetRevision', id: string, name?: string | null, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', id: string, legacyId: number } }>, editsProcessingJob?: { __typename: 'Job', state: Types.JobState } | null, textureSet: { __typename: 'TextureSet', id: string, legacyId: number, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string, createdAt: string, name?: string | null }> } };

export type ImageTextureSetNodeTextureSetQueryVariables = Types.Exact<{
  textureSetLegacyId: Types.Scalars['Int']['input'];
}>;


export type ImageTextureSetNodeTextureSetQuery = { __typename: 'Query', textureSet: { __typename: 'TextureSet', id: string, legacyId: number, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string, createdAt: string, name?: string | null }> } };

export type ImageTextureSetNodeTextureSetRevisionQueryVariables = Types.Exact<{
  textureSetRevisionId: Types.Scalars['ID']['input'];
}>;


export type ImageTextureSetNodeTextureSetRevisionQuery = { __typename: 'Query', textureSetRevision: { __typename: 'TextureSetRevision', id: string, name?: string | null, width: number, height: number, displacement?: number | null, mapAssignments: Array<{ __typename: 'TextureSetRevisionMapAssignment', textureType: Types.TextureType, dataObject: { __typename: 'DataObject', id: string, legacyId: number } }>, editsProcessingJob?: { __typename: 'Job', state: Types.JobState } | null, textureSet: { __typename: 'TextureSet', id: string, legacyId: number, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string, createdAt: string, name?: string | null }> } } };

export const ImageTextureSetNodeTextureSetRevisionInfoFragmentDoc = gql`
    fragment ImageTextureSetNodeTextureSetRevisionInfo on TextureSetRevision {
  id
  createdAt
  name
}
    `;
export const ImageTextureSetNodeTextureSetFragmentDoc = gql`
    fragment ImageTextureSetNodeTextureSet on TextureSet {
  id
  legacyId
  textureSetRevisions {
    ...ImageTextureSetNodeTextureSetRevisionInfo
  }
}
    `;
export const ImageTextureSetNodeTextureSetRevisionFragmentDoc = gql`
    fragment ImageTextureSetNodeTextureSetRevision on TextureSetRevision {
  id
  name
  width
  height
  displacement
  mapAssignments {
    textureType
    dataObject {
      id
      legacyId
    }
  }
  editsProcessingJob {
    state
  }
  textureSet {
    ...ImageTextureSetNodeTextureSet
  }
}
    `;
export const ImageTextureSetNodeTextureSetDocument = gql`
    query imageTextureSetNodeTextureSet($textureSetLegacyId: Int!) {
  textureSet(legacyId: $textureSetLegacyId) {
    ...ImageTextureSetNodeTextureSet
  }
}
    ${ImageTextureSetNodeTextureSetFragmentDoc}
${ImageTextureSetNodeTextureSetRevisionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ImageTextureSetNodeTextureSetGQL extends Apollo.Query<ImageTextureSetNodeTextureSetQuery, ImageTextureSetNodeTextureSetQueryVariables> {
    override document = ImageTextureSetNodeTextureSetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImageTextureSetNodeTextureSetRevisionDocument = gql`
    query imageTextureSetNodeTextureSetRevision($textureSetRevisionId: ID!) {
  textureSetRevision(id: $textureSetRevisionId) {
    ...ImageTextureSetNodeTextureSetRevision
  }
}
    ${ImageTextureSetNodeTextureSetRevisionFragmentDoc}
${ImageTextureSetNodeTextureSetFragmentDoc}
${ImageTextureSetNodeTextureSetRevisionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ImageTextureSetNodeTextureSetRevisionGQL extends Apollo.Query<ImageTextureSetNodeTextureSetRevisionQuery, ImageTextureSetNodeTextureSetRevisionQueryVariables> {
    override document = ImageTextureSetNodeTextureSetRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }