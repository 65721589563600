import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MaterialOutputsDeleteDataObjectMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MaterialOutputsDeleteDataObjectMutation = { __typename: 'Mutation', deleteDataObject?: any | null };

export type MaterialOutputsDeleteJsonFileMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MaterialOutputsDeleteJsonFileMutation = { __typename: 'Mutation', deleteJsonFile?: any | null };

export type MaterialOutputsCreateMaterialOutputMutationVariables = Types.Exact<{
  input: Types.MutationCreateMaterialOutputInput;
}>;


export type MaterialOutputsCreateMaterialOutputMutation = { __typename: 'Mutation', createMaterialOutput: { __typename: 'MaterialOutput', id: string } };

export type MaterialOutputsCancelJobMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MaterialOutputsCancelJobMutation = { __typename: 'Mutation', cancelJob: { __typename: 'Job', id: string } };

export type MaterialOutputsRestartJobMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MaterialOutputsRestartJobMutation = { __typename: 'Mutation', restartJob: { __typename: 'Job', id: string } };

export type MaterialOutputsDeleteOutputMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MaterialOutputsDeleteOutputMutation = { __typename: 'Mutation', deleteMaterialOutput?: any | null };

export const MaterialOutputsDeleteDataObjectDocument = gql`
    mutation materialOutputsDeleteDataObject($id: ID!) {
  deleteDataObject(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialOutputsDeleteDataObjectGQL extends Apollo.Mutation<MaterialOutputsDeleteDataObjectMutation, MaterialOutputsDeleteDataObjectMutationVariables> {
    override document = MaterialOutputsDeleteDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialOutputsDeleteJsonFileDocument = gql`
    mutation materialOutputsDeleteJsonFile($id: ID!) {
  deleteJsonFile(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialOutputsDeleteJsonFileGQL extends Apollo.Mutation<MaterialOutputsDeleteJsonFileMutation, MaterialOutputsDeleteJsonFileMutationVariables> {
    override document = MaterialOutputsDeleteJsonFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialOutputsCreateMaterialOutputDocument = gql`
    mutation materialOutputsCreateMaterialOutput($input: MutationCreateMaterialOutputInput!) {
  createMaterialOutput(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialOutputsCreateMaterialOutputGQL extends Apollo.Mutation<MaterialOutputsCreateMaterialOutputMutation, MaterialOutputsCreateMaterialOutputMutationVariables> {
    override document = MaterialOutputsCreateMaterialOutputDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialOutputsCancelJobDocument = gql`
    mutation materialOutputsCancelJob($id: ID!) {
  cancelJob(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialOutputsCancelJobGQL extends Apollo.Mutation<MaterialOutputsCancelJobMutation, MaterialOutputsCancelJobMutationVariables> {
    override document = MaterialOutputsCancelJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialOutputsRestartJobDocument = gql`
    mutation materialOutputsRestartJob($id: ID!) {
  restartJob(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialOutputsRestartJobGQL extends Apollo.Mutation<MaterialOutputsRestartJobMutation, MaterialOutputsRestartJobMutationVariables> {
    override document = MaterialOutputsRestartJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialOutputsDeleteOutputDocument = gql`
    mutation materialOutputsDeleteOutput($id: ID!) {
  deleteMaterialOutput(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialOutputsDeleteOutputGQL extends Apollo.Mutation<MaterialOutputsDeleteOutputMutation, MaterialOutputsDeleteOutputMutationVariables> {
    override document = MaterialOutputsDeleteOutputDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }