import {ReifiedMeshData} from "@cm/template-nodes"
import {AsyncCacheMap} from "@common/helpers/async-cache-map/async-cache-map"
import {Observable} from "rxjs"
import {MeshNodes, DataNodes} from "@cm/render-nodes"
import {hashObject} from "@cm/utils"
import {evaluateMesh, WebAssemblyWorkerService} from "@app/template-editor/helpers/mesh-processing"

export class MeshDataCache {
    private cache: AsyncCacheMap<string, ReifiedMeshData, [MeshNodes.Mesh, DataNodes.EvaluationResources]>

    constructor(workerService: WebAssemblyWorkerService) {
        this.cache = new AsyncCacheMap((key, [graph, resources]) => {
            return evaluateMesh(workerService, graph, resources)
        })
    }

    evaluateMesh(graph: MeshNodes.Mesh, resources: DataNodes.EvaluationResources): Observable<ReifiedMeshData> {
        return this.cache.get(hashObject(graph), [graph, resources])
    }

    getIfCached(graph: MeshNodes.Mesh): ReifiedMeshData | null {
        return this.cache.getSync(hashObject(graph)) ?? null
    }

    invalidate() {
        this.cache.clear()
    }
}
