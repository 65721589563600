import {Component, DestroyRef, inject, OnDestroy, viewChild} from "@angular/core"
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop"
import {FormsModule} from "@angular/forms"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatSnackBar} from "@angular/material/snack-bar"
import {ActivatedRoute, Router} from "@angular/router"
import {ConfigMenuService} from "@app/common/components/viewers/configurator/config-menu/services/config-menu.service"
import {ConfiguratorComponent} from "@app/common/components/viewers/configurator/configurator/configurator.component"
import {MaterialGraphService} from "@app/common/services/material-graph/material-graph.service"
import {
    MaterialExplorerItemFragment,
    MaterialExplorerMaterialRevisionFragment,
    MaterialExplorerTextureFragment,
    MaterialExplorerTextureRevisionFragment,
    MaterialExplorerTextureSetFragment,
    MaterialExplorerTextureSetRevisionFragment,
} from "@app/platform/components/materials/material-explorer/material-explorer.generated"
import {ThumbnailsService} from "@app/platform/services/thumbnails/thumbnails.service"
import {IMaterialConnection, IMaterialNode} from "@cm/material-nodes"
import {IsDefined} from "@cm/utils/filter"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {LoadingSpinnerComponent} from "@common/components/progress"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {DialogSize} from "@common/models/dialogs"
import {MeasurementTypes} from "@common/models/settings/settings"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {DataObjectState, DataObjectType, ImageDataType, TextureType} from "@generated"
import {UtilsService} from "@legacy/helpers/utils"
import {AddTextureDialogComponent} from "@platform/components/materials/add-texture-dialog/add-texture-dialog.component"
import {
    MaterialExplorerCreateTextureRevisionGQL,
    MaterialExplorerCreateTextureSetRevisionGQL,
    MaterialExplorerDataGQL,
    MaterialExplorerDeleteTextureRevisionGQL,
    MaterialExplorerDeleteTextureSetRevisionGQL,
    MaterialExplorerItemGQL,
    MaterialExplorerMaterialNodeGQL,
} from "@platform/components/materials/material-explorer/material-explorer.generated"
import {AddTextureDialogResult} from "@platform/models/dialogs/add-texture-dialog-result"
import {TextureTypeLabels} from "@platform/models/state-labels/texture-type-labels"
import {filter, firstValueFrom, Observable, Subject, switchMap} from "rxjs"

export const fabricMaterialInputId = "combinedModels/material"

type OldMaterialLayoutData = {
    type: "OldMaterialLayout"
    texture: MaterialExplorerTextureFragment
    textureRevision?: MaterialExplorerTextureRevisionFragment
    texImageNode: IMaterialNode
    mappingNode: IMaterialNode
}

type NewMaterialLayoutData = {
    type: "NewMaterialLayout"
    textureSetRevision?: MaterialExplorerTextureSetRevisionFragment
    imageTextureSetNode: IMaterialNode
    mappingNode: IMaterialNode
}

type MaterialLayoutData = OldMaterialLayoutData | NewMaterialLayoutData

@Component({
    selector: "cm-material-explorer",
    templateUrl: "./material-explorer.component.html",
    styleUrls: ["./material-explorer.component.scss"],
    imports: [RoutedDialogComponent, LoadingSpinnerComponent, MatInputModule, FormsModule, ConfiguratorComponent],
})
export class MaterialExplorerComponent implements OnDestroy {
    readonly $configurator = viewChild.required<ConfiguratorComponent>("configurator")
    dropActive = {}
    dialogSizes = DialogSize
    unsubscribe = new Subject<void>()
    measurementTypes = MeasurementTypes
    textureSet!: MaterialExplorerTextureSetFragment
    materialRevision!: MaterialExplorerMaterialRevisionFragment
    materialConnections!: IMaterialConnection[]
    materialNodes!: IMaterialNode[]
    materialLayoutData!: MaterialLayoutData

    loading = false
    showSizeOptions = false

    parameterWidth: number = 15
    parameterHeight: number = 15
    parameterRotation: number = 0

    readonly destroyRef = inject(DestroyRef)
    readonly organizations = inject(OrganizationsService)
    readonly refresh = inject(RefreshService)
    readonly materialGraphService = inject(MaterialGraphService)
    configMenuService = inject(ConfigMenuService)

    organizationId?: string
    organizationLegacyId?: number
    organizationTemplateId?: string
    materialExplorerItem?: MaterialExplorerItemFragment

    readonly materialExplorerDataGql = inject(MaterialExplorerDataGQL)
    readonly itemGql = inject(MaterialExplorerItemGQL)
    readonly materialNodeGql = inject(MaterialExplorerMaterialNodeGQL)
    readonly deleteTextureRevisionGql = inject(MaterialExplorerDeleteTextureRevisionGQL)
    readonly deleteTextureSetRevisionGql = inject(MaterialExplorerDeleteTextureSetRevisionGQL)
    readonly createTextureRevisionGql = inject(MaterialExplorerCreateTextureRevisionGQL)
    readonly createTextureSetRevisionGql = inject(MaterialExplorerCreateTextureSetRevisionGQL)

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public utils: UtilsService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private uploadService: UploadGqlService,
        private thumbnailsService: ThumbnailsService,
    ) {
        toObservable(this.organizations.$current)
            .pipe(
                filter(IsDefined),
                switchMap((organization) => this.loadData(organization.id)),
                takeUntilDestroyed(),
            )
            .subscribe()

        this.configMenuService.materialSelected$.pipe(takeUntilDestroyed()).subscribe((_material) => {
            this.showSizeOptions = false
        })
    }

    async loadData(currentOrganizationId?: string) {
        //TODO: allow the user to specify the organization
        if (!currentOrganizationId) {
            throw new Error("No current organization.")
        }
        const {organization} = await fetchThrowingErrors(this.materialExplorerDataGql)({
            organizationId: currentOrganizationId,
        })
        if (!organization.matExplorerMaterial) {
            throw Error("No material explorer found for this user's organizations.")
        }

        this.organizationId = organization.id
        this.organizationLegacyId = organization.legacyId
        this.organizationTemplateId = organization.matExplorerTemplate?.id
        this.materialExplorerItem = await fetchThrowingErrors(this.itemGql)({id: organization.matExplorerMaterial.id}).then(({material}) => material)

        if (!this.materialExplorerItem?.latestRevision) {
            throw Error("Material explorer material does not have a latest revision.")
        }

        this.materialNodes = await Promise.all(
            this.materialExplorerItem?.latestRevision?.nodes.map(({id}) =>
                fetchThrowingErrors(this.materialNodeGql)({id}).then(({materialNode: node}) => this.materialGraphService.convertNodeFromFragment(node)),
            ) ?? [],
        )
        this.materialConnections =
            this.materialExplorerItem?.latestRevision?.connections.map((connection) => this.materialGraphService.convertConnectionFromFragment(connection)) ??
            []

        const textureNodes = this.materialNodes.filter((node) => node.name === "TexImage" || node.name === "ShaderNodeTextureSet")
        if (textureNodes.length !== 1) {
            throw Error("Material explorer material should have one and only one texture or texture set node.")
        }

        const mappingNodes = this.materialNodes.filter((node) => node.name === "Mapping")
        if (mappingNodes.length !== 1) {
            throw Error("Material explorer material should have one and only one mapping node.")
        }

        if (!this.materialExplorerItem?.textureGroup) {
            throw Error("Material explorer material does not have any texture group.")
        }

        const textureSets = this.materialExplorerItem?.textureGroup.textureSets
        if (textureSets?.length !== 1) {
            throw Error("Material explorer material texture should have one and only one texture set.")
        }

        this.materialRevision = this.materialExplorerItem!.latestRevision
        this.textureSet = textureSets[0]
        if (textureNodes[0].name === "TexImage") {
            // old layout
            this.materialLayoutData = {
                type: "OldMaterialLayout",
                texture: this.textureSet.textures.find((texture) => texture?.type === TextureType.Diffuse)!,
                textureRevision: undefined,
                texImageNode: textureNodes[0],
                mappingNode: mappingNodes[0],
            }
        } else {
            // new layout
            this.materialLayoutData = {
                type: "NewMaterialLayout",
                textureSetRevision: undefined,
                imageTextureSetNode: textureNodes[0],
                mappingNode: mappingNodes[0],
            }
        }
    }

    async updateMaterial() {
        switch (this.materialLayoutData.type) {
            case "OldMaterialLayout":
                this.updateOldLayoutNodes(this.materialLayoutData)
                break
            case "NewMaterialLayout":
                this.updateNewLayoutNodes(this.materialLayoutData)
                break
            default:
                throw new Error("Unknown material layout type.")
        }

        const graph = await this.materialGraphService.graphFromNodesAndConnections(
            this.materialNodes,
            this.materialConnections,
            this.materialExplorerItem!.latestRevision!.legacyId,
            this.materialExplorerItem!.legacyId,
            "Material Explorer Material",
        )
        this.$configurator().setMaterialGraph(fabricMaterialInputId, graph)
    }

    private updateOldLayoutNodes(materialLayoutData: OldMaterialLayoutData) {
        materialLayoutData.texImageNode.textureRevision = materialLayoutData.textureRevision?.legacyId

        materialLayoutData.mappingNode.parameters = materialLayoutData.mappingNode.parameters.map((parameter) => {
            switch (parameter.name) {
                case "Rotation":
                    return {
                        ...parameter,
                        value: [0, 0, (this.parameterRotation * Math.PI) / 180.0],
                    }
                case "Scale":
                    return {
                        ...parameter,
                        value: [
                            this.measurementTypes.Imperial.convertToMetric(this.parameterWidth),
                            this.measurementTypes.Imperial.convertToMetric(this.parameterHeight),
                            1,
                        ],
                    }
                default:
                    return parameter
            }
        })
    }

    private updateNewLayoutNodes(materialLayoutData: NewMaterialLayoutData) {
        materialLayoutData.imageTextureSetNode.textureSetRevision = {
            id: materialLayoutData.textureSetRevision!.id,
            width: this.measurementTypes.Imperial.convertToMetric(this.parameterWidth),
            height: this.measurementTypes.Imperial.convertToMetric(this.parameterHeight),
            mapAssignments:
                materialLayoutData.textureSetRevision?.mapAssignments.map((mapAssignment) => ({
                    textureType: mapAssignment.textureType,
                    dataObjectLegacyId: mapAssignment.dataObject.legacyId,
                })) ?? [],
        }

        materialLayoutData.mappingNode.parameters = materialLayoutData.mappingNode.parameters.map((parameter) => {
            switch (parameter.name) {
                case "Rotation":
                    return {
                        ...parameter,
                        value: [0, 0, (this.parameterRotation * Math.PI) / 180.0],
                    }
                default:
                    return parameter
            }
        })
    }

    async filesSelectedForUpload(event: Event) {
        try {
            const files: FileList = (<HTMLInputElement>event.target).files!
            if (!files.length) return

            if (this.materialLayoutData.type === "OldMaterialLayout") {
                const newTextureRevision = await firstValueFrom(this.uploadNewTextureRevision(files[0], this.materialLayoutData.texture, this.textureSet))
                if (!newTextureRevision) throw new Error("Failed to create a texture revision.")
                if (newTextureRevision.dataObject.state !== DataObjectState.Completed) throw new Error("Texture data object is not completed.")

                await this.discardOldTextureRevision()
                this.materialLayoutData.textureRevision = newTextureRevision
            } else if (this.materialLayoutData.type === "NewMaterialLayout") {
                const newTextureSetRevision = await firstValueFrom(this.uploadNewTextureSetRevision(files[0], this.textureSet))
                if (!newTextureSetRevision) throw new Error("Failed to create a texture set revision.")
                const diffuseMapAssignment = newTextureSetRevision.mapAssignments.find(({textureType}) => textureType === TextureType.Diffuse)
                if (diffuseMapAssignment?.dataObject.state !== DataObjectState.Completed) throw new Error("Texture data object is not completed.")

                await this.discardOldTextureSetRevision()
                this.materialLayoutData.textureSetRevision = newTextureSetRevision
            } else {
                throw new Error("Unknown material layout type.")
            }
            await this.updateMaterial()
            this.showSizeOptions = true
            this.loading = false
            // eslint-disable-next-line unused-imports/no-unused-vars
        } catch (_error: unknown) {
            this.snackBar.open("Cannot upload file.", "", {duration: 3000})
        }
    }

    uploadNewTextureRevision(
        file: File,
        texture: MaterialExplorerTextureFragment,
        _textureSet: MaterialExplorerTextureSetFragment,
    ): Observable<MaterialExplorerTextureRevisionFragment | null> {
        const dialogRef: MatDialogRef<AddTextureDialogComponent, AddTextureDialogResult> = this.dialog.open(AddTextureDialogComponent, {
            width: "350px",
            data: {
                measurementType: this.measurementTypes.Imperial,
                showColorSpaceSetting: false,
            },
        })
        return dialogRef.afterClosed().pipe(
            filter(IsDefined),
            switchMap(async (result: AddTextureDialogResult) => {
                this.loading = true
                const dataObject = await this.uploadService.createAndUploadDataObject(
                    file,
                    {
                        organizationId: this.organizationId!,
                        type: DataObjectType.Texture,
                        imageDataType: TextureTypeLabels.get(texture.type)?.isColorData ? ImageDataType.Color : ImageDataType.NonColor,
                        imageColorSpace: result.colorSpace,
                    },
                    {processUpload: true},
                )
                await this.thumbnailsService.waitUntilAvailable(dataObject.id)
                const textureRevision = await mutateThrowingErrors(this.createTextureRevisionGql)({
                    input: {
                        textureId: texture.id,
                        width: result.width,
                        height: result.height,
                        displacement: result.displacement,
                        dataObjectId: dataObject.id,
                    },
                }).then((result) => result.createTextureRevision)
                this.parameterWidth = this.measurementTypes.Imperial.convertFromMetric(result.width)
                this.parameterHeight = this.measurementTypes.Imperial.convertFromMetric(result.height)
                this.parameterRotation = 0
                return textureRevision
            }),
            takeUntilDestroyed(this.destroyRef),
        )
    }

    async discardOldTextureRevision() {
        if (this.materialLayoutData.type !== "OldMaterialLayout") {
            throw new Error("Material layout is not old.")
        }
        if (!this.materialLayoutData.textureRevision) return

        await mutateThrowingErrors(this.deleteTextureRevisionGql)(this.materialLayoutData.textureRevision)
        this.materialLayoutData.textureRevision = undefined
    }

    uploadNewTextureSetRevision(file: File, textureSet: MaterialExplorerTextureSetFragment): Observable<MaterialExplorerTextureSetRevisionFragment | null> {
        const dialogRef: MatDialogRef<AddTextureDialogComponent, AddTextureDialogResult> = this.dialog.open(AddTextureDialogComponent, {
            width: "350px",
            data: {
                measurementType: this.measurementTypes.Imperial,
                showColorSpaceSetting: false,
            },
        })
        return dialogRef.afterClosed().pipe(
            filter(IsDefined),
            switchMap(async (result: AddTextureDialogResult) => {
                this.loading = true
                const dataObject = await this.uploadService.createAndUploadDataObject(
                    file,
                    {
                        organizationId: this.organizationId!,
                        type: DataObjectType.Texture,
                        imageDataType: ImageDataType.Color,
                        imageColorSpace: result.colorSpace,
                    },
                    {processUpload: true},
                )
                await this.thumbnailsService.waitUntilAvailable(dataObject.id)
                const textureSetRevision = await mutateThrowingErrors(this.createTextureSetRevisionGql)({
                    input: {
                        textureSetId: textureSet.id,
                        width: result.width,
                        height: result.height,
                        displacement: result.displacement,
                        mapAssignments: [
                            {
                                textureType: TextureType.Diffuse,
                                dataObjectId: dataObject.id,
                            },
                        ],
                    },
                }).then((result) => result.createTextureSetRevision)
                this.parameterWidth = this.measurementTypes.Imperial.convertFromMetric(result.width)
                this.parameterHeight = this.measurementTypes.Imperial.convertFromMetric(result.height)
                this.parameterRotation = 0
                return textureSetRevision
            }),
            takeUntilDestroyed(this.destroyRef),
        )
    }

    async discardOldTextureSetRevision() {
        if (this.materialLayoutData.type !== "NewMaterialLayout") {
            throw new Error("Material layout is not new.")
        }
        if (!this.materialLayoutData.textureSetRevision) return

        await mutateThrowingErrors(this.deleteTextureSetRevisionGql)(this.materialLayoutData.textureSetRevision)
        this.materialLayoutData.textureSetRevision = undefined
    }

    updateSize() {
        void this.updateMaterial()
    }

    overlayClosed() {
        void this.router.navigate(["../"], {relativeTo: this.route, queryParamsHandling: "preserve"})
    }

    ngOnDestroy() {
        void this.discardOldTextureRevision()
    }
}
