import {Size2Like, Vector2Like} from "@cm/math"

export class FloodFill {
    static fill(data: Float32Array, size: Size2Like, pos: Vector2Like, fillValue: number) {
        const set = (x: number, y: number) => {
            data[y * size.width + x] = fillValue
        }
        const get = (x: number, y: number) => {
            return data[y * size.width + x]
        }
        const initialValue = get(pos.x, pos.y)
        const isInside = (x: number, y: number) => {
            return x >= 0 && y >= 0 && x < size.width && y < size.height && get(x, y) === initialValue
        }
        if (!isInside(pos.x, pos.y)) {
            return
        }
        const s = new Array<{x1: number; x2: number; y: number; dy: number}>()
        s.push({x1: pos.x, x2: pos.x, y: pos.y, dy: 1})
        s.push({x1: pos.x, x2: pos.x, y: pos.y - 1, dy: -1})
        for (;;) {
            const entry = s.pop()
            if (!entry) {
                break
            }
            let x = entry.x1
            if (isInside(x, entry.y)) {
                while (isInside(x - 1, entry.y)) {
                    set(x - 1, entry.y)
                    x--
                }
                if (x < entry.x1) {
                    s.push({x1: x, x2: entry.x1 - 1, y: entry.y - entry.dy, dy: -entry.dy})
                }
            }
            while (entry.x1 <= entry.x2) {
                while (isInside(entry.x1, entry.y)) {
                    set(entry.x1, entry.y)
                    entry.x1++
                }
                if (entry.x1 > x) {
                    s.push({x1: x, x2: entry.x1 - 1, y: entry.y + entry.dy, dy: entry.dy})
                }
                if (entry.x1 - 1 > entry.x2) {
                    s.push({x1: entry.x2 + 1, x2: entry.x1 - 1, y: entry.y - entry.dy, dy: -entry.dy})
                }
                entry.x1++
                while (entry.x1 < entry.x2 && !isInside(entry.x1, entry.y)) {
                    entry.x1++
                }
                x = entry.x1
            }
        }
    }
}
