import {IThreadWorker, TransferListItem} from "@cm/web-assembly"
import {fromEvent as observableFromEvent, Observable} from "rxjs"

export declare const WebWorkerImpl: Worker

export class WebWorker implements IThreadWorker {
    constructor(private _workerImpl: typeof WebWorkerImpl) {}

    postMessage(message: unknown, transfer?: TransferListItem[]): void {
        this._workerImpl.postMessage(message, transfer ?? [])
    }

    terminate(): void {
        this._workerImpl.terminate()
    }

    observableFromEvent(_event: string): Observable<MessageEvent> {
        return observableFromEvent<MessageEvent>(this._workerImpl, "message")
    }
}
