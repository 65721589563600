import {Component, OnInit, Input, input, output} from "@angular/core"
import {FormsModule} from "@angular/forms"

@Component({
    selector: "cm-string-input",
    templateUrl: "./string-input.component.html",
    styleUrls: ["./string-input.component.scss"],
    imports: [FormsModule],
})
export class StringInputComponent implements OnInit {
    readonly $validate = input<(x: string) => boolean>(undefined, {alias: "validate"})
    readonly $convert = input<{
        toDisplay: (x: string) => string
        fromDisplay: (x: string) => string
    }>()
    readonly valueChange = output<string>()
    readonly $disabled = input(false, {alias: "disabled"})
    $readonly = input(false, {alias: "readonly"})
    readonly $isButton = input(false, {alias: "isButton"})

    constructor() {}

    private _value!: string

    @Input() set value(value: string) {
        this._value = value
        const convert = this.$convert()
        this.valueString = convert ? convert.toDisplay(value) : value
    }

    get value() {
        return this._value
    }

    private editing = false
    private valueString!: string
    editValid = true
    _editString: string | null = null

    get editString(): string | null {
        if (this.editing) {
            return this._editString
        }
        return this.valueString
    }

    set editString(str: string) {
        if (this.editing) {
            this._editString = str
        }
        try {
            const convert = this.$convert()
            const value = convert ? convert.fromDisplay(str) : str
            const validate = this.$validate()
            if ((this.editValid = validate ? validate(value) : true)) {
                this.valueChange.emit(value)
            }
        } catch {
            this.editValid = false
        }
    }

    ngOnInit(): void {}

    onFocus() {
        this._editString = this.valueString
        this.editing = true
        this.editValid = true
    }

    onBlur() {
        this.editing = false
        this._editString = null
        this.editValid = true
    }
}
