<div class="cm-table-container">
    <table mat-table [dataSource]="scanSubJobs" #scanTable>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let scanSubJob; table: scanTable">{{ scanSubJob.id }}</td>
        </ng-container>
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef>State</th>
            <td mat-cell *matCellDef="let scanSubJob; table: scanTable">
                <cm-state-label
                    class="cm-state-label"
                    [stateLabels]="Labels.States.ScanSubJob"
                    (click)="$event.stopPropagation()"
                    [state]="scanSubJob.state"
                    [editable]="$can().update.scanSubJob()"
                    (stateChange)="updateItem({state: $event}, scanSubJob.id)"
                >
                </cm-state-label>
            </td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Active</th>
            <td mat-cell *matCellDef="let scanSubJob; table: scanTable">
                <mat-checkbox
                    [ngModel]="scanSubJob.active"
                    (ngModelChange)="updateItem({active: $event}, scanSubJob.id)"
                    [disabled]="!$can().update.scanSubJob()"
                >
                </mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="progress">
            <th mat-header-cell *matHeaderCellDef class="cm-clickable-header" (click)="refreshSubJobs()">Progress</th>
            <td mat-cell *matCellDef="let scanSubJob; table: scanTable">
                {{ scanSubJob.progress ?? "" }}
            </td>
        </ng-container>
        <ng-container matColumnDef="started">
            <th mat-header-cell *matHeaderCellDef>Started</th>
            <td mat-cell *matCellDef="let scanSubJob; table: scanTable">
                @if (scanSubJob.started) {
                    {{ scanSubJob.started | cmFormatDate }}
                }
            </td>
        </ng-container>
        <ng-container matColumnDef="completed">
            <th mat-header-cell *matHeaderCellDef>Completed</th>
            <td mat-cell *matCellDef="let scanSubJob; table: scanTable">
                @if (scanSubJob.completed) {
                    {{ scanSubJob.completed | cmFormatDate }}
                }
            </td>
        </ng-container>
        <ng-container matColumnDef="tile_x">
            <th mat-header-cell *matHeaderCellDef>Tile X</th>
            <td mat-cell *matCellDef="let scanSubJob; table: scanTable">{{ scanSubJob.tileX }}</td>
        </ng-container>
        <ng-container matColumnDef="tile_y">
            <th mat-header-cell *matHeaderCellDef>Tile Y</th>
            <td mat-cell *matCellDef="let scanSubJob; table: scanTable">{{ scanSubJob.tileY }}</td>
        </ng-container>
        <ng-container matColumnDef="log">
            <th mat-header-cell *matHeaderCellDef>Logs</th>
            <td mat-cell *matCellDef="let scanSubJob; table: scanTable">
                @if (scanSubJob.logAssignments.length) {
                    <div (click)="downloadLog(scanSubJob); $event.stopPropagation()" class="cm-download">
                        <i class="far fa-arrow-to-bottom"></i>
                    </div>
                }
            </td>
        </ng-container>
        <ng-container matColumnDef="fitting">
            <th mat-header-cell *matHeaderCellDef>Fitting</th>
            <td mat-cell *matCellDef="let scanSubJob; table: scanTable">
                @if (scanSubJob.fittingAssignments.length) {
                    <div (click)="downloadFitting(scanSubJob); $event.stopPropagation()" class="cm-download">
                        <i class="far fa-arrow-to-bottom"></i>
                    </div>
                }
            </td>
        </ng-container>
        <ng-container matColumnDef="assembly">
            <th mat-header-cell *matHeaderCellDef>Assembly</th>
            <td mat-cell *matCellDef="let scanSubJob; table: scanTable">
                @if (scanSubJob.assemblyAssignments.length) {
                    <div (click)="downloadAssembly(scanSubJob); $event.stopPropagation()" class="cm-download">
                        <i class="far fa-arrow-to-bottom"></i>
                    </div>
                }
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
