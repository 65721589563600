import {Component, viewChild} from "@angular/core"
import {AddShaderInputs, AddShaderOutputs} from "@app/material-editor/models/nodes/add-shader"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {TexBrickInputs, TexBrickOutputs} from "@material-editor/models/nodes/tex-brick"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-tex-brick-node",
    templateUrl: "./tex-brick-node.component.html",
    styleUrls: ["./tex-brick-node.component.scss"],
    imports: [NodeBaseComponent],
})
export class TexBrickNodeComponent {
    readonly $nodeBase = viewChild<MaterialNodeBase>("nodeBase")
    inputs = TexBrickInputs
    outputs = TexBrickOutputs
    typeInfo = TexBrickNodeType
}

export const TexBrickNodeType: MaterialNodeType<typeof TexBrickNodeComponent> = {
    id: "texBrick",
    label: "Brick Texture",
    color: "#4987af",
    name: "ShaderNodeTexBrick",
    inputs: [TexBrickInputs.vector, TexBrickInputs.color2, TexBrickInputs.color2, TexBrickInputs.mortar] as never[],
    outputs: [TexBrickOutputs.color, TexBrickOutputs.fac],
    component: TexBrickNodeComponent,
}
