import {Component, DestroyRef, inject, Injector, OnInit, signal, input, output} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatTableModule} from "@angular/material/table"
import {MatTooltipModule} from "@angular/material/tooltip"
import {AssociatedJobsTableItemFragment} from "@app/platform/components/jobs/associated-jobs-table/associated-jobs-table.generated"
import {IsDefined} from "@cm/utils/filter"
import {OverflowableTextComponent} from "@common/components/data"
import {EntityMetadataComponent} from "@common/components/entity/entity-metadata/entity-metadata.component"
import {FullPageFeedbackComponent} from "@common/components/full-page-feedback/full-page-feedback.component"
import {TableCellComponent} from "@common/components/tables/table-cell/table-cell.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {keepCheckingRunningJobs$} from "@common/helpers/jobs"
import {AuthService} from "@common/services/auth/auth.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {TabStateService} from "@common/services/tab-state/tab-state.service"
import {ContentTypeModel, JobState} from "@generated"
import {TippyDirective} from "@ngneat/helipopper"
import {AssociatedJobsTableItemsGQL} from "@platform/components/jobs/associated-jobs-table/associated-jobs-table.generated"
import {JobStateTooltipComponent} from "@platform/components/jobs/job-state-tooltip/job-state-tooltip.component"
import {JobThumbnailComponent} from "@platform/components/jobs/job-thumbnail/job-thumbnail.component"
import {LoadedData} from "@platform/models/data"
import {PLACEHOLDER_ITEMS} from "@platform/models/data/constants"
import {DataLoaderService} from "@platform/services/data"
import {startWith, switchMap} from "rxjs"
import {v4 as uuid4} from "uuid"

@Component({
    selector: "cm-associated-jobs-table",
    imports: [
        FullPageFeedbackComponent,
        MatTableModule,
        TableCellComponent,
        OverflowableTextComponent,
        MatTooltipModule,
        JobThumbnailComponent,
        TippyDirective,
        EntityMetadataComponent,
    ],
    templateUrl: "./associated-jobs-table.component.html",
    styleUrl: "./associated-jobs-table.component.scss",
})
export class AssociatedJobsTableComponent implements OnInit {
    readonly $displayedColumns = input(["id", "state", "name", "creator"], {alias: "displayedColumns"})
    readonly clickItem = output<AssociatedJobsTableItemFragment | undefined>()

    readonly auth = inject(AuthService)
    readonly refresh = inject(RefreshService)

    readonly $assignedTo = input.required<{
        id?: string
        legacyId?: number
        contentTypeModel: ContentTypeModel
    }>({alias: "assignedTo"})

    readonly dataLoader = inject(DataLoaderService)
    readonly destroyRef = inject(DestroyRef)
    readonly tabState = inject(TabStateService)
    readonly injector = inject(Injector)

    readonly $data = signal(PLACEHOLDER_ITEMS(5) as LoadedData<AssociatedJobsTableItemFragment>)

    private readonly associatedJobsTableItems = inject(AssociatedJobsTableItemsGQL)

    ngOnInit() {
        const assignedTo = this.$assignedTo()

        this.tabState.becomesActive$
            .pipe(
                switchMap(() =>
                    fetchThrowingErrors(this.associatedJobsTableItems)({
                        objectId: assignedTo.id,
                        objectLegacyId: assignedTo.legacyId,
                        contentTypeModel: assignedTo.contentTypeModel,
                    }).then((data) => ({
                        items: data.items.filter(IsDefined).map((item) => ({trackId: uuid4(), data: item?.job, error: null})),
                        totalCount: data.totalCount,
                        error: null,
                        complete: true,
                    })),
                ),
                startWith(PLACEHOLDER_ITEMS(5) as LoadedData<AssociatedJobsTableItemFragment>),
            )
            .pipe(takeUntilDestroyed(this.destroyRef), keepCheckingRunningJobs$(this.injector, this.tabState))
            .subscribe((data) => {
                this.$data.set({
                    items: data.items,
                    error: null,
                    complete: false,
                    totalCount: data.totalCount,
                })
            })
    }

    protected readonly JobState = JobState
    protected readonly JobStateTooltipComponent = JobStateTooltipComponent
}
