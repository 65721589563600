import {Component, computed, input} from "@angular/core"
import {ListItemComponent} from "@common/components/item/list-item/list-item.component"
import {getNodeIconClass, getNodeIconSeconaryClass} from "@app/template-editor/helpers/template-icons"
import {getTemplateNodeClassLabel} from "@cm/template-nodes"
import {valueNodeClasses} from "@cm/template-nodes"

@Component({
    selector: "cm-template-menu-item",
    templateUrl: "./template-menu-item.component.html",
    styleUrls: ["./template-menu-item.component.scss", "./../../helpers/template-icons.scss"],
    imports: [ListItemComponent],
})
export class TemplateMenuItemComponent {
    readonly $nodeClass = input.required<string>({alias: "nodeClass"})
    readonly $isNodeValue = computed(() => valueNodeClasses.includes(this.$nodeClass()))
    readonly $selected = input<boolean>(undefined, {alias: "selected"})
    readonly $disabled = input<boolean>(undefined, {alias: "disabled"})
    readonly $label = input<string>(undefined, {alias: "label"})
    readonly $displayLabel = computed(() => {
        const label = this.$label()
        if (label) return label
        return getTemplateNodeClassLabel(this.$nodeClass())
    })
    getNodeIconClass = getNodeIconClass
    getNodeIconSeconaryClass = getNodeIconSeconaryClass
}
