import {
    AfterViewInit,
    Component,
    computed,
    DestroyRef,
    effect,
    ElementRef,
    inject,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    output,
    signal,
    SimpleChanges,
    viewChild,
    ViewEncapsulation,
} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {LoadingSpinnerComponent} from "@app/common/components/progress"
import {ActionMenuComponent} from "@app/common/components/viewers/configurator/action-menu/action-menu.component"
import {ActionMenuService} from "@app/common/components/viewers/configurator/action-menu/services/action-menu.service"
import {ConfigMenuComponent} from "@app/common/components/viewers/configurator/config-menu/config-menu.component"
import {ConfigMenuService} from "@app/common/components/viewers/configurator/config-menu/services/config-menu.service"
import {CmOnboardingHintComponent} from "@app/common/components/viewers/configurator/configurator/onboarding-hint/onboarding-hint.component"
import {getParametersForArGeneration} from "@app/common/helpers/ar/ar"
import {increaseOnboardingCounter, isOnboardingRequired} from "@app/common/components/viewers/configurator/helpers/onboarding"
import {
    createMaterialReferenceFromLegacyId,
    decodeConfiguratorUrlParameters,
    initializeTemplateParameterValue,
} from "@app/common/components/viewers/configurator/helpers/parameters"
import {ArService} from "@app/common/components/viewers/configurator/services/ar.service"
import {
    exitFullscreen,
    fullscreenActive,
    inIframe,
    listenToFullscreenChange,
    openFullscreen,
    stopListeningToFullscreenChange,
} from "@app/common/helpers/fullscreen/fullscreen"
import {FilesService} from "@app/common/services/files/files.service"
import {CMConfiguratorElement} from "@app/common/components/viewers/configurator/types/cm-configurator"
import {PdfGenerationService} from "@app/common/services/pdf-generation/pdf-generation.service"
import {PricingService} from "@app/pricing/services/pricing.service"
import {ThreeTemplateSceneProviderComponent} from "@app/template-editor/components/three-template-scene-provider/three-template-scene-provider.component"
import {AnnotationStyleRegistry} from "@app/template-editor/helpers/annotation-style-registry"
import {captureSnapshot} from "@app/template-editor/helpers/snapshot"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {ThreeSceneManagerService} from "@app/template-editor/services/three-scene-manager.service"
import {IMaterialGraph} from "@cm/material-nodes"
import {ConfiguratorParameter, ConfiguratorParameterType, Parameters, SceneProperties} from "@cm/template-nodes"
import {isMaterialInput} from "@cm/template-nodes/interface-descriptors"
import {MaterialGraphReference} from "@cm/template-nodes/nodes/material-graph-reference"
import {
    GetTemplateDetailsForConfiguratorGQL,
    GetTemplateUuidFromSceneLegacyIdForConfiguratorGQL,
} from "@common/components/viewers/configurator/configurator/configurator.generated"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {SceneCamera, ThreeTemplateSceneViewerComponent} from "@template-editor/components/three-template-scene-viewer/three-template-scene-viewer.component"
import {TemplateNodeClipboardService} from "@template-editor/services/template-node-clipboard.service"
import {toDataURL} from "qrcode"
import {loadTemplateGraph} from "@cm/template-nodes/utils/serialization-utils"

@Component({
    selector: "cm-configurator",
    templateUrl: "./configurator.component.html",
    styleUrl: "./configurator.component.scss",
    imports: [
        ActionMenuComponent,
        CmOnboardingHintComponent,
        ConfigMenuComponent,
        LoadingSpinnerComponent,
        ThreeTemplateSceneProviderComponent,
        ThreeTemplateSceneViewerComponent,
    ],
    encapsulation: ViewEncapsulation.ShadowDom,
    providers: [SceneManagerService, TemplateNodeClipboardService],
})
export class ConfiguratorComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
    //https://github.com/angular/angular/issues/53981
    //input/output signals currently do not work properly on web components:
    //Angular elements does translate them to html attributes, just as @Input decorators, i.e. useExternalMenu is usable as use-external-menu
    //on the web component. However, calling it as useExternalMenu() inside this component then yields an error
    @Input() useExternalMenu = "false" //used externally on the web components, do not rename this. Html attribute, thus a string (not a boolean)
    @Input() parameters: string | undefined = undefined
    @Input() showUi = true
    @Input() urlParameters: string | undefined = undefined
    @Input() templateUuid: string | undefined = undefined
    @Input() sceneLegacyId: number | undefined = undefined

    //@Output is translated to custom html events by angular elements and required for the web components.
    //Angular elements throws an error for output<void>() in Angular 17
    readonly loadingCompleted = output<void>()
    readonly changeCompleted = output<{
        id: string
        value: string
        type: string
    }>() //emitted after any parameter was changed from outer website, i.e. more often than configurationLoaded
    readonly configurationLoaded = output<void>()
    readonly arUrl = output<string | undefined>()

    readonly $runningOperation = signal<"none" | "stl" | "gltf" | "ar" | "loading">("none")

    readonly localSceneManagerService = inject(SceneManagerService)
    readonly configMenuService = inject(ConfigMenuService)
    readonly actionMenuService = inject(ActionMenuService)
    readonly arService = inject(ArService)
    readonly pricingService = inject(PricingService)
    readonly pdfGenerationService = inject(PdfGenerationService)
    readonly injector = inject(Injector)

    readonly templateUuidFromSceneLegacyIdGql = inject(GetTemplateUuidFromSceneLegacyIdForConfiguratorGQL)
    readonly templateDetailsGql = inject(GetTemplateDetailsForConfiguratorGQL)

    private readonly destroyRef = inject(DestroyRef)
    private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef)
    private threeSceneManagerService: ThreeSceneManagerService | undefined
    private $viewer = viewChild.required<ThreeTemplateSceneViewerComponent>("viewer")
    protected readonly $inFullscreen = signal<boolean>(false)

    readonly $showMenu = computed<boolean>(() => {
        const scenePropertiesHideMenu = this.$sceneProperties()?.parameters.uiStyle === "hidden"
        return !scenePropertiesHideMenu && (this.$inFullscreen() || this.useExternalMenu !== "true")
    })

    readonly $sceneProperties = computed<SceneProperties | undefined>(() => {
        const templateGraph = this.localSceneManagerService.$templateGraph()
        return templateGraph.parameters.nodes.parameters.list.find((node): node is SceneProperties => node instanceof SceneProperties)
    })

    cameraEqualityFn = (prev: SceneCamera | undefined, next: SceneCamera | undefined) => {
        if (!prev || !next) return prev === next
        return (
            prev.parameters.focalLength === next.parameters.focalLength &&
            prev.parameters.focalDistance === next.parameters.focalDistance &&
            prev.parameters.target.equals(next.parameters.target)
        )
    }

    readonly $camera = computed<SceneCamera | undefined>(
        () => {
            const cameras = this.localSceneManagerService.$cameras()
            if (cameras.length > 0) return {parameters: cameras[0], transform: cameras[0].transform, ignoreAspectRatio: true}
            return undefined
        },
        {equal: this.cameraEqualityFn},
    )

    readonly $onboardingClicked = signal<boolean>(false)

    readonly $onboardingHintVisible = computed(() => {
        return !this.$onboardingClicked() && Boolean(this.$sceneProperties()?.parameters.enableOnboardingHint && isOnboardingRequired())
    })

    private destroyed = false

    constructor() {
        effect(() => {
            const sceneProperties = this.$sceneProperties()

            this.configMenuService.setUiStyle(sceneProperties?.parameters.uiStyle ?? "default")
            this.configMenuService.setIconSize(sceneProperties?.parameters.iconSize ?? 24)
        })
    }

    ngOnInit() {
        AnnotationStyleRegistry.getInstance().injectIntoShadowDom()

        this.localSceneManagerService.descriptorList$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((descriptors) => {
            this.configMenuService.setInterface(descriptors)
        })

        this.configMenuService.configSelected$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((config) => {
            this.localSceneManagerService.setTemplateParameter(config.config.props.id, config.variant.id)
            this.syncConfigChangesAndNotify()
        })

        this.configMenuService.materialSelected$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (material) => {
            const materialReference = await createMaterialReferenceFromLegacyId(material.legacyId, this.injector)
            this.localSceneManagerService.setTemplateParameter(material.input.props.id, materialReference)
        })

        this.actionMenuService.toggleFullscreen$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.toggleFullscreen()
        })

        this.actionMenuService.creatingStl$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isCreating) => {
            this.creatingStl(isCreating)
        })

        this.actionMenuService.creatingGltf$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isCreating) => {
            this.creatingGltf(isCreating)
        })

        this.actionMenuService.toggleDimensionGuides$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.localSceneManagerService.$showDimensionGuides.set(!this.localSceneManagerService.$showDimensionGuides())
        })

        this.arService.creatingAr$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isCreating) => {
            this.creatingAr(isCreating)
        })

        this.arService.desktopArUrl$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((url) => {
            this.arUrl.emit(url)
        })

        this.configMenuService.pdfDownloadRequested$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async () => {
            const templateRevisionId = this.localSceneManagerService.$templateRevisionId()
            if (!templateRevisionId) throw new Error("Template revision id not set.")
            await this.pdfGenerationService.generatedAndDownloadPdf(this.getThreeSceneManagerService(), templateRevisionId)
            this.configMenuService.emitPdfDownloadFinished()
        })

        listenToFullscreenChange(this.toggleFullscreenButton.bind(this))
    }

    ngOnDestroy() {
        this.destroyed = true
        stopListeningToFullscreenChange(this.toggleFullscreenButton.bind(this))
    }

    bindWebcomponentApi() {
        const nativeElement = this.elementRef.nativeElement as CMConfiguratorElement
        nativeElement.getParameterList = this.getParameterList.bind(this)
        nativeElement.setParameter = this.setParameter.bind(this)
        nativeElement.saveSnapshot = this.saveSnapshot.bind(this)
        nativeElement.captureSnapshotInMemory = this.captureSnapshotInMemory.bind(this)
        nativeElement.viewInAr = this.viewInAr.bind(this)
        nativeElement.zoomIn = this.zoomIn.bind(this)
        nativeElement.zoomOut = this.zoomOut.bind(this)
        nativeElement.resetCamera = this.resetCamera.bind(this)
        nativeElement.loadConfigurator = (templateUuid: string) => this.load(templateUuid, undefined)
        nativeElement.getPricesAsList = this.pricingService.getPricesAsList.bind(this.pricingService)
        nativeElement.generateQrCode = this.generateQrCode.bind(this)
        nativeElement.toggleFullscreen = this.toggleFullscreen.bind(this)
        nativeElement.toggleDimensionGuides = this.toggleDimensionGuides.bind(this)
    }

    ngAfterViewInit(): void {
        this.bindWebcomponentApi()
    }

    onInititalizedThreeSceneManagerService(threeSceneManagerService: ThreeSceneManagerService | undefined) {
        if (threeSceneManagerService) {
            threeSceneManagerService.$ambientLight.set(false)
            threeSceneManagerService.$showGrid.set(false)
            threeSceneManagerService.$displayMode.set("configurator")

            threeSceneManagerService.requestedResize$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
                this.setViewportSize()
                this.localSceneManagerService.compileTemplate()
            })
        }

        this.threeSceneManagerService = threeSceneManagerService
    }

    getThreeSceneManagerService(): ThreeSceneManagerService {
        if (!this.threeSceneManagerService) throw new Error("ThreeSceneManagerService not initialized")
        return this.threeSceneManagerService
    }

    setViewportSize() {
        const parameters = this.localSceneManagerService.$instanceParameters()
        parameters.parameters["$viewportSize"] = this.$viewer().getViewportSize()
        this.localSceneManagerService.$instanceParameters.set(parameters)
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.templateUuid) {
            const updatedTemplateUuid = changes.templateUuid.currentValue as string
            const parametersValue = this.parameters
            const parameters = parametersValue ? (await decodeConfiguratorUrlParameters(parametersValue, this.injector)).parameters : undefined
            if (updatedTemplateUuid !== changes.templateUuid.previousValue) this.load(updatedTemplateUuid, parameters)
        }
        if (changes.sceneLegacyId) {
            const updatedSceneLegacyId = changes.sceneLegacyId.currentValue as number
            if (updatedSceneLegacyId !== changes.sceneLegacyId.previousValue) this.loadWithSceneLegacyId(updatedSceneLegacyId)
        }
        if (changes.urlParameters) {
            const updatedUrlParameters = changes.urlParameters.currentValue as string
            if (updatedUrlParameters !== changes.urlParameters.previousValue) this.loadWithUrlParameters(updatedUrlParameters)
        }
    }

    async loadWithSceneLegacyId(sceneLegacyId: number) {
        const templateId = (await fetchThrowingErrors(this.templateUuidFromSceneLegacyIdGql)({sceneLegacyId})).scene.picture.template?.id
        if (!templateId) throw new Error("Failed to get template id from scene id")
        this.load(templateId, undefined)
    }

    async loadWithUrlParameters(urlParameters: string) {
        const decodedUrlParameters = await decodeConfiguratorUrlParameters(urlParameters, this.injector)
        if (decodedUrlParameters.templateId) this.load(decodedUrlParameters.templateId, decodedUrlParameters.parameters)
        else if (decodedUrlParameters.sceneId) {
            const templateId = (await fetchThrowingErrors(this.templateUuidFromSceneLegacyIdGql)({sceneLegacyId: decodedUrlParameters.sceneId})).scene.picture
                .template?.id
            if (!templateId) throw new Error("Failed to get template id from scene id")
            this.load(templateId, decodedUrlParameters.parameters)
        }
    }

    async load(templateId: string, initialParameters: Parameters | undefined) {
        console.log("Loading template", templateId)
        this.$runningOperation.set("loading")

        const template = (await fetchThrowingErrors(this.templateDetailsGql)({id: templateId})).template
        const graph = loadTemplateGraph(template.latestRevision?.graph)

        this.localSceneManagerService.$templateGraph.set(graph)
        this.localSceneManagerService.$defaultCustomerId.set(template.organizationLegacyId)
        this.localSceneManagerService.$templateRevisionId.set(template.latestRevision?.id)

        if (initialParameters) this.localSceneManagerService.$instanceParameters.set(initialParameters)
        this.setViewportSize()
        this.localSceneManagerService.compileTemplate()
        await this.getThreeSceneManagerService().sync(false)

        this.$runningOperation.set("none")
        await this.pricingService.load(templateId)
        if (!this.destroyed) {
            this.loadingCompleted.emit()
        }
    }

    toggleFullscreen() {
        const fullscreen = fullscreenActive()
        if (fullscreen) {
            exitFullscreen()
        } else {
            const fullscreenElement = inIframe() ? document.documentElement : this.elementRef.nativeElement
            openFullscreen(fullscreenElement)
        }
    }

    toggleFullscreenButton() {
        const fullscreen = fullscreenActive()
        this.$inFullscreen.set(fullscreen)
    }

    creatingStl(isCreating: boolean) {
        this.$runningOperation.set(isCreating ? "stl" : "none")
    }

    creatingGltf(isCreating: boolean) {
        this.$runningOperation.set(isCreating ? "gltf" : "none")
    }

    creatingAr(isCreating: boolean) {
        this.$runningOperation.set(isCreating ? "ar" : "none")
    }

    getParameterList() {
        const result: ConfiguratorParameter[] = []
        for (const descriptor of this.localSceneManagerService.getDescriptors())
            if (descriptor.props.type === "input") result.push(descriptor.toConfiguratorParameter())
        return result
    }

    async setParameter(id: string, type: ConfiguratorParameterType, value: string) {
        const parameterValue = await initializeTemplateParameterValue(type, value, this.injector)
        this.localSceneManagerService.setTemplateParameter(id, parameterValue)
        await this.getThreeSceneManagerService().sync(true)
        this.changeCompleted.emit({id, type, value})
    }

    setMaterialGraph(inputId: string, graph: IMaterialGraph) {
        this.localSceneManagerService.setTemplateParameter(inputId, new MaterialGraphReference({graph}))
    }

    setMaterialGraphToFirstInput(graph: IMaterialGraph) {
        const materialInput = this.localSceneManagerService.getDescriptors().find((descriptor) => isMaterialInput(descriptor))
        if (!materialInput) throw new Error("No material input found")
        this.localSceneManagerService.setTemplateParameter(materialInput.props.id, new MaterialGraphReference({graph}))
    }

    saveSnapshot() {
        const snapshot = captureSnapshot(this.getThreeSceneManagerService(), "image/jpeg", 95)
        FilesService.downloadFile("snapshot.jpg", snapshot)
    }

    captureSnapshotInMemory(): Promise<string> {
        return new Promise((resolve, reject) => {
            try {
                resolve(captureSnapshot(this.getThreeSceneManagerService(), "image/jpeg", 95))
            } catch (error) {
                reject(error)
            }
        })
    }

    viewInAr() {
        const templateRevisionId = this.localSceneManagerService.$templateRevisionId()
        if (!templateRevisionId) return
        this.arService.viewArModel(
            templateRevisionId,
            getParametersForArGeneration(this.localSceneManagerService),
            this.localSceneManagerService.$verticalArPlacement(),
        )
    }

    zoomIn(amount: number): void {
        this.$viewer().zoomIn(amount)
    }

    zoomOut(amount: number): void {
        this.$viewer().zoomOut(amount)
    }

    resetCamera(): void {
        this.$viewer().resetCamera()
    }

    private async syncConfigChangesAndNotify() {
        await this.getThreeSceneManagerService().sync(true)
        this.configurationLoaded.emit()
        this.configMenuService.setSynchronizing(false)
    }

    hideOnboardingIcons() {
        this.$onboardingClicked.set(true)
        increaseOnboardingCounter()
    }

    generateQrCode(url: string, errorCorrectionLevel: "high" | "low", width: number, margin: number): Promise<string> {
        return toDataURL(url, {errorCorrectionLevel, width, margin})
    }

    toggleDimensionGuides() {
        this.actionMenuService.toggleDimensionGuides()
    }
}
