import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { BasicTagInfoFragmentDoc } from '../../../common/services/tags/tags.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DataObjectDetailsForBatchDownloadServiceFragment = { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null };

export type MaterialBatchOperationFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null };

export type BatchMaterialOutputFragment = { __typename: 'Material', id: string, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string } | null, outputForType?: { __typename: 'MaterialOutput', id: string, job?: { __typename: 'Job', id: string, state: Types.JobState } | null, config?: { __typename: 'JsonFile', id: string, content: any } | null, result?: { __typename: 'DataObject', id: string, legacyId: number, downloadUrl: string, originalFileName: string } | null } | null };

export type GetBatchMaterialWithOutputQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  materialOutputType: Types.MaterialOutputType;
}>;


export type GetBatchMaterialWithOutputQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string } | null, outputForType?: { __typename: 'MaterialOutput', id: string, job?: { __typename: 'Job', id: string, state: Types.JobState } | null, config?: { __typename: 'JsonFile', id: string, content: any } | null, result?: { __typename: 'DataObject', id: string, legacyId: number, downloadUrl: string, originalFileName: string } | null } | null } };

export type MaterialBatchOperationMaterialWithMaterialRangeTagsQueryVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type MaterialBatchOperationMaterialWithMaterialRangeTagsQuery = { __typename: 'Query', material: { __typename: 'Material', tagAssignments: Array<{ __typename: 'TagAssignment', tag: { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } }> } };

export type ImageDownloadDetailsForBatchDownloadServiceFragment = { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null, tiff?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null, jpegHigh?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null, jpegMedium?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null, jpegLow?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null, jpegTiny?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null };

export type GetImageDownloadDetailsForBatchDownloadServiceQueryVariables = Types.Exact<{
  materialId: Types.Scalars['ID']['input'];
  assignmentType: Types.DataObjectAssignmentType;
}>;


export type GetImageDownloadDetailsForBatchDownloadServiceQuery = { __typename: 'Query', material: { __typename: 'Material', dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null, tiff?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null, jpegHigh?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null, jpegMedium?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null, jpegLow?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null, jpegTiny?: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string, mediaType?: string | null } | null } }> } };

export type GetPbrFilenameForBatchDownloadServiceQueryVariables = Types.Exact<{
  dataObjectLegacyId: Types.Scalars['Int']['input'];
}>;


export type GetPbrFilenameForBatchDownloadServiceQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', originalFileName: string } };

export const MaterialBatchOperationFragmentDoc = gql`
    fragment MaterialBatchOperation on Material {
  id
  legacyId
  name
}
    `;
export const BatchMaterialOutputFragmentDoc = gql`
    fragment BatchMaterialOutput on Material {
  id
  latestCyclesRevision {
    id
  }
  outputForType(type: $materialOutputType) {
    id
    job {
      id
      state
    }
    config {
      id
      content
    }
    result {
      id
      legacyId
      downloadUrl
      originalFileName
    }
  }
}
    `;
export const DataObjectDetailsForBatchDownloadServiceFragmentDoc = gql`
    fragment DataObjectDetailsForBatchDownloadService on DataObject {
  id
  legacyId
  originalFileName
  mediaType
}
    `;
export const ImageDownloadDetailsForBatchDownloadServiceFragmentDoc = gql`
    fragment ImageDownloadDetailsForBatchDownloadService on DataObject {
  ...DataObjectDetailsForBatchDownloadService
  tiff: thumbnail(fileType: tiff, resolution: High) {
    ...DataObjectDetailsForBatchDownloadService
  }
  jpegHigh: thumbnail(fileType: jpg, resolution: High) {
    ...DataObjectDetailsForBatchDownloadService
  }
  jpegMedium: thumbnail(fileType: jpg, resolution: Medium) {
    ...DataObjectDetailsForBatchDownloadService
  }
  jpegLow: thumbnail(fileType: jpg, resolution: Low) {
    ...DataObjectDetailsForBatchDownloadService
  }
  jpegTiny: thumbnail(fileType: jpg, resolution: Tiny) {
    ...DataObjectDetailsForBatchDownloadService
  }
}
    `;
export const GetBatchMaterialWithOutputDocument = gql`
    query getBatchMaterialWithOutput($id: ID!, $materialOutputType: MaterialOutputType!) {
  material(id: $id) {
    ...BatchMaterialOutput
  }
}
    ${BatchMaterialOutputFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBatchMaterialWithOutputGQL extends Apollo.Query<GetBatchMaterialWithOutputQuery, GetBatchMaterialWithOutputQueryVariables> {
    override document = GetBatchMaterialWithOutputDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialBatchOperationMaterialWithMaterialRangeTagsDocument = gql`
    query materialBatchOperationMaterialWithMaterialRangeTags($legacyId: Int!) {
  material(legacyId: $legacyId) {
    tagAssignments(tagTypes: [MaterialRange]) {
      tag {
        ...BasicTagInfo
      }
    }
  }
}
    ${BasicTagInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialBatchOperationMaterialWithMaterialRangeTagsGQL extends Apollo.Query<MaterialBatchOperationMaterialWithMaterialRangeTagsQuery, MaterialBatchOperationMaterialWithMaterialRangeTagsQueryVariables> {
    override document = MaterialBatchOperationMaterialWithMaterialRangeTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetImageDownloadDetailsForBatchDownloadServiceDocument = gql`
    query getImageDownloadDetailsForBatchDownloadService($materialId: ID!, $assignmentType: DataObjectAssignmentType!) {
  material(id: $materialId) {
    dataObjectAssignments(filter: {assignmentType: [$assignmentType]}) {
      dataObject {
        ...ImageDownloadDetailsForBatchDownloadService
      }
    }
  }
}
    ${ImageDownloadDetailsForBatchDownloadServiceFragmentDoc}
${DataObjectDetailsForBatchDownloadServiceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetImageDownloadDetailsForBatchDownloadServiceGQL extends Apollo.Query<GetImageDownloadDetailsForBatchDownloadServiceQuery, GetImageDownloadDetailsForBatchDownloadServiceQueryVariables> {
    override document = GetImageDownloadDetailsForBatchDownloadServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPbrFilenameForBatchDownloadServiceDocument = gql`
    query getPBRFilenameForBatchDownloadService($dataObjectLegacyId: Int!) {
  dataObject(legacyId: $dataObjectLegacyId) {
    originalFileName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPbrFilenameForBatchDownloadServiceGQL extends Apollo.Query<GetPbrFilenameForBatchDownloadServiceQuery, GetPbrFilenameForBatchDownloadServiceQueryVariables> {
    override document = GetPbrFilenameForBatchDownloadServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }