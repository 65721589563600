import {Component, inject} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatDialogActions, MatDialogClose, MatDialogModule} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {RouterModule} from "@angular/router"
import {SiloService} from "@common/services/auth/silo.service"
import {ContentTypeModel, MembershipRole, MutationCreateUserInput, MutationUpdateUserInput} from "@generated"
import {UserListItemFragment, GetUsersGQL, InviteUserGQL} from "@platform/components/users/users-grid/users-grid.generated"
import {CardErrorComponent, CardPlaceholderComponent} from "@common/components/cards"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {ItemListComponent, ListInfoComponent} from "@common/components/item"
import {InfiniteListComponent} from "@common/components/lists"
import {extractErrorInfo} from "@common/helpers/api/errors"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"

@Component({
    selector: "cm-users-grid",
    templateUrl: "./users-grid.component.html",
    styleUrls: ["./users-grid.component.scss"],
    imports: [
        CardErrorComponent,
        CardPlaceholderComponent,
        EntityCardComponent,
        FormsModule,
        InfiniteListComponent,
        ListInfoComponent,
        MatButtonModule,
        MatDialogActions,
        MatDialogClose,
        MatDialogModule,
        MatInputModule,
        MatSelectModule,
        RouterModule,
    ],
})
export class UsersGridComponent extends ItemListComponent<UserListItemFragment, MutationUpdateUserInput, MutationCreateUserInput> {
    readonly silo = inject(SiloService)

    protected override _contentTypeModel = ContentTypeModel.User
    readonly inviteUserGql = inject(InviteUserGQL)
    protected override _createItem = (data: MutationCreateUserInput) =>
        mutateThrowingErrors(this.inviteUserGql)({
            input: data,
        })
            .then(({inviteUser: model}) => model)
            .catch((errorResponse) => {
                throw Error(extractErrorInfo(errorResponse, {409: "User with this email already exists"}).message)
            })

    readonly usersGql = inject(GetUsersGQL)
    protected override _fetchList = ({skip, take}: {skip: number; take: number}) =>
        fetchThrowingErrors(this.usersGql)({
            take: take,
            skip: skip,
            filter: this.filters.userFilter(),
        }).then(({users, usersCount}) => ({items: users, totalCount: usersCount}))

    protected override _refreshItem = ({id, legacyId}: {id?: string; legacyId?: number}) =>
        fetchThrowingErrors(this.usersGql)({
            take: 1,
            filter: {
                ...this.filters.userFilter(),
                id: id ? {equals: id} : undefined,
                legacyId: legacyId ? {equals: legacyId} : undefined,
            },
        }).then(({users}) => users?.[0] || undefined)

    organizationIds = this.silo.$silo()?.organization?.id ? [this.silo.$silo()?.organization?.id] : []

    async toggleOrganizationMembership(organizationIds: string[]) {
        this.newItemData = {
            ...this.newItemData,
            memberships: organizationIds.map((organizationId) => ({organizationId, role: MembershipRole.Viewer})),
        }
    }
}
