import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type HdriForRenderingQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type HdriForRenderingQuery = { __typename: 'Query', hdri: { __typename: 'Hdri', dataObject?: { __typename: 'DataObject', id: string, legacyId: number } | null } };

export type RenderingServiceCreateJobMutationVariables = Types.Exact<{
  input: Types.MutationCreateJobInput;
}>;


export type RenderingServiceCreateJobMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string } };

export type GetDataObjectDetailsQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetDataObjectDetailsQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string, originalFileName: string, mediaType?: string | null } };

export const HdriForRenderingDocument = gql`
    query hdriForRendering($id: ID, $legacyId: Int) {
  hdri(id: $id, legacyId: $legacyId) {
    dataObject {
      id
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HdriForRenderingGQL extends Apollo.Query<HdriForRenderingQuery, HdriForRenderingQueryVariables> {
    override document = HdriForRenderingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RenderingServiceCreateJobDocument = gql`
    mutation renderingServiceCreateJob($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RenderingServiceCreateJobGQL extends Apollo.Mutation<RenderingServiceCreateJobMutation, RenderingServiceCreateJobMutationVariables> {
    override document = RenderingServiceCreateJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDataObjectDetailsDocument = gql`
    query getDataObjectDetails($id: ID, $legacyId: Int) {
  dataObject(id: $id, legacyId: $legacyId) {
    id
    downloadUrl
    originalFileName
    mediaType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDataObjectDetailsGQL extends Apollo.Query<GetDataObjectDetailsQuery, GetDataObjectDetailsQueryVariables> {
    override document = GetDataObjectDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }