import {SmartPtr} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/smart-ptr"
import {RefCountableBase} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/ref-countable-base"
import {TextureEditorSettings} from "@app/textures/texture-editor/texture-editor-settings"

const TRACE = TextureEditorSettings.EnableFullTrace

export type DrawableImageHandleId = number

export type DrawableImageHandle = {
    readonly isDrawableImageHandle: true
    readonly id: DrawableImageHandleId
}

export function isDrawableImageHandle(obj: unknown): obj is DrawableImageHandle {
    return typeof obj === "object" && obj != null && "isDrawableImageHandle" in obj && obj.isDrawableImageHandle === true
}

export class RefCountedDrawableImageHandle extends RefCountableBase implements DrawableImageHandle {
    readonly isDrawableImageHandle = true

    constructor(
        readonly id: DrawableImageHandleId,
        private disposeFn: ((handle: RefCountedDrawableImageHandle) => void) | undefined,
    ) {
        super({
            addRefFn: () => {
                if (TRACE) {
                    console.log(`RefCountedDrawableImageHandle: addRef of ${this.id}; refCount=${this.refCount}`)
                }
            },
            releaseFn: () => {
                if (TRACE) {
                    console.log(`RefCountedDrawableImageHandle: release of ${this.id}; refCount=${this.refCount}`)
                }
            },
            disposeFn: () => {
                if (TRACE) {
                    console.log(`RefCountedDrawableImageHandle: dispose of ${this.id}`)
                }
                this.disposeFn?.(this)
            },
        })
    }
}

export class ManagedDrawableImageHandle extends SmartPtr<RefCountedDrawableImageHandle> {}
