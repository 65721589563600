import {Component, DestroyRef, inject, ViewEncapsulation, OnInit, input} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {AccordionStyle, InterfaceDescriptor, isConfigInput, UiStyle} from "@cm/template-nodes"
import {Breakpoints} from "@app/common/models/constants"
import {DefaultMenuComponent} from "@common/components/viewers/configurator/config-menu/default-menu/default-menu.component"
import {IconsAccordionMenuComponent} from "@common/components/viewers/configurator/config-menu/icons-accordion-menu/icons-accordion-menu.component"
import {ConfigMenuService} from "@common/components/viewers/configurator/config-menu/services/config-menu.service"

@Component({
    selector: "cm-config-menu",
    templateUrl: "./config-menu.component.html",
    styleUrls: ["./config-menu.component.scss"],
    encapsulation: ViewEncapsulation.ShadowDom,
    imports: [IconsAccordionMenuComponent, DefaultMenuComponent],
})
export class ConfigMenuComponent implements OnInit {
    readonly $hideInputsSetByTemplate = input<boolean>(true, {alias: "hideInputsSetByTemplate"})

    interfaceDescriptors: InterfaceDescriptor<unknown, object>[] = []
    _uiStyle: UiStyle | undefined

    //https://github.com/angular/angular/issues/53981
    //input/output signals currently do not work properly on web components:
    //Do not rename any of the inputs, they are used on the web component and exposed to the customers
    readonly $uiStyleOverride = input<UiStyle>(undefined, {alias: "uiStyleOverride"}) //old name, used by vado and darran //old name, used by vado and darran
    readonly $uiStyle = input<UiStyle>(undefined, {alias: "uiStyle"})
    readonly $accordionStyle = input<AccordionStyle>("autoClose", {alias: "accordionStyle"})
    readonly $externalMenu = input<boolean>(false, {alias: "externalMenu"})
    readonly $useCaptions = input<boolean>(false, {alias: "useCaptions"})

    private readonly configMenuService = inject(ConfigMenuService)
    private readonly destroyRef = inject(DestroyRef)

    minimized = false

    constructor() {
        this.configMenuService.interfaceNew$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((interfaceDescriptors) => {
            this.interfaceDescriptors = interfaceDescriptors.filter((desc) => this.showInput(desc))
        })

        this.configMenuService.uiStyle$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((uiStyle) => {
            if (this.$uiStyleOverride() !== undefined) this._uiStyle = this.$uiStyleOverride()
            else if (this.$uiStyle() !== undefined) this._uiStyle = this.$uiStyle()
            else this._uiStyle = uiStyle
        })
    }

    ngOnInit(): void {
        if (window.innerWidth < Breakpoints.mobileScreen) {
            this.minimized = true
        }
    }

    showInput(descriptor: InterfaceDescriptor<unknown, object>): boolean {
        if (isConfigInput(descriptor)) return true
        return this.$hideInputsSetByTemplate() ? !descriptor.props.isSetByTemplate : true
    }
}
