import {TextureEditorSettings} from "app/textures/texture-editor/texture-editor-settings"
import {AddressSpace, ImageDescriptor, ImageRefId} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {RefCountableBase} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/ref-countable-base"

const TRACE = TextureEditorSettings.EnableFullTrace

export class ImageBase extends RefCountableBase {
    constructor(
        readonly descriptor: ImageDescriptor,
        readonly addressSpace: AddressSpace,
        readonly id: ImageRefId,
        private disposeFn: ((image: ImageBase) => void) | undefined,
        readonly debugInfo?: string,
    ) {
        super({
            addRefFn: () => {
                if (TRACE) {
                    console.log(`ImageRefBase: addRef of ${this.addressSpace}[${this.id}] (${this.debugInfo}); refCount=${this.refCount}`)
                }
            },
            releaseFn: () => {
                if (TRACE) {
                    console.log(`ImageRefBase: release of ${this.addressSpace}[${this.id}] (${this.debugInfo}); refCount=${this.refCount}`)
                }
            },
            disposeFn: () => {
                if (TRACE) {
                    console.log(`ImageRefBase: dispose of ${this.addressSpace}[${this.id}] (${this.debugInfo})`)
                }
                this.disposeFn?.(this)
            },
        })
        if (TRACE) {
            console.log(`ImageRefBase: creation of ${addressSpace}[${id}] (${debugInfo}); refCount=${this.refCount}`)
        }
    }

    equals(other: ImageBase) {
        return this.addressSpace === other.addressSpace && this.id === other.id
    }
}
