<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> Source Setup </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="$node()" [key]="'source'" [label]="'Source'"></cm-value-slot>
        <cm-value-slot [node]="$node()" [key]="'uvChannel'" [label]="'UV Channel'" [decimalPlaces]="0"></cm-value-slot>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Targets Setup </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="$node()" [key]="'targets'" [label]="'Targets'"></cm-value-slot>
        <cm-value-slot [node]="$node()" [key]="'range'" [label]="'Range'" [decimalPlaces]="3"></cm-value-slot>
        <cm-value-slot [node]="$node()" [key]="'mergeRange'" [label]="'Merge Range'" [decimalPlaces]="3"></cm-value-slot>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Resolution </ng-container>
    <ng-container cm-content>
        <div class="cm-resolution">
            <cm-value-slot [node]="$node()" [key]="'width'" [label]="'Width'" [decimalPlaces]="0"></cm-value-slot>
            <div class="cm-multiplier">×</div>
            <cm-value-slot [node]="$node()" [key]="'height'" [label]="'Height'" [decimalPlaces]="0"></cm-value-slot>
        </div>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Filters </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="$node()" [key]="'innerValue'" [label]="'Inside Value'" [decimalPlaces]="2"></cm-value-slot>
        <cm-value-slot [node]="$node()" [key]="'smoothPasses'" [label]="'Smooth Passes'" [decimalPlaces]="0"></cm-value-slot>
    </ng-container>
</cm-inspector-section>
