import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {copyRegion} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-copy-region"
import {createImage} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-create-image"
import {drawableImage} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-drawable-image"
import {CloneStampPanelComponent} from "@app/textures/texture-editor/operator-stack/operators/clone-stamp/panel/clone-stamp-panel.component"
import {CloneStampToolbox} from "@app/textures/texture-editor/operator-stack/operators/clone-stamp/toolbox/clone-stamp-toolbox"
import {Box2Like, Vector2, Vector2Like} from "@cm/math"
import {deepCopy, wrap} from "@cm/utils"
import {
    Operator,
    OperatorInput,
    OperatorOutput,
    OperatorPanelComponentType,
    OperatorProcessingHints,
} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-base"
import {OperatorCallback} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {BrushSettings} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"
import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"
import {DrawableImageHandle, ManagedDrawableImageHandle} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/drawable-image-handle"
import {clear} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-clear"
import {blend} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-blend"

export class OperatorCloneStamp extends OperatorBase<TextureEditNodes.OperatorCloneStamp> {
    readonly panelComponentType: OperatorPanelComponentType = CloneStampPanelComponent
    readonly canvasToolbox: CloneStampToolbox

    readonly type = "operator-clone-stamp" as const

    constructor(callback: OperatorCallback, node: TextureEditNodes.OperatorCloneStamp | null) {
        super(
            callback,
            deepCopy(node) ?? {
                type: "operator-clone-stamp",
                enabled: true,
                strokes: [],
            },
        )
        this.canvasToolbox = new CloneStampToolbox(this)
        this.canvasToolbox.brushToolboxItem.hoverPositionChanged.subscribe(() => this.requestEval())
    }

    // OperatorBase
    override async init() {
        await super.init()

        this.currentStrokeDrawableImageHandle = await this.callback.imageOpContextWebGL2.createDrawableImageHandle({
            width: 1,
            height: 1,
            channelLayout: "R",
            dataType: "uint8",
        })
        this.canvasToolbox.brushToolboxItem.setDrawableImage(this.currentStrokeDrawableImageHandle.ref, "reset")

        await Promise.all(
            this.node.strokes.map(async (stroke) => {
                if (stroke.maskReference.type !== "data-object-reference") {
                    throw new Error(`Unexpected mask reference type: ${JSON.stringify(stroke.maskReference)}`)
                }
                return this.callback.imageOpContextWebGL2
                    .createDrawableImageHandle({
                        dataObjectId: stroke.maskReference.dataObjectId,
                        channelLayout: "R",
                        dataType: "uint8",
                    })
                    .then((drawableImageHandle) => this.drawableImageHandleByMaskReference.set(stroke.maskReference, drawableImageHandle))
            }),
        )
    }

    // OperatorBase
    override dispose(): void {
        super.dispose()
        this.currentStrokeDrawableImageHandle.release()
        for (const drawableImageHandle of this.drawableImageHandleByMaskReference.values()) {
            drawableImageHandle.release()
        }
        this.drawableImageHandleByMaskReference.clear()
        this.canvasToolbox.remove()
    }

    // OperatorBase
    async clone(): Promise<Operator> {
        const clonedOperator = new OperatorCloneStamp(this.callback, deepCopy(this.node))
        await clonedOperator.init()
        await Promise.all(
            this.node.strokes.map((stroke, index) => {
                const clonedStroke = clonedOperator.node.strokes[index]
                const strokeMaskDrawableImageRef = this.getDrawableImageHandleByMaskReference(stroke.maskReference)
                const clonedStrokeMaskDrawableImageRef = clonedOperator.getDrawableImageHandleByMaskReference(clonedStroke.maskReference)
                return this.callback.drawableImageCache.copyDrawableImage({
                    sourceId: strokeMaskDrawableImageRef.id,
                    targetId: clonedStrokeMaskDrawableImageRef.id,
                })
            }),
        )
        return clonedOperator
    }

    get showMask(): boolean {
        return this._showMask
    }

    set showMask(value: boolean) {
        this._showMask = value
        this.requestEval()
    }

    get canUndoStroke(): boolean {
        return this.node.strokes.length > 0
    }

    undoStroke(): void {
        if (this.node.strokes.length === 0) {
            return
        }
        const lastStroke = this.node.strokes.pop()!
        this.removeDrawableImageRefByMaskReference(lastStroke.maskReference)
        this.markEdited()
        this.requestEval()
    }

    // OperatorBase
    async queueImageOps(cmdQueue: ImageOpCommandQueue, input: OperatorInput, _hints: OperatorProcessingHints): Promise<OperatorOutput> {
        cmdQueue.beginScope(this.type)

        this.canvasToolbox.brushToolboxItem.flushBrushStrokesExt(cmdQueue, input.descriptor)

        const showMaskOnly = cmdQueue.mode === "preview" && this.selected && this.showMask
        let sourceImage: ImageRef
        let resultImage: ImageRef
        if (showMaskOnly) {
            sourceImage = createImage(cmdQueue, {
                imageOrDescriptor: input.descriptor,
                fillColor: {r: 1, g: 1, b: 1, a: 1},
            })
            resultImage = createImage(cmdQueue, {
                imageOrDescriptor: input.descriptor,
                fillColor: {r: 0, g: 0, b: 0, a: 1},
            })
        } else {
            sourceImage = input
            resultImage = copyRegion(cmdQueue, {
                sourceImage: sourceImage,
            })
        }
        const applyStroke = (
            maskImage: ImageRef,
            maskRegion: TextureEditNodes.Region,
            sourceOffsetInPixels: Vector2Like,
            sourceImage: ImageRef,
            resultImage: ImageRef,
            optionalMaskStrokeBoundingBox?: Box2Like,
        ): ImageRef => {
            // TODO if we had views AND imageOpBlend would allow for resultImage==backgroundImage by utilizing actual alpha-blending
            // TODO we could use the following op instead of all of the ones below
            // resultImage = imageOpList.push(imageOpBlend, {
            //     backgroundImage: resultImage,
            //     foregroundImage: makeImageView(sourceImage, {
            //          x: stroke.sourceOffsetInPixels.x,
            //          y: stroke.sourceOffsetInPixels.y,
            //          width: stroke.maskRegion.width,
            //          height: stroke.maskRegion.height,
            //      }),
            //     alphaImage: maskImage,
            //     premultipliedAlpha: false,
            //     blendMode: "normal",
            //     resultImage: makeImageView(sourceImage, stroke.maskRegion),
            // })
            const hasStrokeBoundingBox = optionalMaskStrokeBoundingBox !== undefined
            const maskStrokeBoundingBox = hasStrokeBoundingBox ? deepCopy(optionalMaskStrokeBoundingBox) : maskRegion
            const cutoutShiftedSourceImage = copyRegion(cmdQueue, {
                sourceImage: sourceImage,
                sourceRegion: {
                    x: sourceOffsetInPixels.x - maskRegion.x + maskStrokeBoundingBox.x,
                    y: sourceOffsetInPixels.y - maskRegion.y + maskStrokeBoundingBox.y,
                    width: maskStrokeBoundingBox.width,
                    height: maskStrokeBoundingBox.height,
                },
                addressMode: "wrap",
            })
            const cutoutResultImage = copyRegion(cmdQueue, {
                sourceImage: resultImage,
                sourceRegion: maskStrokeBoundingBox,
                addressMode: "wrap",
            })
            const cutoutMaskImage = hasStrokeBoundingBox
                ? copyRegion(cmdQueue, {
                      sourceImage: maskImage,
                      sourceRegion: maskStrokeBoundingBox,
                      addressMode: "wrap",
                  })
                : maskImage
            // const stampedRegion = blendByLaplacianPyramid(cmdQueue, {
            //     backgroundImage: cutoutResultImage,
            //     foregroundImage: cutoutShiftedSourceImage,
            //     maskImage: cutoutMaskImage,
            // }).resultImage
            const stampedRegion = blend(cmdQueue, {
                backgroundImage: cutoutResultImage,
                foregroundImage: cutoutShiftedSourceImage,
                alpha: cutoutMaskImage,
                premultipliedAlpha: false,
                blendMode: "normal",
            })
            resultImage = copyRegion(cmdQueue, {
                sourceImage: stampedRegion,
                targetOffset: {
                    x: maskStrokeBoundingBox.x,
                    y: maskStrokeBoundingBox.y,
                },
                addressMode: "wrap",
                resultImageOrDataType: resultImage,
            })
            return resultImage
        }
        for (const stroke of this.node.strokes) {
            const maskDrawableImageRef = this.getDrawableImageHandleByMaskReference(stroke.maskReference)
            const maskImage = drawableImage(cmdQueue, {
                drawableImageHandle: maskDrawableImageRef,
            })
            resultImage = applyStroke(maskImage, stroke.maskRegion, stroke.sourceOffsetInPixels, sourceImage, resultImage)
        }
        if (cmdQueue.mode === "preview") {
            if (this.canvasToolbox.isStrokeInProgress) {
                const maskImage = drawableImage(cmdQueue, {
                    drawableImageHandle: this.currentStrokeDrawableImageHandle.ref,
                })
                resultImage = applyStroke(
                    maskImage,
                    {
                        type: "region",
                        x: 0,
                        y: 0,
                        width: sourceImage.descriptor.width,
                        height: sourceImage.descriptor.height,
                    },
                    this.canvasToolbox.sourceOffset,
                    sourceImage,
                    resultImage,
                    this.canvasToolbox.brushToolboxItem.boundingBox,
                )
            } else if (
                this.canvasToolbox.mode === "brush" &&
                !this.canvasToolbox.brushToolboxItem.isDrawing &&
                this.canvasToolbox.brushToolboxItem.lastHoverPosition
            ) {
                // draw preview stamp
                const maskImage = this.canvasToolbox.brushToolboxItem.getBrushShape(cmdQueue)
                const halfMaskSize = Vector2.fromSize2Like(maskImage.descriptor).mulInPlace(0.5)
                const wrapAroundOffsets = [
                    new Vector2(0, 0),
                    new Vector2(sourceImage.descriptor.width, 0),
                    new Vector2(0, sourceImage.descriptor.height),
                    new Vector2(sourceImage.descriptor.width, sourceImage.descriptor.height),
                ]
                const offset = this.canvasToolbox.brushToolboxItem.lastHoverPosition.sub(halfMaskSize).floor()
                offset.x = wrap(offset.x, sourceImage.descriptor.width)
                offset.y = wrap(offset.y, sourceImage.descriptor.height)
                if (offset.x > sourceImage.descriptor.width / 2) {
                    offset.x -= sourceImage.descriptor.width
                }
                if (offset.y > sourceImage.descriptor.height / 2) {
                    offset.y -= sourceImage.descriptor.height
                }
                for (const wrapAroundOffset of wrapAroundOffsets) {
                    const maskOffset = offset.add(wrapAroundOffset)
                    if (
                        maskOffset.x + maskImage.descriptor.width < 0 ||
                        maskOffset.y + maskImage.descriptor.height < 0 ||
                        maskOffset.x >= sourceImage.descriptor.width ||
                        maskOffset.y >= sourceImage.descriptor.height
                    ) {
                        continue
                    }
                    resultImage = applyStroke(
                        maskImage,
                        {
                            type: "region",
                            ...maskOffset,
                            width: maskImage.descriptor.width,
                            height: maskImage.descriptor.height,
                        },
                        this.canvasToolbox.cloneSourcePosition.sub(halfMaskSize),
                        sourceImage,
                        resultImage,
                    )
                }
            }
        }
        cmdQueue.endScope(this.type)
        return {
            resultImage,
            options: {
                stopEvaluation: showMaskOnly,
            },
        }
    }

    private getDrawableImageHandleByMaskReference(maskReference: TextureEditNodes.MaskReference): DrawableImageHandle {
        const drawableImageRef = this.drawableImageHandleByMaskReference.get(maskReference)
        if (!drawableImageRef) {
            throw new Error(`Drawable image ref not found for mask reference: ${JSON.stringify(maskReference)}`)
        }
        return drawableImageRef.ref
    }

    private removeDrawableImageRefByMaskReference(maskReference: TextureEditNodes.MaskReference): void {
        const drawableImageRef = this.drawableImageHandleByMaskReference.get(maskReference)
        if (!drawableImageRef) {
            return
        }
        this.drawableImageHandleByMaskReference.delete(maskReference)
        drawableImageRef.release()
    }

    async addCloneStampEdit() {
        const boundingBox = this.canvasToolbox.brushToolboxItem.boundingBox
        if (boundingBox.isEmpty()) {
            return null
        }
        const cloneStampStroke: TextureEditNodes.CloneStampStroke = {
            maskReference: {
                type: "data-object-reference",
                dataObjectId: "",
            },
            maskRegion: {
                type: "region",
                x: boundingBox.x,
                y: boundingBox.y,
                width: boundingBox.width,
                height: boundingBox.height,
            },
            sourceOffsetInPixels: this.canvasToolbox.sourceOffset.add(boundingBox.position),
        }
        const cloneStampStrokeMaskDrawableImageHandle = await this.callback.imageOpContextWebGL2.createDrawableImageHandle({
            width: boundingBox.width,
            height: boundingBox.height,
            channelLayout: "R",
            dataType: "uint8",
        })
        this.drawableImageHandleByMaskReference.set(cloneStampStroke.maskReference, cloneStampStrokeMaskDrawableImageHandle)
        this.node.strokes.push(cloneStampStroke)

        const cmdQueue = this.callback.imageOpContextWebGL2.createCommandQueue()
        const strokeImage = drawableImage(cmdQueue, {
            drawableImageHandle: this.currentStrokeDrawableImageHandle.ref,
        })
        const cutoutMaskImage = copyRegion(cmdQueue, {
            sourceImage: strokeImage,
            sourceRegion: boundingBox,
            resultImageOrDataType: drawableImage(cmdQueue, {
                drawableImageHandle: cloneStampStrokeMaskDrawableImageHandle.ref,
            }),
        })
        // clear stroke image
        clear(cmdQueue, {
            color: {r: 0, g: 0, b: 0, a: 1},
            resultImage: strokeImage,
        })
        this.canvasToolbox.brushToolboxItem.boundingBox.makeEmpty()

        const [evaluatedCutoutMaskImage, evaluatedStrokeImage] = await cmdQueue.execute([cutoutMaskImage, strokeImage])
        evaluatedCutoutMaskImage.release()
        evaluatedStrokeImage.release()

        this.markEdited()
        this.requestEval()
        return cloneStampStroke
    }

    override async save(processingJobId: string) {
        await Promise.all(
            this.node.strokes.map((stroke) => {
                const drawableImageRef = this.getDrawableImageHandleByMaskReference(stroke.maskReference)
                return this.callback.drawableImageCache
                    .getDataObjectId(drawableImageRef.id)
                    .then((dataObjectId) => (stroke.maskReference.dataObjectId = dataObjectId))
            }),
        )
        return super.save(processingJobId)
    }

    readonly brushSettings = new BrushSettings()

    private _showMask = false
    private currentStrokeDrawableImageHandle!: ManagedDrawableImageHandle
    private drawableImageHandleByMaskReference = new Map<TextureEditNodes.MaskReference, ManagedDrawableImageHandle>()
}
