import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ModelsPageUserFragment = { __typename: 'User', id: string, name: string };

export type ModelsPageVisibleUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ModelsPageVisibleUsersQuery = { __typename: 'Query', users: Array<{ __typename: 'User', id: string, name: string } | null> };

export const ModelsPageUserFragmentDoc = gql`
    fragment ModelsPageUser on User {
  id
  name
}
    `;
export const ModelsPageVisibleUsersDocument = gql`
    query modelsPageVisibleUsers {
  users(filter: {visibleInFilters: true}, take: 9999) {
    ...ModelsPageUser
  }
}
    ${ModelsPageUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ModelsPageVisibleUsersGQL extends Apollo.Query<ModelsPageVisibleUsersQuery, ModelsPageVisibleUsersQueryVariables> {
    override document = ModelsPageVisibleUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }