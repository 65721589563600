import {CdkMenu, CdkMenuItem, CdkMenuTrigger} from "@angular/cdk/menu"
import {NgTemplateOutlet} from "@angular/common"
import {Component, computed, effect, HostListener, inject, OnDestroy, OnInit} from "@angular/core"
import {MatButtonModule} from "@angular/material/button"
import {MatDialog} from "@angular/material/dialog"
import {MatListModule} from "@angular/material/list"
import {ActivatedRoute, NavigationEnd, Router, RouterLink, UrlSerializer} from "@angular/router"
import {SiloService} from "@common/services/auth/silo.service"
import {OrganizationType, SystemRole} from "@generated"
import {
    NavigationBarOrganizationFragment,
    NavigationBarUserMembershipsGQL,
} from "@app/common/components/navigation/navigation-bar/navigation-bar-component.generated"
import {IsDefined} from "@cm/utils"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {LineComponent} from "@common/components/line/line.component"
import {
    NavigationBarAllOrganizationsGQL,
    NavigationBarColormassOrganizationGQL,
} from "@common/components/navigation/navigation-bar/navigation-bar-component.generated"
import {NavigationItemComponent} from "@common/components/navigation/navigation-item/navigation-item.component"
import {SidenavComponent} from "@common/components/navigation/sidenav/sidenav.component"
import {fetchIgnoringErrors, fetchThrowingErrors} from "@common/helpers/api/fetch"
import {Breakpoints} from "@common/models/constants"
import {AuthService} from "@common/services/auth/auth.service"
import {NavigationBarService} from "@common/services/navigation-bar/navigation-bar-service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {environment} from "@environment"
import {Labels} from "@labels"
import {UserThumbnailComponent} from "@platform/components/users/user-thumbnail/user-thumbnail.component"
import {filter, Subject, takeUntil} from "rxjs"

@Component({
    selector: "cm-navigation-bar",
    templateUrl: "navigation-bar-component.html",
    styleUrls: ["navigation-bar-component.scss"],
    imports: [
        RouterLink,
        NgTemplateOutlet,
        ToggleComponent,
        NavigationItemComponent,
        SidenavComponent,
        MatButtonModule,
        MatListModule,
        CdkMenu,
        CdkMenuTrigger,
        CdkMenuItem,
        LineComponent,
        UserThumbnailComponent,
    ],
})
export class NavigationBarComponent implements OnInit, OnDestroy {
    @HostListener("window:resize", ["$event"])
    onResize(_event: Event) {
        this.isBigScreen = window.innerWidth >= Breakpoints.smallScreenLimit
    }

    progressBarValue = 100
    selectedLink: string | undefined
    unsubscribe = new Subject<void>()
    sidenavOpen = false
    isBigScreen: boolean = false
    navigationIsToggled = false
    allOrganizations: NavigationBarOrganizationFragment[] = []

    environment = environment
    backgroundColor = environment.style.navigationBar.background ?? "#0000000"

    readonly permission = inject(PermissionsService)
    readonly auth = inject(AuthService)
    readonly organizations = inject(OrganizationsService)
    readonly refresh = inject(RefreshService)
    readonly serializer = inject(UrlSerializer)
    readonly silo = inject(SiloService)
    $can = this.permission.$to
    readonly $canReadAuthenticateForAnyOrganizationMenu = computed(() => this.$can().read.menu("authenticateForAnyOrganization"))

    private readonly navigationBarColormassOrganization = inject(NavigationBarColormassOrganizationGQL)
    private readonly navigationBarAllOrganizations = inject(NavigationBarAllOrganizationsGQL)
    private readonly navigationBarUserMemberships = inject(NavigationBarUserMembershipsGQL)

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public dialog: MatDialog,
        public navBarService: NavigationBarService,
    ) {
        effect(() => {
            const userId = this.auth.$user()?.id
            if (userId !== undefined) void this.loadOrganizations(this.$canReadAuthenticateForAnyOrganizationMenu(), userId)
        })
    }

    ngOnInit() {
        this.isBigScreen = window.innerWidth >= Breakpoints.smallScreenLimit
        this.navBarService.progress.pipe(takeUntil(this.unsubscribe)).subscribe((progress: number) => {
            this.progressBarValue = progress
        })
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this.unsubscribe),
            )
            .subscribe(() => {
                this.selectedLink = this.route.snapshot.children[0].routeConfig?.path
            })
    }

    async loadOrganizations(canReadAuthenticateForAnyOrganizationMenu: boolean, userId: string) {
        if (canReadAuthenticateForAnyOrganizationMenu) {
            this.allOrganizations = []
            const {organizations} = await fetchThrowingErrors(this.navigationBarAllOrganizations)(
                {
                    filter: {visibleInFilters: true},
                },
                {context: {withoutSilo: true}},
            )
            this.allOrganizations = [...this.allOrganizations, ...organizations.filter(IsDefined)]
        } else {
            const {user} = await fetchThrowingErrors(this.navigationBarUserMemberships)({id: userId}, {context: {withoutSilo: true}})
            this.allOrganizations = user.memberships.map((membership) => membership.organization).filter(IsDefined)
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }

    async activateSystemRole(systemRole: SystemRole) {
        await this.silo.activateSystemRole(systemRole)
        const colormassOrganizations = await fetchIgnoringErrors(this.navigationBarColormassOrganization)()
        const colormassId = colormassOrganizations?.organizations?.[0]?.id
        if (colormassId && this.route.snapshot.queryParamMap.get("organizationId")) {
            await this.router.navigate(this.route.snapshot.url, {
                queryParams: {organizationId: colormassId},
                queryParamsHandling: "merge",
            })
        }
        await this.router.navigate(["pictures"], {queryParams: {tab: "projects", organizationId: colormassId}})
    }

    async activateOrganization(organization: {__typename: "Organization"; id: string; name?: string | null; type?: OrganizationType | null}) {
        this.silo.selectOrganization(organization)
        this.refresh.all()
        const organizationId = organization?.id ?? null
        await this.router.navigate(["pictures"], {queryParams: {tab: "projects", organizationId}})
    }

    get showOrganizationSwitcher() {
        return this.$canReadAuthenticateForAnyOrganizationMenu() || (this.$can().read.menu("authenticateForOrganization") && this.allOrganizations.length > 1)
    }

    protected readonly Labels = Labels
    protected readonly SystemRole = SystemRole
}
