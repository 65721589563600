import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MaterialDetailsForMaterialReferenceInspectorFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, organization?: { __typename: 'Organization', legacyId: number, name?: string | null } | null, cyclesRevisions?: Array<{ __typename: 'MaterialRevision', id: string, legacyId: number, number: number }> | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, number: number } | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type MaterialRevisionDetailsForMaterialReferenceInspectorFragment = { __typename: 'MaterialRevision', id: string, legacyId: number, material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, organization?: { __typename: 'Organization', legacyId: number, name?: string | null } | null, cyclesRevisions?: Array<{ __typename: 'MaterialRevision', id: string, legacyId: number, number: number }> | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, number: number } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type GetMaterialRevisionDetailsForMaterialReferenceInspectorQueryVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type GetMaterialRevisionDetailsForMaterialReferenceInspectorQuery = { __typename: 'Query', materialRevision: { __typename: 'MaterialRevision', id: string, legacyId: number, material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, organization?: { __typename: 'Organization', legacyId: number, name?: string | null } | null, cyclesRevisions?: Array<{ __typename: 'MaterialRevision', id: string, legacyId: number, number: number }> | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number, number: number } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } } };

export const MaterialDetailsForMaterialReferenceInspectorFragmentDoc = gql`
    fragment MaterialDetailsForMaterialReferenceInspector on Material {
  id
  legacyId
  name
  organization {
    legacyId
    name
  }
  cyclesRevisions {
    id
    legacyId
    number
  }
  latestCyclesRevision {
    id
    legacyId
    number
  }
  galleryImage {
    id
  }
}
    `;
export const MaterialRevisionDetailsForMaterialReferenceInspectorFragmentDoc = gql`
    fragment MaterialRevisionDetailsForMaterialReferenceInspector on MaterialRevision {
  id
  legacyId
  material {
    ...MaterialDetailsForMaterialReferenceInspector
  }
}
    `;
export const GetMaterialRevisionDetailsForMaterialReferenceInspectorDocument = gql`
    query getMaterialRevisionDetailsForMaterialReferenceInspector($legacyId: Int!) {
  materialRevision(legacyId: $legacyId) {
    ...MaterialRevisionDetailsForMaterialReferenceInspector
  }
}
    ${MaterialRevisionDetailsForMaterialReferenceInspectorFragmentDoc}
${MaterialDetailsForMaterialReferenceInspectorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialRevisionDetailsForMaterialReferenceInspectorGQL extends Apollo.Query<GetMaterialRevisionDetailsForMaterialReferenceInspectorQuery, GetMaterialRevisionDetailsForMaterialReferenceInspectorQueryVariables> {
    override document = GetMaterialRevisionDetailsForMaterialReferenceInspectorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }