import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PictureAssetsSectionFragment = { __typename: 'Picture', id: string, legacyId: number, assetAssignments: Array<{ __typename: 'PictureAssetAssignment', id: string, asset: { __typename: 'Asset', id: string, legacyId: number, name?: string | null, state: Types.AssetState, galleryImage?: { __typename: 'DataObject', id: string } | null, materials: Array<{ __typename: 'Material', id: string, state: Types.MaterialState, galleryImage?: { __typename: 'DataObject', id: string } | null }>, model: { __typename: 'Model', state: Types.ModelState, galleryImage?: { __typename: 'DataObject', id: string } | null }, organization: { __typename: 'Organization', name?: string | null, id: string, legacyId: number } } }> };

export type PictureAssetsSectionQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type PictureAssetsSectionQuery = { __typename: 'Query', item: { __typename: 'Picture', id: string, legacyId: number, assetAssignments: Array<{ __typename: 'PictureAssetAssignment', id: string, asset: { __typename: 'Asset', id: string, legacyId: number, name?: string | null, state: Types.AssetState, galleryImage?: { __typename: 'DataObject', id: string } | null, materials: Array<{ __typename: 'Material', id: string, state: Types.MaterialState, galleryImage?: { __typename: 'DataObject', id: string } | null }>, model: { __typename: 'Model', state: Types.ModelState, galleryImage?: { __typename: 'DataObject', id: string } | null }, organization: { __typename: 'Organization', name?: string | null, id: string, legacyId: number } } }> } };

export const PictureAssetsSectionFragmentDoc = gql`
    fragment PictureAssetsSection on Picture {
  __typename
  id
  legacyId
  assetAssignments {
    id
    asset {
      __typename
      id
      legacyId
      name
      state
      galleryImage {
        id
      }
      materials {
        id
        state
        galleryImage {
          id
        }
      }
      model {
        state
        galleryImage {
          id
        }
      }
      organization {
        name
        id
        legacyId
      }
    }
  }
}
    `;
export const PictureAssetsSectionDocument = gql`
    query pictureAssetsSection($id: ID!) {
  item: picture(id: $id) {
    ...PictureAssetsSection
  }
}
    ${PictureAssetsSectionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureAssetsSectionGQL extends Apollo.Query<PictureAssetsSectionQuery, PictureAssetsSectionQueryVariables> {
    override document = PictureAssetsSectionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }