import {ElementRef, inject, Injectable} from "@angular/core"
import {ActivatedRoute, Router} from "@angular/router"
import {PermissionsService} from "@app/common/services/permissions/permissions.service"
import {
    BatchMaterialOutputFragment,
    DataObjectDetailsForBatchDownloadServiceFragment as DataObjectDetailsFragment,
    GetBatchMaterialWithOutputGQL,
    ImageDownloadDetailsForBatchDownloadServiceFragment as ImageDownloadDetailsFragment,
    MaterialBatchOperationFragment,
} from "@app/platform/services/material/material-batch-operation.generated"
import {MaterialMapsExporter} from "@cm/material-nodes/material-maps-exporter"
import {DownloadResolution, mapDownloadResolutionToImageResolution} from "@cm/utils/data-object"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {getDefaultExportRequests} from "@common/helpers/material-maps-exporter"
import {BatchDownloadData, BatchMenuItem, ExtraBatchActionItem} from "@common/models/item/list-item"
import {MaterialAssetsRenderingService} from "@common/services/material-assets-rendering/material-assets-rendering.service"
import {MaterialMapsExporterService} from "@common/services/material-maps-exporter/material-maps-exporter.service"
import {NameAssetFromSchemaService} from "@common/services/name-asset-from-schema/name-asset-from-schema.service"
import {BasicTagInfoFragment} from "@common/services/tags/tags.generated"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {DataObjectAssignmentType, MaterialOutputType} from "@generated"
import {FlatThumbnailOptionLabels} from "@labels"
import {
    MaterialsGridDeleteThumbnailGQL,
    MaterialsGridItemFragment,
    MaterialsGridUpdateMaterialGQL,
} from "@platform/components/materials/materials-grid/materials-grid.generated"
import {
    GetImageDownloadDetailsForBatchDownloadServiceGQL,
    MaterialBatchOperationMaterialWithMaterialRangeTagsGQL,
    GetPbrFilenameForBatchDownloadServiceGQL,
} from "@platform/services/material/material-batch-operation.generated"
import {MaterialOutputService} from "@platform/services/material/material-output.service"

type GetBatchOperationsItems<T> = {create: T; delete: T; name?: T}

@Injectable({
    providedIn: "root",
})
export class MaterialBatchOperationService {
    readonly router = inject(Router)
    readonly route = inject(ActivatedRoute)
    readonly permission = inject(PermissionsService)
    readonly materialOutputService = inject(MaterialOutputService)
    $can = this.permission.$to

    readonly materialWithOutputGql = inject(GetBatchMaterialWithOutputGQL)
    readonly deleteThumbnailGql = inject(MaterialsGridDeleteThumbnailGQL)
    readonly materialWithRangeTagsGql = inject(MaterialBatchOperationMaterialWithMaterialRangeTagsGQL)
    readonly updateMaterialGql = inject(MaterialsGridUpdateMaterialGQL)
    readonly imageDownloadDetailsGql = inject(GetImageDownloadDetailsForBatchDownloadServiceGQL)
    readonly pbrFilenameGql = inject(GetPbrFilenameForBatchDownloadServiceGQL)

    public constructor(
        protected uploadService: UploadGqlService,
        protected matAssetsRenderingSvc: MaterialAssetsRenderingService,
        protected matMapsExporterSvc: MaterialMapsExporterService,
        protected nameAssetFromSchemaSvc: NameAssetFromSchemaService,
    ) {}

    private getMaterialWithOutput = async (
        materialListItem: MaterialBatchOperationFragment,
        materialOutputType: MaterialOutputType,
    ): Promise<BatchMaterialOutputFragment> => {
        const {material} = await fetchThrowingErrors(this.materialWithOutputGql)({
            id: materialListItem.id,
            materialOutputType,
        })

        return material
    }

    getExtraBatchActions = (): ExtraBatchActionItem<MaterialsGridItemFragment>[] => {
        const result: ExtraBatchActionItem<MaterialsGridItemFragment>[] = []

        if (this.$can().update.material(undefined, "batch")) {
            result.push({
                groupLabel: "Advanced",
                items: this.getAdvancedBatchItems(),
            })
        }

        const thumbnailBatchItems = this.getThumbnailBatchItems()
        const tileableBatchItems = this.getTileableBatchItems()
        const exportBatchItems = this.getExportBatchItems()

        if (this.$can().read.menu("batchStartMaterialImageJobs")) {
            result.push({
                groupLabel: "Generate",
                items: [
                    {...tileableBatchItems.create, label: "Tileable Image"},
                    {groupLabel: "Flat Thumbnails", items: thumbnailBatchItems.create},
                    {groupLabel: "PBR Exports", items: exportBatchItems.create},
                ],
            })

            result.push({
                groupLabel: "Delete",
                items: [
                    {...tileableBatchItems.delete, label: "Tileable Image"},
                    {groupLabel: "Flat Thumbnails", items: thumbnailBatchItems.delete},
                    {groupLabel: "PBR Exports", items: exportBatchItems.delete},
                ],
            })

            result.push({
                groupLabel: "Name",
                items: [
                    ...(tileableBatchItems.name ? [{...tileableBatchItems.name, label: "Tileable Image"}] : []),
                    ...(thumbnailBatchItems.name ? [{groupLabel: "Flat Thumbnails", items: thumbnailBatchItems.name}] : []),
                ],
            })
        }

        return result
    }

    getAdvancedActions = (fileInput: ElementRef) => {
        if (!this.$can().update.material(undefined, "batch")) return []
        return [
            {
                label: "Batch create materials",
                operation: () => {
                    fileInput.nativeElement.click()
                },
            },
        ]
    }

    private getThumbnailBatchItems = (): GetBatchOperationsItems<BatchMenuItem<MaterialBatchOperationFragment>["items"]> => {
        const createThumbnails: BatchMenuItem<MaterialBatchOperationFragment>["items"] = Array.from(FlatThumbnailOptionLabels.values()).map((flatOption) => ({
            label: flatOption.label,
            operation: async (materialListItem) => {
                const material = await this.getMaterialWithOutput(materialListItem, flatOption.state.materialOutputType)
                if (!material?.latestCyclesRevision || material.outputForType) {
                    return false
                }

                await this.materialOutputService.createOutput({
                    materialId: materialListItem.id,
                    materialRevisionId: material.latestCyclesRevision.id,
                    showNotification: false,
                    type: flatOption.state.materialOutputType,
                })

                return true
            },
            tooltip: `Resolution: ${flatOption.state.resolution} x ${flatOption.state.resolution} px`,
        }))

        const deleteThumbnails: BatchMenuItem<MaterialBatchOperationFragment>["items"] = Array.from(FlatThumbnailOptionLabels.values()).map((flatOption) => ({
            label: `Delete ${flatOption.label}`,
            operation: async (materialListItem) => {
                const material = await this.getMaterialWithOutput(materialListItem, flatOption.state.materialOutputType)
                if (!material.outputForType) {
                    return false
                }
                await this.materialOutputService.cancelAndRemoveOutput({materialOutput: material.outputForType, showNotification: false})
                return true
            },
        }))

        const nameThumbnails: BatchMenuItem<MaterialBatchOperationFragment>["items"] = Array.from(FlatThumbnailOptionLabels.values()).map((flatOption) => ({
            label: `Name ${flatOption.label} from schema`,
            operation: async (materialListItem) => {
                const material = await this.getMaterialWithOutput(materialListItem, flatOption.state.materialOutputType)
                if (!material.outputForType || !material.outputForType.result?.id) {
                    return false
                }
                await this.nameAssetFromSchemaSvc.renameRenderFromSchema(materialListItem.id, flatOption.state.dataObjectAssignmentType)
                return true
            },
        }))

        return {
            create: createThumbnails,
            delete: deleteThumbnails,
            name: nameThumbnails,
        }
    }

    private getTileableBatchItems = (): GetBatchOperationsItems<BatchMenuItem<MaterialBatchOperationFragment>["items"][0]> => {
        return {
            create: {
                label: "Create Tileable",
                operation: async (materialListItem) => {
                    const material = await this.getMaterialWithOutput(materialListItem, MaterialOutputType.TileableImage)
                    if (!material?.latestCyclesRevision || material.outputForType) {
                        return false
                    }
                    await this.materialOutputService.createOutput({
                        materialId: materialListItem.id,
                        materialRevisionId: material.latestCyclesRevision.id,
                        showNotification: false,
                        type: MaterialOutputType.TileableImage,
                    })
                    return true
                },
            },
            delete: {
                label: "Delete tileable",
                operation: async (materialListItem) => {
                    const material = await this.getMaterialWithOutput(materialListItem, MaterialOutputType.TileableImage)
                    if (!material.outputForType) {
                        return false
                    }
                    await this.materialOutputService.cancelAndRemoveOutput({materialOutput: material.outputForType, showNotification: false})
                    return true
                },
            },
            name: {
                label: "Name tileable from schema",
                operation: async (materialListItem) => {
                    const material = await this.getMaterialWithOutput(materialListItem, MaterialOutputType.TileableImage)
                    if (!material.outputForType || !material.outputForType.result?.id) {
                        return false
                    }
                    await this.nameAssetFromSchemaSvc.renameRenderFromSchema(materialListItem.id, DataObjectAssignmentType.MaterialTileableRender)
                    return true
                },
            },
        }
    }

    private getExportBatchItems = (): GetBatchOperationsItems<BatchMenuItem<MaterialBatchOperationFragment>["items"]> => {
        const createExport: BatchMenuItem<MaterialBatchOperationFragment>["items"] = getDefaultExportRequests().map((exportRequest) => ({
            label: exportRequest.displayName,
            operation: async (materialListItem) => {
                const material = await this.getMaterialWithOutput(materialListItem, exportRequest.materialOutputType)
                if (!material.latestCyclesRevision?.id || material.outputForType) {
                    // There already exist outputs of this type
                    return false
                }
                await this.materialOutputService.createOutput({
                    materialId: materialListItem.id,
                    materialRevisionId: material.latestCyclesRevision.id,
                    showNotification: false,
                    type: exportRequest.materialOutputType,
                    exportRequest: exportRequest,
                })
                return true
            },
        }))

        const deleteExport: BatchMenuItem<MaterialBatchOperationFragment>["items"] = getDefaultExportRequests().map((exportRequest) => ({
            label: `Delete ${exportRequest.displayName}`,
            operation: async (materialListItem) => {
                const material = await this.getMaterialWithOutput(materialListItem, exportRequest.materialOutputType)
                if (!material.outputForType) {
                    return false
                }
                await this.materialOutputService.cancelAndRemoveOutput({materialOutput: material.outputForType, showNotification: false})
                return true
            },
        }))

        return {
            create: createExport,
            delete: deleteExport,
        }
    }

    private getAdvancedBatchItems = (): BatchMenuItem<MaterialBatchOperationFragment>["items"] => {
        const setArticleId = async (materialListItem: MaterialBatchOperationFragment, test: boolean) => {
            const {
                material: {tagAssignments: materialRangeTagAssignments},
            } = await fetchThrowingErrors(this.materialWithRangeTagsGql)({legacyId: materialListItem.legacyId})
            const materialRangeTag: BasicTagInfoFragment | undefined = materialRangeTagAssignments?.[0]?.tag

            const regex = /\b\d{3}\b/
            const filteredText = materialListItem.name?.match(regex)
            if (materialRangeTag && materialRangeTag.description && filteredText?.length == 1) {
                if (test) {
                    return false
                }
                await mutateThrowingErrors(this.updateMaterialGql)({
                    input: {id: materialListItem.id, articleId: materialRangeTag.description + filteredText[0]},
                })
                return true
            } else {
                console.log(`${materialListItem.legacyId} -> Article ID could not be determined`)
                return false
            }
        }
        return [
            {
                label: "Fill article ID (test)",
                operation: async (item) => setArticleId(item, true),
            },
            {
                label: "Fill article ID",
                operation: async (item) => setArticleId(item, false),
            },
        ]
    }

    private getBatchDownloadDetail = async (
        materialListItem: MaterialBatchOperationFragment,
        dataObjectAssignment: DataObjectAssignmentType,
    ): Promise<ImageDownloadDetailsFragment> => {
        const material = (
            await fetchThrowingErrors(this.imageDownloadDetailsGql)({
                materialId: materialListItem.id,
                assignmentType: dataObjectAssignment,
            })
        ).material

        if (material.dataObjectAssignments.length === 0) throw new Error(`No ${dataObjectAssignment} found for material ${materialListItem.id}`)
        if (material.dataObjectAssignments.length > 1) console.warn(`Multiple ${dataObjectAssignment} assignments found for material ${materialListItem.id}`)

        return material.dataObjectAssignments[0].dataObject
    }

    private makeDownloadItem = async (materialDownloadData: DataObjectDetailsFragment | undefined | null) => {
        if (!materialDownloadData) throw new Error("No data for download found")
        const path = materialDownloadData.originalFileName
        return [{path: path, dataObjectLegacyId: materialDownloadData.legacyId}]
    }

    getBatchDownloadData = (): BatchDownloadData<MaterialBatchOperationFragment>[] => {
        if (!this.$can().read.menu("batchDownloadMaterials")) return []

        const tileableImageJpegExports: BatchDownloadData<MaterialBatchOperationFragment>[] = Object.values(DownloadResolution).map((resolution) => {
            return {
                pathInMenu: `Tileable Image/JPEG/${mapDownloadResolutionToImageResolution(resolution)}`,
                getDataObjectsForDownload: async (materialListItem: MaterialBatchOperationFragment) => {
                    const batchDownloadDetails = await this.getBatchDownloadDetail(materialListItem, DataObjectAssignmentType.MaterialTileableRender)
                    if (batchDownloadDetails.mediaType === "application/zip") throw new Error("No jpeg data for download found")
                    return this.makeDownloadItem(batchDownloadDetails[`jpeg${resolution}`])
                },
            }
        })

        const tileableImageTiffExport: BatchDownloadData<MaterialBatchOperationFragment> = {
            pathInMenu: "Tileable Image/TIFF",
            getDataObjectsForDownload: async (materialListItem: MaterialBatchOperationFragment) => {
                const batchDownloadDetails = await this.getBatchDownloadDetail(materialListItem, DataObjectAssignmentType.MaterialTileableRender)
                if (batchDownloadDetails.mediaType === "application/zip") throw new Error("No tiff data for download found")
                return this.makeDownloadItem(batchDownloadDetails.tiff)
            },
        }

        const thumbnailExports: BatchDownloadData<MaterialBatchOperationFragment>[] = Array.from(FlatThumbnailOptionLabels.values()).flatMap((flatOption) => {
            const jpegPaths = Object.values(DownloadResolution).map((resolution) => ({
                pathInMenu: `Flat Thumbnails/${flatOption.label}/JPEG/${mapDownloadResolutionToImageResolution(resolution)}`,
                getDataObjectsForDownload: async (materialListItem: MaterialBatchOperationFragment) => {
                    const batchDownloadDetails = await this.getBatchDownloadDetail(materialListItem, flatOption.state.dataObjectAssignmentType)
                    return this.makeDownloadItem(batchDownloadDetails[`jpeg${resolution}`])
                },
            }))

            const tiffPath = {
                pathInMenu: `Flat Thumbnails/${flatOption.label}/TIFF`,
                getDataObjectsForDownload: async (materialListItem: MaterialBatchOperationFragment) => {
                    const batchDownloadDetails = await this.getBatchDownloadDetail(materialListItem, flatOption.state.dataObjectAssignmentType)
                    return this.makeDownloadItem(batchDownloadDetails.tiff)
                },
            }

            return [...jpegPaths, tiffPath]
        })

        const pbrExports: BatchDownloadData<MaterialBatchOperationFragment>[] = getDefaultExportRequests().reduce((acc, exportRequest) => {
            const targetFormats: MaterialMapsExporter.Format[] = ["jpeg", "tiff"]
            if (targetFormats.some((format) => exportRequest.root.name.includes(format))) {
                const config = {
                    pathInMenu: "PBR Exports/" + exportRequest.displayName,
                    getDataObjectsForDownload: async (
                        materialListItem: MaterialBatchOperationFragment,
                    ): Promise<{path: string; dataObjectLegacyId: number}[]> => {
                        const allExports = await this.matMapsExporterSvc.queryMapsExportsForMaterial({legacyId: materialListItem.legacyId})
                        const targetExport = allExports.find((x) => x.config.root.name === exportRequest.root.name)
                        if (!targetExport || !targetExport.dataObjectId) throw new Error("Data not found for pbr export")
                        const filename = (await fetchThrowingErrors(this.pbrFilenameGql)({dataObjectLegacyId: targetExport.dataObjectId})).dataObject
                            .originalFileName

                        const path = filename + ".zip"
                        return [{path: path, dataObjectLegacyId: targetExport.dataObjectId}]
                    },
                }
                acc.push(config)
            }
            return acc
        }, [] as BatchDownloadData<MaterialBatchOperationFragment>[])

        return [...tileableImageJpegExports, tileableImageTiffExport, ...thumbnailExports, ...pbrExports]
    }
}
