import {OperatorToolboxBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-toolbox-base"
import {OperatorCloneStamp} from "app/textures/texture-editor/operator-stack/operators/clone-stamp/operator-clone-stamp"
import {Color, Vector2} from "@cm/math"
import {CrosshairToolboxItem} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/crosshair-toolbox-item"
import {SelectCloneSourceToolboxItem} from "app/textures/texture-editor/operator-stack/operators/clone-stamp/toolbox/select-clone-source-toolbox-item"
import {DrawableImageBrushToolboxItem} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/drawable-image-brush-toolbox-item"
import * as paper from "paper"
import {isApple} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {SelectionMode} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {EventEmitter} from "@angular/core"

export class CloneStampToolbox extends OperatorToolboxBase<OperatorCloneStamp> {
    readonly modeChanged = new EventEmitter<Mode>()

    constructor(operator: OperatorCloneStamp) {
        super(operator, SelectionMode.None, true)

        this.brushToolboxItem = new DrawableImageBrushToolboxItem(this, operator.callback.imageOpContextWebGL2)
        this.brushToolboxItem.brushSettings = operator.brushSettings
        this.brushToolboxItem.brushStrokeUpdated.subscribe(() => this.onBrushStrokeUpdated())
        this.brushToolboxItem.brushStrokeCompleted.subscribe(() => this.onBrushStrokeCompleted())

        this.selectCloneSourceToolboxItem = new SelectCloneSourceToolboxItem(this)

        this.mappedBrushIndicatorToolboxItem = new CrosshairToolboxItem(this)
        this.mappedBrushIndicatorToolboxItem.crosshairColor = new Color(1, 0, 0)
        this.mappedBrushIndicatorToolboxItem.visible = false

        this.mode = "brush"
    }

    get isStrokeInProgress(): boolean {
        return this.brushToolboxItem.isDrawing
    }

    set cloneSourcePosition(value: Vector2) {
        this.selectCloneSourceToolboxItem.cloneSourcePosition = value
    }

    get cloneSourcePosition(): Vector2 {
        return this.selectCloneSourceToolboxItem.cloneSourcePosition
    }

    get sourceOffset(): Vector2 {
        const brushStartPosition = this.brushToolboxItem.brushStartPosition
        if (!brushStartPosition) {
            throw new Error("Brush start position is not set")
        }
        return this.cloneSourcePosition.sub(brushStartPosition)
    }

    set mode(value: Mode) {
        if (this._mode === value) {
            return
        }
        this._mode = value
        this.brushToolboxItem.selected = value === "brush"
        this.selectCloneSourceToolboxItem.selected = value === "select-source"
        this.modeChanged.emit(value)
    }

    get mode(): Mode {
        return this._mode
    }

    override onKeyDown(event: KeyboardEvent): boolean {
        if (event.key === this.selectSourceKey) {
            this.mode = "select-source"
        }
        return super.onKeyDown(event)
    }

    override onKeyUp(event: KeyboardEvent): boolean {
        if (event.key === this.selectSourceKey) {
            this.mode = "brush"
        }
        return super.onKeyUp(event)
    }

    private onBrushStrokeUpdated(): void {
        const brushStartPosition = this.brushToolboxItem.brushStartPosition
        const brushCurrentPosition = this.brushToolboxItem.brushCurrentPosition
        if (brushStartPosition && brushCurrentPosition) {
            this.mappedBrushIndicatorToolboxItem.crosshairPosition = this.cloneSourcePosition
                .add(brushCurrentPosition.sub(brushStartPosition))
                .add(new paper.Point(0.5, 0.5))
            this.mappedBrushIndicatorToolboxItem.visible = true
        }
        this.operator.markEdited()
        this.operator.requestEval()
    }

    private async onBrushStrokeCompleted(): Promise<void> {
        this.mappedBrushIndicatorToolboxItem.visible = false
        await this.operator.addCloneStampEdit()
    }

    readonly brushToolboxItem: DrawableImageBrushToolboxItem

    private _mode: Mode = "none"
    private selectCloneSourceToolboxItem: SelectCloneSourceToolboxItem
    private mappedBrushIndicatorToolboxItem: CrosshairToolboxItem
    private readonly selectSourceKey = isApple ? "Meta" : "Alt"
}

export type Mode = "none" | "brush" | "select-source"
