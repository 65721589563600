import {Component, viewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {ValToRgbInputs, ValToRgbOutputs} from "@material-editor/models/nodes/val-to-rgb"

@Component({
    selector: "cm-val-to-rgb-node",
    templateUrl: "./val-to-rgb.component.html",
    styleUrls: ["./val-to-rgb.component.scss"],
    imports: [NodeBaseComponent],
})
export class ValToRgbNodeComponent {
    readonly $nodeBase = viewChild<MaterialNodeBase>("nodeBase")
    inputs = ValToRgbInputs
    outputs = ValToRgbOutputs
    typeInfo = ValToRgbNodeType
}

export const ValToRgbNodeType: MaterialNodeType<typeof ValToRgbNodeComponent> = {
    id: "valToRgb",
    label: "Val to RGB",
    color: "#4987af",
    name: "ShaderNodeValToRGB",
    inputs: [ValToRgbInputs.color, ValToRgbInputs.alpha, ValToRgbInputs.fac] as never[],
    outputs: [ValToRgbOutputs.color],
    component: ValToRgbNodeComponent,
}
