import {Component, inject} from "@angular/core"
import {RouterModule} from "@angular/router"
import {ContentTypeModel, MutationCreateDataObjectInput, MutationUpdateDataObjectInput} from "@generated"
import {FilesGridDataObjectFragment} from "@app/platform/components/files/files-grid/files-grid.generated"
import {CardErrorComponent} from "@common/components/cards/card-error/card-error.component"
import {CardPlaceholderComponent} from "@common/components/cards/card-placeholder/card-placeholder.component"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {ItemListComponent} from "@common/components/item/item-list/item-list.component"
import {ListInfoComponent} from "@common/components/item/list-info/list-info.component"
import {InfiniteListComponent} from "@common/components/lists/infinite-list/infinite-list.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {FilesGridCreateDataObjectGQL, FilesGridItemsGQL} from "@platform/components/files/files-grid/files-grid.generated"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {MemoizePipe} from "@app/common/pipes/memoize/memoize.pipe"

@Component({
    imports: [
        CardErrorComponent,
        InfiniteListComponent,
        ListInfoComponent,
        CardPlaceholderComponent,
        EntityCardComponent,
        StateLabelComponent,
        RouterModule,
        MemoizePipe,
    ],
    selector: "cm-files-grid",
    styleUrls: ["./files-grid.component.scss"],
    templateUrl: "./files-grid.component.html",
})
export class FilesGridComponent extends ItemListComponent<FilesGridDataObjectFragment, MutationUpdateDataObjectInput, MutationCreateDataObjectInput> {
    protected override _contentTypeModel = ContentTypeModel.DataObject
    readonly createDataObjectGql = inject(FilesGridCreateDataObjectGQL)
    protected override _createItem = (data: MutationCreateDataObjectInput) =>
        mutateThrowingErrors(this.createDataObjectGql)({
            input: data,
        }).then(({createDataObject: dataObject}) => dataObject)

    readonly itemsGql = inject(FilesGridItemsGQL)
    protected override _fetchList = ({skip, take}: {skip: number; take: number}) =>
        fetchThrowingErrors(this.itemsGql)({
            take: take,
            skip: skip,
            filter: this.filters.dataObjectFilter(),
        }).then(({dataObjects, dataObjectsCount}) => ({items: dataObjects, totalCount: dataObjectsCount}))

    protected override _refreshItem = ({id, legacyId}: {id?: string; legacyId?: number}) =>
        fetchThrowingErrors(this.itemsGql)({
            take: 1,
            filter: {
                ...this.filters.dataObjectFilter(),
                id: id ? {equals: id} : undefined,
                legacyId: legacyId ? {equals: legacyId} : undefined,
            },
        }).then(({dataObjects}) => dataObjects?.[0] || undefined)

    provideWithGalleryImageField = (item: FilesGridDataObjectFragment) => {
        return {
            ...item,
            galleryImage: {
                id: item.id,
            },
        }
    }
}
