import {Component} from "@angular/core"
import {MatCardModule} from "@angular/material/card"
import {MatIconModule} from "@angular/material/icon"

@Component({
    selector: "cm-page-not-found",
    templateUrl: "page-not-found.component.html",
    styleUrls: ["page-not-found.component.scss"],
    imports: [MatCardModule, MatIconModule],
})
export class PageNotFoundComponent {
    constructor() {}
}
