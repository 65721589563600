import {Observable, Subject} from "rxjs"
import {v4 as uuid4} from "uuid"

export enum BackgroundOperationState {
    Waiting = 1,
    InProgress = 2,
    Completed = 3,
    Cancelled = 4,
    Error = 5,
}

export class BackgroundOperationItem {
    trackId: string = uuid4()
    taskType: string
    description: string
    progress: number
    cancellable = true
    state: BackgroundOperationState
    progressSubject = new Subject<number>()
    progressObservable: Observable<number> = this.progressSubject.asObservable()
    resultUrl: string = ""
    resultTitle: string = ""
    errorCause: string = ""

    constructor(
        taskType: string,
        description: string,
        cancellable: boolean,
        public discardOnCompletion = false,
    ) {
        this.taskType = taskType
        this.description = description
        this.cancellable = cancellable
        this.progress = 0
        this.state = BackgroundOperationState.Waiting
    }

    abort?: () => void

    cancel(): void {
        if (!this.cancellable) throw new Error("Operation is not cancellable")
        if (!this.abort) throw new Error("Operation does not have an abort function")
        this.abort()
        this.state = BackgroundOperationState.Cancelled
    }
}
