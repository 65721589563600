import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetOrganizationListItemsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OrganizationFilterInput>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetOrganizationListItemsQuery = { __typename: 'Query', organizationsCount: number, organizations: Array<{ __typename: 'Organization', id: string, legacyId: number, name?: string | null, url?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export type OrganizationListItemFragment = { __typename: 'Organization', id: string, legacyId: number, name?: string | null, url?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type FindOrganizationQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type FindOrganizationQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null, url?: string | null, visibleInFilters?: boolean | null, salesEmail?: string | null, defaultScannerUrl?: string | null, type?: Types.OrganizationType | null, galleryImage?: { __typename: 'DataObject', id: string } | null, nodeEditorTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerMaterial?: { __typename: 'Material', legacyId: number } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: Types.DataObjectAssignmentType, dataObject: { __typename: 'DataObject', state: Types.DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } }> } };

export type GetOrganizationDetailsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OrganizationFilterInput>;
}>;


export type GetOrganizationDetailsQuery = { __typename: 'Query', organizations: Array<{ __typename: 'Organization', id: string, legacyId: number, name?: string | null, url?: string | null, visibleInFilters?: boolean | null, salesEmail?: string | null, defaultScannerUrl?: string | null, type?: Types.OrganizationType | null, galleryImage?: { __typename: 'DataObject', id: string } | null, nodeEditorTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerMaterial?: { __typename: 'Material', legacyId: number } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: Types.DataObjectAssignmentType, dataObject: { __typename: 'DataObject', state: Types.DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } }> } | null> };

export type CreateOrganizationMutationVariables = Types.Exact<{
  input: Types.MutationCreateOrganizationInput;
}>;


export type CreateOrganizationMutation = { __typename: 'Mutation', createOrganization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null, url?: string | null, visibleInFilters?: boolean | null, salesEmail?: string | null, defaultScannerUrl?: string | null, type?: Types.OrganizationType | null, galleryImage?: { __typename: 'DataObject', id: string } | null, nodeEditorTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerMaterial?: { __typename: 'Material', legacyId: number } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: Types.DataObjectAssignmentType, dataObject: { __typename: 'DataObject', state: Types.DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } }> } };

export type UpdateOrganizationMutationVariables = Types.Exact<{
  input: Types.MutationUpdateOrganizationInput;
}>;


export type UpdateOrganizationMutation = { __typename: 'Mutation', item: { __typename: 'Organization', id: string, legacyId: number, name?: string | null, url?: string | null, visibleInFilters?: boolean | null, salesEmail?: string | null, defaultScannerUrl?: string | null, type?: Types.OrganizationType | null, galleryImage?: { __typename: 'DataObject', id: string } | null, nodeEditorTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerMaterial?: { __typename: 'Material', legacyId: number } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: Types.DataObjectAssignmentType, dataObject: { __typename: 'DataObject', state: Types.DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } }> } };

export type DeleteOrganizationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteOrganizationMutation = { __typename: 'Mutation', deleteOrganization?: any | null };

export type OrganizationDetailsFragment = { __typename: 'Organization', id: string, legacyId: number, name?: string | null, url?: string | null, visibleInFilters?: boolean | null, salesEmail?: string | null, defaultScannerUrl?: string | null, type?: Types.OrganizationType | null, galleryImage?: { __typename: 'DataObject', id: string } | null, nodeEditorTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerTemplate?: { __typename: 'Template', legacyId: number } | null, matExplorerMaterial?: { __typename: 'Material', legacyId: number } | null, dataObjectAssignments: Array<{ __typename: 'DataObjectAssignment', id: string, assignmentType: Types.DataObjectAssignmentType, dataObject: { __typename: 'DataObject', state: Types.DataObjectState, bucketName: string, objectName: string, mediaType?: string | null } }> };

export const OrganizationListItemFragmentDoc = gql`
    fragment OrganizationListItem on Organization {
  id
  legacyId
  name
  url
  galleryImage {
    id
  }
}
    `;
export const OrganizationDetailsFragmentDoc = gql`
    fragment OrganizationDetails on Organization {
  __typename
  id
  legacyId
  name
  url
  visibleInFilters
  salesEmail
  defaultScannerUrl
  type
  galleryImage {
    id
  }
  nodeEditorTemplate {
    legacyId
  }
  matExplorerTemplate {
    legacyId
  }
  matExplorerMaterial {
    legacyId
  }
  dataObjectAssignments {
    id
    assignmentType
    dataObject {
      state
      bucketName
      objectName
      mediaType
    }
  }
}
    `;
export const GetOrganizationListItemsDocument = gql`
    query getOrganizationListItems($filter: OrganizationFilterInput, $skip: Int, $take: Int) {
  organizations(filter: $filter, skip: $skip, take: $take) {
    ...OrganizationListItem
  }
  organizationsCount(filter: $filter)
}
    ${OrganizationListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOrganizationListItemsGQL extends Apollo.Query<GetOrganizationListItemsQuery, GetOrganizationListItemsQueryVariables> {
    override document = GetOrganizationListItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindOrganizationDocument = gql`
    query findOrganization($id: ID, $legacyId: Int) {
  organization(id: $id, legacyId: $legacyId) {
    ...OrganizationDetails
  }
}
    ${OrganizationDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FindOrganizationGQL extends Apollo.Query<FindOrganizationQuery, FindOrganizationQueryVariables> {
    override document = FindOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetOrganizationDetailsDocument = gql`
    query getOrganizationDetails($filter: OrganizationFilterInput) {
  organizations(filter: $filter) {
    ...OrganizationDetails
  }
}
    ${OrganizationDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOrganizationDetailsGQL extends Apollo.Query<GetOrganizationDetailsQuery, GetOrganizationDetailsQueryVariables> {
    override document = GetOrganizationDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOrganizationDocument = gql`
    mutation createOrganization($input: MutationCreateOrganizationInput!) {
  createOrganization(input: $input) {
    ...OrganizationDetails
  }
}
    ${OrganizationDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOrganizationGQL extends Apollo.Mutation<CreateOrganizationMutation, CreateOrganizationMutationVariables> {
    override document = CreateOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOrganizationDocument = gql`
    mutation updateOrganization($input: MutationUpdateOrganizationInput!) {
  item: updateOrganization(input: $input) {
    ...OrganizationDetails
  }
}
    ${OrganizationDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrganizationGQL extends Apollo.Mutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables> {
    override document = UpdateOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOrganizationDocument = gql`
    mutation deleteOrganization($id: ID!) {
  deleteOrganization(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOrganizationGQL extends Apollo.Mutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables> {
    override document = DeleteOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }