import {Component, inject, Injector} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatDialogModule} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatMenuModule} from "@angular/material/menu"
import {MatSelectModule} from "@angular/material/select"
import {RouterModule} from "@angular/router"
import {ModelListItemFragment, GetModelsQuery, GetModelsGQL, GetModelsQueryVariables} from "@app/platform/components/models/models-grid/models-grid.generated"
import {CardErrorComponent, CardPlaceholderComponent} from "@common/components/cards"
import {CommentBoxesComponent} from "@common/components/comment-boxes/comment-boxes.component"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {ItemListComponent, ListInfoComponent} from "@common/components/item"
import {InfiniteListComponent} from "@common/components/lists"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {BatchUpdateProperty} from "@common/models/item/list-item"
import {Settings} from "@common/models/settings/settings"
import {
    ContentTypeModel,
    ModelOrderByCriteria,
    ModelState,
    ModelType,
    MutationCreateModelInput,
    MutationUpdateModelInput,
    NextActor,
    PaymentState,
    SortOrder,
    TagType,
} from "@generated"
import {Labels, StateLabel} from "@labels"
import {AddModelDialogComponent} from "@platform/components/models/add-model-dialog/add-model-dialog.component"
import {BatchUpdateModelsGQL, CreateModelGQL, UpdateModelGQL} from "@platform/components/models/models-grid/models-grid.generated"
import {PaymentStateLabelComponent} from "@platform/components/shared/payment-state-label/payment-state-label.component"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {TagLabelsComponent} from "@platform/components/tags/tag-labels/tag-labels.component"
import {AssignUserDialogComponent} from "@platform/components/users/assign-user-dialog/assign-user-dialog.component"
import {AssignUserComponent} from "@platform/components/users/assign-user/assign-user.component"
import {CancellableRequest} from "@platform/models/data/cancellable-request"

@Component({
    imports: [
        CardErrorComponent,
        InfiniteListComponent,
        ListInfoComponent,
        EntityCardComponent,
        TagLabelsComponent,
        StateLabelComponent,
        AssignUserComponent,
        CommentBoxesComponent,
        CardPlaceholderComponent,
        MatMenuModule,
        RouterModule,
        RouterModule,
        AssignUserDialogComponent,
        MatDialogModule,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        MatButtonModule,
        AddModelDialogComponent,
        PaymentStateLabelComponent,
    ],
    selector: "cm-models-grid",
    styleUrls: ["models-grid.component.scss"],
    templateUrl: "models-grid.component.html",
})
export class ModelsGridComponent extends ItemListComponent<ModelListItemFragment, MutationUpdateModelInput, Partial<MutationCreateModelInput>> {
    public stateLabels: StateLabel<ModelState>[] = Array.from(Labels.ModelState.values())

    readonly modelsGql = inject(GetModelsGQL)
    readonly injector = inject(Injector)

    private fetchRequest = new CancellableRequest<GetModelsQuery, GetModelsQueryVariables>(this.modelsGql, this.injector, this.destroyRef)

    // OVERLOADS

    protected override _contentTypeModel = ContentTypeModel.Model
    readonly batchUpdateModelsGql = inject(BatchUpdateModelsGQL)
    protected override _batchUpdate = (property: BatchUpdateProperty, value: string | boolean) =>
        mutateThrowingErrors(this.batchUpdateModelsGql)({
            filter: this.filters.modelFilter() ?? {},
            [property]: value,
        }).then(({batchUpdateModels: count}) => count)

    readonly createModelGql = inject(CreateModelGQL)
    protected override _createItem = (data: Partial<MutationCreateModelInput>) =>
        mutateThrowingErrors(this.createModelGql)({
            input: {
                ...data,
                comment: Settings.defaultCommentField,
                state: ModelState.Draft,
                paymentState: PaymentState.OrderPlaced,
                nextActor: this.auth.$actingAsCustomer() ? NextActor.Customer : NextActor.Team1,
                type: ModelType.Product,
            },
        }).then(({createModel: model}) => model)

    protected override _fetchList = ({skip, take}: {skip: number; take: number}) =>
        this.fetchRequest
            .fetch({
                take: take,
                skip: skip,
                filter: this.filters.modelFilter(),
                orderBy: [
                    {key: ModelOrderByCriteria.Priority, direction: SortOrder.Asc},
                    {key: ModelOrderByCriteria.Id, direction: SortOrder.Desc},
                ],
            })
            .then(({models, modelsCount}) => ({items: models, totalCount: modelsCount}))

    protected override _initialNewItemData = () => ({
        organizationId: this.organizations.$current()?.id,
        nextActor: this.auth.$actingAsCustomer() ? NextActor.Customer : NextActor.Team1,
        paymentState: PaymentState.OrderPlaced,
        state: ModelState.Draft,
    })

    protected override _refreshItem = ({id, legacyId}: {id?: string; legacyId?: number}) =>
        fetchThrowingErrors(this.modelsGql)({
            take: 1,
            filter: {
                ...this.filters.modelFilter(),
                id: id ? {equals: id} : undefined,
                legacyId: legacyId ? {equals: legacyId} : undefined,
            },
        }).then(({models}) => models?.[0] || undefined)

    readonly updateModelGql = inject(UpdateModelGQL)
    protected override _updateItem = (data: MutationUpdateModelInput) => {
        return mutateThrowingErrors(this.updateModelGql)({
            input: data,
        }).then(({updateModel: model}) => model)
    }

    // CONVENIENCE METHODS

    public getTags(model: ModelListItemFragment, tagType?: TagType) {
        return model.tagAssignments.filter((assignment) => (tagType ? assignment.tag.type === tagType : true)).map(({tag}) => tag)
    }
}
