import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PictureRevisionSelectorPictureFragment = { __typename: 'Picture', id: string, state: Types.PictureState, latestRevision?: { __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, firstName?: string | null, name: string } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string } }> } | null, organization: { __typename: 'Organization', id: string }, revisions: Array<{ __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, firstName?: string | null, name: string } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string } }> }> };

export type PictureRevisionSelectorRevisionFragment = { __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, firstName?: string | null, name: string } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string } }> };

export type PictureRevisionSelectorItemQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type PictureRevisionSelectorItemQuery = { __typename: 'Query', item: { __typename: 'Picture', id: string, state: Types.PictureState, latestRevision?: { __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, firstName?: string | null, name: string } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string } }> } | null, organization: { __typename: 'Organization', id: string }, revisions: Array<{ __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, firstName?: string | null, name: string } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string } }> }> } };

export type PictureRevisionCreateDataObjectAssignmentMutationVariables = Types.Exact<{
  input: Types.MutationCreateDataObjectAssignmentInput;
}>;


export type PictureRevisionCreateDataObjectAssignmentMutation = { __typename: 'Mutation', createDataObjectAssignment: { __typename: 'DataObjectAssignment', id: string } };

export type PictureRevisionSelectorUpdatePictureMutationVariables = Types.Exact<{
  input: Types.MutationUpdatePictureInput;
}>;


export type PictureRevisionSelectorUpdatePictureMutation = { __typename: 'Mutation', updatePicture: { __typename: 'Picture', id: string, state: Types.PictureState, latestRevision?: { __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, firstName?: string | null, name: string } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string } }> } | null, organization: { __typename: 'Organization', id: string }, revisions: Array<{ __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, firstName?: string | null, name: string } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string } }> }> } };

export type PictureRevisionSelectorCreateRevisionMutationVariables = Types.Exact<{
  input: Types.MutationCreatePictureRevisionInput;
}>;


export type PictureRevisionSelectorCreateRevisionMutation = { __typename: 'Mutation', createPictureRevision: { __typename: 'PictureRevision', id: string, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, firstName?: string | null, name: string } | null, pictureDataAssignments: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string } }> } };

export const PictureRevisionSelectorRevisionFragmentDoc = gql`
    fragment PictureRevisionSelectorRevision on PictureRevision {
  id
  __typename
  createdAt
  number
  createdBy {
    id
    firstName
    name
  }
  pictureDataAssignments: dataObjectAssignments(
    filter: {assignmentType: [PictureData]}
  ) {
    dataObject {
      id
      downloadUrl
    }
  }
}
    `;
export const PictureRevisionSelectorPictureFragmentDoc = gql`
    fragment PictureRevisionSelectorPicture on Picture {
  id
  __typename
  state
  latestRevision {
    ...PictureRevisionSelectorRevision
  }
  organization {
    id
  }
  revisions(orderBy: [{key: number, direction: desc}]) {
    ...PictureRevisionSelectorRevision
  }
}
    `;
export const PictureRevisionSelectorItemDocument = gql`
    query pictureRevisionSelectorItem($id: ID!) {
  item: picture(id: $id) {
    ...PictureRevisionSelectorPicture
  }
}
    ${PictureRevisionSelectorPictureFragmentDoc}
${PictureRevisionSelectorRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureRevisionSelectorItemGQL extends Apollo.Query<PictureRevisionSelectorItemQuery, PictureRevisionSelectorItemQueryVariables> {
    override document = PictureRevisionSelectorItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureRevisionCreateDataObjectAssignmentDocument = gql`
    mutation pictureRevisionCreateDataObjectAssignment($input: MutationCreateDataObjectAssignmentInput!) {
  createDataObjectAssignment(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureRevisionCreateDataObjectAssignmentGQL extends Apollo.Mutation<PictureRevisionCreateDataObjectAssignmentMutation, PictureRevisionCreateDataObjectAssignmentMutationVariables> {
    override document = PictureRevisionCreateDataObjectAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureRevisionSelectorUpdatePictureDocument = gql`
    mutation pictureRevisionSelectorUpdatePicture($input: MutationUpdatePictureInput!) {
  updatePicture(input: $input) {
    ...PictureRevisionSelectorPicture
  }
}
    ${PictureRevisionSelectorPictureFragmentDoc}
${PictureRevisionSelectorRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureRevisionSelectorUpdatePictureGQL extends Apollo.Mutation<PictureRevisionSelectorUpdatePictureMutation, PictureRevisionSelectorUpdatePictureMutationVariables> {
    override document = PictureRevisionSelectorUpdatePictureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureRevisionSelectorCreateRevisionDocument = gql`
    mutation pictureRevisionSelectorCreateRevision($input: MutationCreatePictureRevisionInput!) {
  createPictureRevision(input: $input) {
    ...PictureRevisionSelectorRevision
  }
}
    ${PictureRevisionSelectorRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureRevisionSelectorCreateRevisionGQL extends Apollo.Mutation<PictureRevisionSelectorCreateRevisionMutation, PictureRevisionSelectorCreateRevisionMutationVariables> {
    override document = PictureRevisionSelectorCreateRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }