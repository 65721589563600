import {Box2, Vector2, Vector2Like} from "@cm/math"
import {wrap} from "@cm/utils"
import {BrushSettings} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"
import {HalGeometry} from "@common/models/hal/hal-geometry"
import {createHalGeometry} from "@common/models/hal/hal-geometry/create"
import {PainterPrimitiveRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/painter-ref"
import {ImageOpContextWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-webgl2"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {assertSameContext} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"
import {BrushShapeGenerator} from "@app/textures/texture-editor/operator-stack/operators/shared/image-ops/brush-shape-generator"
import {ImageOpCommandQueueWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-webgl2"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"

const BRUSH_SPLAT_SPACING = 0.02 // portion of brush width

export class BrushStrokeGeometry {
    constructor(
        readonly context: ImageOpContextWebGL2,
        readonly tilingEnabled: boolean,
    ) {
        this.painter = this.context.createPainter(
            "primitive",
            "brushStrokeGeometry",
            `
            vec4 computeColor(vec2 worldPosition, vec2 uv, vec4 color) {
                return textureUv0(uv);
            }
        `,
        )
        this.geometry = createHalGeometry(this.context.halContext)
        this.brushShapeGenerator = new BrushShapeGenerator(this.context)
    }

    dispose(): void {
        this.context.releasePainter(this.painter)
        this.geometry.dispose()
        this.brushShapeGenerator.dispose()
    }

    get strokePoints(): readonly Vector2Like[] {
        return this._strokePoints
    }

    startNewStroke() {
        this._strokePoints = []
        this.firstPointDrawn = false
        this.splatDistanceRemainder = 0
        this.needUpdateStrokeGeometry = true
    }

    pushStrokePoints(...points: Vector2Like[]) {
        this._strokePoints.push(...points.map((p) => new Vector2(p.x, p.y)))
        this.needUpdateStrokeGeometry = true
    }

    getBrushShape(cmdQueue: ImageOpCommandQueue, brushSettings: BrushSettings) {
        return this.brushShapeGenerator.getBrushShape(cmdQueue, {
            brushSettings: brushSettings,
            dataType: "uint8",
        })
    }

    paint(
        cmdQueue: ImageOpCommandQueueWebGL2,
        args: {
            resultImage: ImageRef
            brushSettings: BrushSettings
            removeDrawnPoints: boolean
        },
    ) {
        assertSameContext(cmdQueue, this.context)
        this.updateStrokeGeometry(args.brushSettings, args.resultImage.descriptor.width, args.resultImage.descriptor.height)
        const brushShapeImage = this.getBrushShape(cmdQueue, args.brushSettings)
        cmdQueue.paint(this.painter, {
            resultImage: args.resultImage,
            geometry: this.geometry,
            sourceImages: [brushShapeImage],
            options: {blendMode: "screen"},
        })
        if (args.removeDrawnPoints) {
            const lastStrokePoint = this._strokePoints[this.strokePoints.length - 1]
            this._strokePoints = [lastStrokePoint]
        }
        return this.boundingBox
    }

    private updateStrokeGeometry(brushSettings: BrushSettings, targetWidth: number, targetHeight: number) {
        if (!this.needUpdateStrokeGeometry) {
            return
        }
        this.needUpdateStrokeGeometry = false
        this.geometry.clear()
        this.boundingBox = this.addStrokeGeometrySplats(this._strokePoints, brushSettings.brushWidth, targetWidth, targetHeight)
    }

    private addStrokeGeometrySplats(path: Vector2[], width: number, targetWidth: number, targetHeight: number): Box2 {
        if (width <= 0) {
            throw Error("Thickness must be positive")
        }
        const boundingBox = new Box2()
        const splatDistance = width * BRUSH_SPLAT_SPACING
        if (path.length >= 1 && !this.firstPointDrawn) {
            this.firstPointDrawn = true
            boundingBox.expandByBoxInPlace(this.addTileableQuad(path[0], width, targetWidth, targetHeight))
            this.splatDistanceRemainder = splatDistance
        }
        if (path.length >= 2) {
            for (let i = 1; i < path.length; i++) {
                const p0 = path[i - 1]
                const p1 = path[i]
                const delta = p1.sub(p0)
                const distance = delta.norm()
                const dir = delta.mul(1 / distance)
                while (this.splatDistanceRemainder < distance) {
                    const pos = p0.add(dir.mul(this.splatDistanceRemainder))
                    const quadBoundingBox = this.addTileableQuad(pos, width, targetWidth, targetHeight)
                    boundingBox.expandByBoxInPlace(quadBoundingBox)
                    this.splatDistanceRemainder += splatDistance
                }
                this.splatDistanceRemainder -= distance
            }
        }
        return boundingBox
    }

    private addTileableQuad(pos: Vector2, width: number, targetWidth: number, targetHeight: number): Box2 {
        if (this.tilingEnabled) {
            pos = new Vector2(wrap(pos.x, targetWidth), wrap(pos.y, targetHeight))
        }
        const boundingBox = new Box2()
        boundingBox.expandByBoxInPlace(this.addQuad(pos, width))
        if (this.tilingEnabled) {
            const repeatX = pos.x > targetWidth - width / 2 || pos.x < width / 2
            const reoeatY = pos.y > targetHeight - width / 2 || pos.y < width / 2
            const signX = pos.x > targetWidth / 2 ? -1 : 1
            const signY = pos.y > targetHeight / 2 ? -1 : 1
            if (repeatX && reoeatY) {
                boundingBox.expandByBoxInPlace(this.addQuad(pos.add({x: targetWidth * signX, y: targetHeight * signY}), width))
            }
            if (repeatX) {
                boundingBox.expandByBoxInPlace(this.addQuad(pos.add({x: targetWidth * signX, y: 0}), width))
            }
            if (reoeatY) {
                boundingBox.expandByBoxInPlace(this.addQuad(pos.add({x: 0, y: targetHeight * signY}), width))
            }
        }
        boundingBox.expandToIntegersInPlace()
        boundingBox.intersectInPlace(new Box2(0, 0, targetWidth, targetHeight))
        return boundingBox
    }

    private addQuad(pos: Vector2, width: number): Box2 {
        const p00 = new Vector2(pos.x - width / 2, pos.y - width / 2)
        const p10 = new Vector2(pos.x + width / 2, pos.y - width / 2)
        const p01 = new Vector2(pos.x - width / 2, pos.y + width / 2)
        const p11 = new Vector2(pos.x + width / 2, pos.y + width / 2)
        const baseIndex = this.geometry.addVertices([p00, p10, p01, p11], [new Vector2(0, 0), new Vector2(1, 0), new Vector2(0, 1), new Vector2(1, 1)])
        this.geometry.addIndices([baseIndex + 0, baseIndex + 1, baseIndex + 2, baseIndex + 1, baseIndex + 3, baseIndex + 2])
        return Box2.fromMinMax(p00, p11)
    }

    readonly brushShapeGenerator: BrushShapeGenerator

    private painter: PainterPrimitiveRef
    private geometry: HalGeometry
    private _strokePoints: Vector2[] = []
    private firstPointDrawn = false
    private needUpdateStrokeGeometry = true
    private splatDistanceRemainder = 0
    private boundingBox = new Box2()
}
