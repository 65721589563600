<div class="cm-picture-viewer">
    @if (loading) {
        <cm-loading-spinner class="cm-loading-overlay"></cm-loading-spinner>
    }
    <cm-canvas-base #canvasBaseRef (loadingComplete)="loadingComplete()" (loadingError)="loading = false"></cm-canvas-base>
    <cm-picture-controls
        [pictureRevision]="$pictureRevision()"
        [dataObject]="pictureDataObject"
        [pictureCanvas]="$canvasBase()"
        (revisionChange)="changeRevision($event)"
        [selectedAssignment]="selectedAssignment"
        (selectedAssignmentChange)="selectAssignment($event)"
    >
    </cm-picture-controls>
</div>
