<cm-hint>
    <ng-container cm-text>
        @if (isApple) {
            Hold <b>CMD</b> to rotate the region or move edges.
        } @else {
            Hold <b>ALT</b> to rotate the region or move edges.
        }
    </ng-container>
</cm-hint>
<cm-inspector-section>
    <ng-container cm-title>Display</ng-container>
    <ng-container cm-content>
        <cm-toggle
            matTooltip="Toggle display of visual guides [V]"
            [toggle]="$operator().showGuides$.value"
            (toggleChange)="$operator().showGuides$.next($event)"
            [disabled]="$disabled()"
        >
            <ng-container cm-text><span [class.cm-disabled]="$disabled()">Show guides </span></ng-container>
        </cm-toggle>
        <span class="cm-label">View mode</span>
        <div class="cm-mode-selections">
            <cm-button
                class="cm-mode-selection"
                [class.cm-active]="$operator().viewMode$.value === ViewMode.Source"
                matTooltip="View original image [K]"
                (click)="$operator().viewMode$.next(ViewMode.Source)"
                [disabled]="$disabled()"
            >
                <ng-container cm-left-icon><i class="far fa-inbox-arrow-down"></i></ng-container>
                <ng-container cm-text>Source</ng-container>
            </cm-button>
            <cm-button
                class="cm-mode-selection"
                [class.cm-active]="$operator().viewMode$.value === ViewMode.Result"
                matTooltip="View mapped image [L]"
                (click)="$operator().viewMode$.next(ViewMode.Result)"
                [disabled]="$disabled()"
            >
                <ng-container cm-left-icon><i class="far fa-inbox-arrow-up"></i></ng-container>
                <ng-container cm-text>Result</ng-container>
            </cm-button>
        </div>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title>Snapping</ng-container>
    <ng-container cm-content>
        <cm-toggle
            matTooltip="Enable helper line snapping"
            [toggle]="($operator().snapToHelperLinesEnabled$ | async)!"
            (toggleChange)="$operator().snapToHelperLinesEnabled$.next($event)"
            [disabled]="$disabled()"
        >
            <ng-container cm-text><span [class.cm-disabled]="$disabled()">Helper snapping</span></ng-container>
        </cm-toggle>
        <cm-toggle
            matTooltip="Enable similarity snapping"
            [toggle]="($operator().snapToSimilarFeatureEnabled$ | async)!"
            (toggleChange)="$operator().snapToSimilarFeatureEnabled$.next($event)"
            [disabled]="$disabled()"
        >
            <ng-container cm-text><span [class.cm-disabled]="$disabled()">Similarity snapping</span></ng-container>
        </cm-toggle>
        @if (isDebugEnabled) {
            <div>
                <span class="cm-label">Max snap distance ({{ measureUnit }})</span>
                <cm-slider
                    matTooltip="Maximum distance used to search for similar feature"
                    [min]="px2measure(128)"
                    [max]="px2measure(1024)"
                    [displayValuePrecision]="2"
                    [value]="px2measure(($operator().snapDistancePx$ | async)!)"
                    (valueChange)="$operator().snapDistancePx$.next(measure2px($event))"
                    [disabled]="$disabled()"
                ></cm-slider>
            </div>
        }
        <div>
            <span class="cm-label">Distance ({{ measureUnit }})</span>
            <cm-slider
                matTooltip="Determines the maximum radius the snapped feature needs to be in"
                [min]="px2measure(10)"
                [max]="px2measure(1024)"
                [displayValuePrecision]="2"
                [value]="px2measure(($operator().snapDistancePx$ | async)!)"
                (valueChange)="$operator().snapDistancePx$.next(measure2px($event))"
                [disabled]="$disabled()"
            ></cm-slider>
        </div>
    </ng-container>
</cm-inspector-section>
<cm-inspector-section>
    <ng-container cm-title>Helper lines</ng-container>
    <ng-container cm-content>
        <div class="cm-mode-selections">
            <cm-button
                matTooltip="Trace a new helper line"
                class="cm-mode-selection"
                [class.cm-active]="isTracingHelperLine"
                (click)="createHelperLineClicked()"
                [disabled]="$disabled()"
            >
                <ng-container cm-left-icon><i class="far fa-pen-line"></i></ng-container>
                <ng-container cm-text>Trace helper line</ng-container>
            </cm-button>
        </div>
        @if (isTracingHelperLine) {
            <cm-hint>
                <ng-container cm-text> Click and drag to trace a helper line.</ng-container>
            </cm-hint>
        }
        <cm-button
            matTooltip="Retraces selected helper lines on the currently selected map"
            class="cm-button"
            [class.cm-disabled]="$disabled() || !hasSelectedHelperLines"
            (click)="retraceSelectedHelperLinesClicked()"
            [disabled]="$disabled()"
        >
            <ng-container cm-left-icon><i class="far fa-repeat"></i></ng-container>
            <ng-container cm-text>Retrace selected</ng-container>
        </cm-button>
        <cm-toggle
            matTooltip="Lets the region follow nearby helper lines"
            [toggle]="($operator().boundaryFollowsHelperLinesEnabled$ | async)!"
            (toggleChange)="$operator().boundaryFollowsHelperLinesEnabled$.next($event)"
            [disabled]="$disabled()"
        >
            <ng-container cm-text><span [class.cm-disabled]="$disabled()">Region follows nearby helper lines</span></ng-container>
        </cm-toggle>
    </ng-container>
</cm-inspector-section>
<cm-inspector-section>
    <ng-container cm-title>Alignment</ng-container>
    <ng-container cm-content>
        @if (isDebugEnabled) {
            <div>
                <span class="cm-label">Control point spacing ({{ measureUnit }})</span>
                <cm-slider
                    matTooltip="Alignment point spacing used for alignment"
                    [min]="px2measure(8)"
                    [max]="px2measure(256)"
                    [displayValuePrecision]="2"
                    [value]="px2measure(($operator().alignmentSpacingPx$ | async)!)"
                    (valueChange)="$operator().alignmentSpacingPx$.next(measure2px($event))"
                    [disabled]="$disabled()"
                ></cm-slider>
            </div>
            <div>
                <span class="cm-label">Search size ratio</span>
                <cm-slider
                    matTooltip="Ratio to point spacing to search for similar feature"
                    [min]="0.01"
                    [max]="1"
                    [value]="($operator().alignmentSearchSizeRatio$ | async)!"
                    (valueChange)="$operator().alignmentSearchSizeRatio$.next($event)"
                    [disabled]="$disabled()"
                ></cm-slider>
            </div>
            <div>
                <span class="cm-label">Distance penalty along edge</span>
                <cm-slider
                    matTooltip="Correlation penalty with distance along edge"
                    [min]="0"
                    [max]="1"
                    [value]="($operator().alignmentCorrelationPenaltyAlongEdge$ | async)!"
                    (valueChange)="$operator().alignmentCorrelationPenaltyAlongEdge$.next($event)"
                    [disabled]="$disabled()"
                ></cm-slider>
            </div>
            <div>
                <span class="cm-label">Distance penalty across edge</span>
                <cm-slider
                    matTooltip="Correlation penalty with distance across edge"
                    [min]="0"
                    [max]="1"
                    [value]="($operator().alignmentCorrelationPenaltyAcrossEdge$ | async)!"
                    (valueChange)="$operator().alignmentCorrelationPenaltyAcrossEdge$.next($event)"
                    [disabled]="$disabled()"
                ></cm-slider>
            </div>
        }
        <div class="cm-mode-selections">
            <cm-button
                class="cm-mode-selection"
                matTooltip="Create alignment data (this may take a few seconds)"
                (click)="$operator().createAlignmentInfo()"
                [disabled]="$disabled()"
            >
                <ng-container cm-left-icon><i class="far fa-location-dot"></i></ng-container>
                <ng-container cm-text>Run AI alignment</ng-container>
            </cm-button>
            <cm-button
                class="cm-mode-selection"
                matTooltip="Remove all alignment control points"
                (click)="$operator().removeAlignmentControlPoints()"
                [disabled]="$disabled() || !hasAlignmentControlPoints"
            >
                <ng-container cm-left-icon><i class="far fa-location-dot-slash"></i></ng-container>
                <ng-container cm-text>Remove</ng-container>
            </cm-button>
        </div>
        <!--        @if (hasLowQualityAlignment) {-->
        <!--            <cm-hint [hintType]="HintTypes.Warning">-->
        <!--                <ng-container cm-text> The alignment quality is low. Consider providing a more accurate boundary as a starting point.</ng-container>-->
        <!--            </cm-hint>-->
        <!--        }-->
        <div>
            <span class="cm-label">Feature quality threshold</span>
            <cm-slider
                matTooltip="Minimum correlation for similar feature to be considered"
                [min]="0"
                [max]="1"
                [value]="($operator().alignmentMinCorrelation$ | async)!"
                (valueChange)="$operator().alignmentMinCorrelation$.next($event)"
                [disabled]="$disabled() || !isAlignmentDataAvailable"
            ></cm-slider>
        </div>
        <!--        <div class="cm-mode-selections">-->
        <!--            <cm-button-->
        <!--                class="cm-mode-selection"-->
        <!--                matTooltip="Converts all currently visible alignment points to user control points in order to allow manual adjustments"-->
        <!--                (click)="$operator().convertAlignmentToUserPoints()"-->
        <!--                [disabled]="$disabled() || !hasAlignmentControlPoints"-->
        <!--            >-->
        <!--                <ng-container cm-left-icon><i class="far fa-bullseye-pointer"></i></ng-container>-->
        <!--                <ng-container cm-text>Convert alignment to user control-points</ng-container>-->
        <!--            </cm-button>-->
        <!--        </div>-->
    </ng-container>
</cm-inspector-section>
<cm-inspector-section>
    <ng-container cm-title>Border blending</ng-container>
    <ng-container cm-content>
        <cm-toggle
            matTooltip="Toggle border blending"
            [toggle]="($operator().borderBlendEnabled$ | async)!"
            (toggleChange)="$operator().borderBlendEnabled$.next($event)"
            [disabled]="$disabled()"
        >
            <ng-container cm-text><span [class.cm-disabled]="$disabled()">Enable border blending</span></ng-container>
        </cm-toggle>
        <div>
            <span class="cm-label">Border width ({{ measureUnit }})</span>
            <cm-slider
                matTooltip="Width of blended border"
                [min]="px2measure($operator().minBorderBlendingPx)"
                [max]="px2measure(512)"
                [displayValuePrecision]="2"
                [value]="px2measure(($operator().borderBlendDistancePx$ | async)!)"
                (valueChange)="$operator().borderBlendDistancePx$.next(measure2px($event))"
                [disabled]="$disabled()"
            ></cm-slider>
        </div>
    </ng-container>
</cm-inspector-section>
@if (isDebugEnabled) {
    <cm-inspector-section>
        <ng-container cm-title>Debugging</ng-container>
        <ng-container cm-content>
            <cm-toggle [toggle]="($operator().debugDrawEnabled$ | async)!" (toggleChange)="$operator().debugDrawEnabled$.next($event)">
                <ng-container cm-text><span>Enable debug draw</span></ng-container>
            </cm-toggle>
        </ng-container>
    </cm-inspector-section>
}
