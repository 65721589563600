<h2 mat-dialog-title>New material</h2>
<mat-dialog-content [style.width]="'350px'">
    <form class="cm-new-item-form">
        <cm-organization-select
            [updateOrganization]="updateOrganization.bind(this)"
            [organizationId]="$data().organizationId ?? null"
            [showIfViewedByOwner]="false"
            [showLabel]="false"
            [usedInAddEntityContext]="true"
            cm-sidebar
        ></cm-organization-select>
        @if (!$isBatchMode()) {
            <mat-form-field appearance="fill">
                <input matInput placeholder="Name" [(ngModel)]="$name" name="name" />
            </mat-form-field>
        }
        <mat-form-field appearance="fill">
            <mat-select [(value)]="$data().materialRangeTagId" placeholder="Material range">
                @for (range of $materialRangesForSelectedOrganization(); track range.id) {
                    <mat-option [value]="range.id">{{ range.name }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button color="accent" [mat-dialog-close]="$data()" [disabled]="!canConfirmCreate()">Create</button>
</mat-dialog-actions>
