@if ($entity(); as entity) {
    @if (entity.hasOwnProperty("taskInfos")) {
        @if (entity.taskInfos.inProgress > 0 || entity.taskInfos.waitingForFeedback > 0) {
            <div class="cm-comment-boxes">
                <i style="" class="far fa-comment-lines"></i>
                @if (entity.taskInfos.inProgress != 0) {
                    <div class="cm-comments-in-progress">{{ entity.taskInfos.inProgress }}</div>
                }
                @if (entity.taskInfos.waitingForFeedback != 0) {
                    <div class="cm-comments-waiting">{{ entity.taskInfos.waitingForFeedback }}</div>
                }
            </div>
        }
    }
}
