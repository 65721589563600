import {AfterViewInit, Component, inject, Injector} from "@angular/core"
import {MatSnackBar} from "@angular/material/snack-bar"
import {ActivatedRoute} from "@angular/router"
import {assignGltfDataObject, computeArLodAssignmentKey, getArAssignmentKey, getGltfDataObjects} from "@app/common/helpers/ar/ar"
import {GltfExportService} from "@app/common/services/gltf-export/gltf-export.service"
import {ThreeTemplateSceneProviderComponent} from "@app/template-editor/components/three-template-scene-provider/three-template-scene-provider.component"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {ThreeSceneManagerService} from "@app/template-editor/services/three-scene-manager.service"
import {deserializeNodeGraph} from "@cm/graph/node-graph"
import {Parameters} from "@cm/template-nodes/nodes/parameters"
import {loadTemplateGraph} from "@cm/template-nodes/utils/serialization-utils"
import {IsDefined} from "@cm/utils"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {AuthService} from "@common/services/auth/auth.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {GetTemplateRevisionForBackendArGenerationGQL} from "@pages/ar-generation/backend-ar-generation.generated"
import {removeCachedArData} from "@pages/ar-generation/helpers/helpers"
import {filter, take} from "rxjs"

@Component({
    selector: "cm-backend-ar-generation",
    templateUrl: "./backend-ar-generation.component.html",
    providers: [SceneManagerService],
    styleUrls: ["./backend-ar-generation.component.scss"],
    imports: [ThreeTemplateSceneProviderComponent],
})
export class BackendArGenerationComponent implements AfterViewInit {
    readonly auth = inject(AuthService)
    readonly injector = inject(Injector)
    readonly gltfExportService = inject(GltfExportService)

    threeSceneManagerService: ThreeSceneManagerService | undefined
    userAvailable = false

    readonly templateRevisionGql = inject(GetTemplateRevisionForBackendArGenerationGQL)

    constructor(
        private route: ActivatedRoute,
        private uploadService: UploadGqlService,
        private snackBar: MatSnackBar,
    ) {}

    ngAfterViewInit(): void {
        // need to wait for the user to be loaded due to Angular bug in headless mode:
        // component is activated before the `canActivate` guard is resolved
        this.auth.userId$.pipe(filter(IsDefined), take(1)).subscribe(() => {
            this.userAvailable = true
            this.exportSceneIfPossible()
        })
    }

    getTemplateRevisionId(): string {
        const result = this.route.snapshot.queryParamMap.get("templateRevisionId")
        if (!result) throw new Error("The templateRevisionId query parameter is missing.")
        return result
    }

    getParameters() {
        const parameterStringB64 = this.route.snapshot.queryParamMap.get("templateParametersBase64")
        if (!parameterStringB64) throw new Error("The templateParametersBase64 query parameter is missing.")

        const parameters = deserializeNodeGraph(JSON.parse(atob(parameterStringB64)))
        if (!(parameters instanceof Parameters)) throw new Error("Wrong parameter types")
        return parameters
    }

    /*Incoming requests either have ar or web lod, because the configurator runs with web lod and we do not want to
    compile a template in ar lod inside the configurator. The lod changes the assignment key (web lod can have more parameters).
    Ar models are always computed in ar lod though. Web lod assignments are then mapped to the corresponding ar lod model.*/
    async setupAndExportScene(threeSceneManagerService: ThreeSceneManagerService) {
        const templateRevisionId = this.getTemplateRevisionId()
        const parameters = this.getParameters()
        const webOrArLodAssignmentKey = getArAssignmentKey(parameters)
        const {templateRevision} = await fetchThrowingErrors(this.templateRevisionGql)({
            id: templateRevisionId,
            assignmentKey: {equals: webOrArLodAssignmentKey},
        })

        await removeCachedArData(templateRevision, this.injector)

        console.log("Exporting scene with assignment key:", webOrArLodAssignmentKey)
        console.log("Parameters: ", parameters)

        const templateGraph = loadTemplateGraph(templateRevision.graph)

        const arLodAssigmentKey = await computeArLodAssignmentKey(threeSceneManagerService.sceneManagerService, templateRevisionId, parameters, templateGraph)

        const dataObjectAssignments = await getGltfDataObjects(templateRevisionId, arLodAssigmentKey, this.injector)

        if (dataObjectAssignments.length > 0) {
            if (arLodAssigmentKey === webOrArLodAssignmentKey) throw new Error("Ar model was not removed")
            const dataObjectAssignment = dataObjectAssignments[0]
            if (dataObjectAssignment === null) throw new Error("Could not read data object assignment")
            console.log("Reusing existing data object")
            assignGltfDataObject(dataObjectAssignment.dataObject.id, templateRevisionId, webOrArLodAssignmentKey, this.injector)
        } else {
            console.log("Creating new ar export")
            const glbData = await this.gltfExportService.exportGltfFile(templateGraph, parameters, threeSceneManagerService, "ar")

            await this.uploadAndAttachGLTFToEntity(
                glbData,
                arLodAssigmentKey,
                webOrArLodAssignmentKey,
                templateRevisionId,
                templateRevision.template.organizationId,
            )

            console.log("Export and upload completed.")
        }

        //For debugging: FilesService.downloadFile("glb-export.glb", new Blob([glbData]))
    }

    async uploadAndAttachGLTFToEntity(
        glbData: Uint8Array,
        arLodAssignmentKey: string,
        webOrArLodAssignmentKey: string,
        templateRevisionId: string,
        organizationId: string,
    ) {
        const dataObject = await this.uploadService.createAndUploadDataObject(new File([glbData], "export.glb"), {
            organizationId,
            mediaType: "model/gltf-binary",
            size: glbData.byteLength,
        })

        await assignGltfDataObject(dataObject.id, templateRevisionId, arLodAssignmentKey, this.injector) //always assign the ar lod model
        if (arLodAssignmentKey !== webOrArLodAssignmentKey)
            await assignGltfDataObject(dataObject.id, templateRevisionId, webOrArLodAssignmentKey, this.injector) //if ar was triggered from web lod with different parameters, assign the ar model also with web lod assignment key
    }

    async exportSceneIfPossible() {
        if (!this.threeSceneManagerService || !this.userAvailable) return
        await this.setupAndExportScene(this.threeSceneManagerService)
        window.status = "ready" // Let puppeteer know that the AR generation finished.
    }

    onInititalizedThreeSceneManagerService(threeSceneManagerService: ThreeSceneManagerService | undefined) {
        if (threeSceneManagerService) {
            threeSceneManagerService.$ambientLight.set(false)
            threeSceneManagerService.$showGrid.set(false)
            threeSceneManagerService.$displayMode.set("configurator")
        }

        this.threeSceneManagerService = threeSceneManagerService

        this.exportSceneIfPossible()
    }
}
