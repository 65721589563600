import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type BasicTagInfoFragment = { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null };

export type BasicTagAssignmentFragment = { __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string, type: Types.TagType } };

export type FullTagInfoFragment = { __typename: 'Tag', description?: string | null, id: string, legacyId: number, color?: string | null, name: string, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null };

export type AllTagsBasicInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AllTagsBasicInfoQuery = { __typename: 'Query', tags: Array<{ __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } | null> };

export type TagFullInfoQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type TagFullInfoQuery = { __typename: 'Query', tag: { __typename: 'Tag', description?: string | null, id: string, legacyId: number, color?: string | null, name: string, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } };

export type GetTagAssignmentsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.TagAssignmentFilterInput>;
}>;


export type GetTagAssignmentsQuery = { __typename: 'Query', tagAssignments: Array<{ __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string, type: Types.TagType } } | null> };

export type HasTagAssignmentsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.TagAssignmentFilterInput>;
}>;


export type HasTagAssignmentsQuery = { __typename: 'Query', tagAssignmentsCount: number };

export type AssignTagMutationVariables = Types.Exact<{
  objectId: Types.Scalars['ID']['input'];
  contentTypeModel: Types.ContentTypeModel;
  tagId: Types.Scalars['ID']['input'];
}>;


export type AssignTagMutation = { __typename: 'Mutation', createTagAssignment: { __typename: 'TagAssignment', id: string } };

export type UnassignTagMutationVariables = Types.Exact<{
  tagAssignmentId: Types.Scalars['ID']['input'];
}>;


export type UnassignTagMutation = { __typename: 'Mutation', deleteTagAssignment?: any | null };

export const BasicTagAssignmentFragmentDoc = gql`
    fragment BasicTagAssignment on TagAssignment {
  id
  tag {
    id
    type
  }
}
    `;
export const BasicTagInfoFragmentDoc = gql`
    fragment BasicTagInfo on Tag {
  id
  legacyId
  color
  description
  name
  type
  createdBy {
    id
  }
  organization {
    id
  }
}
    `;
export const FullTagInfoFragmentDoc = gql`
    fragment FullTagInfo on Tag {
  ...BasicTagInfo
  description
}
    `;
export const AllTagsBasicInfoDocument = gql`
    query allTagsBasicInfo {
  tags(take: 9999) {
    ...BasicTagInfo
  }
}
    ${BasicTagInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AllTagsBasicInfoGQL extends Apollo.Query<AllTagsBasicInfoQuery, AllTagsBasicInfoQueryVariables> {
    override document = AllTagsBasicInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TagFullInfoDocument = gql`
    query tagFullInfo($id: ID!) {
  tag(id: $id) {
    ...FullTagInfo
  }
}
    ${FullTagInfoFragmentDoc}
${BasicTagInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TagFullInfoGQL extends Apollo.Query<TagFullInfoQuery, TagFullInfoQueryVariables> {
    override document = TagFullInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagAssignmentsDocument = gql`
    query getTagAssignments($filter: TagAssignmentFilterInput) {
  tagAssignments(filter: $filter) {
    ...BasicTagAssignment
  }
}
    ${BasicTagAssignmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagAssignmentsGQL extends Apollo.Query<GetTagAssignmentsQuery, GetTagAssignmentsQueryVariables> {
    override document = GetTagAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HasTagAssignmentsDocument = gql`
    query hasTagAssignments($filter: TagAssignmentFilterInput) {
  tagAssignmentsCount(filter: $filter)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HasTagAssignmentsGQL extends Apollo.Query<HasTagAssignmentsQuery, HasTagAssignmentsQueryVariables> {
    override document = HasTagAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssignTagDocument = gql`
    mutation assignTag($objectId: ID!, $contentTypeModel: ContentTypeModel!, $tagId: ID!) {
  createTagAssignment(
    input: {objectId: $objectId, contentTypeModel: $contentTypeModel, tagId: $tagId}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssignTagGQL extends Apollo.Mutation<AssignTagMutation, AssignTagMutationVariables> {
    override document = AssignTagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UnassignTagDocument = gql`
    mutation unassignTag($tagAssignmentId: ID!) {
  deleteTagAssignment(id: $tagAssignmentId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UnassignTagGQL extends Apollo.Mutation<UnassignTagMutation, UnassignTagMutationVariables> {
    override document = UnassignTagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }