import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TagSearchFilterTagFragment = { __typename: 'Tag', id: string, name: string, type: Types.TagType };

export type TagSearchFilterTagsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.TagFilterInput>;
}>;


export type TagSearchFilterTagsQuery = { __typename: 'Query', tags: Array<{ __typename: 'Tag', id: string, name: string, type: Types.TagType } | null> };

export type TagSearchFilterOrganizationsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OrganizationFilterInput>;
}>;


export type TagSearchFilterOrganizationsQuery = { __typename: 'Query', organizations: Array<{ __typename: 'Organization', id: string, name?: string | null } | null> };

export type TagSearchFilterUserQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type TagSearchFilterUserQuery = { __typename: 'Query', user: { __typename: 'User', memberships: Array<{ __typename: 'Membership', organization: { __typename: 'Organization', id: string } }> } };

export const TagSearchFilterTagFragmentDoc = gql`
    fragment TagSearchFilterTag on Tag {
  id
  name
  type
}
    `;
export const TagSearchFilterTagsDocument = gql`
    query tagSearchFilterTags($filter: TagFilterInput) {
  tags(filter: $filter, take: 9999) {
    ...TagSearchFilterTag
  }
}
    ${TagSearchFilterTagFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TagSearchFilterTagsGQL extends Apollo.Query<TagSearchFilterTagsQuery, TagSearchFilterTagsQueryVariables> {
    override document = TagSearchFilterTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TagSearchFilterOrganizationsDocument = gql`
    query tagSearchFilterOrganizations($filter: OrganizationFilterInput) {
  organizations(filter: $filter, take: 9999) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TagSearchFilterOrganizationsGQL extends Apollo.Query<TagSearchFilterOrganizationsQuery, TagSearchFilterOrganizationsQueryVariables> {
    override document = TagSearchFilterOrganizationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TagSearchFilterUserDocument = gql`
    query tagSearchFilterUser($id: ID!) {
  user(id: $id) {
    memberships {
      organization {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TagSearchFilterUserGQL extends Apollo.Query<TagSearchFilterUserQuery, TagSearchFilterUserQueryVariables> {
    override document = TagSearchFilterUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }