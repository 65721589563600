import {Component, input, output} from "@angular/core"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextAreaComponent} from "@common/components/inputs/native/native-input-text-area/native-input-text-area.component"

type ItemT = {description?: string | null}

@Component({
    selector: "cm-sidebar-description-section",
    imports: [InputContainerComponent, NativeInputTextAreaComponent],
    templateUrl: "./sidebar-description-section.component.html",
    styleUrl: "./sidebar-description-section.component.scss",
})
export class SidebarDescriptionSectionComponent {
    readonly $item = input<ItemT>(undefined, {alias: "item"})
    readonly $label = input<string>("Description:", {alias: "label"})
    readonly $minRows = input<number>(2, {alias: "minRows"})
    readonly $canEdit = input<boolean>(false, {alias: "canEdit"})
    onChanged = output<ItemT>()
}
