<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> HDRI </ng-container>
    <ng-container cm-content>
        @if ($hdriIdPossibilities(); as hdriIdPossibilities) {
            <cm-value-slot [node]="$node()" [key]="'hdriId'" [label]="'HDRI'" [selectionPossibilities]="hdriIdPossibilities"></cm-value-slot>
        }
        <cm-value-slot [node]="$node()" [key]="'intensity'" [label]="'Intensity'"></cm-value-slot>
        <cm-value-slot [node]="$node()" [key]="'clampHighlights'" [label]="'Clamp'"></cm-value-slot>
        <cm-value-slot [node]="$node()" [key]="'rotation'" [subKey]="0" [label]="'Rotation (X)'"></cm-value-slot>
        <cm-value-slot [node]="$node()" [key]="'rotation'" [subKey]="1" [label]="'Rotation (Y)'"></cm-value-slot>
        <cm-value-slot [node]="$node()" [key]="'rotation'" [subKey]="2" [label]="'Rotation (Z)'"></cm-value-slot>
        <cm-value-slot [node]="$node()" [key]="'mirror'" [label]="'Mirror'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>
