import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetTemplateRevisionIdForConfiguratorIframeTestQueryVariables = Types.Exact<{
  templateId: Types.Scalars['ID']['input'];
}>;


export type GetTemplateRevisionIdForConfiguratorIframeTestQuery = { __typename: 'Query', template: { __typename: 'Template', latestRevision?: { __typename: 'TemplateRevision', id: string, graph?: any | null } | null } };

export const GetTemplateRevisionIdForConfiguratorIframeTestDocument = gql`
    query getTemplateRevisionIdForConfiguratorIframeTest($templateId: ID!) {
  template(id: $templateId) {
    latestRevision {
      id
      graph
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateRevisionIdForConfiguratorIframeTestGQL extends Apollo.Query<GetTemplateRevisionIdForConfiguratorIframeTestQuery, GetTemplateRevisionIdForConfiguratorIframeTestQueryVariables> {
    override document = GetTemplateRevisionIdForConfiguratorIframeTestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }