import * as Types from '../../../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AutoTilingGetTextureRevisionDataObjectIdQueryVariables = Types.Exact<{
  textureRevisionId: Types.Scalars['ID']['input'];
}>;


export type AutoTilingGetTextureRevisionDataObjectIdQuery = { __typename: 'Query', textureRevision: { __typename: 'TextureRevision', dataObject: { __typename: 'DataObject', id: string } } };

export type AutoTilingCreateDataObjectMutationVariables = Types.Exact<{
  input: Types.MutationCreateDataObjectInput;
}>;


export type AutoTilingCreateDataObjectMutation = { __typename: 'Mutation', createDataObject: { __typename: 'DataObject', id: string, legacyId: number, signedUploadUrl: string } };

export const AutoTilingGetTextureRevisionDataObjectIdDocument = gql`
    query autoTilingGetTextureRevisionDataObjectId($textureRevisionId: ID!) {
  textureRevision(id: $textureRevisionId) {
    dataObject {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AutoTilingGetTextureRevisionDataObjectIdGQL extends Apollo.Query<AutoTilingGetTextureRevisionDataObjectIdQuery, AutoTilingGetTextureRevisionDataObjectIdQueryVariables> {
    override document = AutoTilingGetTextureRevisionDataObjectIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AutoTilingCreateDataObjectDocument = gql`
    mutation autoTilingCreateDataObject($input: MutationCreateDataObjectInput!) {
  createDataObject(input: $input) {
    id
    legacyId
    signedUploadUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AutoTilingCreateDataObjectGQL extends Apollo.Mutation<AutoTilingCreateDataObjectMutation, AutoTilingCreateDataObjectMutationVariables> {
    override document = AutoTilingCreateDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }