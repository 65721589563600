import {Component, inject, OnInit} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatInputModule} from "@angular/material/input"
import {MatMenu, MatMenuItem, MatMenuModule} from "@angular/material/menu"
import {MatSelectModule} from "@angular/material/select"
import {MatTooltipModule} from "@angular/material/tooltip"
import {RouterOutlet} from "@angular/router"
import {ModelDetailsFragment} from "@app/platform/components/models/model-details/model-details.generated"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {CopyLegacyIdButtonComponent} from "@common/components/buttons/copy-legacy-id-button/copy-legacy-id-button.component"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {BooleanLabelSelectComponent} from "@common/components/inputs/select/boolean-label-select/boolean-label-select.component"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"
import {TagSelectComponent} from "@common/components/inputs/select/tag-select/tag-select.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateReturningDataAndErrors, mutateThrowingErrors} from "@common/helpers/api/mutate"
import {DialogSize} from "@common/models/dialogs"
import {ContentTypeModel, MutationUpdateModelInput, OrganizationType} from "@generated"
import {BaseDetailsComponent} from "@platform/components/base/base-details/base-details.component"
import {ActivitySectionComponent} from "@platform/components/details/activity-section/activity-section.component"
import {ArticleIdSectionComponent} from "@platform/components/details/article-id-section/article-id-section.component"
import {CommentSectionComponent} from "@platform/components/details/comment-section/comment-section.component"
import {FilesSectionComponent} from "@platform/components/details/files-section/files-section.component"
import {GalleryImageComponent} from "@platform/components/details/gallery-image/gallery-image.component"
import {ProjectManagementTasksSectionComponent} from "@platform/components/details/project-management-tasks-section/project-management-tasks-section.component"
import {ShowAssetsButtonComponent} from "@platform/components/details/show-assets-button/show-assets-button.component"
import {SidebarDescriptionSectionComponent} from "@platform/components/details/sidebar-description-section/sidebar-description-section.component"
import {TitleSectionComponent} from "@platform/components/details/title-section/title-section.component"
import {CornersLayoutComponent} from "@platform/components/layouts/corners-layout/corners-layout.component"
import {DetailsDialogLayoutComponent} from "@platform/components/layouts/details-dialog-layout/details-dialog-layout.component"
import {DeleteModelDetailsGQL, ModelDetailsGQL, UpdateModelDetailsGQL} from "@platform/components/models/model-details/model-details.generated"
import {PaymentStateLabelComponent} from "@platform/components/shared/payment-state-label/payment-state-label.component"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"

@Component({
    selector: "cm-model-details",
    templateUrl: "model-details.component.html",
    styleUrls: ["model-details.component.scss"],
    imports: [
        ButtonComponent,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        DetailsDialogLayoutComponent,
        BooleanLabelSelectComponent,
        TagSelectComponent,
        GalleryImageComponent,
        CornersLayoutComponent,
        StateLabelComponent,
        RoutedDialogComponent,
        MatMenu,
        MatMenuItem,
        MatMenuModule,
        MatTooltipModule,
        CopyLegacyIdButtonComponent,
        OrganizationSelectComponent,
        CommentSectionComponent,
        SidebarDescriptionSectionComponent,
        FilesSectionComponent,
        ActivitySectionComponent,
        ProjectManagementTasksSectionComponent,
        ArticleIdSectionComponent,
        TitleSectionComponent,
        ShowAssetsButtonComponent,
        TagSelectComponent,
        PaymentStateLabelComponent,
        RouterOutlet,
    ],
})
export class ModelDetailsComponent extends BaseDetailsComponent<ModelDetailsFragment, Omit<MutationUpdateModelInput, "id">> implements OnInit {
    override _contentTypeModel = ContentTypeModel.Model
    override _fetchItem = fetchThrowingErrors(inject(ModelDetailsGQL))
    override _updateItem = mutateThrowingErrors(inject(UpdateModelDetailsGQL))
    override _deleteItem = mutateReturningDataAndErrors(inject(DeleteModelDetailsGQL))

    protected readonly ContentTypeModel = ContentTypeModel
    protected readonly DialogSize = DialogSize

    canNavigateToAssets = () => this.silo.$silo()?.organization?.type !== OrganizationType.Photographer
}
