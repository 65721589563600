import {Component, ElementRef, inject, Injector, OnInit, viewChild} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatDialogModule} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatMenuModule} from "@angular/material/menu"
import {MatSelectModule} from "@angular/material/select"
import {MatTooltipModule} from "@angular/material/tooltip"
import {RouterModule} from "@angular/router"
import {
    CreateMaterialNodeMutation,
    GetMaterialsGQL,
    GetMaterialsQuery,
    MaterialsGridItemFragment,
} from "@app/platform/components/materials/materials-grid/materials-grid.generated"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {
    ContentTypeModel,
    DataObjectAssignmentType,
    MaterialOrderByCriteria,
    MaterialState,
    MutationCreateMaterialConnectionInput,
    MutationCreateMaterialInput,
    MutationCreateMaterialNodeInput,
    MutationCreateMaterialRevisionInput,
    MutationUpdateMaterialInput,
    NextActor,
    PaymentState,
    SortOrder,
} from "@generated"
import {IsDefined} from "@cm/utils/filter"
import {ItemListComponent} from "@common/components/item/item-list/item-list.component"
import {ListInfoComponent} from "@common/components/item/list-info/list-info.component"
import {InfiniteListComponent} from "@common/components/lists/infinite-list/infinite-list.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {AdvancedAction, BatchDownloadData, ExtraBatchActionItem} from "@common/models/item/list-item"
import {BasicTagInfoFragment} from "@common/services/tags/tags.generated"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {Labels, StateLabel} from "@labels"
import {AddMaterialDialogComponent} from "@platform/components/materials/add-material-dialog/add-material-dialog.component"
import {MaterialExplorerInfoGQL} from "@platform/components/materials/material-explorer/material-explorer.generated"
import {MaterialsCardComponent} from "@platform/components/materials/materials-card/materials-card.component"
import {
    BatchUpdateMaterialsGQL,
    CreateMaterialConnectionGQL,
    CreateMaterialGQL,
    CreateMaterialNodeGQL,
    CreateMaterialRevisionGQL,
    GetMaterialForCopyGQL,
    GetMaterialsQueryVariables,
    MaterialRevisionForCopyFragment,
    MaterialsGridCreateDataObjectAssignmentGQL,
    MaterialsGridMaterialRangeTagsGQL,
    UpdateMaterialGQL,
} from "@platform/components/materials/materials-grid/materials-grid.generated"
import {CancellableRequest} from "@platform/models/data/cancellable-request"
import {MaterialBatchOperationService} from "@platform/services/material/material-batch-operation.service"

export type CreateMaterialData = MutationCreateMaterialInput & {materialRangeTagId?: string | null}

@Component({
    imports: [
        FormsModule,
        InfiniteListComponent,
        ListInfoComponent,
        MatButtonModule,
        MatDialogModule,
        MatInputModule,
        MatMenuModule,
        MatSelectModule,
        RouterModule,
        AddMaterialDialogComponent,
        MatTooltipModule,
        MaterialsCardComponent,
    ],
    providers: [SceneManagerService],
    selector: "cm-materials-grid",
    styleUrls: ["materials-grid.component.scss"],
    templateUrl: "materials-grid.component.html",
})
export class MaterialsGridComponent
    extends ItemListComponent<MaterialsGridItemFragment, MutationUpdateMaterialInput, Partial<CreateMaterialData>>
    implements OnInit
{
    public stateLabels: StateLabel<MaterialState>[] = []
    public materialRangeTags: BasicTagInfoFragment[] = []
    public filteredMaterialRangeTags: BasicTagInfoFragment[] = []
    public extraBatchActions: ExtraBatchActionItem<MaterialsGridItemFragment>[] = []
    public batchDownloadData: BatchDownloadData<MaterialsGridItemFragment>[] = []
    public advancedActions: AdvancedAction[] = []
    public newItemBatchMode = false
    public showMaterialExplorer = false
    public enableBatchUpdates = false

    readonly $fileInput = viewChild.required<ElementRef>("fileInput")

    protected readonly uploadService = inject(UploadGqlService)
    protected readonly materialBatchOperationService = inject(MaterialBatchOperationService)
    protected readonly sceneManagerService = inject(SceneManagerService)

    readonly materialExplorerInfoGql = inject(MaterialExplorerInfoGQL)
    readonly materialRangeTagsGql = inject(MaterialsGridMaterialRangeTagsGQL)
    readonly batchUpdateMaterialsGql = inject(BatchUpdateMaterialsGQL)
    readonly materialForCopyGql = inject(GetMaterialForCopyGQL)
    readonly createMaterialConnectionGql = inject(CreateMaterialConnectionGQL)
    readonly createDataObjectAssignmentGql = inject(MaterialsGridCreateDataObjectAssignmentGQL)

    readonly materialsGql = inject(GetMaterialsGQL)
    readonly createMaterialRevisionGql = inject(CreateMaterialRevisionGQL)
    readonly injector = inject(Injector)

    private fetchRequest = new CancellableRequest<GetMaterialsQuery, GetMaterialsQueryVariables>(this.materialsGql, this.injector, this.destroyRef)

    override ngOnInit() {
        this.stateLabels = Array.from(Labels.MaterialState.values())
        this.extraBatchActions = this.materialBatchOperationService.getExtraBatchActions()
        this.batchDownloadData = this.materialBatchOperationService.getBatchDownloadData()

        const organizationDetails = this.organizations.current
        if (organizationDetails) {
            fetchThrowingErrors(this.materialExplorerInfoGql)({organizationId: organizationDetails.id}).then((materialExplorerInfo) => {
                this.showMaterialExplorer =
                    this.$can().read.material(null, "explorer") &&
                    materialExplorerInfo.organization?.matExplorerMaterial?.id !== undefined &&
                    materialExplorerInfo.organization?.matExplorerTemplate?.id !== undefined
            })
        }

        this.updateOrganization()

        this.advancedActions = this.materialBatchOperationService.getAdvancedActions(this.$fileInput())

        super.ngOnInit()

        void fetchThrowingErrors(this.materialRangeTagsGql)({}).then(({tags}) => {
            this.materialRangeTags = tags.filter(IsDefined)
        })
    }

    // OVERLOADS

    protected override _contentTypeModel = ContentTypeModel.Material
    protected override _initialNewItemData = () => ({
        organizationId: this.organizations.$current()?.id,
    })

    protected override _batchUpdate = async (
        property: "addTagId" | "assignUserId" | "nextActor" | "paymentState" | "removeTagId" | "removeUserAssignment" | "state",
        value: string | boolean,
    ): Promise<number> => {
        const material = await mutateThrowingErrors(this.batchUpdateMaterialsGql)({
            filter: this.filters.materialFilter() ?? {},
            [property]: value,
        })
        return material.batchUpdateMaterials
    }

    protected override _fetchList = ({skip, take}: {skip: number; take: number}) => {
        return this.fetchRequest
            .fetch({
                take,
                skip,
                filter: this.filters.materialFilter(),
                orderBy: [
                    {key: MaterialOrderByCriteria.Priority, direction: SortOrder.Asc},
                    {key: MaterialOrderByCriteria.Id, direction: SortOrder.Desc},
                ],
            })
            .then(({materials, materialsCount}) => ({items: materials, totalCount: materialsCount}))
    }

    protected override _refreshItem = ({id, legacyId}: {id?: string; legacyId?: number}): Promise<MaterialsGridItemFragment | undefined> =>
        fetchThrowingErrors(this.materialsGql)({
            take: 1,
            filter: {
                ...this.filters.materialFilter(),
                id: id ? {equals: id} : undefined,
                legacyId: legacyId ? {equals: legacyId} : undefined,
            },
        }).then(({materials}) => materials?.[0] || undefined)

    readonly updateMaterialGql = inject(UpdateMaterialGQL)
    protected override _updateItem = (data: MutationUpdateMaterialInput) =>
        mutateThrowingErrors(this.updateMaterialGql)({
            input: data,
        }).then(({updateMaterial: material}) => material)

    readonly createMaterialGql = inject(CreateMaterialGQL)
    protected override _createItem = (data: Partial<CreateMaterialData>) => {
        const {materialRangeTagId, tagAssignments, name, ...rest} = data
        if (name === undefined) throw new Error("Name is required")
        return mutateThrowingErrors(this.createMaterialGql)({
            input: {
                ...rest,
                name,
                tagAssignments: materialRangeTagId ? [materialRangeTagId, ...(tagAssignments ?? [])] : tagAssignments,
                state: rest.state ?? MaterialState.Draft,
                paymentState: rest.paymentState ?? PaymentState.OrderPlaced,
                meshSpecific: rest.meshSpecific ?? false,
                nextActor: rest.nextActor ?? NextActor.Team1,
            },
        }).then(({createMaterial: material}) => material)
    }

    readonly createMaterialNodeGql = inject(CreateMaterialNodeGQL)

    override openNewItemDialog() {
        this.newItemBatchMode = false
        super.openNewItemDialog()
        this.updateOrganization()
    }

    async copyMaterial(item: {id: string}): Promise<void> {
        // TODO factor out common logic for duplication of material revision into material graph service
        const material = await fetchThrowingErrors(this.materialForCopyGql)({id: item.id}).then(({material}) => material)

        const {articleId, meshSpecific, nextActor, organization, public: isPublic, sampleArrival, tagAssignments} = material

        const createMaterialInput: MutationCreateMaterialInput = {
            articleId,
            meshSpecific,
            nextActor,
            organizationId: organization?.id,
            paymentState: PaymentState.OrderPlaced,
            public: isPublic,
            sampleArrival,
            tagAssignments: tagAssignments.map((tag) => tag.id),
            name: material.name + " (Copy)",
            description: `Copy of material with id "${material.legacyId}" and with name "${material.name}".`,
            state: MaterialState.Draft,
        }

        const createMaterialResult = await this._createItem(createMaterialInput)

        try {
            const orgId: number = material.legacyId
            const newId: string = createMaterialResult.id
            const newLegacyId: number = createMaterialResult.legacyId

            // find material with the highest revision number among the ones that have a cycles material
            const latestCyclesRevision = material.revisions.reduce<MaterialRevisionForCopyFragment | null>((latestCycles, revision) => {
                if (revision.hasCyclesMaterial && revision.graphSchema && revision.nodes.length > 0) {
                    if (!latestCycles || revision.number > latestCycles.number) {
                        return revision
                    }
                }
                return latestCycles
            }, null)

            if (!latestCyclesRevision) {
                this.notifications.showInfo(`Material with the id ${orgId} has been copied. The id of the copy is ${newLegacyId}.`)
                return
            }

            // Create a copy of the latest cycles revision.
            const createMaterialRevisionInput: MutationCreateMaterialRevisionInput = {
                graphSchema: latestCyclesRevision.graphSchema!,
                materialId: createMaterialResult.id,
                comment: latestCyclesRevision.comment,
            }

            const createMaterialRevisionResult = await mutateThrowingErrors(this.createMaterialRevisionGql)({input: createMaterialRevisionInput}).then(
                ({createMaterialRevision}) => createMaterialRevision,
            )

            // Create a copy of the material node graph.
            // First all the nodes should be copied and the mapping from old to newly created ids should be kept.
            // Then all the connections should be copied by using this mapping.
            const nodeMap: {[id: string]: CreateMaterialNodeMutation["createMaterialNode"]} = {}

            await Promise.all(
                latestCyclesRevision.nodes.map(async (node) => {
                    const createMaterialNodeInput: MutationCreateMaterialNodeInput = {
                        materialRevisionId: createMaterialRevisionResult.id,
                        name: node.name,
                        parameters: node.parameters,
                        textureRevisionId: node.textureRevision?.id,
                        textureSetRevisionId: node.textureSetRevision?.id,
                    }
                    nodeMap[node.id] = await mutateThrowingErrors(this.createMaterialNodeGql)({input: createMaterialNodeInput}).then(
                        ({createMaterialNode}) => createMaterialNode,
                    )
                }),
            )

            await Promise.all(
                latestCyclesRevision.connections.map(async (connection) => {
                    const createMaterialConnectionInput: MutationCreateMaterialConnectionInput = {
                        destinationId: nodeMap[connection.destination.id].id,
                        destinationParameter: connection.destinationParameter,
                        materialRevisionId: createMaterialRevisionResult.id,
                        sourceId: nodeMap[connection.source.id].id,
                        sourceParameter: connection.sourceParameter,
                    }
                    await mutateThrowingErrors(this.createMaterialConnectionGql)({input: createMaterialConnectionInput})
                }),
            )

            this.notifications.showInfo(`Material with the id ${orgId} has been copied. The id of the copy is ${newLegacyId}.`)

            setTimeout(() => {
                void this.router.navigate([newId], {
                    relativeTo: this.route,
                    queryParamsHandling: "preserve",
                })
            })
        } finally {
            this.refresh.contentTypeModel(ContentTypeModel.Material)
        }
    }

    batchCreateMaterials(event: Event) {
        const files = (<HTMLInputElement>event.target).files
        if (!files || files.length === 0) return

        const newItemDialog = this.$newItemDialog()
        if (!newItemDialog) {
            // put an `<ng-template #newItemDialog>` tag containing the form in the html template
            throw new Error("Missing newItemDialog ref in template")
        }

        this.newItemBatchMode = true
        this.newItemDialogRef = this.dialog.open(newItemDialog, {
            data: this._initialNewItemData(),
        })
        this.newItemDialogRef.afterClosed().subscribe(async (data) => {
            if (!data) return

            const {organizationId} = data

            if (!organizationId) return

            try {
                for (const file of Array.from(files)) {
                    try {
                        const dataObject = await this.uploadService.createAndUploadDataObject(
                            file,
                            {
                                organizationId: organizationId,
                            },
                            {processUpload: true, showUploadToolbar: true},
                        )

                        const material = await this._createItem({
                            ...data,
                            name: file.name.replace(/\.[^/.]+$/, ""),
                            state: MaterialState.InfoReview,
                        })

                        if (!material) throw Error("Returned material is undefined")

                        await mutateThrowingErrors(this.createDataObjectAssignmentGql)({
                            input: {
                                dataObjectId: dataObject.id,
                                objectId: material.id,
                                contentTypeModel: ContentTypeModel.Material,
                                type: DataObjectAssignmentType.GalleryImage,
                            },
                        })
                    } catch (error) {
                        this.snackBar.open("Cannot create new material.", "", {duration: 3000})
                        console.error(error)
                    }
                }
            } finally {
                this.refresh.contentTypeModel(ContentTypeModel.Material)
            }
        })
    }

    // CONVENIENCE METHODS

    updateOrganization() {
        this.filteredMaterialRangeTags = this.materialRangeTags.filter((tag) => {
            return tag?.organization?.id === this.newItemData.organizationId
        })
        // reset the current selection if it is no longer available
        this.newItemData.materialRangeTagId = this.filteredMaterialRangeTags.some((tag) => tag.id === this.newItemData.materialRangeTagId)
            ? this.newItemData.materialRangeTagId
            : undefined
    }
}
