import {inject} from "@angular/core"
import {ApolloLink, HttpLink} from "@apollo/client/core"
import {setContext} from "@apollo/client/link/context"
import {onError} from "@apollo/client/link/error"
import {Settings} from "@common/models/settings/settings"
import {LocalStorageService} from "@common/services/local-storage/local-storage.service"

const uri = Settings.GQL_API_ENDPOINT

export const getHttpLink = (): ApolloLink => {
    const httpLink = new HttpLink({
        uri,
    })

    const localStorageService = inject(LocalStorageService)
    const headersLink = setContext((_request, previousContext) => {
        if ("withoutSilo" in previousContext) {
            return {
                headers: localStorageService.headersWithoutSilo,
            }
        } else {
            return {
                headers: localStorageService.headers,
            }
        }
    })

    const errorLink = onError(({graphQLErrors, networkError}) => {
        if (graphQLErrors) {
            graphQLErrors.map(({message, path, locations, source, originalError}) => {
                console.error(`[GraphQL error]@${path}: ${message}`, {locations, source, originalError})
            })
        } else if (networkError) {
            console.error(`[Network error]: ${networkError}`, networkError.stack)
        }
    })

    return ApolloLink.from([headersLink, errorLink, httpLink])
}
