import {Component, OnDestroy, input} from "@angular/core"
import {BrushSettingsPanelComponent} from "@app/textures/texture-editor/operator-stack/operators/shared/panel/brush-settings-panel/brush-settings-panel.component"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {HintComponent} from "@common/components/hint/hint.component"
import {Hotkeys} from "@common/services/hotkeys/hotkeys.service"
import {Subject, takeUntil} from "rxjs"
import {OperatorCloneStamp} from "app/textures/texture-editor/operator-stack/operators/clone-stamp/operator-clone-stamp"
import {isApple} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"

@Component({
    selector: "cm-clone-stamp-panel",
    templateUrl: "./clone-stamp-panel.component.html",
    styleUrls: ["./clone-stamp-panel.component.scss"],
    imports: [HintComponent, ButtonComponent, InspectorSectionComponent, BrushSettingsPanelComponent, ToggleComponent],
})
export class CloneStampPanelComponent implements OnDestroy {
    readonly $operator = input.required<OperatorCloneStamp>({alias: "operator"})
    readonly $disabled = input(false, {alias: "disabled"})

    constructor(hotkeys: Hotkeys) {
        hotkeys
            .addShortcut("undo")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.undoClicked())
    }

    ngOnDestroy(): void {
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }

    get showMask(): boolean {
        return this.$operator().showMask
    }

    set showMask(value: boolean) {
        this.$operator().showMask = value
    }

    get canUndo(): boolean {
        return this.$operator().canUndoStroke
    }

    undoClicked(): void {
        this.$operator().undoStroke()
    }

    isApple = isApple

    private unsubscribe = new Subject<void>()
}
