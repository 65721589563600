import {DeclareTemplateNode} from "#template-nodes/declare-template-node"
import {TemplateNode} from "#template-nodes/types"
import {registerNode} from "@cm/graph/register-node"
import {namedNodeParameters} from "#template-nodes/nodes/named-node"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {IMaterialGraph} from "@cm/material-nodes/interfaces/material-data"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {z} from "zod"
import {LoadMaterialRevision} from "#template-nodes/runtime-graph/nodes/load-material-revision"

const materialReferenceParameters = namedNodeParameters.merge(
    z.object({
        materialRevisionId: z.number(),
    }),
)

export type MaterialReferenceParameters = z.infer<typeof materialReferenceParameters>

@registerNode
export class MaterialReference
    extends DeclareTemplateNode({parameters: materialReferenceParameters}, {}, {nodeClass: "MaterialReference"})
    implements EvaluableTemplateNode<IMaterialGraph>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        const {materialGraph} = scope.node(LoadMaterialRevision, {
            sceneManager: evaluator.templateContext.sceneManager,
            materialRevisionId: this.parameters.materialRevisionId,
        })
        return materialGraph
    }
}

export type MaterialReferenceFwd = TemplateNode<MaterialReferenceParameters> & EvaluableTemplateNode<IMaterialGraph>
