import {SelectionChange} from "@angular/cdk/collections"
import {CdkTreeModule, NestedTreeControl} from "@angular/cdk/tree"
import {AsyncPipe} from "@angular/common"
import {ChangeDetectorRef, Component, DestroyRef, inject, input, model, OnInit} from "@angular/core"
import {outputToObservable, takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop"
import {FormControl, ReactiveFormsModule} from "@angular/forms"
import {MatAutocompleteModule, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete"
import {MatDialog} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatMenuModule} from "@angular/material/menu"
import {MatSnackBar} from "@angular/material/snack-bar"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatTreeNestedDataSource} from "@angular/material/tree"
import {ActivatedRoute, Router, RouterModule} from "@angular/router"
import {
    ProjectTreeProjectFragment,
    ProjectTreeSetFragment,
    ProjectTreeUserFragment,
} from "@app/platform/components/pictures/project-tree/project-tree.generated"
import {IsDefined, IsNonNull} from "@cm/utils/filter"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {DialogRenameComponent} from "@common/components/dialogs/dialog-rename/dialog-rename.component"
import {ListItemComponent} from "@common/components/item"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {ThumbnailLayout} from "@common/models/enums/thumbnail-layout"
import {Settings} from "@common/models/settings/settings"
import {AuthService} from "@common/services/auth/auth.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {BasicOrganizationInfoFragment} from "@common/services/organizations/organizations.generated"
import {DownloadResolution} from "@generated"
import {UtilsService} from "@legacy/helpers/utils"
import {
    ProjectTreeCreateProjectGQL,
    ProjectTreeCreateSetGQL,
    ProjectTreeDeleteProjectGQL,
    ProjectTreeDeleteSetGQL,
    ProjectTreePicturesGQL,
    ProjectTreeProjectsGQL,
    ProjectTreeSetGQL,
    ProjectTreeUpdatePictureGQL,
    ProjectTreeUpdateProjectGQL,
    ProjectTreeUpdateSetGQL,
    ProjectTreeVisibleUsersGQL,
} from "@platform/components/pictures/project-tree/project-tree.generated"
import {OrganizationDetailsFragment} from "@platform/pages/organizations/organizations-page.generated"
import {from, map, Observable, of, switchMap} from "rxjs"

@Component({
    selector: "cm-project-tree",
    templateUrl: "./project-tree.component.html",
    styleUrls: ["./project-tree.component.scss"],
    imports: [
        MatInputModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        CdkTreeModule,
        RouterModule,
        MatTooltipModule,
        MatMenuModule,
        AsyncPipe,
        ListItemComponent,
        DataObjectThumbnailComponent,
    ],
})
export class ProjectTreeComponent implements OnInit {
    settings = Settings
    readonly $currentProjectId = model<string | undefined>(undefined, {alias: "currentProjectId"})
    readonly $currentSetId = model<string | undefined>(undefined, {alias: "currentSetId"})
    readonly $organization = input<{id: string} | undefined>(undefined, {alias: "organization"})
    dataSource?: MatTreeNestedDataSource<ProjectTreeProjectFragment>
    projects?: ProjectTreeProjectFragment[]
    treeControl = new NestedTreeControl<ProjectTreeProjectFragment | ProjectTreeSetFragment, string>(
        (node) => (node.__typename === "Project" ? node.sets : undefined),
        {
            trackBy: ({id}) => id,
        },
    )
    hasChild = (_: number, node: ProjectTreeProjectFragment | ProjectTreeSetFragment) => ("sets" in node ? !!node.sets : false)
    customerInputControl = new FormControl<string | Pick<BasicOrganizationInfoFragment, "name" | "id"> | null>(null)
    filteredOrganizations?: Observable<BasicOrganizationInfoFragment[]>
    visibleUsers: ProjectTreeUserFragment[] | null = null
    public userIsStaff = false

    readonly organizations = inject(OrganizationsService)
    readonly permission = inject(PermissionsService)
    readonly refresh = inject(RefreshService)
    $can = this.permission.$to

    readonly visibleUsersGql = inject(ProjectTreeVisibleUsersGQL)
    readonly setGql = inject(ProjectTreeSetGQL)
    readonly projectsGql = inject(ProjectTreeProjectsGQL)
    readonly updateProjectGql = inject(ProjectTreeUpdateProjectGQL)
    readonly updateSetGql = inject(ProjectTreeUpdateSetGQL)
    readonly createProjectGql = inject(ProjectTreeCreateProjectGQL)
    readonly createSetGql = inject(ProjectTreeCreateSetGQL)
    readonly deleteProjectGql = inject(ProjectTreeDeleteProjectGQL)
    readonly deleteSetGql = inject(ProjectTreeDeleteSetGQL)
    readonly picturesGql = inject(ProjectTreePicturesGQL)
    readonly updatePictureGql = inject(ProjectTreeUpdatePictureGQL)

    protected readonly DownloadResolution = DownloadResolution
    changeDetectorRef = inject(ChangeDetectorRef)

    constructor(
        public dialog: MatDialog,
        public authService: AuthService,
        private route: ActivatedRoute,
        public router: Router,
        private snackBar: MatSnackBar,
        public utils: UtilsService,
        private destroyRef: DestroyRef,
    ) {
        toObservable(this.$organization)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap((organization) => {
                    if (!organization) return of(null)
                    this.customerInputControl.setValue(organization)
                    return from(this.fetchProjects(organization.id))
                }),
                map((projects) => {
                    if (!projects) return
                    this.clearTree()
                    this.projects = projects
                    for (const project of this.projects) {
                        if (project.sets.some((set) => set.id === this.$currentSetId())) {
                            this.$currentProjectId.set(project.id)
                        }
                    }
                    this.initTree(this.projects)
                }),
            )
            .subscribe()
    }

    ngOnInit() {
        fetchThrowingErrors(this.visibleUsersGql)({}).then(({users}) => {
            this.visibleUsers = users.filter(IsDefined)
        })
        this.userIsStaff = this.authService.isStaff()
        this.filteredOrganizations = this.customerInputControl.valueChanges.pipe(
            map((value) => {
                return this.filterCustomers(typeof value === "string" ? value : "")
            }),
        )
        this.treeControl.expansionModel.changed.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => this.onExpandedChanged(data))
    }

    private _isCustomerDropdownOpened = false

    public customerAutocompleteOpened() {
        this._isCustomerDropdownOpened = true
        if (!this._isCustomerAutocompleteInputInFocus) {
            this.customerInputControl.setValue("")
        }
    }

    public customerAutocompleteClosed() {
        if (!this._isCustomerAutocompleteInputInFocus) {
            this.customerInputControl.setValue(this.$organization() ?? "")
        }
        this._isCustomerDropdownOpened = false
    }

    private _isCustomerAutocompleteInputInFocus = false

    public customerAutocompleteInputFocusin() {
        if (!this._isCustomerDropdownOpened) {
            this.customerInputControl.setValue("")
        }
        this._isCustomerAutocompleteInputInFocus = true
    }

    public customerAutocompleteInputFocusout() {
        if (!this._isCustomerDropdownOpened) {
            this.customerInputControl.setValue(this.$organization() ?? "")
        }
        this._isCustomerAutocompleteInputInFocus = false
    }

    private filterCustomers(value: string): BasicOrganizationInfoFragment[] {
        const filterValue = value.toLocaleLowerCase()
        return this.organizations.$all()?.filter((organization) => organization.name?.toLocaleLowerCase()?.startsWith(filterValue)) ?? []
    }

    protected customerDisplayFunction(organization: OrganizationDetailsFragment): string {
        return organization?.name ?? ""
    }

    protected async customerSelected(event: MatAutocompleteSelectedEvent) {
        const organizationId = event.option.value.id
        if (this.$organization()?.id === organizationId) {
            return
        }
        const queryParams = {tab: "projects", organizationId}
        await this.router.navigate([], {queryParams: queryParams})
    }

    initTree(projects: ProjectTreeProjectFragment[]): void {
        this.dataSource = new MatTreeNestedDataSource()
        this.dataSource.data = projects
        this.treeControl.dataNodes = this.dataSource.data
        void this.expandSelectedProject()
    }

    clearTree(): void {
        this.projects = []
        this.dataSource = new MatTreeNestedDataSource()
        this.dataSource.data = this.projects
        this.treeControl.dataNodes = this.dataSource.data
    }

    async expandSelectedProject() {
        const currentProjectId = this.$currentProjectId()
        if (!currentProjectId) {
            return
        }
        const project = this.getProjectNode(currentProjectId)
        if (project) {
            this.treeControl.expand(project)
            return
        }
        const currentSetId = this.$currentSetId()
        if (!currentSetId) return
        const {set} = await fetchThrowingErrors(this.setGql)({id: currentSetId})
        const newProject = this.getProjectNode(set.project.id)
        if (newProject) {
            this.treeControl.expand(newProject)
        }
    }

    private async fetchProjects(organizationId: string | undefined) {
        if (!organizationId) return null
        try {
            return fetchThrowingErrors(this.projectsGql)({organizationId}).then((result) => result.projects.filter(IsNonNull))
        } catch {
            this.snackBar.open(`Cannot fetch project for organization id: ${organizationId}`, "", {duration: 3000})
        }
        return null
    }

    onToggleExpanded(item: ProjectTreeProjectFragment | ProjectTreeSetFragment, type: "project" | "set") {
        if (!this.treeControl.isExpanded(item)) {
            this.treeControl.toggleDescendants(item)
        }
        if (type === "set") {
            this.$currentProjectId.set(undefined)
            this.$currentSetId.set(item.id)
        } else {
            this.$currentProjectId.set(item.id)
            this.$currentSetId.set(undefined)
        }
    }

    expandedNodes = new Set<string>()

    onExpandedChanged(change: SelectionChange<string>) {
        change.added.forEach((nodeId) => {
            this.expandedNodes.add(nodeId)
        })
        change.removed.forEach((nodeId) => {
            this.expandedNodes.delete(nodeId)
        })
        this.changeDetectorRef.markForCheck()
        this.changeDetectorRef.detectChanges()
    }

    getProjectNode(id: string): ProjectTreeProjectFragment | undefined {
        for (const node of this.treeControl.dataNodes) {
            if (node.id === id) {
                return node as ProjectTreeProjectFragment
            }
        }
        return undefined
    }

    updateProjectName(projectNode: ProjectTreeProjectFragment) {
        const dialogRef = this.dialog.open(DialogRenameComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: projectNode.name,
            },
        })
        outputToObservable(dialogRef.componentInstance.onConfirm)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(async (title: string) => {
                try {
                    await mutateThrowingErrors(this.updateProjectGql)({input: {id: projectNode.id, name: title}})
                    if (this.dataSource) {
                        this.dataSource.data = this.dataSource.data?.map((project) =>
                            project.id === projectNode.id
                                ? {
                                      ...project,
                                      name: title,
                                  }
                                : project,
                        )
                    }
                    this.snackBar.open("Changes saved.", "", {duration: 3000})
                } catch {
                    this.snackBar.open("Cannot save changes.", "", {duration: 3000})
                }
            })
    }

    updateSetName(setNode: ProjectTreeSetFragment) {
        const dialogRef = this.dialog.open(DialogRenameComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: setNode.name,
            },
        })
        dialogRef.componentInstance.onConfirm.subscribe(async (title: string) => {
            try {
                await mutateThrowingErrors(this.updateSetGql)({input: {id: setNode.id, name: title}})
                if (this.dataSource) {
                    this.dataSource.data = this.dataSource.data?.map((project) => ({
                        ...project,
                        sets: project.sets.map((set) =>
                            set.id === setNode.id
                                ? {
                                      ...set,
                                      name: title,
                                  }
                                : set,
                        ),
                    }))
                }
                this.snackBar.open("Changes saved.", "", {duration: 3000})
            } catch {
                this.snackBar.open("Cannot save changes.", "", {duration: 3000})
            }
        })
    }

    addProject() {
        const dialogRef = this.dialog.open(DialogRenameComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: "",
            },
        })
        dialogRef.componentInstance.onConfirm.subscribe(async (title: string) => {
            try {
                const organizationId = this.$organization()?.id
                if (!organizationId) {
                    return
                }
                const {createProject: project} = await mutateThrowingErrors(this.createProjectGql)({
                    input: {
                        name: title,
                        organizationId,
                    },
                })
                this.projects = [project, ...(this.projects ?? [])]
                if (this.dataSource) {
                    // https://github.com/angular/components/issues/11381
                    this.dataSource.data = []
                    this.dataSource.data = this.projects
                }
                await this.router.navigate([], {
                    relativeTo: this.route,
                    queryParamsHandling: "merge",
                    queryParams: {organizationId: this.$organization()?.id, project: project.id},
                })
            } catch {
                this.snackBar.open("Cannot create project.", "", {duration: 3000})
            }
        })
    }

    addSet(projectId: string) {
        const dialogRef = this.dialog.open(DialogRenameComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: "",
            },
        })
        outputToObservable(dialogRef.componentInstance.onConfirm)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(async (title: string) => {
                try {
                    const {createSet: set} = await mutateThrowingErrors(this.createSetGql)({
                        input: {
                            name: title,
                            projectId: projectId,
                        },
                    })
                    this.projects =
                        this.projects?.map((project) => {
                            if (project.id === projectId) {
                                return {
                                    ...project,
                                    sets: [...project.sets, set],
                                }
                            }
                            return project
                        }) ?? []
                    if (this.dataSource) {
                        this.dataSource.data = this.projects
                    }
                    const project = this.projects.find((project) => project.id === projectId)
                    if (project) {
                        this.treeControl.expand(project)
                    }
                    await this.router.navigate([], {
                        relativeTo: this.route,
                        queryParamsHandling: "merge",
                        queryParams: {organizationId: this.$organization()?.id, setId: set.id},
                    })
                } catch (error) {
                    console.error(error)
                    this.snackBar.open("Cannot create set.", "", {duration: 3000})
                }
            })
    }

    async deleteProject(projectNode: ProjectTreeProjectFragment) {
        if (projectNode.sets.length) {
            this.snackBar.open("Cannot delete a project with sets, please delete the sets first.", "", {duration: 3000})
            return
        }
        try {
            await mutateThrowingErrors(this.deleteProjectGql)({id: projectNode.id})
            this.projects = this.projects?.filter((project) => project.id !== projectNode.id) ?? []
            if (this.dataSource) {
                this.dataSource.data = this.projects
            }
            this.snackBar.open("Project deleted.", "", {duration: 3000})
        } catch {
            this.snackBar.open("Cannot delete project.", "", {duration: 3000})
        }
    }

    async deleteSet(setNode: ProjectTreeSetFragment) {
        try {
            await mutateThrowingErrors(this.deleteSetGql)({id: setNode.id})
            this.projects =
                this.projects
                    ?.filter((project) => project.id !== setNode.id)
                    ?.map((node) => ({...node, sets: node.sets.filter((set) => set.id !== setNode.id)})) ?? []
            if (this.dataSource) {
                this.dataSource.data = this.projects
            }
            this.snackBar.open("Set deleted.", "", {duration: 3000})
        } catch {
            this.snackBar.open("Cannot delete set. Does it still contain pictures?", "", {duration: 3000})
        }
    }

    async setHighestPriority(setNode: ProjectTreeSetFragment) {
        try {
            const {pictures} = await fetchThrowingErrors(this.picturesGql)({
                take: 9999,
                filter: {setId: {equals: setNode.id}},
            })

            for (const picture of pictures.filter(IsNonNull)) {
                await mutateThrowingErrors(this.updatePictureGql)({input: {id: picture.id, priorityOrder: 0}})
            }

            this.snackBar.open("Changes saved.", "", {duration: 3000})
        } catch {
            this.snackBar.open("Cannot save changes.", "", {duration: 3000})
        }
    }

    protected readonly ThumbnailLayout = ThumbnailLayout
}
