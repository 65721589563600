import {IMaterialData, keyForMaterialData} from "@cm/material-nodes/interfaces/material-data"
import {createMeshBuffersForMaterialGroups, MeshBuffers} from "@cm/template-nodes/geometry-processing/mesh-data"
import {SceneNodes} from "@cm/template-nodes/interfaces/scene-object"
import {anyDifference, mapDifferent, objectFieldsDifferent} from "@template-editor/helpers/change-detection"
import {getGroupPartNumber, isGroupPart} from "@template-editor/services/scene-manager.service"
import {getThreeObjectPart, mathIsEqual, setThreeObjectPart, ThreeObject, updateTransform} from "@template-editor/helpers/three-object"
import {ThreeSceneManagerService} from "@template-editor/services/three-scene-manager.service"
import {Observable, of, tap} from "rxjs"
import {Three as THREE} from "@cm/material-nodes/three"
import {ThreeMeshBvh} from "@cm/material-nodes/three"
import {keyForMeshMaterialData} from "@cm/template-nodes"

THREE.BufferGeometry.prototype.computeBoundsTree = ThreeMeshBvh.computeBoundsTree
THREE.BufferGeometry.prototype.disposeBoundsTree = ThreeMeshBvh.disposeBoundsTree
THREE.Mesh.prototype.raycast = ThreeMeshBvh.acceleratedRaycast

THREE.BatchedMesh.prototype.computeBoundsTree = ThreeMeshBvh.computeBatchedBoundsTree
THREE.BatchedMesh.prototype.disposeBoundsTree = ThreeMeshBvh.disposeBatchedBoundsTree
THREE.BatchedMesh.prototype.raycast = ThreeMeshBvh.acceleratedRaycast

export function createBufferGeometry(meshBuffers: MeshBuffers): THREE.BufferGeometry {
    const {vertices, normals, uvs, indices} = meshBuffers

    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3))
    geometry.setAttribute("normal", new THREE.BufferAttribute(normals, 3))
    geometry.setAttribute("uv", new THREE.BufferAttribute(uvs[0], 2))
    for (let uvIdx = 1; uvIdx < uvs.length; uvIdx++) geometry.setAttribute(`uv${uvIdx}`, new THREE.BufferAttribute(uvs[uvIdx], 2))
    geometry.setIndex(new THREE.BufferAttribute(indices, 1))

    return geometry
}

export class ThreeMesh extends ThreeObject<SceneNodes.Mesh> {
    protected override renderObject: THREE.Group = new THREE.Group()
    wasDisposed = false

    constructor(threeSceneManagerService: ThreeSceneManagerService, onAsyncUpdate: () => void) {
        super(threeSceneManagerService, onAsyncUpdate)
        setThreeObjectPart(this.renderObject, this)
    }

    override setup(sceneNode: SceneNodes.Mesh) {
        const {sceneManagerService, materialManagerService} = this.threeSceneManagerService

        const meshHash = sceneNode.completeMeshData.reified.contentHash

        const setupDeferredMaterialMaterial = (
            materialIndex: number,
            mesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>,
            deferredMaterial: [Observable<THREE.Material>, IMaterialData],
            callback?: (material: THREE.Material) => void,
        ) => {
            const [material, materialData] = deferredMaterial
            sceneManagerService.addTask({
                description: `setupMaterial(${meshHash}-${materialIndex}, ${keyForMeshMaterialData(materialData, sceneNode)})`,
                task: material.pipe(
                    tap((material) => {
                        if (this.wasDisposed) {
                            materialManagerService.releaseMaterial(material)
                            return
                        }

                        materialManagerService.releaseMaterial(mesh.material)
                        mesh.material = material

                        if (callback) callback(material)

                        this.threeSceneManagerService.exchangedShadowMapMeshTexture(mesh)

                        this.onAsyncUpdate()
                    }),
                ),
                critical: true,
            })
        }

        const getMaterial = (materialIndex: number): [THREE.Material, [Observable<THREE.Material>, IMaterialData] | null] => {
            const materialData = sceneNode.materialMap.get(materialIndex)
            if (materialData) {
                const [defaultMaterial, deferredMaterial] = materialManagerService.acquireMaterial(materialData, sceneNode, materialIndex)
                if (!deferredMaterial) return [defaultMaterial, null]
                return [defaultMaterial, [deferredMaterial, materialData]]
            } else return [materialManagerService.acquireDefaultMaterial(undefined, materialIndex), null]
        }

        const notifyCachedMaterial = (materialIndex: number) => {
            const materialData = sceneNode.materialMap.get(materialIndex)
            if (materialData)
                sceneManagerService.addTask({
                    description: `setupCachedMaterial(${meshHash}-${materialIndex}, ${keyForMeshMaterialData(materialData, sceneNode)})`,
                    task: of(null),
                    critical: true,
                })
        }

        const key = (materialData: IMaterialData | null | undefined, mesh: SceneNodes.Mesh | undefined) => {
            if (materialData === null) return null
            if (materialData === undefined) return undefined
            if (!mesh) return keyForMaterialData(materialData)
            return keyForMeshMaterialData(materialData, mesh)
        }

        let subMeshesUpdated = false
        return anyDifference([
            objectFieldsDifferent(
                sceneNode.completeMeshData.reified,
                this.parameters?.completeMeshData.reified,
                ["uvs", "materialGroups", "vertices", "normals", "faceIDs"],
                undefined,
                ({uvs, materialGroups, vertices, normals, faceIDs}) => {
                    if (uvs.length === 0) throw new Error("ReifiedMeshData has no UVs")

                    const subMeshes = this.getSubMeshes()
                    const meshBuffersByGroup = createMeshBuffersForMaterialGroups(vertices, normals, uvs, faceIDs, materialGroups)

                    for (const meshBuffer of meshBuffersByGroup) {
                        if (meshBuffer.indices.length === 0) continue

                        const bufferGeometry = createBufferGeometry(meshBuffer)
                        const {materialIndex} = meshBuffer

                        if (this.threeSceneManagerService.$displayMode() === "editor") {
                            bufferGeometry.computeBoundsTree({strategy: ThreeMeshBvh.SAH})
                        }

                        const subMesh = subMeshes.find((subMesh) => materialIndex === subMesh.materialIndex)?.subMesh

                        if (subMesh) {
                            subMesh.geometry.disposeBoundsTree()
                            subMesh.geometry.dispose()
                            subMesh.geometry = bufferGeometry
                        } else {
                            const mesh = new THREE.Mesh(bufferGeometry)
                            setThreeObjectPart(mesh, this, `group${materialIndex}`)
                            this.renderObject.add(mesh)
                        }
                    }

                    for (const subMesh of subMeshes) {
                        if (!meshBuffersByGroup.find((meshBuffer) => meshBuffer.materialIndex === subMesh.materialIndex)) {
                            this.disposeSubMesh(subMesh.subMesh)
                        }
                    }

                    subMeshesUpdated = true
                },
            ),
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["transform"],
                (valueA, valueB) => mathIsEqual(valueA, valueB),
                ({transform}) => {
                    updateTransform(transform, this.renderObject)
                },
            ),
            (() => {
                if (
                    mapDifferent(sceneNode.materialMap, this.parameters?.materialMap, (valueA, valueB) => {
                        return valueA === valueB || key(valueA, sceneNode) === key(valueB, this.parameters)
                    }) ||
                    objectFieldsDifferent(
                        sceneNode.meshRenderSettings,
                        this.parameters?.meshRenderSettings,
                        [
                            "displacementUvChannel",
                            "displacementMin",
                            "displacementMax",
                            "displacementNormalStrength",
                            "displacementNormalSmoothness",
                            "displacementNormalOriginalResolution",
                        ],
                        undefined,
                    ) ||
                    sceneNode.meshRenderSettings.displacementImage?.imageNode.hash !== this.parameters?.meshRenderSettings?.displacementImage?.imageNode.hash
                ) {
                    const {materialManagerService} = this.threeSceneManagerService

                    const subMeshes = this.getSubMeshes()

                    let allMaterialsDeferred = true
                    for (const [materialIndex, materialData] of sceneNode.materialMap) {
                        const oldMaterialData = this.parameters?.materialMap.get(materialIndex)

                        if (key(materialData, sceneNode) === key(oldMaterialData, this.parameters)) continue

                        const mesh = subMeshes.find((subMesh) => subMesh.materialIndex === materialIndex)?.subMesh
                        if (!mesh) continue

                        const [material, deferredMaterial] = getMaterial(materialIndex)

                        if (!deferredMaterial) {
                            materialManagerService.releaseMaterial(mesh.material)
                            mesh.material = material
                            this.threeSceneManagerService.exchangedShadowMapMeshTexture(mesh)
                            allMaterialsDeferred = false
                            notifyCachedMaterial(materialIndex)
                        } else {
                            if (mesh.material instanceof THREE.MeshBasicMaterial && subMeshesUpdated) {
                                materialManagerService.releaseMaterial(mesh.material)
                                mesh.material = material
                                this.threeSceneManagerService.exchangedShadowMapMeshTexture(mesh)
                                allMaterialsDeferred = false
                            } else {
                                materialManagerService.releaseMaterial(material)
                            }

                            if (this.threeSceneManagerService.$displayMode() === "configurator" && subMeshesUpdated) {
                                mesh.visible = false
                                setupDeferredMaterialMaterial(materialIndex, mesh, deferredMaterial, () => {
                                    mesh.visible = true
                                    if (this.getSceneNode().castRealtimeShadows || this.getSceneNode().receiveRealtimeShadows)
                                        this.threeSceneManagerService.requestShadowMapUpdate()
                                })
                            } else setupDeferredMaterialMaterial(materialIndex, mesh, deferredMaterial)
                        }
                    }

                    return !allMaterialsDeferred
                } else return false
            })(),
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["receiveRealtimeShadows"],
                (a, b) => a === b && subMeshesUpdated === false,
                ({receiveRealtimeShadows}) => {
                    this.getSubMeshes().forEach(({subMesh}) => {
                        subMesh.receiveShadow = receiveRealtimeShadows
                        if (receiveRealtimeShadows) this.threeSceneManagerService.addShadowMapMesh(subMesh)
                        else this.threeSceneManagerService.removeShadowMapMesh(subMesh, false)
                    })
                },
            ),
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["castRealtimeShadows"],
                (a, b) => a === b && subMeshesUpdated === false,
                ({castRealtimeShadows}) => {
                    this.getSubMeshes().forEach(({subMesh}) => {
                        subMesh.castShadow = castRealtimeShadows
                    })
                    this.threeSceneManagerService.requestShadowMapUpdate()
                },
            ),
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["isDecal"],
                (a, b) => a === b && subMeshesUpdated === false,
                ({isDecal}) => {
                    this.getSubMeshes().forEach(({subMesh}) => {
                        subMesh.renderOrder = isDecal ? 1 : 0
                    })
                },
            ),
        ])
    }

    getSubMesh(materialIndex: number) {
        for (const child of this.renderObject.children) {
            if (child instanceof THREE.Mesh) {
                const mesh = child as THREE.Mesh<THREE.BufferGeometry, THREE.Material>
                const threeObjectPart = getThreeObjectPart(mesh)

                if (threeObjectPart && threeObjectPart.part === `group${materialIndex}`) return mesh
            }
        }

        throw new Error(`SubMesh with materialIndex ${materialIndex} not found`)
    }

    getSubMeshes() {
        const result: {subMesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>; materialIndex: number}[] = []

        for (const child of this.renderObject.children) {
            if (child instanceof THREE.Mesh) {
                const mesh = child as THREE.Mesh<THREE.BufferGeometry, THREE.Material>
                const threeObjectPart = getThreeObjectPart(mesh)

                if (threeObjectPart && isGroupPart(threeObjectPart.part)) {
                    const materialIndex = getGroupPartNumber(threeObjectPart.part)
                    result.push({subMesh: mesh, materialIndex})
                }
            }
        }

        return result
    }

    private disposeSubMesh(subMesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>) {
        const {materialManagerService} = this.threeSceneManagerService

        this.threeSceneManagerService.removeShadowMapMesh(subMesh, true)
        subMesh.geometry.disposeBoundsTree()
        subMesh.geometry.dispose()
        materialManagerService.releaseMaterial(subMesh.material)

        this.renderObject.remove(subMesh)
    }

    override dispose(final: boolean) {
        this.getSubMeshes().forEach(({subMesh}) => {
            this.disposeSubMesh(subMesh)
        })

        if (final) this.wasDisposed = true
    }
}
