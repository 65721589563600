import {Component, input} from "@angular/core"

@Component({
    selector: "cm-line",
    imports: [],
    templateUrl: "./line.component.html",
    styleUrl: "./line.component.scss",
})
export class LineComponent {
    readonly $color = input<string>("#f1f1f1", {alias: "color"})
}
