import {Component, viewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {DistributionMethod, PrincipledBsdfInputs, PrincipledBsdfOutputs, SubsurfaceMethod} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-principled-bsdf-node",
    templateUrl: "./principled-bsdf-node.component.html",
    styleUrls: ["./principled-bsdf-node.component.scss"],
    imports: [NodeBaseComponent],
})
export class PrincipledBsdfNodeComponent {
    readonly $nodeBase = viewChild<MaterialNodeBase>("nodeBase")
    inputs = PrincipledBsdfInputs
    outputs = PrincipledBsdfOutputs
    typeInfo = PrincipledBsdfNodeType
    settings = [DistributionMethod, SubsurfaceMethod]
}

export const PrincipledBsdfNodeType: MaterialNodeType<typeof PrincipledBsdfNodeComponent> = {
    id: "principledBsdf",
    label: "Principled BSDF",
    color: "#2d8a5d",
    name: "BsdfPrincipled",
    inputs: [
        PrincipledBsdfInputs.baseColor,
        // PrincipledBsdfInputs.subsurface,
        // PrincipledBsdfInputs.subsurfaceRadius,
        // PrincipledBsdfInputs.subsurfaceColor,
        PrincipledBsdfInputs.metallic,
        PrincipledBsdfInputs.specular,
        PrincipledBsdfInputs.roughness,
        PrincipledBsdfInputs.anisotropic,
        PrincipledBsdfInputs.anisotropic,
        PrincipledBsdfInputs.anisotropicRotation,
        // PrincipledBsdfInputs.ior,
        PrincipledBsdfInputs.alpha,
        PrincipledBsdfInputs.normal,
        PrincipledBsdfInputs.tangent,
        PrincipledBsdfInputs.emission,
    ],
    outputs: [PrincipledBsdfOutputs.bsdf],
    component: PrincipledBsdfNodeComponent,
}
