import {TexturesApiService} from "@app/textures/service/textures-api.service"
import {ImageOpCommandQueueImgProc} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-imgproc"
import {ImageOpContextBase} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-base"
import {
    ImageDescriptor,
    ImageRef,
    ImageRefId,
    ManagedImageRef,
    RefCountedImageRef,
} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageImgProc} from "@app/textures/texture-editor/operator-stack/image-op-system/image-imgproc"
import {ImageProcessingNodes} from "@cm/image-processing-nodes"
import {JobNodes} from "@cm/job-nodes/job-nodes"
import {ReplaceAWithB} from "@cm/utils/type"
import {DrawableImageCache} from "app/textures/texture-editor/operator-stack/image-op-system/detail/drawable-image-cache"

export class ImageOpContextImgProc extends ImageOpContextBase {
    constructor(
        readonly texturesApi: TexturesApiService,
        drawableImageCache: DrawableImageCache,
    ) {
        super("final", drawableImageCache)
    }

    dispose(): void {}

    createCommandQueue(): ImageOpCommandQueueImgProc {
        return new ImageOpCommandQueueImgProc(this)
    }

    getImage(imageRef: ImageRef): Promise<ImageProcessingNodes.ImageNode | undefined> {
        switch (imageRef.addressSpace) {
            case "temporary":
                throw new Error("Temporary images are not supported")
            case "drawable":
                if (typeof imageRef.id !== "number") {
                    throw new Error(`Drawable image ref id must be a number, but got ${imageRef.id}`)
                }
                return this.getDrawableImage(imageRef.id)
            case "data-object":
                if (typeof imageRef.id !== "string") {
                    throw new Error(`Data object image ref id must be a string, but got ${imageRef.id}`)
                }
                return this.getDataObjectImage(imageRef.id)
            default:
                throw new Error(`Unknown address space: ${imageRef.addressSpace}`)
        }
    }

    async createDataObjectImageRef(dataObjectId: string): Promise<ManagedImageRef> {
        const dataObjectImageDescriptor = await this.texturesApi.getDataObjectImageDescriptor(dataObjectId)
        const node = ImageProcessingNodes.decode(
            ImageProcessingNodes.externalData(JobNodes.dataObjectReference(dataObjectImageDescriptor.legacyId), "encodedData"),
        )
        const descriptor: ImageDescriptor = {
            width: dataObjectImageDescriptor.width,
            height: dataObjectImageDescriptor.height,
            channelLayout: "RGB", // TODO this is a guess
            dataType: "float32",
        }
        const imageRef = new RefCountedImageRef(
            "data-object",
            dataObjectId,
            descriptor,
            () => {
                if (!this.dataObjectImageByImageRefId.has(dataObjectId)) {
                    throw new Error(`Image ${dataObjectId} not found`)
                }
                this.dataObjectImageByImageRefId.delete(dataObjectId)
            },
            "ImageOpContextImgProc.createDataObjectImageRef",
        )
        this.dataObjectImageByImageRefId.set(dataObjectId, node)
        return new ManagedImageRef(imageRef)
    }

    private getDrawableImage(id: number): Promise<ImageImgProc> {
        if (!this.drawableImageCache) {
            throw new Error("Drawable image cache is not set")
        }
        return this.drawableImageCache.getImageImgProc(id)
    }

    private async getDataObjectImage(dataObjectId: string): Promise<ImageImgProc> {
        const image = this.dataObjectImageByImageRefId.get(dataObjectId)
        if (!image) {
            throw new Error(`Image ${dataObjectId} not found`)
        }
        return image
    }

    private dataObjectImageByImageRefId = new Map<ImageRefId, ImageImgProc>()
}

export type ImgProcImageToImageRef<T extends ImageProcessingNodes.ImageNode> = {
    [K in keyof T]: ReplaceAWithB<T[K], ImageProcessingNodes.ImageNode, ImageRef>
}
