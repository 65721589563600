import {Component, inject, OnInit} from "@angular/core"
import {ActivatedRoute, ParamMap, Router} from "@angular/router"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {AuthService} from "@common/services/auth/auth.service"
import {VerifyExternalLoginGQL} from "@common/components/handle-sso-callback/handle-sso-callback.generated"
import {FullPageFeedbackComponent} from "@common/components/full-page-feedback/full-page-feedback.component"
import {LoginProvider} from "@generated"
import {z} from "zod"

@Component({
    selector: "cm-handle-sso-callback",
    imports: [FullPageFeedbackComponent],
    templateUrl: "./handle-sso-callback.component.html",
    styleUrl: "./handle-sso-callback.component.scss",
})
export class HandleSsoCallbackComponent implements OnInit {
    readonly auth = inject(AuthService)
    readonly activatedRoute = inject(ActivatedRoute)
    readonly notifications = inject(NotificationsService)
    readonly router = inject(Router)
    readonly verifyExternalLogin = inject(VerifyExternalLoginGQL)

    provider?: "google" | "microsoft"

    ngOnInit() {
        // using the fragment for Microsoft, query params for Google
        // TODO: unify this and include provider in the state to make it more robust
        const parsedData = z.object({provider: z.literal("google").or(z.literal("microsoft"))}).parse(this.activatedRoute.snapshot.data)
        this.provider = parsedData.provider
        switch (this.provider) {
            case "google":
                void this.performLogin(LoginProvider.Google, this.activatedRoute.snapshot.queryParamMap)
                break
            case "microsoft": {
                const paramMap = new URLSearchParams(this.activatedRoute.snapshot.fragment ?? "")
                void this.performLogin(LoginProvider.Microsoft, paramMap)
            }
        }
    }

    private performLogin = async (provider: LoginProvider, paramMap: ParamMap | URLSearchParams) => {
        const error = paramMap.get("error_description") ?? paramMap.get("error")
        if (error) {
            this.notifications.showError(error)
            await this.router.navigateByUrl("/login")
            return
        }
        const state = paramMap.get("state")
        if (!state) {
            this.notifications.showError("Missing state parameter")
            await this.router.navigateByUrl("/login")
            return
        }
        const stateData = z.object({csrfToken: z.string(), redirectUri: z.string().optional()}).parse(JSON.parse(atob(state)))
        if (stateData.csrfToken !== this.auth.csrfToken) {
            this.notifications.showError("Invalid CSRF token")
            await this.router.navigateByUrl("/login")
            return
        }
        const code = paramMap.get("code") ?? paramMap.get("id_token")
        if (!code) {
            this.notifications.showError("No code found")
            await this.router.navigateByUrl("/login")
            return
        }
        const result = await this.notifications.mutateWithUserFeedback(this.verifyExternalLogin, {code, provider}, {})
        if (result) {
            const {verifyExternalLogin: loginInfo} = result
            const success = await this.auth.completeLogin(loginInfo)
            if (success) {
                if (stateData.redirectUri) {
                    await this.router.navigateByUrl(stateData.redirectUri)
                } else {
                    await this.auth.navigateToDefaultView()
                }
            } else {
                this.notifications.showError("Failed to complete login process")
                await this.router.navigateByUrl("/login")
                return
            }
        } else {
            await this.router.navigateByUrl("/login")
            return
        }
    }
}
