import {DeclareMaterialNode, DeclareMaterialNodeType, materialSlots} from "#material-nodes/declare-material-node"
import {threeValueNode} from "#material-nodes/three-utils"
import {getAll} from "@cm/graph/utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes.js"
import {dot, float, normalize, normalView, positionViewDirection} from "three/examples/jsm/nodes/Nodes.js"
import {z} from "zod"

const ReturnTypeSchema = z.object({
    fresnel: materialSlots,
    facing: materialSlots,
})
const InputTypeSchema = z.object({
    blend: materialSlots.optional(),
    normal: materialSlots.optional(),
})
const ParametersTypeSchema = z.object({})
export class LayerWeight extends (DeclareMaterialNode(
    {
        returns: ReturnTypeSchema,
        inputs: InputTypeSchema,
        parameters: ParametersTypeSchema,
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {blend, normal} = await getAll(inputs, get)

            const facing = float(1.0)
                .sub(dot(normalize(positionViewDirection), normalize(normal ?? normalView)).abs())
                .pow(float(2.0))

            const fresnel = THREENodes.mix(float(0.0), facing, blend ?? threeValueNode(0.5))

            return {fresnel, facing}
        },
    },
) as DeclareMaterialNodeType<typeof ReturnTypeSchema, typeof InputTypeSchema, typeof ParametersTypeSchema>) {}
