import {ErrorHandler, Injectable, inject} from "@angular/core"
import {MatSnackBarRef, TextOnlySnackBar} from "@angular/material/snack-bar"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {z} from "zod"

@Injectable({
    providedIn: "root",
})
export class ErrorHandlerService implements ErrorHandler {
    private readonly notifications = inject(NotificationsService)
    private snackBarRef: MatSnackBarRef<TextOnlySnackBar> | undefined

    handleError(error: unknown) {
        if (error instanceof DOMException && error.name === "AbortError") {
            // ignore cancelled requests
            return
        }

        console.error(error)

        /*if (this.$can().read.page("errorsInSnackBar"))*/ {
            const parsedError = z.object({message: z.string()}).safeParse(error)
            const message = parsedError.success ? parsedError.data.message || "An unspecific error occurred" : "An unspecific error occurred"

            if (this.snackBarRef)
                this.snackBarRef.afterDismissed().subscribe(() => {
                    this.snackBarRef = this.notifications.showError(`Error: ${message}`, undefined, "Dismiss")
                })
            else this.snackBarRef = this.notifications.showError(`Error: ${message}`, undefined, "Dismiss")

            this.snackBarRef.afterDismissed().subscribe(() => {
                this.snackBarRef = undefined
            })
        }
    }
}
