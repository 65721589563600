import {inject, Injectable} from "@angular/core"
import {ContentTypeModel, DataObjectAssignmentType} from "@generated"
import {CreateAttachmentGQL} from "@common/components/files/attachments/attachments.generated"
import {GalleryImageAssignmentRemoveGQL, GalleryImageAssignmentsGQL, GalleryImageCountGQL} from "@common/services/gallery-image/gallery-image.service.generated"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {forceEnum} from "@common/helpers/utils/enum"
import {ColormassEntity} from "@common/models/abilities"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {RefreshService} from "@common/services/refresh/refresh.service"

type ItemT = {id: string; galleryImage?: {id?: string} | null} & ColormassEntity

@Injectable({
    providedIn: "root",
})
export class GalleryImageService {
    readonly notifications = inject(NotificationsService)
    readonly refresh = inject(RefreshService)

    private readonly createAttachment = inject(CreateAttachmentGQL)
    private readonly galleryImageAssignments = inject(GalleryImageAssignmentsGQL)
    private readonly galleryImageAssignmentRemove = inject(GalleryImageAssignmentRemoveGQL)
    private readonly galleryImageCount = inject(GalleryImageCountGQL)

    async assign(item: ItemT, galleryImageId: string) {
        await this.notifications.mutateWithUserFeedback(
            this.createAttachment,
            {
                input: {
                    dataObjectId: galleryImageId,
                    objectId: item.id,
                    contentTypeModel: forceEnum(item.__typename, ContentTypeModel),
                    type: this.assignmentTypeForItem(item, true)[0],
                },
            },
            {
                success: `Successfully assigned ${this.labelForItem(item)}`,
                error: `Failed to assign ${this.labelForItem(item)}`,
            },
        )
        this.refresh.item(item)
    }

    async remove(item: ItemT | undefined | null) {
        await this.notifications.withUserFeedback(
            async () => {
                if (!item) {
                    throw Error("No item to remove")
                }
                const assignments = await Promise.all(
                    this.assignmentTypeForItem(item, false).map(async (assignmentType) =>
                        fetchThrowingErrors(this.galleryImageAssignments)({
                            objectId: item.id,
                            contentTypeModel: forceEnum(item.__typename, ContentTypeModel),
                            assignmentType,
                        }),
                    ),
                ).then((results) => results.map((result) => result.galleryImageAssignments).reduce((acc, val) => acc.concat(val), []))

                if (!(assignments.length && assignments[0])) throw new Error(`No ${this.labelForItem(item)} assignments found`)
                await mutateThrowingErrors(this.galleryImageAssignmentRemove)(assignments[0])
                this.refresh.item(item)
            },
            {
                success: `Successfully removed ${item ? this.labelForItem(item) : "item"}`,
                error: `Failed to remove ${item ? this.labelForItem(item) : "item"}`,
            },
        )
    }

    async available(item: ItemT) {
        const count = await Promise.all(
            this.assignmentTypeForItem(item, false).map(async (assignmentType) =>
                fetchThrowingErrors(this.galleryImageCount)({
                    objectId: item.id,
                    contentTypeModel: forceEnum(item.__typename, ContentTypeModel),
                    assignmentType: assignmentType,
                }),
            ),
        ).then((results) => results.map((result) => result.galleryImageCount).reduce((acc, val) => acc + val, 0))

        return !!count
    }

    private assignmentTypeForItem(
        item: ItemT,
        newAssignment: boolean,
    ): (DataObjectAssignmentType.GalleryImage | DataObjectAssignmentType.ProfilePicture | DataObjectAssignmentType.CustomerLogo)[] {
        switch (item.__typename) {
            case ContentTypeModel.Organization:
                return [DataObjectAssignmentType.CustomerLogo]
            case ContentTypeModel.User:
                return newAssignment
                    ? [DataObjectAssignmentType.ProfilePicture] // TODO double check this
                    : [DataObjectAssignmentType.GalleryImage, DataObjectAssignmentType.ProfilePicture]
            default:
                return [DataObjectAssignmentType.GalleryImage]
        }
    }

    labelForItem(item: ItemT) {
        switch (item.__typename) {
            case ContentTypeModel.Organization:
                return "organization logo"
            case ContentTypeModel.User:
                return "profile picture"
            default:
                return "gallery image"
        }
    }
}
