import {CanvasBaseComponent} from "@common/components/canvas"
import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {takeUntil} from "rxjs"

export class CanvasBaseToolboxRootItem extends CanvasBaseToolboxItemBase<CanvasBaseComponent> {
    constructor(readonly _canvasBase: CanvasBaseComponent) {
        super(_canvasBase)

        this.canvasBase.canvasBoundsChange.pipe(takeUntil(this.unsubscribe)).subscribe((canvasBounds) => this.canvasBoundsChange.emit(canvasBounds))
        this.canvasBase.navigation.viewChange.pipe(takeUntil(this.unsubscribe)).subscribe((transform) => this.viewChange.emit(transform))
        this.canvasBase.physicalInfoChange.pipe(takeUntil(this.unsubscribe)).subscribe((physicalInfo) => this.physicalInfoChange.emit(physicalInfo))
    }

    // override get canvasBase(): CanvasBaseComponent {
    //     return this._canvasBase
    // }
}
