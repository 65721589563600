import {Component, ElementRef, inject, OnDestroy, OnInit, input, viewChild, output} from "@angular/core"
import {MimeType, UtilsService} from "@legacy/helpers/utils"
import {NotificationsService} from "@common/services/notifications/notifications.service"

@Component({
    selector: "cm-drop-files",
    templateUrl: "./drop-files.component.html",
    styleUrls: ["./drop-files.component.scss"],
})
export class DropFilesComponent implements OnInit, OnDestroy {
    readonly $maxFiles = input(100, {alias: "maxFiles"})
    readonly $mimeTypeFilter = input(MimeType.All, {alias: "mimeTypeFilter"})
    readonly $showDropzone = input(false, {alias: "showDropzone"})
    readonly $enableDrop = input(true, {alias: "enableDrop"})
    readonly $subtitle = input<string>(undefined, {alias: "subtitle"})

    readonly filesDropped = output<File[]>()

    readonly $fileInput = viewChild<ElementRef<HTMLInputElement>>("fileInput")

    // Drag events bubble up from children, which will lead to multiple enter/leave events
    // We keep a counter to know if we are still dragging over the element
    // https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
    enterCounter = 0

    get isDropping() {
        return this.enterCounter > 0
    }

    readonly elementRef = inject(ElementRef)
    readonly notifications = inject(NotificationsService)
    readonly utils = inject(UtilsService)

    ngOnInit() {
        this.elementRef.nativeElement.addEventListener("dragenter", this.onDragEnter)
        this.elementRef.nativeElement.addEventListener("dragleave", this.onDragLeave)
        this.elementRef.nativeElement.addEventListener("dragover", this.onDragOver)
        this.elementRef.nativeElement.addEventListener("drop", this.onDrop)
    }

    ngOnDestroy() {
        this.elementRef.nativeElement.removeEventListener("dragenter", this.onDragEnter)
        this.elementRef.nativeElement.removeEventListener("dragleave", this.onDragLeave)
        this.elementRef.nativeElement.removeEventListener("dragover", this.onDragOver)
        this.elementRef.nativeElement.removeEventListener("drop", this.onDrop)
    }

    shouldHandleDragEvent = async (event: DragEvent) => {
        if (!this.$enableDrop()) {
            return false
        }
        if (event.dataTransfer?.types?.includes("Files")) {
            event.stopPropagation()
            event.preventDefault()
            return true
        }
        return false
    }

    onDragEnter = (event: DragEvent) =>
        // events  bubble, so we need to ignore events fired on the children
        // if the dra(g enters a child, we are already in the process of dragging into the parent
        this.shouldHandleDragEvent(event).then((shouldHandle) => {
            if (shouldHandle) {
                this.enterCounter++
            }
        })

    onDragLeave = (event: DragEvent) =>
        this.shouldHandleDragEvent(event).then((shouldHandle) => {
            if (shouldHandle) {
                this.enterCounter--
            }
        })

    // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop#prevent_the_browsers_default_drag_behavior
    onDragOver = (event: DragEvent) =>
        // nothing to do, we have already stopped propagation and default behavior
        this.shouldHandleDragEvent(event)

    onDrop = (event: DragEvent) =>
        this.shouldHandleDragEvent(event).then((shouldHandle) => {
            if (shouldHandle) {
                this.enterCounter = 0
                this.processFileList(event.dataTransfer?.files)
            }
        })

    openFileSelector() {
        this.$fileInput()?.nativeElement?.click()
    }

    selectFiles(event: Event) {
        const inputElement = event.target as HTMLInputElement
        if (inputElement.files) {
            this.processFileList(inputElement.files)
            // reset value to make event fire again if same file is selected
            inputElement.value = ""
        }
    }

    processFileList(fileList?: FileList) {
        const files = Array.from(fileList ?? [])
        if (files.length > this.$maxFiles()) {
            this.notifications.showInfo(`The number of files allowed is ${this.$maxFiles()}.`)
            return
        }

        const allowedFiles = files.filter((file) => {
            let mimeType = file.type
            if (file.name.toLowerCase().endsWith(".exr")) {
                mimeType = "image/x-exr"
            } else if (file.name.toLowerCase().endsWith(".hdr")) mimeType = "image/vnd.radiance"

            return UtilsService.mimeTypeMatch(this.$mimeTypeFilter(), mimeType)
        })
        if (files.length > allowedFiles.length) {
            const deniedFileCount = files.length - allowedFiles.length
            this.notifications.showInfo(
                `Cannot upload ${deniedFileCount === 1 ? `file` : `${deniedFileCount} files`}. Only MIME type ${this.$mimeTypeFilter().toString()} is accepted.`,
            )
        }
        this.filesDropped.emit(allowedFiles)
    }
}
