import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ImageViewerItemFragment = { __typename: 'DataObject', id: string, downloadUrl: string, height?: number | null, mediaType?: string | null, originalFileName: string, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null };

export type ImageViewerItemsQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type ImageViewerItemsQuery = { __typename: 'Query', dataObjects: Array<{ __typename: 'DataObject', id: string, downloadUrl: string, height?: number | null, mediaType?: string | null, originalFileName: string, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } | null> };

export const ImageViewerItemFragmentDoc = gql`
    fragment ImageViewerItem on DataObject {
  id
  downloadUrl
  height
  thumbnail(fileType: jpg, resolution: High) {
    downloadUrl
  }
  mediaType
  originalFileName
  width
}
    `;
export const ImageViewerItemsDocument = gql`
    query imageViewerItems($ids: [ID!]!) {
  dataObjects(filter: {id: {in: $ids}}) {
    ...ImageViewerItem
  }
}
    ${ImageViewerItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ImageViewerItemsGQL extends Apollo.Query<ImageViewerItemsQuery, ImageViewerItemsQueryVariables> {
    override document = ImageViewerItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }