import {Component, input} from "@angular/core"

@Component({
    selector: "cm-tag-thumbnail",
    imports: [],
    templateUrl: "./tag-thumbnail.component.html",
    styleUrl: "./tag-thumbnail.component.scss",
})
export class TagThumbnailComponent {
    readonly $tag = input<
        | {
              color?: string | null
          }
        | undefined
        | null
    >(null, {alias: "tag"})
}
