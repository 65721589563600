import {Component, input} from "@angular/core"
import {MatButtonModule} from "@angular/material/button"

@Component({
    selector: "cm-entity-add-card",
    templateUrl: "./entity-add-card.component.html",
    styleUrls: ["./entity-add-card.component.scss"],
    imports: [MatButtonModule],
})
export class EntityAddCardComponent {
    readonly $squareFormat = input(false, {alias: "squareFormat"})

    constructor() {}
}
