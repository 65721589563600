import {Component} from "@angular/core"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {SliderComponent} from "@common/components/inputs/slider/slider.component"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {OperatorTiling} from "@app/textures/texture-editor/operator-stack/operators/tiling/operator-tiling"
import {AsyncPipe} from "@angular/common"
import {MatTooltipModule} from "@angular/material/tooltip"
import {OperatorPanelBase} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator-panel-base"
import {isApple} from "@common/helpers/device-browser-detection/device-browser-detection"
import {HintComponent, HintTypes} from "@common/components/hint/hint.component"
import {ViewMode} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/spatial-mapping-item"

@Component({
    selector: "cm-tiling-panel",
    imports: [InspectorSectionComponent, SliderComponent, ToggleComponent, ButtonComponent, AsyncPipe, MatTooltipModule, HintComponent],
    templateUrl: "./tiling-panel.component.html",
    styleUrl: "./tiling-panel.component.scss",
})
export class TilingPanelComponent extends OperatorPanelBase<OperatorTiling> {
    protected readonly ViewMode = ViewMode

    get isDebugEnabled(): boolean {
        return this.$operator().callback.isDebugEnabled ?? false
    }

    get isAlignmentDataAvailable(): boolean {
        return this.$operator().isAlignmentDataAvailable
    }

    get hasAlignmentControlPoints(): boolean {
        return this.$operator().hasAlignmentControlPoints
    }

    get hasLowQualityAlignment(): boolean {
        return this.$operator().hasLowQualityAlignment
    }

    createHelperLineClicked() {
        this.$operator().traceHelperLineEditModeEnabled$.next(!this.$operator().traceHelperLineEditModeEnabled$.value)
    }

    get isTracingHelperLine(): boolean {
        return this.$operator().traceHelperLineEditModeEnabled$.value
    }

    get hasSelectedHelperLines(): boolean {
        return this.$operator().selectedHelperLines.length > 0
    }

    retraceSelectedHelperLinesClicked() {
        void this.$operator().retraceSelectedHelperLines()
    }

    protected readonly isApple = isApple
    protected readonly HintTypes = HintTypes
}
