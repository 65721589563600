<cm-routed-dialog [dialogSize]="dialogSizes.Large" [dialogScroll]="false" [needsConfirmationToClose]="false">
    <ng-container cm-overlay-content>
        <div class="cm-main-card">
            <div class="cm-menu-section">
                <cm-config-menu [useCaptions]="true" [uiStyleOverride]="'accordion'" [accordionStyle]="'manualClose'"></cm-config-menu>
                <cm-pdf-download-button></cm-pdf-download-button>
            </div>
            <div class="cm-viewer-section">
                <!-- 955 -->
                <cm-configurator [templateUuid]="'8ee95263-fd7c-40fc-b99f-08961594e14e'"></cm-configurator>
                <!-- dove -->
                <!-- <cm-configurator [templateUuid]="'22fee4a0-dc34-40c3-a2d3-290126fb8111'"></cm-configurator> -->
                <!-- lookdev -->
                <!-- <cm-configurator [templateUuid]="'1bcc23fa-3840-4b5f-92d6-d1c0dc342ae6'"></cm-configurator> -->
                <!-- <cm-configurator [templateUuid]="'d12dfd86-a8fb-46d9-81ab-5491866ce126'"></cm-configurator> -->
                <!-- cameo / vado -->
                <!-- <cm-configurator [templateUuid]="'d12dfd86-a8fb-46d9-81ab-5491866ce126'"></cm-configurator> -->
                <!-- material test -->
                <!-- <cm-configurator [templateUuid]="'fbb820a7-fadd-4489-8b4d-f7d8363871d4'"></cm-configurator> -->
                <!-- material test 2-->
                <!-- <cm-configurator
                    [templateUuid]="'9f867cb8-966f-4736-9106-3cacba50b3b4'"
                    [parameters]="'param(3d23006d-1eb2-47f1-a616-e357695316b8)=material(2d8709e8-d5b9-4028-86b1-d06e8575011b)'"
                ></cm-configurator> -->
            </div>
        </div>
    </ng-container>
</cm-routed-dialog>
