import {Component, input} from "@angular/core"
import {GridSize} from "@legacy/helpers/utils"

@Component({
    selector: "cm-grid-list",
    templateUrl: "./grid-list.component.html",
    styleUrls: ["./grid-list.component.scss"],
})
export class GridListComponent {
    readonly $gridSize = input.required<GridSize>({alias: "gridSize"})
    gridSizes = GridSize
}
