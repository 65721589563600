<div [class.cm-square-container]="$squareFormat()">
    <div class="cm-card">
        <div class="cm-content">
            <div class="cm-picture-container">
                <i class="fa fa-eye-slash cm-picture-placeholder"></i>
            </div>
        </div>
        <div class="cm-details">
            <div class="cm-title">{{ message }}</div>
        </div>
    </div>
</div>
