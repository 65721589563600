<input
    [(ngModel)]="editString"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [class.cm-invalid]="!editValid"
    class="cm-input"
    [disabled]="$disabled()"
    [readonly]="$readonly()"
    [class.cm-button]="$isButton()"
/>
