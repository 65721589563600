import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ItemListUserFragment = { __typename: 'User', id: string, name: string };

export type ItemListVisibleUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ItemListVisibleUsersQuery = { __typename: 'Query', users: Array<{ __typename: 'User', id: string, name: string } | null> };

export type ItemListCreateUserAssignmentMutationVariables = Types.Exact<{
  input: Types.MutationCreateUserAssignmentInput;
}>;


export type ItemListCreateUserAssignmentMutation = { __typename: 'Mutation', createUserAssignment: { __typename: 'UserAssignment', id: string, state?: Types.UserAssignmentState | null, user: { __typename: 'User', firstName?: string | null, lastName?: string | null } } };

export type ItemListDeleteUserAssignmentsMutationVariables = Types.Exact<{
  input: Types.MutationDeleteUserAssignmentsInput;
}>;


export type ItemListDeleteUserAssignmentsMutation = { __typename: 'Mutation', deleteUserAssignments: number };

export const ItemListUserFragmentDoc = gql`
    fragment ItemListUser on User {
  id
  name
}
    `;
export const ItemListVisibleUsersDocument = gql`
    query itemListVisibleUsers {
  users(filter: {visibleInFilters: true}, take: 9999) {
    ...ItemListUser
  }
}
    ${ItemListUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ItemListVisibleUsersGQL extends Apollo.Query<ItemListVisibleUsersQuery, ItemListVisibleUsersQueryVariables> {
    override document = ItemListVisibleUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ItemListCreateUserAssignmentDocument = gql`
    mutation itemListCreateUserAssignment($input: MutationCreateUserAssignmentInput!) {
  createUserAssignment(input: $input) {
    id
    state
    user {
      firstName
      lastName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ItemListCreateUserAssignmentGQL extends Apollo.Mutation<ItemListCreateUserAssignmentMutation, ItemListCreateUserAssignmentMutationVariables> {
    override document = ItemListCreateUserAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ItemListDeleteUserAssignmentsDocument = gql`
    mutation itemListDeleteUserAssignments($input: MutationDeleteUserAssignmentsInput!) {
  deleteUserAssignments(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ItemListDeleteUserAssignmentsGQL extends Apollo.Mutation<ItemListDeleteUserAssignmentsMutation, ItemListDeleteUserAssignmentsMutationVariables> {
    override document = ItemListDeleteUserAssignmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }