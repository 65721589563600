import {Component, computed, effect, inject, input, output, signal, viewChild} from "@angular/core"
import {MatTooltip} from "@angular/material/tooltip"
import {ArConfiguratorActionsComponent} from "@app/template-editor/components/ar-configurator-actions/ar-configurator-actions.component"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {TemplateNodeClipboardService} from "@app/template-editor/services/template-node-clipboard.service"
import {ObjectId, TemplateGraph} from "@cm/template-nodes"
import {TemplateAllVariationsComponent} from "@template-editor/components/template-all-variations/template-all-variations.component"
import {TemplateInspectorComponent} from "@template-editor/components/template-inspector/template-inspector.component"
import {TemplateSceneViewerOptionsComponent} from "@template-editor/components/template-scene-viewer-options/template-scene-viewer-options.component"
import {TemplateSelectVariationComponent} from "@template-editor/components/template-select-variation/template-select-variation.component"
import {
    TemplateTreeViewType,
    TemplateTreeViewTypeSelectorComponent,
} from "@template-editor/components/template-tree-view-type-selector/template-tree-view-type-selector.component"
import {TemplateTreeComponent} from "@template-editor/components/template-tree/template-tree.component"
import {
    TemplateViewType,
    TemplateViewTypeSelectorComponent,
} from "@template-editor/components/template-view-type-selector/template-view-type-selector.component"
import {ThreeTemplateSceneProviderComponent} from "@template-editor/components/three-template-scene-provider/three-template-scene-provider.component"
import {SceneCamera, ThreeTemplateSceneViewerComponent} from "@template-editor/components/three-template-scene-viewer/three-template-scene-viewer.component"
import {TemplateImageViewerComponent} from "../template-image-viewer/template-image-viewer.component"
import {ButtonComponent} from "@app/common/components/buttons/button/button.component"
import {TemplateAddComponent} from "../template-add/template-add.component"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatMenuModule} from "@angular/material/menu"
import {LocalPreviewRenderingService} from "@common/services/rendering/local-preview-rendering.service"
import {TemplateJobManagerService} from "@app/template-editor/services/template-job-manager.service"
import {BlenderConnectorService} from "@app/template-editor/services/blender-connector.service"
import {ThreeSceneManagerService} from "@app/template-editor/services/three-scene-manager.service"

@Component({
    selector: "cm-template-edit",
    templateUrl: "./template-edit.component.html",
    styleUrl: "./template-edit.component.scss",
    providers: [SceneManagerService, TemplateJobManagerService, LocalPreviewRenderingService, BlenderConnectorService],
    imports: [
        TemplateViewTypeSelectorComponent,
        TemplateTreeViewTypeSelectorComponent,
        TemplateTreeComponent,
        TemplateAllVariationsComponent,
        TemplateSelectVariationComponent,
        ThreeTemplateSceneViewerComponent,
        TemplateSceneViewerOptionsComponent,
        ThreeTemplateSceneProviderComponent,
        TemplateInspectorComponent,
        MatTooltip,
        TemplateImageViewerComponent,
        ArConfiguratorActionsComponent,
        ButtonComponent,
        TemplateAddComponent,
        MatProgressBarModule,
        MatMenuModule,
    ],
})
export class TemplateEditComponent {
    readonly $title = input<string | undefined>(undefined, {alias: "title"})
    readonly $templateGraph = input.required<TemplateGraph>({alias: "templateGraph"})
    readonly $defaultCustomerId = input.required<number>({alias: "defaultCustomerId"})
    readonly $templateId = input.required<string>({alias: "templateId"})
    readonly $templateRevisionId = input.required<string>({alias: "templateRevisionId"})

    readonly requestSave = output<() => void>()

    readonly $threeViewer = viewChild<ThreeTemplateSceneViewerComponent>("threeViewer")

    readonly $templateViewType = signal<TemplateViewType>("edit")
    readonly $templateTreeViewType = signal<TemplateTreeViewType>("tree")
    readonly $selectedCameraId = signal<ObjectId | undefined>(undefined)

    readonly localSceneManagerService = inject(SceneManagerService)
    readonly localPreviewRenderingService = inject(LocalPreviewRenderingService)
    readonly blenderConnectorService = inject(BlenderConnectorService)

    readonly $selectedCameraNode = computed(() => {
        const selectedCameraId = this.$selectedCameraId()

        if (selectedCameraId) {
            const cameraNode = this.localSceneManagerService.$cameras().find((x) => x.id === selectedCameraId)
            if (cameraNode) return cameraNode
        }
        return undefined
    })

    readonly $camera = computed<SceneCamera | undefined>(() => {
        const selectedCameraNode = this.$selectedCameraNode()
        return selectedCameraNode ? {parameters: selectedCameraNode, transform: selectedCameraNode.transform} : undefined
    })

    readonly $previewImageData = computed(() => {
        if (this.localSceneManagerService.$currentlyModifyingScene()) return null
        return this.localPreviewRenderingService.$previewImageData()
    })

    readonly $numPendingTasks = computed(() => {
        return this.localSceneManagerService.$numPendingTasks() + this.localPreviewRenderingService.$numPendingTasks()
    })

    readonly templateGraphModified = output<boolean>()

    constructor() {
        this.localSceneManagerService.$showDimensionGuides.set(true)

        effect(() => {
            this.templateGraphModified.emit(this.localSceneManagerService.$templateGraphModified())
        })

        effect(() => {
            const templateGraph = this.$templateGraph()
            this.localSceneManagerService.$templateGraph.set(templateGraph)
        })

        effect(() => {
            const defaultCustomerId = this.$defaultCustomerId()
            this.localSceneManagerService.$defaultCustomerId.set(defaultCustomerId)
        })

        effect(() => {
            const templateRevisionId = this.$templateRevisionId()
            this.localSceneManagerService.$templateRevisionId.set(templateRevisionId)
        })

        effect(() => {
            const nodes = this.localSceneManagerService.$scene()
            if (this.localSceneManagerService.$currentlyModifyingScene()) return
            this.localPreviewRenderingService.$sceneNodes.set(nodes)
        })

        effect(() => {
            const viewer = this.$threeViewer()
            if (!viewer) return
            const {width, height, pixelRatio} = viewer.$renderDimensions()
            const resolutionScale = pixelRatio * 1
            this.localPreviewRenderingService.$size.set([width * resolutionScale, height * resolutionScale])
        })

        effect(() => {
            const viewer = this.$threeViewer()
            if (!viewer) return
            const viewCamera = viewer.$viewCamera()
            this.localPreviewRenderingService.$cameraOverride.set(viewCamera)
        })
    }

    save() {
        this.requestSave.emit(() => {
            this.localSceneManagerService.synchronizeSerializedTemplateGraph()
        })
    }

    onInititalizedThreeSceneManagerService(threeSceneManagerService: ThreeSceneManagerService | undefined) {
        this.blenderConnectorService.setThreeSceneManagerService(threeSceneManagerService)
    }
}
