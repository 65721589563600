import {NgTemplateOutlet} from "@angular/common"
import {Component, HostBinding, Input, input, model, viewChild, output, effect} from "@angular/core"
import {ParameterType, ParameterValue, ScalarSocketRange, SocketPosition} from "@node-editor/models"
import {ColorInputSocketComponent} from "@node-editor/components/color-input-socket/color-input-socket.component"
import {NodeSocketComponent} from "@node-editor/components/node-socket/node-socket.component"
import {PlainInputSocketComponent} from "@node-editor/components/plain-input-socket/plain-input-socket.component"
import {ScalarInputSocketComponent} from "@node-editor/components/scalar-input-socket/scalar-input-socket.component"
import {VectorInputSocketComponent} from "@node-editor/components/vector-input-socket/vector-input-socket.component"
import {Socket, SocketEvent, SocketValueType} from "@node-editor/models"

@Component({
    selector: "cm-node-io",
    templateUrl: "./node-io.component.html",
    styleUrls: ["./node-io.component.scss"],
    imports: [
        ColorInputSocketComponent,
        VectorInputSocketComponent,
        NodeSocketComponent,
        ScalarInputSocketComponent,
        PlainInputSocketComponent,
        NgTemplateOutlet,
    ],
})
export class NodeIoComponent {
    readonly $socketComponent = viewChild.required<NodeSocketComponent>("socketComponent")

    readonly $valueType = input.required<SocketValueType>({alias: "valueType"})
    readonly $range = input<ScalarSocketRange>(undefined, {alias: "range"})
    readonly $socket = input.required<Socket>({alias: "socket"})
    readonly $disableEditingIfConnected = input(true, {alias: "disableEditingIfConnected"})

    readonly $value = model<string | number | number[] | undefined>(undefined, {alias: "value"})
    readonly valueChange = output<{
        value: ParameterValue
        type: ParameterType
    }>()

    readonly $active = input<boolean>(true, {alias: "active"})

    constructor() {
        effect(() => {
            const value = this.$active()
            this.hidden = !value
            if (!value) this.connectionChange.emit({type: "deactivate", socket: this.$socket()})
        })
    }

    connected: {value: boolean} = {value: false}

    readonly connectionChange = output<SocketEvent>()

    @HostBinding("hidden")
    private hidden = false

    getSocketPosition(): SocketPosition {
        return this.$socketComponent().getPosition()
    }

    get valueAsNumberArray() {
        return this.$value() as [number, number, number]
    }
    get valueAsNumber() {
        return this.$value() as number
    }
    get valueAsString() {
        return this.$value() as string
    }
}
