<div class="cm-list-content">
    <ng-content select="[cm-list-header]"></ng-content>
    @if ($count() === 0) {
        <div class="cm-no-entities">
            <div class="cm-no-entities-text">
                <i class="far fa-magnifying-glass"></i>
                <div class="cm-no-entities-text-title">No items found.</div>
            </div>
        </div>
    }
    <!-- Grid -->
    @if ($listType() === "grid") {
        <div
            [class.cm-grid-list-large]="$gridSize() === 'large'"
            [class.cm-grid-list-medium]="$gridSize() === 'medium'"
            [class.cm-grid-list-small]="$gridSize() === 'small'"
            [class.cm-grid-list-tiny]="$gridSize() === 'tiny'"
        >
            <ng-container *ngTemplateOutlet="content"></ng-container>
            <ng-container *ngTemplateOutlet="intersectionObserver"></ng-container>
        </div>
    }
    <!-- Table -->
    @if ($listType() === "table") {
        <table>
            <ng-container *ngTemplateOutlet="content"></ng-container>
            <ng-container *ngTemplateOutlet="intersectionObserver"></ng-container>
        </table>
    }
    <!-- This has to be defined as a template in order to make the ng-content work as expected. -->
    <ng-template #content>
        <ng-content></ng-content>
    </ng-template>
    <!-- This has to be defined as a template in order to avoid conflicting IDs. -->
    <ng-template #intersectionObserver>
        <div #cm_intersection_observer_trigger id="cm_intersection_observer_trigger"></div>
    </ng-template>
</div>
