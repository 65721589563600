import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {Geometry, rasterizeGeometry} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-rasterize-geometry"
import {createView} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/utils/create-view"
import {DebugImage} from "@app/textures/texture-editor/operator-stack/image-op-system/utils/debug-image"
import {uploadGeometry} from "@app/textures/texture-editor/operator-stack/image-op-system/utils/rasterize-geometry-helpers"
import {
    Operator,
    OperatorFlags,
    OperatorInput,
    OperatorOutput,
    OperatorPanelComponentType,
    OperatorProcessingHints,
} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorBase} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator-base"
import {OperatorCallback} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {copyRegion} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-copy-region"
import {rotateVectorMap} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/rotate-vector-map"
import {rotateAngleMap} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/rotate-angle-map"
import {
    createGridMappingGeometry,
    ResultType as GridMappingResultType,
} from "@app/textures/texture-editor/operator-stack/operators/tiling/helpers/create-grid-mapping-geometry"
import {determineFeatureWaveLength} from "@app/textures/texture-editor/operator-stack/operators/tiling/helpers/determine-feature-wave-length"
import {featureRayCast} from "@app/textures/texture-editor/operator-stack/operators/tiling/helpers/feature-ray-cast"
import {findBestMatchAlongSegment} from "@app/textures/texture-editor/operator-stack/operators/tiling/helpers/find-best-match-along-segment"
import {CacheData, hierarchicalCrossCorrelation} from "@app/textures/texture-editor/operator-stack/operators/tiling/helpers/hierarchical-cross-correlation"
import {TilingPanelComponent} from "@app/textures/texture-editor/operator-stack/operators/tiling/panel/tiling-panel.component"
import {Vector} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/helper-lines/edit-vector-item"
import {HelperLineItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/helper-lines/helper-line-item"
import {BoundaryCurveControlPoint} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-curve-control-point"
import {ChangeEvent} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-curve-item"
import {BoundaryDirection, BoundaryItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-item"
import {ViewMode} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/spatial-mapping-item"
import {TilingToolbox} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-toolbox"
import {JobNodes} from "@cm/job-nodes"
import {Color, Size2, Size2Like, Vector2, Vector2Like} from "@cm/math"
import {assertNever, deepCopy} from "@cm/utils"
import {AsyncReentrancyGuard} from "@cm/utils/async-reentrancy-guard"
import {Hotkeys} from "@common/services/hotkeys/hotkeys.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"
import {MaskReference} from "app/textures/texture-editor/texture-edit-nodes"
import {BehaviorSubject, filter, merge, Observable, Subject, takeUntil} from "rxjs"
import {TextureType} from "@generated"
import {computeTValuesForBoundary, subdivideTValues} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/utils"
import {computeBorderMaskImage} from "@app/textures/texture-editor/operator-stack/operators/tiling/helpers/compute-border-mask-image"
import {ImageOpCommandQueueWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-webgl2"
import {blendByLaplacianPyramid} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/blend-by-laplacian-pyramid"
import {ManagedDrawableImageHandle} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/drawable-image-handle"
import {colorGradient} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/color-gradient"
import {drawableImage} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-drawable-image"

export class OperatorTiling extends OperatorBase<TextureEditNodes.OperatorTiling> {
    // OperatorBase
    override readonly flags = new Set<OperatorFlags>(["no-clone", "no-disable", "apply-to-all-texture-types"])

    readonly panelComponentType: OperatorPanelComponentType = TilingPanelComponent
    readonly canvasToolbox: TilingToolbox

    readonly type = "operator-tiling" as const

    readonly showGuides$: BehaviorSubject<boolean>
    readonly viewMode$: BehaviorSubject<ViewMode>
    readonly snapToHelperLinesEnabled$: BehaviorSubject<boolean>
    readonly snapToSimilarFeatureEnabled$: BehaviorSubject<boolean>
    readonly snapDistancePx$: BehaviorSubject<number>
    readonly traceHelperLineEditModeEnabled$: BehaviorSubject<boolean>
    readonly boundaryFollowsHelperLinesEnabled$: BehaviorSubject<boolean>
    readonly alignmentSpacingPx$: BehaviorSubject<number>
    readonly alignmentSearchSizeRatio$: BehaviorSubject<number>
    readonly alignmentMinCorrelation$: BehaviorSubject<number>
    readonly alignmentCorrelationPenaltyAlongEdge$: BehaviorSubject<number>
    readonly alignmentCorrelationPenaltyAcrossEdge$: BehaviorSubject<number>
    readonly borderBlendEnabled$: BehaviorSubject<boolean>
    readonly borderBlendDistancePx$: BehaviorSubject<number>
    readonly debugDrawEnabled$ = new BehaviorSubject(false)

    constructor(callback: OperatorCallback, node: TextureEditNodes.OperatorTiling | null) {
        super(
            callback,
            deepCopy(node) ?? {
                type: "operator-tiling",
                enabled: true,
                cornerControlPoints: {
                    topLeft: {
                        positionPx: {
                            x: 0,
                            y: 0,
                        },
                    },
                    topRight: {
                        positionPx: {
                            x: callback.textureEditorData.textureTypeSpecific?.sourceDataObject.width ?? 0,
                            y: 0,
                        },
                    },
                    bottomLeft: {
                        positionPx: {
                            x: 0,
                            y: callback.textureEditorData.textureTypeSpecific?.sourceDataObject.height ?? 0,
                        },
                    },
                    bottomRight: {
                        positionPx: {
                            x: callback.textureEditorData.textureTypeSpecific?.sourceDataObject.width ?? 0,
                            y: callback.textureEditorData.textureTypeSpecific?.sourceDataObject.height ?? 0,
                        },
                    },
                },
                boundaries: {
                    horizontal: {
                        controlPoints: [],
                    },
                    vertical: {
                        controlPoints: [],
                    },
                },
                display: {
                    showGuides: true,
                    viewMode: "source",
                },
                helperLines: {
                    curves: [],
                    boundaryFollows: true,
                },
                alignment: {
                    controlPointSpacingPx: 128,
                    searchSizeRatio: 1,
                    minCorrelation: 0.3,
                    correlationPenaltyAlongEdge: 0.3,
                    correlationPenaltyAcrossEdge: 0.3,
                },
                borderBlending: {
                    enabled: false,
                    widthPx: 128,
                },
            },
        )

        this.uploadService = this.callback.injector.get(UploadGqlService)

        // bidirectional binding from node to UI
        const biBind = <T>(initialValue: T, setter: (value: T) => void, markEdited = true) => {
            const obs = new BehaviorSubject(initialValue)
            let isInitialValue = true
            obs.pipe(takeUntil(this.destroyed)).subscribe((value) => {
                setter(value)
                if (!isInitialValue && markEdited) {
                    this.markEdited()
                }
                isInitialValue = false
            })
            return obs
        }
        this.showGuides$ = biBind(this.node.display.showGuides, (value) => (this.node.display.showGuides = value), false)
        this.viewMode$ = biBind(this.node.display.viewMode as ViewMode, (value) => (this.node.display.viewMode = value), false)
        this.alignmentSpacingPx$ = biBind(this.node.alignment.controlPointSpacingPx, (value) => {
            this.node.alignment.controlPointSpacingPx = value
            this.removeAlignmentInfo()
        })
        this.alignmentSearchSizeRatio$ = biBind(this.node.alignment.searchSizeRatio, (value) => {
            this.node.alignment.searchSizeRatio = value
            this.removeAlignmentInfo()
        })
        this.alignmentCorrelationPenaltyAlongEdge$ = biBind(this.node.alignment.correlationPenaltyAlongEdge ?? 0, (value) => {
            this.node.alignment.correlationPenaltyAlongEdge = value
            this.removeAlignmentInfo()
        })
        this.alignmentCorrelationPenaltyAcrossEdge$ = biBind(this.node.alignment.correlationPenaltyAcrossEdge ?? 0, (value) => {
            this.node.alignment.correlationPenaltyAcrossEdge = value
            this.removeAlignmentInfo()
        })
        this.alignmentMinCorrelation$ = biBind(this.node.alignment.minCorrelation, (value) => {
            this.node.alignment.minCorrelation = value
            this.updateAlignmentControlPoints()
        })
        this.borderBlendEnabled$ = biBind(this.node.borderBlending.enabled, (value) => (this.node.borderBlending.enabled = value))
        this.borderBlendDistancePx$ = biBind(this.node.borderBlending.widthPx, (value) => (this.node.borderBlending.widthPx = value))
        this.boundaryFollowsHelperLinesEnabled$ = biBind(this.node.helperLines?.boundaryFollows ?? false, (value) => {
            if (!this.node.helperLines) {
                this.node.helperLines = {
                    curves: [],
                    boundaryFollows: value,
                }
            } else {
                this.node.helperLines.boundaryFollows = value
            }
            this.removeAlignmentInfo()
        })

        this.canvasToolbox = new TilingToolbox(this)
        this.debugImage = new DebugImage()

        this.snapToHelperLinesEnabled$ = new BehaviorSubject(true)
        this.snapToSimilarFeatureEnabled$ = new BehaviorSubject(true)
        this.snapDistancePx$ = new BehaviorSubject(1024)
        this.traceHelperLineEditModeEnabled$ = new BehaviorSubject(false)
        this.traceHelperLineEditModeEnabled$.subscribe((value) => this.onTraceHelperLineEditModeChange(value))
        this.performTraceStep$.subscribe((traceInfo) => this.performTraceStep(traceInfo))

        this.canvasToolbox.helperLinesBag.traceVectorCreated.subscribe((vector) => this.onTraceVectorCreated(vector))
        merge(this.canvasToolbox.helperLinesBag.helperLineCreated, this.canvasToolbox.helperLinesBag.helperLineRemoved).subscribe(() => this.markEdited())

        // restore helper lines
        if (this.node.helperLines) {
            for (const curve of this.node.helperLines.curves) {
                const points = curve.points.map((point) => Vector2.fromVector2Like(point))
                const helperLine = this.canvasToolbox.helperLinesBag.createHelperLine(curve.origin ?? points[0], curve.target ?? points[1])
                helperLine.setPoints(points)
                helperLine.state = "finished"
            }
        }

        const applyHotkeyPipe = <T>(obs: Observable<T>) =>
            obs.pipe(
                takeUntil(this.destroyed),
                filter(() => this.callback.selectedOperator === this),
            )
        const hotkeys = this.callback.injector.get(Hotkeys)
        applyHotkeyPipe(hotkeys.addShortcut(["k"])).subscribe(() => this.viewMode$.next(ViewMode.Source))
        applyHotkeyPipe(hotkeys.addShortcut(["l"])).subscribe(() => this.viewMode$.next(ViewMode.Result))
        applyHotkeyPipe(hotkeys.addShortcut(["s"])).subscribe(() => this.toggleSnapMode())
        applyHotkeyPipe(hotkeys.addShortcut(["v"])).subscribe(() => this.showGuides$.next(!this.showGuides$.value))

        this.canvasToolbox.tilingArea.areaChanged.subscribe((event) => this.onTilingAreaChanged(event))

        // make sure we remove the alignment data when the user changes the control points or the boundary has changed
        const pointVizBagItem = this.canvasToolbox.tilingArea.spatialMapping.pointVizBagItem
        merge(pointVizBagItem.pointCreated, pointVizBagItem.pointRemoved, pointVizBagItem.pointMoved)
            .pipe(
                filter((pointVizItem) => pointVizItem.type === "user"),
                takeUntil(this.unsubscribe),
            )
            .subscribe(() => this.removeAlignmentInfo())

        merge(this.viewMode$, this.debugDrawEnabled$).subscribe(() => this.requestEval())
        // dragEnd is needed here because we don't want to border blend during dragging which needs recalc of the grid
        merge(this.borderBlendDistancePx$, pointVizBagItem.dragEnd).subscribe(() => {
            this.invalidateBorderBlendMask() // we need to new mask if the border changes
            this.invalidateCachedGrid() // we need to new grid if the border changes
            this.requestEval()
        })
        this.borderBlendEnabled$.subscribe(() => {
            this.invalidateCachedGrid() // we need to new grid if the border changes
            this.requestEval()
        })

        const getUploadedGeometryDescriptor = (uploadedGridMapping: TextureEditNodes.OperatorTiling["uploadedGridMapping"]) => {
            return uploadedGridMapping
                ? {
                      dataObjectReference: this.callback.texturesApi
                          .getDataObjectLegacyId(uploadedGridMapping.meshDataObject.dataObjectId)
                          .then((legacyId): DataObjectReference => ({id: uploadedGridMapping!.meshDataObject.dataObjectId, legacyId})),
                      resultSize: uploadedGridMapping.resultSize,
                  }
                : undefined
        }
        this.uploadedGridGeometry = getUploadedGeometryDescriptor(this.node.uploadedGridMapping)

        this.resetEdited()
    }

    // OperatorBase
    override async init() {
        await super.init()

        const createBorderBlendMaskHandle = async (maskReference: TextureEditNodes.MaskReference | undefined) => {
            if (!maskReference) {
                return undefined
            }
            return this.callback.imageOpContextWebGL2.createDrawableImageHandle({
                dataObjectId: maskReference.dataObjectId,
                channelLayout: "R",
                dataType: "uint8",
            })
        }
        const [horizontalBorderBlendMask, verticalBorderBlendMask] = await Promise.all([
            createBorderBlendMaskHandle(this.node.borderBlending.horizontalMask),
            createBorderBlendMaskHandle(this.node.borderBlending.verticalMask),
        ])
        this.horizontalBorderBlendMask = horizontalBorderBlendMask
        this.verticalBorderBlendMask = verticalBorderBlendMask
    }

    // OperatorBase
    override dispose(): void {
        this.destroyed.next()
        this.destroyed.complete()
        super.dispose()
        this.invalidateBorderBlendMask()
        this.canvasToolbox.remove()
        this.debugImage.dispose()
        this.hierarchicalCrossCorrelationCacheData.dispose()
    }

    // OperatorBase
    async clone(): Promise<Operator> {
        throw Error("Tiling operator can not be cloned")
    }

    // OperatorBase
    async queueImageOps(cmdQueue: ImageOpCommandQueue, input: OperatorInput, hints: OperatorProcessingHints): Promise<OperatorOutput> {
        cmdQueue.beginScope(this.type)
        if (hints.inputChanged) {
            // the input has changed; let's invalidate the cache
            this.hierarchicalCrossCorrelationCacheData.dispose()
        }
        let resultImage: ImageRef
        if (cmdQueue.mode === "preview" && this.selected && this.viewMode$.value === ViewMode.Source) {
            resultImage = input
        } else {
            // mapping
            const spatialMapping = this.canvasToolbox.tilingArea.spatialMapping
            const borderBlendEnabled = this.borderBlendEnabled$.value && !spatialMapping.pointVizBagItem.isDraggingControlPoint
            const borderBlendDistance = borderBlendEnabled ? Math.round(Math.max(this.minBorderBlendingPx, this.borderBlendDistancePx$.value)) : 0
            if (!this.cachedGridGeometry) {
                // let tRangeU: [number, number]
                // let tRangeV: [number, number]
                // if (borderBlendDistance > 0) {
                //     const tBorderH = borderBlendDistance / spatialMapping.mappedSize.x
                //     const tBorderV = borderBlendDistance / spatialMapping.mappedSize.y
                //     tRangeU = [-tBorderH, 1 + tBorderH]
                //     tRangeV = [-tBorderV, 1 + tBorderV]
                // } else {
                //     tRangeU = [0, 1]
                //     tRangeV = [0, 1]
                // }
                // const tessellatedGridPoints = spatialMapping.computeGridPoints(tRangeU, tRangeV)
                let tValuesH = computeTValuesForBoundary(spatialMapping.boundaryH)
                let tValuesV = computeTValuesForBoundary(spatialMapping.boundaryV)
                if (borderBlendDistance > 0) {
                    const tBorderH = borderBlendDistance / spatialMapping.mappedSize.x
                    const tBorderV = borderBlendDistance / spatialMapping.mappedSize.y
                    tValuesH = [-tBorderH, ...tValuesH, 1 + tBorderH]
                    tValuesV = [-tBorderV, ...tValuesV, 1 + tBorderV]
                }
                while (tValuesH.length < 16) {
                    tValuesH = subdivideTValues(tValuesH, this.numGridControlPointSegmentSubdivisions)
                }
                while (tValuesV.length < 16) {
                    tValuesV = subdivideTValues(tValuesV, this.numGridControlPointSegmentSubdivisions)
                }
                const tessellatedGridPoints = spatialMapping.computeGridPoints(tValuesH, tValuesV)
                this.cachedGridGeometry = createGridMappingGeometry({
                    gridPoints: tessellatedGridPoints,
                    targetPixelOffset: {
                        x: borderBlendDistance,
                        y: borderBlendDistance,
                    },
                })
            }
            let gridGeometry: Geometry | JobNodes.DataObjectReference
            let resultSize: Size2Like
            if (cmdQueue.mode === "preview") {
                gridGeometry = this.cachedGridGeometry.geometry
                resultSize = this.cachedGridGeometry.resultSize
            } else {
                // final
                if (!this.uploadedGridGeometry) {
                    // upload the geometry so we can reuse it for the different texture types (rather than having them embedded in every image-processing graph)
                    const organizationId = this.callback.organization.id
                    const promisedDataObjectReference = uploadGeometry(
                        this.uploadService,
                        organizationId,
                        this.cachedGridGeometry.geometry,
                        "grid-geometry.json",
                    ).then(
                        (result): DataObjectReference => ({
                            id: result.id,
                            legacyId: result.legacyId,
                        }),
                    )
                    this.uploadedGridGeometry = {
                        dataObjectReference: promisedDataObjectReference,
                        resultSize: this.cachedGridGeometry.resultSize,
                    }
                }
                gridGeometry = await this.uploadedGridGeometry.dataObjectReference.then((dataObjectReference) =>
                    JobNodes.dataObjectReference(dataObjectReference.legacyId),
                )
                resultSize = this.uploadedGridGeometry.resultSize
            }
            resultImage = rasterizeGeometry(cmdQueue, {
                geometry: gridGeometry,
                textureImage: input,
                resultImageOrDescriptor: {
                    ...input.descriptor,
                    ...resultSize,
                },
            })

            // border blending
            if (borderBlendDistance > 0) {
                const blendBorder = async (sourceImage: ImageRef, direction: BoundaryDirection, borderWidth: number, regenerateMaskImage: boolean) => {
                    const borderImageSize = new Size2(
                        direction === BoundaryDirection.Horizontal ? sourceImage.descriptor.width : borderWidth,
                        direction === BoundaryDirection.Vertical ? sourceImage.descriptor.height : borderWidth,
                    )
                    const combinedBorderImageSize = borderImageSize.mul({
                        width: direction === BoundaryDirection.Horizontal ? 1 : 2,
                        height: direction === BoundaryDirection.Vertical ? 1 : 2,
                    })
                    const highBorderImageOffset = new Vector2(
                        direction === BoundaryDirection.Horizontal ? 0 : borderWidth,
                        direction === BoundaryDirection.Vertical ? 0 : borderWidth,
                    )

                    // low border
                    const foregroundImage = createView(resultImage, {
                        ...Vector2.zero,
                        ...combinedBorderImageSize,
                    })

                    // high border
                    const backgroundImage = createView(resultImage, {
                        ...Vector2.fromSize2Like(resultImage.descriptor).subInPlace(Vector2.fromSize2Like(combinedBorderImageSize)),
                        ...combinedBorderImageSize,
                    })

                    let maskImageHandle = direction === BoundaryDirection.Horizontal ? this.horizontalBorderBlendMask : this.verticalBorderBlendMask
                    let maskImage: ImageRef | undefined = undefined
                    if (maskImageHandle) {
                        maskImage = drawableImage(cmdQueue, {
                            drawableImageHandle: maskImageHandle.ref,
                        })
                        if (maskImage.descriptor.width !== combinedBorderImageSize.width || maskImage.descriptor.height !== combinedBorderImageSize.height) {
                            // wrong size, regenerate
                            maskImage = undefined
                        }
                    }
                    if (regenerateMaskImage && !maskImage) {
                        maskImageHandle = await this.callback.imageOpContextWebGL2.createDrawableImageHandle({
                            ...combinedBorderImageSize,
                            channelLayout: "R",
                            dataType: "uint8",
                        })
                        if (direction === BoundaryDirection.Horizontal) {
                            this.horizontalBorderBlendMask?.release()
                            this.horizontalBorderBlendMask = maskImageHandle
                        } else {
                            this.verticalBorderBlendMask?.release()
                            this.verticalBorderBlendMask = maskImageHandle
                        }
                        maskImage = drawableImage(cmdQueue, {
                            drawableImageHandle: maskImageHandle.ref,
                        })
                        if (!(cmdQueue instanceof ImageOpCommandQueueWebGL2)) {
                            throw new Error("Regeneration of mask image is only supported in WebGL2 mode")
                        }
                        const newMaskImage = computeBorderMaskImage(cmdQueue, {
                            backgroundImage,
                            foregroundImage,
                            direction,
                        })
                        maskImage = copyRegion(cmdQueue, {
                            sourceImage: newMaskImage,
                            resultImageOrDataType: maskImage,
                        })
                    }
                    // simple transition as fallback
                    if (!maskImage) {
                        const normalizedTransitionWidth = 2 / (borderWidth * 2) // 2px
                        maskImage = colorGradient(cmdQueue, {
                            resultImageOrDescriptor: {
                                ...combinedBorderImageSize,
                                channelLayout: "R",
                                dataType: "uint8",
                            },
                            type: "linear",
                            startPos: Vector2.zero,
                            endPos: highBorderImageOffset.mul(2),
                            stops: [
                                {t: 0, color: new Color(0)},
                                {t: 0.5 - normalizedTransitionWidth / 2, color: new Color(0)},
                                {t: 0.5 + normalizedTransitionWidth / 2, color: new Color(1)},
                                {t: 1, color: new Color(1)},
                            ],
                        })
                    }

                    let blendedBorder: ImageRef
                    if (this.debugDrawEnabled$.value) {
                        blendedBorder = maskImage
                    } else {
                        blendedBorder = blendByLaplacianPyramid(cmdQueue, {
                            backgroundImage,
                            foregroundImage,
                            maskImage,
                        }).resultImage
                    }

                    resultImage = createView(resultImage, {
                        ...highBorderImageOffset,
                        ...Size2.fromSize2Like(resultImage.descriptor).subInPlace(Size2.fromVector2Like(highBorderImageOffset.mul(2))),
                    })
                    resultImage = copyRegion(cmdQueue, {
                        sourceImage: blendedBorder,
                        sourceRegion: {
                            ...Vector2.zero,
                            ...borderImageSize,
                        },
                        targetOffset: Vector2.fromSize2Like(resultImage.descriptor).subInPlace(Vector2.fromSize2Like(borderImageSize)),
                        resultImageOrDataType: resultImage,
                    })
                    resultImage = copyRegion(cmdQueue, {
                        sourceImage: blendedBorder,
                        sourceRegion: {
                            ...highBorderImageOffset,
                            ...borderImageSize,
                        },
                        targetOffset: Vector2.zero,
                        resultImageOrDataType: resultImage,
                    })

                    return resultImage
                }
                const allowRegenerateMask = cmdQueue.mode === "preview"
                resultImage = await blendBorder(resultImage, BoundaryDirection.Horizontal, borderBlendDistance, allowRegenerateMask)
                resultImage = await blendBorder(resultImage, BoundaryDirection.Vertical, borderBlendDistance, allowRegenerateMask)
            }

            // normal and anisotropy map correction
            const angleInDegrees = this.computeAverageMappingAngleInDegrees()
            if (hints.textureType === TextureType.Normal) {
                // normal maps need to be rotated as well to keep consistent
                resultImage = rotateVectorMap(cmdQueue, {sourceImage: resultImage, angleInDegrees: angleInDegrees, angleFactor: -1}) // we need to rotate the opposite direction
            } else if (hints.textureType === TextureType.Anisotropy) {
                // anisotropy maps need to be rotated as well to keep consistent
                resultImage = rotateVectorMap(cmdQueue, {sourceImage: resultImage, angleInDegrees: angleInDegrees, angleFactor: -2}) // we need to rotate by twice the angle because anisotropy is periodic in 180°
            } else if (hints.textureType === TextureType.AnisotropyRotation) {
                // anisotropy rotation map need to be rotated as well to keep consistent
                resultImage = rotateAngleMap(cmdQueue, {sourceImage: resultImage, angleInDegrees: angleInDegrees, angleFactor: -1}) // anisotropy is periodic in 180° but the map stores only 0..0.5 range
            }
        }
        // draw debug image
        if (cmdQueue.mode === "preview" && this.debugImage.isInited && this.debugDrawEnabled$.value) {
            resultImage = copyRegion(cmdQueue, {
                sourceImage: resultImage,
            })
            resultImage = copyRegion(cmdQueue, {
                sourceImage: this.debugImage.imageRef,
                resultImageOrDataType: resultImage,
            })
        }
        cmdQueue.endScope(this.type)
        return {resultImage}
    }

    private invalidateBorderBlendMask() {
        this.horizontalBorderBlendMask?.release()
        this.horizontalBorderBlendMask = undefined
        this.verticalBorderBlendMask?.release()
        this.verticalBorderBlendMask = undefined
    }

    private onTraceHelperLineEditModeChange(enabled: boolean) {
        if (enabled) {
            this.showGuides$.next(true) // make sure guides are shown when tracing
        }
        this.canvasToolbox.helperLinesBag.setHelperLineTracingMode(enabled)
    }

    private onTraceVectorCreated(vector: Vector) {
        this.traceHelperLineEditModeEnabled$.next(false)
        this.traceHelperLine(vector)
    }

    private traceHelperLine(vector: Vector) {
        const helperLine = this.canvasToolbox.helperLinesBag.createHelperLine(vector.from, vector.to)
        this.performTraceStep$.next({helperLine, state: "init"})
        return helperLine
    }

    private async performTraceStep(traceInfo: TraceInfo) {
        const minCorrelationToProceed = 0.1

        const imageOpContextWebGL2 = this.callback.imageOpContextWebGL2
        const sourceImageRef = this.callback.selectedOperatorInput
        if (!sourceImageRef) {
            throw new Error("No source image")
        }

        const {helperLine, state} = traceInfo
        if (helperLine.isRemoved) {
            return
        }
        this.markEdited()
        if (state === "init") {
            // console.log("Starting feature tracing")
            // console.log(
            //     `Starting at (${helperLine.sourceCastRayOrigin.x}, ${helperLine.sourceCastRayOrigin.y}) to (${helperLine.sourceCastRayTarget.x}, ${helperLine.sourceCastRayTarget.y})`,
            // )
            // this is the first step; let's determine the ideal wavelength
            const result = await determineFeatureWaveLength(imageOpContextWebGL2, {
                sourceImage: sourceImageRef,
                rayOrigin: helperLine.sourceCastRayOrigin,
                rayTarget: helperLine.sourceCastRayTarget,
            })
            helperLine.rescaleSourceTargetDistance(result.waveLength)
            // console.log(`Ideal wave length is ${result.waveLength} with energy ${result.energy}`)

            // result.details.correlationData.forEach((correlation, index) => {
            //     const from = Vector2.fromVector2Like(result.details.searchSegment.from)
            //     const to = Vector2.fromVector2Like(result.details.searchSegment.to)
            //     const referenceLength = Math.round(Vector2.fromVector2Like(result.details.referenceSegment.to).sub(result.details.referenceSegment.from).norm())
            //     const searchLength = Math.round(to.sub(from).norm())
            //     const searchDir = to.sub(from).normalized()
            //     const pos = searchDir.mul(((searchLength - referenceLength) * index) / (result.details.correlationData.length - 1)).add(from)
            //     const dirPerp = to.sub(from).perp().normalized()
            //     new LineItem(this.canvasToolbox, [pos, pos.add(dirPerp.mul(correlation * 10))], 1, new Color(1, 0, 0))
            // })
            // new ArrowItem(this.canvasToolbox, result.details.searchSegment.from, result.nextPosition, 1, new Color(Math.random(), Math.random(), Math.random()))

            // trace in both directions
            helperLine.addTailPoint(helperLine.sourceCastRayOrigin)
            this.performTraceStep$.next({helperLine, state: "forward-trace"})
            this.performTraceStep$.next({helperLine, state: "backward-trace"})
        } else {
            const traceLength = Vector2.distance(helperLine.sourceCastRayOrigin, helperLine.sourceCastRayTarget)
            const linePoints = helperLine.points
            let referenceRayOrigin: Vector2
            let referenceRayTarget: Vector2
            let rayOrigin: Vector2
            let rayDirection: Vector2
            switch (state) {
                case "forward-trace":
                    referenceRayOrigin = helperLine.sourceCastRayOrigin
                    referenceRayTarget = helperLine.sourceCastRayTarget
                    if (linePoints.length >= 2) {
                        const lastPoint = linePoints[linePoints.length - 1]
                        const secondLastPoint = linePoints[linePoints.length - 2]
                        rayOrigin = lastPoint
                        rayDirection = lastPoint.sub(secondLastPoint).normalized()
                    } else {
                        rayOrigin = helperLine.sourceCastRayOrigin
                        rayDirection = helperLine.sourceCastRayTarget.sub(rayOrigin).normalized()
                    }
                    break
                case "backward-trace":
                    referenceRayOrigin = helperLine.sourceCastRayTarget
                    referenceRayTarget = helperLine.sourceCastRayOrigin
                    if (linePoints.length >= 2) {
                        const firstPoint = linePoints[0]
                        const secondPoint = linePoints[1]
                        rayOrigin = firstPoint
                        rayDirection = firstPoint.sub(secondPoint).normalized()
                    } else {
                        rayOrigin = helperLine.sourceCastRayOrigin
                        rayDirection = helperLine.sourceCastRayOrigin.sub(helperLine.sourceCastRayTarget).normalized()
                    }
                    break
                default:
                    assertNever(state)
            }
            const rayTarget = rayOrigin.add(rayDirection.mul(traceLength))

            const result = await featureRayCast(imageOpContextWebGL2, {
                sourceImage: sourceImageRef,
                sourceCastRayOrigin: referenceRayOrigin,
                sourceCastRayTarget: referenceRayTarget,
                currentCastRayOrigin: rayOrigin,
                currentCastRayTarget: rayTarget,
            })

            // console.log(`Best peak along line at (${result.nextPosition.x}, ${result.nextPosition.y}) with value ${result.details.peakValue}`)
            // const showDebugInfo = true
            // if (showDebugInfo) {
            // result.details.correlationData.forEach((correlation, index) => {
            //     const from = Vector2.fromVector2Like(result.details.searchSegment.from)
            //     const to = Vector2.fromVector2Like(result.details.searchSegment.to)
            //     const referenceLength = Math.round(Vector2.fromVector2Like(result.details.referenceSegment.to).sub(result.details.referenceSegment.from).norm())
            //     const searchLength = Math.round(to.sub(from).norm())
            //     const searchDir = to.sub(from).normalized()
            //     const pos = searchDir.mul(((searchLength - referenceLength) * index) / (result.details.correlationData.length - 1)).add(from)
            //     const dirPerp = to.sub(from).perp().normalized()
            //     new LineItem(this.canvasToolbox, [pos, pos.add(dirPerp.mul(correlation * 10))], 1, new Color(1, 0, 0))
            // })
            // new ArrowItem(this.canvasToolbox, helperLine.currentCastRayOrigin, result.nextPosition, 4, new Color(Math.random(), Math.random(), Math.random()))
            // }

            let doneTracing: boolean
            if (result && result.details.peakValue >= minCorrelationToProceed) {
                const newOrigin = result.nextPosition
                if (newOrigin.x >= 0 && newOrigin.y >= 0 && newOrigin.x < sourceImageRef.descriptor.width && newOrigin.y < sourceImageRef.descriptor.height) {
                    switch (state) {
                        case "forward-trace":
                            helperLine.addTailPoint(newOrigin)
                            this.performTraceStep$.next(traceInfo) // continue tracing
                            break
                        case "backward-trace":
                            helperLine.addHeadPoint(newOrigin)
                            this.performTraceStep$.next(traceInfo) // continue tracing
                            break
                        default:
                            assertNever(state)
                    }
                    doneTracing = false
                } else {
                    doneTracing = true
                }
            } else {
                doneTracing = true
            }
            if (doneTracing) {
                switch (state) {
                    case "forward-trace":
                        helperLine.state = helperLine.state === "tracing-bidirectional" ? "tracing-backward" : "finished"
                        break
                    case "backward-trace":
                        helperLine.state = helperLine.state === "tracing-bidirectional" ? "tracing-forward" : "finished"
                        break
                    default:
                        assertNever(state)
                }
            }
        }
    }

    private computeAverageMappingAngleInDegrees() {
        const tilingArea = this.canvasToolbox.tilingArea
        const lowHorizontalCurveControlPoints = tilingArea.spatialMapping.boundaryH.curveMin.curveControlPoints
        const highHorizontalCurveControlPoints = tilingArea.spatialMapping.boundaryH.curveMax.curveControlPoints
        const p00 = lowHorizontalCurveControlPoints[0].controlPoint.sourcePosition
        const p10 = lowHorizontalCurveControlPoints[lowHorizontalCurveControlPoints.length - 1].controlPoint.sourcePosition
        const p01 = highHorizontalCurveControlPoints[0].controlPoint.sourcePosition
        const p11 = highHorizontalCurveControlPoints[highHorizontalCurveControlPoints.length - 1].controlPoint.sourcePosition
        const h0 = Vector2.fromVector2Like(p10).subInPlace(p00)
        const h1 = Vector2.fromVector2Like(p11).subInPlace(p01)
        const v0 = Vector2.fromVector2Like(p01).subInPlace(p00)
        const v1 = Vector2.fromVector2Like(p11).subInPlace(p10)
        // average vectors in order to get the average angle
        const avgH = h0.add(h1)
        const avgV = v0.add(v1)
        const avgVector = avgH.sub(avgV.perp())
        const avgAngle = -Math.atan2(avgVector.y, avgVector.x)
        return avgAngle * (180 / Math.PI)
    }

    async createAlignmentInfo() {
        // in case the texture type has changed, we need to remove the alignment info and recompute it
        const textureType = this.callback.textureEditorData.textureTypeSpecific?.textureType
        if (!textureType) {
            throw new Error("Texture type is not available")
        }
        if (this.alignmentInfo && this.alignmentInfo.textureType !== textureType) {
            this.removeAlignmentInfo()
        }
        if (!this.isAlignmentDataAvailable) {
            // TODO
            // this.callback.setBusy(true)
            // const tilingArea = this.canvasToolbox.tilingArea
            //
            // const adjustBoundary = (boundaryDirection: BoundaryDirection) => {
            //     const searchDistance = (this.alignmentSpacingPx$.value / 2) * this.alignmentSearchSizeRatio$.value
            //     const distancePenalty = new Vector2(this.alignmentCorrelationPenaltyAlongEdge$.value, this.alignmentCorrelationPenaltyAcrossEdge$.value)
            //     const boundary = tilingArea.spatialMapping.getBoundary(boundaryDirection)
            //     const controlPointsLow = boundary.curveMin.curveControlPoints
            //     const controlPointsHigh = boundary.curveMax.curveControlPoints
            //     const promisedSnapResults: (() => Promise<AlignmentPointInfo | undefined>)[] = []
            //     for (let i = 1; i < controlPointsLow.length; i++) {
            //         const posLowPrev = controlPointsLow[i - 1]
            //         const posLowNext = controlPointsLow[i]
            //         const posHighPrev = controlPointsHigh[i - 1]
            //         const posHighNext = controlPointsHigh[i]
            //         const tPrev = (posLowPrev.t + posHighPrev.t) / 2
            //         const tNext = (posLowNext.t + posHighNext.t) / 2
            //         const sourcePositionLowDelta = Vector2.fromVector2Like(posLowNext.controlPoint.sourcePosition).subInPlace(
            //             posLowPrev.controlPoint.sourcePosition,
            //         )
            //         const sourcePositionHighDelta = Vector2.fromVector2Like(posHighNext.controlPoint.sourcePosition).subInPlace(
            //             posHighPrev.controlPoint.sourcePosition,
            //         )
            //         const sourcePositionAvgLength = (sourcePositionLowDelta.norm() + sourcePositionHighDelta.norm()) / 2
            //         const numPointsToInsert = Math.floor(sourcePositionAvgLength / this.alignmentSpacingPx$.value)
            //         for (let j = 1; j <= numPointsToInsert; j++) {
            //             const interpolator = j / (numPointsToInsert + 1)
            //             const t = tPrev * (1 - interpolator) + tNext * interpolator
            //             const referencePosition = Vector2.fromVector2Like(posLowPrev.controlPoint.sourcePosition).addInPlace(
            //                 sourcePositionLowDelta.mul(interpolator),
            //             )
            //             const originalPosition = Vector2.fromVector2Like(posHighPrev.controlPoint.sourcePosition).addInPlace(
            //                 sourcePositionHighDelta.mul(interpolator),
            //             )
            //             const boundarySlope = boundary.curveMax.getTangent(t)
            //             const promisedSnapResultFn = () =>
            //                 this.snapToSimilarFeature(originalPosition, referencePosition, searchDistance, distancePenalty, boundarySlope).then(
            //                     (snapResult): AlignmentPointInfo | undefined => {
            //                         return snapResult
            //                             ? {
            //                                   boundaryDirection,
            //                                   t,
            //                                   referencePosition,
            //                                   originalPosition,
            //                                   snapResult,
            //                                   boundarySlope,
            //                               }
            //                             : undefined
            //                     },
            //                 )
            //             promisedSnapResults.push(promisedSnapResultFn)
            //         }
            //     }
            //     return promisedSnapResults
            // }
            //
            // this.removeAlignmentInfo()
            // const promisedAlignmentPointFns = [...adjustBoundary(BoundaryDirection.Horizontal), ...adjustBoundary(BoundaryDirection.Vertical)]
            // const maxConcurrency = 4
            // const alignmentPointInfos = await asyncBatchExec(promisedAlignmentPointFns, maxConcurrency).then((results) => results.filter(IsDefined))
            // this.alignmentInfo = {
            //     textureType,
            //     points: alignmentPointInfos,
            // }
            // this.callback.setBusy(false)
            const result = await this.alignBoundaries()
            this.alignmentInfo = {
                textureType,
                points: result?.alignmentPointInfos ?? [],
                alignmentQuality: Math.max(0, result?.minCorrelation ?? 0),
            }
        }
        this.updateAlignmentControlPoints()
    }

    removeAlignmentInfo() {
        this.removeAlignmentControlPoints()
        this.alignmentInfo = undefined
    }

    updateAlignmentControlPoints() {
        if (!this.alignmentInfo) {
            return
        }
        const tilingArea = this.canvasToolbox.tilingArea
        for (const pointInfo of this.alignmentInfo.points) {
            const boundary = tilingArea.spatialMapping.getBoundary(pointInfo.boundaryDirection)
            const isAccepted = pointInfo.snapResult.correlation >= this.alignmentMinCorrelation$.value
            if (isAccepted) {
                if (!pointInfo.curveControlPoints) {
                    pointInfo.curveControlPoints = boundary.insertControlPoints(
                        "alignment",
                        pointInfo.t,
                        pointInfo.referencePosition,
                        pointInfo.snapResult.snappedPosition,
                    )
                } else {
                    pointInfo.curveControlPoints.curveControlPoint1.controlPoint.sourcePosition = pointInfo.snapResult.snappedPosition
                }
            } else {
                if (pointInfo.curveControlPoints) {
                    tilingArea.spatialMapping.pointVizBagItem.deleteControlPoints([
                        pointInfo.curveControlPoints.curveControlPoint0.controlPoint,
                        pointInfo.curveControlPoints.curveControlPoint1.controlPoint,
                    ])
                    pointInfo.curveControlPoints = undefined
                }
            }
        }
    }

    removeAlignmentControlPoints() {
        this.canvasToolbox?.tilingArea.spatialMapping.pointVizBagItem.deleteControlPointsOfType("alignment")
        this.alignmentInfo?.points.forEach((pointInfo) => (pointInfo.curveControlPoints = undefined))
    }

    get isAlignmentDataAvailable() {
        return this.alignmentInfo !== undefined
    }

    get hasAlignmentControlPoints() {
        return this.canvasToolbox.tilingArea.spatialMapping.pointVizBagItem.hasControlPointsOfType("alignment")
    }

    get hasLowQualityAlignment() {
        if (!this.alignmentInfo) {
            return false
        }
        return this.alignmentInfo.alignmentQuality < 0.1
    }

    get hasHelperLines() {
        return this.canvasToolbox.helperLinesBag.helperLines.length > 0
    }

    get selectedHelperLines() {
        return this.canvasToolbox.helperLinesBag.helperLines.filter((helperLine) => helperLine.selected)
    }

    async retraceSelectedHelperLines() {
        const selectedHelperLines = this.selectedHelperLines
        for (const helperLine of selectedHelperLines) {
            this.canvasToolbox.helperLinesBag.removeHelperLine(helperLine)
            const newHelperLine = this.traceHelperLine({from: helperLine.sourceCastRayOrigin, to: helperLine.sourceCastRayTarget})
            newHelperLine.selected = true
        }
    }

    // convertAlignmentToUserPoints() {
    //     const getAlignmentPointInfos = (boundary: BoundaryItem) => {
    //         const alignmentIndices = boundary.curveMin.curveControlPoints
    //             .map((curveControlPoint, index) => [curveControlPoint, index] as const)
    //             .filter((curveControlPointAndIndex) => curveControlPointAndIndex[0].controlPoint.type === "alignment")
    //             .map((curveControlPointAndIndex) => curveControlPointAndIndex[1])
    //         return alignmentIndices.map((index) => {
    //             const minCurveControlPoint = boundary.curveMin.curveControlPoints[index]
    //             const maxCurveControlPoint = boundary.curveMax.curveControlPoints[index]
    //             return [minCurveControlPoint, maxCurveControlPoint] as const
    //         })
    //     }
    //     const insertAlignmentPointsAsUserPoints = (
    //         boundary: BoundaryItem,
    //         minAndMaxPoint: (readonly [BoundaryCurveControlPoint, BoundaryCurveControlPoint])[],
    //     ) => {
    //         minAndMaxPoint.forEach(([minCurveControlPoint, maxCurveControlPoint]) => {
    //             boundary.insertControlPoints(
    //                 "user",
    //                 minCurveControlPoint.t,
    //                 minCurveControlPoint.controlPoint.sourcePosition,
    //                 maxCurveControlPoint.controlPoint.sourcePosition,
    //             )
    //         })
    //     }
    //     const tilingArea = this.canvasToolbox.tilingArea
    //     const alignmentPointInfosH = getAlignmentPointInfos(tilingArea.spatialMapping.boundaryH)
    //     const alignmentPointInfosV = getAlignmentPointInfos(tilingArea.spatialMapping.boundaryV)
    //     this.removeAlignmentControlPoints()
    //     this.removeAlignmentInfo()
    //     insertAlignmentPointsAsUserPoints(tilingArea.spatialMapping.boundaryH, alignmentPointInfosH)
    //     insertAlignmentPointsAsUserPoints(tilingArea.spatialMapping.boundaryV, alignmentPointInfosV)
    // }

    // updateBoundaryByHelperLines() {
    //     const helperLinesBag = this.canvasToolbox.helperLinesBag
    //     const boundaryH = this.canvasToolbox.tilingArea.spatialMapping.boundaryH
    //     const boundaryV = this.canvasToolbox.tilingArea.spatialMapping.boundaryV
    //     const updateCurve = (curve: BoundaryCurveItem) => {
    //         // find the closest helper line to the control points
    //         const bestHelperLineAndDistance = helperLinesBag.helperLines.reduce(
    //             (bestHelperLineAndSum, helperLine) => {
    //                 const sumDistance = curve.curveControlPoints.reduce((sum, curveControlPoint) => {
    //                     const result = helperLine.getClosestPointAndOffsetToLocation(curveControlPoint.controlPoint.sourcePosition)
    //                     const distance = Vector2.distance(result.closestPoint, curveControlPoint.controlPoint.sourcePosition)
    //                     return sum + distance
    //                 }, 0)
    //                 if (!bestHelperLineAndSum || sumDistance < bestHelperLineAndSum[1]) {
    //                     return [helperLine, sumDistance] as const
    //                 } else {
    //                     return bestHelperLineAndSum
    //                 }
    //             },
    //             undefined as readonly [HelperLineItem, number] | undefined,
    //         )
    //         // align segments
    //         if (bestHelperLineAndDistance) {
    //             const bestHelperLine = bestHelperLineAndDistance[0]
    //             const helperLineInfoPerControlPoint = curve.curveControlPoints.map((curveControlPoint) => {
    //                 const closestPointAndOffset = bestHelperLine.getClosestPointAndOffsetToLocation(curveControlPoint.controlPoint.sourcePosition)
    //                 const tangent = bestHelperLine.getTangentAtOffset(closestPointAndOffset.offset).normalized()
    //                 const normal = tangent.perp()
    //                 return {...closestPointAndOffset, tangent, normal}
    //             })
    //             const curveGuidePoints: [BoundaryControlPoint, number][] = []
    //             for (let i = 1; i < curve.curveControlPoints.length; i++) {
    //                 const controlPoint0 = curve.curveControlPoints[i - 1]
    //                 const controlPoint1 = curve.curveControlPoints[i]
    //                 const helperLineInfo0 = helperLineInfoPerControlPoint[i - 1]
    //                 const helperLineInfo1 = helperLineInfoPerControlPoint[i]
    //                 const controlSegmentDistance0 = Vector2.dot(
    //                     helperLineInfo0.normal,
    //                     controlPoint0.controlPoint.sourcePosition.sub(helperLineInfo0.closestPoint),
    //                 )
    //                 const controlSegmentDistance1 = Vector2.dot(
    //                     helperLineInfo1.normal,
    //                     controlPoint1.controlPoint.sourcePosition.sub(helperLineInfo1.closestPoint),
    //                 )
    //                 const helperLinePoints = bestHelperLine.getPointsForInterval(helperLineInfo0.offset, helperLineInfo1.offset)
    //                 if (helperLinePoints.length < 2) {
    //                     throw new Error("Helper line has too few points")
    //                 }
    //                 let helperLineSegmentLength = 0
    //                 for (let j = 1; j < helperLinePoints.length; j++) {
    //                     helperLineSegmentLength += Vector2.distance(helperLinePoints[j - 1], helperLinePoints[j])
    //                 }
    //                 let currentHelperLineLength = 0
    //                 for (let j = 1; j < helperLinePoints.length - 1; j++) {
    //                     currentHelperLineLength += Vector2.distance(helperLinePoints[j - 1], helperLinePoints[j])
    //                     const t = currentHelperLineLength / helperLineSegmentLength
    //                     const helperLinePoint = helperLinePoints[j]
    //                     const helperLineTangent = bestHelperLine
    //                         .getTangentAtOffset(helperLineInfo0.offset + (helperLineInfo1.offset - helperLineInfo0.offset) * t)
    //                         .normalized()
    //                     const helperLineNormal = helperLineTangent.perp()
    //                     const distance = controlSegmentDistance0 + (controlSegmentDistance1 - controlSegmentDistance0) * t
    //                     const targetPosition = helperLinePoint.add(helperLineNormal.mul(distance))
    //                     const curveT = controlPoint0.t * (1 - t) + controlPoint1.t * t
    //                     const pointVizItem = this.canvasToolbox.tilingArea.spatialMapping.pointVizBagItem.createControlPoint({
    //                         type: "guide",
    //                         sourcePosition: targetPosition,
    //                         resultUV: {
    //                             x: curve.boundary.direction === BoundaryDirection.Horizontal ? curveT : 0,
    //                             y: curve.boundary.direction === BoundaryDirection.Vertical ? curveT : 0,
    //                         },
    //                         deletable: true,
    //                     })
    //                     curveGuidePoints.push([pointVizItem, curveT])
    //                 }
    //             }
    //             curveGuidePoints.forEach((pointVizItem) => curve.createControlPoint(pointVizItem[0], pointVizItem[1]))
    //         }
    //     }
    //     const updateBoundary = (boundary: BoundaryItem) => {
    //         updateCurve(boundary.curveMin)
    //         updateCurve(boundary.curveMax)
    //     }
    //     updateBoundary(boundaryH)
    //     // updateBoundary(boundaryV)
    // }

    private async alignBoundaries() {
        const boundaryH = this.canvasToolbox.tilingArea.spatialMapping.boundaryH
        const boundaryV = this.canvasToolbox.tilingArea.spatialMapping.boundaryV
        this.removeAlignmentControlPoints()
        const searchDistanceAcrossCurve = 32
        const resultH = await this.alignBoundary(boundaryH, {searchDistanceAcrossCurve})
        const resultV = await this.alignBoundary(boundaryV, {searchDistanceAcrossCurve})
        if (resultH && resultV) {
            const minCorrelation = Math.min(resultH.minCorrelation, resultV.minCorrelation)
            const maxCorrelation = Math.max(resultH.maxCorrelation, resultV.maxCorrelation)
            const alignmentPointInfos = resultH.alignmentPointInfos.concat(resultV.alignmentPointInfos)
            return {minCorrelation, maxCorrelation, alignmentPointInfos}
        } else if (resultH) {
            const minCorrelation = resultH.minCorrelation
            const maxCorrelation = resultH.maxCorrelation
            const alignmentPointInfos = resultH.alignmentPointInfos
            return {minCorrelation, maxCorrelation, alignmentPointInfos}
        } else if (resultV) {
            const minCorrelation = resultV.minCorrelation
            const maxCorrelation = resultV.maxCorrelation
            const alignmentPointInfos = resultV.alignmentPointInfos
            return {minCorrelation, maxCorrelation, alignmentPointInfos}
        } else {
            return undefined
        }
    }

    private async alignBoundary(
        boundary: BoundaryItem,
        options: {
            searchDistanceAlongCurve?: number
            searchDistanceAcrossCurve?: number
            distancePenaltyAlongCurve?: number
            distancePenaltyAcrossCurve?: number
        },
    ) {
        this.callback.setBusy(true)

        const convertPenalty = (spacing: number, penalty: number) => (spacing > 0 ? (4 * penalty) / spacing : 0)
        const pointSpacing = this.alignmentSpacingPx$.value
        const searchDistanceAlongCurve = options.searchDistanceAlongCurve ?? pointSpacing * 0.5
        const searchDistanceAcrossCurve = Math.round(options.searchDistanceAcrossCurve ?? pointSpacing * 0.5)
        const distancePenaltyAlongCurve = convertPenalty(
            searchDistanceAlongCurve,
            options.distancePenaltyAlongCurve ?? this.alignmentCorrelationPenaltyAlongEdge$.value,
        )
        const distancePenaltyAcrossCurve = convertPenalty(
            searchDistanceAcrossCurve,
            options.distancePenaltyAcrossCurve ?? this.alignmentCorrelationPenaltyAcrossEdge$.value,
        )
        const length = boundary.mappedLength
        const numSteps = Math.ceil(length / pointSpacing)
        const minCurveInterpolator = boundary.curveMin.interpolator
        const maxCurveInterpolator = boundary.curveMax.interpolator

        const referenceSegmentLength = Math.round(pointSpacing)
        const referenceSegmentWidth = 64

        const imageOpContextWebGL2 = this.callback.imageOpContextWebGL2
        const sourceImage = this.callback.selectedOperatorInput
        if (!sourceImage) {
            throw new Error("No source image")
        }
        let strategy: "forward-integration" | /*"backward-integration" |*/ "fixed" = "forward-integration"
        try {
            for (;;) {
                let minCorrelation = 1
                let maxCorrelation = -1
                const alignmentPointInfos: AlignmentPointInfo[] = []
                const stepSize = 1 / (numSteps - 1)
                let currCurveMaxT = 0 // strategy === "backward-integration" ? 1 : 0
                for (let i = 0; i < numSteps; i++) {
                    const t = i / (numSteps - 1)
                    // if (strategy === "backward-integration") {
                    //     t = 1 - t
                    // }
                    const curveMinPos = minCurveInterpolator.evaluate(t)
                    const curveMinTangent = minCurveInterpolator.evaluateTangent(t)
                    if (strategy === "fixed") {
                        currCurveMaxT = t
                    }
                    const curveMaxPos = maxCurveInterpolator.evaluate(currCurveMaxT)
                    const curveMaxTangent = maxCurveInterpolator.evaluateTangent(currCurveMaxT)
                    const referenceSegment = {
                        from: curveMinPos.add(curveMinTangent.mul(-referenceSegmentLength * 0.5)),
                        to: curveMinPos.add(curveMinTangent.mul(referenceSegmentLength * 0.5)),
                        width: referenceSegmentWidth,
                    }
                    const searchSegment = {
                        from: curveMaxPos.add(curveMaxTangent.mul(-referenceSegmentLength * 0.5 - searchDistanceAlongCurve * 0.5)),
                        to: curveMaxPos.add(curveMaxTangent.mul(referenceSegmentLength * 0.5 + searchDistanceAlongCurve * 0.5)),
                        width: referenceSegmentWidth + searchDistanceAcrossCurve,
                    }
                    const lineSearchPenaltyFn = (position: Vector2) => {
                        const correctedPosition = position.add(curveMaxTangent.mul(referenceSegmentLength * 0.5))
                        const delta = correctedPosition.sub(curveMaxPos)
                        const penaltyAlongCurve = Vector2.dot(delta, curveMaxTangent) * distancePenaltyAlongCurve
                        const penaltyAcrossCurve = Vector2.dot(delta, curveMaxTangent.perp()) * distancePenaltyAcrossCurve
                        // apply penalty in an elliptical shape
                        return Math.sqrt(penaltyAlongCurve * penaltyAlongCurve + penaltyAcrossCurve * penaltyAcrossCurve)
                    }
                    const result = await findBestMatchAlongSegment(imageOpContextWebGL2, {
                        sourceImage,
                        referenceSegment,
                        searchSegment,
                        penaltyFn: lineSearchPenaltyFn,
                    })
                    const correctedCurveMaxPosition = result.bestMatchPosition.add(curveMaxTangent.mul(referenceSegmentLength * 0.5)) // + searchDistanceAlongCurve * 0.5))
                    const correctedCurveMaxT = maxCurveInterpolator.solveForT(correctedCurveMaxPosition)
                    // ignore out of range points for now
                    if (t > 0 && t < 1 && correctedCurveMaxT > 0 && correctedCurveMaxT < 1) {
                        minCorrelation = Math.min(minCorrelation, result.peakValue)
                        maxCorrelation = Math.max(maxCorrelation, result.peakValue)
                        alignmentPointInfos.push({
                            boundaryDirection: boundary.direction,
                            t: t,
                            referencePosition: curveMinPos,
                            snapResult: {
                                snappedPosition: correctedCurveMaxPosition,
                                correlation: result.peakValue,
                            },
                        })
                    }
                    currCurveMaxT = correctedCurveMaxT
                    if (i + 1 < numSteps) {
                        currCurveMaxT += stepSize //* (strategy === "backward-integration" ? -1 : 1)
                    }
                }
                const eps = stepSize * 0.1
                const hasSucceeded =
                    strategy === "fixed" || (currCurveMaxT > -eps && currCurveMaxT < eps) || (currCurveMaxT > 1 - eps && currCurveMaxT < 1 + eps)
                if (hasSucceeded) {
                    console.log("Alignment succeeded with strategy", strategy)
                    return {minCorrelation, maxCorrelation, alignmentPointInfos}
                } else {
                    switch (strategy) {
                        case "forward-integration":
                            // strategy = "backward-integration"
                            strategy = "fixed"
                            break
                        // case "backward-integration":
                        //     strategy = "fixed"
                        //     break
                        case "fixed":
                            throw new Error("Failed to align boundary")
                    }
                }
            }
        } catch (error) {
            console.error(error)
            return undefined
        } finally {
            this.callback.setBusy(false)
        }
    }

    private toggleSnapMode() {
        let snapEnabled = this.snapToSimilarFeatureEnabled$.value || this.snapToHelperLinesEnabled$.value
        snapEnabled = !snapEnabled
        this.snapToSimilarFeatureEnabled$.next(snapEnabled)
        this.snapToHelperLinesEnabled$.next(snapEnabled)
    }

    private onTilingAreaChanged(event: ChangeEvent) {
        if (event.type !== "point-result-uv-moved") {
            this.markEdited()
        }
        this.invalidateCachedGrid()
        this.invalidateBorderBlendMask()
        if (this.viewMode$.value === ViewMode.Result) {
            this.requestEval()
        }
    }

    private invalidateCachedGrid() {
        this.cachedGridGeometry = undefined
        this.uploadedGridGeometry = undefined
    }

    override async save(processingJobId: string): Promise<TextureEditNodes.Operator | undefined> {
        this.copyToNode()
        const uploadGridGeometry = async () => {
            this.node.uploadedGridMapping = this.uploadedGridGeometry
                ? {
                      resultSize: this.uploadedGridGeometry.resultSize,
                      meshDataObject: {
                          type: "data-object-reference",
                          dataObjectId: await this.uploadedGridGeometry.dataObjectReference.then((result) => result.id),
                      },
                  }
                : undefined
        }
        const uploadBorderBlendMask = async (borderBlendMask: ManagedDrawableImageHandle | undefined): Promise<MaskReference | undefined> => {
            if (!borderBlendMask) {
                return undefined
            }
            return {
                type: "data-object-reference",
                dataObjectId: await this.callback.drawableImageCache.getDataObjectId(borderBlendMask.ref.id),
            }
        }
        const uploadHorizontalBorderBlendMask = async () =>
            (this.node.borderBlending.horizontalMask = await uploadBorderBlendMask(this.horizontalBorderBlendMask))
        const uploadVerticalBorderBlendMask = async () => (this.node.borderBlending.verticalMask = await uploadBorderBlendMask(this.verticalBorderBlendMask))
        await Promise.all([uploadGridGeometry(), uploadHorizontalBorderBlendMask(), uploadVerticalBorderBlendMask()])
        return super.save(processingJobId)
    }

    private copyToNode() {
        const tilingArea = this.canvasToolbox.tilingArea
        const node = this.node
        const isCornerControlPoint = (index: number, array: Array<unknown>) => index === 0 || index === array.length - 1
        // horizontal sub-division control points
        const boundaryH = tilingArea.spatialMapping.boundaryH
        const horizontalLowBoundCurveControlPoints = boundaryH.curveMin.curveControlPoints
        const horizontalHighBoundCurveControlPoints = boundaryH.curveMax.curveControlPoints
        node.boundaries.horizontal.controlPoints = horizontalLowBoundCurveControlPoints
            .map((curveControlPoint, index) => ({
                type: curveControlPoint.controlPoint.type,
                loBound: {positionPx: curveControlPoint.controlPoint.sourcePosition, normalizedCurvePosition: curveControlPoint.t},
                hiBound: {
                    positionPx: horizontalHighBoundCurveControlPoints[index].controlPoint.sourcePosition,
                    normalizedCurvePosition: horizontalHighBoundCurveControlPoints[index].t,
                },
            }))
            .filter((_, index, array) => !isCornerControlPoint(index, array))
        // vertical sub-division control points
        const boundaryV = tilingArea.spatialMapping.boundaryV
        const verticalLowBoundCurveControlPoints = boundaryV.curveMin.curveControlPoints
        const verticalHighBoundCurveControlPoints = boundaryV.curveMax.curveControlPoints
        node.boundaries.vertical.controlPoints = verticalLowBoundCurveControlPoints
            .map((curveControlPoint, index) => ({
                type: curveControlPoint.controlPoint.type,
                loBound: {positionPx: curveControlPoint.controlPoint.sourcePosition, normalizedCurvePosition: curveControlPoint.t},
                hiBound: {
                    positionPx: verticalHighBoundCurveControlPoints[index].controlPoint.sourcePosition,
                    normalizedCurvePosition: verticalHighBoundCurveControlPoints[index].t,
                },
            }))
            .filter((_, index, array) => !isCornerControlPoint(index, array))
        // corner control points
        node.cornerControlPoints.topLeft.positionPx = horizontalLowBoundCurveControlPoints[0].controlPoint.sourcePosition
        node.cornerControlPoints.topRight.positionPx =
            horizontalLowBoundCurveControlPoints[horizontalLowBoundCurveControlPoints.length - 1].controlPoint.sourcePosition
        node.cornerControlPoints.bottomLeft.positionPx = horizontalHighBoundCurveControlPoints[0].controlPoint.sourcePosition
        node.cornerControlPoints.bottomRight.positionPx =
            horizontalHighBoundCurveControlPoints[horizontalHighBoundCurveControlPoints.length - 1].controlPoint.sourcePosition

        // helper lines
        if (!node.helperLines) {
            node.helperLines = {
                curves: [],
                boundaryFollows: false,
            }
        }
        node.helperLines.curves = this.canvasToolbox.helperLinesBag.helperLines.map((helperLine) => ({
            origin: helperLine.sourceCastRayOrigin,
            target: helperLine.sourceCastRayTarget,
            points: helperLine.points,
        }))
    }

    async computeSnapPosition(parameters: {position: Vector2; referencePosition: Vector2 | undefined} | undefined): Promise<Vector2 | undefined> {
        if (!parameters) {
            this.canvasToolbox.showSnapRadius(undefined)
            return undefined
        }
        const snapDistance = Math.round(this.snapDistancePx$.value)
        if (snapDistance <= 0) {
            return undefined
        }
        const referencePosition = parameters.referencePosition
        const snapToHelperLinesEnabled = this.snapToHelperLinesEnabled$.value && this.hasHelperLines
        const snapToSimilarFeatureEnabled = this.snapToSimilarFeatureEnabled$.value && referencePosition
        if (snapToHelperLinesEnabled || snapToSimilarFeatureEnabled) {
            // only show snap area if there is anything to snap to
            this.canvasToolbox.showSnapRadius({
                position: parameters.position,
                snapDistancePx: snapDistance,
            })
        }
        let snapPosition: Vector2 | undefined
        if (snapToHelperLinesEnabled) {
            snapPosition = this.snapToHelpers(parameters.position, snapDistance)
        }
        if (!snapPosition && snapToSimilarFeatureEnabled) {
            // if a new snap request is made while the previous computation is still running, we return the previous result
            snapPosition = await this._snapToFeatureReentrancyGuard
                .startIfIdleOrGetCurrent(async () => {
                    return await this.snapToSimilarFeature(parameters.position, referencePosition, snapDistance).then(
                        (snapResult) => snapResult?.snappedPosition,
                    )
                })
                .catch((e) => {
                    // throw as an unhandled exception
                    setTimeout(() => {
                        throw e
                    }, 0)
                    return undefined
                })
        }
        return snapPosition
    }

    private snapToHelpers(position: Vector2Like, snapDistance: number): Vector2 | undefined {
        const crossingDistanceDistanceScale = 0.5 // scale factor to make crossing distances more important than line distances
        const crossingSnapDistance = Math.min(snapDistance * 0.2, 20 / this.canvasToolbox.zoomLevel) // distance under which to snap to crossings
        // search crossings
        const resultCrossings = this.canvasToolbox.helperLinesBag.helperLineCrossings.reduce(
            (result, helperLineCrossing) => {
                const distance = Vector2.distance(helperLineCrossing.point, position)
                if (!result || distance < result.distance) {
                    return {closestPoint: helperLineCrossing.point, distance}
                } else {
                    return result
                }
            },
            undefined as {closestPoint: Vector2; distance: number} | undefined,
        )
        if (resultCrossings && resultCrossings.distance < crossingSnapDistance) {
            return resultCrossings.closestPoint
        }
        // search lines
        const resultLines = this.canvasToolbox.helperLinesBag.getClosestPoint(position)
        if (!resultCrossings && !resultLines) {
            return undefined
        }
        let minDistance: number
        let minClosestPoint: Vector2
        if (resultCrossings && resultLines) {
            const crossingsAreCloser = resultCrossings.distance * crossingDistanceDistanceScale < resultLines.distance
            minDistance = crossingsAreCloser ? resultCrossings.distance : resultLines.distance
            minClosestPoint = crossingsAreCloser ? resultCrossings.closestPoint : resultLines.closestPoint
        } else if (resultCrossings) {
            minDistance = resultCrossings.distance
            minClosestPoint = resultCrossings.closestPoint
        } else if (resultLines) {
            minDistance = resultLines.distance
            minClosestPoint = resultLines.closestPoint
        } else {
            throw new Error("This should not happen")
        }
        if (minDistance > snapDistance) {
            return undefined
        }
        return minClosestPoint
    }

    private async snapToSimilarFeature(position: Vector2Like, referencePosition: Vector2Like, snapDistance: number): Promise<CorrelationResult | undefined> {
        const start = this.debugDrawEnabled$.value ? performance.now() : 0

        const maxSearchRadius = 1024
        const snapDistancePenalty = 1 - snapDistance / maxSearchRadius
        this.canvasToolbox.clearDebugRects()
        const debugImage = this.debugDrawEnabled$.value ? this.debugImage : undefined

        const imageOpContextWebGL2 = this.callback.imageOpContextWebGL2
        const sourceImageRef = this.callback.selectedOperatorInput
        if (!sourceImageRef) {
            throw new Error("No source image")
        }

        // const numLevels = Math.ceil(Math.log2(maxSearchRadius))
        // const maxTemplateSize = 2 ** (numLevels - 1) * correlationWindowSize
        // const maxSourceImageSize = 2 ** (numLevels - 1) * (correlationWindowSize + searchSize - 1)

        // compute regions
        // const templateRegion = {
        //     x: Math.round(referencePosition.x - maxTemplateSize / 2),
        //     y: Math.round(referencePosition.y - maxTemplateSize / 2),
        //     width: maxTemplateSize,
        //     height: maxTemplateSize,
        // }
        // if (this.debugDrawEnabled$.value) {
        //     this.canvasToolbox.createDebugRect(templateRegion, "green")
        // }
        // const sourceRegion = {
        //     x: Math.round(position.x - maxSourceImageSize / 2),
        //     y: Math.round(position.y - maxSourceImageSize / 2),
        //     width: maxSourceImageSize,
        //     height: maxSourceImageSize,
        // }
        // if (this.debugDrawEnabled$.value) {
        //     this.canvasToolbox.createDebugRect(sourceRegion, "blue")
        // }
        const convertSnapDistancePenalty = (snapDistancePenalty: number) => (4 * snapDistancePenalty) / maxSearchRadius
        const correlationPenaltyPerPixel = convertSnapDistancePenalty(snapDistancePenalty)

        const cmdQueue = imageOpContextWebGL2.createCommandQueue()
        debugImage?.init(cmdQueue, {width: 512, height: 4096}, {r: 0.05, g: 0.05, b: 0.05, a: 1})
        const sourceOffsetAndCorrelation = hierarchicalCrossCorrelation(cmdQueue, {
            sourceImage: sourceImageRef,
            sourceImageReferencePosition: position,
            templateImage: sourceImageRef,
            templateImageReferencePosition: referencePosition,
            maxSearchRadius: maxSearchRadius,
            correlationPenaltyPerPixel: correlationPenaltyPerPixel,
            cacheData: this.hierarchicalCrossCorrelationCacheData,
            debugImage: this.debugDrawEnabled$.value ? debugImage : undefined,
            debugRectFn: this.debugDrawEnabled$.value ? (rect, color) => this.canvasToolbox.createDebugRect(rect, color) : undefined,
        })
        const [evaluatedSourceOffsetAndCorrelation] = await cmdQueue.execute([sourceOffsetAndCorrelation], {waitForCompletion: true})
        const sourceOffsetAndCorrelationData = await evaluatedSourceOffsetAndCorrelation.ref.halImageView.readImageDataFloat()
        evaluatedSourceOffsetAndCorrelation.release()
        const sourceOffsetVec = new Vector2(sourceOffsetAndCorrelationData[0], sourceOffsetAndCorrelationData[1])
        const correlation = sourceOffsetAndCorrelationData[2]
        const snappedPosition = sourceOffsetVec //.add(position)

        if (debugImage) {
            this.requestEval()
        }

        if (this.debugDrawEnabled$.value) {
            const end = performance.now()
            console.log("Snap time:", end - start, "ms")
            console.log("Snapped position:", snappedPosition)
            console.log("Correlation:", correlation)
        }

        return correlation >= -1 ? {snappedPosition, correlation} : undefined
    }

    readonly minBorderBlendingPx = 16
    readonly numGridControlPointSegmentSubdivisions = 4 // rather than constant subdivision this should be more adaptive to the actual non-linearity imposed by the grid

    private uploadService: UploadGqlService
    private destroyed = new Subject<void>()
    private cachedGridGeometry?: GridMappingResultType
    private uploadedGridGeometry?: UploadedGridGeometry
    private horizontalBorderBlendMask?: ManagedDrawableImageHandle
    private verticalBorderBlendMask?: ManagedDrawableImageHandle
    private alignmentInfo?: AlignmentInfo
    private debugImage: DebugImage
    private hierarchicalCrossCorrelationCacheData = new CacheData()
    private performTraceStep$ = new Subject<TraceInfo>()
    private _snapToFeatureReentrancyGuard = new AsyncReentrancyGuard.PromiseGate<Vector2 | undefined>()
}

type TraceInfo = {
    helperLine: HelperLineItem
    state: "init" | "forward-trace" | "backward-trace"
}

type DataObjectReference = {
    id: string
    legacyId: number
}

type UploadedGridGeometry = {
    dataObjectReference: Promise<DataObjectReference>
    resultSize: Size2Like
}

type AlignmentPointInfo = {
    boundaryDirection: BoundaryDirection
    t: number
    referencePosition: Vector2
    // originalPosition: Vector2
    snapResult: {
        snappedPosition: Vector2
        correlation: number
    }
    // boundarySlope: Vector2
    curveControlPoints?: {
        curveControlPoint0: BoundaryCurveControlPoint
        curveControlPoint1: BoundaryCurveControlPoint
    }
}

type AlignmentInfo = {
    textureType: TextureType // texture type used to create the alignment info
    points: AlignmentPointInfo[]
    alignmentQuality: number
}

type CorrelationResult = {
    snappedPosition: Vector2
    correlation: number
}
