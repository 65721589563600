import {Component, inject, OnInit} from "@angular/core"
import {MatPaginatorModule} from "@angular/material/paginator"
import {RouterOutlet} from "@angular/router"
import {CheckboxesFilterComponent, TagSearchFilterComponent} from "@common/components/filters"
import {ItemFiltersComponent} from "@common/components/item"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {Labels, StateLabel} from "@labels"
import {MaterialsPageVisibleUsersGQL} from "@pages/materials/materials-page.generated"
import {JobsTableComponent} from "@platform/components/jobs/jobs-table/jobs-table.component"
import {ResponsiveTableLayoutComponent} from "@platform/components/layouts/responsive-table-layout/responsive-table-layout.component"
import {TableContentDirective} from "@platform/directives/content/table-content.directive"
import {IsDefined} from "@cm/utils/filter"
import {BasePageComponent} from "@pages/base/base-page.component"

@Component({
    selector: "cm-jobs-page",
    imports: [
        CheckboxesFilterComponent,
        ItemFiltersComponent,
        MatPaginatorModule,
        RouterOutlet,
        TagSearchFilterComponent,
        ResponsiveTableLayoutComponent,
        JobsTableComponent,
        TableContentDirective,
    ],
    templateUrl: "./jobs-page.component.html",
    styleUrl: "./jobs-page.component.scss",
})
export class JobsPageComponent extends BasePageComponent implements OnInit {
    readonly organizations = inject(OrganizationsService)

    readonly visibleUsersGql = inject(MaterialsPageVisibleUsersGQL)

    userLabels: StateLabel<string>[] | null = null

    override ngOnInit() {
        super.ngOnInit()
        fetchThrowingErrors(this.visibleUsersGql)({}).then(({users}) => {
            this.userLabels = users.filter(IsDefined).map((user) => ({
                label: user.name,
                state: user.id,
            }))
        })
    }

    public readonly Labels = Labels
}
