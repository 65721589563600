import {Component, input, output} from "@angular/core"
import {RouterModule} from "@angular/router"
import {ThumbnailComponent} from "@app/common/components/thumbnail/thumbnail.component"

@Component({
    selector: "cm-card",
    templateUrl: "./card.component.html",
    styleUrls: ["./card.component.scss"],
    imports: [RouterModule, ThumbnailComponent],
})
export class CardComponent {
    readonly $squareFormat = input(false, {alias: "squareFormat"})
    readonly $defaultThumbnail = input(true, {alias: "defaultThumbnail"})
    readonly $pictureZoneActive = input(false, {alias: "pictureZoneActive"})
    readonly $link = input<Array<unknown>>(undefined, {alias: "link"})
    readonly $queryParamsHandling = input<"merge" | "preserve" | "">("", {alias: "queryParamsHandling"})
    readonly $thumbnailUrl = input<string | null>(null, {alias: "thumbnailUrl"})
    readonly $disabled = input(false, {alias: "disabled"})
    readonly selectItem = output<void>()

    onSelectItem() {
        if (this.$disabled()) return
        this.selectItem.emit()
    }
}
