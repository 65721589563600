import {Component, ElementRef, OnInit, input, viewChild} from "@angular/core"
import * as paper from "paper"
import {ConnectionId, SocketPosition} from "@node-editor/models"

@Component({
    selector: "cm-node-editor-canvas",
    templateUrl: "./node-editor-canvas.component.html",
    styleUrls: ["./node-editor-canvas.component.scss"],
})
export class NodeEditorCanvasComponent implements OnInit {
    readonly $visualCanvas = viewChild.required<ElementRef<HTMLCanvasElement>>("visualCanvas")
    readonly $scale = input(1, {alias: "scale"})

    private project!: paper.Project | undefined
    private connectionPaths: Map<ConnectionId, paper.Path> = new Map<ConnectionId, paper.Path>()

    constructor(protected element: ElementRef) {}

    ngOnInit(): void {
        this.project = new paper.Project(this.$visualCanvas().nativeElement)
    }

    updateConnectionLine(id: ConnectionId, source: SocketPosition, destination: SocketPosition): void {
        if (!this.project) return
        const visualCanvasBoundingRect: DOMRect = this.$visualCanvas().nativeElement.getBoundingClientRect()
        const sourceX: number = source.x - visualCanvasBoundingRect.x
        const sourceY: number = source.y - visualCanvasBoundingRect.y
        const destinationX: number = destination.x - visualCanvasBoundingRect.x
        const destinationY: number = destination.y - visualCanvasBoundingRect.y

        let connection = this.connectionPaths.get(id)
        if (connection) {
            connection.firstSegment.point.x = sourceX
            connection.firstSegment.point.y = sourceY
            connection.lastSegment.point.x = destinationX
            connection.lastSegment.point.y = destinationY
        } else {
            connection = new paper.Path({insert: false})
            this.project.activeLayer.addChild(connection)
            connection.strokeColor = new paper.Color("#b5b5b5")
            connection.add(new paper.Point(sourceX, sourceY))
            connection.add(new paper.Point(destinationX, destinationY))
            this.connectionPaths.set(id, connection)
        }
        connection.strokeWidth = this.$scale() * 2
    }

    deleteConnectionLine(id: ConnectionId): void {
        this.connectionPaths.get(id)?.remove()
        this.connectionPaths.delete(id)
    }

    updateVisualCanvasSize(hostContainerBoundingRect: DOMRect): void {
        if (!this.project) return
        this.project.view.viewSize.width = hostContainerBoundingRect.width
        this.project.view.viewSize.height = hostContainerBoundingRect.height
    }
}
