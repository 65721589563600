import {HttpErrorResponse} from "@angular/common/http"
import {computed, DestroyRef, inject, Injectable, Signal} from "@angular/core"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"
import {MatSnackBar} from "@angular/material/snack-bar"
import {SiloService} from "@common/services/auth/silo.service"
import {LocalStorageService} from "@common/services/local-storage/local-storage.service"
import {ContentTypeModel, MutationLoginInput, SystemRole} from "@generated"
import {AuthenticatedUserFragment, AuthSiloDataGQL} from "@app/common/services/auth/auth.service.generated"
import {IsDefined, IsNotUndefined} from "@cm/utils"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {decodeToken} from "@common/helpers/auth/token"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {AuthenticatedUserGQL, PerformLoginGQL} from "@pages/login/login-page.generated"
import {Apollo} from "apollo-angular"
import {BehaviorSubject, filter, firstValueFrom, map, Observable, take, throwError as observableThrowError} from "rxjs"
import {ActivatedRoute, Router} from "@angular/router"
import {ErrorCode} from "@common/models/errors"
import {extractErrorInfo} from "@common/helpers/api/errors"
import {v4 as uuid4} from "uuid"

// TODO: make this configurable
const COLORMASS_ORGANIZATION_ID = "023aa8d4-9540-4be8-b9bd-2cc047bb6923"

@Injectable({
    providedIn: "root",
})
export class AuthService {
    // null if the user is not logged in
    // undefined during initial app load and during login until the user has been loaded
    // otherwise a user fragment
    public user$ = new BehaviorSubject<AuthenticatedUserFragment | null | undefined>(undefined)
    // undefined during initial app load and during login until the user has been loaded
    // null if the user is not logged in
    // otherwise a user fragment
    public $user: Signal<AuthenticatedUserFragment | null | undefined>

    userId$ = new BehaviorSubject<string | null | undefined>(undefined)
    isStaff$: Observable<boolean>

    $actingAsCustomer = computed(() => {
        const activeMembership = this.silo.$silo()
        return activeMembership?.organization?.id !== COLORMASS_ORGANIZATION_ID
    })

    public initialLoadCompleted$ = new BehaviorSubject<boolean>(false)

    readonly apollo = inject(Apollo)
    readonly destroyRef = inject(DestroyRef)
    readonly notifications = inject(NotificationsService)
    readonly refresh = inject(RefreshService)
    readonly silo = inject(SiloService)
    readonly snackBar = inject(MatSnackBar)
    readonly router = inject(Router)
    readonly localStorageService = inject(LocalStorageService)

    private readonly authenticatedUser = inject(AuthenticatedUserGQL)
    private readonly logInGql = inject(PerformLoginGQL)
    private readonly authSiloData = inject(AuthSiloDataGQL)

    public csrfToken: string

    constructor() {
        this.$user = toSignal(this.user$, {initialValue: undefined})

        this.isStaff$ = this.user$.pipe(
            filter((user) => user !== undefined),
            map((user) => !!user?.isStaff),
        )

        // allow the refresh service to trigger a reload of the current user
        // e.g. when the user's details change
        this.refresh
            .keepFetched$<AuthenticatedUserFragment>(this.userId$, ContentTypeModel.User, (id) =>
                fetchThrowingErrors(this.authenticatedUser)(id).catch((error) => {
                    console.error(error)
                    this.logOut()
                    this.notifications.showInfo("You have been logged out automatically.", 10000)
                    return null
                }),
            )
            .subscribe((reloadedUser) => {
                this.user$.next(reloadedUser)
            })

        this.loadUserIdFromStorage().then((userId) => {
            this.userId$.next(userId)
            this.initialLoadCompleted$.next(true)
        })
        this.csrfToken = this.loadOrCreateCsrfToken()
    }

    get user(): Promise<AuthenticatedUserFragment | null> {
        return firstValueFrom(this.user$.pipe(filter(IsNotUndefined)))
    }

    async loadUserIdFromStorage(): Promise<string | null> {
        const token = this.localStorageService.loadToken()
        if (!token) {
            return null
        }

        try {
            const decodedToken = decodeToken(token)
            return decodedToken?.id ?? null
        } catch (error) {
            console.error(error)
            return null
        }
    }

    async logIn(credentials: MutationLoginInput): Promise<boolean> {
        this.user$.next(undefined)
        try {
            const {login: loginInfo} = await mutateThrowingErrors(this.logInGql)({input: credentials})
            return await this.completeLogin(loginInfo)
        } catch (error) {
            if (`${error}`.startsWith("TypeError: Network request failed")) {
                console.error(error)
                this.snackBar.open("Login failed. Server unavailable.", "", {duration: 3000})
                this.handleError(error as HttpErrorResponse)
                this.userId$.next(null)
                return false
            } else {
                console.error(error)
                this.snackBar.open("Login failed. Wrong email or password.", "", {duration: 3000})
                this.handleError(error as HttpErrorResponse)
                this.userId$.next(null)
                return false
            }
        }
    }

    async completeLogin(loginInfo: {user: {id: string}; token: string}) {
        this.storeToken(loginInfo.token)
        // set an appropriate auth silo, unless it's already set
        if (!this.silo.$silo()?.organization) {
            const {user} = await fetchThrowingErrors(this.authSiloData)({id: loginInfo.user.id}, {context: {withoutSilo: true}})
            if (user.role === SystemRole.Superadmin) {
                await this.silo.activateSystemRole(SystemRole.Superadmin)
            } else if (user.role === SystemRole.Staff) {
                await this.silo.activateSystemRole(SystemRole.Staff)
            } else if (user.memberships && user.memberships.length > 0) {
                this.silo.activateMembership(user.memberships[0])
            }
        }
        await this.apollo.client.clearStore()
        this.userId$.next(loginInfo.user.id)
        // wait for the user to be loaded after setting the user id
        // this is required, because auth guards use the value of user,
        // so navigating to a new page straightaway would run into a race condition
        await firstValueFrom(this.user$.pipe(filter(IsDefined), take(1), takeUntilDestroyed(this.destroyRef)))
        return true
    }

    handleError(error: HttpErrorResponse): Observable<never> {
        console.error(error)
        let errorMessage: string = error.message || "An error occurred."
        if (error.error) {
            if (error.error instanceof ErrorEvent) {
                // A client-side or network error occurred.
                errorMessage = error.error.message
            } else if (error.error.detail !== undefined) {
                // The backend returned an unsuccessful response code.
                errorMessage = error.error.detail
            }
        }
        return observableThrowError(errorMessage)
    }

    public logOut(): void {
        this.localStorageService.storeToken(null)
        this.localStorageService.storeSilo({
            // removing these causes the silo selection to be lost when logging out and back in
            // however, it also leads to authentication errors if the user logs in with a different account (!)
            systemRole: null,
            organizationId: null,
        })
        this.silo.$silo.set(null)
        this.userId$.next(null)
        void this.apollo.client.clearStore()
    }

    public storeToken(token: string) {
        this.localStorageService.storeToken(token)
    }

    isLoggedIn() {
        return !!this.userId$.value
    }

    isStaff() {
        if (!this.$user()) {
            return false
        }
        return this.$user()?.isStaff ?? false
    }

    isSuperuser() {
        if (!this.$user()) {
            return false
        }
        return this.$user()?.isSuperuser ?? false
    }

    /**
     * Handle an authorization errors for queries that are essential for the app to function. If such a query fails,
     * the page should not be shown at all.
     */
    handleAuthorizationError = (route: ActivatedRoute) => async (error: unknown) => {
        const errorInfo = extractErrorInfo(error)
        const state = route.snapshot
        switch (errorInfo.code) {
            case ErrorCode.Forbidden:
                await this.router.navigate(["/unauthorized"])
                return
            case ErrorCode.Unauthenticated:
                console.error("Unauthenticated error")
                await this.router.navigate(["/login"], {queryParams: {returnUrl: state.url}})
                return
        }
        throw error
    }

    /**
     * If the user is already set, use it to decide what the default view is.
     * Otherwise, get the user from the API. This normally happens at the login process.
     */
    async navigateToDefaultView() {
        const silo = this.silo.$silo()
        const organizationId = silo?.organization?.id ?? null
        return this.router.navigate(["pictures"], {queryParams: {tab: "projects", organizationId}})
    }

    loadOrCreateCsrfToken() {
        const loadedToken = this.localStorageService.loadCsrfToken()
        if (loadedToken) {
            return loadedToken
        }
        const newToken = uuid4()
        this.localStorageService.storeCsrfToken(newToken)
        return newToken
    }
}
