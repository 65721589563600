import {Component, DestroyRef, inject, OnInit} from "@angular/core"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {ActivatedRoute, Router} from "@angular/router"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {filter, take} from "rxjs"
import {IsDefined} from "@cm/utils/filter"

@Component({
    selector: "cm-base-page",
    imports: [],
    templateUrl: "./base-page.component.html",
    styleUrl: "./base-page.component.scss",
})
export class BasePageComponent implements OnInit {
    readonly destroyRef = inject(DestroyRef)
    readonly permission = inject(PermissionsService)
    readonly route = inject(ActivatedRoute)
    readonly router = inject(Router)

    ngOnInit() {
        const path = this.route.snapshot.url?.[0]?.path
        this.permission
            .canViewPage$(path)
            .pipe(takeUntilDestroyed(this.destroyRef), filter(IsDefined), take(1))
            .subscribe(async (canViewPage) => {
                if (!canViewPage) {
                    await this.router.navigate(["/unauthorized"])
                }
            })
    }
}
