import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TemplateAddMaterialItemFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, description?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null };

export type TemplateAddMaterialItemsQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.MaterialFilterInput>;
}>;


export type TemplateAddMaterialItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Material', id: string, legacyId: number, name?: string | null, description?: string | null, organization?: { __typename: 'Organization', id: string, name?: string | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null } | null> };

export const TemplateAddMaterialItemFragmentDoc = gql`
    fragment TemplateAddMaterialItem on Material {
  id
  legacyId
  name
  description
  organization {
    id
    name
  }
  galleryImage {
    id
  }
  latestCyclesRevision {
    id
    legacyId
  }
}
    `;
export const TemplateAddMaterialItemsDocument = gql`
    query templateAddMaterialItems($skip: Int, $take: Int, $filter: MaterialFilterInput) {
  items: materials(
    skip: $skip
    take: $take
    filter: $filter
    orderBy: [{direction: asc, key: legacyId}]
  ) {
    ...TemplateAddMaterialItem
  }
  totalCount: materialsCount(filter: $filter)
}
    ${TemplateAddMaterialItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateAddMaterialItemsGQL extends Apollo.Query<TemplateAddMaterialItemsQuery, TemplateAddMaterialItemsQueryVariables> {
    override document = TemplateAddMaterialItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }