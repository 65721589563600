import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { AuthenticatedUserFragmentDoc } from '../../../common/services/auth/auth.service.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PerformLoginMutationVariables = Types.Exact<{
  input: Types.MutationLoginInput;
}>;


export type PerformLoginMutation = { __typename: 'Mutation', login: { __typename: 'LoginResult', token: string, user: { __typename: 'User', id: string, legacyId: number } } };

export type AuthenticatedUserQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type AuthenticatedUserQuery = { __typename: 'Query', item: { __typename: 'User', id: string, email: string, isStaff: boolean, isSuperuser: boolean, name: string, role?: Types.SystemRole | null, galleryImage?: { __typename: 'DataObject', id: string } | null, memberships: Array<{ __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, name?: string | null, type?: Types.OrganizationType | null } }> } };

export type LoginSendPasswordResetEmailMutationVariables = Types.Exact<{
  input: Types.MutationSendPasswordResetEmailInput;
}>;


export type LoginSendPasswordResetEmailMutation = { __typename: 'Mutation', sendPasswordResetEmail: boolean };

export type LoginResetPasswordMutationVariables = Types.Exact<{
  input: Types.MutationResetPasswordInput;
}>;


export type LoginResetPasswordMutation = { __typename: 'Mutation', resetPassword: string };

export const PerformLoginDocument = gql`
    mutation performLogin($input: MutationLoginInput!) {
  login(input: $input) {
    token
    user {
      id
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PerformLoginGQL extends Apollo.Mutation<PerformLoginMutation, PerformLoginMutationVariables> {
    override document = PerformLoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthenticatedUserDocument = gql`
    query authenticatedUser($id: ID!) {
  item: user(id: $id) {
    ...AuthenticatedUser
  }
}
    ${AuthenticatedUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthenticatedUserGQL extends Apollo.Query<AuthenticatedUserQuery, AuthenticatedUserQueryVariables> {
    override document = AuthenticatedUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginSendPasswordResetEmailDocument = gql`
    mutation loginSendPasswordResetEmail($input: MutationSendPasswordResetEmailInput!) {
  sendPasswordResetEmail(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginSendPasswordResetEmailGQL extends Apollo.Mutation<LoginSendPasswordResetEmailMutation, LoginSendPasswordResetEmailMutationVariables> {
    override document = LoginSendPasswordResetEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginResetPasswordDocument = gql`
    mutation loginResetPassword($input: MutationResetPasswordInput!) {
  resetPassword(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginResetPasswordGQL extends Apollo.Mutation<LoginResetPasswordMutation, LoginResetPasswordMutationVariables> {
    override document = LoginResetPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }