import {Component, OnDestroy, ViewContainerRef, effect, input, viewChild} from "@angular/core"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {Operator} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {TilingCanvasComponent} from "@app/textures/texture-editor/tiling-canvas/tiling-canvas.component"
import {TextureType} from "@generated"
import {descriptorByTextureType} from "@app/textures/utils/texture-type-descriptor"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"

@Component({
    selector: "cm-operator-inspector",
    templateUrl: "./operator-inspector.component.html",
    styleUrls: ["./operator-inspector.component.scss"],
    imports: [InspectorSectionComponent, ToggleComponent],
})
export class OperatorInspectorComponent implements OnDestroy {
    readonly $operatorPanelContainer = viewChild("operatorPanelContainer", {read: ViewContainerRef})

    readonly $disabled = input.required<boolean>({alias: "disabled"})
    readonly $operator = input<Operator | null>(null, {alias: "operator"})

    readonly $textureEditCanvas = input.required<TilingCanvasComponent>({alias: "textureEditCanvas"})
    readonly $availableTextureTypes = input<TextureType[]>([], {alias: "availableTextureTypes"})

    constructor() {
        effect(() => {
            this.setOperatorPanelAndToolbox(this.$operator())
        })
    }

    ngOnDestroy(): void {
        this.setOperatorPanelAndToolbox(null)
    }

    shouldApplyTo(textureType: TextureType): boolean {
        return this.$operator()?.shouldApplyTo(textureType) ?? false
    }

    setShouldApplyTo(textureType: TextureType, enabled: boolean) {
        const operator = this.$operator()
        if (!operator) {
            return
        }
        operator.setShouldApplyTo(textureType, enabled)
        operator.requestEvaluation.emit()
    }

    get showApplyToSelection(): boolean {
        const operator = this.$operator()
        return operator != null && !operator.flags.has("apply-to-all-texture-types")
    }

    private setOperatorPanelAndToolbox(operator: Operator | null): void {
        const operatorPanelContainer = this.$operatorPanelContainer()
        if (!operatorPanelContainer) {
            return
        }
        operatorPanelContainer.clear()
        const textureEditCanvas = this.$textureEditCanvas()
        if (textureEditCanvas.toolbox) {
            textureEditCanvas.toolbox = null
        }
        if (!operator) {
            return
        }
        if (operator.panelComponentType) {
            const componentRef = operatorPanelContainer.createComponent(operator.panelComponentType)
            componentRef.setInput("operator", operator)
            componentRef.setInput("disabled", this.$disabled())
        }

        if (this.$disabled()) return // Don't show canvas toolbox if editing disabled

        if (operator.canvasToolbox) {
            textureEditCanvas.toolbox = operator.canvasToolbox
        }
    }

    protected descriptorByTextureType = descriptorByTextureType
}
