import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TasksServiceTaskPinFragment = { __typename: 'TaskPin', id: string, x: number, y: number };

export type TasksServiceTaskFragment = { __typename: 'Task', id: string, createdAt?: string | null, number: number, public: boolean, state: Types.TaskState, comments: Array<{ __typename: 'TaskComment', createdAt?: string | null, text: string }>, pins: Array<{ __typename: 'TaskPin', id: string, x: number, y: number }> };

export type TasksServiceTasksQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  contentTypeModel: Types.ContentTypeModel;
}>;


export type TasksServiceTasksQuery = { __typename: 'Query', tasks: Array<{ __typename: 'Task', id: string, createdAt?: string | null, number: number, public: boolean, state: Types.TaskState, comments: Array<{ __typename: 'TaskComment', createdAt?: string | null, text: string }>, pins: Array<{ __typename: 'TaskPin', id: string, x: number, y: number }> } | null> };

export const TasksServiceTaskPinFragmentDoc = gql`
    fragment TasksServiceTaskPin on TaskPin {
  id
  x
  y
}
    `;
export const TasksServiceTaskFragmentDoc = gql`
    fragment TasksServiceTask on Task {
  id
  __typename
  comments {
    createdAt
    text
  }
  createdAt
  number
  pins {
    ...TasksServiceTaskPin
  }
  public
  state
}
    `;
export const TasksServiceTasksDocument = gql`
    query tasksServiceTasks($id: ID!, $contentTypeModel: ContentTypeModel!) {
  tasks(
    filter: {objectId: {equals: $id}, contentTypeModel: $contentTypeModel}
    take: 1000
  ) {
    ...TasksServiceTask
  }
}
    ${TasksServiceTaskFragmentDoc}
${TasksServiceTaskPinFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TasksServiceTasksGQL extends Apollo.Query<TasksServiceTasksQuery, TasksServiceTasksQueryVariables> {
    override document = TasksServiceTasksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }