import {inject, Injectable} from "@angular/core"
import {ActivatedRouteSnapshot, Router} from "@angular/router"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {ResolveServicePictureFromLegacyIdGQL} from "@platform/services/resolve/resolve.service.generated"

@Injectable({
    providedIn: "root",
})
export class LegacyPictureResolve {
    readonly pictureFromLegacyIdGql = inject(ResolveServicePictureFromLegacyIdGQL)

    constructor(private router: Router) {}

    async resolve(route: ActivatedRouteSnapshot): Promise<null> {
        try {
            const pictureLegacyId = String(route.params["itemId"])

            if (/^\d+$/.test(pictureLegacyId)) {
                const {picture} = await fetchThrowingErrors(this.pictureFromLegacyIdGql)({legacyId: Number(pictureLegacyId)})
                if (picture) {
                    const lastSegment = route.url?.[2]
                    void this.router.navigate(["pictures", picture.id, "revisions", lastSegment?.path ?? "latest"], {queryParams: route.queryParams})
                } else {
                    void this.router.navigate(["404"])
                }
            }
            return null
        } catch (e) {
            console.error(`Error resolving picture by legacyId: ${route.params["itemId"]}`, e)
            return null
        }
    }
}
