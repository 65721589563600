import {CommonModule} from "@angular/common"
import {AfterViewInit, Component, ElementRef, OnDestroy, input, viewChild, output} from "@angular/core"
import {Subject} from "rxjs"

export type GridSize = "large" | "medium" | "small" | "tiny"

@Component({
    selector: "cm-infinite-list",
    templateUrl: "./infinite-list.component.html",
    styleUrls: ["./infinite-list.component.scss"],
    imports: [CommonModule],
})
export class InfiniteListComponent implements AfterViewInit, OnDestroy {
    // The grid type is used for the card based views, the table for row based display of items.
    readonly $listType = input<"grid" | "table">("grid", {alias: "listType"})
    readonly $gridSize = input<GridSize>(
        "large",
        // use 0 for no items, undefined for loading state
        {alias: "gridSize"},
    )

    // use 0 for no items, undefined for loading state
    readonly $count = input<number | undefined>(
        0,
        // called when the intersection status changes
        // true for intersecting, false for not intersecting
        {alias: "count"},
    )

    // called when the intersection status changes
    // true for intersecting, false for not intersecting
    readonly $intersectionStatusChange = input.required<Subject<boolean>>({alias: "intersectionStatusChange"})

    readonly createItemEvent = output<void>()

    readonly $container = input<HTMLElement>(undefined, {alias: "container"})
    readonly $containerSelector = input<string>(undefined, {alias: "containerSelector"})
    // an intersection observer positioned at the bottom of the list detects when we need to load more elements

    // an intersection observer positioned at the bottom of the list detects when we need to load more elements
    readonly $intersectionObserverTrigger = viewChild.required<ElementRef>("cm_intersection_observer_trigger")
    private intersectionObserver!: IntersectionObserver

    ngAfterViewInit() {
        const intersectionObserver = new IntersectionObserver(
            (entries) => {
                const latestEntry = entries?.[entries.length - 1]
                this.$intersectionStatusChange()?.next(!latestEntry?.isIntersecting)
            },
            {
                root: this.$container() ?? document.querySelector(this.$containerSelector() ?? ".cm-app-main-container"),
                rootMargin: "400px",
                threshold: [0, 1],
            },
        )
        intersectionObserver.observe(this.$intersectionObserverTrigger().nativeElement)
    }

    ngOnDestroy() {
        this.intersectionObserver?.disconnect()
    }
}
