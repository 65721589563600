import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PictureDetailsRevisionFragment = { __typename: 'PictureRevision', id: string, legacyId: number, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null } | null };

export type PictureDetailsFragment = { __typename: 'Picture', id: string, legacyId: number, description?: string | null, jobAssignmentsCount: number, name: string, nextActor: Types.NextActor, number: number, state: Types.PictureState, attachments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }>, latestRevision?: { __typename: 'PictureRevision', id: string, legacyId: number, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, set: { __typename: 'Set', id: string, legacyId: number, name: string, number: number, project: { __typename: 'Project', id: string, legacyId: number, name: string, number: number, organization: { __typename: 'Organization', id: string } } }, template?: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number }>, latestRevision?: { __typename: 'TemplateRevision', id: string } | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type PictureDetailsRenderJobFragment = { __typename: 'Job', id: string, legacyId: number, finished?: string | null, message?: string | null, name?: string | null, progress?: number | null, state: Types.JobState, started?: string | null, createdBy?: { __typename: 'User', id: string, name: string } | null };

export type PictureDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type PictureDetailsQuery = { __typename: 'Query', item: { __typename: 'Picture', id: string, legacyId: number, description?: string | null, jobAssignmentsCount: number, name: string, nextActor: Types.NextActor, number: number, state: Types.PictureState, attachments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }>, latestRevision?: { __typename: 'PictureRevision', id: string, legacyId: number, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, set: { __typename: 'Set', id: string, legacyId: number, name: string, number: number, project: { __typename: 'Project', id: string, legacyId: number, name: string, number: number, organization: { __typename: 'Organization', id: string } } }, template?: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number }>, latestRevision?: { __typename: 'TemplateRevision', id: string } | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type PictureDetailsRenderJobsQueryVariables = Types.Exact<{
  pictureLegacyId: Types.Scalars['Int']['input'];
}>;


export type PictureDetailsRenderJobsQuery = { __typename: 'Query', jobAssignments: Array<{ __typename: 'JobAssignment', job: { __typename: 'Job', id: string, legacyId: number, finished?: string | null, message?: string | null, name?: string | null, progress?: number | null, state: Types.JobState, started?: string | null, createdBy?: { __typename: 'User', id: string, name: string } | null } } | null> };

export type CreatePictureDetailsAssetAssignmentMutationVariables = Types.Exact<{
  input: Types.MutationCreatePictureAssetAssignmentInput;
}>;


export type CreatePictureDetailsAssetAssignmentMutation = { __typename: 'Mutation', createPictureAssetAssignment: { __typename: 'PictureAssetAssignment', id: string, legacyId: number } };

export type UpdatePictureDetailsMutationVariables = Types.Exact<{
  input: Types.MutationUpdatePictureInput;
}>;


export type UpdatePictureDetailsMutation = { __typename: 'Mutation', item: { __typename: 'Picture', id: string, legacyId: number, description?: string | null, jobAssignmentsCount: number, name: string, nextActor: Types.NextActor, number: number, state: Types.PictureState, attachments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }>, latestRevision?: { __typename: 'PictureRevision', id: string, legacyId: number, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, set: { __typename: 'Set', id: string, legacyId: number, name: string, number: number, project: { __typename: 'Project', id: string, legacyId: number, name: string, number: number, organization: { __typename: 'Organization', id: string } } }, template?: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number }>, latestRevision?: { __typename: 'TemplateRevision', id: string } | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export type PictureDetailsDeleteAssetAssignmentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type PictureDetailsDeleteAssetAssignmentMutation = { __typename: 'Mutation', deletePictureAssetAssignment?: any | null };

export type PictureDetailsDeleteRevisionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type PictureDetailsDeleteRevisionMutation = { __typename: 'Mutation', deletePictureRevision?: any | null };

export type DeletePictureDetailsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeletePictureDetailsMutation = { __typename: 'Mutation', deletePicture?: any | null };

export type PictureDetailsUpdatePictureMutationVariables = Types.Exact<{
  input: Types.MutationUpdatePictureInput;
}>;


export type PictureDetailsUpdatePictureMutation = { __typename: 'Mutation', updatePicture: { __typename: 'Picture', id: string, legacyId: number, description?: string | null, jobAssignmentsCount: number, name: string, nextActor: Types.NextActor, number: number, state: Types.PictureState, attachments: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }>, latestRevision?: { __typename: 'PictureRevision', id: string, legacyId: number, createdAt: string, number: number, createdBy?: { __typename: 'User', id: string, legacyId: number, firstName?: string | null, lastName?: string | null } | null } | null, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, set: { __typename: 'Set', id: string, legacyId: number, name: string, number: number, project: { __typename: 'Project', id: string, legacyId: number, name: string, number: number, organization: { __typename: 'Organization', id: string } } }, template?: { __typename: 'Template', id: string, legacyId: number, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number }>, latestRevision?: { __typename: 'TemplateRevision', id: string } | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null } };

export const PictureDetailsRevisionFragmentDoc = gql`
    fragment PictureDetailsRevision on PictureRevision {
  id
  legacyId
  __typename
  createdAt
  number
  createdBy {
    id
    legacyId
    firstName
    lastName
  }
}
    `;
export const PictureDetailsFragmentDoc = gql`
    fragment PictureDetails on Picture {
  __typename
  id
  legacyId
  description
  jobAssignmentsCount
  name
  nextActor
  number
  state
  attachments: dataObjectAssignments(filter: {assignmentType: [Attachment]}) {
    id
    dataObject {
      id
    }
  }
  latestRevision {
    ...PictureDetailsRevision
  }
  organization {
    id
    legacyId
    name
  }
  set {
    id
    legacyId
    name
    number
    project {
      id
      legacyId
      name
      number
      organization {
        id
      }
    }
  }
  template {
    id
    legacyId
    revisions {
      id
      legacyId
    }
    latestRevision {
      id
    }
  }
  galleryImage {
    id
  }
}
    `;
export const PictureDetailsRenderJobFragmentDoc = gql`
    fragment PictureDetailsRenderJob on Job {
  id
  legacyId
  finished
  message
  name
  progress
  state
  started
  createdBy {
    id
    name
  }
}
    `;
export const PictureDetailsDocument = gql`
    query pictureDetails($id: ID!) {
  item: picture(id: $id) {
    ...PictureDetails
  }
}
    ${PictureDetailsFragmentDoc}
${PictureDetailsRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureDetailsGQL extends Apollo.Query<PictureDetailsQuery, PictureDetailsQueryVariables> {
    override document = PictureDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureDetailsRenderJobsDocument = gql`
    query pictureDetailsRenderJobs($pictureLegacyId: Int!) {
  jobAssignments(
    filter: {objectLegacyId: $pictureLegacyId, contentTypeModel: Picture}
  ) {
    job {
      ...PictureDetailsRenderJob
    }
  }
}
    ${PictureDetailsRenderJobFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureDetailsRenderJobsGQL extends Apollo.Query<PictureDetailsRenderJobsQuery, PictureDetailsRenderJobsQueryVariables> {
    override document = PictureDetailsRenderJobsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePictureDetailsAssetAssignmentDocument = gql`
    mutation createPictureDetailsAssetAssignment($input: MutationCreatePictureAssetAssignmentInput!) {
  createPictureAssetAssignment(input: $input) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePictureDetailsAssetAssignmentGQL extends Apollo.Mutation<CreatePictureDetailsAssetAssignmentMutation, CreatePictureDetailsAssetAssignmentMutationVariables> {
    override document = CreatePictureDetailsAssetAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePictureDetailsDocument = gql`
    mutation updatePictureDetails($input: MutationUpdatePictureInput!) {
  item: updatePicture(input: $input) {
    ...PictureDetails
  }
}
    ${PictureDetailsFragmentDoc}
${PictureDetailsRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePictureDetailsGQL extends Apollo.Mutation<UpdatePictureDetailsMutation, UpdatePictureDetailsMutationVariables> {
    override document = UpdatePictureDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureDetailsDeleteAssetAssignmentDocument = gql`
    mutation pictureDetailsDeleteAssetAssignment($id: ID!) {
  deletePictureAssetAssignment(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureDetailsDeleteAssetAssignmentGQL extends Apollo.Mutation<PictureDetailsDeleteAssetAssignmentMutation, PictureDetailsDeleteAssetAssignmentMutationVariables> {
    override document = PictureDetailsDeleteAssetAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureDetailsDeleteRevisionDocument = gql`
    mutation pictureDetailsDeleteRevision($id: ID!) {
  deletePictureRevision(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureDetailsDeleteRevisionGQL extends Apollo.Mutation<PictureDetailsDeleteRevisionMutation, PictureDetailsDeleteRevisionMutationVariables> {
    override document = PictureDetailsDeleteRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePictureDetailsDocument = gql`
    mutation deletePictureDetails($id: ID!) {
  deletePicture(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePictureDetailsGQL extends Apollo.Mutation<DeletePictureDetailsMutation, DeletePictureDetailsMutationVariables> {
    override document = DeletePictureDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureDetailsUpdatePictureDocument = gql`
    mutation pictureDetailsUpdatePicture($input: MutationUpdatePictureInput!) {
  updatePicture(input: $input) {
    ...PictureDetails
  }
}
    ${PictureDetailsFragmentDoc}
${PictureDetailsRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureDetailsUpdatePictureGQL extends Apollo.Mutation<PictureDetailsUpdatePictureMutation, PictureDetailsUpdatePictureMutationVariables> {
    override document = PictureDetailsUpdatePictureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }