import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type JobFarmGroupsTableItemFragment = { __typename: 'JobFarmGroup', id: string, name: string, otherConfig?: any | null, queueGroup: string, organization: { __typename: 'Organization', id: string, name?: string | null } };

export type JobFarmGroupsTableItemsQueryVariables = Types.Exact<{
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type JobFarmGroupsTableItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'JobFarmGroup', id: string, name: string, otherConfig?: any | null, queueGroup: string, organization: { __typename: 'Organization', id: string, name?: string | null } } | null> };

export type CreateJobFarmGroupsTableItemMutationVariables = Types.Exact<{
  input: Types.MutationCreateJobFarmGroupInput;
}>;


export type CreateJobFarmGroupsTableItemMutation = { __typename: 'Mutation', createJobFarmGroup: { __typename: 'JobFarmGroup', id: string, name: string, otherConfig?: any | null, queueGroup: string, organization: { __typename: 'Organization', id: string, name?: string | null } } };

export type UpdateJobFarmGroupsTableItemMutationVariables = Types.Exact<{
  input: Types.MutationUpdateJobFarmGroupInput;
}>;


export type UpdateJobFarmGroupsTableItemMutation = { __typename: 'Mutation', updateJobFarmGroup: { __typename: 'JobFarmGroup', id: string, name: string, otherConfig?: any | null, queueGroup: string, organization: { __typename: 'Organization', id: string, name?: string | null } } };

export type DeleteJobFarmGroupsTableItemMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteJobFarmGroupsTableItemMutation = { __typename: 'Mutation', deleteJobFarmGroup?: any | null };

export const JobFarmGroupsTableItemFragmentDoc = gql`
    fragment JobFarmGroupsTableItem on JobFarmGroup {
  id
  name
  otherConfig
  queueGroup
  organization {
    id
    name
  }
}
    `;
export const JobFarmGroupsTableItemsDocument = gql`
    query jobFarmGroupsTableItems($take: Int, $skip: Int) {
  items: jobFarmGroups(
    take: $take
    skip: $skip
    orderBy: [{key: name, direction: asc}]
  ) {
    ...JobFarmGroupsTableItem
  }
  totalCount: jobFarmGroupsCount
}
    ${JobFarmGroupsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobFarmGroupsTableItemsGQL extends Apollo.Query<JobFarmGroupsTableItemsQuery, JobFarmGroupsTableItemsQueryVariables> {
    override document = JobFarmGroupsTableItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateJobFarmGroupsTableItemDocument = gql`
    mutation createJobFarmGroupsTableItem($input: MutationCreateJobFarmGroupInput!) {
  createJobFarmGroup(input: $input) {
    ...JobFarmGroupsTableItem
  }
}
    ${JobFarmGroupsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateJobFarmGroupsTableItemGQL extends Apollo.Mutation<CreateJobFarmGroupsTableItemMutation, CreateJobFarmGroupsTableItemMutationVariables> {
    override document = CreateJobFarmGroupsTableItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateJobFarmGroupsTableItemDocument = gql`
    mutation updateJobFarmGroupsTableItem($input: MutationUpdateJobFarmGroupInput!) {
  updateJobFarmGroup(input: $input) {
    ...JobFarmGroupsTableItem
  }
}
    ${JobFarmGroupsTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateJobFarmGroupsTableItemGQL extends Apollo.Mutation<UpdateJobFarmGroupsTableItemMutation, UpdateJobFarmGroupsTableItemMutationVariables> {
    override document = UpdateJobFarmGroupsTableItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteJobFarmGroupsTableItemDocument = gql`
    mutation deleteJobFarmGroupsTableItem($id: ID!) {
  deleteJobFarmGroup(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteJobFarmGroupsTableItemGQL extends Apollo.Mutation<DeleteJobFarmGroupsTableItemMutation, DeleteJobFarmGroupsTableItemMutationVariables> {
    override document = DeleteJobFarmGroupsTableItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }