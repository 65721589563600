import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type JobTaskTableItemFragment = { __typename: 'JobTask', id: string, legacyId: number, attemptCount: number, deferredUntil?: string | null, finished?: string | null, maxAttempts: number, message?: string | null, name?: string | null, taskType?: string | null, progress?: number | null, state: Types.JobTaskState, started?: string | null, claimedBy?: string | null, createdBy: { __typename: 'User', id: string, name: string } };

export type JobTaskTableItemsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type JobTaskTableItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'JobTask', id: string, legacyId: number, attemptCount: number, deferredUntil?: string | null, finished?: string | null, maxAttempts: number, message?: string | null, name?: string | null, taskType?: string | null, progress?: number | null, state: Types.JobTaskState, started?: string | null, claimedBy?: string | null, createdBy: { __typename: 'User', id: string, name: string } } | null> };

export const JobTaskTableItemFragmentDoc = gql`
    fragment JobTaskTableItem on JobTask {
  id
  legacyId
  attemptCount
  deferredUntil
  finished
  maxAttempts
  message
  name
  taskType
  progress
  state
  started
  claimedBy
  createdBy {
    id
    name
  }
}
    `;
export const JobTaskTableItemsDocument = gql`
    query jobTaskTableItems($id: ID!) {
  items: jobTasks(
    filter: {jobId: {equals: $id}}
    take: 1000
    orderBy: [{key: legacyId, direction: asc}]
  ) {
    ...JobTaskTableItem
  }
  totalCount: jobTasksCount(filter: {jobId: {equals: $id}})
}
    ${JobTaskTableItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobTaskTableItemsGQL extends Apollo.Query<JobTaskTableItemsQuery, JobTaskTableItemsQueryVariables> {
    override document = JobTaskTableItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }