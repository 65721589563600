<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> Template Instance </ng-container>
    <ng-container cm-content>
        <cm-card [defaultThumbnail]="false">
            <ng-container cm-thumbnail>
                @if ($thumbnailObject(); as thumbnailData) {
                    <cm-data-object-thumbnail [dataObjectId]="thumbnailData.id"></cm-data-object-thumbnail>
                } @else {
                    @if ($thumbnailUrl(); as thumbnailData) {
                        <cm-thumbnail [url]="thumbnailData"></cm-thumbnail>
                    }
                }
            </ng-container>
            <ng-container cm-title>
                @if ($templateRevisionId() !== undefined) {
                    {{ $title() }}
                } @else {
                    <cm-template-node [node]="$template()" [noDrag]="true" #dragImage></cm-template-node>
                }
            </ng-container>
        </cm-card>
        <cm-value-slot [node]="$node()" [key]="'id'" [label]="'ID'" [validate]="isValidExternalId"></cm-value-slot>
        <cm-value-slot [node]="$node()" [key]="'name'" [label]="'Name'"></cm-value-slot>
        <div class="cm-template-revision-settings">
            @if ($templateReference(); as templateReference) {
                <cm-value-slot
                    [node]="templateReference"
                    [key]="'templateRevisionId'"
                    [label]="'Revision'"
                    [selectionPossibilities]="$revisionPossibilities()"
                ></cm-value-slot>

                @if ($latestTemplateRevisionId() !== undefined && $latestTemplateRevisionId() !== $templateRevisionId()) {
                    <cm-button class="cm-action-button" (click)="updateToLatestTemplateRevision()">
                        <ng-container cm-text> <i class="far fa-arrow-up"></i> Update </ng-container>
                    </cm-button>
                } @else {
                    <div class="cm-latest"><i class="far fa-check"></i>Latest</div>
                }
            }
        </div>
    </ng-container>
</cm-inspector-section>

@if ($isActive()) {
    <cm-inspector-section>
        <ng-container cm-title> Inputs </ng-container>
        <ng-container cm-content>
            @if ($inputs().length === 0) {
                No inputs
            } @else {
                @if ($slotInputs().length > 0) {
                    <div class="cm-template-settings">
                        @for (input of $slotInputs(); track input.key) {
                            <cm-value-slot
                                [node]="$parameters().parameters"
                                [key]="input.key"
                                [label]="input.label"
                                [icon]="input.icon"
                                [schema]="input.schema"
                                [selectionPossibilities]="input.selectionPossibilities"
                                [topLabel]="true"
                                [overwrittenValue]="input.overwrittenValue"
                                [changeSideEffect]="onChangedInputSideEffect(input)"
                                (updatedOverwrittenValue)="updateOverwrittenValue(input.key, $event)"
                            >
                            </cm-value-slot>
                        }
                    </div>
                }
                @if ($nonSlotInputs().length > 0) {
                    <div class="cm-template-settings">
                        @for (input of $nonSlotInputs(); track input.key) {
                            <cm-value-slot
                                [node]="$parameters().parameters"
                                [key]="input.key"
                                [label]="input.label"
                                [icon]="input.icon"
                                [schema]="input.schema"
                                [selectionPossibilities]="input.selectionPossibilities"
                                [topLabel]="true"
                                [overwrittenValue]="input.overwrittenValue"
                                [throttledUpdate]="input.throttledUpdate ?? false"
                                [min]="input.min"
                                [max]="input.max"
                                [delta]="input.delta"
                                [numberModifier]="input.numberModifier"
                                [changeSideEffect]="onChangedInputSideEffect(input)"
                                (updatedOverwrittenValue)="updateOverwrittenValue(input.key, $event)"
                            >
                                <ng-container cm-top-right>
                                    @if (input.type === "material") {
                                        @if ($parameters().parameters.parameters[input.key]; as material) {
                                            <div matTooltip="Move material" (click)="$event.stopPropagation()" [matMenuTriggerFor]="meshUVMenu" class="cm-icon">
                                                <i class="far fa-arrows"></i>
                                                <!-- hasBackdrop needs to be false in order for node drag and drop to work! -->
                                                <mat-menu [hasBackdrop]="false" #meshUVMenu>
                                                    <div (click)="$event.stopPropagation()" class="cm-overlay-settings">
                                                        <cm-inspector-section>
                                                            <ng-container cm-title> Material Settings </ng-container>
                                                            <ng-container cm-content>
                                                                @for (parameter of input.subParameters; track parameter.key) {
                                                                    <cm-value-slot
                                                                        [node]="$parameters().parameters"
                                                                        [key]="parameter.key"
                                                                        [label]="parameter.label"
                                                                        [icon]="parameter.icon"
                                                                        [schema]="parameter.schema"
                                                                        [selectionPossibilities]="parameter.selectionPossibilities"
                                                                        [overwrittenValue]="parameter.overwrittenValue"
                                                                        [throttledUpdate]="parameter.throttledUpdate ?? false"
                                                                        [min]="parameter.min"
                                                                        [max]="parameter.max"
                                                                        [delta]="parameter.delta"
                                                                        [numberModifier]="parameter.numberModifier"
                                                                        (updatedOverwrittenValue)="updateOverwrittenValue(parameter.key, $event)"
                                                                    >
                                                                    </cm-value-slot>
                                                                }
                                                            </ng-container>
                                                        </cm-inspector-section>
                                                    </div>
                                                </mat-menu>
                                            </div>
                                        }
                                    }
                                </ng-container>
                            </cm-value-slot>
                        }
                    </div>
                }
            }
        </ng-container>
    </cm-inspector-section>

    <cm-inspector-section>
        <ng-container cm-title> Outputs </ng-container>
        <ng-container cm-content>
            @for (output of $outputs(); track output.parameters.outputId) {
                <cm-template-instance-output [node]="output"></cm-template-instance-output>
            }
            @if ($outputs().length === 0) {
                No outputs
            }
        </ng-container>
    </cm-inspector-section>
} @else {
    <cm-inspector-section>
        <ng-container cm-title> Inputs / Outputs </ng-container>
        <ng-container cm-content>Instance is currently not active </ng-container>
    </cm-inspector-section>
}
