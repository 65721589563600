<div class="cm-container">
    <cm-entity-responsive-sidebar class="cm-entity-sidebar">
        <cm-item-filters>
            <ng-content select="[cm-filters]"></ng-content>
        </cm-item-filters>
    </cm-entity-responsive-sidebar>
    <div class="cm-item-list">
        <cm-infinite-list
            [count]="$data()?.totalCount"
            [intersectionStatusChange]="$pageFilledStatusChange()"
            containerSelector=".cdk-overlay-container"
            class="cm-items-list"
            [listType]="'grid'"
            [gridSize]="$gridSize()"
        >
            @for (item of $data()?.items; track item.trackId) {
                @if (item.error) {
                    <cm-card-error @fadeInPlaceholder></cm-card-error>
                } @else if (item.data) {
                    <cm-entity-card @fadeInCard [entity]="item.data" (selectEntity)="selectEntity(item.data)" [showTooltip]="false"> </cm-entity-card>
                } @else {
                    <cm-card-placeholder @fadeInPlaceholder></cm-card-placeholder>
                }
            }
        </cm-infinite-list>
    </div>
</div>
