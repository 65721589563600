import {Injector} from "@angular/core"
import {
    CreateJobGQL,
    CreateDataObjectAssignmentGQL,
    DeleteDataObjectAssignmentGQL,
    GetAllGlftAssignmentsForDataObjectGQL,
    GetGltfDataObjectAssignmentsGQL,
    GetJobTaskGQL,
    DeleteDataObjectArHelperGQL,
} from "@app/common/helpers/ar/ar.helper.generated"
import {Settings} from "@app/common/models/settings/settings"
import {arGltfGenerationTask, arUsdzGenerationTask} from "@cm/job-nodes/ar"
import {JobNodes} from "@cm/job-nodes/job-nodes"
import {Parameters, TemplateGraph} from "@cm/template-nodes"
import {graphToJson, sortedJSONStringify} from "@cm/utils"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {filter, firstValueFrom, map, take} from "rxjs"
import {ContentTypeModel, DataObjectAssignmentType, JobTaskState} from "@generated"
import {fetchThrowingErrors} from "@app/common/helpers/api/fetch"

export type ArContentModel = "model/vnd.usdz+zip" | "model/gltf-binary"

export const getParametersForArGeneration = (sceneManagerService: SceneManagerService) => {
    //the local configuration is wrong, it does not contain all relevant parameters.
    return sceneManagerService.$currentGlobalConfiguration()
}

export const getArAssignmentKey = (parameters: Parameters) => {
    return sortedJSONStringify(parameters.parameters)
}

const makeJobGraph = (templateRevisionId: string, configurationBase64: string) => {
    const gltfGenerationTask = JobNodes.task(arGltfGenerationTask, {
        input: JobNodes.struct({
            templateRevisionId: JobNodes.value(templateRevisionId),
            templateParametersBase64: JobNodes.value(configurationBase64),
        }),
    })
    const usdzGenerationTask = JobNodes.task(arUsdzGenerationTask, {
        input: gltfGenerationTask,
    })

    return JobNodes.jobGraph(JobNodes.list([gltfGenerationTask, usdzGenerationTask]), {
        platformVersion: Settings.APP_VERSION,
    })
}

export const generateNewArModels = async (
    templateId: string,
    templateRevisionId: string,
    currentParams: Parameters,
    organizationLegacyId: number,
    injector: Injector,
) => {
    const templateParametersBase64 = btoa(JSON.stringify(currentParams.serialize()))

    console.log(`Starting AR generation job for revision ${templateRevisionId} of template ${templateId}`)
    console.log("URL parameters:", `?templateRevisionId=${templateRevisionId}&templateParametersBase64=${templateParametersBase64}`)

    return mutateThrowingErrors(injector.get(CreateJobGQL))({
        input: {
            graph: graphToJson(makeJobGraph(templateRevisionId, templateParametersBase64), console),
            name: "AR generation",
            organizationLegacyId: organizationLegacyId,
        },
    })
}

export const waitForJobToFinish = async (jobId: string, injector: Injector) => {
    const jobStateWithOutput = injector.get(GetJobTaskGQL)
    const jobTask = await firstValueFrom(
        jobStateWithOutput.watch({id: jobId}, {pollInterval: 2000}).valueChanges.pipe(
            map(({data: {jobTask}}) => jobTask),
            filter((jobTask) => {
                switch (jobTask.state) {
                    case JobTaskState.Runnable:
                    case JobTaskState.Running:
                    case JobTaskState.Init:
                        return false
                    default:
                        return true
                }
            }),
            take(1),
        ),
    )

    if (jobTask.state !== JobTaskState.Complete) {
        throw new Error("AR generation failed")
    }
}

export const runBatchedArJobs = async (
    arCreationParameters: Parameters[],
    templateId: string,
    templateRevisionId: string,
    organizationLegacyId: number,
    concurrency: number,
    injector: Injector,
) => {
    const createAndWaitForJob = async (params: Parameters) => {
        const {createJob: arGenerationJob} = await generateNewArModels(templateId, templateRevisionId, params, organizationLegacyId, injector)
        try {
            await waitForJobToFinish(arGenerationJob.id, injector)
        } catch {
            const templateParametersBase64 = btoa(JSON.stringify(params.serialize()))
            console.error("AR generation failed for")
            console.log("URL parameters:", `?templateRevisionId=${templateRevisionId}&templateParametersBase64=${templateParametersBase64}`)
        }
    }

    const jobPromises: Promise<void>[] = []
    for (const params of arCreationParameters) {
        jobPromises.push(createAndWaitForJob(params))
        if (jobPromises.length >= concurrency) {
            await Promise.all(jobPromises)
            jobPromises.length = 0
        }
    }

    if (jobPromises.length) await Promise.all(jobPromises)
}

export const computeArLodAssignmentKey = async (
    sceneManagerService: SceneManagerService,
    templateRevisionId: string,
    parameters: Parameters,
    templateGraph: TemplateGraph,
) => {
    sceneManagerService.$lodType.set("ar")
    sceneManagerService.$templateRevisionId.set(templateRevisionId)
    sceneManagerService.$instanceParameters.set(parameters)
    sceneManagerService.$templateGraph.set(templateGraph)
    sceneManagerService.compileTemplate()
    await sceneManagerService.sync(true)
    return getArAssignmentKey(getParametersForArGeneration(sceneManagerService))
}

export const assignGltfDataObject = async (dataObjectId: string, templateRevisionId: string, assignmentKey: string, injector: Injector) => {
    await mutateThrowingErrors(injector.get(CreateDataObjectAssignmentGQL))({
        input: {
            dataObjectId,
            contentTypeModel: ContentTypeModel.TemplateRevision,
            objectId: templateRevisionId,
            type: DataObjectAssignmentType.CachedTemplateGltf,
            assignmentKey,
        },
    })
}

export const getAllGlftAssignments = async (dataObjectId: string, injector: Injector) => {
    const {dataObjectAssignments} = await fetchThrowingErrors(injector.get(GetAllGlftAssignmentsForDataObjectGQL))({
        dataObjectId,
    })
    return dataObjectAssignments
}

export const getGltfDataObjects = async (templateRevisionId: string, assignmentKey: string, injector: Injector) => {
    const {dataObjectAssignments} = await fetchThrowingErrors(injector.get(GetGltfDataObjectAssignmentsGQL))({templateRevisionId, assignmentKey})
    return dataObjectAssignments
}

export const assignWebKeyToExistingArModel = async (templateRevisionId: string, arAssignmentKey: string, webAssignmentKey: string, injector: Injector) => {
    if (arAssignmentKey === webAssignmentKey) return
    const arKeyAssignments = (await getGltfDataObjects(templateRevisionId, arAssignmentKey, injector)).filter((x) => x !== null)

    if (arKeyAssignments.length === 0) throw new Error("No ar model found")
    await assignGltfDataObject(arKeyAssignments[0].dataObject.id, templateRevisionId, webAssignmentKey, injector)
}

export const assignWebKeysToExistingArModel = async (templateRevisionId: string, webKeyToArKeyMap: Map<string, string>, injector: Injector) => {
    for (const [webKey, arKey] of webKeyToArKeyMap) {
        if (arKey === webKey) continue
        console.log(`Assigning web key ${webKey} to ar model of ${arKey}`)
        const dataObjectAssignments = await getGltfDataObjects(templateRevisionId, webKey, injector)
        for (const dataObjectAssignment of dataObjectAssignments)
            if (dataObjectAssignment !== null) await deleteDataObjectAssignment(dataObjectAssignment.id, injector)

        try {
            await assignWebKeyToExistingArModel(templateRevisionId, arKey, webKey, injector)
        } catch (e) {
            console.error(e)
        }
    }
}

export const deleteDataObjectAssignment = async (dataObjectAssignmentId: string, injector: Injector) => {
    await mutateThrowingErrors(injector.get(DeleteDataObjectAssignmentGQL))({id: dataObjectAssignmentId})
}

export const deleteDataObject = async (dataObjectId: string, injector: Injector) => {
    await mutateThrowingErrors(injector.get(DeleteDataObjectArHelperGQL))({dataObjectId: dataObjectId})
}
