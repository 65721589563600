import {Component, ElementRef, HostListener, input, output} from "@angular/core"
import {SocketPosition} from "@node-editor/models"

@Component({
    selector: "cm-node-socket",
    templateUrl: "./node-socket.component.html",
    styleUrls: ["./node-socket.component.scss"],
    host: {
        "[class.cm-input-socket]": "$type()==='input'",
        "[class.cm-output-socket]": "$type()==='output'",
    },
})
export class NodeSocketComponent {
    readonly $type = input.required<"input" | "output">({alias: "type"})
    readonly connectionStart = output<void>()
    readonly connectionEnd = output<void>()

    @HostListener("mousedown", ["$event"]) onMouseDown(event: MouseEvent) {
        event.stopPropagation()
        this.connectionStart.emit()
    }

    @HostListener("mouseup") onMouseUp() {
        this.connectionEnd.emit()
    }

    constructor(private elementRef: ElementRef) {}

    getPosition(): SocketPosition {
        const boundingRect: DOMRect = this.elementRef.nativeElement.getBoundingClientRect()
        return {
            x: boundingRect.x + boundingRect.width / 2,
            y: boundingRect.y + boundingRect.height / 2,
        }
    }
}
