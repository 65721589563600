import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type JobFarmInstancesTableFragment = { __typename: 'JobFarmInstance', id: string, hwFingerprint: string, otherInfo?: any | null, lastReport?: string | null, state: Types.JobFarmInstanceState, type: Types.JobFarmInstanceType, version: string, user: { __typename: 'User', email: string }, group?: { __typename: 'JobFarmGroup', id: string, name: string } | null, claim?: { __typename: 'JobTask', id: string, legacyId: number, job?: { __typename: 'JobTask', id: string } | null } | null };

export type JobFarmInstancesDeleteJobFarmInstanceMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type JobFarmInstancesDeleteJobFarmInstanceMutation = { __typename: 'Mutation', deleteJobFarmInstance?: any | null };

export type JobFarmInstancesUpdateJobFarmInstanceMutationVariables = Types.Exact<{
  input: Types.MutationUpdateJobFarmInstanceInput;
}>;


export type JobFarmInstancesUpdateJobFarmInstanceMutation = { __typename: 'Mutation', updateJobFarmInstance: { __typename: 'JobFarmInstance', id: string } };

export type JobFarmInstancesTableItemsQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type JobFarmInstancesTableItemsQuery = { __typename: 'Query', jobFarmInstances: Array<{ __typename: 'JobFarmInstance', id: string, hwFingerprint: string, otherInfo?: any | null, lastReport?: string | null, state: Types.JobFarmInstanceState, type: Types.JobFarmInstanceType, version: string, user: { __typename: 'User', email: string }, group?: { __typename: 'JobFarmGroup', id: string, name: string } | null, claim?: { __typename: 'JobTask', id: string, legacyId: number, job?: { __typename: 'JobTask', id: string } | null } | null } | null> };

export const JobFarmInstancesTableFragmentDoc = gql`
    fragment JobFarmInstancesTable on JobFarmInstance {
  id
  hwFingerprint
  otherInfo
  lastReport
  state
  type
  version
  user {
    email
  }
  group {
    id
    name
  }
  claim {
    id
    legacyId
    job {
      id
    }
  }
}
    `;
export const JobFarmInstancesDeleteJobFarmInstanceDocument = gql`
    mutation jobFarmInstancesDeleteJobFarmInstance($id: ID!) {
  deleteJobFarmInstance(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class JobFarmInstancesDeleteJobFarmInstanceGQL extends Apollo.Mutation<JobFarmInstancesDeleteJobFarmInstanceMutation, JobFarmInstancesDeleteJobFarmInstanceMutationVariables> {
    override document = JobFarmInstancesDeleteJobFarmInstanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobFarmInstancesUpdateJobFarmInstanceDocument = gql`
    mutation jobFarmInstancesUpdateJobFarmInstance($input: MutationUpdateJobFarmInstanceInput!) {
  updateJobFarmInstance(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class JobFarmInstancesUpdateJobFarmInstanceGQL extends Apollo.Mutation<JobFarmInstancesUpdateJobFarmInstanceMutation, JobFarmInstancesUpdateJobFarmInstanceMutationVariables> {
    override document = JobFarmInstancesUpdateJobFarmInstanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JobFarmInstancesTableItemsDocument = gql`
    query jobFarmInstancesTableItems($skip: Int, $take: Int) {
  jobFarmInstances(skip: $skip, take: $take) {
    ...JobFarmInstancesTable
  }
}
    ${JobFarmInstancesTableFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class JobFarmInstancesTableItemsGQL extends Apollo.Query<JobFarmInstancesTableItemsQuery, JobFarmInstancesTableItemsQueryVariables> {
    override document = JobFarmInstancesTableItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }