import {BoundaryItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-item"
import {Vector2} from "@cm/math"
import {CurveInterpolator} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/curve-interpolator"

export function computeTValuesForBoundary(boundary: BoundaryItem, errorThresh = 0.25) {
    const curveMin = boundary.curveMin
    const curveMax = boundary.curveMax
    const tValues = [...curveMin.tValues, ...curveMax.tValues].sort((a, b) => a - b)
    // try to remove intermediate values if the error is small
    const isRemovalErrorAcceptable = (interpolator: CurveInterpolator, t: number, tPrev: number, tNext: number) => {
        const posPrev = interpolator.evaluate(tPrev)
        const posNext = interpolator.evaluate(tNext)
        const posT = interpolator.evaluate(t)
        const coeff = (t - tPrev) / (tNext - tPrev)
        const posInterpolated = Vector2.lerp(posPrev, posNext, coeff)
        const distanceSquared = Vector2.distanceSquared(posT, posInterpolated)
        return distanceSquared <= errorThresh * errorThresh
    }
    const interpolatorMin = curveMin.interpolator
    const interpolatorMax = curveMax.interpolator
    for (let i = tValues.length - 2; i >= 1; i--) {
        const t = tValues[i]
        const tPrev = tValues[i - 1]
        const tNext = tValues[i + 1]
        if (isRemovalErrorAcceptable(interpolatorMin, t, tPrev, tNext) && isRemovalErrorAcceptable(interpolatorMax, t, tPrev, tNext)) {
            tValues.splice(i, 1)
        }
    }
    return tValues
}

export function subdivideTValues(tValues: number[], numSubdivisions: number): number[] {
    if (numSubdivisions < 0) {
        throw new Error("numSubdivisions must be non-negative")
    }
    if (numSubdivisions === 0) {
        return tValues
    }
    const newTValues = new Array<number>((tValues.length - 1) * (numSubdivisions + 1) + 1)
    for (let i = 0; i < tValues.length - 1; i++) {
        const tStart = tValues[i]
        const tEnd = tValues[i + 1]
        for (let j = 0; j <= numSubdivisions; j++) {
            newTValues[i * (numSubdivisions + 1) + j] = tStart + (tEnd - tStart) * (j / (numSubdivisions + 1))
        }
    }
    newTValues[(tValues.length - 1) * (numSubdivisions + 1)] = tValues[tValues.length - 1]
    return newTValues
}
