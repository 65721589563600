import {Component, input, output} from "@angular/core"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextAreaComponent} from "@common/components/inputs/native/native-input-text-area/native-input-text-area.component"
import {SectionComponent} from "@common/components/item"

type ItemT = {comment?: string | null}

@Component({
    selector: "cm-comment-section",
    imports: [SectionComponent, InputContainerComponent, NativeInputTextAreaComponent],
    templateUrl: "./comment-section.component.html",
    styleUrl: "./comment-section.component.scss",
})
export class CommentSectionComponent {
    readonly $item = input<ItemT>(undefined, {alias: "item"})
    readonly $label = input<string>(" Description", {alias: "label"})
    readonly $minRows = input<number>(6, {alias: "minRows"})
    readonly $canEdit = input<boolean>(false, {alias: "canEdit"})
    readonly onChanged = output<ItemT>()
}
