import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TaskListItemFragment = { __typename: 'Task', id: string, createdAt?: string | null, number: number, public: boolean, state: Types.TaskState, comments: Array<{ __typename: 'TaskComment', createdAt?: string | null, text: string }> };

export type TaskListQueryVariables = Types.Exact<{
  objectId: Types.Scalars['ID']['input'];
  contentTypeModel: Types.ContentTypeModel;
}>;


export type TaskListQuery = { __typename: 'Query', tasks: Array<{ __typename: 'Task', id: string, createdAt?: string | null, number: number, public: boolean, state: Types.TaskState, comments: Array<{ __typename: 'TaskComment', createdAt?: string | null, text: string }> } | null> };

export type UpdateTaskListItemMutationVariables = Types.Exact<{
  input: Types.MutationUpdateTaskInput;
}>;


export type UpdateTaskListItemMutation = { __typename: 'Mutation', updateTask: { __typename: 'Task', id: string, createdAt?: string | null, number: number, public: boolean, state: Types.TaskState, comments: Array<{ __typename: 'TaskComment', createdAt?: string | null, text: string }> } };

export type AddTaskListItemMutationVariables = Types.Exact<{
  input: Types.MutationCreateTaskInput;
}>;


export type AddTaskListItemMutation = { __typename: 'Mutation', createTask: { __typename: 'Task', id: string, createdAt?: string | null, number: number, public: boolean, state: Types.TaskState, comments: Array<{ __typename: 'TaskComment', createdAt?: string | null, text: string }> } };

export type AddCommentToTaskListItemMutationVariables = Types.Exact<{
  input: Types.MutationCreateTaskCommentInput;
}>;


export type AddCommentToTaskListItemMutation = { __typename: 'Mutation', createTaskComment: { __typename: 'TaskComment', id: string } };

export const TaskListItemFragmentDoc = gql`
    fragment TaskListItem on Task {
  id
  createdAt
  number
  public
  state
  comments {
    createdAt
    text
  }
}
    `;
export const TaskListDocument = gql`
    query taskList($objectId: ID!, $contentTypeModel: ContentTypeModel!) {
  tasks(
    filter: {objectId: {equals: $objectId}, contentTypeModel: $contentTypeModel}
    take: 1000
  ) {
    ...TaskListItem
  }
}
    ${TaskListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaskListGQL extends Apollo.Query<TaskListQuery, TaskListQueryVariables> {
    override document = TaskListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTaskListItemDocument = gql`
    mutation updateTaskListItem($input: MutationUpdateTaskInput!) {
  updateTask(input: $input) {
    ...TaskListItem
  }
}
    ${TaskListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTaskListItemGQL extends Apollo.Mutation<UpdateTaskListItemMutation, UpdateTaskListItemMutationVariables> {
    override document = UpdateTaskListItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddTaskListItemDocument = gql`
    mutation addTaskListItem($input: MutationCreateTaskInput!) {
  createTask(input: $input) {
    ...TaskListItem
  }
}
    ${TaskListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddTaskListItemGQL extends Apollo.Mutation<AddTaskListItemMutation, AddTaskListItemMutationVariables> {
    override document = AddTaskListItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddCommentToTaskListItemDocument = gql`
    mutation addCommentToTaskListItem($input: MutationCreateTaskCommentInput!) {
  createTaskComment(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddCommentToTaskListItemGQL extends Apollo.Mutation<AddCommentToTaskListItemMutation, AddCommentToTaskListItemMutationVariables> {
    override document = AddCommentToTaskListItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }