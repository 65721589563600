import {Component, OnDestroy, input} from "@angular/core"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {SliderComponent} from "@common/components/inputs/slider/slider.component"
import {Hotkeys} from "@common/services/hotkeys/hotkeys.service"
import {filter, Subject, takeUntil} from "rxjs"
import {BrushMode, BrushSettings} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"

@Component({
    selector: "cm-brush-settings-panel",
    templateUrl: "./brush-settings-panel.component.html",
    styleUrls: ["./brush-settings-panel.component.scss"],
    imports: [ButtonComponent, SliderComponent],
})
export class BrushSettingsPanelComponent implements OnDestroy {
    readonly $brushSettings = input(new BrushSettings(), {alias: "brushSettings"})
    readonly $allowBrushModeSelection = input(true, {alias: "allowBrushModeSelection"})
    readonly $disabled = input(false, {alias: "disabled"})

    constructor(hotkeys: Hotkeys) {
        hotkeys
            .addShortcut(["+"])
            .pipe(
                takeUntil(this.unsubscribe),
                filter(() => this.$allowBrushModeSelection()),
            )
            .subscribe(() => (this.$brushSettings().brushMode = BrushMode.Add))
        hotkeys
            .addShortcut(["-"])
            .pipe(
                takeUntil(this.unsubscribe),
                filter(() => this.$allowBrushModeSelection()),
            )
            .subscribe(() => (this.$brushSettings().brushMode = BrushMode.Subtract))
    }

    ngOnDestroy(): void {
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }

    BrushMode = BrushMode

    private unsubscribe = new Subject<void>()
}
