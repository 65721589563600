import {inject, Injectable} from "@angular/core"
import {localStorageToken} from "@common/helpers/auth/token"
import {CM_COLORMASS_CLIENT} from "@common/config/client-injector"

/**
 * Simple service using local storage to obtain headers required for authentication etc.
 * Must not use GQL, as that would create a circular dependency involving Apollo
 */
@Injectable({
    providedIn: "root",
})
export class LocalStorageService {
    private readonly colormassClient = inject(CM_COLORMASS_CLIENT)

    get headersWithoutSilo(): Record<string, string> {
        const token = localStorageToken()
        return {
            Authorization: `Bearer ${token}`,
            "X-Colormass-Client": this.colormassClient,
        }
    }

    get headers(): Record<string, string> {
        try {
            const systemRole = localStorage.getItem("cm-auth-silo-system-role")
            const organizationId = localStorage.getItem("cm-auth-silo-organization")
            return {
                ...this.headersWithoutSilo,
                ...(systemRole ? {"X-Colormass-Auth-Silo-System-Role": systemRole} : {}),
                ...(organizationId ? {"X-Colormass-Auth-Silo-Organization": organizationId} : {}),
            }
        } catch (error) {
            console.error("Failed to load headers from local storage", error)
            return {}
        }
    }

    public loadSilo() {
        try {
            const systemRole = localStorage.getItem("cm-auth-silo-system-role")
            const organizationId = localStorage.getItem("cm-auth-silo-organization")
            return {systemRole, organizationId}
        } catch (error) {
            console.error("Failed to load auth silo from local storage", error)
            return {systemRole: null, organizationId: null}
        }
    }

    public storeSilo(values: {systemRole?: string | null; organizationId?: string | null}) {
        try {
            switch (values.systemRole) {
                case null:
                    localStorage.removeItem("cm-auth-silo-system-role")
                    break
                case undefined:
                    break
                default:
                    localStorage.setItem("cm-auth-silo-system-role", values.systemRole)
            }
            switch (values.organizationId) {
                case null:
                    localStorage.removeItem("cm-auth-silo-organization")
                    break
                case undefined:
                    break
                default:
                    localStorage.setItem("cm-auth-silo-organization", values.organizationId)
            }
        } catch (error) {
            console.error("Failed to save auth silo to local storage", error, values)
        }
    }

    public useStoredToken = true

    public loadToken(): string | null {
        if (!this.useStoredToken) {
            return null
        }
        try {
            return localStorage.getItem("token")
        } catch {
            // probably don't have access to localStorage, default to null
            return null
        }
    }

    public storeToken(token: string | null) {
        try {
            if (token === null) {
                localStorage.removeItem("token")
            } else {
                localStorage.setItem("token", token)
            }
        } catch (error) {
            console.error("Failed to store token in localStorage: ", error)
        }
    }

    public loadCsrfToken(): string | null {
        try {
            return localStorage.getItem("cm-csrf-token")
        } catch {
            // probably don't have access to localStorage, default to null
            return null
        }
    }

    public storeCsrfToken(token: string | null) {
        try {
            if (token === null) {
                localStorage.removeItem("cm-csrf-token")
            } else {
                localStorage.setItem("cm-csrf-token", token)
            }
        } catch (error) {
            console.error("Failed to store CSRF token in localStorage: ", error)
        }
    }
}
