<!--Label-->
@if (data) {
    <div (click)="$event.stopPropagation()">
        @if ($editable()) {
            <div
                class="cm-card-tag cm-card-tag-editable"
                [style.background]="$backgroundColor() ? $backgroundColor() : data.background"
                [matMenuTriggerFor]="status"
            >
                {{ data.label }} <i class="fa-solid fa-chevron-down"></i>
            </div>
            <mat-menu #status="matMenu">
                @for (item of $stateLabels() | keyvalue: this.utils.orderByKey; track item.key) {
                    <button [disabled]="data.state === item.key || ($disableCompleted() && isCompleted)" mat-menu-item (click)="onStateSelected(item.key)">
                        <span>{{ item.value.label }}</span>
                    </button>
                }
            </mat-menu>
        } @else {
            <div class="cm-card-tag cm-card-tag-not-editable" [style.background]="$backgroundColor() ? $backgroundColor() : data.background">
                {{ data.label }}
            </div>
        }
    </div>
}
