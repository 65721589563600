<div class="cm-container">
    <cm-entity-responsive-sidebar class="cm-entity-sidebar">
        <ng-content select="[cm-filters]"></ng-content>
    </cm-entity-responsive-sidebar>
    <div class="cm-item-list">
        <div class="cm-button-bar-container">
            <div>
                <ng-content select="[cm-buttons]"></ng-content>
            </div>
        </div>

        @if (breakpoints.$isMobileLandscape()) {
            @if (tableContent()?.templateRef; as templateRef) {
                <ng-container [ngTemplateOutlet]="templateRef"></ng-container>
            }
        } @else {
            @if (gridContent()?.templateRef; as templateRef) {
                <ng-container [ngTemplateOutlet]="templateRef"></ng-container>
            }
        }
        <cm-infinite-scroll-anchor></cm-infinite-scroll-anchor>
    </div>
</div>
