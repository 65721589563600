import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetTemplateRevisionDetailsTemplateAddSceneQueryVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type GetTemplateRevisionDetailsTemplateAddSceneQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', id: string, legacyId: number, graph?: any | null } };

export const GetTemplateRevisionDetailsTemplateAddSceneDocument = gql`
    query getTemplateRevisionDetailsTemplateAddScene($legacyId: Int!) {
  templateRevision(legacyId: $legacyId) {
    id
    legacyId
    graph
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateRevisionDetailsTemplateAddSceneGQL extends Apollo.Query<GetTemplateRevisionDetailsTemplateAddSceneQuery, GetTemplateRevisionDetailsTemplateAddSceneQueryVariables> {
    override document = GetTemplateRevisionDetailsTemplateAddSceneDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }