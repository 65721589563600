import {Component, input} from "@angular/core"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"

@Component({
    selector: "cm-card-placeholder",
    templateUrl: "./card-placeholder.component.html",
    styleUrls: ["./card-placeholder.component.scss"],
    imports: [DataObjectThumbnailComponent],
})
export class CardPlaceholderComponent {
    readonly $squareFormat = input(false, {alias: "squareFormat"})
}
