import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type BatchExportMaterialCreateJobMutationVariables = Types.Exact<{
  input: Types.MutationCreateJobInput;
}>;


export type BatchExportMaterialCreateJobMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string } };

export type BatchExportMaterialCancelJobMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type BatchExportMaterialCancelJobMutation = { __typename: 'Mutation', cancelJob: { __typename: 'Job', id: string } };

export type BatchExportMaterialGetJobStateQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type BatchExportMaterialGetJobStateQuery = { __typename: 'Query', job: { __typename: 'Job', id: string, progress?: number | null, state: Types.JobState } };

export type BatchExportMaterialGetJobDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type BatchExportMaterialGetJobDetailsQuery = { __typename: 'Query', job: { __typename: 'Job', id: string, output?: any | null, state: Types.JobState } };

export const BatchExportMaterialCreateJobDocument = gql`
    mutation batchExportMaterialCreateJob($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchExportMaterialCreateJobGQL extends Apollo.Mutation<BatchExportMaterialCreateJobMutation, BatchExportMaterialCreateJobMutationVariables> {
    override document = BatchExportMaterialCreateJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchExportMaterialCancelJobDocument = gql`
    mutation batchExportMaterialCancelJob($id: ID!) {
  cancelJob(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchExportMaterialCancelJobGQL extends Apollo.Mutation<BatchExportMaterialCancelJobMutation, BatchExportMaterialCancelJobMutationVariables> {
    override document = BatchExportMaterialCancelJobDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchExportMaterialGetJobStateDocument = gql`
    query batchExportMaterialGetJobState($id: ID!) {
  job(id: $id) {
    id
    progress
    state
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchExportMaterialGetJobStateGQL extends Apollo.Query<BatchExportMaterialGetJobStateQuery, BatchExportMaterialGetJobStateQueryVariables> {
    override document = BatchExportMaterialGetJobStateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchExportMaterialGetJobDetailsDocument = gql`
    query batchExportMaterialGetJobDetails($id: ID!) {
  job(id: $id) {
    id
    output
    state
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchExportMaterialGetJobDetailsGQL extends Apollo.Query<BatchExportMaterialGetJobDetailsQuery, BatchExportMaterialGetJobDetailsQueryVariables> {
    override document = BatchExportMaterialGetJobDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }