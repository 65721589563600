import {Box2, Matrix3x2, Vector2} from "@cm/math"
import {HalPainterImageCompositor, PainterImageCompositorPaintArgs} from "@common/models/hal/hal-painter-image-compositor"
import {WebGl2Context} from "@common/models/webgl2/webgl2-context"
import {WebGl2PainterPrimitive} from "@common/models/webgl2/webgl2-painter-primitive"
import {HalPainterPrimitive} from "@common/models/hal/hal-painter-primitive"
import {HalGeometry} from "@common/models/hal/hal-geometry"
import {WebGl2Geometry} from "@common/models/webgl2/webgl2-geometry"

export class WebGl2PainterImageCompositor implements HalPainterImageCompositor {
    constructor(
        readonly context: WebGl2Context,
        compositingFunction?: string,
    ) {
        this.painterPrimitive = new WebGl2PainterPrimitive(this.context, this.getShadingFunction(compositingFunction ?? DEFAULT_COMPOSITING_FUNCTION))
        this.geometry = new WebGl2Geometry(this.context)
        this.geometry.addRect(Box2.fromPositionAndSize(Vector2.zero, Vector2.one), Box2.fromPositionAndSize(Vector2.zero, Vector2.one))
    }

    // HalEntity
    dispose(): void {
        this.painterPrimitive.dispose()
        this.geometry.dispose()
    }

    // HalPainterImageCompositor
    paint({target, parameters, sourceImages, options}: PainterImageCompositorPaintArgs) {
        const targetSize = target
        const targetRegion = options?.targetRegion ?? Box2.fromPositionAndSize(new Vector2(0, 0), new Vector2(targetSize.width, targetSize.height))
        const uvTransform = new Matrix3x2().translate(targetRegion).scale(new Vector2(targetRegion.width, targetRegion.height))
        const transform = options?.transform ? Matrix3x2.fromMatrix3x2Like(options.transform).append(uvTransform) : uvTransform
        this.painterPrimitive.paint({
            target,
            geometry: this.geometry,
            parameters,
            sourceImages,
            options: {
                ...options,
                transform,
                uvTransform,
            },
        })
    }

    private getShadingFunction(compositingFunction: string): string {
        return `
            ${compositingFunction}
            vec4 computeColor(vec2 position, vec2 uv, vec4 color) {
                return computeColor(ivec2(uv)) * color;
            }
        `
    }

    private readonly painterPrimitive: HalPainterPrimitive
    private readonly geometry: HalGeometry
}

const DEFAULT_COMPOSITING_FUNCTION = `
    vec4 computeColor(ivec2 targetPixel) {
        return texelFetch0(targetPixel);
    }
`
