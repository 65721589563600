import {Component, OnInit} from "@angular/core"

@Component({
    selector: "cm-add-menu-section",
    templateUrl: "./add-menu-section.component.html",
    styleUrls: ["./add-menu-section.component.scss"],
})
export class AddMenuSectionComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
