import {DeclareTemplateNodeTS, TemplateNodeImplementation, TemplateNodeMeta} from "#template-nodes/declare-template-node"
import {
    BooleanInfo,
    CurveInfo,
    ImageInfo,
    JSONInfo,
    MaterialInfo,
    MeshInfo,
    NumberInfo,
    ObjectInfo,
    StringInfo,
    TemplateInfo,
} from "#template-nodes/interface-descriptors"
import {
    BooleanLike,
    booleanLike,
    CurveLike,
    curveLike,
    ImageLike,
    imageLike,
    JSONLike,
    jsonLike,
    MaterialLike,
    materialLike,
    meshLike,
    MeshLike,
    NumberLike,
    numberLike,
    ObjectLike,
    objectLike,
    StringLike,
    stringLike,
    TemplateLike,
    templateLike,
} from "#template-nodes/node-types"
import {idNodeParameters, IdNodeParameters} from "#template-nodes/nodes/id-node"
import {namedNodeParameters, NamedNodeParameters} from "#template-nodes/nodes/named-node"
import {EvaluatedTemplateValueType, TemplateNode} from "#template-nodes/types"
import {skipped, visitNone} from "@cm/graph/declare-visitor-node"
import {NodeGraphClass} from "@cm/graph/node-graph"
import {registerNode} from "@cm/graph/register-node"
import {z} from "zod"

/*Export logic:
- Every export node of a template creates an output descriptor to publish which ids provide outputs.
- It stores the evaluated result in the templateOutputs of the current context as `output-${outputType}-${id}`.
- In CompileTemplate the templateOutputs is made available via output, so that it becomes available in RunTemplate as outputs outlet.
- In template instance the outputs of RunTemplate is made available to the current template via an alias to `templateOutputs-${localId}`.
- It can then be consumed with an output node, that during evaluation will reference the corresponding output coming from `templateOutputs-${localId}` using `output-${outputType}-${id}`.
- The output node will just evaluate to the value that has been stored there.*/

type ZodNode<T> = z.ZodType<T, z.ZodTypeDef, any>

const exportParameters = <T>(tValidation: ZodNode<T>) =>
    namedNodeParameters.merge(idNodeParameters).merge(
        z.object({
            node: tValidation.nullable(),
        }),
    )
type ExportParameters<T> = NamedNodeParameters &
    IdNodeParameters & {
        node: T | null
    }

const generateExport = <T>(
    tValidation: ZodNode<T>,
    implementation: TemplateNodeImplementation<ExportParameters<T>>,
    meta: TemplateNodeMeta<ExportParameters<T>>,
): NodeGraphClass<TemplateNode<ExportParameters<T>>> => {
    const {onVisited, ...rest} = implementation
    const retClass = class extends DeclareTemplateNodeTS<ExportParameters<T>>(
        {
            validation: {paramsSchema: exportParameters(tValidation)},
            onVisited: {
                onFilterActive: ({parameters}) => {
                    if (parameters.node === null) return skipped
                    return visitNone(parameters)
                },
                ...onVisited,
            },
            ...rest,
        },
        meta,
    ) {}
    return retClass
}

////////////////////////////////////////////////////////////////////

export interface ObjectExportParameters extends ExportParameters<ObjectLike> {} // workaround for recursive type

@registerNode
export class ObjectExport extends generateExport<ObjectLike>(
    objectLike,
    {
        onVisited: {
            onCompile: function (this: ObjectExport, {context, parameters}) {
                const {id, name, node} = parameters
                const {evaluator, currentTemplate} = context
                const {descriptorList, templateOutputs} = currentTemplate
                const scope = evaluator.getScope(this)

                const evalObject = evaluator.evaluateObject(scope, node)

                const outputType: EvaluatedTemplateValueType = "object"
                templateOutputs[`output-${outputType}-${id}`] = evalObject
                descriptorList.push(
                    scope.pureLambda(
                        scope.tuple(id, name, evalObject, this),
                        ([id, name, object, origin]) => new ObjectInfo({id, name, value: object, type: "output", origin}),
                        "objectInfo",
                    ),
                )

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "ObjectExport"},
) {}

export type ObjectExportFwd = TemplateNode<ObjectExportParameters>

////////////////////////////////////////////////////////////////////

export interface MeshExportParameters extends ExportParameters<MeshLike> {} // workaround for recursive type

@registerNode
export class MeshExport extends generateExport<MeshLike>(
    meshLike,
    {
        onVisited: {
            onCompile: function (this: MeshExport, {context, parameters}) {
                const {id, name, node} = parameters
                const {evaluator, currentTemplate} = context
                const {descriptorList, templateOutputs} = currentTemplate
                const scope = evaluator.getScope(this)

                const evalMesh = evaluator.evaluateMesh(scope, node)

                const outputType: EvaluatedTemplateValueType = "object"
                templateOutputs[`output-${outputType}-${id}`] = evalMesh
                descriptorList.push(
                    scope.pureLambda(
                        scope.tuple(id, name, evalMesh, this),
                        ([id, name, mesh, origin]) => new MeshInfo({id, name, value: mesh, type: "output", origin}),
                        "meshInfo",
                    ),
                )

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "MeshExport"},
) {}

export type MeshExportFwd = TemplateNode<MeshExportParameters>

////////////////////////////////////////////////////////////////////

export interface CurveExportParameters extends ExportParameters<CurveLike> {} // workaround for recursive type

@registerNode
export class CurveExport extends generateExport<CurveLike>(
    curveLike,
    {
        onVisited: {
            onCompile: function (this: CurveExport, {context, parameters}) {
                const {id, name, node} = parameters
                const {evaluator, currentTemplate} = context
                const {descriptorList, templateOutputs} = currentTemplate
                const scope = evaluator.getScope(this)

                const evalCurve = evaluator.evaluateCurve(scope, node)

                const outputType: EvaluatedTemplateValueType = "object"
                templateOutputs[`output-${outputType}-${id}`] = evalCurve
                descriptorList.push(
                    scope.pureLambda(
                        scope.tuple(id, name, evalCurve, this),
                        ([id, name, curve, origin]) => new CurveInfo({id, name, value: curve, type: "output", origin}),
                        "curveInfo",
                    ),
                )

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "CurveExport"},
) {}

export type CurveExportFwd = TemplateNode<CurveExportParameters>

////////////////////////////////////////////////////////////////////

export interface MaterialExportParameters extends ExportParameters<MaterialLike> {} // workaround for recursive type

@registerNode
export class MaterialExport extends generateExport<MaterialLike>(
    materialLike,
    {
        onVisited: {
            onCompile: function (this: MaterialExport, {context, parameters}) {
                const {id, name, node} = parameters
                const {evaluator, currentTemplate} = context
                const {descriptorList, templateOutputs} = currentTemplate
                const scope = evaluator.getScope(this)

                const evalMaterial = evaluator.evaluateMaterial(scope, node)

                const outputType: EvaluatedTemplateValueType = "material"
                templateOutputs[`output-${outputType}-${id}`] = evalMaterial
                descriptorList.push(
                    scope.pureLambda(
                        scope.tuple(id, name, evalMaterial, this),
                        ([id, name, material, origin]) => new MaterialInfo({id, name, value: material, type: "output", origin}),
                        "materialInfo",
                    ),
                )

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "MaterialExport"},
) {}

export type MaterialExportFwd = TemplateNode<MaterialExportParameters>

////////////////////////////////////////////////////////////////////

export interface TemplateExportParameters extends ExportParameters<TemplateLike> {} // workaround for recursive type

@registerNode
export class TemplateExport extends generateExport<TemplateLike>(
    templateLike,
    {
        onVisited: {
            onCompile: function (this: TemplateExport, {context, parameters}) {
                const {id, name, node} = parameters
                const {evaluator, currentTemplate} = context
                const {descriptorList, templateOutputs} = currentTemplate
                const scope = evaluator.getScope(this)

                const evalTemplate = evaluator.evaluateTemplate(scope, node)

                const outputType: EvaluatedTemplateValueType = "template"
                templateOutputs[`output-${outputType}-${id}`] = evalTemplate
                descriptorList.push(
                    scope.pureLambda(
                        scope.tuple(id, name, evalTemplate, this),
                        ([id, name, template, origin]) => new TemplateInfo({id, name, value: template, type: "output", origin}),
                        "templateInfo",
                    ),
                )

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "TemplateExport"},
) {}

export type TemplateExportFwd = TemplateNode<TemplateExportParameters>

////////////////////////////////////////////////////////////////////

export interface ImageExportParameters extends ExportParameters<ImageLike> {} // workaround for recursive type

@registerNode
export class ImageExport extends generateExport<ImageLike>(
    imageLike,
    {
        onVisited: {
            onCompile: function (this: ImageExport, {context, parameters}) {
                const {id, name, node} = parameters
                const {evaluator, currentTemplate} = context
                const {descriptorList, templateOutputs} = currentTemplate
                const scope = evaluator.getScope(this)

                const evalImage = evaluator.evaluateImage(scope, node)

                const outputType: EvaluatedTemplateValueType = "image"
                templateOutputs[`output-${outputType}-${id}`] = evalImage
                descriptorList.push(
                    scope.pureLambda(
                        scope.tuple(id, name, evalImage, this),
                        ([id, name, image, origin]) => new ImageInfo({id, name, value: image, type: "output", origin}),
                        "imageInfo",
                    ),
                )

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "ImageExport"},
) {}

export type ImageExportFwd = TemplateNode<ImageExportParameters>

////////////////////////////////////////////////////////////////////

export type StringExportParameters = ExportParameters<StringLike>

@registerNode
export class StringExport extends generateExport<StringLike>(
    stringLike,
    {
        onVisited: {
            onCompile: function (this: StringExport, {context, parameters}) {
                const {id, name, node} = parameters
                const {evaluator, currentTemplate} = context
                const {descriptorList, templateOutputs} = currentTemplate
                const scope = evaluator.getScope(this)

                const evalString = evaluator.evaluateString(scope, node)

                const outputType: EvaluatedTemplateValueType = "string"
                templateOutputs[`output-${outputType}-${id}`] = evalString
                descriptorList.push(
                    scope.pureLambda(
                        scope.tuple(id, name, evalString, this),
                        ([id, name, string, origin]) => new StringInfo({id, name, value: string, type: "output", origin}),
                        "stringInfo",
                    ),
                )

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "StringExport"},
) {}

export type StringExportFwd = TemplateNode<StringExportParameters>

////////////////////////////////////////////////////////////////////

export type NumberExportParameters = ExportParameters<NumberLike>

@registerNode
export class NumberExport extends generateExport<NumberLike>(
    numberLike,
    {
        onVisited: {
            onCompile: function (this: NumberExport, {context, parameters}) {
                const {id, name, node} = parameters
                const {evaluator, currentTemplate} = context
                const {descriptorList, templateOutputs} = currentTemplate
                const scope = evaluator.getScope(this)

                const evalNumber = evaluator.evaluateNumber(scope, node)

                const outputType: EvaluatedTemplateValueType = "number"
                templateOutputs[`output-${outputType}-${id}`] = evalNumber
                descriptorList.push(
                    scope.pureLambda(
                        scope.tuple(id, name, evalNumber, this),
                        ([id, name, number, origin]) => new NumberInfo({id, name, value: number, type: "output", origin}),
                        "numberInfo",
                    ),
                )

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "NumberExport"},
) {}

export type NumberExportFwd = TemplateNode<NumberExportParameters>

////////////////////////////////////////////////////////////////////

export type BooleanExportParameters = ExportParameters<BooleanLike>

@registerNode
export class BooleanExport extends generateExport<BooleanLike>(
    booleanLike,
    {
        onVisited: {
            onCompile: function (this: BooleanExport, {context, parameters}) {
                const {id, name, node} = parameters
                const {evaluator, currentTemplate} = context
                const {descriptorList, templateOutputs} = currentTemplate
                const scope = evaluator.getScope(this)

                const evalBoolean = evaluator.evaluateBoolean(scope, node)

                const outputType: EvaluatedTemplateValueType = "boolean"
                templateOutputs[`output-${outputType}-${id}`] = evalBoolean
                descriptorList.push(
                    scope.pureLambda(
                        scope.tuple(id, name, evalBoolean, this),
                        ([id, name, boolean, origin]) => new BooleanInfo({id, name, value: boolean, type: "output", origin}),
                        "booleanInfo",
                    ),
                )

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "BooleanExport"},
) {}

export type BooleanExportFwd = TemplateNode<BooleanExportParameters>

////////////////////////////////////////////////////////////////////

export type JSONExportParameters = ExportParameters<JSONLike>

@registerNode
export class JSONExport extends generateExport<JSONLike>(
    jsonLike,
    {
        onVisited: {
            onCompile: function (this: JSONExport, {context, parameters}) {
                const {id, name, node} = parameters
                const {evaluator, currentTemplate} = context
                const {descriptorList, templateOutputs} = currentTemplate
                const scope = evaluator.getScope(this)

                const evalJSON = evaluator.evaluateJSON(scope, node)

                const outputType: EvaluatedTemplateValueType = "json"
                templateOutputs[`output-${outputType}-${id}`] = evalJSON
                descriptorList.push(
                    scope.pureLambda(
                        scope.tuple(id, name, evalJSON, this),
                        ([id, name, json, origin]) => new JSONInfo({id, name, value: json, type: "output", origin}),
                        "jsonInfo",
                    ),
                )

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "JSONExport"},
) {}

export type JSONExportFwd = TemplateNode<JSONExportParameters>
