import {Component, computed, inject, model, OnInit, signal, input} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatDialogModule} from "@angular/material/dialog"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatIconModule} from "@angular/material/icon"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {IsDefined} from "@cm/utils/filter"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {
    AddMaterialDialogMaterialRangeTagsGQL,
    AddMaterialDialogTagFragment,
} from "@platform/components/materials/add-material-dialog/add-material-dialog.generated"
import {CreateMaterialData} from "@platform/components/materials/materials-grid/materials-grid.component"

type AddMaterialData = Partial<CreateMaterialData>

@Component({
    selector: "cm-add-material-dialog",
    imports: [FormsModule, MatIconModule, MatDialogModule, MatFormFieldModule, MatSelectModule, MatInputModule, OrganizationSelectComponent, MatButtonModule],
    templateUrl: "./add-material-dialog.component.html",
    styleUrl: "./add-material-dialog.component.scss",
})
export class AddMaterialDialogComponent implements OnInit {
    readonly $addMaterial = input.required<
        | ((data: AddMaterialData) => Promise<
              | {
                    id: string
                    legacyId: number
                }
              | undefined
          >)
        | undefined
    >({alias: "addMaterial"})
    readonly $isBatchMode = input(false, {alias: "isBatchMode"})

    readonly organizations = inject(OrganizationsService)
    readonly materialRangeTags = inject(AddMaterialDialogMaterialRangeTagsGQL)

    readonly $updateSelectedOrganization = signal<{id: string} | undefined | null>(null)
    readonly $selectedOrganization = computed(() => {
        const updateSelectedOrganization = this.$updateSelectedOrganization()
        if (updateSelectedOrganization) return updateSelectedOrganization
        return this.organizations.$current()
    })
    readonly $materialRangesForSelectedOrganization = computed(() => {
        return this.$materialRangeTags().filter((range) => range.organization?.id === this.$selectedOrganization()?.id)
    })
    readonly $materialRangeTags = signal<AddMaterialDialogTagFragment[]>([])

    readonly $name = model<string>("", {alias: "name"})

    readonly $data = computed<AddMaterialData>(() => {
        return {
            name: this.$name(),
            materialRangeTagId: undefined,
            organizationId: this.$selectedOrganization()?.id,
        }
    })

    ngOnInit() {
        fetchThrowingErrors(this.materialRangeTags)({}).then(({tags}) => {
            this.$materialRangeTags.set(tags.filter(IsDefined))
        })
    }

    canConfirmCreate = () => (!!this.$data().name || this.$isBatchMode()) && !!this.$data().organizationId

    updateOrganization = async (data: {organizationId: string}) => {
        this.$updateSelectedOrganization.set({id: data.organizationId})
    }

    confirmCreate = () => {
        const addMaterial = this.$addMaterial()
        if (addMaterial === undefined) throw new Error("Missing addMaterial function in add-material-button component")
        return addMaterial(this.$data())
    }
}
