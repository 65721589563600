import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type FeedbackCanvasItemFragment = { __typename: 'PictureRevision', id: string, legacyId: number, number: number, picture: { __typename: 'Picture', id: string }, pictureData: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }>, drawingData: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, bucketName: string, downloadUrl: string, height?: number | null, objectName: string, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }> };

export type FeedbackCanvasDataObjectFragment = { __typename: 'DataObject', id: string, bucketName: string, downloadUrl: string, height?: number | null, objectName: string, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null };

export type FeedbackCanvasItemUploadLinkQueryVariables = Types.Exact<{
  dataObjectId: Types.Scalars['ID']['input'];
}>;


export type FeedbackCanvasItemUploadLinkQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', signedUploadUrl: string } };

export type FeedbackCanvasItemQueryVariables = Types.Exact<{
  pictureRevisionId: Types.Scalars['ID']['input'];
}>;


export type FeedbackCanvasItemQuery = { __typename: 'Query', item: { __typename: 'PictureRevision', id: string, legacyId: number, number: number, picture: { __typename: 'Picture', id: string }, pictureData: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string } }>, drawingData: Array<{ __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, bucketName: string, downloadUrl: string, height?: number | null, objectName: string, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } }> } };

export type FeedbackCanvasDeleteDataObjectAssignmentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type FeedbackCanvasDeleteDataObjectAssignmentMutation = { __typename: 'Mutation', deleteDataObjectAssignment?: any | null };

export type FeedbackCanvasUpdateDrawingDataMutationVariables = Types.Exact<{
  input: Types.MutationUpdateDataObjectInput;
}>;


export type FeedbackCanvasUpdateDrawingDataMutation = { __typename: 'Mutation', updateDataObject: { __typename: 'DataObject', id: string, bucketName: string, downloadUrl: string, height?: number | null, objectName: string, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } };

export type FeedbackCanvasCreateDataObjectAssignmentMutationVariables = Types.Exact<{
  input: Types.MutationCreateDataObjectAssignmentInput;
}>;


export type FeedbackCanvasCreateDataObjectAssignmentMutation = { __typename: 'Mutation', createDataObjectAssignment: { __typename: 'DataObjectAssignment', id: string, dataObject: { __typename: 'DataObject', id: string, bucketName: string, downloadUrl: string, height?: number | null, objectName: string, width?: number | null, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } } };

export type FeedbackCanvasTaskPinFragment = { __typename: 'TaskPin', id: string, x: number, y: number };

export type FeedbackCanvasTaskFragment = { __typename: 'Task', id: string, number: number, state: Types.TaskState, pins: Array<{ __typename: 'TaskPin', id: string, x: number, y: number }> };

export type FeedbackCanvasTaskQueryVariables = Types.Exact<{
  taskId: Types.Scalars['ID']['input'];
}>;


export type FeedbackCanvasTaskQuery = { __typename: 'Query', task: { __typename: 'Task', id: string, number: number, state: Types.TaskState, pins: Array<{ __typename: 'TaskPin', id: string, x: number, y: number }> } };

export type FeedbackCanvasCreateTaskPinMutationVariables = Types.Exact<{
  input: Types.MutationCreateTaskPinInput;
}>;


export type FeedbackCanvasCreateTaskPinMutation = { __typename: 'Mutation', createTaskPin: { __typename: 'TaskPin', id: string, x: number, y: number } };

export type FeedbackCanvasUpdateTaskPinPositionMutationVariables = Types.Exact<{
  input: Types.MutationUpdateTaskPinInput;
}>;


export type FeedbackCanvasUpdateTaskPinPositionMutation = { __typename: 'Mutation', updateTaskPin: { __typename: 'TaskPin', id: string, x: number, y: number } };

export const FeedbackCanvasDataObjectFragmentDoc = gql`
    fragment FeedbackCanvasDataObject on DataObject {
  id
  bucketName
  downloadUrl
  height
  thumbnail {
    downloadUrl
  }
  objectName
  width
}
    `;
export const FeedbackCanvasItemFragmentDoc = gql`
    fragment FeedbackCanvasItem on PictureRevision {
  id
  legacyId
  __typename
  number
  picture {
    id
  }
  pictureData: dataObjectAssignments(filter: {assignmentType: [PictureData]}) {
    id
    dataObject {
      id
    }
  }
  drawingData: dataObjectAssignments(filter: {assignmentType: [DrawingData]}) {
    id
    dataObject {
      ...FeedbackCanvasDataObject
    }
  }
}
    `;
export const FeedbackCanvasTaskPinFragmentDoc = gql`
    fragment FeedbackCanvasTaskPin on TaskPin {
  id
  x
  y
}
    `;
export const FeedbackCanvasTaskFragmentDoc = gql`
    fragment FeedbackCanvasTask on Task {
  id
  __typename
  number
  pins {
    ...FeedbackCanvasTaskPin
  }
  state
}
    `;
export const FeedbackCanvasItemUploadLinkDocument = gql`
    query feedbackCanvasItemUploadLink($dataObjectId: ID!) {
  dataObject(id: $dataObjectId) {
    signedUploadUrl(allowCache: false)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FeedbackCanvasItemUploadLinkGQL extends Apollo.Query<FeedbackCanvasItemUploadLinkQuery, FeedbackCanvasItemUploadLinkQueryVariables> {
    override document = FeedbackCanvasItemUploadLinkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedbackCanvasItemDocument = gql`
    query feedbackCanvasItem($pictureRevisionId: ID!) {
  item: pictureRevision(id: $pictureRevisionId) {
    ...FeedbackCanvasItem
  }
}
    ${FeedbackCanvasItemFragmentDoc}
${FeedbackCanvasDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FeedbackCanvasItemGQL extends Apollo.Query<FeedbackCanvasItemQuery, FeedbackCanvasItemQueryVariables> {
    override document = FeedbackCanvasItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedbackCanvasDeleteDataObjectAssignmentDocument = gql`
    mutation feedbackCanvasDeleteDataObjectAssignment($id: ID!) {
  deleteDataObjectAssignment(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FeedbackCanvasDeleteDataObjectAssignmentGQL extends Apollo.Mutation<FeedbackCanvasDeleteDataObjectAssignmentMutation, FeedbackCanvasDeleteDataObjectAssignmentMutationVariables> {
    override document = FeedbackCanvasDeleteDataObjectAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedbackCanvasUpdateDrawingDataDocument = gql`
    mutation feedbackCanvasUpdateDrawingData($input: MutationUpdateDataObjectInput!) {
  updateDataObject(input: $input) {
    ...FeedbackCanvasDataObject
  }
}
    ${FeedbackCanvasDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FeedbackCanvasUpdateDrawingDataGQL extends Apollo.Mutation<FeedbackCanvasUpdateDrawingDataMutation, FeedbackCanvasUpdateDrawingDataMutationVariables> {
    override document = FeedbackCanvasUpdateDrawingDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedbackCanvasCreateDataObjectAssignmentDocument = gql`
    mutation feedbackCanvasCreateDataObjectAssignment($input: MutationCreateDataObjectAssignmentInput!) {
  createDataObjectAssignment(input: $input) {
    id
    dataObject {
      ...FeedbackCanvasDataObject
    }
  }
}
    ${FeedbackCanvasDataObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FeedbackCanvasCreateDataObjectAssignmentGQL extends Apollo.Mutation<FeedbackCanvasCreateDataObjectAssignmentMutation, FeedbackCanvasCreateDataObjectAssignmentMutationVariables> {
    override document = FeedbackCanvasCreateDataObjectAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedbackCanvasTaskDocument = gql`
    query feedbackCanvasTask($taskId: ID!) {
  task(id: $taskId) {
    ...FeedbackCanvasTask
  }
}
    ${FeedbackCanvasTaskFragmentDoc}
${FeedbackCanvasTaskPinFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FeedbackCanvasTaskGQL extends Apollo.Query<FeedbackCanvasTaskQuery, FeedbackCanvasTaskQueryVariables> {
    override document = FeedbackCanvasTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedbackCanvasCreateTaskPinDocument = gql`
    mutation feedbackCanvasCreateTaskPin($input: MutationCreateTaskPinInput!) {
  createTaskPin(input: $input) {
    ...FeedbackCanvasTaskPin
  }
}
    ${FeedbackCanvasTaskPinFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FeedbackCanvasCreateTaskPinGQL extends Apollo.Mutation<FeedbackCanvasCreateTaskPinMutation, FeedbackCanvasCreateTaskPinMutationVariables> {
    override document = FeedbackCanvasCreateTaskPinDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeedbackCanvasUpdateTaskPinPositionDocument = gql`
    mutation feedbackCanvasUpdateTaskPinPosition($input: MutationUpdateTaskPinInput!) {
  updateTaskPin(input: $input) {
    ...FeedbackCanvasTaskPin
  }
}
    ${FeedbackCanvasTaskPinFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FeedbackCanvasUpdateTaskPinPositionGQL extends Apollo.Mutation<FeedbackCanvasUpdateTaskPinPositionMutation, FeedbackCanvasUpdateTaskPinPositionMutationVariables> {
    override document = FeedbackCanvasUpdateTaskPinPositionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }