import {Component, computed, inject, output, signal} from "@angular/core"
import {cardAnimations} from "@app/common/components/cards/animations"
import {BaseSelectComponent} from "@app/platform/components/base/base-select/base-select.component"
import {getNodeIconClass} from "@app/template-editor/helpers/template-icons"
import {Matrix4} from "@cm/math"
import {AreaLight, Camera, defaultsForToneMapping, HDRILight, LightPortal, Node, PostProcessRender, Render, SceneProperties} from "@cm/template-nodes"
import {CardErrorComponent} from "@common/components/cards/card-error/card-error.component"
import {CardPlaceholderComponent} from "@common/components/cards/card-placeholder/card-placeholder.component"
import {OwnOrganizationsFilterComponent} from "@common/components/filters/own-organizations-filter/own-organizations-filter.component"
import {SearchComponent} from "@common/components/inputs/search/search.component"
import {InfiniteListComponent} from "@common/components/lists/infinite-list/infinite-list.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {HdriFilterInput} from "@generated"
import {SelectHdriItemFragment, SelectHdriItemsGQL} from "@platform/components/hdris/select-hdri/select-hdri.generated"
import {TemplateAddCardComponent} from "../template-add-card/template-add-card.component"

type SceneItem = {
    name: string
    node: Node
    iconClass: string
}

@Component({
    selector: "cm-template-add-scene-item",
    templateUrl: "./template-add-scene-item.component.html",
    styleUrl: "./template-add-scene-item.component.scss",
    animations: [...cardAnimations],
    imports: [SearchComponent, InfiniteListComponent, TemplateAddCardComponent, CardPlaceholderComponent, CardErrorComponent, OwnOrganizationsFilterComponent],
})
export class TemplateAddSceneItemComponent extends BaseSelectComponent<SelectHdriItemFragment, HdriFilterInput> {
    readonly selectHdriItemsGql = inject(SelectHdriItemsGQL)
    private rawData = this.implementDataLoader(fetchThrowingErrors(this.selectHdriItemsGql))
    readonly $data = computed(() => this.rawData().items.map((item) => ({...item, node: item.data ? this.getHdriLight(item.data) : undefined})))
    readonly onItemClicked = output()

    readonly $currentSection = signal<"camera" | "light" | "hdri">("camera")

    readonly $cameraSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Camera",
                node: new Camera({
                    name: "Camera",
                    target: [0, 0, 0],
                    targeted: true,
                    lockedTransform: Matrix4.translation(0, 0, 100).toArray(),
                    visible: true,
                    resolutionX: 1920,
                    resolutionY: 1080,
                    filmGauge: 36,
                    fStop: 128,
                    focalLength: 50,
                    focalDistance: 100,
                    autoFocus: true,
                    ev: 0.0,
                    shiftX: 0,
                    shiftY: 0,
                    automaticVerticalTilt: false,
                    enablePanning: true,
                    screenSpacePanning: true,
                    toneMapping: defaultsForToneMapping("pbr-neutral"),
                }),
                iconClass: getNodeIconClass(Camera.getNodeClass()),
            },
            {
                name: "Render",
                node: new Render({
                    width: 1920,
                    height: 1080,
                    samples: 100,
                    gpu: true,
                    cloud: true,
                }),
                iconClass: getNodeIconClass(Render.getNodeClass()),
            },
            {
                name: "Post Process Render",
                node: new PostProcessRender({
                    mode: "whiteBackground",
                    render: undefined,
                    ev: 0,
                    whiteBalance: 0,
                    transparent: false,
                    composite: false,
                    backgroundColor: [1, 1, 1],
                    processShadows: false,
                    shadowInner: 0,
                    shadowOuter: 0,
                    shadowFalloff: 1,
                    autoCrop: false,
                    autoCropMargin: 50,
                    toneMapping: defaultsForToneMapping("pbr-neutral"),
                }),
                iconClass: getNodeIconClass(PostProcessRender.getNodeClass()),
            },
            {
                name: "Scene Properties",
                node: new SceneProperties({
                    maxSubdivisionLevel: 2,
                    backgroundColor: [1, 1, 1],
                    uiColor: [0, 0, 0],
                    uiStyle: "default",
                    iconSize: 24,
                    enableAr: false,
                    enableSalesEnquiry: false,
                    textureResolution: "2000px",
                    textureFiltering: false,
                    enableRealtimeShadows: true,
                    enableRealtimeLights: true,
                    enableRealtimeMaterials: true,
                    enableOnboardingHint: false,
                    enableGltfDownload: false,
                    enableStlDownload: false,
                    enablePdfGeneration: false,
                    enableSnapshot: true,
                    enableFullscreen: true,
                    environmentMapMode: "full",
                    showAnnotations: true,
                    enableDimensionGuides: false,
                    enableAdaptiveSubdivision: false,
                    verticalArPlacement: false,
                }),
                iconClass: getNodeIconClass(SceneProperties.getNodeClass()),
            },
        ]
    })

    readonly $lightSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Area Light",
                node: new AreaLight({
                    name: "Area Light",
                    width: 100,
                    height: 100,
                    lockedTransform: Matrix4.identity().toArray(),
                    visible: true,
                    target: [0, 0, -100],
                    targeted: true,
                    intensity: 1,
                    color: [1, 1, 1],
                    on: true,
                    directionality: 0,
                    visibleDirectly: true,
                    visibleInReflections: true,
                    visibleInRefractions: true,
                    transparent: false,
                    lightType: "Corona",
                }),
                iconClass: getNodeIconClass(AreaLight.getNodeClass()),
            },
            {
                name: "Light Portal",
                node: new LightPortal({
                    name: "Light Portal",
                    width: 100,
                    height: 100,
                    lockedTransform: Matrix4.identity().toArray(),
                    visible: true,
                }),
                iconClass: getNodeIconClass(LightPortal.getNodeClass()),
            },
        ]
    })

    readonly $sceneItems = computed<SceneItem[] | undefined>(() => {
        const currentSection = this.$currentSection()
        if (currentSection === "camera") return this.$cameraSceneItems()
        else if (currentSection === "light") return this.$lightSceneItems()
        else return undefined
    })

    private getHdriLight(data: SelectHdriItemFragment) {
        const {name, legacyId} = data
        return new HDRILight({
            name: name ?? "HDRI Light",
            hdriId: legacyId,
            intensity: 1,
            rotation: [0, 0, 0],
            mirror: false,
        })
    }
}
