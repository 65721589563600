<cm-hint>
    <ng-container cm-text>
        @if (isApple) {
            Hold <b>CMD</b> and click to pick a cloning source.
        } @else {
            Hold <b>ALT</b> and click to pick a cloning source.
        }
    </ng-container>
</cm-hint>
<cm-button class="cm-undo" [class.cm-disabled]="!canUndo || $disabled()" [class.cm-active]="canUndo" (click)="undoClicked()" [disabled]="$disabled()">
    <ng-container cm-left-icon><i class="far fa-undo"></i></ng-container>
    <ng-container cm-text>Undo last stroke</ng-container>
</cm-button>
<cm-inspector-section>
    <ng-container cm-title> Visibility</ng-container>
    <ng-container cm-content>
        <cm-toggle [(toggle)]="showMask" [disabled]="$disabled()">
            <ng-container cm-text><span [class.cm-disabled]="$disabled()">Show mask</span></ng-container>
        </cm-toggle>
    </ng-container>
</cm-inspector-section>
<cm-inspector-section>
    <ng-container cm-title> Brush settings</ng-container>
    <ng-container cm-content>
        <cm-brush-settings-panel
            [allowBrushModeSelection]="false"
            [brushSettings]="$operator().brushSettings"
            [disabled]="$disabled()"
        ></cm-brush-settings-panel>
    </ng-container>
</cm-inspector-section>
