import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {SpatialMappingItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/spatial-mapping-item"
import {auditTime, merge, Subject, takeUntil} from "rxjs"
import {BoundaryDirection, BoundaryItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/boundary-item"
import {CurveVizItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/basic/curve-viz-item"
import {Vector2} from "@cm/math"

export class GridHelperLinesItem extends CanvasBaseToolboxItemBase<SpatialMappingItem> {
    constructor(readonly spatialMapping: SpatialMappingItem) {
        super(spatialMapping)

        merge(spatialMapping.mappingChanged, spatialMapping.viewModeChanged)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.onDisplayedMappingChanged())

        this._synchronizeDisplay.pipe(auditTime(0), takeUntil(this.unsubscribe)).subscribe(() => this.updateDisplay())
        this._synchronizeDisplay.next()

        this.sendToBack()
    }

    private onDisplayedMappingChanged() {
        this._synchronizeDisplay.next()
    }

    private updateDisplay() {
        this.createDisplayCurveForBoundary(this.spatialMapping.boundaryH, this.spatialMapping.boundaryV, this._curveVizItemsH)
        this.createDisplayCurveForBoundary(this.spatialMapping.boundaryV, this.spatialMapping.boundaryH, this._curveVizItemsV)
    }

    private createDisplayCurveForBoundary(boundary: BoundaryItem, otherBoundary: BoundaryItem, curveVizItems: CurveVizItem[]) {
        const curveControlPointsMin = boundary.curveMin.curveControlPoints
        const otherTValues =
            otherBoundary.curveMin.tValues.length > otherBoundary.curveMax.tValues.length ? otherBoundary.curveMin.tValues : otherBoundary.curveMax.tValues // use the more fine-grained t-values
        // exclude first and last control point as we only want to show the interior grid, not the boundary
        const userControlPointsExceptFirstAndLast = curveControlPointsMin.filter(
            (curveControlPoint, index) => index !== 0 && index !== curveControlPointsMin.length - 1 && curveControlPoint.controlPoint.type === "user",
        )
        userControlPointsExceptFirstAndLast.forEach((controlPoint, index) => {
            const t = controlPoint.t
            const curvePoints = otherTValues.map((otherT) => {
                const uv = boundary.direction === BoundaryDirection.Horizontal ? new Vector2(t, otherT) : new Vector2(otherT, t)
                return this.spatialMapping.mapUVToScreenSpace(uv)
            })
            let curveViz = index < curveVizItems.length ? curveVizItems[index] : null
            if (!curveViz) {
                curveViz = new CurveVizItem(this, 1, false)
                curveViz.showLabel = false
                curveVizItems.push(curveViz)
            }
            curveViz.setCurvePoints(curvePoints, otherTValues)
        })
        // remove excess curve viz items
        for (let i = userControlPointsExceptFirstAndLast.length; i < curveVizItems.length; i++) {
            curveVizItems[i].remove()
        }
        curveVizItems.length = userControlPointsExceptFirstAndLast.length
    }

    private _synchronizeDisplay = new Subject<void>()
    private _curveVizItemsH: CurveVizItem[] = []
    private _curveVizItemsV: CurveVizItem[] = []
}
