import {inject, Injectable} from "@angular/core"
import {MatSnackBar} from "@angular/material/snack-bar"
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router"
import {MutationLoginInput} from "@generated"
import {AuthService} from "@common/services/auth/auth.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {catchError, firstValueFrom, from, map, of, tap} from "rxjs"

@Injectable({providedIn: "root"})
export class AuthGuard {
    readonly notifications = inject(NotificationsService)

    constructor(
        private auth: AuthService,
        private router: Router,
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = await this.auth.user
        if (user) {
            return true
        }

        // not logged in so redirect to login page with the return url and return false
        console.error("Auth guard redirecting to login")
        return this.router.createUrlTree(["/login"], {queryParams: {returnUrl: state.url}})
    }
}

@Injectable({providedIn: "root"})
export class BackendAuthGuard {
    constructor(
        private auth: AuthService,
        private router: Router,
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const credentials: MutationLoginInput = {
            email: route.queryParamMap.get("email") ?? "",
            password: route.queryParamMap.get("password") ?? "",
        }
        const user = await this.auth.user
        if (user) {
            return true
        }

        if (credentials.email != null && credentials.password != null) {
            return from(this.auth.logIn(credentials)).pipe(
                map(() => true),
                tap(() => {
                    console.info("Login with credentials succeeded")
                }),
                catchError(() => {
                    console.error("Invalid credentials.")
                    return of(this.router.createUrlTree(["/login"], {queryParams: {returnUrl: state.url}}))
                }),
            )
        }

        console.error("No credentials provided to log in.")
        return this.router.createUrlTree(["/login"], {queryParams: {returnUrl: state.url}})
    }
}

@Injectable({providedIn: "root"})
export class StaffGuard {
    readonly permission = inject(PermissionsService)
    $can = this.permission.$to

    constructor(
        private router: Router,
        private snackBar: MatSnackBar,
    ) {}

    async canActivate() {
        if (!this.$can().read.page("staffAreas")) {
            this.snackBar.open("Permission denied.", "", {duration: 3000})
            return this.router.parseUrl("/login")
        }
        return true
    }
}

@Injectable({providedIn: "root"})
export class PicturesAuthGuard {
    readonly permission = inject(PermissionsService)
    readonly organizations = inject(OrganizationsService)
    $can = this.permission.$to

    constructor(
        private auth: AuthService,
        private router: Router,
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = await this.auth.user
        if (user) {
            if (this.$can().read.organization()) {
                return true
            }
            if (!Object.keys(route.queryParams).length) {
                await firstValueFrom(this.organizations.initialLoadCompleted$)
                const organizationId = this.organizations.$current()?.id ?? user.memberships?.[0]?.organization?.id ?? null
                return this.router.createUrlTree(["pictures"], {queryParams: {tab: "projects", organizationId}})
            }
            return true
        }

        console.error("Pictures auth guard redirecting to login", user, route.queryParams)
        return this.router.createUrlTree(["/login"], {queryParams: {returnUrl: state.url}})
    }
}
