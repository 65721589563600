import {Component, HostListener, inject, Input, input, output, viewChild} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {MatTooltip} from "@angular/material/tooltip"
import {ActionBarComponent} from "@app/common/components/menu/actions/action-bar/action-bar.component"
import {ActionItemComponent} from "@app/common/components/menu/actions/action-item/action-item.component"
import {ActionPanelRowComponent} from "@app/common/components/menu/actions/action-panel-row/action-panel-row.component"
import {ActionPanelComponent} from "@app/common/components/menu/actions/action-panel/action-panel.component"
import {ScanningActionBarTagFragment} from "@app/platform/components/scanning/scanning-action-bar/scanning-action-bar.generated"
import {ScanRegion} from "@app/platform/models/scanning"
import {ResolutionOption, ScanParameters, TablePosition} from "@app/platform/models/scanning/types"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {ThumbnailLayout} from "@common/models/enums/thumbnail-layout"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {Enums} from "@enums"
import {Labels} from "@labels"
import {MaterialWithRangeTagsGQL} from "@platform/components/scanning/scanning-action-bar/scanning-action-bar.generated"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"

@Component({
    selector: "cm-scanning-action-bar",
    templateUrl: "./scanning-action-bar.component.html",
    styleUrls: ["./scanning-action-bar.component.scss"],
    imports: [
        MatInputModule,
        MatSelectModule,
        FormsModule,
        ActionPanelRowComponent,
        ActionPanelComponent,
        ActionBarComponent,
        ActionItemComponent,
        StateLabelComponent,
        DataObjectThumbnailComponent,
        MatTooltip,
    ],
})
export class ScanningActionBarComponent {
    readonly $scanSettingsActionItem = viewChild.required<ActionItemComponent>("scanSettingsActionItem")
    readonly $debugActionItem = viewChild.required<ActionItemComponent>("debugActionItem")

    readonly $scanParams = input.required<ScanParameters>({alias: "scanParams"})
    readonly scanParamsChange = output<ScanParameters>()

    private _scanRegion?: ScanRegion
    @Input() set scanRegion(value: ScanRegion | undefined) {
        this._scanRegion = value
        this.updateMaterialRangeTag()
    }

    get scanRegion() {
        return this._scanRegion
    }

    readonly $notReady = input.required<boolean>({alias: "notReady"})
    readonly $taskInProgress = input<boolean>(undefined, {alias: "taskInProgress"})
    readonly $canStartScan = input<boolean>(undefined, {alias: "canStartScan"})
    readonly $resolutionOptions = input<ResolutionOption[]>(undefined, {alias: "resolutionOptions"})
    readonly $hasTransparencySupport = input<boolean>(undefined, {alias: "hasTransparencySupport"})

    readonly materialSelect = output<void>()
    readonly overviewScanStart = output<void>()
    readonly scanStart = output<void>()
    readonly cancel = output<void>()
    readonly tableCalibrationSkip = output<void>()
    readonly tableCalibrationStart = output<void>()
    readonly backlightCalibrationStart = output<void>()
    readonly tableMove = output<TablePosition>()
    readonly testImageCapture = output<void>()
    readonly logsClick = output<void>()

    @HostListener("window:keydown.control.shift.d", ["$event"])
    toggleDebugMenu(event: KeyboardEvent) {
        event.preventDefault()
        if (!this.$can().update.menu("debug")) {
            return
        }
        this.showDebugMenu = !this.showDebugMenu
        if (!this.showDebugMenu) {
            this.$debugActionItem().$panel()?.hide()
        }
    }

    readonly permission = inject(PermissionsService)
    $can = this.permission.$to

    rangeTag: ScanningActionBarTagFragment | null = null
    showDebugMenu = false

    readonly materialWithRangeTagsGql = inject(MaterialWithRangeTagsGQL)

    activateScanRegionSettingsPanel(): void {
        this.$scanSettingsActionItem().active = true
    }

    async updateMaterialRangeTag(): Promise<void> {
        if (!this.scanRegion?.material) return

        const {
            material: {tagAssignments: rangeTagAssignments},
        } = await fetchThrowingErrors(this.materialWithRangeTagsGql)({id: this.scanRegion.material.id})

        if (rangeTagAssignments.length > 0) {
            this.rangeTag = rangeTagAssignments[0].tag
        } else {
            this.rangeTag = null
        }
    }

    updateParams() {
        this.scanParamsChange.emit(this.$scanParams())
    }

    protected readonly Enums = Enums
    protected readonly Labels = Labels
    protected readonly ThumbnailLayout = ThumbnailLayout
}
