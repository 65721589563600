import {Component, OnInit, computed} from "@angular/core"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {ValueSlotComponent} from "../../value-slot/value-slot.component"
import {ButtonComponent} from "../../../../common/components/buttons/button/button.component"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MeshCurve} from "@cm/template-nodes/nodes/mesh-curve"
import {SceneNodes} from "@cm/template-nodes/interfaces/scene-object"
import {getControlPointPartNumber, isControlPointPart} from "@app/template-editor/services/scene-manager.service"
import {Vector3} from "@cm/math"
import {z} from "zod"

@Component({
    selector: "cm-mesh-curve-inspector",
    templateUrl: "./mesh-curve-inspector.component.html",
    styleUrl: "./mesh-curve-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent, ButtonComponent],
})
export class MeshCurveInspectorComponent extends BaseInspectorComponent<MeshCurve> implements OnInit {
    booleanSchema = z.boolean()
    readonly $mesh = computed(() => this.$parameters().mesh)
    readonly $controlPoints = computed(() => this.$parameters().controlPoints.map((controlPoint, index) => index))
    readonly $selectedControlPoints = computed(() => {
        const filteredNodeParts = this.sceneManagerService
            .$selectedNodeParts()

            .filter((nodePart): nodePart is {templateNode: MeshCurve; part: `controlPoint${number}`} => {
                const {templateNode, part} = nodePart
                if (templateNode !== this.$node() || !isControlPointPart(part)) return false
                const index = getControlPointPartNumber(part)
                return this.$parameters().controlPoints[index] !== undefined
            })

        return new Set(filteredNodeParts.map(({part}) => getControlPointPartNumber(part)).sort())
    })

    override ngOnInit() {
        super.ngOnInit()

        this.sceneManagerService.templateTreeChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((differences) => {
            const node = this.$node()

            const changes = differences.modifiedNodes.get(node)
            if (changes) {
                const selectedNodePartsToRemove = this.sceneManagerService.$selectedNodeParts().filter(({templateNode, part}) => {
                    if (templateNode !== node || !isControlPointPart(part)) return false

                    const index = getControlPointPartNumber(part)
                    const change = changes.find((change) => change.path === `controlPoints.${index}`)
                    if (!change) return false

                    return change.newValue === undefined
                })

                if (selectedNodePartsToRemove.length > 0) {
                    for (const selectedNodePart of selectedNodePartsToRemove) this.sceneManagerService.removeNodeFromSelection(selectedNodePart)

                    if (!this.sceneManagerService.$selectedNodeParts().some(({templateNode}) => templateNode === node))
                        this.sceneManagerService.addNodeToSelection({templateNode: node, part: "root"})
                }
            }
        })
    }

    onMouseEnter(index: number) {
        const node = this.$node()
        this.sceneManagerService.hoverNode({templateNode: node, part: `controlPoint${index}`})
    }

    onMouseLeave() {
        this.sceneManagerService.hoverNode(undefined)
    }

    deletePoints() {
        const selectedControlPoints = this.$selectedControlPoints()
        if (selectedControlPoints.size === 0) return

        const node = this.$node()

        this.sceneManagerService.modifyTemplateGraph(() => {
            this.$node().updateParameters({controlPoints: [...node.parameters.controlPoints].filter((_, index) => !selectedControlPoints.has(index))})
        })
    }

    addPoint() {
        const node = this.$node()

        const transform = this.sceneManagerService.getTransformAccessor(node)?.getTransform()

        if (!transform) return

        const sceneNodeParts = this.sceneManagerService.getSceneNodeParts({templateNode: node, part: "root"})
        if (sceneNodeParts.length !== 1) return
        const meshCurveControl = sceneNodeParts[0].sceneNode
        if (!SceneNodes.MeshCurveControl.is(meshCurveControl)) return

        this.sceneManagerService
            .watchForClickedSceneNodePart()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((sceneNodePartClickEvent) => {
                const meshIntersection = sceneNodePartClickEvent.target.find(
                    ({sceneNodePart}) => sceneNodePart.sceneNode.topLevelObjectId === meshCurveControl.mesh.topLevelObjectId,
                )
                if (!meshIntersection) return

                const {intersection} = meshIntersection
                if (!intersection) return

                const {point, normal} = intersection
                if (!normal) return

                const inverseTransform = transform.inverse()
                const surfacePoint = Vector3.fromArray(inverseTransform.multiplyVectorXYZW(point.x, point.y, point.z, 1.0))
                const surfaceNormal = new Vector3(normal.x, normal.y, normal.z).normalized()

                const curveIntersection = sceneNodePartClickEvent.target.find(({sceneNodePart}) => sceneNodePart.sceneNode === meshCurveControl)

                const insertionPosition = (() => {
                    if (curveIntersection) {
                        if (!meshCurveControl.curvePoints) return undefined
                        const {points, segments} = meshCurveControl.curvePoints

                        const pointsArray: Vector3[] = []
                        for (let i = 0; i < points.length; i += 3) pointsArray.push(new Vector3(points[i], points[i + 1], points[i + 2]))

                        const minDistanceIndex = pointsArray.reduce((minIndex, currentPoint, index) => {
                            const distance = surfacePoint.distance(currentPoint)
                            return distance < surfacePoint.distance(pointsArray[minIndex]) ? index : minIndex
                        }, 0)

                        return Math.floor(segments[minDistanceIndex]) + 1
                    } else return undefined
                })()

                const controlPoints = [...node.parameters.controlPoints]
                if (insertionPosition !== undefined)
                    controlPoints.splice(insertionPosition, 0, {
                        position: surfacePoint.toArray(),
                        normal: surfaceNormal.toArray(),
                        corner: false,
                    })
                else controlPoints.push({position: surfacePoint.toArray(), normal: surfaceNormal.toArray(), corner: false})

                this.sceneManagerService.modifyTemplateGraph(() => {
                    node.updateParameters({controlPoints})
                })

                const selectedNodeParts = this.sceneManagerService.$selectedNodeParts()
                for (const selectedNodePart of selectedNodeParts)
                    if (selectedNodePart.templateNode === node) this.sceneManagerService.removeNodeFromSelection(selectedNodePart)
                this.sceneManagerService.addNodeToSelection({
                    templateNode: node,
                    part: `controlPoint${insertionPosition !== undefined ? insertionPosition : controlPoints.length - 1}`,
                })
            })
    }
}
