import {Injector} from "@angular/core"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {Matrix4, Vector3} from "@cm/math"
import {RenderNodes} from "@cm/render-nodes"
import {
    AreaLight,
    Camera,
    defaultsForToneMapping,
    MaterialAssignment,
    MaterialAssignments,
    MaterialReference,
    Nodes,
    ProceduralMesh,
    Render,
    SceneNodes,
    SceneProperties,
    TemplateGraph,
} from "@cm/template-nodes"
import {buildRenderGraph} from "@cm/template-nodes/render-graph/scene-graph-to-render-graph"
import {cameraLookAt} from "@cm/template-nodes/utils/camera-utils"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {MaterialAssetsRenderingMaterialFragment} from "@common/services/material-assets-rendering/material-assets-rendering.generated"
import {HdriForRenderingGQL} from "@common/services/rendering/rendering.generated"

export const MAX_PIXEL_PER_MM = 32.9
export const CM_TO_M = 0.01
export const CM_TO_MM = 10
export const CM_TO_INCH = 0.393701

type NonNullableOverRootFields<T extends {[key: string]: unknown}> = {[K in keyof T]: NonNullable<T[K]>}

export type LegacyId = number
export type MaterialDetails = NonNullableOverRootFields<Required<Omit<MaterialAssetsRenderingMaterialFragment, "__typename">>>
export type JobGraphMaterialDetails = Omit<MaterialDetails, "latestCyclesRevision">

export async function renderGraphForTemplateGraph(sceneNodes: SceneNodes.SceneNode[], injector: Injector): Promise<RenderNodes.Render> {
    const resolveFns = {
        getHdriDataObjectIdDetails: async (hdriIdDetails: {legacyId: number}): Promise<{legacyId: number}> => {
            const hdriForRendering = injector.get(HdriForRenderingGQL)
            const dataObjectIdDetails = (await fetchThrowingErrors(hdriForRendering)(hdriIdDetails)).hdri.dataObject
            if (!dataObjectIdDetails) throw Error("Failed to query hdri data object id details")
            return dataObjectIdDetails
        },
    }
    return buildRenderGraph({nodes: sceneNodes, final: true, verifySchema: true, passes: ["Combined"]}, resolveFns)
}

export async function setupTemplateGraphForPlaneRender(
    materialRevisionId: LegacyId,
    resolutionX: number,
    resolutionY: number,
    samples: number,
    planeX: number,
    planeY: number,
    fieldOfView: number,
    sensorSize: number,
    useGpu: boolean,
    useCloud: boolean,
    sceneManagerService: SceneManagerService,
    matOffsetX = 0,
    matOffsetY = 0,
    matRot = 180,
): Promise<SceneNodes.SceneNode[]> {
    const focalLength = getFocalLengthFromFOVAndSensorSize(fieldOfView, sensorSize)
    const cameraDistanceToPlane = (focalLength / sensorSize) * Math.max(planeX, planeY)
    // console.log("cameraDistanceToPlane: ", cameraDistanceToPlane);

    const materialReference = new MaterialReference({name: "Material Reference", materialRevisionId})

    const plane_padding = 20.0
    const lightScale = 100

    const templateGraph = new TemplateGraph({
        name: "Untitled Scene",
        nodes: new Nodes({
            list: [
                new ProceduralMesh({
                    name: "Studio",
                    geometryGraph: "simpleStudioRoom",

                    materialAssignments: new MaterialAssignments({
                        "0": new MaterialAssignment({
                            node: materialReference,
                            horizontalOffset: matOffsetX,
                            verticalOffset: matOffsetY,
                            rotation: matRot,
                            side: "back",
                        }),
                    }),
                    materialSlotNames: {"0": "Floor"},
                    parameters: {
                        width: (planeX + plane_padding) * CM_TO_M,
                        length: (planeY + plane_padding) * CM_TO_M,
                        height: 1,
                        showFloor: true,
                        showWalls: false,
                        showCeiling: false,
                    },
                    lockedTransform: Matrix4.rotationX(90).multiply(Matrix4.rotationZ(180)).toArray(),
                    visible: true,
                    visibleDirectly: true,
                    visibleInReflections: true,
                    visibleInRefractions: true,
                    castRealtimeShadows: true,
                    receiveRealtimeShadows: true,
                }),
                new SceneProperties({
                    backgroundColor: [1, 1, 1],
                    maxSubdivisionLevel: 1,
                    uiStyle: "default",
                    uiColor: [0, 0, 0],
                    textureResolution: "2000px",
                    iconSize: 24,
                    enableAr: false,
                    enableSalesEnquiry: false,
                    textureFiltering: false,
                    enableRealtimeShadows: false,
                    enableRealtimeLights: false,
                    enableRealtimeMaterials: true,
                    enableOnboardingHint: false,
                    enableGltfDownload: false,
                    enableStlDownload: false,
                    enablePdfGeneration: false,
                    enableSnapshot: false,
                    enableFullscreen: false,
                    environmentMapMode: "full",
                    showAnnotations: false,
                    enableAdaptiveSubdivision: false,
                    enableDimensionGuides: false,
                    verticalArPlacement: false,
                }),
                new Camera({
                    name: "Main Camera",
                    resolutionX: resolutionX,
                    resolutionY: resolutionY,
                    lockedTransform: cameraLookAt(new Vector3(0, 0, cameraDistanceToPlane), new Vector3(0, 0, 0)).toArray(),
                    target: [0, 0, 0],
                    filmGauge: sensorSize,
                    focalLength: focalLength,
                    focalDistance: cameraDistanceToPlane,
                    fStop: 16,
                    shiftX: 0,
                    shiftY: 0,
                    automaticVerticalTilt: false,
                    enablePanning: true,
                    screenSpacePanning: true,
                    minDistance: 0,
                    maxDistance: 10000,
                    minPolarAngle: 0,
                    maxPolarAngle: 180,
                    minAzimuthAngle: -Infinity,
                    maxAzimuthAngle: Infinity,
                    targeted: false,
                    autoFocus: false,
                    toneMapping: defaultsForToneMapping("linear"),
                    ev: Math.log2(1),
                    visible: true,
                }),
                new AreaLight({
                    name: "Area Light",
                    intensity: 35,
                    width: 600 * lightScale,
                    height: 100 * lightScale,
                    color: [1, 1, 1],
                    lockedTransform: cameraLookAt(new Vector3(3000 * lightScale, 0, 1500 * lightScale), new Vector3(0, 0, 0)).toArray(),
                    target: [0, 0, 0],
                    on: true,
                    directionality: 0.6,
                    visibleDirectly: false,
                    visibleInReflections: true,
                    visibleInRefractions: true,
                    targeted: false,
                    transparent: false,
                    lightType: "Corona",
                    visible: true,
                }),
                new AreaLight({
                    name: "Area Light",
                    intensity: 0.01,
                    width: lightScale,
                    height: lightScale,
                    color: [1, 1, 1],
                    lockedTransform: cameraLookAt(new Vector3(0, 540 * lightScale, 150 * lightScale), new Vector3(0, 0, 0)).toArray(),
                    target: [0, 0, 0],
                    on: true,
                    directionality: 0.6,
                    visibleDirectly: false,
                    visibleInReflections: true,
                    visibleInRefractions: true,
                    targeted: false,
                    transparent: false,
                    lightType: "Corona",
                    visible: true,
                }),
                new Render({
                    width: resolutionX,
                    height: resolutionY,
                    samples: samples,
                    gpu: useGpu,
                    cloud: useCloud,
                }),
            ],
        }),
    })

    sceneManagerService.$templateGraph.set(templateGraph)
    sceneManagerService.compileTemplate()
    await sceneManagerService.sync(true)
    return sceneManagerService.$scene()
}

export function getFocalLengthFromFOVAndSensorSize(FOVInDeg: number, sensorSize: number): number {
    return sensorSize / (2 * Math.atan(((FOVInDeg / 2) * Math.PI) / 180))
}
