import {DestroyRef, effect, inject, Injectable, OnDestroy, signal, untracked} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {TraverseType, WebSocketMessaging} from "@app/common/helpers/websocket/websocket-messaging"
import {z} from "zod"
import {SceneManagerService} from "./scene-manager.service"
import {
    AreaLight,
    Camera,
    DataObjectReference,
    defaultsForToneMapping,
    isMesh,
    MaterialAssignment,
    MaterialAssignments,
    MaterialReference,
    MeshCurve,
    StoredMesh,
} from "@cm/template-nodes"
import {from3dsCoordinate, from3dsMaxTransform} from "@cm/template-nodes/utils/3ds-max-utils"
import {Vector3} from "../../../../../../shared/packages/math/src/vector3"
import {convertRawPolyMeshToDracoAndPLY, RawPolyMeshData} from "../helpers/mesh-processing"
import {timer} from "rxjs"
import {UploadGqlService} from "@app/common/services/upload/upload.gql.service"
import {DataObjectType} from "@generated"
import {Matrix4} from "@cm/math"
import {closestCurvePointsToObject, toThreeMatrix} from "../helpers/three-utils"
import {ThreeSceneManagerService} from "./three-scene-manager.service"
import {Three as THREE} from "@cm/material-nodes/three"
import {PriorityQueue} from "@cm/utils"

export const addLightSchema = z.object({
    type: z.literal("add_light"),
    name: z.string(),
    location: z.object({
        x: z.number(),
        y: z.number(),
        z: z.number(),
    }),
    rotation: z.object({
        x: z.number(),
        y: z.number(),
        z: z.number(),
    }),
    color: z.object({
        r: z.number(),
        g: z.number(),
        b: z.number(),
    }),
    width: z.number(),
    height: z.number(),
    spread: z.number(),
    power: z.number(),
    visible: z.boolean(),
})

export const addCameraSchema = z.object({
    type: z.literal("add_camera"),
    name: z.string(),
    location: z.object({
        x: z.number(),
        y: z.number(),
        z: z.number(),
    }),
    rotation: z.object({
        x: z.number(),
        y: z.number(),
        z: z.number(),
    }),
    shift: z.object({
        x: z.number(),
        y: z.number(),
    }),
    focalLength: z.number(),
    focusDistance: z.number(),
    fStop: z.number(),
    sensor: z.object({
        width: z.number(),
        height: z.number(),
    }),
    clipping: z.object({
        start: z.number(),
        end: z.number(),
    }),
    resolution: z.object({
        x: z.number(),
        y: z.number(),
    }),
})

const addMeshSchema = z.object({
    type: z.literal("add_mesh"),
    name: z.string(),
    location: z.object({
        x: z.number(),
        y: z.number(),
        z: z.number(),
    }),
    rotation: z.object({
        x: z.number(),
        y: z.number(),
        z: z.number(),
    }),
    polyVertexCount: z.array(z.number()),
    faceMaterialIndices: z.array(z.number()),
    vertexPositions: z.array(z.tuple([z.number(), z.number(), z.number()])),
    vertexNormals: z.array(z.tuple([z.number(), z.number(), z.number()])),
    vertexUVs: z.array(z.array(z.tuple([z.number(), z.number()]))),
    subdivisionLevel: z.number(),
    materialSlots: z.record(z.string(), z.number()),
})

const addCurveSchema = z.object({
    type: z.literal("add_curve"),
    name: z.string(),
    location: z.object({
        x: z.number(),
        y: z.number(),
        z: z.number(),
    }),
    rotation: z.object({
        x: z.number(),
        y: z.number(),
        z: z.number(),
    }),
    vertexPositions: z.array(z.tuple([z.number(), z.number(), z.number()])),
})

export const blenderMessageSchema = z.discriminatedUnion("type", [addLightSchema, addCameraSchema, addMeshSchema, addCurveSchema])
export type BlenderMessage = z.infer<typeof blenderMessageSchema>

const CONNECT_INTERVAL_MS = 3000
const BLENDER_CONNECTOR_SERVER_URL = "ws://127.0.0.1:17096"

@Injectable()
export class BlenderConnectorService implements OnDestroy {
    private readonly $_state = signal<"Disconnected" | "RetryConnecting" | "Connecting" | "Connected">("Disconnected")
    readonly $state = this.$_state.asReadonly()
    protected readonly $blenderConnector = signal<WebSocketMessaging | undefined>(undefined)
    private readonly destroyRef = inject(DestroyRef)
    private sceneManagerService = inject(SceneManagerService)
    private threeSceneManagerService: ThreeSceneManagerService | undefined = undefined
    private uploadService = inject(UploadGqlService)

    $enable = signal<boolean>(false)

    constructor() {
        effect(() => {
            if (this.$enable()) {
                this.$_state.set("Connecting")
            } else {
                this.$_state.set("Disconnected")
            }
        })
        effect(() => {
            const blenderConnector = untracked(() => this.$blenderConnector())

            switch (this.$_state()) {
                case "Connecting":
                    {
                        if (blenderConnector) {
                            this.$_state.set("Connected")
                        } else {
                            WebSocketMessaging.connect(BLENDER_CONNECTOR_SERVER_URL)
                                .pipe(takeUntilDestroyed(this.destroyRef))
                                .subscribe({
                                    next: (webSocketMessaging) => {
                                        this.$blenderConnector.set(webSocketMessaging)
                                        this.$_state.set("Connected")

                                        webSocketMessaging.message$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((message) => {
                                            this.onBlenderMessage(message)
                                        })
                                        webSocketMessaging.close$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
                                            this.$blenderConnector.set(undefined)
                                            if (this.$state() === "Connected") this.$_state.set("RetryConnecting")
                                        })
                                    },
                                    error: () => {
                                        if (this.$blenderConnector() === undefined && this.$state() === "Connecting") this.$_state.set("RetryConnecting")
                                    },
                                })
                        }
                    }
                    break
                case "RetryConnecting":
                    {
                        timer(CONNECT_INTERVAL_MS)
                            .pipe(takeUntilDestroyed(this.destroyRef))
                            .subscribe(() => {
                                if (this.$state() === "RetryConnecting") {
                                    this.$_state.set("Connecting")
                                }
                            })
                    }
                    break
                case "Disconnected":
                    {
                        if (blenderConnector) {
                            blenderConnector.destroy()
                            this.$blenderConnector.set(undefined)
                        }
                    }

                    break
            }
        })
    }

    ngOnDestroy(): void {
        const blenderConnector = this.$blenderConnector()

        if (blenderConnector) blenderConnector.destroy()
    }

    setThreeSceneManagerService(threeSceneManagerService: ThreeSceneManagerService | undefined) {
        this.threeSceneManagerService = threeSceneManagerService
    }

    private onBlenderMessage(message: TraverseType) {
        const parseResult = blenderMessageSchema.safeParse(message)
        if (!parseResult.success) {
            throw new Error("Invalid message from Blender")
        }

        const {data} = parseResult
        this.processBlenderMessage(data)
    }

    private processBlenderMessage(message: BlenderMessage) {
        switch (message.type) {
            case "add_light": {
                const {name, location, rotation, color, width, height, spread, power, visible} = message

                this.sceneManagerService.modifyTemplateGraph((graph) => {
                    graph.parameters.nodes.addEntry(
                        new AreaLight({
                            name,
                            width: width * 100,
                            height: height * 100,
                            lockedTransform: from3dsMaxTransform(
                                new Vector3(location.x * 100, location.y * 100, location.z * 100),
                                new Vector3(rotation.x, rotation.y, rotation.z),
                                undefined,
                                true,
                            ).toArray(),
                            visible: true,
                            target: [0, 0, 0],
                            targeted: false,
                            intensity: power,
                            color: [color.r, color.g, color.b],
                            on: true,
                            directionality: 1 - spread / Math.PI,
                            visibleDirectly: visible,
                            visibleInReflections: true,
                            visibleInRefractions: true,
                            transparent: false,
                            lightType: "Blender",
                        }),
                    )
                })
                break
            }
            case "add_camera": {
                const {name, location, rotation, shift, focalLength, focusDistance, fStop, sensor, clipping, resolution} = message

                this.sceneManagerService.modifyTemplateGraph((graph) => {
                    graph.parameters.nodes.addEntry(
                        new Camera({
                            name,
                            target: [0, 0, 0],
                            targeted: false,
                            lockedTransform: from3dsMaxTransform(
                                new Vector3(location.x * 100, location.y * 100, location.z * 100),
                                new Vector3(rotation.x, rotation.y, rotation.z),
                                undefined,
                                true,
                            ).toArray(),
                            visible: true,
                            resolutionX: resolution.x,
                            resolutionY: resolution.y,
                            filmGauge: sensor.width,
                            fStop: fStop,
                            focalLength,
                            focalDistance: focusDistance * 100,
                            autoFocus: false,
                            ev: 0.0,
                            shiftX: shift.x,
                            shiftY: shift.y,
                            automaticVerticalTilt: false,
                            enablePanning: true,
                            screenSpacePanning: true,
                            nearClip: clipping.start * 100,
                            farClip: clipping.end * 100,
                            toneMapping: defaultsForToneMapping("pbr-neutral"),
                        }),
                    )
                })
                break
            }
            case "add_mesh": {
                const defaultCustomerId = this.sceneManagerService.$defaultCustomerId()
                if (!defaultCustomerId) throw new Error("defaultCustomerId is not defined")

                const {workerService} = this.sceneManagerService

                const {
                    name,
                    location,
                    rotation,
                    polyVertexCount,
                    faceMaterialIndices,
                    vertexPositions,
                    vertexNormals,
                    vertexUVs,
                    subdivisionLevel,
                    materialSlots,
                } = message

                const rawPolyMeshData: RawPolyMeshData = {
                    polyVertexCount: new Uint32Array(polyVertexCount),
                    position: new Float32Array(
                        vertexPositions
                            .map((x) => from3dsCoordinate(Vector3.fromArray(x)).toArray())
                            .flat()
                            .map((v) => v * 100),
                    ),
                    normal: new Float32Array(vertexNormals.map((x) => from3dsCoordinate(Vector3.fromArray(x)).toArray()).flat()),
                    materialID: new Uint32Array(faceMaterialIndices),
                    uvs: vertexUVs.map((uv) => new Float32Array(uv.flat())),
                }

                convertRawPolyMeshToDracoAndPLY(workerService, rawPolyMeshData, 0.001, false)
                    .pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe(async (result) => {
                        const {drcData, plyData} = result

                        const [plyDataObject, dracoDataObject] = await Promise.all([
                            this.uploadService.createAndUploadDataObject(
                                new File([plyData], `${name}.ply`, {type: "application/ply"}),
                                {
                                    type: DataObjectType.Mesh,
                                    organizationLegacyId: defaultCustomerId,
                                },
                                {processUpload: true, showUploadToolbar: true},
                            ),
                            this.uploadService.createAndUploadDataObject(
                                new File([drcData], `${name}.drc`, {type: "application/draco"}),
                                {
                                    type: DataObjectType.Mesh,
                                    organizationLegacyId: defaultCustomerId,
                                },
                                {processUpload: true, showUploadToolbar: true},
                            ),
                        ])

                        const materialAssignments = new MaterialAssignments(
                            Object.fromEntries(
                                Object.entries(materialSlots).map(([key, materialRevisionId]) => [
                                    key,
                                    materialRevisionId === -1
                                        ? null
                                        : new MaterialAssignment({
                                              node: new MaterialReference({name: "(Material Ref)", materialRevisionId}),
                                              side: "front",
                                          }),
                                ]),
                            ),
                        )

                        this.sceneManagerService.modifyTemplateGraph((graph) => {
                            graph.parameters.nodes.addEntry(
                                new StoredMesh({
                                    name,
                                    metaData: {
                                        dracoBitDepth: result.dracoBitDepth,
                                        dracoResolution: result.dracoResolution,
                                        //osdUseRenderIterations: result.osdUseRenderIterations,
                                        //osdRenderIterations: result.osdRenderIterations,
                                        defaultPosition: result.defaultPosition,
                                        centered: result.centered,
                                        exporterVersion: result.exporterVersion,
                                    },
                                    drcDataObject: new DataObjectReference({name: `${name}.drc`, dataObjectId: dracoDataObject.legacyId}),
                                    plyDataObject: new DataObjectReference({name: `${name}.ply`, dataObjectId: plyDataObject.legacyId}),
                                    subdivisionRenderIterations: subdivisionLevel,
                                    materialAssignments,
                                    materialSlotNames: {},
                                    lockedTransform: from3dsMaxTransform(
                                        new Vector3(location.x * 100, location.y * 100, location.z * 100),
                                        new Vector3(rotation.x, rotation.y, rotation.z),
                                        undefined,
                                        false,
                                    )
                                        .multiply(Matrix4.translation(...result.defaultPosition))
                                        .toArray(),
                                    visible: true,
                                    visibleDirectly: true,
                                    visibleInReflections: true,
                                    visibleInRefractions: true,
                                    castRealtimeShadows: true,
                                    receiveRealtimeShadows: true,
                                }),
                            )
                        })
                    })

                break
            }
            case "add_curve": {
                const {name, location, rotation, vertexPositions} = message

                const selectedMesh = this.sceneManagerService
                    .$selectedNodeParts()
                    .map((x) => x.templateNode)
                    .find(isMesh)
                if (!selectedMesh) return

                const id = this.sceneManagerService.getObjectId(selectedMesh)
                if (id === undefined) return

                if (!this.threeSceneManagerService) throw new Error("ThreeSceneManagerService is not set")

                const threeObject = this.threeSceneManagerService.getThreeObject(id)
                if (!threeObject) return

                const meshTransform = this.sceneManagerService.getTransformAccessor(selectedMesh)?.getTransform()
                if (!meshTransform) return

                const inverseMeshTransform = meshTransform.inverse()

                const curveTransform = from3dsMaxTransform(
                    new Vector3(location.x * 100, location.y * 100, location.z * 100),
                    new Vector3(rotation.x, rotation.y, rotation.z),
                    undefined,
                    true,
                )

                const localPositions = simplifyControlPoints(
                    densifyControlPoints(
                        vertexPositions.map((x) => {
                            const position = curveTransform.multiplyVectorXYZW(...Vector3.fromArray(x).mul(100).toArray(), 1)
                            const localPosition = inverseMeshTransform.multiplyVectorXYZW(...position)
                            return new Vector3(localPosition[0], localPosition[1], localPosition[2])
                        }),
                        0.03,
                    ),
                    0.03,
                )

                const segmentLength = 0.1
                const queryRange = 2 * segmentLength

                const mappedPositions = closestCurvePointsToObject(
                    new Float32Array(localPositions.map((v) => v.toArray()).flat()),
                    toThreeMatrix(meshTransform),
                    queryRange,
                    threeObject.getRenderObject(),
                )

                const controlPoints: {position: [number, number, number]; normal: [number, number, number]; corner: boolean}[] = []
                for (let i = 0; i < mappedPositions.hitPoints.length; i++) {
                    const hitPoint = mappedPositions.hitPoints[i]
                    const normal = mappedPositions.hitNormals[i]

                    if (mappedPositions.valid[i]) {
                        controlPoints.push({
                            position: [hitPoint.x, hitPoint.y, hitPoint.z],
                            normal: [normal.x, normal.y, normal.z],
                            corner: false,
                        })
                    }
                }

                if (controlPoints.length > 0) {
                    this.sceneManagerService.modifyTemplateGraph((graph) => {
                        graph.parameters.nodes.addEntry(
                            new MeshCurve({
                                name,
                                mesh: selectedMesh,
                                closed: false,
                                controlPoints,
                                visible: true,
                            }),
                        )
                    })
                }

                break
            }
            default:
                throw new Error("Unknown Blender message type")
        }
    }
}

const densifyControlPoints = (controlPoints: Vector3[], maxDistance: number) => {
    const newControlPoints: Vector3[] = []
    newControlPoints.push(controlPoints[0])
    for (let i = 0; i < controlPoints.length - 1; i++) {
        const start = controlPoints[i]
        const end = controlPoints[i + 1]
        const distance = start.distance(end)

        if (distance > maxDistance) {
            const numSegments = Math.ceil(distance / maxDistance)
            for (let j = 1; j <= numSegments; j++) {
                const t = j / numSegments
                newControlPoints.push(start.add(end.sub(start).mul(t)))
            }
        } else {
            newControlPoints.push(end)
        }
    }

    return newControlPoints
}

const simplifyControlPoints = (controlPoints: Vector3[], maxError: number) => {
    const getCurvature = (controlPoints: Vector3[], index: number) => {
        const prev = controlPoints[index - 1]
        const current = controlPoints[index]
        const next = controlPoints[index + 1]

        const v1 = current.sub(prev).normalized()
        const v2 = next.sub(current).normalized()

        return 1 - v1.dot(v2)
    }

    const getCurveDifference = (controlPoints1: Vector3[], controlPoints2: Vector3[]) => {
        const curve1 = new THREE.CatmullRomCurve3(
            controlPoints1.map((p) => new THREE.Vector3(p.x, p.y, p.z)),
            false,
            "centripetal",
        )

        const curve2 = new THREE.CatmullRomCurve3(
            controlPoints2.map((p) => new THREE.Vector3(p.x, p.y, p.z)),
            false,
            "centripetal",
        )

        let diffSum = 0
        for (let u = 0; u <= 1; u += 0.01) {
            const originalPoint = curve1.getPointAt(u)
            const newPoint = curve2.getPointAt(u)
            diffSum += originalPoint.distanceTo(newPoint)
        }
        return (diffSum / 100) * Math.min(curve1.getLength(), curve2.getLength())
    }

    const priorityQueue = new PriorityQueue<Vector3>()
    for (let i = 1; i < controlPoints.length - 1; i++) {
        const curvature = getCurvature(controlPoints, i)
        priorityQueue.enqueue(controlPoints[i], curvature)
    }

    const currentControlPoints = [...controlPoints]

    while ((priorityQueue.peekPriority() ?? Infinity) !== Infinity) {
        const point = priorityQueue.dequeue()
        if (!point) break

        const index = currentControlPoints.findIndex((p) => p.equals(point))
        if (index >= 2 && index <= currentControlPoints.length - 3) {
            //Center
            const maxDiff = getCurveDifference(
                [
                    currentControlPoints[index - 2],
                    currentControlPoints[index - 1],
                    currentControlPoints[index],
                    currentControlPoints[index + 1],
                    currentControlPoints[index + 2],
                ],
                [currentControlPoints[index - 2], currentControlPoints[index - 1], currentControlPoints[index + 1], currentControlPoints[index + 2]],
            )

            if (maxDiff < maxError) {
                currentControlPoints.splice(index, 1)
                const leftPoint = currentControlPoints[index - 1]
                const rightPoint = currentControlPoints[index]
                priorityQueue.updatePriority(leftPoint, getCurvature(currentControlPoints, index - 1))
                priorityQueue.updatePriority(rightPoint, getCurvature(currentControlPoints, index))
            } else {
                priorityQueue.enqueue(point, Infinity)
            }
        } else if (index === 1 && currentControlPoints.length > 4) {
            //Left end
            const maxDiff = getCurveDifference(
                [
                    currentControlPoints[index - 1],
                    currentControlPoints[index],
                    currentControlPoints[index + 1],
                    currentControlPoints[index + 2],
                    currentControlPoints[index + 3],
                ],
                [currentControlPoints[index - 1], currentControlPoints[index + 1], currentControlPoints[index + 2], currentControlPoints[index + 3]],
            )

            if (maxDiff < maxError) {
                currentControlPoints.splice(index, 1)
                const rightPoint = currentControlPoints[index]
                priorityQueue.updatePriority(rightPoint, getCurvature(currentControlPoints, index))
            } else {
                priorityQueue.enqueue(point, Infinity)
            }
        } else if (index === currentControlPoints.length - 2 && currentControlPoints.length > 4) {
            //Right end
            const maxDiff = getCurveDifference(
                [
                    currentControlPoints[index - 3],
                    currentControlPoints[index - 2],
                    currentControlPoints[index - 1],
                    currentControlPoints[index],
                    currentControlPoints[index + 1],
                ],
                [currentControlPoints[index - 3], currentControlPoints[index - 2], currentControlPoints[index - 1], currentControlPoints[index + 1]],
            )

            if (maxDiff < maxError) {
                currentControlPoints.splice(index, 1)
                const leftPoint = currentControlPoints[index - 1]
                priorityQueue.updatePriority(leftPoint, getCurvature(currentControlPoints, index - 1))
            } else {
                priorityQueue.enqueue(point, Infinity)
            }
        } else {
            priorityQueue.enqueue(point, Infinity)
        }
    }

    return currentControlPoints
}
