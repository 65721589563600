import * as SimpleGraph from "@platform/helpers/simple-graph/simple-graph"

// eslint-disable-next-line @typescript-eslint/no-require-imports
const XMLWriter = require("xml-writer")

class IdGenerator {
    private nextId = 1

    constructor(private prefix: string) {}

    fetchId() {
        return this.prefix + this.nextId++
    }
}

type NodeIdByNode = Map<SimpleGraph.Node, string>

type State = {
    nodeIdGenerator: IdGenerator
    edgeIdGenerator: IdGenerator
    nodeIdByNode: NodeIdByNode
}

export class ExporterYed {
    private readonly DEFAULT_GROUP_COLOR = "#F5F5F5"
    private readonly DEFAULT_NODE_COLOR = "#CCCCFF"

    export(graph: SimpleGraph.Graph) {
        const state = {
            nodeIdGenerator: new IdGenerator("n"),
            edgeIdGenerator: new IdGenerator("e"),
            nodeIdByNode: new Map<SimpleGraph.Node, string>(),
        }
        const writer = new XMLWriter()
        this.writeHeader(writer)
        this.writeNodes(writer, graph.rootNodes, state)
        this.writeEdges(writer, graph.edges, state)
        writer.endDocument()
        return writer.toString()
    }

    private writeHeader(writer: typeof XMLWriter) {
        writer.startDocument()
        writer
            .startElement("graphml")
            .writeAttribute("xmlns", "http://graphml.graphdrawing.org/xmlns")
            .writeAttribute("xmlns:java", "http://www.yworks.com/xml/yfiles-common/1.0/java")
            .writeAttribute("xmlns:sys", "http://www.yworks.com/xml/yfiles-common/markup/primitives/2.0")
            .writeAttribute("xmlns:x", "http://www.yworks.com/xml/yfiles-common/markup/2.0")
            .writeAttribute("xmlns:xsi", "http://www.w3.org/2001/XMLSchema-instance")
            .writeAttribute("xmlns:y", "http://www.yworks.com/xml/graphml")
            .writeAttribute("xmlns:yed", "http://www.yworks.com/xml/yed/3")
            .writeAttribute("xsi:schemaLocation", "http://graphml.graphdrawing.org/xmlns http://www.yworks.com/xml/schema/graphml/1.1/ygraphml.xsd")
        writer.startElement("key").writeAttribute("for", "node").writeAttribute("id", "nodeId").writeAttribute("yfiles.type", "nodegraphics").endElement()
        writer.startElement("key").writeAttribute("for", "edge").writeAttribute("id", "edgeId").writeAttribute("yfiles.type", "edgegraphics").endElement()
        writer.startElement("graph").writeAttribute("edgedefault", "directed").writeAttribute("id", "G")
    }

    private writeNodes(writer: typeof XMLWriter, nodes: SimpleGraph.Node[], state: State) {
        for (const node of nodes) {
            const nodeId = state.nodeIdGenerator.fetchId()
            state.nodeIdByNode.set(node, nodeId)
            if (node.children) {
                this.writeGroupNodeBegin(writer, nodeId, node.label, node.color ?? this.DEFAULT_GROUP_COLOR)
                this.writeNodes(writer, node.children, state)
                this.writeGroupNodeEnd(writer)
            } else {
                this.writeNode(writer, nodeId, node.label, node.color ?? this.DEFAULT_NODE_COLOR)
            }
        }
    }

    private writeGroupNodeBegin(writer: typeof XMLWriter, nodeId: string, label: string | null, color: string) {
        writer.startElement("node").writeAttribute("id", nodeId).writeAttribute("yfiles.foldertype", "group")
        writer.startElement("data").writeAttribute("key", "nodeId")
        writer.startElement("y:ProxyAutoBoundsNode")
        writer.startElement("y:Realizers").writeAttribute("active", "0")
        writer.startElement("y:GroupNode")
        writer
            .startElement("y:Geometry")
            .writeAttribute("height", "50")
            .writeAttribute("width", "50")
            .writeAttribute("x", "0")
            .writeAttribute("y", "0")
            .endElement()
        writer
            .startElement("y:Fill")
            .writeAttribute("color", color.toLowerCase() === "transparent" ? this.DEFAULT_GROUP_COLOR : color)
            .writeAttribute("transparent", color.toLowerCase() === "transparent" ? "true" : "false")
            .endElement()
        writer.startElement("y:BorderStyle").writeAttribute("color", "#000000").writeAttribute("type", "dashed").writeAttribute("width", "1.0").endElement()
        if (label) {
            writer
                .startElement("y:NodeLabel")
                .writeAttribute("alignment", "right")
                .writeAttribute("autoSizePolicy", "node_width")
                .writeAttribute("backgroundColor", "#EBEBEB")
                .writeAttribute("hasBackgroundColor", color.toLowerCase() === "transparent" ? "false" : "true")
                .writeAttribute("borderDistance", "1.0")
                .writeAttribute("fontFamily", "Dialog")
                .writeAttribute("fontSize", "12")
                .writeAttribute("fontStyle", "plain")
                .writeAttribute("hasLineColor", "false")
                .writeAttribute("height", "21")
                .writeAttribute("horizontalTextPosition", "center")
                .writeAttribute("iconTextGap", "4")
                .writeAttribute("modelName", "internal")
                .writeAttribute("modelPosition", "t")
                .writeAttribute("textColor", "#000000")
                .writeAttribute("verticalTextPosition", "bottom")
                .writeAttribute("visible", "true")
                .writeAttribute("width", "400")
                .writeAttribute("x", "0")
                .writeAttribute("y", "0")
                .writeAttribute("xml:space", "preserve")
            writer.text(label)
            writer.endElement() // y:NodeLabel
        }
        writer.startElement("y:Shape").writeAttribute("type", "roundrectangle").endElement()
        writer
            .startElement("y:State")
            .writeAttribute("closed", "false")
            .writeAttribute("closedHeight", "50.0")
            .writeAttribute("closedWidth", "50.0")
            .writeAttribute("innerGraphDisplayEnabled", "false")
            .endElement()
        writer
            .startElement("y:Insets")
            .writeAttribute("bottom", "15")
            .writeAttribute("bottomF", "15.0")
            .writeAttribute("left", "15")
            .writeAttribute("leftF", "15.0")
            .writeAttribute("right", "15")
            .writeAttribute("rightF", "15.0")
            .writeAttribute("top", "15")
            .writeAttribute("topF", "15.0")
            .endElement()
        writer
            .startElement("y:BorderInsets")
            .writeAttribute("bottom", "15")
            .writeAttribute("bottomF", "15.0")
            .writeAttribute("left", "15")
            .writeAttribute("leftF", "15.0")
            .writeAttribute("right", "15")
            .writeAttribute("rightF", "15.0")
            .writeAttribute("top", "15")
            .writeAttribute("topF", "15.0")
            .endElement()
        writer.endElement() // y:GroupNode
        writer.endElement() // y:Realizers
        writer.endElement() // y:ProxyAutoBoundsNode
        writer.endElement() // data
        // new graph for group
        writer
            .startElement("graph")
            .writeAttribute("edgedefault", "directed")
            .writeAttribute("id", nodeId + ":")
    }

    private writeGroupNodeEnd(writer: typeof XMLWriter) {
        writer.endElement() // graph
        writer.endElement() // node
    }

    private writeNode(writer: typeof XMLWriter, nodeId: string, label: string | null, color: string) {
        writer.startElement("node").writeAttribute("id", nodeId)
        writer.startElement("data").writeAttribute("key", "nodeId")
        writer.startElement("y:ShapeNode")
        writer.startElement("y:Fill").writeAttribute("color", color).writeAttribute("transparent", false).endElement()
        writer.startElement("y:Geometry").endElement()
        if (label) {
            writer
                .startElement("y:NodeLabel")
                .writeAttribute("alignment", "left")
                .writeAttribute("autoSizePolicy", "content")
                .writeAttribute("fontFamily", "Dialog")
                .writeAttribute("fontSize", "12")
                .writeAttribute("fontStyle", "plain")
                .writeAttribute("hasBackgroundColor", "false")
                .writeAttribute("hasLineColor", "false")
                .writeAttribute("horizontalTextPosition", "center")
                .writeAttribute("iconTextGap", "4")
                .writeAttribute("modelName", "custom")
                .writeAttribute("textColor", "#000000")
                .writeAttribute("verticalTextPosition", "bottom")
                .writeAttribute("visible", "true")
                .writeAttribute("xml:space", "preserve")
            writer.text(label)
            writer.endElement() // y:NodeLabel
        }
        writer.endElement() // y:ShapeNode
        writer.endElement() // data
        writer.endElement() // node
    }

    private writeEdges(writer: typeof XMLWriter, edges: SimpleGraph.Edge[], state: State) {
        const idByLineStyle: {[key in SimpleGraph.LineStyle]: string} = {
            solid: "line",
            dashed: "dashed",
            dotted: "dotted",
        }
        const idByArrowStyle: {[key in SimpleGraph.ArrowStyle]: string} = {
            none: "none",
            standard: "standard",
        }
        const edgeIdGenerator = new IdGenerator("e")
        for (const edge of edges) {
            const edgeId = edgeIdGenerator.fetchId()
            const sourceId = state.nodeIdByNode.get(edge.source)
            const targetId = state.nodeIdByNode.get(edge.target)
            if (sourceId == null || targetId == null) {
                throw Error("Node id could not be resolved. Are all referenced nodes in the edges added to the nodes of the graph ?")
            }
            writer.startElement("edge").writeAttribute("id", edgeId).writeAttribute("source", sourceId).writeAttribute("target", targetId)
            {
                writer.startElement("data").writeAttribute("key", "edgeId")
                {
                    writer.startElement("y:PolyLineEdge")
                    {
                        writer
                            .startElement("y:Path")
                            .writeAttribute("sx", "0.0")
                            .writeAttribute("sy", "0.0")
                            .writeAttribute("tx", "0.0")
                            .writeAttribute("ty", "0.0")
                            .endElement()
                        writer
                            .startElement("y:LineStyle")
                            .writeAttribute("color", "#000000")
                            .writeAttribute("type", idByLineStyle[edge.style.lineStyle])
                            .writeAttribute("width", "1.0")
                            .endElement()
                        writer
                            .startElement("y:Arrows")
                            .writeAttribute("source", idByArrowStyle[edge.style.sourceArrowStyle])
                            .writeAttribute("target", idByArrowStyle[edge.style.targetArrowStyle])
                            .endElement()
                        if (edge.sourceLabel) {
                            writer
                                .startElement("y:EdgeLabel")
                                .writeAttribute("alignment", "center")
                                .writeAttribute("configuration", "AutoFlippingLabels")
                                .writeAttribute("distance", "2.0")
                                .writeAttribute("fontFamily", "Dialog")
                                .writeAttribute("fontSize", "8")
                                .writeAttribute("fontStyle", "plain")
                                .writeAttribute("hasBackgroundColor", "false")
                                .writeAttribute("hasLineColor", "false")
                                .writeAttribute("height", "18")
                                .writeAttribute("horizontalTextPosition", "center")
                                .writeAttribute("iconTextGap", "4")
                                .writeAttribute("modelName", "six_pos")
                                .writeAttribute("modelPosition", "shead")
                                .writeAttribute("preferredPlacement", "anywhere")
                                .writeAttribute("ratio", "0.5")
                                .writeAttribute("textColor", "#000000")
                                .writeAttribute("verticalTextPosition", "bottom")
                                .writeAttribute("visible", "true")
                                .writeAttribute("width", "52")
                                .writeAttribute("x", "10")
                                .writeAttribute("xml:space", "preserve")
                                .writeAttribute("y", "-17")
                            {
                                writer.text(edge.sourceLabel)
                                writer
                                    .startElement("y:PreferredPlacementDescriptor")
                                    .writeAttribute("angle", "0.0")
                                    .writeAttribute("angleOffsetOnRightSide", "0")
                                    .writeAttribute("angleReference", "relative_to_edge_flow")
                                    .writeAttribute("angleRotationOnRightSide", "co")
                                    .writeAttribute("distance", "-1.0")
                                    .writeAttribute("placement", "anywhere")
                                    .writeAttribute("side", "anywhere")
                                    .writeAttribute("sideReference", "relative_to_edge_flow")
                                    .endElement()
                            }
                            writer.endElement()
                        }
                        if (edge.targetLabel) {
                            writer
                                .startElement("y:EdgeLabel")
                                .writeAttribute("alignment", "center")
                                .writeAttribute("configuration", "AutoFlippingLabels")
                                .writeAttribute("distance", "2.0")
                                .writeAttribute("fontFamily", "Dialog")
                                .writeAttribute("fontSize", "8")
                                .writeAttribute("fontStyle", "plain")
                                .writeAttribute("hasBackgroundColor", "false")
                                .writeAttribute("hasLineColor", "false")
                                .writeAttribute("height", "18")
                                .writeAttribute("horizontalTextPosition", "center")
                                .writeAttribute("iconTextGap", "4")
                                .writeAttribute("modelName", "six_pos")
                                .writeAttribute("modelPosition", "thead")
                                .writeAttribute("preferredPlacement", "anywhere")
                                .writeAttribute("ratio", "0.5")
                                .writeAttribute("textColor", "#000000")
                                .writeAttribute("verticalTextPosition", "bottom")
                                .writeAttribute("visible", "true")
                                .writeAttribute("width", "52")
                                .writeAttribute("x", "10")
                                .writeAttribute("xml:space", "preserve")
                                .writeAttribute("y", "-17")
                            {
                                writer.text(edge.targetLabel)
                                writer
                                    .startElement("y:PreferredPlacementDescriptor")
                                    .writeAttribute("angle", "0.0")
                                    .writeAttribute("angleOffsetOnRightSide", "0")
                                    .writeAttribute("angleReference", "relative_to_edge_flow")
                                    .writeAttribute("angleRotationOnRightSide", "co")
                                    .writeAttribute("distance", "-1.0")
                                    .writeAttribute("placement", "anywhere")
                                    .writeAttribute("side", "anywhere")
                                    .writeAttribute("sideReference", "relative_to_edge_flow")
                                    .endElement()
                            }
                            writer.endElement()
                        }
                        writer.startElement("y:BendStyle").writeAttribute("smoothed", "false").endElement()
                    }
                    writer.endElement()
                }
                writer.endElement()
            }
            writer.endElement()
        }
    }
}
