import {
    IConnectionSolver,
    IOptimizationEntity,
    IRelation,
    IResidualBlock,
    IRigidBody,
    IVariable,
    IVariable_R1,
    IVariable_S1,
    IVariable_S3,
    Matrix,
    ObjectId,
    RotationalFreedomMode,
    RotationalJoint,
    Tensor,
    TranslationalJoint,
    VariableId,
    VariableType,
    Vector,
} from "@cm/template-nodes"
import {Matrix4, Vector3, Quaternion} from "@cm/math"
import {ISurfaceMap} from "@app/template-editor/helpers/connection-solver/surface-map-builder"
import {TransformParameters2D, TransformParameters3D} from "@app/template-editor/helpers/connection-solver/parameter-blocks"
import {toMatrix, zeros, toVector, matVec4Mul, matInverse4, angleDegreesToQuaternion, quaternionToAngleDegrees} from "@common/helpers/utils/math-utils"
import {Solver} from "@app/template-editor/helpers/connection-solver/least-squares-solver"

export type SurfaceId = string

function angleDegreesToSphere1(angle: number) {
    angle *= Math.PI / 180
    return [Math.sin(angle), Math.cos(angle)] as const
}

function sphere1ToAngleDegrees(values: [number, number]) {
    return Math.atan2(values[0], values[1]) * (180 / Math.PI)
}

type SurfaceData = {
    map: ISurfaceMap
}

enum ObjectState {
    Active,
    Inactive,
    InactiveUntilConvergenceReached,
    CurrentlyMovedByUserInitiallyActive,
    CurrentlyMovedByUserInitiallyInactive,
}

class Variable_R1 implements IVariable_R1 {
    type = "boundedReal" as const
    relation!: IRelation
    property!: "tx" | "ty" | "tz"
    range!: [number, number]
    default!: number
    value!: number
    holdDefault = true
}

class Variable_S1 implements IVariable_S1 {
    type = "1-sphere" as const
    relation!: IRelation
    property!: "rx" | "ry" | "rz"
    default!: [number, number]
    value!: [number, number]
    holdDefault = true
}

class Variable_S3 implements IVariable_S3 {
    type = "3-sphere" as const
    relation!: IRelation
    property!: "r"
    default!: [number, number, number, number]
    value!: [number, number, number, number]
    holdDefault = true
}

/** A relation between two surfaces of two different objects.
    The optimizable parameters are the rigid body transforms of each object, and (optionally) the "surface offset".
    The surface offset determines the alignment target, but is also free to be optimized. This allows certain useful degrees of freedom
    when optimizing the full system (Two surfaces free to slide or rotate against each other while other constraints are satisfied).
    */
class SurfaceRelation implements IRelation {
    surfaceOffset: TransformParameters2D = new TransformParameters2D(true)
    transform: TransformParameters3D = new TransformParameters3D()
    readonly transformLimits = {tx: [0, 0], ty: [0, 0], tz: [0, 0]}

    postConverge: undefined | (() => boolean) = undefined

    private localAxesA!: Matrix /** The correspondence matrix for surface A, which is like a representation of the local coordinate space of a surface, after overlapping area has been taken into account. */
    private localAxesAInv: Matrix = zeros(4, 4)
    private localAxesB!: Matrix /** The correspondence matrix for surface B, which is like a representation of the local coordinate space of a surface, after overlapping area has been taken into account. */
    private localAxesA_Jac!: Tensor /** The Jacobian of localAxesA, with respect to surfaceOffset parameters */
    private localAxesB_Jac!: Tensor /** The Jacobian of localAxesB, with respect to surfaceOffset parameters */
    private resScale!: Vector /** Hack for scaling residuals so that they all have roughly the same magnitude. (Improves convergence) */

    private transformedLocalAxesA = zeros(4, 4)
    private transformedLocalAxesB = zeros(4, 4)
    private transformedLocalAxesA_dParamsT = zeros(6, 4, 4)
    private transformedLocalAxesB_dParamsT = zeros(6, 4, 4)

    public needUpdate = true
    readonly residualBlocks: IResidualBlock[]

    constructor(
        readonly objectA: IRigidBody,
        readonly surfaceA: SurfaceData,
        readonly objectB: IRigidBody,
        readonly surfaceB: SurfaceData,
    ) {
        this.transform.tx_free = false
        this.transform.ty_free = false
        this.transform.tz_free = false
        this.transform.r_free = RotationalFreedomMode.None
        this.surfaceOffset.tx_free = false
        this.surfaceOffset.ty_free = false
        this.surfaceOffset.r_free = false
        this.update()

        this.residualBlocks = [
            {
                // coarse-to-fine alignment of surface maps
                type: "linear",
                size: 4 * 4,
                active: true,
                relatedParameters: [this.objectA.transform, this.objectB.transform, this.transform],
                // relatedParameters: [this.objectA.transform, this.objectB.transform],
                evaluate: (residuals: Vector, jacobians: Matrix[]) => {
                    //const matrixA = this.objectA.transform.matrix;
                    const matrixAInv = this.objectA.transform.matrixInv
                    //const dMatrixA_dParamsA = this.objectA.transform.jacobian;
                    const dMatrixAInv_dParamsA = this.objectA.transform.jacobianInv
                    const matrixB = this.objectB.transform.matrix
                    const dMatrixB_dParamsB = this.objectB.transform.jacobian
                    const jacobianA = jacobians[0]
                    const jacobianB = jacobians[1]
                    const jacobianT = jacobians[2]
                    // const localAxesA = this.localAxesA
                    const localAxesAInv = this.localAxesAInv
                    const localAxesB = this.localAxesB
                    // const resScale = this.resScale
                    const transform = this.transform.matrix
                    const dTransform_dParamsT = this.transform.jacobian
                    const MALAInv = this.transformedLocalAxesA
                    const MBLB = this.transformedLocalAxesB
                    const dMALAInv_dParamsA = this.transformedLocalAxesA_dParamsT
                    const dMBLB_dParamsB = this.transformedLocalAxesB_dParamsT

                    // LA^-1 MA^-1 MB LB = T

                    // LA^-1 dMA^-1/dP (MB LB)

                    for (let k = 0; k < 4; k++) {
                        for (let i = 0; i < 4; i++) {
                            let tmpA = 0
                            let tmpB = 0
                            for (let j = 0; j < 4; j++) {
                                tmpA += localAxesAInv.get(k, j) * matrixAInv.get(j, i)
                                tmpB += matrixB.get(k, j) * localAxesB.get(j, i)
                            }
                            MALAInv.set(k, i, tmpA)
                            MBLB.set(k, i, tmpB)
                        }
                    }

                    for (let k = 0; k < 4; k++) {
                        for (let i = 0; i < 4; i++) {
                            let tmp = 0
                            for (let j = 0; j < 4; j++) {
                                tmp += MALAInv.get(k, j) * MBLB.get(j, i)
                            }
                            residuals.set(k * 4 + i, tmp - transform.get(k, i))
                        }
                    }

                    // jacobian
                    const nParams = dMatrixAInv_dParamsA.shape[0]

                    for (let p = 0; p < nParams; p++) {
                        for (let k = 0; k < 4; k++) {
                            for (let i = 0; i < 4; i++) {
                                let tmpA = 0
                                let tmpB = 0
                                for (let j = 0; j < 4; j++) {
                                    tmpA += localAxesAInv.get(k, j) * dMatrixAInv_dParamsA.get(p, j, i)
                                    tmpB += dMatrixB_dParamsB.get(p, k, j) * localAxesB.get(j, i)
                                }
                                dMALAInv_dParamsA.set(p, k, i, tmpA)
                                dMBLB_dParamsB.set(p, k, i, tmpB)
                            }
                        }
                    }

                    for (let p = 0; p < nParams; p++) {
                        for (let k = 0; k < 4; k++) {
                            for (let i = 0; i < 4; i++) {
                                let tmpA = 0
                                let tmpB = 0
                                for (let j = 0; j < 4; j++) {
                                    tmpA += dMALAInv_dParamsA.get(p, k, j) * MBLB.get(j, i)
                                    tmpB += MALAInv.get(k, j) * dMBLB_dParamsB.get(p, j, i)
                                }
                                jacobianA.set(k * 4 + i, p, tmpA)
                                jacobianB.set(k * 4 + i, p, tmpB)
                                jacobianT.set(k * 4 + i, p, -dTransform_dParamsT.get(p, k, i))
                            }
                        }
                    }

                    // if (this.surfaceOffset.hasFreeParams()) {
                    //     // only calculate this block if sufaceOffset is included in the list of related parameters
                    //     // jacobians[2] = dResiduals_dSurfaceParams
                    //     const nParams = dLocalAxesA_dSurfaceParams.shape[0];
                    //     for (let p = 0; p < nParams; p++) {
                    //         let dLocalAxesA_dSurfaceParams_tangentOffset = zeros(4,4);
                    //         for (let k = 0; k < 4; k++){
                    //             for (let i = 0; i < 4; i++){
                    //                 let tmp = 0;
                    //                 for (let j = 0; j < 4; j++){
                    //                     tmp += dLocalAxesA_dSurfaceParams.get(p, j, i) * tangentOffset.get(j, k);
                    //                 }
                    //                 dLocalAxesA_dSurfaceParams_tangentOffset.set(i, k, tmp);
                    //             }
                    //         }

                    //         for(let k = 0; k < 4; k++) {
                    //             const resScaleK = k == 3 ? .1 : 1; //resScale.get(k); // residual scaling hack
                    //             for (let i = 0; i < 4; i++) {
                    //                 let tmp = 0;
                    //                 for (let j = 0; j < 4; j++) {
                    //                     tmp += matrixA.get(i, j) * dLocalAxesA_dSurfaceParams_tangentOffset.get(j, k);
                    //                     tmp -= matrixB.get(i, j) * dLocalAxesB_dSurfaceParams.get(p, k, j);
                    //                 }
                    //                 jacobians[2].set(k*4 + i, p, tmp * resScaleK);
                    //             }
                    //         }
                    //     }
                    // }

                    return true
                },
            },
            {
                // Transform constraints
                type: "linear",
                size: 3,
                active: true,
                relatedParameters: [this.transform],
                evaluate: (residuals: Vector, jacobians: Matrix[]) => {
                    const J = jacobians[0]
                    const p = this.transform
                    const [x0, x1] = this.transformLimits.tx
                    const [y0, y1] = this.transformLimits.ty
                    const [z0, z1] = this.transformLimits.tz
                    if (p.tx_free) {
                        const px = p.tx
                        residuals.set(0, px - clamp(px, x0, x1))
                        J.set(0, 0, 1 - d_clamp(px, x0, x1))
                    }
                    if (p.ty_free) {
                        const py = p.ty
                        residuals.set(1, py - clamp(py, y0, y1))
                        J.set(1, 1, 1 - d_clamp(py, y0, y1))
                    }
                    if (p.tz_free) {
                        const pz = p.tz
                        residuals.set(2, pz - clamp(pz, z0, z1))
                        J.set(2, 2, 1 - d_clamp(pz, z0, z1))
                    }
                    return true
                },
            },
        ]
    }

    getLocalAxes(): Matrix[] {
        return [this.localAxesA, this.localAxesB]
    }

    update() {
        this.needUpdate = true
    }

    preEval() {
        if (this.surfaceOffset.hasFreeParams() || this.needUpdate) {
            this.surfaceOffset.updateMatrixAndJacobian()
            this.transform.updateMatrixAndJacobian()
            // const matrixBtoA = this.surfaceOffset.matrix
            // const jacobianBtoA = this.surfaceOffset.jacobian
            const matrixAtoB = this.surfaceOffset.matrixInv
            const jacobianAtoB = this.surfaceOffset.jacobianInv
            ;[this.localAxesA, this.localAxesA_Jac, this.localAxesB, this.localAxesB_Jac, this.resScale] = computeCorrespondanceVectors(
                this.surfaceA.map,
                this.surfaceB.map,
                matrixAtoB,
                jacobianAtoB,
            )
            matInverse4(this.localAxesA, this.localAxesAInv)
            this.needUpdate = false
        }
    }
}

function clamp(x: number, a: number, b: number) {
    if (x < a) return a
    else if (x > b) return b
    else return x
}

function d_clamp(x: number, a: number, b: number) {
    if (x < a) return 0
    else if (x > b) return 0
    else return 1
}

export class ObjectRelation implements IRelation {
    transform: TransformParameters3D = new TransformParameters3D()
    readonly transformLimits = {tx: [0, 0], ty: [0, 0], tz: [0, 0]}

    postConverge: undefined | (() => boolean) = undefined

    readonly residualBlocks: IResidualBlock[]

    constructor(
        readonly objectA: ObjectData,
        readonly objectB: ObjectData,
    ) {
        this.transform.tx_free = false
        this.transform.ty_free = false
        this.transform.tz_free = false
        this.transform.r_free = RotationalFreedomMode.None

        this.residualBlocks = [
            {
                type: "linear",
                size: 4 * 4,
                active: true,
                relatedParameters: [this.objectA.transform, this.objectB.transform, this.transform],
                evaluate: (residuals: Vector, jacobians: Matrix[]) => {
                    const matrixA = this.objectA.transform.matrix
                    const matrixAInv = this.objectA.transform.matrixInv
                    const dMatrixA_dTransformA = this.objectA.transform.jacobian
                    const dMatrixAInv_dTransformA = this.objectA.transform.jacobianInv
                    const matrixB = this.objectB.transform.matrix
                    const dMatrixB_dTransformB = this.objectB.transform.jacobian
                    const jacobianA = jacobians[0]
                    const jacobianB = jacobians[1]
                    const jacobianT = jacobians[2]

                    const transform = this.transform.matrix
                    const dTransform_dParamsT = this.transform.jacobian

                    //residuals
                    for (let k = 0; k < 4; k++) {
                        for (let i = 0; i < 4; i++) {
                            let tmp = 0
                            for (let j = 0; j < 4; j++) {
                                tmp += matrixAInv.get(k, j) * matrixB.get(j, i)
                            }
                            residuals.set(k * 4 + i, tmp - transform.get(k, i))
                        }
                    }

                    // jacobian
                    const nParams = dMatrixA_dTransformA.shape[0]
                    for (let p = 0; p < nParams; p++) {
                        for (let k = 0; k < 4; k++) {
                            for (let i = 0; i < 4; i++) {
                                let tmpA = 0
                                let tmpB = 0
                                for (let j = 0; j < 4; j++) {
                                    tmpA += dMatrixAInv_dTransformA.get(p, k, j) * matrixB.get(j, i)
                                    tmpB += matrixAInv.get(k, j) * dMatrixB_dTransformB.get(p, j, i)
                                }
                                jacobianA.set(k * 4 + i, p, tmpA)
                                jacobianB.set(k * 4 + i, p, tmpB)
                                jacobianT.set(k * 4 + i, p, -dTransform_dParamsT.get(p, k, i))
                            }
                        }
                    }
                    return true
                },
            },
            {
                // Transform constraints
                type: "linear",
                size: 3,
                active: true,
                relatedParameters: [this.transform],
                evaluate: (residuals: Vector, jacobians: Matrix[]) => {
                    const J = jacobians[0]
                    const p = this.transform
                    const [x0, x1] = this.transformLimits.tx
                    const [y0, y1] = this.transformLimits.ty
                    const [z0, z1] = this.transformLimits.tz
                    if (p.tx_free) {
                        const px = p.tx
                        residuals.set(0, px - clamp(px, x0, x1))
                        J.set(0, 0, 1 - d_clamp(px, x0, x1))
                    }
                    if (p.ty_free) {
                        const py = p.ty
                        residuals.set(1, py - clamp(py, y0, y1))
                        J.set(1, 1, 1 - d_clamp(py, y0, y1))
                    }
                    if (p.tz_free) {
                        const pz = p.tz
                        residuals.set(2, pz - clamp(pz, z0, z1))
                        J.set(2, 2, 1 - d_clamp(pz, z0, z1))
                    }
                    return true
                },
            },
        ]
    }

    update() {
        this.transform.updateMatrixAndJacobian()
        this.residualBlocks[1].active = this.transform.tx_free || this.transform.ty_free || this.transform.tz_free
    }
}

/** A weak constraint that tries to move a point in object space to a fixed location. Useful for real-time manipulation of an object */
class PointTarget implements IOptimizationEntity {
    public weight = 0.01

    private projectionMatrix!: Matrix
    private objPoint = [0, 0, 0, 1]
    private targetX = 0
    private targetY = 0
    readonly residualBlocks: IResidualBlock[]

    constructor(readonly object: IRigidBody) {
        this.residualBlocks = [
            {
                type: "linear",
                size: 2,
                active: true,
                relatedParameters: [this.object.transform],
                evaluate: (residuals: Vector, jacobians: Matrix[]) => {
                    const projMatrix = this.projectionMatrix
                    const objMatrix = this.object.transform.matrix
                    const dObjMatrix_dParams = this.object.transform.jacobian
                    const objPoint = this.objPoint
                    const worldPoint = [0, 0, 0, 1]
                    const projPoint = [0, 0, 0, 1]

                    // object space to world space
                    for (let i = 0; i < 3; i++) {
                        let tmp = 0
                        for (let j = 0; j < 4; j++) {
                            tmp += objMatrix.get(i, j) * objPoint[j]
                        }
                        worldPoint[i] = tmp
                    }

                    // world space to projective camera space
                    for (let i = 0; i < 4; i++) {
                        let tmp = 0
                        for (let j = 0; j < 4; j++) {
                            tmp += projMatrix.get(i, j) * worldPoint[j]
                        }
                        projPoint[i] = tmp
                    }

                    // project camera space to screen (see THREEjs Vector3.project / applyMatrix4)
                    const screenX = projPoint[0]
                    const screenY = projPoint[1]
                    residuals.set(0, (screenX - this.targetX * projPoint[3]) * this.weight)
                    residuals.set(1, (screenY - this.targetY * projPoint[3]) * this.weight)

                    // dScreen_dParams = dScreen_dProjPoint * dProjPoint_dWorldPoint * dWorldPoint_dObjMatrix * dObjMatrix_dParams
                    const dScreenX_dProjPoint = [1, 0, 0, 0]
                    const dScreenY_dProjPoint = [0, 1, 0, 0]
                    const nParams = dObjMatrix_dParams.shape[0]
                    for (let p = 0; p < nParams; p++) {
                        const dWorldPoint = [0, 0, 0, 0]
                        for (let i = 0; i < 4; i++) {
                            let tmp = 0
                            for (let j = 0; j < 4; j++) {
                                tmp += dObjMatrix_dParams.get(p, i, j) * objPoint[j]
                            }
                            dWorldPoint[i] = tmp
                        }

                        const dProjPoint = [0, 0, 0, 0]
                        for (let i = 0; i < 4; i++) {
                            let tmp = 0
                            for (let j = 0; j < 4; j++) {
                                tmp += projMatrix.get(i, j) * dWorldPoint[j]
                            }
                            dProjPoint[i] = tmp
                        }

                        let jacobianX = 0
                        let jacobianY = 0
                        for (let i = 0; i < 4; i++) {
                            jacobianX += dProjPoint[i] * dScreenX_dProjPoint[i]
                            jacobianY += dProjPoint[i] * dScreenY_dProjPoint[i]
                        }

                        jacobianX -= this.targetX * dProjPoint[3]
                        jacobianY -= this.targetY * dProjPoint[3]
                        jacobians[0].set(0, p, jacobianX * this.weight)
                        jacobians[0].set(1, p, jacobianY * this.weight)
                    }
                    return true
                },
            },
        ]
    }

    setProjectionMatrix(projectionMatrix: Matrix4): void {
        this.projectionMatrix = toMatrix(projectionMatrix.toArrayTransposed(), 4, 4)
    }

    setObjPoint(objPoint: Vector3): void {
        this.objPoint[0] = objPoint.x
        this.objPoint[1] = objPoint.y
        this.objPoint[2] = objPoint.z
    }

    setTarget(targetX: number, targetY: number): void {
        this.targetX = targetX
        this.targetY = targetY
    }
}

/** Compute the correspondance between two surfaces with a given set of parameters, and return a set of vectors for each which represents the local axes, while taking into account
    only the parts of the surfaces which overlap. Also computes the derivative of these vectors with respect to the surface offset parameters. */
function computeCorrespondanceVectors(mapA: ISurfaceMap, mapB: ISurfaceMap, matrixCoordAtoCoordB: Matrix, dMatrixCoordAtoCoordB_dP: Tensor) {
    //const beginTime = performance.now();
    const nParams = dMatrixCoordAtoCoordB_dP.shape[0]

    //TODO: this can probably be optimized quite a bit.

    const samplePtsA = mapA.generateSamplingPattern(mapB, matrixCoordAtoCoordB)

    const dCoordA_dP = zeros(nParams, 4)
    const dCoordB_dP = zeros(nParams, 4)
    const coordA = [0, 0, 0, 1]
    const coordB = [0, 0, 0, 1]

    let weightOutSum = 0
    const numSamples = samplePtsA.shape[0]
    const weightOut = zeros(numSamples)
    const dWeightOut_dP = zeros(nParams, numSamples)
    const dWeightOutSum_dP = zeros(nParams)
    const dWeightA_dCoordA = [0, 0, 0, 0]
    const dWeightB_dCoordB = [0, 0, 0, 0]
    for (let si = 0; si < numSamples; si++) {
        ////
        coordA[0] = samplePtsA.get(si, 0)
        coordA[1] = samplePtsA.get(si, 1)
        matVec4Mul(matrixCoordAtoCoordB, coordA[0], coordA[1], 0, 1, coordB)
        for (let p = 0; p < nParams; p++) {
            for (let i = 0; i < 2; i++) {
                const dCoordBi_dp =
                    dMatrixCoordAtoCoordB_dP.get(p, i, 0) * coordA[0] +
                    dMatrixCoordAtoCoordB_dP.get(p, i, 1) * coordA[1] +
                    dMatrixCoordAtoCoordB_dP.get(p, i, 3)
                dCoordA_dP.set(p, i, 0)
                dCoordB_dP.set(p, i, dCoordBi_dp)
            }
        }
        ////
        const weightA = mapA.sampleWeightWithCoordD(coordA, dWeightA_dCoordA) / samplePtsA.get(si, 2)
        const weightB = mapB.sampleWeightWithCoordD(coordB, dWeightB_dCoordB) / samplePtsA.get(si, 3)
        const w = weightA * weightB
        weightOut.set(si, w)
        weightOutSum += w

        for (let p = 0; p < nParams; p++) {
            let dWeightA_dp = 0
            let dWeightB_dp = 0
            for (let i = 0; i < 2; i++) {
                dWeightA_dp += dWeightA_dCoordA[i] * dCoordA_dP.get(p, i)
                dWeightB_dp += dWeightB_dCoordB[i] * dCoordB_dP.get(p, i)
            }
            const dw_dp = weightA * dWeightB_dp + dWeightA_dp * weightB
            dWeightOut_dP.set(p, si, dw_dp)
            dWeightOutSum_dP.set(p, dWeightOutSum_dP.get(p) + dw_dp)
        }
    }

    const invWeightOutSum = 1.0 / (weightOutSum + 1e-8)
    const dInvWeightOutSum_dP = zeros(nParams)
    for (let p = 0; p < nParams; p++) {
        dInvWeightOutSum_dP.set(p, -(invWeightOutSum * invWeightOutSum) * dWeightOutSum_dP.get(p))
    }

    const ptA = [0, 0, 0, 0]
    const ptB = [0, 0, 0, 0]
    const dPtA_dCoordA = zeros(4, 4)
    const dPtB_dCoordB = zeros(4, 4)
    const centroidA = [0, 0, 0, 0]
    const dCentroidA_dP = zeros(nParams, 4)
    const centroidB = [0, 0, 0, 0]
    const dCentroidB_dP = zeros(nParams, 4)
    const centroidCoordA = [0, 0, 0, 0]
    // const dCentroidCoordA_dP = zeros(nParams, 4)
    for (let si = 0; si < numSamples; si++) {
        ////
        coordA[0] = samplePtsA.get(si, 0)
        coordA[1] = samplePtsA.get(si, 1)
        matVec4Mul(matrixCoordAtoCoordB, coordA[0], coordA[1], 0, 1, coordB)
        for (let p = 0; p < nParams; p++) {
            for (let i = 0; i < 2; i++) {
                const dCoordBi_dp =
                    dMatrixCoordAtoCoordB_dP.get(p, i, 0) * coordA[0] +
                    dMatrixCoordAtoCoordB_dP.get(p, i, 1) * coordA[1] +
                    dMatrixCoordAtoCoordB_dP.get(p, i, 3)
                dCoordA_dP.set(p, i, 0)
                dCoordB_dP.set(p, i, dCoordBi_dp)
            }
        }
        ////
        mapA.samplePointWithCoordD(coordA, ptA, dPtA_dCoordA)
        mapB.samplePointWithCoordD(coordB, ptB, dPtB_dCoordB)
        const w = weightOut.get(si)
        const wSc = w * invWeightOutSum
        for (let j = 0; j < 4; j++) {
            const ptAj = ptA[j]
            const ptBj = ptB[j]
            centroidA[j] += ptAj * wSc
            centroidB[j] += ptBj * wSc
            const cAj = coordA[j]
            centroidCoordA[j] += cAj * wSc
            for (let p = 0; p < nParams; p++) {
                const diwos_dp = dInvWeightOutSum_dP.get(p)
                const dw_dp = dWeightOut_dP.get(p, si)
                let dPtAj_dp = 0
                let dPtBj_dp = 0
                for (let i = 0; i < 2; i++) {
                    dPtAj_dp += dPtA_dCoordA.get(i, j) * dCoordA_dP.get(p, i)
                    dPtBj_dp += dPtB_dCoordB.get(i, j) * dCoordB_dP.get(p, i)
                }
                dCentroidA_dP.set(p, j, dCentroidA_dP.get(p, j) + dPtAj_dp * w * invWeightOutSum + ptAj * dw_dp * invWeightOutSum + ptAj * w * diwos_dp)
                dCentroidB_dP.set(p, j, dCentroidB_dP.get(p, j) + dPtBj_dp * w * invWeightOutSum + ptBj * dw_dp * invWeightOutSum + ptBj * w * diwos_dp)
                //TODO:
                // dCentroidCoordA_dP.set(p, j, dCentroidA_dP.get(p, j) + (dPtAj_dp * w * invWeightOutSum) + (ptAj * dw_dp * invWeightOutSum) + (ptAj * w * diwos_dp));
            }
        }
    }

    const axesSumA = zeros(4, 4)
    const dAxesSumA_dP = zeros(nParams, 4, 4)
    const axesSumB = zeros(4, 4)
    const dAxesSumB_dP = zeros(nParams, 4, 4)
    for (let si = 0; si < numSamples; si++) {
        ////
        coordA[0] = samplePtsA.get(si, 0)
        coordA[1] = samplePtsA.get(si, 1)
        matVec4Mul(matrixCoordAtoCoordB, coordA[0], coordA[1], 0, 1, coordB)
        for (let p = 0; p < nParams; p++) {
            for (let i = 0; i < 2; i++) {
                const dCoordBi_dp =
                    dMatrixCoordAtoCoordB_dP.get(p, i, 0) * coordA[0] +
                    dMatrixCoordAtoCoordB_dP.get(p, i, 1) * coordA[1] +
                    dMatrixCoordAtoCoordB_dP.get(p, i, 3)
                dCoordA_dP.set(p, i, 0)
                dCoordB_dP.set(p, i, dCoordBi_dp)
            }
        }
        ////
        mapA.samplePointWithCoordD(coordA, ptA, dPtA_dCoordA)
        mapB.samplePointWithCoordD(coordB, ptB, dPtB_dCoordB)
        const w = weightOut.get(si)
        const fx = (coordA[0] - centroidCoordA[0]) / mapA.scaleX
        const fy = (coordA[1] - centroidCoordA[1]) / mapA.scaleY

        for (let j = 0; j < 4; j++) {
            const cptAj = ptA[j] - centroidA[j]
            const cptBj = ptB[j] - centroidB[j]
            axesSumA.set(j, 0, axesSumA.get(j, 0) + fx * cptAj * w)
            axesSumA.set(j, 1, axesSumA.get(j, 1) + fy * cptAj * w)
            axesSumB.set(j, 0, axesSumB.get(j, 0) + fx * cptBj * w)
            axesSumB.set(j, 1, axesSumB.get(j, 1) + fy * cptBj * w)
            for (let p = 0; p < nParams; p++) {
                let dPtAj_dp = 0
                let dPtBj_dp = 0
                for (let i = 0; i < 2; i++) {
                    dPtAj_dp += dPtA_dCoordA.get(i, j) * dCoordA_dP.get(p, i)
                    dPtBj_dp += dPtB_dCoordB.get(i, j) * dCoordB_dP.get(p, i)
                }
                const dw_dp = dWeightOut_dP.get(p, si)
                const dcptAj_dp = dPtAj_dp - dCentroidA_dP.get(p, j)
                const dcptBj_dp = dPtBj_dp - dCentroidB_dP.get(p, j)
                dAxesSumA_dP.set(p, j, 0, dAxesSumA_dP.get(p, j, 0) + fx * dcptAj_dp * w + fx * cptAj * dw_dp)
                dAxesSumA_dP.set(p, j, 1, dAxesSumA_dP.get(p, j, 1) + fy * dcptAj_dp * w + fy * cptAj * dw_dp)
                dAxesSumB_dP.set(p, j, 0, dAxesSumB_dP.get(p, j, 0) + fx * dcptBj_dp * w + fx * cptBj * dw_dp)
                dAxesSumB_dP.set(p, j, 1, dAxesSumB_dP.get(p, j, 1) + fy * dcptBj_dp * w + fy * cptBj * dw_dp)
            }
        }
    }

    /////

    const MA = axesSumA
    const dMA_dP = dAxesSumA_dP

    // find strongest vector and use as first basis vector
    let vecX = new Vector3(MA.get(0, 0), MA.get(1, 0), MA.get(2, 0))
    let vecY = new Vector3(MA.get(0, 1), MA.get(1, 1), MA.get(2, 1))
    let normX = vecX.norm()
    let normY = vecY.norm()
    vecX = vecX.normalized()
    vecY = vecY.normalized()
    let vecZ = vecX.cross(vecY).normalized()

    // reconstruct the other vector
    if (normX >= normY) vecY = vecZ.cross(vecX)
    else vecX = vecY.cross(vecZ)

    MA.set(0, 0, vecX.x)
    MA.set(1, 0, vecX.y)
    MA.set(2, 0, vecX.z)
    MA.set(3, 0, 0)
    MA.set(0, 1, vecY.x)
    MA.set(1, 1, vecY.y)
    MA.set(2, 1, vecY.z)
    MA.set(3, 1, 0)
    MA.set(0, 2, vecZ.x)
    MA.set(1, 2, vecZ.y)
    MA.set(2, 2, vecZ.z)
    MA.set(3, 2, 0)
    MA.set(0, 3, centroidA[0])
    MA.set(1, 3, centroidA[1])
    MA.set(2, 3, centroidA[2])
    MA.set(3, 3, 1)

    // Same thing for B:

    const MB = axesSumB
    const dMB_dP = dAxesSumB_dP

    // find strongest vector and use as first basis vector
    vecX = new Vector3(MB.get(0, 0), MB.get(1, 0), MB.get(2, 0))
    vecY = new Vector3(MB.get(0, 1), MB.get(1, 1), MB.get(2, 1))
    normX = vecX.norm()
    normY = vecY.norm()
    vecX = vecX.normalized()
    vecY = vecY.normalized()
    vecZ = vecX.cross(vecY).normalized()

    // reconstruct the other vector
    if (normX >= normY) vecY = vecZ.cross(vecX)
    else vecX = vecY.cross(vecZ)

    MB.set(0, 0, vecX.x)
    MB.set(1, 0, vecX.y)
    MB.set(2, 0, vecX.z)
    MB.set(3, 0, 0)
    MB.set(0, 1, vecY.x)
    MB.set(1, 1, vecY.y)
    MB.set(2, 1, vecY.z)
    MB.set(3, 1, 0)
    MB.set(0, 2, vecZ.x)
    MB.set(1, 2, vecZ.y)
    MB.set(2, 2, vecZ.z)
    MB.set(3, 2, 0)
    MB.set(0, 3, centroidB[0])
    MB.set(1, 3, centroidB[1])
    MB.set(2, 3, centroidB[2])
    MB.set(3, 3, 1)

    //TODO: compute jacobian for MA, MB

    return [MA, dMA_dP, MB, dMB_dP, toVector([1, 1, 1, 1])]
}

class ObjectData implements IRigidBody {
    transform = new TransformParameters3D()
    surfaces = new Map<SurfaceId, SurfaceData>()
    optimizationEntities = new Set<IOptimizationEntity>()
    state: ObjectState = ObjectState.Active
}

export class ConnectionSolver implements IConnectionSolver {
    private solver = new Solver()
    private objects = new Map<ObjectId, ObjectData>()
    private optimizationEntities = new Set<IOptimizationEntity>()
    private iterationsPerStep = 10
    private defaultUpdateStepCount = 30
    private stepsRemaining = 30

    constructor() {
        this.solver.onPreEval = () => {
            for (const entity of this.optimizationEntities) {
                if (entity.preEval) entity.preEval()
            }
        }
    }

    destroy() {
        this.solver.destroy()
    }

    private addEntity(entity: IOptimizationEntity): void {
        entity.residualBlocks.forEach((r) => this.solver.addResidualBlock(r))
        this.optimizationEntities.add(entity)
    }

    private removeEntity(entity: IOptimizationEntity): void {
        entity.residualBlocks.forEach((r) => this.solver.removeResidualBlock(r))
        this.optimizationEntities.delete(entity)
    }

    public addObject(objId: ObjectId): IRigidBody {
        const objData = new ObjectData()
        this.objects.set(objId, objData)
        this.triggerUpdate()
        return objData
    }

    public removeObject(objId: ObjectId): void {
        const objData = this.objects.get(objId)
        if (!objData) {
            return
        }
        this.objects.delete(objId)
        objData.optimizationEntities.forEach((entity) => this.removeEntity(entity))
        this.triggerUpdate()
    }

    public addUpdateSurface(objId: ObjectId, surfId: SurfaceId, surfaceMap: ISurfaceMap) {
        const objData = this.objects.get(objId)!
        let surfData = objData.surfaces.get(surfId)
        if (surfData) {
            surfData.map = surfaceMap
            for (const entity of this.optimizationEntities) {
                if (entity instanceof SurfaceRelation) {
                    if (entity.surfaceA === surfData || entity.surfaceB === surfData) {
                        entity.update()
                    }
                }
            }
        } else {
            // new surface
            surfData = {
                map: surfaceMap,
            }
            objData.surfaces.set(surfId, surfData)
        }
        this.triggerUpdate()
    }

    public removeSurface(objId: ObjectId, surfId: SurfaceId): void {
        const objData = this.objects.get(objId)!
        objData.surfaces.delete(surfId)
        //TODO: remove relations?
        this.triggerUpdate()
    }

    private _setObjectMatrix(obj: ObjectData, matrix: Matrix4): void {
        const {position, quaternion} = matrix.decompose()
        obj.transform.tx = position.x
        obj.transform.ty = position.y
        obj.transform.tz = position.z
        obj.transform.qx = quaternion.x
        obj.transform.qy = quaternion.y
        obj.transform.qz = quaternion.z
        obj.transform.qw = quaternion.w
        obj.transform.updateMatrixAndJacobian()
        //TODO: update residual block flags?
        this.triggerUpdate()
    }

    public setObjectMatrix(objId: ObjectId, matrix: Matrix4): void {
        const objData = this.objects.get(objId)
        if (!objData) {
            console.warn(`Object ${objId} not found`)
            return
        }
        this._setObjectMatrix(objData, matrix)
    }

    public setObjectActive(objId: ObjectId, translationActive: boolean, rotationActive?: boolean, force?: boolean): void {
        const objData = this.objects.get(objId)
        if (!objData) {
            console.warn(`Object ${objId} not found`)
            return
        }

        if (force === undefined) {
            force = false
        }

        if (objData.state === ObjectState.InactiveUntilConvergenceReached && !force) {
            return
        }

        if (rotationActive === undefined) {
            rotationActive = translationActive
        }

        objData.state = translationActive || rotationActive ? ObjectState.Active : ObjectState.Inactive
        this.internalSetObjectActive(objData, translationActive, rotationActive)
    }

    public setObjectBeingMoved(objId: ObjectId): void {
        const objData = this.objects.get(objId)
        if (!objData) {
            console.warn(`Object ${objId} not found`)
            return
        }

        const oldState = objData.state
        if (oldState === ObjectState.Active || oldState === ObjectState.InactiveUntilConvergenceReached) {
            objData.state = ObjectState.CurrentlyMovedByUserInitiallyActive
        } else if (oldState === ObjectState.Inactive) {
            objData.state = ObjectState.CurrentlyMovedByUserInitiallyInactive
        }

        if (oldState !== objData.state) {
            this.internalSetObjectActive(objData, false, false)
        }
    }

    public setObjectReleased(objId: ObjectId): void {
        const objData = this.objects.get(objId)
        if (!objData) {
            console.warn(`Object ${objId} not found`)
            return
        }

        if (objData.state === ObjectState.CurrentlyMovedByUserInitiallyActive) {
            objData.state = ObjectState.InactiveUntilConvergenceReached
        } else if (objData.state === ObjectState.CurrentlyMovedByUserInitiallyInactive) {
            objData.state = ObjectState.Inactive
        }
    }

    public setObjectInstantMove(objId: ObjectId): void {
        const objData = this.objects.get(objId)
        if (!objData) {
            console.warn(`Object ${objId} not found`)
            return
        }

        if (objData.state === ObjectState.Active || objData.state === ObjectState.CurrentlyMovedByUserInitiallyActive) {
            objData.state = ObjectState.InactiveUntilConvergenceReached
            this.internalSetObjectActive(objData, false, false)
        }
    }

    private updateObjectsStates(): void {
        for (const obj of this.objects) {
            const objData = obj[1]
            if (objData.state === ObjectState.InactiveUntilConvergenceReached) {
                objData.state = ObjectState.Active
                this.internalSetObjectActive(objData, true, true)
            }
        }
    }

    private runPostConvergeCallbacks(): void {
        let reTriggerUpdate = false
        for (const entity of this.optimizationEntities) {
            if (entity.postConverge) {
                if (entity.postConverge()) {
                    reTriggerUpdate = true
                }
            }
        }
        if (reTriggerUpdate) {
            this.triggerUpdate()
        }
    }

    private internalSetObjectActive(objData: ObjectData, translationActive: boolean, rotationActive: boolean): void {
        let didChange = false
        if (translationActive !== objData.transform.tx_free) {
            objData.transform.tx_free = translationActive
            objData.transform.ty_free = translationActive
            objData.transform.tz_free = translationActive
            didChange = true
        }
        if (rotationActive !== (objData.transform.r_free === RotationalFreedomMode.Full)) {
            objData.transform.r_free = rotationActive ? RotationalFreedomMode.Full : RotationalFreedomMode.None
            didChange = true
        }
        if (didChange) {
            objData.transform.updateMatrixAndJacobian()
            this.triggerUpdate()
        }
    }

    public getObjectMatrix(objId: ObjectId): Matrix4 | null {
        const objData = this.objects.get(objId)
        if (!objData) {
            return null
        }
        return Matrix4.fromArrayTransposed(objData.transform.matrix.data as ArrayLike<number>)
    }

    public addVariable(varId: VariableId, type: VariableType): IVariable {
        switch (type) {
            case "boundedReal":
                return new Variable_R1()
            case "1-sphere":
                return new Variable_S1()
            case "3-sphere":
                return new Variable_S3()
        }
    }

    public removeVariable(variable: IVariable): void {
        const transform = variable.relation?.transform
        if (transform) {
            transform.tx_free = false
            transform.ty_free = false
            transform.tz_free = false
            transform.r_free = RotationalFreedomMode.None
        }
    }

    public getVariableValue(variable: IVariable) {
        const transform = variable.relation?.transform
        if (transform) {
            const getAngles = () => quaternionToAngleDegrees(new Quaternion(transform.qx, transform.qy, transform.qz, transform.qw))
            switch (variable.property) {
                case "tx":
                    return transform.tx * 2
                case "ty":
                    return transform.ty * 2
                case "tz":
                    return transform.tz * 2
                case "rx":
                    return angleDegreesToSphere1(getAngles()[0])
                case "ry":
                    return angleDegreesToSphere1(getAngles()[1])
                case "rz":
                    return angleDegreesToSphere1(getAngles()[2])
                case "r": {
                    const q = new Quaternion(transform.qx, transform.qy, transform.qz, transform.qw)
                    return [q.x, q.y, q.z, q.w] as const
                }
            }
        } else {
            return undefined
        }
    }

    public setVariableValue(variable: IVariable, value: any, triggerUpdate = true) {
        const transform = variable.relation?.transform
        variable.value = value
        if (transform) {
            const setAngles = (rx: number, ry: number, rz: number) => {
                const q = angleDegreesToQuaternion(rx, ry, rz)
                transform.qx = q.x
                transform.qy = q.y
                transform.qz = q.z
                transform.qw = q.w
            }
            switch (variable.property) {
                case "tx":
                    transform.tx = value ?? 0
                    break
                case "ty":
                    transform.ty = value ?? 0
                    break
                case "tz":
                    transform.tz = value ?? 0
                    break
                case "rx":
                    setAngles(sphere1ToAngleDegrees(value ?? 0), 0, 0)
                    break
                case "ry":
                    setAngles(0, sphere1ToAngleDegrees(value ?? 0), 0)
                    break
                case "rz":
                    setAngles(0, 0, sphere1ToAngleDegrees(value ?? 0))
                    break
                case "r":
                    {
                        value ??= [0, 0, 0, 1]
                        const q = new Quaternion(value[0], value[1], value[2], value[3])
                        transform.qx = q.x
                        transform.qy = q.y
                        transform.qz = q.z
                        transform.qw = q.w
                    }
                    break
            }
            variable.relation.update()
            if (triggerUpdate) {
                this.triggerUpdate()
            }
        }
    }

    public setVariableDefault(
        variable: IVariable,
        defaultValue: number | [number, number] | [number, number, number, number],
        holdUntilConvergence: boolean,
        triggerUpdate = true,
    ) {
        variable.default = defaultValue
        if (holdUntilConvergence) {
            variable.holdDefault = true
            // console.log("Holding defaults for", variable);
        }
        this.setVariableValue(variable, defaultValue, triggerUpdate)
    }

    public setVariableConstraints(variable: IVariable, range: [number, number], triggerUpdate = true): void {
        if (variable.type === "boundedReal") {
            variable.range = range

            const transform = variable.relation?.transform
            if (transform) {
                const min = Math.min(...range)
                const max = Math.max(...range)
                transform[variable.property] = clamp(transform[variable.property], min, max)
                variable.relation.transformLimits[variable.property] = [min, max]
                variable.relation.update()
                if (triggerUpdate) {
                    this.triggerUpdate()
                }
            }
        }
    }

    public addSurfaceRelation(objIdA: ObjectId, surfIdA: SurfaceId, objIdB: ObjectId, surfIdB: SurfaceId): SurfaceRelation {
        const objA = this.objects.get(objIdA)!
        const objB = this.objects.get(objIdB)!
        const surfA = objA.surfaces.get(surfIdA)!
        const surfB = objB.surfaces.get(surfIdB)!
        const relation = new SurfaceRelation(objA, surfA, objB, surfB)
        this.addEntity(relation)
        objA.optimizationEntities.add(relation)
        objB.optimizationEntities.add(relation)
        this.triggerUpdate()
        return relation
    }

    public setSurfaceOffset(relation: SurfaceRelation, x: number, y: number, angleDegrees: number) {
        relation.surfaceOffset.tx = x
        relation.surfaceOffset.ty = y
        relation.surfaceOffset.r = angleDegrees * (Math.PI / 180)
        relation.surfaceOffset.tx_free = false
        relation.surfaceOffset.ty_free = false
        relation.surfaceOffset.r_free = false
        relation.update()
        this.triggerUpdate()
    }

    public addObjectRelation(objIdA: ObjectId, objIdB: ObjectId) {
        const objA = this.objects.get(objIdA)
        const objB = this.objects.get(objIdB)
        if (!objA) {
            console.error("Could not find object:", objIdA)
            return undefined
        }
        if (!objB) {
            console.error("Could not find object:", objIdB)
            return undefined
        }
        const relation = new ObjectRelation(objA, objB)
        this.addEntity(relation)
        objA.optimizationEntities.add(relation)
        objB.optimizationEntities.add(relation)
        this.triggerUpdate()
        return relation
    }

    public removeRelation(relation: IRelation): void {
        ;(relation.objectA as ObjectData).optimizationEntities.delete(relation)
        ;(relation.objectB as ObjectData).optimizationEntities.delete(relation)
        this.removeEntity(relation)
        this.triggerUpdate()
    }

    public setRelationConstraints(relation: IRelation, trans: TranslationalJoint, rot: RotationalJoint, triggerUpdate = true) {
        const transform = relation.transform

        const holdClearList: IVariable[] = []

        const setTrans = (key: "tx" | "ty" | "tz", freeKey: "tx_free" | "ty_free" | "tz_free") => {
            const t = trans[key]
            if (t instanceof Variable_R1) {
                t.relation = relation
                t.property = key
                if (t.holdDefault) {
                    transform[freeKey] = false
                    holdClearList.push(t)
                } else {
                    transform[freeKey] = true
                }
                this.setVariableConstraints(t, t.range, triggerUpdate)
                this.setVariableDefault(t, t.default, false, triggerUpdate)
            } else if (typeof t === "number") {
                transform[freeKey] = false
                transform[key] = t
                relation.transformLimits[key] = [t, t]
            }
        }

        setTrans("tx", "tx_free")
        setTrans("ty", "ty_free")
        setTrans("tz", "tz_free")

        if (rot.type === "fixed") {
            transform.r_free = RotationalFreedomMode.None
            const q = angleDegreesToQuaternion(rot.rx, rot.ry, rot.rz)
            transform.qx = q.x
            transform.qy = q.y
            transform.qz = q.z
            transform.qw = q.w
        } else if (rot.type === "hinge") {
            rot.angleVariable.relation = relation

            if (rot.axis === "x") {
                transform.r_free = RotationalFreedomMode.X_Only
                rot.angleVariable.property = "rx"
            } else if (rot.axis === "y") {
                transform.r_free = RotationalFreedomMode.Y_Only
                rot.angleVariable.property = "ry"
            } else if (rot.axis === "z") {
                transform.r_free = RotationalFreedomMode.Z_Only
                rot.angleVariable.property = "rz"
            }

            if (rot.angleVariable.holdDefault) {
                holdClearList.push(rot.angleVariable)
                transform.r_free = RotationalFreedomMode.None
            }

            this.setVariableDefault(rot.angleVariable, rot.angleVariable.default, false, triggerUpdate)

            //TODO: hinge angle constraint (see http://twvideo01.ubm-us.net/o1/vault/gdc2016/Presentations/VanDenBergen_Gino_Rotational_Joint_Limits.pdf)
        } else if (rot.type === "ball") {
            rot.angleVariable.relation = relation
            rot.angleVariable.property = "r"

            transform.r_free = RotationalFreedomMode.Full
            if (rot.angleVariable.holdDefault) {
                holdClearList.push(rot.angleVariable)
                transform.r_free = RotationalFreedomMode.None
            }

            this.setVariableDefault(rot.angleVariable, rot.angleVariable.default, false, triggerUpdate)
        } else {
            console.error("Invalid rotation type:", rot)
        }

        relation.postConverge = () => {
            let didClear = false
            for (const variable of holdClearList) {
                variable.holdDefault = false
                // console.log("Clearing hold of defaults for", variable);
                didClear = true
            }
            if (didClear) {
                this.setRelationConstraints(relation, trans, rot, false)
            }
            return false
        }

        relation.update()

        if (triggerUpdate) {
            this.triggerUpdate()
        }
    }

    public setFixedRelationWithMatrix(relation: ObjectRelation, matrix: Matrix4) {
        const transform = relation.transform
        const {position, quaternion, scale} = matrix.decompose()

        transform.tx_free = false
        transform.ty_free = false
        transform.tz_free = false
        transform.r_free = RotationalFreedomMode.None
        transform.tx = position.x
        transform.ty = position.y
        transform.tz = position.z
        transform.qx = quaternion.x
        transform.qy = quaternion.y
        transform.qz = quaternion.z
        transform.qw = quaternion.w
        relation.update()
        this.triggerUpdate()
    }

    public relationHasOpenDegreesOfFreedom(relation: IRelation): boolean {
        return relation.transform.hasFreeParams()
    }

    public getCurrentTransformationForRelation(relation: IRelation): number[] {
        const transform = relation.transform
        const q = new Quaternion(transform.qx, transform.qy, transform.qz, transform.qw)
        return [transform.tx, transform.ty, transform.tz, ...quaternionToAngleDegrees(q)]
    }

    public getRelativeTransformationBetweenSurfaces(relation: IRelation): number[] | null {
        if (relation instanceof SurfaceRelation) {
            const matrixA = relation.objectA.transform.matrix
            const matrixB = relation.objectB.transform.matrix
            const [localAxesA, localAxesB] = relation.getLocalAxes()

            const matrixB_localAxesB = zeros(4, 4)
            const matrixA_localAxesA = zeros(4, 4)
            const localAxesAInv_matrixAInv = zeros(4, 4)

            for (let k = 0; k < 4; k++) {
                for (let i = 0; i < 4; i++) {
                    let tmpA = 0
                    let tmpB = 0
                    for (let j = 0; j < 4; j++) {
                        tmpA += matrixA.get(k, j) * localAxesA.get(j, i)
                        tmpB += matrixB.get(k, j) * localAxesB.get(j, i)
                    }
                    matrixA_localAxesA.set(k, i, tmpA)
                    matrixB_localAxesB.set(k, i, tmpB)
                }
            }

            matInverse4(matrixA_localAxesA, localAxesAInv_matrixAInv)
            const newTransform = zeros(4, 4)

            for (let k = 0; k < 4; k++) {
                for (let i = 0; i < 4; i++) {
                    let tmp = 0
                    for (let j = 0; j < 4; j++) {
                        tmp += localAxesAInv_matrixAInv.get(k, j) * matrixB_localAxesB.get(j, i)
                    }
                    newTransform.set(k, i, tmp)
                }
            }

            const ret: number[] = [0, 0, 0, 0, 0, 0]
            {
                ret[0] = newTransform.get(0, 3)
                ret[1] = newTransform.get(1, 3)
                ret[2] = newTransform.get(2, 3)

                const sin_b = -newTransform.get(2, 0)

                if (sin_b > 0.998) {
                    ret[3] = Math.atan2(newTransform.get(0, 1), newTransform.get(1, 1)) * (180 / Math.PI)
                    ret[4] = 90
                    ret[5] = 0
                } else if (sin_b < -0.998) {
                    ret[3] = Math.atan2(-newTransform.get(0, 1), newTransform.get(1, 1)) * (180 / Math.PI)
                    ret[4] = -90
                    ret[5] = 0
                } else {
                    ret[3] = Math.atan2(newTransform.get(2, 1), newTransform.get(2, 2)) * (180 / Math.PI)
                    ret[4] = Math.asin(-newTransform.get(2, 0)) * (180 / Math.PI)
                    ret[5] = Math.atan2(newTransform.get(1, 0), newTransform.get(0, 0)) * (180 / Math.PI)
                }
            }
            return ret
        }

        return null
    }

    public addPointTarget(objId: ObjectId, objPoint: Vector3, projectionMatrix: Matrix4): PointTarget {
        const obj = this.objects.get(objId)!
        const targ = new PointTarget(obj)
        targ.setObjPoint(objPoint)
        targ.setProjectionMatrix(projectionMatrix)
        this.addEntity(targ)
        obj.optimizationEntities.add(targ)
        this.triggerUpdate()
        return targ
    }

    public removePointTarget(targ: PointTarget): void {
        ;(targ.object as ObjectData).optimizationEntities.delete(targ)
        this.removeEntity(targ)
        this.triggerUpdate()
    }

    public setPointTarget(entity: PointTarget, targetX: number, targetY: number) {
        entity.setTarget(targetX, targetY)
        this.triggerUpdate()
    }

    public removeAll(): void {
        this.objects.forEach((objData, objId) => {
            objData.optimizationEntities.forEach((entity) => this.removeEntity(entity))
        })
        this.objects.clear()
        this.triggerUpdate()
    }

    private buildRelationMap(): Map<ObjectData, [ObjectData, IRelation][]> {
        const relMap = new Map<ObjectData, [ObjectData, IRelation][]>()
        const addEntry = (a: ObjectData, b: ObjectData, r: IRelation) => {
            // symmetric map (add two entries: a->b and b->a)
            let list = relMap.get(a)
            if (!list) {
                list = []
                relMap.set(a, list)
            }
            list.push([b, r])
            list = relMap.get(b)
            if (!list) {
                list = []
                relMap.set(b, list)
            }
            list.push([a, r])
        }
        for (const entity of this.optimizationEntities) {
            if (entity instanceof ObjectRelation) {
                addEntry(entity.objectA, entity.objectB, entity)
            }
        }
        return relMap
    }

    public propagateFixedRelations(rootIds: ObjectId[]) {
        const relMap = this.buildRelationMap()
        const visitedSet = new Set<ObjectData>()
        const traverse = (fromObj: ObjectData, prevObj: ObjectData | null, depth: number) => {
            if (visitedSet.has(fromObj)) {
                console.warn("Object has multiple or circular constraints with no open degrees of freedom:", fromObj)
                return
            }
            visitedSet.add(fromObj)
            const entries = relMap.get(fromObj)
            if (!entries) return
            for (const [toObj, relation] of entries) {
                if (toObj === prevObj) {
                    continue // relation map is symmetric, so don't follow links to the immediate predecessor
                } else if (!this.relationHasOpenDegreesOfFreedom(relation)) {
                    if (relation instanceof ObjectRelation) {
                        if (fromObj === relation.objectA) {
                            // from A to B: B = A T
                            const matrixA = Matrix4.fromArrayTransposed(relation.objectA.transform.matrix.data as ArrayLike<number>)
                            const transform = Matrix4.fromArrayTransposed(relation.transform.matrix.data as ArrayLike<number>)
                            this._setObjectMatrix(toObj, matrixA.multiply(transform))
                        } else if (fromObj === relation.objectB) {
                            // from B to A: A = B T^-1
                            const matrixB = Matrix4.fromArrayTransposed(relation.objectB.transform.matrix.data as ArrayLike<number>)
                            const transformInv = Matrix4.fromArrayTransposed(relation.transform.matrixInv.data as ArrayLike<number>)
                            this._setObjectMatrix(toObj, matrixB.multiply(transformInv))
                        }
                        traverse(toObj, fromObj, depth + 1)
                    } else {
                        //TODO: other relation types
                    }
                }
            }
        }
        for (const rootId of rootIds) {
            const rootObj = this.objects.get(rootId)
            if (rootObj) {
                traverse(rootObj, null, 0)
            }
        }
        //TODO: determine if solver needs to run, if there are no open/active DOFs
    }

    public triggerUpdate(): void {
        this.solver.restart()
        this.stepsRemaining = this.defaultUpdateStepCount
    }

    public checkReady(): boolean {
        return this.solver.checkReady()
    }

    public step(): boolean {
        if (!this.solver.checkReady()) {
            return true
        }

        if (this.stepsRemaining > 0) {
            --this.stepsRemaining
            for (let i = 0; i < this.iterationsPerStep; i++) {
                this.solver.step()
            }
            if (this.stepsRemaining === 0) {
                // NOTE: These functions may reset stepsRemaining!
                // this.solver.dumpStats();
                this.updateObjectsStates()
                this.runPostConvergeCallbacks()
            }
        }

        return this.stepsRemaining > 0
    }

    get running(): boolean {
        return this.stepsRemaining > 0
    }
}
