<cm-routed-dialog [dialogSize]="dialogSizes.Large" [dialogScroll]="false" (close)="overlayClosed()">
    <ng-container cm-overlay-content>
        <div class="cm-material-editor-card">
            <div [class.cm-node-editor-split]="screenSize === screenSizes.splitScreen" class="cm-node-editor">
                <cm-node-editor
                    #nodeEditor
                    [nodeAccessor]="nodeAccessor"
                    (deleteNodes)="onDeleteNodes($event)"
                    (addConnection)="onAddConnection($event)"
                    (deleteConnections)="onDeleteConnections($event)"
                    (moveNode)="onMoveNode($event)"
                    (selectNode)="onSelectNode($event)"
                    (parameterChange)="onParameterChange($event)"
                >
                </cm-node-editor>
                <div class="cm-top-right-container"></div>
                <div class="cm-top-left-container">
                    @if (activeRevision) {
                        <div class="cm-tag-container">
                            <span [matMenuTriggerFor]="revisionMenu" class="cm-card-tag"> v{{ activeRevision.number }} ({{ activeRevision.legacyId }})▾ </span>
                        </div>
                    }
                </div>
                <div class="cm-bottom-right-container">
                    <cm-floating-button id="addNodes" class="cm-add-button">
                        <ng-container cm-icon>
                            <i class="far fa-plus"></i>
                        </ng-container>
                    </cm-floating-button>
                    @if (allowSave) {
                        <cm-floating-button (click)="saveNewMaterialRevision()" class="cm-save-button">
                            <ng-container cm-icon>
                                <i class="fa-light fa-floppy-disk"></i>
                            </ng-container>
                        </cm-floating-button>
                    }
                </div>
            </div>
            @if (material && nodeEditorTemplateId && materialGraph) {
                <div
                    [class.cm-splitscreen]="screenSize === screenSizes.splitScreen"
                    [class.cm-fullscreen]="screenSize === screenSizes.fullScreen"
                    [class.cm-minimize]="screenSize === screenSizes.minimize"
                    class="cm-preview-scene-container"
                >
                    <cm-title-bar (screenSizeChange)="screenSize = $event" class="cm-title-bar"></cm-title-bar>
                    <cm-preview-scene [materialGraph]="materialGraph" class="cm-preview-scene"> </cm-preview-scene>
                </div>
            }
        </div>
    </ng-container>
</cm-routed-dialog>
<mat-menu #revisionMenu="matMenu">
    @for (revision of cyclesRevisions; track revision.id) {
        <button mat-menu-item [disabled]="revision === activeRevision" (click)="loadRevision(revision)">v{{ revision.number }} ({{ revision.id }})</button>
    }
</mat-menu>
<div id="addNodesContainer" style="display: none">
    <div class="cm-add-menu-container">
        <cm-add-menu-section>
            <ng-container cm-section-title>
                <i class="far fa-book"></i>
                Advanced
            </ng-container>
            <ng-container cm-section>
                @for (nodeType of addableNodeTypes; track nodeType.id) {
                    <cm-list-item (click)="addNode(nodeType)">
                        <ng-container cm-title> {{ nodeType.label }}</ng-container>
                    </cm-list-item>
                }
            </ng-container>
        </cm-add-menu-section>
    </div>
</div>
