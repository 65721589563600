@if ($props(); as props) {
    @switch (props.state) {
        @case ("initial") {
            @if ($isDebugEnabled() && $debugMenu()) {
                <button
                    [matMenuTriggerFor]="$debugMenu()"
                    [matMenuTriggerData]="{onClick: props.onCreate}"
                    [matTooltip]="props.createTooltip"
                    class="{{ props.buttonClass }} cm-unavailable"
                >
                    @if (props.icon) {
                        <i [class]="props.icon"></i>
                    }
                    <span class="label">{{ props.label }}</span>
                    <i class="fa-regular fa-play"></i>
                </button>
            } @else {
                <button [matTooltip]="props.createTooltip" class="{{ props.buttonClass }} cm-unavailable" disableAfterClick (click)="props.onCreate()">
                    @if (props.icon) {
                        <i [class]="props.icon"></i>
                    }
                    <span class="label">{{ props.label }}</span>
                    <i class="fa-regular fa-play"></i>
                </button>
            }
        }
        @case ("loading") {
            <button
                [matTooltip]="props.cancelTooltip"
                class="{{ props.buttonClass }}"
                disableAfterClick
                (click)="props.canCancel ? props.onCancel?.() : undefined"
                [disabled]="!props.canCancel"
            >
                @if (props.icon) {
                    <i [class]="props.icon"></i>
                }
                <span class="label">{{ props.label }}</span>
                @if (props.canCancel) {
                    <i class="fa-regular fa-stop"></i>
                }
                <cm-loading-spinner-icon></cm-loading-spinner-icon>
            </button>
        }
        @case ("failed") {
            @if (props.buttonClass === "cm-text-button") {
                <button [matTooltip]="props.retryTooltip" class="cm-text-button overflow-ellipsis" disableAfterClick (click)="props.onRetry()">
                    @if (props.icon) {
                        <i [class]="props.icon"></i>
                    }
                    <span class="label overflow-ellipsis">{{ props.label }}</span>
                    <i class="fa-regular fa-exclamation-triangle"></i>
                    <i class="fa-regular fa-rotate"></i>
                </button>
                <div class="cm-inline-actions">
                    <button class="cm-text-button delete-button" (click)="props.onRemove?.()" [matTooltip]="props.removeTooltip">
                        <i class="fa-regular fa-trash"></i>
                    </button>
                </div>
            } @else {
                <button
                    [matTooltip]="props.retryTooltip"
                    [matTooltipDisabled]="outerTooltipDisabled"
                    class="cm-button"
                    disableAfterClick
                    (click)="props.onRetry()"
                >
                    <div class="cm-inline-buttons">
                        <div class="cm-main-action">
                            @if (props.icon) {
                                <i [class]="props.icon"></i>
                            }
                            <span class="label">{{ props.label }}</span>
                            <i class="fa-regular fa-exclamation-triangle"></i>
                            <i class="fa-regular fa-rotate"></i>
                        </div>
                        <div class="cm-inline-actions">
                            <button
                                class="cm-text-button delete-button"
                                (click)="$event.stopPropagation(); props.onRemove?.()"
                                [matTooltip]="props.removeTooltip"
                                (mouseleave)="outerTooltipDisabled = false"
                                (mouseover)="outerTooltipDisabled = true"
                            >
                                <i class="fa-regular fa-trash"></i>
                            </button>
                        </div>
                    </div>
                </button>
            }
        }
        @case ("complete") {
            @if (props.buttonClass === "cm-text-button") {
                <div class="cm-inline-buttons">
                    <button
                        [matMenuTriggerFor]="$imageDownloadMenu()"
                        [matMenuTriggerData]="{dataObject: props.result}"
                        [matTooltip]="props.downloadTooltip"
                        class="cm-text-button overflow-ellipsis"
                        (click)="!$imageDownloadMenu() ? props.onDownload?.() : undefined"
                    >
                        @if (props.icon) {
                            <i [class]="props.icon"></i>
                        }
                        <span class="label overflow-ellipsis">{{ props.label }}</span>
                        <i class="fa-regular fa-arrow-down-to-line"></i>
                    </button>
                    <div class="cm-inline-actions">
                        @if (props.canPreview) {
                            <button class="cm-text-button" (click)="props.onPreview?.()" [matTooltip]="props.previewTooltip">
                                <i class="fa-regular fa-magnifying-glass"></i>
                            </button>
                        }
                        @if (props.canRemove) {
                            <button class="cm-text-button delete-button" (click)="props.onRemove?.()" [matTooltip]="props.removeTooltip">
                                <i class="fa-regular fa-trash"></i>
                            </button>
                        }
                        @if (props.canEdit) {
                            <button class="cm-text-button" (click)="props.onEdit?.()" [matTooltip]="props.editTooltip">
                                <i class="fa-regular fa-wrench"></i>
                            </button>
                        }
                        @if (props.hasUpdate) {
                            <button class="cm-text-button info" [matTooltip]="props.updateTooltip">
                                <i class="fa-regular fa-circle-exclamation"></i>
                            </button>
                        }
                    </div>
                </div>
            } @else {
                <!-- The current design forces us to nest buttons. Redesign if possible -->
                <button
                    [matMenuTriggerFor]="$imageDownloadMenu()"
                    [matMenuTriggerData]="{dataObject: props.result}"
                    [matTooltip]="props.downloadTooltip"
                    [matTooltipDisabled]="outerTooltipDisabled"
                    class="cm-button"
                    (click)="!$imageDownloadMenu() ? props.onDownload?.() : undefined"
                >
                    <div class="cm-inline-buttons">
                        <div class="cm-main-action">
                            @if (props.icon) {
                                <i [class]="props.icon"></i>
                            }
                            <span class="label">{{ props.label }}</span>
                            <i class="fa-regular fa-arrow-down-to-line"></i>
                        </div>
                        <div class="cm-inline-actions">
                            @if (props.canPreview) {
                                <button
                                    class="cm-text-button"
                                    (click)="$event.stopPropagation(); props.onPreview?.()"
                                    [matTooltip]="props.previewTooltip"
                                    (mouseleave)="outerTooltipDisabled = false"
                                    (mouseover)="outerTooltipDisabled = true"
                                >
                                    <i class="fa-regular fa-magnifying-glass"></i>
                                </button>
                            }
                            @if (props.canRemove) {
                                <button
                                    class="cm-text-button delete-button"
                                    (click)="$event.stopPropagation(); props.onRemove?.()"
                                    [matTooltip]="props.removeTooltip"
                                    (mouseleave)="outerTooltipDisabled = false"
                                    (mouseover)="outerTooltipDisabled = true"
                                >
                                    <i class="fa-regular fa-trash"></i>
                                </button>
                            }
                            @if (props.canEdit) {
                                <button
                                    class="cm-text-button"
                                    (click)="$event.stopPropagation(); props.onEdit?.()"
                                    [matTooltip]="props.editTooltip"
                                    (mouseleave)="outerTooltipDisabled = false"
                                    (mouseover)="outerTooltipDisabled = true"
                                >
                                    <i class="fa-regular fa-wrench"></i>
                                </button>
                            }
                            @if (props.hasUpdate) {
                                <button class="cm-text-button info" [matTooltip]="props.updateTooltip">
                                    <i class="fa-regular fa-circle-exclamation"></i>
                                </button>
                            }
                        </div>
                    </div>
                </button>
            }
        }
    }
}
