<cm-routed-dialog [dialogSize]="dialogSizes.Large" [dialogScroll]="false" [needsConfirmationToClose]="$templateGraphModified()">
    <ng-container cm-overlay-content>
        @if ($templateRevision(); as templateRevision) {
            @if ($templateGraph(); as templateGraph) {
                <cm-template-edit
                    [title]="templateRevision.template.name ?? undefined"
                    [templateGraph]="templateGraph"
                    [defaultCustomerId]="templateRevision.template.organization.legacyId"
                    [templateId]="templateRevision.template.id"
                    [templateRevisionId]="templateRevision.id"
                    (templateGraphModified)="handleTemplateGraphModified($event)"
                    (requestSave)="handleSave($event)"
                ></cm-template-edit>
            } @else {
                <cm-loading-spinner>
                    <ng-container cm-text-bottom>
                        <div class="cm-loading-text">Loading Template Graph...</div>
                    </ng-container>
                </cm-loading-spinner>
            }
        } @else {
            <cm-loading-spinner>
                <ng-container cm-text-bottom>
                    <div class="cm-loading-text">Loading Template Revision...</div>
                </ng-container>
            </cm-loading-spinner>
        }
    </ng-container>
</cm-routed-dialog>
