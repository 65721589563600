import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type VerifyExternalLoginMutationVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
  provider: Types.LoginProvider;
}>;


export type VerifyExternalLoginMutation = { __typename: 'Mutation', verifyExternalLogin: { __typename: 'LoginResult', token: string, user: { __typename: 'User', id: string, legacyId: number } } };

export const VerifyExternalLoginDocument = gql`
    mutation verifyExternalLogin($code: String!, $provider: LoginProvider!) {
  verifyExternalLogin(input: {code: $code, provider: $provider}) {
    token
    user {
      id
      legacyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class VerifyExternalLoginGQL extends Apollo.Mutation<VerifyExternalLoginMutation, VerifyExternalLoginMutationVariables> {
    override document = VerifyExternalLoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }