import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { BasicTagInfoFragmentDoc } from '../../../../common/services/tags/tags.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PicturesGridItemFragment = { __typename: 'Picture', id: string, legacyId: number, name: string, state: Types.PictureState, priorityOrder: any, nextActor: Types.NextActor, number: number, downloadUrl: string, assetInfos: { __typename: 'PictureAssetInfos', completed: number, new: number, readyForShading: number, review: number, shading: number }, organization: { __typename: 'Organization', id: string, name?: string | null }, set: { __typename: 'Set', id: string, name: string, number: number, project: { __typename: 'Project', name: string, number: number, organization: { __typename: 'Organization', id: string } } }, template?: { __typename: 'Template', id: string, latestRevision?: { __typename: 'TemplateRevision', id: string, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'PictureRevision', id: string, number: number } | null, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } }> };

export type PicturesGridPictureDataQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type PicturesGridPictureDataQuery = { __typename: 'Query', pictureRevision: { __typename: 'PictureRevision', pictureData: Array<{ __typename: 'DataObjectAssignment', dataObject: { __typename: 'DataObject', id: string, legacyId: number, originalFileName: string } }> } };

export type PicturesGridUpdateDataObjectMutationVariables = Types.Exact<{
  input: Types.MutationUpdateDataObjectInput;
}>;


export type PicturesGridUpdateDataObjectMutation = { __typename: 'Mutation', updateDataObject: { __typename: 'DataObject', id: string } };

export type PicturesGridCreatePictureMutationVariables = Types.Exact<{
  input: Types.MutationCreatePictureInput;
}>;


export type PicturesGridCreatePictureMutation = { __typename: 'Mutation', createPicture: { __typename: 'Picture', id: string, legacyId: number, name: string, state: Types.PictureState, priorityOrder: any, nextActor: Types.NextActor, number: number, downloadUrl: string, assetInfos: { __typename: 'PictureAssetInfos', completed: number, new: number, readyForShading: number, review: number, shading: number }, organization: { __typename: 'Organization', id: string, name?: string | null }, set: { __typename: 'Set', id: string, name: string, number: number, project: { __typename: 'Project', name: string, number: number, organization: { __typename: 'Organization', id: string } } }, template?: { __typename: 'Template', id: string, latestRevision?: { __typename: 'TemplateRevision', id: string, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'PictureRevision', id: string, number: number } | null, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } }> } };

export type PicturesGridCreateTemplateMutationVariables = Types.Exact<{
  input: Types.MutationCreateTemplateInput;
}>;


export type PicturesGridCreateTemplateMutation = { __typename: 'Mutation', createTemplate: { __typename: 'Template', id: string } };

export type PicturesGridItemsQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.PictureFilterInput>;
  orderBy?: Types.InputMaybe<Array<Types.PictureOrderByInput> | Types.PictureOrderByInput>;
}>;


export type PicturesGridItemsQuery = { __typename: 'Query', picturesCount: number, pictures: Array<{ __typename: 'Picture', id: string, legacyId: number, name: string, state: Types.PictureState, priorityOrder: any, nextActor: Types.NextActor, number: number, downloadUrl: string, assetInfos: { __typename: 'PictureAssetInfos', completed: number, new: number, readyForShading: number, review: number, shading: number }, organization: { __typename: 'Organization', id: string, name?: string | null }, set: { __typename: 'Set', id: string, name: string, number: number, project: { __typename: 'Project', name: string, number: number, organization: { __typename: 'Organization', id: string } } }, template?: { __typename: 'Template', id: string, latestRevision?: { __typename: 'TemplateRevision', id: string, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'PictureRevision', id: string, number: number } | null, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } }> } | null> };

export type PicturesGridUpdatePictureMutationVariables = Types.Exact<{
  input: Types.MutationUpdatePictureInput;
}>;


export type PicturesGridUpdatePictureMutation = { __typename: 'Mutation', updatePicture: { __typename: 'Picture', id: string, legacyId: number, name: string, state: Types.PictureState, priorityOrder: any, nextActor: Types.NextActor, number: number, downloadUrl: string, assetInfos: { __typename: 'PictureAssetInfos', completed: number, new: number, readyForShading: number, review: number, shading: number }, organization: { __typename: 'Organization', id: string, name?: string | null }, set: { __typename: 'Set', id: string, name: string, number: number, project: { __typename: 'Project', name: string, number: number, organization: { __typename: 'Organization', id: string } } }, template?: { __typename: 'Template', id: string, latestRevision?: { __typename: 'TemplateRevision', id: string, renderedImages: Array<{ __typename: 'DataObject', id: string }> } | null } | null, galleryImage?: { __typename: 'DataObject', id: string } | null, latestRevision?: { __typename: 'PictureRevision', id: string, number: number } | null, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null, tagAssignments: Array<{ __typename: 'TagAssignment', id: string, tag: { __typename: 'Tag', id: string, legacyId: number, color?: string | null, description?: string | null, name: string, type: Types.TagType, createdBy: { __typename: 'User', id: string }, organization?: { __typename: 'Organization', id: string } | null } }> } };

export type PicturesGridDeletePictureMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type PicturesGridDeletePictureMutation = { __typename: 'Mutation', deletePicture?: any | null };

export type PicturesGridCreatePictureRevisionMutationVariables = Types.Exact<{
  input: Types.MutationCreatePictureRevisionInput;
}>;


export type PicturesGridCreatePictureRevisionMutation = { __typename: 'Mutation', createPictureRevision: { __typename: 'PictureRevision', id: string } };

export type PicturesGridBatchUpdatePicturesMutationVariables = Types.Exact<{
  addTagId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  assignUserId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  nextActor?: Types.InputMaybe<Types.NextActor>;
  filter: Types.PictureFilterInput;
  paymentState?: Types.InputMaybe<Types.PaymentState>;
  removeTagId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  removeUserAssignment?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  state?: Types.InputMaybe<Types.PictureState>;
}>;


export type PicturesGridBatchUpdatePicturesMutation = { __typename: 'Mutation', batchUpdatePictures: number };

export type PicturesGridSetForPicturesQueryVariables = Types.Exact<{
  setLegacyId: Types.Scalars['Int']['input'];
}>;


export type PicturesGridSetForPicturesQuery = { __typename: 'Query', set: { __typename: 'Set', id: string } };

export type PicturesGridProjectForPicturesQueryVariables = Types.Exact<{
  projectLegacyId: Types.Scalars['Int']['input'];
}>;


export type PicturesGridProjectForPicturesQuery = { __typename: 'Query', project: { __typename: 'Project', id: string } };

export type PicturesGridUserFragment = { __typename: 'User', id: string, name: string };

export type PicturesGridVisibleUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PicturesGridVisibleUsersQuery = { __typename: 'Query', users: Array<{ __typename: 'User', id: string, name: string } | null> };

export type CreateTemplateRevisionMutationVariables = Types.Exact<{
  input: Types.MutationCreateTemplateRevisionInput;
}>;


export type CreateTemplateRevisionMutation = { __typename: 'Mutation', createTemplateRevision: { __typename: 'TemplateRevision', id: string, legacyId: number } };

export type TemplateWithLatestRevisionFragment = { __typename: 'Template', state: Types.TemplateState, type: Types.TemplateType, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string } | null } | null };

export type GetTemplateWithLatestRevisionQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetTemplateWithLatestRevisionQuery = { __typename: 'Query', template: { __typename: 'Template', state: Types.TemplateState, type: Types.TemplateType, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, createdAt: string, graph?: any | null, createdBy?: { __typename: 'User', id: string } | null } | null } };

export const PicturesGridItemFragmentDoc = gql`
    fragment PicturesGridItem on Picture {
  __typename
  id
  legacyId
  name
  state
  priorityOrder
  nextActor
  number
  assetInfos {
    completed
    new
    readyForShading
    review
    shading
  }
  organization {
    id
    name
  }
  set {
    id
    name
    number
    project {
      name
      number
      organization {
        id
      }
    }
  }
  template {
    id
    latestRevision {
      id
      renderedImages(take: 4) {
        id
      }
    }
  }
  downloadUrl
  galleryImage {
    id
  }
  latestRevision {
    id
    number
  }
  taskInfos {
    inProgress
    waitingForFeedback
    completed
    archived
  }
  currentUserAssignment {
    id
    firstName
    lastName
    galleryImage {
      id
    }
  }
  tagAssignments {
    id
    tag {
      ...BasicTagInfo
    }
  }
}
    `;
export const PicturesGridUserFragmentDoc = gql`
    fragment PicturesGridUser on User {
  id
  name
}
    `;
export const TemplateWithLatestRevisionFragmentDoc = gql`
    fragment TemplateWithLatestRevision on Template {
  state
  type
  latestRevision {
    id
    legacyId
    createdAt
    graph
    createdBy {
      id
    }
  }
}
    `;
export const PicturesGridPictureDataDocument = gql`
    query picturesGridPictureData($id: ID!) {
  pictureRevision(id: $id) {
    pictureData: dataObjectAssignments(
      filter: {assignmentType: PictureData}
      take: 1
    ) {
      dataObject {
        id
        legacyId
        originalFileName
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridPictureDataGQL extends Apollo.Query<PicturesGridPictureDataQuery, PicturesGridPictureDataQueryVariables> {
    override document = PicturesGridPictureDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridUpdateDataObjectDocument = gql`
    mutation picturesGridUpdateDataObject($input: MutationUpdateDataObjectInput!) {
  updateDataObject(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridUpdateDataObjectGQL extends Apollo.Mutation<PicturesGridUpdateDataObjectMutation, PicturesGridUpdateDataObjectMutationVariables> {
    override document = PicturesGridUpdateDataObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridCreatePictureDocument = gql`
    mutation picturesGridCreatePicture($input: MutationCreatePictureInput!) {
  createPicture(input: $input) {
    ...PicturesGridItem
  }
}
    ${PicturesGridItemFragmentDoc}
${BasicTagInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridCreatePictureGQL extends Apollo.Mutation<PicturesGridCreatePictureMutation, PicturesGridCreatePictureMutationVariables> {
    override document = PicturesGridCreatePictureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridCreateTemplateDocument = gql`
    mutation picturesGridCreateTemplate($input: MutationCreateTemplateInput!) {
  createTemplate(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridCreateTemplateGQL extends Apollo.Mutation<PicturesGridCreateTemplateMutation, PicturesGridCreateTemplateMutationVariables> {
    override document = PicturesGridCreateTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridItemsDocument = gql`
    query picturesGridItems($skip: Int, $take: Int, $filter: PictureFilterInput, $orderBy: [PictureOrderByInput!]) {
  pictures(skip: $skip, take: $take, filter: $filter, orderBy: $orderBy) {
    ...PicturesGridItem
  }
  picturesCount(filter: $filter)
}
    ${PicturesGridItemFragmentDoc}
${BasicTagInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridItemsGQL extends Apollo.Query<PicturesGridItemsQuery, PicturesGridItemsQueryVariables> {
    override document = PicturesGridItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridUpdatePictureDocument = gql`
    mutation picturesGridUpdatePicture($input: MutationUpdatePictureInput!) {
  updatePicture(input: $input) {
    ...PicturesGridItem
  }
}
    ${PicturesGridItemFragmentDoc}
${BasicTagInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridUpdatePictureGQL extends Apollo.Mutation<PicturesGridUpdatePictureMutation, PicturesGridUpdatePictureMutationVariables> {
    override document = PicturesGridUpdatePictureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridDeletePictureDocument = gql`
    mutation picturesGridDeletePicture($id: ID!) {
  deletePicture(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridDeletePictureGQL extends Apollo.Mutation<PicturesGridDeletePictureMutation, PicturesGridDeletePictureMutationVariables> {
    override document = PicturesGridDeletePictureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridCreatePictureRevisionDocument = gql`
    mutation picturesGridCreatePictureRevision($input: MutationCreatePictureRevisionInput!) {
  createPictureRevision(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridCreatePictureRevisionGQL extends Apollo.Mutation<PicturesGridCreatePictureRevisionMutation, PicturesGridCreatePictureRevisionMutationVariables> {
    override document = PicturesGridCreatePictureRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridBatchUpdatePicturesDocument = gql`
    mutation picturesGridBatchUpdatePictures($addTagId: ID, $assignUserId: ID, $nextActor: NextActor, $filter: PictureFilterInput!, $paymentState: PaymentState, $removeTagId: ID, $removeUserAssignment: Boolean, $state: PictureState) {
  batchUpdatePictures(
    filter: $filter
    addTagId: $addTagId
    assignUserId: $assignUserId
    nextActor: $nextActor
    paymentState: $paymentState
    removeTagId: $removeTagId
    removeUserAssignment: $removeUserAssignment
    state: $state
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridBatchUpdatePicturesGQL extends Apollo.Mutation<PicturesGridBatchUpdatePicturesMutation, PicturesGridBatchUpdatePicturesMutationVariables> {
    override document = PicturesGridBatchUpdatePicturesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridSetForPicturesDocument = gql`
    query picturesGridSetForPictures($setLegacyId: Int!) {
  set(legacyId: $setLegacyId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridSetForPicturesGQL extends Apollo.Query<PicturesGridSetForPicturesQuery, PicturesGridSetForPicturesQueryVariables> {
    override document = PicturesGridSetForPicturesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridProjectForPicturesDocument = gql`
    query picturesGridProjectForPictures($projectLegacyId: Int!) {
  project(legacyId: $projectLegacyId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridProjectForPicturesGQL extends Apollo.Query<PicturesGridProjectForPicturesQuery, PicturesGridProjectForPicturesQueryVariables> {
    override document = PicturesGridProjectForPicturesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PicturesGridVisibleUsersDocument = gql`
    query picturesGridVisibleUsers {
  users(filter: {visibleInFilters: true}, take: 9999) {
    ...PicturesGridUser
  }
}
    ${PicturesGridUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PicturesGridVisibleUsersGQL extends Apollo.Query<PicturesGridVisibleUsersQuery, PicturesGridVisibleUsersQueryVariables> {
    override document = PicturesGridVisibleUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTemplateRevisionDocument = gql`
    mutation createTemplateRevision($input: MutationCreateTemplateRevisionInput!) {
  createTemplateRevision(input: $input) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTemplateRevisionGQL extends Apollo.Mutation<CreateTemplateRevisionMutation, CreateTemplateRevisionMutationVariables> {
    override document = CreateTemplateRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTemplateWithLatestRevisionDocument = gql`
    query getTemplateWithLatestRevision($id: ID!) {
  template(id: $id) {
    ...TemplateWithLatestRevision
  }
}
    ${TemplateWithLatestRevisionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateWithLatestRevisionGQL extends Apollo.Query<GetTemplateWithLatestRevisionQuery, GetTemplateWithLatestRevisionQueryVariables> {
    override document = GetTemplateWithLatestRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }