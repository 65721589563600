import {Injector} from "@angular/core"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {deserializeNodeGraph} from "@cm/graph"
import {srgbToLinearNoClip} from "@cm/image-processing/tone-mapping"
import {ShaderBallPostRenderStepInput, shaderBallPostRenderStepTask} from "@cm/job-nodes/asset-rendering"
import {CreateJobGraphData, JobNodes} from "@cm/job-nodes/job-nodes"
import {cmRenderTaskForPassNames, renderTaskQueueDomain} from "@cm/job-nodes/rendering"
import {Matrix4} from "@cm/math"
import {
    MaterialReference,
    TemplateGraph,
    Nodes,
    Camera,
    defaultsForToneMapping,
    AreaLight,
    HDRILight,
    SceneProperties,
    Render,
    TemplateInstance,
    Parameters,
    uuid4,
    isMaterialInput,
    SceneNodes,
    isTemplateLike,
    isTemplateNode,
} from "@cm/template-nodes"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {LegacyId} from "@common/helpers/rendering/material/material-assets-rendering/common"
import {GetTemplateDetailsForMaterialAssetsRenderingGQL} from "@common/helpers/rendering/material/material-assets-rendering/template.generated"
import {combinedPassFromRenderTask} from "@common/helpers/rendering/rendering"
import {JobGraphMaterial} from "@common/models/material-assets-rendering/job-graph-material"
import {Settings} from "@common/models/settings/settings"

const SHADER_BALL_TEMPLATE_ID = Settings.MATERIAL_ASSETS_RENDERING_SHADER_BALL_TEMPLATE_ID
const SHADER_BALL_HDRI_ID = Settings.MATERIAL_ASSETS_RENDERING_SHADER_BALL_HDRI_ID

const loadShaderBallTemplate = async (injector: Injector) => {
    const getTemplateDetailsForMaterialAssetsRendering = injector.get(GetTemplateDetailsForMaterialAssetsRenderingGQL)
    const templateDetails = (await fetchThrowingErrors(getTemplateDetailsForMaterialAssetsRendering)({legacyId: SHADER_BALL_TEMPLATE_ID})).template
    if (!templateDetails.latestRevision) throw new Error("Missing latest revision!")
    if (!templateDetails.latestRevision.graph) throw new Error("Missing graph in latest revision")

    const template = deserializeNodeGraph(templateDetails.latestRevision.graph)
    if (!isTemplateNode(template) || !isTemplateLike(template)) throw new Error("Expected template-like graph")

    return new TemplateInstance({
        name: "Shader ball Template",
        id: uuid4(),
        template,
        parameters: new Parameters({}),
        visible: true,
    })
}

function scaleDownTo1(color: number[]): number[] {
    const max = Math.max(...color)
    return max <= 1 ? color : color.map((x) => x / max)
}

export async function setupTemplateGraphForShaderBallRender(
    materialRevisionId: LegacyId,
    resolutionX: number,
    resolutionY: number,
    samples: number,
    sensorSize: number,
    injector: Injector,
    useGpu: boolean,
    useCloud: boolean,
    sceneManagerService: SceneManagerService,
): Promise<SceneNodes.SceneNode[]> {
    const FROM_BLENDER_CS = new Matrix4([1, 0, 0, 0, 0, 0, 1, 0, 0, -1, 0, 0, 0, 0, 0, 1])

    const BLENDER_CAMERA_MATRIX = new Matrix4([
        0.9999978542327881, -0.0009130112011916935, 0.0018655003514140844, 0.11999999405816197, 0.0020769403781741858, 0.4395934045314789, -0.8981944918632507,
        -133.542001247406, 0.0, 0.8981963992118835, 0.4395943284034729, 78.78099679946899, 0.0, 0.0, 0.0, 1.0,
    ])

    const BLENDER_AREA_LIGHT_MATRIX = new Matrix4([
        -0.7071067690849304, -3.0908619663705394e-8, -0.7071067690849304, -69.81599926948547, -0.7071067690849304, -9.272586254382986e-8, 0.7071067690849304,
        52.6229977607727, -8.742277657347586e-8, 1.0, 4.371138828673793e-8, 39.56499993801117, 0.0, 0.0, 0.0, 1.0,
    ])
    const BLENDER_AREA_LIGHT_COLOR = scaleDownTo1([1.00245, 0.98751, 1.11557].map(srgbToLinearNoClip)) // output from blackbody node, T = 7047

    const BLENDER_AREA_LIGHT_001_MATRIX = new Matrix4([
        1.0, 0.0, 0.0, -0.6610000040382147, 0.0, 1.0, 0.0, -27.23200023174286, 0.0, 0.0, 1.0, 83.31499695777893, 0.0, 0.0, 0.0, 1.0,
    ])

    const CAMERA_MATRIX = BLENDER_CAMERA_MATRIX.multiply(FROM_BLENDER_CS)

    const graph = new TemplateGraph({
        name: "Untitled Scene",
        nodes: new Nodes({
            list: [
                new Camera({
                    name: "Camera01",
                    resolutionX: resolutionX,
                    resolutionY: resolutionY,
                    lockedTransform: CAMERA_MATRIX.transpose().toArray(),
                    target: [0, 0, 0],
                    filmGauge: sensorSize,
                    focalLength: 80,
                    focalDistance: 140,
                    fStop: 10000, // aperture size = 0 in blender scene
                    shiftX: 0,
                    shiftY: 0,
                    automaticVerticalTilt: false,
                    enablePanning: true,
                    screenSpacePanning: true,
                    minDistance: 10,
                    maxDistance: 10000,
                    minPolarAngle: 0,
                    maxPolarAngle: 180,
                    minAzimuthAngle: -Infinity,
                    maxAzimuthAngle: Infinity,
                    targeted: false,
                    autoFocus: false,
                    toneMapping: defaultsForToneMapping("linear"),
                    ev: Math.log2(1),
                    visible: true,
                }),
                new AreaLight({
                    name: "AreaLight01",
                    intensity: 3.2,
                    width: 40,
                    height: 40,
                    color: BLENDER_AREA_LIGHT_COLOR as [number, number, number],
                    lockedTransform: BLENDER_AREA_LIGHT_MATRIX.multiply(FROM_BLENDER_CS).transpose().toArray(),
                    target: [0, 0, 0],
                    on: true,
                    directionality: 0.0,
                    visibleDirectly: false,
                    visibleInReflections: true,
                    visibleInRefractions: true,
                    targeted: false,
                    transparent: false,
                    lightType: "Corona",
                    visible: true,
                }),
                new AreaLight({
                    name: "AreaLight02",
                    intensity: 4.1,
                    width: 73.92,
                    height: 15.1,
                    color: [1, 1, 1],
                    lockedTransform: BLENDER_AREA_LIGHT_001_MATRIX.multiply(FROM_BLENDER_CS).transpose().toArray(),
                    target: [0, 0, 0],
                    on: true,
                    directionality: 0.0,
                    visibleDirectly: false,
                    visibleInReflections: true,
                    visibleInRefractions: true,
                    targeted: false,
                    transparent: false,
                    lightType: "Corona",
                    visible: true,
                }),
                new HDRILight({
                    name: "hdriLight01",
                    intensity: 1.25,
                    rotation: [0, 205, 0],
                    mirror: true,
                    hdriId: SHADER_BALL_HDRI_ID,
                }),
                new SceneProperties({
                    backgroundColor: [1, 1, 1],
                    maxSubdivisionLevel: 3,
                    uiStyle: "default",
                    uiColor: [0, 0, 0],
                    maxSubdivisionLevelOnMobile: 9999,
                    iconSize: 24,
                    enableAr: false,
                    enableSalesEnquiry: false,
                    textureResolution: "2000px",
                    textureFiltering: false,
                    enableRealtimeShadows: false,
                    enableRealtimeLights: false,
                    enableRealtimeMaterials: true,
                    enableOnboardingHint: false,
                    enableGltfDownload: false,
                    enableStlDownload: false,
                    enablePdfGeneration: false,
                    enableSnapshot: false,
                    enableFullscreen: false,
                    environmentMapMode: "full",
                    showAnnotations: false,
                    enableAdaptiveSubdivision: false,
                    enableDimensionGuides: false,
                    verticalArPlacement: false,
                }),
                new Render({
                    width: resolutionX,
                    height: resolutionY,
                    samples: samples,
                    gpu: useGpu,
                    cloud: useCloud,
                }),
                await loadShaderBallTemplate(injector),
            ],
        }),
    })

    sceneManagerService.$templateGraph.set(graph)
    sceneManagerService.compileTemplate()
    await sceneManagerService.sync(true)

    const materialInputs = sceneManagerService.getDescriptors().filter((descriptor) => isMaterialInput(descriptor))
    if (materialInputs.length !== 1) throw new Error("Expected exactly one material input")

    sceneManagerService.setTemplateParameter(materialInputs[0].props.id, new MaterialReference({name: "Material reference", materialRevisionId}))
    await sceneManagerService.sync(true)

    return sceneManagerService.$scene()
}

export function jobGraphFn_shaderBall(
    renderGraphDataObjectId: number,
    materialDetails: JobGraphMaterial,
    useGpu: boolean,
    useCloud: boolean,
): CreateJobGraphData {
    // TODO: need to make these types more precise: JSON graph !== deduplicated DAG (!)
    const inputPlaceholder = {}
    const image = {
        type: "convert",
        dataType: "float32",
        channelLayout: "RGBA",
        input: {
            type: "adjustExposure",
            input: {
                type: "input",
                image: inputPlaceholder,
            },
            ev: -0.7,
        },
    }

    const renderTask = JobNodes.task(cmRenderTaskForPassNames("Combined"), {input: JobNodes.input(), queueDomain: renderTaskQueueDomain(useGpu, useCloud)})

    const postRenderTask = JobNodes.task(shaderBallPostRenderStepTask, {
        input: JobNodes.struct({
            renderImage: combinedPassFromRenderTask(renderTask),
            imageProcessingGraph: JobNodes.value({
                inputPlaceholder,
                output: image,
            }),
            materialId: JobNodes.value(materialDetails.legacyId),
            customerId: JobNodes.value(materialDetails.organization.legacyId),
            renderFileName: JobNodes.value("Thumbnail - " + materialDetails.name + ".exr"),
        }) as JobNodes.TypedDataNode<ShaderBallPostRenderStepInput>,
    })

    return JobNodes.jobGraph(postRenderTask, {
        progress: {
            type: "progressGroup",
            items: [
                {node: renderTask, factor: 18},
                {node: postRenderTask, factor: 1},
            ],
        },
        input: {
            renderGraph: JobNodes.dataObjectReference(renderGraphDataObjectId),
            customerId: materialDetails.organization.legacyId,
        },
        platformVersion: Settings.APP_VERSION,
    })
}
