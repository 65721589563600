<svg
    [attr.viewBox]="'0 0 ' + $width() + ' ' + $height()"
    [attr.width]="$width()"
    [attr.height]="$height()"
    class="cm-curve-container"
    xmlns="http://www.w3.org/2000/svg"
    (document:mousemove)="onMouseMove($event); $event.stopPropagation()"
    (document:mouseup)="onMouseUp($event); $event.stopPropagation()"
    #curveContainer
>
    <clipPath id="clipRgb">
        <rect width="100%" height="100%" />
    </clipPath>
    <path [attr.d]="$path()" clip-path="url(#clipRgb)" stroke="black" stroke-width="1" fill="none" />
    <path
        (click)="addControlPoint($event)"
        [attr.d]="$path()"
        class="cm-curve"
        clip-path="url(#clipRgb)"
        stroke="transparent"
        stroke-width="4"
        fill="none"
        pointer-events="stroke"
    />
    <svg class="cm-background">
        <path class="cm-curve" [attr.d]="'M 0 ' + $height() + ' L ' + $width() + ' 0'" stroke="#6e6e6e" stroke-width="0.25" fill="none" />
    </svg>
    @for (controlPoint of $controlPoints(); track $index) {
        <ng-container>
            <circle
                #circle
                (mousedown)="onMouseDown($event, $index); $event.stopPropagation()"
                [attr.cx]="controlPoint.x * $width()"
                [attr.cy]="(1 - controlPoint.y) * $height()"
                [class.cm-active-circle]="$index === $selectedPoint()"
                class="cm-circle"
                r="2"
                fill="black"
            />
        </ng-container>
    }
</svg>
@if ($selectedPoint(); as selectedPoint) {
    @if ($controlPoints()[selectedPoint]; as controlPoint) {
        <div class="cm-coordinates">
            <div>x: {{ controlPoint.x | number: "1.2-2" }}</div>
            <div>y: {{ controlPoint.y | number: "1.2-2" }}</div>
            <i class="far fa-trash cm-remove" (click)="onControlPointRemoved.emit(selectedPoint); $selectedPoint.set(undefined)"></i>
        </div>
    }
}
