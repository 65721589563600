import {Component, DestroyRef, inject, Inject, OnInit} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MAT_DIALOG_DATA} from "@angular/material/dialog"
import {MatTableModule} from "@angular/material/table"
import {ContentTypeModel, ScanSubJobState} from "@generated"
import {
    ScanJobDialogGQL,
    ScanJobDialogSubJobGQL,
    ScanJobDialogUpdateSubJobGQL,
    ScanJobDialogSubJobFragment,
} from "@app/textures/texture-group-details/scan-job-dialog/scan-job-dialog.generated"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {FormatDatePipe} from "@common/pipes/format-date/format-date.pipe"
import {UtilsService} from "@legacy/helpers/utils"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {Labels} from "@labels"
import {FilesService} from "@common/services/files/files.service"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {switchMap} from "rxjs"
import {TypedMatCellDefDirective} from "@app/common/directives"

@Component({
    selector: "cm-scan-job-dialog",
    templateUrl: "./scan-job-dialog.component.html",
    styleUrls: ["./scan-job-dialog.component.scss"],
    imports: [MatTableModule, MatCheckboxModule, FormsModule, StateLabelComponent, FormatDatePipe, TypedMatCellDefDirective],
})
export class ScanJobDialogComponent implements OnInit {
    displayedColumns: string[] = ["id", "state", "active", "progress", "started", "completed", "duration", "tile_x", "tile_y", "log", "fitting", "assembly"]

    readonly permission = inject(PermissionsService)
    readonly destroyRef = inject(DestroyRef)
    readonly notifications = inject(NotificationsService)
    readonly refresh = inject(RefreshService)
    readonly utils = inject(UtilsService)
    $can = this.permission.$to
    readonly scanJobDialog = inject(ScanJobDialogGQL)
    readonly scanJobDialogSubJob = inject(ScanJobDialogSubJobGQL)
    readonly scanJobDialogUpdateSubJob = inject(ScanJobDialogUpdateSubJobGQL)

    scanJobId: string
    scanSubJobs: ScanJobDialogSubJobFragment[]

    constructor(@Inject(MAT_DIALOG_DATA) public data: {scanJobId: string}) {
        this.scanJobId = data.scanJobId
        this.scanSubJobs = []
    }

    ngOnInit(): void {
        this.refresh
            .keepFetched$(this.scanJobId, ContentTypeModel.ScanJob, () => fetchThrowingErrors(this.scanJobDialog)({id: this.scanJobId}))
            .pipe(
                switchMap(async (scanJob) => {
                    return Promise.all(
                        scanJob?.subJobs?.map((subJob) =>
                            fetchThrowingErrors(this.scanJobDialogSubJob)({scanSubJobId: subJob.id}).then(({scanSubJob}) => scanSubJob),
                        ) || [],
                    )
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(async (subJobs) => {
                this.scanSubJobs = subJobs
            })
    }

    refreshSubJobs() {
        this.refresh.item({id: this.scanJobId, __typename: ContentTypeModel.ScanJob})
    }

    updateItem(input: {active?: boolean; state?: ScanSubJobState}, scanSubJobId: string) {
        void this.notifications.withUserFeedback(
            async () => {
                await mutateThrowingErrors(this.scanJobDialogUpdateSubJob)({
                    input: {
                        active: input.active,
                        id: scanSubJobId,
                        state: input.state,
                    },
                })
                this.scanSubJobs = this.scanSubJobs.map((subJob) => {
                    if (subJob.id === scanSubJobId) {
                        return {...subJob, active: input.active ?? subJob.active, state: input.state ?? subJob.state}
                    }
                    return subJob
                })
            },
            {
                success: "Changes saved",
                error: "Cannot save changes",
            },
        )
    }

    downloadLog(scanSubJob: ScanJobDialogSubJobFragment): void {
        const url = scanSubJob.logAssignments?.[0].dataObject.downloadUrl
        if (url) {
            FilesService.downloadFile("test", url)
        }
    }

    downloadFitting(scanSubJob: ScanJobDialogSubJobFragment): void {
        const url = scanSubJob.fittingAssignments?.[0].dataObject.downloadUrl
        if (url) {
            FilesService.downloadFile("test", url)
        }
    }

    downloadAssembly(scanSubJob: ScanJobDialogSubJobFragment): void {
        const url = scanSubJob.assemblyAssignments?.[0].dataObject.downloadUrl
        if (url) {
            FilesService.downloadFile("test", url)
        }
    }

    protected readonly Labels = Labels
}
