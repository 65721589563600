import {HalContext} from "@common/models/hal/hal-context"
import {HalPainterImageCompositor} from "@common/models/hal/hal-painter-image-compositor"
import {ImageCacheWebGL2} from "app/textures/texture-editor/operator-stack/image-op-system/detail/image-cache-webgl2"
import {ImagePtrWebGl2, ImageWebGL2} from "app/textures/texture-editor/operator-stack/image-op-system/image-webgl2"
import {DrawableImageCache} from "app/textures/texture-editor/operator-stack/image-op-system/detail/drawable-image-cache"
import {HalPainterImageBlit} from "@common/models/hal/common/hal-painter-image-blit"
import {TextureEditorSettings} from "@app/textures/texture-editor/texture-editor-settings"
import {TexturesApiService} from "@app/textures/service/textures-api.service"
import {createHalPainterImageCompositor} from "@common/models/hal/hal-painter-image-compositor/create"
import {HalPainterPrimitive} from "@common/models/hal/hal-painter-primitive"
import {createHalPainterPrimitive} from "@common/models/hal/hal-painter-primitive/create"
import {
    ImageDescriptor,
    ImageRef,
    ImageRefId,
    makeImageRef,
    ManagedImageRef,
    RefCountedImageRef,
    resolveOriginalImageRef,
} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpContextBase} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-base"
import {ImageOpCommandQueueWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-webgl2"
import {PainterRef, PainterRefByType, PainterType} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/painter-ref"
import {assertNever} from "@cm/utils"
import {ImageDataType} from "@generated"
import {HalGeometry} from "@common/models/hal/hal-geometry"
import {createHalGeometry} from "@common/models/hal/hal-geometry/create"

const TRACE = TextureEditorSettings.EnableFullTrace

export class ImageOpContextWebGL2 extends ImageOpContextBase {
    constructor(
        readonly halContext: HalContext,
        readonly imageCacheWebGL2: ImageCacheWebGL2,
        readonly texturesApi?: TexturesApiService,
        drawableImageCache?: DrawableImageCache,
    ) {
        super("preview", drawableImageCache)
        this.inlineGeometry = createHalGeometry(halContext)
        this.halBlitter = new HalPainterImageBlit(halContext)
    }

    dispose(): void {
        this.inlineGeometry.dispose()
        this.halBlitter.dispose()
        // release all remaining images
        for (const image of this.temporaryImageByImageRefId.values()) {
            if (image instanceof Promise) {
                image.then((img) => img.release())
            } else {
                image?.release()
            }
        }
        this.temporaryImageByImageRefId.clear()
        // release painters
        for (const imageBlitter of this.imageBlitterByBlittingFunction.values()) {
            imageBlitter.dispose()
        }
        this.imageBlitterByBlittingFunction.clear()
        for (const imageCompositor of this.imageCompositorByCompositingFunction.values()) {
            imageCompositor.dispose()
        }
        this.imageCompositorByCompositingFunction.clear()
        for (const primitivePainter of this.primitivePainterByShadingFunction.values()) {
            primitivePainter.dispose()
        }
        this.primitivePainterByShadingFunction.clear()
    }

    createCommandQueue(): ImageOpCommandQueueWebGL2 {
        return new ImageOpCommandQueueWebGL2(this)
    }

    async flush(waitForCompletion: boolean): Promise<void> {
        await this.halContext.flush(waitForCompletion)
    }

    createPainter<T extends PainterType>(type: T, name: string, source?: string): PainterRefByType<T> {
        let painterRefBySource = this.painterRefBySourceByType.get(type)
        if (!painterRefBySource) {
            painterRefBySource = new Map<string | undefined, PainterRef>()
            this.painterRefBySourceByType.set(type, painterRefBySource)
        }
        const painterRef = painterRefBySource.get(source)
        if (painterRef) {
            if (painterRef.type !== type) {
                throw new Error(`Painter type mismatch. Expected ${type}, but got ${painterRef.type}`)
            }
            return painterRef as PainterRefByType<T>
        } else {
            const id = this.nextPainterRefId++
            const painterRef: PainterRefByType<T> = {isPainterRef: true, type, name, id} as PainterRefByType<T> // TODO why is this cast necessary ?
            painterRefBySource.set(source, painterRef)
            this.painterSourceById.set(id, source)
            return painterRef
        }
    }

    releasePainter(_painter: PainterRef): void {
        // TODO implement
    }

    async getPainter<T extends PainterType>(painterRef: PainterRefByType<T>): Promise<PainterByType<T>> {
        let painterData = this.painterDataById.get(painterRef.id)
        if (!painterData) {
            const source = this.painterSourceById.get(painterRef.id)
            if (!source) {
                throw new Error(`Painter source not found for painter ${painterRef.id}`)
            }
            switch (painterRef.type) {
                case "blitter":
                    painterData = {type: "blitter", painter: await this.getOrCreateImageBlitter(source)}
                    break
                case "compositor":
                    painterData = {type: "compositor", painter: await this.getOrCreateImageCompositor(source)}
                    break
                case "primitive":
                    painterData = {type: "primitive", painter: await this.getOrCreatePrimitivePainter(source)}
                    break
                default:
                    assertNever(painterRef)
            }
            this.painterDataById.set(painterRef.id, painterData)
        }
        return painterData.painter as PainterByType<T>
    }

    private async getOrCreateImageBlitter(blittingFunction: string | undefined): Promise<HalPainterImageBlit> {
        let imageBlitter = this.imageBlitterByBlittingFunction.get(blittingFunction)
        if (!imageBlitter) {
            imageBlitter = new HalPainterImageBlit(this.halContext, blittingFunction)
            this.imageBlitterByBlittingFunction.set(blittingFunction, imageBlitter)
        }
        return imageBlitter
    }

    private async getOrCreateImageCompositor(compositingFunction: string | undefined): Promise<HalPainterImageCompositor> {
        let imageCompositor = this.imageCompositorByCompositingFunction.get(compositingFunction)
        if (!imageCompositor) {
            imageCompositor = createHalPainterImageCompositor(this.halContext, compositingFunction)
            this.imageCompositorByCompositingFunction.set(compositingFunction, imageCompositor)
        }
        return imageCompositor
    }

    private async getOrCreatePrimitivePainter(shadingFunction: string): Promise<HalPainterPrimitive> {
        let primitivePainter = this.primitivePainterByShadingFunction.get(shadingFunction)
        if (!primitivePainter) {
            primitivePainter = createHalPainterPrimitive(this.halContext, shadingFunction)
            this.primitivePainterByShadingFunction.set(shadingFunction, primitivePainter)
        }
        return primitivePainter
    }

    createTemporaryImage(descriptor: ImageDescriptor): ImageRef {
        if (descriptor.width < 0 || descriptor.height < 0) {
            throw Error("Image dimensions must be positive.")
        }
        if (!Number.isInteger(descriptor.width) || !Number.isInteger(descriptor.height)) {
            throw Error("Image dimensions must be integers.")
        }
        const imageRef = makeImageRef("temporary", this.fetchUniqueImageRefId(), descriptor, "ImageOpContextWebGL2.createTemporaryImage")
        this.temporaryImageByImageRefId.set(imageRef.id, null)
        return imageRef
    }

    getImage(imageRef: ImageRef): Promise<ImagePtrWebGl2> {
        switch (imageRef.addressSpace) {
            case "temporary":
                return this.getTemporaryImage(imageRef)
            case "drawable":
                if (imageRef.view) {
                    throw new Error("Drawable image view not supported")
                }
                if (typeof imageRef.id !== "number") {
                    throw new Error(`Drawable image id must be a number, but got ${imageRef.id}`)
                }
                return this.getDrawableImage(imageRef.id).then((drawableImage) => new ImagePtrWebGl2(drawableImage))
            case "data-object":
                if (imageRef.view) {
                    throw new Error("Data-object image view not supported")
                }
                if (typeof imageRef.id !== "string") {
                    throw new Error(`Data object id must be a string, but got ${imageRef.id}`)
                }
                return this.getDataObjectImage(imageRef.id)
            default:
                throw new Error(`Unknown address space: ${imageRef.addressSpace}`)
        }
    }

    async createDataObjectImageRef(dataObjectId: string): Promise<ManagedImageRef> {
        if (!this.texturesApi) {
            throw new Error("Textures API not available and therefore cannot create data-object image ref")
        }
        let imageRef = this.dataObjectImageRefByDataObjectId.get(dataObjectId)
        let managedImageRef: ManagedImageRef
        if (!imageRef) {
            // create and load the image descriptor
            if (TRACE) {
                console.log(`ImageOpContextWebGL2: creating data-object image-ref ${dataObjectId}`)
            }
            const promisedImageRef = this.texturesApi.getDataObjectImageDescriptor(dataObjectId).then((dataObjectImageDescriptor) => {
                const descriptor: ImageDescriptor = {
                    width: dataObjectImageDescriptor.width,
                    height: dataObjectImageDescriptor.height,
                    channelLayout: "RGB", // TODO this is a guess
                    dataType: dataObjectImageDescriptor.imageDataType === ImageDataType.Color ? "uint8srgb" : "uint8", // uint8 because we load a jpg
                }
                return new RefCountedImageRef(
                    "data-object",
                    dataObjectId,
                    descriptor,
                    () => this.disposeDataObjectImageRef(dataObjectId),
                    "ImageOpContextWebGL2.createDataObjectImageRef",
                )
            })
            this.dataObjectImageRefByDataObjectId.set(dataObjectId, promisedImageRef)
            imageRef = await promisedImageRef
            this.dataObjectImageRefByDataObjectId.set(dataObjectId, imageRef)
            managedImageRef = new ManagedImageRef(imageRef)
            imageRef.release()
        } else if (imageRef instanceof Promise) {
            imageRef = await imageRef
            managedImageRef = new ManagedImageRef(imageRef)
        } else {
            managedImageRef = new ManagedImageRef(imageRef)
        }
        return managedImageRef
    }

    private disposeDataObjectImageRef(dataObjectId: string) {
        if (!this.dataObjectImageRefByDataObjectId.has(dataObjectId)) {
            throw new Error(`Data object ${dataObjectId} not found`)
        }
        if (TRACE) {
            console.log(`ImageOpContextWebGL2: disposing data-object image-ref ${dataObjectId}`)
        }
        this.dataObjectImageRefByDataObjectId.delete(dataObjectId)
        const image = this.dataObjectImageByDataObjectId.get(dataObjectId)
        if (image) {
            this.dataObjectImageByDataObjectId.delete(dataObjectId)
            if (image instanceof Promise) {
                image.then((img) => img.release())
            } else {
                image.release()
            }
        }
    }

    private getTemporaryImage(imageRef: ImageRef): Promise<ImagePtrWebGl2> {
        const image = this.temporaryImageByImageRefId.get(imageRef.id)
        if (image === undefined) {
            throw new Error(`Temporary image ${imageRef.id} not found`)
        }
        if (image === null) {
            // this is only a placeholder thus far; let's create the actual image now
            const originalImageRef = resolveOriginalImageRef(imageRef)
            const halPaintableImage = this.imageCacheWebGL2.getImage(originalImageRef.descriptor)
            const promisedImage = new ImageWebGL2(
                "temporary",
                imageRef.id,
                () => {
                    if (!this.temporaryImageByImageRefId.has(imageRef.id)) {
                        throw new Error(`Temporary image ${imageRef.id} not found during release`)
                    }
                    this.temporaryImageByImageRefId.delete(imageRef.id)
                    this.imageCacheWebGL2.releaseImage(halPaintableImage)
                },
                halPaintableImage,
                {
                    ...halPaintableImage.descriptor,
                    batching: imageRef.descriptor.batching,
                },
            )
            this.temporaryImageByImageRefId.set(imageRef.id, promisedImage)
            const imagePtr = new ImagePtrWebGl2(promisedImage)
            promisedImage.release()
            return Promise.resolve(imagePtr)
        } else {
            return Promise.resolve(new ImagePtrWebGl2(image))
        }
    }

    private getDrawableImage(id: number): Promise<ImageWebGL2> {
        if (!this.drawableImageCache) {
            throw new Error("Drawable image cache not available")
        }
        return this.drawableImageCache.getImageWebGL2(id)
    }

    private getDataObjectImage(dataObjectId: string): Promise<ImagePtrWebGl2> {
        const texturesApi = this.texturesApi
        if (!texturesApi) {
            throw new Error("Textures API not available")
        }
        const image = this.dataObjectImageByDataObjectId.get(dataObjectId)
        if (!image) {
            if (TextureEditorSettings.EnableAllocTrace) {
                console.log(`ImageOpContextWebGL2: loading data-object image ${dataObjectId}`)
            }
            const promisedImage = texturesApi
                .createHalImageFromDataObject(this.imageCacheWebGL2, dataObjectId, {
                    downloadFormat: "jpg",
                    preferredOutputFormat: "uint8",
                })
                .then(
                    (halImage) =>
                        new ImageWebGL2(
                            "data-object",
                            dataObjectId,
                            () => {
                                this.imageCacheWebGL2.releaseImage(halImage)
                                if (TextureEditorSettings.EnableAllocTrace) {
                                    console.log(`ImageOpContextWebGL2: disposing data-object image ${dataObjectId}`)
                                }
                            },
                            halImage,
                            {
                                ...halImage.descriptor,
                            },
                            `getDataObjectImage`,
                        ),
                )
            this.dataObjectImageByDataObjectId.set(dataObjectId, promisedImage)
            return promisedImage.then((image) => {
                this.dataObjectImageByDataObjectId.set(dataObjectId, image)
                return new ImagePtrWebGl2(image)
            })
        } else if (image instanceof Promise) {
            return image.then((img) => new ImagePtrWebGl2(img))
        } else {
            return Promise.resolve(new ImagePtrWebGl2(image))
        }
    }

    get blitter(): HalPainterImageBlit {
        return this.halBlitter
    }

    readonly inlineGeometry: HalGeometry
    private halBlitter: HalPainterImageBlit
    private temporaryImageByImageRefId = new Map<ImageRefId, ImageWebGL2 | null>()
    private dataObjectImageRefByDataObjectId = new Map<string, RefCountedImageRef | Promise<RefCountedImageRef>>()
    private dataObjectImageByDataObjectId = new Map<string, ImageWebGL2 | Promise<ImageWebGL2>>()
    private imageBlitterByBlittingFunction = new Map<string | undefined, HalPainterImageBlit>()
    private imageCompositorByCompositingFunction = new Map<string | undefined, HalPainterImageCompositor>()
    private primitivePainterByShadingFunction = new Map<string | undefined, HalPainterPrimitive>()
    private painterRefBySourceByType = new Map<PainterType, Map<string | undefined, PainterRef>>()
    private painterSourceById = new Map<number, string | undefined>()
    private painterDataById = new Map<number, PainterData>()
    private nextPainterRefId = 1
}

export type PainterDataBlitter = {type: "blitter"; painter: HalPainterImageBlit}
export type PainterDataCompositor = {type: "compositor"; painter: HalPainterImageCompositor}
export type PainterDataPrimitive = {type: "primitive"; painter: HalPainterPrimitive}
export type PainterData = PainterDataBlitter | PainterDataCompositor | PainterDataPrimitive

export type PainterByType<T extends PainterType> = T extends "blitter"
    ? PainterDataBlitter
    : T extends "compositor"
      ? HalPainterImageCompositor
      : HalPainterPrimitive
