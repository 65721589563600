import {computed, effect, inject, Injectable, OnDestroy, signal} from "@angular/core"
import {BrowserWebSocketImplementation, createWebSocketMessagingConnection} from "@cm/browser-utils"
import {connectRenderEngineMessaging, gatherDataObjectReferences, RenderEngineMessagingConnection, RendererImage, RenderNodes} from "@cm/render-nodes"
import {SceneNodes} from "@cm/template-nodes/interfaces/scene-object"
import {buildRenderGraph} from "@cm/template-nodes/render-graph/scene-graph-to-render-graph"
import {contentTypeForFilenameOrNull} from "@cm/utils/content-types"
import {TypedImageData} from "@cm/utils/typed-image-data"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {GetDataObjectDetailsGQL, HdriForRenderingGQL} from "@common/services/rendering/rendering.generated"

const DEBUG_WITH_STANDALONE_RENDER_ENGINE = false
const STANDALONE_RENDER_ENGINE_URL = "ws://127.0.0.1:17093" // cmRenderEngine
const INTERACTIVE_RENDER_SERVER_URL = "ws://127.0.0.1:17095" // colormass Render Server app
const UPDATE_INTERVAL_MS = 500
const CONNECT_INTERVAL_MS = 3000
const USE_GPU_DEFAULT = true
const MAX_MESSAGE_SIZE_BYTES = 1024 * 1024 * 1024

type LegacyId = number

type PreviewConfig = {
    highQuality: boolean
    useGPU: boolean
    width: number
    height: number
    samples: number
    pass?: RenderNodes.PassName
}

class PromiseCache<K, V> extends Map<K, Promise<V>> {
    constructor() {
        super()
    }

    getOrResolve(key: K, fn: (key: K) => Promise<V>): Promise<V> {
        let promise = super.get(key)
        if (!promise) {
            promise = fn(key)
            super.set(key, promise)
        }
        return promise
    }
}

function computeGraphHash(_graph: RenderNodes.Render): string | undefined {
    // return hashObject(graph)
    return undefined
}

function rendererImageToTypedImageData(image: RendererImage): TypedImageData {
    return {
        data: image.data,
        width: image.width,
        height: image.height,
        channelLayout: (() => {
            switch (image.components) {
                case 1:
                    return "L"
                case 3:
                    return "RGB"
                case 4:
                    return "RGBA"
                default:
                    throw Error("Unsupported number of components")
            }
        })(),
        dataType: image.dataType,
        colorSpace: "linear",
    }
}

function resolveDataObjectContentType(dataObject: {id: string; originalFileName: string; mediaType?: string | null}): string {
    const contentType = (dataObject.originalFileName ? contentTypeForFilenameOrNull(dataObject.originalFileName) : null) ?? dataObject.mediaType
    if (!contentType) throw Error(`Could not resolve content type for DataObject ${dataObject.id}`)
    return contentType
}

@Injectable()
export class LocalPreviewRenderingService implements OnDestroy {
    private $renderer = signal<RenderEngineMessagingConnection<BrowserWebSocketImplementation> | null>(null)
    private lastProgress: number | undefined = undefined
    private timerId!: ReturnType<typeof setInterval>
    private hdriDataObjectIdCache = new PromiseCache<LegacyId, LegacyId>()
    private dataObjectSendCache = new PromiseCache<LegacyId, void>()
    private lastGraphHash: string | undefined = undefined
    private $_numPendingTasks = signal<number>(0)
    private state: "Disconnected" | "Connecting" | "Idle" | "Building" | "FetchingData" | "Updating" | "FetchingStatus" | "FetchingPreview" | "Disconnecting" =
        "Disconnected"
    private updatePending = false
    private lastConnectionAttempt: Date | undefined = undefined

    // inputs signals:
    $enable = signal<boolean>(false)
    $sceneNodes = signal<SceneNodes.SceneNode[] | undefined>(undefined)
    $cameraOverride = signal<SceneNodes.Camera | undefined>(undefined)
    $highQuality = signal<boolean>(false)
    $useGPU = signal<boolean>(USE_GPU_DEFAULT)
    $size = signal<[number, number] | undefined>(undefined)
    $samples = signal<number>(128)
    $pass = signal<RenderNodes.PassName>("Combined")

    // output signals:
    $statusMessage = signal<string>("")
    $progress = signal<number | null>(null)
    $previewImageData = signal<TypedImageData | null>(null)
    $connected = signal<boolean>(false)
    $numPendingTasks = this.$_numPendingTasks.asReadonly()

    readonly availablePasses: {name: string; value: RenderNodes.PassName}[] = [
        {name: "Combined", value: "Combined"},
        {name: "Diffuse Direct", value: "DiffuseDirect"},
        {name: "Diffuse Indirect", value: "DiffuseIndirect"},
        {name: "Diffuse Color", value: "DiffuseColor"},
        {name: "Glossy Direct", value: "GlossyDirect"},
        {name: "Glossy Indirect", value: "GlossyIndirect"},
        {name: "Glossy Color", value: "GlossyColor"},
        {name: "Transmission Direct", value: "TransmissionDirect"},
        {name: "Transmission Indirect", value: "TransmissionIndirect"},
        {name: "Transmission Color", value: "TransmissionColor"},
        {name: "Emission", value: "Emission"},
    ]

    private $config = computed(() => {
        const size = this.$size()
        if (!size) return undefined
        return {
            highQuality: this.$highQuality(),
            useGPU: this.$useGPU(),
            width: size[0],
            height: size[1],
            samples: this.$samples(),
            pass: this.$pass(),
        }
    })

    readonly getDataObjectDetails = inject(GetDataObjectDetailsGQL)
    readonly hdriForRendering = inject(HdriForRenderingGQL)

    constructor() {
        effect(() => {
            if (this.$enable()) {
                this.$sceneNodes() // dependency
                this.$config() // dependency
                this.$cameraOverride() // dependency
                this.update()
            }
        })

        this.timerId = setInterval(() => this.onUpdateTimer(), UPDATE_INTERVAL_MS)

        effect(() => {
            console.log("Renderer status:", this.$statusMessage())
        })
        // effect(() => {
        //     console.log("Progress:", this.$progress())
        // })
    }

    ngOnDestroy() {
        clearInterval(this.timerId)
        this.disconnect()
    }

    private changeState(newState: typeof LocalPreviewRenderingService.prototype.state) {
        // console.log("Changed state from", this.state, "to", newState)
        this.state = newState
        switch (newState) {
            case "Disconnected":
            case "Connecting":
                this.$connected.set(false)
                break
            default:
                this.$connected.set(true)
                break
        }
    }

    private connect() {
        switch (this.state) {
            case "Disconnected":
                break
            default:
                return
        }
        this.lastConnectionAttempt = new Date()
        this.changeState("Connecting")
        const url = DEBUG_WITH_STANDALONE_RENDER_ENGINE ? STANDALONE_RENDER_ENGINE_URL : INTERACTIVE_RENDER_SERVER_URL
        connectRenderEngineMessaging(createWebSocketMessagingConnection, console)(url, MAX_MESSAGE_SIZE_BYTES).then(
            (renderer) => {
                if (this.state !== "Connecting") {
                    return
                }
                renderer.close$.then(() => {
                    // console.log("Renderer closed")
                    this.changeState("Disconnected")
                    this.$statusMessage.set("")
                    this.lastProgress = undefined
                    this.$progress.set(null)
                    this.$previewImageData.set(null)
                    this.$renderer.set(null)
                    this.dataObjectSendCache.clear()
                    this.lastConnectionAttempt = new Date()
                })
                this.$renderer.set(renderer)
                this.$statusMessage.set("")
                this.$progress.set(null)
                this.lastProgress = undefined
                this.$previewImageData.set(null)
                this.dataObjectSendCache.clear()
                this.updatePending = true
                this.checkForPendingUpdateOrReturnToIdle()
            },
            (_err) => {
                this.changeState("Disconnected")
                // console.error("Failed to connect to renderer:", err)
            },
        )
    }

    private disconnect() {
        if (this.state === "Disconnected") return
        this.changeState("Disconnecting")
        this.$renderer()?.disconnect()
    }

    private incrementPendingTasks() {
        this.$_numPendingTasks.set(this.$_numPendingTasks() + 1)
    }

    private decrementPendingTasks() {
        this.$_numPendingTasks.set(this.$_numPendingTasks() - 1)
    }

    async clearCaches() {
        this.dataObjectSendCache.clear()
        await this.$renderer()?.clearCaches()
    }

    private async fetchDataObjectData(dataObjectLegacyId: LegacyId) {
        const dataObject = (await fetchThrowingErrors(this.getDataObjectDetails)({legacyId: dataObjectLegacyId})).dataObject
        const resp = await fetch(dataObject.downloadUrl)
        return {
            buffer: await resp.arrayBuffer(),
            contentType: resolveDataObjectContentType(dataObject),
        }
    }

    private async preloadDataObjects(dataObjectLegacyIds: LegacyId[]): Promise<void> {
        await Promise.all(
            dataObjectLegacyIds.map((legacyId) =>
                this.dataObjectSendCache.getOrResolve(legacyId, async (dataObjectLegacyId) => {
                    this.incrementPendingTasks()
                    const dataObject = await this.fetchDataObjectData(dataObjectLegacyId)
                    const renderer = this.$renderer()
                    if (!renderer) {
                        this.decrementPendingTasks()
                        throw Error("No renderer session")
                    }
                    await renderer.cacheEntity({
                        type: "dataObject",
                        dataObjectId: dataObjectLegacyId,
                        data: new Uint8Array(dataObject.buffer),
                        contentType: dataObject.contentType,
                    })
                    this.decrementPendingTasks()
                }),
            ),
        )
    }

    private checkForPendingUpdateOrReturnToIdle() {
        // note: if the socket was closed while we were fetching data, the state will already be set to "Disconnected"
        if (this.state === "Disconnected") return
        this.changeState("Idle")
        if (this.updatePending) {
            this.updatePending = false
            this.update()
        }
    }

    private onUpdateTimer() {
        if (this.$enable()) {
            const renderer = this.$renderer()
            switch (this.state) {
                case "Disconnected":
                    if (!this.lastConnectionAttempt || new Date().getTime() - this.lastConnectionAttempt.getTime() > CONNECT_INTERVAL_MS) {
                        this.connect()
                    }
                    break
                case "Idle":
                    if (renderer) {
                        this.changeState("FetchingStatus")
                        renderer
                            .getStatus()
                            .then((status) => {
                                this.$statusMessage.set(status.message)
                                this.$progress.set(status.progress)
                                if (status.progress !== this.lastProgress) {
                                    this.lastProgress = status.progress
                                    if (status.progress > 0 && !this.updatePending) {
                                        this.changeState("FetchingPreview")
                                        renderer
                                            .getPreviewImage("raw")
                                            .then((image) => {
                                                if (image && !this.updatePending) {
                                                    this.$previewImageData.set(rendererImageToTypedImageData(image))
                                                } else {
                                                    this.$previewImageData.set(null)
                                                }
                                                this.checkForPendingUpdateOrReturnToIdle()
                                            })
                                            .catch((err) => {
                                                console.error("Failed to get preview image:", err)
                                                this.checkForPendingUpdateOrReturnToIdle()
                                            })
                                    } else {
                                        this.checkForPendingUpdateOrReturnToIdle()
                                    }
                                } else {
                                    this.checkForPendingUpdateOrReturnToIdle()
                                }
                            })
                            .catch((err) => {
                                console.error("Failed to get status:", err)
                                this.checkForPendingUpdateOrReturnToIdle()
                            })
                    }
                    break
                default:
                    break
            }
        } else {
            switch (this.state) {
                case "Disconnected":
                case "Disconnecting":
                    break
                default:
                    this.disconnect()
                    break
            }
        }
    }

    private overrideSceneNodes(nodes: SceneNodes.SceneNode[], config: PreviewConfig, camera?: SceneNodes.Camera): SceneNodes.SceneNode[] {
        const newNodes: SceneNodes.SceneNode[] = [
            {
                id: "previewRenderSettings",
                topLevelObjectId: "previewRenderSettings",
                type: "RenderSettings",
                width: config.width,
                height: config.height,
                samples: config.samples,
                gpu: config.useGPU,
                cloud: false,
            },
        ]

        //Preview should always have exposure 1, as post-processing effects are applied in the viewer in realtime
        const exposureOneCamera = (camera: SceneNodes.Camera): SceneNodes.Camera => {
            const {exposure: _exposure, ...rest} = camera
            return {
                ...rest,
                exposure: 1,
            }
        }

        let haveCamera = false
        if (camera) {
            newNodes.push(exposureOneCamera(camera))
            haveCamera = true
        }
        for (const node of nodes) {
            if (SceneNodes.RenderSettings.is(node)) {
                continue
            } else if (SceneNodes.Camera.is(node)) {
                if (haveCamera) continue
                newNodes.push(exposureOneCamera(node))
                haveCamera = true
            } else {
                newNodes.push(node)
            }
        }
        return newNodes
    }

    private overrideRenderNodes(root: RenderNodes.Render, config: PreviewConfig): RenderNodes.Render {
        return {
            ...root,
            width: Math.round(config.width),
            height: Math.round(config.height),
            samples: Math.round(config.samples),
            session: {
                ...root.session,
                options: {
                    ...root.session.options,
                    gpu: config.useGPU,
                    passes: [config.pass ?? "Combined"],
                    previewPass: config.pass,
                },
            },
        }
    }

    private async update() {
        if (this.state !== "Idle") {
            this.updatePending = true
            return
        }
        try {
            const nodes = this.$sceneNodes()
            const config = this.$config()
            const camera = this.$cameraOverride()
            const renderer = this.$renderer()
            if (!(renderer && nodes && config)) {
                return
            }
            this.changeState("Building")
            const resolveFns = {
                getHdriDataObjectIdDetails: async (hdriIdDetails: {legacyId: LegacyId}): Promise<{legacyId: LegacyId}> => {
                    const dataObjectId = await this.hdriDataObjectIdCache.getOrResolve(hdriIdDetails.legacyId, async (hdriLegacyId) => {
                        const dataObjectDetails = (await fetchThrowingErrors(this.hdriForRendering)({legacyId: hdriLegacyId})).hdri.dataObject
                        if (!dataObjectDetails) throw Error("Failed to query hdri data object id details")
                        return dataObjectDetails.legacyId
                    })
                    return {legacyId: dataObjectId}
                },
            }
            // make sure to disable schema verification, as this significantly slows down updates
            const mainRenderGraph = this.overrideRenderNodes(
                await buildRenderGraph(
                    {
                        nodes: this.overrideSceneNodes(nodes, config, camera),
                        final: config.highQuality,
                        verifySchema: false,
                    },
                    resolveFns,
                ),
                config,
            )
            const graphHash = computeGraphHash(mainRenderGraph)
            if (graphHash !== this.lastGraphHash || graphHash === undefined) {
                this.lastGraphHash = graphHash
                const dataObjectsIds = gatherDataObjectReferences(mainRenderGraph)
                this.changeState("FetchingData")
                await this.preloadDataObjects(dataObjectsIds)
                this.changeState("Updating")
                this.$previewImageData.set(null)
                await renderer.update(mainRenderGraph)
            }
        } catch (err) {
            console.error("Failed to update render:", err)
        }
        this.checkForPendingUpdateOrReturnToIdle()
    }
}
