<cm-sidebar-layout>
    <cm-item-filters cm-filters>
        <cm-item-filters>
            <cm-search-filter placeholder="Name, ..."></cm-search-filter>
        </cm-item-filters>
    </cm-item-filters>
    <cm-infinite-list listType="grid" [count]="$totalCount()" [intersectionStatusChange]="pageFilledStatusChange" class="cm-items-list">
        <cm-list-info cm-list-header [parentList]="this" [count]="$totalCount() ?? 0" [createItem]="openNewItemDialog.bind(this)"></cm-list-info>
        @for (item of $items(); track item.trackId) {
            @if (item.data; as organization) {
                <cm-entity-card @fadeInCard [entity]="organization" [entityLink]="[organization.id]" [defaultSubtitle]="false">
                    <ng-container cm-custom-subtitle>
                        <div class="cm-url">
                            {{ organization.url }}
                        </div>
                    </ng-container>
                </cm-entity-card>
            } @else if (item.placeholder) {
                <cm-card-placeholder @fadeInPlaceholder></cm-card-placeholder>
            } @else if (item.error) {
                <cm-card-error @fadeInPlaceholder></cm-card-error>
            }
        }
    </cm-infinite-list>
</cm-sidebar-layout>
<router-outlet></router-outlet>
<ng-template #newItemDialog>
    <h2 mat-dialog-title>New Organization</h2>
    <mat-dialog-content [style.width]="'350px'">
        <form class="cm-new-item-form">
            <mat-form-field appearance="fill">
                <input matInput placeholder="Name" [(ngModel)]="newItemData.name" name="name" />
            </mat-form-field>
            <mat-form-field appearance="fill">
                <input matInput placeholder="URL" [(ngModel)]="newItemData.url" name="name" />
            </mat-form-field>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-button [mat-dialog-close]="newItemData" [disabled]="!newItemData.name || !newItemData.url">Create</button>
    </mat-dialog-actions>
</ng-template>
