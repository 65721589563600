import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PictureArStatisticsQueryVariables = Types.Exact<{
  filter: Types.ArStatisticsFilterInput;
}>;


export type PictureArStatisticsQuery = { __typename: 'Query', arStatisticsItemsCount: number };

export type PictureSceneStatisticsQueryVariables = Types.Exact<{
  filter: Types.SceneStatisticsFilterInput;
}>;


export type PictureSceneStatisticsQuery = { __typename: 'Query', sceneStatisticsItemsCount: number };

export const PictureArStatisticsDocument = gql`
    query pictureArStatistics($filter: ArStatisticsFilterInput!) {
  arStatisticsItemsCount(filter: $filter)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureArStatisticsGQL extends Apollo.Query<PictureArStatisticsQuery, PictureArStatisticsQueryVariables> {
    override document = PictureArStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PictureSceneStatisticsDocument = gql`
    query pictureSceneStatistics($filter: SceneStatisticsFilterInput!) {
  sceneStatisticsItemsCount(filter: $filter)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PictureSceneStatisticsGQL extends Apollo.Query<PictureSceneStatisticsQuery, PictureSceneStatisticsQueryVariables> {
    override document = PictureSceneStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }