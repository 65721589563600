import {TemplateListNode} from "#template-nodes/declare-template-node"
import {
    isMaterialLike,
    isNodeOwner,
    isOutput,
    isTemplateContainer,
    isTemplateOwningContainer,
    isTemplateReferenceContainer,
    isValue,
    node,
    Node,
    NodeOwner,
    Switch,
    TemplateContainer,
    TemplateOwningContainer,
} from "#template-nodes/node-types"
import {LodType} from "#template-nodes/nodes/lod-type"
import {MaterialAssignment, MaterialAssignments} from "#template-nodes/nodes/material-assignment"
import {isNamedNode} from "#template-nodes/nodes/named-node"
import {Nodes} from "#template-nodes/nodes/nodes"
import {Parameters} from "#template-nodes/nodes/parameters"
import {RigidRelation} from "#template-nodes/nodes/rigid-relation"
import {TemplateGraph} from "#template-nodes/nodes/template-graph"
import {TemplateInstance} from "#template-nodes/nodes/template-instance"
import {getLodTypeDescription, isTemplateNode, TemplateNode} from "#template-nodes/types"
import {TraversalAction} from "@cm/graph"
import {isNodeGraphInstance, mapGraphParameters, NodeParameters, traverseGraphParameters} from "@cm/graph/node-graph"
import {ensureValidParameters} from "@cm/graph/validation"
import {isClass} from "@cm/utils"
import * as changeCase from "change-case"

export const getTemplateNodeClassLabel = (node: TemplateNode | string) => {
    return changeCase.capitalCase(typeof node === "string" ? node : node.getNodeClass())
}

export const getTemplateNodeLabel = (node: TemplateNode | null): string => {
    if (!node) return "???"

    if (isNamedNode(node) && node.parameters.name.length > 0) return node.parameters.name

    if (node instanceof RigidRelation) return `Align [${getTemplateNodeLabel(node.parameters.targetA)}] to [${getTemplateNodeLabel(node.parameters.targetB)}]`
    else if (isValue(node)) return `Value: ${JSON.stringify(node.parameters.value)}`
    else if (node instanceof LodType) return `LOD Type: ${getLodTypeDescription(node.parameters.lodType)}`

    return getTemplateNodeClassLabel(node)
}

export const getTemplateSwitchItemLabel = (node: Switch) => {
    return getTemplateNodeClassLabel(node).replace(" Switch", "")
}

export function getUniqueTemplateNodeParent(node: TemplateNode, callback?: (parent: TemplateNode) => void): TemplateNode {
    if (node.parents.size === 1) {
        const [parent] = node.parents

        if (isTemplateNode(parent)) {
            if (callback) callback(parent)
            return parent
        }
    }

    throw new Error("Node has invalid number of node parents")
}

export function getNodeOwner(node: Node | Nodes, callback?: (nodeOwner: NodeOwner) => void): NodeOwner | null {
    if (node instanceof Nodes) {
        const nodeOwner = getUniqueTemplateNodeParent(node)
        if (!isNodeOwner(nodeOwner)) throw new Error("Nodes has no node parent")
        if (callback) callback(nodeOwner)
        return nodeOwner
    }

    const nodesParents = [...node.parents].filter((nodes): nodes is Nodes => nodes instanceof Nodes)
    if (nodesParents.length === 0) return null
    else if (nodesParents.length === 1) {
        const [nodes] = nodesParents
        return getNodeOwner(nodes, callback)
    } else throw new Error("Node has multiple node parents")
}

export function expandTemplateNodesToDelete(nodes: Set<TemplateNode>) {
    const nodesToDelete = new Set<TemplateNode>()
    const collectNodesToDelete = (node: TemplateNode) => {
        nodesToDelete.add(node)
        if (isNodeOwner(node)) {
            nodesToDelete.add(node.parameters.nodes)
            node.parameters.nodes.parameters.list.forEach(collectNodesToDelete)
        }
    }
    nodes.forEach(collectNodesToDelete)

    return nodesToDelete
}

export type ReferencingDeletedTemplateNodes = Map<TemplateNode, Map<string, TemplateNode>>

export function getReferencingDeletedTemplateNodes(templateGraph: TemplateGraph, nodes: Set<TemplateNode>) {
    const nodesToDelete = expandTemplateNodesToDelete(nodes)

    const nodesToDeleteOwningParentContainers = new Set<TemplateOwningContainer>()
    nodesToDelete.forEach((node) => {
        node.parents.forEach((parent) => {
            if (isTemplateNode(parent) && isTemplateOwningContainer(parent) && !nodesToDelete.has(parent)) nodesToDeleteOwningParentContainers.add(parent)
        })
    })

    const addTemplateNodeReference = (referencingDeletedPaths: ReferencingDeletedTemplateNodes, node: TemplateNode, path: string, reference: TemplateNode) => {
        const existing = referencingDeletedPaths.get(node)
        if (existing) existing.set(path, reference)
        else {
            const newMap = new Map<string, TemplateNode>()
            newMap.set(path, reference)
            referencingDeletedPaths.set(node, newMap)
        }
    }

    const passReferenceDeleteToParentLibrary: {
        appliesTo: (node: TemplateNode) => boolean
        passChildReferenceDeleteToParent: (child: TemplateNode) => boolean
        mapToParentParamaters: (node: TemplateNode) => ReferencingDeletedTemplateNodes
    }[] = [
        {
            appliesTo: (node) => node instanceof MaterialAssignment,
            passChildReferenceDeleteToParent: (child) => isMaterialLike(child),
            mapToParentParamaters: (node) => {
                const parent = getUniqueTemplateNodeParent(node)
                if (!(parent instanceof MaterialAssignments)) throw new Error("Invalid parent type for MaterialAssignment")

                const referencingDeletedPaths: ReferencingDeletedTemplateNodes = new Map()
                traverseGraphParameters(parent.parameters, (child, path) => {
                    if (child === node) addTemplateNodeReference(referencingDeletedPaths, parent, path, node)
                })

                return referencingDeletedPaths
            },
        },
        {
            appliesTo: (node) => isOutput(node),
            passChildReferenceDeleteToParent: (child) => child instanceof TemplateInstance,
            mapToParentParamaters: (node) => {
                const parent = getUniqueTemplateNodeParent(node)
                if (parent instanceof Parameters) {
                    const referencingDeletedPaths: ReferencingDeletedTemplateNodes = new Map()
                    traverseGraphParameters(parent.parameters, (child, path) => {
                        if (child === node) addTemplateNodeReference(referencingDeletedPaths, parent, path, node)
                    })
                    return referencingDeletedPaths
                } else if (parent instanceof MaterialAssignment) {
                    const grandParent = getUniqueTemplateNodeParent(parent)
                    if (!(grandParent instanceof MaterialAssignments)) throw new Error("Invalid parent type for MaterialAssignment")

                    const referencingDeletedPaths: ReferencingDeletedTemplateNodes = new Map()
                    traverseGraphParameters(grandParent.parameters, (child, path) => {
                        if (child === parent) addTemplateNodeReference(referencingDeletedPaths, grandParent, path, parent)
                    })
                    return referencingDeletedPaths
                }

                throw new Error("Invalid parent type for Output")
            },
        },
    ]

    const referencingDeletedPaths: ReferencingDeletedTemplateNodes = new Map()

    for (const node of getAllTemplateNodes(templateGraph)) {
        if (nodesToDelete.has(node)) continue
        if (isTemplateOwningContainer(node)) if (nodesToDeleteOwningParentContainers.has(node)) continue
        if (isTemplateReferenceContainer(node)) {
            const templateContainerParent = getUniqueTemplateNodeParent(node)
            if (nodesToDelete.has(templateContainerParent)) continue
        }

        const passChildReferenceDeleteToParentData = passReferenceDeleteToParentLibrary.filter((data) => data.appliesTo(node))
        if (passChildReferenceDeleteToParentData.length > 1) throw new Error("Not unique passChildDeleteToParentData")
        const passReferenceDeleteToParent = passChildReferenceDeleteToParentData.at(0)

        traverseGraphParameters(node.parameters, (child, path) => {
            if (isTemplateNode(child)) {
                if (nodesToDelete.has(child)) {
                    if (passReferenceDeleteToParent) {
                        const {passChildReferenceDeleteToParent, mapToParentParamaters} = passReferenceDeleteToParent
                        if (passChildReferenceDeleteToParent(child)) {
                            const parentDeleteReferences = mapToParentParamaters(node)
                            for (const [parent, children] of parentDeleteReferences) {
                                for (const [path, child] of children.entries()) addTemplateNodeReference(referencingDeletedPaths, parent, path, child)
                            }
                            return
                        }
                    }

                    addTemplateNodeReference(referencingDeletedPaths, node, path, child)
                }
            }
        })
    }

    return referencingDeletedPaths
}

const deleteToken = Symbol("deleteToken")

const removeDeleteTokens = (parameters: NodeParameters): NodeParameters => {
    const eraseParameters = (parameter: unknown): unknown => {
        if (typeof parameter === "object" && parameter) {
            if (parameter instanceof Array)
                return parameter
                    .filter((entry) => {
                        return entry !== deleteToken
                    })
                    .map((entry) => eraseParameters(entry))
            else if (isClass(parameter)) return parameter
            else
                return Object.entries(parameter).reduce<NodeParameters>((acc, [key, value]) => {
                    acc[key] = eraseParameters(value)
                    return acc
                }, {})
        } else return parameter
    }

    return eraseParameters(parameters) as NodeParameters
}

export function deleteNodesFromTemplateGraph(templateGraph: TemplateGraph, nodes: Set<TemplateNode>) {
    const nodesToDelete = expandTemplateNodesToDelete(nodes)
    const referencingDeletedNodes = getReferencingDeletedTemplateNodes(templateGraph, nodesToDelete)

    //Dry run to check if we can safely unlink the referenced nodes
    const removeFromContainers: Map<TemplateContainer, Set<TemplateNode>> = new Map()
    const removeFromNodes: Map<TemplateNode, Map<string, {node: TemplateNode; newValue: null | undefined | typeof deleteToken}>> = new Map()
    for (const [node, children] of referencingDeletedNodes) {
        if (isTemplateContainer(node)) {
            removeFromContainers.set(node, new Set(children.values()))
        } else {
            const newValues: Map<string, {node: TemplateNode; newValue: null | undefined | typeof deleteToken}> = new Map()

            const schema = node.getParamsSchema()
            for (const [childPath, child] of children) {
                const replacementValues = [null, undefined, deleteToken as typeof deleteToken]
                for (const replacementValue of replacementValues) {
                    const newParameters = removeDeleteTokens(
                        mapGraphParameters(node.parameters, (node, path) => {
                            if (child !== node || path !== childPath) return node
                            return replacementValue
                        }),
                    )

                    if (schema.safeParse(newParameters).success) {
                        newValues.set(childPath, {node: child, newValue: replacementValue})
                        break
                    }
                }

                if (!newValues.has(childPath)) {
                    console.error("Failed to unreference node", node, children)
                    throw new Error(`Failed to unreference node ${getTemplateNodeLabel(node)}`)
                }
            }

            removeFromNodes.set(node, newValues)
        }
    }

    //Unlink the referenced nodes
    for (const [node, children] of removeFromContainers) {
        children.forEach((child) => {
            if (isTemplateNode(child)) (node as TemplateListNode<TemplateNode>).removeEntry(child)
        })
    }

    for (const [node, children] of removeFromNodes) {
        const newParameters = removeDeleteTokens(
            mapGraphParameters(node.parameters, (node, path) => {
                const child = children.get(path)
                if (!child) return node
                if (child.node !== node) return node
                return child.newValue
            }),
        )

        node.replaceParameters(removeDeleteTokens(newParameters))
    }

    //Remove the nodes to delete
    nodesToDelete.forEach((node) => {
        node.parents.forEach((parent) => {
            if (isTemplateNode(parent) && isTemplateContainer(parent)) (parent as TemplateListNode<TemplateNode>).removeEntry(node)
        })
    })

    //Validation that all nodes are deleted
    const failedToDelete = new Set<TemplateNode>()
    for (const node of getAllTemplateNodes(templateGraph)) if (nodesToDelete.has(node)) failedToDelete.add(node)

    if (failedToDelete.size > 0) {
        console.error("Failed to delete nodes", failedToDelete)
        throw new Error("Failed to successfully delete all nodes")
    }
}

export function getAllTemplateNodes(templateGraph: TemplateGraph) {
    const nodes = new Set<TemplateNode>()
    templateGraph.depthFirstTraversalPreorder(() => TraversalAction.Continue, nodes)

    return nodes
}
