import {ApplicationModule, Component} from "@angular/core"
import {FormsModule, ReactiveFormsModule} from "@angular/forms"
import {MatNativeDateModule} from "@angular/material/core"
import {HammerModule} from "@angular/platform-browser"
import {RouterModule} from "@angular/router"
import {BackgroundOperationListComponent} from "@common/components/background-operation-list/background-operation-list.component"
import {NavigationBarComponent} from "@common/components/navigation"
import {GlobalAppInjector} from "@common/helpers/app-injector/app-injector"
import {InjectorService} from "@common/services/injector/injector.service"
import {UtilsService} from "@legacy/helpers/utils"

@Component({
    selector: "colormass-app",
    templateUrl: "app.component.html",
    styleUrls: ["app.component.scss"],
    imports: [
        ApplicationModule,
        BackgroundOperationListComponent,
        FormsModule,
        HammerModule,
        MatNativeDateModule,
        NavigationBarComponent,
        ReactiveFormsModule,
        RouterModule,
    ],
})
export class AppComponent {
    constructor(injector: InjectorService) {
        GlobalAppInjector.injector = injector.injector
        UtilsService.printAppVersion()
    }
}
