import {Color, Vector2, Vector2Like} from "@cm/math"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"
import {LineItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/basic/line-item"
import {EventEmitter} from "@angular/core"

export class HelperLineItem extends LineItem {
    readonly stateChanged = new EventEmitter<State>()

    constructor(parent: CanvasBaseToolboxItem, from: Vector2Like, to: Vector2Like) {
        super(parent, [], 2, new Color(1, 0.2, 1, 1), "smooth")
        this.cursor = "pointer"
        this._sourceCastRayOrigin = Vector2.fromVector2Like(from)
        this._sourceCastRayTarget = Vector2.fromVector2Like(to)
        this._state = "tracing-bidirectional"
        this.sendToBack()
    }

    override remove() {
        super.remove()
        this._state = "removed"
    }

    get isRemoved() {
        return this._state === "removed"
    }

    get sourceCastRayOrigin(): Vector2 {
        return this._sourceCastRayOrigin
    }

    get sourceCastRayTarget(): Vector2 {
        return this._sourceCastRayTarget
    }

    get state(): State {
        return this._state
    }

    set state(value: State) {
        if (this._state === value) {
            return
        }
        if (this._state === "removed") {
            throw Error("Cannot change state of removed helper line")
        }
        this._state = value
        this.stateChanged.emit(value)
    }

    rescaleSourceTargetDistance(distance: number) {
        const dir = this._sourceCastRayTarget.sub(this._sourceCastRayOrigin).normalized()
        this._sourceCastRayTarget = this._sourceCastRayOrigin.add(dir.mul(distance))
    }

    addHeadPoint(point: Vector2Like) {
        this.setPoints([point, ...this.points])
    }

    addTailPoint(point: Vector2Like) {
        this.setPoints([...this.points, point])
    }

    private _sourceCastRayOrigin: Vector2
    private _sourceCastRayTarget: Vector2
    private _state: State
}

export type State = "tracing-bidirectional" | "tracing-forward" | "tracing-backward" | "finished" | "removed"
