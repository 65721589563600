import {Component, Inject} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {ImageColorSpace} from "@generated"
import {MeasurementType, MeasurementTypes} from "@common/models/settings/settings"
import {Labels} from "@labels"
import {AddTextureDialogResult} from "@platform/models/dialogs/add-texture-dialog-result"

@Component({
    selector: "cm-add-texture-dialog",
    templateUrl: "./add-texture-dialog.component.html",
    styleUrls: ["./add-texture-dialog.component.scss"],
    imports: [MatDialogModule, MatInputModule, FormsModule, MatSelectModule, MatButtonModule],
})
export class AddTextureDialogComponent {
    imperial = false
    showColorSpaceSetting = true
    result: AddTextureDialogResult = {
        width: 0,
        height: 0,
        colorSpace: ImageColorSpace.Unknown,
    }

    constructor(
        public dialogRef: MatDialogRef<AddTextureDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {width: number; height: number; displacement: number; measurementType: MeasurementType; showColorSpaceSetting?: boolean},
    ) {
        if (data) {
            this.result = {
                width: data.width,
                height: data.height,
                displacement: data.displacement,
                colorSpace: typeof data.showColorSpaceSetting !== "undefined" ? ImageColorSpace.Srgb : ImageColorSpace.Unknown,
            }
            this.imperial = data.measurementType === MeasurementTypes.Imperial
            if (typeof data.showColorSpaceSetting !== "undefined") {
                this.showColorSpaceSetting = data.showColorSpaceSetting
            }
        }
    }

    cancelAction(): void {
        this.dialogRef.close(false)
    }

    confirmAction(): void {
        if (this.result && this.imperial) {
            this.result.width = MeasurementTypes.Imperial.convertToMetric(this.result.width)
            this.result.height = MeasurementTypes.Imperial.convertToMetric(this.result.height)
            if (this.result.displacement) this.result.displacement = MeasurementTypes.Imperial.convertToMetric(this.result.displacement)
        }
        this.dialogRef.close(this.result)
    }

    protected readonly ImageColorSpace = ImageColorSpace
    protected readonly Labels = Labels
}
