<div class="cm-rgb-curves">
    <div class="cm-curve-toggle">
        <div class="cm-curve-toggle-item cm-rgb" [class.cm-active]="$selectedCurveType() === 'rgb'" (click)="$selectedCurveType.set('rgb')">RGB</div>
        <div class="cm-curve-toggle-item cm-r" [class.cm-active]="$selectedCurveType() === 'r'" (click)="$selectedCurveType.set('r')">R</div>
        <div class="cm-curve-toggle-item cm-g" [class.cm-active]="$selectedCurveType() === 'g'" (click)="$selectedCurveType.set('g')">G</div>
        <div class="cm-curve-toggle-item cm-b" [class.cm-active]="$selectedCurveType() === 'b'" (click)="$selectedCurveType.set('b')">B</div>
    </div>
    <cm-spline-curve
        [controlPoints]="$splineControlPoints()"
        [width]="$width()"
        [height]="$height()"
        (onControlPointAdded)="onControlPointAdded.emit({curve: $selectedCurveType(), point: $event})"
        (onControlPointRemoved)="onControlPointRemoved.emit({curve: $selectedCurveType(), pointId: $event})"
        (onBeginControlPointDrag)="onBeginControlPointDrag.emit()"
        (onControlPointChanged)="onControlPointChanged.emit({curve: $selectedCurveType(), pointId: $event.pointId, point: $event.point})"
        (onEndControlPointDrag)="onEndControlPointDrag.emit()"
    ></cm-spline-curve>
</div>
