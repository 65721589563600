<div class="cm-label">
    <div #ref><ng-content select="[cm-label]"> </ng-content></div>
    @if (!ref.innerHTML.trim()) {
        {{ $labelText() }}
    }
</div>
<cm-card
    [defaultThumbnail]="false"
    (click)="openSelectMaterialDialog()"
    class="cm-accept-nodes"
    [class.cm-drop]="$highlighted()"
    [draggable]="true"
    (dragstart)="dragStart($event)"
    (dragend)="drag.dragEnd($event)"
>
    <ng-container cm-thumbnail>
        @if ($thumbnailObject(); as thumbnailData) {
            <cm-data-object-thumbnail [dataObjectId]="thumbnailData.id"></cm-data-object-thumbnail>
        } @else {
            @if ($thumbnailUrl(); as thumbnailData) {
                <cm-thumbnail [url]="thumbnailData"></cm-thumbnail>
            }
        }
    </ng-container>
    <ng-container cm-title>
        @if ($node(); as material) {
            <cm-template-node [node]="material" [noDrag]="true" #dragImage></cm-template-node>
        } @else {
            <i class="fa fa-sign-in"></i>
            <span class="cm-material-title-none"> (No Material) </span>
        }
    </ng-container>
    <ng-container cm-top-left>
        @if ($node(); as node) {
            <div matTooltip="Remove assignment" (click)="$event.stopPropagation(); removeMaterial()" class="cm-icon">
                <i class="far fa-trash"></i>
            </div>
        }
    </ng-container>
    <ng-container cm-top-right>
        <div class="cm-icon">
            <ng-content select="[cm-top-right]"></ng-content>
        </div>
    </ng-container>
    <ng-container cm-bottom-left>
        @if ($isReference()) {
            <div matTooltip="Node is a reference" (click)="$event.stopPropagation(); gotoReference()" class="cm-icon">
                <i class="far fa-external-link"></i>
            </div>
        }
    </ng-container>
    <ng-container cm-bottom-right>
        <div class="cm-icon">
            <ng-content select="[cm-bottom-right]"></ng-content>
        </div>
    </ng-container>
</cm-card>
