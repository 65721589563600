import {Pipe, PipeTransform} from "@angular/core"
import {stringToDate} from "@common/helpers/utils/dates"
import {formatDate} from "date-fns/format"

@Pipe({
    name: "cmFormatDate",
})
export class FormatDatePipe implements PipeTransform {
    transform(dateOrString: string | Date | null | undefined): string | undefined {
        const date = stringToDate(dateOrString)
        if (date) {
            return formatDate(date, "do MMMM yyyy HH:mm")
        }
        return undefined
    }
}
