import {Component, computed, inject, Injector} from "@angular/core"
import {toObservable, toSignal} from "@angular/core/rxjs-interop"
import {MatDialog} from "@angular/material/dialog"
import {MatTooltipModule} from "@angular/material/tooltip"
import {Settings} from "@app/common/models/settings/settings"
import {OrganizationsService} from "@app/common/services/organizations/organizations.service"
import {UploadGqlService} from "@app/common/services/upload/upload.gql.service"
import {Labels} from "@app/platform/models/state-labels"
import {selectFile, uploadImage} from "@app/template-editor/helpers/upload"
import {DataObjectReference} from "@cm/template-nodes/nodes/data-object-reference"
import {CardComponent} from "@common/components/cards"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {ThumbnailComponent} from "@common/components/thumbnail/thumbnail.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {FilesizePipe} from "@common/pipes/filesize/filesize.pipe"
import {FormatDatePipe} from "@common/pipes/format-date/format-date.pipe"
import {TimeAgoPipe} from "@common/pipes/time-ago/time-ago.pipe"
import {GetDataObjectDetailsForDataObjectReferenceInspectorGQL} from "@template-editor/components/inspectors/data-object-reference-inspector/data-object-reference-inspector.generated"
import {from, map, switchMap} from "rxjs"
import {ValueSlotComponent} from "../../value-slot/value-slot.component"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {ActionPanelRowComponent} from "@app/common/components/menu/actions/action-panel-row/action-panel-row.component"

@Component({
    selector: "cm-data-object-reference-inspector",
    templateUrl: "./data-object-reference-inspector.component.html",
    styleUrl: "./data-object-reference-inspector.component.scss",
    imports: [
        InspectorSectionComponent,
        ValueSlotComponent,
        CardComponent,
        DataObjectThumbnailComponent,
        ThumbnailComponent,
        ActionPanelRowComponent,
        FilesizePipe,
        MatTooltipModule,
        TimeAgoPipe,
        FormatDatePipe,
    ],
})
export class DataObjectReferenceInspectorComponent extends BaseInspectorComponent<DataObjectReference> {
    private readonly uploadService = inject(UploadGqlService)
    readonly organizations = inject(OrganizationsService)
    private readonly dialog = inject(MatDialog)
    readonly injector = inject(Injector)
    readonly dataObjectDetailsGql = inject(GetDataObjectDetailsForDataObjectReferenceInspectorGQL)
    readonly $dataObjectId = computed(() => this.$parameters().dataObjectId)
    readonly $dataObject = toSignal(
        toObservable(this.$dataObjectId).pipe(
            switchMap((dataObjectId) => from(fetchThrowingErrors(this.dataObjectDetailsGql)({legacyId: dataObjectId}))),
            map(({dataObject}) => dataObject),
        ),
        {
            initialValue: null,
        },
    )

    readonly $thumbnailUrl = computed(() => {
        const dataObjectData = this.$dataObject()
        if (!dataObjectData) return Settings.IMAGE_NOT_AVAILABLE_SMALL_URL
        return undefined
    })
    readonly $thumbnailObject = computed(() => {
        const dataObjectData = this.$dataObject()
        if (dataObjectData) return dataObjectData
        return undefined
    })
    readonly $title = computed(() => {
        const dataObjectData = this.$dataObject()
        if (!dataObjectData) return this.$parameters().name

        const {legacyId, originalFileName} = dataObjectData

        return `${legacyId} - ${originalFileName}`
    })

    async onClick() {
        const file = await selectFile("Select an image file for upload", "image/jpeg, image/png, image/webp")
        if (file) {
            const image = await uploadImage(this.injector, this.uploadService, this.sceneManagerService, this.dialog, file)
            if (!image) return
            this.sceneManagerService.modifyTemplateGraph(() => {
                this.$node().updateParameters({dataObjectId: image.parameters.dataObjectId})
            })
        }
    }

    protected readonly Labels = Labels
}
