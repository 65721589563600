import {ChangeDetectorRef, Component, DestroyRef, HostListener, inject, model, OnInit, viewChild} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatSnackBar} from "@angular/material/snack-bar"
import {ActivatedRoute} from "@angular/router"
import {CanvasBaseComponent} from "@common/components/canvas/canvas-base/canvas-base.component"
import {LoadingSpinnerComponent} from "@common/components/progress/loading-spinner/loading-spinner.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {Hotkeys} from "@common/services/hotkeys/hotkeys.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {ImageColorSpace} from "@generated"
import {PictureControlsComponent} from "@platform/components/pictures/picture-controls/picture-controls.component"
import {
    PictureViewerDataObjectAssignmentFragment,
    PictureViewerItemGQL,
    PictureViewerPictureDataObjectFragment,
    PictureViewerPictureDataObjectThumbnailGQL,
    PictureViewerPictureFragment,
    PictureViewerPictureRevisionFragment,
} from "@platform/components/pictures/picture-viewer/picture-viewer.generated"
import {firstValueFrom, switchMap} from "rxjs"

@Component({
    selector: "cm-picture-viewer",
    templateUrl: "./picture-viewer.component.html",
    styleUrls: ["./picture-viewer.component.scss"],
    imports: [CanvasBaseComponent, PictureControlsComponent, LoadingSpinnerComponent],
})
export class PictureViewerComponent implements OnInit {
    readonly $picture = model<PictureViewerPictureFragment | undefined>(undefined, {alias: "picture"})
    readonly $pictureRevision = model<PictureViewerPictureRevisionFragment | undefined>(undefined, {alias: "pictureRevision"})

    loading = false
    selectedAssignment?: PictureViewerDataObjectAssignmentFragment
    pictureDataObject?: PictureViewerPictureDataObjectFragment
    private zoomLock = false

    readonly $canvasBase = viewChild.required<CanvasBaseComponent>("canvasBaseRef")

    readonly destroyRef = inject(DestroyRef)
    readonly route = inject(ActivatedRoute)
    readonly upload = inject(UploadGqlService)
    readonly itemGql = inject(PictureViewerItemGQL)

    readonly pictureDataObjectThumbnailGql = inject(PictureViewerPictureDataObjectThumbnailGQL)

    constructor(
        private changeDetector: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        hotkeys: Hotkeys,
    ) {
        // register hotkeys
        hotkeys
            .addShortcut(["ArrowLeft", "d"])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.previousRevision())
        hotkeys
            .addShortcut(["ArrowRight", "f"])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.nextRevision())
        hotkeys
            .addShortcut("Space")
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                const physicalZoomLevel: number = this.$canvasBase().navigation.getPhysicalZoomLevel()
                // This is needed because of the rounding errors, since the zoom level at 100% is often 0.99999999 instead of 1.
                const epsilon: number = Math.abs(physicalZoomLevel - 1)
                if (epsilon < 0.01) {
                    this.$canvasBase().navigation.zoomToFitImage()
                } else {
                    this.$canvasBase().navigation.physicalZoomTo(1)
                }
            })
    }

    ngOnInit() {
        this.route.paramMap
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap((params) => {
                    const pictureId = params.get("itemId")
                    if (!pictureId) {
                        throw new Error("No picture ID provided")
                    }
                    const revisionNumberParam = params.get("revisionNumber")
                    if (!revisionNumberParam) {
                        throw new Error("No revisionNumber provided")
                    }
                    const revisionNumber = parseInt(revisionNumberParam)
                    return fetchThrowingErrors(this.itemGql)({id: pictureId}).then(({picture}) => ({
                        picture,
                        revisionNumber: revisionNumberParam === "latest" ? "latest" : (revisionNumber as number | "latest"),
                    }))
                }),
            )
            .subscribe(async ({picture, revisionNumber}) => {
                this.$picture.set(picture)
                await this.loadRevisionNumber(revisionNumber)
                this.changeDetector.detectChanges()
            })
    }

    @HostListener("document:keydown", ["$event"]) onKeydownHandler(event: KeyboardEvent) {
        switch (event.code) {
            case "ShiftLeft":
            case "ShiftRight":
                this.zoomLock = true
                break
        }
    }

    @HostListener("document:keyup", ["$event"]) onKeyupHandler(event: KeyboardEvent) {
        switch (event.code) {
            case "ShiftLeft":
            case "ShiftRight":
                this.zoomLock = false
                break
        }
    }

    loadingComplete() {
        // event from canvas
        this.loading = false
    }

    private clear() {
        this.pictureDataObject = undefined
        this.selectedAssignment = undefined
    }

    private async loadFromDataObject(
        dataObject: PictureViewerPictureDataObjectFragment,
        assignment?: PictureViewerDataObjectAssignmentFragment,
    ): Promise<void> {
        this.clear()
        this.pictureDataObject = dataObject
        this.selectedAssignment = assignment

        let thumbnailUrl = dataObject?.thumbnail?.downloadUrl

        if (!thumbnailUrl) {
            await this.upload.waitForUploadProcessing(dataObject.id, this.destroyRef)
            thumbnailUrl = (await fetchThrowingErrors(this.pictureDataObjectThumbnailGql)(dataObject)).dataObject?.thumbnail?.downloadUrl
        }

        if (typeof thumbnailUrl !== "string") throw new Error(`Invalid thumbnail url for picture revision: ${thumbnailUrl}`)
        // TODO does the comparison in the second argument actually make sense here, since it considers colorspace of the original image and not the thumbnail?
        return firstValueFrom(this.$canvasBase().loadImage(thumbnailUrl, dataObject?.imageColorSpace == ImageColorSpace.Srgb))
    }

    changeRevision(value: "previous" | "next"): void {
        switch (value) {
            case "previous":
                this.previousRevision()
                break
            case "next":
                this.nextRevision()
                break
        }
    }

    private previousRevision(): void {
        const pictureRevision = this.$pictureRevision()
        if (pictureRevision && pictureRevision.number !== 1) this.loadRevisionNumber(pictureRevision.number - 1)
    }

    private nextRevision(): void {
        const pictureRevision = this.$pictureRevision()
        if (pictureRevision && !this.isLatestRevision()) this.loadRevisionNumber(pictureRevision.number + 1)
    }

    private isLatestRevision(): boolean {
        const pictureRevision = this.$pictureRevision()
        const picture = this.$picture()
        return !!picture?.revisions && !!pictureRevision?.number && picture?.revisions?.length <= pictureRevision?.number
    }

    private async loadRevisionNumber(revisionNumber: number | "latest") {
        if (revisionNumber === "latest") {
            const revisions = this.$picture()?.revisions
            if (revisions) {
                // revisions are sorted by descending number, so the first revision is the latest
                await this.loadRevision(revisions?.[0])
            }
        } else {
            const revision = this.$picture()?.revisions?.find((revision) => revision.number === revisionNumber)
            if (revision) {
                await this.loadRevision(revision)
            }
        }
    }

    private async loadRevision(pictureRevision?: PictureViewerPictureRevisionFragment) {
        this.$pictureRevision.set(pictureRevision)
        const assignment = pictureRevision?.pictureDataAssignments?.[0]
        if (assignment) {
            await this.selectAssignment(assignment, !this.zoomLock)
        } else {
            throw new Error("No picture data assignment found")
        }
    }

    async selectAssignment(assignment: PictureViewerDataObjectAssignmentFragment, resetZoom = false) {
        await this.loadFromDataObject(assignment.dataObject, assignment).catch((err) => {
            this.loading = false
            this.snackBar.open(err.toString(), "", {duration: 3000})
            throw err
        })
        if (resetZoom) {
            this.$canvasBase().navigation.zoomToFitImage()
        }
    }
}
