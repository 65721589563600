import {Component, inject, computed} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatDialogModule} from "@angular/material/dialog"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatIconModule} from "@angular/material/icon"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {ContentTypeModel, MutationCreateTagInput, TagType} from "@generated"
import {ColorInputComponent} from "@common/components/inputs/color-input/color-input.component"
import {AddEntityButtonComponent} from "@common/components/data/add-entity-button/add-entity-button.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextAreaComponent} from "@common/components/inputs/native/native-input-text-area/native-input-text-area.component"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {AuthService} from "@common/services/auth/auth.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {Enums} from "@enums"
import {Labels} from "@labels"
import {AddTagGQL} from "@platform/components/tags/add-tag-button/add-tag-button.generated"

@Component({
    selector: "cm-add-tag-button",
    imports: [
        FormsModule,
        MatIconModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        AddEntityButtonComponent,
        MatInputModule,
        InputContainerComponent,
        NativeInputTextAreaComponent,
        ColorInputComponent,
        OrganizationSelectComponent,
    ],
    templateUrl: "./add-tag-button.component.html",
    styleUrl: "./add-tag-button.component.scss",
})
export class AddTagButtonComponent {
    protected readonly Enums = Enums
    protected readonly Labels = Labels

    readonly auth = inject(AuthService)
    readonly organizations = inject(OrganizationsService)
    readonly permission = inject(PermissionsService)
    readonly addTagGql = inject(AddTagGQL)
    $can = this.permission.$to

    data: Omit<MutationCreateTagInput, "organizationId"> & {color: string} = {
        name: "",
        type: TagType.MaterialRange,
        description: "",
        color: "",
    }

    readonly $organizationId = computed<string | undefined>(() => this.organizations.$current()?.id)
    selectedOrganizationId = this.$organizationId()

    canConfirmCreate = () => !!this.data.name

    updateOrganization = async (data: {organizationId: string}) => {
        this.selectedOrganizationId = data.organizationId
    }

    confirmCreate = async () => {
        const result = await mutateThrowingErrors(this.addTagGql)({
            input: {...this.data, organizationId: this.selectedOrganizationId ?? this.$organizationId()},
        }).then(({createTag: {id, legacyId}}) => ({id, legacyId}))
        this.data = {
            name: "",
            type: TagType.MaterialRange,
            description: "",
            color: "",
        }
        return result
    }

    $tagTypes = computed(() => Array.from(Labels.TagType.values()).filter((label) => this.$can().read.tagType(label.state)))

    protected readonly ContentTypeModel = ContentTypeModel
}
