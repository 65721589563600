import {EventEmitter} from "@angular/core"
import {CanvasBaseComponent, CanvasPhysicalInfo} from "@common/components/canvas/canvas-base/canvas-base.component"
import * as paper from "paper"
import {fromEvent, Subject, Subscription, takeUntil} from "rxjs"
import {Matrix3x2, Size2, Vector2, Vector2Like} from "@cm/math"
import {CanvasBaseToolboxRootItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-root-item"
import {CanvasBaseToolboxItem, ToolKeyEvent, ToolMouseEvent, ToolWheelEvent} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export class CanvasNavigation {
    readonly viewChange = new EventEmitter<Matrix3x2>()

    private _toolboxRootItem: CanvasBaseToolboxRootItem | null = null
    physicalInfo: CanvasPhysicalInfo | undefined

    private currentDefaultCursor = "auto"
    private currentCustomCursorItem: CanvasBaseToolboxItem | null = null
    private currentCursorItemSubscription?: Subscription
    private _lastMousePos = new Vector2(0, 0) // we need this since there seems to be no way to poll the cursor position :/
    private _lastMouseDownCanvasPos = new Vector2(0, 0)
    private _LMBDown = false
    private _RMBDown = false
    private _notifiedLeave = true
    private unhandledMouseDown = false
    private hammer!: HammerManager
    private destroySubject = new Subject<void>()
    readonly toolsLayer: paper.Layer

    private _lastHitItem: CanvasBaseToolboxItem | null = null
    private _lastHitItemHasCapture = false

    constructor(
        public canvasBase: CanvasBaseComponent,
        public canvas: HTMLCanvasElement,
        public project: paper.Project,
        public image: HTMLImageElement,
    ) {
        this.init()
        this.toolsLayer = new paper.Layer()
        this.toolsLayer.name = "ToolsLayer"
        this.setDefaultCursor("grab")
    }

    destroy(): void {
        this.toolboxRootItem = null
        this.destroySubject.next()
        this.destroySubject.complete()
        // this.tool.remove()
        this.hammer.destroy()
    }

    get toolboxRootItem(): CanvasBaseToolboxRootItem | null {
        return this._toolboxRootItem
    }

    set toolboxRootItem(canvasToolbox: CanvasBaseToolboxRootItem | null) {
        if (this._toolboxRootItem === canvasToolbox) {
            return
        }
        if (this._toolboxRootItem) {
            this._toolboxRootItem.paperLayer.remove()
        }
        this._toolboxRootItem = canvasToolbox
        this._lastHitItem = null
        this._lastHitItemHasCapture = false
        if (this._toolboxRootItem) {
            if (this._toolboxRootItem.canvasBase !== this.canvasBase) {
                throw Error("Attempting to attach a foreign toolbox to a canvas.")
            }
            this.toolsLayer.addChild(this._toolboxRootItem.paperLayer)
        }
    }

    private updateToolboxHitInfo(point: Vector2Like) {
        if (this._toolboxRootItem) {
            // we only update the last hit info if the hit item does not capture the input
            if (!this._lastHitItemHasCapture) {
                this._lastHitItem = this._toolboxRootItem.hitTest(point)
            }
            this.setCustomCursorItem(this._lastHitItem)
        }
    }

    private updateCursor(): void {
        this.canvas.style.cursor = this.currentCustomCursorItem?.cursor ?? this.currentDefaultCursor
    }

    private setDefaultCursor(cursor: string) {
        if (this.currentDefaultCursor == cursor) {
            return
        }
        this.currentDefaultCursor = cursor
        this.updateCursor()
    }

    setCustomCursorItem(item: CanvasBaseToolboxItem | null) {
        if (this.currentCustomCursorItem == item) {
            return
        }
        this.currentCursorItemSubscription?.unsubscribe()
        this.currentCursorItemSubscription = undefined
        this.currentCustomCursorItem = item
        if (item) {
            this.currentCursorItemSubscription = item.cursorChange.subscribe(() => this.updateCursor())
        }
        this.updateCursor()
    }

    get canvasCursorPosition(): Vector2 {
        return this._lastMousePos
    }

    private executeToolboxEvent<
        HandlerName extends "onKeyDown" | "onKeyUp" | "onMouseWheel" | "onMouseDown" | "onMouseUp" | "onMouseDrag" | "onMouseMove" | "onMouseLeave",
        EventType extends ToolKeyEvent | ToolWheelEvent | ToolMouseEvent,
    >(toolboxEventHandler: HandlerName, event: EventType): boolean {
        if (!this._toolboxRootItem) {
            return true
        }
        this.updateToolboxHitInfo(this._lastMousePos)
        switch (toolboxEventHandler) {
            case "onKeyDown":
                return this._toolboxRootItem.onKeyDown(event as ToolKeyEvent)
            case "onKeyUp":
                return this._toolboxRootItem.onKeyUp(event as ToolKeyEvent)
            case "onMouseWheel":
                return this._lastHitItem ? this._lastHitItem.onMouseWheel(event as ToolWheelEvent) : true
            case "onMouseDown":
                // this.setSelectedItem(this._lastHitItem)
                if (this._lastHitItem) {
                    this._lastHitItemHasCapture = true
                    return this._lastHitItem.onMouseDown(event as ToolMouseEvent)
                } else {
                    return true
                }
            case "onMouseUp":
                if (this._lastHitItem && this._lastHitItemHasCapture) {
                    this._lastHitItemHasCapture = false
                    return this._lastHitItem.onMouseUp(event as ToolMouseEvent)
                } else {
                    return true
                }
            case "onMouseDrag":
                return this._lastHitItem ? this._lastHitItem.onMouseDrag(event as ToolMouseEvent) : true
            case "onMouseMove":
                return this._lastHitItem ? this._lastHitItem.onMouseMove(event as ToolMouseEvent) : true
            case "onMouseLeave":
                return this._lastHitItem ? this._lastHitItem.onMouseLeave(event as ToolMouseEvent) : true
            default:
                throw new Error(`Unknown toolbox event handler: ${toolboxEventHandler}`)
        }
    }

    private onKeyDown(event: KeyboardEvent): void {
        this.executeToolboxEvent("onKeyDown", event)
    }

    private onKeyUp(event: KeyboardEvent): void {
        this.executeToolboxEvent("onKeyUp", event)
    }

    private onWheel(event: WheelEvent): void {
        const toolWheelEvent: ToolWheelEvent = {delta: event.deltaY}
        if (!this.executeToolboxEvent("onMouseWheel", toolWheelEvent)) {
            return
        }
        const mousePositionProject = this._lastMousePos //this.mapMouseToCanvasPosition(event)
        const baseZoomFactor = 1.15
        let zoomFactor: number
        if (event.deltaY > 0) {
            zoomFactor = 1 / baseZoomFactor
        } else {
            zoomFactor = baseZoomFactor
        }
        this.zoomTo(this.project.view.zoom * zoomFactor)
        // Paper's zoom has the view's center as a fixed point. Make the cursor's position fixed instead.
        const mousePositionProjectZoomed = mousePositionProject.sub(this.project.view.center).div(zoomFactor)
        const zoomCompensatingTranslation = mousePositionProject.sub(mousePositionProjectZoomed).sub(this.project.view.center)
        this.offsetView(zoomCompensatingTranslation)
    }

    private onMouseDown(event: MouseEvent): void {
        const canvasPos = this.mapMouseToCanvasPosition(event)[0]
        const [toolMouseEvent, isPointInCanvas] = this.mapMouseEvent(event, canvasPos, this._lastMousePos)
        if (!isPointInCanvas) {
            return
        }
        this._lastMousePos = Vector2.fromVector2Like(toolMouseEvent.point)
        this._lastMouseDownCanvasPos = toolMouseEvent.downPoint
        if (event.button === 0) {
            this._LMBDown = true
        }
        if (event.button === 2) {
            this._RMBDown = true
        }
        // Clicking on the canvas does not trigger blur() on the currently focused element. On one hand this leads to an inconsistent UI behavior, since the input fields do
        // not lose focus when clicking on the canvas. On the other hand it can lead to the ExpressionChangedAfterItHasBeenCheckedError.
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur()
        }
        this.setDefaultCursor("grabbing")
        if (this.isValidToolMouseEvent(event, false)) {
            if (!this.executeToolboxEvent("onMouseDown", toolMouseEvent)) {
                this.unhandledMouseDown = false
                return
            }
        }
        this.unhandledMouseDown = true
    }

    private onMouseUp(event: MouseEvent): void {
        const [toolMouseEvent, isPointInCanvas] = this.mapMouseEvent(event, this._lastMouseDownCanvasPos, this._lastMousePos)
        if (!isPointInCanvas && !this._LMBDown && !this._RMBDown) {
            return
        }
        this._lastMousePos = Vector2.fromVector2Like(toolMouseEvent.point)
        if (event.button === 0) {
            this._LMBDown = false
        }
        if (event.button === 2) {
            this._RMBDown = false
        }
        this.setDefaultCursor("grab")
        if (this.isValidToolMouseEvent(event, true)) {
            if (!this.executeToolboxEvent("onMouseUp", toolMouseEvent)) {
                this.unhandledMouseDown = false
                return
            }
        }
    }

    private onMouseMove(event: MouseEvent) {
        const [toolMouseEvent, isPointInCanvas] = this.mapMouseEvent(event, this._lastMouseDownCanvasPos, this._lastMousePos)
        if (!isPointInCanvas && !this._LMBDown && !this._RMBDown) {
            if (!this._notifiedLeave) {
                this._notifiedLeave = true
                this.executeToolboxEvent("onMouseLeave", toolMouseEvent)
            }
            return
        }
        this._notifiedLeave = false
        this._lastMousePos = Vector2.fromVector2Like(toolMouseEvent.point)
        const isDragging = this._LMBDown || this._RMBDown
        if (isDragging) {
            event.stopPropagation()
            event.preventDefault()

            // Don't pan in case of a multi-touch event to avoid conflict with pinch to zoom/pan.
            if (event.type === "touchmove" && (event as unknown as TouchEvent).touches.length > 1) return

            if (this.isValidToolMouseEvent(event, false)) {
                if (!this.executeToolboxEvent("onMouseDrag", toolMouseEvent)) {
                    return
                }
            }
            if (this.unhandledMouseDown && (event.buttons === 1 || event.buttons === 2 || event.type === "touchmove")) {
                const offset = toolMouseEvent.downPoint.sub(toolMouseEvent.point)
                this.offsetView(offset)
            }
        } else {
            if (this.isValidToolMouseEvent(event, false)) {
                if (!this.executeToolboxEvent("onMouseMove", toolMouseEvent)) {
                    return
                }
            }
        }
    }

    private mapMouseToCanvasPosition(event: MouseEvent): [Vector2, boolean] {
        const screenPos = new Vector2(event.clientX, event.clientY)
        const canvasClientRect = this.canvas.getBoundingClientRect()
        const canvasLocalPos = screenPos.sub(new Vector2(canvasClientRect.left, canvasClientRect.top))
        const canvasPos = Vector2.fromVector2Like(this.project.view.viewToProject(canvasLocalPos))
        const isPointInCanvas =
            canvasLocalPos.x >= 0 && canvasLocalPos.x <= canvasClientRect.width && canvasLocalPos.y >= 0 && canvasLocalPos.y <= canvasClientRect.height
        return [canvasPos, isPointInCanvas]
    }

    private mapMouseEvent(event: MouseEvent, downPoint: Vector2, lastMousePos: Vector2): [ToolMouseEvent, boolean] {
        const [point, isPointInCanvas] = this.mapMouseToCanvasPosition(event)
        const lastPoint = Vector2.fromVector2Like(lastMousePos)
        const delta = point.sub(lastPoint)
        return [{point, downPoint, lastPoint, delta, buttons: event.buttons}, isPointInCanvas]
    }

    private init(): void {
        this.destroySubject = new Subject<void>()
        this.initPinchZoom()

        fromEvent<KeyboardEvent>(document, "keydown")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((event) => this.onKeyDown(event))
        fromEvent<KeyboardEvent>(document, "keyup")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((event) => this.onKeyUp(event))

        fromEvent<WheelEvent>(this.canvas, "wheel")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((event: WheelEvent) => this.onWheel(event))

        // add event listeners (mouse down on canvas, but mouse move and up on document to support dragging outside the canvas)
        const isEventSupported = (eventName: string) => eventName in document
        const bindMouseEvent = (node: Node, eventName: string, handler: (event: MouseEvent) => void) =>
            fromEvent<MouseEvent>(node, eventName)
                .pipe(takeUntil(this.destroySubject))
                .subscribe((event) => handler(event))
        if (isEventSupported("onpointerdown")) {
            bindMouseEvent(this.canvas, "pointerdown", (event) => this.onMouseDown(event))
            bindMouseEvent(document, "pointerup", (event) => this.onMouseUp(event))
            bindMouseEvent(document, "pointermove", (event) => this.onMouseMove(event))
        } else if (isEventSupported("ontouchstart")) {
            bindMouseEvent(this.canvas, "touchstart", (event) => this.onMouseDown(event))
            bindMouseEvent(document, "touchend", (event) => this.onMouseUp(event))
            bindMouseEvent(document, "touchmove", (event) => this.onMouseMove(event))
        } else if (isEventSupported("onmousedown")) {
            bindMouseEvent(this.canvas, "mousedown", (event) => this.onMouseDown(event))
            bindMouseEvent(document, "mouseup", (event) => this.onMouseUp(event))
            bindMouseEvent(document, "mousemove", (event) => this.onMouseMove(event))
        }
    }

    private isValidToolMouseEvent(mouseEvent: MouseEvent, isMouseUp: boolean): boolean {
        // we only process NO-BUTTON and LMB events for now such that RMB/MMB is reserved for navigation
        const button = isMouseUp ? mouseEvent.button : mouseEvent.buttons // it is rather strange that in case of mouse-up events the corresponding button is found in "button" rather than "buttons"
        return button === 0 || button === 1
    }

    // TODO: unite touch and mouse navigation?
    initPinchZoom() {
        const canvasElement = this.canvas
        const box = canvasElement.getBoundingClientRect()
        const offset = new paper.Point(box.left, box.top)

        const hammer = new Hammer(canvasElement, {})
        this.hammer = hammer
        hammer.get("pinch").set({enable: true})

        let startMatrix: paper.Matrix, startMatrixInverted: paper.Matrix, p0ProjectCoords: paper.Point

        fromEvent<HammerInput>(hammer, "pinchstart")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((e) => {
                startMatrix = this.project.view.matrix.clone()
                startMatrixInverted = startMatrix.inverted()
                const p0 = getCenterPoint(e)
                p0ProjectCoords = this.project.view.viewToProject(p0)
            })

        fromEvent<HammerInput>(hammer, "pinch")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((e) => {
                // Translate and scale view using pinch event's "center" and "scale" properties.
                // Translation computes center's distance from initial center (considering current scale).
                const p = getCenterPoint(e)
                const pProject0 = p.transform(startMatrixInverted)
                const delta = pProject0.subtract(p0ProjectCoords).divide(e.scale)
                this.project.view.matrix = startMatrix.clone().scale(e.scale, p0ProjectCoords).translate(delta)
            })

        function getCenterPoint(e: {center: {x: number; y: number}}) {
            return new paper.Point(e.center.x, e.center.y).subtract(offset)
        }
    }

    zoomToFitSize(width: number, height: number, adjustFactor = 1, stretch = false): void {
        const logicalCanvasSize = Size2.fromSize2Like(this.canvas).mulInPlace(1 / devicePixelRatio)
        if (width >= logicalCanvasSize.width || height >= logicalCanvasSize.height || stretch) {
            const canvasAspectRatio: number = logicalCanvasSize.width / logicalCanvasSize.height
            const pictureAspectRatio: number = width / height
            if (pictureAspectRatio >= canvasAspectRatio) {
                this.zoomTo((logicalCanvasSize.width / width) * adjustFactor)
            } else {
                this.zoomTo((logicalCanvasSize.height / height) * adjustFactor)
            }
        } else {
            this.zoomTo(1)
        }
        this.centerPosition(width / 2, height / 2)
    }

    centerPosition(x: number, y: number): void {
        this.project.view.center = new paper.Point(x, y)
        this.onViewChanged()
    }

    zoomToFitCanvasBounds(adjustFactor = 1, stretch = false): void {
        this.zoomToFitSize(this.canvasBase.canvasBounds.width, this.canvasBase.canvasBounds.height, adjustFactor, stretch)
    }

    zoomToFitImage(adjustFactor = 1, stretch = false): void {
        this.zoomToFitSize(this.image.width, this.image.height, adjustFactor, stretch)
    }

    zoomTo(percentage: number): void {
        this.project.view.zoom = percentage
        this.onViewChanged()
    }

    // zoom level in relation to the physical pixels
    physicalZoomTo(percentage: number): void {
        this.zoomTo(percentage / devicePixelRatio)
    }

    offsetView(offset: Vector2Like): void {
        this.project.view.center = this.project.view.center.add(offset)
        this.onViewChanged()
    }

    getZoomLevel(): number {
        return this.project.view.zoom
    }

    // zoom level in relation to the physical pixels
    getPhysicalZoomLevel(): number {
        return this.getZoomLevel() * devicePixelRatio
    }

    getCenterPosition(): Vector2 {
        return Vector2.fromVector2Like(this.project.view.center)
    }

    getTopLeftPosition(): Vector2 {
        return Vector2.fromVector2Like(this.project.view.viewToProject(new paper.Point(0, 0)))
    }

    private onViewChanged(): void {
        // here we correct the view matrix to avoid fractional offsets (which cause blurry rendering)
        const matrix = new Matrix3x2([
            this.project.view.matrix.a,
            this.project.view.matrix.b,
            this.project.view.matrix.c,
            this.project.view.matrix.d,
            Math.round(this.project.view.matrix.tx),
            Math.round(this.project.view.matrix.ty),
        ])
        this.project.view.matrix.set(matrix.toArray())
        this.viewChange.emit(matrix)
    }
}
