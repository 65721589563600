import {Router} from "@angular/router"

export function createLinkToEditorFromPictures(pictureId: string, templateRevisionId: string): string[] {
    return ["/pictures", pictureId, "template-revisions", templateRevisionId, "edit"]
}

export function createLinkAddTemplateRevisionFromPictures(pictureId: string): string[] {
    return ["/pictures", pictureId, "template-revisions", "add"]
}

export function createLinkToEditorFromTemplates(router: Router, templateId: string, templateRevisionId: string): string[] {
    const currentPath = router.url.split("?")[0] // Get the current path without query params
    if (currentPath.includes("templates")) return createLinkToEditorFromTemplatesForType("templates", templateId, templateRevisionId)
    else if (currentPath.includes("scenes")) return createLinkToEditorFromTemplatesForType("scenes", templateId, templateRevisionId)
    else if (currentPath.includes("products")) return createLinkToEditorFromTemplatesForType("products", templateId, templateRevisionId)
    else throw new Error("Could not determine path to editor from current path")
}

export function createLinkToEditorFromTemplatesForType(type: "templates" | "scenes" | "products", templateId: string, templateRevisionId: string): string[] {
    if (type === "templates") return ["/templates", templateId, "revisions", templateRevisionId, "edit"]
    else if (type === "scenes") return ["/scenes", templateId, "revisions", templateRevisionId, "edit"]
    else if (type === "products") return ["/products", templateId, "revisions", templateRevisionId, "edit"]
    else throw new Error("Invalid type")
}

type WritableAnchorElemKeys = "href" | "rel"

export function triggerDOMLink(properties: {[K in WritableAnchorElemKeys]?: HTMLAnchorElement[K]}) {
    const a = document.createElement("a")
    //a.href = url;
    //a.download = fileName;
    for (const key of Object.keys(properties) as (keyof typeof properties)[]) {
        const value = properties[key]
        if (value !== undefined) a[key] = value
    }
    document.body.appendChild(a)
    a.style.display = "none"
    a.click()
    a.remove()
}
