import {MaterialNode, MaterialSlot} from "#material-nodes/declare-material-node"
import {
    ImageResource,
    IMaterialGraph,
    MaterialGraphNode,
    ResolvedResource,
    unwrapNodeOutput,
    UnwrappedMaterialGraphNode,
    WrappedMaterialGraphNode,
} from "#material-nodes/material-node-graph"
import {AddShader} from "#material-nodes/nodes/add-shader"
import {BrightnessContrast} from "#material-nodes/nodes/brightness-contrast"
import {BsdfDiffuse} from "#material-nodes/nodes/bsdf-diffuse"
import {BsdfPrincipled} from "#material-nodes/nodes/bsdf-principled"
import {BsdfTranslucent} from "#material-nodes/nodes/bsdf-translucent"
import {BsdfTransparent} from "#material-nodes/nodes/bsdf-transparent"
import {Bump} from "#material-nodes/nodes/bump"
import {ColorRamp} from "#material-nodes/nodes/color-ramp"
import {CombineHSV} from "#material-nodes/nodes/combine-hsv"
import {CombineRGB} from "#material-nodes/nodes/combine-rgb"
import {CombineXYZ} from "#material-nodes/nodes/combine-xyz"
import {Displacement} from "#material-nodes/nodes/displacement"
import {DistanceTexture} from "#material-nodes/nodes/distance-texture"
import {Emission} from "#material-nodes/nodes/emission"
import {Fresnel} from "#material-nodes/nodes/fresnel"
import {Gamma} from "#material-nodes/nodes/gamma"
import {HSV} from "#material-nodes/nodes/hsv"
import {Invert} from "#material-nodes/nodes/invert"
import {LightPath} from "#material-nodes/nodes/light-path"
import {Mapping} from "#material-nodes/nodes/mapping"
import {Math} from "#material-nodes/nodes/math"
import {Mix} from "#material-nodes/nodes/mix"
import {MixRGB} from "#material-nodes/nodes/mix-rgb"
import {MixShader} from "#material-nodes/nodes/mix-shader"
import {Noise} from "#material-nodes/nodes/noise"
import {NormalMap} from "#material-nodes/nodes/normal-map"
import {OutputMaterial} from "#material-nodes/nodes/output-material"
import {RGB} from "#material-nodes/nodes/rgb"
import {RGBCurve} from "#material-nodes/nodes/rgb-curve"
import {RGBToBW} from "#material-nodes/nodes/rgb-to-bw"
import {ScannedTransmission} from "#material-nodes/nodes/scanned-transmission"
import {SeparateHSV} from "#material-nodes/nodes/separate-hsv"
import {SeparateRGB} from "#material-nodes/nodes/separate-rgb"
import {SeparateXYZ} from "#material-nodes/nodes/separate-xyz"
import {SetTexture} from "#material-nodes/nodes/set-texture"
import {Tangent} from "#material-nodes/nodes/tangent"
import {TexBrick} from "#material-nodes/nodes/tex-brick"
import {TexCoord} from "#material-nodes/nodes/tex-coord"
import {TexGradient} from "#material-nodes/nodes/tex-gradient"
import {TexImage} from "#material-nodes/nodes/tex-image"
import {TexVoronoi} from "#material-nodes/nodes/tex-voronoi"
import {TextureSet} from "#material-nodes/nodes/texture-set"
import {UVMap} from "#material-nodes/nodes/uv-map"
import {Value} from "#material-nodes/nodes/value"
import {VectorMath} from "#material-nodes/nodes/vector-math"
import {Color, Context, isColor, isVec3, isVec4, Vec2, Vec3} from "#material-nodes/types"
import {NodeGraph} from "@cm/graph/node-graph"
import {getProperty} from "@cm/graph/utils"
import {mapFieldNames, mapFields} from "@cm/utils"
import * as changeCase from "change-case"
import {MapRange} from "#material-nodes/nodes/map-range"
import {LayerWeight} from "#material-nodes/nodes/layer-weight"

export const isSceneNode = (x: object) => x !== null && "type" in x && typeof x.type === "string"

function cached(target: LegacyMaterialConverter, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value

    descriptor.value = function (...args: any[]) {
        const nodeCache = (this as any).nodeCache
        const node = args[0]
        const cacheValue = nodeCache.get(node)
        if (cacheValue !== undefined) return cacheValue

        const result = originalMethod.apply(this, args)
        nodeCache.set(node, result)

        return result
    }

    return descriptor
}

export class LegacyMaterialConverter {
    nodeCache = new Map<MaterialGraphNode, MaterialNode | NodeGraph<MaterialSlot, Context, {}>>()

    convertMaterialGraph(graph: IMaterialGraph) {
        const rootNode = graph.rootNode
        return this.convertUnwrappedNode(rootNode) as OutputMaterial
    }

    @cached
    convertUnwrappedNode(node: UnwrappedMaterialGraphNode): MaterialNode {
        const convertedInputs = mapFields(node.inputs ?? {}, (value) => {
            if (value === null) return undefined
            return this.convertWrappedNode(value)
        })
        const convertedParameters = mapFields(node.parameters ?? {}, (value, key) => {
            if (value === null) return undefined

            if (Array.isArray(value)) {
                const first = value[0]
                if (typeof first === "number") {
                    if (value.length === 4 || (value.length === 3 && typeof key === "string" && (key.endsWith("Color") || key === "Emission")))
                        return {
                            r: value[0],
                            g: value[1],
                            b: value[2],
                            a: value[3],
                        } as Color
                    else if (value.length === 3) return {x: value[0], y: value[1], z: value[2]} as Vec3
                    else if (value.length === 2) return {x: value[0], y: value[1]} as Vec2
                    else if (value.length === 256) return value
                    else throw new Error(`Invalid numeric vector ${key}: ${value}`)
                } else {
                    if (Array.isArray(first) && first.length === 3) return value.map(([r, g, b]) => ({r, g, b}) as Color)
                    else if (typeof first === "object" && isSceneNode(first)) return value
                    else throw new Error(`Invalid non-numeric vector ${key}: ${value}`)
                }
            } else return value
        })

        const inputs = mapFieldNames(convertedInputs, (key) => changeCase.camelCase(key))
        const parameters = mapFieldNames(convertedParameters, (key) => changeCase.camelCase(key.replace("internal.", "")))
        const {resolvedResources} = node

        const isImageResource = (resource: ResolvedResource): resource is ImageResource => {
            return resource != undefined && typeof resource === "object" && ("mainDataObject" in resource || "transientDataObject" in resource)
        }

        switch (node.nodeType) {
            case "UVMap":
                return new UVMap({...inputs, parameters})
            case "ShaderNodeValue":
                return new Value({...inputs, parameters})
            case "ShaderNodeRGB":
                return new RGB({...inputs, parameters})
            case "BsdfPrincipled":
                return new BsdfPrincipled({...inputs, parameters})
            case "OutputMaterial":
                return new OutputMaterial({...inputs, parameters})
            case "Mapping":
                return new Mapping({...inputs, parameters})
            case "TexImage": {
                if (!resolvedResources || resolvedResources.length === 0 || !isImageResource(resolvedResources[0]))
                    throw new Error("TexImage node has no image resources")
                return new TexImage({...inputs, parameters: {imageResource: resolvedResources[0], ...parameters}})
            }
            case "ShaderNodeMixRGB": {
                const {color, color1, color2, ...rest} = parameters
                const mapColors = (color: unknown) => {
                    if (color === undefined) return undefined
                    if (isVec3(color)) return {r: color.x, g: color.y, b: color.z}
                    else if (isVec4(color)) return {r: color.x, g: color.y, b: color.z, a: color.w}
                    else if (isColor(color)) return color
                    else throw new Error(`Invalid color value: ${color}`)
                }

                return new MixRGB({...inputs, parameters: {color1: mapColors(color1) ?? mapColors(color), color2: mapColors(color2), ...rest}})
            }
            case "ShaderNodeInvert":
                return new Invert({...inputs, parameters})
            case "ShaderNodeMath":
                return new Math({...inputs, parameters})
            case "ShaderNodeNormalMap":
                return new NormalMap({...inputs, parameters})
            case "ShaderNodeTexNoise":
                return new Noise({...inputs, parameters})
            case "ShaderNodeBump":
                return new Bump({...inputs, parameters})
            case "ShaderNodeValToRGB": {
                const updatedParameters = mapFieldNames(parameters, (key) => changeCase.camelCase(key.replace("colorRamp", "")))
                const {
                    elements_0Color_0,
                    elements_0Color_1,
                    elements_0Color_2,
                    elements_0Alpha,
                    elements_0Position,
                    elements_1Color_0,
                    elements_1Color_1,
                    elements_1Color_2,
                    elements_1Alpha,
                    elements_1Position,
                    ...rest
                } = updatedParameters
                const color0 = {r: elements_0Color_0, g: elements_0Color_1, b: elements_0Color_2, a: elements_0Alpha}
                const color1 = {r: elements_1Color_0, g: elements_1Color_1, b: elements_1Color_2, a: elements_1Alpha}
                return new ColorRamp({...inputs, parameters: {...rest, color0, color1, position0: elements_0Position, position1: elements_1Position}})
            }
            case "ShaderNodeRGBCurve": {
                const {fac, color, mappingCyclesMappingTable} = parameters
                const controlPoints: [Vec2[], Vec2[], Vec2[], Vec2[]] = [[], [], [], []]
                for (let curveIndex = 0; curveIndex < 4; curveIndex++) {
                    const curveControlPoints = controlPoints[curveIndex]
                    for (let i = 0; ; i++) {
                        const curControlPoint = parameters[`mappingCurves_${curveIndex}Points_${i}Location`]
                        if (!curControlPoint) break
                        curveControlPoints.push(curControlPoint)
                    }
                    if (curveControlPoints.length === 0) throw new Error(`No control points found for curve ${curveIndex}`)
                }
                return new RGBCurve({...inputs, parameters: {color, fac, cyclesMappingTable: mappingCyclesMappingTable, controlPoints}})
            }
            case "ShaderNodeVectorMath":
                return new VectorMath({...inputs, parameters})
            case "ShaderNodeSeparateXYZ":
                return new SeparateXYZ({...inputs, parameters})
            case "ShaderNodeSeparateRGB":
                return new SeparateRGB({...inputs, parameters})
            case "ShaderNodeSeparateHSV":
                return new SeparateHSV({...inputs, parameters})
            case "ShaderNodeCombineXYZ":
                return new CombineXYZ({...inputs, parameters})
            case "ShaderNodeCombineRGB":
                return new CombineRGB({...inputs, parameters})
            case "ShaderNodeCombineHSV":
                return new CombineHSV({...inputs, parameters})
            case "ShaderNodeTangent":
                return new Tangent({...inputs, parameters})
            case "ShaderNodeHueSaturation":
                return new HSV({...inputs, parameters})
            case "ShaderNodeGamma":
                return new Gamma({...inputs, parameters})
            case "ShaderNodeBrightContrast":
                return new BrightnessContrast({...inputs, parameters})
            case "ShaderNodeRGBToBW":
                return new RGBToBW({...inputs, parameters})
            case "ShaderNodeFresnel":
                return new Fresnel({...inputs, parameters})
            case "ShaderNodeTexCoord":
                return new TexCoord({...inputs, parameters})
            case "ShaderNodeDisplacement":
                return new Displacement({...inputs, parameters})
            case "ShaderNodeBsdfTranslucent":
                return new BsdfTranslucent({...inputs, parameters})
            case "ShaderNodeBsdfTransparent":
                return new BsdfTransparent({...inputs, parameters})
            case "ShaderNodeBsdfDiffuse":
                return new BsdfDiffuse({...inputs, parameters})
            case "ShaderNodeAddShader":
                return new AddShader({...inputs, parameters})
            case "ShaderNodeMixShader":
                return new MixShader({...inputs, parameters})
            case "ShaderNodeLightPath":
                return new LightPath({...inputs, parameters})
            case "ShaderNodeEmission":
                return new Emission({...inputs, parameters})
            case "ShaderNodeTexVoronoi":
                return new TexVoronoi({...inputs, parameters})
            case "ShaderNodeTexBrick":
                return new TexBrick({...inputs, parameters})
            case "ShaderNodeTextureSet": {
                if (!resolvedResources) throw new Error("TextureSet node has no image resources")
                if (resolvedResources.some((resource) => !isImageResource(resource))) throw new Error("TextureSet node has invalid image resources")
                return new TextureSet({...inputs, parameters: {imageResources: resolvedResources, ...parameters}})
            }
            case "ShaderNodeSetTexture": {
                if (!resolvedResources) throw new Error("TextureSet node has no image resources")
                if (resolvedResources.some((resource) => !isImageResource(resource))) throw new Error("SetTexture node has invalid image resources")
                return new SetTexture({...inputs, parameters: {imageResources: resolvedResources, ...parameters}})
            }
            case "ShaderNodeTexGradient":
                return new TexGradient({...inputs, parameters})
            case "ShaderNodeScannedTransmission":
                return new ScannedTransmission({...inputs, parameters})
            case "DistanceTexture":
                return new DistanceTexture({...inputs, parameters})
            case "ShaderNodeMapRange":
                return new MapRange({...inputs, parameters})
            case "ShaderNodeMix":
                return new Mix({...inputs, parameters})
            case "ShaderNodeLayerWeight":
                return new LayerWeight({...inputs, parameters})
        }

        /*The following nodes are not yet implemented:
        ShaderNodeBsdfVelvet
        ShaderNodeBsdfGlass
        ShaderNodeCombineHSV
        ShaderNodeBlackbody
        ShaderNodeWavelength
        ShaderNodeTexChecker
        ShaderNodeNormal
        ShaderNodeLightFalloff
        ShaderNodeNewGeometry
        ShaderNodeTexMagic
        ShaderNodeTexMusgrave
        ShaderNodeTexWave
        ShaderNodeSubsurfaceScattering
        ShaderNodeAmbientOcclusion
        ShaderNodeVectorDisplacement
        ShaderNodeShaderToRGB*/
        throw new Error(`Unknown node type: ${node.nodeType}`)
    }

    @cached
    convertWrappedNode(node: WrappedMaterialGraphNode): NodeGraph<MaterialSlot, Context, {}> {
        const [unwrappedNode, outputName] = unwrapNodeOutput(node)
        if (!outputName) throw new Error("Output name not found in wrapped node")
        return getProperty<{[key: string]: MaterialSlot}, string, Context>(this.convertUnwrappedNode(unwrappedNode), changeCase.camelCase(outputName))
    }
}
