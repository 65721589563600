import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DataObjectDetailsForDataObjectInspectorFragment = { __typename: 'DataObject', id: string, legacyId: number, createdAt?: string | null, width?: number | null, height?: number | null, imageColorSpace?: Types.ImageColorSpace | null, size?: any | null, originalFileName: string, createdBy?: { __typename: 'User', name: string } | null };

export type GetDataObjectDetailsForDataObjectReferenceInspectorQueryVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type GetDataObjectDetailsForDataObjectReferenceInspectorQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, legacyId: number, createdAt?: string | null, width?: number | null, height?: number | null, imageColorSpace?: Types.ImageColorSpace | null, size?: any | null, originalFileName: string, createdBy?: { __typename: 'User', name: string } | null } };

export const DataObjectDetailsForDataObjectInspectorFragmentDoc = gql`
    fragment DataObjectDetailsForDataObjectInspector on DataObject {
  id
  legacyId
  createdAt
  createdBy {
    name
  }
  width
  height
  imageColorSpace
  size
  originalFileName
}
    `;
export const GetDataObjectDetailsForDataObjectReferenceInspectorDocument = gql`
    query getDataObjectDetailsForDataObjectReferenceInspector($legacyId: Int!) {
  dataObject(legacyId: $legacyId) {
    ...DataObjectDetailsForDataObjectInspector
  }
}
    ${DataObjectDetailsForDataObjectInspectorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDataObjectDetailsForDataObjectReferenceInspectorGQL extends Apollo.Query<GetDataObjectDetailsForDataObjectReferenceInspectorQuery, GetDataObjectDetailsForDataObjectReferenceInspectorQueryVariables> {
    override document = GetDataObjectDetailsForDataObjectReferenceInspectorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }