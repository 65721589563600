import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type EditJobFarmInstanceDialogJobFarmGroupFragment = { __typename: 'JobFarmGroup', id: string, name: string };

export type EditJobFarmInstanceJobFarmGroupsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EditJobFarmInstanceJobFarmGroupsQuery = { __typename: 'Query', jobFarmGroups: Array<{ __typename: 'JobFarmGroup', id: string, name: string } | null> };

export const EditJobFarmInstanceDialogJobFarmGroupFragmentDoc = gql`
    fragment EditJobFarmInstanceDialogJobFarmGroup on JobFarmGroup {
  id
  name
}
    `;
export const EditJobFarmInstanceJobFarmGroupsDocument = gql`
    query editJobFarmInstanceJobFarmGroups {
  jobFarmGroups {
    ...EditJobFarmInstanceDialogJobFarmGroup
  }
}
    ${EditJobFarmInstanceDialogJobFarmGroupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class EditJobFarmInstanceJobFarmGroupsGQL extends Apollo.Query<EditJobFarmInstanceJobFarmGroupsQuery, EditJobFarmInstanceJobFarmGroupsQueryVariables> {
    override document = EditJobFarmInstanceJobFarmGroupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }