import {DeclareObjectNode, TemplateObjectNode} from "#template-nodes/declare-object-node"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {SceneNodes} from "#template-nodes/interfaces/scene-object"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {CurveData, CurveObjectData} from "#template-nodes/interfaces/object-data"
import {meshLike} from "#template-nodes/node-types"
import {namedNodeParameters} from "#template-nodes/nodes/named-node"
import {BuilderInlet} from "#template-nodes/runtime-graph/graph-builder"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {GetControlPoints, SampleCurve} from "#template-nodes/runtime-graph/nodes/sample-curve"
import {vectorValue} from "#template-nodes/types"
import {skipped, visitNone} from "@cm/graph/declare-visitor-node"
import {registerNode} from "@cm/graph/register-node"
import {IMaterialData} from "@cm/material-nodes/interfaces/material-data"
import {z} from "zod"

const controlPoint = z.object({
    position: vectorValue,
    normal: vectorValue,
    corner: z.boolean(),
})

const meshCurveParameters = namedNodeParameters.merge(
    z.object({
        mesh: meshLike.nullable(),
        closed: z.boolean(),
        controlPoints: z.array(controlPoint),
        resolution: z.number().positive().optional(),
    }),
)
export type MeshCurveParameters = z.infer<typeof meshCurveParameters>

@registerNode
export class MeshCurve
    extends DeclareObjectNode(
        {parameters: meshCurveParameters},
        {
            onVisited: {
                onFilterActive: ({parameters}) => {
                    if (parameters.mesh === null) return skipped
                    return visitNone(parameters)
                },
                onCompile: function (this: MeshCurveFwd, {context, parameters}) {
                    const {mesh, closed, controlPoints: controlPointsData, resolution} = parameters

                    if (!mesh) return skipped

                    const {evaluator} = context
                    const {templateScope} = evaluator

                    const scope = evaluator.getScope(this)

                    const [meshObjectData, meshObjectDataInvalid] = scope.branch(
                        scope.pureLambda(
                            evaluator.evaluateMesh(scope, mesh),
                            (meshObjectData) => {
                                if (!meshObjectData) return null
                                return {...meshObjectData, visible: true}
                            },
                            "meshObjectData",
                        ),
                    )
                    const transform = scope.get(meshObjectData, "matrix")

                    const {controlPoints} = scope.node(GetControlPoints, {
                        controlPointsData,
                    })

                    const {curvePoints} = scope.node(SampleCurve, {
                        closed: closed ? "repeat" : false,
                        allowScaling: true,
                        controlPoints,
                        segmentLength: resolution ?? 0.1,
                        offsetLength: undefined,
                    })

                    const curveData = scope.struct<CurveData>("CurveData", {
                        type: "curve",
                        visible: scope.pureLambda(
                            scope.tuple(scope.get(meshObjectData, "visible"), this.parameters.visible),
                            ([meshVisible, curveVisible]) => meshVisible && curveVisible,
                            "curveVisible",
                        ),
                        controlPoints,
                        closed: closed,
                        curvePoints,
                        matrix: transform,
                        meshId: scope.get(meshObjectData, "id"),
                    })

                    templateScope.alias(scope.phi(curveData, meshObjectDataInvalid), `curveData-${evaluator.getLocalId(this)}`)

                    this.setupObject(
                        scope,
                        context,
                        "MeshCurveControl",
                        undefined,
                        meshObjectData,
                        (objectProps) => {
                            return scope.pureLambda(
                                scope.tuple(
                                    objectProps.$id,
                                    objectProps.id,
                                    objectProps.topLevelObjectId,
                                    objectProps.transform,
                                    controlPoints,
                                    meshObjectData,
                                    curvePoints,
                                ),
                                ([$id, id, topLevelObjectId, transform, controlPoints, meshObjectData, curvePoints]) => {
                                    const mesh: SceneNodes.Mesh = {
                                        type: "Mesh",
                                        id: meshObjectData.id,
                                        topLevelObjectId: meshObjectData.topLevelObjectId,
                                        transform: meshObjectData.matrix,
                                        completeMeshData: meshObjectData.completeMeshData,
                                        meshRenderSettings: {},
                                        materialMap: new Map<number, IMaterialData | null>(),
                                        visibleDirectly: false,
                                        visibleInReflections: false,
                                        visibleInRefractions: false,
                                        receiveRealtimeShadows: false,
                                        castRealtimeShadows: false,
                                        isDecal: false,
                                        parent: null,
                                        isProcedural: meshObjectData.isProcedural,
                                    }

                                    const meshCurveControl: SceneNodes.MeshCurveControl & {$id: string} = {
                                        type: "MeshCurveControl",
                                        $id,
                                        id,
                                        topLevelObjectId,
                                        transform,
                                        controlPoints,
                                        mesh,
                                        curvePoints,
                                    }
                                    return meshCurveControl
                                },
                                "meshCurveControl",
                            )
                        },
                        meshObjectDataInvalid,
                    )

                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "MeshCurve"},
    )
    implements EvaluableTemplateNode<CurveObjectData | null>
{
    override evaluate = (scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<CurveObjectData | null> => {
        const objectData = super.evaluate(scope, evaluator)
        const curveData = scope.unresolvedToNull(evaluator.templateScope.resolve<CurveData | null>(`curveData-${evaluator.getLocalId(this)}`))
        return scope.pureLambda(
            scope.tuple(objectData, curveData),
            ([objectData, curveData]) => {
                if (objectData === null || curveData === null) return null
                return {
                    ...objectData,
                    ...curveData,
                }
            },
            "evaluatedMeshCurve",
        )
    }
}

export type MeshCurveFwd = TemplateObjectNode<MeshCurveParameters> & EvaluableTemplateNode<CurveObjectData | null>
