<div class="cm-button-list">
    @if ($viewType() === "image" || $viewType() === "configurator") {
        <cm-button (click)="viewTypeChanged.emit('edit')" class="cm-view-type-button cm-active">
            <ng-container cm-left-icon>
                <i class="fa-solid fa-chevron-left"></i>
            </ng-container>
            <ng-container cm-text> 3D Edit </ng-container>
        </cm-button>
    } @else {
        <cm-button (click)="viewTypeChanged.emit('configurator')" class="cm-view-type-button">
            <ng-container cm-left-icon>
                <i class="fa-solid fa-share-nodes"></i>
            </ng-container>
            <ng-container cm-text> Publish 3D </ng-container>
        </cm-button>
        <cm-button (click)="viewTypeChanged.emit('image')" class="cm-view-type-button cm-active">
            <ng-container cm-left-icon>
                <i class="fa-solid fa-play"></i>
            </ng-container>
            <ng-container cm-text> Render Image </ng-container>
        </cm-button>
    }
</div>
