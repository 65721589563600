import {Component, input} from "@angular/core"
import {EntityMetadataTooltipComponent} from "@common/components/entity/entity-metdata-tooltip/entity-metadata-tooltip.component"
import {TimeAgoPipe} from "@common/pipes/time-ago/time-ago.pipe"
import {TippyDirective} from "@ngneat/helipopper"
import {UserThumbnailComponent} from "@platform/components/users/user-thumbnail/user-thumbnail.component"

@Component({
    selector: "cm-entity-metadata",
    imports: [UserThumbnailComponent, TippyDirective, TimeAgoPipe],
    templateUrl: "./entity-metadata.component.html",
    styleUrl: "./entity-metadata.component.scss",
})
export class EntityMetadataComponent {
    readonly $data = input<{
        createdAt?: string | Date | null
        updatedAt?: string | Date | null
        createdBy?: {name: string; galleryImage?: {id: string} | null} | null
        updatedBy?: {name: string; galleryImage?: {id: string} | null} | null
    } | null>(null)
    protected readonly EntityChangedTooltipComponent = EntityMetadataTooltipComponent
}
