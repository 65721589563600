import {Component, inject} from "@angular/core"
import {AssetFilterInput} from "@generated"
import {SelectAssetItemFragment} from "@app/platform/components/assets/select-asset/select-asset.generated"
import {SelectDialogComponent} from "@common/components/dialogs/select-dialog/select-dialog.component"
import {SearchComponent} from "@common/components/inputs/search/search.component"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {SelectAssetItemsGQL} from "@platform/components/assets/select-asset/select-asset.generated"
import {BaseSelectComponent} from "@platform/components/base/base-select/base-select.component"

@Component({
    selector: "cm-select-asset",
    imports: [SearchComponent, SelectDialogComponent],
    templateUrl: "./select-asset.component.html",
    styleUrl: "./select-asset.component.scss",
})
export class SelectAssetComponent extends BaseSelectComponent<SelectAssetItemFragment, AssetFilterInput> {
    readonly selectAssetItems = inject(SelectAssetItemsGQL)
    $data = this.implementDataLoader(fetchThrowingErrors(this.selectAssetItems))
}
