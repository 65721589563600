import {NgClass} from "@angular/common"
import {ChangeDetectionStrategy, Component, inject, input, signal} from "@angular/core"
import {toSignal} from "@angular/core/rxjs-interop"
import {AccordionStyle, ConfigInfo, InterfaceDescriptor, isConfigInput, isMaterialInput, UiStyle, VariantInfo} from "@cm/template-nodes"
import {ConfigButtonComponent} from "@common/components/viewers/configurator/config-menu/icons-accordion-menu/config-button/config-button.component"
import {SelectMaterialButton} from "@common/components/viewers/configurator/config-menu/select-material/select-material.component"
import {ConfigMenuService} from "@common/components/viewers/configurator/config-menu/services/config-menu.service"

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "cm-icons-accordion-menu",
    templateUrl: "./icons-accordion-menu.component.html",
    styleUrls: ["./icons-accordion-menu.component.scss"],
    imports: [NgClass, ConfigButtonComponent, SelectMaterialButton],
})
export class IconsAccordionMenuComponent {
    readonly $useCaptions = input<boolean>(false, {alias: "useCaptions"})
    readonly $tooltipContainer = input.required<HTMLDivElement | undefined>({alias: "tooltipContainer"})
    readonly $interfaceDescriptors = input.required<InterfaceDescriptor<unknown, object>[]>({alias: "interfaceDescriptors"})
    readonly $uiStyle = input.required<UiStyle>({alias: "uiStyle"})
    readonly $accordionStyle = input.required<AccordionStyle>({alias: "accordionStyle"})
    readonly $selectedDescriptorIds = signal<Set<string>>(new Set())

    isConfigInput = isConfigInput
    isMaterialInput = isMaterialInput

    readonly configMenuService = inject(ConfigMenuService)
    readonly $iconSize = toSignal(this.configMenuService.iconSize$)

    constructor() {}

    onDescriptorClicked(id: string) {
        const selectedDescriptorIds = this.$selectedDescriptorIds()

        if (selectedDescriptorIds.has(id)) {
            // clicked on an open accordion, close it
            selectedDescriptorIds.delete(id)
        } else {
            // clicked on a closed accordion, open it
            if (this.$accordionStyle() === "autoClose") {
                selectedDescriptorIds.clear()
            }
            selectedDescriptorIds.add(id)
        }

        this.$selectedDescriptorIds.set(selectedDescriptorIds)
    }

    selectVariant(config: ConfigInfo, variant: VariantInfo): void {
        this.configMenuService.emitConfigSelected({config, variant})
    }
}
