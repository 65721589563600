<h2 mat-dialog-title>Texture settings</h2>
<mat-dialog-content>
    <form id="textureForm" (submit)="confirmAction()">
        @if (showDisplacementSetting) {
            <mat-form-field>
                <input
                    [(ngModel)]="result.displacement"
                    matInput
                    type="number"
                    [placeholder]="!imperial ? 'Displacement (cm)' : 'Displacement (in)'"
                    name="textureDisplacement"
                />
            </mat-form-field>
        }
        <mat-form-field>
            <mat-select [(ngModel)]="result.colorSpace" placeholder="Image file color space" name="textureColorSpace">
                @for (imageColorspaceLabel of Labels.ImageColorSpace.values(); track imageColorspaceLabel.state) {
                    <mat-option [value]="imageColorspaceLabel.state">{{ imageColorspaceLabel.label }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="cancelAction()">Cancel</button>
    <button mat-button form="textureForm" [disabled]="(showDisplacementSetting && result.displacement === undefined) || !result.colorSpace">Ok</button>
</mat-dialog-actions>
