import {ImageOpContextWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-webgl2"
import {ImageOpCommandQueueWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-webgl2"
import {BrushMode} from "@app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"
import {Box2, Box2Like} from "@cm/math"
import {PainterBlitterRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/painter-ref"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {assertSameContext} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"

export class BrushBlendCurrentStroke {
    constructor(readonly context: ImageOpContextWebGL2) {
        this.painter = this.context.createPainter(
            "blitter",
            "brushBlendCurrentStroke",
            `
            uniform float u_opacity;
        
            vec4 computeColor(ivec2 targetPixel, ivec2 sourcePixel) {
                vec4 preStroke = texelFetch0(sourcePixel);
                vec4 currStroke = texelFetch1(sourcePixel);
                return clamp(preStroke + currStroke * u_opacity, 0.0, 1.0);
            }
        `,
        )
    }

    dispose(): void {
        this.context.releasePainter(this.painter)
    }

    paint(
        cmdQueue: ImageOpCommandQueueWebGL2,
        args: {
            resultImage: ImageRef
            preStrokeBrushedImage: ImageRef
            currentStrokeImage: ImageRef
            brushOpacity: number
            brushMode: BrushMode
            boundingBox: Box2Like
        },
    ) {
        assertSameContext(cmdQueue, this.context)
        cmdQueue.paint(this.painter, {
            resultImage: args.resultImage,
            parameters: {
                u_opacity: {type: "float", value: args.brushOpacity * (args.brushMode === BrushMode.Add ? 1 : -1)},
            },
            sourceImages: [args.preStrokeBrushedImage, args.currentStrokeImage],
            options: {
                sourceRegion: Box2.fromBox2Like(args.boundingBox),
                targetOffset: {x: args.boundingBox.x, y: args.boundingBox.y},
            },
        })
    }

    private readonly painter: PainterBlitterRef
}
