import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SelectHdriItemFragment = { __typename: 'Hdri', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', legacyId: number, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null };

export type SelectHdriItemsQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.HdriFilterInput>;
}>;


export type SelectHdriItemsQuery = { __typename: 'Query', totalCount: number, items: Array<{ __typename: 'Hdri', id: string, legacyId: number, name?: string | null, organization: { __typename: 'Organization', legacyId: number, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null } | null> };

export const SelectHdriItemFragmentDoc = gql`
    fragment SelectHdriItem on Hdri {
  id
  legacyId
  name
  organization {
    legacyId
    name
  }
  galleryImage {
    id
  }
}
    `;
export const SelectHdriItemsDocument = gql`
    query selectHdriItems($skip: Int, $take: Int, $filter: HdriFilterInput) {
  items: hdris(skip: $skip, take: $take, filter: $filter) {
    ...SelectHdriItem
  }
  totalCount: hdrisCount(filter: $filter)
}
    ${SelectHdriItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SelectHdriItemsGQL extends Apollo.Query<SelectHdriItemsQuery, SelectHdriItemsQueryVariables> {
    override document = SelectHdriItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }