import {Component, input} from "@angular/core"
import {Output} from "@cm/template-nodes"
import {TemplateNodeComponent} from "../template-node/template-node.component"

@Component({
    selector: "cm-template-instance-output",
    templateUrl: "./template-instance-output.component.html",
    styleUrl: "./template-instance-output.component.scss",
    imports: [TemplateNodeComponent],
})
export class TemplateInstanceOutputComponent {
    readonly $node = input.required<Output>({alias: "node"})
}
