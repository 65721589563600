import {OutputEmitterRef} from "@angular/core"
import {
    TextureSetDataFragment,
    TextureEditorDataObjectFragment,
    TextureSetRevisionDataFragment,
    TextureSetRevisionDescriptorFragment,
} from "@app/textures/service/textures-api.generated"
import {TextureType} from "@generated"
import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"
import {Size2Like} from "@cm/math"

export type SourceImageData = {dataObjectId: string; isResult?: boolean}
export type SourceData = {sourceImage: SourceImageData; textureEdits?: TextureEditNodes.TextureEdits | null}

export interface TextureEditorCallback {
    readonly sourceDataChanged: OutputEmitterRef<SourceData | null>

    data?: TextureEditorData

    readonly isDebugEnabled: boolean
}

export type TextureSetRevisionSpecific = {
    textureSetRevision: TextureSetRevisionDataFragment & {version: number}
    textureEdits?: TextureEditNodes.TextureEdits
    availableTextureTypes: TextureType[]
    textureSet: TextureSetDataFragment
    sortedTextureSetRevisionDescriptors: (TextureSetRevisionDescriptorFragment & {version: number})[]
    isInUse: boolean
    hasValidResolutions: boolean
    hasValidRatio: boolean
    autoGenerateDisplacementMap: boolean
}

export type TextureTypeSpecific = {
    textureType: TextureType
    sourceDataObject: TextureEditorDataObjectFragment
    resultDataObject: TextureEditorDataObjectFragment
    pxPerCm: Size2Like
}

export type TextureEditorData = {
    textureSetRevisionSpecific: TextureSetRevisionSpecific
    textureTypeSpecific: TextureTypeSpecific | undefined
}

export const getBakedMapDataObjectIds = (data: TextureSetRevisionDataFragment): Map<TextureType, string> => {
    return new Map(data.mapAssignments.map((assignment) => [assignment.textureType, assignment.dataObject.id] as [TextureType, string]))
}

export const getSourceMapDataObjectIds = (data: TextureSetRevisionDataFragment): Map<TextureType, string> => {
    // if we have texture edits, the source maps are stored in the maps object, otherwise we use the map assignments
    const editsJson = data.editsJson as TextureEditNodes.TextureEdits
    if (editsJson) {
        return new Map(Object.entries(editsJson.maps.dataObjectByTextureType).map(([key, value]) => [key, value.dataObjectId]) as [TextureType, string][])
    } else {
        return getBakedMapDataObjectIds(data)
    }
}
