import {Component, input} from "@angular/core"
import {MatMenu, MatMenuItem} from "@angular/material/menu"
import {MatIcon} from "@angular/material/icon"

@Component({
    selector: "cm-copy-value-to-clipboard-menu",
    imports: [MatIcon, MatMenuItem],
    templateUrl: "./copy-value-to-clipboard-menu.component.html",
    styleUrl: "./copy-value-to-clipboard-menu.component.scss",
})
export class CopyValueToClipboardMenuComponent extends MatMenu {
    readonly $copyItems = input<{value: string | undefined | (() => string | undefined); displayText: string; icon?: string}[]>([], {alias: "copyItems"})

    async copyValueToClipboard(event: Event, item: {value: string | undefined | (() => string | undefined)}) {
        const value = item.value instanceof Function ? item.value() : item.value
        await navigator.clipboard.writeText(value ?? "")
        event.stopPropagation()
    }
}
