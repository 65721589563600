import {Component, viewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {DisplacementInputs, DisplacementOutputs, Space} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-displacement-node",
    templateUrl: "./displacement-node.component.html",
    styleUrls: ["./displacement-node.component.scss"],
    imports: [NodeBaseComponent],
})
export class DisplacementNodeComponent {
    readonly $nodeBase = viewChild<MaterialNodeBase>("nodeBase")
    outputs = DisplacementOutputs
    inputs = DisplacementInputs
    typeInfo = DisplacementNodeType
    settings = [Space]
}

export const DisplacementNodeType: MaterialNodeType<typeof DisplacementNodeComponent> = {
    id: "displacement",
    label: "Displacement",
    color: "#6e5eb2",
    name: "ShaderNodeDisplacement",
    inputs: [DisplacementInputs.height, DisplacementInputs.midlevel, DisplacementInputs.scale, DisplacementInputs.normal],
    outputs: [DisplacementOutputs.vector],
    component: DisplacementNodeComponent,
}
