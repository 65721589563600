import {Component, computed, inject, input} from "@angular/core"
import {MatListModule} from "@angular/material/list"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {HdriFilterInput, MaterialFilterInput, TemplateFilterInput} from "@generated"
import {AuthService} from "@app/common/services/auth/auth.service"
import {OrganizationsService} from "@app/common/services/organizations/organizations.service"
import {getNodeIconClass} from "@app/template-editor/helpers/template-icons"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {TemplateNodeClipboardService} from "@app/template-editor/services/template-node-clipboard.service"
import {
    BooleanLikes,
    BooleanSwitch,
    ConfigGroup,
    ConfigVariant,
    getTemplateSwitchItemLabel,
    ImageLikes,
    ImageSwitch,
    isBooleanLikeNode,
    isImageLike,
    isMaterialLike,
    isMeshLike,
    isNodeOwner,
    isNumberLikeNode,
    isObject,
    isObjectLike,
    isStringLikeNode,
    isSwitch,
    isTemplateLike,
    JSONLikes,
    JSONSwitch,
    MaterialLikes,
    MaterialSwitch,
    Node,
    NodeOwner,
    Nodes,
    NumberLikes,
    NumberSwitch,
    Object,
    ObjectLikes,
    ObjectSwitch,
    SceneNodes,
    StringLikes,
    StringSwitch,
    Switch,
    TemplateLikes,
    TemplateListNode,
    TemplateSwitch,
} from "@cm/template-nodes"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {TemplateMenuItemComponent} from "@template-editor/components/template-menu-item/template-menu-item.component"
import {TemplateMenuSectionComponent} from "@template-editor/components/template-menu-section/template-menu-section.component"
import {TemplateMenuComponent} from "@template-editor/components/template-menu/template-menu.component"
import {TemplateTreeComponent, TemplateTreeNode} from "@template-editor/components/template-tree/template-tree.component"
import {v4 as uuid4} from "uuid"
import {CurveLikes, CurveSwitch, MeshLikes, MeshSwitch} from "@cm/template-nodes/nodes/switch"
import {isCurveLike} from "@cm/template-nodes/node-types"

@Component({
    selector: "cm-template-tree-add",
    templateUrl: "./template-tree-add.component.html",
    styleUrls: ["./template-tree-add.component.scss", "./../../helpers/template-icons.scss"],
    imports: [MatTooltipModule, TemplateMenuComponent, TemplateMenuSectionComponent, MatMenuModule, TemplateMenuItemComponent, MatListModule],
})
export class TemplateTreeAddComponent {
    readonly $treeNode = input.required<TemplateTreeNode<NodeOwner | Switch>>({alias: "treeNode"})
    readonly $templateTree = input.required<TemplateTreeComponent>({alias: "templateTree"})
    private underCursor = false
    mode: "placeholder" | "buttons" | "menu" = "placeholder"
    public readonly authService = inject(AuthService)
    readonly organizations = inject(OrganizationsService)
    protected readonly permission = inject(PermissionsService)
    readonly clipboardService = inject(TemplateNodeClipboardService)
    $can = this.permission.$to

    hdriFilters: HdriFilterInput = {}
    templateFilters: TemplateFilterInput = {}
    materialFilters: MaterialFilterInput = {hasCyclesMaterial: true}
    getNodeIconClass = getNodeIconClass
    private readonly sceneManagerService = inject(SceneManagerService)
    readonly $hasSelection = computed(() => this.sceneManagerService.$selectedNodeParts().length > 0)
    readonly $node = computed(() => this.$treeNode().node)
    readonly $configGroupNode = computed(() => {
        const node = this.$node()
        if (node instanceof ConfigGroup) return node
        else return undefined
    })
    readonly $switchNode = computed(() => {
        const node = this.$node()
        if (isSwitch(node)) return node
        else return undefined
    })
    readonly $nodeOwnerNode = computed(() => {
        const node = this.$node()
        if (isNodeOwner(node)) return node
        else return undefined
    })
    readonly $selectedNodes = computed(() => this.sceneManagerService.$selectedNodeParts().map((node) => node.templateNode))
    readonly $selectedPointGuideNodes = computed(() =>
        this.sceneManagerService
            .$selectedNodeParts()
            .map((templateNodePart) => this.sceneManagerService.getSceneNodeParts(templateNodePart))
            .flat()
            .map((node) => node.sceneNode)
            .filter((x): x is SceneNodes.Mesh => SceneNodes.Mesh.is(x)),
    )
    readonly $selectedObjects = computed(() => this.$selectedNodes().filter((node): node is Object => isObject(node)))

    MaterialSwitch = MaterialSwitch
    ImageSwitch = ImageSwitch
    TemplateSwitch = TemplateSwitch
    ObjectSwitch = ObjectSwitch
    MeshSwitch = MeshSwitch
    CurveSwitch = CurveSwitch
    StringSwitch = StringSwitch
    NumberSwitch = NumberSwitch
    BooleanSwitch = BooleanSwitch
    JSONSwitch = JSONSwitch

    getTemplateSwitchItemLabel = getTemplateSwitchItemLabel

    onMenuOpen() {
        this.mode = "menu"
        this.$templateTree().highlightNode(this.$treeNode(), "addMenu", true)
    }

    onMenuClose() {
        this.$templateTree().highlightNode(this.$treeNode(), "addMenu", false)
        this.mode = "buttons"
    }

    private addToConfigGroup(node: Node) {
        const configGroup = this.$configGroupNode()
        if (!configGroup) return

        this.sceneManagerService.modifyTemplateGraph(() => configGroup.parameters.nodes.addEntry(node))
    }

    addConfigVariant() {
        this.addToConfigGroup(new ConfigVariant({name: "Config Variant", nodes: new Nodes({list: []}), id: uuid4()}))
    }

    addMaterialSwitch() {
        this.addToConfigGroup(new MaterialSwitch({name: "Material Switch", nodes: new MaterialLikes({list: []})}))
    }

    addTemplateSwitch() {
        this.addToConfigGroup(new TemplateSwitch({name: "Template Switch", nodes: new TemplateLikes({list: []})}))
    }

    addImageSwitch() {
        this.addToConfigGroup(new ImageSwitch({name: "Image Switch", nodes: new ImageLikes({list: []})}))
    }

    addObjectSwitch() {
        this.addToConfigGroup(new ObjectSwitch({name: "Object Switch", nodes: new ObjectLikes({list: []})}))
    }

    addMeshSwitch() {
        this.addToConfigGroup(new MeshSwitch({name: "Mesh Switch", nodes: new MeshLikes({list: []})}))
    }

    addCurveSwitch() {
        this.addToConfigGroup(new CurveSwitch({name: "Curve Switch", nodes: new CurveLikes({list: []})}))
    }

    addStringSwitch() {
        this.addToConfigGroup(new StringSwitch({name: "String Switch", nodes: new StringLikes({list: []})}))
    }

    addNumberSwitch() {
        this.addToConfigGroup(new NumberSwitch({name: "Number Switch", nodes: new NumberLikes({list: []})}))
    }

    addBooleanSwitch() {
        this.addToConfigGroup(new BooleanSwitch({name: "Boolean Switch", nodes: new BooleanLikes({list: []})}))
    }

    addJSONSwitch() {
        this.addToConfigGroup(new JSONSwitch({name: "JSON Switch", nodes: new JSONLikes({list: []})}))
    }

    private addReference<T>(nodes: TemplateListNode<T>, node: T) {
        if (nodes.parameters.list.includes(node)) return
        nodes.addEntry(node)
    }

    addSelectionToSwitch() {
        const switchNode = this.$switchNode()
        if (!switchNode) return

        const otherSelectedNodes = this.$selectedNodes().filter((node) => node !== this.$node())

        if (otherSelectedNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() => {
            if (switchNode instanceof ObjectSwitch)
                otherSelectedNodes.filter(isObjectLike).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof MeshSwitch)
                otherSelectedNodes.filter(isMeshLike).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof CurveSwitch)
                otherSelectedNodes.filter(isCurveLike).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof MaterialSwitch)
                otherSelectedNodes.filter(isMaterialLike).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof TemplateSwitch)
                otherSelectedNodes.filter(isTemplateLike).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof ImageSwitch)
                otherSelectedNodes.filter(isImageLike).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof StringSwitch)
                otherSelectedNodes.filter(isStringLikeNode).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof NumberSwitch)
                otherSelectedNodes.filter(isNumberLikeNode).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof BooleanSwitch)
                otherSelectedNodes.filter(isBooleanLikeNode).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else throw new Error("Unsupported switch node type")
        })
    }
}
