import {Component, computed} from "@angular/core"
import {Camera} from "@cm/template-nodes"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {ValueSlotComponent} from "../../value-slot/value-slot.component"
import {SceneCamera, ThreeTemplateSceneViewerComponent} from "../../three-template-scene-viewer/three-template-scene-viewer.component"
import {SceneNodes} from "@cm/template-nodes"
import {ToneMappingInspectorComponent} from "../tone-mapping-inspector/tone-mapping-inspector.component"
import {ButtonComponent} from "../../../../common/components/buttons/button/button.component"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {Three as THREE} from "@cm/material-nodes/three"
import {updateThreeCamera} from "@app/template-editor/helpers/three-utils"
import {constrainTransformTargetableNode} from "@app/template-editor/helpers/transform"
import {Matrix4} from "@cm/math"
import {TransformMode} from "@app/template-editor/services/scene-manager.service"

@Component({
    selector: "cm-camera-inspector",
    templateUrl: "./camera-inspector.component.html",
    styleUrl: "./camera-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent, ThreeTemplateSceneViewerComponent, ToneMappingInspectorComponent, ButtonComponent],
})
export class CameraInspectorComponent extends BaseInspectorComponent<Camera> {
    readonly $sceneNode = computed(() => {
        const sceneNodes = this.sceneManagerService.getSceneNodeParts({templateNode: this.$node(), part: "root"}).map((part) => part.sceneNode)
        const camera = sceneNodes.find(SceneNodes.Camera.is)
        return camera
    })
    readonly $automaticTarget = computed(() => this.$parameters().automaticTarget)
    readonly $camera = computed<SceneCamera | undefined>(() => {
        const sceneNode = this.$sceneNode()
        if (!sceneNode) return undefined
        return {parameters: sceneNode, transform: sceneNode.transform}
    })

    chooseFocus() {
        const sceneNode = this.$sceneNode()
        if (!sceneNode) return

        this.sceneManagerService
            .watchForClickedSceneNodePart()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((sceneNodePartClickEvent) => {
                const {target} = sceneNodePartClickEvent
                if (target.length === 0) return

                const {intersection} = target[0]
                if (!intersection) return

                const {point} = intersection
                const clickPosition = new THREE.Vector3(point.x, point.y, point.z)

                const threeCamera = new THREE.PerspectiveCamera()
                updateThreeCamera(threeCamera, sceneNode, sceneNode.transform)

                const cameraPosition = new THREE.Vector3()
                const cameraDirection = new THREE.Vector3()
                threeCamera.getWorldPosition(cameraPosition)
                threeCamera.getWorldDirection(cameraDirection)

                const focalDistance = clickPosition.sub(cameraPosition).dot(cameraDirection)

                this.sceneManagerService.modifyTemplateGraph(() => {
                    this.$node().updateParameters({focalDistance})
                })
            })
    }

    onChangedTargeted(value: unknown) {
        if (typeof value === "boolean") {
            const node = this.$node()

            const {lockedTransform} = node.parameters
            if (!lockedTransform) return

            const {transform: constrainedTransform, target: constrainedTarget} = constrainTransformTargetableNode(
                this.sceneManagerService,
                new Matrix4(lockedTransform),
                {templateNode: node, part: "root"},
                TransformMode.Translate,
            )

            if (constrainedTransform) node.updateParameters({lockedTransform: constrainedTransform.toArray()})
            if (constrainedTarget) node.updateParameters({target: constrainedTarget.toArray()})
        }
    }
}
