<cm-input-container>
    <ng-container cm-top-label> {{ $label() }}</ng-container>
    <ng-container cm-input>
        <cm-native-input-text
            [initialValue]="$item()?.articleId ?? ''"
            [disabled]="!$canEdit()"
            (valueChange)="onChanged.emit({articleId: $event})"
            [placeholder]="''"
        ></cm-native-input-text>
    </ng-container>
</cm-input-container>
