import {CommonModule} from "@angular/common"
import {Component, inject, contentChild} from "@angular/core"
import {EntityResponsiveSidebarComponent} from "@common/components/entity/entity-responsive-sidebar/entity-responsive-sidebar.component"
import {InfiniteScrollAnchorComponent} from "@common/components/data/infinite-scroll-anchor/infinite-scroll-anchor.component"
import {BreakpointsService} from "@common/services/breakpoints/breakpoints.service"
import {TableContentDirective} from "@platform/directives/content/table-content.directive"
import {GridContentDirective} from "@platform/directives/content/grid-content.directive"

@Component({
    selector: "cm-responsive-table-layout",
    imports: [CommonModule, EntityResponsiveSidebarComponent, InfiniteScrollAnchorComponent],
    templateUrl: "./responsive-table-layout.component.html",
    styleUrl: "./responsive-table-layout.component.scss",
})
export class ResponsiveTableLayoutComponent {
    readonly breakpoints = inject(BreakpointsService)

    readonly gridContent = contentChild(GridContentDirective)
    readonly tableContent = contentChild(TableContentDirective)
}
