import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MaterialAssetsRenderingMaterialFragment = { __typename: 'Material', id: string, legacyId: number, name?: string | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null, organization?: { __typename: 'Organization', id: string, legacyId: number } | null };

export type MaterialAssetsRenderingMaterialQueryVariables = Types.Exact<{
  materialId: Types.Scalars['ID']['input'];
}>;


export type MaterialAssetsRenderingMaterialQuery = { __typename: 'Query', material: { __typename: 'Material', id: string, legacyId: number, name?: string | null, latestCyclesRevision?: { __typename: 'MaterialRevision', id: string, legacyId: number } | null, organization?: { __typename: 'Organization', id: string, legacyId: number } | null } };

export type MaterialAssetsRenderingCreateJobTaskMutationVariables = Types.Exact<{
  input: Types.MutationCreateJobInput;
}>;


export type MaterialAssetsRenderingCreateJobTaskMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string, legacyId: number } };

export const MaterialAssetsRenderingMaterialFragmentDoc = gql`
    fragment MaterialAssetsRenderingMaterial on Material {
  id
  legacyId
  name
  latestCyclesRevision {
    id
    legacyId
  }
  organization {
    id
    legacyId
  }
}
    `;
export const MaterialAssetsRenderingMaterialDocument = gql`
    query materialAssetsRenderingMaterial($materialId: ID!) {
  material(id: $materialId) {
    ...MaterialAssetsRenderingMaterial
  }
}
    ${MaterialAssetsRenderingMaterialFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialAssetsRenderingMaterialGQL extends Apollo.Query<MaterialAssetsRenderingMaterialQuery, MaterialAssetsRenderingMaterialQueryVariables> {
    override document = MaterialAssetsRenderingMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialAssetsRenderingCreateJobTaskDocument = gql`
    mutation materialAssetsRenderingCreateJobTask($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    id
    legacyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialAssetsRenderingCreateJobTaskGQL extends Apollo.Mutation<MaterialAssetsRenderingCreateJobTaskMutation, MaterialAssetsRenderingCreateJobTaskMutationVariables> {
    override document = MaterialAssetsRenderingCreateJobTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }