import {Component, Input, input, model, output} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatInputModule} from "@angular/material/input"
import {colorToString, parseColor} from "@cm/utils"

@Component({
    selector: "cm-color-input",
    templateUrl: "./color-input.component.html",
    styleUrls: ["./color-input.component.scss"],
    imports: [FormsModule, MatInputModule],
})
export class ColorInputComponent {
    readonly $label = input<string>(undefined, {alias: "label"})
    @Input() set value(tupleValue: [number, number, number]) {
        this.$hexValue.set(colorToString(tupleValue))
    }
    get value() {
        return parseColor(this.$hexValue())
    }
    readonly $hexValue = model("#ffffff", {alias: "hexValue"})

    changeValue(value: string) {
        this.$hexValue.set(value)
        this.hexValueChange.emit(this.$hexValue())
        this.valueChange.emit(this.value)
    }

    onFocus() {
        this.isPopupOpen.emit(true)
    }

    onBlur() {
        this.isPopupOpen.emit(false)
    }

    readonly hexValueChange = output<string>()
    readonly valueChange = output<[number, number, number]>()
    readonly isPopupOpen = output<boolean>()
}
