import {Vector2, Vector2Like} from "#math/vector2"

type paperLikeMatrix = {
    a: number
    b: number
    c: number
    d: number
    tx: number
    ty: number
}

export type Matrix3x2Like = {elements: number[]} | paperLikeMatrix | [number, number, number, number, number, number]

// TODO thoroughly test and extend this class !
// adopted from paperjs
/**
 * @name Matrix3x2
 *
 * @class An affine transformation matrix performs a linear mapping from 2D
 *     coordinates to other 2D coordinates that preserves the "straightness" and
 *     "parallelness" of lines.
 *
 * Such a coordinate transformation can be represented by a 3 row by 3
 * column matrix with an implied last row of `[ 0 0 1 ]`. This matrix
 * transforms source coordinates `(x, y)` into destination coordinates `(x',y')`
 * by considering them to be a column vector and multiplying the coordinate
 * vector by the matrix according to the following process:
 *
 *     [ x ]   [ a  c  tx ] [ x ]   [ a * x + c * y + tx ]
 *     [ y ] = [ b  d  ty ] [ y ] = [ b * x + d * y + ty ]
 *     [ 1 ]   [ 0  0  1  ] [ 1 ]   [         1          ]
 */
export class Matrix3x2 {
    elements: [number, number, number, number, number, number]

    constructor(elements?: ArrayLike<number>) {
        this.elements = [1, 0, 0, 1, 0, 0]
        if (elements !== undefined) {
            if (elements.length !== 6) {
                throw new Error("Matrix3x2 must have exactly 6 elements!")
            }
            for (let n = 0; n < 6; n++) {
                this.elements[n] = elements[n]
            }
        }
    }

    static identity(): Matrix3x2 {
        return new Matrix3x2()
    }

    toArray(): [number, number, number, number, number, number] {
        return this.elements
    }

    static fromArray(elements: ArrayLike<number>) {
        return new this(elements)
    }

    toArrayTransposed(): number[] {
        const e = this.elements
        return [e[0], e[2], e[1], e[3], e[4], e[5]]
    }

    static fromArrayTransposed(elements: ArrayLike<number> | any) {
        const e = elements
        return new this([e[0], e[2], e[1], e[3], e[4], e[5]])
    }

    static fromMatrix3x2Like(m: Matrix3x2Like) {
        if ("elements" in m) {
            return new this(m.elements)
        } else if (Array.isArray(m)) {
            return new this(m)
        } else {
            return new this([m.a, m.b, m.c, m.d, m.tx, m.ty])
        }
    }

    copy(): Matrix3x2 {
        return Matrix3x2.fromArray(this.toArray())
    }

    append(other: Matrix3x2): Matrix3x2 {
        const a1 = this.elements[0],
            b1 = this.elements[1],
            c1 = this.elements[2],
            d1 = this.elements[3],
            tx1 = this.elements[4],
            ty1 = this.elements[5],
            a2 = other.elements[0],
            b2 = other.elements[2],
            c2 = other.elements[1],
            d2 = other.elements[3],
            tx2 = other.elements[4],
            ty2 = other.elements[5]
        this.elements[0] = a2 * a1 + c2 * c1
        this.elements[1] = b2 * a1 + d2 * c1
        this.elements[2] = a2 * b1 + c2 * d1
        this.elements[3] = b2 * b1 + d2 * d1
        this.elements[4] = tx1 + tx2 * a1 + ty2 * c1
        this.elements[5] = ty1 + tx2 * b1 + ty2 * d1
        return this
    }

    multiply(other: Matrix3x2Like): Matrix3x2 {
        const a = this.elements
        const b = Matrix3x2.fromMatrix3x2Like(other).elements
        const a11 = a[0],
            a12 = a[2],
            a13 = a[4]
        const a21 = a[1],
            a22 = a[3],
            a23 = a[5]
        const b11 = b[0],
            b12 = b[2],
            b13 = b[4]
        const b21 = b[1],
            b22 = b[3],
            b23 = b[5]
        return new Matrix3x2([
            a11 * b11 + a12 * b21,
            a21 * b11 + a22 * b21,
            a11 * b12 + a12 * b22,
            a21 * b12 + a22 * b22,
            a11 * b13 + a12 * b23 + a13,
            a21 * b13 + a22 * b23 + a23,
        ])
    }

    translate(offset: Vector2Like): Matrix3x2 {
        this.elements[4] += offset.x * this.elements[0] + offset.y * this.elements[2]
        this.elements[5] += offset.x * this.elements[1] + offset.y * this.elements[3]
        return this
    }

    // angle in degrees; positive angle is clockwise
    rotate(angleInDegrees: number, center?: Vector2Like) {
        angleInDegrees *= Math.PI / 180
        center ??= {x: 0, y: 0}
        const x = center.x
        const y = center.y
        const cos = Math.cos(angleInDegrees)
        const sin = Math.sin(angleInDegrees)
        const tx = x - x * cos + y * sin
        const ty = y - x * sin - y * cos
        const a = this.elements[0]
        const b = this.elements[1]
        const c = this.elements[2]
        const d = this.elements[3]
        this.elements[0] = cos * a + sin * c
        this.elements[1] = cos * b + sin * d
        this.elements[2] = -sin * a + cos * c
        this.elements[3] = -sin * b + cos * d
        this.elements[4] += tx * a + ty * c
        this.elements[5] += tx * b + ty * d
        return this
    }

    scale(scale: Vector2, center?: Vector2Like) {
        if (center) {
            this.translate(center)
        }
        this.elements[0] *= scale.x
        this.elements[1] *= scale.x
        this.elements[2] *= scale.y
        this.elements[3] *= scale.y
        if (center) {
            this.translate({x: -center.x, y: -center.y})
        }
        return this
    }

    inverse(): Matrix3x2 {
        const ret = new Matrix3x2()
        const e = this.elements
        const re = ret.elements
        const a = e[0],
            b = e[1],
            c = e[2],
            d = e[3],
            tx = e[4],
            ty = e[5],
            det = a * d - b * c
        if (!det || isNaN(det) || !isFinite(tx) || !isFinite(ty)) {
            console.warn("Could not compute matrix inverse!")
            return ret
        }
        re[0] = d / det
        re[1] = -b / det
        re[2] = -c / det
        re[3] = a / det
        re[4] = (c * ty - d * tx) / det
        re[5] = (b * tx - a * ty) / det
        return ret
    }

    multiplyVector(v: Vector2Like) {
        const e = this.elements
        const x = e[0] * v.x + e[2] * v.y + e[4]
        const y = e[1] * v.x + e[3] * v.y + e[5]
        return new Vector2(x, y)
    }
}
