import {Component, DestroyRef, inject, input, output} from "@angular/core"
import {provideAnimations} from "@angular/platform-browser/animations"
import {CardErrorComponent, CardPlaceholderComponent} from "@common/components/cards"
import {cardAnimations} from "@common/components/cards/animations"
import {DialogSize} from "@common/models/dialogs"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {EntityResponsiveSidebarComponent} from "@common/components/entity/entity-responsive-sidebar/entity-responsive-sidebar.component"
import {ItemFiltersComponent} from "@common/components/item"
import {GridSize, InfiniteListComponent} from "@common/components/lists"
import {LoadedData} from "@platform/models/data"
import {Subject} from "rxjs"
import {JobState} from "@generated"

@Component({
    selector: "cm-select-dialog",
    imports: [CardPlaceholderComponent, EntityCardComponent, CardErrorComponent, InfiniteListComponent, ItemFiltersComponent, EntityResponsiveSidebarComponent],
    templateUrl: "./select-dialog.component.html",
    styleUrl: "./select-dialog.component.scss",
    animations: [...cardAnimations],
    providers: [provideAnimations()],
})
export class SelectDialogComponent<
    EntityType extends {
        name?: string | null
        legacyId?: number
        organization?: {id?: string; name?: string | null} | null
        thumbnail?: {downloadUrl: string}
        thumbnailProcessingJob?: {state: JobState}
    } = {
        name?: string | null
        legacyId?: number
        organization?: {id?: string; name?: string | null} | null
        thumbnail?: {downloadUrl: string}
        thumbnailProcessingJob?: {state: JobState}
    },
> {
    readonly $data = input<LoadedData<EntityType>>(undefined, {alias: "data"})
    readonly $pageFilledStatusChange = input.required<Subject<boolean>>({alias: "pageFilledStatusChange"})
    readonly $gridSize = input<GridSize>("large", {alias: "gridSize"})
    readonly selectItem = output<EntityType>()

    readonly cancel = output<void>()

    readonly $title = input<string>("Select an entity", {alias: "title"})

    readonly destroyRef = inject(DestroyRef)

    selectEntity(entity: EntityType) {
        this.selectItem.emit(entity)
    }

    protected readonly DialogSize = DialogSize
}
