import {EventEmitter} from "@angular/core"
import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"
import {TextureEditorSettings} from "app/textures/texture-editor/texture-editor-settings"
import {
    Operator,
    OperatorCanvasToolboxType,
    OperatorFlags,
    OperatorInput,
    OperatorOutput,
    OperatorPanelComponentType,
    OperatorProcessingHints,
    OperatorType,
} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorCallback} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {TextureType} from "@generated"
import {Subject} from "rxjs"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {v4 as uuidv4} from "uuid"

const TRACE = TextureEditorSettings.EnableFullTrace

export abstract class OperatorBase<NodeType extends TextureEditNodes.Operator | undefined> implements Operator {
    readonly trackId = uuidv4()

    readonly requestEvaluation = new EventEmitter<void>()

    readonly flags = new Set<OperatorFlags>()

    abstract readonly panelComponentType?: OperatorPanelComponentType
    abstract readonly canvasToolbox?: OperatorCanvasToolboxType

    abstract readonly type: OperatorType

    abstract clone(): Promise<Operator>

    abstract queueImageOps(cmdQueue: ImageOpCommandQueue, input: OperatorInput, hints: OperatorProcessingHints): Promise<OperatorOutput>

    constructor(
        readonly callback: OperatorCallback,
        node: NodeType,
    ) {
        if (TRACE) {
            console.log("Creating operator")
        }
        this._node = node
    }

    async init() {
        if (TRACE) {
            console.log("Initializing operator")
        }
    }

    dispose(): void {
        if (TRACE) {
            console.log("Disposing operator")
        }
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }

    setShouldApplyTo(textureType: TextureType, enabled: boolean) {
        if (!this.node) {
            return
        }
        if (!this.node.ignoreTextureTypes) {
            this.node.ignoreTextureTypes = []
        }
        if (enabled) {
            // remove from ignore list
            const index = this.node.ignoreTextureTypes.indexOf(textureType)
            if (index >= 0) {
                this.node.ignoreTextureTypes.splice(index, 1)
            }
        } else {
            // add to ignore list
            if (this.node.ignoreTextureTypes.indexOf(textureType) < 0) {
                this.node.ignoreTextureTypes.push(textureType)
            }
        }
        this.markEdited()
    }

    shouldApplyTo(textureType: TextureType): boolean {
        if (!this.enabled) {
            return false
        }
        if (this.flags.has("apply-to-all-texture-types")) {
            return true
        }
        if (textureType === TextureType.Displacement) {
            return this.shouldApplyTo(TextureType.Normal) // displacement is always mapped to whatever setting the normal map has, because it is auto generated from it
        } else {
            return !this.ignoreTextureTypes.includes(textureType)
        }
    }

    get node(): NodeType {
        return this._node
    }

    get selected(): boolean {
        return this.callback.selectedOperator === this
    }

    set locked(value: boolean) {
        if (this._node && this._node.locked !== value) {
            this._node.locked = value
            this.markEdited()
        }
    }

    get locked(): boolean {
        return this._node?.locked ?? false
    }

    set enabled(value: boolean) {
        if (this.flags.has("no-disable") && !value) {
            console.warn("Tried to disable operator that has the 'no-disable' flag")
            return
        }
        if (this._node && this._node.enabled !== value) {
            this._node.enabled = value
            this.markEdited()
        }
    }

    get enabled(): boolean {
        return this._node?.enabled ?? true
    }

    private get ignoreTextureTypes(): TextureType[] {
        return this._node?.ignoreTextureTypes ?? []
    }

    get edited(): boolean {
        return this._isEdited
    }

    requestEval(): void {
        this.requestEvaluation.emit()
    }

    markEdited(): void {
        this._isEdited = true
    }

    async save(_processingJobId: string): Promise<TextureEditNodes.Operator | undefined> {
        this.resetEdited()
        return this._node
    }

    protected resetEdited(): void {
        this._isEdited = false
    }

    protected unsubscribe = new Subject<void>()

    private _node: NodeType
    private _isEdited = false
}
