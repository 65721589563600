<cm-routed-dialog [dialogSize]="DialogSize.Small">
    <ng-container cm-overlay-content>
        <cm-details-dialog-layout [loadError]="$loadError()" [isLoaded]="!!$item()">
            <ng-container cm-title>
                <cm-title-section
                    [title]="title"
                    [isLoaded]="$item() !== undefined"
                    [canEdit]="$can().update.asset($item(), 'name')"
                    (onChanged)="updateItemSubject.next({name: $event})"
                    (onClosed)="closeModal()"
                ></cm-title-section>
            </ng-container>

            <ng-container cm-buttons>
                @if ($item(); as asset) {
                    <button class="cm-simple-window-header-button cm-item-menu" [matMenuTriggerFor]="moreMenu">
                        <i class="fas fa-ellipsis-v"></i>
                    </button>
                    <mat-menu #moreMenu="matMenu">
                        <button mat-menu-item (click)="removeGalleryImage()" [disabled]="!canRemoveGalleryImage">Remove gallery image</button>
                        <button mat-menu-item (click)="requestDeleteConfirmation()" [disabled]="!asset || !$can().delete.item(asset)">Delete asset</button>
                    </mat-menu>
                }
            </ng-container>
            <ng-container cm-main>
                @if ($item(); as asset) {
                    <cm-corners-layout>
                        <cm-gallery-image [item]="asset"></cm-gallery-image>
                        @if ($can().read.asset(asset, "labels")) {
                            <div cm-bottom-left class="cm-state-container">
                                <cm-state-label
                                    [stateLabels]="Labels.AssetState"
                                    [state]="asset.state"
                                    [editable]="true"
                                    (stateChange)="updateItem({state: $event})"
                                ></cm-state-label>
                            </div>
                        }
                        <cm-copy-legacy-id-button cm-bottom-right [legacyId]="asset.legacyId"></cm-copy-legacy-id-button>
                    </cm-corners-layout>
                    @if ($can().read.asset(asset, "tasks")) {
                        <cm-project-management-tasks-section [item]="asset"></cm-project-management-tasks-section>
                    }
                    <cm-section>
                        <ng-container cm-icon><i class="far fa-cube"></i></ng-container>
                        <ng-container cm-title> Model and Materials</ng-container>
                        <ng-container cm-content>
                            <div class="cm-model-materials">
                                <cm-entity-card
                                    [entity]="asset.model"
                                    [entityLink]="['/models', asset.model.id]"
                                    [style.pointer-events]="$can().open.model(asset.model) ? 'auto' : 'none'"
                                >
                                    <ng-container cm-bottom-left>
                                        <cm-state-label [stateLabels]="Labels.ModelState" [state]="asset.model.state" [editable]="false"></cm-state-label>
                                    </ng-container>
                                </cm-entity-card>
                                @for (materialAssignment of asset.materialAssignments; track materialAssignment.id) {
                                    <cm-entity-card
                                        [entity]="materialAssignment.material"
                                        [entityLink]="['/materials', materialAssignment.material.id]"
                                        [style.pointer-events]="$can().open.material(materialAssignment.material) ? 'auto' : 'none'"
                                    >
                                        <ng-container cm-bottom-left>
                                            <cm-state-label
                                                [stateLabels]="Labels.MaterialState"
                                                [state]="materialAssignment.material.state"
                                                [editable]="false"
                                            ></cm-state-label>
                                        </ng-container>
                                        <ng-container cm-top-right>
                                            @if ($can().delete.materialAssignment(materialAssignment)) {
                                                <i
                                                    (click)="$event.stopPropagation(); deleteMaterialAssignment(materialAssignment)"
                                                    class="cm-shaded-model-component-delete far fa-trash"
                                                    aria-label="Delete"
                                                ></i>
                                            }
                                        </ng-container>
                                    </cm-entity-card>
                                }
                                @if ($can().create.materialAssignment(null)) {
                                    <cm-entity-add-card (click)="openSelectMaterialDialog()"></cm-entity-add-card>
                                }
                            </div>
                        </ng-container>
                    </cm-section>
                    @if ($can().read.asset(asset, "comment")) {
                        <cm-comment-section
                            [item]="asset"
                            [canEdit]="$can().update.asset(asset, 'comment')"
                            (onChanged)="updateItemSubject.next($event)"
                        ></cm-comment-section>
                    }

                    @if ($can().read.asset(asset, "files")) {
                        <cm-files-section [item]="asset"></cm-files-section>
                    }

                    @if ($can().read.asset(asset, "activity")) {
                        <cm-activity-section [item]="asset"></cm-activity-section>
                    }
                }
            </ng-container>
            <ng-container cm-sidebar>
                @if ($item(); as asset) {
                    @if (asset.state === Enums.AssetState.InfoReview && asset.nextActor === Enums.NextActor.Customer) {
                        <cm-button (click)="updateItem({nextActor: Enums.NextActor.ProjectManager})" class="cm-action-button">
                            <ng-container cm-left-icon>
                                <i class="far fa-check"></i>
                            </ng-container>
                            <ng-container cm-text> Submit</ng-container>
                        </cm-button>
                    }
                    @if (canNavigateToPicture()) {
                        <cm-show-pictures-button [queryParamsKey]="'assetId'" [entityId]="asset.id"></cm-show-pictures-button>
                    }
                    <cm-article-id-section
                        [cmIsLoading]="!asset"
                        [item]="asset"
                        [canEdit]="$can().update.asset(asset, 'articleId')"
                        (onChanged)="updateItem($event)"
                    ></cm-article-id-section>
                    <cm-organization-select
                        [label]="'Customer'"
                        [updateOrganization]="updateItem.bind(this)"
                        [organizationId]="asset?.organization?.id ?? null"
                        [showIfViewedByOwner]="true"
                    ></cm-organization-select>
                }
            </ng-container>
        </cm-details-dialog-layout>
    </ng-container>
</cm-routed-dialog>
<router-outlet></router-outlet>
