import {DOCUMENT} from "@angular/common"
import {Component, Inject, input, output, Renderer2, signal} from "@angular/core"

@Component({
    selector: "cm-numeric-up-down-arrows",
    imports: [],
    templateUrl: "./numeric-up-down-arrows.component.html",
    styleUrl: "./numeric-up-down-arrows.component.scss",
})
export class NumericUpDownArrowsComponent {
    readonly $value = input.required<number>({alias: "value"})
    readonly $delta = input(1, {alias: "delta"})
    readonly $min = input<number>(undefined, {alias: "min"})
    readonly $max = input<number>(undefined, {alias: "max"})
    readonly valueChange = output<number>()
    readonly isDragging = output<boolean>()

    private initialY: number = 0
    private initialValue = 0
    readonly $isChanging = signal(false)

    constructor(
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    startChange(event: MouseEvent) {
        this.initialY = event.clientY
        this.initialValue = this.$value()

        this.document.addEventListener("mousemove", this.boundMouseMove)
        this.document.addEventListener("mouseup", this.boundMouseUp)
    }

    stopChange() {
        this.document.removeEventListener("mousemove", this.boundMouseMove)
        this.document.removeEventListener("mouseup", this.boundMouseUp)

        if (this.$isChanging()) {
            this.$isChanging.set(false)
            this.isDragging.emit(false)
            this.renderer.removeClass(this.document.body, "cm-dragging-cursor")
        }
    }

    private boundMouseMove = (event: MouseEvent) => this.onMouseMove(event)
    private boundMouseUp = (event: MouseEvent) => this.onMouseUp(event)

    onMouseMove(event: MouseEvent) {
        if (!this.$isChanging()) {
            this.$isChanging.set(true)
            this.isDragging.emit(true)
            this.renderer.addClass(this.document.body, "cm-dragging-cursor")
        }

        const currentY = event.clientY
        const changeAmount = (this.initialY - currentY) * this.$delta()
        const newValue = this.initialValue + changeAmount
        const min = this.$min()
        const max = this.$max()
        if (min !== undefined && newValue < min) this.valueChange.emit(min)
        else if (max !== undefined && newValue > max) this.valueChange.emit(max)
        else this.valueChange.emit(newValue)
    }

    onMouseUp(_event: MouseEvent) {
        this.stopChange()
    }

    tickIncrement() {
        this.valueChange.emit(this.$value() + this.$delta())
    }

    tickDecrement() {
        this.valueChange.emit(this.$value() - this.$delta())
    }
}
