import {inject, Injectable, signal} from "@angular/core"
import {toObservable} from "@angular/core/rxjs-interop"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {maybeEnum} from "@common/helpers/utils/enum"
import {AuthorizationSilo} from "@common/models/auth/authorization-silo"
import {LocalStorageService} from "@common/services/local-storage/local-storage.service"
import {ColormassOrganizationGQL, OrganizationGQL} from "@common/services/upload/upload.generated"
import {MembershipRole, OrganizationType, SystemRole} from "@generated"

@Injectable({
    providedIn: "root",
})
export class SiloService {
    $silo = signal<AuthorizationSilo | null | undefined>(undefined)
    silo$ = toObservable(this.$silo)

    readonly colormassOrganizationGql = inject(ColormassOrganizationGQL)
    readonly organizationGql = inject(OrganizationGQL)
    readonly localStorageService = inject(LocalStorageService)

    constructor() {
        void this.load()
    }

    async load() {
        const {systemRole, organizationId} = this.localStorageService.loadSilo()
        const organization = organizationId
            ? await fetchThrowingErrors(this.organizationGql)({id: organizationId}, {context: {withoutSilo: true}}).then(({organization}) => organization)
            : undefined
        this.$silo.set({organization, systemRole: maybeEnum(systemRole, SystemRole), label: systemRole ?? organization?.name ?? "-"})
    }

    activateMembership(membership: {
        __typename: "Membership"
        organization: {id: string; name?: string | null; type?: OrganizationType | null}
        role?: MembershipRole
    }) {
        this.$silo.set({organization: membership.organization, label: membership.organization.name ?? "-"})
        this.localStorageService.storeSilo({
            systemRole: null,
            organizationId: membership.organization.id,
        })
    }

    async activateSystemRole(systemRole: SystemRole) {
        try {
            this.localStorageService.storeSilo({
                systemRole,
                organizationId: null,
            })
            const {organizations} = await fetchThrowingErrors(this.colormassOrganizationGql)({}, {context: {withoutSilo: true}})
            this.localStorageService.storeSilo({
                organizationId: organizations?.[0]?.id,
            })
            this.$silo.set({systemRole, label: systemRole, organization: organizations?.[0]})
        } catch (error) {
            console.error("Failed to save auth silo to local storage", error)
        }
    }

    selectOrganization(organization: {__typename: "Organization"; id: string; name?: string | null; type?: OrganizationType | null}) {
        this.activateMembership({__typename: "Membership", organization, role: MembershipRole.Viewer})
        this.localStorageService.storeSilo({
            systemRole: null,
            organizationId: organization.id,
        })
    }
}
