import {Component, inject, OnInit, signal, input} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatOptionModule, MatOptionSelectionChange} from "@angular/material/core"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {IsLoadingDirective} from "@app/common/directives"
import {IsDefined} from "@cm/utils"
import {
    TagSelectAssignGQL,
    TagSelectTagAssignmentsGQL,
    TagSelectTagsGQL,
    TagSelectUnassignGQL,
} from "@common/components/inputs/select/tag-select/tag-select.generated"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {BasicTagInfoFragment} from "@common/services/tags/tags.generated"
import {TagSelectTagFragment} from "@app/common/components/inputs/select/tag-select/tag-select.generated"
import {ContentTypeModel, TagType} from "@generated"
import {UtilsService} from "@legacy/helpers/utils"

@Component({
    selector: "cm-tag-select",
    imports: [MatInputModule, MatSelectModule, MatFormFieldModule, MatOptionModule, FormsModule, IsLoadingDirective, MatButtonModule],
    templateUrl: "./tag-select.component.html",
    styleUrl: "./tag-select.component.scss",
})
export class TagSelectComponent implements OnInit {
    readonly $assignedToId = input.required<string>({alias: "assignedToId"})
    readonly $contentTypeModel = input.required<ContentTypeModel>({alias: "contentTypeModel"})
    readonly $organizationId = input<string>(undefined, {alias: "organizationId"})
    readonly $type = input<TagType>(undefined, {alias: "type"})
    readonly $label = input("Tags", {alias: "label"})
    readonly $canEdit = input(true, {alias: "canEdit"})
    readonly $includeTagsWithNoOrganization = input(false, {alias: "includeTagsWithNoOrganization"})

    readonly notifications = inject(NotificationsService)
    readonly tagsGql = inject(TagSelectTagsGQL)
    readonly tagAssignmentsGql = inject(TagSelectTagAssignmentsGQL)
    readonly utils = inject(UtilsService)

    options?: TagSelectTagFragment[]
    readonly $assigned = signal<{tagId: string; tagAssignmentId: string}[]>([])

    private readonly tagSelectAssign = inject(TagSelectAssignGQL)
    private readonly tagSelectUnassign = inject(TagSelectUnassignGQL)

    ngOnInit() {
        void this.refresh()
    }

    async refresh() {
        const type = this.$type()
        this.options = await fetchThrowingErrors(this.tagsGql)({
            filter: {
                tagType: type ? [type] : undefined,
                // use undefined to show all, null to show only tags with no organization
                organizationId: this.organizationIdFilter(this.$organizationId()),
            },
        }).then(({tags}) => tags.filter(IsDefined))
        if (this.$organizationId() && this.$includeTagsWithNoOrganization()) {
            const typeValue = this.$type()
            const optionsWithoutOrganization = await fetchThrowingErrors(this.tagsGql)({
                filter: {tagType: typeValue ? [typeValue] : undefined, organizationId: null},
            }).then(({tags}) => tags.filter(IsDefined))
            this.options = [...this.options, ...optionsWithoutOrganization]
        }
        this.options.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

        const assignments = await fetchThrowingErrors(this.tagAssignmentsGql)({
            filter: {objectId: this.$assignedToId(), contentTypeModel: this.$contentTypeModel()},
        }).then(({tagAssignments}) => tagAssignments.filter(IsDefined))
        const assignedOptions = assignments
            .map((assignment) => ({
                tagId: assignment.tag.id,
                tagAssignmentId: assignment.id,
            }))
            .filter((assigned) => this.options?.some((option) => option.id === assigned.tagId))
        this.$assigned.set(assignedOptions)
    }

    toggleTag(toggleEvent: MatOptionSelectionChange<TagSelectTagFragment>) {
        if (toggleEvent.isUserInput) {
            const tagId = toggleEvent.source.value.id
            void this.notifications.withUserFeedback(
                async () => {
                    const assignment = this.$assigned().find((assigned) => assigned.tagId === tagId)
                    if (assignment) {
                        await mutateThrowingErrors(this.tagSelectUnassign)({tagAssignmentId: assignment.tagAssignmentId})
                        this.$assigned.update((tags) => {
                            return tags.filter((assigned) => assigned.tagId !== tagId)
                        })
                    } else {
                        const {createTagAssignment: tagAssignment} = await mutateThrowingErrors(this.tagSelectAssign)({
                            tagId,
                            contentTypeModel: this.$contentTypeModel(),
                            objectId: this.$assignedToId(),
                        })
                        this.$assigned.update((tags) => {
                            return [...tags, {tagAssignmentId: tagAssignment.id, tagId}]
                        })
                    }
                },
                {
                    success: "Tag updated",
                    error: "Failed to update tag",
                },
            )
        }
    }

    compareAssignments(option: BasicTagInfoFragment, selection: {tagId: string}): boolean {
        return option.id === selection.tagId
    }

    organizationIdFilter(organizationId: string | null | undefined) {
        switch (organizationId) {
            case undefined:
                return undefined
            case null:
                return null
            default:
                return {equals: organizationId}
        }
    }
}
