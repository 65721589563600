import {inject, Injectable} from "@angular/core"
import {JobNodes} from "@cm/job-nodes/job-nodes"
import {uploadProcessingMetadataTask, uploadProcessingThumbnailsTask} from "@cm/job-nodes/upload-processing"
import {graphToJson} from "@cm/utils/graph-json"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {mutateThrowingErrors} from "@common/helpers/api/mutate"
import {Settings} from "@common/models/settings/settings"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {UploadProcessingCreateJobGQL, UploadProcessingDataObjectGQL} from "@common/services/upload/upload-processing.generated"

@Injectable({providedIn: "root"})
export class UploadProcessingService {
    readonly organization = inject(OrganizationsService)
    readonly createJobGql = inject(UploadProcessingCreateJobGQL)
    readonly uploadProcessingDataObject = inject(UploadProcessingDataObjectGQL)

    public async createUploadProcessingJob(dataObjectLegacyId: number, organizationId?: string) {
        const {dataObject} = await fetchThrowingErrors(this.uploadProcessingDataObject)({
            legacyId: dataObjectLegacyId,
        })
        // don't create another job if we are already processing this data object
        if (dataObject.metadataProcessingJob && dataObject.thumbnailProcessingJob) {
            return dataObject.metadataProcessingJob
        }
        const graph = JobNodes.jobGraph(
            JobNodes.task(uploadProcessingThumbnailsTask, {
                input: JobNodes.task(uploadProcessingMetadataTask, {
                    input: JobNodes.value({
                        dataObject: JobNodes.dataObjectReference(dataObjectLegacyId),
                    }),
                }),
            }),
            {
                platformVersion: Settings.APP_VERSION,
            },
        )
        return mutateThrowingErrors(this.createJobGql)({
            input: {
                metadataProcessingJobForDataObjectLegacyId: dataObjectLegacyId,
                name: `Upload processing for data object ${dataObjectLegacyId}`,
                graph: graphToJson(graph, console),
                organizationId: await this.organization.ensureUserIsMember(organizationId),
                thumbnailProcessingJobForDataObjectLegacyId: dataObjectLegacyId,
            },
        }).then(({createJob}) => createJob)
    }
}
