import {isBlobLike} from "#utils/legacy"
import {CmLogger} from "#utils/log"

type IdType = string | number
type ObjType = any
type ArrType = any
type ValueType = any
type JsonType = any
type KeyType = any
type FixupTargetType = ObjType | ArrType

const LOCAL_ID_KEY = "$_id"
const REFERENCE_ID_KEY = "$id"
const FALLBACK_ID_KEY = "id"

function traverseGraphToJson(
    value: ValueType,
    objectMap: Map<ObjType, ObjType>,
    visitedIdSet: Set<IdType>,
    makeLocalId: () => number,
    logger: CmLogger,
): ValueType {
    if (typeof value === "object") {
        if (value === null) {
            return null
        } else if (Array.isArray(value)) {
            const jsonValue: JsonType[] = []
            for (const elem of value) {
                jsonValue.push(traverseGraphToJson(elem, objectMap, visitedIdSet, makeLocalId, logger))
            }
            return jsonValue
        } else if (isBlobLike(value)) {
            //TODO: flag to disallow binary blobs
            return value
        } else {
            const existing = objectMap.get(value)
            if (existing) {
                let id = existing[LOCAL_ID_KEY]
                if (id === undefined) {
                    // assign local unique ID
                    existing[LOCAL_ID_KEY] = id = makeLocalId()
                } else {
                    const idType = typeof id
                    if (!(idType === "string" || idType === "number")) {
                        throw new Error(
                            `Object was referenced more than once while serializing, but it does not have an 'id' property with a string or number value: ${value}`,
                        )
                    }
                }
                return {[REFERENCE_ID_KEY]: id}
            } else {
                const id = value[LOCAL_ID_KEY]
                if (id !== undefined) {
                    const idType = typeof id
                    if (!(idType === "string" || idType === "number")) {
                        throw new Error(`Object with id found while serializing, but the id is not a string or number value: ${value}`)
                    } else if (visitedIdSet.has(id)) {
                        // throw new Error(`Object id ${id} appeared in more than one object while serializing`);
                        logger.error(`Object id ${id} appeared in more than one object while serializing:`, value)
                        // allow this for now, but replace with reference...
                        return {[REFERENCE_ID_KEY]: id}
                    } else {
                        visitedIdSet.add(id)
                    }
                }
                const jsonValue: JsonType = {}
                objectMap.set(value, jsonValue)
                for (const key in value) {
                    if (!key.startsWith("$")) {
                        jsonValue[key] = traverseGraphToJson(value[key], objectMap, visitedIdSet, makeLocalId, logger)
                    }
                }
                return jsonValue
            }
        }
    } else {
        return value
    }
}

export function graphToJson(root: ValueType, logger: CmLogger): JsonType {
    const objectMap = new Map<ObjType, ObjType>()
    const visitedIdSet = new Set<IdType>()
    let localIdCounter = 0
    const makeLocalId = () => {
        return ++localIdCounter
    }
    return traverseGraphToJson(root, objectMap, visitedIdSet, makeLocalId, logger)
}

function traversePrepareResolve(
    value: ValueType,
    valueParent: FixupTargetType,
    keyInParent: KeyType,
    objectMap: Map<IdType, ObjType>,
    fixupList: [FixupTargetType, KeyType, IdType][],
    isBlobLike: (suspectedBlob: unknown) => boolean,
    logger: CmLogger,
): ValueType {
    if (typeof value === "object") {
        if (value === null) {
            return null
        } else if (Array.isArray(value)) {
            const newValue: ValueType[] = []
            for (let idx = 0; idx < value.length; idx++) {
                newValue.push(traversePrepareResolve(value[idx], newValue, idx, objectMap, fixupList, isBlobLike, logger))
            }
            return newValue
        } else if (isBlobLike(value)) {
            //TODO: flag to disallow binary blobs
            return value
        } else {
            const refId = value[REFERENCE_ID_KEY]
            if (refId !== undefined) {
                const refIdType = typeof refId
                if (!(refIdType === "string" || refIdType === "number")) {
                    throw new Error(`Object with reference ${REFERENCE_ID_KEY} found while deserializing, but the id is not a string or number value: ${value}`)
                }
                if (valueParent === undefined) {
                    throw new Error(`Root value cannot be a reference!`)
                }
                fixupList.push([valueParent, keyInParent, refId])
                return undefined
            } else {
                const id = value[LOCAL_ID_KEY] ?? value[FALLBACK_ID_KEY]
                if (id !== undefined) {
                    const idType = typeof id
                    if (!(idType === "string" || idType === "number")) {
                        throw new Error(`Object with id found while deserializing, but the id is not a string or number value: ${value}`)
                    }
                    if (objectMap.has(id)) {
                        // throw new Error(`Object id ${id} appeared in more than one object while deserializing`);
                        logger.error(`Object id ${id} appeared in more than one object while deserializing:`, value)
                        // allow this for now...
                    }
                }
                const newValue: ObjType = {}
                for (const key in value) {
                    if (!key.startsWith("$")) {
                        newValue[key] = traversePrepareResolve(value[key], newValue, key, objectMap, fixupList, isBlobLike, logger)
                    }
                }
                if (id !== undefined) {
                    objectMap.set(id, newValue)
                }
                return newValue
            }
        }
    } else {
        return value // not an object, no need to clone
    }
}

function applyFixupList(objectMap: Map<IdType, ObjType>, fixupList: [FixupTargetType, KeyType, IdType][]): void {
    for (const [target, key, id] of fixupList) {
        const obj = objectMap.get(id)
        if (obj === undefined) {
            throw new Error(`Unresolved reference to object with id '${id}'`)
        }
        target[key] = obj
    }
}

export function jsonToGraph(root: JsonType, isBlobLike: (suspectedBlob: unknown) => boolean, logger: CmLogger): ValueType {
    const objectMap = new Map<IdType, ObjType>()
    const fixupList: [FixupTargetType, KeyType, IdType][] = []
    const resolvedRoot = traversePrepareResolve(root, undefined, undefined, objectMap, fixupList, isBlobLike, logger) // this will do a deep copy of the structure, which will be modified by applyFixupList
    applyFixupList(objectMap, fixupList)
    return resolvedRoot
}
