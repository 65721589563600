import {VisualPinTaskFragment, VisualPinTaskPinFragment} from "@platform/models/pictures/visual-pin/visual-pin.generated"
import {Observable, Subject} from "rxjs"
import * as paper from "paper"

export class VisualPin {
    private clickSource: Subject<void> = new Subject<void>()
    click: Observable<void> = this.clickSource.asObservable()
    public taskPin?: VisualPinTaskPinFragment
    public task?: VisualPinTaskFragment

    private pin?: paper.Group
    private pinLabel?: paper.PointText
    private pinSymbol?: paper.Path.Rectangle
    private pinBoundsWidth?: number
    private pinBoundsHeight?: number

    private scaleFactor = 0.8

    constructor(
        private project: paper.Project,
        private editable: boolean,
        private updatePosition?: (position: {x: number; y: number}) => Promise<void>,
        private logicalSize: paper.Size = new paper.Size(320, 320),
    ) {}

    draw(x?: number, y?: number) {
        if (!this.project.view) {
            return
        }
        let point: paper.Point
        if (this.taskPin) {
            point = new paper.Point(this.logicalSize.width * this.taskPin.x, this.logicalSize.height * this.taskPin.y)
        } else {
            if (!x || !y) {
                throw new Error("Either the taskPin attribute has to be set or you have to provide the arguments x and y.")
            }
            point = new paper.Point(x, y)
        }

        this.pinSymbol = new paper.Path.Rectangle({
            center: point,
            size: new paper.Size(20 * this.scaleFactor, 20 * this.scaleFactor),
            fillColor: "#f9f9f9",
            strokeColor: "#f9f9f9",
            strokeWidth: this.scaleFactor,
        })

        this.pinLabel = new paper.PointText(point)
        this.pinLabel.content = this.task?.number?.toString() ?? "?"
        this.pinLabel.style = {
            ...this.pinLabel.style,
            fontFamily: "Roboto",
            fontWeight: "bold",
            fontSize: 8 * this.scaleFactor,
            fillColor: new paper.Color("#808080"),
            justification: "center",
        }
        this.pinLabel.position = point
        this.pin = new paper.Group([this.pinSymbol, this.pinLabel])

        // Init mouse events
        this.pinBoundsWidth = this.pin.bounds.width
        this.pinBoundsHeight = this.pin.bounds.height

        if (this.editable) {
            this.pin.onMouseDrag = (event: paper.MouseEvent) => {
                // Normally this event is triggered even if the drag operation started outside of the pin.
                // Only drag around the active pin.
                if (this.pin !== this.project.activeLayer.lastChild) return
                const newX: number = event.point.x / this.logicalSize.width
                const newY: number = event.point.y / this.logicalSize.height
                // Do not allow to leave the picture
                if (newX > 0 && newX < 1 && newY > 0 && newY < 1) {
                    if (this.taskPin) {
                        this.taskPin.x = newX
                        this.taskPin.y = newY
                    }
                }
                this.update()
            }
        }

        let oldX: number | undefined
        let oldY: number | undefined
        const pin = this.pin
        pin.onMouseDown = () => {
            this.project.view.element.style.cursor = "grabbing"
            oldX = this.taskPin?.x
            oldY = this.taskPin?.y
            this.project.activeLayer.addChild(pin)
            this.clickSource.next()
        }

        pin.onMouseUp = (_event: paper.MouseEvent) => {
            this.project.view.element.style.cursor = "grab"
            if (this.pinBoundsWidth) {
                pin.bounds.width = this.pinBoundsWidth
            }
            if (this.pinBoundsHeight) {
                pin.bounds.height = this.pinBoundsHeight
            }
            this.update()
            const taskPin = this.taskPin
            if (taskPin) {
                const newX = taskPin.x
                const newY = taskPin.y
                if (newX !== oldX || newY !== oldY) {
                    void this.updatePosition?.({x: newX, y: newY})
                }
            }
        }

        this.project.view.update()
    }

    update() {
        if (this.project.view) {
            if (this.taskPin) {
                if (this.pinLabel) {
                    this.pinLabel.content = this.task?.number?.toString() ?? ""
                }
                if (this.pin) {
                    this.pin.position = new paper.Point(this.logicalSize.width * this.taskPin.x, this.logicalSize.height * this.taskPin.y)
                }
            }
            this.project.view.update()
        }
    }

    remove() {
        this.pin?.remove()
        this.project.view.update()
    }

    highlight(): void {
        if (this.pin?.children?.length && this.pin?.children?.length >= 2) {
            this.pin.children[0].fillColor = new paper.Color("#434a51")
            this.pin.children[1].fillColor = new paper.Color("white")
        }
    }

    unhighlight(): void {
        if (this.pin?.children?.length && this.pin?.children?.length >= 2) {
            this.pin.children[0].fillColor = new paper.Color("#f9f9f9")
            this.pin.children[1].fillColor = new paper.Color("#808080")
        }
    }
}
