import {Component, computed} from "@angular/core"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {SelectionPossibilities, ValueSlotComponent} from "../../value-slot/value-slot.component"
import {DecalMaskType, MeshDecal, SceneNodes} from "@cm/template-nodes"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MaterialAssignment} from "@cm/template-nodes"

const UV_PRECISION = 0.01

@Component({
    selector: "cm-mesh-decal-inspector",
    templateUrl: "./mesh-decal-inspector.component.html",
    styleUrl: "./mesh-decal-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent, ButtonComponent],
})
export class MeshDecalInspectorComponent extends BaseInspectorComponent<MeshDecal> {
    readonly $mesh = computed(() => this.$parameters().mesh)
    invertMaskPossibilities: SelectionPossibilities<boolean> = [
        {value: true, name: "White is transparent"},
        {value: false, name: "Black is transparent"},
    ]
    maskTypePossibilities: SelectionPossibilities<DecalMaskType> = [
        {value: "binary", name: "Binary"},
        {value: "opacity", name: "Opacity"},
    ]

    onRequestUpdate(materialAssignment: unknown) {
        if (materialAssignment instanceof MaterialAssignment) {
            this.sceneManagerService.modifyTemplateGraph(() => this.$node().updateParameters({materialAssignment: materialAssignment}))
        } else if (materialAssignment === null) {
            this.sceneManagerService.modifyTemplateGraph(() => this.$node().updateParameters({materialAssignment: null}))
        }
    }

    chooseUV() {
        const node = this.$node()
        if (!node) return

        const sceneNodeParts = this.sceneManagerService.getSceneNodeParts({templateNode: node, part: "root"})
        if (sceneNodeParts.length !== 1) return
        const mesh = sceneNodeParts[0].sceneNode
        if (!SceneNodes.Mesh.is(mesh)) return

        this.sceneManagerService
            .watchForClickedSceneNodePart()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((sceneNodePartClickEvent) => {
                const {target} = sceneNodePartClickEvent

                if (target.length === 0) return

                const {sceneNodePart, intersection} = target[0]
                if (!intersection) return

                const {uv} = intersection
                if (!uv) return

                const {sceneNode} = sceneNodePart
                if (sceneNode.topLevelObjectId !== mesh.parent?.topLevelObjectId) return

                const {x, y} = uv

                const u = Math.round(x / UV_PRECISION) * UV_PRECISION
                const v = Math.round(y / UV_PRECISION) * UV_PRECISION

                this.sceneManagerService.modifyTemplateGraph(() => {
                    this.$node().updateParameters({offset: [u, v]})
                })
            })
    }
}
