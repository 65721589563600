import {DeclareTemplateNodeTS, TemplateNodeClass} from "#template-nodes/declare-template-node"
import {EvaluableTemplateNode} from "#template-nodes/evaluable-template-node"
import {NodeEvaluator} from "#template-nodes/node-evaluator"
import {ImageLike, imageLike} from "#template-nodes/node-types"
import {NamedNodeParameters, namedNodeParameters} from "#template-nodes/nodes/named-node"
import {GraphBuilderScope} from "#template-nodes/runtime-graph/graph-builder-scope"
import {TemplateNode} from "#template-nodes/types"
import {skipped, visitNone} from "@cm/graph/declare-visitor-node"
import {registerNode} from "@cm/graph/register-node"
import {ImageGenerator} from "@cm/material-nodes/interfaces/image-generator"
import {wrapNodeOutput} from "@cm/material-nodes/material-node-graph"
import {mathNodeOperation} from "@cm/material-nodes/nodes/math"
import {hashObject} from "@cm/utils/hashing"
import {z} from "zod"

export const imageOperatorOperation = z.union([z.literal("SMOOTH_STEP"), mathNodeOperation])
export type ImageOperatorOperation = z.infer<typeof imageOperatorOperation>

const imageOperatorParameters = namedNodeParameters.merge(
    z.object({
        input: imageLike.nullable(),
        operation: imageOperatorOperation,
        value: z.number(),
    }),
)
export type ImageOperatorParameters = NamedNodeParameters & {
    input: ImageLike | null
    operation: ImageOperatorOperation
    value: number
}

@registerNode
export class ImageOperator
    extends (DeclareTemplateNodeTS<ImageOperatorParameters>(
        {
            validation: {paramsSchema: imageOperatorParameters},
            onVisited: {
                onFilterActive: ({parameters}) => {
                    const {input} = parameters
                    if (input === null) return skipped
                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "ImageOperator"},
    ) as TemplateNodeClass<ImageOperatorParameters>)
    implements EvaluableTemplateNode<ImageGenerator | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        const {input, ...rest} = this.parameters
        const inputImage = evaluator.evaluateImage(scope, input)

        return scope.pureLambda<[ImageGenerator | null, ImageOperatorParameters["operation"], number], ImageGenerator | null>(
            scope.tuple(inputImage, rest.operation, rest.value),
            ([inputImage, operation, value]) => {
                if (!inputImage) return null

                const {imageNode, metadata} = inputImage

                return {
                    imageNode: {
                        generator: (generatorData) => {
                            const image = imageNode.generator(generatorData)

                            if (operation === "SMOOTH_STEP") {
                                const clampedValue = wrapNodeOutput(
                                    {
                                        nodeType: "ShaderNodeMath",
                                        inputs: {
                                            Value: wrapNodeOutput(
                                                {
                                                    nodeType: "ShaderNodeMath",
                                                    inputs: {
                                                        Value: image.color,
                                                    },
                                                    parameters: {
                                                        "internal.operation": "MAXIMUM",
                                                        Value_001: 0,
                                                    },
                                                },
                                                "Value",
                                            ),
                                        },
                                        parameters: {
                                            "internal.operation": "MINIMUM",
                                            Value_001: 1,
                                        },
                                    },
                                    "Value",
                                )
                                if (value <= 0) {
                                    return {
                                        color: clampedValue,
                                        alpha: image.alpha,
                                    }
                                } else {
                                    // Implemented as (1-(1-clamp(x,0,1))^i)^(1/i)

                                    const oneMinusClampedValue = wrapNodeOutput(
                                        {
                                            nodeType: "ShaderNodeMath",
                                            inputs: {
                                                Value_001: clampedValue,
                                            },
                                            parameters: {
                                                "internal.operation": "SUBTRACT",
                                                Value: 1,
                                            },
                                        },
                                        "Value",
                                    )

                                    const pow = wrapNodeOutput(
                                        {
                                            nodeType: "ShaderNodeMath",
                                            inputs: {
                                                Value: oneMinusClampedValue,
                                            },
                                            parameters: {
                                                "internal.operation": "POWER",
                                                Value_001: value,
                                            },
                                        },
                                        "Value",
                                    )

                                    const oneMinusPow = wrapNodeOutput(
                                        {
                                            nodeType: "ShaderNodeMath",
                                            inputs: {
                                                Value_001: pow,
                                            },
                                            parameters: {
                                                "internal.operation": "SUBTRACT",
                                                Value: 1,
                                            },
                                        },
                                        "Value",
                                    )

                                    const root = wrapNodeOutput(
                                        {
                                            nodeType: "ShaderNodeMath",
                                            inputs: {
                                                Value: oneMinusPow,
                                            },
                                            parameters: {
                                                "internal.operation": "POWER",
                                                Value_001: 1 / value,
                                            },
                                        },
                                        "Value",
                                    )

                                    return {
                                        color: root,
                                        alpha: image.alpha,
                                    }
                                }
                            } else
                                return {
                                    color: wrapNodeOutput(
                                        {
                                            nodeType: "ShaderNodeMath",
                                            inputs: {
                                                Value: image.color,
                                            },
                                            parameters: {
                                                "internal.operation": operation,
                                                Value_001: value,
                                            },
                                        },
                                        "Value",
                                    ),
                                    alpha: image.alpha,
                                }
                        },
                        hash: hashObject({
                            type: "ImageOperator",
                            inputImage: imageNode.hash,
                            operation,
                            value,
                        }),
                    },
                    metadata,
                }
            },
            "imageOperator",
        )
    }
}

export type ImageOperatorFwd = TemplateNode<ImageOperatorParameters> & EvaluableTemplateNode<ImageGenerator | null>
