import {Component, input, computed, inject} from "@angular/core"
import {NgClass} from "@angular/common"
import {toSignal} from "@angular/core/rxjs-interop"
import {RouterModule} from "@angular/router"
import {FiltersService, checkFilterCriterionStatesEqual} from "@common/services/filters/filters.service"

/**
 * Single option for a pinned filter.
 */
@Component({
    selector: "cm-pinned-filter-option",
    templateUrl: "./pinned-filter-option.component.html",
    styleUrls: ["./pinned-filter-option.component.scss"],
    imports: [RouterModule, NgClass],
})
export class PinnedFilterOptionComponent {
    readonly $queryParams = input<{[name: string]: string | undefined} | undefined>(undefined, {alias: "queryParams"})
    readonly $indicateActivated = input<boolean>(true, {alias: "indicateActivated"})

    readonly filters = inject(FiltersService)
    readonly $currentFilterStates = toSignal(this.filters.states)

    readonly $isActivated = computed<boolean>(() => {
        if (!this.$indicateActivated()) return false
        const currentFilterStates = this.$currentFilterStates()
        const queryParams = this.$queryParams()

        if (!queryParams || !currentFilterStates) return false
        const filterCriteria = Object.fromEntries(
            Object.entries(queryParams)
                .filter((x): x is [string, string] => !!x[1])
                .map(([name, value]) => [name, new Set(value.split(","))]),
        )
        return checkFilterCriterionStatesEqual(filterCriteria, currentFilterStates.criteria)
    })
}
