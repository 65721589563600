// background-task.service.ts
import {Injectable} from "@angular/core"
import {BackgroundOperationItem} from "@app/common/models/background-operation"
import {Observable, Subject} from "rxjs"

@Injectable({
    providedIn: "root",
})
export class BackgroundOperationService {
    private backgroundOperationItemSource: Subject<BackgroundOperationItem> = new Subject<BackgroundOperationItem>()
    backgroundOperationItem$: Observable<BackgroundOperationItem> = this.backgroundOperationItemSource.asObservable()

    private closeToolbarAndCleanOperationsSource: Subject<void> = new Subject<void>()
    closeToolbarAndCleanOperations$ = this.closeToolbarAndCleanOperationsSource.asObservable()

    closeToolbarAndCleanOperations(): void {
        this.closeToolbarAndCleanOperationsSource.next()
    }

    constructor() {}

    public registerBackgroundOperation(backgroundOperationItem: BackgroundOperationItem): void {
        this.backgroundOperationItemSource.next(backgroundOperationItem)
    }

    public addBackgroundOperationToList(taskType: string, fileName: string, cancellable: boolean, discardOnCompletion = false): BackgroundOperationItem {
        const backgroundOperationItem: BackgroundOperationItem = new BackgroundOperationItem(taskType, fileName, cancellable, discardOnCompletion)
        this.backgroundOperationItemSource.next(backgroundOperationItem)
        return backgroundOperationItem
    }
}
