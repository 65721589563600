import {AfterViewInit, Component, ElementRef, EventEmitter, input, Input, OnDestroy, output, viewChild} from "@angular/core"
import {WebGl2CanvasComponent} from "@common/components/canvas/webgl2-canvas/webgl2-canvas.component"
import {CanvasNavigation} from "@common/helpers/canvas/canvas-navigation"
import {HalContext} from "@common/models/hal/hal-context"
import {HalImage} from "@common/models/hal/hal-image"
import {createHalImage} from "@common/models/hal/hal-image/create"
import {HalPainterImageStretch} from "@common/models/hal/common/hal-painter-image-stretch"
import * as paper from "paper"
import {fromEvent, map, merge, Observable, Subject, Subscription, switchMap, take, takeUntil} from "rxjs"
import {Box2, Box2Like, Matrix3x2, Vector2, Vector2Like} from "@cm/math"
import {cancelDeferredTask, queueDeferredTask} from "@cm/browser-utils"
import {CanvasBaseToolboxRootItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-root-item"
import {Hotkeys} from "@common/services/hotkeys/hotkeys.service"
import {outputToObservable} from "@angular/core/rxjs-interop"
import {RulerComponent} from "@common/components/ruler/ruler.component"
import {CanvasBaseToolboxItem, ToolKeyEvent, ToolMouseEvent, ToolWheelEvent} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"
import {ViewMode} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area/spatial-mapping-item"
import {MatMenuModule} from "@angular/material/menu"
import {NgClass} from "@angular/common"
import {MatDividerModule} from "@angular/material/divider"
import {assertNever} from "@cm/utils"

export const USE_MIPMAPS_FOR_CANVAS = true

@Component({
    selector: "cm-canvas-base",
    templateUrl: "./canvas-base.component.html",
    styleUrls: ["./canvas-base.component.scss"],
    imports: [WebGl2CanvasComponent, RulerComponent, MatMenuModule, MatDividerModule, NgClass],
})
export class CanvasBaseComponent implements AfterViewInit, OnDestroy {
    readonly $canvasElementRef = viewChild.required<ElementRef>("canvasElement")
    readonly $webGlCanvasComponent = viewChild.required<WebGl2CanvasComponent>("webGlCanvas")

    @Input() set imageUrl(url: string | null) {
        if (this.imageLoadSubscription) {
            this.imageLoadSubscription.unsubscribe()
        }
        if (url) this.imageLoadSubscription = this.loadImage(url, true).pipe(takeUntil(this.destroySubject)).subscribe() // assume sRGB
    }

    @Input() set imageData(value: ImageData) {
        if (!value) return
        const canvas: HTMLCanvasElement = document.createElement("canvas")
        canvas.width = value.width
        canvas.height = value.height
        canvas.getContext("2d")?.putImageData(value, 0, 0)
        if (this.imageLoadSubscription) {
            this.imageLoadSubscription.unsubscribe()
        }
        this.imageLoadSubscription = this.loadImage(canvas.toDataURL(), true).pipe(takeUntil(this.destroySubject)).subscribe() // assume sRGB
    }

    readonly $zoomToFitOnLoadingComplete = input(false, {alias: "zoomToFitOnLoadingComplete"})

    @Input() showRulers = false

    private _physicalInfo: CanvasPhysicalInfo | undefined
    @Input()
    set physicalInfo(value: CanvasPhysicalInfo) {
        this._physicalInfo = value
        if (this._navigation) {
            this._navigation.physicalInfo = value
        }
        this.physicalInfoChange.emit(value)
    }

    get physicalInfo(): CanvasPhysicalInfo {
        if (!this._physicalInfo) {
            throw Error("Attempting to retrieve physical-info which has never been set.")
        }
        return this._physicalInfo
    }

    readonly loadingComplete = output<void>()
    readonly loadingError = output<void>()
    readonly canvasBoundsChange = new EventEmitter<Box2>()
    readonly physicalInfoChange = new EventEmitter<CanvasPhysicalInfo | undefined>()

    constructor(readonly hotkeys: Hotkeys) {}

    ngAfterViewInit() {
        this._canvas = this.$canvasElementRef().nativeElement
        this._paperScope = new paper.PaperScope()
        this._paperScope.setup(this._canvas)
        this._paperProject = this._paperScope.project
        this._paperLayer = this._paperProject.activeLayer
        this._paperLayer.name = "PaperLayer"

        fromEvent<ErrorEvent>(this.image, "error")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((error: ErrorEvent) => {
                console.error("Cannot load image.", error)
                this.loadingError.emit()
            })

        this.$webGlCanvasComponent().resized.subscribe(() => this.drawWebGlCanvas())
        this._halPainterImageStretch = HalPainterImageStretch.create(this.halContext)

        this._paperScope.activate()
        this._navigation = new CanvasNavigation(this, this._canvas, this._paperProject, this.image)
        this._navigation.viewChange.pipe(takeUntil(this.destroySubject)).subscribe(() => this.onViewChanged())
        this._navigation.physicalInfo = this._physicalInfo
        this._navigation.zoomTo(1)
    }

    ngOnDestroy(): void {
        this._destroyed = true

        if (this._redrawRequestId != null) {
            cancelDeferredTask(this._redrawRequestId)
            this._redrawRequestId = null
        }

        this._halPainterImageStretch.dispose()
        this._halImage?.dispose()

        this._paperProject.clear()
        this._paperProject.remove()
        this.destroySubject.next()
        this.destroySubject.complete()
        this._navigation.destroy()
    }

    get destroyed(): boolean {
        return this._destroyed
    }

    get halContext(): HalContext {
        return this.$webGlCanvasComponent().halContext
    }

    get navigation(): CanvasNavigation {
        return this._navigation
    }

    get paperScope(): paper.PaperScope {
        return this._paperScope
    }

    get paperLayer(): paper.Layer {
        return this._paperLayer
    }

    get image(): HTMLImageElement {
        return this._image
    }

    get halImage(): HalImage | null {
        return this._halImage
    }

    get toolbox(): CanvasBaseToolboxRootItem | null {
        return this._navigation.toolboxRootItem
    }

    set toolbox(canvasToolbox: CanvasBaseToolboxRootItem | null) {
        this._navigation.toolboxRootItem = canvasToolbox
    }

    get canvasBounds(): Box2 {
        return this._canvasBounds
    }

    get canvasCursorPosition(): Vector2 {
        return this._navigation.canvasCursorPosition
    }

    get customDrawFn(): CustomDrawFn | null {
        return this._customDrawFn
    }

    set customDrawFn(value: CustomDrawFn | null) {
        this._customDrawFn = value
        this.requestRedraw()
    }

    get isSRGB(): boolean {
        return this._imageIsSrgb
    }

    set isSRGB(value: boolean) {
        this._imageIsSrgb = value
    }

    get displayGamma(): number {
        return this._displayGamma
    }

    set displayGamma(value: number) {
        this._displayGamma = value
    }

    private onViewChanged() {
        this.requestRedraw()
    }

    loadImage(url: string, isSRGB: boolean): Observable<void> {
        this.isSRGB = isSRGB
        this._image.crossOrigin = "anonymous"
        const loadObservable = fromEvent(this._image, "load").pipe(
            take(1),
            switchMap(async () => {
                await this.showOriginalImage()
                if (this.$zoomToFitOnLoadingComplete()) {
                    this._navigation.zoomToFitImage()
                }
                this.loadingComplete.emit()
            }),
        )
        this._image.src = url
        return merge(
            loadObservable,
            outputToObservable(this.loadingError).pipe(
                take(1),
                map(() => {
                    throw new Error("Failed to load image")
                }),
            ),
        )
    }

    async showOriginalImage() {
        await this.showImage(this._image, this._imageIsSrgb)
    }

    async showImage(image: HTMLImageElement, isSrgb: boolean): Promise<void> {
        this._halImage?.dispose()
        this._halImage = createHalImage(this.halContext, {
            width: image.width,
            height: image.height,
            channelLayout: "RGBA",
            dataType: isSrgb ? "uint8srgb" : "uint8",
            options: {useMipMaps: USE_MIPMAPS_FOR_CANVAS},
        })
        this._halImage.writeImageData({
            isSrgb: isSrgb,
            data: image,
        })
        await this.drawWebGlCanvas()
    }

    async showCanvas(canvas: HTMLCanvasElement, isSrgb: boolean): Promise<void> {
        this._halImage?.dispose()
        this._halImage = createHalImage(this.halContext, {
            width: canvas.width,
            height: canvas.height,
            channelLayout: "RGBA",
            dataType: isSrgb ? "uint8srgb" : "uint8",
            options: {useMipMaps: USE_MIPMAPS_FOR_CANVAS},
        })
        this._halImage.writeImageData({
            isSrgb: isSrgb,
            data: canvas,
        })
        await this.drawWebGlCanvas()
    }

    requestRedraw() {
        if (this._redrawRequestId == null && !this._destroyed) {
            this._redrawRequestId = queueDeferredTask(() => this.drawWebGlCanvas())
        }
    }

    get viewTransform(): paper.Matrix {
        const dpiCorrectedTransform = new paper.Matrix().scale(devicePixelRatio, devicePixelRatio)
        dpiCorrectedTransform.append(this._paperProject.view.matrix)
        return dpiCorrectedTransform
    }

    private async drawWebGlCanvas(): Promise<void> {
        this._redrawRequestId = null
        await this.$webGlCanvasComponent().clearBackBuffer()
        const drawFn = this._customDrawFn ?? this.defaultDrawFn.bind(this)
        const imageBounds = await drawFn()
        this.setCanvasBounds(imageBounds)
        await this.$webGlCanvasComponent().presentBackBuffer(this._imageIsSrgb, this._displayGamma)
    }

    private async defaultDrawFn(): Promise<Box2Like> {
        if (!this.halImage) {
            return {x: 0, y: 0, width: 0, height: 0}
        }
        this._halPainterImageStretch.paint(this.$webGlCanvasComponent().backBufferImage, this.halImage, {transform: this.viewTransform})
        return {x: 0, y: 0, width: this.halImage.descriptor.width, height: this.halImage.descriptor.height}
    }

    private setCanvasBounds(boundingBox: Box2Like) {
        if (this._canvasBounds.equals(boundingBox)) {
            return
        }
        this._canvasBounds = Box2.fromBox2Like(boundingBox)
        this.canvasBoundsChange.emit(this._canvasBounds)
    }

    protected get measurementUnitOptions(): MeasurementUnit[] {
        return ["px", "mm", "cm", "in"]
    }

    protected get measurementUnit(): MeasurementUnit {
        return this._measurementUnit
    }

    protected set measurementUnit(value: MeasurementUnit) {
        this._measurementUnit = value
    }

    protected get rulerPixelsPerUnit(): number {
        return (this.navigation?.getZoomLevel() ?? 1) * this.pixelsPerUnit(this.measurementUnit)
    }

    protected get rulerXOriginUnitOffset(): number {
        return (this.navigation?.getTopLeftPosition().x ?? 0) / this.pixelsPerUnit(this.measurementUnit)
    }

    protected get rulerYOriginUnitOffset(): number {
        return (this.navigation?.getTopLeftPosition().y ?? 0) / this.pixelsPerUnit(this.measurementUnit)
    }

    protected get rulerCursorPositionX(): number {
        return (this.navigation?.canvasCursorPosition.x ?? 0) / this.pixelsPerUnit(this.measurementUnit)
    }

    protected get rulerCursorPositionY(): number {
        return (this.navigation?.canvasCursorPosition.y ?? 0) / this.pixelsPerUnit(this.measurementUnit)
    }

    private pixelsPerUnit(unit: MeasurementUnit): number {
        switch (unit) {
            case "px":
                return 1
            case "mm":
                return this.physicalInfo.pixelsPerCm / 10
            case "cm":
                return this.physicalInfo.pixelsPerCm
            case "in":
                return this.physicalInfo.pixelsPerCm * 2.54
            default:
                assertNever(unit)
        }
    }

    private _redrawRequestId: number | null = null
    private _customDrawFn: CustomDrawFn | null = null
    private _halPainterImageStretch!: HalPainterImageStretch
    private _navigation!: CanvasNavigation
    private imageLoadSubscription!: Subscription
    private destroySubject = new Subject<void>()
    private _canvas!: HTMLCanvasElement
    private _image: HTMLImageElement = new Image()
    private _imageIsSrgb = false
    private _displayGamma = 1
    private _halImage: HalImage | null = null
    private _canvasBounds: Box2 = new Box2(0, 0, 0, 0)
    private _measurementUnit: MeasurementUnit = "cm"

    private _paperScope!: paper.PaperScope
    private _paperProject!: paper.Project
    private _paperLayer!: paper.Layer

    private _destroyed = false

    protected showCursorLabel = false

    // CanvasBaseToolboxItem dummy implementation (only beginPaperCreation is used)
    // TODO clen this up (probably by making the canvas-navigation the actual base item)
    get canvasBase(): CanvasBaseComponent {
        return this
    }

    readonly viewChange = new EventEmitter<Matrix3x2>()
    readonly cursorChange = new EventEmitter<string | undefined>()

    visible = true
    selected = false
    cursor: string | undefined

    get children(): readonly CanvasBaseToolboxItem[] {
        return []
    }

    remove(): void {}

    hitTest(_point: Vector2Like): CanvasBaseToolboxItem | null {
        return null
    }

    beginPaperCreation(): void {
        this._paperScope.activate()
        this._paperLayer.activate()
    }

    sendToBack(): void {}

    bringToFront(): void {}

    sendItemToBack(_item: CanvasBaseToolboxItem): void {}

    bringItemToFront(_item: CanvasBaseToolboxItem): void {}

    onKeyDown(_event: ToolKeyEvent): boolean {
        return true
    }

    onKeyUp(_event: ToolKeyEvent): boolean {
        return true
    }

    onMouseWheel(_event: ToolWheelEvent): boolean {
        return true
    }

    onMouseDown(_event: ToolMouseEvent): boolean {
        return true
    }

    onMouseUp(_event: ToolMouseEvent): boolean {
        return true
    }

    onMouseDrag(_event: ToolMouseEvent): boolean {
        return true
    }

    onMouseMove(_event: ToolMouseEvent): boolean {
        return true
    }

    onMouseLeave(_event: ToolMouseEvent): boolean {
        return true
    }

    addChildItem(_item: CanvasBaseToolboxItem): void {}

    protected readonly ViewMode = ViewMode
}

type CustomDrawFn = () => Promise<Box2Like> // returns the box of the drawn image

export type CanvasPhysicalInfo = {
    pixelsPerCm: number
    originXCm: number
    originYCm: number
}

export type MeasurementUnit = "px" | "mm" | "cm" | "in"
