import {ScanSubJobState} from "@generated"
import {StateLabel} from "@platform/models/state-labels/state-label"

export const ScanSubJobStateLabels: Map<ScanSubJobState, StateLabel<ScanSubJobState>> = new Map<ScanSubJobState, StateLabel<ScanSubJobState>>([
    [ScanSubJobState.Init, {state: ScanSubJobState.Init, label: "Init", background: "#989898"}],
    [ScanSubJobState.Processing, {state: ScanSubJobState.Processing, label: "Processing", background: "#ffab4a"}],
    [ScanSubJobState.Completed, {state: ScanSubJobState.Completed, label: "Completed", background: "#7ec17a"}],
    [ScanSubJobState.Aborted, {state: ScanSubJobState.Aborted, label: "Aborted", background: "#000000"}],
    [ScanSubJobState.Failed, {state: ScanSubJobState.Failed, label: "Failed", background: "#000000"}],
])
