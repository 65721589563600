<cm-card
    [defaultThumbnail]="false"
    [draggable]="$disabled() !== true && $node() !== undefined"
    (dragstart)="dragStart($event)"
    (dragend)="drag.dragEnd($event)"
    [disabled]="$disabled() === true"
    (selectItem)="onClicked()"
>
    <ng-container cm-thumbnail>
        @if ($iconClass(); as iconClass) {
            <div class="cm-icon-container">
                <i [class]="iconClass"></i>
            </div>
        } @else {
            <cm-data-object-thumbnail [dataObjectId]="$galleryImage()?.id" [layout]="ThumbnailLayout.Cover"></cm-data-object-thumbnail>
        }
    </ng-container>
    <ng-container cm-title>
        @if ($node(); as node) {
            <cm-template-node
                [node]="node"
                [name]="$data()?.name ?? undefined"
                [noDrag]="true"
                [alwaysEnabled]="true"
                [notClickable]="true"
                #dragImage
            ></cm-template-node>
        } @else {
            @if ($data(); as data) {
                <span [matTooltip]="data.name ?? ''"> {{ data.name }} </span>
            }
        }
    </ng-container>
    <ng-container cm-top-left>
        @if ($node(); as node) {
            @if ($disabled() !== true) {
                <div
                    matTooltip="Add to template"
                    (click)="addNode(); $event.stopPropagation()"
                    class="cm-icon"
                    [class.cm-black]="$iconClass() !== undefined || !$galleryImage()?.id"
                >
                    <i class="far fa-plus"></i>
                </div>
            }
        }
    </ng-container>
</cm-card>
