import type {CryptomatteId} from "#image-processing/matte-processing"
import type {ImageProcessingNodes as Nodes, PostProcessingSettings, MaskPostProcessingSettings, PostProcessingInputData} from "@cm/image-processing-nodes"
import {typedEntries} from "@cm/utils"

function subgraphForSettings(image: Nodes.ImageNode, settings: MaskPostProcessingSettings, mask?: Nodes.Mask): Nodes.ImageNode {
    if (mask) {
        image = {
            type: "applyMask",
            input: image,
            mask,
        }
    }
    if (settings.exposure) {
        image = {
            type: "adjustExposure",
            input: image,
            ev: Math.log2(settings.exposure),
        }
    }
    if (settings.whiteBalance) {
        image = {
            type: "whiteBalance",
            input: image,
            whitePoint: {type: "colorTemperature", temperature: settings.whiteBalance},
        }
    }
    if (settings.toneMapping && settings.toneMapping.mode !== "linear") {
        image = {
            type: "toneMap",
            input: image,
            ...settings.toneMapping,
        }
    }
    if (settings.lutUrl) {
        image = {
            type: "applyLUT",
            input: image,
            lut: {
                type: "predefinedLUT",
                url: settings.lutUrl,
            },
        }
    }
    return image
}

export function postProcessingGraph(data: PostProcessingInputData, settings: PostProcessingSettings, selectedMask?: CryptomatteId) {
    const cryptoManifest = data.maskData?.cryptoManifest
    const cryptoPasses = data.maskData?.cryptoPasses
    const masks = settings.masks

    type ImageNode = Nodes.ImageNode
    type MaskNode = Nodes.Mask

    let image: Nodes.ImageNode = data.combinedPass

    let shadow: Nodes.ImageNode | undefined | null = data.shadowCatcherPass

    let shadowMask: MaskNode | undefined
    if (data.shadowMaskPass) {
        shadowMask = {
            type: "colorToMask",
            input: data.shadowMaskPass,
        }
    }

    let cropRegion: Nodes.RegionNode | undefined
    let maybeCrop: <T>(x: T | undefined) => T | undefined
    if (settings.autoCrop && settings.autoCropMargin) {
        cropRegion = {
            type: "dilateRegion",
            amount: settings.autoCropMargin,
            region: {
                type: "detectMaskRegion",
                input: {
                    type: "alphaToMask",
                    input: image,
                },
            },
        }
        maybeCrop = (x: any) => (x ? {type: "crop", region: cropRegion, input: x} : undefined) as any
    } else {
        maybeCrop = (x: any) => x
    }

    image = maybeCrop(image)!
    shadow = maybeCrop(shadow)
    shadowMask = maybeCrop(shadowMask)

    const unprocessedImage = image
    image = subgraphForSettings(image, settings)

    if ((settings.processShadows ?? true) && shadowMask && shadow) {
        shadow = {
            type: "clamp",
            input: {
                type: "blend",
                mode: "screen",
                background: shadow,
                foreground: {
                    type: "adjustShadowMask",
                    input: shadowMask,
                    mode: "aoMask",
                    autoMargin: 3,
                    inner: settings.shadowInner ?? 0,
                    outer: settings.shadowOuter ?? 0,
                    falloff: settings.shadowFalloff ?? 1,
                },
            },
        }
    }

    if (shadow) {
        if (settings.transparent) {
            shadow = {
                type: "applyMask",
                input: {
                    type: "linearRGB",
                    color: [0, 0, 0],
                },
                mask: {
                    type: "invert",
                    input: {
                        type: "clamp",
                        input: {
                            type: "colorToMask",
                            input: shadow,
                        },
                    },
                },
            }
        }
        image = {
            type: "composite",
            foreground: image,
            background: shadow,
        }
    }

    function isColorTuple(x: any): x is Nodes.RGBColor {
        return Array.isArray(x) && x.length === 3 && x.every((num) => typeof num === "number")
    }

    if (settings.composite && settings.backgroundColor) {
        const getAlphaPremultipliedImageFromMask = (image: Nodes.ImageNode, mask: Nodes.ImageNode) => {
            const rgbaImage: Nodes.Convert = {type: "convert", input: image, channelLayout: "RGBA", dataType: "float32", sRGB: false}
            const r: Nodes.ExtractChannel = {type: "extractChannel", input: rgbaImage, channel: 0}
            const g: Nodes.ExtractChannel = {type: "extractChannel", input: rgbaImage, channel: 1}
            const b: Nodes.ExtractChannel = {type: "extractChannel", input: rgbaImage, channel: 2}
            const a: Nodes.Convert = {type: "convert", input: mask, channelLayout: "L", dataType: "float32", sRGB: false}
            const ra: Nodes.Math = {type: "math", firstInput: r, secondInput: a, operation: "*"}
            const ga: Nodes.Math = {type: "math", firstInput: g, secondInput: a, operation: "*"}
            const ba: Nodes.Math = {type: "math", firstInput: b, secondInput: a, operation: "*"}
            const premultipliedImage: Nodes.CombineChannels<"RGBA"> = {type: "combineChannels", channelLayout: "RGBA", input: [ra, ga, ba, a]}
            return premultipliedImage
        }
        image = {
            type: "composite",
            foreground: settings.mask ? getAlphaPremultipliedImageFromMask(image, settings.mask) : image,
            background: isColorTuple(settings.backgroundColor)
                ? {
                      type: "sRGB",
                      color: settings.backgroundColor,
                  }
                : settings.backgroundColor,
            linear: true,
        }
    }

    let selectedMaskNode: MaskNode | undefined
    if (masks && cryptoManifest && cryptoPasses) {
        const cryptoData: Nodes.CryptomatteData = {
            type: "cryptomatteData",
            manifest: {type: "value", value: cryptoManifest},
            passes: {type: "list", items: cryptoPasses.map((image) => maybeCrop(image)!)},
        }
        for (const [cryptoId, maskSettings] of typedEntries(masks)) {
            const mask: MaskNode = {type: "cryptomatteMask", data: cryptoData, ids: [cryptoId]}
            image = {
                type: "composite",
                foreground: subgraphForSettings(unprocessedImage, maskSettings, mask),
                background: image,
                linear: false, //FIXME ?
            }
            if (cryptoId === selectedMask) {
                selectedMaskNode = mask
            }
        }
    }

    return {
        image,
        selectedMask: selectedMaskNode,
    }
}
