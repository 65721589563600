import * as Types from '../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type QueryTextureSetDataQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type QueryTextureSetDataQuery = { __typename: 'Query', textureSet: { __typename: 'TextureSet', id: string, legacyId: number, name?: string | null, textureGroup: { __typename: 'TextureGroup', id: string }, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string, name?: string | null, width: number, height: number, displacement?: number | null, eligibleForMaterialAi: boolean }> } };

export type TextureSetViewRevisionDataFragment = { __typename: 'TextureSetRevision', id: string, name?: string | null, width: number, height: number, displacement?: number | null, eligibleForMaterialAi: boolean };

export type TextureSetViewDataFragment = { __typename: 'TextureSet', id: string, legacyId: number, name?: string | null, textureGroup: { __typename: 'TextureGroup', id: string }, textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string, name?: string | null, width: number, height: number, displacement?: number | null, eligibleForMaterialAi: boolean }> };

export type UpdateTextureSetNameMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpdateTextureSetNameMutation = { __typename: 'Mutation', updateTextureSet: { __typename: 'TextureSet', id: string } };

export type CreateInitialTextureSetRevisionMutationVariables = Types.Exact<{
  input: Types.MutationCreateTextureSetRevisionInput;
}>;


export type CreateInitialTextureSetRevisionMutation = { __typename: 'Mutation', createTextureSetRevision: { __typename: 'TextureSetRevision', id: string } };

export type QueryLatestTextureSetRevisionIdQueryVariables = Types.Exact<{
  textureSetId: Types.Scalars['ID']['input'];
}>;


export type QueryLatestTextureSetRevisionIdQuery = { __typename: 'Query', textureSetRevisions: Array<{ __typename: 'TextureSetRevision', id: string } | null> };

export type QueryInitialTextureRevisionsQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type QueryInitialTextureRevisionsQuery = { __typename: 'Query', textureSet: { __typename: 'TextureSet', scanJob?: { __typename: 'ScanJob', createdBy?: { __typename: 'User', id: string } | null } | null, textures: Array<{ __typename: 'Texture', type: Types.TextureType, revisions: Array<{ __typename: 'TextureRevision', number?: number | null, createdAt: string, width: number, height: number, displacement?: number | null, createdBy: { __typename: 'User', id: string }, dataObject: { __typename: 'DataObject', id: string } }> }> } };

export const TextureSetViewRevisionDataFragmentDoc = gql`
    fragment TextureSetViewRevisionData on TextureSetRevision {
  id
  name
  width
  height
  displacement
  eligibleForMaterialAi
}
    `;
export const TextureSetViewDataFragmentDoc = gql`
    fragment TextureSetViewData on TextureSet {
  id
  legacyId
  name
  textureGroup {
    id
  }
  textureSetRevisions {
    ...TextureSetViewRevisionData
  }
}
    `;
export const QueryTextureSetDataDocument = gql`
    query queryTextureSetData($id: ID) {
  textureSet(id: $id) {
    ...TextureSetViewData
  }
}
    ${TextureSetViewDataFragmentDoc}
${TextureSetViewRevisionDataFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryTextureSetDataGQL extends Apollo.Query<QueryTextureSetDataQuery, QueryTextureSetDataQueryVariables> {
    override document = QueryTextureSetDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTextureSetNameDocument = gql`
    mutation updateTextureSetName($id: ID!, $name: String) {
  updateTextureSet(input: {id: $id, name: $name}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTextureSetNameGQL extends Apollo.Mutation<UpdateTextureSetNameMutation, UpdateTextureSetNameMutationVariables> {
    override document = UpdateTextureSetNameDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateInitialTextureSetRevisionDocument = gql`
    mutation createInitialTextureSetRevision($input: MutationCreateTextureSetRevisionInput!) {
  createTextureSetRevision(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateInitialTextureSetRevisionGQL extends Apollo.Mutation<CreateInitialTextureSetRevisionMutation, CreateInitialTextureSetRevisionMutationVariables> {
    override document = CreateInitialTextureSetRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryLatestTextureSetRevisionIdDocument = gql`
    query queryLatestTextureSetRevisionId($textureSetId: ID!) {
  textureSetRevisions(
    take: 1
    filter: {textureSetId: {equals: $textureSetId}}
    orderBy: [{direction: desc, key: createdAt}]
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryLatestTextureSetRevisionIdGQL extends Apollo.Query<QueryLatestTextureSetRevisionIdQuery, QueryLatestTextureSetRevisionIdQueryVariables> {
    override document = QueryLatestTextureSetRevisionIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryInitialTextureRevisionsDocument = gql`
    query queryInitialTextureRevisions($id: ID, $legacyId: Int) {
  textureSet(id: $id, legacyId: $legacyId) {
    scanJob {
      createdBy {
        id
      }
    }
    textures {
      type
      revisions {
        number
        createdAt
        createdBy {
          id
        }
        width
        height
        displacement
        dataObject {
          id
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryInitialTextureRevisionsGQL extends Apollo.Query<QueryInitialTextureRevisionsQuery, QueryInitialTextureRevisionsQueryVariables> {
    override document = QueryInitialTextureRevisionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }