import {Component, viewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {MixShaderInputs, MixShaderOutputs} from "@material-editor/models/nodes/mix-shader"

@Component({
    selector: "cm-mix-shader-node",
    templateUrl: "./mix-shader.component.html",
    styleUrls: ["./mix-shader.component.scss"],
    imports: [NodeBaseComponent],
})
export class MixShaderNodeComponent {
    readonly $nodeBase = viewChild<MaterialNodeBase>("nodeBase")
    inputs = MixShaderInputs
    outputs = MixShaderOutputs
    typeInfo = MixShaderNodeType
}

export const MixShaderNodeType: MaterialNodeType<typeof MixShaderNodeComponent> = {
    id: "mixShader",
    label: "Mix Shader",
    color: "#4987af",
    name: "ShaderNodeMixShader",
    inputs: [MixShaderInputs.shader, MixShaderInputs.shader_001, MixShaderInputs.fac] as never[],
    outputs: [MixShaderOutputs.shader],
    component: MixShaderNodeComponent,
}
