import {Component, viewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {GammaInputs, GammaOutputs} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-gamma-node",
    templateUrl: "./gamma-node.component.html",
    styleUrls: ["./gamma-node.component.scss"],
    imports: [NodeBaseComponent],
})
export class GammaNodeComponent {
    readonly $nodeBase = viewChild<MaterialNodeBase>("nodeBase")
    outputs = GammaOutputs
    inputs = GammaInputs
    typeInfo = GammaNodeType
}

export const GammaNodeType: MaterialNodeType<typeof GammaNodeComponent> = {
    id: "gamma",
    label: "Gamma",
    color: "#6e5eb2",
    name: "ShaderNodeGamma",
    inputs: [GammaInputs.color, GammaInputs.gamma],
    outputs: [GammaOutputs.color],
    component: GammaNodeComponent,
}
