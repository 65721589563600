import {Component, computed, DestroyRef, effect, inject, Injector, input, OnDestroy, OnInit, signal} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {MatMenuModule} from "@angular/material/menu"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatTooltipModule} from "@angular/material/tooltip"
import {DialogComponent} from "@app/common/components/dialogs/dialog/dialog.component"
import {NotificationsService} from "@app/common/services/notifications/notifications.service"
import {RenderingService} from "@app/common/services/rendering/rendering.service"
import {DIALOG_DEFAULT_WIDTH} from "@app/template-editor/helpers/constants"
import {
    createPostProcessJob,
    createRenderJob,
    deletePostProcessJob,
    deleteRenderJob,
    getAllJobAssignments,
    getAssignmentKey,
    getConfigurationString,
    getJobAssignments,
    JobData,
    parseAssignmentKey,
    UnassignedJobData,
} from "@app/template-editor/helpers/render-jobs"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {ConfigInfo, Parameters, SceneNodes} from "@cm/template-nodes"
import {hashObject} from "@cm/utils/hashing"
import {fetchThrowingErrors} from "@common/helpers/api/fetch"
import {GetJobDetailsForTemplateImageViewerGQL} from "@template-editor/components/template-image-viewer/template-image-viewer.component.generated"
import {TemplateJobManagerService} from "@template-editor/services/template-job-manager.service"
import {combineLatest, concatMap, firstValueFrom, from, map, Observable, of, switchMap, tap} from "rxjs"
import {ButtonComponent} from "../../../common/components/buttons/button/button.component"
import {ListItemComponent} from "../../../common/components/item/list-item/list-item.component"
import {TemplateJobIconComponent} from "../template-job-icon/template-job-icon.component"
import {ConfigurationInfo, gatherAllVariations} from "@app/template-editor/helpers/all-variations"

type ConfigurationData = ConfigurationInfo & {
    render?: JobData
    postProcess?: JobData
}

@Component({
    selector: "cm-template-all-variations",
    imports: [MatProgressBarModule, MatTooltipModule, ListItemComponent, TemplateJobIconComponent, ButtonComponent, MatMenuModule],
    providers: [SceneManagerService],
    templateUrl: "./template-all-variations.component.html",
    styleUrl: "./template-all-variations.component.scss",
})
export class TemplateAllVariationsComponent implements OnInit, OnDestroy {
    readonly $sceneManagerService = input.required<SceneManagerService>({alias: "sceneManagerService"})

    private readonly localSceneManagerService = inject(SceneManagerService)
    private readonly destroyRef = inject(DestroyRef)
    private readonly injector = inject(Injector)
    private readonly matDialog = inject(MatDialog)
    private readonly notifications = inject(NotificationsService)
    private readonly renderingService = inject(RenderingService)
    private readonly templateJobManagerService = inject(TemplateJobManagerService)

    readonly jobGql = inject(GetJobDetailsForTemplateImageViewerGQL)

    private readonly $_progress = signal<number>(0)
    $progress = this.$_progress.asReadonly()

    readonly $state = signal<"loading" | "loaded" | "error">("loading")

    readonly $renderNode = computed(() => this.$sceneManagerService().$scene().find(SceneNodes.RenderSettings.is))
    readonly $renderAllDisabled = computed(() => this.$state() !== "loaded" || this.$renderNode() === undefined)
    readonly $postProcessingSettings = computed(() => this.$sceneManagerService().$scene().find(SceneNodes.RenderPostProcessingSettings.is))
    readonly $finalizeAllDisabled = computed(() => this.$state() !== "loaded" || this.$postProcessingSettings() === undefined)
    readonly $deleteAllDisabled = computed(() => this.$state() !== "loaded")

    readonly $allVariations = signal<ConfigurationData[]>([])
    readonly $allConfigInfo = signal<ConfigInfo[]>([])

    $unassignedJobs = this.templateJobManagerService.$unassignedJobs
    $selectedUnassignedJob = this.templateJobManagerService.$selectedUnassignedJob

    readonly $currentLocalConfigurationHash = computed(() => {
        return this.$sceneManagerService().$currentLocalConfiguration().getHash()
    })

    async setConfiguration(configuration: ConfigurationData) {
        this.$sceneManagerService().$lodType.set("pathTraced")
        this.$sceneManagerService().$instanceParameters.set(configuration.parameters)

        const templateRevisionId = this.$sceneManagerService().$templateRevisionId()
        if (!templateRevisionId) return

        const configurationString = getConfigurationString(configuration.parameters)

        const [render, postProcess] = await getJobAssignments(this.injector, templateRevisionId, [
            getAssignmentKey("render", configurationString),
            getAssignmentKey("postProcess", configurationString),
        ])

        this.$allVariations.update((variations) => variations.map((v) => (v.hash === configuration.hash ? {...configuration, render, postProcess} : v)))
        this.$selectedUnassignedJob.set(undefined)
    }

    constructor() {
        effect(() => {
            const state = this.$state()
            if (state === "loaded") this.refreshUnassignedJobs()
        })

        effect(() => {
            if (this.$selectedUnassignedJob() === undefined) this.refreshUnassignedJobs()
        })
    }

    ngOnInit() {
        this.localSceneManagerService.$lodType.set("pathTraced")
        const includeAllSubTemplateInputs = false
        this.localSceneManagerService.$exposeClaimedSubTemplateInputs.set(includeAllSubTemplateInputs)

        const sceneManagerService = this.$sceneManagerService()

        combineLatest([sceneManagerService.templateRevisionId$, sceneManagerService.defaultCustomerId$, sceneManagerService.templateGraph$])
            .pipe(
                tap(() => {
                    this.$allVariations.set([])
                    this.$_progress.set(0)

                    this.$state.set("loading")
                }),
                switchMap(([templateRevisionId, defaultCustomerId, templateGraph]) => {
                    this.localSceneManagerService.$templateRevisionId.set(templateRevisionId)
                    this.localSceneManagerService.$defaultCustomerId.set(defaultCustomerId)
                    const clonedTemplateGraph = templateGraph.clone({cloneSubNode: () => true})
                    this.localSceneManagerService.$templateGraph.set(clonedTemplateGraph)

                    const configInfoSideEffect = (configInfo: ConfigInfo) => {
                        this.$allConfigInfo.update((oldValue) => {
                            if (oldValue.some((x) => x.props.id === configInfo.props.id)) return oldValue
                            return [...oldValue, configInfo]
                        })
                    }

                    return gatherAllVariations(this.localSceneManagerService, (progress) => this.$_progress.set(progress), configInfoSideEffect).pipe(
                        map((variation) => [variation, templateRevisionId] as const),
                    )
                }),
                concatMap(([variation, templateRevisionId]) => {
                    if (variation === "done") return of("done" as const)

                    if (!templateRevisionId) return of(variation)

                    const configurationString = getConfigurationString(variation.parameters)

                    return from(
                        getJobAssignments(this.injector, templateRevisionId, [
                            getAssignmentKey("render", configurationString),
                            getAssignmentKey("postProcess", configurationString),
                        ]),
                    ).pipe(
                        map(([render, postProcess]) => ({
                            ...variation,
                            render,
                            postProcess,
                        })),
                    )
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe({
                next: (variation) => {
                    if (variation === "done") {
                        console.log("Done gathering variations", this.$allVariations().length)
                        this.$state.set("loaded")
                    } else {
                        console.log("Added variation to allVariations")
                        this.$allVariations.update((oldValue) => {
                            const newValue = [...oldValue, variation]
                            newValue.sort((a, b) => a.name.localeCompare(b.name))
                            return newValue
                        })
                    }
                },
                error: (e) => {
                    this.$state.set("error")
                    console.error("Error while gathering variations", e)
                },
            })
    }

    async submitAllJobs(type: "render" | "postProcess") {
        const variations = this.$allVariations().filter(
            (variation) => variation[type] === undefined && (type === "render" ? true : variation.render !== undefined),
        )
        if (variations.length > 200) this.notifications.showInfo(`Starting more than 200 render jobs is not allowed.`)

        const dialogRef: MatDialogRef<DialogComponent, boolean> = this.matDialog.open(DialogComponent, {
            disableClose: false,
            width: DIALOG_DEFAULT_WIDTH,
            data: {
                title: "Submit jobs",
                message: `You are submitting <b>${variations.length}</b> ${type === "render" ? "render" : "finalization"} jobs.</br></br>Are you sure you want to continue?`,
                confirmLabel: "Submit jobs",
                cancelLabel: "Cancel",
            },
        })

        const confirmed = await firstValueFrom(dialogRef.afterClosed().pipe(takeUntilDestroyed(this.destroyRef)))
        if (confirmed !== true) return

        const templateRevisionId = this.localSceneManagerService.$templateRevisionId()
        if (!templateRevisionId) throw Error("No template revision id set")

        const defaultCustomerId = this.localSceneManagerService.$defaultCustomerId()
        if (!defaultCustomerId) throw Error("No default customer id set")

        let numSubmitted = 0
        let numFailed = 0
        for (const variation of variations) {
            try {
                const [jobAssignment, job] = await (async () => {
                    const variationString = getConfigurationString(variation.parameters)

                    if (type === "render") {
                        this.localSceneManagerService.$instanceParameters.set(variation.parameters)

                        this.localSceneManagerService.compileTemplate()
                        await this.localSceneManagerService.sync(true)

                        return createRenderJob(
                            this.injector,
                            this.renderingService,
                            templateRevisionId,
                            variationString,
                            this.localSceneManagerService.$scene(),
                            defaultCustomerId,
                        )
                    } else {
                        const postProcessingSettings = this.$postProcessingSettings()
                        if (!postProcessingSettings) throw Error("No post processing settings set")

                        return createPostProcessJob(this.injector, templateRevisionId, variationString, postProcessingSettings, defaultCustomerId)
                    }
                })()
                this.$allVariations.update((variations) => variations.map((v) => (v.hash === variation.hash ? {...variation, [type]: job} : v)))
                numSubmitted += 1
            } catch (e) {
                console.error("Error while submitting job", e)
                numFailed += 1
            }
        }

        this.notifications.showInfo(
            `Submitted ${numSubmitted} ${type === "render" ? "render" : "finalization"} jobs.${numFailed > 0 ? ` Failed to submit ${numFailed} jobs.` : ""}`,
        )
    }

    async deleteAllJobs(type: "postProcess" | "both") {
        const variations = this.$allVariations().filter((variation) => {
            if (type === "both") return variation.render !== undefined || variation.postProcess !== undefined
            return variation[type] !== undefined
        })

        const dialogRef: MatDialogRef<DialogComponent, boolean> = this.matDialog.open(DialogComponent, {
            disableClose: false,
            width: DIALOG_DEFAULT_WIDTH,
            data: {
                title: "Submit jobs",
                message: `You are deleting ${type === "postProcess" ? "finalizations" : "images"} of <b>${variations.length}</b> configurations.</br></br>Are you sure you want to continue?`,
                confirmLabel: "Submit jobs",
                cancelLabel: "Cancel",
            },
        })

        const confirmed = await firstValueFrom(dialogRef.afterClosed().pipe(takeUntilDestroyed(this.destroyRef)))
        if (confirmed !== true) return

        const templateRevisionId = this.localSceneManagerService.$templateRevisionId()
        if (!templateRevisionId) throw Error("No template revision id set")

        let numDeleted = 0
        let numFailed = 0
        for (const variation of variations) {
            try {
                const configurationString = getConfigurationString(variation.parameters)
                if (type === "both" || type === "postProcess") await deletePostProcessJob(this.injector, templateRevisionId, configurationString)
                if (type === "both") await deleteRenderJob(this.injector, templateRevisionId, configurationString)
                this.$allVariations.update((variations) =>
                    variations.map((v) =>
                        v.hash === variation.hash ? {...variation, render: type === "both" ? undefined : variation.render, postProcess: undefined} : v,
                    ),
                )
                numDeleted += 1
            } catch (e) {
                console.error("Error while deleting job", e)
                numFailed += 1
            }
        }

        this.notifications.showInfo(
            `Deleted ${numDeleted} ${type === "postProcess" ? "finalizations" : "images"} of configurations.${
                numFailed > 0 ? ` Failed to delete ${numFailed} ${type === "postProcess" ? "finalizations" : "images"} of configurations.` : ""
            }`,
        )
    }

    refreshUnassignedJobs() {
        const templateRevisionId = this.localSceneManagerService.$templateRevisionId()
        if (!templateRevisionId) return

        const displayName = (configuration?: Parameters) => {
            const fallbackDisplayName = "Unknown config(s)"
            if (!configuration) return fallbackDisplayName

            const configNames: string[] = []
            let someConfigNotFound = false
            Object.entries(configuration.parameters).forEach(([key, value]) => {
                const allConfigInfo = this.$allConfigInfo()
                const configName = allConfigInfo.find((x) => x.props.id === key)?.props.name
                const variantName = allConfigInfo.find((x) => x.props.id === key)?.props.variants.find((x) => x.id === value)?.name
                if (configName === undefined || variantName === undefined) {
                    someConfigNotFound = true
                    return
                }
                configNames.push(`${configName}: ${variantName}`)
            })
            if (someConfigNotFound) configNames.unshift(fallbackDisplayName)
            return configNames.length > 0 ? configNames.join(", ") : "Single Variation"
        }

        getAllJobAssignments(this.injector, templateRevisionId).then((jobAssignments) => {
            const allVariations = this.$allVariations()
            const unassignedJobs = jobAssignments
                .filter((jobAssignment) => {
                    if (!jobAssignment || !jobAssignment.assignmentKey) return false
                    return !allVariations.some(
                        (variation) => variation.render?.id === jobAssignment.job.id || variation.postProcess?.id === jobAssignment.job.id,
                    )
                })
                .map((jobAssignment) => {
                    // TODO: From version 5.5 onwards typescript allows for correct type inference here. Remove ! after the upgrade
                    const {type, configurationParameters} = parseAssignmentKey(jobAssignment!.assignmentKey!)
                    return {
                        job: jobAssignment!.job,
                        displayName: displayName(configurationParameters),
                        type: type,
                    }
                })
            this.$unassignedJobs.set(unassignedJobs)
        })
    }

    async selectUnassignedJob(job: UnassignedJobData) {
        const refreshedJobQuery = await fetchThrowingErrors(this.jobGql)(job.job)
        const refreshedJob = {...job, job: refreshedJobQuery.job}
        this.$unassignedJobs.update((oldJobs) => oldJobs.map((oldJob) => (oldJob.job.id === job.job.id ? refreshedJob : oldJob)))
        this.$selectedUnassignedJob.set(refreshedJob)
    }

    ngOnDestroy() {
        this.$selectedUnassignedJob.set(undefined)
    }
}
