import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TemplateDetailsForTemplateInstanceInspectorFragment = { __typename: 'Template', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number, number: number }>, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, number: number } | null };

export type TemplateRevisionDetailsForTemplateInstanceInspectorFragment = { __typename: 'TemplateRevision', id: string, legacyId: number, template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number, number: number }>, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, number: number } | null } };

export type GetTemplateRevisionDetailsForTemplateInstanceInspectorQueryVariables = Types.Exact<{
  legacyId: Types.Scalars['Int']['input'];
}>;


export type GetTemplateRevisionDetailsForTemplateInstanceInspectorQuery = { __typename: 'Query', templateRevision: { __typename: 'TemplateRevision', id: string, legacyId: number, template: { __typename: 'Template', id: string, legacyId: number, name?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null, revisions: Array<{ __typename: 'TemplateRevision', id: string, legacyId: number, number: number }>, latestRevision?: { __typename: 'TemplateRevision', id: string, legacyId: number, number: number } | null } } };

export const TemplateDetailsForTemplateInstanceInspectorFragmentDoc = gql`
    fragment TemplateDetailsForTemplateInstanceInspector on Template {
  id
  legacyId
  name
  galleryImage {
    id
  }
  revisions {
    id
    legacyId
    number
  }
  latestRevision {
    id
    legacyId
    number
  }
}
    `;
export const TemplateRevisionDetailsForTemplateInstanceInspectorFragmentDoc = gql`
    fragment TemplateRevisionDetailsForTemplateInstanceInspector on TemplateRevision {
  id
  legacyId
  template {
    ...TemplateDetailsForTemplateInstanceInspector
  }
}
    `;
export const GetTemplateRevisionDetailsForTemplateInstanceInspectorDocument = gql`
    query getTemplateRevisionDetailsForTemplateInstanceInspector($legacyId: Int!) {
  templateRevision(legacyId: $legacyId) {
    ...TemplateRevisionDetailsForTemplateInstanceInspector
  }
}
    ${TemplateRevisionDetailsForTemplateInstanceInspectorFragmentDoc}
${TemplateDetailsForTemplateInstanceInspectorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplateRevisionDetailsForTemplateInstanceInspectorGQL extends Apollo.Query<GetTemplateRevisionDetailsForTemplateInstanceInspectorQuery, GetTemplateRevisionDetailsForTemplateInstanceInspectorQueryVariables> {
    override document = GetTemplateRevisionDetailsForTemplateInstanceInspectorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }