import {Component, computed, input, output, signal} from "@angular/core"
import {SplineCurveComponent} from "../spline-curve/spline-curve.component"
import {Vector2Like} from "@cm/math"

type CurveType = "rgb" | "r" | "g" | "b"

@Component({
    selector: "cm-rgb-curves",
    imports: [SplineCurveComponent],
    templateUrl: "./rgb-curves.component.html",
    styleUrl: "./rgb-curves.component.scss",
})
export class RgbCurvesComponent {
    readonly $controlPoints = input.required<{r: Vector2Like[]; g: Vector2Like[]; b: Vector2Like[]; rgb: Vector2Like[]}>({alias: "controlPoints"})
    readonly $selectedCurveType = signal<CurveType>("rgb")
    readonly $splineControlPoints = computed(() => {
        const curveType = this.$selectedCurveType()
        const controlPoints = this.$controlPoints()
        return controlPoints[curveType]
    })
    readonly $width = input(100, {alias: "width"})
    readonly $height = input(100, {alias: "height"})

    readonly onControlPointAdded = output<{curve: CurveType; point: Vector2Like}>()
    readonly onControlPointRemoved = output<{curve: CurveType; pointId: number}>()
    readonly onBeginControlPointDrag = output<void>()
    readonly onControlPointChanged = output<{curve: CurveType; pointId: number; point: Vector2Like}>()
    readonly onEndControlPointDrag = output<void>()
}
