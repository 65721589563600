import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AssetListItemFragment = { __typename: 'Asset', id: string, legacyId: number, articleId?: string | null, comment?: string | null, name?: string | null, nextActor: Types.NextActor, state: Types.AssetState, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null };

export type GetAssetsQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  take?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.AssetFilterInput>;
  orderBy?: Types.InputMaybe<Array<Types.AssetOrderByInput> | Types.AssetOrderByInput>;
}>;


export type GetAssetsQuery = { __typename: 'Query', assetsCount: number, assets: Array<{ __typename: 'Asset', id: string, legacyId: number, articleId?: string | null, comment?: string | null, name?: string | null, nextActor: Types.NextActor, state: Types.AssetState, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } | null> };

export type BatchUpdateAssetsMutationVariables = Types.Exact<{
  filter: Types.AssetFilterInput;
  state?: Types.InputMaybe<Types.AssetState>;
  addTagId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  removeTagId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  assignUserId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  removeUserAssignment?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  nextActor?: Types.InputMaybe<Types.NextActor>;
}>;


export type BatchUpdateAssetsMutation = { __typename: 'Mutation', batchUpdateAssets: number };

export type CreateAssetMutationVariables = Types.Exact<{
  input: Types.MutationCreateAssetInput;
}>;


export type CreateAssetMutation = { __typename: 'Mutation', createAsset: { __typename: 'Asset', id: string, legacyId: number, articleId?: string | null, comment?: string | null, name?: string | null, nextActor: Types.NextActor, state: Types.AssetState, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } };

export type UpdateAssetMutationVariables = Types.Exact<{
  input: Types.MutationUpdateAssetInput;
}>;


export type UpdateAssetMutation = { __typename: 'Mutation', updateAsset: { __typename: 'Asset', id: string, legacyId: number, articleId?: string | null, comment?: string | null, name?: string | null, nextActor: Types.NextActor, state: Types.AssetState, organization: { __typename: 'Organization', id: string, legacyId: number, name?: string | null }, galleryImage?: { __typename: 'DataObject', id: string } | null, taskInfos: { __typename: 'TaskInfos', inProgress: number, waitingForFeedback: number, completed: number, archived: number }, currentUserAssignment?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, galleryImage?: { __typename: 'DataObject', id: string } | null } | null } };

export const AssetListItemFragmentDoc = gql`
    fragment AssetListItem on Asset {
  __typename
  id
  legacyId
  articleId
  comment
  name
  nextActor
  state
  organization {
    id
    legacyId
    name
  }
  galleryImage {
    id
  }
  taskInfos {
    inProgress
    waitingForFeedback
    completed
    archived
  }
  currentUserAssignment {
    id
    firstName
    lastName
    galleryImage {
      id
    }
  }
}
    `;
export const GetAssetsDocument = gql`
    query getAssets($skip: Int, $take: Int, $filter: AssetFilterInput, $orderBy: [AssetOrderByInput!]) {
  assets(skip: $skip, take: $take, filter: $filter, orderBy: $orderBy) {
    ...AssetListItem
  }
  assetsCount(filter: $filter)
}
    ${AssetListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAssetsGQL extends Apollo.Query<GetAssetsQuery, GetAssetsQueryVariables> {
    override document = GetAssetsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BatchUpdateAssetsDocument = gql`
    mutation batchUpdateAssets($filter: AssetFilterInput!, $state: AssetState, $addTagId: ID, $removeTagId: ID, $assignUserId: ID, $removeUserAssignment: Boolean, $nextActor: NextActor) {
  batchUpdateAssets(
    filter: $filter
    addTagId: $addTagId
    assignUserId: $assignUserId
    removeTagId: $removeTagId
    removeUserAssignment: $removeUserAssignment
    state: $state
    nextActor: $nextActor
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BatchUpdateAssetsGQL extends Apollo.Mutation<BatchUpdateAssetsMutation, BatchUpdateAssetsMutationVariables> {
    override document = BatchUpdateAssetsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAssetDocument = gql`
    mutation createAsset($input: MutationCreateAssetInput!) {
  createAsset(input: $input) {
    ...AssetListItem
  }
}
    ${AssetListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAssetGQL extends Apollo.Mutation<CreateAssetMutation, CreateAssetMutationVariables> {
    override document = CreateAssetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAssetDocument = gql`
    mutation updateAsset($input: MutationUpdateAssetInput!) {
  updateAsset(input: $input) {
    ...AssetListItem
  }
}
    ${AssetListItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAssetGQL extends Apollo.Mutation<UpdateAssetMutation, UpdateAssetMutationVariables> {
    override document = UpdateAssetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }