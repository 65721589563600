import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AuthenticatedUserFragment = { __typename: 'User', id: string, email: string, isStaff: boolean, isSuperuser: boolean, name: string, role?: Types.SystemRole | null, galleryImage?: { __typename: 'DataObject', id: string } | null, memberships: Array<{ __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, name?: string | null, type?: Types.OrganizationType | null } }> };

export type AuthSiloDataFragment = { __typename: 'User', id: string, role?: Types.SystemRole | null, memberships: Array<{ __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, name?: string | null, type?: Types.OrganizationType | null } }> };

export type AuthSiloDataQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type AuthSiloDataQuery = { __typename: 'Query', user: { __typename: 'User', id: string, email: string, isStaff: boolean, isSuperuser: boolean, name: string, role?: Types.SystemRole | null, galleryImage?: { __typename: 'DataObject', id: string } | null, memberships: Array<{ __typename: 'Membership', id: string, organization: { __typename: 'Organization', id: string, name?: string | null, type?: Types.OrganizationType | null } }> } };

export const AuthenticatedUserFragmentDoc = gql`
    fragment AuthenticatedUser on User {
  id
  __typename
  email
  isStaff
  isSuperuser
  name
  role
  galleryImage {
    id
  }
  memberships {
    id
    organization {
      id
      name
      type
    }
  }
}
    `;
export const AuthSiloDataFragmentDoc = gql`
    fragment AuthSiloData on User {
  id
  __typename
  role
  memberships {
    id
    organization {
      id
      name
      type
    }
  }
}
    `;
export const AuthSiloDataDocument = gql`
    query authSiloData($id: ID!) {
  user(id: $id) {
    ...AuthenticatedUser
  }
}
    ${AuthenticatedUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthSiloDataGQL extends Apollo.Query<AuthSiloDataQuery, AuthSiloDataQueryVariables> {
    override document = AuthSiloDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }