import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetJobAssignmentsDetailsForTemplateImageViewerQueryVariables = Types.Exact<{
  filter: Types.JobAssignmentFilterInput;
}>;


export type GetJobAssignmentsDetailsForTemplateImageViewerQuery = { __typename: 'Query', jobAssignments: Array<{ __typename: 'JobAssignment', id: string, assignmentKey?: string | null, job: { __typename: 'Job', id: string, name?: string | null, output?: any | null, state: Types.JobState, progress?: number | null, message?: string | null } } | null> };

export type GetJobDetailsForTemplateImageViewerQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetJobDetailsForTemplateImageViewerQuery = { __typename: 'Query', job: { __typename: 'Job', id: string, name?: string | null, output?: any | null, state: Types.JobState, progress?: number | null, message?: string | null } };

export type GetDataObjectDetailsForTemplateImageViewerQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  legacyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  fileType?: Types.InputMaybe<Types.DownloadFileType>;
  resolution?: Types.InputMaybe<Types.DownloadResolution>;
}>;


export type GetDataObjectDetailsForTemplateImageViewerQuery = { __typename: 'Query', dataObject: { __typename: 'DataObject', id: string, downloadUrl: string, mediaType?: string | null, imageColorSpace?: Types.ImageColorSpace | null, imageColorProfile?: string | null, width?: number | null, height?: number | null, size?: any | null, originalFileName: string, thumbnail?: { __typename: 'DataObject', downloadUrl: string } | null } };

export type JobAssignmentDetailsForTemplateImageViewerFragment = { __typename: 'JobAssignment', id: string, assignmentKey?: string | null, job: { __typename: 'Job', id: string, name?: string | null, output?: any | null, state: Types.JobState, progress?: number | null, message?: string | null } };

export type CreateJobAssignmentForTemplateImageViewerMutationVariables = Types.Exact<{
  input: Types.MutationCreateJobAssignmentInput;
}>;


export type CreateJobAssignmentForTemplateImageViewerMutation = { __typename: 'Mutation', createJobAssignment: { __typename: 'JobAssignment', id: string, assignmentKey?: string | null, job: { __typename: 'Job', id: string, name?: string | null, output?: any | null, state: Types.JobState, progress?: number | null, message?: string | null } } };

export type DeleteJobForTemplateImageViewerMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteJobForTemplateImageViewerMutation = { __typename: 'Mutation', deleteJob?: any | null };

export type JobDetailsForTemplateImageViewerFragment = { __typename: 'Job', id: string, name?: string | null, output?: any | null, state: Types.JobState, progress?: number | null, message?: string | null };

export type CreateJobForTemplateImageViewerMutationVariables = Types.Exact<{
  input: Types.MutationCreateJobInput;
}>;


export type CreateJobForTemplateImageViewerMutation = { __typename: 'Mutation', createJob: { __typename: 'Job', id: string, name?: string | null, output?: any | null, state: Types.JobState, progress?: number | null, message?: string | null } };

export type CancelJobForTemplateImageViewerMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type CancelJobForTemplateImageViewerMutation = { __typename: 'Mutation', cancelJob: { __typename: 'Job', id: string } };

export const JobDetailsForTemplateImageViewerFragmentDoc = gql`
    fragment JobDetailsForTemplateImageViewer on Job {
  id
  name
  output
  state
  progress
  message
}
    `;
export const JobAssignmentDetailsForTemplateImageViewerFragmentDoc = gql`
    fragment JobAssignmentDetailsForTemplateImageViewer on JobAssignment {
  id
  assignmentKey
  job {
    ...JobDetailsForTemplateImageViewer
  }
}
    `;
export const GetJobAssignmentsDetailsForTemplateImageViewerDocument = gql`
    query getJobAssignmentsDetailsForTemplateImageViewer($filter: JobAssignmentFilterInput!) {
  jobAssignments(filter: $filter) {
    ...JobAssignmentDetailsForTemplateImageViewer
  }
}
    ${JobAssignmentDetailsForTemplateImageViewerFragmentDoc}
${JobDetailsForTemplateImageViewerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetJobAssignmentsDetailsForTemplateImageViewerGQL extends Apollo.Query<GetJobAssignmentsDetailsForTemplateImageViewerQuery, GetJobAssignmentsDetailsForTemplateImageViewerQueryVariables> {
    override document = GetJobAssignmentsDetailsForTemplateImageViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetJobDetailsForTemplateImageViewerDocument = gql`
    query getJobDetailsForTemplateImageViewer($id: ID, $legacyId: Int) {
  job(id: $id, legacyId: $legacyId) {
    ...JobDetailsForTemplateImageViewer
  }
}
    ${JobDetailsForTemplateImageViewerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetJobDetailsForTemplateImageViewerGQL extends Apollo.Query<GetJobDetailsForTemplateImageViewerQuery, GetJobDetailsForTemplateImageViewerQueryVariables> {
    override document = GetJobDetailsForTemplateImageViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDataObjectDetailsForTemplateImageViewerDocument = gql`
    query getDataObjectDetailsForTemplateImageViewer($id: ID, $legacyId: Int, $fileType: DownloadFileType = jpg, $resolution: DownloadResolution = High) {
  dataObject(id: $id, legacyId: $legacyId) {
    id
    downloadUrl
    mediaType
    imageColorSpace
    imageColorProfile
    width
    height
    size
    originalFileName
    thumbnail(fileType: $fileType, resolution: $resolution) {
      downloadUrl
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDataObjectDetailsForTemplateImageViewerGQL extends Apollo.Query<GetDataObjectDetailsForTemplateImageViewerQuery, GetDataObjectDetailsForTemplateImageViewerQueryVariables> {
    override document = GetDataObjectDetailsForTemplateImageViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateJobAssignmentForTemplateImageViewerDocument = gql`
    mutation createJobAssignmentForTemplateImageViewer($input: MutationCreateJobAssignmentInput!) {
  createJobAssignment(input: $input) {
    ...JobAssignmentDetailsForTemplateImageViewer
  }
}
    ${JobAssignmentDetailsForTemplateImageViewerFragmentDoc}
${JobDetailsForTemplateImageViewerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateJobAssignmentForTemplateImageViewerGQL extends Apollo.Mutation<CreateJobAssignmentForTemplateImageViewerMutation, CreateJobAssignmentForTemplateImageViewerMutationVariables> {
    override document = CreateJobAssignmentForTemplateImageViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteJobForTemplateImageViewerDocument = gql`
    mutation deleteJobForTemplateImageViewer($id: ID!) {
  deleteJob(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteJobForTemplateImageViewerGQL extends Apollo.Mutation<DeleteJobForTemplateImageViewerMutation, DeleteJobForTemplateImageViewerMutationVariables> {
    override document = DeleteJobForTemplateImageViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateJobForTemplateImageViewerDocument = gql`
    mutation createJobForTemplateImageViewer($input: MutationCreateJobInput!) {
  createJob(input: $input) {
    ...JobDetailsForTemplateImageViewer
  }
}
    ${JobDetailsForTemplateImageViewerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateJobForTemplateImageViewerGQL extends Apollo.Mutation<CreateJobForTemplateImageViewerMutation, CreateJobForTemplateImageViewerMutationVariables> {
    override document = CreateJobForTemplateImageViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CancelJobForTemplateImageViewerDocument = gql`
    mutation cancelJobForTemplateImageViewer($id: ID!) {
  cancelJob(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CancelJobForTemplateImageViewerGQL extends Apollo.Mutation<CancelJobForTemplateImageViewerMutation, CancelJobForTemplateImageViewerMutationVariables> {
    override document = CancelJobForTemplateImageViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }