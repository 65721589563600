import {animate, style, transition, trigger} from "@angular/animations"

import {Component, input} from "@angular/core"
import {provideAnimations} from "@angular/platform-browser/animations"
import {LoadedDataItem} from "@platform/models/data"

@Component({
    selector: "cm-table-cell",
    imports: [],
    templateUrl: "./table-cell.component.html",
    styleUrl: "./table-cell.component.scss",
    animations: [
        trigger("fadeInPlaceholder", [transition("void => *", [style({opacity: 0.4, scale: 0.7}), animate("1000ms", style({opacity: 1, scale: 0.98}))])]),
    ],
    providers: [provideAnimations()],
})
export class TableCellComponent<EntityType> {
    readonly $item = input<LoadedDataItem<EntityType>>(undefined, {alias: "item"})
    readonly $centered = input(false, {alias: "centered"})
    readonly $header = input(false, {alias: "header"})
}
